import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    GET_FUND_TASK_USERS_BY_ENTITY_REQUEST,
    ADD_FUND_REQUEST,
    GET_FUND_LIST_REQUEST,
    GET_FUND_DETAILS_REQUEST,
    UPDATE_FUND_REQUEST,
    DELETE_FUND_BY_FUND_ID_REQUEST,
    RETRIEVE_FUND_BY_FUND_ID_REQUEST,
    GET_FUND_TASK_LIST_REQUEST,
    ADD_FUND_TASK_REQUEST,
    UPDATE_FUND_TASK_REQUEST,
    DELETE_FUND_TASK_BY_TASK_ID_REQUEST,
    ADD_FUND_SUB_TASK_REQUEST,
    DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_REQUEST,
    GET_FUND_TASK_DETAILS_REQUEST,
    ADD_FUND_TASK_COMMENT_REQUEST,
    ADD_FUND_TASK_ATTACHMENT_REQUEST,
    GET_FUND_TASK_ACTIVITY_REQUEST,
    DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST,
    CLOSE_FUND_REQUEST,
    ACTIVE_OR_DEACTIVE_FUND_REQUEST,
    GET_FUND_HEALTH_CHECK_REQUEST,
    GET_FUND_CHECK_ACCESS_REQUEST,
    UPDATE_FUND_TASK_SEQUENCE_ORDER_REQUEST,
    GET_FUND_TASK_COUNT_BY_FUND_ID_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import {
    FUND_TASK_USERS_BY_ENTITY,
    FUND,
    FUND_LIST,
    FUND_DETAILS,
    FUND_TASK_LIST,
    FUND_TASK,
    FUND_SUB_TASK,
    FUND_TASK_DETAILS,
    FUND_TASK_COMMENT,
    FUND_TASK_ATTACHMENT,
    FUND_TASK_ACTIVITY,
    FUND_TASK_DOCUMENT,
    CLOSE_FUND,
    ACTIVE_OR_DEACTIVE_FUND,
    FUND_HEALTH_DETAILS,
    FUND_CHECK_ACCESS,
    FUND_TASK_SEQUENCE_ORDER,
    FUND_TASK_COUNT,
    FUND_RETRIEVE
} from 'helpers/apiConstants';
import {
    getFundTaskUsersByEntityResponse,
    addFundResponse,
    getFundListResponse,
    getFundDetailsResponse,
    updateFundResponse,
    deleteFundByFundIdResponse,
    getFundListRequest,
    getFundTaskListResponse,
    addFundTaskResponse,
    updateFundTaskResponse,
    deleteFundTaskByTaskidResponse,
    addFundSubTaskResponse,
    deleteFundSubTaskBySubTaskIdResponse,
    getFundTaskDetailsResponse,
    addFundTaskCommentResponse,
    addFundTaskAttachmentResponse,
    getFundTaskActivityResponse,
    deleteFundTaskDocumentByDocumentIdResponse,
    closeFundResponse,
    activeOrDeactiveFundResponse,
    getFundHealthCheckResponse,
    getFundCheckAccessResponse,
    updateFundTaskSequenceOrderResponse,
    getFundTaskCountByFundIdResponse,
    retrieveFundByFundIdResponse,
} from './actions';
import { DANGER, SUCCESS } from 'constants/commonConstants';

function* fundTaskUsersByEntityList({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            FUND_TASK_USERS_BY_ENTITY,
            data
        );
        if (response.status) {
            yield put(getFundTaskUsersByEntityResponse(response.data.list));
        } else {
            yield put(getFundTaskUsersByEntityResponse({}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* addFund({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', FUND, data);
        if (response.status) {
            yield put(getFundListRequest());
            yield put(addFundResponse(response.message, SUCCESS));
        } else {
            yield put(addFundResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getFundList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', FUND_LIST, data);

        if (response.status) {
            yield put(getFundListResponse(response.data.list));
        } else {
            yield put(getFundListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getFundDetails({ payload: { fundDetailsId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            FUND_DETAILS + fundDetailsId
        );

        if (response.status) {
            yield put(
                getFundDetailsResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getFundDetailsResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateFund({ payload: { fundId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            FUND + '/' + fundId,
            data
        );

        if (response.status) {
            yield put(getFundListRequest());
            yield put(updateFundResponse(response.message, SUCCESS));
        } else {
            yield put(updateFundResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteFundByFundId({ payload: { fundId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            FUND + '/' + fundId
        );

        if (response.status) {
            yield put(getFundListRequest());
            yield put(
                deleteFundByFundIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                deleteFundByFundIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* retrieveFundByFundId({ payload: { fundId } }) {
    try {
        const response = yield call(invokeApi, 'post', FUND_RETRIEVE + '/' + fundId);

        if (response.status) {
            yield put(getFundListRequest());
            yield put(retrieveFundByFundIdResponse(response.message, SUCCESS));
        } else {
            yield put(retrieveFundByFundIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getFundTaskList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', FUND_TASK_LIST, data);

        if (response.status) {
            yield put(
                getFundTaskListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getFundTaskListResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addFundTask({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', FUND_TASK, data);

        if (response.status) {
            yield put(addFundTaskResponse(response.message, SUCCESS));
        } else {
            yield put(addFundTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateFundTask({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            FUND_TASK + '/' + taskId,
            data
        );
        if (response.status) {
            yield put(updateFundTaskResponse(response.message, SUCCESS));
        } else {
            yield put(updateFundTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteFundTaskByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            FUND_TASK + '/' + taskId
        );

        if (response.status) {
            yield put(
                deleteFundTaskByTaskidResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                deleteFundTaskByTaskidResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addFundSubTask({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            FUND_SUB_TASK + '/' + taskId,
            data
        );

        if (response.status) {
            yield put(addFundSubTaskResponse(response.message, SUCCESS));
        } else {
            yield put(addFundSubTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteFundSubTaskBySubTaskId({ payload: { subTaskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            FUND_TASK + '/' + subTaskId
        );

        if (response.status) {
            yield put(
                deleteFundSubTaskBySubTaskIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteFundSubTaskBySubTaskIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getFundTaskDetaiksList({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            FUND_TASK_DETAILS + taskId
        );

        if (response.status) {
            yield put(getFundTaskDetailsResponse(response.data.list));
        } else {
            yield put(getFundTaskDetailsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addFundTaskComment({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            FUND_TASK_COMMENT + taskId,
            data
        );

        if (response.status) {
            yield put(addFundTaskCommentResponse(response.message, SUCCESS));
        } else {
            yield put(addFundTaskCommentResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* addFundTaskAttachment({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            FUND_TASK_ATTACHMENT,
            data
        );

        if (response.status) {
            yield put(
                addFundTaskAttachmentResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                addFundTaskAttachmentResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getFundTaskActivity({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            FUND_TASK_ACTIVITY + taskId
        );

        if (response.status) {
            yield put(
                getFundTaskActivityResponse(response.message, response.data, SUCCESS)
            );
        } else {
            getFundTaskActivityResponse(response.message, {}, DANGER)

        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteFundTaskDocumentByDocumentId({ payload: { documentId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            FUND_TASK_DOCUMENT + '/' + documentId
        );

        if (response.status) {
            yield put(
                deleteFundTaskDocumentByDocumentIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteFundTaskDocumentByDocumentIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* closeFund({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            CLOSE_FUND + '/' + id
        );

        if (response.status) {
            yield put(
                closeFundResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                closeFundResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* activeOrDeactiveFund({ payload: { id, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            ACTIVE_OR_DEACTIVE_FUND + '/' + id,
            data
        );

        if (response.status) {
            yield put(getFundListRequest());
            yield put(
                activeOrDeactiveFundResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                activeOrDeactiveFundResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getFundHealthCheck({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            FUND_HEALTH_DETAILS, data
        );

        if (response.status) {
            yield put(
                getFundHealthCheckResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getFundHealthCheckResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getFundCheckAccess({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            FUND_CHECK_ACCESS
        );

        if (response.status) {
            yield put(
                getFundCheckAccessResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getFundCheckAccessResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* updateFundTaskSequenceOrder({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            FUND_TASK_SEQUENCE_ORDER,
            data
        );

        if (response.status) {
            yield put(updateFundTaskSequenceOrderResponse(response.message, SUCCESS));
        } else {
            yield put(updateFundTaskSequenceOrderResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* getFundTaskCountByFundId({ payload: { fundId } }) {
    try {
        const response = yield call(invokeApi, 'get', FUND_TASK_COUNT + '/' + fundId);

        if (response.status) {
            yield put(
                getFundTaskCountByFundIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getFundTaskCountByFundIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchFunds() {
    yield takeEvery(GET_FUND_TASK_USERS_BY_ENTITY_REQUEST, fundTaskUsersByEntityList);
    yield takeEvery(ADD_FUND_REQUEST, addFund);
    yield takeEvery(GET_FUND_LIST_REQUEST, getFundList);
    yield takeEvery(GET_FUND_DETAILS_REQUEST, getFundDetails);
    yield takeEvery(UPDATE_FUND_REQUEST, updateFund);
    yield takeEvery(DELETE_FUND_BY_FUND_ID_REQUEST, deleteFundByFundId);
    yield takeEvery(RETRIEVE_FUND_BY_FUND_ID_REQUEST, retrieveFundByFundId);
    yield takeEvery(GET_FUND_TASK_LIST_REQUEST, getFundTaskList);
    yield takeEvery(ADD_FUND_TASK_REQUEST, addFundTask);
    yield takeEvery(UPDATE_FUND_TASK_REQUEST, updateFundTask);
    yield takeEvery(DELETE_FUND_TASK_BY_TASK_ID_REQUEST, deleteFundTaskByTaskId);
    yield takeEvery(ADD_FUND_SUB_TASK_REQUEST, addFundSubTask);
    yield takeEvery(DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_REQUEST, deleteFundSubTaskBySubTaskId);
    yield takeEvery(GET_FUND_TASK_DETAILS_REQUEST, getFundTaskDetaiksList);
    yield takeEvery(ADD_FUND_TASK_COMMENT_REQUEST, addFundTaskComment);
    yield takeEvery(ADD_FUND_TASK_ATTACHMENT_REQUEST, addFundTaskAttachment);
    yield takeEvery(GET_FUND_TASK_ACTIVITY_REQUEST, getFundTaskActivity);
    yield takeEvery(DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST, deleteFundTaskDocumentByDocumentId);
    yield takeEvery(CLOSE_FUND_REQUEST, closeFund);
    yield takeEvery(ACTIVE_OR_DEACTIVE_FUND_REQUEST, activeOrDeactiveFund);
    yield takeEvery(GET_FUND_HEALTH_CHECK_REQUEST, getFundHealthCheck);
    yield takeEvery(GET_FUND_CHECK_ACCESS_REQUEST, getFundCheckAccess);
    yield takeEvery(UPDATE_FUND_TASK_SEQUENCE_ORDER_REQUEST, updateFundTaskSequenceOrder);
    yield takeEvery(GET_FUND_TASK_COUNT_BY_FUND_ID_REQUEST, getFundTaskCountByFundId);
}
function* FundsSaga() {
    yield all([fork(watchFunds)]);
}
export default FundsSaga;
