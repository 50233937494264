import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CANCEL, DANGER, SEND } from 'constants/commonConstants';
import { toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    getTicketMessageListRequest,
    addNewTicketMessageRequest
} from 'store/actions';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { getInitials } from 'helpers/generalUtils';
import { TICKET_HISTORY_TYPE_ACTIVITY, TICKET_HISTORY_TYPE_ATTACHMENT, TICKET_HISTORY_TYPE_MESSAGE } from 'constants/databaseConstants';
import { getDateTimeString, getDayFromDateTimeString, getFullDateTimeFromDateTimeString, getMonthFromDateTimeString, getTimeFromDateTimeString, getYearFromDateTimeString } from 'helpers/dateTimeHelper';
import ChartThree from '../../../images/03_no_chat.png';
import RichTextEditor from 'react-rte';
import parse from 'html-react-parser';
import { customTicketChatFileValidator } from 'helpers/customValidators';
import { makeTicketSelectField } from 'store/Ticket/selector';
import { getAttchmentClassExtWise } from 'helpers/projectUtils';
import DOMPurify from 'dompurify';
import CustomisedModal from 'components/Application/CustomisedModal';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'LINK_BUTTONS',
        'HISTORY_BUTTONS',
    ],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
    ],
};
class TicketHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketId: this.props.ticketId,
            ticketMessageList: [],
            message: RichTextEditor.createEmptyValue(),
            showChatFileDialog: false,
            selectedFile: null,
            downloadFileName: '',
            downloadFileUrl: '',
            isOpenToolbar: false
        };
        this.inputFile = React.createRef(null);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.toggleChatFileDialog = this.toggleChatFileDialog.bind(this);
        this.openToolbar = this.openToolbar.bind(this);
    }
    componentDidMount() {
        this.props.getTicketMessageListRequest(this.state.ticketId);
    }
    onChangeMessageInput = (value) => {
        this.setState({ message: value });
    };
    openToolbar = () => {
        this.setState({
            isOpenToolbar: !this.state.isOpenToolbar,
        });
    };
    toggleChatFileDialog = () => {
        this.setState({
            showChatFileDialog: !this.state.showChatFileDialog,
        }, () => {
            //api call
            if (!this.state.showChatFileDialog) {
                this.setState({ selectedFile: null });
            }
        }
        );
    };
    isValueBlank = (editorValue) => {
        const cleanValue = DOMPurify.sanitize(editorValue.toString(), { ALLOWED_TAGS: [] });
        const strippedValue = cleanValue.replace(/&nbsp;|\s/g, ''); // Remove non-breaking spaces and other whitespace characters
        return strippedValue.length === 0;
    };
    handleFileChange = async (selectorFiles) => {
        let file = selectorFiles[0];
        if (file) {
            if (!customTicketChatFileValidator(file.name)) {
                toastrMessage(__t('validations.custom.ticket_chat_file'), DANGER);
                this.setState({ selectedFile: null });
                return false;
            }
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            });
            this.setState({ selectedFile: file });
            this.toggleChatFileDialog();
        }
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    showOpenFileDlg = () => {
        this.inputFile.current.click();
    };
    addNewMessage = async (attach = false, file = null) => {
        if (this.state.message || file) {
            let formData = new FormData();
            if (attach && file) {
                formData.append('attachmentFile', file);
                formData.append('messageType', parseInt(TICKET_HISTORY_TYPE_ATTACHMENT));
            } else {
                let messageInputText = this.state.message.toString('html');
                formData.append('message', messageInputText);
                formData.append('messageType', parseInt(TICKET_HISTORY_TYPE_MESSAGE));
            }
            formData.append('ticketId', this.state.ticketId);
            this.props.addNewTicketMessageRequest(formData);
        }
    };
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.addNewTicketMessageApiResponse &&
            nextprops.addNewTicketMessageApiResponse !==
            this.props.addNewTicketMessageApiResponse
        ) {
            this.setState({
                selectedFile: null,
                message: RichTextEditor.createEmptyValue(),
                showChatFileDialog: false
            }, () => {
                this.props.getTicketMessageListRequest(this.state.ticketId);
            });
        }
        if (
            nextprops.ticketMessageList &&
            nextprops.ticketMessageList !==
            this.props.ticketMessageList
        ) {
            this.setState({
                ticketMessageList: nextprops.ticketMessageList,
            }, () => {
                window.setInterval(function () {
                    var elem = document.getElementById('chat-area');
                    if (elem) {
                        elem.scrollTop = elem.scrollHeight;
                    }
                }, 1000);
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="task-chat ticket-chat">
                    <div className="chat-main animate__animated animate__fadeInRight w-100">
                        <CardBody className="p-0 h-100 d-flex flex-column">
                            <div className="chat-header">
                                <div className="chat-profile">
                                    <h6>
                                        <div className="chat-profile-img mr-2">
                                            <i className="fas fa-user-friends"></i>
                                        </div>
                                        <span className='code_td'>{' '}{this.props.ticketData?.code}</span>
                                    </h6>
                                </div>
                                <div className="chat-header-action">
                                    <div className='ticket-on'>
                                        Raised On:
                                        <span>{(this.props.ticketData?.createdAt) ? getFullDateTimeFromDateTimeString(this.props.ticketData?.createdAt) : '-'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-area" id="chat-area">
                                <ul>
                                    {this.state.ticketMessageList.length > 0 && this.state.ticketMessageList.map((message, key) => {
                                        let currentMessageYear = getYearFromDateTimeString(message.createdAt);
                                        let currentMessageMonth = getMonthFromDateTimeString(message.createdAt);
                                        let currentMessageDay = getDayFromDateTimeString(message.createdAt);
                                        let previousMessageYear, previousMessageMonth, previousMessageDay, flag = false;
                                        if (key > 0) {
                                            previousMessageYear = getYearFromDateTimeString(this.state.ticketMessageList[key - 1].createdAt);
                                            previousMessageMonth = getMonthFromDateTimeString(this.state.ticketMessageList[key - 1].createdAt);
                                            previousMessageDay = getDayFromDateTimeString(this.state.ticketMessageList[key - 1].createdAt);
                                        }
                                        if (currentMessageYear !== previousMessageYear || currentMessageMonth !== previousMessageMonth || currentMessageDay !== previousMessageDay) {
                                            flag = true;
                                        }

                                        return (
                                            <React.Fragment key={key}>
                                                {flag && (
                                                    <li key={key} className="notification sticky">
                                                        <span>
                                                            {getDateTimeString(message.createdAt, currentMessageDay, currentMessageMonth, currentMessageYear)}
                                                        </span>
                                                    </li>
                                                )}
                                                {(message.type == TICKET_HISTORY_TYPE_ACTIVITY) && (
                                                    <li key={key} className="notification">
                                                        <span>{message.comment}</span>
                                                    </li>
                                                )}
                                                {(message.type == TICKET_HISTORY_TYPE_MESSAGE) && (
                                                    <li key={key} className={message.createdBy != this.props.loginUserId ? 'receive animate__animated animate__fadeInLeft' : 'send animate__animated animate__fadeInRight'}>
                                                        {message.createdBy != this.props.loginUserId && (
                                                            <div className="msg_img_inner">
                                                                {getInitials(message.createdByUser.fullName)}
                                                            </div>
                                                        )}
                                                        <div className="chat_msg">
                                                            <div className="chat_user">
                                                                {message.createdBy != this.props.loginUserId && (
                                                                    <span className="user_name">
                                                                        {message.createdByUser.fullName}
                                                                    </span>
                                                                )}
                                                                <span className="msg_time">
                                                                    {getTimeFromDateTimeString(message.createdAt)}
                                                                </span>
                                                            </div>
                                                            <p>{message.comment ? parse(message.comment.toString()) : ''}
                                                            </p>
                                                        </div>
                                                    </li>
                                                )}
                                                {(message.type == TICKET_HISTORY_TYPE_ATTACHMENT) && (
                                                    <li key={key} className={message.createdBy != this.props.loginUserId ? 'receive animate__animated animate__fadeInLeft' : 'send animate__animated animate__fadeInRight'}>
                                                        {message.createdBy != this.props.loginUserId && (
                                                            <div className="msg_img_inner">
                                                                {getInitials(message.createdByUser.fullName)}
                                                            </div>
                                                        )}
                                                        <div className="chat_msg">
                                                            <div className="chat_user">
                                                                {message.createdBy != this.props.loginUserId && (
                                                                    <span className="user_name">
                                                                        {message.createdByUser.fullName}
                                                                    </span>
                                                                )}
                                                                <span className="msg_time">
                                                                    {getTimeFromDateTimeString(message.createdAt)}
                                                                </span>
                                                            </div>
                                                            <div className="attachments">
                                                                <div className="file">
                                                                    <div className="file_inner">
                                                                        <span><i className={getAttchmentClassExtWise(message.fileName)}></i></span>
                                                                        <p>
                                                                            <a
                                                                                href={() => false}
                                                                                title="Download Uploaded File"
                                                                                className="text-primary"
                                                                                onClick={() =>
                                                                                    this.saveFile({
                                                                                        type: 'ticket',
                                                                                        fileName: message.fileName,
                                                                                        fileUrl: message.systemFileName
                                                                                    })}
                                                                            >
                                                                                {message.fileName ? parse(message.fileName.toString()) : ''}
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                    )}
                                </ul>
                                {this.state.ticketMessageList.length === 0 && (
                                    <div className="no_chat">
                                        <img
                                            src={ChartThree}
                                            alt="No Comments available"
                                        />
                                        <h3>No comments are available in this ticket</h3>
                                    </div>
                                )}
                            </div>
                            <div class="chat-footer">
                                <div
                                    className="chat-input"
                                    id="chatContentArea"
                                    onKeyPress={(e) => console.log(e.key)}
                                >

                                    <RichTextEditor
                                        id="enterKey"
                                        placeholder="Type a message"
                                        toolbarConfig={toolbarConfig}
                                        value={this.state.message}
                                        onChange={this.onChangeMessageInput}
                                        toolbarClassName={
                                            this.state.isOpenToolbar
                                                ? 'custom-chat-toolbar show'
                                                : 'custom-chat-toolbar'
                                        }
                                        editorClassName="custom-chat-editor"
                                    />
                                    <Button
                                        color="link"
                                        className="attachment-btn text-fortamet p-0"
                                        onClick={() => {
                                            this.openToolbar();
                                        }}
                                    >
                                        <svg
                                            width="16px"
                                            height="16px"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                        >
                                            <path d="M3.36 7L1 13h1.34l.51-1.47h2.26L5.64 13H7L4.65 7H3.36zm-.15 3.53l.78-2.14.78 2.14H3.21zM11.82 4h-1.6L7 13h1.56l.75-2.29h3.36l.77 2.29H15l-3.18-9zM9.67 9.5l1.18-3.59c.059-.185.1-.376.12-.57.027.192.064.382.11.57l1.25 3.59H9.67z" />
                                        </svg>
                                    </Button>
                                    <Button
                                        color="link"
                                        className="attachment-btn p-0"
                                        onClick={this.showOpenFileDlg}
                                    >
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M21.4383 11.6622L12.2483 20.8522C11.1225 21.9781 9.59552 22.6106 8.00334 22.6106C6.41115 22.6106 4.88418 21.9781 3.75834 20.8522C2.63249 19.7264 2 18.1994 2 16.6072C2 15.015 2.63249 13.4881 3.75834 12.3622L12.9483 3.17222C13.6989 2.42166 14.7169 2 15.7783 2C16.8398 2 17.8578 2.42166 18.6083 3.17222C19.3589 3.92279 19.7806 4.94077 19.7806 6.00222C19.7806 7.06368 19.3589 8.08166 18.6083 8.83222L9.40834 18.0222C9.03306 18.3975 8.52406 18.6083 7.99334 18.6083C7.46261 18.6083 6.95362 18.3975 6.57834 18.0222C6.20306 17.6469 5.99222 17.138 5.99222 16.6072C5.99222 16.0765 6.20306 15.5675 6.57834 15.1922L15.0683 6.71222"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <input
                                            type="file"
                                            id="file"
                                            ref={this.inputFile}
                                            style={{ display: 'none' }}
                                            onChange={(e) =>
                                                this.handleFileChange(
                                                    e.target.files
                                                )
                                            }
                                        />
                                    </Button>
                                    <Button
                                        type='button'
                                        onClick={() => {
                                            var messageText = this.state.message.toString('html');
                                            const valueIsEmpty = this.isValueBlank(messageText); if (!valueIsEmpty) {
                                                this.addNewMessage();
                                            } else {
                                                return false;
                                            }
                                        }}
                                        color="link"
                                        className="send-btn"
                                    >
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m6 12-3 9 18-9L3 3l3 9zm0 0h6"
                                            />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </div>
                </div>
                <CustomisedModal
                    isModalOpen={this.state.showChatFileDialog}
                    modalName={__t(
                        'chat.chat_attachment_header'
                    )}
                    onModalDismiss={() => {
                        this.toggleChatFileDialog();
                    }}
                    modalSize={'modal-md'}
                >
                    {this.state.selectedFile && (
                        <Card className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                                <Row className="align-items-center">
                                    <Col>
                                        <Link
                                            to="#"
                                            className="upload-document-title font-weight-bold"
                                        >
                                            {
                                                this.state
                                                    .selectedFile
                                                    .name
                                            }
                                        </Link>
                                        <p className="mb-0">
                                            <strong>
                                                {
                                                    this.state
                                                        .selectedFile
                                                        .formattedSize
                                                }
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    )}
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button
                                color="primary"
                                className="mr-2"
                                onClick={() =>
                                    this.addNewMessage(
                                        true,
                                        this.state.selectedFile
                                    )
                                }
                            >
                                {SEND}
                            </Button>
                            <Button
                                color="secondary"
                                onClick={
                                    this.toggleChatFileDialog
                                }
                            >
                                {CANCEL}
                            </Button>
                        </Col>
                    </Row>
                </CustomisedModal>

                <DownloadFileByModel
                    modelType={'ticket'}
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    ticketMessageList: makeTicketSelectField('ticketMessageList'),
    addNewTicketMessageApiResponse: makeTicketSelectField('addNewTicketMessageApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getTicketMessageListRequest,
    addNewTicketMessageRequest
});
export default compose(
    withConnect,
    withRouter
)(TicketHistory);
