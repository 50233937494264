import produce from 'immer';
import { ADD_AUDIT_OBSERVATION_RESPONSE, GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_RESPONSE, UPDATE_AUDIT_OBSERVATION_STATUS_RESPONSE } from './actionTypes';
export const initialState = {
    auditObservationApiResponse: null,
    auditObservationList: [],
    auditObservationStatusApiResponse: null
};

const auditObservation = (state = initialState, action = '') => produce(state, draft => {
    switch (action.type) {
        case ADD_AUDIT_OBSERVATION_RESPONSE:
            draft.auditObservationApiResponse = {
                message: action.payload.message,
                responseType: action.payload.responseType,
            }
            break;

        case GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_RESPONSE:
            draft.auditObservationList = action.payload.auditObservationList
            break;

        case UPDATE_AUDIT_OBSERVATION_STATUS_RESPONSE:
            draft.auditObservationStatusApiResponse = {
                message: action.payload.message,
                responseType: action.payload.responseType
            }
            break;
    }
});
export default auditObservation;