import produce from 'immer';
import { GET_CHAT_LIST_BY_TASK_ID_RESPONSE, GET_MESSAGE_LIST_BY_CHAT_ID_RESPONSE, ADD_NEW_MESSAGE_RESPONSE, GET_TASK_USERS_RESPONSE, ADD_OR_REMOVE_USER_RESPONSE, CREATE_CHAT_RESPONSE, RENAME_GROUP_RESPONSE } from './actionTypes';
export const initialState = {
    chatList: [],
    messageListApiResponse: null,
    messageApiResponse: null,
    addNewMessageApiResponse: null,
    createChatApiResponse: null,
    taskUserList: [],
    addOrRemoveUserApiResponse: null,
    renameGroupApiResponse: null
};
const chat = (state = initialState, action = '') => produce(state, draft => {
    switch (action.type) {
        case GET_CHAT_LIST_BY_TASK_ID_RESPONSE:
            draft.chatList = action.payload.chatList
            break;

        case GET_MESSAGE_LIST_BY_CHAT_ID_RESPONSE:
            draft.messageListApiResponse = {
                message: action.payload.message,
                data: action.payload.data,
                responseType: action.payload.responseType,
            }
            break;

        case ADD_NEW_MESSAGE_RESPONSE:
            draft.addNewMessageApiResponse = {
                message: action.payload.message,
                data: action.payload.data,
                responseType: action.payload.responseType
            }
            break;

        case GET_TASK_USERS_RESPONSE:
            draft.taskUserList = action.payload.taskUserList
            break;

        case ADD_OR_REMOVE_USER_RESPONSE:
            draft.addOrRemoveUserApiResponse = {
                message: action.payload.message,
                data: action.payload.data,
                responseType: action.payload.responseType,
            }
            break;

        case CREATE_CHAT_RESPONSE:
            draft.createChatApiResponse = {
                message: action.payload.message,
                data: action.payload.data,
                responseType: action.payload.responseType
            }
            break;

        case RENAME_GROUP_RESPONSE:
            draft.renameGroupApiResponse = {
                message: action.payload.message,
                data: action.payload.data,
                responseType: action.payload.responseType
            }
            break;

        default:
            state = { ...state };
            break;
    }
});
export default chat;
