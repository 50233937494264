import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ALLOCATE_CHECK_COMPLIANCES_ENTITY_REQUEST,
    ALLOCATE_COMPLIANCES_TO_ENTITY_REQUEST,
    ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_REQUEST,
    CHANGE_STATUS_TRACK_ALLOCATION_REQUEST,
    DELETE_ALLOCATION_BY_ALLOCATION_ID_REQUEST,
    DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_REQUEST,
    GET_ALLOCATION_SEARCH_LIST_REQUEST,
    GET_ASSIGNED_EVENTS_LIST_REQUEST,
    GET_ASSIGNED_EVENT_COMPLIANCES_LIST_REQUEST,
    GET_SCHEDULE_LIST_BY_ALLOCATION_ID_REQUEST,
    GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_REQUEST,
    GET_TRACK_ALLOCATION_BY_COMPLIANCE_REQUEST,
    GET_TRACK_ALLOCATION_SEARCH_LIST_REQUEST,
    GET_USER_ASSIGNED_COMPLIANCES_REQUEST,
    UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_REQUEST,
    UPDATE_SCHEDULE_BY_ALLOCATION_ID_REQUEST,
    UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_REQUEST,
    UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    allocateCheckCompliancesEntityResponse,
    allocateCompliancesToEntityResponse,
    assignUserAndDepartmentResponse,
    changeStatusTrackAllocationResponse,
    deleteAllocationByAllocationIdResponse,
    deleteTrackAllocationsByComplianceResponse,
    getAllocationSearchListResponse,
    getAssignedEventCompliancesListResponse,
    getAssignedEventsListResponse,
    getScheduleListByAllocationIdResponse,
    getTrackAllocationActivitiesByComplianceResponse,
    getTrackAllocationByComplianceResponse,
    getTrackAllocationSearchListResponse,
    getUserAssignedComplianceListResponse,
    updateAllocationStatusByAllocationIdsResponse,
    updateScheduleByAllocationIdResponse,
    updateTaskScheduleRenewalDateResponse,
    updateViewScheduleStatusByAllocationTaskIdResponse,
} from './actions';
import {
    ALLOCATE_CHECK_COMPLIANCES_ENTITY,
    ALLOCATIONS_SEARCH,
    UPDATE_SCHEDULE,
    ASSIGNED_COMPLIANCES,
    ASSIGN_USER_AND_DEPARTMENT_ALLOCATION,
    COMPLIANCES_FOR_ALLOCATE_TO_ENTITY,
    DELETE_ALLOCATION,
    GET_SCHEDULE_LIST,
    UPDATE_ALLOCATION_STATUS,
    GET_ASSIGNED_EVENTS,
    GET_ASSIGNED_EVENT_COMPLIANCES,
    CHANGE_STATUS_TRACK_ALLOCATION,
    TRACK_ALLOCATION,
    TRACK_ALLOCATION_ACTIVITIES,
    TRACK_ALLOCATIONS_SEARCH,
    ALLOCATION_RENEWAL_DATE,
    DETLETE_TRACK_ALLOCATION,
} from 'helpers/apiConstants';
function* allocateCompliancesToEntity({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            COMPLIANCES_FOR_ALLOCATE_TO_ENTITY,
            data
        );
        if (response.status) {
            yield put(
                allocateCompliancesToEntityResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                allocateCompliancesToEntityResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* allocateCheckCompliancesEntity({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ALLOCATE_CHECK_COMPLIANCES_ENTITY,
            data
        );
        if (response.status) {
            yield put(
                allocateCheckCompliancesEntityResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                allocateCheckCompliancesEntityResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAllocationSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ALLOCATIONS_SEARCH,
            data
        );
        if (pagination) {
            yield put(
                getAllocationSearchListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteAllocationByAllocationId({ payload: { allocationId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            DELETE_ALLOCATION + '/' + allocationId
        );
        if (response.status) {
            yield put(
                deleteAllocationByAllocationIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteAllocationByAllocationIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* assignUserAndDepartment({ payload: { allocationId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            ASSIGN_USER_AND_DEPARTMENT_ALLOCATION + '/' + allocationId,
            data
        );
        if (response.status) {
            yield put(
                assignUserAndDepartmentResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                assignUserAndDepartmentResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateAllocationStatusByAllocationIds({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            UPDATE_ALLOCATION_STATUS,
            data
        );
        if (response.status) {
            yield put(
                updateAllocationStatusByAllocationIdsResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateAllocationStatusByAllocationIdsResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getScheduleListByAllocationId({ payload: { allocationId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            GET_SCHEDULE_LIST + '/' + allocationId
        );
        if (response.status) {
            yield put(getScheduleListByAllocationIdResponse(response.data));
        } else {
            yield put(getScheduleListByAllocationIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateViewScheduleStatusByAllocationTaskId({
    payload: { id, status },
}) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            UPDATE_SCHEDULE + '/' + id + '/status/' + status
        );
        if (response.status) {
            yield put(
                updateViewScheduleStatusByAllocationTaskIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateViewScheduleStatusByAllocationTaskIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateScheduleByAllocationId({ payload: { allocationId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            UPDATE_SCHEDULE + '/' + allocationId,
            data
        );
        if (response.status) {
            yield put(
                updateScheduleByAllocationIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                updateScheduleByAllocationIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUserAssignedComplianceList({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ASSIGNED_COMPLIANCES,
            data
        );
        if (response.status) {
            yield put(
                getUserAssignedComplianceListResponse(
                    response.data?.list ? response.data.list : []
                )
            );
        } else {
            yield put(getUserAssignedComplianceListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAssignedEventsList({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_ASSIGNED_EVENTS,
            data
        );
        yield put(
            getAssignedEventsListResponse(
                response.message,
                SUCCESS,
                response.data
            )
        );
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAssignedEventCompliancesList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_ASSIGNED_EVENT_COMPLIANCES,
            data
        );
        if (pagination) {
            yield put(
                getAssignedEventCompliancesListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* changeStatusTrackAllocation({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            CHANGE_STATUS_TRACK_ALLOCATION,
            data
        );
        if (response.status) {
            yield put(
                changeStatusTrackAllocationResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                changeStatusTrackAllocationResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getTrackAllocationByCompliance({ payload: { complianceId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            TRACK_ALLOCATION + '/' + complianceId, data
        );
        if (response.status) {
            yield put(
                getTrackAllocationByComplianceResponse(response.data.list)
            );
        } else {
            yield put(getTrackAllocationByComplianceResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getTrackAllocationActivitiesByCompliance({
    payload: { complianceId, type },
}) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            TRACK_ALLOCATION_ACTIVITIES + '/' + complianceId + '/' + type
        );
        if (response.status) {
            yield put(
                getTrackAllocationActivitiesByComplianceResponse(
                    response.data.list
                )
            );
        } else {
            yield put(getTrackAllocationActivitiesByComplianceResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getTrackAllocationSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            TRACK_ALLOCATIONS_SEARCH,
            data
        );
        if (pagination) {
            yield put(
                getTrackAllocationSearchListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateTaskScheduleRenewalDate({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            ALLOCATION_RENEWAL_DATE,
            data
        );

        if (response.status) {
            yield put(
                updateTaskScheduleRenewalDateResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                updateTaskScheduleRenewalDateResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteTrackAllocationsByCompliance({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            DETLETE_TRACK_ALLOCATION + '/' + data.complianceId,
            data
        );

        if (response.status) {
            yield put(
                deleteTrackAllocationsByComplianceResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                deleteTrackAllocationsByComplianceResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchAllocation() {
    yield takeEvery(
        ALLOCATE_COMPLIANCES_TO_ENTITY_REQUEST,
        allocateCompliancesToEntity
    );
    yield takeEvery(
        ALLOCATE_CHECK_COMPLIANCES_ENTITY_REQUEST,
        allocateCheckCompliancesEntity
    );
    yield takeEvery(
        DELETE_ALLOCATION_BY_ALLOCATION_ID_REQUEST,
        deleteAllocationByAllocationId
    );
    yield takeEvery(
        GET_ALLOCATION_SEARCH_LIST_REQUEST,
        getAllocationSearchList
    );
    yield takeEvery(
        ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_REQUEST,
        assignUserAndDepartment
    );
    yield takeEvery(
        UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_REQUEST,
        updateAllocationStatusByAllocationIds
    );
    yield takeEvery(
        GET_SCHEDULE_LIST_BY_ALLOCATION_ID_REQUEST,
        getScheduleListByAllocationId
    );
    yield takeEvery(
        UPDATE_SCHEDULE_BY_ALLOCATION_ID_REQUEST,
        updateScheduleByAllocationId
    );
    yield takeEvery(
        GET_USER_ASSIGNED_COMPLIANCES_REQUEST,
        getUserAssignedComplianceList
    );
    yield takeEvery(
        UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_REQUEST,
        updateViewScheduleStatusByAllocationTaskId
    );
    yield takeEvery(GET_ASSIGNED_EVENTS_LIST_REQUEST, getAssignedEventsList);
    yield takeEvery(
        GET_ASSIGNED_EVENT_COMPLIANCES_LIST_REQUEST,
        getAssignedEventCompliancesList
    );
    yield takeEvery(
        CHANGE_STATUS_TRACK_ALLOCATION_REQUEST,
        changeStatusTrackAllocation
    );
    yield takeEvery(
        GET_TRACK_ALLOCATION_BY_COMPLIANCE_REQUEST,
        getTrackAllocationByCompliance
    );
    yield takeEvery(
        GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_REQUEST,
        getTrackAllocationActivitiesByCompliance
    );
    yield takeEvery(
        GET_TRACK_ALLOCATION_SEARCH_LIST_REQUEST,
        getTrackAllocationSearchList
    );
    yield takeEvery(
        UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_REQUEST,
        updateTaskScheduleRenewalDate
    );
    yield takeEvery(
        DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_REQUEST,
        deleteTrackAllocationsByCompliance
    );
}
function* allocationSaga() {
    yield all([fork(watchAllocation)]);
}
export default allocationSaga;
