import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SUBMIT, UPDATE } from 'constants/commonConstants';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllCountryListRequest,
    updateCountryStatusByCountryIdRequest,
    addCountryRequest,
    updateCountryRequest,
    deleteCountryByCountryIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import { makeCountrySelectField } from 'store/Masters/Country/selector';
import { initialCountryDataState, listColumns } from './CommonFunctions';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            countryData: initialCountryDataState(),
            operationType: SUBMIT,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve country list
        this.props.getAllCountryListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateCountryStatusByCountryIdRequest(id, checked);
    }
    viewCountry(countryData) {
        this.setState(
            {
                countryData: initialCountryDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ countryData: countryData });
            }
        );
    }
    deleteCountry = async (countryId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.country_delete')
        );
        if (response) {
            this.props.deleteCountryByCountryIdRequest(countryId);
        }
    };
    formSubmit = (values) => {
        this.setState({ countryData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateCountryRequest(values.id, values);
        } else {
            this.props.addCountryRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            countryData: initialCountryDataState(),
            operationType: SUBMIT,
        });
    };
    generateTable = (records) => {
        let countryRecords = [];
        countryRecords = records.map((countryData, i) => {
            return {
                countryName: countryData.countryName,
                numericCode: countryData.numericCode,
                isoCode: countryData.isoCode,
                dialingCode: countryData.dialingCode,
                currency: countryData.currency,
            };
        });
        return countryRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.countryList &&
            this.props.countryList !== nextprops.countryList
        ) {
            this.setState({
                countryList: this.generateTable(nextprops.countryList),
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.countryList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'country'} />
                        </Col>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t('page_headers.country_list')}
                                        </h4>
                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <CustomisedDatatable tableRecords={data} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    countryList: makeCountrySelectField('countryList'),
    countryApiResponse: makeCountrySelectField('countryApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getAllCountryListRequest,
    updateCountryStatusByCountryIdRequest,
    addCountryRequest,
    updateCountryRequest,
    deleteCountryByCountryIdRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withRouter
)(Index);
