import { getDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import { getInitials } from 'helpers/generalUtils';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
class NotificationItem extends Component {
    render() {
        return (
            <li
                className={
                    !this.props.notification.isRead
                        ? 'unread-notification-box'
                        : 'read-notification-box'
                }
            >
                {!this.props.notification.isRead && (
                    <Button
                        color="link"
                        className="mark_asread"
                        title="Mark as read"
                        onClick={() =>
                            this.props.readNotificationById(
                                this.props.notification.id
                            )
                        }
                    >
                        <svg
                            width="20"
                            height="14"
                            viewBox="0 0 20 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="10"
                                cy="7"
                                r="3"
                                stroke="CurrentColor"
                                strokeWidth="2"
                            />
                            <path
                                d="M18.188 5.93429C18.5762 6.40556 18.7703 6.64119 18.7703 7C18.7703 7.35881 18.5762 7.59444 18.188 8.06571C16.7679 9.78986 13.6357 13 10 13C6.36427 13 3.23206 9.78986 1.81197 8.06571C1.42381 7.59444 1.22973 7.35881 1.22973 7C1.22973 6.64119 1.42381 6.40556 1.81197 5.93429C3.23206 4.21014 6.36427 1 10 1C13.6357 1 16.7679 4.21014 18.188 5.93429Z"
                                stroke="CurrentColor"
                                strokeWidth="2"
                            />
                        </svg>
                    </Button>
                )}
                <div className="notification_item">
                    <div className="chat-title">
                        <span className="assigne-box green-round">
                            {getInitials(
                                this.props.notification.sender.fullName
                            )}
                        </span>
                    </div>
                    <div className="notification_detail">
                        <div>
                            <h5>{this.props.notification.sender.fullName}</h5>
                            <div className="small-title">
                                <small>
                                    {getDateTimeFromDateTimeString(
                                        this.props.notification.createdAt
                                    )}
                                </small>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0">
                                {this.props.notification.message}
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}
export default NotificationItem;
