import { ACTIVE, AUTH_MODE_LC } from 'constants/commonConstants';
import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Company Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Full Name',
        field: 'fullName',
    },
    {
        label: 'User Name',
        field: 'userName',
    },
    {
        label: 'Email ID',
        field: 'email',
    },
    {
        label: 'Block Reason',
        field: 'bounceReason',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialUserSearchDataState = () => {
    return {
        companyGroupId: '',
        entityId: '',
        isResolved: false
    };
};