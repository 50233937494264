import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import __t from 'i18n/translator';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { NA } from 'constants/commonConstants';
import {  getFirstCapitalize } from 'helpers/generalUtils';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { clearComplianceCategoryResponse, clearComplianceTypeResponse, getComplianceCategoriesRequest, getComplianceTypesRequest } from 'store/actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
const listColumns = [
    {
        label: 'Code',
        field: 'complianceCode',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State',
        field: 'stateName',
    },
    {
        label: 'Section No',
        field: 'sectionReference',
    },
    {
        label: 'Rule No',
        field: 'ruleReference',
    },
    {
        label: 'Compliance Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Applicablity Details',
        field: 'complianceApplicability',
    },
    {
        label: 'Class',
        field: 'complianceClass',
    },
    {
        label: 'Classification',
        field: 'complianceClassification',
    },
    {
        label: 'Due Date',
        field: 'dueDateDescription',
    },
    {
        label: 'Short Name Of Act/Legislation',
        field: 'lawShortName',
    },
    {
        label: 'Frequency',
        Translabel: 'tables.statutory.frequency',
        field: 'periodicityName',
    },
    {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
        attributes: {
            className: 'action_col',
        },
    },
];

const listEventColumns = [
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Event Name',
        field: 'eventName',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'Type',
        field: 'type',
    },
    {
        label: 'Event Date',
        field: 'eventOccurrenceDate',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Action',
        field: 'actions',
        attributes: {
            className: 'action_col',
        },
        sort: 'disabled'
    }
];
const listEventMasterColumns = [
    {
        label: 'Event Name',
        field: 'eventName',
    },
    {
        label: 'Event Applicability',
        field: 'eventApplicability',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class QuickAccessTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isQuickAccessType: this.props.isQuickAccessType,
            listData: [],
        };
    }
    componentDidMount = () => {
        this.props.getComplianceCategoriesRequest();
        this.props.getComplianceTypesRequest();
    }
    componentWillUnmount() {
        this.props.clearComplianceTypeResponse();
        this.props.clearComplianceCategoryResponse();
    }
    generateTable = (records, categories, types) => {
        let actsRecords = [];
        actsRecords = records.map((lawDataRecord, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title="View Details"
                        className="btn btn-link tbl-action text-success"
                        onClick={() => {
                            window.open(
                                '/statutory-compliances/view-details/' +
                                lawDataRecord.id,
                                '_blank'
                            );
                        }}
                    >
                        <i className="far fa-eye"></i>
                    </a>
                </div>
            );
            let complianceTypeId = lawDataRecord?.complianceTypeId;
            let complianceTypeName = types.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = lawDataRecord?.complianceCategoryId;
            let complianceCategoryName =categories.find((c) => c.id === complianceCategoryId)?.complianceCategoryName || '';

            return {
                countryName: lawDataRecord?.law?.country?.countryName,
                stateName: lawDataRecord?.law?.state
                    ? lawDataRecord?.law?.state?.stateName
                    : NA,
                complianceTitle: lawDataRecord?.complianceTitle,
                complianceCode: lawDataRecord?.complianceCode,
                sectionReference: lawDataRecord?.sectionReference,
                ruleReference: lawDataRecord?.ruleReference,
                complianceApplicability:
                    lawDataRecord.complianceApplicability !== null
                        ? lawDataRecord.complianceApplicability
                        : '',
                complianceClassification: complianceCategoryName,
                complianceClass: complianceTypeName,
                lawShortName: lawDataRecord?.law?.lawShortName,
                periodicityName: lawDataRecord?.periodicity?.periodicityName,
                dueDateDescription: lawDataRecord?.dueDateDescription,
                actions: actions,
            };
        });
        return actsRecords;
    };

    generateEventTable = (records) => {

        let allocationRecords = [];
        allocationRecords = records.map((eventData, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            if (eventData.status == "Ongoing") {
                                window.open('/ongoing-events');
                            } else {
                                window.open('/closed-events');
                            }
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                </div>
            );
            return {
                entityName: eventData.entity?.entityName,
                countryName: eventData.event?.country?.countryName,
                eventName: eventData.event?.eventName,
                type: (eventData?.eventType) ? getFirstCapitalize(eventData?.eventType) : '',
                eventOccurrenceDate: eventData.eventOccurrenceDate,
                status: eventData.status,
                actions: actions,
            };
        });
        return allocationRecords;
    };

    generateEventMasterTable = (records) => {
        let eventRecords = [];
        eventRecords = records.map((eventData, i) => {
            let actions = (
                <div className="text-right d-flex event_action_btn">
                    <a
                        href={() => false}
                        title={__t('common.compliance_hierarchy')}
                        className="btn btn-link text-info tbl-action"
                        onClick={() => {
                            window.open(
                                '/event-compliances/' +
                                eventData.id,
                                '_blank'
                            );
                        }}
                    >
                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16" y="7" width="4" height="4" rx="2" transform="rotate(90 16 7)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="16" y="15" width="4" height="4" rx="2" transform="rotate(90 16 15)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="1" y="5" width="4" height="4" rx="2" transform="rotate(-90 1 5)" fill="#7E869E" fillOpacity="1" stroke="currentColor" strokeWidth="1.5"></rect><path d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12" stroke="currentColor" strokeWidth="1.5"></path><path d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12" stroke="currentColor" strokeWidth="1.5"></path></svg>
                    </a>
                </div>
            );
            return {
                eventName: eventData.eventName,
                countryName: eventData?.country?.countryName,
                eventApplicability: (eventData?.eventApplicability) ? eventData?.eventApplicability : '',
                actions: actions,
            };
        });
        return eventRecords;
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
      


        if (nextprops.allCompliancesTypesList && nextprops.allCompliancesCategoriesList && nextprops.isQuickAccessType) {
            let listInfoData = [];
            if (nextprops.isQuickAccessType == 'events') {
                listInfoData = this.generateEventTable(nextprops.data);
            }
            if (nextprops.isQuickAccessType == 'event-master') {
                listInfoData = this.generateEventMasterTable(nextprops.data);
            }
            if (nextprops.isQuickAccessType == 'compliances') {
                listInfoData = this.generateTable(nextprops.data, nextprops.allCompliancesCategoriesList, nextprops.allCompliancesTypesList);
            }
            this.setState({
                listData: listInfoData
            });
        }
    }
    render() {
        let columns = [];
        if (this.props.isQuickAccessType == 'events') {
            columns = listEventColumns
        }
        if (this.props.isQuickAccessType == 'event-master') {
            columns = listEventMasterColumns
        }
        if (this.props.isQuickAccessType == 'compliances') {
            columns = listColumns
        }
        const data = {
            columns: columns,
            rows: this.state.listData
        }
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12" className=''>
                        <Link to="#" className="dashboard_back_btn btn btn-primary mb-3" onClick={() => {
                            this.props.closeQuickAccessPanel(false)
                        }}><span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                    <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Back
                        </Link>
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable tableRecords={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    allCompliancesCategoriesList: makeLawComplianceSelectField('allCompliancesCategoriesList'),
    allCompliancesTypesList: makeLawComplianceSelectField('allCompliancesTypesList')
});
const withConnect = connect(mapStatetoProps, {
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
});
export default compose(
    withConnect,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(QuickAccessTable);
