import React, { Component } from 'react';
import {
    Row, Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Button,
    Label
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DANGER, CANCEL, SUCCESS, UPDATE } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getUserProfileRequest,
    updateUserProfileRequest
} from 'store/actions';
import { compose } from 'redux';
import { makeUserSelectField } from 'store/User/selector';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import { setValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { setEmptyToNull } from 'helpers/generalUtils';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { customValidPhoneField } from 'helpers/customValidators';
import __t from 'i18n/translator';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const initialProfileDataState = () => {
    return {
        email: "",
        contactNo: "",
    }
}
const intFields = [];
const nullableFields = [];
const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidPhoneField()
            )
        });
        this.state = {
            userData: initialProfileDataState(),
            operationType: UPDATE,
            errors: [],
        };
    }
    componentDidMount() {
        this.props.getUserProfileRequest();
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            values = formatValuesForApi(values);
            this.props.updateUserProfileRequest(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.props.history.push('/');
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.userApiResponse && this.props.userApiResponse !== nextprops.userApiResponse) {
            if (nextprops.userApiResponse.responseType === SUCCESS) {
                this.setState({
                    userData: nextprops.userApiResponse.data.user
                });
            } else {
                toastrMessage(nextprops.userApiResponse.message, DANGER);
            }
        }
        if (nextprops.userProfileApiResponse && this.props.userProfileApiResponse !== nextprops.userProfileApiResponse) {
            if (nextprops.userProfileApiResponse.responseType === SUCCESS) {
                setValueByKey(authConstants.USER_EMAIL, nextprops.userProfileApiResponse.data.user.email);
                setValueByKey(authConstants.USER_FULL_NAME, nextprops.userProfileApiResponse.data.user.fullName);
                setValueByKey(authConstants.USER_CONTACT_NO, nextprops.userProfileApiResponse.data.user.contactNo);
                toastrMessage(nextprops.userProfileApiResponse.message, SUCCESS);
                // this.props.history.push('/');
            } else {
                toastrMessage(nextprops.userProfileApiResponse.message, DANGER);
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className='page-header'>
                                    <h4 className='with-back-btn'>{__t('page_headers.edit_profile')}</h4>
                                </div>
                                <div className='page-action'>
                                    {this.state.userData.userName}
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={this.state.userData}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <Row>
                                                <FormGroup className='col-lg-4'>
                                                    <Field
                                                        name="fullName"
                                                        className="form-control"
                                                        value={values.fullName}
                                                        placeholder={__t('form_labels.masters.users.name')}
                                                        maxLength={100}
                                                    />
                                                    <Label className="form-label">
                                                        {__t('form_labels.masters.users.name')}
                                                        <span className='text-danger required'>*</span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t('form_labels.masters.users.name'),
                                                        values.fullName,
                                                        'required|alpha_space|max:100',
                                                        {
                                                            messages: {
                                                                required: __t('validations.masters.users.name_required'),
                                                                max: __t('validations.masters.users.name_max', { max: 100 }),
                                                                alpha_space: __t('validations.masters.users.name_alpha_space')
                                                            }
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className='col-lg-4'>
                                                    <Field
                                                        name="email"
                                                        className="form-control"
                                                        value={values.email}
                                                        placeholder={__t('form_labels.masters.users.email')}
                                                        maxLength={150}
                                                    />
                                                    <Label className="form-label">
                                                        {__t('form_labels.masters.users.email')} <span className='text-danger required'>*</span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t('form_labels.masters.users.email'),
                                                        values.email,
                                                        'required|email|max:150',
                                                        {
                                                            messages: {
                                                                required: __t('validations.masters.users.email_required'),
                                                                email: __t('validations.masters.users.email_email'),
                                                                max: __t('validations.masters.users.email_max', { max: 150 })
                                                            }
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className='col-lg-4'>
                                                    <Field
                                                        name="contactNo"
                                                        className="form-control"
                                                        value={values.contactNo}
                                                        placeholder={__t('form_labels.masters.users.contact_no')}
                                                        maxLength={14}
                                                    />
                                                    <Label className="form-label">
                                                        {__t('form_labels.masters.users.contact_no')} <span className='text-danger required'>*</span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t('form_labels.masters.users.contact_no'),
                                                        values.contactNo,
                                                        'required|customPhone|max:14',
                                                        {
                                                            messages: {
                                                                required: __t('validations.masters.users.contact_no_required'),
                                                                max: __t('validations.masters.users.contact_no_max')
                                                            }
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                                        <div className="d-inline-block">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                            >
                                                                {
                                                                    this
                                                                        .state
                                                                        .operationType
                                                                }
                                                            </Button>
                                                            <Button type="reset"
                                                                color="secondary"
                                                                onClick={this.resetForm}>
                                                                {CANCEL}
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    userApiResponse: makeUserSelectField('userApiResponse'),
    userProfileApiResponse: makeUserSelectField('userProfileApiResponse'),
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList()
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getUserProfileRequest,
    updateUserProfileRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withUserReducer,
    withUserSaga,
    withRouter
)(EditProfile);