import React, { Component } from 'react';
import { Row, Col, Card, FormGroup, Button, Label } from 'reactstrap';
import { ACTIVE, CANCEL, DANGER, DATE_FORMAT_DATEPICKER, INACTIVE, SUBMIT, SUCCESS, UPDATE } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import CustomisedModal from 'components/Application/CustomisedModal';
import { compareString } from 'helpers/generalUtils';
import { getLegalUpdateReferenceIdRequest, updateLawDocumentLegalRequest, addLawDocumentRequest } from 'store/actions';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { makeLawDocumentSelectField } from 'store/LawDocument/selector';
import { displayModeValues } from 'constants/dropdowns';
import { formatDateForApi, getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { customDocumentFileValidator } from 'helpers/customValidators';
import Dropzone from 'react-dropzone';
import { CONFIRMED, DEACTIVATION, DEACTIVATION_DOCUMENT } from 'constants/databaseConstants';

class DocumentForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            lawId: this.props.lawId,
            operationType: SUBMIT,
            legalUpdateReferenceList: [],
            legalUpdateReferenceDropdownList: [],
            selectedFile: [],
            errors: '',
            isDocumentActiveFreeze: true
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }
    componentDidMount() {
        this.props.getLegalUpdateReferenceIdRequest(this.state.lawId, 'document_edit');
    }
    setFreezeValues = (id) => {
        let legalUpdateData = this.state.legalUpdateReferenceList.find((x) => x.id === id);
        let updateType = legalUpdateData.updateTypeId ? legalUpdateData.updateTypeId.split(',').map(Number) : [];
        let subUpdateType = legalUpdateData.subUpdateTypeId ? legalUpdateData.subUpdateTypeId.split(',').map(Number) : [];
        if (updateType.includes(DEACTIVATION) && legalUpdateData.legalUpdateStatusId === CONFIRMED) {
            if (updateType.includes(DEACTIVATION)) {
                this.setState({
                    isDocumentActiveFreeze: subUpdateType.includes(DEACTIVATION_DOCUMENT) ? false : true
                });
            }
        } else {
            this.setState({
                isDocumentActiveFreeze: true
            });
        }
    };
    resetForm = (refreshList = false) => {
        this.setState({
            selectedFile: [],
            errors: '',
            operationType: SUBMIT,
            legalUpdateReferenceDropdownList: [],
        }, () => {
            this.props.onModalDismiss(refreshList);
        });
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            if (this.state.selectedFile.length === 0) {
                this.setState({
                    errors: __t(
                        'validations.document_repository.document_file_required'
                    ),
                });
            } else {
                if (this.state.errors.length === 0) {
                    let formValues = {
                        ...values,
                        releaseDate: values.releaseDate ? formatDateForApi(values.releaseDate) : '',
                        effectiveDate: values.effectiveDate ? formatDateForApi(values.effectiveDate) : ''
                    };
                    const formData = new FormData();
                    formData.append('lawId', this.state.lawId);
                    
                    for (const [formKey, formVal] of Object.entries(formValues)) {
                        formData.append(formKey, formVal);
                    }
                    formData.append('attachmentFile', this.state.selectedFile[0]);
                    if (this.state.operationType === UPDATE) {
                        formData.append('lawDocumentId', this.props.lawDocumentId);
                        this.props.updateLawDocumentLegalRequest(this.props.lawDocumentId, formData);
                    } else {
                        this.props.addLawDocumentRequest(formData);
                    }
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customDocumentFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.document_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({
                errors:
                    __t('form_labels.document_repository.document_file') +
                    ' ' +
                    __t('validations.custom.document_file'),
            });
        } else {
            this.setState({ errors: '' });
        }
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );
        this.setState({ selectedFile: files });
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.lawDocumentId !== this.props.lawDocumentId) {
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.setState({
                operationType: (nextprops.lawDocumentId > 0) ? UPDATE : SUBMIT
            }, () => {
                this.props.getLegalUpdateReferenceIdRequest(this.state.lawId, 'document_edit');
            })
        }
        if (
            nextprops.legalUpdateReferenceList &&
            this.props.legalUpdateReferenceList !==
            nextprops.legalUpdateReferenceList
        ) {
            // if (nextprops.legalUpdateReferenceList.length === 0) {
            //     toastrMessage(__t('alerts.permission_denied'), DANGER);
            // } else {
            this.setState({
                legalUpdateReferenceList:
                    nextprops.legalUpdateReferenceList,
                legalUpdateReferenceDropdownList:
                    nextprops.legalUpdateReferenceList
                        .map((opt) => ({
                            value: opt.id,
                            label: opt.legalUpdateCode,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
            // }
        }
        if (
            nextprops.updateLawDocumentApiResponse &&
            this.props.updateLawDocumentApiResponse !==
            nextprops.updateLawDocumentApiResponse
        ) {
            if (nextprops.updateLawDocumentApiResponse.message != '') {
                toastrMessage(
                    nextprops.updateLawDocumentApiResponse.message,
                    nextprops.updateLawDocumentApiResponse.responseType
                );
            }
            if (nextprops.updateLawDocumentApiResponse.responseType === SUCCESS) {
                this.resetForm(true);
            }
        }
        if (
            nextprops.addLawDocumentApiResponse &&
            this.props.addLawDocumentApiResponse !==
            nextprops.addLawDocumentApiResponse
        ) {
            if (nextprops.addLawDocumentApiResponse.message != '') {
                toastrMessage(
                    nextprops.addLawDocumentApiResponse.message,
                    nextprops.addLawDocumentApiResponse.responseType
                );
            }
            if (nextprops.addLawDocumentApiResponse.responseType === SUCCESS) {
                this.resetForm(true);
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={(this.state.operationType === UPDATE) ? __t('form_labels.documents.edit_document') : __t('form_labels.documents.add_document')}
                    isModalOpen={this.props.isModalOpen}
                    width="150%"
                    onModalDismiss={() => {
                        this.resetForm();
                    }}
                >
                    <Formik
                        initialValues={Object.assign(
                            {},
                            this.props.lawDocumentData
                        )}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {/* {JSON.stringify(values)} */}
                                <Row>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component={Select}
                                            name="referenceId"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .legalUpdateReferenceDropdownList
                                            }
                                            onChange={(opt) => {
                                                setFieldValue('referenceId', opt.value);
                                                if (this.state.operationType === UPDATE) {
                                                    this.setFreezeValues(opt.value);
                                                }
                                            }}
                                            value={this.state.legalUpdateReferenceDropdownList?.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.referenceId
                                            )}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.compliance.legal_update_reference'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.compliance.legal_update_reference'
                                            ),
                                            values.referenceId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.statutory_compliances.legal_update_reference_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component={Select}
                                            name="documentTypeId"
                                            className="form-control selectbox"
                                            options={this.props.sortedDocumentTypeListDropdown}
                                            onChange={(opt) => {
                                                setFieldValue('documentTypeId', opt.value);
                                            }}
                                            value={this.props.sortedDocumentTypeListDropdown?.filter((option) => option.value === values.documentTypeId)}
                                            disabled={(this.state.operationType === UPDATE)}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.documents.document_type'
                                            )}{' '}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.documents.document_type'
                                            ),
                                            values.documentTypeId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.documents.document_type_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            name="documentName"
                                            className="form-control"
                                            placeholder={__t(
                                                'form_labels.documents.document_name'
                                            )}
                                            value={values.documentName}
                                            maxLength={150}
                                            disabled={(this.state.operationType === UPDATE)}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.documents.document_name'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.documents.document_name'
                                            ),
                                            values.documentName,
                                            'required|max:150',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.documents.document_name_required'
                                                    ),
                                                    max: __t(
                                                        'validations.documents.document_name_max',
                                                        {
                                                            max: 150,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            name="referenceNo"
                                            className="form-control"
                                            placeholder={__t('form_labels.documents.reference_no')}
                                            value={values.referenceNo}
                                            maxLength={255}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.documents.reference_no'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.documents.reference_no'
                                            ),
                                            values.referenceNo,
                                            'required|max:255',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.documents.reference_no_required'
                                                    ),
                                                    max: __t(
                                                        'validations.documents.reference_no_max',
                                                        {
                                                            max: 255,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className={this.state.operationType === SUBMIT ? "col-lg-4 w-100 sticky_label" : "col-lg-6 w-100 sticky_label"}>
                                        <Field
                                            name="releaseDate"
                                            component={DatePicker}
                                            isClearable={true}
                                            className="form-control"
                                            placeholderText={__t('form_labels.documents.date_of_publication')}
                                            selected={
                                                values.releaseDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.releaseDate
                                                    )
                                                    : null
                                            }
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    `releaseDate`,
                                                    date
                                                );
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            onFocus={(e) =>
                                                e.target.blur()
                                            }
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.documents.date_of_publication')}{' '}
                                            <span className="text-danger required">*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.documents.date_of_publication'
                                            ),
                                            values.releaseDate ? getDateObjectWithValueFromMoment(values.releaseDate) : '',
                                            'required|customDate',
                                            {
                                                messages: {
                                                    required: __t('validations.documents.date_of_publication_required'),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className={this.state.operationType === SUBMIT ? "col-lg-4 w-100 sticky_label" : "col-lg-6 w-100 sticky_label"}>
                                        <Field
                                            name="effectiveDate"
                                            component={DatePicker}
                                            isClearable={true}
                                            className="form-control"
                                            placeholderText={__t(
                                                'form_labels.documents.effective_date'
                                            )}
                                            selected={
                                                values.effectiveDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.effectiveDate
                                                    )
                                                    : null
                                            }
                                            dateFormat={
                                                DATE_FORMAT_DATEPICKER
                                            }
                                            onChange={(date) => {
                                                setFieldValue(
                                                    `effectiveDate`,
                                                    date
                                                );
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            onFocus={(e) =>
                                                e.target.blur()
                                            }
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.documents.effective_date'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.documents.effective_date'
                                            ),
                                            values.effectiveDate ? getDateObjectWithValueFromMoment(values.effectiveDate) : '',
                                            'required|customDate',
                                            {
                                                messages: {
                                                    required: __t('validations.documents.effective_date_required')
                                                },
                                            }
                                        )}
                                    </FormGroup>

                                    {this.state.operationType === SUBMIT && (
                                        <FormGroup className="col-lg-4">
                                            <Field
                                                component={Select}
                                                name="displayMode"
                                                className="form-control selectbox"
                                                options={displayModeValues}
                                                onChange={(opt) => {
                                                    let displayModeValue =
                                                        opt
                                                            ? opt.value
                                                            : '';
                                                    setFieldValue(
                                                        'displayMode',
                                                        displayModeValue
                                                    );
                                                }}
                                                value={displayModeValues?.filter(
                                                    (option) =>
                                                        option.value ===
                                                        values.displayMode
                                                )}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.laws.display_mode'
                                                )}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.laws.display_mode'
                                                ),
                                                values.displayMode,
                                                'required',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.laws.display_mode_required'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    )}
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component="textarea"
                                            name="documentDescription"
                                            className="form-control"
                                            placeholder={__t(
                                                'form_labels.documents.document_description'
                                            )}
                                            value={values.documentDescription}
                                            maxLength={1024}
                                            rows="3"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.documents.document_description'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.documents.document_description'
                                            ),
                                            values.documentDescription,
                                            'required|max:1024',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.documents.document_description_required'
                                                    ),
                                                    max: __t(
                                                        'validations.documents.document_description_max',
                                                        {
                                                            max: 1024,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>

                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component="textarea"
                                            name="briefNote"
                                            className="form-control"
                                            placeholder={__t(
                                                'form_labels.documents.brief_note'
                                            )}
                                            value={values.briefNote}
                                            maxLength={1024}
                                            rows="3"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.documents.brief_note'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.documents.brief_note'
                                            ),
                                            values.briefNote,
                                            'required|max:1024',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.documents.brief_note_required'
                                                    ),
                                                    max: __t(
                                                        'validations.documents.brief_note_max',
                                                        {
                                                            max: 1024,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>

                                    <FormGroup className="col-lg-12">
                                        <Dropzone
                                            multiple={false}
                                            onDrop={(acceptedFiles) =>
                                                this.handleAcceptedFiles(
                                                    acceptedFiles
                                                )
                                            }
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => (
                                                <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        <h3>
                                                            Drop files here or
                                                            click to upload.
                                                            <span className="text-danger required">*</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {this.state.errors != '' && (
                                            <div className="srv-validation-message">
                                                {this.state.errors}
                                            </div>
                                        )}
                                        <div
                                            className="dropzone-previews mt-2"
                                            id="file-previews"
                                        >
                                            {this.state.selectedFile.map(
                                                (f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + '-file'}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="upload-document-title font-weight-bold"
                                                                        >
                                                                            {
                                                                                f.name
                                                                            }
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>
                                                                                {
                                                                                    f.formattedSize
                                                                                }
                                                                            </strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </FormGroup>

                                    {this.state.operationType === UPDATE && (
                                        <FormGroup className="col-lg-4 sticky_label radio_btn">
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isActive"
                                                    id="isActive1"
                                                    disabled={this.state.isDocumentActiveFreeze}
                                                    onChange={(opt) => {
                                                        if (opt) {
                                                            setFieldValue(
                                                                'isActive',
                                                                ACTIVE
                                                            );
                                                        } else {
                                                            setFieldValue(
                                                                'isActive',
                                                                INACTIVE
                                                            );
                                                        }
                                                    }}
                                                    value={ACTIVE}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    for="isActive1"
                                                >
                                                    {__t(
                                                        'form_labels.compliance.active'
                                                    )}
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isActive"
                                                    id="isActive2"
                                                    disabled={this.state.isDocumentActiveFreeze}
                                                    onChange={(opt) => {
                                                        if (opt) {
                                                            setFieldValue(
                                                                'isActive',
                                                                INACTIVE
                                                            );
                                                        } else {
                                                            setFieldValue(
                                                                'isActive',
                                                                ACTIVE
                                                            );
                                                        }
                                                    }}
                                                    value={INACTIVE}
                                                />
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.compliance.inActive'
                                                    ),
                                                    values.isActive,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required:
                                                                __t(
                                                                    'validations.statutory_compliances.required_active'
                                                                ),
                                                        },
                                                    }
                                                )}
                                                <Label
                                                    className="form-check-label"
                                                    for="isActive2"
                                                >
                                                    {__t(
                                                        'form_labels.compliance.inActive'
                                                    )}
                                                </Label>
                                            </div>

                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.compliance.status'
                                                )}
                                            </Label>
                                        </FormGroup>
                                    )}
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                            <div className="d-inline-block">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {this.state.operationType}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={this.resetForm}
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    legalUpdateReferenceList: makeLawComplianceSelectField('legalUpdateReferenceList'),
    updateLawDocumentApiResponse: makeLawDocumentSelectField('updateLawDocumentApiResponse'),
    addLawDocumentApiResponse: makeLawDocumentSelectField('addLawDocumentApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getLegalUpdateReferenceIdRequest,
    updateLawDocumentLegalRequest,
    addLawDocumentRequest
});
export default compose(withConnect, withRouter)(DocumentForm);
