import React, { Component } from 'react';
import {
    Row,
    Col,
    CardBody,
    Card,
    CardHeader,
    NavLink,
    NavItem,
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    SUBMIT,
    ADD_NEW,
    DATE_FORMAT_DATEPICKER,
    DANGER,
    TO_DO_FUND_STATUS,
    PENDING_WITH_REVIEWER_STATUS,
    PENDING_WITH_APPROVER_STATUS,
    REJECTED_BY_REVIEWER,
    REJECTED_BY_APPROVER,
    RE_ARRANGE_TASKS,
    ACTIVE,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { getInitials } from 'helpers/generalUtils';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import DatePickerRange from './CustomDatePicker';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import {
    addFundRequest,
    getFundDetailsRequest,
    updateFundRequest,
    getUserListByStatusRequest,
    getFundTaskUsersByEntityRequest,
    getFundTaskListRequest,
    deleteFundTaskByTaskidRequest,
    updateFundTaskRequest,
    updateFundTaskSequenceOrderRequest,
    getFundMasterListByStatusRequest
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import { makeFundsSelectField } from 'store/Masters/Funds/selector';
import {
    customDateValidator,
    formatDateForApi,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import { customValidTextField } from 'helpers/customValidators';
import __t from 'i18n/translator';
import fundsReducer from 'store/Masters/Funds/reducer';
import FundsSaga from 'store/Masters/Funds/saga';
import AddEditFundTaskModal from './AddEditFundTaskModal';
import classnames from 'classnames';
import { taskPriorityStatusList } from 'constants/dropdowns';
import CommentAndList from './CommentAndList';
import TaskActivityLog from './TaskActivityLog';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { isTenantAdminRole } from 'helpers/projectUtils';
import { PROJECT_TASK_APPROVER, PROJECT_TASK_REVIEWER, PROJECT_TASK_SUBMITTER } from 'constants/databaseConstants';
import Switch from 'react-switch';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomisedCollapse from 'components/Application/CustomisedCollapse';
import fundMasterReducer from 'store/Masters/FundMaster/reducer';
import fundMasterSaga from 'store/Masters/FundMaster/saga';
import { makeFundMasterSelectField } from 'store/Masters/FundMaster/selector';

const fundsKey = 'funds';
const withFundsReducer = injectReducer({
    key: fundsKey,
    reducer: fundsReducer,
});
const withFundsSaga = injectSaga({
    key: fundsKey,
    saga: FundsSaga,
});
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const fundMasterKey = 'fundMaster';
const withFundMasterReducer = injectReducer({
    key: fundMasterKey,
    reducer: fundMasterReducer,
});
const withFundMasterSaga = injectSaga({
    key: fundMasterKey,
    saga: fundMasterSaga,
});
class FundDetailsView extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField(),
                customDateValidator()
            ),
        });
        this.state = {
            isDragEnable: false,
            isOpenSideMenu: true,
            operationType: SUBMIT,
            fundId: this.props.match.params.fundId
                ? this.props.match.params.fundId
                : '',
            pageHeaderName: this.props.match.params.fundId
                ? __t('page_headers.edit_fund')
                : __t('page_headers.add_fund'),
            fundTeamsData: {},
            isModalOpen: false,
            sortedEntityListDropdown: [],
            fundDetailsList: {},
            taskList: [],
            taskModal: false,
            activeTab: 'allTab',
            selectedEntityId: '',
            selectedFundMasterId: '',
            fundStatusId: '',
            taskId: '',
            collapseEntityId: '',
            fundTaskCounts: [],
            actionId: '',
            priorityId: '',
            taskType: '',
            addSubtask: false,
            commentToggleId: '',
            taskActivityLogModal: false,
            activityTaskId: '',
            taskUserToggleId: '',
            taskUser1ToggleId: '',
            taskUserApproverToggle: '',
            activeTabForActionButton: 'commentTab',
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            addNewTask: false,
            viewOnlyAccess: false,
            filterTaskType: '',
            isOwner: false,
            isManagement: false,
            selectedDates: [
                moment().startOf('month').valueOf(),
                moment().add(3, 'months').endOf('month').valueOf()
            ]
        };
        this.onStatusChange = this.onStatusChange.bind(this);
        this.setSelectedDates = this.setSelectedDates.bind(this);
    }
    componentDidMount() {
        if (this.state.fundId) {
            this.props.getFundDetailsRequest(this.state.fundId);
            this.props.getFundMasterListByStatusRequest(ACTIVE);
        }
    }
    filterTaskStatus = (value) => {
        this.setState(
            {
                fundStatusId: value,
            },
            () => {
                this.getFundTaskByEntity();
            }
        );
    };
    setSelectedDates(dates) {
        this.setState({ selectedDates: dates },
            () => {
                this.getFundTaskByEntity();
            });
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => {
                    switch (tab) {
                        case 'todoTab':
                            this.filterTaskStatus(1);
                            break;
                        case 'inprogressTab':
                            this.filterTaskStatus(2);
                            break;
                        case 'completedTab':
                            this.filterTaskStatus(3);
                            break;
                        case 'overdueTab':
                            this.filterTaskStatus(8);
                            break;
                        case 'pendingWithReviewerTab':
                            this.filterTaskStatus(4);
                            break;
                        case 'pendingWithApproverTab':
                            this.filterTaskStatus(6);
                            break;
                        case 'allTab':
                            this.filterTaskStatus('');
                            break;
                        default:
                            this.filterTaskStatus('');
                            break;
                    }
                }
            );
        }
    }
    deleteTask = async (taskId, taskType, taskUsers, subTaskUsers = [], taskSubmitters = []) => {
        if (this.state.viewOnlyAccess == true) {
            if (taskType == 'task') {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;
            }
            if ((taskType == 'subTask' && taskUsers.length == 0) || (taskType == 'subTask' && taskUsers?.filter((u) => u.userId == this.state.loginUserId).length == 0)) {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;
            }
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            taskType === 'subTask'
                ? __t('alerts.sub_task_delete')
                : __t('alerts.task_delete')
        );
        if (response) {
            this.props.deleteFundTaskByTaskidRequest(taskId);
        }
    };

    addTask() {
        this.setState({
            taskId: '',
            taskModal: true,
            operationType: SUBMIT,
            addNewTask: true,
        });
    }
    closeTaskModal = () => {
        this.setState({
            taskId: '',
            taskModal: false,
            operationType: SUBMIT,
            addSubtask: false,
            addNewTask: false,
        });
        this.getFundTaskByEntity();
    };
    editTask(task, taskType, addSubtask, activeTab) {
        let taskID = task.id;
        let activeTaskTypeSubTask = (task.parentFundTaskId && task.parentFundTaskId != '') ? true : false;
        if (this.state.isManagement && activeTaskTypeSubTask) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        // if (this.state.viewOnlyAccess == true) {
        //     if ((taskType == 'task' && taskUsers.length == 0) || (taskType == 'task' && taskUsers?.filter((u) => u.userId == this.state.loginUserId).length == 0)) {
        //         toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
        //         return false;
        //     }
        //     if ((taskType == 'subTask' && taskUsers.length == 0) ||
        //         ((taskType == 'subTask' && taskSubmitters?.filter((u) => u == this.state.loginUserId).length == 0) &&
        //             (taskUsers.length > 0 && subTaskUsers.filter((su) => su.userId == this.state.loginUserId).length == 0))) {
        //         toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
        //         return false;
        //     }
        // }
        // let isEdit = this.checkEditTask(task)
        // if (!isEdit) {
        //     toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
        //     return false;
        // }
        this.setState(
            {
                taskModal: true,
                taskId: '',
                operationType: UPDATE,
                taskType: taskType,
                addNewTask: false,
            },
            () => {
                this.setState({
                    taskId: taskID,
                    addSubtask: addSubtask != undefined ? addSubtask : false,
                    activeTabForActionButton: activeTab != undefined ? activeTab : 'commentTab',
                });
            }
        );
    }

    checkEditTask(task) {
        const { fundDetailsList, loginUserId } = this.state;
        const { fundTeams } = fundDetailsList || {};

        if (fundTeams?.length > 0) {
            let loginUserData = fundTeams.filter(
                (teamUser) => teamUser.userId === loginUserId
            )[0];

            let isEdit = false;
            let relevantTeamUsers = [];

            if (loginUserData?.isSubmitter && task.fundStatusId === TO_DO_FUND_STATUS || task.fundStatusId === REJECTED_BY_REVIEWER) {
                relevantTeamUsers = fundTeams.filter(
                    (teamUser) => teamUser.userId === loginUserId && teamUser.isSubmitter
                );
            } else if (loginUserData?.isReviewer && task.fundStatusId === PENDING_WITH_REVIEWER_STATUS || task.fundStatusId === REJECTED_BY_REVIEWER || task.fundStatusId === REJECTED_BY_APPROVER) {
                relevantTeamUsers = fundTeams.filter(
                    (teamUser) => teamUser.userId === loginUserId && teamUser.isReviewer
                );
            } else if (loginUserData?.isApprover && task.fundStatusId === PENDING_WITH_APPROVER_STATUS || task.fundStatusId === REJECTED_BY_APPROVER) {

                relevantTeamUsers = fundTeams.filter(
                    (teamUser) => teamUser.userId === loginUserId && teamUser.isApprover
                );
            } else if (loginUserData.isOwner && task.fundStatusId === TO_DO_FUND_STATUS) {
                relevantTeamUsers = fundTeams.filter(
                    (teamUser) => teamUser.userId === loginUserId && teamUser.isOwner
                );
            }
            if (relevantTeamUsers.length > 0) {
                isEdit = true;
            }
            return isEdit;
        }
        return false;
    };

    tableToggle(taskId) {
        this.setState({
            collapseEntityId:
                this.state.collapseEntityId === taskId ? 0 : taskId,
        });
    }
    taskTableCommentsToggle(taskId) {
        this.setState({
            commentToggleId: this.state.commentToggleId === taskId ? 0 : taskId,
        });
    }
    assignee(users) {
        let usersName = '';
        users.map((user) => {
            usersName += getInitials(user.user.fullName);
        });
        return usersName;
    }
    actionToggle(taskId) {
        this.setState({
            actionId: this.state.actionId === taskId ? 0 : taskId,
        });
    }
    priorityToggle(taskId) {
        this.setState({
            priorityId: this.state.priorityId === taskId ? 0 : taskId,
        });
    }
    getFundTaskByEntity() {
        let formValues = {
            fundId: this.state.fundId,
            entityId: this.state.selectedEntityId,
            fundMasterId: this.state.selectedFundMasterId,
            fundStatusId: this.state.fundStatusId,
            taskType: this.state.filterTaskType,
            startMonthDate: this.state.selectedDates[0] && typeof this.state.selectedDates[0] === "number" && moment(this.state.selectedDates[0]).format("MM/YYYY") || this.state.selectedDates[0] || '',
            endMonthDate: this.state.selectedDates[1] && typeof this.state.selectedDates[1] === "number" && moment(this.state.selectedDates[1]).format("MM/YYYY") || this.state.selectedDates[1] || '',
        };
        if (!formValues.endMonthDate) {
            toastrMessage(__t('alerts.date_range_error'), DANGER);
            return false;
        }
        this.props.getFundTaskListRequest(formValues);
    }
    priorityColor(priority) {
        let priorityColor = '';
        switch (priority) {
            case 'Urgent':
                priorityColor = '#007bff';
                break;
            case 'High':
                priorityColor = '#dc3545';
                break;
            case 'Normal':
                priorityColor = '#28a745';
                break;
            case 'Low':
                priorityColor = '#ffc107';
                break;
            default:
                priorityColor = '#6c757d';
                break;
        }
        return priorityColor;
    }
    fundStatusBox(statusName) {
        let statusBoxColor = '';
        if (statusName) {
            switch (statusName) {
                case 'To Do':
                    statusBoxColor += 'todo-bg';
                    break;
                case 'Rejected By Reviewer':
                    statusBoxColor += 'inprogress-bg';
                    break;
                case 'Rejected By Approver':
                    statusBoxColor += 'inprogress-bg';
                    break;
                case 'Completed':
                    statusBoxColor += 'completes-bg';
                    break;
                case 'Pending with Reviewer':
                    statusBoxColor += 'pending-with-reviewer-bg';
                    break;
                case 'Pending with Approver':
                    statusBoxColor += 'pending-with-approver-bg';
                    break;
                case 'Overdue':
                    statusBoxColor += 'overdue-bg';
                    break;
                default:
                    statusBoxColor += 'gray-bg';
                    break;
            }
        }
        return statusBoxColor;
    }
    priorityUpdate = (task, selectedPriority) => {
        if (this.state.viewOnlyAccess == true) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        let formValues = {
            fundId: this.state.fundId,
            priority: selectedPriority,
            taskTitle: task.taskTitle,
            fundMasterId: this.state.selectedFundMasterId
        };
        this.props.updateFundTaskRequest(task.id, formValues);
    };
    onChangeDateInput = (selectedDate, task, type, taskType, taskUsers = [], subTaskUsers = [], taskSubmitters = []) => {
        if (this.state.viewOnlyAccess == true) {
            if (taskType == 'task') {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;
            }
            // if ((taskType == 'subTask' && taskUsers.length == 0) || (taskType == 'subTask' && taskUsers?.filter((u) => u.userId == this.state.loginUserId).length == 0)) {
            //     toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            //     return false;
            // }
            if ((taskType == 'subTask' && taskUsers.length == 0) ||
                ((taskType == 'subTask' && taskSubmitters?.filter((u) => u == this.state.loginUserId).length == 0) &&
                    (taskUsers.length > 0 && subTaskUsers.filter((su) => su.userId == this.state.loginUserId).length == 0))) {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;
            }
        }
        let formValues = '';
        if (type == 'startDate') {
            formValues = {
                fundId: this.state.fundId,
                startDate: formatDateForApi(selectedDate),
                taskTitle: task.taskTitle,
                fundMasterId: this.state.selectedFundMasterId
            };
        } else {
            formValues = {
                fundId: this.state.fundId,
                dueDate: formatDateForApi(selectedDate),
                taskTitle: task.taskTitle,
                fundMasterId: this.state.selectedFundMasterId
            };
        }
        this.props.updateFundTaskRequest(task.id, formValues);
    };
    taskActivityLog(taskId) {
        this.setState(
            {
                taskActivityLogModal: true,
                activityTaskId: '',
            },
            () =>
                this.setState({
                    activityTaskId: taskId,
                })
        );
    }
    closeTaskActivityLogModal = () => {
        this.setState({
            activityTaskId: '',
            taskActivityLogModal: false,
        });
    };
    taskUsersToggle(taskId, taskType = 'task', taskUsers = [], subTaskUsers = [], taskSubmitters = []) {
        if ((taskType == 'subTask' && taskUsers.length == 0) ||
            (taskType == 'subTask' && taskSubmitters?.filter((u) => u == this.state.loginUserId).length == 0)) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        this.setState({
            taskUserToggleId:
                this.state.taskUserToggleId === taskId ? 0 : taskId,
        });
    }
    taskUsers1Toggle(taskId) {
        this.setState({
            taskUser1ToggleId:
                this.state.taskUser1ToggleId === taskId ? 0 : taskId,
        });
    }
    taskUsersApproverToggle(taskId) {
        this.setState({
            taskUserApproverToggle:
                this.state.taskUserApproverToggle === taskId ? 0 : taskId,
        });
    }
    userUpdate = (selectedUserId, task, taskType, userType, taskUsers = []) => {
        if (this.state.viewOnlyAccess == true) {
            if (taskType == 'task') {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;
            }
            if ((taskType == 'subTask' && taskUsers.length == 0) || (taskType == 'subTask' && taskUsers?.filter((u) => u.userId == this.state.loginUserId).length == 0)) {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;
            }
        }
        let userArray = [];
        let submitterUsers = [];
        let reviewerUsers = [];
        let approverUsers = [];
        submitterUsers = this.state.fundDetailsList?.fundTeamUsers?.filter((u) => u.isSubmitter == 1).map((opt) => opt.userId);
        reviewerUsers = this.state.fundDetailsList?.fundTeamUsers?.filter((u) => u.isReviewer == 1).map((opt) => opt.userId);
        approverUsers = this.state.fundDetailsList?.fundTeamUsers?.filter((u) => u.isApprover == 1).map((opt) => opt.userId);
        let allUsersList = task?.fundTaskUsers?.map((x) => {
            if (taskType == 'subTask') {
                if (
                    submitterUsers.includes(x.userId) &&
                    userType == 'submitter' && x.userType === PROJECT_TASK_SUBMITTER
                ) {
                    return x.userId;
                } else if (
                    reviewerUsers.includes(x.userId) &&
                    userType == 'reviewer' && x.userType === PROJECT_TASK_REVIEWER
                ) {
                    return x.userId;
                }
            }
            if (taskType == 'task') {
                if (
                    submitterUsers.includes(x.userId) &&
                    userType == 'submitter' && x.userType === PROJECT_TASK_SUBMITTER
                ) {
                    return x.userId;
                } else if (
                    reviewerUsers.includes(x.userId) &&
                    userType == 'reviewer' && x.userType === PROJECT_TASK_REVIEWER
                ) {
                    return x.userId;
                } else if (
                    approverUsers.includes(x.userId) &&
                    userType == 'approver' && x.userType === PROJECT_TASK_APPROVER
                ) {
                    return x.userId;
                }
            }
        });
        const index = allUsersList?.findIndex(
            (item) => item === selectedUserId
        );
        userArray = allUsersList;
        if (index !== -1) {
            userArray.splice(index, 1);
        } else {
            userArray.push(selectedUserId);
        }
        let formValues = {
            fundId: this.state.fundId,
            userData: [{ userType: userType, userIds: userArray.filter(item => !!item) }],
            taskTitle: task.taskTitle,
            fundMasterId: this.state.selectedFundMasterId
        };

        this.props.updateFundTaskRequest(task.id, formValues);
    };
    fundTaskUsers = (taskUsers, userId, userType) => {
        const submitterUsers = this.state.fundDetailsList?.fundTeamUsers
            ?.filter((u) => u.isSubmitter == 1)
            .map((opt) => opt.userId);
        const reviewerUsers = this.state.fundDetailsList?.fundTeamUsers
            ?.filter((u) => u.isReviewer == 1)
            .map((opt) => opt.userId);
        let userArray = taskUsers.map((x) => x.userId);

        let status = userArray.indexOf(userId) > -1;
        return status;
    };
    onStatusChange(e) {
        let taskTypeFilter = '';
        if (e.target.checked) {
            taskTypeFilter = e.target.value;
        }
        this.setState({
            filterTaskType: taskTypeFilter
        }, () => {
            this.getFundTaskByEntity()
        })
    }
    submitTaskOrder = async () => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.reorder_tasks')
        );
        if (response) {
            let fundTasks = [];
            // let fundSubTasks = [];
            this.state.taskList.forEach((row, parentKeyIndex) => {
                let orderNo = parentKeyIndex + 1;
                fundTasks.push({ id: row.id, order: orderNo });
                // if (row && row.fundSubTasks.length > 0) {
                //     row.fundSubTasks.forEach((subTask, subKeyIndex) => {
                //         let subOrderNo = subKeyIndex + 1;
                //         fundSubTasks.push({ id: subTask.id, order: subOrderNo });
                //     });
                // }
            });
            const combinedTasks = [...fundTasks];
            await this.props.updateFundTaskSequenceOrderRequest({ fundTasks: combinedTasks });
        }
    };
    checkAlertAvailable = (fundTaskUsers) => {
        let alertUser = fundTaskUsers.filter((tu) => tu.userId === this.state.loginUserId && tu.isAlert === 1);
        return (alertUser.length > 0) ? true : false;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.fundDetailsApiResponse &&
            this.props.fundDetailsApiResponse !==
            nextprops.fundDetailsApiResponse
        ) {
            const fund = nextprops.fundDetailsApiResponse.data.fund;
            let fundOwners = fund?.fundTeams.filter(
                (teamUser) =>
                    teamUser.userId === this.state.loginUserId &&
                    teamUser.isOwner
            );
            let fundManagements = fund?.fundTeams.filter(
                (teamUser) =>
                    teamUser.userId === this.state.loginUserId &&
                    teamUser.isManagement
            );
            let fundSubmitters = fund?.fundTeams.filter(
                (teamUser) =>
                    teamUser.userId === this.state.loginUserId &&
                    teamUser.isSubmitter
            );
            let fundReviwers = fund?.fundTeams.filter(
                (teamUser) =>
                    teamUser.userId === this.state.loginUserId &&
                    teamUser.isReviewer
            );
            let fundApprover = fund?.fundTeams.filter(
                (teamUser) =>
                    teamUser.userId === this.state.loginUserId &&
                    teamUser.isApprover
            );
            let displayFund = true;
            if (isTenantAdminRole() === false && fundOwners.length === 0 && fundManagements.length === 0 && fundSubmitters.length === 0 && fundReviwers.length === 0 && fundApprover.length === 0) {
                displayFund = false;
            }
            if (displayFund == false) {
                toastrMessage(__t('alerts.fund_not_found'), DANGER);
                this.props.history.push('/funds');
            }

            let viewOnlyAccess = false;
            if (
                isTenantAdminRole() == true ||
                (fundOwners.length == 0 && fundManagements.length > 0) ||
                (fundOwners.length == 0 && fundSubmitters.length > 0) ||
                (fundOwners.length == 0 && fundReviwers.length > 0) ||
                (fundOwners.length == 0 && fundApprover.length > 0)
            ) {
                viewOnlyAccess = true;
            }

            this.setState({
                viewOnlyAccess: viewOnlyAccess,
                fundDetailsList: nextprops.fundDetailsApiResponse.data.fund,
                isOwner: fundOwners.length > 0 ? true : false,
                isManagement: fundManagements.length > 0 ? true : false
            });
            const entityTasks = nextprops.fundDetailsApiResponse.data.fund.entityTasks
                ? nextprops.fundDetailsApiResponse.data.fund.entityTasks
                : [];
            if (entityTasks.length > 0 && this.state.selectedEntityId == '') {
                this.setState({
                    selectedEntityId: entityTasks[0].entityId,
                    selectedFundMasterId: entityTasks[0].fundMasterId,
                }, () => {
                    this.getFundTaskByEntity();
                }
                );
            }
        }
        if (
            nextprops.fundTaskList &&
            this.props.fundTaskList !== nextprops.fundTaskList
        ) {
            this.setState({
                taskList: nextprops.fundTaskList.data.list,
                fundTaskCounts: nextprops.fundTaskList.data.fundTaskCounts,
            });
        }
        if (
            nextprops.fundTaskApiResponse &&
            this.props.fundTaskApiResponse !== nextprops.fundTaskApiResponse
        ) {
            if (nextprops.fundTaskApiResponse.responseType === SUCCESS) {
                this.getFundTaskByEntity();
            }
        }
        if (
            nextprops.fundSubTaskApiResponse &&
            this.props.fundSubTaskApiResponse !==
            nextprops.fundSubTaskApiResponse
        ) {
            if (nextprops.fundSubTaskApiResponse.responseType === SUCCESS) {
                this.getFundTaskByEntity();
            }
        }

        if (
            nextprops.updateFundTaskSequenceOrderApiResponse &&
            this.props.updateFundTaskSequenceOrderApiResponse !== nextprops.updateFundTaskSequenceOrderApiResponse
        ) {
            toastrMessage(
                nextprops.updateFundTaskSequenceOrderApiResponse.message,
                nextprops.updateFundTaskSequenceOrderApiResponse.responseType
            );
            this.setState({
                isDragEnable: false
            })
        }
    }
    render() {
        const { loginUserId } = this.state;
        let fundOwners = this.state?.fundDetailsList?.fundTeams?.filter(
            (teamUser) => teamUser.userId === this.state.loginUserId && teamUser.isOwner
        ) ?? [];
        let fundOwnerUserIds = fundOwners.map((x) => x.userId);
        let fundManagements = this.state?.fundDetailsList?.fundTeams?.filter(
            (teamUser) => teamUser.userId === this.state.loginUserId && teamUser.isManagement
        ) ?? [];
        let fundManagementUserIds = fundManagements.map((x) => x.userId);
        let selectedEntity = this.state.fundDetailsList?.fundEntities?.find(
            (e) => e.id === this.state.selectedEntityId
        );
        let selectedFundMaster = this.props.fundMasterList?.find(
            (e) => e.id === this.state.selectedFundMasterId
        );
        const selectedEntityName = selectedEntity
            ? selectedEntity.entityName
            : '';
        const selectedFundMasterName = selectedFundMaster
            ? selectedFundMaster.fundName
            : '';
        let rightArrow = `<svg
        width="8"
        height="14"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.07568 13.0498L7.08193 7.04356L1.07568 1.03732"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>`;
        let fundEntityName = selectedEntityName + rightArrow + selectedFundMasterName + rightArrow;
        let addEditTaskAndSubTaskModal = '';
        if (this.state.operationType === SUBMIT) {
            addEditTaskAndSubTaskModal =
                fundEntityName + __t('form_labels.fund_task.add_fund_task');
        } else if (this.state.taskType == 'subTask') {
            addEditTaskAndSubTaskModal =
                fundEntityName + __t('form_labels.fund_task.edit_sub_task');
        } else {
            addEditTaskAndSubTaskModal =
                fundEntityName + __t('form_labels.fund_task.edit_fund_task');
        }

        const checkIsTaskDraft = (task) => {
            if (task.isDraft) {
                let relevantTeamUser = {};
                let taskSubmitterUsers = task.fundTaskUsers.filter((ft) => ft.userType === 1);
                let taskReviewerUsers = task.fundTaskUsers.filter((ft) => ft.userType === 2);
                let taskApproverUsers = task.fundTaskUsers.filter((ft) => ft.userType === 3);
                if (task.fundStatusId === TO_DO_FUND_STATUS || task.fundStatusId === REJECTED_BY_REVIEWER) {
                    relevantTeamUser = taskSubmitterUsers.find((teamUser) => teamUser.userId === loginUserId);
                } else if (task.fundStatusId === PENDING_WITH_REVIEWER_STATUS || task.fundStatusId === REJECTED_BY_REVIEWER || task.fundStatusId === REJECTED_BY_APPROVER) {
                    relevantTeamUser = taskReviewerUsers.filter((teamUser) => teamUser.userId === loginUserId);
                } else if (task.fundStatusId === PENDING_WITH_APPROVER_STATUS || task.fundStatusId === REJECTED_BY_APPROVER) {
                    relevantTeamUser = taskApproverUsers.filter((teamUser) => teamUser.userId === loginUserId);
                }
                return (relevantTeamUser) ? true : false;
            }
            return false;
        };
        const checkTaskEditEnable = (task) => {
            let activeTaskTypeSubTask = (task.parentFundTaskId && task.parentFundTaskId != '') ? true : false;
            let assignEditAllowed = false;
            let titleEditAllowed = false;
            if (activeTaskTypeSubTask === true) {
                if (task.submitterId.includes(loginUserId)) {
                    assignEditAllowed = true;
                    titleEditAllowed = true;
                }
            } else {
                if (fundOwnerUserIds.includes(loginUserId)) { /** If login user is fund owner then can edit assigne, title, description */
                    assignEditAllowed = true;
                    titleEditAllowed = true;
                }
            }
            return assignEditAllowed;
        }
        const getListStyle = isDraggingOver => ({
            background: isDraggingOver ? "lightblue" : "lightgrey",
            padding: 8,
            width: 250
        });
        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: "none",
            padding: 8 * 2,
            margin: `0 0 ${8}px 0`,
            // styles we need to apply on draggables
            ...draggableStyle
        });

        const onDragEnd = (result) => {
            // dropped outside the list
            if (!result.destination) {
                return;
            }
            if (!this.state.isOwner) {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;

            }
            const items = Array.from(this.state.taskList);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            this.setState({
                taskList: items
            });
        }

        const onSubTaskDragEnd = (result) => {
            // Dropped outside the list
            if (!result.destination) {
                return;
            }
            if (!this.state.isOwner) {
                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                return false;

            }
            // Find the task containing the subtasks
            const taskList = [...this.state.taskList];
            const taskIndex = taskList.findIndex((task) => task.id === result.destination.droppableId);

            if (taskIndex === -1) {
                return;
            }

            // Get the subtasks of the found task
            const subTaskList = [...taskList[taskIndex].fundSubTasks];
            const [reorderedItem] = subTaskList.splice(result.source.index, 1);
            subTaskList.splice(result.destination.index, 0, reorderedItem);

            // Update the task's subtasks
            taskList[taskIndex].fundSubTasks = subTaskList;

            // Set the new state
            this.setState({
                taskList: taskList
            });
        };

        return (
            <React.Fragment>
                <div className="project-details-box fund_details_box">
                    <div className={this.state.isOpenSideMenu === false ? "card-box fund_details_list active" : "card-box fund_details_list"}>
                        <Card className='m-0'>
                            <CardHeader>
                                <div className="page-header pb-0">
                                    <div className="common-flex-box">
                                        <h4>
                                            <Button color='link' className='collapse-btn' type='button' onClick={() => {
                                                this.setState({
                                                    isOpenSideMenu: !this.state.isOpenSideMenu
                                                })
                                            }}>
                                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M18.75 2C18.75 2.41421 18.4142 2.75 18 2.75L2 2.75C1.58579 2.75 1.25 2.41421 1.25 2C1.25 1.58579 1.58579 1.25 2 1.25L18 1.25C18.4142 1.25 18.75 1.58579 18.75 2Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" /><path fillRule="evenodd" clipRule="evenodd" d="M18.75 8C18.75 8.41421 18.4142 8.75 18 8.75L2 8.75C1.58579 8.75 1.25 8.41421 1.25 8C1.25 7.58579 1.58579 7.25 2 7.25L18 7.25C18.4142 7.25 18.75 7.58579 18.75 8Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" /><path fillRule="evenodd" clipRule="evenodd" d="M18.75 14C18.75 14.4142 18.4142 14.75 18 14.75L2 14.75C1.58579 14.75 1.25 14.4142 1.25 14C1.25 13.5858 1.58579 13.25 2 13.25L18 13.25C18.4142 13.25 18.75 13.5858 18.75 14Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" /></svg>
                                            </Button>
                                            {this.state.fundDetailsList?.title}
                                        </h4>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <ul className="list-group">
                                    {this.state.fundDetailsList?.fundEntities?.map(
                                        (entity) => {
                                            let entityTasksList = this.state.fundDetailsList.entityTaskUsers.filter((et) => et.entityId === entity.id);
                                            let isTaskAlert = entityTasksList.find(entityTask => entityTask.isAlert === 1);
                                            if (entityTasksList.length > 0) {
                                                return (
                                                    <li
                                                        className={(entity.id === this.state.selectedEntityId) ? " active mb-2 fund-project-list" : "mb-2 fund-project-list"}
                                                        key={entity.id}
                                                    >
                                                        {isTaskAlert && isTaskAlert.isAlert == 1 && (
                                                            <span className='blink-box'>
                                                                <span className='bg-danger'>&nbsp;</span>
                                                            </span>
                                                        )}
                                                        <CustomisedCollapse
                                                            key={entity.id}
                                                            dialogName={(this.state.isOpenSideMenu) ? entity.entityName : getInitials(entity.entityName)}
                                                            viewDialog={(this.state.isOpenSideMenu === true && entity.id === this.state.selectedEntityId)}
                                                        >
                                                            {entityTasksList.map((eTask) => {
                                                                let fundMasterDetailData = this.props.fundMasterList.find((fundMaster) => fundMaster.id === eTask.fundMasterId);
                                                                return (
                                                                    <a
                                                                        href={() => false}
                                                                        className={
                                                                            entity.id ===
                                                                                this.state
                                                                                    .selectedEntityId && eTask.fundMasterId === this.state.selectedFundMasterId
                                                                                ? 'list-group-item list-group-item-action active'
                                                                                : 'list-group-item list-group-item-action'
                                                                        }
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    selectedEntityId: entity.id,
                                                                                    selectedFundMasterId: eTask.fundMasterId
                                                                                },
                                                                                () => {
                                                                                    this.getFundTaskByEntity();
                                                                                }
                                                                            )
                                                                        }
                                                                        }
                                                                    >
                                                                        {eTask.isAlert === 1 &&
                                                                            <div className='blink-box'>
                                                                                <span className='bg-danger'>&nbsp;</span>
                                                                            </div>
                                                                        }
                                                                        {fundMasterDetailData?.fundName}
                                                                        {/* --------------------- */}
                                                                    </a>
                                                                )
                                                            })}
                                                        </CustomisedCollapse>
                                                    </li>
                                                );
                                            }
                                        }
                                    )}
                                </ul>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="card-box fund_details_main">
                        <Card className='m-0'>
                            <CardHeader>
                                <div className="common-flex-box w-100 justify-content-between">
                                    <h6>
                                        <a href={() => false} className="back-btn mr-2" onClick={() => { this.props.history.push('/funds'); }}>
                                            <i className="dripicons-arrow-thin-left"></i>
                                        </a>
                                        <span>{selectedEntityName}</span>
                                        {
                                            <svg width="8" height="14" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.07568 13.0498L7.08193 7.04356L1.07568 1.03732" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                        }
                                        <span>{selectedFundMasterName}</span>
                                    </h6>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <div className='fund-date-picker'>
                                            <DatePickerRange
                                                className="form-control"
                                                selectedDates={this.state.selectedDates}
                                                setSelectedDates={this.setSelectedDates}
                                            />
                                        </div>
                                        {this.state.fundDetailsList &&
                                            fundOwners.length > 0 && this.state.taskList.length > 0 && (
                                                <>
                                                    {!this.state.isDragEnable && (
                                                        <Button type="button"
                                                            color="info"
                                                            className="mr-2" onClick={() => {
                                                                this.setState({
                                                                    isDragEnable: !this.state.isDragEnable
                                                                });
                                                            }}>
                                                            {__t('form_labels.funds.rearrange_tasks')}
                                                        </Button>
                                                    )}
                                                    {this.state.isDragEnable && (
                                                        <Button type="button"
                                                            color="warning"
                                                            className="mr-2" onClick={() => {
                                                                this.submitTaskOrder();
                                                            }}>
                                                            {__t('form_labels.funds.save_rearrange_tasks')}
                                                        </Button>
                                                    )}
                                                </>
                                            )
                                        }
                                        <div className="d-flex form">
                                            <div class="switch-field">
                                                <input type="radio" id="filterTaskType1" name='filtertasktype' value="" onChange={this.onStatusChange} checked={this.state.filterTaskType === ''} />
                                                <label for="filterTaskType1">All</label>
                                                <input type="radio" id="filterTaskType2" name='filtertasktype' value="1" onChange={this.onStatusChange} checked={this.state.filterTaskType === '1'} />
                                                <label for="filterTaskType2">One time</label>
                                                <input type="radio" id="filterTaskType3" name='filtertasktype' value="2" onChange={this.onStatusChange} checked={this.state.filterTaskType === '2'} />
                                                <label for="filterTaskType3">Recurring</label>
                                            </div>
                                        </div>
                                        {this.state.fundDetailsList &&
                                            fundOwners.length > 0 && (
                                                <a
                                                    href={() => false}
                                                    className="btn btn-primary waves-effect waves-light float-right ml-2"
                                                    onClick={() => {
                                                        this.addTask();
                                                    }}
                                                >
                                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M7.5 2L7.5 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" /> <path d="M13 7.5L2 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" /></svg>{' '}
                                                    {ADD_NEW}
                                                </a>
                                            )}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row>
                                    <Col lg={12}>
                                        <Nav tabs className="nav-tabs-custom nav-justified laws_compliance_tabs">
                                            {' '}
                                            <NavItem className="bg-secondary">
                                                <NavLink className={classnames({
                                                    active: this.state.activeTab === 'allTab',
                                                })} onClick={() => {
                                                    this.toggle('allTab');
                                                }}
                                                >{__t('form_labels.funds.all')}
                                                    <span>
                                                        {this.state.fundTaskCounts?.all}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="bg-primary">
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            'todoTab',
                                                    })}
                                                    onClick={() => {
                                                        this.toggle('todoTab');
                                                    }}
                                                >
                                                    {__t(
                                                        'form_labels.funds.todo'
                                                    )}
                                                    <span>
                                                        {this.state
                                                            .fundTaskCounts
                                                            .toDo != undefined
                                                            ? this.state
                                                                .fundTaskCounts
                                                                .toDo
                                                            : '0'}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="bg-warning">
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            'inprogressTab',
                                                    })}
                                                    onClick={() => {
                                                        this.toggle(
                                                            'inprogressTab'
                                                        );
                                                    }}
                                                >
                                                    {__t(
                                                        'form_labels.funds.rework'
                                                    )}
                                                    <span>
                                                        {this.state
                                                            .fundTaskCounts
                                                            .inProgress !=
                                                            undefined
                                                            ? this.state
                                                                .fundTaskCounts
                                                                .inProgress
                                                            : '0'}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="bg-muted">
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            'pendingWithReviewerTab',
                                                    })}
                                                    onClick={() => {
                                                        this.toggle(
                                                            'pendingWithReviewerTab'
                                                        );
                                                    }}
                                                >
                                                    {__t(
                                                        'form_labels.funds.pending_with_reviewer'
                                                    )}
                                                    <span>
                                                        {this.state
                                                            .fundTaskCounts
                                                            ?.pendingWithReviewer !=
                                                            undefined
                                                            ? this.state
                                                                .fundTaskCounts
                                                                ?.pendingWithReviewer
                                                            : '0'}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="bg-info">
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            'pendingWithApproverTab',
                                                    })}
                                                    onClick={() => {
                                                        this.toggle(
                                                            'pendingWithApproverTab'
                                                        );
                                                    }}
                                                >
                                                    {__t(
                                                        'form_labels.funds.pending_with_approver'
                                                    )}
                                                    <span>
                                                        {this.state
                                                            .fundTaskCounts
                                                            ?.pendingWithApprover !=
                                                            undefined
                                                            ? this.state
                                                                .fundTaskCounts
                                                                ?.pendingWithApprover
                                                            : '0'}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="bg-success">
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            'completedTab',
                                                    })}
                                                    onClick={() => {
                                                        this.toggle(
                                                            'completedTab'
                                                        );
                                                    }}
                                                >
                                                    {__t(
                                                        'form_labels.funds.completed'
                                                    )}
                                                    <span>
                                                        {this.state
                                                            .fundTaskCounts
                                                            .completed !=
                                                            undefined
                                                            ? this.state
                                                                .fundTaskCounts
                                                                .completed
                                                            : '0'}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="bg-danger">
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            this.state
                                                                .activeTab ===
                                                            'overdueTab',
                                                    })}
                                                    onClick={() => {
                                                        this.toggle(
                                                            'overdueTab'
                                                        );
                                                    }}
                                                >
                                                    {__t(
                                                        'form_labels.funds.overdue'
                                                    )}
                                                    <span>
                                                        {this.state
                                                            .fundTaskCounts
                                                            .overDue !=
                                                            undefined
                                                            ? this.state
                                                                .fundTaskCounts
                                                                .overDue
                                                            : '0'}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId={"droppable-001"}>
                                        {(provided, snapshot) => (
                                            <div
                                                className='droppable_box'
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                <table className={this.state.isDragEnable === true ? "table tab-content-box drag-enabled" : "table tab-content-box"}>
                                                    {this.state.taskList.length > 0 && (
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="text-left"></th>
                                                                <th scope="col">{__t('form_labels.funds.code')}</th>
                                                                <th scope="col">{__t('form_labels.funds.status')}</th>
                                                                <th scope="col">{__t('form_labels.funds.submitter')}</th>
                                                                <th scope="col">{__t('form_labels.funds.reviewer')} </th>
                                                                <th scope="col">{__t('form_labels.funds.approver')} </th>
                                                                <th scope="col" className="task-date-box">{__t('form_labels.fund_task.start_date')}</th>
                                                                <th scope="col" className="task-date-box">{__t('form_labels.fund_task.due_date')}</th>
                                                                <th scope="col" className="action-box">{__t('form_labels.fund_task.priority')}</th>
                                                                {/* <th scope="col" className="action-box">{__t('form_labels.funds.action')}</th> */}
                                                            </tr>
                                                        </thead>
                                                    )}
                                                    {this.state.taskList.map(
                                                        (task, taskKey) => {
                                                            const taskSubmitters = task.fundTaskUsers.map(
                                                                (user) => {
                                                                    if (user.userType === PROJECT_TASK_SUBMITTER) {
                                                                        return user.userId;
                                                                    }
                                                                }
                                                            );
                                                            return (
                                                                <Draggable key={task.id} draggableId={task.id} index={taskKey} isDragDisabled={!this.state.isDragEnable}>
                                                                    {(providedCompliance, snapshotCompliance) => (
                                                                        <>
                                                                            <tbody key={taskKey} className="tab-color-box tab-status-line-box" ref={providedCompliance.innerRef}
                                                                                {...providedCompliance.draggableProps}
                                                                                {...providedCompliance.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    snapshotCompliance.isDragging,
                                                                                    providedCompliance.draggableProps.style
                                                                                )}>
                                                                                <tr
                                                                                    className={"droppable_row"}
                                                                                >
                                                                                    <td className={'inner-task-box task-title text-left ' +
                                                                                        this.fundStatusBox(
                                                                                            task?.fundStatusName
                                                                                        )
                                                                                    }
                                                                                    >
                                                                                        <div className="d-flex">
                                                                                            <a
                                                                                                href={() => false}
                                                                                                title={
                                                                                                    task?.taskTitle
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        task?.fundSubTasks &&
                                                                                                        task
                                                                                                            ?.fundSubTasks
                                                                                                            .length >
                                                                                                        0
                                                                                                    ) {
                                                                                                        this.tableToggle(
                                                                                                            task.id
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <span className="project-list-status-box"></span>
                                                                                                {task?.taskTitle}
                                                                                            </a>
                                                                                            <ul>
                                                                                                {(task?.fundSubTasks?.length > 0 || taskSubmitters.includes(this.state.loginUserId)) && (
                                                                                                    <li>
                                                                                                        {task?.fundSubTasks &&
                                                                                                            task
                                                                                                                ?.fundSubTasks
                                                                                                                .length >
                                                                                                            0 && (
                                                                                                                <>
                                                                                                                    <a
                                                                                                                        href={() => false}
                                                                                                                        title="Connection"
                                                                                                                        // className="collapse-btn"
                                                                                                                        onClick={() =>
                                                                                                                            this.tableToggle(
                                                                                                                                task.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            task
                                                                                                                                ?.fundSubTasks
                                                                                                                                .length
                                                                                                                        }
                                                                                                                        <svg
                                                                                                                            width="14"
                                                                                                                            height="16"
                                                                                                                            viewBox="0 0 14 16"
                                                                                                                            fill="none"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                        >
                                                                                                                            <rect
                                                                                                                                x="12.6665"
                                                                                                                                y="5.66675"
                                                                                                                                width="3.11111"
                                                                                                                                height="3.11111"
                                                                                                                                rx="1.55556"
                                                                                                                                transform="rotate(90 12.6665 5.66675)"
                                                                                                                                stroke="currentColor"
                                                                                                                            />
                                                                                                                            <rect
                                                                                                                                x="12.6665"
                                                                                                                                y="11.8887"
                                                                                                                                width="3.11111"
                                                                                                                                height="3.11111"
                                                                                                                                rx="1.55556"
                                                                                                                                transform="rotate(90 12.6665 11.8887)"
                                                                                                                                stroke="currentColor"
                                                                                                                            />
                                                                                                                            <rect
                                                                                                                                x="1"
                                                                                                                                y="4.11108"
                                                                                                                                width="3.11111"
                                                                                                                                height="3.11111"
                                                                                                                                rx="1.55556"
                                                                                                                                transform="rotate(-90 1 4.11108)"
                                                                                                                                stroke="currentColor"
                                                                                                                            />
                                                                                                                            <path
                                                                                                                                d="M2.55566 4.88892V9.44447C2.55566 11.3301 2.55566 12.2729 3.14145 12.8587C3.72724 13.4445 4.67005 13.4445 6.55566 13.4445H9.55566"
                                                                                                                                stroke="currentColor"
                                                                                                                            />
                                                                                                                            <path
                                                                                                                                d="M2.55566 4.11108V4.11108C2.55566 5.14742 2.55566 5.66558 2.74305 6.06743C2.94177 6.49358 3.28428 6.83609 3.71043 7.03481C4.11227 7.2222 4.63044 7.2222 5.66678 7.2222H9.55566"
                                                                                                                                stroke="currentColor"
                                                                                                                            />
                                                                                                                        </svg>
                                                                                                                    </a>
                                                                                                                </>
                                                                                                            )}
                                                                                                        {/* Add sub task btn */}
                                                                                                        {taskSubmitters.includes(
                                                                                                            this
                                                                                                                .state
                                                                                                                .loginUserId
                                                                                                        ) ==
                                                                                                            true && (
                                                                                                                <a
                                                                                                                    href={() => false}
                                                                                                                    title={__t(
                                                                                                                        'form_labels.funds.add_subtask'
                                                                                                                    )}
                                                                                                                    onClick={() =>
                                                                                                                        this.editTask(
                                                                                                                            task,
                                                                                                                            'task',
                                                                                                                            true,
                                                                                                                            '',
                                                                                                                            task.fundTaskUsers
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <svg
                                                                                                                        width="16"
                                                                                                                        height="16"
                                                                                                                        viewBox="0 0 16 16"
                                                                                                                        fill="none"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                    >
                                                                                                                        <path
                                                                                                                            d="M8 1L8 15"
                                                                                                                            stroke="currentColor"
                                                                                                                            strokeWidth="1.5"
                                                                                                                            strokeLinecap="round"
                                                                                                                        />
                                                                                                                        <path
                                                                                                                            d="M15 8L1 8"
                                                                                                                            stroke="currentColor"
                                                                                                                            strokeWidth="1.5"
                                                                                                                            strokeLinecap="round"
                                                                                                                        />
                                                                                                                    </svg>
                                                                                                                </a>
                                                                                                            )}
                                                                                                    </li>
                                                                                                )}
                                                                                                <li>
                                                                                                    <Dropdown
                                                                                                        isOpen={
                                                                                                            this
                                                                                                                .state
                                                                                                                .commentToggleId ===
                                                                                                            task.id
                                                                                                        }
                                                                                                        tag="div"
                                                                                                    >
                                                                                                        <DropdownToggle
                                                                                                            className="nav-link arrow-none waves-effect"
                                                                                                            tag="a"
                                                                                                            title={__t(
                                                                                                                'form_labels.funds.comments'
                                                                                                            )}
                                                                                                            onClick={() =>
                                                                                                                this.editTask(
                                                                                                                    task,
                                                                                                                    'task',
                                                                                                                    true,
                                                                                                                    '',
                                                                                                                    task.fundTaskUsers
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <svg
                                                                                                                width="16"
                                                                                                                height="17"
                                                                                                                viewBox="0 0 16 17"
                                                                                                                fill="none"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                            >
                                                                                                                <path
                                                                                                                    d="M14.4101 2.5555C15 3.43829 15 4.66719 15 7.125C15 9.58281 15 10.8117 14.4101 11.6945C14.1548 12.0767 13.8267 12.4048 13.4445 12.6601C12.6733 13.1754 11.638 13.2406 9.75 13.2488V13.25L8.78262 15.1848C8.46016 15.8297 7.53984 15.8297 7.21738 15.1848L6.25 13.25V13.2488C4.36201 13.2406 3.32669 13.1754 2.5555 12.6601C2.17334 12.4048 1.84521 12.0767 1.58986 11.6945C1 10.8117 1 9.58281 1 7.125C1 4.66719 1 3.43829 1.58986 2.5555C1.84521 2.17334 2.17334 1.84521 2.5555 1.58986C3.43829 1 4.66719 1 7.125 1H8.875C11.3328 1 12.5617 1 13.4445 1.58986C13.8267 1.84521 14.1548 2.17334 14.4101 2.5555Z"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeLinecap="round"
                                                                                                                    strokeLinejoin="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M5.375 5.375L10.625 5.375"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeLinecap="round"
                                                                                                                    strokeLinejoin="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M5.375 8.875H8"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeLinecap="round"
                                                                                                                    strokeLinejoin="round"
                                                                                                                />
                                                                                                            </svg>
                                                                                                            {
                                                                                                                task
                                                                                                                    .fundTaskActivities
                                                                                                                    .length
                                                                                                            }
                                                                                                        </DropdownToggle>
                                                                                                        <DropdownMenu
                                                                                                            className="dropdown-menu-lg"
                                                                                                            right
                                                                                                        >
                                                                                                            {this
                                                                                                                .state
                                                                                                                .commentToggleId ===
                                                                                                                task.id && (
                                                                                                                    <CommentAndList
                                                                                                                        taskId={
                                                                                                                            this
                                                                                                                                .state
                                                                                                                                .commentToggleId
                                                                                                                        }
                                                                                                                        fundTaskApi={() =>
                                                                                                                            this.getFundTaskByEntity()
                                                                                                                        }
                                                                                                                    />
                                                                                                                )}
                                                                                                        </DropdownMenu>
                                                                                                    </Dropdown>
                                                                                                </li>
                                                                                                {task.isOverDue ===
                                                                                                    true && (
                                                                                                        <li>
                                                                                                            <a
                                                                                                                href={() => false}
                                                                                                                title={__t(
                                                                                                                    'form_labels.funds.overDueActivity'
                                                                                                                )}
                                                                                                                onClick={() =>
                                                                                                                    this.editTask(
                                                                                                                        task,
                                                                                                                        'task',
                                                                                                                        '',
                                                                                                                        'activityLogTab',
                                                                                                                        task.fundTaskUsers
                                                                                                                    )
                                                                                                                }
                                                                                                                className='text-danger'
                                                                                                            >
                                                                                                                <svg width="19" height="18" style={{ width: '1rem', height: '1rem' }} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
                                                                                                                    <path d="M8 4.5L8 8.1875C8 8.32557 7.88807 8.4375 7.75 8.4375L4.9375 8.4375" stroke="currentColor" strokeLinecap="round" />
                                                                                                                    <path d="M13.5 17.5C15.975 17.5 18 15.475 18 13C18 10.525 15.975 8.5 13.5 8.5C11.025 8.5 9 10.525 9 13C9 15.475 11.025 17.5 13.5 17.5Z" fill="currentColor" opacity="0.8" stroke="currentColor" stroke-width="0.9" strokeLinecap="round" stroke-linejoin="round" />
                                                                                                                    <path d="M13.5 11.2V13.45" stroke="#ffffff" stroke-width="1" strokeLinecap="round" stroke-linejoin="round" />
                                                                                                                    <path d="M13.4974 14.8H13.5014" stroke="#ffffff" strokeLinecap="round" stroke-linejoin="round" />
                                                                                                                </svg>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    )}

                                                                                                {
                                                                                                    checkIsTaskDraft(task) && (
                                                                                                        <small className='task-draft-icon'>
                                                                                                            <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 18.2501C4 17.8358 4.33579 17.5001 4.75 17.5001H12.75C13.1642 17.5001 13.5 17.8358 13.5 18.2501C13.5 18.6643 13.1642 19.0001 12.75 19.0001H4.75C4.33579 19.0001 4 18.6643 4 18.2501Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4 20.7501C4 20.3358 4.33579 20.0001 4.75 20.0001C5.16421 20.0001 10.25 20.0001 10.25 20.0001C10.6642 20.0001 11 20.3358 11 20.7501C11 21.1643 10.6642 21.5001 10.25 21.5001H4.75C4.33579 21.5001 4 21.1643 4 20.7501Z" fill="currentColor" /><path d="M20.9999 9.925L21 14.5377C21 19.4874 21 21.9623 19.5355 23.5C18.0711 25.0377 15.714 25.0377 11 25.0377C6.28594 25.0377 3.92892 25.0377 2.46446 23.5C0.999989 21.9623 1 17.9497 1 13C1 8.05025 0.999886 4.07538 2.46435 2.53769C3.92882 1 6.28584 1 10.9999 1H12.4999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M15.652 2.45506L16.3009 1.80624C17.3759 0.731252 19.1188 0.731252 20.1938 1.80624C21.2687 2.88124 21.2687 4.62415 20.1938 5.69914L19.5449 6.34795M15.652 2.45506C15.652 2.45506 15.7331 3.83379 16.9497 5.05032C18.1662 6.26685 19.5449 6.34795 19.5449 6.34795M15.652 2.45506L9.68718 8.41993C9.28316 8.82394 9.08116 9.02595 8.90743 9.24868C8.70249 9.51143 8.52679 9.79572 8.38344 10.0965C8.26191 10.3515 8.17157 10.6225 7.99089 11.1646L7.41242 12.9M19.5449 6.34795L13.5801 12.3128C13.1761 12.7168 12.9741 12.9188 12.7513 13.0926C12.4886 13.2975 12.2043 13.4732 11.9035 13.6166C11.6485 13.7381 11.3775 13.8284 10.8354 14.0091L9.1 14.5876M9.1 14.5876L7.97709 14.9619C7.71035 15.0508 7.41626 14.9814 7.21744 14.7826C7.01862 14.5837 6.9492 14.2897 7.03811 14.0229L7.41242 12.9M9.1 14.5876L7.41242 12.9" stroke="currentColor" stroke-width="1.5" /></svg>
                                                                                                        </small>
                                                                                                    )
                                                                                                }
                                                                                            </ul>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <a className="text-nowrap btn btn-link p-0 blink-box" href={() => false} title="Edit" onClick={() => this.editTask(task, 'task', false, '', task.fundTaskUsers)}>
                                                                                            {task.code}
                                                                                            {this.checkAlertAvailable(task.fundTaskUsers) && (
                                                                                                <span className='bg-danger'>&nbsp;</span>
                                                                                            )}
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="text-nowrap">
                                                                                            {
                                                                                                task?.fundStatusName
                                                                                            }
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="assigne-group-box">
                                                                                            {task.fundTaskUsers.map((user, newUserKey) => {
                                                                                                let userFromTeam = this.state.fundDetailsList?.fundTeamUsers?.find((opt) =>
                                                                                                    opt.userId == user.userId && user.userType === PROJECT_TASK_SUBMITTER
                                                                                                );
                                                                                                if (userFromTeam) {
                                                                                                    return (
                                                                                                        <span className="assigne-box green-round" key={newUserKey} title={__t(userFromTeam?.user?.fullName)}                                                                                                        >
                                                                                                            <p key={newUserKey}                                                                                                            >
                                                                                                                {userFromTeam?.user?.fullName ? getInitials(userFromTeam?.user?.fullName) : ''}
                                                                                                            </p>
                                                                                                        </span>
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                            )}
                                                                                            {checkTaskEditEnable(task) === true && (
                                                                                                <Dropdown
                                                                                                    isOpen={
                                                                                                        this
                                                                                                            .state
                                                                                                            .taskUserToggleId ===
                                                                                                        task.id
                                                                                                    }
                                                                                                    toggle={() =>
                                                                                                        this.taskUsersToggle(
                                                                                                            task.id
                                                                                                        )
                                                                                                    }
                                                                                                    className="notification-list list-inline-item mr-1"
                                                                                                    tag="li"
                                                                                                >
                                                                                                    <DropdownToggle
                                                                                                        className="arrow-none waves-effect"
                                                                                                        tag="a"
                                                                                                    >
                                                                                                        <a
                                                                                                            href={() => false}
                                                                                                            title={__t(
                                                                                                                'form_labels.funds.submitter'
                                                                                                            )}
                                                                                                            className="assigne-box assign-dropdown"
                                                                                                        >
                                                                                                            <svg
                                                                                                                width="19"
                                                                                                                height="20"
                                                                                                                viewBox="0 0 19 20"
                                                                                                                fill="none"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                            >
                                                                                                                <circle
                                                                                                                    cx="8.22754"
                                                                                                                    cy="5"
                                                                                                                    r="4"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    fillRule="evenodd"
                                                                                                                    clipRule="evenodd"
                                                                                                                    d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                                                    fill="currentColor"
                                                                                                                    fillOpacity="0.5"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M14.2275 11L14.2275 19"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M18.2275 15L10.2275 15"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                            </svg>
                                                                                                        </a>
                                                                                                    </DropdownToggle>
                                                                                                    <DropdownMenu
                                                                                                        className="dropdown-menu"
                                                                                                        right
                                                                                                    >
                                                                                                        {this.state.fundDetailsList?.fundTeamUsers?.map(
                                                                                                            (
                                                                                                                users
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    users.isSubmitter ==
                                                                                                                    1
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <DropdownItem
                                                                                                                            tag="a"
                                                                                                                            className="priority-dropdown"
                                                                                                                            active={this.fundTaskUsers(
                                                                                                                                task.fundTaskUsers,
                                                                                                                                users?.userId,
                                                                                                                                'submitter'
                                                                                                                            )}
                                                                                                                            onClick={() => {
                                                                                                                                this.userUpdate(
                                                                                                                                    users?.userId,
                                                                                                                                    task,
                                                                                                                                    'task',
                                                                                                                                    'submitter'
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {
                                                                                                                                users
                                                                                                                                    ?.user
                                                                                                                                    ?.fullName
                                                                                                                            }
                                                                                                                        </DropdownItem>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </DropdownMenu>
                                                                                                </Dropdown>
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="assigne-group-box reviewer">
                                                                                            {task.fundTaskUsers.map((user, newUserKey) => {
                                                                                                let userFromTeam = this.state.fundDetailsList?.fundTeamUsers?.find((opt) =>
                                                                                                    opt.userId == user.userId && user.userType === PROJECT_TASK_REVIEWER
                                                                                                );
                                                                                                if (userFromTeam) {
                                                                                                    return (
                                                                                                        <span className="assigne-box green-round" key={newUserKey} title={__t(userFromTeam?.user?.fullName)}>
                                                                                                            <p key={newUserKey}>{userFromTeam?.user?.fullName ? getInitials(userFromTeam?.user?.fullName) : ''}</p>
                                                                                                        </span>
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                            )}
                                                                                            {checkTaskEditEnable(task) === true && (
                                                                                                <Dropdown
                                                                                                    isOpen={
                                                                                                        this
                                                                                                            .state
                                                                                                            .taskUser1ToggleId ===
                                                                                                        task.id
                                                                                                    }
                                                                                                    toggle={() =>
                                                                                                        this.taskUsers1Toggle(
                                                                                                            task.id
                                                                                                        )
                                                                                                    }
                                                                                                    className="notification-list list-inline-item mr-1"
                                                                                                    tag="li"
                                                                                                >
                                                                                                    <DropdownToggle
                                                                                                        className="arrow-none waves-effect"
                                                                                                        tag="a"
                                                                                                    >
                                                                                                        <a
                                                                                                            href={() => false}
                                                                                                            title={__t(
                                                                                                                'form_labels.funds.reviewer'
                                                                                                            )}
                                                                                                            className="assigne-box assign-dropdown"
                                                                                                        >
                                                                                                            <svg
                                                                                                                width="19"
                                                                                                                height="20"
                                                                                                                viewBox="0 0 19 20"
                                                                                                                fill="none"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                            >
                                                                                                                <circle
                                                                                                                    cx="8.22754"
                                                                                                                    cy="5"
                                                                                                                    r="4"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    fillRule="evenodd"
                                                                                                                    clipRule="evenodd"
                                                                                                                    d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                                                    fill="currentColor"
                                                                                                                    fillOpacity="0.5"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M14.2275 11L14.2275 19"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M18.2275 15L10.2275 15"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                            </svg>
                                                                                                        </a>
                                                                                                    </DropdownToggle>
                                                                                                    <DropdownMenu
                                                                                                        className="dropdown-menu"
                                                                                                        right
                                                                                                    >
                                                                                                        {this.state.fundDetailsList?.fundTeamUsers?.map(
                                                                                                            (
                                                                                                                users
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    users.isReviewer ==
                                                                                                                    1
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <DropdownItem
                                                                                                                            tag="a"
                                                                                                                            className="priority-dropdown"
                                                                                                                            active={this.fundTaskUsers(
                                                                                                                                task.fundTaskUsers,
                                                                                                                                users?.userId,
                                                                                                                                'reviewer'
                                                                                                                            )}
                                                                                                                            onClick={() => {
                                                                                                                                this.userUpdate(
                                                                                                                                    users?.userId,
                                                                                                                                    task,
                                                                                                                                    'task',
                                                                                                                                    'reviewer'
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {
                                                                                                                                users
                                                                                                                                    ?.user
                                                                                                                                    ?.fullName
                                                                                                                            }
                                                                                                                        </DropdownItem>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </DropdownMenu>
                                                                                                </Dropdown>
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {/* 222 */}
                                                                                        <div className="assigne-group-box reviewer">
                                                                                            {task.fundTaskUsers.map((user, newUserKey) => {
                                                                                                let userFromTeam = this.state.fundDetailsList?.fundTeamUsers?.find(
                                                                                                    (
                                                                                                        opt
                                                                                                    ) =>
                                                                                                        opt.userId ==
                                                                                                        user.userId &&
                                                                                                        user.userType === PROJECT_TASK_APPROVER
                                                                                                );
                                                                                                if (userFromTeam) {
                                                                                                    return (
                                                                                                        <span className="assigne-box green-round" key={newUserKey} title={__t(userFromTeam?.user?.fullName)}>
                                                                                                            <p key={newUserKey}>{userFromTeam?.user?.fullName ? getInitials(userFromTeam?.user?.fullName) : ''}</p>
                                                                                                        </span>
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                            )}
                                                                                            {checkTaskEditEnable(task) === true && (
                                                                                                <Dropdown
                                                                                                    isOpen={
                                                                                                        this.state.taskUserApproverToggle ===
                                                                                                        task.id
                                                                                                    }
                                                                                                    toggle={() =>
                                                                                                        this.taskUsersApproverToggle(
                                                                                                            task.id
                                                                                                        )
                                                                                                    }
                                                                                                    className="notification-list list-inline-item mr-1"
                                                                                                    tag="li"
                                                                                                >
                                                                                                    <DropdownToggle
                                                                                                        className="arrow-none waves-effect"
                                                                                                        tag="a"
                                                                                                    >
                                                                                                        <a
                                                                                                            href={() => false}
                                                                                                            title={__t(
                                                                                                                'form_labels.funds.approver'
                                                                                                            )}
                                                                                                            className="assigne-box assign-dropdown"
                                                                                                        >
                                                                                                            <svg
                                                                                                                width="19"
                                                                                                                height="20"
                                                                                                                viewBox="0 0 19 20"
                                                                                                                fill="none"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                            >
                                                                                                                <circle
                                                                                                                    cx="8.22754"
                                                                                                                    cy="5"
                                                                                                                    r="4"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    fillRule="evenodd"
                                                                                                                    clipRule="evenodd"
                                                                                                                    d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                                                    fill="currentColor"
                                                                                                                    fillOpacity="0.5"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M14.2275 11L14.2275 19"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M18.2275 15L10.2275 15"
                                                                                                                    stroke="currentColor"
                                                                                                                    strokeOpacity="1.5"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                            </svg>
                                                                                                        </a>
                                                                                                    </DropdownToggle>
                                                                                                    <DropdownMenu
                                                                                                        className="dropdown-menu"
                                                                                                        right
                                                                                                    >
                                                                                                        {this.state.fundDetailsList?.fundTeamUsers?.map(
                                                                                                            (
                                                                                                                users
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    users.isApprover ==
                                                                                                                    1
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <DropdownItem
                                                                                                                            tag="a"
                                                                                                                            className="priority-dropdown"
                                                                                                                            active={this.fundTaskUsers(
                                                                                                                                task.fundTaskUsers,
                                                                                                                                users?.userId,
                                                                                                                                'approver'
                                                                                                                            )}
                                                                                                                            onClick={() => {
                                                                                                                                this.userUpdate(
                                                                                                                                    users?.userId,
                                                                                                                                    task,
                                                                                                                                    'task',
                                                                                                                                    'approver'
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {
                                                                                                                                users
                                                                                                                                    ?.user
                                                                                                                                    ?.fullName
                                                                                                                            }
                                                                                                                        </DropdownItem>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </DropdownMenu>
                                                                                                </Dropdown>
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            task?.startDate !=
                                                                                                ''
                                                                                                ? 'projectdetail-date-picker'
                                                                                                : 'projectdetail-date-icon'
                                                                                        }
                                                                                    >
                                                                                        <DatePicker
                                                                                            selected={
                                                                                                task?.startDate
                                                                                                    ? getDateObjectWithValueFromMoment(
                                                                                                        task?.startDate
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                            dateFormat={
                                                                                                DATE_FORMAT_DATEPICKER
                                                                                            }
                                                                                            onChange={(
                                                                                                date
                                                                                            ) =>
                                                                                                this.onChangeDateInput(
                                                                                                    date,
                                                                                                    task,
                                                                                                    'startDate',
                                                                                                    'task'
                                                                                                )
                                                                                            }
                                                                                            maxDate={getDateObjectWithValueFromMoment(
                                                                                                task?.dueDate
                                                                                            )}
                                                                                            onFocus={(e) =>
                                                                                                e.target.blur()
                                                                                            }

                                                                                        />
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            task?.dueDate !=
                                                                                                ''
                                                                                                ? 'projectdetail-date-picker'
                                                                                                : 'projectdetail-date-icon'
                                                                                        }
                                                                                    >
                                                                                        <DatePicker
                                                                                            selected={
                                                                                                task?.dueDate
                                                                                                    ? getDateObjectWithValueFromMoment(
                                                                                                        task?.dueDate
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                            dateFormat={
                                                                                                DATE_FORMAT_DATEPICKER
                                                                                            }
                                                                                            onChange={(
                                                                                                date
                                                                                            ) =>
                                                                                                this.onChangeDateInput(
                                                                                                    date,
                                                                                                    task,
                                                                                                    'dueDate',
                                                                                                    'task'
                                                                                                )
                                                                                            }
                                                                                            minDate={getDateObjectWithValueFromMoment(
                                                                                                task?.startDate
                                                                                            )}
                                                                                            onFocus={(e) =>
                                                                                                e.target.blur()
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td className="action-box">
                                                                                        <div className="medium-priority">
                                                                                            <Dropdown
                                                                                                isOpen={
                                                                                                    this
                                                                                                        .state
                                                                                                        .priorityId ===
                                                                                                    task.id
                                                                                                }
                                                                                                toggle={() =>
                                                                                                    this.priorityToggle(
                                                                                                        task.id
                                                                                                    )
                                                                                                }
                                                                                                className="notification-list list-inline-item mr-1"
                                                                                                tag="li"
                                                                                            >
                                                                                                <DropdownToggle
                                                                                                    className="nav-link arrow-none waves-effect"
                                                                                                    tag="a"
                                                                                                    title={__t(
                                                                                                        task.priority
                                                                                                    )}
                                                                                                >
                                                                                                    <svg
                                                                                                        color={this.priorityColor(
                                                                                                            task.priority
                                                                                                        )}
                                                                                                        width="16"
                                                                                                        height="20"
                                                                                                        viewBox="0 0 16 20"
                                                                                                        fill={this.priorityColor(
                                                                                                            task.priority
                                                                                                        )}
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                    >
                                                                                                        <path
                                                                                                            d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                                                            stroke="currentColor"
                                                                                                            strokeOpacity="0.5"
                                                                                                            strokeWidth="2"
                                                                                                            strokeLinecap="round"
                                                                                                        />
                                                                                                    </svg>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu
                                                                                                    className="dropdown-menu"
                                                                                                    right
                                                                                                >
                                                                                                    {taskPriorityStatusList.map(
                                                                                                        (
                                                                                                            priority,
                                                                                                            priorityKey
                                                                                                        ) => (
                                                                                                            <DropdownItem
                                                                                                                key={
                                                                                                                    priorityKey
                                                                                                                }
                                                                                                                tag="a"
                                                                                                                className="text-primary notify-all"
                                                                                                                active={
                                                                                                                    priority.value ===
                                                                                                                    task.priority
                                                                                                                }
                                                                                                                onClick={() =>
                                                                                                                    this.priorityUpdate(
                                                                                                                        task,
                                                                                                                        priority.value
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <svg
                                                                                                                    color={this.priorityColor(
                                                                                                                        priority.value
                                                                                                                    )}
                                                                                                                    width="16"
                                                                                                                    height="20"
                                                                                                                    viewBox="0 0 16 20"
                                                                                                                    fill="currentColor"
                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                >
                                                                                                                    <path
                                                                                                                        d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                                                                        stroke="currentColor"
                                                                                                                        strokeOpacity="0.5"
                                                                                                                        strokeWidth="2"
                                                                                                                        strokeLinecap="round"
                                                                                                                    />
                                                                                                                </svg>
                                                                                                                {' ' +
                                                                                                                    priority.label}
                                                                                                            </DropdownItem>
                                                                                                        )
                                                                                                    )}
                                                                                                </DropdownMenu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                {this.state.collapseEntityId === task.id && (
                                                                                    <tr className={'collapes-tr ' + this.fundStatusBox(task?.fundStatusName)}>
                                                                                        <td colSpan={9}>
                                                                                            <table className="w-100 tab-color-box">
                                                                                                {
                                                                                                    task?.fundSubTasks?.map(
                                                                                                        (subTask, i) => {
                                                                                                            return (

                                                                                                                <tr key={i}>
                                                                                                                    <td
                                                                                                                        className={
                                                                                                                            'inner-task-box text-left sub-task-title ' +
                                                                                                                            this.fundStatusBox(
                                                                                                                                subTask?.fundStatusName
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <div className="common-flex-box">
                                                                                                                            <span className="project-list-status-box"></span>
                                                                                                                            <p>
                                                                                                                                {
                                                                                                                                    subTask?.taskTitle
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                            <ul>
                                                                                                                                {subTask.isOverDue ===
                                                                                                                                    true && (
                                                                                                                                        <li>
                                                                                                                                            <a
                                                                                                                                                href={() => false}
                                                                                                                                                title={__t(
                                                                                                                                                    'form_labels.funds.overDueActivity'
                                                                                                                                                )}
                                                                                                                                                onClick={() =>
                                                                                                                                                    this.editTask(
                                                                                                                                                        subTask,
                                                                                                                                                        'subTask',
                                                                                                                                                        false,
                                                                                                                                                        '',
                                                                                                                                                        task.fundTaskUsers
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                className='text-danger'
                                                                                                                                            >
                                                                                                                                                <svg width="19" height="18" style={{ width: '1rem', height: '1rem' }} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                                                    <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
                                                                                                                                                    <path d="M8 4.5L8 8.1875C8 8.32557 7.88807 8.4375 7.75 8.4375L4.9375 8.4375" stroke="currentColor" strokeLinecap="round" />
                                                                                                                                                    <path d="M13.5 17.5C15.975 17.5 18 15.475 18 13C18 10.525 15.975 8.5 13.5 8.5C11.025 8.5 9 10.525 9 13C9 15.475 11.025 17.5 13.5 17.5Z" fill="currentColor" opacity="0.8" stroke="currentColor" stroke-width="0.9" strokeLinecap="round" stroke-linejoin="round" />
                                                                                                                                                    <path d="M13.5 11.2V13.45" stroke="#ffffff" stroke-width="1" strokeLinecap="round" stroke-linejoin="round" />
                                                                                                                                                    <path d="M13.4974 14.8H13.5014" stroke="#ffffff" strokeLinecap="round" stroke-linejoin="round" />
                                                                                                                                                </svg>
                                                                                                                                            </a>
                                                                                                                                        </li>
                                                                                                                                    )}
                                                                                                                                {
                                                                                                                                    checkIsTaskDraft(subTask) && (
                                                                                                                                        <small className='task-draft-icon'>
                                                                                                                                            <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 18.2501C4 17.8358 4.33579 17.5001 4.75 17.5001H12.75C13.1642 17.5001 13.5 17.8358 13.5 18.2501C13.5 18.6643 13.1642 19.0001 12.75 19.0001H4.75C4.33579 19.0001 4 18.6643 4 18.2501Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M4 20.7501C4 20.3358 4.33579 20.0001 4.75 20.0001C5.16421 20.0001 10.25 20.0001 10.25 20.0001C10.6642 20.0001 11 20.3358 11 20.7501C11 21.1643 10.6642 21.5001 10.25 21.5001H4.75C4.33579 21.5001 4 21.1643 4 20.7501Z" fill="currentColor" /><path d="M20.9999 9.925L21 14.5377C21 19.4874 21 21.9623 19.5355 23.5C18.0711 25.0377 15.714 25.0377 11 25.0377C6.28594 25.0377 3.92892 25.0377 2.46446 23.5C0.999989 21.9623 1 17.9497 1 13C1 8.05025 0.999886 4.07538 2.46435 2.53769C3.92882 1 6.28584 1 10.9999 1H12.4999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M15.652 2.45506L16.3009 1.80624C17.3759 0.731252 19.1188 0.731252 20.1938 1.80624C21.2687 2.88124 21.2687 4.62415 20.1938 5.69914L19.5449 6.34795M15.652 2.45506C15.652 2.45506 15.7331 3.83379 16.9497 5.05032C18.1662 6.26685 19.5449 6.34795 19.5449 6.34795M15.652 2.45506L9.68718 8.41993C9.28316 8.82394 9.08116 9.02595 8.90743 9.24868C8.70249 9.51143 8.52679 9.79572 8.38344 10.0965C8.26191 10.3515 8.17157 10.6225 7.99089 11.1646L7.41242 12.9M19.5449 6.34795L13.5801 12.3128C13.1761 12.7168 12.9741 12.9188 12.7513 13.0926C12.4886 13.2975 12.2043 13.4732 11.9035 13.6166C11.6485 13.7381 11.3775 13.8284 10.8354 14.0091L9.1 14.5876M9.1 14.5876L7.97709 14.9619C7.71035 15.0508 7.41626 14.9814 7.21744 14.7826C7.01862 14.5837 6.9492 14.2897 7.03811 14.0229L7.41242 12.9M9.1 14.5876L7.41242 12.9" stroke="currentColor" stroke-width="1.5" /></svg>
                                                                                                                                        </small>
                                                                                                                                    )
                                                                                                                                }

                                                                                                                            </ul>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <a
                                                                                                                            class="text-nowrap btn btn-link p-0"
                                                                                                                            href={() => false}
                                                                                                                            title="Edit"
                                                                                                                            onClick={() =>
                                                                                                                                this.editTask(subTask, 'subTask', false, '')
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {subTask.code}
                                                                                                                        </a>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <span className="text-nowrap">
                                                                                                                            {
                                                                                                                                subTask?.fundStatusName
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                    <td className="sub-task-submitter">
                                                                                                                        <div className="assigne-group-box">
                                                                                                                            {subTask?.fundTaskUsers?.map(
                                                                                                                                (
                                                                                                                                    user
                                                                                                                                ) => {
                                                                                                                                    let userFromTeam = this.state.fundDetailsList?.fundTeamUsers?.find((opt) =>
                                                                                                                                        opt.userId == user.userId && opt.isSubmitter == 1 && user.userType === PROJECT_TASK_SUBMITTER
                                                                                                                                    );
                                                                                                                                    if (userFromTeam) {
                                                                                                                                        return (
                                                                                                                                            <span className="assigne-box green-round" key={userKey} title={__t(userFromTeam?.user?.fullName)}>
                                                                                                                                                <p key={userKey}>
                                                                                                                                                    {userFromTeam?.user?.fullName ? getInitials(userFromTeam?.user?.fullName) : ''}
                                                                                                                                                </p>
                                                                                                                                            </span>
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            )}
                                                                                                                            <Dropdown
                                                                                                                                isOpen={this.state.taskUserToggleId === (subTask.id + "Submitter")}
                                                                                                                                toggle={() => this.taskUsersToggle(subTask.id + 'Submitter', 'subTask', task.fundTaskUsers, subTask?.fundTaskUsers, taskSubmitters)}
                                                                                                                                className="notification-list list-inline-item mr-1" tag="li"                                                                                                                                                    >
                                                                                                                                <DropdownToggle
                                                                                                                                    className="arrow-none waves-effect"
                                                                                                                                    tag="a"
                                                                                                                                >
                                                                                                                                    <a
                                                                                                                                        href={() => false}
                                                                                                                                        title={__t(
                                                                                                                                            'form_labels.funds.submitter'
                                                                                                                                        )}
                                                                                                                                        className="assigne-box assign-dropdown"
                                                                                                                                    >
                                                                                                                                        <svg
                                                                                                                                            width="19"
                                                                                                                                            height="20"
                                                                                                                                            viewBox="0 0 19 20"
                                                                                                                                            fill="none"
                                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        >
                                                                                                                                            <circle
                                                                                                                                                cx="8.22754"
                                                                                                                                                cy="5"
                                                                                                                                                r="4"
                                                                                                                                                stroke="currentColor"
                                                                                                                                                strokeOpacity="1.5"
                                                                                                                                                strokeLinecap="round"
                                                                                                                                            />
                                                                                                                                            <path
                                                                                                                                                fillRule="evenodd"
                                                                                                                                                clipRule="evenodd"
                                                                                                                                                d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                                                                                fill="currentColor"
                                                                                                                                                fillOpacity="0.5"
                                                                                                                                            />
                                                                                                                                            <path
                                                                                                                                                d="M14.2275 11L14.2275 19"
                                                                                                                                                stroke="currentColor"
                                                                                                                                                strokeOpacity="1.5"
                                                                                                                                                strokeLinecap="round"
                                                                                                                                            />
                                                                                                                                            <path
                                                                                                                                                d="M18.2275 15L10.2275 15"
                                                                                                                                                stroke="currentColor"
                                                                                                                                                strokeOpacity="1.5"
                                                                                                                                                strokeLinecap="round"
                                                                                                                                            />
                                                                                                                                        </svg>
                                                                                                                                    </a>
                                                                                                                                </DropdownToggle>
                                                                                                                                <DropdownMenu
                                                                                                                                    className="dropdown-menu"
                                                                                                                                    right
                                                                                                                                >
                                                                                                                                    {this.state.fundDetailsList?.fundTeamUsers?.map((users) => {
                                                                                                                                        let taskUserIds = task.fundTaskUsers.map((x) => x.userId);
                                                                                                                                        if (taskUserIds.includes(users.userId) && users.isSubmitter) {
                                                                                                                                            return (
                                                                                                                                                <DropdownItem
                                                                                                                                                    tag="a"
                                                                                                                                                    className="priority-dropdown"
                                                                                                                                                    active={this.fundTaskUsers(
                                                                                                                                                        subTask.fundTaskUsers,
                                                                                                                                                        users?.userId,
                                                                                                                                                        'submitter'
                                                                                                                                                    )}
                                                                                                                                                    onClick={() => {
                                                                                                                                                        this.userUpdate(
                                                                                                                                                            users?.userId,
                                                                                                                                                            subTask,
                                                                                                                                                            'subTask',
                                                                                                                                                            'submitter',
                                                                                                                                                            task.fundTaskUsers
                                                                                                                                                        );
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    {
                                                                                                                                                        users
                                                                                                                                                            ?.user
                                                                                                                                                            ?.fullName
                                                                                                                                                    }
                                                                                                                                                </DropdownItem>
                                                                                                                                            );
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    )}
                                                                                                                                </DropdownMenu>
                                                                                                                            </Dropdown>
                                                                                                                        </div>

                                                                                                                    </td>
                                                                                                                    <td className="sub-task-submitter">
                                                                                                                        <div className="assigne-group-box">
                                                                                                                            {subTask?.fundTaskUsers?.map(
                                                                                                                                (
                                                                                                                                    user
                                                                                                                                ) => {
                                                                                                                                    let userFromTeam = this.state.fundDetailsList?.fundTeamUsers?.find((opt) => opt.userId == user.userId && opt.isReviewer == 1 && user.userType === PROJECT_TASK_REVIEWER);
                                                                                                                                    if (userFromTeam) {
                                                                                                                                        return (
                                                                                                                                            <span className="assigne-box green-round" key={userKey} title={__t(userFromTeam?.user?.fullName)}>
                                                                                                                                                <p key={userKey}>
                                                                                                                                                    {userFromTeam?.user?.fullName ? getInitials(userFromTeam?.user?.fullName) : ''}
                                                                                                                                                </p>
                                                                                                                                            </span>
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            )}
                                                                                                                            <Dropdown
                                                                                                                                isOpen={
                                                                                                                                    this
                                                                                                                                        .state
                                                                                                                                        .taskUserToggleId ===
                                                                                                                                    (subTask.id + "Reviewer")
                                                                                                                                }
                                                                                                                                toggle={() =>
                                                                                                                                    this.taskUsersToggle(
                                                                                                                                        subTask.id + "Reviewer",
                                                                                                                                        'subTask',
                                                                                                                                        task.fundTaskUsers,
                                                                                                                                        subTask?.fundTaskUsers,
                                                                                                                                        taskSubmitters
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                className="notification-list list-inline-item mr-1"
                                                                                                                                tag="li"
                                                                                                                            >
                                                                                                                                <DropdownToggle
                                                                                                                                    className="arrow-none waves-effect"
                                                                                                                                    tag="a"
                                                                                                                                >
                                                                                                                                    <a
                                                                                                                                        href={() => false}
                                                                                                                                        title={__t(
                                                                                                                                            'form_labels.funds.reviewer'
                                                                                                                                        )}
                                                                                                                                        className="assigne-box assign-dropdown"
                                                                                                                                    >
                                                                                                                                        <svg
                                                                                                                                            width="19"
                                                                                                                                            height="20"
                                                                                                                                            viewBox="0 0 19 20"
                                                                                                                                            fill="none"
                                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        >
                                                                                                                                            <circle
                                                                                                                                                cx="8.22754"
                                                                                                                                                cy="5"
                                                                                                                                                r="4"
                                                                                                                                                stroke="currentColor"
                                                                                                                                                strokeOpacity="1.5"
                                                                                                                                                strokeLinecap="round"
                                                                                                                                            />
                                                                                                                                            <path
                                                                                                                                                fillRule="evenodd"
                                                                                                                                                clipRule="evenodd"
                                                                                                                                                d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                                                                                fill="currentColor"
                                                                                                                                                fillOpacity="0.5"
                                                                                                                                            />
                                                                                                                                            <path
                                                                                                                                                d="M14.2275 11L14.2275 19"
                                                                                                                                                stroke="currentColor"
                                                                                                                                                strokeOpacity="1.5"
                                                                                                                                                strokeLinecap="round"
                                                                                                                                            />
                                                                                                                                            <path
                                                                                                                                                d="M18.2275 15L10.2275 15"
                                                                                                                                                stroke="currentColor"
                                                                                                                                                strokeOpacity="1.5"
                                                                                                                                                strokeLinecap="round"
                                                                                                                                            />
                                                                                                                                        </svg>
                                                                                                                                    </a>
                                                                                                                                </DropdownToggle>
                                                                                                                                <DropdownMenu
                                                                                                                                    className="dropdown-menu"
                                                                                                                                    right
                                                                                                                                >
                                                                                                                                    {this.state.fundDetailsList?.fundTeamUsers?.map(
                                                                                                                                        (
                                                                                                                                            users
                                                                                                                                        ) => {
                                                                                                                                            let taskUserIds =
                                                                                                                                                task.fundTaskUsers.map(
                                                                                                                                                    (
                                                                                                                                                        x
                                                                                                                                                    ) =>
                                                                                                                                                        x.userId
                                                                                                                                                );
                                                                                                                                            if (
                                                                                                                                                taskUserIds.includes(
                                                                                                                                                    users.userId
                                                                                                                                                ) && users.isReviewer
                                                                                                                                            ) {
                                                                                                                                                return (
                                                                                                                                                    <DropdownItem
                                                                                                                                                        tag="a"
                                                                                                                                                        className="priority-dropdown"
                                                                                                                                                        active={this.fundTaskUsers(
                                                                                                                                                            subTask.fundTaskUsers,
                                                                                                                                                            users?.userId,
                                                                                                                                                            'reviewer'
                                                                                                                                                        )}
                                                                                                                                                        onClick={() => {
                                                                                                                                                            this.userUpdate(
                                                                                                                                                                users?.userId,
                                                                                                                                                                subTask,
                                                                                                                                                                'subTask',
                                                                                                                                                                'reviewer',
                                                                                                                                                                task.fundTaskUsers
                                                                                                                                                            );
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        {
                                                                                                                                                            users
                                                                                                                                                                ?.user
                                                                                                                                                                ?.fullName
                                                                                                                                                        }
                                                                                                                                                    </DropdownItem>
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    )}
                                                                                                                                </DropdownMenu>
                                                                                                                            </Dropdown>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <span className='false_space'></span>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        className={
                                                                                                                            subTask?.startDate !=
                                                                                                                                ''
                                                                                                                                ? 'projectdetail-date-picker'
                                                                                                                                : 'projectdetail-date-icon'
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <DatePicker
                                                                                                                            selected={
                                                                                                                                subTask?.startDate
                                                                                                                                    ? getDateObjectWithValueFromMoment(
                                                                                                                                        subTask?.startDate
                                                                                                                                    )
                                                                                                                                    : null
                                                                                                                            }
                                                                                                                            dateFormat={
                                                                                                                                DATE_FORMAT_DATEPICKER
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                date
                                                                                                                            ) =>
                                                                                                                                this.onChangeDateInput(
                                                                                                                                    date,
                                                                                                                                    subTask,
                                                                                                                                    'startDate',
                                                                                                                                    'subTask',
                                                                                                                                    task.fundTaskUsers,
                                                                                                                                    subTask?.fundTaskUsers,
                                                                                                                                    taskSubmitters
                                                                                                                                )
                                                                                                                            }
                                                                                                                            maxDate={getDateObjectWithValueFromMoment(
                                                                                                                                subTask?.dueDate
                                                                                                                            )}
                                                                                                                            onFocus={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                e.target.blur()
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        className={
                                                                                                                            subTask?.dueDate !=
                                                                                                                                ''
                                                                                                                                ? 'projectdetail-date-picker'
                                                                                                                                : 'projectdetail-date-icon'
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <DatePicker
                                                                                                                            selected={
                                                                                                                                subTask?.dueDate
                                                                                                                                    ? getDateObjectWithValueFromMoment(
                                                                                                                                        subTask?.dueDate
                                                                                                                                    )
                                                                                                                                    : null
                                                                                                                            }
                                                                                                                            dateFormat={
                                                                                                                                DATE_FORMAT_DATEPICKER
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                date
                                                                                                                            ) =>
                                                                                                                                this.onChangeDateInput(
                                                                                                                                    date,
                                                                                                                                    subTask,
                                                                                                                                    'dueDate',
                                                                                                                                    'subTask',
                                                                                                                                    task.fundTaskUsers,
                                                                                                                                    subTask?.fundTaskUsers,
                                                                                                                                    taskSubmitters
                                                                                                                                )
                                                                                                                            }
                                                                                                                            minDate={getDateObjectWithValueFromMoment(
                                                                                                                                subTask?.startDate
                                                                                                                            )}
                                                                                                                            onFocus={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                e.target.blur()
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                    <td className="action-box">
                                                                                                                        <div className="medium-priority">
                                                                                                                            <Dropdown
                                                                                                                                isOpen={
                                                                                                                                    this
                                                                                                                                        .state
                                                                                                                                        .priorityId ===
                                                                                                                                    subTask.id
                                                                                                                                }
                                                                                                                                toggle={() =>
                                                                                                                                    this.priorityToggle(
                                                                                                                                        subTask.id
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                className="notification-list list-inline-item mr-1"
                                                                                                                                tag="li"
                                                                                                                            >
                                                                                                                                <DropdownToggle
                                                                                                                                    className="nav-link arrow-none waves-effect"
                                                                                                                                    tag="a"
                                                                                                                                    title={__t(
                                                                                                                                        subTask?.priority
                                                                                                                                    )}
                                                                                                                                >
                                                                                                                                    <svg
                                                                                                                                        color={this.priorityColor(
                                                                                                                                            subTask?.priority
                                                                                                                                        )}
                                                                                                                                        width="16"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 16 20"
                                                                                                                                        fill="currentColor"
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                                                                                            stroke="currentColor"
                                                                                                                                            strokeOpacity="0.5"
                                                                                                                                            strokeWidth="2"
                                                                                                                                            strokeLinecap="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </DropdownToggle>
                                                                                                                                <DropdownMenu
                                                                                                                                    className="dropdown-menu"
                                                                                                                                    right
                                                                                                                                >
                                                                                                                                    {taskPriorityStatusList.map(
                                                                                                                                        (
                                                                                                                                            priority,
                                                                                                                                            z
                                                                                                                                        ) => (
                                                                                                                                            <DropdownItem
                                                                                                                                                key={
                                                                                                                                                    z
                                                                                                                                                }
                                                                                                                                                tag="a"
                                                                                                                                                className="text-primary notify-all"
                                                                                                                                                active={
                                                                                                                                                    priority.value ===
                                                                                                                                                    subTask?.priority
                                                                                                                                                }
                                                                                                                                                onClick={() =>
                                                                                                                                                    this.priorityUpdate(
                                                                                                                                                        subTask,
                                                                                                                                                        priority.value
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <svg
                                                                                                                                                    color={this.priorityColor(
                                                                                                                                                        priority.value
                                                                                                                                                    )}
                                                                                                                                                    width="16"
                                                                                                                                                    height="20"
                                                                                                                                                    viewBox="0 0 16 20"
                                                                                                                                                    fill="currentColor"
                                                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                >
                                                                                                                                                    <path
                                                                                                                                                        d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                                                                                                        stroke="currentColor"
                                                                                                                                                        strokeOpacity="0.5"
                                                                                                                                                        strokeWidth="2"
                                                                                                                                                        strokeLinecap="round"
                                                                                                                                                    />
                                                                                                                                                </svg>
                                                                                                                                                {' ' +
                                                                                                                                                    priority.label}
                                                                                                                                            </DropdownItem>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </DropdownMenu>
                                                                                                                            </Dropdown>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })}
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        }
                                                    )}
                                                </table>
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                { }
                <AddEditFundTaskModal
                    isModalOpen={this.state.taskModal}
                    onModalDismiss={() => this.closeTaskModal()}
                    modalName={addEditTaskAndSubTaskModal}
                    fundDetails={this.state.fundDetailsList}
                    operationType={this.state.operationType}
                    taskId={this.state.taskId}
                    selectedEntityId={this.state.selectedEntityId}
                    selectedFundMasterId={this.state.selectedFundMasterId}
                    addNewTask={this.state.addNewTask}
                    taskType={this.state.taskType}
                    addSubtask={this.state.addSubtask}
                    fundEntityName={fundEntityName}
                    activeTab={this.state.activeTabForActionButton}
                    fundTeamUsers={this.state.fundDetailsList?.fundTeamUsers}
                    fundOwnerUserIds={fundOwnerUserIds}
                    fundManagementUserIds={fundManagementUserIds}
                />
                <TaskActivityLog
                    modalName={addEditTaskAndSubTaskModal}
                    isModalOpen={this.state.taskActivityLogModal}
                    onModalDismiss={() => this.closeTaskActivityLogModal()}
                    taskId={this.state.activityTaskId}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    fundDetailsApiResponse: makeFundsSelectField('editFundApiResponse'),
    fundTaskApiResponse: makeFundsSelectField('fundTaskApiResponse'),
    fundTaskList: makeFundsSelectField('fundTaskList'),
    fundSubTaskApiResponse: makeFundsSelectField('fundSubTaskApiResponse'),
    updateFundTaskSequenceOrderApiResponse: makeFundsSelectField('updateFundTaskSequenceOrderApiResponse'),
    fundMasterList: makeFundMasterSelectField('fundMasterList')
});
const withConnect = connect(mapStatetoProps, {
    addFundRequest,
    getFundDetailsRequest,
    updateFundRequest,
    getUserListByStatusRequest,
    getFundTaskUsersByEntityRequest,
    getFundTaskListRequest,
    deleteFundTaskByTaskidRequest,
    updateFundTaskRequest,
    updateFundTaskSequenceOrderRequest,
    getFundMasterListByStatusRequest
});
export default compose(
    withConnect,
    withUserReducer,
    withUserSaga,
    withFundsReducer,
    withFundsSaga,
    withFundMasterReducer,
    withFundMasterSaga,
    withRouter
)(FundDetailsView);
