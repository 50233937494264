import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.section || initialState;
const makeSectionSelectField = field =>
    createSelector(reducerState, sectionState => sectionState[field]);
const selectSortedSectionList = () =>
    createSelector(makeSectionSelectField('sectionList'), sectionList =>
        sectionList.map(opt => ({
            value: opt.id,
            label: opt.sectionName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeSectionSelectField,
    selectSortedSectionList
};
