import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label, CustomInput } from 'reactstrap';
import {
    SUCCESS,
    AUTH_MODE_LC,
    AUTH_MODE_SSO,
    UPDATE,
    SUBMIT,
    CANCEL,
    NONE,
} from 'constants/commonConstants';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import {
    customValidPhoneField,
    customValidTextField,
} from 'helpers/customValidators';
import __t from 'i18n/translator';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getEntityListByCompanyGroupIdRequest,
} from 'store/actions';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedEntityList } from 'store/Entity/selector';
import { withRouter } from 'react-router-dom';
import { initialUserDataState } from './CommonFunctions';
import { customValidatorForUserName } from 'helpers/customValidators';
import ReactTooltip from 'react-tooltip';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

class AddUserForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidPhoneField(),
                customValidTextField(),
                customValidatorForUserName()
            ),
        });
        this.state = {
            userData: initialUserDataState(),
            saveContinue: false,
        };
    }

    getEntityListByCompanyGroup(companyGroupId) {
        this.props.getEntityListByCompanyGroupIdRequest(companyGroupId);
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.setState(
                {
                    saveContinue: values.saveContinue ? true : false,
                },
                () => {
                    this.props.onFormValid(values);
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                if (this.state.saveContinue === false) {
                    this.resetForm();
                } else {
                    this.setState(
                        {
                            saveContinue: false,
                        },
                        () => {
                            this.setState({
                                saveContinue: true,
                                userData: {
                                    ...initialUserDataState(),
                                    entityId: this.props.userData.entityId,
                                    companyGroupId:
                                        this.props.userData.companyGroupId,
                                },
                            });
                            this.validator.hideMessages();
                        }
                    );
                }
            }
        }
        if (nextprops.userData !== this.props.userData) {
            if (nextprops.operationType === UPDATE) {
                this.getEntityListByCompanyGroup(
                    nextprops.userData.companyGroupId
                );
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <ReactTooltip place="bottom" />
                <Formik
                    initialValues={Object.assign(
                        {},
                        this.state.saveContinue === false
                            ? this.props.userData
                            : this.state.userData
                    )}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Row>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="companyGroupId"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedCompanyGroupListDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('companyGroupId', selectedValues);
                                            if (selectedValues.includes(NONE)) {
                                                setFieldValue('entityId', [NONE]);
                                            } else {
                                                setFieldValue('entityId', []);
                                            }
                                            this.getEntityListByCompanyGroup(selectedValues);
                                        }}
                                        value={this.props.sortedCompanyGroupListDropdown?.filter(
                                            (option) =>
                                                values.companyGroupId.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti={true}

                                    />

                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.entity.company_group'
                                        )}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.entity.company_group'),
                                        values.companyGroupId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.entity.company_group_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="entityId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'entityId',
                                                selectedValues
                                            );
                                            if (values.companyGroupId.includes(NONE) && !selectedValues.includes(NONE)) {
                                                setFieldValue('companyGroupId', values.companyGroupId.filter((x) => x !== NONE));
                                            }
                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            (option) =>
                                                values.entityId.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti={true}

                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.entity')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.entity'),
                                        values.entityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.entity_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        name="fullName"
                                        className="form-control"
                                        value={values.fullName}
                                        placeholder={__t(
                                            'form_labels.masters.users.user_full_name'
                                        )}
                                        maxLength={100}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.masters.users.user_full_name'
                                        )}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.masters.users.user_full_name'
                                        ),
                                        values.fullName,
                                        'required|alpha_space|max:100',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.users.name_required'
                                                ),
                                                max: __t(
                                                    'validations.masters.users.name_max',
                                                    { max: 100 }
                                                ),
                                                alpha_space: __t(
                                                    'validations.masters.users.name_alpha_space'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        name="userName"
                                        className="form-control"
                                        value={values.userName}
                                        placeholder={__t(
                                            'form_labels.masters.users.user_name'
                                        )}
                                        maxLength={60}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.masters.users.user_name'
                                        )}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.masters.users.user_name'
                                        ),
                                        values.userName,
                                        'required|min:6|max:60|customUserName',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.users.user_name_required'
                                                ),
                                                min: __t(
                                                    'validations.masters.users.user_name_min',
                                                    { min: 6 }
                                                ),
                                                max: __t(
                                                    'validations.masters.users.user_name_max',
                                                    { max: 60 }
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        name="email"
                                        className="form-control"
                                        value={values.email}
                                        placeholder={__t(
                                            'form_labels.masters.users.email'
                                        )}
                                        maxLength={150}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.users.email')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.users.email'),
                                        values.email,
                                        'required|email|max:150',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.users.email_required'
                                                ),
                                                email: __t(
                                                    'validations.masters.users.email_email'
                                                ),
                                                max: __t(
                                                    'validations.masters.users.email_max',
                                                    { max: 150 }
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        name="contactNo"
                                        className="form-control"
                                        value={values.contactNo}
                                        placeholder={__t(
                                            'form_labels.masters.users.contact_no'
                                        )}
                                        maxLength={14}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.masters.users.contact_no'
                                        )}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.masters.users.contact_no'
                                        ),
                                        values.contactNo,
                                        'required|min:7|max:14|customPhone',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.users.contact_no_required'
                                                ),
                                                min: __t(
                                                    'validations.masters.users.contact_no_min',
                                                    { min: 7 }
                                                ),
                                                max: __t(
                                                    'validations.masters.users.contact_no_max',
                                                    { max: 15 }
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>

                                <FormGroup className="col-lg-2 sticky_label radio_btn mb-0">
                                    <div className="form-check form-check-inline">
                                        <Field
                                            className="form-check-input"
                                            type="radio"
                                            title={__t(
                                                'form_labels.masters.users.login_credentials'
                                            )}
                                            name="authenticationMode"
                                            id="authenticationModeLc"
                                            onChange={(opt) => {
                                                if (opt) {
                                                    setFieldValue(
                                                        'authenticationMode',
                                                        AUTH_MODE_LC
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        'authenticationMode',
                                                        AUTH_MODE_SSO
                                                    );
                                                }
                                            }}
                                            value={AUTH_MODE_LC}
                                        />
                                        <Label
                                            className="form-check-label"
                                            for="authenticationModeLc"
                                            data-tip={__t(
                                                'form_labels.masters.users.lc_full'
                                            )}
                                        >
                                            {__t(
                                                'form_labels.masters.users.lc'
                                            )}
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <Field
                                            className="form-check-input"
                                            type="radio"
                                            name="authenticationMode"
                                            id="authenticationModeSso"
                                            onChange={(opt) => {
                                                if (opt) {
                                                    setFieldValue(
                                                        'authenticationMode',
                                                        AUTH_MODE_SSO
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        'authenticationMode',
                                                        AUTH_MODE_LC
                                                    );
                                                }
                                            }}
                                            value={AUTH_MODE_SSO}
                                        />
                                        <Label
                                            className="form-check-label"
                                            for="authenticationModeSso"
                                            data-tip={__t(
                                                'form_labels.masters.users.sso_full'
                                            )}
                                        >
                                            {__t(
                                                'form_labels.masters.users.sso'
                                            )}
                                        </Label>
                                    </div>
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.masters.users.authentication_mode'
                                        )}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col sticky_label radio_btn flex-column-reverse mb-0">
                                    <CustomInput type="checkbox"
                                        id="isGt"
                                        name="isGt"
                                        className='is_user_checkbox'
                                        disabled={this.props.operationType === UPDATE}
                                        onChange={(e) => {
                                            const { checked } =
                                                e.target;

                                            setFieldValue(
                                                'isGt',
                                                checked
                                            );
                                        }}
                                        checked={
                                            values.isGt
                                        }
                                        label={__t(
                                            'form_labels.masters.users.is_gt'
                                        )} />
                                    <Label className="form-label m-0">
                                       &nbsp;&nbsp;
                                    </Label>
                                </FormGroup>
                                <Col
                                    lg="6"
                                    className="d-flex align-items-end justify-content-end"
                                >
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                                name="submit"
                                                value={'submit'}
                                                onClick={() => {
                                                    setFieldValue(
                                                        'submit',
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'saveContinue',
                                                        false
                                                    );
                                                }}
                                            >
                                                {this.props.operationType}
                                            </Button>
                                            {this.props.operationType ===
                                                SUBMIT && (
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                        name="saveContinue"
                                                        value={'saveContinue'}
                                                        onClick={() => {
                                                            setFieldValue(
                                                                'saveContinue',
                                                                true
                                                            );
                                                            setFieldValue(
                                                                'submit',
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        Save & Continue
                                                    </Button>
                                                )}

                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {CANCEL}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedEntityListDropdown: selectSortedEntityList(),
});

const withConnect = connect(mapStatetoProps, {
    getEntityListByCompanyGroupIdRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(AddUserForm);
