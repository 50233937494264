import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import __t from 'i18n/translator';
import {
    Row,
    Col,
    Button,
    Container,
    Input,
    FormGroup,
    Label,
} from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import {
    dateDifference,
    getCurrentDateInFormat,
    getDateFrom,
    getDateFromFormat,
    getFullDateTimeFromDateTimeString,
} from 'helpers/dateTimeHelper';
import {
    DEFAULT_PAGE_LENGTH_FOR_TICKETS,
    SUCCESS,
} from 'constants/commonConstants';
import { listTicketCategoryRequest, listTicketRequest, getTicketCountRequest } from 'store/actions';
import { makeTicketSelectField, selectSortedTicketCategoryList } from 'store/Ticket/selector';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import ticketSaga from 'store/Ticket/saga';
import ticketReducer from 'store/Ticket/reducer';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { TICKET_ALL_TYPE, TICKET_CLOSE, TICKET_IN_PROGRESS, TICKET_MY_TYPE, TICKET_ON_HOLD, TICKET_OPEN, TICKET_PENDING_TYPE, TICKET_UN_RESPONDED_TYPE } from 'constants/databaseConstants';
import ShowMoreText from "react-show-more-text";
import { getTicketStatusNameById, isGTROLE, isGTSuperAdminRole } from 'helpers/projectUtils';
import Select from 'react-select';
import { ticketStatusList } from 'constants/dropdowns';
import { getInitials } from 'helpers/generalUtils';

const ticketKey = 'ticket';
const withTicketReducer = injectReducer({
    key: ticketKey,
    reducer: ticketReducer,
});
const withTicketSaga = injectSaga({ key: ticketKey, saga: ticketSaga });
const initialTicketsFilterDataState = (fromDate = '') => {
    return {
        searchText: '',
        type: 2,
        status: ''
    };
};
class MainTicketsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: initialTicketsFilterDataState(),
            currentPage: 1,
            hasNextPage: false,
            pageSize: DEFAULT_PAGE_LENGTH_FOR_TICKETS,
            ticketsList: [],
            orderByKey: 'createdAt',
            orderByValue: 'desc',
            allCount: 0,
            pendingCount: 0,
            myCount: 0,
            unassignedCount: 0
        };
    }
    componentDidMount() {
        this.props.getTicketCountRequest();
        this.getTicketsByPage(this.state.currentPage);
    }
    getTicketsByPage = (page, searchData = {}) => {
        let filterData = this.state.filters;
        this.setState({
            filters: { ...filterData, ...searchData }
        }, () => {
            this.props.listTicketRequest({
                limit: this.state.pageSize,
                page: page,
                orderBy: {
                    key: this.state.orderByKey,
                    value: this.state.orderByValue,
                },
                filter: this.state.filters,
            });
        })
    };

    getShowMoreTextElement = (element) => {
        return <ShowMoreText
            lines={2}
            more="Show more"
            less="Show less"
            className="show-more-content"
            anchorClass="show-more-link"
            expandByClick={true}
            expanded={false}
            keepNewLines={true}
        >{`` + element + '\n'}</ShowMoreText>
    }
    formSubmit = (values) => {
        this.setState(
            {
                filters: values,
            },
            () => {
                this.getTicketsByPage(1, this.state.filters);
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                filters: initialTicketsFilterDataState(),
            },
            () => {
                this.getTicketsByPage(1, this.state.filters);
            }
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.ticketApiResponse &&
            nextprops.ticketApiResponse !== this.props.ticketApiResponse
        ) {
            if (nextprops.ticketApiResponse.responseType === SUCCESS) {
                this.setState({
                    currentPage: nextprops.ticketApiResponse.data.currentPage,
                    ticketsList:
                        nextprops.ticketApiResponse.data.currentPage == 1
                            ? nextprops.ticketApiResponse.data.list
                            : [
                                ...this.state.ticketsList,
                                ...nextprops.ticketApiResponse.data.list,
                            ],
                    hasNextPage: nextprops.ticketApiResponse.data.hasNextPage,
                });
            }
        }
        if (
            nextprops.ticketCountApiResponse &&
            nextprops.ticketCountApiResponse !== this.props.ticketCountApiResponse
        ) {
            if (nextprops.ticketCountApiResponse.responseType === SUCCESS) {
                this.setState({
                    allCount: nextprops.ticketCountApiResponse.data.ticketCount?.allCount,
                    pendingCount: nextprops.ticketCountApiResponse.data.ticketCount?.pendingCount,
                    myCount: nextprops.ticketCountApiResponse.data.ticketCount?.myCount,
                    unassignedCount: nextprops.ticketCountApiResponse.data.ticketCount?.unassignedCount
                })
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Col lg="12">
                    <CommonBreadcrum type={'support-tickets'} />
                </Col>
                <Col lg="12" className='project_list_row mb-2' style={{ minHeight: 'auto' }}>
                    <div className='common-header'>
                        <div className='common-flex-box'>
                            <div className='page-header'>
                                <h4>
                                    {__t('page_headers.ticket_list')}
                                </h4>
                            </div>
                            <div className='common-flex-box'>
                                <Link className="btn btn-primary btn-sm" to='/support-tickets/create'>{__t('form_labels.support_tickets.create_new_ticket')}</Link>
                            </div>
                        </div>
                    </div>
                </Col>

                <Container fluid className="support_tickets">
                    <Row className='support-head'>
                        <Col md="12" lg="6" xl="5" className="filter_col">
                            <ul className='list-group'>
                                <li className={(this.state.filters.type === TICKET_ALL_TYPE) ? 'list-group-item bg-primary text-primary active' : 'list-group-item bg-primary text-primary'} onClick={() => {
                                    this.getTicketsByPage(1, {
                                        status: '',
                                        type: (this.state.filters.type === TICKET_ALL_TYPE) ? '' : TICKET_ALL_TYPE
                                    })
                                }} ><strong>{this.state.allCount}</strong>All Tickets</li>
                                <li className={(this.state.filters.type === TICKET_MY_TYPE) ? 'list-group-item bg-info text-info active' : 'list-group-item bg-info text-info'} onClick={() => {
                                    this.getTicketsByPage(1, {
                                        status: '',
                                        type: (this.state.filters.type === TICKET_MY_TYPE) ? '' : TICKET_MY_TYPE
                                    })
                                }} ><strong>{this.state.myCount}</strong>My Tickets</li>
                                <li className={(this.state.filters.type === TICKET_PENDING_TYPE) ? 'list-group-item bg-warning active' : 'list-group-item bg-warning'} onClick={() => {
                                    this.getTicketsByPage(1, {
                                        status: '',
                                        type: (this.state.filters.type === TICKET_PENDING_TYPE) ? '' : TICKET_PENDING_TYPE
                                    })
                                }}><strong>{this.state.pendingCount}</strong>Pending Tickets</li>
                                <li className={(this.state.filters.type === TICKET_UN_RESPONDED_TYPE) ? 'list-group-item bg-danger text-danger active' : 'list-group-item bg-danger text-danger'} onClick={() => {
                                    this.getTicketsByPage(1, {
                                        status: '',
                                        type: (this.state.filters.type === TICKET_UN_RESPONDED_TYPE) ? '' : TICKET_UN_RESPONDED_TYPE
                                    })
                                }} ><strong>{this.state.unassignedCount}</strong>Unresponded</li>
                            </ul>
                        </Col>
                        <Col md="12" lg="6" xl="7" className='d-flex align-items-center justify-content-end'>
                            <FormGroup className='mr-3'>
                                <Select
                                    name="role"
                                    className="form-control selectbox"
                                    options={ticketStatusList}
                                    onChange={(opt) => {
                                        let statusVal =
                                            opt && opt?.value
                                                ? opt.value
                                                : '';
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                type: '',
                                                status: statusVal
                                            }
                                        }, () => {
                                            this.getTicketsByPage(1, this.state.filters);
                                        });
                                    }}
                                    value={ticketStatusList.filter(
                                        (option) =>
                                            this.state
                                                .filters
                                                ?.status ==
                                            option.value
                                    )}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                />
                                <Label className="form-label">
                                    Status
                                </Label>
                            </FormGroup>
                            <div className="search_box mr-3">
                                <Input
                                    type="search"
                                    placeholder="Search"
                                    className='pl-4'
                                    onKeyPress={(e) => {
                                        (e.keyCode === 13 ||
                                            e.key === 'Enter') &&
                                            e.preventDefault();
                                    }}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                filters: {
                                                    ...this.state.filters,
                                                    searchText: e.target.value
                                                        .trim()
                                                        .toLowerCase(),
                                                },
                                            },
                                            () => {
                                                this.getTicketsByPage(
                                                    1,
                                                    this.state.filters
                                                );
                                            }
                                        );
                                    }}
                                />
                                <svg
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <Button
                                className="py-2"
                                onClick={(e) => {
                                    this.setState(
                                        {
                                            orderByValue:
                                                this.state.orderByValue ==
                                                    'desc'
                                                    ? 'asc'
                                                    : 'desc',
                                        },
                                        () => {
                                            this.getTicketsByPage(
                                                1,
                                                this.state.filters
                                            );
                                        }
                                    );
                                }}
                            >
                                Date
                                {this.state.orderByValue == 'desc' ? (
                                    <i className="fas fa-sort-alpha-up"></i>
                                ) : (
                                    <i className="fas fa-sort-alpha-down"></i>
                                )}
                                { }

                            </Button>
                        </Col>
                    </Row>
                    <div lg="9" className="support_wrapper">
                        <ul className="news_card">
                            <li className='ticket-head'>
                                <span className='ticket-code'>
                                    Ticket no.
                                </span>
                                <span className='ticket-title'>
                                </span>
                                {isGTROLE() === true && (
                                    <span className='ticket-tenant'>
                                        Tenant
                                    </span>
                                )}
                                <span className='ticket-status'>
                                    Status
                                </span>
                                <span className='ticket-raised'>
                                    Raised on
                                </span>
                                <span className='ticket-due'>
                                    Pending Since
                                </span>
                                <span className='ticket-by'>
                                    Raised By
                                </span>
                                <span className='ticket-department'>
                                    Department
                                </span>
                                <span className='ticket-assign'>
                                    Assign
                                </span>

                            </li>
                            {this.state.ticketsList.length > 0 &&
                                this.state.ticketsList.map((ticket, key) => {
                                    let pendingSinceDays = dateDifference(getDateFromFormat(ticket.createdAt), getCurrentDateInFormat());
                                    let { statusName, statusClassName } = getTicketStatusNameById(ticket.status);
                                    return (
                                        <li>
                                            <Link to={'/support-tickets/details/' + ticket.id} title={__t('common.details')}>
                                                <span className='ticket-code code_td'>{ticket?.code}</span>
                                                <span className='ticket-title'>
                                                    <strong>{ticket.title}</strong>
                                                </span>
                                                {isGTROLE() === true && (
                                                    <span className='ticket-tenant'>
                                                        {(ticket.tenant) && (
                                                            ticket.tenant.name
                                                        )}
                                                    </span>
                                                )}
                                                <span className='ticket-status'>
                                                    <span className={statusClassName}>{statusName}</span>
                                                </span>
                                                <span className='ticket-raised'>
                                                    {getFullDateTimeFromDateTimeString(ticket.createdAt)}
                                                </span>
                                                <span className='ticket-due'>
                                                    {ticket.status !== TICKET_CLOSE && pendingSinceDays != '-' && (
                                                        pendingSinceDays
                                                    )}
                                                </span>
                                                <span className='ticket-by'>
                                                    {(ticket.createdByUser) && (
                                                        <div>
                                                            <span>{getInitials(ticket.createdByUser.fullName)}</span>
                                                            <small>{ticket.createdByUser.fullName}</small>
                                                        </div>
                                                    )}
                                                </span>
                                                <span className='ticket-department'>
                                                    {(ticket.department) && (
                                                        <>
                                                            {ticket.department.departmentName}
                                                        </>
                                                    )}
                                                </span>
                                                <span className='ticket-assign'>
                                                    {ticket.assignedUser && (
                                                        <div>
                                                            <span>{getInitials(ticket.assignedUser.fullName)}</span>
                                                            <small>{ticket.assignedUser.fullName}</small>
                                                        </div>
                                                    )}
                                                </span>
                                            </Link>
                                        </li>
                                    );
                                })}
                        </ul>
                        {this.state.hasNextPage && (
                            <div className="d-flex justify-content-center">
                                <Button
                                    onClick={() => {
                                        this.getTicketsByPage(
                                            this.state.currentPage + 1
                                        );
                                    }}
                                >
                                    Load More
                                </Button>
                            </div>
                        )}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedTicketCategoryListDropDown: selectSortedTicketCategoryList(),
    ticketApiResponse: makeTicketSelectField('ticketApiResponse'),
    ticketCountApiResponse: makeTicketSelectField('ticketCountApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    listTicketCategoryRequest,
    listTicketRequest,
    getTicketCountRequest
});
export default compose(
    withConnect,
    withTicketReducer,
    withTicketSaga,
    withRouter
)(MainTicketsIndex);
