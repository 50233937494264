import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    CANCEL,
    SUCCESS,
    ENABLE_DELETE,
    SUBMIT,
    NONE,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    filterUserRequest,
    getSystemConfigurationRoleListRequest,
    saveSystemConfigurationUsersRequest,
    getSystemConfigurationUsersListRequest,
    updateSystemConfigurationUserRoleStatusByUserRoleIdRequest,
    deleteSystemConfigurationUserRoleByUserRoleIdRequest,
    getCountryListByStatusRequest,
    getUserRoleWiseCountriesListRequest,
    cleareFilterUserResponse
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import roleReducer from 'store/Masters/Role/reducer';
import roleSaga from 'store/Masters/Role/saga';
import { getFirstCapitalize, setEmptyToNull } from 'helpers/generalUtils';
import { selectSortedUserList } from 'store/User/selector';
import __t from 'i18n/translator';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import {
    makeRoleSelectField,
    selectSortedRoleList,
} from 'store/Masters/Role/selector';

import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { roleType } from 'constants/dropdowns';
import { compareString } from 'helpers/generalUtils';
import { ROLE_GLOBAL_COMPLIANCE_UPDATER } from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });

const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const roleKey = 'role';
const withRoleReducer = injectReducer({
    key: roleKey,
    reducer: roleReducer,
});
const withRoleSaga = injectSaga({ key: roleKey, saga: roleSaga });
const initialSystemConfigurationDataState = () => {
    return {
        roleId: '',
        userId: [],
        isActive: ACTIVE,
        countryId: '',
        type: '',
    };
};
const intFields = ['roleId'];
const nullableFields = [];
const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
const listColumns = [
    {
        label: 'User Name',
        field: 'fullName',
    },
    {
        label: 'User Email',
        field: 'email',
    },
    {
        label: 'Role Name',
        field: 'roleName',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
class SystemUserSetting extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            exportSystemConfigurationUserList: [],
            userRolesList: [],
            systemConfigurationUserList: [],
            systemConfigurationData: initialSystemConfigurationDataState(),
            operationType: SUBMIT,
            sortedCountryListDropDown: [],
            activePage: 1,
        };
    }
    componentDidMount() {
        this.props.getUserRoleWiseCountriesListRequest({
            userId: [],
        });
        this.props.filterUserRequest({ filter: { companyGroupId: [NONE], entityId: [NONE] } });
        this.props.getSystemConfigurationRoleListRequest();
        this.props.getSystemConfigurationUsersListRequest();
    }
    componentWillUnmount() {
        this.props.cleareFilterUserResponse();
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            values = formatValuesForApi(values);
            this.props.saveSystemConfigurationUsersRequest(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                systemConfigurationData: {},
            },
            () => {
                this.setState({
                    systemConfigurationData:
                        initialSystemConfigurationDataState(),
                });
            }
        );
        this.validator.hideMessages();
    };
    deleteUser = async (userRoleId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.system_user_delete')
        );
        if (response) {
            this.props.deleteSystemConfigurationUserRoleByUserRoleIdRequest(
                userRoleId
            );
        }
    };
    generateTable = (records) => {
        let userRoleRecords = [];
        userRoleRecords = records.map((userRoleData, i) => {
            let actions = (
                <div className="text-center">
                    {ENABLE_DELETE.includes('systemConfiguration') && (
                        <a
                            href={() => false}
                            title={__t('common.delete')}
                            className="btn btn-link text-danger tbl-action"
                            onClick={() => {
                                if (
                                    userRoleData.id !=
                                    getValueByKey(authConstants.USER_ID)
                                ) {
                                    this.deleteUser(userRoleData.id);
                                } else {
                                    return false;
                                }
                            }}
                            disabled={
                                userRoleData.id ==
                                getValueByKey(authConstants.USER_ID)
                            }
                        >
                            <i className="fas fa-trash-alt"></i>
                        </a>
                    )}
                </div>
            );
            let roleName = userRoleData?.role?.roleName;
            if (userRoleData?.role?.id == ROLE_GLOBAL_COMPLIANCE_UPDATER) {
                let countryName = (userRoleData?.country) ? userRoleData.country.countryName : '';
                if (userRoleData.type) {
                    roleName += ' - ' + getFirstCapitalize(userRoleData.type);
                }
                if (countryName != '') {
                    roleName += ' (' + countryName + ')';
                }
            }

            return {
                fullName: userRoleData?.user?.fullName,
                email: userRoleData?.user?.email,
                roleName: roleName,
                // countryName: (userRoleData?.country) ? userRoleData.country.countryName : '',
                actions: actions,
            };
        });
        return userRoleRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.systemConfigurationUserList &&
            this.props.systemConfigurationUserList !==
            nextprops.systemConfigurationUserList
        ) {
            this.setState({
                userRolesList: nextprops.systemConfigurationUserList
                    .filter(opt => opt.user !== null)
                    .map(opt => ({
                        id: opt.id,
                        roleId: opt.role?.id,
                        userId: opt.user?.id,
                        countryId: opt?.countryId,
                    })),
                exportSystemConfigurationUserList:
                    nextprops.systemConfigurationUserList,
                systemConfigurationUserList: this.generateTable(
                    nextprops.systemConfigurationUserList
                ),
            });
        }
        if (
            nextprops.systemConfigurationRoleApiResponse &&
            this.props.systemConfigurationRoleApiResponse !==
            nextprops.systemConfigurationRoleApiResponse
        ) {
            toastrMessage(
                nextprops.systemConfigurationRoleApiResponse.message,
                nextprops.systemConfigurationRoleApiResponse.responseType
            );
            if (
                nextprops.systemConfigurationRoleApiResponse.responseType ===
                SUCCESS
            ) {
                this.resetForm();
            }
        }
        if (
            nextprops.deleteSystemConfigurationApiResponse &&
            this.props.deleteSystemConfigurationApiResponse !==
            nextprops.deleteSystemConfigurationApiResponse
        ) {
            toastrMessage(
                nextprops.deleteSystemConfigurationApiResponse.message,
                nextprops.deleteSystemConfigurationApiResponse.responseType
            );
        }
        if (
            nextprops.updateStatusSystemConfigurationRoleApiResponse &&
            this.props.updateStatusSystemConfigurationRoleApiResponse !==
            nextprops.updateStatusSystemConfigurationRoleApiResponse
        ) {
            toastrMessage(
                nextprops.updateStatusSystemConfigurationRoleApiResponse
                    .message,
                nextprops.updateStatusSystemConfigurationRoleApiResponse
                    .responseType
            );
        }
        if (
            nextprops.getUserRoleWiseCountriesList &&
            this.props.getUserRoleWiseCountriesList !==
            nextprops.getUserRoleWiseCountriesList
        ) {
            let countryList = nextprops.getUserRoleWiseCountriesList
                .map((opt) => ({
                    value: opt.id,
                    label: opt.countryName,
                }))
                .sort((item1, item2) =>
                    compareString(item1.label, item2.label)
                );

            this.setState({
                sortedCountryListDropDown: countryList,
            });
        }
    }
    getUserRoleWiseCountriesList(opt, setFieldValue) {
        let formData = {
            userId: opt != null ? opt.map((x) => x.value) : [],
        };
        this.props.getUserRoleWiseCountriesListRequest(formData);
    }

    usersFilter(values) {
        if (values.roleId === 3) {
            return this.props.sortedUserListDropdown;
        } else {
            return this.props.sortedUserListDropdown?.filter((opt) => {
                let checkUsersWithSelectedRole = this.state.userRolesList
                    .filter((optU) => optU.roleId == values.roleId)
                    ?.map((optUR) => optUR.userId);
                return !checkUsersWithSelectedRole.includes(opt.value);
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.systemConfigurationUserList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'system-configuration'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={true}
                            dialogName={__t(
                                'page_headers.system_configuration'
                            )}
                        >
                            <CardBody className="px-4 pt-4 pb-3">
                                <Formik
                                    initialValues={
                                        this.state.systemConfigurationData
                                    }
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>

                                            <Row>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="roleId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedRoleListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'roleId',
                                                                opt.value
                                                            );
                                                            setFieldValue(
                                                                'countryId',
                                                                ''
                                                            );
                                                            setFieldValue(
                                                                'type',
                                                                ''
                                                            );
                                                            setFieldValue(
                                                                'userId',
                                                                ''
                                                            );
                                                        }}
                                                        value={this.props.sortedRoleListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.roleId
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.masters.system_configuration.role'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.masters.system_configuration.role'
                                                        ),
                                                        values.roleId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.masters.system_configuration.role_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-8">
                                                    <Field
                                                        component={Select}
                                                        name="userId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            values.roleId == 3
                                                                ? this.props
                                                                    .sortedUserListDropdown
                                                                : this.props.sortedUserListDropdown?.filter(
                                                                    (opt) => {
                                                                        let checkUsersWithSelectedRole =
                                                                            this.state.userRolesList
                                                                                .filter(
                                                                                    (
                                                                                        optU
                                                                                    ) =>
                                                                                        optU.roleId ==
                                                                                        values.roleId
                                                                                )
                                                                                ?.map(
                                                                                    (
                                                                                        optUR
                                                                                    ) =>
                                                                                        optUR.userId
                                                                                );
                                                                        return !checkUsersWithSelectedRole.includes(
                                                                            opt.value
                                                                        );
                                                                    }
                                                                )
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'userId',
                                                                opt &&
                                                                opt.map(
                                                                    (x) =>
                                                                        x.value
                                                                )
                                                            );
                                                            this.getUserRoleWiseCountriesList(
                                                                opt,
                                                                setFieldValue
                                                            );
                                                        }}
                                                        value={this.props.sortedUserListDropdown?.filter(
                                                            (option) =>
                                                                values.userId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti={true}
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.masters.system_configuration.user'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.masters.system_configuration.user'
                                                        ),
                                                        values.userId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.masters.system_configuration.user_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                {values.roleId === 3 && (
                                                    <>
                                                        <FormGroup className="col-lg-4">
                                                            <Field
                                                                component={
                                                                    Select
                                                                }
                                                                name="countryId"
                                                                className="form-control selectbox"
                                                                // options={
                                                                //     this.props
                                                                //         .sortedCountryListDropDown
                                                                // }

                                                                options={
                                                                    this.state
                                                                        .sortedCountryListDropDown
                                                                }
                                                                onChange={(
                                                                    opt
                                                                ) => {
                                                                    setFieldValue(
                                                                        'countryId',
                                                                        opt
                                                                            ? opt.value
                                                                            : ''
                                                                    );
                                                                }}
                                                                value={this.state.sortedCountryListDropDown.filter(
                                                                    (option) =>
                                                                        option.value ===
                                                                        values.countryId
                                                                )}
                                                                isClearable={
                                                                    true
                                                                }
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.laws.country'
                                                                )}{' '}
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.laws.country'
                                                                ),
                                                                values.countryId,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.laws.country_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup className="col-lg-4">
                                                            <Field
                                                                component={
                                                                    Select
                                                                }
                                                                name="type"
                                                                className="form-control selectbox"
                                                                options={
                                                                    roleType
                                                                }
                                                                onChange={(
                                                                    opt
                                                                ) => {
                                                                    setFieldValue(
                                                                        'type',
                                                                        opt
                                                                            ? opt.value
                                                                            : ''
                                                                    );
                                                                }}
                                                                value={roleType.filter(
                                                                    (option) =>
                                                                        option.value ===
                                                                        values.type
                                                                )}
                                                                isClearable={
                                                                    true
                                                                }
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.laws.role_type'
                                                                )}{' '}
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.laws.role_type'
                                                                ),
                                                                values.type,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.laws.role_type_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                    </>
                                                )}
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                                        <div className="d-inline-block">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                            >
                                                                {
                                                                    this.state
                                                                        .operationType
                                                                }
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="secondary"
                                                                onClick={
                                                                    this
                                                                        .resetForm
                                                                }
                                                            >
                                                                {CANCEL}
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className="page-header">
                                    <h4>
                                        {__t(
                                            'page_headers.system_configuration_users_list'
                                        )}
                                    </h4>
                                </div>

                            </CardHeader>
                            <CardBody>
                                <CustomisedMDBDatatablePage
                                    // <CustomisedDatatable
                                    activepage={this.state.activePage}
                                    onHandlePageChange={this.handlePageChange}
                                    tableRecords={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedUserListDropdown: selectSortedUserList('userList'),
    systemConfigurationUserList: makeRoleSelectField(
        'systemConfigurationUserList'
    ),
    sortedRoleListDropDown: selectSortedRoleList(),
    systemConfigurationRoleApiResponse: makeRoleSelectField(
        'systemConfigurationRoleApiResponse'
    ),
    updateStatusSystemConfigurationRoleApiResponse: makeRoleSelectField(
        'updateStatusSystemConfigurationRoleApiResponse'
    ),
    deleteSystemConfigurationApiResponse: makeRoleSelectField(
        'deleteSystemConfigurationApiResponse'
    ),
    sortedCountryListDropDown: selectSortedCountryList(),
    getUserRoleWiseCountriesList: makeRoleSelectField(
        'getUserRoleWiseCountriesList'
    ),
});
const withConnect = connect(mapStatetoProps, {
    filterUserRequest,
    getSystemConfigurationRoleListRequest,
    saveSystemConfigurationUsersRequest,
    getSystemConfigurationUsersListRequest,
    updateSystemConfigurationUserRoleStatusByUserRoleIdRequest,
    deleteSystemConfigurationUserRoleByUserRoleIdRequest,
    getCountryListByStatusRequest,
    getUserRoleWiseCountriesListRequest,
    cleareFilterUserResponse
});
export default compose(
    withCountryReducer,
    withCountrySaga,
    withConnect,
    withUserReducer,
    withUserSaga,
    withRoleReducer,
    withRoleSaga,
    withRouter
)(SystemUserSetting);
