import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import {
    ACTIVE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    NONE,
    SUCCESS,
    UPDATE,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import Select from 'react-select';
import __t from 'i18n/translator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import {
    customDateValidator,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
class AddEntityLocationForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            sortedCityListDropDown: []
        };
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
    }
    getEntityListByCompanyGroup(companyGroupId) {
        this.props.getEntityListByCompanyGroupIdRequest([companyGroupId]);
    }
    getStateListByCountry(countryId) {
        this.setState({
            sortedCityListDropDown: []
        }, () => {
            this.props.getStateListByCountryRequest([countryId]);
        })
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest([stateId]);
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
        if (nextprops.entityLocationData !== this.props.entityLocationData) {
            if (nextprops.operationType === UPDATE) {
                this.getEntityListByCompanyGroup(
                    nextprops.entityLocationData.companyGroupId
                );
                this.getStateListByCountry(
                    nextprops.entityLocationData.countryId
                );
                this.getCityListByState(nextprops.entityLocationData.stateId);
            }
        }
        if (nextprops.sortedCityListDropDown !== this.props.sortedCityListDropDown) {
            this.setState({
                sortedCityListDropDown: nextprops.sortedCityListDropDown
            })
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign(
                        {},
                        this.props.entityLocationData
                    )}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Row>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="companyGroupId"
                                        className="form-control selectbox"
                                        options={this.props.sortedCompanyGroupListDropdown.filter(
                                            (opt) => opt.value != NONE
                                        )}
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'companyGroupId',
                                                opt.value
                                            );
                                            this.getEntityListByCompanyGroup(
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedCompanyGroupListDropdown.filter(
                                            (option) =>
                                                option.value ===
                                                values.companyGroupId
                                        )}
                                        isDisabled={
                                            values.id && values.id !== 0
                                        }
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.entity.company_group'
                                        )}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.entity.company_group'),
                                        values.companyGroupId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.entity.company_group_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-8">
                                    <Field
                                        component={Select}
                                        name="entityId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropDown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'entityId',
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedEntityListDropDown.filter(
                                            (option) =>
                                                option.value === values.entityId
                                        )}
                                        isDisabled={
                                            values.id && values.id !== 0
                                        }
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.entity')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.entity'),
                                        values.entityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.entity_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="countryId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropDown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'countryId',
                                                opt.value
                                            );
                                            setFieldValue('stateId', '')
                                            setFieldValue('cityId', '')
                                            this.getStateListByCountry(
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedCountryListDropDown.filter(
                                            (option) =>
                                                option.value ===
                                                values.countryId
                                        )}
                                        isDisabled={
                                            values.id && values.id !== 0
                                        }
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.entity.country')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.entity.country'),
                                        values.countryId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.entity.country_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="stateId"
                                        className="form-control selectbox"
                                        options={this.props.sortedStateListDropDown.filter(
                                            (opt) => opt.value != -1
                                        )}
                                        onChange={(opt) => {
                                            setFieldValue(`stateId`, opt.value);
                                            this.getCityListByState(opt.value);
                                        }}
                                        value={this.props.sortedStateListDropDown.filter(
                                            (option) =>
                                                option.value === values.stateId
                                        )}
                                        isDisabled={
                                            values.id && values.id !== 0
                                        }
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.entity.state')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.entity.state'),
                                        values.stateId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.entity.state_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="cityId"
                                        className="form-control selectbox"
                                        options={this.state.sortedCityListDropDown.filter(
                                            (opt) => opt.value != -1
                                        )}
                                        onChange={(opt) => {
                                            setFieldValue('cityId', opt.value);
                                        }}
                                        value={this.state.sortedCityListDropDown?.filter(
                                            (option) =>
                                                option.value === values.cityId
                                        )}
                                        isDisabled={
                                            values.id && values.id !== 0
                                        }
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.entity.city')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.entity.city'),
                                        values.cityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.entity.city_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>

                                <FormGroup className="col-lg-4">
                                    <Field
                                        name="registrationNumber"
                                        className="form-control"
                                        placeholder={__t(
                                            'form_labels.entity.registration_number'
                                        )}
                                        maxLength={250}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.entity.registration_number'
                                        )}
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.entity.registration_number'
                                        ),
                                        values.registrationNumber,
                                        'alpha_num_dash|max:250',
                                        {
                                            messages: {
                                                max: __t(
                                                    'validations.entity.registration_number_max',
                                                    { max: 250 }
                                                ),
                                                alpha_num_dash: __t(
                                                    'validations.entity.registration_number_alpha_num_dash'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4 w-100 sticky_label">
                                    <Field
                                        component={DatePicker}
                                        isClearable={true}
                                        className="form-control"
                                        name="registrationDate"
                                        selected={
                                            values.registrationDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.registrationDate
                                                )
                                                : null
                                        }
                                        dateFormat={DATE_FORMAT_DATEPICKER}
                                        onChange={(date) => {
                                            setFieldValue(
                                                'registrationDate',
                                                date
                                            );
                                        }}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dropdownMode="select"
                                        placeholderText={__t(
                                            'form_labels.entity.registration_date'
                                        )}
                                        onFocus={(e) => e.target.blur()}
                                        maxDate={new Date()}
                                    ></Field>
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.entity.registration_date'
                                        )}
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.entity.registration_date'
                                        ),
                                        values.registrationDate
                                            ? getDateObjectWithValueFromMoment(
                                                values.registrationDate
                                            )
                                            : '',
                                        'customDate',
                                        {
                                            messages: {
                                            },
                                        }
                                    )}
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >
                                                {this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {CANCEL}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedEntityListDropDown: selectSortedEntityList(),
});
const withConnect = connect(mapStatetoProps, {
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(AddEntityLocationForm);
