import produce from 'immer';
import {
    ADD_COMPLIANCE_UPDATE_UPLOAD_RESPONSE,
    GET_COMPLIANCE_UPDATE_FILE_LOGS_RESPONSE,
    GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_RESPONSE,
    UPDATE_ASSIGN_GCM_LEGAL_UPDATE_RESPONSE,
    GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_RESPONSE,
    GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_RESPONSE,
    LEGAL_UPDATE_DOCUMENT_UPLOAD_RESPONSE,
    COMPLIANCE_LEGAL_UPDATE_RESPONSE,
    DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_RESPONSE,
    GET_ALL_UPDATE_TYPE_LIST_RESPONSE,
    GET_ALL_SUB_UPDATE_TYPE_LIST_RESPONSE,
    GET_ALL_LEGAL_UPDATE_STATUS_LIST_RESPONSE,
    GET_ALL_RELEVANT_UPDATE_RESPONSE,
    CLOSE_LEGAL_UPDATE_BY_ID_RESPONSE
} from './actionTypes';
export const initialState = {
    complianceUpdateUploadApiResponse: null,
    complianceUpdateFileLogsList: [],
    complianceLegalUpdateSearchApiResponse: null,
    relevantUpdateSearchApiResponse: null,
    assignGcmLegalUpdateApiResponse: [],
    compliancelegalUpdateDetailsApiResponse: null,
    compliancelegalUpdateActivityApiResponse: [],
    legalUpdatesDocumentApiResponse: [],
    complianceLegalUpdateApiResponse: [],
    complianceLegalUpdateDeleteApiResponse: [],
    updateTypeList: [],
    subUpdateTypeList: [],
    legalUpdateStatusList: [],
    closeLegalUpdateApiResponse: null
};
const legalUpdates = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_COMPLIANCE_UPDATE_UPLOAD_RESPONSE:
                draft.complianceUpdateUploadApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_COMPLIANCE_UPDATE_FILE_LOGS_RESPONSE:
                draft.complianceUpdateFileLogsList =
                    action.payload.complianceUpdateFileLogsList;
                break;
            case GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_RESPONSE:
                draft.complianceLegalUpdateSearchApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_ALL_RELEVANT_UPDATE_RESPONSE:
                draft.relevantUpdateSearchApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case UPDATE_ASSIGN_GCM_LEGAL_UPDATE_RESPONSE:
                draft.assignGcmLegalUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_RESPONSE:
                draft.compliancelegalUpdateDetailsApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_RESPONSE:
                draft.compliancelegalUpdateActivityApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case LEGAL_UPDATE_DOCUMENT_UPLOAD_RESPONSE:
                draft.legalUpdatesDocumentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case COMPLIANCE_LEGAL_UPDATE_RESPONSE:
                draft.complianceLegalUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_RESPONSE:
                draft.complianceLegalUpdateDeleteApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_UPDATE_TYPE_LIST_RESPONSE:
                draft.updateTypeList = action.payload.updateTypeList;
                break;
            case GET_ALL_SUB_UPDATE_TYPE_LIST_RESPONSE:
                draft.subUpdateTypeList = action.payload.subUpdateTypeList;
                break;
            case GET_ALL_LEGAL_UPDATE_STATUS_LIST_RESPONSE:
                draft.legalUpdateStatusList = action.payload.list;
                break;
            case CLOSE_LEGAL_UPDATE_BY_ID_RESPONSE:
                draft.closeLegalUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default legalUpdates;
