import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import laptop from '../images/laptop.png'

class Error404 extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
        };
    }



    render() {
        return (
            <React.Fragment>
                <div className='error_wrapper'>
                    <Row className='error_main'>
                        <Col lg={5} className="errorcontent">
                            <article>
                                <h1>OOPS! You weren't supposed to see this...</h1>
                            </article>
                        </Col>
                        <Col lg={7} className="laptop hidden-xs">
                            <img src={laptop} alt="laptop" />
                            <div class="glitch">404</div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});
export default compose(withConnect, withRouter)(Error404);
