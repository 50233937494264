import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_FUND_MASTER_REQUEST,
    DELETE_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
    GET_ALL_FUND_MASTER_LIST_REQUEST,
    GET_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
    GET_FUND_MASTER_LIST_BY_STATUS_REQUEST,
    UPDATE_FUND_MASTER_REQUEST,
    UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addFundMasterResponse,
    deleteFundMasterByFundMasterIdResponse,
    getAllFundMasterListRequest,
    getAllFundMasterListResponse,
    getFundMasterByFundMasterIdResponse,
    getFundMasterListByStatusResponse,
    updateFundMasterResponse,
    updateFundMasterStatusByFundMasterIdResponse,
} from './actions';
import {
    FUND_MASTER,
    FUND_MASTER_ACTIVE_INACTIVE,
} from 'helpers/apiConstants';
function* addFundMaster({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', FUND_MASTER, data);
        if (response.status) {
            yield put(getAllFundMasterListRequest());
            yield put(addFundMasterResponse(response.message, SUCCESS));
        } else {
            yield put(addFundMasterResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllFundMasterList() {
    try {
        const response = yield call(invokeApi, 'get', FUND_MASTER, '');
        if (response.status) {
            yield put(getAllFundMasterListResponse(response.data.list));
        } else {
            yield put(getAllFundMasterListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getFundMasterByFundMasterId({ payload: { fundMasterId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            FUND_MASTER + '/' + fundMasterId,
            ''
        );
        if (response.status) {
            yield put(
                getFundMasterByFundMasterIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getFundMasterByFundMasterIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateFundMaster({ payload: { fundMasterId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            FUND_MASTER + '/' + fundMasterId,
            data
        );
        if (response.status) {
            yield put(getAllFundMasterListRequest());
            yield put(updateFundMasterResponse(response.message, SUCCESS));
        } else {
            yield put(updateFundMasterResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateFundMasterStatusByFundMasterId({
    payload: { fundMasterId, data },
}) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            FUND_MASTER_ACTIVE_INACTIVE + '/' + fundMasterId, data
        );
        if (response.status) {
            yield put(getAllFundMasterListRequest());
            yield put(
                updateFundMasterStatusByFundMasterIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateFundMasterStatusByFundMasterIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getFundMasterListByStatus({ payload: { status } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            FUND_MASTER + '?status=' + status
        );
        if (response.status) {
            yield put(getFundMasterListByStatusResponse(response.data.list));
        } else {
            yield put(getFundMasterListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteFundMasterByFundMasterId({ payload: { fundMasterId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            FUND_MASTER + '/' + fundMasterId
        );
        if (response.status) {
            yield put(getAllFundMasterListRequest());
            yield put(
                deleteFundMasterByFundMasterIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteFundMasterByFundMasterIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchFundMaster() {
    yield takeEvery(ADD_FUND_MASTER_REQUEST, addFundMaster);
    yield takeEvery(GET_ALL_FUND_MASTER_LIST_REQUEST, getAllFundMasterList);
    yield takeEvery(
        GET_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
        getFundMasterByFundMasterId
    );
    yield takeEvery(UPDATE_FUND_MASTER_REQUEST, updateFundMaster);
    yield takeEvery(
        UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_REQUEST,
        updateFundMasterStatusByFundMasterId
    );
    yield takeEvery(
        GET_FUND_MASTER_LIST_BY_STATUS_REQUEST,
        getFundMasterListByStatus
    );
    yield takeEvery(
        DELETE_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
        deleteFundMasterByFundMasterId
    );
}
function* fundMasterSaga() {
    yield all([fork(watchFundMaster)]);
}
export default fundMasterSaga;
