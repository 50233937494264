import { ADD_CITY_REQUEST, ADD_CITY_RESPONSE, DELETE_CITY_BY_CITY_ID_REQUEST, DELETE_CITY_BY_CITY_ID_RESPONSE, GET_ALL_CITY_LIST_REQUEST, GET_ALL_CITY_LIST_RESPONSE, GET_CITY_BY_CITY_ID_REQUEST, GET_CITY_BY_CITY_ID_RESPONSE, GET_CITY_LIST_BY_STATE_REQUEST, GET_CITY_LIST_BY_STATE_RESPONSE, GET_CITY_LIST_BY_STATUS_REQUEST, GET_CITY_LIST_BY_STATUS_RESPONSE, UPDATE_CITY_REQUEST, UPDATE_CITY_RESPONSE, UPDATE_CITY_STATUS_BY_CITY_ID_REQUEST, UPDATE_CITY_STATUS_BY_CITY_ID_RESPONSE } from "./actionTypes";
export const addCityRequest = data => {
    return {
        type: ADD_CITY_REQUEST,
        payload: { data },
    };
};
export const addCityResponse = (message, responseType) => {
    return {
        type: ADD_CITY_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllCityListRequest = () => {
    return {
        type: GET_ALL_CITY_LIST_REQUEST
    };
};
export const getAllCityListResponse = cityList => {
    return {
        type: GET_ALL_CITY_LIST_RESPONSE,
        payload: { cityList },
    };
};
export const getCityByCityIdRequest = (cityId) => {
    return {
        type: GET_CITY_BY_CITY_ID_REQUEST,
        payload: { cityId: cityId },
    };
};
export const getCityByCityIdResponse = (message, responseType, data) => {
    return {
        type: GET_CITY_BY_CITY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateCityRequest = (cityId, data) => {
    return {
        type: UPDATE_CITY_REQUEST,
        payload: { cityId, data },
    };
};
export const updateCityResponse = (message, responseType) => {
    return {
        type: UPDATE_CITY_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateCityStatusByCityIdRequest = (cityId, status) => {
    return {
        type: UPDATE_CITY_STATUS_BY_CITY_ID_REQUEST,
        payload: { cityId, status },
    };
};
export const updateCityStatusByCityIdResponse = (message, responseType) => {
    return {
        type: UPDATE_CITY_STATUS_BY_CITY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getCityListByStatusRequest = status => {
    return {
        type: GET_CITY_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getCityListByStatusResponse = cityList => {
    return {
        type: GET_CITY_LIST_BY_STATUS_RESPONSE,
        payload: { cityList },
    };
};
export const deleteCityByCityIdRequest = (cityId) => {
    return {
        type: DELETE_CITY_BY_CITY_ID_REQUEST,
        payload: { cityId },
    };
};
export const deleteCityByCityIdResponse = (message, responseType) => {
    return {
        type: DELETE_CITY_BY_CITY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getCityListByStateRequest = stateIds => {
    return {
        type: GET_CITY_LIST_BY_STATE_REQUEST,
        payload: { stateIds },
    };
};
export const getCityListByStateResponse = cityList => {
    return {
        type: GET_CITY_LIST_BY_STATE_RESPONSE,
        payload: { cityList },
    };
};