export const ADD_APPLICABILITY_REQUEST = 'add_applicability_request';
export const ADD_APPLICABILITY_RESPONSE = 'add_applicability_response';
export const GET_ALL_APPLICABILITY_LIST_REQUEST = 'get_all_applicability_list_request';
export const GET_ALL_APPLICABILITY_LIST_RESPONSE = 'get_all_applicability_list_response';
export const UPDATE_APPLICABILITY_REQUEST = 'update_applicability_request';
export const UPDATE_APPLICABILITY_RESPONSE = 'update_applicability_response';
export const UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_REQUEST = 'update_applicability_status_by_applicability_id_request';
export const UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_RESPONSE = 'update_applicability_status_by_applicability_id_response';
export const DELETE_APPLICABILITY_BY_APPLICABILITY_ID_REQUEST = 'delete_applicability_by_applicability_id_request';
export const DELETE_APPLICABILITY_BY_APPLICABILITY_ID_RESPONSE = 'delete_applicability_by_applicability_id_response';
export const GET_APPLICABILITY_LIST_BY_STATUS_REQUEST = 'get_applicability_list_by_status_request';
export const GET_APPLICABILITY_LIST_BY_STATUS_RESPONSE = 'get_applicability_list_by_status_response';
export const GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_REQUEST = 'get_sub_applicability_list_by_applicability_request';
export const GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_RESPONSE = 'get_sub_applicability_list_by_applicability_response';
