import React, { Component } from 'react';
import { CardBody, Card, CardHeader, Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    DANGER,
    ENABLE_DELETE,
    SUBMIT,
    UPDATE,
} from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllEventListRequest,
    addEventRequest,
    updateEventRequest,
    updateEventStatusByEventIdRequest,
    deleteEventByEventIdRequest,
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import { makeEventSelectField } from 'store/Masters/Event/selector';
import { initialEventDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import AddEventForm from './AddEventForm';
import __t from 'i18n/translator';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import AuditLogModal from 'components/Application/AuditLogModal';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_EVENT_MASTER,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import EventTreeDetails from './EventTreeDetails';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDialog: false,
            exportEventList: [],
            eventList: [],
            eventData: initialEventDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: '',
            treeEventId: '',
            showEventTree: false,
            activePage: 1,
            sortedStateListDropDown: [],
            sortedCityListDropDown: []
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve event list
        this.props.getAllEventListRequest();
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    viewEvent(eventData) {
        this.setState(
            {
                eventData: initialEventDataState(),
                operationType: UPDATE,
                viewDialog: true
            },
            () => {
                this.setState({ eventData: eventData });
                if (eventData && eventData.countryId) {
                    this.getStateListByCountry(eventData.countryId);
                }
                if (eventData && eventData.stateId) {
                    this.getCityListByState(eventData.stateId);
                }
            }
        );
    }
    getStateListByCountry(countryId) {
        this.setState({
            sortedCityListDropDown: []
        }, () => {
            this.props.getStateListByCountryRequest([countryId]);
        })
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest([stateId]);
    }
    onStatusChange(checked, e, id) {
        this.props.updateEventStatusByEventIdRequest(id, checked);
    }
    deleteEvent = async (eventId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.event_delete')
        );
        if (response) {
            this.props.deleteEventByEventIdRequest(eventId);
        }
    };
    formSubmit = (values) => {
        this.setState({ eventData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateEventRequest(values.id, values);
        } else {
            this.props.addEventRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            eventData: initialEventDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let eventRecords = [];
        eventRecords = records.map((eventData, i) => {
            let actions = (
                <div className="text-right d-flex event_action_btn">
                    {checkModulePermission(
                        MODULE_EVENT_MASTER,
                        PERMISSION_EDIT
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => this.viewEvent(eventData)}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('event') &&
                        checkModulePermission(
                            MODULE_EVENT_MASTER,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteEvent(eventData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    {(eventData.isSequenceUpdated == true) && (
                        <a href={() => false} title={__t('common.sequence_updated')} className='text-success'>
                            <i className='fas fa-flag'></i>
                        </a>
                    )}

                    {(eventData.isSequenceUpdated == false) && (
                        <a href={() => false} title={__t('common.sequence_not_updated')} className='text-warning'>
                            <i className='fas fa-flag'></i>
                        </a>
                    )}
                    <a
                        href={() => false}
                        title={(eventData.lawCompliances.length > 0) ? __t('common.compliance_hierarchy') : __t('form_labels.masters.events.upload_compliances')}
                        className="btn btn-link text-info tbl-action"
                        disabled={(eventData.lawCompliances.length == 0) ? true : false}
                        onClick={() => {
                            if (eventData.lawCompliances.length > 0) {
                                this.setState({
                                    treeEventId: eventData.id,
                                    showEventTree: true
                                })
                            } else {
                                toastrMessage(__t('alerts.no_compliances_found'), DANGER);
                            }
                        }}
                    >
                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16" y="7" width="4" height="4" rx="2" transform="rotate(90 16 7)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="16" y="15" width="4" height="4" rx="2" transform="rotate(90 16 15)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="1" y="5" width="4" height="4" rx="2" transform="rotate(-90 1 5)" fill="#7E869E" fillOpacity="1" stroke="currentColor" strokeWidth="1.5"></rect><path d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12" stroke="currentColor" strokeWidth="1.5"></path><path d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12" stroke="currentColor" strokeWidth="1.5"></path></svg>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(eventData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(eventData.id)}
                        checked={eventData.isActive}
                    />
                    &nbsp;
                </div>
            );
            return {
                eventName: eventData.eventName,
                countryName: eventData?.country?.countryName,
                stateName: eventData?.state?.stateName,
                cityName: eventData?.city?.cityName,
                eventApplicability: (eventData?.eventApplicability) ? eventData?.eventApplicability : '',
                isActive: isActive,
                actions: actions,
            };
        });
        return eventRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.eventList &&
            this.props.eventList !== nextprops.eventList
        ) {
            this.setState({
                exportEventList: nextprops.eventList,
                eventList: this.generateTable(nextprops.eventList),
            });
        }
        if (
            nextprops.deleteEventApiResponse &&
            this.props.deleteEventApiResponse !==
            nextprops.deleteEventApiResponse
        ) {
            toastrMessage(
                nextprops.deleteEventApiResponse.message,
                nextprops.deleteEventApiResponse.responseType
            );
        }
        if (
            nextprops.eventStatusUpdateApiResponse &&
            this.props.eventStatusUpdateApiResponse !==
            nextprops.eventStatusUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.eventStatusUpdateApiResponse.message,
                nextprops.eventStatusUpdateApiResponse.responseType
            );
        }
        if (nextprops.sortedStateListDropDown !== this.props.sortedStateListDropDown) {
            this.setState({
                sortedStateListDropDown: nextprops.sortedStateListDropDown
            })
        }
        if (nextprops.sortedCityListDropDown !== this.props.sortedCityListDropDown) {
            this.setState({
                sortedCityListDropDown: nextprops.sortedCityListDropDown
            })
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.eventList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    {this.state.showEventTree == false && (
                        <React.Fragment>
                            <Row>
                                <Col lg="12">
                                    <CommonBreadcrum type={'event'} />
                                </Col>
                            </Row>
                            {checkModulePermission(MODULE_EVENT_MASTER, PERMISSION_ADD) === true && (
                                <CustomisedCollapseForOne
                                    className="accordion"
                                    viewDialog={this.state.viewDialog}
                                    dialogName={__t('page_headers.event_form')}
                                >
                                    <CardBody className="px-4 pt-4 pb-2">
                                        <AddEventForm
                                            operationType={this.state.operationType}
                                            eventData={this.state.eventData}
                                            sortedCountryListDropDown={
                                                this.props.sortedCountryListDropDown
                                            }
                                            sortedStateListDropDown={
                                                this.state.sortedStateListDropDown
                                            }
                                            sortedCityListDropDown={
                                                this.state.sortedCityListDropDown
                                            }
                                            getStateListByCountry={(country) => {
                                                this.getStateListByCountry(country);
                                            }}
                                            getCityListByState={(state) => {
                                                this.getCityListByState(state);
                                            }}
                                            operationResponse={
                                                this.props.eventApiResponse
                                            }
                                            onFormValid={this.formSubmit}
                                            onFormReset={this.resetForm}
                                            resetSortedStateAndCityDropDown={() => this.setState({ sortedStateListDropDown: [], sortedCityListDropDown: [] })}
                                        />
                                    </CardBody>
                                </CustomisedCollapseForOne>
                            )}
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>{__t('page_headers.event_list')}</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage
                                        activepage={this.state.activePage}
                                        onHandlePageChange={this.handlePageChange}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                            <AuditLogModal
                                exportBtn={true}
                                isModalOpen={this.state.isAuditModalOpen}
                                modalName={__t('page_headers.audit_trails')}
                                auditType={'event'}
                                auditId={this.state.auditId}
                                onModalDismiss={() => this.closeAuditLogModal()}
                            />
                        </React.Fragment>
                    )}
                    {this.state.showEventTree == true && (
                        <EventTreeDetails
                            eventId={this.state.treeEventId}
                            hideTreeDetails={() => {
                                this.setState({
                                    treeEventId: '',
                                    showEventTree: false
                                })
                            }}
                        />
                    )}
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    eventList: makeEventSelectField('eventList'),
    eventApiResponse: makeEventSelectField('eventApiResponse'),
    eventStatusUpdateApiResponse: makeEventSelectField(
        'eventStatusUpdateApiResponse'
    ),
    deleteEventApiResponse: makeEventSelectField('deleteEventApiResponse'),
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
});
const withConnect = connect(mapStatetoProps, {
    getAllEventListRequest,
    addEventRequest,
    updateEventRequest,
    updateEventStatusByEventIdRequest,
    deleteEventByEventIdRequest,
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest
});
export default compose(
    withConnect,
    withEventReducer,
    withEventSaga,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withRouter
)(Index);
