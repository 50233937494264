import produce from 'immer';
import { ADD_CITY_RESPONSE, DELETE_CITY_BY_CITY_ID_RESPONSE, GET_ALL_CITY_LIST_RESPONSE, GET_CITY_BY_CITY_ID_RESPONSE, GET_CITY_LIST_BY_STATE_RESPONSE, GET_CITY_LIST_BY_STATUS_RESPONSE, UPDATE_CITY_RESPONSE, UPDATE_CITY_STATUS_BY_CITY_ID_RESPONSE } from './actionTypes';
export const initialState = {
    cityApiResponse: null,
    editCityApiResponse: null,
    cityList: []
};
const city = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_CITY_RESPONSE:
            case UPDATE_CITY_RESPONSE:
            case UPDATE_CITY_STATUS_BY_CITY_ID_RESPONSE:
            case DELETE_CITY_BY_CITY_ID_RESPONSE:
                draft.cityApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_CITY_LIST_RESPONSE:
                draft.cityList = action.payload.cityList;
                break;
            case GET_CITY_BY_CITY_ID_RESPONSE:
                draft.editCityApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;




            case GET_CITY_LIST_BY_STATUS_RESPONSE:
                draft.cityList = action.payload.cityList;
                break;



            case GET_CITY_LIST_BY_STATE_RESPONSE:
                draft.cityList = action.payload.cityList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default city;
