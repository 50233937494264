export const ADD_LAW_CATEGORY_REQUEST = 'add_law_category_request';
export const ADD_LAW_CATEGORY_RESPONSE = 'add_law_category_response';
export const GET_ALL_LAW_CATEGORY_LIST_REQUEST = 'get_all_law_category_list_request';
export const GET_ALL_LAW_CATEGORY_LIST_RESPONSE = 'get_all_law_category_list_response';
export const GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST = 'get_law_category_by_law_category_id_request';
export const GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE = 'get_law_category_by_law_category_id_response';
export const UPDATE_LAW_CATEGORY_REQUEST = 'update_law_category_request';
export const UPDATE_LAW_CATEGORY_RESPONSE = 'update_law_category_response';
export const UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_REQUEST = 'update_law_category_status_by_law_category_id_request';
export const UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_RESPONSE = 'update_law_category_status_by_law_category_id_response';
export const GET_LAW_CATEGORY_LIST_BY_STATUS_REQUEST = 'get_law_category_list_by_status_request';
export const GET_LAW_CATEGORY_LIST_BY_STATUS_RESPONSE = 'get_law_category_list_by_status_response';
export const DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST = 'delete_law_category_by_law_category_id_request';
export const DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE = 'delete_law_category_by_law_category_id_response';