import { compareString } from 'helpers/generalUtils';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.entityLocation || initialState;
const makeEntityLocationSelectField = field =>
    createSelector(reducerState, entityLocationState => entityLocationState[field]);
const selectSortedEntityLocationList = () =>
    createSelector(makeEntityLocationSelectField('entityLocationList'), entityLocationList =>
        entityLocationList.map(opt => ({
            value: opt.id,
            label: opt.country?.countryName + ', ' + opt.state?.stateName + ', ' + opt.city?.cityName + ' - ' + opt.pincode
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );

export {
    makeEntityLocationSelectField,
    selectSortedEntityLocationList
};
