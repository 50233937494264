export const ADD_CITY_REQUEST = 'add_city_request';
export const ADD_CITY_RESPONSE = 'add_city_response';
export const GET_ALL_CITY_LIST_REQUEST = 'get_all_city_list_request';
export const GET_ALL_CITY_LIST_RESPONSE = 'get_all_city_list_response';
export const GET_CITY_BY_CITY_ID_REQUEST = 'get_city_by_city_id_request';
export const GET_CITY_BY_CITY_ID_RESPONSE = 'get_city_by_city_id_response';
export const UPDATE_CITY_REQUEST = 'update_city_request';
export const UPDATE_CITY_RESPONSE = 'update_city_response';
export const UPDATE_CITY_STATUS_BY_CITY_ID_REQUEST = 'update_city_status_by_city_id_request';
export const UPDATE_CITY_STATUS_BY_CITY_ID_RESPONSE = 'update_city_status_by_city_id_response';
export const GET_CITY_LIST_BY_STATUS_REQUEST = 'get_city_list_by_status_request';
export const GET_CITY_LIST_BY_STATUS_RESPONSE = 'get_city_list_by_status_response';
export const DELETE_CITY_BY_CITY_ID_REQUEST = 'delete_city_by_city_id_request';
export const DELETE_CITY_BY_CITY_ID_RESPONSE = 'delete_city_by_city_id_response';
export const GET_CITY_LIST_BY_STATE_REQUEST = 'get_city_list_by_state_request';
export const GET_CITY_LIST_BY_STATE_RESPONSE = 'get_city_list_by_state_response';
