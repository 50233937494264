import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';
import { CANCEL, SUBMIT_COMPLIANCE_ORDER, SUCCESS } from 'constants/commonConstants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { getLawComplianceListByEventIdRequest, updateEventOrderByEventIdRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { makeEventSelectField } from 'store/Masters/Event/selector';
import EventComplianceSubItem from './EventComplianceSubItem';
import { checkModulePermission } from 'helpers/projectUtils';
import { MODULE_EVENT_MASTER, PERMISSION_EDIT } from 'constants/databaseConstants';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import __t from 'i18n/translator';
const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });
let allCmpList = [];
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 8,
    width: 250
});
class EventTreeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.eventId
                ? parseInt(this.props.eventId)
                : '',
            eventData: {},
            complianceList: [],
            selectedCompliance: [],
            submitAction: false
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    componentDidMount() {
        this.props.getLawComplianceListByEventIdRequest(this.state.eventId);
    }
    getAllNestedChildCompliances = async (row, parentOrderNo) => {
        if (row && row.subTask.length > 0) {
            await Promise.all(row.subTask.map(async (opt, keyIndex) => {
                let subOrderNo = parseFloat(parentOrderNo + "." + (keyIndex + 1));
                if (this.state.selectedCompliance.includes(opt.id) === true) {
                    allCmpList.push({ complianceId: opt.id, order: keyIndex + 1, isFinal: 1 })
                } else {
                    allCmpList.push({ complianceId: opt.id, order: keyIndex + 1, isFinal: 0 })
                }
                return await this.getAllNestedChildCompliances(opt, subOrderNo);
            }));
        }
    }
    submitEventComplianceOrder = async () => {
        if (this.state.submitAction === false) {
            const response = await commonConfirmBox(
                __t('alerts.compliance_hierarchy_confirm')
            );
            if (response) {
                this.setState({
                    submitAction: true
                })
                return false;
            }
        }
        allCmpList = [];
        this.state.complianceList.forEach(async (row, parentKeyIndex) => {
            let orderNo = parentKeyIndex + 1;
            if (this.state.selectedCompliance.includes(row.id) === true) {
                allCmpList.push({ complianceId: row.id, order: orderNo, isFinal: 1 })
            } else {
                allCmpList.push({ complianceId: row.id, order: orderNo, isFinal: 0 });
            }
            if (row && row.subTask.length > 0) {
                await Promise.all(row.subTask.map(async (opt, keyIndex) => {
                    let subOrderNo = parseFloat(orderNo + "." + (keyIndex + 1))
                    if (this.state.selectedCompliance.includes(opt.id) === true) {
                        allCmpList.push({ complianceId: opt.id, order: keyIndex + 1, isFinal: 1 })
                    } else {
                        allCmpList.push({ complianceId: opt.id, order: keyIndex + 1, isFinal: 0 });
                    }
                    return await this.getAllNestedChildCompliances(opt, subOrderNo);
                }));
            }
        })
        this.props.updateEventOrderByEventIdRequest(this.state.eventId, { compliances: allCmpList });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.eventComplianceListApiResponse &&
            this.props.eventComplianceListApiResponse !==
            nextprops.eventComplianceListApiResponse
        ) {
            if (
                nextprops.eventComplianceListApiResponse.responseType ===
                SUCCESS
            ) {
                this.setState({
                    eventData:
                        nextprops.eventComplianceListApiResponse.data
                            .eventData,
                    complianceList: nextprops.eventComplianceListApiResponse.data
                        .complianceList,
                });
            } else {
                toastrMessage(
                    nextprops.eventComplianceListApiResponse.message,
                    nextprops.eventComplianceListApiResponse.responseType
                );
            }
        }
        if (nextprops.eventOrderUpdateApiResponse && nextprops.eventOrderUpdateApiResponse !== this.props.eventOrderUpdateApiResponse) {
            toastrMessage(nextprops.eventOrderUpdateApiResponse.message, nextprops.eventOrderUpdateApiResponse.responseType)
            if (
                nextprops.eventOrderUpdateApiResponse.responseType ===
                SUCCESS
            ) {
                this.setState({
                    complianceList: [],
                    selectedCompliance: [],
                    submitAction: false
                }, () => {
                    allCmpList = [];
                    this.props.getLawComplianceListByEventIdRequest(this.state.eventId);
                })
            }
        }
    }

    onDragEnd(result) {

        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const resultData = Array.from(this.state.complianceList);
        const startIndex = resultData.findIndex((element, index) => {
            if (element.id === result.source.index) {
                return true
            }
        });
        const endIndex = resultData.findIndex((element, index) => {
            if (element.id === result.destination.index) {
                return true
            }
        });

        const [removed] = resultData.splice(startIndex, 1);
        resultData.splice(endIndex, 0, removed);


        this.setState({
            complianceList: resultData
        });
    }

    handleChecked = (value, id) => {
        if (value === true) {
            this.state.selectedCompliance.push(id)
        } else if (value === false) {
            this.state.selectedCompliance.splice(this.state.selectedCompliance.indexOf(value), 1)
        }
    }

    render() {
        let complianceLoop = this.state.complianceList;
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card className='event_compliances draggable-event-compliances'>
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        <a
                                            href={() => false}
                                            className="dripicons-arrow-thin-left"
                                            onClick={() => {
                                                this.props.hideTreeDetails()
                                            }}
                                        >
                                            { }
                                        </a>
                                        <span>
                                            {this.state.eventData?.eventName}
                                        </span>
                                    </h4>
                                    <span className='event_country'><img width={20} src={'/data/flags/' + this.state.eventData?.country?.isoCode2 + '.svg'} /> {this.state.eventData?.country?.countryName}</span>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {/* Event Compliance Tree List */}
                                <div className="event-compliances tree_details">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId={"droppable-001"}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className='droppable_box'
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >

                                                    {complianceLoop?.length > 0 &&
                                                        complianceLoop.map((compliance, key) => {

                                                            return (

                                                                <Draggable key={compliance.id} draggableId={compliance.id} index={compliance.id}>
                                                                    {(providedCompliance, snapshotCompliance) => (
                                                                        <div
                                                                            className='droppable_row'
                                                                            ref={providedCompliance.innerRef}
                                                                            {...providedCompliance.draggableProps}
                                                                            {...providedCompliance.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshotCompliance.isDragging,
                                                                                providedCompliance.draggableProps.style
                                                                            )}
                                                                        >

                                                                            <div
                                                                                className={
                                                                                    compliance.subTask.length > 0
                                                                                        ? ' tree-parent has-child '
                                                                                        : ' tree-parent'
                                                                                }
                                                                                key={compliance.id}
                                                                            >


                                                                                <EventComplianceSubItem
                                                                                    handleChecked={this.handleChecked}
                                                                                    submitAction={this.state.submitAction}
                                                                                    history={this.props.history}
                                                                                    subTask={compliance}
                                                                                    onDragReceive={(reArrangedSubTasks) => {
                                                                                        let cmpList = this.state.complianceList;
                                                                                        this.setState({
                                                                                            complianceList: cmpList.map((opt) => {
                                                                                                if (opt.id == compliance.id) {
                                                                                                    return {
                                                                                                        ...opt,
                                                                                                        subTask: reArrangedSubTasks
                                                                                                    }
                                                                                                } else {
                                                                                                    return opt
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                </Draggable>
                                                            );
                                                        })}


                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                </div>
                            </CardBody>
                            {checkModulePermission(MODULE_EVENT_MASTER, PERMISSION_EDIT) == true && this.state.complianceList?.length > 0 && this.state.eventData?.isSequenceUpdated == false && (
                                <CardFooter>
                                    <div className="d-flex justify-content-end">
                                        <Button type="button"
                                            color="primary"
                                            className="mr-2" onClick={() => {
                                                this.submitEventComplianceOrder();
                                            }}>{SUBMIT_COMPLIANCE_ORDER}</Button>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            className="mr-2"
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >
                                            {CANCEL}
                                        </Button>
                                    </div>
                                </CardFooter>
                            )}
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    eventComplianceListApiResponse: makeEventSelectField(
        'eventComplianceListApiResponse'
    ),
    eventOrderUpdateApiResponse: makeEventSelectField('eventOrderUpdateApiResponse')
});

const withConnect = connect(mapStatetoProps, {
    getLawComplianceListByEventIdRequest,
    updateEventOrderByEventIdRequest
});

export default compose(
    withConnect,
    withEventReducer,
    withEventSaga,
    withRouter
)(EventTreeDetails);
