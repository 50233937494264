import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Select from 'react-select';
import CustomisedModal from 'components/Application/CustomisedModal';
import { CANCEL } from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import { Button, Card, Col, FormGroup, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import SimpleReactValidator from 'simple-react-validator';
import Dropzone from 'react-dropzone';
import { customProofFileValidator } from 'helpers/customValidators';

class ObservationModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            formData: {
                description: '',
                receiverType: []
            },
            selectedFiles: [],
            errors: '',
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid({ ...values, attachmentFile: this.state.selectedFiles });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customProofFileValidator(file.name)) {
                errorsArray.push(
                    __t('validations.custom.observation_file')
                );
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({
                errors:
                    __t('form_labels.reporting.observation_file') +
                    ' ' +
                    __t('validations.custom.observation_file'),
            });
        } else {
            this.setState({ errors: '' });
        }
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );
        this.setState({ selectedFiles: [files[0]] });
    };
    removeFile = (file) => {
        this.setState({
            selectedFiles: [],
            errors: '',
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.formData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <FormGroup>
                                            <Field
                                                component={Select}
                                                name="receiverType"
                                                className="form-control selectbox"
                                                options={this.props.roleListForObservation}
                                                onChange={(opt) => {
                                                    let selectedValue = opt ? opt.map((e) => e.value) : [];
                                                    setFieldValue('receiverType', selectedValue);
                                                }}
                                                value={this.props.roleListForObservation?.filter((option) => values.receiverType.includes(option.value))}
                                                isClearable={true}
                                                isMulti={true}
                                                closeMenuOnSelect={
                                                    false
                                                }
                                            />
                                            <Label className="form-label">
                                                {__t('form_labels.reporting.send_to')}{' '}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t('form_labels.reporting.send_to'),
                                                values.receiverType,
                                                'required',
                                                {
                                                    messages: {
                                                        required: __t('validations.reporting.send_to_required')
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <Row className="d-flex">
                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    component="textarea"
                                                    name="description"
                                                    className="form-control"
                                                    rows={3}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.reporting.observation_remarks')}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t('form_labels.reporting.observation_remarks'),
                                                    values.description,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t('validations.reporting.observation_remarks_required')
                                                        },
                                                    }
                                                )}
                                            </FormGroup>

                                            <FormGroup className="col-lg-12">
                                                <Label>
                                                    {__t(
                                                        'form_labels.reporting.observation_file_upload'
                                                    )}
                                                </Label>
                                                <Dropzone
                                                    onDrop={(acceptedFiles) =>
                                                        this.handleAcceptedFiles(
                                                            acceptedFiles
                                                        )
                                                    }
                                                    multiple={false}
                                                >
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                    }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}
                                                            >
                                                                <input
                                                                    {...getInputProps()}
                                                                />
                                                                <h3>
                                                                    {__t(
                                                                        'form_labels.reporting.drag_drop'
                                                                    )}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {this.state.errors != '' && (
                                                    <div className="srv-validation-message">
                                                        {this.state.errors}
                                                    </div>
                                                )}
                                                <div
                                                    className="dropzone-previews mt-2"
                                                    id="file-previews"
                                                >
                                                    {this.state.selectedFiles.map(
                                                        (f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={
                                                                        i +
                                                                        '-file'
                                                                    }
                                                                >
                                                                    <div className="p-2">
                                                                        <Row className="align-items-center">
                                                                            <Col>
                                                                                <Link
                                                                                    to="#"
                                                                                    className="text-muted font-weight-bold"
                                                                                >
                                                                                    {
                                                                                        f.name
                                                                                    }
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                    <strong>
                                                                                        {
                                                                                            f.formattedSize
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            </Col>
                                                                            <Col>
                                                                                <Button
                                                                                    className="btn btn-danger btn-sm"
                                                                                    title="Delete file"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        this.removeFile(
                                                                                            f
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                    </svg>
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </FormGroup>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="mr-2"
                                                    >
                                                        {__t('form_labels.reporting.share_observation_btn')}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={this.props.onModalDismiss}
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});
export default compose(
    withConnect,
    withRouter
)(ObservationModal);
