import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getAuditListRequest
} from 'store/actions';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import auditReducer from 'store/Audit/reducer';
import auditSaga from 'store/Audit/saga';
import CustomisedDatatable from './CustomisedDatatable';
import { makeAuditSelectField } from 'store/Audit/selector';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
const auditKey = 'audit';
const withAuditReducer = injectReducer({
    key: auditKey,
    reducer: auditReducer,
});
const withAuditSaga = injectSaga({ key: auditKey, saga: auditSaga });
const columns = [
    {
        label: 'Field',
        field: 'field'
    },
    {
        label: 'Old Value',
        field: 'oldValue',
        sort: 'disabled'
    },
    {
        label: 'New Value',
        field: 'newValue',
        sort: 'disabled'
    },
    {
        label: 'User',
        field: 'user'
    },
    {
        label: 'Date Time',
        field: 'dateTime',
    },
];
class AuditLogContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditList: [],
            auditType: ''
        }
    }

    getAuditRecords = () => {
        this.props.getAuditListRequest({
            modelType: this.state.auditType,
            modelId: this.state.auditId
        });
    }
    generateTable = records => {
        let auditRecords = [];
        auditRecords = records.map(item => {
            return {
                action: item?.event,
                user: (item?.user && item.user?.fullName && item.user.fullName != null) ? item.user?.fullName : '',
                // field: item?.field,
                field: (item?.displayName && item?.displayName != null) ? item?.displayName : '',
                dateTime: getFullDateTimeFromDateTimeString(item.createdAt),
                oldValue: (item?.oldReferenceValue && item.oldReferenceValue != null) ? item?.oldReferenceValue : '',
                newValue: (item?.newReferenceValue && item.newReferenceValue != null) ? item?.newReferenceValue : '',
            }
        })
        return auditRecords;
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.auditType !== this.props.auditType || nextprops.auditId !== this.props.auditId) {
            this.setState({
                auditType: nextprops.auditType,
                auditId: nextprops.auditId
            }, () => {
                this.getAuditRecords()
            })
        }
        if (nextprops.auditList && nextprops.auditList !== this.props.auditList) {
            this.setState({
                auditList: this.generateTable(nextprops.auditList)
            })
        }
    }
    render() {
        const reactTableData = {
            columns: columns,
            rows: this.state.auditList,
        };
        return (
            <React.Fragment>
                <CustomisedDatatable
                    className="table table-bordered"
                    tableRecords={reactTableData}
                    entries={20}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    auditList: makeAuditSelectField('auditList')
});
const withConnect = connect(mapStatetoProps, {
    getAuditListRequest
});
export default compose(
    withConnect,
    withAuditReducer,
    withAuditSaga,
    withRouter
)(AuditLogContent);