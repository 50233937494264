export const ADD_EVENT_REQUEST = 'add_event_request';
export const ADD_EVENT_RESPONSE = 'add_event_response';
export const GET_ALL_EVENT_LIST_REQUEST = 'get_all_event_list_request';
export const GET_ALL_EVENT_LIST_RESPONSE = 'get_all_event_list_response';
export const GET_EVENT_BY_EVENT_ID_REQUEST = 'get_event_by_event_id_request';
export const GET_EVENT_BY_EVENT_ID_RESPONSE = 'get_event_by_event_id_response';
export const UPDATE_EVENT_REQUEST = 'update_event_request';
export const UPDATE_EVENT_RESPONSE = 'update_event_response';
export const UPDATE_EVENT_STATUS_BY_EVENT_ID_REQUEST = 'update_event_status_by_event_id_request';
export const UPDATE_EVENT_STATUS_BY_EVENT_ID_RESPONSE = 'update_event_status_by_event_id_response';
export const GET_EVENT_LIST_BY_STATUS_REQUEST = 'get_event_list_by_status_request';
export const GET_EVENT_LIST_BY_STATUS_RESPONSE = 'get_event_list_by_status_response';
export const DELETE_EVENT_BY_EVENT_ID_REQUEST = 'delete_event_by_event_id_request';
export const DELETE_EVENT_BY_EVENT_ID_RESPONSE = 'delete_event_by_event_id_response';
export const GET_EVENT_LIST_BY_SEARCH_REQUEST = 'get_event_list_by_search_request';
export const GET_EVENT_LIST_BY_SEARCH_RESPONSE = 'get_event_list_by_search_response';
export const GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_REQUEST = 'get_event_list_by_filter_for_allocation_request';
export const GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_RESPONSE = 'get_event_list_by_filter_for_allocation_response';
export const GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_REQUEST = 'get_law_compliance_list_by_event_id_request';
export const GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_RESPONSE = 'get_law_compliance_list_by_event_id_response';
export const UPDATE_EVENT_ORDER_BY_EVENT_ID_REQUEST = 'update_event_order_by_event_id_request';
export const UPDATE_EVENT_ORDER_BY_EVENT_ID_RESPONSE = 'update_event_order_by_event_id_response';