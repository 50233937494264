import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ADD_NEW,
    ENABLE_DELETE,
    SUBMIT,
    UPDATE,
} from 'constants/commonConstants';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllDelegationListRequest,
    addDelegationRequest,
    updateDelegationRequest,
    deleteDelegationRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import entityLocationReducer from 'store/ServiceDesk/Delegation/reducer';
import entityLocationSaga from 'store/ServiceDesk/Delegation/saga';
import { makeDelegationSelectField } from 'store/ServiceDesk/Delegation/selector';
import { initialDelegationDataState, listColumns } from './CommonFunctions';
import { commonConfirmBox } from 'helpers/messageHelper';
import AddDelegationForm from './AddDelegationForm';
import __t from 'i18n/translator';
import { formatDateForApi } from 'helpers/dateTimeHelper';
import { checkModulePermission } from 'helpers/projectUtils';
import { toastrMessage } from 'helpers/messageHelper';
import {
    PERMISSION_DELETE,
    DELEGATION_STATUS,
    MODULE_DELEGATION,
} from 'constants/databaseConstants';
import moment from 'moment';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';

const delegationKey = 'delegation';
const withDelegationReducer = injectReducer({
    key: delegationKey,
    reducer: entityLocationReducer,
});
const withDelegationSaga = injectSaga({
    key: delegationKey,
    saga: entityLocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delegationList: [],
            exportEntityLocationList: [],
            delegationUserData: initialDelegationDataState(),
            operationType: SUBMIT,
            isFormOpen: false,
            isAuditModalOpen: false,
            auditId: '',
        };
    }
    componentDidMount() {
        this.props.getAllDelegationListRequest();
    }
    viewDelegation(delegationUserData) {
        this.setState(
            {
                isFormOpen: true,
                delegationUserData: initialDelegationDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ delegationUserData: delegationUserData });
            }
        );
    }
    addDelegation() {
        this.setState({
            isFormOpen: true,
        });
    }
    deleteDelegation = async (delegationUserId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.delegation_delete')
        );
        if (response) {
            this.props.deleteDelegationRequest(delegationUserId);
        }
    };
    updateDelegationStatus = async (delegationUserId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.delegation_update_status')
        );
        if (response) {
            this.props.updateDelegationRequest(delegationUserId, {
                delegationStatus: DELEGATION_STATUS,
            });
        }
    };
    formSubmit = (values) => {
        let formValues = {
            ...values,
            fromDate: values.fromDate ? formatDateForApi(values.fromDate) : '',
            toDate: values.toDate ? formatDateForApi(values.toDate) : '',
        };
        if (this.state.operationType === UPDATE) {
            this.props.updateDelegationRequest(values.id, formValues);
        } else {
            this.props.addDelegationRequest(formValues);
        }
    };
    resetForm = () => {
        this.setState({
            delegationUserData: initialDelegationDataState(),
            operationType: SUBMIT,
            isFormOpen: false,
        });
    };

    generateTable = (records) => {
        let delegationRecords = [];
        delegationRecords = records.map((delegationUserData, i) => {
            let currentDate = moment().format('YYYY-MM-DD'); //today
            let fromDate = moment(
                delegationUserData.fromDate,
                'DD-MM-YYYY'
            ).format('YYYY-MM-DD'); //fromDate

            let actions = (
                <div className="text-right mr-1">
                    {delegationUserData.type === 'TEMPORARY' &&
                        delegationUserData.delegationStatus == 1 &&
                        (fromDate <= currentDate === true ? (
                            <>
                                <a
                                    href={() => false}
                                    title={__t('common.stop')}
                                    className="btn btn-link text-success tbl-action"
                                    onClick={() => {
                                        this.updateDelegationStatus(
                                            delegationUserData.id
                                        );
                                    }}
                                >
                                    <i className="fas fa-stop"></i>
                                </a>
                            </>
                        ) : (
                            <>
                                <a
                                    href={() => false}
                                    title={__t('common.cancel')}
                                    className="btn btn-link text-info tbl-action"
                                    onClick={() => {
                                        this.updateDelegationStatus(
                                            delegationUserData.id
                                        );
                                    }}
                                >
                                    <i className="fa fa-times"></i>
                                </a>
                            </>
                        ))}

                    {ENABLE_DELETE.includes('delegation') &&
                        checkModulePermission(
                            MODULE_DELEGATION,
                            PERMISSION_DELETE
                        ) === true &&
                        fromDate > currentDate && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteDelegation(
                                        delegationUserData.id
                                    );
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                </div>
            );
            return {
                entityName: delegationUserData.entity?.entityName,
                fromUser: delegationUserData.fromUser?.fullName,
                toUser: delegationUserData.toUser?.fullName,
                type: delegationUserData?.type,
                fromDate: delegationUserData?.fromDate,
                toDate: delegationUserData?.toDate,
                actions: actions,
            };
        });
        return delegationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.delegationList &&
            this.props.delegationList !== nextprops.delegationList
        ) {
            this.setState({
                exportEntityLocationList: nextprops.delegationList,
                delegationList: this.generateTable(nextprops.delegationList),
            });
        }
        if (
            nextprops.delegationApiResponse &&
            this.props.delegationApiResponse !== nextprops.delegationApiResponse
        ) {
            toastrMessage(
                nextprops.delegationApiResponse.message,
                nextprops.delegationApiResponse.responseType
            );
        }
    }

    render() {
        const data = {
            columns: listColumns,
            rows: this.state.delegationList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'delegation'} />
                        </Col>
                        {this.state.isFormOpen && (
                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <div className="page-header">
                                            <h4 className="with-back-btn">
                                                <a
                                                    href={() => false}
                                                    className="dripicons-arrow-thin-left"
                                                    onClick={() => {
                                                        this.resetForm();
                                                    }}
                                                >
                                                    { }
                                                </a>{' '}
                                                &nbsp;
                                                {this.state.operationType ===
                                                    SUBMIT
                                                    ? __t(
                                                        'delegation.add_delegation'
                                                    )
                                                    : __t(
                                                        'delegation.edit_delegation'
                                                    )}{' '}
                                            </h4>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <AddDelegationForm
                                            operationType={
                                                this.state.operationType
                                            }
                                            delegationUserData={
                                                this.state.delegationUserData
                                            }
                                            operationResponse={
                                                this.props.delegationApiResponse
                                            }
                                            onFormValid={this.formSubmit}
                                            onFormReset={this.resetForm}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {!this.state.isFormOpen && (
                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <div className="page-header">
                                            <h4>
                                                {__t(
                                                    'page_headers.delegation_list'
                                                )}
                                            </h4>
                                        </div>
                                        <div className="page-action">

                                            <a
                                                href={() => false}
                                                className="btn btn-primary waves-effect waves-light float-right ml-2"
                                                onClick={() => {
                                                    this.addDelegation();
                                                }}
                                            >
                                                <i className="fas fa-plus"> </i>{' '}
                                                {ADD_NEW}
                                            </a>

                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <CustomisedDatatable
                                            tableRecords={data}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    delegationList: makeDelegationSelectField('delegationList'),
    delegationApiResponse: makeDelegationSelectField('delegationApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getAllDelegationListRequest,
    addDelegationRequest,
    updateDelegationRequest,
    deleteDelegationRequest,
});
export default compose(
    withConnect,
    withDelegationReducer,
    withDelegationSaga,
    withRouter
)(Index);
