import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Company Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Tenant',
        field: 'tenantName',
    },
    {
        label: 'Company Group Admin',
        field: 'companyGroupAdmin',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialCompanyGroupDataState = () => {
    return {
        id: '',
        companyGroupName: '',
        companyGroupAdmin: [],
        tenantId: '',
        isActive: ACTIVE
    }
}
export const intFields = [];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};