import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'reactstrap';
import {
    ACTIVE_LABEL,
    AUTH_MODE_LC,
    EXPORT_BTN,
    INACTIVE_LABEL,
} from 'constants/commonConstants';
import __t from 'i18n/translator';
import { getCurrentDateTime } from 'helpers/dateTimeHelper';
export const ExportCSV = ({ propsData, type, auditType }) => {
    const allTypes = [
        { key: 'industry', fileName: 'Industries & Sub Industry' },
        { key: 'department', fileName: 'Departments' },
        { key: 'country', fileName: 'Countries' },
        { key: 'state', fileName: 'States' },
        { key: 'city', fileName: 'Cities' },
        { key: 'lawCategory', fileName: 'Law Categories' },
        { key: 'law', fileName: 'Laws' },
        { key: 'sectionRule', fileName: 'Section Rules' },
        { key: 'companyGroup', fileName: 'Company Groups' },
        { key: 'entity', fileName: 'Entities' },
        { key: 'entityLocation', fileName: 'Entity Locations' },
        { key: 'entityRole', fileName: 'Entity Roles' },
        { key: 'user', fileName: 'Users' },
        { key: 'systemUser', fileName: 'System Configuration Users' },
        { key: 'event', fileName: 'Events' },
        { key: 'audit', fileName: 'Audit Trail' },
    ];
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const getIndustrySheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Industry Name': record.industryName,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getSubIndustrySheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Sub Industry Name': record.subIndustryName,
            'Industry Name': record.industryName,
        }));
    };
    const getDepartmentSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Department Name': record.departmentName,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getCountrySheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Country Name': record.countryName,
            'ISO Code': record.isoCode,
            'Numeric Code': record.numericCode,
            'Dialing Code': record.dialingCode,
        }));
    };
    const getStateSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'State Name': record.stateName,
            Country: record.countryName,
        }));
    };
    const getCitySheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'City Name': record.cityName,
            State: record.stateName,
        }));
    };
    const getLawCategorySheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Law Category Name': record.lawCategoryName,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getSectionRuleSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Section/Rule Name': record.sectionName,
            'Law Short Name': record.law?.lawShortName,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getLawSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Short Name of Law/Regulation': record.lawShortName,
            'Category of Law': record.lawCategory?.lawCategoryName,
            Country: record.country?.countryName,
            State: record.state?.stateName,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getCompanyGroupSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Company Group Name': record.companyGroupName,
            'Company Group Admin':
                record.companyGroupAdmin.length > 0
                    ? record.companyGroupAdmin.join('\n')
                    : '',
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getEntitySheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Company Group': record.companyGroup?.companyGroupName,
            'Entity Name': record.entityName,
            Email: record.email,
            'Contact No': record.contactNo,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getEntityLocationSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Company Group': record.entity?.companyGroup?.companyGroupName,
            Entity: record.entity?.entityName,
            Country: record.country?.countryName,
            State: record.state?.stateName,
            City: record.city?.cityName,
            Pincode: record.pincode,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getEntityRoleSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Entity Name': record.entity?.entityName,
            'Role Name': record.role?.roleName,
            'Role Term': record.roleTerm,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getUserSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            Name: record.fullName,
            'Email ID': record.email,
            'Contact No': record.contactNo,
            'Authentication Mode':
                record.authenticationMode === AUTH_MODE_LC
                    ? __t('form_labels.masters.users.lc')
                    : __t('form_labels.masters.users.sso'),
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getEventSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            'Event Name': record.eventName,
            Country: record.country?.countryName,
            'Event Description': record.eventDescription,
            Status: record.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
        }));
    };
    const getSystemUserSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            Name: record.user?.fullName,
            'Email ID': record.user?.email,
            'Role Name': record.role?.roleName,
        }));
    };
    const getAuditLogSheetData = (recordList) => {
        return recordList.map((record, index) => ({
            'Sr. No': index + 1,
            Field: record.field,
            'Old Value': record.oldValue,
            'New Value': record.newValue,
            User: record.user,
            'Date Time': record.dateTime,
        }));
    };
    const getDataByModuleType = (propsData, type, auditType) => {
        let exportTableDataSheets = [];
        let filename = '';
        switch (type) {
            case 'industry':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    if (key == 0) {
                        exportTableData.data = getIndustrySheetData(recordList);
                        exportTableData.sheetName = 'Industry';
                        exportTableDataSheets.push(exportTableData);
                    }
                    if (key == 1) {
                        exportTableData.data =
                            getSubIndustrySheetData(recordList);
                        exportTableData.sheetName = 'Sub Industry';
                        exportTableDataSheets.push(exportTableData);
                    }
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'department':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getDepartmentSheetData(recordList);
                    exportTableData.sheetName = 'Department';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'country':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getCountrySheetData(recordList);
                    exportTableData.sheetName = 'Country';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'state':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getStateSheetData(recordList);
                    exportTableData.sheetName = 'State';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'city':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getCitySheetData(recordList);
                    exportTableData.sheetName = 'City';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'lawCategory':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getLawCategorySheetData(recordList);
                    exportTableData.sheetName = 'Law Category';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'law':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getLawSheetData(recordList);
                    exportTableData.sheetName = 'Law';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'sectionRule':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getSectionRuleSheetData(recordList);
                    exportTableData.sheetName = 'Section Rule';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'companyGroup':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getCompanyGroupSheetData(recordList);
                    exportTableData.sheetName = 'Company Group';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'entity':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    if (key == 0) {
                        exportTableData.data = getEntitySheetData(recordList);
                        exportTableData.sheetName = 'Entity';
                        exportTableDataSheets.push(exportTableData);
                    }
                    if (key == 1) {
                        exportTableData.data =
                            getEntityLocationSheetData(recordList);
                        exportTableData.sheetName = 'Entity Locations';
                        exportTableDataSheets.push(exportTableData);
                    }

                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'entityLocation':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data =
                        getEntityLocationSheetData(recordList);
                    exportTableData.sheetName = 'Entity Locations';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'entityRole':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getEntityRoleSheetData(recordList);
                    exportTableData.sheetName = 'Entity Role';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'user':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getUserSheetData(recordList);
                    exportTableData.sheetName = 'User';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'systemUser':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getSystemUserSheetData(recordList);
                    exportTableData.sheetName = 'System User';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'event':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getEventSheetData(recordList);
                    exportTableData.sheetName = 'Event';
                    exportTableDataSheets.push(exportTableData);
                }
                filename = allTypes.find((opt) => opt.key === type).fileName;
                break;
            case 'audit':
                for (let key = 0; key < propsData.length; key++) {
                    let exportTableData = { sheetName: '', data: [] };
                    const recordList = propsData[key];
                    exportTableData.data = getAuditLogSheetData(recordList);
                    exportTableData.sheetName = auditType + ' - Audit Trail';
                    exportTableDataSheets.push(exportTableData);
                }
                filename =
                    allTypes.find((opt) => opt.key === type).fileName +
                    '-' +
                    auditType;
                break;
            default:
                break;
        }
        return { sheets: exportTableDataSheets, fileName: filename };
    };
    const exportToCSV = (propsCsvData, typeData, auditTypeData) => {
        const exportTableData = getDataByModuleType(propsCsvData, typeData, auditTypeData);
        const dataSheets = exportTableData.sheets;
        const fileName = exportTableData.fileName + '_' + getCurrentDateTime();
        if (dataSheets.length > 0) {
            const sheetNames = [];
            const sheetData = {};
            for (
                let sheetIndex = 0;
                sheetIndex < dataSheets.length;
                sheetIndex++
            ) {
                const sheet = dataSheets[sheetIndex];
                const ws = XLSX.utils.json_to_sheet(sheet.data);
                sheetData[sheet.sheetName] = ws;
                sheetNames.push(sheet.sheetName);
            }
            const wb = {
                Sheets: sheetData,
                SheetNames: sheetNames,
            };
            const excelBuffer = XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'array',
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };
    return (
        <Button
            className="btn btn-warning float-right"
            onClick={(e) => {
                return propsData[0].length > 0
                    ? exportToCSV(propsData, type, auditType)
                    : false;
            }}
            disabled={propsData[0].length === 0 ? true : false}
        >
            <i className="far fa-file-excel"> </i>
            {EXPORT_BTN}
        </Button>
    );
};
