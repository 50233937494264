import crypto from "crypto";

const secretKey = process.env.REACT_APP_SECRET__KEY;
const algorithm = process.env.REACT_APP_CRYPTO_ALGORITHYM;

export const decryptText = (ciphertext) => {
  try {
    // Create a decipher using AES-256-CBC algorithm with the secret key
    const decipher = crypto.createDecipheriv(algorithm, Buffer.from(secretKey), Buffer.alloc(16));
    // Decrypt the ciphertext and get the plaintext
    let decrypted = decipher.update(ciphertext, 'base64', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
  } catch (error) {
    return null; // Return null on decryption failure
  }
}