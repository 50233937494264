import {
    DOWNLOAD_FILE_BY_MODEL_REQUEST,
    DOWNLOAD_FILE_BY_MODEL_RESPONSE
} from './actionTypes';
export const downloadFileByModelRequest = (data) => {
    return {
        type: DOWNLOAD_FILE_BY_MODEL_REQUEST,
        payload: { data },
    };
};
export const downloadFileByModelResponse = (message, responseType, data) => {
    return {
        type: DOWNLOAD_FILE_BY_MODEL_RESPONSE,
        payload: { message, responseType, data },
    };
};