import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    ENABLE_DELETE,
    NA,
    SUBMIT,
    UPDATE,
} from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllSectionListRequest,
    updateSectionStatusBySectionIdRequest,
    addSectionRequest,
    updateSectionRequest,
    deleteSectionBySectionIdRequest,
    getLawListByStatusRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import sectionReducer from 'store/Masters/Section/reducer';
import sectionSaga from 'store/Masters/Section/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { makeSectionSelectField } from 'store/Masters/Section/selector';
import { initialSectionDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox } from 'helpers/messageHelper';
import AddSectionForm from './AddSectionForm';
import __t from 'i18n/translator';
import {
    makeLawSelectField,
    selectSortedLawList,
} from 'store/Masters/Law/selector';
import AuditLogModal from 'components/Application/AuditLogModal';
import {
    MODULE_LAW_SECTION_RULE,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import { checkModulePermission } from 'helpers/projectUtils';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import ShowMoreText from 'react-show-more-text';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const sectionKey = 'section';
const withSectionReducer = injectReducer({
    key: sectionKey,
    reducer: sectionReducer,
});
const withSectionSaga = injectSaga({ key: sectionKey, saga: sectionSaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionList: [],
            exportSectionList: [],
            sectionData: initialSectionDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: '',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve section list
        this.props.getLawListByStatusRequest(ACTIVE);
        this.props.getAllSectionListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateSectionStatusBySectionIdRequest(id, checked);
    }
    viewSection(sectionData) {
        this.setState(
            {
                sectionData: initialSectionDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ sectionData: sectionData });
            }
        );
    }
    deleteSection = async (sectionId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.section_delete')
        );
        if (response) {
            this.props.deleteSectionBySectionIdRequest(sectionId);
        }
    };
    formSubmit = (values) => {
        this.setState({ sectionData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateSectionRequest(values.id, values);
        } else {
            this.props.addSectionRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            sectionData: initialSectionDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records, allCityData) => {
        let sectionRecords = [];
        sectionRecords = records.map((sectionData, i) => {
            let actions = (
                <div className="text-center">
                    {!this.props.isImport &&
                        checkModulePermission(
                            MODULE_LAW_SECTION_RULE,
                            PERMISSION_EDIT
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewSection(sectionData);
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('section') &&
                        checkModulePermission(
                            MODULE_LAW_SECTION_RULE,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteSection(sectionData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(sectionData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(sectionData.id)}
                        checked={sectionData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            let cityNames = [];
            if (sectionData?.law.cityId) {
                const cityIds = sectionData?.law.cityId.split(',') || [];
                cityIds.map((cityId) => {
                    let cityData = allCityData.find(
                        (city) => city.id == cityId
                    );
                    cityNames.push(cityData?.cityName);
                });
            }
            return {
                sectionName: sectionData.sectionName,
                lawShortName: sectionData.law?.lawShortName,
                lawCode: sectionData.law?.lawCode,
                country: sectionData.law?.country?.countryName,
                state: (sectionData.law?.stateId == '') ? NA : sectionData.law?.state?.stateName,
                city: (sectionData.law?.cityId == '') ? NA : this.getShowMoreTextElement(cityNames.join(", ")),
                isActive: isActive,
                actions: actions,
            };
        });
        return sectionRecords;
    };
    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.sectionList &&
            this.props.sectionList !== nextprops.sectionList
        ) {
            this.setState({
                exportSectionList: nextprops.sectionList,
                sectionList: this.generateTable(
                    nextprops.sectionList,
                    nextprops.allCityData
                ),
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        let listColumnsData = this.props.isImport
            ? listColumns.slice(0, -2)
            : listColumns;
        const data = {
            columns: listColumnsData,
            rows: this.state.sectionList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        {!this.props.isImport && (
                            <Col lg="12">
                                <CommonBreadcrum type={'section-rule'} />
                            </Col>
                        )}

                        <Col lg={this.props.isImport ? '12' : '7'}>
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t('page_headers.section_list')}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage
                                        activepage={this.state.activePage}
                                        onHandlePageChange={
                                            this.handlePageChange
                                        }
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>

                        {!this.props.isImport &&
                            checkModulePermission(
                                MODULE_LAW_SECTION_RULE,
                                PERMISSION_ADD
                            ) === true && (
                                <Col lg="5">
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'page_headers.section_form'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <AddSectionForm
                                                operationType={
                                                    this.state.operationType
                                                }
                                                sectionData={
                                                    this.state.sectionData
                                                }
                                                sortedLawListDropDown={
                                                    this.props
                                                        .sortedLawListDropDown
                                                }
                                                operationResponse={
                                                    this.props
                                                        .sectionApiResponse
                                                }
                                                onFormValid={this.formSubmit}
                                                onFormReset={this.resetForm}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                    </Row>
                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'sectionRule'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sectionList: makeSectionSelectField('sectionList'),
    allCityData: makeSectionSelectField('allCityData'),
    sectionApiResponse: makeSectionSelectField('sectionApiResponse'),
    lawList: makeLawSelectField('lawList'),
    sortedLawListDropDown: selectSortedLawList(),
});
const withConnect = connect(mapStatetoProps, {
    getAllSectionListRequest,
    updateSectionStatusBySectionIdRequest,
    addSectionRequest,
    updateSectionRequest,
    deleteSectionBySectionIdRequest,
    getLawListByStatusRequest,
});
export default compose(
    withConnect,
    withSectionReducer,
    withSectionSaga,
    withLawReducer,
    withLawSaga,
    withRouter
)(Index);
