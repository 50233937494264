export const GET_NEWS_LIST_REQUEST = 'get_news_list_request';
export const GET_NEWS_LIST_RESPONSE = 'get_news_list_response';
export const GET_NEWS_DETAILS_BY_ID_REQUEST = 'get_news_details_by_id_request';
export const GET_NEWS_DETAILS_BY_ID_RESPONSE = 'get_news_details_by_id_response';
export const CREATE_NEWS_REQUEST = 'create_news_request';
export const CREATE_NEWS_RESPONSE = 'create_news_response';
export const UPDATE_NEWS_REQUEST = 'update_news_request';
export const UPDATE_NEWS_RESPONSE = 'update_news_response';
export const ACTIVATE_OR_DEACTIVATE_NEWS_REQUEST = 'activate_or_deactivate_news_request';
export const ACTIVATE_OR_DEACTIVATE_NEWS_RESPONSE = 'activate_or_deactivate_news_response';
export const PUBLISH_NEWS_BY_NEWS_ID_REQUEST = 'publish_news_by_news_id_request';
export const PUBLISH_NEWS_BY_NEWS_ID_RESPONSE = 'publish_news_by_news_id_response';
export const GET_NEWS_WITH_SEARCH_REQUEST = 'get_news_with_search_request';
export const GET_NEWS_WITH_SEARCH_RESPONSE = 'get_news_with_search_response';
export const GET_ALL_RELEASE_TYPE_LIST_REQUEST = 'get_aa_release_type_list_request';
export const GET_ALL_RELEASE_TYPE_LIST_RESPONSE = 'get_aa_release_type_list_response';
