import React, { Component } from 'react';
import __t from 'i18n/translator';
import { getDayDateName, getMonthName, getTimeFromDateTimeString, getYearFromDateTimeString } from 'helpers/dateTimeHelper';
import { Col, Row } from 'reactstrap';

class AuditObservationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {

    }
    render() {

        return (
            <React.Fragment>
                <ul className="timeline list-unstyled">
                    {this.props.auditObservationList.map((record) => {
                        return (
                            <li className="timeline-list" key={'observation_' + record.id}>
                                <div className="cd-timeline-content p-2">
                                    <div className="time-line-content-header">
                                        <div className="d-flex align-items-center">
                                            <div className="mt-0 mb-2 observation_title activity_title">
                                                <span>
                                                    <svg
                                                        width="27"
                                                        height="27"
                                                        viewBox="0 0 27 27"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M13.9091 0C18.2019 0 21.6818 3.47997 21.6818 7.77273C21.6818 10.7065 20.0565 13.2606 17.6571 14.5837C22.8492 16.3205 26.5909 21.2233 26.5909 27H24.9545C24.9545 20.6738 19.8262 15.5455 13.5 15.5455C7.17382 15.5455 2.04545 20.6738 2.04545 27H0.409088C0.409088 21.0275 4.40868 15.9892 9.87561 14.4173C7.63372 13.0548 6.13636 10.5886 6.13636 7.77273C6.13636 3.47997 9.61633 0 13.9091 0ZM13.9091 1.63636C10.5201 1.63636 7.77272 4.38371 7.77272 7.77273C7.77272 11.1617 10.5201 13.9091 13.9091 13.9091C17.2981 13.9091 20.0455 11.1617 20.0455 7.77273C20.0455 4.38371 17.2981 1.63636 13.9091 1.63636Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span>
                                                <h6>
                                                    {record.senderFullName}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        {(record.observationUserIds !== null && record.observationUserIds !== '') && (
                                            <Col lg="4">
                                                <div className="recent_card">
                                                    <p>{__t('form_labels.reporting.to_user')}</p>
                                                    <span>
                                                        {this.props.observationUsers?.map((ou) => ou.fullName).join(', ')}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}

                                        {(record.observationUserIds === null || record.observationUserIds === '') && record.receiverFullName != '' && record.receiverFullName != null && (
                                            <Col lg="4">
                                                <div className="recent_card">
                                                    <p>{__t('form_labels.reporting.to_user')}</p>
                                                    <span>
                                                        {record.receiverFullName}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        <Col>
                                            <div className="recent_card">
                                                <p>
                                                    {__t('form_labels.reporting.remarks')}
                                                </p>
                                                <span>
                                                    {this.props.getShowMoreTextElement(record.description)}
                                                </span>
                                            </div>
                                        </Col>
                                        {record.fileName !== '' && record.fileName !== null && (
                                            <Col>
                                                <div className="recent_card">
                                                    <p>
                                                        {__t('form_labels.reporting.observation_file')}
                                                    </p>
                                                    <a rel="noopener noreferrer"
                                                        className="text-primary file_view_btn ml-2"
                                                        onClick={() => {
                                                            this.props.saveFile({
                                                                type: 'observation',
                                                                fileName: record.fileName,
                                                                fileUrl: record.systemFileName,
                                                            })
                                                        }}>
                                                        {record.fileName}
                                                    </a>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="date">
                                        <h5 className="m-0">
                                            {getDayDateName(record.createdAt)}
                                        </h5>
                                        <p className="mb-0 text-orange">
                                            <span className="mr-1">
                                                {getMonthName(record.createdAt)}
                                            </span>
                                            {getYearFromDateTimeString(record.createdAt)}
                                        </p>
                                        <p className="mb-0 bg-white">
                                            {getTimeFromDateTimeString(record.createdAt)}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        );
                    }
                    )}
                </ul>
            </React.Fragment>
        );
    }
}
export default AuditObservationList;
