import produce from 'immer';
import { ADD_ENTITY_RESPONSE, GET_ALL_ENTITY_LIST_RESPONSE, GET_ENTITY_BY_ENTITY_ID_RESPONSE, GET_ENTITY_LIST_BY_STATUS_RESPONSE, UPDATE_ENTITY_RESPONSE, UPDATE_ENTITY_STATUS_BY_ENTITY_ID_RESPONSE, DELETE_ENTITY_BY_ENTITY_ID_RESPONSE, GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_RESPONSE, GET_ENTITY_TEAMS_BY_ENTITY_ID_RESPONSE, GET_USER_ENTITY_LIST_RESPONSE, GET_ENTITY_LIST_BY_COUNTRY_RESPONSE, GET_ENTITY_ALL_USERS_BY_ENTITY_ID_RESPONSE, GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_RESPONSE, GET_USER_LIST_BY_ENTITY_AND_ROLE_RESPONSE, CLEAR_FILTER_ENTITY_USER_RESPONSE } from './actionTypes';
export const initialState = {
    entityApiResponse: null,
    editEntityApiResponse: null,
    deleteEntityApiResponse: null,
    entityStatusUpdateApiResponse: null,
    entityList: [],
    entityTeams: {},
    entityAllUsersList: [],
    entityListOnUserList: [],
    userListByRole: []
};
const entity = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_ENTITY_RESPONSE:
            case UPDATE_ENTITY_RESPONSE:
                draft.entityApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_ENTITY_LIST_RESPONSE:
                draft.entityList = action.payload.entityList;
                break;
            case GET_ENTITY_BY_ENTITY_ID_RESPONSE:
                draft.editEntityApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case UPDATE_ENTITY_STATUS_BY_ENTITY_ID_RESPONSE:
                draft.entityStatusUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ENTITY_LIST_BY_STATUS_RESPONSE:
                draft.entityList = action.payload.entityList;
                break;
            case DELETE_ENTITY_BY_ENTITY_ID_RESPONSE:
                draft.deleteEntityApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_RESPONSE:
                draft.entityList = action.payload.entityList;
                break;
            case GET_ENTITY_TEAMS_BY_ENTITY_ID_RESPONSE:
                draft.entityTeams = action.payload.entityTeams;
                break;
            case GET_USER_ENTITY_LIST_RESPONSE:
                draft.entityList = action.payload.entityList;
                break;
            case GET_ENTITY_LIST_BY_COUNTRY_RESPONSE:
                draft.entityList = action.payload.entityList;
                break;
            case GET_ENTITY_ALL_USERS_BY_ENTITY_ID_RESPONSE:
                draft.entityAllUsersList = action.payload.entityAllUsersList;
                break;
            case GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_RESPONSE:
                draft.entityListOnUserList = action.payload.entityListOnUserList;
                break;
            case GET_USER_LIST_BY_ENTITY_AND_ROLE_RESPONSE:
                draft.userListByRole = action.payload.userListByRole;
                break;
            case CLEAR_FILTER_ENTITY_USER_RESPONSE:
                draft.userListByRole = []
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default entity;
