export const LOGIN_REQUEST = 'login_request';
export const LOGIN_RESPONSE = 'login_response';
export const SEND_CODE_REQUEST = 'send_code_request';
export const SEND_CODE_RESPONSE = 'send_code_response';
export const LOGIN_PASSWORD_CHANGE_REQUEST = 'login_password_change_request';
export const LOGIN_PASSWORD_CHANGE_RESPONSE = 'login_password_change_response';
export const LOGOUT_REQUEST = 'logout_request';
export const LOGOUT_RESPONSE = 'logout_response';
export const API_FAILED = 'api_failed';

export const VERIFY_TOKEN_REQUEST = 'verify_token_request';
export const VERIFY_TOKEN_RESPONSE = 'verify_token_response';

export const VERIFY_CODE_REQUEST = 'verify_code_request';
export const VERIFY_CODE_RESPONSE = 'verify_code_response';