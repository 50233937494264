import swal from '@sweetalert/with-react';
import { toastr } from 'react-redux-toastr';
export const successAlertMessage = message => {
    return swal({
        title: 'Success',
        text: message,
        closeOnClickOutside: false,
        closeOnEsc: false,
    });
};
export const errorAlertMessage = (message, containHtml = false) => {
    let errorMessage = {
        title: 'Info',
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
    };
    if (containHtml) {
        // create separate div element for the HTML error messages
        var element = document.createElement('div');
        element.innerHTML = message;
        errorMessage.content = element;
    } else {
        errorMessage.text = message;
    }
    return swal(errorMessage);
};

export const cancelConfirmBox = async () => {
    const confirmBox1 = await swal({
        title: 'Are you sure?',
        text: 'Do you want to cancel this record?',
        icon: 'warning',
        dangerMode: true,
        buttons: {
            cancel: {
                text: 'No',
                value: false,
                visible: true,
            },
            confirm: {
                text: 'Yes',
                value: true,
                visible: true,
            },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
    });
    return confirmBox1;
};
export const commonConfirmBox = async (title, text, containHtml = false) => {
    let confirmBox1 = {
        title: title,
        icon: 'warning',
        dangerMode: true,
        buttons: {
            cancel: {
                text: 'No',
                value: false,
                visible: true,
            },
            confirm: {
                text: 'Yes',
                value: true,
                visible: true,
            },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
    };
    if (containHtml) {
        // create separate div element for the HTML error messages
        var element = document.createElement('div');
        element.innerHTML = text;
        confirmBox1.content = element;
    } else {
        confirmBox1.text = text;
    }
    return swal(confirmBox1);
};
export const confirmBox = async (title, text) => {
    const confirmBoxMsg = await swal({
        title: title,
        text: text,
        icon: 'warning',
        dangerMode: true,
        buttons: {
            cancel: {
                text: 'No',
                value: false,
                visible: true,
            },
            confirm: {
                text: 'Yes',
                value: true,
                visible: true,
            },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
    });
    return confirmBoxMsg;
};
export const toastrMessage = (message, type) => {
    toastr.light(message, ' ', {
        status: type
    });
};

export const permissionDeniedMessage = (message, containHtml = false) => {
    let errorMessage = {
        title: 'No Access',
        icon: 'error',
        closeOnClickOutside: false,
        closeOnEsc: false,
    };
    if (containHtml) {
        // create separate div element for the HTML error messages
        var element = document.createElement('div');
        element.innerHTML = message;
        errorMessage.content = element;
    } else {
        errorMessage.text = message;
    }
    return swal(errorMessage);
};

export const commonConfirmBoxWithRemarks = async (title, text, placeholder) => {
    var textarea = document.createElement("textarea");
    textarea.className = 'form-control';
    textarea.placeholder = placeholder;
    let confirmBox2 = {
        content: textarea,
        title: title,
        icon: 'warning',
        dangerMode: true,
        buttons: {
            cancel: {
                text: 'No',
                value: false,
                visible: true,
            },
            confirm: {
                text: 'Yes',
                value: true,
                visible: true,
            },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
    };
    confirmBox2.text = text;

    return await swal(confirmBox2).then(result => {
        return { result, textarea };
    });
};

export const confirmBoxByIconWithRemarks = async (title, text, placeholder, icon, dangerMode = false) => {
    var textarea = document.createElement("textarea");
    textarea.className = 'form-control';
    textarea.placeholder = placeholder;
    let confirmBox = {
        content: textarea,
        title: title,
        icon: icon,
        dangerMode: dangerMode,
        buttons: {
            cancel: {
                text: 'Cancel',
                value: false,
                visible: true,
            },
            confirm: {
                text: 'Submit',
                value: true,
                visible: true,
            },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
    };
    confirmBox.text = text;

    return await swal(confirmBox).then(result => {
        return { result, textarea };
    });
};