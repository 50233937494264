import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label, Progress } from 'reactstrap';
import {
    ACTIVE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
    NONE,
    SUCCESS,
    UPLOAD,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import Select from 'react-select';
import __t from 'i18n/translator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
import {
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getArchiveDocumentListRequest,
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDateObjectWithValueFromMoment, formatDateForApi } from 'helpers/dateTimeHelper';
import { customDocumentFileValidator } from 'helpers/customValidators';
import Dropzone from 'react-dropzone';
import CustomisedModal from 'components/Application/CustomisedModal';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import {
    makeArchiveRecordSelectField,
    selectSortedArchiveDepartmentList,
} from 'store/ArchiveRecords/selector';
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

class MultipleUploadDocumentForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDocumentFileValidator()),
        });
        this.state = {
            selectedFile: [],
            errors: '',
            fileProgress: [],
            uploadedFiles: [],
            sortedEntityListDropdown: [],
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }
    componentDidMount() {
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getArchiveDocumentListRequest();
    }
    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customDocumentFileValidator(file.name)) {
                errorsArray.push('Please select valid file');
                return false;
            }
            return file;
        });
        if (errorsArray.length > 0) {
            this.setState({ errors: ['Please select valid file'] });
        } else {
            files.map((file) => {
                // push new item to the state value
                this.state.fileProgress.push({
                    fileName: file.name,
                    percentage: 0,
                });
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: this.formatBytes(file.size),
                });
                return file;
            });
            this.setState({
                selectedFile: files,
                errors: [],
            });
        }
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
        if (
            nextprops.sortedEntityListDropdown &&
            nextprops.sortedEntityListDropdown !==
            this.props.sortedEntityListDropdown
        ) {
            this.setState({
                sortedEntityListDropdown: nextprops.sortedEntityListDropdown,
            });
        }
        if (
            nextprops.uploadArchiveRecordsApiResponse &&
            this.props.uploadArchiveRecordsApiResponse !==
            nextprops.uploadArchiveRecordsApiResponse
        ) {
            toastrMessage(
                nextprops.uploadArchiveRecordsApiResponse.message,
                nextprops.uploadArchiveRecordsApiResponse.responseType
            );
            if (
                nextprops.uploadArchiveRecordsApiResponse.responseType ===
                SUCCESS
            ) {
                this.resetForm();
            } else {
                this.state.selectedFile.forEach((file, i) => {
                    if (!this.state.uploadedFiles.includes(file.name)) {
                        let items = [...this.state.fileProgress];
                        let itemIndex = items.findIndex(
                            (filedata) => filedata.fileName === file.name
                        );
                        items[itemIndex].percentage = 0;
                        this.setState({
                            fileProgress: items,
                        });
                    }
                });
            }
        }
    }
    removeFile = (file) => {
        var array = [...this.state.selectedFile]; // make a separate copy of the array
        var index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ selectedFile: array }, () => {
                if (this.state.selectedFile.length === 0) {
                    this.setState({
                        uploadedFiles: [],
                        errors: [],
                        fileProgress: [],
                    });
                }
            });
        }
    };
    resetForm = () => {
        this.setState(
            {
                selectedFile: [],
                errors: '',
                fileProgress: [],
                uploadedFiles: [],
            },
            () => {
                this.validator.hideMessages();
                this.props.onModalDismiss();
            }
        );
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            if (this.state.selectedFile.length === 0) {
                this.setState({
                    errors: __t(
                        'validations.document_repository.document_file_required'
                    ),
                });
            } else {
                if (this.state.errors.length === 0) {
                    const data = new FormData();
                    this.state.selectedFile.forEach((file, i) => {
                        if (!this.state.uploadedFiles.includes(file.name)) {
                            let items = [...this.state.fileProgress];
                            let itemIndex = items.findIndex(
                                (filedata) => filedata.fileName === file.name
                            );
                            items[itemIndex].percentage = 100;
                            this.setState(
                                {
                                    fileProgress: items,
                                },
                                () => {
                                    data.append('file', file);
                                }
                            );
                        }
                    });
                    data.append('companyGroupId', values.companyGroupId);
                    data.append('entityId', values.entityId);
                    data.append(
                        'archiveDepartmentId',
                        values.archiveDepartmentId
                    );
                    data.append('fromDate', formatDateForApi(values.fromDate));
                    data.append(
                        'toDate',
                        values.toDate ? formatDateForApi(values.toDate) : null
                    );

                    this.props.onFormValid(data);
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={
                        'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider auditlog_modal archive_upload_modal'
                    }
                    modalName={__t('page_headers.new_upload')}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => {
                        this.resetForm();
                    }}
                >
                    <Formik
                        initialValues={Object.assign(
                            {},
                            this.props.multipleDocumentData
                        )}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>

                                <Row>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component={Select}
                                            className="form-control selectbox"
                                            options={this.props.sortedCompanyGroupListDropdown.filter(
                                                (opt) => opt.value != NONE
                                            )}
                                            onChange={(opt) => {
                                                let companyGroup = opt
                                                    ? opt.value
                                                    : '';
                                                setFieldValue(
                                                    'companyGroupId',
                                                    companyGroup
                                                );
                                                setFieldValue('entityId', '');
                                                this.setState(
                                                    {
                                                        sortedEntityListDropdown:
                                                            [],
                                                    },
                                                    () => {
                                                        if (companyGroup) {
                                                            this.props.getEntityListByCompanyGroupIdRequest(
                                                                [companyGroup]
                                                            );
                                                        }
                                                    }
                                                );
                                            }}
                                            value={this.props.sortedCompanyGroupListDropdown.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.companyGroupId
                                            )}
                                            isClearable={true}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.archive_records.company_group'
                                            )}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.allocation.company_group'
                                            ),
                                            values.companyGroupId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.archive_records.company_group_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component={Select}
                                            name="entityId"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .sortedEntityListDropdown
                                            }
                                            onChange={(opt) => {
                                                setFieldValue(
                                                    'entityId',
                                                    opt ? opt.value : ''
                                                );
                                            }}
                                            value={this.state.sortedEntityListDropdown?.filter(
                                                (option) =>
                                                    values.entityId ==
                                                    option.value
                                            )}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.archive_records.entity'
                                            )}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.archive_records.entity'
                                            ),
                                            values.entityId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.archive_records.entity_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component={Select}
                                            name="archiveDepartmentId"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedArchiveDocumentListDropdown
                                            }
                                            onChange={(opt) => {
                                                setFieldValue(
                                                    'archiveDepartmentId',
                                                    opt ? opt.value : ''
                                                );
                                            }}
                                            value={this.props.sortedArchiveDocumentListDropdown.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.archiveDepartmentId
                                            )}
                                            isClearable={true}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.archive_records.archive_department'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.archive_records.archive_department'
                                            ),
                                            values.archiveDepartmentId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.archive_records.archive_department_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-3 sticky_label">
                                        <Field
                                            component={DatePicker}
                                            className="form-control"
                                            name="fromDate"
                                            selected={
                                                values.fromDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.fromDate
                                                    )
                                                    : null
                                            }
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            onChange={(date) => {
                                                setFieldValue('fromDate', date);
                                                let dateCheck =
                                                    getDateObjectWithValueFromMoment(
                                                        values.toDate
                                                    ) <
                                                        getDateObjectWithValueFromMoment(
                                                            date
                                                        )
                                                        ? true
                                                        : false;
                                                if (dateCheck) {
                                                    setFieldValue('toDate', '');
                                                }
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            placeholderText={
                                                DATE_FORMAT_PLACEHOLDER
                                            }
                                            onFocus={(e) => e.target.blur()}
                                        //  maxDate={new Date()}
                                        ></Field>
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.archive_records.from_period'
                                            )}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.archive_records.from_period'
                                            ),
                                            values.fromDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.fromDate
                                                )
                                                : '',
                                            'required|customDate',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.archive_records.from_period_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-3 sticky_label">
                                        <Field
                                            component={DatePicker}
                                            className="form-control"
                                            name="toDate"
                                            selected={
                                                values.toDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.toDate
                                                    )
                                                    : null
                                            }
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            onChange={(date) => {
                                                setFieldValue('toDate', date);
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            placeholderText={
                                                DATE_FORMAT_PLACEHOLDER
                                            }
                                            onFocus={(e) => e.target.blur()}
                                            minDate={getDateObjectWithValueFromMoment(
                                                values?.fromDate
                                            )}
                                            disabled={
                                                values.numberOfDays > 0
                                                    ? true
                                                    : false
                                            }
                                        ></Field>
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.archive_records.to_period'
                                            )}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.archive_records.to_period'
                                            ),
                                            values.toDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.toDate
                                                )
                                                : '',
                                            'required|customDate',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.archive_records.to_period_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-12">
                                        <Dropzone
                                            onDrop={(acceptedFiles) =>
                                                this.handleAcceptedFiles(
                                                    acceptedFiles
                                                )
                                            }
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => (
                                                <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        <h3>
                                                            {__t(
                                                                'form_labels.upload.drag_drop'
                                                            )}
                                                            <small>
                                                                {__t(
                                                                    'form_labels.archive_records.accepted_file_ext'
                                                                )}
                                                            </small>
                                                        </h3>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {this.state.errors != '' && (
                                            <div className="srv-validation-message">
                                                {this.state.errors}
                                            </div>
                                        )}

                                        <Row>
                                            {this.state.selectedFile.length >
                                                0 && (
                                                    <Col>
                                                        <div
                                                            className="dropzone-previews mt-3"
                                                            id="file-previews"
                                                        >
                                                            <Row>
                                                                {this.state.selectedFile.map(
                                                                    (f, i) => {
                                                                        let currentFileProgressData =
                                                                            this.state.fileProgress.find(
                                                                                (
                                                                                    fileP
                                                                                ) =>
                                                                                    fileP.fileName ===
                                                                                    f.name
                                                                            );
                                                                        return (
                                                                            <Col
                                                                                key={
                                                                                    i +
                                                                                    '-file'
                                                                                }
                                                                                md="4"
                                                                                className="animate__animated animate__fadeInRight"
                                                                            >
                                                                                <div className="file-upload shadow-none border p-2">
                                                                                    <div className="file-upload-info mr-2">
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="upload-document-title"
                                                                                        >
                                                                                            {
                                                                                                f.name
                                                                                            }
                                                                                        </Link>
                                                                                        {!this.state.uploadedFiles.includes(
                                                                                            f.name
                                                                                        ) && (
                                                                                                <div className="progress_bar">
                                                                                                    <Progress
                                                                                                        animated
                                                                                                        color="primary"
                                                                                                        style={{
                                                                                                            height: '1rem',
                                                                                                        }}
                                                                                                        value={
                                                                                                            this
                                                                                                                .state
                                                                                                                .fileProgress[
                                                                                                                i
                                                                                                            ]
                                                                                                                .percentage
                                                                                                        }
                                                                                                    ></Progress>
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                    {currentFileProgressData?.percentage ===
                                                                                        0 && (
                                                                                            <Button
                                                                                                className="btn btn-danger btn-sm"
                                                                                                title="Delete file"
                                                                                                type="button"
                                                                                                onClick={() =>
                                                                                                    this.removeFile(
                                                                                                        f
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    currentFileProgressData?.percentage >
                                                                                                        0
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            >
                                                                                                <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                                </svg>
                                                                                            </Button>
                                                                                        )}
                                                                                    {this.state.uploadedFiles.includes(
                                                                                        f.name
                                                                                    ) && (
                                                                                            <Button
                                                                                                className="btn btn-success btn-sm"
                                                                                                title="Delete file"
                                                                                                type="button"
                                                                                            >
                                                                                                <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                                </svg>
                                                                                            </Button>
                                                                                        )}
                                                                                </div>
                                                                            </Col>
                                                                        );
                                                                    }
                                                                )}
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                )}
                                        </Row>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                            <div className="d-inline-block">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {UPLOAD}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={this.resetForm}
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedArchiveDocumentListDropdown: selectSortedArchiveDepartmentList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    uploadArchiveRecordsApiResponse: makeArchiveRecordSelectField(
        'uploadArchiveRecordsApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getArchiveDocumentListRequest,
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(MultipleUploadDocumentForm);
