import React, { Component } from 'react';
import {
    Row,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    CardHeader,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    ACTIVE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    SUBMIT,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    getCompanyGroupListByStatusRequest,
    addProjectRequest,
    getProjectDetailsRequest,
    updateProjectRequest,
    getEntityListByCompanyGroupIdRequest,
    getProjectTaskUsersByEntityRequest,
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';

import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { makeProjectsSelectField } from 'store/Masters/Projects/selector';
import {
    customDateValidator,
    formatDateForApi,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import { customValidTextField } from 'helpers/customValidators';
import {
    initialEntityDataState,
    initialProjectTeamsDataState,
} from './CommonFunctions';
import __t from 'i18n/translator';
import AddEditProjectTeamsFormModal from './AddEditProjectTeamsFormModal';

import { selectSortedEntityAllUsersList } from 'store/Masters/Projects/selector';
import { selectSortedEntityList } from 'store/Entity/selector';

import projectsReducer from 'store/Masters/Projects/reducer';
import ProjectsSaga from 'store/Masters/Projects/saga';
import { getInitials } from 'helpers/generalUtils';

const projectsKey = 'projects';
const withProjectsReducer = injectReducer({
    key: projectsKey,
    reducer: projectsReducer,
});
const withProjectsSaga = injectSaga({
    key: projectsKey,
    saga: ProjectsSaga,
});

const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

class ProjectForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField(),
                customDateValidator()
            ),
        });
        this.state = {
            operationType: SUBMIT,
            projectId: this.props.match.params.projectId
                ? this.props.match.params.projectId
                : '',
            pageHeaderName: this.props.match.params.projectId
                ? __t('page_headers.edit_project')
                : __t('page_headers.add_project'),
            entityData: initialEntityDataState(),
            projectTeamsData: {},
            isModalOpen: false,
            projectTeamsOperationType: SUBMIT,
            isProjectTeamsEdit: false,
            isProjectModalOpen: false,
            sortedEntityListDropdown: [],
        };
    }
    componentDidMount() {
        if (this.state.projectId) {
            this.props.getProjectDetailsRequest(this.state.projectId);
        }
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
    }

    openProjectModal = (projectId) => {
        this.setState({
            isProjectModalOpen: true,
            projectId: projectId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isProjectModalOpen: false,
            projectId: '',
        });
    };

    addNewProjectTeams() {
        this.setState({
            isModalOpen: true,
            projectTeamsOperationType: SUBMIT,
            projectTeamsData: initialProjectTeamsDataState(),
            isProjectTeamsEdit: false,
        });
    }
    editProjectYear = (data) => {
        this.setState({
            isModalOpen: true,
            projectTeamsOperationType: UPDATE,
            projectTeamsData: data,
            isProjectTeamsEdit: true,
        });
    };
    deleteProjectYear = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.project_team_delete')
        );

        if (response) {
            let projectTeams = values.projectTeams.filter(
                (obj) => obj !== data
            );
            setFieldValue('projectTeams', projectTeams);
        }
    };
    closeProjectsTeamsModal() {
        this.setState(
            {
                isModalOpen: true,
                projectTeamsOperationType: SUBMIT,
                projectTeamsData: {},
                isProjectTeamsEdit: false,
            },
            () => {
                this.setState({
                    isModalOpen: false,
                });
            }
        );
    }
    generateProjectTable = (records, setFieldValue, values) => {
        let financialYearRecords = [];
        financialYearRecords = records.map((projectsRecord, i) => {
            let actions = (
                <div className="text-center">
                    {projectsRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => this.editProjectYear(projectsRecord)}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteProjectYear(
                                projectsRecord,
                                setFieldValue,
                                values
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                    {projectsRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openProjectModal(projectsRecord.id);
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );

            let selectedValue =
                this.props.selectSortedEntityAllUsersDropdown?.filter(
                    (option) => option.value === projectsRecord?.userId
                );

            return {
                users: selectedValue[0]?.label,
                isOwner: projectsRecord.isOwner ? 'Yes' : 'No',
                actions: actions,
            };
        });
        return financialYearRecords.reverse();
    };

    getUserNameFilter = (userId) => {
        let userName = this.props.selectSortedEntityAllUsersDropdown?.filter(
            (option) => option.value === userId
        )[0]?.['label'];
        if (userName != '') {
            return userName;
        } else {
            return '';
        }
    };

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = {
                ...values,
                startDate: formatDateForApi(values.startDate),
                endDate:
                    values.endDate != ''
                        ? formatDateForApi(values.endDate)
                        : '',
            };
            if (this.state.operationType === UPDATE) {
                this.props.updateProjectRequest(
                    this.state.projectId,
                    formValues
                );
            } else {
                this.props.addProjectRequest(formValues);
            }
        } else {
            window.scrollTo(0, 0);
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    getEntityListByCompanyGroup(companyGroupId) {
        this.props.getEntityListByCompanyGroupIdRequest([companyGroupId]);
    }
    getProjectTaskUsersByEntity(entityId) {
        this.props.getProjectTaskUsersByEntityRequest(entityId);
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.projectApiResponse &&
            this.props.projectApiResponse !== nextprops.projectApiResponse
        ) {
            toastrMessage(
                nextprops.projectApiResponse.message,
                nextprops.projectApiResponse.responseType
            );
            if (nextprops.projectApiResponse.responseType === SUCCESS) {
                this.props.history.push('/projects');
            }
        }

        if (
            nextprops.editProjectApiResponse &&
            this.props.editProjectApiResponse !==
            nextprops.editProjectApiResponse
        ) {
            this.getEntityListByCompanyGroup(
                nextprops.editProjectApiResponse?.data?.list?.companyGroupId
            );

            let entityId =
                nextprops.editProjectApiResponse?.data?.list?.projectEntities.map(
                    (x) => x.entityId
                );
            this.getProjectTaskUsersByEntity({
                entityId: entityId,
            });

            let projectTeamsJson = [];

            let data =
                nextprops.editProjectApiResponse?.data?.list.projectTeams?.map(
                    (x) =>
                        projectTeamsJson.push({
                            id: 0,
                            userId: x.userId,
                            isOwner: x.isOwner,
                        })
                );

            this.setState({
                operationType: UPDATE,
                entityData: {
                    title: nextprops.editProjectApiResponse?.data?.list?.title,
                    companyGroupId:
                        nextprops.editProjectApiResponse?.data?.list
                            ?.companyGroupId,
                    startDate: getDateObjectWithValueFromMoment(
                        nextprops.editProjectApiResponse?.data?.list?.startDate
                    ),
                    endDate: nextprops.editProjectApiResponse?.data?.list
                        ?.endDate
                        ? getDateObjectWithValueFromMoment(
                            nextprops.editProjectApiResponse?.data?.list
                                ?.endDate
                        )
                        : '',
                    description:
                        nextprops.editProjectApiResponse?.data?.list
                            ?.description,
                    entityId:
                        nextprops.editProjectApiResponse?.data?.list?.projectEntities.map(
                            (x) => x.entityId
                        ),
                    isActive:
                        nextprops.editProjectApiResponse?.data?.list?.isActive,
                    projectTeams: projectTeamsJson,
                },
            });
        }

        if (
            nextprops.sortedEntityListDropdown &&
            this.props.sortedEntityListDropdown !==
            nextprops.sortedEntityListDropdown
        ) {
            this.setState(
                {
                    sortedEntityListDropdown:
                        nextprops.sortedEntityListDropdown,
                },
                () => {
                    this.setState({
                        entityData: this.state.entityData,
                    });
                }
            );
        }
    }
    render() {
        let userName = '';
        return (
            <React.Fragment>
                <div className="page-header">
                    <h4 className="with-back-btn mt-0 d-flex">
                        <a
                            href={() => false}
                            className="dripicons-arrow-thin-left"
                            onClick={() => {
                                this.props.history.push('/projects');
                            }}
                        >
                            { }
                        </a>{' '}
                        &nbsp;{this.state.pageHeaderName}{' '}
                    </h4>
                </div>
                <Formik
                    initialValues={this.state.entityData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="add-entity add-project-form">

                            <div className="row">
                                <div className="col-4 col-md-5">
                                    <Card>
                                        <CardHeader className="py-2">
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'form_labels.entity.basic_details'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        name="title"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.projects.title'
                                                        )}
                                                        value={values.title}
                                                        maxLength={250}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.projects.title'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.projects.title'
                                                        ),
                                                        values.title,
                                                        'required|max:250|onlyCharacters',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.projects.title_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6">
                                                    <Field
                                                        component={Select}
                                                        name="companyGroupId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedCompanyGroupListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'companyGroupId',
                                                                opt.value
                                                            );
                                                            setFieldValue(
                                                                'entityId',
                                                                []
                                                            );

                                                            this.getEntityListByCompanyGroup(
                                                                opt.value
                                                            );
                                                            setFieldValue(
                                                                'projectTeams',
                                                                []
                                                            );
                                                        }}
                                                        value={this.props.sortedCompanyGroupListDropdown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.companyGroupId
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.entity.company_group'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.entity.company_group'
                                                        ),
                                                        values.companyGroupId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.entity.company_group_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6">
                                                    <Field
                                                        component={Select}
                                                        name="entityId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .sortedEntityListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'entityId',
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : []
                                                            );
                                                            this.getProjectTaskUsersByEntity(
                                                                {
                                                                    entityId:
                                                                        opt
                                                                            ? opt.map(
                                                                                (
                                                                                    x
                                                                                ) =>
                                                                                    x.value
                                                                            )
                                                                            : [],
                                                                }
                                                            );
                                                            setFieldValue(
                                                                'projectTeams',
                                                                []
                                                            );
                                                        }}
                                                        value={this.state.sortedEntityListDropdown?.filter(
                                                            (option) =>
                                                                values.entityId.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti={true}

                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.entity'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.events.plan.entity'
                                                        ),
                                                        values.entityId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.events.plan.entity_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6 w-100 sticky_label">
                                                    <Field
                                                        name="startDate"
                                                        component={DatePicker}
                                                        isClearable={true}
                                                        className="form-control"
                                                        placeholderText={__t(
                                                            'form_labels.projects.start_date'
                                                        )}
                                                        selected={
                                                            values.startDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.startDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                `startDate`,
                                                                date
                                                            );

                                                            let dateCheck =
                                                                getDateObjectWithValueFromMoment(
                                                                    values.endDate
                                                                ) <
                                                                    getDateObjectWithValueFromMoment(
                                                                        date
                                                                    )
                                                                    ? true
                                                                    : false;
                                                            if (dateCheck) {
                                                                setFieldValue(
                                                                    'endDate',
                                                                    ''
                                                                );
                                                            }
                                                        }}

                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        dropdownMode="select"
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.projects.start_date'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.projects.start_date'
                                                        ),
                                                        values.startDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.startDate
                                                            )
                                                            : '',
                                                        'required|customDate',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.projects.start_date_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6 w-100 sticky_label">
                                                    <Field
                                                        name="endDate"
                                                        component={DatePicker}
                                                        isClearable={true}
                                                        className="form-control"
                                                        placeholderText={__t(
                                                            'form_labels.projects.end_date'
                                                        )}
                                                        selected={
                                                            values.endDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.endDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                `endDate`,
                                                                date
                                                            );
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        dropdownMode="select"
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                        minDate={getDateObjectWithValueFromMoment(
                                                            values?.startDate
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.projects.end_date'
                                                        )}{' '}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component="textarea"
                                                        name="description"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.projects.description'
                                                        )}
                                                        value={
                                                            values.description
                                                        }
                                                        maxLength={1000}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.projects.description'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                            </Row>
                                            <div className="d-inline-block">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    Submit
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={() => {
                                                        this.props.history.push(
                                                            '/projects'
                                                        );
                                                    }}
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="col-8 col-md-7">
                                    <div className="card">
                                        <div className="project-team-header">
                                            <div className="page-header">
                                                <h5>
                                                    {__t(
                                                        'form_labels.projects.project_teams'
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="project-team-body">
                                            <ul className="m-0 p-0">
                                                <li
                                                    className={
                                                        this.state
                                                            .isModalOpen ===
                                                            true
                                                            ? 'add_project show_form'
                                                            : 'add_project'
                                                    }
                                                    onClick={() =>
                                                        this.addNewProjectTeams()
                                                    }
                                                >
                                                    {this.state.isModalOpen ===
                                                        true ? (
                                                        <AddEditProjectTeamsFormModal
                                                            selectSortedUserList={
                                                                this.props
                                                                    .selectSortedEntityAllUsersDropdown
                                                            }
                                                            projectTeamsData={
                                                                this.state
                                                                    .projectTeamsData
                                                            }
                                                            projectList={
                                                                values.projectTeams
                                                            }
                                                            onFormValid={(
                                                                finValues
                                                            ) => {
                                                                setFieldValue(
                                                                    'projectTeams',
                                                                    [
                                                                        ...values.projectTeams,
                                                                        finValues,
                                                                    ]
                                                                );
                                                                this.closeProjectsTeamsModal();
                                                            }}
                                                            onModalDismiss={() =>
                                                                this.closeProjectsTeamsModal()
                                                            }
                                                        />
                                                    ) : (
                                                        <a
                                                            href={() => false}
                                                            title="Add Project Team"
                                                        >
                                                            <svg
                                                                width="71"
                                                                height="71"
                                                                viewBox="0 0 94 94"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect
                                                                    x="0.5"
                                                                    y="0.5"
                                                                    width="93"
                                                                    height="93"
                                                                    rx="9.5"
                                                                    stroke="currentColor"
                                                                    strokeDasharray="5 5"
                                                                ></rect>
                                                                <path
                                                                    d="M48.5 32L48.5 61"
                                                                    stroke="currentColor"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                ></path>
                                                                <path
                                                                    d="M63 46.5L34 46.5"
                                                                    stroke="currentColor"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                ></path>
                                                            </svg>
                                                        </a>
                                                    )}
                                                </li>
                                                {values.projectTeams?.map(
                                                    (users) => (
                                                        <li>
                                                            <div>
                                                                <div className="d-none">
                                                                    {
                                                                        (userName =
                                                                            this.getUserNameFilter(
                                                                                users.userId
                                                                            ))
                                                                    }
                                                                </div>
                                                                <span className="user-box">
                                                                    {getInitials(
                                                                        userName
                                                                    )}
                                                                </span>
                                                                <h6>
                                                                    {userName}
                                                                </h6>
                                                                <Label className="m-0">
                                                                    Owner:{' '}
                                                                    {users.isOwner ===
                                                                        true
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </Label>
                                                            </div>
                                                            <div className="mt-2">
                                                                <a
                                                                    href={() => false}
                                                                    className="btn btn-danger"
                                                                    title={__t(
                                                                        'common.delete'
                                                                    )}
                                                                    onClick={() => {
                                                                        this.deleteProjectYear(
                                                                            users,
                                                                            setFieldValue,
                                                                            values
                                                                        );
                                                                    }}
                                                                >
                                                                    Delete
                                                                </a>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    editProjectApiResponse: makeProjectsSelectField('editProjectApiResponse'),
    selectSortedEntityAllUsersDropdown: selectSortedEntityAllUsersList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    projectApiResponse: makeProjectsSelectField('addProjectApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getCompanyGroupListByStatusRequest,
    addProjectRequest,
    getProjectDetailsRequest,
    updateProjectRequest,
    getEntityListByCompanyGroupIdRequest,
    getProjectTaskUsersByEntityRequest,
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withEntityReducer,
    withEntitySaga,
    withProjectsReducer,
    withProjectsSaga,
    withRouter
)(ProjectForm);
