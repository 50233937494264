import produce from 'immer';
import { ADD_LAW_CATEGORY_RESPONSE, DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE, GET_ALL_LAW_CATEGORY_LIST_RESPONSE, GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE, GET_LAW_CATEGORY_LIST_BY_STATUS_RESPONSE, UPDATE_LAW_CATEGORY_RESPONSE, UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_RESPONSE } from './actionTypes';
export const initialState = {
    lawCategoryApiResponse: null,
    editLawCategoryApiResponse: null,
    lawCategoryList: []
};
const lawCategory = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_LAW_CATEGORY_RESPONSE:
            case UPDATE_LAW_CATEGORY_RESPONSE:
            case UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_RESPONSE:
            case DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE:
                draft.lawCategoryApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_LAW_CATEGORY_LIST_RESPONSE:
                draft.lawCategoryList = action.payload.lawCategoryList;
                break;
            case GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE:
                draft.editLawCategoryApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;



            case GET_LAW_CATEGORY_LIST_BY_STATUS_RESPONSE:
                draft.lawCategoryList = action.payload.lawCategoryList;
                break;


            default:
                state = { ...state };
                break;
        }
    });
export default lawCategory;
