import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { getDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import { getInitials } from 'helpers/generalUtils';
import parse from 'html-react-parser';

class ActivityDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {

    }

    render() {
        let fundActivityType = (this.props.activity.fundActivityTypeId) ? this.props.fundActivityTypes.find((type) => type.id == this.props.activity.fundActivityTypeId) : null;
        return (
            <React.Fragment>
                <div className="chat-title">
                    <span className="assigne-box green-round" title={__t(this.props.activity.user.fullName)}>
                        {getInitials(this.props.activity.user.fullName)}
                    </span>
                </div>
                <div className="ml-3">
                    <div>
                        <h5>
                            {(this.props.loginUserId == this.props.activity.createdBy) ? 
                            'You' : this.props.activity.user.fullName}
                        </h5>
                        <div className="small-title">
                            <p className="m-0">
                                {(fundActivityType) ? fundActivityType.activityTypeName : ''}
                            </p>
                            <small>
                                {getDateTimeFromDateTimeString(this.props.activity.createdAt)}
                            </small>
                        </div>
                    </div>
                    <div>
                        <p className="mb-1 text-left">
                            <p>
                                {(this.props.activity.fundActivityTypeId === 10) && (
                                    <>
                                        <p>
                                            <strong>Old Date</strong>:{' '}
                                            {this.props.activity?.fundTaskRevisedDates?.oldDate}
                                        </p>
                                        <p>
                                            <strong>Revised Date</strong>:{' '}
                                            {this.props.activity?.fundTaskRevisedDates?.revisedDate}
                                        </p>
                                        <br></br>
                                    </>
                                )}
                                {this.props.activity.fundActivityTypeId === 12 && (
                                    <>
                                        <p>
                                            <strong>Old Date </strong>:{' '}
                                            {this.props.activity?.fundTaskRevisedDates?.oldDate}
                                        </p>
                                        <p>
                                            <strong>Revised Date</strong>:{' '}
                                            {this.props.activity?.fundTaskRevisedDates?.revisedDate}
                                        </p>
                                    </>
                                )}
                            </p>
                            {this.props.activity?.fundActivityTypeId == 13 ?
                                this.props.activity?.remarks.replace(/fund-tasks\//gi, '')
                                : ''}
                            {(this.props.activity.fundActivityTypeId !== 10 &&
                                this.props.activity.fundActivityTypeId !== 12 &&
                                this.props.activity?.fundActivityTypeId !== 13) ??
                                this.props.activity?.remarks != null ?
                                parse(this.props.activity?.remarks.toString())
                                : ''}
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});

export default compose(withConnect, withRouter)(ActivityDetails);
