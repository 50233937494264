import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { ACTIVE, CANCEL, DANGER, INACTIVE, SUBMIT, SUCCESS, UPDATE } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import CustomisedModal from 'components/Application/CustomisedModal';
import { compareString } from 'helpers/generalUtils';
import { getLegalUpdateReferenceIdRequest, updateLawDefinitionRequest, addLawDefinitionRequest } from 'store/actions';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { makeLawDefinitionSelectField } from 'store/LawDefinition/selector';
import { displayModeValues } from 'constants/dropdowns';
import { CONFIRMED, DEACTIVATION, DEACTIVATION_DEFINITION } from 'constants/databaseConstants';
class DefinitionForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            lawId: this.props.lawId,
            operationType: SUBMIT,
            legalUpdateReferenceList: [],
            legalUpdateReferenceDropdownList: [],
            isDefinitionActiveFreeze: true
        };
    }
    componentDidMount() {
        this.props.getLegalUpdateReferenceIdRequest(this.state.lawId, 'definition_edit');
    }
    setFreezeValues = (id) => {
        let legalUpdateData = this.state.legalUpdateReferenceList.find((x) => x.id === id);
        let updateType = legalUpdateData.updateTypeId ? legalUpdateData.updateTypeId.split(',').map(Number) : [];
        let subUpdateType = legalUpdateData.subUpdateTypeId ? legalUpdateData.subUpdateTypeId.split(',').map(Number) : [];
        if (updateType.includes(DEACTIVATION) && legalUpdateData.legalUpdateStatusId == CONFIRMED) {
            if (updateType.includes(DEACTIVATION)) {
                this.setState({
                    isDefinitionActiveFreeze: subUpdateType.includes(DEACTIVATION_DEFINITION) ? false : true
                });
            }
        } else {
            this.setState({
                isDefinitionActiveFreeze: true
            });
        }
    };
    resetForm = (refreshList = false) => {
        this.setState({
            operationType: SUBMIT,
            legalUpdateReferenceDropdownList: [],
        }, () => {
            this.props.onModalDismiss(refreshList);
        });
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = { ...values, lawId: this.state.lawId };
            if (this.state.operationType === UPDATE) {
                formValues.lawDefinitionId = this.props.lawDefinitionId;
                this.props.updateLawDefinitionRequest(this.props.lawDefinitionId, formValues);
            } else {
                this.props.addLawDefinitionRequest(formValues);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.lawDefinitionId !== this.props.lawDefinitionId) {
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.setState({
                operationType: (nextprops.lawDefinitionId > 0) ? UPDATE : SUBMIT
            }, () => {
                this.props.getLegalUpdateReferenceIdRequest(this.state.lawId, 'definition_edit');
            })
        }
        if (
            nextprops.legalUpdateReferenceList &&
            this.props.legalUpdateReferenceList !==
            nextprops.legalUpdateReferenceList
        ) {
            // if (nextprops.legalUpdateReferenceList.length === 0) {
            //     toastrMessage(__t('alerts.permission_denied'), DANGER);
            // } else {
            this.setState({
                legalUpdateReferenceList:
                    nextprops.legalUpdateReferenceList,
                legalUpdateReferenceDropdownList:
                    nextprops.legalUpdateReferenceList
                        .map((opt) => ({
                            value: opt.id,
                            label: opt.legalUpdateCode,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
            // }
        }
        if (
            nextprops.updateLawDefinitionApiResponse &&
            this.props.updateLawDefinitionApiResponse !==
            nextprops.updateLawDefinitionApiResponse
        ) {
            if (nextprops.updateLawDefinitionApiResponse.message != '') {
                toastrMessage(
                    nextprops.updateLawDefinitionApiResponse.message,
                    nextprops.updateLawDefinitionApiResponse.responseType
                );
            }
            if (nextprops.updateLawDefinitionApiResponse.responseType === SUCCESS) {
                this.resetForm(true);
            }
        }
        if (
            nextprops.addLawDefinitionApiResponse &&
            this.props.addLawDefinitionApiResponse !==
            nextprops.addLawDefinitionApiResponse
        ) {
            if (nextprops.addLawDefinitionApiResponse.message != '') {
                toastrMessage(
                    nextprops.addLawDefinitionApiResponse.message,
                    nextprops.addLawDefinitionApiResponse.responseType
                );
            }
            if (nextprops.addLawDefinitionApiResponse.responseType === SUCCESS) {
                this.resetForm(true);
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={(this.state.operationType === UPDATE) ? __t('form_labels.definitions.edit_definition') : __t('form_labels.definitions.add_definition')}
                    isModalOpen={this.props.isModalOpen}
                    width="150%"
                    onModalDismiss={() => {
                        this.resetForm();
                    }}
                >
                    <Formik
                        initialValues={Object.assign(
                            {},
                            this.props.lawDefinitionData
                        )}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <Row>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="referenceId"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .legalUpdateReferenceDropdownList
                                            }
                                            onChange={(opt) => {
                                                setFieldValue(
                                                    'referenceId',
                                                    opt.value
                                                );
                                                if (this.state.operationType === UPDATE) {
                                                    this.setFreezeValues(opt.value);
                                                }
                                            }}
                                            value={this.state.legalUpdateReferenceDropdownList?.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.referenceId
                                            )}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.compliance.legal_update_reference'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.compliance.legal_update_reference'
                                            ),
                                            values.referenceId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.statutory_compliances.legal_update_reference_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    {this.state.operationType === SUBMIT && (

                                        <FormGroup className="col-lg-2">
                                            <Field
                                                component={Select}
                                                name="displayMode"
                                                className="form-control selectbox"
                                                options={displayModeValues}
                                                onChange={(opt) => {
                                                    let displayModeValue =
                                                        opt
                                                            ? opt.value
                                                            : '';
                                                    setFieldValue(
                                                        'displayMode',
                                                        displayModeValue
                                                    );
                                                }}
                                                value={displayModeValues?.filter(
                                                    (option) =>
                                                        option.value ===
                                                        values.displayMode
                                                )}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.laws.display_mode'
                                                )}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.laws.display_mode'
                                                ),
                                                values.displayMode,
                                                'required',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.laws.display_mode_required'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    )}
                                    <FormGroup className={this.state.operationType === SUBMIT ? "col-lg-6" : "col-lg-8"}>
                                        <Field
                                            name="terminology"
                                            className="form-control"
                                            placeholder={__t(
                                                'form_labels.definitions.terminology'
                                            )}
                                            value={values.terminology}
                                            maxLength={250}
                                            disabled={(this.state.operationType === UPDATE)}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.definitions.terminology'
                                            )}{' '}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            name="section"
                                            className="form-control"
                                            placeholder={__t('form_labels.definitions.section')}
                                            value={values.section}
                                            maxLength={255}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.definitions.section'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.definitions.section'
                                            ),
                                            values.section,
                                            'required|max:255',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.definitions.section_required'
                                                    ),
                                                    max: __t(
                                                        'validations.definitions.section_max',
                                                        {
                                                            max: 255,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>

                                    <FormGroup className="col-lg-6">
                                        <Field
                                            name="rule"
                                            className="form-control"
                                            placeholder={__t('form_labels.definitions.rule')}
                                            value={values.rule}
                                            maxLength={255}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.definitions.rule'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.definitions.rule'
                                            ),
                                            values.rule,
                                            'required|max:255',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.definitions.rule_required'
                                                    ),
                                                    max: __t(
                                                        'validations.definitions.rule_max',
                                                        {
                                                            max: 255,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-12">
                                        <Field
                                            component="textarea"
                                            name="definition"
                                            className="form-control"
                                            placeholder={__t(
                                                'form_labels.definitions.definition'
                                            )}
                                            value={values.definition}
                                            maxLength={5000}
                                            rows="5"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.definitions.definition'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.definitions.definition'
                                            ),
                                            values.definition,
                                            'required|max:5000',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.definitions.definition_required'
                                                    ),
                                                    max: __t(
                                                        'validations.definitions.definition_max',
                                                        {
                                                            max: 5000,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>

                                    <FormGroup className="col-lg-12">
                                        <Field
                                            component="textarea"
                                            name="definitionRemarks"
                                            className="form-control"
                                            placeholder={__t(
                                                'form_labels.definitions.cross_referencing'
                                            )}
                                            value={values.definitionRemarks}
                                            maxLength={1000}
                                            rows="3"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.definitions.cross_referencing'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.definitions.cross_referencing'
                                            ),
                                            values.definitionRemarks,
                                            'required|max:1000',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.definitions.cross_referencing_required'
                                                    ),
                                                    max: __t(
                                                        'validations.definitions.cross_referencing_max',
                                                        {
                                                            max: 1000,
                                                        }
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    {this.state.operationType === UPDATE && (
                                        <FormGroup className="col-lg-4 sticky_label radio_btn">
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isActive"
                                                    id="isActive1"
                                                    disabled={this.state.isDefinitionActiveFreeze}
                                                    onChange={(opt) => {
                                                        if (opt) {
                                                            setFieldValue(
                                                                'isActive',
                                                                ACTIVE
                                                            );
                                                        } else {
                                                            setFieldValue(
                                                                'isActive',
                                                                INACTIVE
                                                            );
                                                        }
                                                    }}
                                                    value={ACTIVE}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    for="isActive1"
                                                >
                                                    {__t(
                                                        'form_labels.compliance.active'
                                                    )}
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="isActive"
                                                    id="isActive2"
                                                    disabled={this.state.isDefinitionActiveFreeze}
                                                    onChange={(opt) => {
                                                        if (opt) {
                                                            setFieldValue(
                                                                'isActive',
                                                                INACTIVE
                                                            );
                                                        } else {
                                                            setFieldValue(
                                                                'isActive',
                                                                ACTIVE
                                                            );
                                                        }
                                                    }}
                                                    value={INACTIVE}
                                                />
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.compliance.inActive'
                                                    ),
                                                    values.isActive,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required:
                                                                __t(
                                                                    'validations.statutory_compliances.required_active'
                                                                ),
                                                        },
                                                    }
                                                )}
                                                <Label
                                                    className="form-check-label"
                                                    for="isActive2"
                                                >
                                                    {__t(
                                                        'form_labels.compliance.inActive'
                                                    )}
                                                </Label>
                                            </div>

                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.compliance.status'
                                                )}
                                            </Label>
                                        </FormGroup>
                                    )}
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                            <div className="d-inline-block">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {this.state.operationType}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={this.resetForm}
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    legalUpdateReferenceList: makeLawComplianceSelectField('legalUpdateReferenceList'),
    updateLawDefinitionApiResponse: makeLawDefinitionSelectField('updateLawDefinitionApiResponse'),
    addLawDefinitionApiResponse: makeLawDefinitionSelectField('addLawDefinitionApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getLegalUpdateReferenceIdRequest,
    updateLawDefinitionRequest,
    addLawDefinitionRequest
});
export default compose(withConnect, withRouter)(DefinitionForm);
