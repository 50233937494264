import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_COUNTRY_REQUEST, DELETE_COUNTRY_BY_COUNTRY_ID_REQUEST, GET_ALL_COUNTRY_LIST_REQUEST, GET_COUNTRY_BY_COUNTRY_ID_REQUEST, GET_COUNTRY_LIST_BY_STATUS_REQUEST, UPDATE_COUNTRY_REQUEST, UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_REQUEST, GET_GCM_AUTH_USER_COUNTRY_LIST_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addCountryResponse, deleteCountryByCountryIdResponse, getAllCountryListRequest, getAllCountryListResponse, getCountryByCountryIdResponse, getCountryListByStatusResponse, updateCountryResponse, updateCountryStatusByCountryIdResponse, getGcmAuthUserCountryListResponse } from './actions';
import { COUNTRY, COUNTRY_BY_STATUS, GCM_AUTH_USER_COUNTRIES } from 'helpers/apiConstants';
function* addCountry({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', COUNTRY, data);
        if (response.status) {
            yield put(getAllCountryListRequest());
            yield put(addCountryResponse(response.message, SUCCESS));
        } else {
            yield put(addCountryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllCountryList() {
    try {
        const response = yield call(invokeApi, 'get', COUNTRY, '');
        if (response.status) {
            yield put(getAllCountryListResponse(response.data.list));
        } else {
            yield put(getAllCountryListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getCountryByCountryId({ payload: { countryId } }) {
    try {
        const response = yield call(invokeApi, 'get', COUNTRY + '/' + countryId, '');
        if (response.status) {
            yield put(
                getCountryByCountryIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getCountryByCountryIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateCountry({ payload: { countryId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', COUNTRY + '/' + countryId, data);
        if (response.status) {
            yield put(getAllCountryListRequest());
            yield put(updateCountryResponse(response.message, SUCCESS));
        } else {
            yield put(updateCountryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateCountryStatusByCountryId({
    payload: { countryId, status },
}) {
    try {
        const response = yield call(invokeApi, 'patch', +COUNTRY + '/' + countryId + '/status/' + status);
        if (response.status) {
            yield put(getAllCountryListRequest());
            yield put(updateCountryStatusByCountryIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateCountryStatusByCountryIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getCountryListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', COUNTRY_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getCountryListByStatusResponse(response.data.list));
        } else {
            yield put(getCountryListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteCountryByCountryId({
    payload: { countryId },
}) {
    try {
        const response = yield call(invokeApi, 'delete', COUNTRY + '/' + countryId);
        if (response.status) {
            yield put(getAllCountryListRequest());
            yield put(deleteCountryByCountryIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteCountryByCountryIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getGcmAuthUserCountryList() {
    try {
        const response = yield call(invokeApi, 'get', GCM_AUTH_USER_COUNTRIES, '');
        if (response.status) {
            yield put(getGcmAuthUserCountryListResponse(response.data.list));
        } else {
            yield put(getGcmAuthUserCountryListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchCountry() {
    yield takeEvery(ADD_COUNTRY_REQUEST, addCountry);
    yield takeEvery(GET_ALL_COUNTRY_LIST_REQUEST, getAllCountryList);
    yield takeEvery(GET_COUNTRY_BY_COUNTRY_ID_REQUEST, getCountryByCountryId);
    yield takeEvery(UPDATE_COUNTRY_REQUEST, updateCountry);
    yield takeEvery(UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_REQUEST, updateCountryStatusByCountryId);
    yield takeEvery(GET_COUNTRY_LIST_BY_STATUS_REQUEST, getCountryListByStatus);
    yield takeEvery(DELETE_COUNTRY_BY_COUNTRY_ID_REQUEST, deleteCountryByCountryId);
    yield takeEvery(GET_GCM_AUTH_USER_COUNTRY_LIST_REQUEST, getGcmAuthUserCountryList);
}
function* countrySaga() {
    yield all([fork(watchCountry)]);
}
export default countrySaga;