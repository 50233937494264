import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';
import parse from 'html-react-parser';

class MenuItemWithChildren extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    SubMenuClick = event => {
        event.preventDefault();
        const subMenu = event.target.nextSibling;
        const parent = event.target.parentElement;
        var width = window.innerWidth;
        if (width < 992) {
            if (subMenu) subMenu.classList.toggle('open');
            if (parent) parent.classList.toggle('open');
        }
        return false;
    };

    render() {
        const item = this.props.item || {};
        return (
            <React.Fragment>
                {item.modules && this.props.loginUserModules.some(moduleAccess => item.modules?.includes(moduleAccess)) && (
                    <li className={item.class_name}>
                        <Link to="" className="waves-effect waves-light" onClick={this.SubMenuClick}>
                            {item.svgIcon && (
                                <React.Fragment>
                                    {parse(item.svgIcon)}
                                </React.Fragment>
                            )}
                            <span> {item.label ? item.label : null}{' '}
                                {item.badgeicon ? <span className={item.badgeicon}>{item.bagdevalue} </span> :
                                    <span className="float-right menu-arrow">
                                        <i className="mdi mdi-chevron-right"></i>
                                    </span>}
                            </span>
                        </Link>
                        <ul className="submenu">
                            {item.children.map((child, i) => {
                                if (child.children) {
                                    return <MenuItemWithChildren item={child} key={i} loginUserModules={this.props.loginUserModules} />
                                } else {
                                    if (child.modules && this.props.loginUserModules.some(moduleAccess => child.modules?.includes(moduleAccess))) {
                                        return <li key={i}> <MenuItem item={child} /></li>
                                    }
                                }
                            })}
                        </ul>
                    </li>
                )}
            </React.Fragment>
        );

    }

}

export default MenuItemWithChildren;
