import produce from 'immer';
import {
    DOWNLOAD_FILE_BY_MODEL_RESPONSE
} from './actionTypes';
export const initialState = {
    downloadFileApiResponse: null
};
const common = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case DOWNLOAD_FILE_BY_MODEL_RESPONSE:
                draft.downloadFileApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default common;
