import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_ENTITY_REQUEST, DELETE_ENTITY_BY_ENTITY_ID_REQUEST, GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_REQUEST, GET_ALL_ENTITY_LIST_REQUEST, GET_ENTITY_ALL_USERS_BY_ENTITY_ID_REQUEST, GET_ENTITY_BY_ENTITY_ID_REQUEST, GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_REQUEST, GET_ENTITY_LIST_BY_COUNTRY_REQUEST, GET_ENTITY_LIST_BY_STATUS_REQUEST, GET_ENTITY_TEAMS_BY_ENTITY_ID_REQUEST, GET_USER_ENTITY_LIST_REQUEST, GET_USER_LIST_BY_ENTITY_AND_ROLE_REQUEST, UPDATE_ENTITY_REQUEST, UPDATE_ENTITY_STATUS_BY_ENTITY_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addEntityResponse, deleteEntityByEntityIdResponse, getAllEntityListOnUserListPageResponse, getAllEntityListRequest, getAllEntityListResponse, getEntityAllUsersByEntityIdResponse, getEntityByEntityIdResponse, getEntityListByCompanyGroupIdResponse, getEntityListByCountryResponse, getEntityListByStatusResponse, getEntityTeamsByEntityIdResponse, getUserEntityListResponse, getUserListByEntityAndRoleResponse, updateEntityResponse, updateEntityStatusByEntityIdResponse } from './actions';
import { ENTITY, ENTITY_ALL_USERS_BY_ENTITY, ENTITY_BY_COMPANY_GROUP, ENTITY_BY_COUNTRY, ENTITY_BY_STATUS, ENTITY_DETAILS, ENTITY_TEAM_BY_ENTITY, USER_BY_ENTITY_AND_ROLE, USER_ENTITY } from 'helpers/apiConstants';
function* addEntity({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ENTITY, data);
        if (response.status) {
            yield put(getAllEntityListRequest());
            yield put(addEntityResponse(response.message, SUCCESS));
        } else {
            yield put(addEntityResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllEntityList() {
    try {
        const response = yield call(invokeApi, 'get', ENTITY, '');
        if (response.status) {
            yield put(getAllEntityListResponse(response.data.list));
        } else {
            yield put(getAllEntityListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEntityByEntityId({ payload: { entityId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_DETAILS + '/' + entityId);
        if (response.status) {
            yield put(
                getEntityByEntityIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getEntityByEntityIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateEntity({ payload: { entityId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', ENTITY + '/' + entityId, data);
        if (response.status) {
            yield put(updateEntityResponse(response.message, SUCCESS));
        } else {
            yield put(updateEntityResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateEntityStatusByEntityId({
    payload: { entityId, status },
}) {
    try {
        const response = yield call(invokeApi, 'patch', ENTITY + '/' + entityId + '/status/' + status);
        if (response.status) {
            yield put(getAllEntityListRequest());
            yield put(
                updateEntityStatusByEntityIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateEntityStatusByEntityIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getEntityListByStatusResponse(response.data.list));
        } else {
            yield put(getEntityListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteEntityByEntityId({
    payload: { entityId },
}) {
    try {
        const response = yield call(invokeApi, 'delete', ENTITY + '/' + entityId);
        if (response.status) {
            yield put(getAllEntityListRequest());
            yield put(
                deleteEntityByEntityIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteEntityByEntityIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityListByCompanyGroupId({ payload: { companyGroupIds } }) {
    try {
        const response = yield call(invokeApi, 'post', ENTITY_BY_COMPANY_GROUP, { companyGroupIds: companyGroupIds });
        if (response.status) {
            yield put(getEntityListByCompanyGroupIdResponse(response.data.list));
        } else {
            yield put(getEntityListByCompanyGroupIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityTeamsByEntityId({ payload: { entityId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_TEAM_BY_ENTITY + '/' + entityId);
        if (response.status) {
            yield put(getEntityTeamsByEntityIdResponse(response.data.entity));
        } else {
            yield put(getEntityTeamsByEntityIdResponse({}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityAllUsersByEntityId({ payload: { entityId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_ALL_USERS_BY_ENTITY + '/' + entityId);
        if (response.status) {
            yield put(getEntityAllUsersByEntityIdResponse(response.data.entity.allEntityUsers));
        } else {
            yield put(getEntityAllUsersByEntityIdResponse({}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUserEntityList({ payload: { roleId } }) {
    try {
        const response = yield call(invokeApi, 'get', USER_ENTITY + '/' + roleId, '');
        if (response.status) {
            yield put(getUserEntityListResponse(response.data.list));
        } else {
            yield put(getUserEntityListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEntityListByCountry({ payload: { countryIds } }) {
    try {
        const response = yield call(invokeApi, 'post', ENTITY_BY_COUNTRY, { countryId: countryIds });
        if (response.status) {
            yield put(getEntityListByCountryResponse(response.data.list));
        } else {
            yield put(getEntityListByCountryResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAllEntityListOnUserListPage() {
    try {
        const response = yield call(invokeApi, 'get', ENTITY, '');
        if (response.status) {
            yield put(getAllEntityListOnUserListPageResponse(response.data.list));
        } else {
            yield put(getAllEntityListOnUserListPageResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUserListByEntityAndRole({ payload: { entityIds, roleIds } }) {
    try {
        const response = yield call(invokeApi, 'post', USER_BY_ENTITY_AND_ROLE, { entityId: entityIds, roleId: roleIds });
        if (response.status) {
            yield put(getUserListByEntityAndRoleResponse(response.data.list));
        } else {
            yield put(getUserListByEntityAndRoleResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchEntity() {
    yield takeEvery(ADD_ENTITY_REQUEST, addEntity);
    yield takeEvery(GET_ALL_ENTITY_LIST_REQUEST, getAllEntityList);
    yield takeEvery(GET_ENTITY_BY_ENTITY_ID_REQUEST, getEntityByEntityId);
    yield takeEvery(UPDATE_ENTITY_REQUEST, updateEntity);
    yield takeEvery(UPDATE_ENTITY_STATUS_BY_ENTITY_ID_REQUEST, updateEntityStatusByEntityId);
    yield takeEvery(GET_ENTITY_LIST_BY_STATUS_REQUEST, getEntityListByStatus);
    yield takeEvery(DELETE_ENTITY_BY_ENTITY_ID_REQUEST, deleteEntityByEntityId);
    yield takeEvery(GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_REQUEST, getEntityListByCompanyGroupId);
    yield takeEvery(GET_ENTITY_TEAMS_BY_ENTITY_ID_REQUEST, getEntityTeamsByEntityId);
    yield takeEvery(GET_ENTITY_ALL_USERS_BY_ENTITY_ID_REQUEST, getEntityAllUsersByEntityId);
    yield takeEvery(GET_USER_ENTITY_LIST_REQUEST, getUserEntityList);
    yield takeEvery(GET_ENTITY_LIST_BY_COUNTRY_REQUEST, getEntityListByCountry);
    yield takeEvery(GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_REQUEST, getAllEntityListOnUserListPage);
    yield takeEvery(GET_USER_LIST_BY_ENTITY_AND_ROLE_REQUEST, getUserListByEntityAndRole);

}
function* entitySaga() {
    yield all([fork(watchEntity)]);
}
export default entitySaga;