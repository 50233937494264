export const ALLOCATE_COMPLIANCES_TO_ENTITY_REQUEST =
    'allocate_compliances_to_entity_request';
export const ALLOCATE_COMPLIANCES_TO_ENTITY_RESPONSE =
    'allocate_compliances_to_entity_response';
export const ALLOCATE_CHECK_COMPLIANCES_ENTITY_REQUEST =
    'allocate_check_compliances_entity_request';
export const ALLOCATE_CHECK_COMPLIANCES_ENTITY_RESPONSE =
    'allocate_check_compliances_entity_response';
export const GET_ALLOCATION_SEARCH_LIST_REQUEST =
    'get_allocation_search_list_request';
export const GET_ALLOCATION_SEARCH_LIST_RESPONSE =
    'get_allocation_search_list_response';
export const GET_TRACK_ALLOCATION_SEARCH_LIST_REQUEST =
    'get_track_allocation_search_list_request';
export const GET_TRACK_ALLOCATION_SEARCH_LIST_RESPONSE =
    'get_track_allocation_search_list_response';
export const DELETE_ALLOCATION_BY_ALLOCATION_ID_REQUEST =
    'delete_allocation_by_allocation_id_request';
export const DELETE_ALLOCATION_BY_ALLOCATION_ID_RESPONSE =
    'delete_allocation_by_allocation_id_response';
export const ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_REQUEST =
    'assign_user_and_department_by_allocation_id_request';
export const ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_RESPONSE =
    'assign_user_and_department_by_allocation_id_response';
export const UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_REQUEST =
    'update_allocation_status_by_allocation_ids_request';
export const UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_RESPONSE =
    'update_allocation_status_by_allocation_ids_response';
export const GET_SCHEDULE_LIST_BY_ALLOCATION_ID_REQUEST =
    'get_schedule_list_by_allocation_id_request';
export const GET_SCHEDULE_LIST_BY_ALLOCATION_ID_RESPONSE =
    'get_schedule_list_by_allocation_id_response';
export const UPDATE_SCHEDULE_BY_ALLOCATION_ID_REQUEST =
    'update_schedule_by_allocation_id_request';
export const UPDATE_SCHEDULE_BY_ALLOCATION_ID_RESPONSE =
    'update_schedule_by_allocation_id_response';
export const GET_USER_ASSIGNED_COMPLIANCES_REQUEST =
    'get_user_assigned_compliances_request';
export const GET_USER_ASSIGNED_COMPLIANCES_RESPONSE =
    'get_user_assigned_compliances_response';
export const UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_REQUEST =
    'update_view_schedule_status_by_allocation_task_id_request';
export const UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_RESPONSE =
    'update_view_schedule_status_by_allocation_task_id_response';
export const GET_ASSIGNED_EVENTS_LIST_REQUEST =
    'get_assigned_events_list_request';
export const GET_ASSIGNED_EVENTS_LIST_RESPONSE =
    'get_assigned_events_list_response';
export const GET_ASSIGNED_EVENT_COMPLIANCES_LIST_REQUEST =
    'get_assigned_event_compliances_list_request';
export const GET_ASSIGNED_EVENT_COMPLIANCES_LIST_RESPONSE =
    'get_assigned_event_compliances_list_response';
export const CHANGE_STATUS_TRACK_ALLOCATION_REQUEST =
    'change_status_track_allocation_request';
export const CHANGE_STATUS_TRACK_ALLOCATION_RESPONSE =
    'change_status_track_allocation_response';
export const GET_TRACK_ALLOCATION_BY_COMPLIANCE_REQUEST =
    'get_track_allocation_by_compliance_request';
export const GET_TRACK_ALLOCATION_BY_COMPLIANCE_RESPONSE =
    'get_track_allocation_by_compliance_response';
export const GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_REQUEST =
    'get_track_allocation_activities_by_compliance_request';
export const GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_RESPONSE =
    'get_track_allocation_activities_by_compliance_response';

export const UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_REQUEST =
    'update_tasks_schedule_renewal_date_request';
export const UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_RESPONSE =
    'update_tasks_schedule_renewal_date_response';
export const DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_REQUEST =
    'delete_track_allocations_by_compliance_request';
export const DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_RESPONSE =
    'delete_track_allocations_by_compliance_response';
