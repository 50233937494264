import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.fundMaster || initialState;
const makeFundMasterSelectField = field =>
    createSelector(reducerState, fundMasterState => fundMasterState[field]);
const selectSortedFundMasterList = () =>
    createSelector(makeFundMasterSelectField('fundMasterList'), fundMasterList =>
        fundMasterList.map(opt => ({
            value: opt.id,
            label: opt.fundName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeFundMasterSelectField,
    selectSortedFundMasterList
};
