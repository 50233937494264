import { SAVE_USER_NOTIFICATION_REQUEST, GET_USER_NOTIFICATION_REQUEST, READ_USER_NOTIFICATION_REQUEST, READ_NOTIFICATION_BY_ID_REQUEST } from "./actionTypes";
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getUserNotificationResponse, readNotificationByIdResponse, readUserNotificationResponse, saveUserNotificationResponse } from "./actions";
import { NOTIFICATION, NOTIFICATIONS, READ_NOTIFICATION, READ_NOTIFICATIONS } from "helpers/apiConstants";
import { DANGER, SUCCESS } from "constants/commonConstants";
import { invokeApi } from "helpers/axiosHelper";
import { handleApiCallException } from "store/Shared/actions";

function* saveUserNotification({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', NOTIFICATION, data);
        if (response.status) {
            yield put(saveUserNotificationResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(saveUserNotificationResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getUserNotifications() {
    try {
        const response = yield call(invokeApi, 'get', NOTIFICATIONS, '');
        if (response.status) {
            yield put(getUserNotificationResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getUserNotificationResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* readUserNotification({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            READ_NOTIFICATIONS,
            data
        );
        if (response.status) {
            yield put(readUserNotificationResponse(response.message, SUCCESS));

        } else {
            yield put(readUserNotificationResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* readNotificationById({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            READ_NOTIFICATION + id
        );
        if (response.status) {
            yield put(readNotificationByIdResponse(response.message, SUCCESS));

        } else {
            yield put(readNotificationByIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchNotification() {
    yield takeEvery(SAVE_USER_NOTIFICATION_REQUEST, saveUserNotification);
    yield takeEvery(GET_USER_NOTIFICATION_REQUEST, getUserNotifications);
    yield takeEvery(READ_USER_NOTIFICATION_REQUEST, readUserNotification);
    yield takeEvery(READ_NOTIFICATION_BY_ID_REQUEST, readNotificationById);
}
function* notificationSaga() {
    yield all([fork(watchNotification)]);
}
export default notificationSaga;