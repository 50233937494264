import React from 'react';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import slide_img_1 from '../../images/slide__01.png';
import slide_img_2 from '../../images/slide__02.png';
import slide_img_3 from '../../images/slide__03.png';
import slide_img_4 from '../../images/slide__04.png';
import { Card } from 'reactstrap';

const LoginSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        // fade: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        adaptiveHeight: true,
        lazyLoad: true,
    };
    return (
        <Slider {...settings}>
                        <div className='small_slide'>
                                <span>
                                    <img src={slide_img_1} alt="login_bg"/>
                                </span>
                                <p>Unique global dashboards with consolidated view and access to compliance data across countries & entities</p>
                          
                        </div>
                        <div className='small_slide'>
                                <span>
                                    <img src={slide_img_2} alt="login_bg"/>
                                </span>
                                <p>24*7 Helpdesk & Virtual Assistant powered by AI</p>
                            
                        </div>
                        <div className='small_slide'>
                                <span>
                                    <img src={slide_img_3} alt="login_bg"/>
                                </span>
                                <p>In-Built Project & Task Management Application</p>
                            
                        </div>
                        <div className='small_slide'>
                                <span>
                                    <img src={slide_img_4} alt="login_bg"/>
                                </span>
                                <p>Management of legacy documents, compliance evidence, internal reports, contracts etc. with dedicated access for each system user.</p>
                            
                        </div>
                    </Slider>
    );
};

export default LoginSlider;
