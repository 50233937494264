export const ADD_TENANT_REQUEST = 'add_tenant_request';
export const ADD_TENANT_RESPONSE = 'add_tenant_response';
export const GET_ALL_TENANT_LIST_REQUEST = 'get_all_tenant_list_request';
export const GET_ALL_TENANT_LIST_RESPONSE = 'get_all_tenant_list_response';
export const GET_TENANT_LIST_REQUEST = 'get_tenant_list_request';
export const GET_TENANT_LIST_RESPONSE = 'get_tenant_list_response';
export const GET_TENANT_BY_TENANT_ID_REQUEST = 'get_tenant_by_tenant_id_request';
export const GET_TENANT_BY_TENANT_ID_RESPONSE = 'get_tenant_by_tenant_id_response';
export const UPDATE_TENANT_REQUEST = 'update_tenant_request';
export const UPDATE_TENANT_RESPONSE = 'update_tenant_response';
export const UPDATE_TENANT_STATUS_BY_TENANT_ID_REQUEST = 'update_tenant_status_by_tenant_id_request';
export const UPDATE_TENANT_STATUS_BY_TENANT_ID_RESPONSE = 'update_tenant_status_by_tenant_id_response';
export const GET_TENANT_LIST_BY_STATUS_REQUEST = 'get_tenant_list_by_status_request';
export const GET_TENANT_LIST_BY_STATUS_RESPONSE = 'get_tenant_list_by_status_response';
export const DELETE_TENANT_BY_TENANT_ID_REQUEST = 'delete_tenant_by_tenant_id_request';
export const DELETE_TENANT_BY_TENANT_ID_RESPONSE = 'delete_tenant_by_tenant_id_response';
export const GENERATE_DATABASE_BY_TENANT_ID_REQUEST = 'generate_database_by_tenant_id_request';
export const GENERATE_DATABASE_BY_TENANT_ID_RESPONSE = 'generate_database_by_tenant_id_response';