import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADD_NEW, ENABLE_DELETE, NA } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import classnames from 'classnames';
import {
    getAllLawListRequest,
    updateLawStatusByLawIdRequest,
    deleteLawByLawIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { makeLawSelectField } from 'store/Masters/Law/selector';
import { listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_LAW,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import Section from '../Section/Index'
import ReactTooltip from 'react-tooltip';
import ShowMoreText from 'react-show-more-text';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportLawList: [],
            lawList: [],
            isAuditModalOpen: false,
            auditId: '',
            activeTab: 'lawTab',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve law list
        this.props.getAllLawListRequest();
    }
    editLawDetails = (record) => {
        this.props.history.push('/laws/manage/edit/' + record.id);
    };
    addLawDetails = () => {
        this.props.history.push('/laws/manage/add');
    };
    onStatusChange(checked, e, id) {
        this.props.updateLawStatusByLawIdRequest(id, checked);
    }
    deleteLaw = async (lawId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.law_delete')
        );
        if (response) {
            this.props.deleteLawByLawIdRequest(lawId);
        }
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records, allCityData) => {
        let lawRecords = [];
        lawRecords = records.map((lawData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_LAW, PERMISSION_EDIT) ===
                        true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => this.editLawDetails(lawData)}
                            >
                                <i className="fas fa-pencil-alt m-0"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('law') &&
                        checkModulePermission(MODULE_LAW, PERMISSION_DELETE) ===
                        true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteLaw(lawData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt m-0"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(lawData.id);
                        }}
                    >
                        <i className="fas fa-history m-0"></i>
                    </a>
                    <ReactTooltip id={`show-law-` + lawData.id} place="top" html={true} style={{ width: "80%", wordBreak: "break-all" }} />
                    <a href={() => false} className="btn btn-link text-warning tbl-action"
                        data-tip={'<p style="margin-bottom: 0">' + __t('form_labels.laws.applicability_details') + ': <br/>' + lawData.applicabilityDetails + '</p>'}
                        data-for={
                            `show-law-` +
                            lawData.id
                        }
                    >
                        <i className="fas fa-info-circle m-0"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(lawData.id)}
                        checked={lawData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            let cityNames = [];
            if (lawData.cityId) {
                const cityIds = lawData.cityId.split(',').map(Number) || [];
                cityIds.map((cityId) => {
                    let cityData = allCityData.find(city => city.id === cityId)

                    cityNames.push(cityData?.cityName)
                })
            }
            return {
                lawCode: lawData?.lawCode,
                lawShortName: lawData.lawShortName,
                lawCategoryName: lawData.lawCategory?.lawCategoryName,
                countryName: lawData.country?.countryName,
                stateName: (lawData.stateId == '') ? NA : lawData.state?.stateName,
                cityName: (lawData.cityId == '') ? NA : this.getShowMoreTextElement(cityNames.join(", ")),
                isActive: isActive,
                actions: actions,
            };
        });
        return lawRecords;
    };
    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.lawList && this.props.lawList !== nextprops.lawList) {
            this.setState({
                exportLawList: nextprops.lawList,
                lawList: this.generateTable(nextprops.lawList, nextprops.allCityData),
            });
        }
        if (
            nextprops.deleteLawApiResponse &&
            this.props.deleteLawApiResponse !== nextprops.deleteLawApiResponse
        ) {
            toastrMessage(
                nextprops.deleteLawApiResponse.message,
                nextprops.deleteLawApiResponse.responseType
            );
        }
        if (
            nextprops.lawStatusUpdateApiResponse &&
            this.props.lawStatusUpdateApiResponse !==
            nextprops.lawStatusUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.lawStatusUpdateApiResponse.message,
                nextprops.lawStatusUpdateApiResponse.responseType
            );
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.lawList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Col lg="12">
                    <CommonBreadcrum type={'law'} />
                </Col>
                <Col lg="12" className='viewlaws_tabs'>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab === 'lawTab',
                                })}
                                onClick={() => {
                                    this.setState(
                                        {
                                            activeTab:
                                                'lawTab',
                                        }
                                    );
                                }}
                            >
                                Law
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab ===
                                        'sectionRuleTab',
                                })}
                                onClick={() => {
                                    this.setState(
                                        {
                                            activeTab:
                                                'sectionRuleTab',
                                        }
                                    );
                                }}
                            >
                                Section/Rule
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane className="laws_tab" tabId="lawTab">
                            <Row>

                                <Col lg="12">
                                    <Card className="mb-2">
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>{__t('page_headers.law_list')}</h4>
                                            </div>
                                            <div className="page-action">
                                                {(checkModulePermission(
                                                    MODULE_LAW,
                                                    PERMISSION_ADD
                                                ) === true ||
                                                    checkModulePermission(
                                                        MODULE_LAW,
                                                        PERMISSION_EDIT
                                                    ) === true) && (
                                                        <a
                                                            href={() => false}
                                                            className="btn btn-primary waves-effect waves-light float-right ml-3"
                                                            onClick={() => {
                                                                this.addLawDetails();
                                                            }}
                                                        >
                                                            <i className="fas fa-plus"> </i>{' '}
                                                            {ADD_NEW}
                                                        </a>
                                                    )}
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <CustomisedMDBDatatablePage
                                                activepage={this.state.activePage}
                                                onHandlePageChange={this.handlePageChange}
                                                tableRecords={data}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            className="laws_tab"
                            tabId="sectionRuleTab"
                        >
                            <Row>
                                <Section isImport={true} />
                            </Row>
                        </TabPane>
                    </TabContent>
                </Col>

                <AuditLogModal
                    exportBtn={true}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'law'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawList: makeLawSelectField('lawList'),
    lawStatusUpdateApiResponse: makeLawSelectField(
        'lawStatusUpdateApiResponse'
    ),
    deleteLawApiResponse: makeLawSelectField('deleteLawApiResponse'),
    allCityData: makeLawSelectField('allCityData')
});
const withConnect = connect(mapStatetoProps, {
    getAllLawListRequest,
    updateLawStatusByLawIdRequest,
    deleteLawByLawIdRequest,
});
export default compose(
    withConnect,
    withLawReducer,
    withLawSaga,
    withRouter
)(Index);
