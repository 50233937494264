import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ShowMoreText from "react-show-more-text";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { newsHeaders } from './CommonFunctions';
import { NA } from 'constants/commonConstants';

class MainDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsDetails: this.props.newsDetails
        };
    }


    scrollToSection = (className) => {
        const section = document.querySelector('#' + className);
        let position = section.getBoundingClientRect();

        window.scrollTo(position.left, position.top + window.scrollY - 140);
        document.getElementById(className).classList.add("animate__bounceIn");
        setTimeout(function () {
            document.getElementById(className).classList.remove("animate__bounceIn");
        }, 2000)
    };

    getShowMoreTextElement = (element) => {
        return <ShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            className="show-more-content"
            anchorClass="show-more-link"
            expandByClick={true}
            expanded={false}
            keepNewLines={true}
        >{`` + element + '\n'}</ShowMoreText>
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.newsDetails && this.props.newsDetails !== nextprops.newsDetails) {
            this.setState({
                newsDetails: nextprops.newsDetails
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className='page-header'>
                                    <h4 className='with-back-btn'>
                                        {this.props.history.length !== 1 && (
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.goBack()
                                                }}
                                            >
                                                { }
                                            </a>
                                        )}
                                        <span>&nbsp;{this.state.newsDetails?.newsCode}</span></h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className='compliance_details'>
                                    <Row>
                                        <Col lg="12">
                                            <h4>{this.state.newsDetails?.newsTitle}</h4>
                                            <ul className='show-compliance-badge'>
                                            {/* {newsHeaders.map((itemData, i) => {
                                                return <li key={i}>
                                                    <a className='badge badge-primary mr-2 p-1' href={() => false} onClick={() => this.scrollToSection(itemData.field)}>{itemData.label}</a>
                                                </li>
                                            })} */}
                                            {this.state.newsDetails?.keywords?.map((keyword, i) => {
                                                return <li key={'keyword'+i}>
                                                    <a className='badge badge-outline-primary mr-2 p-1' href={() => false} >#{keyword}</a>
                                                </li>
                                            })}
                                            </ul>
                                        </Col>
                                        <Col lg="12">
                                            <ul className='basic-details'>
                                                <li id="releaseType">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.release_type')}</b>
                                                        <p>{this.state.newsDetails?.releaseType?.releaseTypeName}</p>
                                                    </div>
                                                </li>
                                                <li id="country">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.country')}</b>
                                                        <p>{this.state.newsDetails?.country?.countryName}</p>
                                                    </div>
                                                </li>
                                                <li id="state">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.state')}</b>
                                                        <p>{(this.state.newsDetails?.state) ? this.state.newsDetails?.state?.stateName : NA}</p>
                                                    </div>
                                                </li>
                                                <li id="city">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.city')}</b>
                                                        <p>{(this.state.newsDetails?.city) ? this.state.newsDetails?.city?.cityName : NA}</p>
                                                    </div>
                                                </li>
                                                <li id="lawCategory">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.law_category')}</b>
                                                        <p>{this.state.newsDetails?.lawCategory?.lawCategoryName}</p>
                                                    </div>
                                                </li>
                                                <li id="newsTitle">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.news_title')}</b>
                                                        <p>{this.state.newsDetails?.newsTitle}</p>
                                                    </div>
                                                </li>
                                                <li id="publicationDate">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.publication_date')}</b>
                                                        <p>{this.state.newsDetails?.publicationDate}</p>
                                                    </div>
                                                </li>
                                                <li id="industry">
                                                    <div className='details-info industry_list'>
                                                        <b>{__t('form_labels.news.industry')}</b>
                                                        <p>
                                                            {this.state.newsDetails?.industries?.length > 0 && (
                                                                this.state.newsDetails?.industries?.map((industry) => {
                                                                    return (
                                                                        <span className='badge badge-primary'>{industry.industryName}</span>
                                                                    )
                                                                })
                                                            )}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li id="subIndustry">
                                                    <div className='details-info industry_list'>
                                                        <b>{__t('form_labels.news.sub_industry')}</b>
                                                        <p> {this.state.newsDetails?.subIndustries?.length > 0 && (
                                                            this.state.newsDetails?.subIndustries?.map((subIndustry) => {
                                                                return (
                                                                    <span className='badge badge-primary'>{subIndustry.subIndustryName}</span>
                                                                )
                                                            })
                                                        )}</p>
                                                    </div>
                                                </li>
                                                <li id="webLink">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.weblink')}</b>
                                                        <p>{(this.state.newsDetails?.webLink) ? this.state.newsDetails?.webLink : '-'}</p>
                                                    </div>
                                                </li>
                                                <li id="readTime">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.read_time')}</b>
                                                        <p>{this.state.newsDetails?.readTime + ' minutes'}</p>
                                                    </div>
                                                </li>
                                                <li id="newsDetails">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.news_details')}</b>
                                                        <div className='JoditEditor-border' dangerouslySetInnerHTML={{
                                                            __html: this.state.newsDetails?.newsDetails
                                                        }}></div>
                                                    </div>
                                                </li>
                                                <li id="updateType">
                                                    <div className='details-info industry_list'>
                                                        <b>{__t('form_labels.news.update_type')}</b>
                                                        <p>{this.state.newsDetails?.updateTypes?.length > 0 &&
                                                            this.state.newsDetails.updateTypes.map((updateType) => {
                                                                return (
                                                                    <span className='badge badge-primary'>{updateType.releaseTypeName}</span>
                                                                )
                                                            })
                                                        }</p>
                                                    </div>
                                                </li>
                                                <li id="particularsOfUpdate">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.particulars_of_update')}</b>
                                                        {this.getShowMoreTextElement(this.state.newsDetails?.particularsOfUpdate)}
                                                    </div>
                                                </li>

                                                <li id="sourceOfUpdate">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.source_of_update')}</b>
                                                        {this.getShowMoreTextElement(this.state.newsDetails?.sourceOfUpdate)}
                                                    </div>
                                                </li>

                                                <li id="effectiveDate">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.effective_date_of_publication')}</b>
                                                        <p>{this.state.newsDetails?.effectiveDate}</p>
                                                    </div>
                                                </li>
                                                <li id="impactOfUpdate">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.impact_of_update')}</b>
                                                        {this.getShowMoreTextElement(this.state.newsDetails?.impactOfUpdate)}
                                                    </div>
                                                </li>

                                                <li id="remarks">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.remarks')}</b>
                                                        {this.getShowMoreTextElement(this.state.newsDetails?.remarks)}
                                                    </div>
                                                </li>

                                                <li id="businessFunctionImpacted">
                                                    <div className='details-info'>
                                                        <b>{__t('form_labels.news.business_function_impacted')}</b>
                                                        {this.getShowMoreTextElement(this.state.newsDetails?.businessFunctionImpacted)}
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
});

const withConnect = connect(mapStatetoProps, {
});

export default compose(
    withConnect,
    withRouter
)(MainDetails);