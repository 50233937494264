import React, { Component } from 'react';
import {
    Row,
    Col,
    Container,
    Button,
    Input,
    CardBody,
    Card,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SUBMIT } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getArchiveRecordsListRequest,
    uploadArchiveRecordsRequest,
    deleteArchiveRecordByIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
    initialMultipleDocumentDataState,

} from './CommonFunctions';
import __t from 'i18n/translator';
import {
    formatDateForApiSystem,
    getDateStringFromDateObject,
    getFullDateTimeFromDateTimeString,
} from 'helpers/dateTimeHelper';
import MultipleUploadDocumentForm from './MultipleUploadDocumentForm';
import Jpegfile from '../../../images/file-icon/jpeg.png';
import Pngfile from '../../../images/file-icon/png.png';
import Zipfile from '../../../images/file-icon/zip.png';
import Pdffile from '../../../images/file-icon/pdf.png';
import Jpgfile from '../../../images/file-icon/jpg.png';
import Csvfile from '../../../images/file-icon/csv.png';
import Txtfile from '../../../images/file-icon/txt.png';
import Docfile from '../../../images/file-icon/doc.png';
import Docxfile from '../../../images/file-icon/docx.png';
import Xlsxfile from '../../../images/file-icon/xlsx.png';
import Xlsfile from '../../../images/file-icon/xls.png';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    LOCAL_FILE_STORAGE,
    MODULE_ARCHIVE_RECORDS,
    PERMISSION_ADD,
} from 'constants/databaseConstants';
import FileSaver from 'file-saver';
import archiveRecordsReducer from 'store/ArchiveRecords/reducer';
import archiveRecordSaga from 'store/ArchiveRecords/saga';
import { makeArchiveRecordSelectField } from 'store/ArchiveRecords/selector';
import FilterForm from './FilterForm';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import ReactPaginate from 'react-paginate';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { getBaseUrl } from 'helpers/axiosHelper';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';

const fileStorage = process.env.REACT_APP_FILE_STORAGE;
const s3Url = process.env.REACT_APP_S3_URL;
const archiveKey = 'archive';
const withArchiveRecordsReducer = injectReducer({
    key: archiveKey,
    reducer: archiveRecordsReducer,
});
const withArchiveRecordSaga = injectSaga({
    key: archiveKey,
    saga: archiveRecordSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadFileUrl: '',
            downloadFileName: '',
            currentPage: 1,
            pageSize: 12,
            allRecords: [],
            archiveRecordsList: [],
            operationType: SUBMIT,
            isFormOpen: false,
            isMultipleFormOpen: false,
            multipleDocumentData: initialMultipleDocumentDataState(),
            searchFormData: {},
            fileIcons: {
                jpeg: Jpegfile,
                png: Pngfile,
                zip: Zipfile,
                pdf: Pdffile,
                jpg: Jpgfile,
                csv: Csvfile,
                txt: Txtfile,
                doc: Docfile,
                docx: Docxfile,
                xlsx: Xlsxfile,
                xls: Xlsfile,
            },
        };
    }
    componentDidMount() {
        this.getArchiveRecordsList({
            fromDate: (this.props.match.params?.defaultDate) ? getDateStringFromDateObject(this.props.match.params?.defaultDate) : ''
        });
    }
    MultipleUploadDocument() {
        this.setState({
            isMultipleFormOpen: true,
            multipleDocumentData: initialMultipleDocumentDataState(),
        });
    }
    resetMultipleForm = () => {
        this.setState(
            {
                multipleDocumentData: initialMultipleDocumentDataState(),
                isMultipleFormOpen: false,
            },
            () => {
                this.props.getArchiveRecordsListRequest();
            }
        );
    };
    multipleFormSubmit = (values) => {
        let formValues = values;
        this.props.uploadArchiveRecordsRequest(formValues);
    };
    filterFormSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
            },
            () => {
                this.getArchiveRecordsList(this.state.searchFormData);
            }
        );
    };
    resetFilterForm = () => {
        this.setState(
            {
                searchFormData: {},
            },
            () => {
                this.getArchiveRecordsList({});
            }
        );
    };
    getArchiveRecordsList = (filterData) => {
        let filterRequestData = {};
        if (Object.keys(filterData).length > 0) {
            filterRequestData = {
                ...filterData,
                fromDate:
                    filterData?.fromDate && filterData.fromDate != ''
                        ? formatDateForApiSystem(filterData.fromDate)
                        : '',
                toDate:
                    filterData.toDate && filterData?.toDate != ''
                        ? formatDateForApiSystem(filterData.toDate)
                        : '',
            };
            this.setState({
                searchFormData: filterData
            }, () => {
                this.props.getArchiveRecordsListRequest({
                    filter: filterRequestData,
                });
            })
        } else {
            this.props.getArchiveRecordsListRequest({});
        }
    };
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    searchText = (searchValue) => {
        let isData;
        let searchedData = this.state.allRecords?.filter((values) => {
            isData = values.fileName.trim().toLowerCase().includes(searchValue);
            if (!isData) {
                isData = values.companyGroup?.companyGroupName
                    .trim()
                    .toLowerCase()
                    .includes(searchValue);
            }
            if (!isData) {
                isData = values.entity?.entityName
                    .trim()
                    .toLowerCase()
                    .includes(searchValue);
            }
            if (!isData) {
                isData = values.createdByUser?.userName
                    .trim()
                    .toLowerCase()
                    .includes(searchValue);
            }
            return isData;
        });
        this.setState({
            archiveRecordsList: searchedData,
        });
    };
    removeArchiveRecord = async (archiveRecordId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.archive_file_delete')
        );
        if (response) {
            return this.props.deleteArchiveRecordByIdRequest(archiveRecordId);
        }
    };
    paginate = ({ selected }) => {
        const indexOfLastPost = (selected + 1) * this.state.pageSize;
        const indexOfFirstPost = indexOfLastPost - this.state.pageSize;
        const paginatedData = this.state.allRecords.slice(
            indexOfFirstPost,
            indexOfLastPost
        );
        this.setState({
            currentPage: selected + 1,
            archiveRecordsList: paginatedData,
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.archiveRecordsList &&
            this.props.archiveRecordsList != nextprops.archiveRecordsList
        ) {
            let currentPage = this.state.currentPage;
            const indexOfLastPost = currentPage * this.state.pageSize;
            const indexOfFirstPost = indexOfLastPost - this.state.pageSize;
            let paginatedData = nextprops.archiveRecordsList.slice(
                indexOfFirstPost,
                indexOfLastPost
            );
            if (paginatedData.length == 0) {
                currentPage = 1;
                const indexOfLastPost1 = currentPage * this.state.pageSize;
                paginatedData = nextprops.archiveRecordsList.slice(
                    indexOfFirstPost,
                    indexOfLastPost1
                );
            }
            this.setState(
                {
                    currentPage: currentPage,
                },
                () => {
                    this.setState({
                        allRecords: nextprops.archiveRecordsList,
                        archiveRecordsList: paginatedData,
                    });
                }
            );
        }

        if (
            nextprops.archiveRecordDeleteApiResponse &&
            this.props.archiveRecordDeleteApiResponse !==
            nextprops.archiveRecordDeleteApiResponse
        ) {
            toastrMessage(
                nextprops.archiveRecordDeleteApiResponse.message,
                nextprops.archiveRecordDeleteApiResponse.responseType
            );
            this.getArchiveRecordsList(this.state.searchFormData);
        }
    }
    render() {

        const baseUrl = getBaseUrl();
        const fileHostUrl = (fileStorage === LOCAL_FILE_STORAGE) ? baseUrl : s3Url;

        return (
            <React.Fragment>
                <Container fluid>
                    <CommonBreadcrum type={'archive-records'} />
                    <div className="archive_record">
                        <div className="page-header">
                            <h4>{__t('page_headers.archive_records')}</h4>
                            <div className="page-action">
                                <div className="search_box">
                                    <Input
                                        type="search"
                                        onChange={(e) =>
                                            this.searchText(
                                                e.target.value
                                                    .trim()
                                                    .toLowerCase()
                                            )
                                        }
                                        onKeyPress={(e) => {
                                            (e.key === 'Enter') &&
                                                e.preventDefault();
                                        }}
                                    />
                                    <svg
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </div>
                                {checkModulePermission(
                                    MODULE_ARCHIVE_RECORDS,
                                    PERMISSION_ADD
                                ) && (
                                        <Link
                                            to={'#'}
                                            className="btn btn-primary btn-sm waves-effect waves-light float-right"
                                            onClick={() => {
                                                this.MultipleUploadDocument();
                                            }}
                                        >
                                            <svg
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8 2L7.29289 1.29289L8 0.585786L8.70711 1.29289L8 2ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11L9 11ZM2.29289 6.29289L7.29289 1.29289L8.70711 2.70711L3.70711 7.70711L2.29289 6.29289ZM8.70711 1.29289L13.7071 6.29289L12.2929 7.70711L7.29289 2.70711L8.70711 1.29289ZM9 2L9 11L7 11L7 2L9 2Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M1 13L1 14C1 15.1046 1.89543 16 3 16L13 16C14.1046 16 15 15.1046 15 14V13"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                />
                                            </svg>
                                            New Upload
                                        </Link>
                                    )}
                            </div>
                        </div>
                        <Row className="mt-2">
                            <Col lg="3" className="px-0">
                                <Card className="archive-filters">
                                    <CardBody className="p-3">
                                        <FilterForm
                                            filterData={
                                                this.state.searchFormData
                                            }
                                            onFormSubmit={(values) => {
                                                this.filterFormSubmit(values);
                                            }}
                                            onFormReset={this.resetFilterForm}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="9" className="pr-0">
                                <div className="archive_list">
                                    <ul>
                                        {this.state.archiveRecordsList.length >
                                            0 &&
                                            this.state.archiveRecordsList.map(
                                                (archiveRecord, key) => {
                                                    let fileNameLink = (
                                                        <>
                                                            {
                                                                archiveRecord.fileName
                                                            }
                                                            &nbsp;

                                                        </>
                                                    );

                                                    return (
                                                        <li key={key}>
                                                            <div className="file_name">
                                                                <div>
                                                                    <span>
                                                                        <img
                                                                            src={
                                                                                this
                                                                                    .state
                                                                                    .fileIcons[
                                                                                archiveRecord
                                                                                    .fileExtension
                                                                                ]
                                                                            }
                                                                            alt="File extension"
                                                                        />
                                                                    </span>
                                                                    <div>
                                                                        <h6
                                                                            title={
                                                                                archiveRecord.fileName
                                                                            }
                                                                        >
                                                                            {
                                                                                fileNameLink
                                                                            }
                                                                        </h6>
                                                                        <p>
                                                                            <span>
                                                                                {
                                                                                    archiveRecord
                                                                                        .createdByUser
                                                                                        .userName
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <p className="mt-2">
                                                                    <b>
                                                                        Period :
                                                                    </b>
                                                                    <span>
                                                                        {' '}
                                                                        {
                                                                            archiveRecord.fromDate
                                                                        }{' '}
                                                                        to
                                                                    </span>
                                                                    <span>
                                                                        {' '}
                                                                        {
                                                                            archiveRecord.toDate
                                                                        }{' '}
                                                                    </span>
                                                                </p>
                                                                <p
                                                                    className="file-department"
                                                                    title={
                                                                        archiveRecord
                                                                            .archiveDepartment
                                                                            ?.archiveDepartmentName
                                                                    }
                                                                >
                                                                    <b>
                                                                        Department
                                                                        :
                                                                    </b>{' '}
                                                                    {
                                                                        archiveRecord
                                                                            .archiveDepartment
                                                                            ?.archiveDepartmentName
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="file_details">
                                                                <div>
                                                                    <p>
                                                                        {' '}
                                                                        {
                                                                            archiveRecord
                                                                                .companyGroup
                                                                                .companyGroupName
                                                                        }{' '}
                                                                    </p>
                                                                    <p>
                                                                        {' '}
                                                                        {
                                                                            archiveRecord
                                                                                .entity
                                                                                .entityName
                                                                        }{' '}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p>
                                                                        {getFullDateTimeFromDateTimeString(
                                                                            archiveRecord.createdAt
                                                                        )}
                                                                    </p>
                                                                    <div className="btn-box">
                                                                        <Link
                                                                            to="#"
                                                                            color="link"
                                                                            onClick={() =>
                                                                                this.saveFile(
                                                                                    {
                                                                                        id: archiveRecord.id,
                                                                                        fileName:
                                                                                            archiveRecord.fileName,
                                                                                        fileUrl: archiveRecord.systemFileName
                                                                                    }
                                                                                )
                                                                            }
                                                                            className="btn btn-primary"
                                                                            title="Download file"
                                                                        >
                                                                            <svg
                                                                                width="15"
                                                                                height="21"
                                                                                viewBox="0 0 15 21"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M1 20H13.6667M7.33333 1V15.7778M7.33333 15.7778L12.6111 10.5M7.33333 15.7778L2.05556 10.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                        <Button
                                                                            className="btn btn-sm"
                                                                            title="Delete file"
                                                                            color="link"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                this.removeArchiveRecord(
                                                                                    archiveRecord.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <svg
                                                                                width="18"
                                                                                height="19"
                                                                                viewBox="0 0 18 19"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M7 13L7 10"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M11 13L11 10"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M0 5H18V5C17.0681 5 16.6022 5 16.2346 5.15224C15.7446 5.35523 15.3552 5.74458 15.1522 6.23463C15 6.60218 15 7.06812 15 8V14C15 15.8856 15 16.8284 14.4142 17.4142C13.8284 18 12.8856 18 11 18H7C5.11438 18 4.17157 18 3.58579 17.4142C3 16.8284 3 15.8856 3 14V8C3 7.06812 3 6.60218 2.84776 6.23463C2.64477 5.74458 2.25542 5.35523 1.76537 5.15224C1.39782 5 0.931883 5 0 5V5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M7.06815 1.37059C7.1821 1.26427 7.43319 1.17033 7.78248 1.10332C8.13177 1.03632 8.55973 1 9 1C9.44027 1 9.86823 1.03632 10.2175 1.10332C10.5668 1.17033 10.8179 1.26427 10.9319 1.37059"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="2"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                            </svg>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </ul>
                                </div>
                                {this.state.allRecords.length > 0 && (
                                    <ReactPaginate
                                        onPageChange={this.paginate}
                                        pageCount={Math.ceil(
                                            this.state.allRecords.length /
                                            this.state.pageSize
                                        )}
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        containerClassName={
                                            'pagination justify-content-end mb-0'
                                        }
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        activeLinkClassName={'active'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg="12">
                            <MultipleUploadDocumentForm
                                isModalOpen={this.state.isMultipleFormOpen}
                                multipleDocumentData={
                                    this.state.multipleDocumentData
                                }
                                onFormValid={this.multipleFormSubmit}
                                onModalDismiss={this.resetMultipleForm}
                            />
                        </Col>
                    </Row>
                </Container>
                <DownloadFileByModel
                    modelType='archiveRecord'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    archiveRecordsList: makeArchiveRecordSelectField('archiveRecordsList'),
    archiveRecordDeleteApiResponse: makeArchiveRecordSelectField(
        'archiveRecordDeleteApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getArchiveRecordsListRequest,
    uploadArchiveRecordsRequest,
    deleteArchiveRecordByIdRequest,
});
export default compose(
    withConnect,
    withArchiveRecordsReducer,
    withArchiveRecordSaga,
    withRouter
)(Index);
