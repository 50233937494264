import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { getFundTaskCountByFundIdRequest } from 'store/actions';
import { makeFundsSelectField } from 'store/Masters/Funds/selector';

class FundEntityAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fundEntityWiseAnalysisData: [],
            entityList: []
        };
    }
    componentDidMount() {
        this.props.getFundTaskCountByFundIdRequest(this.props.fundId);
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.fundTaskCountApiResponse && nextprops.fundTaskCountApiResponse !== this.props.fundTaskCountApiResponse) {
            if (nextprops.fundTaskCountApiResponse?.data) {
                this.setState({
                    fundEntityWiseAnalysisData: nextprops.fundTaskCountApiResponse.data.fundTaskCount,
                    entityList: nextprops.fundTaskCountApiResponse.data.entityList
                })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='custom_table'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Entity Name</th>
                                <th>Total Tasks</th>
                                <th>Total Sub Tasks</th>
                                <th>Overdue</th>
                                <th>Delayed</th>
                                <th>Rework</th>
                                <th>To Do</th>
                                <th>Completed</th>
                            </tr>
                        </thead>
                        {this.state.entityList.length > 0 && (
                            <tbody>
                                {this.state.entityList.map((entity, key) => {
                                    let entityWiseData = this.state.fundEntityWiseAnalysisData.find((fEntity) => fEntity.entityId === entity.id);
                                    return (
                                        <tr key={key}>
                                            <td>{entity.entityName}</td>
                                            <td>{(entityWiseData?.totalTasksCount) ? entityWiseData.totalTasksCount : 0}</td>
                                            <td>{(entityWiseData?.totalSubtasksCount) ? entityWiseData.totalSubtasksCount : 0}</td>
                                            <td>{(entityWiseData?.overdueCount) ? entityWiseData.overdueCount : 0}</td>
                                            <td>{(entityWiseData?.delayedCount) ? entityWiseData.delayedCount : 0}</td>
                                            <td>{(entityWiseData?.reworkCount) ? entityWiseData.reworkCount : 0}</td>
                                            <td>{(entityWiseData?.todoCount) ? entityWiseData.todoCount : 0}</td>
                                            <td>{(entityWiseData?.completedCount) ? entityWiseData.completedCount : 0} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        )}
                    </table>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    fundTaskCountApiResponse: makeFundsSelectField('fundTaskCountApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getFundTaskCountByFundIdRequest
});

export default compose(withConnect, withRouter)(FundEntityAnalysis);
