import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    DOWNLOAD_FILE_BY_MODEL_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { downloadFileByModelResponse } from './actions';
import { DOWNLOAD_FILE_BY_MODEL } from 'helpers/apiConstants';
function* downloadFileByModel({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', DOWNLOAD_FILE_BY_MODEL, data);
        if (response.status) {
            yield put(downloadFileByModelResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(downloadFileByModelResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchCommon() {
    yield takeEvery(DOWNLOAD_FILE_BY_MODEL_REQUEST, downloadFileByModel);
}
function* commonSaga() {
    yield all([fork(watchCommon)]);
}
export default commonSaga;