import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Law code',
        field: 'lawCode',
    },
    {
        label: 'Section/Rule Name',
        field: 'sectionName',
    },
    {
        label: 'Country',
        field: 'country',
    },
    {
        label: 'State/Province',
        field: 'state',
    },
    {
        label: 'City/Location',
        field: 'city',
        attributes: {
            className: 'w-25',
        },
    },
    {
        label: 'Short Name of Act/Regulation',
        field: 'lawShortName',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialSectionDataState = () => {
    return {
        lawId: '',
        sectionName: '',
        isActive: ACTIVE
    }
}
export const intFields = ['lawId'];
export const nullableFields = [''];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};