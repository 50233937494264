import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SELECT_ALL, SUBMIT, SUCCESS } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import { allocateCheckCompliancesEntityRequest } from 'store/actions';
import { compose } from 'redux';
import { makeAllocationSelectField } from 'store/Allocation/selector';
class MapLocationForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.toggle = this.toggle.bind(this);
        this.toggleSub = this.toggleSub.bind(this);
        this.state = {
            entityListData: this.props.entityListData,
            collapseEntityId: '',
            collapseStateId: '',
            locationIds: this.props.alreadySelectedLocations,
            entityIds: this.props.alreadySelectedEntities,
            entityWithLocations: this.props.entityWithLocations,
            isCheckAll: [],
            alreadyAllocatedLocationIds: [],
            alreadyAllocatedCompanyWithLocations: [],
        };
    }
    componentDidMount() {
        this.props.allocateCheckCompliancesEntityRequest({
            complianceId: this.props.complianceId,
            entityIds: this.state.entityListData.entityAllocations.map(
                (opt) => opt.entityId
            ),
            countryId: this.props.allocationFilterData?.countryId,
            stateId: this.props.allocationFilterData?.stateId,
            cityId: this.props.allocationFilterData?.cityId,
        });
    }
    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({
            collapseEntityId:
                this.state.collapseEntityId == Number(event)
                    ? 0
                    : Number(event),
        });
    }
    toggleSub(e) {
        let event = e.target.dataset.event;
        this.setState({
            collapseStateId:
                this.state.collapseStateId == Number(event)
                    ? 0
                    : Number(event),
        });
    }
    formSubmit = () => {
        this.props.onFormSubmit(this.state.entityWithLocations);
    };
    checkAll = () => {
        let isCheckAllArrayList = [];
        this.state.entityListData.entityAllocations.forEach((opt) => {
            opt.entityLocations.forEach((optS) => {
                if (this.checkAllByState(opt.entityId, optS.stateId)) {
                    isCheckAllArrayList.push(
                        `cities_` + opt.entityId + `_` + optS.stateId
                    );
                }
            });
        });
        this.setState({
            isCheckAll: isCheckAllArrayList,
        });
    };
    checkAllByState = (entityId, stateId) => {
        let entityCities =
            this.state.entityListData.entityAllocations
                .find((opt) => opt.entityId === entityId)
                ?.entityLocations?.find((optS) => optS.stateId === stateId)
                ?.cities || [];
        let allLocationIds = entityCities.map((opt) => Number(opt.locationId));
        let totalAddedLocationIds =
            allLocationIds.filter(
                (opt) =>
                    this.state.locationIds.includes(opt) ||
                    this.state.alreadyAllocatedLocationIds.includes(opt)
            ) || [];
        return totalAddedLocationIds.length === entityCities.length;
    };
    checkIfAllSelected = (stateCities) => {
        let selectedLocations = [];
        stateCities.forEach((opt) => {
            if (
                this.state.alreadyAllocatedLocationIds.includes(opt.locationId)
            ) {
                selectedLocations.push(opt.locationId);
            }
        });
        return selectedLocations.length === stateCities.length ? true : false;
    };
    handleChangeAll = (e, entityId, stateId) => {
        const { checked } = e.target;
        let entityCities =
            this.state.entityListData.entityAllocations
                .find((opt) => opt.entityId === entityId)
                ?.entityLocations?.find((optS) => optS.stateId === stateId)
                ?.cities || [];
        let allLocationIds = entityCities.map((opt) => Number(opt.locationId));
        let allCompanyWithLocations = entityCities.map((opt) => ({
            locationId: Number(opt.locationId),
            entityId: entityId,
        }));
        let isCheckAll = [...this.state.isCheckAll];
        let locationIds = [...this.state.locationIds];
        let entityWithLocations = [...this.state.entityWithLocations];
        if (checked) {
            let finalCompanyWithLocations = [
                ...entityWithLocations,
                ...allCompanyWithLocations.filter(
                    (x) =>
                        !this.state.alreadyAllocatedLocationIds.includes(
                            x.locationId
                        )
                ),
            ];
            // Creates an array of objects with unique "name" property values.
            let allFinalCompanyWithLocations = [
                ...new Map(
                    finalCompanyWithLocations.map((item) => [
                        item['locationId'],
                        item,
                    ])
                ).values(),
            ];

            this.setState(
                {
                    locationIds: [
                        ...locationIds,
                        ...allLocationIds.filter(
                            (x) =>
                                !this.state.alreadyAllocatedLocationIds.includes(
                                    x
                                )
                        ),
                    ].reduce(function (a, b) {
                        if (a.indexOf(b) < 0) a.push(b);
                        return a;
                    }, []),
                    entityWithLocations: allFinalCompanyWithLocations,
                    isCheckAll: [
                        ...isCheckAll,
                        `cities_` + entityId + `_` + stateId,
                    ],
                },
                () => {
                    this.setState({
                        entityIds: this.removeDuplicateEntityIds(this.state.entityWithLocations),
                    });
                }
            );
        }
        // Case 2  : The user unchecks the box
        else {
            this.setState(
                {
                    locationIds: locationIds.filter(
                        (x) => !allLocationIds.includes(x)
                    ),
                    entityWithLocations: entityWithLocations.filter(
                        (x) => !allLocationIds.includes(x.locationId)
                    ),
                    isCheckAll: isCheckAll.filter(
                        (x) => x !== `cities_` + entityId + `_` + stateId
                    ),
                },
                () => {
                    this.setState({
                        entityIds: this.removeDuplicateEntityIds(this.state.entityWithLocations),
                    });
                }
            );
        }
    };
    removeDuplicateEntityIds(entitiesWithLocations) {
        return entitiesWithLocations
            .map((opt) => opt.entityId)
            .reduce(function (a, b) {
                if (a.indexOf(b) < 0) a.push(b);
                return a;
            }, []);
    }
    handleChange = (e, locationId, entityId, stateId) => {
        // Destructuring
        const { checked } = e.target;
        let locationIds = [...this.state.locationIds];
        let entityWithLocations = [...this.state.entityWithLocations];
        // Case 1 : The user checks the box
        let entityCities =
            this.state.entityListData.entityAllocations
                .find((opt) => opt.entityId === entityId)
                ?.entityLocations?.find((optS) => optS.stateId === stateId)
                ?.cities || [];
        let allLocationIds = entityCities.map((opt) => Number(opt.locationId));
        let isCheckAll = [...this.state.isCheckAll];
        if (checked) {
            this.setState(
                {
                    locationIds: [...locationIds, Number(locationId)],
                    entityWithLocations: [
                        ...entityWithLocations,
                        {
                            locationId: Number(locationId),
                            entityId: Number(entityId),
                        },
                    ],
                },
                () => {
                    let totalAddedLocationIds =
                        allLocationIds.filter((opt) =>
                            this.state.locationIds.includes(opt)
                        ) || [];
                    let isCheckAllVal =
                        totalAddedLocationIds.length === entityCities.length
                            ? [
                                ...isCheckAll,
                                `cities_` + entityId + `_` + stateId,
                            ]
                            : isCheckAll.filter(
                                (x) =>
                                    x !== `cities_` + entityId + `_` + stateId
                            );
                    this.setState({
                        isCheckAll: isCheckAllVal,
                        entityIds: this.state.entityWithLocations
                            .map((opt) => opt.entityId)
                            .reduce(function (a, b) {
                                if (a.indexOf(b) < 0) a.push(b);
                                return a;
                            }, []),
                    });
                }
            );
        }
        // Case 2  : The user unchecks the box
        else {
            this.setState(
                {
                    locationIds: locationIds.filter(
                        (x) => x !== Number(locationId)
                    ),
                    entityWithLocations: entityWithLocations.filter(
                        (x) => x.locationId !== Number(locationId)
                    ),
                },
                () => {
                    let totalAddedLocationIds =
                        allLocationIds.filter((opt) =>
                            this.state.locationIds.includes(opt)
                        ) || [];
                    let isCheckAllVal =
                        totalAddedLocationIds.length === entityCities.length
                            ? [
                                ...isCheckAll,
                                `cities_` + entityId + `_` + stateId,
                            ]
                            : isCheckAll.filter(
                                (x) =>
                                    x !== `cities_` + entityId + `_` + stateId
                            );
                    this.setState({
                        isCheckAll: isCheckAllVal,
                        entityIds: this.state.entityWithLocations
                            .map((opt) => opt.entityId)
                            .reduce(function (a, b) {
                                if (a.indexOf(b) < 0) a.push(b);
                                return a;
                            }, []),
                    });
                }
            );
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.getComplianceLocationApiResponse &&
            nextprops.getComplianceLocationApiResponse !==
            this.props.getComplianceLocationApiResponse
        ) {
            if (
                nextprops.getComplianceLocationApiResponse.responseType ===
                SUCCESS
            ) {
                let alreadyAllocatedLocationIds =
                    nextprops.getComplianceLocationApiResponse.data.locations?.map(
                        (opt) => opt.entityLocationId
                    );
                let alreadyAllocatedCompanyWithLocations =
                    nextprops.getComplianceLocationApiResponse.data?.locations.map(
                        (opt) => ({
                            entityId: opt.entityId,
                            locationId: opt.entityLocationId,
                        })
                    );
                this.setState(
                    {
                        alreadyAllocatedLocationIds:
                            alreadyAllocatedLocationIds,
                        alreadyAllocatedCompanyWithLocations:
                            alreadyAllocatedCompanyWithLocations,

                    },
                    () => {
                        this.checkAll();
                    }
                );
            } else {
                this.setState({
                    alreadyAllocatedLocationIds: [],
                });
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={{ ...this.state.entityListData }}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="location_modal">
                                <ul className="list_group_main entity_list_ul">
                                    {values.entityAllocations?.length > 0 &&
                                        values.entityAllocations.map(
                                            (item, idx) => {
                                                return (
                                                    item.entityLocations
                                                        .length > 0 && (
                                                        <li
                                                            className="list-group-item entity-li"
                                                            key={idx}
                                                            data-entity={
                                                                item.entityName
                                                            }
                                                        >
                                                            <p
                                                                onClick={
                                                                    this.toggle
                                                                }
                                                                data-event={
                                                                    item.entityId
                                                                }
                                                            >
                                                                {
                                                                    item.entityName
                                                                }
                                                                <label className="badge badge-primary m-0 ml-2">
                                                                    {this.state.entityWithLocations.filter(
                                                                        (opt) =>
                                                                            opt.entityId ===
                                                                            item.entityId
                                                                    )?.length +
                                                                        this.state.alreadyAllocatedCompanyWithLocations.filter(
                                                                            (
                                                                                opt
                                                                            ) =>
                                                                                opt.entityId ===
                                                                                item.entityId
                                                                        )
                                                                            ?.length}
                                                                </label>
                                                            </p>
                                                            <Collapse
                                                                isOpen={
                                                                    this.state
                                                                        .collapseEntityId ===
                                                                    item.entityId
                                                                }
                                                            >
                                                                <ul className="list_group_sub mx-0 state_list_ul">
                                                                    {item
                                                                        .entityLocations
                                                                        ?.length >
                                                                        0 &&
                                                                        item.entityLocations.map(
                                                                            (
                                                                                subItem,
                                                                                subIdx
                                                                            ) => (
                                                                                <React.Fragment
                                                                                    key={
                                                                                        item.entityId +
                                                                                        subItem.stateId
                                                                                    }
                                                                                >
                                                                                    <li
                                                                                        className={
                                                                                            `state-li list-group-item ` +
                                                                                            (this
                                                                                                .state
                                                                                                .collapseStateId ==
                                                                                                item.entityId +
                                                                                                subItem.stateId
                                                                                                ? 'active'
                                                                                                : '')
                                                                                        }
                                                                                        data-state={subItem.stateName}
                                                                                    >
                                                                                        <p
                                                                                            onClick={
                                                                                                this
                                                                                                    .toggleSub
                                                                                            }
                                                                                            data-event={
                                                                                                item.entityId +
                                                                                                subItem.stateId
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                subItem.stateName
                                                                                            }
                                                                                        </p>
                                                                                        <Collapse
                                                                                            isOpen={
                                                                                                this
                                                                                                    .state
                                                                                                    .collapseStateId ==
                                                                                                item.entityId +
                                                                                                subItem.stateId
                                                                                            }
                                                                                        >
                                                                                            <ul className="list_group_child city_list_ul">
                                                                                                {subItem
                                                                                                    .cities
                                                                                                    ?.length >
                                                                                                    0 && (
                                                                                                        <li
                                                                                                            className="list-group-item form-check"
                                                                                                            key={
                                                                                                                `cities_` +
                                                                                                                item.entityId +
                                                                                                                `_` +
                                                                                                                subItem.stateId
                                                                                                            }
                                                                                                        >
                                                                                                            <Field
                                                                                                                type="checkbox"
                                                                                                                id={
                                                                                                                    `cities_` +
                                                                                                                    item.entityId +
                                                                                                                    `_` +
                                                                                                                    subItem.stateId
                                                                                                                }
                                                                                                                className="form-check-input"
                                                                                                                name={
                                                                                                                    `cities_` +
                                                                                                                    item.entityId +
                                                                                                                    `_` +
                                                                                                                    subItem.stateId
                                                                                                                }
                                                                                                                value="1"
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) => {
                                                                                                                    this.handleChangeAll(
                                                                                                                        e,
                                                                                                                        item.entityId,
                                                                                                                        subItem.stateId
                                                                                                                    );
                                                                                                                }}
                                                                                                                checked={this.state.isCheckAll.includes(
                                                                                                                    `cities_` +
                                                                                                                    item.entityId +
                                                                                                                    `_` +
                                                                                                                    subItem.stateId
                                                                                                                )}
                                                                                                                disabled={
                                                                                                                    this
                                                                                                                        .state
                                                                                                                        .locationIds
                                                                                                                        .length >
                                                                                                                        0
                                                                                                                        ? this.checkIfAllSelected(
                                                                                                                            subItem.cities
                                                                                                                        )
                                                                                                                        : false
                                                                                                                }
                                                                                                            />
                                                                                                            <label
                                                                                                                className="form-check-label"
                                                                                                                htmlFor={
                                                                                                                    `cities_` +
                                                                                                                    item.entityId +
                                                                                                                    `_` +
                                                                                                                    subItem.stateId
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    SELECT_ALL
                                                                                                                }
                                                                                                            </label>
                                                                                                        </li>
                                                                                                    )}
                                                                                                {subItem
                                                                                                    .cities
                                                                                                    ?.length >
                                                                                                    0 &&
                                                                                                    subItem.cities.map(
                                                                                                        (
                                                                                                            subChildItem,
                                                                                                            subChildIdx
                                                                                                        ) => (
                                                                                                            <li
                                                                                                                className="city-li list-group-item form-check"
                                                                                                                key={
                                                                                                                    subChildIdx
                                                                                                                }
                                                                                                                data-city={subChildItem.cityName}
                                                                                                            >
                                                                                                                <Field
                                                                                                                    type="checkbox"
                                                                                                                    id={
                                                                                                                        `locations_` +
                                                                                                                        subChildItem.locationId
                                                                                                                    }
                                                                                                                    className="form-check-input"
                                                                                                                    name={
                                                                                                                        `locations_` +
                                                                                                                        subChildItem.locationId
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        subChildItem.locationId
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) => {
                                                                                                                        this.handleChange(
                                                                                                                            e,
                                                                                                                            subChildItem.locationId,
                                                                                                                            item.entityId,
                                                                                                                            subItem.stateId
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    checked={
                                                                                                                        this.state.locationIds.includes(
                                                                                                                            subChildItem.locationId
                                                                                                                        ) ||
                                                                                                                        this.state.alreadyAllocatedLocationIds.includes(
                                                                                                                            subChildItem.locationId
                                                                                                                        )
                                                                                                                    }
                                                                                                                    disabled={this.state.alreadyAllocatedLocationIds.includes(
                                                                                                                        subChildItem.locationId
                                                                                                                    )}
                                                                                                                />
                                                                                                                <label
                                                                                                                    className="form-check-label"
                                                                                                                    htmlFor={
                                                                                                                        `locations_` +
                                                                                                                        subChildItem.locationId
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        subChildItem.cityName
                                                                                                                    }
                                                                                                                </label>
                                                                                                            </li>
                                                                                                        )
                                                                                                    )}
                                                                                            </ul>
                                                                                        </Collapse>
                                                                                    </li>
                                                                                </React.Fragment>
                                                                            )
                                                                        )}
                                                                </ul>
                                                            </Collapse>
                                                        </li>
                                                    )
                                                );
                                            }
                                        )}
                                </ul>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button
                                    type="button"
                                    color="primary"
                                    className="mr-2"
                                    onClick={this.formSubmit}
                                >
                                    {SUBMIT}
                                </Button>

                            </div>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    getComplianceLocationApiResponse: makeAllocationSelectField(
        'getComplianceLocationApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    allocateCheckCompliancesEntityRequest,
});
export default compose(withConnect, withRouter)(MapLocationForm);
