import {
    ADD_FUND_MASTER_REQUEST,
    ADD_FUND_MASTER_RESPONSE,
    DELETE_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
    DELETE_FUND_MASTER_BY_FUND_MASTER_ID_RESPONSE,
    GET_ALL_FUND_MASTER_LIST_REQUEST,
    GET_ALL_FUND_MASTER_LIST_RESPONSE,
    GET_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
    GET_FUND_MASTER_BY_FUND_MASTER_ID_RESPONSE,
    GET_FUND_MASTER_LIST_BY_STATUS_REQUEST,
    GET_FUND_MASTER_LIST_BY_STATUS_RESPONSE,
    UPDATE_FUND_MASTER_REQUEST,
    UPDATE_FUND_MASTER_RESPONSE,
    UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_REQUEST,
    UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_RESPONSE,
} from './actionTypes';
export const addFundMasterRequest = (data) => {
    return {
        type: ADD_FUND_MASTER_REQUEST,
        payload: { data },
    };
};
export const addFundMasterResponse = (message, responseType) => {
    return {
        type: ADD_FUND_MASTER_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllFundMasterListRequest = () => {
    return {
        type: GET_ALL_FUND_MASTER_LIST_REQUEST,
    };
};
export const getAllFundMasterListResponse = (fundMasterList) => {
    return {
        type: GET_ALL_FUND_MASTER_LIST_RESPONSE,
        payload: { fundMasterList },
    };
};
export const getFundMasterByFundMasterIdRequest = (fundMasterId) => {
    return {
        type: GET_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
        payload: { fundMasterId: fundMasterId },
    };
};
export const getFundMasterByFundMasterIdResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_FUND_MASTER_BY_FUND_MASTER_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateFundMasterRequest = (fundMasterId, data) => {
    return {
        type: UPDATE_FUND_MASTER_REQUEST,
        payload: { fundMasterId, data },
    };
};
export const updateFundMasterResponse = (message, responseType) => {
    return {
        type: UPDATE_FUND_MASTER_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateFundMasterStatusByFundMasterIdRequest = (
    fundMasterId,
    data
) => {
    return {
        type: UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_REQUEST,
        payload: { fundMasterId, data },
    };
};
export const updateFundMasterStatusByFundMasterIdResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getFundMasterListByStatusRequest = (status) => {
    return {
        type: GET_FUND_MASTER_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getFundMasterListByStatusResponse = (fundMasterList) => {
    return {
        type: GET_FUND_MASTER_LIST_BY_STATUS_RESPONSE,
        payload: { fundMasterList },
    };
};
export const deleteFundMasterByFundMasterIdRequest = (fundMasterId) => {
    return {
        type: DELETE_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST,
        payload: { fundMasterId },
    };
};
export const deleteFundMasterByFundMasterIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_FUND_MASTER_BY_FUND_MASTER_ID_RESPONSE,
        payload: { message, responseType },
    };
};