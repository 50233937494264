import produce from 'immer';
import {
    CLEAR_OVERALL_TASK_API_RESPONSE,
    GET_DASHBOARD_ADMIN_DETAILS_DATA_RESPONSE,
    GET_DASHBOARD_DATA_BY_ROLE_RESPONSE,
    GET_DASHBOARD_DATA_FOR_RISK_RESPONSE,
    GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_RESPONSE,
    GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_RESPONSE,
    GET_DASHBOARD_DETAILS_DATA_RESPONSE,
    GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_RESPONSE,
    GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_RESPONSE,
    GET_DASHBOARD_LAW_SEARCH_RESPONSE,
    GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_RESPONSE,
    GET_DASHBOARD_M_DETAILS_DATA_RESPONSE,
    GET_OVERALL_TASK_LIST_RESPONSE,
} from './actionTypes';
export const initialState = {
    dashboardApiResponse: {},
    dashboardDetailsResponse: {},
    dashboardAdminDetailsResponse: {},
    dashboardMDetailsResponse: {},
    dashboardManagementDetailsResponse: {},
    overallTaskApiResponse: {},
    dashboardLawSearchApiResponse: {},
    dashboardLawCompliancesSearchApiResponse: {},
    dashboardLawDocumentSearchApiResponse: {},
    dashboardDepartmentTaskByEntity: {},
    monthWiseResponseData: {},
    dashboardDataForRiskResponse: {}
};
const dashboard = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_DASHBOARD_DATA_BY_ROLE_RESPONSE:
                draft.dashboardApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_DETAILS_DATA_RESPONSE:
                draft.dashboardDetailsResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_ADMIN_DETAILS_DATA_RESPONSE:
                draft.dashboardAdminDetailsResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_M_DETAILS_DATA_RESPONSE:
                draft.dashboardMDetailsResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_RESPONSE:
                draft.dashboardManagementDetailsResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_OVERALL_TASK_LIST_RESPONSE:
                draft.overallTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_LAW_SEARCH_RESPONSE:
                draft.dashboardLawSearchApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_RESPONSE:
                draft.dashboardLawCompliancesSearchApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_RESPONSE:
                draft.dashboardLawDocumentSearchApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;

            case GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_RESPONSE:
                draft.dashboardDepartmentTaskByEntity = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_RESPONSE:
                draft.monthWiseResponseData = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;

            case GET_DASHBOARD_DATA_FOR_RISK_RESPONSE:

                draft.dashboardDataForRiskResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case CLEAR_OVERALL_TASK_API_RESPONSE:
                draft.overallTaskApiResponse = {}
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default dashboard;
