import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import {
    RESET,
    SUCCESS
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import Select from 'react-select';
import { customValidTextField } from 'helpers/customValidators';
import __t from 'i18n/translator';
class AddEntityRoleForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField()
            )
        });
        this.state = {
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign({}, this.props.entityRoleData)}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className='col-lg-12'>
                                    <Field
                                        component={Select}
                                        name="entityId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropDown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'entityId',
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedEntityListDropDown.filter(
                                            option =>
                                                option.value ===
                                                values.entityId
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.entity')} <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.entity'),
                                        values.entityId,
                                        'required', {
                                        messages: {
                                            required: __t('validations.masters.entity_required')
                                        }
                                    }
                                    )}
                                </FormGroup>
                                <FormGroup className='col-lg-12'>
                                    <Field
                                        component={Select}
                                        name="roleId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedRoleListDropDown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'roleId',
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedRoleListDropDown.filter(
                                            option =>
                                                option.value ===
                                                values.roleId
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.role')} <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.role'),
                                        values.roleId,
                                        'required', {
                                        messages: {
                                            required: __t('validations.masters.role_required')
                                        }
                                    }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        name="roleTerm"
                                        className="form-control"
                                        placeholder={__t('form_labels.masters.role_term')}
                                        value={
                                            values.roleTerm
                                        }
                                        maxLength={150}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.role_term')} <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.role_term'),
                                        values.roleTerm,
                                        'required|max:150|alpha_space', {
                                        messages: {
                                            required: __t('validations.masters.role_term_required'),
                                            max: __t('validations.masters.role_term_max', { max: 150 }),
                                            alpha_space: __t('validations.masters.role_term_alpha_space')
                                        }
                                    }
                                    )}
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >{this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}>{RESET}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
export default AddEntityRoleForm;