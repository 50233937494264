import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.country || initialState;
const makeCountrySelectField = field =>
    createSelector(reducerState, countryState => countryState[field]);
const selectSortedCountryList = () =>
    createSelector(makeCountrySelectField('countryList'), countryList =>
        countryList.map(opt => ({
            value: opt.id,
            label: opt.countryName,
            isoCode2: opt.isoCode2,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
    
const selectSortedCurrencyList = () =>
    createSelector(makeCountrySelectField('countryList'), countryList =>
        countryList.map(opt => ({
            value: opt.id,
            label: opt.currency + ' - ' + opt.countryName,
            currencyRate: opt.currencyRate,
            currencySymbol: opt.currencySymbol,
            currency: opt.currency,
            countryName: opt.countryName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
const selectGcmAuthUserCountryList = () =>
    createSelector(makeCountrySelectField('gcmAuthUserCountryList'), countryList =>
        countryList.map(opt => ({
            value: opt.id,
            label: opt.countryName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeCountrySelectField,
    selectSortedCountryList,
    selectSortedCurrencyList,
    selectGcmAuthUserCountryList
};
