import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { ACTIVE, RESET, SUBMIT, SUCCESS, UPDATE } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import {
    customValidTextForSpecificField,
} from 'helpers/customValidators';
import __t from 'i18n/translator';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getUserListByStatusRequest,
    getUsersByCompanyGroupRequest,
    getTenantListByStatusRequest,
    cleareFilterUserResponse
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import tenantReducer from 'store/Masters/Tenant/reducer';
import tenantSaga from 'store/Masters/Tenant/saga';
import { selectSortedUserList } from 'store/User/selector';
import Select from 'react-select';
import { selectSortedTenantList } from 'store/Masters/Tenant/selector';

const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const tenantKey = 'tenant';
const withTenantReducer = injectReducer({
    key: tenantKey,
    reducer: tenantReducer,
});
const withTenantSaga = injectSaga({ key: tenantKey, saga: tenantSaga });
class AddCompanyGroupForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customValidTextForSpecificField()),
        });
        this.state = {
            sortedUserListDropdown: [],
            tenantList: [],
        };
    }
    componentDidMount() {
        this.props.getTenantListByStatusRequest(ACTIVE);
    }
    componentWillUnmount() {
        this.props.cleareFilterUserResponse();
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.props.getTenantListByStatusRequest(ACTIVE);
                this.resetForm();
            }
        }
        if (
            nextprops.companyGroupData &&
            this.props.companyGroupData !== nextprops.companyGroupData
        ) {
            if (nextprops.companyGroupData?.id) {
                this.props.getUsersByCompanyGroupRequest(
                    nextprops.companyGroupData.id
                );
            } else {
                this.setState({
                    sortedUserListDropdown: [],
                });
            }
        }

        if (
            nextprops.sortedUserListDropdown &&
            this.props.sortedUserListDropdown !==
            nextprops.sortedUserListDropdown
        ) {
            this.setState({
                sortedUserListDropdown: nextprops.sortedUserListDropdown,
            });
        }

        if (
            nextprops.sortedTenantListDropdown &&
            this.props.sortedTenantListDropdown !==
            nextprops.sortedTenantListDropdown
        ) {
            this.setState({
                tenantList: nextprops.sortedTenantListDropdown,
            });
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign(
                        {},
                        this.props.companyGroupData
                    )}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        component={Select}
                                        name="tenantId"
                                        className="form-control selectbox"
                                        options={this.state.tenantList.filter((tenant) => {
                                            if (this.props.operationType === SUBMIT) {
                                                return (tenant.companyGroups.length === 0)
                                            }
                                            if (this.props.operationType === UPDATE) {
                                                return ((tenant.value === this.props.companyGroupData.tenantId && tenant.companyGroups.length === 1) || (tenant.value !== values.tenantId && tenant.companyGroups.length === 0))
                                            }
                                            return false
                                        })}
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'tenantId',
                                                opt ? opt.value : ''
                                            );
                                        }}
                                        value={this.state.tenantList.filter(
                                            (option) =>
                                                option.value === values.tenantId
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.tenant')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.tenant'),
                                        values.tenantId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.tenant_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        name="companyGroupName"
                                        className="form-control"
                                        placeholder={__t(
                                            'form_labels.masters.company_group_name'
                                        )}
                                        value={values.companyGroupName}
                                        maxLength={255}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.masters.company_group_name'
                                        )}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.masters.company_group_name'
                                        ),
                                        values.companyGroupName,
                                        'required|max:255|onlyCharactersForSpecific',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.company_group_name_required'
                                                ),
                                                max: __t(
                                                    'validations.masters.company_group_name_max',
                                                    { max: 255 }
                                                ),
                                                alpha_space: __t(
                                                    'validations.masters.company_group_name_alpha_space'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>

                                <FormGroup className="col-lg-12">
                                    <Field
                                        component={Select}
                                        name="companyGroupAdmin[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedUserListDropdown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'companyGroupAdmin',
                                                opt && opt.map((x) => x.value)
                                            );
                                        }}
                                        value={this.state.sortedUserListDropdown.filter(
                                            (option) =>
                                                values.companyGroupAdmin?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.masters.company_group_admin'
                                        )}
                                    </Label>

                                </FormGroup>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >
                                                {this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {RESET}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedUserListDropdown: selectSortedUserList(),
    sortedTenantListDropdown: selectSortedTenantList(),
});
const withConnect = connect(mapStatetoProps, {
    getUserListByStatusRequest,
    getUsersByCompanyGroupRequest,
    getTenantListByStatusRequest,
    cleareFilterUserResponse
});
export default compose(
    withConnect,
    withUserReducer,
    withUserSaga,
    withTenantReducer,
    withTenantSaga,
    withRouter
)(AddCompanyGroupForm);
