import produce from 'immer';
import { CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE, GET_EXECUTED_EVENT_LIST_RESPONSE, GET_ONGOING_EVENT_LIST_RESPONSE, GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_RESPONSE, PLAN_EVENT_RESPONSE, UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE, UPDATE_USER_DETAILS_BY_TASK_ID_RESPONSE } from './actionTypes';
export const initialState = {
    planEventApiResponse: null,
    ongoingEventList: [],
    executedEventList: [],
    planEventDetailsApiResponse: null,
    updateUserDetailsApiResponse: null
};
const eventAllocation = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case PLAN_EVENT_RESPONSE:
                draft.planEventApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType
                }
                break;

            case GET_ONGOING_EVENT_LIST_RESPONSE:
                draft.ongoingEventList = action.payload.ongoingEventList
                break;



            case GET_EXECUTED_EVENT_LIST_RESPONSE:
                draft.executedEventList = action.payload.executedEventList
                break;

            case GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_RESPONSE:
                draft.planEventDetailsApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType
                }
                break;

            case CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE:
            case UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE:
                draft.updateStatusEventAllocationApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType
                }
                break;
            case UPDATE_USER_DETAILS_BY_TASK_ID_RESPONSE:
                draft.updateUserDetailsApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default eventAllocation;
