import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ACTIVATE_ACCOUNT_REQUEST,
    ACTIVATE_ACCOUNT_TOKEN_REQUEST,
    FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST, RESET_PASSWORD_TOKEN_REQUEST
} from './actionTypes';
import {
    activateAccountResponse,
    activateAccountTokenResponse,
    resetPasswordResponse, resetPasswordTokenResponse
} from './actions';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { SUCCESS, DANGER } from 'constants/commonConstants';
import { CONFIRM_ACTIVATE_ACCOUNT_TOKEN, ACTIVATE_ACCOUNT } from 'helpers/apiConstants';
function* activateAccountToken({ payload: { token } }) {
    try {
        const response = yield call(invokeApi, 'get', CONFIRM_ACTIVATE_ACCOUNT_TOKEN + '/' + token);
        if (response.status) {
            yield put(
                activateAccountTokenResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(activateAccountTokenResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* activateAccount({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ACTIVATE_ACCOUNT, data);
        if (response.status) {
            yield put(activateAccountResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(activateAccountResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchUserActivateAccount() {
    yield takeEvery(ACTIVATE_ACCOUNT_TOKEN_REQUEST, activateAccountToken);
    yield takeEvery(ACTIVATE_ACCOUNT_REQUEST, activateAccount);
}
function* activateAccountSaga() {
    yield all([fork(watchUserActivateAccount)]);
}
export default activateAccountSaga;
