import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.lawCategory || initialState;
const makeLawCategorySelectField = field =>
    createSelector(reducerState, lawCategoryState => lawCategoryState[field]);
const selectSortedLawCategoryList = () =>
    createSelector(makeLawCategorySelectField('lawCategoryList'), lawCategoryList =>
        lawCategoryList.map(opt => ({
            value: opt.id,
            label: opt.lawCategoryName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeLawCategorySelectField,
    selectSortedLawCategoryList
};
