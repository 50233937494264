import React, { Component } from 'react';
import { Row, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    CLEAR,
    DATE_FORMAT_DATEPICKER,
    SEARCH,
    SUBMIT,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getUserEntityListRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getTaskStatusListRequest,
    getUserAssignedComplianceListRequest,
    getDepartmentListByStatusRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { initialTaskFilterDataState } from './CommonFunctions';
import __t from 'i18n/translator';
import { selectSortedTaskStatusList } from 'store/Task/selector';
import { selectSortedAssignedComplianceList } from 'store/Allocation/selector';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
    DISCREPANCY_COMPLETED_RESOLUTION,
    DISCREPANCY_ONGOING_RESOLUTION,
    DISCREPANCY_PENDING_RESOLUTION,
    PENDING_FOR_AUDIT,
    PENDING_FOR_COMPLETION,
    PENDING_WITH_FIRST_APPROVER,
    PENDING_WITH_SECOND_APPROVER,
    REOPENED,
    SEND_BACK_BY_AUDITOR_TO_APPROVER,
    SEND_BACK_BY_AUDITOR_TO_SUBMITTER,
    SEND_BACK_TO_APPROVER,
    SEND_BACK_TO_SUBMITTER,
} from 'constants/databaseConstants';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import { getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
class TaskFilterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            filterData: initialTaskFilterDataState(),
            operationType: SUBMIT,
        };
    }
    componentDidMount() {
        this.props.getUserEntityListRequest();
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getTaskStatusListRequest();
        this.props.getDepartmentListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? stateIds : []);
    }
    getAssignedComplianceList(values) {
        this.props.getUserAssignedComplianceListRequest(values);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                filterData: initialTaskFilterDataState(),
            },
            () => {
                this.props.onFormReset();
            }
        );
    };

    render() {
        let sortedStatusDropdown = [];
        if (this.props.pageType == 'report') {
            const reportStatusIds = [
                PENDING_FOR_COMPLETION,
                REOPENED,
                SEND_BACK_TO_SUBMITTER,
                SEND_BACK_BY_AUDITOR_TO_SUBMITTER,
            ];
            sortedStatusDropdown =
                this.props.sortedTaskStatusListDropdown.filter((opt) =>
                    reportStatusIds.includes(opt.value)
                );
        }

        if (this.props.pageType == 'review') {
            const reportStatusIds = [
                PENDING_WITH_FIRST_APPROVER,
                PENDING_WITH_SECOND_APPROVER,
                SEND_BACK_TO_APPROVER,
                SEND_BACK_BY_AUDITOR_TO_APPROVER,
            ];
            sortedStatusDropdown =
                this.props.sortedTaskStatusListDropdown.filter((opt) =>
                    reportStatusIds.includes(opt.value)
                );
        }

        if (this.props.pageType == 'audit') {
            const reportStatusIds = [CLOSED, PENDING_FOR_AUDIT, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION, AUDITED_AND_CLOSED];
            sortedStatusDropdown =
                this.props.sortedTaskStatusListDropdown.filter((opt) =>
                    reportStatusIds.includes(opt.value)
                );
        }
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.state.filterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={Select}
                                        name="entityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropdown
                                        }
                                        onChange={(opt) => {
                                            let entityId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('entityId', entityId);
                                            this.getAssignedComplianceList({
                                                ...values,
                                                entityId: entityId,
                                            });
                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            (option) =>
                                                values.entityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.task_filters.entity')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={Select}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropdown
                                        }
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let countryId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'countryId',
                                                countryId
                                            );
                                            setFieldValue('stateId', []);
                                            this.getStateListByCountry(
                                                countryId
                                            );
                                            this.getAssignedComplianceList({
                                                ...values,
                                                countryId: countryId,
                                            });
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            (option) =>
                                                values.countryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.task_filters.country'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedStateListDropdown
                                        }
                                        onChange={(opt) => {
                                            let stateId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('stateId', stateId);
                                            setFieldValue('cityId', []);
                                            this.getCityListByState(
                                                opt && opt.map((x) => x.value)
                                            );
                                            this.getAssignedComplianceList({
                                                ...values,
                                                stateId: stateId,
                                            });
                                        }}
                                        value={this.props.sortedStateListDropdown?.filter(
                                            (option) =>
                                                values.stateId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.task_filters.state')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCityListDropdown
                                        }
                                        onChange={(opt) => {
                                            let cityId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('cityId', cityId);
                                            this.getAssignedComplianceList({
                                                ...values,
                                                cityId: cityId,
                                            });
                                        }}
                                        value={this.props.sortedCityListDropdown?.filter(
                                            (option) =>
                                                values.cityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.task_filters.city')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-6">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="complianceId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedComplianceListDropdown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'complianceId',
                                                opt
                                                    ? opt.map((x) => x.value)
                                                    : []
                                            );
                                        }}
                                        value={this.props.sortedComplianceListDropdown?.filter(
                                            (option) =>
                                                values.complianceId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.task_filters.compliance'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="statusId[]"
                                        className="form-control selectbox"
                                        options={sortedStatusDropdown}
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'statusId',
                                                opt
                                                    ? opt.map((x) => x.value)
                                                    : []
                                            );
                                        }}
                                        value={sortedStatusDropdown?.filter(
                                            (option) =>
                                                values.statusId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.task_filters.status')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="departmentId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedDepartmentListDropdown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'departmentId',
                                                opt
                                                    ? opt.map((x) => x.value)
                                                    : []
                                            );
                                        }}
                                        value={this.props.sortedDepartmentListDropdown?.filter(
                                            (option) =>
                                                values.departmentId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.task_filters.department'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3 w-100 sticky_label">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="riskRatingId[]"
                                        className="form-control selectbox"
                                        options={this.props.riskRatingsList}
                                        onChange={(opt) => {
                                            let riskRatingId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('riskRatingId', riskRatingId);
                                        }}
                                        value={this.props.riskRatingsList?.filter(
                                            (option) =>
                                                values.riskRatingId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">Risk Level</Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3 w-100 sticky_label">
                                    <Field
                                        component={DatePicker}
                                        isClearable={true}
                                        className="form-control"
                                        name="fromDate"
                                        selected={
                                            values.fromDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.fromDate
                                                )
                                                : null
                                        }
                                        dateFormat={DATE_FORMAT_DATEPICKER}
                                        onChange={(date) => {
                                            setFieldValue('fromDate', date);
                                        }}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dropdownMode="select"
                                        placeholderText={__t(
                                            'form_labels.task_filters.from_date'
                                        )}
                                        onFocus={(e) => e.target.blur()}
                                        maxDate={values.toDate}
                                    ></Field>
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.task_filters.from_date'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3 w-100 sticky_label">
                                    <Field
                                        component={DatePicker}
                                        isClearable={true}
                                        className="form-control"
                                        name="toDate"
                                        selected={
                                            values.toDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.toDate
                                                )
                                                : null
                                        }
                                        dateFormat={DATE_FORMAT_DATEPICKER}
                                        onChange={(date) => {
                                            setFieldValue('toDate', date);
                                        }}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dropdownMode="select"
                                        placeholderText={__t(
                                            'form_labels.task_filters.to_date'
                                        )}
                                        onFocus={(e) => e.target.blur()}
                                        minDate={values.fromDate}
                                    ></Field>
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.task_filters.to_date'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3 d-flex justify-content-end mb-0">
                                    <div className="d-inline-block">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="mr-1"
                                        >
                                            {SEARCH}
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >
                                            {CLEAR}
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
    sortedComplianceListDropdown: selectSortedAssignedComplianceList(),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getUserEntityListRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getTaskStatusListRequest,
    getUserAssignedComplianceListRequest,
    getDepartmentListByStatusRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withEntityReducer,
    withEntitySaga,
    withAllocationReducer,
    withAllocationSaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withRouter
)(TaskFilterForm);
