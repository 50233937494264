import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import {
    ACTIVE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    SUCCESS,
    UPDATE,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import __t from 'i18n/translator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import {
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getAllEntityListRequest,
    getEntityTeamsByEntityIdRequest,
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';

import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import {
    selectSortedEntityList,
    selectSortedEntityTeamAndConsultantTeamUserList,
} from 'store/Entity/selector';
import { delegationTypeList } from 'constants/dropdowns';
import {
    customDateValidator,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { makeEntitySelectField } from 'store/Entity/selector';

const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
class AddDelegationForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            type: true,
        };
    }
    componentDidMount() {
        this.props.getAllEntityListRequest();
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
    }
    getEntityTeamsByEntityId(entityId) {
        this.props.getEntityTeamsByEntityIdRequest(entityId);
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
        if (nextprops.delegationUserData !== this.props.delegationUserData) {
            if (nextprops.operationType === UPDATE) {
                this.getEntityTeamsByEntityId(
                    nextprops.delegationUserData?.entityId
                );
            }
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign(
                        {},
                        this.props.delegationUserData
                    )}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Row>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="entityId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropDown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'entityId',
                                                opt.value
                                            );
                                            setFieldValue('fromUserId', '');
                                            setFieldValue('toUserId', '');
                                            this.getEntityTeamsByEntityId(
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedEntityListDropDown.filter(
                                            (option) =>
                                                option.value === values.entityId
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.entity')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.entity'),
                                        values.entityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.entity_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="fromUserId"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedEntityTeamAndConsultantTeamUsersDropdown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'fromUserId',
                                                opt.value
                                            );
                                            setFieldValue('toUserId', '');
                                        }}
                                        value={this.props.sortedEntityTeamAndConsultantTeamUsersDropdown.filter(
                                            (option) =>
                                                option.value ===
                                                values.fromUserId
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.delegation.from_user'
                                        )}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.delegation.from_user'),
                                        values.fromUserId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.delegation.from_user'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="toUserId"
                                        className="form-control selectbox"
                                        options={this.props.sortedEntityTeamAndConsultantTeamUsersDropdown.filter(
                                            (opt) => {
                                                if (
                                                    opt.value !=
                                                    values.fromUserId
                                                )
                                                    return opt;
                                            }
                                        )}
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'toUserId',
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedEntityTeamAndConsultantTeamUsersDropdown.filter(
                                            (option) =>
                                                option.value === values.toUserId
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.delegation.to_user')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.delegation.to_user'),
                                        values.toUserId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.delegation.to_user'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>

                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="type"
                                        className="form-control selectbox"
                                        options={delegationTypeList}
                                        onChange={(opt) => {
                                            setFieldValue('type', opt.value);

                                        }}
                                        value={delegationTypeList.filter(
                                            (option) =>
                                                option.value === values.type
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.delegation.type')}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.delegation.type'),
                                        values.type,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.delegation.type'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                {values.type === 'TEMPORARY' && (
                                    <>
                                        <FormGroup className="col-lg-4 w-100 sticky_label">
                                            <Field
                                                component={DatePicker}
                                                isClearable={true}
                                                className="form-control"
                                                name="fromDate"
                                                selected={
                                                    values.fromDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.fromDate
                                                        )
                                                        : null
                                                }
                                                dateFormat={
                                                    DATE_FORMAT_DATEPICKER
                                                }
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'fromDate',
                                                        date
                                                    );

                                                    let dateCheck =
                                                        getDateObjectWithValueFromMoment(
                                                            values.toDate
                                                        ) <
                                                            getDateObjectWithValueFromMoment(
                                                                date
                                                            )
                                                            ? true
                                                            : false;
                                                    if (dateCheck) {
                                                        setFieldValue(
                                                            'toDate',
                                                            ''
                                                        );
                                                    }
                                                }}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                                placeholderText={__t(
                                                    'form_labels.delegation.from_period'
                                                )}
                                                onFocus={(e) => e.target.blur()}
                                                minDate={new Date()}
                                            ></Field>

                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.delegation.from_period'
                                                )}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>

                                            {this.validator.message(
                                                __t(
                                                    'form_labels.delegation.from_period'
                                                ),
                                                values.fromDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.fromDate
                                                    )
                                                    : '',
                                                'required|customDate',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.delegation.from_date'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className="col-lg-4 w-100 sticky_label">
                                            <Field
                                                component={DatePicker}
                                                isClearable={true}
                                                className="form-control"
                                                name="toDate"
                                                selected={
                                                    values.toDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.toDate
                                                        )
                                                        : null
                                                }
                                                dateFormat={
                                                    DATE_FORMAT_DATEPICKER
                                                }
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'toDate',
                                                        date
                                                    );
                                                }}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                                placeholderText={__t(
                                                    'form_labels.delegation.to_period'
                                                )}
                                                onFocus={(e) => e.target.blur()}
                                                minDate={values.fromDate}
                                            ></Field>
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.delegation.to_period'
                                                )}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.delegation.to_period'
                                                ),
                                                values.toDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.toDate
                                                    )
                                                    : '',
                                                'required|customDate',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.delegation.to_date'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </>
                                )}
                                {values.type === 'PERMANENT' && (
                                    <>
                                        <FormGroup className="col-lg-4 w-100 sticky_label">
                                            <Field
                                                component={DatePicker}
                                                isClearable={true}
                                                className="form-control"
                                                name="fromDate"
                                                selected={
                                                    values.fromDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.fromDate
                                                        )
                                                        : null
                                                }
                                                dateFormat={
                                                    DATE_FORMAT_DATEPICKER
                                                }
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'fromDate',
                                                        date
                                                    );

                                                    let dateCheck =
                                                        getDateObjectWithValueFromMoment(
                                                            values.toDate
                                                        ) <
                                                            getDateObjectWithValueFromMoment(
                                                                date
                                                            )
                                                            ? true
                                                            : false;
                                                    if (dateCheck) {
                                                        setFieldValue(
                                                            'toDate',
                                                            ''
                                                        );
                                                    }
                                                }}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                                placeholderText={__t(
                                                    'form_labels.delegation.from_period'
                                                )}
                                                onFocus={(e) => e.target.blur()}
                                                minDate={new Date()}
                                            ></Field>

                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.delegation.from_period'
                                                )}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>

                                            {this.validator.message(
                                                __t(
                                                    'form_labels.delegation.from_period'
                                                ),
                                                values.fromDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.fromDate
                                                    )
                                                    : '',
                                                'required|customDate',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.delegation.from_date'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                    </>
                                )}
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >
                                                {this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {CANCEL}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    entityList: makeEntitySelectField('entityList'),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedEntityListDropDown: selectSortedEntityList(),
    sortedEntityTeamAndConsultantTeamUsersDropdown:
        selectSortedEntityTeamAndConsultantTeamUserList(),
});
const withConnect = connect(mapStatetoProps, {
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getAllEntityListRequest,
    getEntityTeamsByEntityIdRequest,
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(AddDelegationForm);
