export const GET_FUND_TASK_USERS_BY_ENTITY_REQUEST =
    'get_fund_task_users_by_entity_request';
export const GET_FUND_TASK_USERS_BY_ENTITY_RESPONSE =
    'get_fund_task_users_by_entity_response';

export const ADD_FUND_REQUEST = 'add_fund_request';
export const ADD_FUND_RESPONSE = 'add_fund_response';

export const GET_FUND_LIST_REQUEST = 'get_fund_list_request';
export const GET_FUND_LIST_RESPONSE = 'get_fund_list_response';

export const GET_FUND_DETAILS_REQUEST = 'get_fund_details_request';
export const GET_FUND_DETAILS_RESPONSE = 'get_fund_details_response';

export const UPDATE_FUND_REQUEST = 'update_fund_request';
export const UPDATE_FUND_RESPONSE = 'update_fund_response';

export const DELETE_FUND_BY_FUND_ID_REQUEST =
    'delete_fund_by_fund_id_request';
export const DELETE_FUND_BY_FUND_ID_RESPONSE =
    'delete_fund_by_fund_id_response';

export const GET_FUND_TASK_LIST_REQUEST = 'get_fund_task_list_request';
export const GET_FUND_TASK_LIST_RESPONSE = 'get_fund_task_list_response';

export const ADD_FUND_TASK_REQUEST = 'add_fund_task_request';
export const ADD_FUND_TASK_RESPONSE = 'add_fund_task_response';

export const UPDATE_FUND_TASK_REQUEST = 'update_fund_task_request';
export const UPDATE_FUND_TASK_RESPONSE = 'update_fund_task_response';

export const DELETE_FUND_TASK_BY_TASK_ID_REQUEST =
    'delete_fund_task_by_task_id_request';
export const DELETE_FUND_TASK_BY_TASK_ID_RESPONSE =
    'delete_fund_task_by_task_id_response';

export const ADD_FUND_SUB_TASK_REQUEST = 'add_fund_sub_task_request';
export const ADD_FUND_SUB_TASK_RESPONSE = 'add_fund_sub_task_response';

export const DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_REQUEST =
    'delete_fund_sub_task_by_sub_task_id_request';
export const DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_RESPONSE =
    'delete_fund_sub_task_by_sub_task_id_response';

export const GET_FUND_TASK_DETAILS_REQUEST =
    'get_fund_task_details_request';
export const GET_FUND_TASK_DETAILS_RESPONSE =
    'get_fund_task_details_response';

export const ADD_FUND_TASK_COMMENT_REQUEST =
    'add_fund_task_comment_request';
export const ADD_FUND_TASK_COMMENT_RESPONSE =
    'add_fund_task_comment_response';

export const ADD_FUND_TASK_ATTACHMENT_REQUEST =
    'add_fund_task_attachment_request';
export const ADD_FUND_TASK_ATTACHMENT_RESPONSE =
    'add_fund_task_attachment_response';

export const GET_FUND_TASK_ACTIVITY_REQUEST =
    'get_fund_task_activity_request';
export const GET_FUND_TASK_ACTIVITY_RESPONSE =
    'get_fund_task_activity_response';

export const DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST =
    'delete_fund_task_document_by_document_id_request';
export const DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE =
    'delete_fund_task_document_by_document_id_response';

export const CLOSE_FUND_REQUEST = 'close_fund_request';
export const CLOSE_FUND_RESPONSE = 'close_fund_response';

export const ACTIVE_OR_DEACTIVE_FUND_REQUEST = 'active_or_deactive_fund_request';
export const ACTIVE_OR_DEACTIVE_FUND_RESPONSE = 'active_or_deactive_fund_response';

export const GET_FUND_HEALTH_CHECK_REQUEST = 'get_fund_health_check_request';
export const GET_FUND_HEALTH_CHECK_RESPONSE = 'get_fund_health_check_response';

export const GET_FUND_CHECK_ACCESS_REQUEST = 'get_fund_check_access_request';
export const GET_FUND_CHECK_ACCESS_RESPONSE = 'get_fund_check_access_response';

export const UPDATE_FUND_TASK_SEQUENCE_ORDER_REQUEST = 'update_fund_task_sequence_order_request';
export const UPDATE_FUND_TASK_SEQUENCE_ORDER_RESPONSE = 'update_fund_task_sequence_order_response';

export const GET_FUND_TASK_COUNT_BY_FUND_ID_REQUEST = 'get_fund_task_count_by_fund_id_request';
export const GET_FUND_TASK_COUNT_BY_FUND_ID_RESPONSE = 'get_fund_task_count_by_fund_id_response';

export const RETRIEVE_FUND_BY_FUND_ID_REQUEST = 'retrieve_fund_by_fund_id_request';
export const RETRIEVE_FUND_BY_FUND_ID_RESPONSE = 'retrieve_fund_by_fund_id_response';
