import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_GENERATE_CERTIFICATE_REQUEST, GET_REPORT_DATA_BY_TYPE_REQUEST, GET_SYSTEM_RUNTIME_REPORT_REQUEST } from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { getGenerateCertificateResponse, getReportDataByTypeResponse, getSystemRuntimeReportResponse } from './actions';
import { GET_CERTIFICATE_DATA, REPORT_DATA_BY_TYPE, REPORT_SYSTEM_RUNTIME } from 'helpers/apiConstants';
import { DANGER, SUCCESS } from 'constants/commonConstants';

function* getReportDataByType({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', REPORT_DATA_BY_TYPE, data);
        if (response.status) {
            yield put(getReportDataByTypeResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getReportDataByTypeResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSystemRunTimeReport({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', REPORT_SYSTEM_RUNTIME, data);
        if (response.status) {
            yield put(getSystemRuntimeReportResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getSystemRuntimeReportResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getGenerateCertificate({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', GET_CERTIFICATE_DATA, data);
        if (response.status) {
            yield put(getGenerateCertificateResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getGenerateCertificateResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchReport() {
    yield takeEvery(GET_REPORT_DATA_BY_TYPE_REQUEST, getReportDataByType);
    yield takeEvery(GET_SYSTEM_RUNTIME_REPORT_REQUEST, getSystemRunTimeReport);
    yield takeEvery(GET_GENERATE_CERTIFICATE_REQUEST, getGenerateCertificate);
}
function* reportSaga() {
    yield all([fork(watchReport)]);
}
export default reportSaga;
