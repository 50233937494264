import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Parent Task Name',
        field: 'fundMasterName',
    },
    {
        label: 'Company Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Entity',
        field: 'entityName',
        attributes: {
            className: 'white-space',
        },
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];


export const fundMasterListOfColumns = [
    {
        label: 'Fund Name',
        field: 'fundMasterName',
    },

    {
        label: 'Entity',
        field: 'entityName',
        attributes: {
            className: 'white-space',
        },
    },
   
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];

export const initialFundMasterDataState = () => {
    return {
        fundName: '',
        companyGroupId: '',
        entityId:[],
        isActive: ACTIVE
    }
}
export const intFields = [];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};