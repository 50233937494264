export const TOKEN = 'Token';
export const ROLE_ID = 'roleId';
export const USER_ID = 'userId';
export const USER_EMAIL = 'email';
export const USER_USERNAME = 'userName';
export const USER_CONTACT_NO = 'contactNo';
export const USER_FULL_NAME = 'fullName';
export const SET_NEW_PASSWORD = 'issetPassword';
export const ROLES = 't.session.userguard';
export const PERMISSIONS = 't.session.perguardsecurity';
export const CURRENT_ROLE = 'currentRole';
export const PROJECT_LAYOUT = 'project_enabled';
export const FUND_LAYOUT = 'fund_enabled';
export const USER_ROLES = 't.session.grantuserguard';
export const LOGIN_TYPE = 'login_type';
export const TENANT_FUND = "tenant_fund";
export const THEME_MODE = 'theme_mode';