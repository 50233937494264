import produce from 'immer';
import {
    DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_RESPONSE,
    GET_ENTITY_ROLE_LIST_RESPONSE,
    GET_MODULE_LIST_RESPONSE,
    GET_PERMISSION_LIST_RESPONSE,
    GET_ROLE_BY_ROLE_ID_RESPONSE,
    GET_ROLE_LIST_RESPONSE,
    GET_ROLE_PERMISSION_BY_LOGIN_USER_RESPONSE,
    GET_SYSTEM_CONFIGURATION_ROLE_LIST_RESPONSE,
    GET_SYSTEM_CONFIGURATION_USERS_LIST_RESPONSE,
    SAVE_SYSTEM_CONFIGURATION_USERS_RESPONSE,
    UPDATE_ROLE_BY_ROLE_ID_RESPONSE,
    UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_RESPONSE,
    GET_USER_ROLE_WISE_COUNTRIES_LIST_RESPONSE,
    GET_GCM_USERS_BY_COUNTRY_RESPONSE,
} from './actionTypes';
export const initialState = {
    roleList: [],
    moduleList: [],
    permissionList: [],
    roleApiResponse: null,
    editRoleApiResponse: null,
    loginUserRolePermissionResponse: null,
    systemConfigurationUserList: [],
    systemConfigurationRoleApiResponse: null,
    updateStatusSystemConfigurationRoleApiResponse: null,
    deleteSystemConfigurationApiResponse: null,
    getUserRoleWiseCountriesList: null,
    getGcmUsersList: null,
};
const role = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_ROLE_LIST_RESPONSE:
                draft.roleList = action.payload.roleList;
                break;
            case GET_MODULE_LIST_RESPONSE:
                draft.moduleList = action.payload.moduleList;
                break;
            case GET_PERMISSION_LIST_RESPONSE:
                draft.permissionList = action.payload.permissionList;
                break;
            case GET_ROLE_BY_ROLE_ID_RESPONSE:
                draft.editRoleApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case UPDATE_ROLE_BY_ROLE_ID_RESPONSE:
                draft.roleApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ROLE_PERMISSION_BY_LOGIN_USER_RESPONSE:
                draft.loginUserRolePermissionResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ENTITY_ROLE_LIST_RESPONSE:
                draft.roleList = action.payload.roleList;
                break;
            case GET_SYSTEM_CONFIGURATION_ROLE_LIST_RESPONSE:
                draft.roleList = action.payload.roleList;
                break;
            case SAVE_SYSTEM_CONFIGURATION_USERS_RESPONSE:
                draft.systemConfigurationRoleApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_SYSTEM_CONFIGURATION_USERS_LIST_RESPONSE:
                draft.systemConfigurationUserList =
                    action.payload.systemConfigurationUserList;
                break;
            case UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_RESPONSE:
                draft.updateStatusSystemConfigurationRoleApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_RESPONSE:
                draft.deleteSystemConfigurationApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_USER_ROLE_WISE_COUNTRIES_LIST_RESPONSE:
                draft.getUserRoleWiseCountriesList = action.payload.countryList;
                break;
            case GET_GCM_USERS_BY_COUNTRY_RESPONSE:
                draft.getGcmUsersList = action.payload.userList;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default role;
