import React, { Component } from 'react';
import {
    Row, Col,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CANCEL, DANGER, SUBMIT, UPDATE } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { customValidatorForSectionName } from 'helpers/customValidators';
import { toastrMessage } from 'helpers/messageHelper';
class AddEditSectionFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ validators: customValidatorForSectionName() });
        this.state = {
            sectionList: this.props.sectionList,
            sectionData: this.props.sectionData
        }
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = async values => {
        if (this.validator.allValid()) {
            if (values.sectionName.includes(";") && this.props.operationType === SUBMIT) {
                let sectionNameArr = values.sectionName.split(";");
                sectionNameArr = sectionNameArr.filter((sacName) => sacName.trim().length > 0);
                sectionNameArr = [...new Set(sectionNameArr.map((sectionName) => sectionName.trim()))];
                let newSectionArr = sectionNameArr.map((sectionName) => ({ sectionName: sectionName }))
                let sectionNameExist = this.state.sectionList.some((element) => newSectionArr.some((e) => element.sectionName === e.sectionName));
                if (sectionNameExist === true) {
                    toastrMessage(__t('validations.masters.section_name_exist'), DANGER);
                } else {
                    this.props.onFormValid(newSectionArr);
                }
            } else {
                let sectionNameExist = this.state.sectionList.filter((section) => section.sectionName == values.sectionName.trim())
                if (sectionNameExist.length > 0) {
                    toastrMessage(__t('validations.masters.section_name_exist'), DANGER);
                } else {
                    this.props.onFormValid(values);
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.sectionList !== this.props.sectionList) {
            this.setState({
                sectionList: nextprops.sectionList
            })
        }
        if (nextprops.operationType !== this.props.operationType) {
            this.setState({
                sectionData: nextprops.sectionData,
                operationType: nextprops.operationType
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={(this.props.operationType === SUBMIT) ? __t('form_labels.laws.add_section') : __t('form_labels.laws.edit_section')}
                    isModalOpen={this.props.isModalOpen}
                    width="50%"
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.sectionData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Row>
                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    name="sectionName"
                                                    className="form-control"
                                                    placeholder={__t('form_labels.masters.section_name')}
                                                    value={
                                                        values.sectionName
                                                    }
                                                    maxLength={150}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.masters.section_name')} <span className='text-danger required'>*</span>
                                                </Label>
                                                {

                                                    this.props.operationType === UPDATE ?
                                                        this.validator.message(
                                                            __t('form_labels.masters.section_name'),
                                                            values.sectionName,
                                                            'required|min:3|max:150|onlySectionName', {
                                                            messages: {
                                                                required: __t('validations.masters.section_name_required'),
                                                                max: __t('validations.masters.section_name_max', { max: 150 })
                                                            }
                                                        }) :
                                                        this.validator.message(
                                                            __t('form_labels.masters.section_name'),
                                                            values.sectionName,
                                                            'required|min:3|max:3000|onlySectionName', {
                                                            messages: {
                                                                required: __t('validations.masters.section_name_required'),
                                                                max: __t('validations.masters.section_name_max', { max: 3000 })
                                                            }
                                                        }
                                                        )}
                                                {this.props.operationType === SUBMIT && (<small className='help-text'><strong>Note:</strong> {__t('form_labels.masters.section_name_note')}</small>
                                                )}
                                            </FormGroup>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {this.props.operationType}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={this.props.onModalDismiss}
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});
export default compose(
    withConnect,
    withRouter
)(AddEditSectionFormModal);