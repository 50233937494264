import React from 'react';
import { MDBDataTable } from 'mdbreact';
import { DEFAULT_PAGE_LENGTH, DEFAULT_PAGE_SIZE_OPTIONS } from 'constants/commonConstants';
class CustomisedDatatable extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    data={this.props.tableRecords}
                    noBottomColumns
                    searching={
                        this.props.searching === false
                            ? this.props.searching
                            : true
                    }
                    paging={
                        this.props.paging === false ? this.props.paging : true
                    }
                    
                    entriesOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    displayEntries={this.props.displayEntries === false ? this.props.displayEntries : true}
                    entries={(this.props.entries) ? this.props.entries : DEFAULT_PAGE_LENGTH}
                    sortRows={(this.props.sortRows) ? this.props.sortRows : []}
                />
            </React.Fragment>
        );
    }
}
export default CustomisedDatatable;
