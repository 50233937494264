import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { compose } from 'redux';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import __t from 'i18n/translator';
import { getTrackAllocationStatusNameById } from 'helpers/projectUtils';
export const listColumns = [
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Compliance Title',
        field: 'complianceTitle',
    },
    {
        label: 'Company Group',
        field: 'companyGroupName',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State',
        field: 'stateName',
    },
    {
        label: 'City',
        field: 'cityName',
    },
    {
        label: 'Short Name of Act/Regulation',
        field: 'lawShortName',
    },
    {
        label: 'Compliance Classification',
        field: 'complianceClassification',
    },
    {
        label: 'Status',
        field: 'status'
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
class OverallAllocationIndex extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            allocationList: []
        };
    }
    componentDidMount() {
        this.setState({
            allocationList: this.generateTable(this.props.tableData),
        });
    }
    getUpdateTypeName = (value) => {
        let data = this.props.updateTypeList
            ?.filter((x) => value?.includes(x.value))
            ?.map((y) => y.label);
        if (data.length === 1) {
            return data.map((x) => {
                return x;
            });
        }
        return (
            <ul className="update_type_list">
                {data.map((x) => {
                    return (
                        <>
                            <li>{x}</li>
                        </>
                    );
                })}
            </ul>
        );
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((allocationData, i) => {
            let actions = (
                <div className="tbl_action">
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            window.open(
                                '/statutory-compliances/view-details/' +
                                allocationData.lawComplianceId,
                                '_blank'
                            );
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                </div>
            );
            return {
                companyGroupName:
                    allocationData.entity?.companyGroup?.companyGroupName,
                entityName: allocationData.entity?.entityName,
                complianceCode: allocationData.lawCompliance?.complianceCode,
                countryName:
                    allocationData.entityLocation?.country?.countryName,
                stateName: allocationData.entityLocation?.state?.stateName,
                cityName: allocationData.entityLocation?.city?.cityName,
                lawShortName: allocationData.lawCompliance?.law?.lawShortName,
                complianceTitle: allocationData.lawCompliance?.complianceTitle,
                complianceClassification:
                    allocationData.lawCompliance?.complianceCategory
                        ?.complianceCategoryName,
                status: getTrackAllocationStatusNameById(allocationData.status),
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.tableData) {
            this.setState({
                allocationList: this.generateTable([]),
            }, () => {
                this.setState({
                    allocationList: this.generateTable(nextprops.tableData),
                });
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.allocationList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <Link
                            to="#"
                            className="btn btn-primary mb-3 dashboard_back_btn"
                            onClick={() => {
                                this.props.closeClickData();
                            }}
                        >
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                    <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Back
                        </Link>
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable
                                    tableRecords={data}
                                    sortRows={[
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});
export default compose(
    withConnect,
    withRouter
)(OverallAllocationIndex);
