import produce from 'immer';
import { ADD_TENANT_RESPONSE, GET_ALL_TENANT_LIST_RESPONSE, GET_TENANT_BY_TENANT_ID_RESPONSE, GET_TENANT_LIST_BY_STATUS_RESPONSE, UPDATE_TENANT_RESPONSE, UPDATE_TENANT_STATUS_BY_TENANT_ID_RESPONSE, DELETE_TENANT_BY_TENANT_ID_RESPONSE, GET_TENANT_LIST_RESPONSE, GENERATE_DATABASE_BY_TENANT_ID_RESPONSE } from './actionTypes';
export const initialState = {
    tenantApiResponse: null,
    editTenantApiResponse: null,
    deleteTenantApiResponse: null,
    tenantStatusUpdateApiResponse: null,
    tenantDbApiResponse: null,
    tenantList: []
};
const tenant = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_TENANT_RESPONSE:
            case UPDATE_TENANT_RESPONSE:
                draft.tenantApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_TENANT_LIST_RESPONSE:
                draft.tenantList = action.payload.tenantList;
                break;
            case GET_TENANT_LIST_RESPONSE:
                draft.tenantList = action.payload.tenantList;
                break;
            case GET_TENANT_BY_TENANT_ID_RESPONSE:
                draft.editTenantApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case UPDATE_TENANT_STATUS_BY_TENANT_ID_RESPONSE:
                draft.tenantStatusUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_TENANT_LIST_BY_STATUS_RESPONSE:
                draft.tenantList = action.payload.tenantList;
                break;
            case DELETE_TENANT_BY_TENANT_ID_RESPONSE:
                draft.deleteTenantApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GENERATE_DATABASE_BY_TENANT_ID_RESPONSE:
                draft.tenantDbApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                }
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default tenant;
