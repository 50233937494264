export const ADD_FUND_MASTER_REQUEST = 'add_fund_master_request';
export const ADD_FUND_MASTER_RESPONSE = 'add_fund_master_response';
export const GET_ALL_FUND_MASTER_LIST_REQUEST =
    'get_all_fund_master_list_request';
export const GET_ALL_FUND_MASTER_LIST_RESPONSE =
    'get_all_fund_master_list_response';
export const GET_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST =
    'get_fund_master_by_fund_master_id_request';
export const GET_FUND_MASTER_BY_FUND_MASTER_ID_RESPONSE =
    'get_fund_master_by_fund_master_id_response';
export const UPDATE_FUND_MASTER_REQUEST = 'update_fund_master_request';
export const UPDATE_FUND_MASTER_RESPONSE = 'update_fund_master_response';
export const UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_REQUEST =
    'update_fund_master_status_by_fund_master_id_request';
export const UPDATE_FUND_MASTER_STATUS_BY_FUND_MASTER_ID_RESPONSE =
    'update_fund_master_status_by_fund_master_id_response';
export const GET_FUND_MASTER_LIST_BY_STATUS_REQUEST =
    'get_fund_master_list_by_status_request';
export const GET_FUND_MASTER_LIST_BY_STATUS_RESPONSE =
    'get_fund_master_list_by_status_response';
export const DELETE_FUND_MASTER_BY_FUND_MASTER_ID_REQUEST =
    'delete_fund_master_by_fund_master_id_request';
export const DELETE_FUND_MASTER_BY_FUND_MASTER_ID_RESPONSE =
    'delete_fund_master_by_fund_master_id_response';