import {
    ADD_COMPLIANCE_UPDATE_UPLOAD_REQUEST,
    ADD_COMPLIANCE_UPDATE_UPLOAD_RESPONSE,
    GET_COMPLIANCE_UPDATE_FILE_LOGS_RESPONSE,
    GET_COMPLIANCE_UPDATE_FILE_LOGS_REQUEST,
    GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_RESPONSE,
    GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_REQUEST,
    UPDATE_ASSIGN_GCM_LEGAL_UPDATE_REQUEST,
    UPDATE_ASSIGN_GCM_LEGAL_UPDATE_RESPONSE,
    GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_REQUEST,
    GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_RESPONSE,
    GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_REQUEST,
    GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_RESPONSE,
    LEGAL_UPDATE_DOCUMENT_UPLOAD_REQUEST,
    LEGAL_UPDATE_DOCUMENT_UPLOAD_RESPONSE,
    COMPLIANCE_LEGAL_UPDATE_REQUEST,
    COMPLIANCE_LEGAL_UPDATE_RESPONSE,
    DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_REQUEST,
    DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_RESPONSE,
    GET_ALL_UPDATE_TYPE_LIST_REQUEST,
    GET_ALL_UPDATE_TYPE_LIST_RESPONSE,
    GET_ALL_SUB_UPDATE_TYPE_LIST_REQUEST,
    GET_ALL_SUB_UPDATE_TYPE_LIST_RESPONSE,
    GET_ALL_LEGAL_UPDATE_STATUS_LIST_REQUEST,
    GET_ALL_LEGAL_UPDATE_STATUS_LIST_RESPONSE,
    GET_ALL_RELEVANT_UPDATE_REQUEST,
    GET_ALL_RELEVANT_UPDATE_RESPONSE,
    CLOSE_LEGAL_UPDATE_BY_ID_REQUEST,
    CLOSE_LEGAL_UPDATE_BY_ID_RESPONSE
} from './actionTypes';

export const addCompliancesUpdateUploadRequest = (data) => {
    return {
        type: ADD_COMPLIANCE_UPDATE_UPLOAD_REQUEST,
        payload: { data },
    };
};
export const addCompliancesUpdateUploadResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: ADD_COMPLIANCE_UPDATE_UPLOAD_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getComplianceUploadedFilesLogsRequest = () => {
    return {
        type: GET_COMPLIANCE_UPDATE_FILE_LOGS_REQUEST,
    };
};
export const getComplianceUploadedFilesLogsResponse = (
    complianceUpdateFileLogsList
) => {
    return {
        type: GET_COMPLIANCE_UPDATE_FILE_LOGS_RESPONSE,
        payload: { complianceUpdateFileLogsList },
    };
};

export const getComplianceLegalUpdateSearchRequest = (data, pagination) => {
    return {
        type: GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_REQUEST,
        payload: { data, pagination },
    };
};

export const getComplianceLegalUpdateSearchResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getRelevantUpdateSearchRequest = (data) => {
    return {
        type: GET_ALL_RELEVANT_UPDATE_REQUEST,
        payload: { data },
    };
};

export const getRelevantUpdateSearchResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_ALL_RELEVANT_UPDATE_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const updateAssigngcmLegalUpdateRequest = (data, id) => {
    return {
        type: UPDATE_ASSIGN_GCM_LEGAL_UPDATE_REQUEST,
        payload: { data, id },
    };
};

export const updateAssigngcmLegalUpdateResponse = (message, responseType) => {
    return {
        type: UPDATE_ASSIGN_GCM_LEGAL_UPDATE_RESPONSE,
        payload: { message, responseType },
    };
};

export const getComplianceLegalUpdateDetailsByIdRequest = (id) => {
    return {
        type: GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_REQUEST,
        payload: { id: id },
    };
};

export const getComplianceLegalUpdateDetailsByIdResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getComplianceLegalUpdateActivityByIdRequest = (id) => {
    return {
        type: GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_REQUEST,
        payload: { id: id },
    };
};

export const getComplianceLegalUpdateActivityByIdResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const legalUpdateDocumentUploadRequest = (data) => {
    return {
        type: LEGAL_UPDATE_DOCUMENT_UPLOAD_REQUEST,
        payload: { data },
    };
};
export const legalUpdateDocumentUploadResponse = (message, responseType) => {
    return {
        type: LEGAL_UPDATE_DOCUMENT_UPLOAD_RESPONSE,
        payload: { message, responseType },
    };
};

export const complianceLegalUpdateRequest = (id, data) => {
    return {
        type: COMPLIANCE_LEGAL_UPDATE_REQUEST,
        payload: { id, data },
    };
};

export const complianceLegalUpdateResponse = (message, responseType) => {
    return {
        type: COMPLIANCE_LEGAL_UPDATE_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteLegalUpdateByLegalUpdateIdRequest = (id) => {
    return {
        type: DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_REQUEST,
        payload: { id },
    };
};
export const deleteLegalUpdateByLegalUpdateIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getAllupdateTypeListRequest = () => {
    return {
        type: GET_ALL_UPDATE_TYPE_LIST_REQUEST,
    };
};
export const getAllupdateTypeListResponse = (updateTypeList) => {
    return {
        type: GET_ALL_UPDATE_TYPE_LIST_RESPONSE,
        payload: { updateTypeList },
    };
};


export const getAllSubUpdateTypeListRequest = () => {
    return {
        type: GET_ALL_SUB_UPDATE_TYPE_LIST_REQUEST,
    };
};
export const getAllSubUpdateTypeListResponse = (subUpdateTypeList) => {
    return {
        type: GET_ALL_SUB_UPDATE_TYPE_LIST_RESPONSE,
        payload: { subUpdateTypeList },
    };
};

export const getAllLegalUpdateStatusListRequest = () => {
    return {
        type: GET_ALL_LEGAL_UPDATE_STATUS_LIST_REQUEST,
    };
};
export const getAllLegalUpdateStatusListResponse = (list) => {
    return {
        type: GET_ALL_LEGAL_UPDATE_STATUS_LIST_RESPONSE,
        payload: { list },
    };
};

export const closeLegalUpdateByIdRequest = (data, id) => {
    return {
        type: CLOSE_LEGAL_UPDATE_BY_ID_REQUEST,
        payload: { data, id },
    };
};

export const closeLegalUpdateByIdResponse = (message, responseType) => {
    return {
        type: CLOSE_LEGAL_UPDATE_BY_ID_RESPONSE,
        payload: { message, responseType }
    };
};