export const listColumns = [
    {
        label: 'Code',
        field: 'newsCode',
    },
    {
        label: 'Type of Release',
        field: 'releaseType'
    },
    {
        label: 'Country',
        field: 'country',
    },
    {
        label: 'State/Province',
        field: 'state',
    },
    {
        label: 'Law Category',
        field: 'lawCategory',
    },
    {
        label: 'Title',
        field: 'newsTitle',
    },
    {
        label: 'Publication Date',
        field: 'publicationDate',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialNewsDataState = () => {
    return {
        releaseTypeId: '',
        countryId: '',
        stateId: '',
        cityId: '',
        lawCategoryId: '',
        readTime: '',
        newsDetails: '',
        publicationDate: null,
        industryId: [],
        subIndustryId: [],
        webLink: '',
        newsTitle: '',
        updateTypeId: [],
        particularsOfUpdate: '',
        impactOfUpdate: '',
        remarks: '',
        sourceOfUpdate: '',
        effectiveDate: '',
        businessFunctionImpacted: '',
        keywords: []
    }
}

export const newsHeaders = [
    {
        label: 'Type of Release',
        field: 'releaseType',
    },
    {
        label: 'Country',
        field: 'country',
    },
    {
        label: 'State/Province',
        field: 'state',
    },
    {
        label: 'City/Location',
        field: 'state',
    },
    {
        label: 'Law Category',
        field: 'lawCategory',
    },
    {
        label: 'News Title',
        field: 'newsTitle',
    },
    {
        label: 'Publication Date',
        field: 'publicationDate',
    },
    {
        label: 'Industry',
        field: 'industry',
    },
    {
        label: 'Sub Industry',
        field: 'subIndustry',
    },
    {
        label: 'Weblink',
        field: 'webLink',
    },
    {
        label: 'Read Time',
        field: 'readTime',
    },
    {
        label: 'Details of News',
        field: 'newsDetails',
    },
    {
        label: 'Particulars of Update',
        field: 'particularsOfUpdate',
    },
    {
        label: 'Impact of Update',
        field: 'impactOfUpdate',
    },
    {
        label: 'Remarks/Notes, If any',
        field: 'remarks',
    },
    {
        label: 'Source of Update',
        field: 'sourceOfUpdate',
    },
    {
        label: 'Details of NeEffective Date of Publication',
        field: 'effectiveDate',
    },
    {
        label: 'Business Function that may be impacted?',
        field: 'businessFunctionImpacted',
    },
    {
        label: 'Update Type',
        field: 'updateType',
    }
];