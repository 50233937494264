import {
    ACTIVE,
    NATURE_APPLICABILITY_MANDATORY,
} from 'constants/commonConstants';

export const uploadColumns = [
    {
        label: 'Batch Code',
        field: 'batchId',
    },
    {
        label: 'File Name',
        field: 'fileName',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Uploaded On',
        field: 'uploadedOn',
    },
    {
        label: 'Uploaded By',
        field: 'uploadedBy',
    },
    {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
    },
];
export const complianceTableColumnsTableData = [
    {
        label: 'Code',
        field: 'complianceCode',
    },
    {
        label: 'Section / Reference',
        field: 'sectionReference',
    },
    {
        label: 'Rule / Reference',
        field: 'ruleReference',
    },
    {
        label: 'Compliance Title',
        field: 'complianceTitle',
    },
    {
        label: 'Law Code',
        field: 'lawCode',
    },
    {
        label: 'Short Name of Act/Regulation',
        field: 'lawShortName',
    },
    {
        label: 'Class',
        field: 'complianceTypeName',
    },
    {
        label: 'Classification',
        field: 'complianceCategoryName',
    },
    {
        label: 'Applicability Type',
        field: 'applicabilityCondition',
    },
    {
        label: 'Frequency',
        field: 'periodicityName',
    },
    {
        label: 'Status',
        // attributes: {
        //     className: 'active_switch',
        // },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'View',
        field: 'actions',
        sort: 'disabled',
    },
];
export const complianceAllHeaders = [
    // {
    //     label: 'Section / Reference',
    //     field: 'sectionReference',
    // },
    // {
    //     label: 'Rule / Reference',
    //     field: 'ruleReference',
    // },
    // {
    //     label: 'Relevant Law Id(s)',
    //     field: 'relevantLaws',
    // },
    // {
    //     label: 'Compliance Title',
    //     field: 'complianceTitle',
    // },
    {
        label: 'Compliance Actionable',
        field: 'complianceSpecification',
        // highlightClass: true
    },
    {
        label: 'More Details on Compliance',
        field: 'complianceDetails',
        // highlightClass: true
    },
    {
        label: 'Compliance Class',
        field: 'complianceTypeName',
        // highlightClass: true
    },
    {
        label: 'Compliance Classification',
        field: 'complianceCategoryName',
        // highlightClass: true
    },
    // {
    //     label: 'Applicability Type',
    //     field: 'applicabilityCondition',
    // },
    {
        label: 'Compliance Applicability',
        field: 'complianceApplicability',
        // highlightClass: true
    },
    {
        label: 'Frequency',
        field: 'periodicityName',
        // highlightClass: true
    },
    {
        label: 'Due Date Description',
        field: 'dueDateDescription',
        // highlightClass: true
    },
    {
        label: 'Parent Event Name',
        field: 'eventName',
        // highlightClass: true
    },
    // {
    //     label: 'Compliance Period',
    //     field: 'compliancePeriod',
    // },
    {
        label: 'Form/Format/E-filing weblink, if any',
        field: 'formNo',
        // highlightClass: true
    },
    // {
    //     label: 'Filing Required',
    //     field: 'filingRequired',
    // },
    // {
    //     label: 'Filing Type',
    //     field: 'filingTypeName',
    // },
    // {
    //     label: 'Need Compliance Evidence?',
    //     field: 'isProofRequired',
    // },
    // {
    //     label: 'Consequence Category',
    //     field: 'penaltyTypeName',
    // },
    {
        label: 'Consequence of Non-Compliance',
        field: 'penaltyDescription',
        // highlightClass: true
    },

    {
        label: 'Risk Level',
        field: 'riskRatingName',
        // highlightClass: true
    },
    // {
    //     label: 'Monetary Penalty Value (Only Numeric)',
    //     field: 'monetaryPenaltyValue',
    // },

    // {
    //     label: 'Governing Authority Website',
    //     field: 'governingAuthorityWebsite',
    // },
    // {
    //     label: 'Brief Notes (If any)',
    //     field: 'briefNotes',
    // },
    {
        label: 'Date of Enforcement',
        field: 'enforcementDate',
        // highlightClass: true
    },
    {
        label: 'Management Personnel Impacted due to Non-Compliance',
        field: 'impactManagementPersonnel',
        // highlightClass: true
    }
];
export const complianceTableColumns = complianceAllHeaders;
export const definitionTableColumns = [
    {
        label: 'Terminology',
        field: 'terminology',
    },
    {
        label: 'Section',
        field: 'section',
    },
    {
        label: 'Rule',
        field: 'rule',
    },
    {
        label: 'Definition',
        field: 'definition',
    },
    {
        label: 'Cross Referencing (If Any)',
        field: 'definitionRemarks',
    },
    {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
    }
];
export const documentTableColumns = [
    {
        label: 'Document Type',
        field: 'documentType',
    },
    {
        label: 'Document Name',
        field: 'documentName',
    },
    {
        label: 'Reference No',
        field: 'referenceNo',
    },
    {
        label: 'Date of Publication',
        field: 'releaseDate',
    },
    {
        label: 'Effective Date',
        field: 'effectiveDate',
    },
    {
        label: 'Description of the Document',
        field: 'documentDescription',
    },
    {
        label: 'Brief Notes (If Any)',
        field: 'briefNote',
    },
    {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
    }
];

export const initialStatutoryCompliancesState = () => {
    return {
        lawName: '',
        lawId: '',
        complianceCode: '',
        countryId: '',
        sectionReference: '',
        ruleReference: '',
        verbatimSectionReference: '',
        verbatimRuleReference: '',
        complianceTitle: '',
        complianceSpecification: '',
        complianceDetails: '', // More Details on Compliance
        complianceApplicability: '', //Compliance Applicability
        complianceTypeId: '', //Compliance Class
        complianceCategoryId: '', // compliance classification
        applicabilityCondition: '', //Applicability Type
        applicabilityConditionName: '',
        periodicityId: '', //frequency
        dueDateDescription: '',
        dueDateFormula: '',
        isEventBased: '',
        eventId: '',
        eventDueDateFormula: '',
        compliancePeriodId: '',
        formNo: '',
        isFilingRequired: '',
        filingTypeId: '',
        isProofRequired: '', //Need Compliance Evidence
        penaltyDescription: '', //consequence_of_non_compliance
        penaltyTypeId: [], //consequence_category
        entityTypeId: [],
        facilityTypeId: [],
        applicabilityId: [],
        subApplicabilityId: [],
        impactManagementId: [], //management_personnel_impact
        relevantLawId: [], //Relevant Law Ids
        riskRatingId: '',
        monetaryPenaltyValue: '',
        currency: '',
        governingAuthorityWebsite: '',
        briefNotes: '',
        enforcementDate: '',
        isRevision: true,
        triggeringComplianceId: '',
        isActive: '',
    };
};

export const initialLawDataState = () => {
    return {
        lawName: '',
        lawCategoryId: '',
        countryId: '',
        stateId: '',
        cityId: [],
        lawShortName: '',
        effectiveDate: '',
        applicabilityDetails: '',
        natureApplicability: NATURE_APPLICABILITY_MANDATORY,
        entityTypeId: [],
        facilityTypeId: [],
        industryId: [],
        subIndustryId: [],
        weblink: '',
        governingAuthorityDetails: '',
        exemptions: '',
        isActive: ACTIVE,
        lawSections: [],
        isRevision: true,
    };
};
export const initialSectionDataState = () => {
    return {
        id: 0,
        sectionName: '',
    };
};

export const sectionListColumns = [
    {
        label: 'Section/Rule Name',
        field: 'sectionName',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
