import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_STATE_REQUEST, DELETE_STATE_BY_STATE_ID_REQUEST, GET_ALL_STATE_LIST_REQUEST, GET_STATE_BY_STATE_ID_REQUEST, GET_STATE_LIST_BY_COUNTRY_REQUEST, GET_STATE_LIST_BY_STATUS_REQUEST, UPDATE_STATE_REQUEST, UPDATE_STATE_STATUS_BY_STATE_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addStateResponse, deleteStateByStateIdResponse, getAllStateListRequest, getAllStateListResponse, getStateByStateIdResponse, getStateListByCountryResponse, getStateListByStatusResponse, updateStateResponse, updateStateStatusByStateIdResponse } from './actions';
import { STATE, STATE_BY_COUNTRY, STATE_BY_STATUS } from 'helpers/apiConstants';
function* addState({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', STATE, data);
        if (response.status) {
            yield put(getAllStateListRequest());
            yield put(addStateResponse(response.message, SUCCESS));
        } else {
            yield put(addStateResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllStateList() {
    try {
        const response = yield call(invokeApi, 'get', STATE, '');
        if (response.status) {
            yield put(getAllStateListResponse(response.data.list));
        } else {
            yield put(getAllStateListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getStateByStateId({ payload: { stateId } }) {
    try {
        const response = yield call(invokeApi, 'get', STATE + '/' + stateId, '');
        if (response.status) {
            yield put(getStateByStateIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getStateByStateIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateState({ payload: { stateId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', STATE + '/' + stateId, data);
        if (response.status) {
            yield put(getAllStateListRequest());
            yield put(updateStateResponse(response.message, SUCCESS));
        } else {
            yield put(updateStateResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateStateStatusByStateId({
    payload: { stateId, status },
}) {
    try {
        const response = yield call(invokeApi, 'patch', STATE + '/' + stateId + '/status/' + status);
        if (response.status) {
            yield put(getAllStateListRequest());
            yield put(updateStateStatusByStateIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateStateStatusByStateIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getStateListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', STATE_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getStateListByStatusResponse(response.data.list));
        } else {
            yield put(getStateListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteStateByStateId({ payload: { stateId }, }) {
    try {
        const response = yield call(invokeApi, 'delete', STATE + '/' + stateId);
        if (response.status) {
            yield put(getAllStateListRequest());
            yield put(deleteStateByStateIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteStateByStateIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getStateListByCountry({ payload: { countryIds } }) {
    try {
        const response = yield call(invokeApi, 'post', STATE_BY_COUNTRY, { countryId: countryIds });
        if (response.status) {
            yield put(getStateListByCountryResponse(response.data.list));
        } else {
            yield put(getStateListByCountryResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchState() {
    yield takeEvery(ADD_STATE_REQUEST, addState);
    yield takeEvery(GET_ALL_STATE_LIST_REQUEST, getAllStateList);
    yield takeEvery(GET_STATE_BY_STATE_ID_REQUEST, getStateByStateId);
    yield takeEvery(UPDATE_STATE_REQUEST, updateState);
    yield takeEvery(UPDATE_STATE_STATUS_BY_STATE_ID_REQUEST, updateStateStatusByStateId);
    yield takeEvery(GET_STATE_LIST_BY_STATUS_REQUEST, getStateListByStatus);
    yield takeEvery(DELETE_STATE_BY_STATE_ID_REQUEST, deleteStateByStateId);
    yield takeEvery(GET_STATE_LIST_BY_COUNTRY_REQUEST, getStateListByCountry);
}
function* stateSaga() {
    yield all([fork(watchState)]);
}
export default stateSaga;