import { ADD_LAW_CATEGORY_REQUEST, ADD_LAW_CATEGORY_RESPONSE, DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST, DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE, GET_ALL_LAW_CATEGORY_LIST_REQUEST, GET_ALL_LAW_CATEGORY_LIST_RESPONSE, GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST, GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE, GET_LAW_CATEGORY_LIST_BY_STATUS_REQUEST, GET_LAW_CATEGORY_LIST_BY_STATUS_RESPONSE, UPDATE_LAW_CATEGORY_REQUEST, UPDATE_LAW_CATEGORY_RESPONSE, UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_REQUEST, UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_RESPONSE } from "./actionTypes";
export const addLawCategoryRequest = data => {
    return {
        type: ADD_LAW_CATEGORY_REQUEST,
        payload: { data },
    };
};
export const addLawCategoryResponse = (message, responseType) => {
    return {
        type: ADD_LAW_CATEGORY_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllLawCategoryListRequest = () => {
    return {
        type: GET_ALL_LAW_CATEGORY_LIST_REQUEST
    };
};
export const getAllLawCategoryListResponse = lawCategoryList => {
    return {
        type: GET_ALL_LAW_CATEGORY_LIST_RESPONSE,
        payload: { lawCategoryList },
    };
};
export const getLawCategoryByLawCategoryIdRequest = (lawCategoryId) => {
    return {
        type: GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST,
        payload: { lawCategoryId: lawCategoryId },
    };
};
export const getLawCategoryByLawCategoryIdResponse = (message, responseType, data) => {
    return {
        type: GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateLawCategoryRequest = (lawCategoryId, data) => {
    return {
        type: UPDATE_LAW_CATEGORY_REQUEST,
        payload: { lawCategoryId, data },
    };
};
export const updateLawCategoryResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_CATEGORY_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateLawCategoryStatusByLawCategoryIdRequest = (lawCategoryId, status) => {
    return {
        type: UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_REQUEST,
        payload: { lawCategoryId, status },
    };
};
export const updateLawCategoryStatusByLawCategoryIdResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getLawCategoryListByStatusRequest = status => {
    return {
        type: GET_LAW_CATEGORY_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getLawCategoryListByStatusResponse = lawCategoryList => {
    return {
        type: GET_LAW_CATEGORY_LIST_BY_STATUS_RESPONSE,
        payload: { lawCategoryList },
    };
};
export const deleteLawCategoryByLawCategoryIdRequest = (lawCategoryId) => {
    return {
        type: DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST,
        payload: { lawCategoryId },
    };
};
export const deleteLawCategoryByLawCategoryIdResponse = (message, responseType) => {
    return {
        type: DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_RESPONSE,
        payload: { message, responseType },
    };
};