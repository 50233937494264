export const GET_DASHBOARD_DATA_BY_ROLE_REQUEST =
    'get_dashboard_data_by_role_request';
export const GET_DASHBOARD_DATA_BY_ROLE_RESPONSE =
    'get_dashboard_data_by_role_response';

export const GET_DASHBOARD_DETAILS_DATA_REQUEST =
    'get_dashboard_details_data_request';
export const GET_DASHBOARD_DETAILS_DATA_RESPONSE =
    'get_dashboard_details_data_response';

export const GET_DASHBOARD_ADMIN_DETAILS_DATA_REQUEST =
    'get_dashboard_admin_details_data_request';
export const GET_DASHBOARD_ADMIN_DETAILS_DATA_RESPONSE =
    'get_dashboard_admin_details_data_response';

export const GET_DASHBOARD_M_DETAILS_DATA_REQUEST =
    'get_dashboard_m_details_data_request';
export const GET_DASHBOARD_M_DETAILS_DATA_RESPONSE =
    'get_dashboard_m_details_data_response';

export const GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_REQUEST =
    'get_dashboard_management_details_data_request';
export const GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_RESPONSE =
    'get_dashboard_management_details_data_response';

export const GET_OVERALL_TASK_LIST_REQUEST = 'get_overall_task_list_request';
export const GET_OVERALL_TASK_LIST_RESPONSE = 'get_overall_task_list_response';

export const GET_DASHBOARD_LAW_SEARCH_REQUEST =
    'get_dashboard_law_search_request';
export const GET_DASHBOARD_LAW_SEARCH_RESPONSE =
    'get_dashboard_law_search_response';

export const GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_REQUEST =
    'get_dashboard_law_compliances_search_request';
export const GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_RESPONSE =
    'get_dashboard_law_compliances_search_response';

export const GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_REQUEST =
    'get_dashboard_law_documents_search_request';
export const GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_RESPONSE =
    'get_dashboard_law_documents_search_response';

export const GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_REQUEST =
    'get_dashboard_department_task_by_entity_request';
export const GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_RESPONSE =
    'get_dashboard_department_task_by_entity_response';

export const GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_REQUEST =
    'get_dashboard_data_month_wise_by_role_request';
export const GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_RESPONSE =
    'get_dashboard_data_month_wise_by_role_response';


export const GET_DASHBOARD_DATA_FOR_RISK_REQUEST =
    'get_dashboard_data_for_risk_request';
export const GET_DASHBOARD_DATA_FOR_RISK_RESPONSE =
    'get_dashboard_data_for_risk_response';

export const CLEAR_OVERALL_TASK_API_RESPONSE = 'clear_overall_task_api_response'