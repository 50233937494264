import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.event || initialState;
const makeEventSelectField = field =>
    createSelector(reducerState, eventState => eventState[field]);
const selectSortedEventList = () =>
    createSelector(makeEventSelectField('eventList'), eventList =>
        eventList.map(opt => ({
            value: opt.id,
            label: opt.eventName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeEventSelectField,
    selectSortedEventList
};
