import React, { Component } from 'react';
import { FormGroup, Button, Card } from 'reactstrap';
import { CANCEL, GENERATE_DOWNLOAD } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { AUDITED_AND_CLOSED, CLOSED, DISCREPANCY_COMPLETED_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_PENDING_RESOLUTION, PENDING_FOR_AUDIT, RISK_RATING_CRITICAL, RISK_RATING_HIGH, RISK_RATING_LOW, RISK_RATING_MODERATE } from 'constants/databaseConstants';
import { PDFExport } from '@progress/kendo-react-pdf';
import { compareString } from 'helpers/generalUtils';
import HtmlReport from '../PDFUtils/HtmlReport';
import Dropzone from 'react-dropzone';
import { customReportLogoFileValidator } from 'helpers/customValidators';
import { getTodayDate } from 'helpers/dateTimeHelper';

const initialReportModalFilterDataState = () => {
    return {
        reportType: '',
        fromDate: '',
        toDate: '',
        tabular: true,
        graphical: true
    }
}
class PdfReportModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            selectedFile: [],
            errors: '',
            filterData: initialReportModalFilterDataState(),
            entityRows: [],
            countryRows: [],
            lawRows: [],
            departmentRows: [],
            pdfReportData: { list: [], typeOfReports: [] },
            departmentChart: [],
            riskRatingChart: [],
            riskAnalysisChartCount: {},
            uniqueComplianceIds: [],
            unreportedTasksCount: 0,
            uniqueTaskUserCount: 0,
            reportedTaskCount: 0,
            delayedTasksCount: 0,
            overdueTasksCount: 0,
            pdfLogo: '',
        };
        this.pdfExportComponent = React.createRef(null);
    }
    groupByRiskRating = (records, isGraphical) => {
        let riskRatingChart = [];
        this.props.riskRatingsList.map((riskRating) => {
            let riskRatingChartCheck = riskRatingChart.find((d) => d.riskRatingId === riskRating.value);
            if (!riskRatingChartCheck) {
                riskRatingChart.push({
                    riskRatingId: riskRating.value,
                    riskRatingName: riskRating.label,
                    closed: records.filter((taskRecod) => taskRecod.riskRatingId === riskRating.value && taskRecod.isClosedTask).length,
                    inprogress: records.filter((taskRecod) => taskRecod.riskRatingId === riskRating.value && taskRecod.isInprogressTask).length,
                    delayed: records.filter((taskRecod) => taskRecod.riskRatingId === riskRating.value && taskRecod.isDelayTask).length,
                    overdue: records.filter((taskRecod) => taskRecod.riskRatingId === riskRating.value && taskRecod.isOverdueTask).length,
                });
            }
        });
        return riskRatingChart;
    }
    groupByRiskAnalysis = (records) => {
        let criticalRiskTasks = records.filter((taskRecord) => taskRecord.riskRatingId == 1);
        let highRiskTasks = records.filter((taskRecord) => taskRecord.riskRatingId == 2);
        let moderateRiskTasks = records.filter((taskRecord) => taskRecord.riskRatingId == 3);
        let lowRiskTasks = records.filter((taskRecord) => taskRecord.riskRatingId == 4);
        let criticalRiskTaskCounts = {
            auditedAndClosedTaskCount: criticalRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == AUDITED_AND_CLOSED).length,
            pendingAuditorTaskCount: criticalRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT).length,
            pendingAuditObservationTaskCount: criticalRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT && taskRecord.pendingObservation == 1).length,
            discrepancyTaskCount: criticalRiskTasks.filter((taskRecord) => (taskRecord.taskStatusId == DISCREPANCY_PENDING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_ONGOING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_COMPLETED_RESOLUTION)).length,
        }
        let highRiskTaskCounts = {
            auditedAndClosedTaskCount: highRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == AUDITED_AND_CLOSED).length,
            pendingAuditorTaskCount: highRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT).length,
            pendingAuditObservationTaskCount: highRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT && taskRecord.pendingObservation == 1).length,
            discrepancyTaskCount: highRiskTasks.filter((taskRecord) => (taskRecord.taskStatusId == DISCREPANCY_PENDING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_ONGOING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_COMPLETED_RESOLUTION)).length,
        }
        let moderateRiskTaskCounts = {
            auditedAndClosedTaskCount: moderateRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == AUDITED_AND_CLOSED).length,
            pendingAuditorTaskCount: moderateRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT).length,
            pendingAuditObservationTaskCount: moderateRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT && taskRecord.pendingObservation == 1).length,
            discrepancyTaskCount: moderateRiskTasks.filter((taskRecord) => (taskRecord.taskStatusId == DISCREPANCY_PENDING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_ONGOING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_COMPLETED_RESOLUTION)).length,
        }
        let lowRiskTaskCounts = {
            auditedAndClosedTaskCount: lowRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == AUDITED_AND_CLOSED).length,
            pendingAuditorTaskCount: lowRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT).length,
            pendingAuditObservationTaskCount: lowRiskTasks.filter((taskRecord) => taskRecord.taskStatusId == PENDING_FOR_AUDIT && taskRecord.pendingObservation == 1).length,
            discrepancyTaskCount: lowRiskTasks.filter((taskRecord) => (taskRecord.taskStatusId == DISCREPANCY_PENDING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_ONGOING_RESOLUTION || taskRecord.taskStatusId == DISCREPANCY_COMPLETED_RESOLUTION)).length,
        }
        return {
            criticalRiskTaskCounts,
            highRiskTaskCounts,
            moderateRiskTaskCounts,
            lowRiskTaskCounts
        };
    }
    groupByEntity = (records) => {
        return Object.values(records.reduce(function (res, obj) {
            if (!res[obj.complianceAllocationEntityId]) {
                res[obj.complianceAllocationEntityId] = {
                    entityId: obj.complianceAllocationEntityId,
                    entityName: obj.entityName,
                    cityWiseCount: []
                }
            }
            if (!res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId]) {
                res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId] = {
                    entityId: obj.complianceAllocationEntityId,
                    entityName: obj.entityName,
                    cityId: obj.cityId,
                    cityName: obj.city,
                    userWiseData: [],
                    allocated: [],
                    reported: [],
                    pending: [],
                    delayed: [],
                    overdue: [],
                };
            }
            if (obj.taskUserIds != '') {
                let taskUserIds = obj.taskUserIds.split(',').map(Number);
                for (let index = 0; index < taskUserIds.length; index++) {
                    let userId = parseInt(taskUserIds[index]);

                    if (!res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId]) {
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId] = {
                            userName: '',
                            allocated: [],
                            reported: [],
                            pending: [],
                            delayed: [],
                            completed: [],
                            lastLoggedIn: '',
                            pendingAudit: [],
                            userRoles: [],
                            userDepartments: []
                        }
                    }
                    let collaboratorUserIds = (obj.taskCollaboratorUserId != '') ? obj.taskCollaboratorUserId?.split(',').map(Number) : [];
                    let rowDepartmentIds = obj.departmentIds.split(',');
                    let rowDepartmentNames = obj.department.split(', ');
                    let userDepartments = [...new Set([...res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userDepartments, ...rowDepartmentNames])];
                    let add = false;
                    if (obj.taskSubmitterUserId != '' && parseInt(obj.taskSubmitterUserId) === userId) {
                        add = true;
                        if (res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName == '') {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName = obj.submitter;
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].lastLoggedIn = obj.taskSubmitterUserLastActiveAt;
                        }
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userRoles.push('Submitter');
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userDepartments = userDepartments;
                    }
                    if (obj.taskFirstApproverUserId != '' && parseInt(obj.taskFirstApproverUserId) === userId) {
                        add = true;
                        if (res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName == '') {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName = obj.approver1;
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].lastLoggedIn = obj.taskFirstApproverUserLastActiveAt;
                        }
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userRoles.push('Reviewer');
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userDepartments = userDepartments;
                    }
                    if (obj.taskSecondApproverUserId != '' && parseInt(obj.taskSecondApproverUserId) === userId) {
                        add = true;
                        if (res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName == '') {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName = obj.approver2;
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].lastLoggedIn = obj.taskSecondApproverUserLastActiveAt;
                        }
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userRoles.push('Approver');
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userDepartments = userDepartments;
                    }
                    if (obj.taskAuditorId != '' && parseInt(obj.taskAuditorId) === userId) {
                        add = true;
                        if (res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName == '') {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userName = obj.auditor;
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].lastLoggedIn = obj.taskAuditorLastActiveAt;
                        }
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userRoles.push('Auditor');
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userDepartments = userDepartments;
                    }
                    // if (collaboratorUserIds.includes(userId)) {
                    //     add = true;
                    //     res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].userRoles.push('Collaborator')
                    // }
                    if (add) {
                        let taskInfo = { taskId: obj.taskId };
                        res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].allocated.push(taskInfo);
                        if (obj.isReportedTask) {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].reported.push(taskInfo);
                        }
                        if (obj.isPendingTask) {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].pending.push(taskInfo);
                        }
                        if (obj.isDelayTask) {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].delayed.push(taskInfo);
                        }
                        if (obj.isClosedTask) {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].completed.push(taskInfo);
                        }
                        if (obj.isPendingAuditTask) {
                            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].userWiseData[userId].pendingAudit.push(taskInfo);
                        }
                    }
                }
            }

            res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].allocated.push(obj);
            if (obj.isReportedTask) {
                res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].reported.push(obj);
            }
            if (obj.isPendingTask) {
                res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].pending.push(obj);
            }
            if (obj.isDelayTask) {
                res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].delayed.push(obj);
            }
            if (obj.isOverdueTask) {
                res[obj.complianceAllocationEntityId].cityWiseCount[obj.cityId].overdue.push(obj);
            }
            return res;
        }, {}));
    }
    groupByCountry = (records) => {
        let countryData = Object.values(records.reduce(function (res, obj) {
            if (!res[obj.countryId]) {
                res[obj.countryId] = {
                    countryId: obj.countryId,
                    countryName: obj.country,
                    completed: [],
                    allocated: [],
                    reported: [],
                    pending: [],
                    delayed: [],
                    overdue: [],
                    statewiseCount: []
                };
            }
            if (!res[obj.countryId].statewiseCount[obj.stateId]) {
                res[obj.countryId].statewiseCount[obj.stateId] = {
                    stateId: obj.stateId,
                    stateName: obj.state,
                    completed: records.filter((rec) => rec.stateId === obj.stateId && rec.isClosedTask).length || 0,
                    allocated: records.filter((rec) => rec.stateId === obj.stateId).length || 0,
                    reported: records.filter((rec) => rec.stateId === obj.stateId && rec.isReportedTask).length || 0,
                    pending: records.filter((rec) => rec.stateId === obj.stateId && rec.isPendingTask).length || 0,
                    delayed: records.filter((rec) => rec.stateId === obj.stateId && rec.isDelayTask).length || 0,
                    overdue: records.filter((rec) => rec.stateId === obj.stateId && rec.isOverdueTask).length || 0
                }
            }
            res[obj.countryId].allocated.push(obj);
            if (obj.isReportedTask) {
                res[obj.countryId].reported.push(obj);
            }
            if (obj.isPendingTask) {
                res[obj.countryId].pending.push(obj);
            }
            if (obj.isDelayTask) {
                res[obj.countryId].delayed.push(obj);
            }
            if (obj.isOverdueTask) {
                res[obj.countryId].overdue.push(obj);
            }
            if ([CLOSED, AUDITED_AND_CLOSED].includes(obj.taskStatusId)) {
                res[obj.countryId].completed.push(obj);
            }
            return res;
        }, {}));
        return countryData?.sort((a, b) => compareString(a.countryName, b.countryName));
    }
    groupByLaw = (records) => {
        let stateData = this.props.masterData?.masterStateData;
        return Object.values(records.reduce(function (res, obj) {
            if (!res[obj.countryId]) {
                res[obj.countryId] = {
                    countryId: obj.countryId,
                    countryName: obj.country,
                    lawWiseData: []
                }
            }
            if (!res[obj.countryId].lawWiseData[obj.lawId]) {
                let stateName = '';
                if (obj.lawStateId) {
                    stateName = stateData.find((s) => s.id === obj.lawStateId)?.stateName;
                }
                res[obj.countryId].lawWiseData[obj.lawId] = {
                    lawId: obj.lawId,
                    lawShortName: obj.lawShortName,
                    countryId: obj.countryId,
                    countryName: obj.country,
                    stateId: obj.lawStateId,
                    stateName: stateName,
                    allocated: [],
                    reported: [],
                    pending: [],
                    delayed: [],
                    overdue: [],
                };
            }
            res[obj.countryId].lawWiseData[obj.lawId].allocated.push(obj);
            if (obj.isReportedTask) {
                res[obj.countryId].lawWiseData[obj.lawId].reported.push(obj);
            }
            if (obj.isPendingTask) {
                res[obj.countryId].lawWiseData[obj.lawId].pending.push(obj);
            }
            if (obj.isDelayTask) {
                res[obj.countryId].lawWiseData[obj.lawId].delayed.push(obj);
            }
            if (obj.isOverdueTask) {
                res[obj.countryId].lawWiseData[obj.lawId].overdue.push(obj);
            }
            return res;
        }, {}));
    }
    groupByDepartment = (records, isGraphical) => {
        let departmentChart = [];
        let dataPerEntityDepartment = this.props.masterData.entityDepartmentsWithHod.reduce(function (res, obj) {
            let dKeyCheck = obj.entityId + 'dep' + obj.departmentId;
            if (!res[dKeyCheck]) {
                let departmentTasks = records.filter((taskRecod) => taskRecod.entityId == obj.entityId && taskRecod.departmentIds.includes(obj.departmentId)) || [];
                let originalData = Object.groupBy(departmentTasks, (({ cityId }) => cityId));
                let cityWiseCount = Object.entries(originalData).map(([cityId, cityTasks]) => {
                    let cityName = cityTasks[0].city;
                    let cityIdValue = cityId;
                    let allocated = cityTasks.length;
                    let reported = cityTasks.filter(task => task.isReportedTask).length;
                    let pending = cityTasks.filter(task => task.isPendingTask).length;
                    let auditAllocated = cityTasks.filter(task => task.auditor !== '').length;
                    let audited = cityTasks.filter(task => task.isAuditedTask).length;
                    let pendingObservation = cityTasks.filter(task => task.isPendingObservationTask).length;
                    let pendingAudit = cityTasks.filter(task => task.isPendingAuditTask).length;
                    let discrepancy = cityTasks.filter(task => task.isDiscrepancyTask).length;
                    let discrepancyResolution = cityTasks.filter(task => task.isDiscrepancyResolutionTask).length;
                    let discrepancyMonetary = cityTasks.filter(task => task.isDiscrepancyMonetaryTask).length;
                    let discrepancyNonMonetary = cityTasks.filter(task => task.isDiscrepancyNonMonetaryTask).length;

                    let delayed = cityTasks.filter((task) => task.isDelayTask).length;

                    let delayedCritical = cityTasks.filter(
                        (task) => task.isDelayTask && task.riskRatingId === RISK_RATING_CRITICAL
                    ).length;

                    let delayedHigh = cityTasks.filter(
                        (task) => task.isDelayTask && task.riskRatingId === RISK_RATING_HIGH
                    ).length;

                    let delayedModerate = cityTasks.filter(
                        (task) => task.isDelayTask && task.riskRatingId === RISK_RATING_MODERATE
                    ).length;

                    let delayedLow = cityTasks.filter(
                        (task) => task.isDelayTask && task.riskRatingId === RISK_RATING_LOW
                    ).length;

                    let overdue = cityTasks.filter(
                        (task) => task.isOverdueTask
                    ).length;
                    let overdueCritical = cityTasks.filter(
                        (task) => task.isOverdueTask && task.riskRatingId === RISK_RATING_CRITICAL
                    ).length;

                    let overdueHigh = cityTasks.filter(
                        (task) => task.isOverdueTask && task.riskRatingId === RISK_RATING_HIGH
                    ).length;

                    let overdueModerate = cityTasks.filter(
                        (task) => task.isOverdueTask && task.riskRatingId === RISK_RATING_MODERATE
                    ).length;

                    let overdueLow = cityTasks.filter(
                        (task) => task.isOverdueTask && task.riskRatingId === RISK_RATING_LOW
                    ).length;

                    return {
                        cityId: cityIdValue,
                        cityName,
                        allocated,
                        reported,
                        pending,
                        auditAllocated,
                        audited,
                        pendingObservation,
                        pendingAudit,
                        discrepancy,
                        discrepancyResolution,
                        discrepancyMonetary,
                        discrepancyNonMonetary,
                        delayed,
                        overdue,
                        delayedCritical,
                        delayedHigh,
                        delayedModerate,
                        delayedLow,
                        overdueCritical,
                        overdueHigh,
                        overdueModerate,
                        overdueLow
                    };
                });
                res[dKeyCheck] = {
                    entityId: obj.entityId,
                    entityName: obj.entityName,
                    departmentId: obj.departmentId,
                    departmentName: obj.departmentName,
                    hods: [],
                    cityWiseCount: cityWiseCount || [],
                }
            }
            if (isGraphical) {
                let departmentCheckChart = departmentChart.find((d) => d.departmentId === obj.departmentId);
                if (!departmentCheckChart) {
                    let chartDepartmentTasks = records.filter((taskRecod) => taskRecod.departmentIds.includes(obj.departmentId)) || [];
                    departmentChart.push({
                        departmentId: obj.departmentId,
                        departmentName: obj.departmentName,
                        pending: chartDepartmentTasks.filter((taskRecod) => taskRecod.isPendingTask).length,
                        reported: chartDepartmentTasks.filter((taskRecod) => taskRecod.isReportedTask).length,
                        delayed: chartDepartmentTasks.filter((taskRecod) => taskRecod.isDelayTask).length,
                        overdue: chartDepartmentTasks.filter((taskRecod) => taskRecod.isOverdueTask).length,
                        closed: chartDepartmentTasks.filter((taskRecod) => taskRecod.isClosedTask).length,
                        critical: chartDepartmentTasks.filter((taskRecod) => taskRecod.riskRatingId === RISK_RATING_CRITICAL).length,
                        high: chartDepartmentTasks.filter((taskRecod) => taskRecod.riskRatingId === RISK_RATING_HIGH).length,
                        moderate: chartDepartmentTasks.filter((taskRecod) => taskRecod.riskRatingId === RISK_RATING_MODERATE).length,
                        low: chartDepartmentTasks.filter((taskRecod) => taskRecod.riskRatingId === RISK_RATING_LOW).length,
                    });
                }
            }
            res[dKeyCheck].hods.push({
                userId: obj.userIds,
                userName: obj.userNames,
                userFullName: obj.userFullNames
            });
            return res;
        }, []) || [];
        return {
            departmentRows: Array.from(Object.values(dataPerEntityDepartment))?.sort((a, b) => compareString(a.departmentName, b.departmentName)),
            departmentChart: departmentChart
        };
    }

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            if (!values.tabular && !values.graphical) {
                this.setState({
                    isFormError: true
                })
                return false;
            }
            let entityRows = this.groupByEntity(this.state.pdfReportData.list);
            let uniqueUserIds = [];
            this.state.pdfReportData.list.forEach(task => {
                if (task.taskUserIds) uniqueUserIds = [...new Set([...uniqueUserIds, ...task.taskUserIds.split(",").map(Number)])]
            });
            if (uniqueUserIds.length > 0) {
                this.setState({ uniqueTaskUserCount: uniqueUserIds.length })
            }
            let risKRatingChart = this.groupByRiskRating(this.state.pdfReportData.list, values.graphical);
            let riskAnalysisChartCount = this.groupByRiskAnalysis(this.state.pdfReportData.list);
            let uniqueComplianceIdsTemp = [...new Set(this.state.pdfReportData.list?.map(item => item.complianceAllocationLawComplianceId))];
            let unreportedTasksCount = this.state.pdfReportData.list?.filter(item => item.isPendingTask === true).length;
            let reportedTaskCount = this.state.pdfReportData.list?.filter(item => item.isReportedTask === true).length;
            let delayedTasksCount = this.state.pdfReportData.list?.filter(item => item.isDelayTask === true);
            let overdueTasksCount = this.state.pdfReportData.list?.filter(item => item.isOverdueTask === true);
            this.setState({
                uniqueComplianceIds: uniqueComplianceIdsTemp,
                unreportedTasksCount: unreportedTasksCount,
                reportedTaskCount: reportedTaskCount,
                overdueTasksCount: overdueTasksCount.length,
                delayedTasksCount: delayedTasksCount.length
            })
            let countryRows = this.groupByCountry(this.state.pdfReportData.list);
            let lawRows = this.groupByLaw(this.state.pdfReportData.list);
            let { departmentRows, departmentChart } = this.groupByDepartment(this.state.pdfReportData.list, values.graphical);
            this.setState({
                isFormError: false,
                filterData: values,
                entityRows: entityRows,
                countryRows: countryRows,
                lawRows: lawRows,
                departmentRows: departmentRows,
                departmentChart: departmentChart,
                riskRatingChart: risKRatingChart,
                riskAnalysisChartCount: riskAnalysisChartCount
            }, () => {
                if (this.pdfExportComponent.current) {
                    this.pdfExportComponent.current.save();
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                filterData: initialReportModalFilterDataState(),
                entityRows: []
            },
            () => {
                this.props.onModalDismiss();
            }
        );
        this.validator.hideMessages();
    };

    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customReportLogoFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.report_logo_document_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({ errors: __t('form_labels.reports.logo_file') + ' ' + __t('validations.custom.report_logo_document_file') });
        } else {
            this.setState({ errors: '' })
        }
        let formData = new FormData();
        files.map((file) => {
            formData.append('file', file);
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size)
            });
            this.setState({ pdfLogo: URL.createObjectURL(file) });
        });
        formData.append('type', 'report');

        this.setState({ selectedFile: files }, () => {
        });
    }

    /**
    * Formats the size
    */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.reportType && nextprops.reportType !== this.props.reportType) {
            this.setState({
                pdfReportData: nextprops.pdfReportData,
                filterData: {
                    ...this.state.filterData,
                    reportType: nextprops.reportType
                }
            })
        }
    }
    pageTemplate = (page) => {
        return (page.pageNum == page.totalPages ? (
            <div
                style={{
                    position: 'absolute',
                    bottom: '1rem',
                    left: '1rem',
                    right: '1rem',
                    width: 'calc(100% - 2rem)',
                }}
            >
                <table style={{ width: '100%', margin: '0' }}>
                    <tfoot>
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'center', padding: '0 0', margin: '1rem 0 0.3rem' }}>
                                <p style={{ color: '#2A3142', backgroundColor: 'rgba(79, 45, 127, 0.05)', width: '100%', fontSize: '0.85rem', margin: '0.5rem 0 0.3rem', padding: '0.4rem 0.6rem', borderRadius: '0.3rem' }}>This is a system generated report and does not require any signature/authentication</p>
                                <p style={{ color: '#2A3142', fontSize: '0.8rem', paddingTop: '0.5rem', margin: '0', lineHeight: '1' }}>Powered by <strong style={{ fontWeight: '700', color: '#4F2D7F', fontSize: '0.9rem', textDecoration: 'underline', display: 'block' }}>Grant Thornton Bharat</strong></p>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        ) : (
            <div style={{ position: 'absolute', bottom: '20px', right: '20px', fontSize: '8px', color: 'gray' }}>
                Page {page.pageNum} of {page.totalPages}
            </div>
        ));
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize="modal-md pdf-export-modal"
                    modalName={__t('page_headers.pdf')}
                    isModalOpen={this.props.isModalOpen}
                    width="100%"
                    onModalDismiss={() => {
                        this.resetForm();
                    }}
                >
                    <Formik
                        initialValues={this.state.filterData}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {/* {JSON.stringify(values)} */}
                                <div>
                                    <div className='pdf-export-box'>
                                        <FormGroup>
                                            <Field
                                                type="checkbox"
                                                id="tabular"
                                                name="tabular"
                                                className="form-check-input"
                                                onChange={(
                                                    e
                                                ) => {
                                                    const { checked } = e.target;
                                                    if (checked) {
                                                        setFieldValue('tabular', checked);
                                                    } else {
                                                        setFieldValue('tabular', false);
                                                    }
                                                }}
                                                checked={values.tabular}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="tabular"
                                                title='Pdf in Tabular'
                                            >
                                                <span>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22 8.27V4.23C22 2.64 21.36 2 19.77 2H15.73C14.14 2 13.5 2.64 13.5 4.23V8.27C13.5 9.86 14.14 10.5 15.73 10.5H19.77C21.36 10.5 22 9.86 22 8.27Z" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                        <path d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                        <path d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                        <path d="M15 15.5H21" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" />
                                                        <path d="M15 19.5H21" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" />
                                                    </svg>
                                                </span>
                                                Tabular
                                            </label>
                                        </FormGroup>

                                        <FormGroup>
                                            <Field
                                                type="checkbox"
                                                id="graphical"
                                                name="graphical"
                                                className="form-check-input"
                                                onChange={(
                                                    e
                                                ) => {
                                                    const { checked } = e.target;
                                                    if (checked) {
                                                        setFieldValue('graphical', checked);
                                                    } else {
                                                        setFieldValue('graphical', false);
                                                    }
                                                }}
                                                checked={values.graphical}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="graphical"
                                                title='Pdf in Graphical'
                                            >
                                                <span>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                        <path d="M15.5 18.5C16.6 18.5 17.5 17.6 17.5 16.5V7.5C17.5 6.4 16.6 5.5 15.5 5.5C14.4 5.5 13.5 6.4 13.5 7.5V16.5C13.5 17.6 14.39 18.5 15.5 18.5Z" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                        <path d="M8.5 18.5C9.6 18.5 10.5 17.6 10.5 16.5V13C10.5 11.9 9.6 11 8.5 11C7.4 11 6.5 11.9 6.5 13V16.5C6.5 17.6 7.39 18.5 8.5 18.5Z" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </span>
                                                Graphical
                                            </label>
                                        </FormGroup>
                                    </div>
                                    {this.state.isFormError && (
                                        <span className="text-danger">Please select at least one</span>
                                    )}
                                    {this.props.reportType === 'custom' && (
                                        <FormGroup className='col-lg-12'>
                                            <Dropzone multiple={false} onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div className="dz-message needsclick" {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <h3>Drop logo file here or click to upload.</h3>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            {this.state.errors != '' && (
                                                <div className="srv-validation-message">{this.state.errors}</div>
                                            )}
                                            {/* {this.validator.message(
                                                'Logo ' + __t(
                                                    'validations.common.required'
                                                ),
                                                this.state.pdfLogo,
                                                'required',
                                                {
                                                    messages: {
                                                        required: 'Logo file ' + __t(
                                                            'validations.common.required'
                                                        ),
                                                    },
                                                }
                                            )} */}
                                            <div className="dropzone-previews" id="file-previews">
                                                {this.state.selectedFile.map((f, i) => {
                                                    return <Card className="shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                                                        <div className="dropzone-preview-inner">
                                                            <div>
                                                                <Link to="#" className="upload-document-title font-weight-bold">{f.name}</Link>
                                                                <p className="mb-0"><strong>{f.formattedSize}</strong></p>

                                                            </div>
                                                            <div>
                                                                <Button
                                                                    className="btn btn-danger btn-sm"
                                                                    title="Delete file"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            errors: '',
                                                                            selectedFile: []
                                                                        })
                                                                    }}
                                                                >
                                                                    <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                    </svg>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                })}
                                            </div>
                                        </FormGroup>
                                    )}
                                    <div className="d-block text-center pt-2 border-top">
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >
                                            {CANCEL}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="ml-2"
                                        >
                                            {GENERATE_DOWNLOAD}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div
                        style={{
                            position: "absolute",
                            left: "-770rem",
                            top: 0,
                        }}
                    >
                        <PDFExport paperSize="A4" margin={{ top: 10, left: 0, right: 0, bottom: 10 }} fileName={`${this.props.reportType === 'custom' ? 'custom-report-' : 'pro-report-'}${getTodayDate()}`} forcePageBreak=".page-break" keepTogether=".page-break-avoid" ref={this.pdfExportComponent}
                            pageTemplate={this.pageTemplate}

                        >
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <HtmlReport
                                    isTabular={this.state.filterData.tabular}
                                    isGraphical={this.state.filterData.graphical}
                                    periodFromDate={this.state.filterData.fromDate}
                                    periodToDate={this.state.filterData.toDate}
                                    reportType={this.state.filterData.reportType}
                                    filters={this.state.pdfReportData.filters}
                                    riskRatingsList={this.props.riskRatingsList}
                                    graphRiskRatingWiseData={this.state.riskRatingChart}
                                    riskAnalysisWiseData={this.state.riskAnalysisChartCount}
                                    graphDepartmentWiseData={this.state.departmentChart}
                                    entityWiseData={this.state.entityRows}
                                    uniqueTaskUserCount={this.state.uniqueTaskUserCount}
                                    countryWiseData={this.state.countryRows}
                                    lawWiseData={this.state.lawRows}
                                    pdfLogo={this.state.pdfLogo}
                                    //find compliacnce according to law return count only
                                    departmentWiseData={this.state.departmentRows}
                                    performersData={this.props.masterData?.performersData}
                                    entityAdmins={this.props.masterData?.entityAdmins}
                                    companyGroup={this.props.masterData?.companyGroup}
                                    uniqueComplianceIds={this.state.uniqueComplianceIds}
                                    unreportedTasksCount={this.state.unreportedTasksCount}
                                    reportedTaskCount={this.state.reportedTaskCount}
                                    delayedTasksCount={this.state.delayedTasksCount}
                                    overdueTasksCount={this.state.overdueTasksCount}
                                    userWiseData={this.state.complianceStatusOfUsers}
                                />
                            </div>
                        </PDFExport>
                    </div>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({

});
const withConnect = connect(mapStatetoProps, {

});
export default compose(
    withConnect,
    withRouter
)(PdfReportModal);
