import produce from 'immer';
import {
    ADD_NEW_TICKET_MESSAGE_RESPONSE,
    ADD_NEW_TICKET_RESPONSE,
    EDIT_TICKET_RESPONSE,
    GET_TICKET_COUNT_RESPONSE,
    GET_TICKET_DETAILS_BY_ID_RESPONSE,
    GET_TICKET_MESSAGE_LIST_RESPONSE,
    LIST_TICKET_ASSIGNEE_USERS_RESPONSE,
    LIST_TICKET_CATEGORY_RESPONSE,
    LIST_TICKET_RESPONSE
} from './actionTypes';

export const initialState = {
    ticketApiResponse: null,
    ticketList: [],
    ticketCategoryList: [],
    ticketAssigneeUserList: [],
    ticketDetailsApiResponse: null,
    addNewTicketMessageApiResponse: null,
    ticketMessageList: [],
    ticketCountApiResponse: null
};
const ticket = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_NEW_TICKET_RESPONSE:
                draft.ticketApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType
                };
                break;

            case EDIT_TICKET_RESPONSE:
                draft.ticketApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType
                };
                break;

            case LIST_TICKET_CATEGORY_RESPONSE:
                draft.ticketCategoryList = action.payload.ticketCategoryList;
                break;

            case LIST_TICKET_ASSIGNEE_USERS_RESPONSE:
                draft.ticketAssigneeUserList = action.payload.ticketAssigneeUserList;
                break;

            case LIST_TICKET_RESPONSE:
                draft.ticketApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_TICKET_DETAILS_BY_ID_RESPONSE:
                draft.ticketDetailsApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case ADD_NEW_TICKET_MESSAGE_RESPONSE:
                draft.addNewTicketMessageApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType
                }
                break;
            case GET_TICKET_MESSAGE_LIST_RESPONSE:
                draft.ticketMessageList = action.payload.ticketMessageList;
                break;
                
            case GET_TICKET_COUNT_RESPONSE:
                draft.ticketCountApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType
                }
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default ticket;