import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_LAW_CATEGORY_REQUEST, DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST, GET_ALL_LAW_CATEGORY_LIST_REQUEST, GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST, GET_LAW_CATEGORY_LIST_BY_STATUS_REQUEST, UPDATE_LAW_CATEGORY_REQUEST, UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addLawCategoryResponse, deleteLawCategoryByLawCategoryIdResponse, getAllLawCategoryListRequest, getAllLawCategoryListResponse, getLawCategoryByLawCategoryIdResponse, getLawCategoryListByStatusResponse, updateLawCategoryResponse, updateLawCategoryStatusByLawCategoryIdResponse } from './actions';
import { LAW_CATEGORY, LAW_CATEGORY_BY_STATUS } from 'helpers/apiConstants';
function* addLawCategory({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW_CATEGORY, data);
        if (response.status) {
            yield put(getAllLawCategoryListRequest());
            yield put(addLawCategoryResponse(response.message, SUCCESS));
        } else {
            yield put(addLawCategoryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllLawCategoryList() {
    try {
        const response = yield call(invokeApi, 'get', LAW_CATEGORY, '');
        if (response.status) {
            yield put(getAllLawCategoryListResponse(response.data.list));
        } else {
            yield put(getAllLawCategoryListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawCategoryByLawCategoryId({ payload: { lawCategoryId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            LAW_CATEGORY + '/' + lawCategoryId,
            ''
        );
        if (response.status) {
            yield put(
                getLawCategoryByLawCategoryIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getLawCategoryByLawCategoryIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLawCategory({ payload: { lawCategoryId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', LAW_CATEGORY + '/' + lawCategoryId, data);
        if (response.status) {
            yield put(getAllLawCategoryListRequest());
            yield put(updateLawCategoryResponse(response.message, SUCCESS));
        } else {
            yield put(updateLawCategoryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLawCategoryStatusByLawCategoryId({
    payload: { lawCategoryId, status },
}) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            LAW_CATEGORY + '/' + lawCategoryId + '/status/' + status
        );
        if (response.status) {
            yield put(getAllLawCategoryListRequest());
            yield put(
                updateLawCategoryStatusByLawCategoryIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateLawCategoryStatusByLawCategoryIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawCategoryListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', LAW_CATEGORY_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getLawCategoryListByStatusResponse(response.data.list));
        } else {
            yield put(getLawCategoryListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteLawCategoryByLawCategoryId({
    payload: { lawCategoryId },
}) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            LAW_CATEGORY + '/' + lawCategoryId
        );
        if (response.status) {
            yield put(getAllLawCategoryListRequest());
            yield put(
                deleteLawCategoryByLawCategoryIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteLawCategoryByLawCategoryIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchLawCategory() {
    yield takeEvery(ADD_LAW_CATEGORY_REQUEST, addLawCategory);
    yield takeEvery(GET_ALL_LAW_CATEGORY_LIST_REQUEST, getAllLawCategoryList);
    yield takeEvery(GET_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST, getLawCategoryByLawCategoryId);
    yield takeEvery(UPDATE_LAW_CATEGORY_REQUEST, updateLawCategory);
    yield takeEvery(UPDATE_LAW_CATEGORY_STATUS_BY_LAW_CATEGORY_ID_REQUEST, updateLawCategoryStatusByLawCategoryId);
    yield takeEvery(GET_LAW_CATEGORY_LIST_BY_STATUS_REQUEST, getLawCategoryListByStatus);
    yield takeEvery(DELETE_LAW_CATEGORY_BY_LAW_CATEGORY_ID_REQUEST, deleteLawCategoryByLawCategoryId);
}
function* lawCategorySaga() {
    yield all([fork(watchLawCategory)]);
}
export default lawCategorySaga;