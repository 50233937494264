import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { getEntityTeamsByEntityIdRequest } from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedConsultantTeamUserList, selectSortedEntityTeamAndConsultantTeamUserList } from 'store/Entity/selector';
import { CANCEL } from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import { Button, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import SimpleReactValidator from 'simple-react-validator';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

class SendAuditModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            auditDetailsData: this.props.auditDetailsData,
        };
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.taskDetails !== this.props.taskDetails) {
            this.props.getEntityTeamsByEntityIdRequest(nextprops.taskDetails?.entityId);
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-md'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.props.taskDetails}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>

                                        <Row className="d-flex mr-0">
                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    component={Select}
                                                    name="auditorId"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedConsultantTeamUserDropdown
                                                        // .filter(opt => (opt.value !== values.submitterId && opt.value !== values.firstApproverUserId))
                                                    }
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'auditorId',
                                                            opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.auditorId
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.auditor'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.reporting.auditor'
                                                    ),
                                                    values.auditorId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.reporting.auditor_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col ml-1 mb-0">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="allowAuditorFileAccess"
                                                    name="allowAuditorFileAccess"
                                                    className='is_user_checkbox'
                                                    onChange={(e) => {
                                                        const { checked } = e.target;
                                                        setFieldValue('allowAuditorFileAccess', checked);
                                                    }}
                                                    checked={values.allowAuditorFileAccess}
                                                    label={__t('form_labels.reporting.allow_auditor_file_access')} />
                                                <Label className="form-label m-0">
                                                    &nbsp;&nbsp;
                                                </Label>
                                            </FormGroup>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="mr-2"
                                                    >
                                                        {__t(
                                                            'form_labels.reporting.send_for_audit'
                                                        )}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={
                                                            this.props
                                                                .onModalDismiss
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    // sortedEntityTeamAndConsultantTeamUserDropdown: selectSortedEntityTeamAndConsultantTeamUserList(),
    sortedConsultantTeamUserDropdown: selectSortedConsultantTeamUserList()
});
const withConnect = connect(mapStatetoProps, {
    getEntityTeamsByEntityIdRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(SendAuditModal);
