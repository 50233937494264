import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_APPLICABILITY_REQUEST, DELETE_APPLICABILITY_BY_APPLICABILITY_ID_REQUEST, GET_ALL_APPLICABILITY_LIST_REQUEST, GET_APPLICABILITY_LIST_BY_STATUS_REQUEST, GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_REQUEST, UPDATE_APPLICABILITY_REQUEST, UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_REQUEST, } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addApplicabilityResponse, deleteApplicabilityByApplicabilityIdResponse, getAllApplicabilityListRequest, getAllApplicabilityListResponse, getApplicabilityListByStatusResponse, getSubApplicabilityListByApplicabilityResponse, updateApplicabilityResponse, updateApplicabilityStatusByApplicabilityIdResponse } from './actions';
import { APPLICABILITY, APPLICABILITY_BY_STATUS, SUB_APPLICABILITY_LIST_BY_APPLICABILITY } from 'helpers/apiConstants';
function* addApplicability({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', APPLICABILITY, data);
        if (response.status) {
            yield put(getAllApplicabilityListRequest());
            yield put(addApplicabilityResponse(response.message, SUCCESS));
        } else {
            yield put(addApplicabilityResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllApplicabilityList() {
    try {
        const response = yield call(invokeApi, 'get', APPLICABILITY, '');
        if (response.status) {
            yield put(getAllApplicabilityListResponse(response.data.list));
        } else {
            yield put(getAllApplicabilityListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateApplicability({ payload: { applicabilityId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', APPLICABILITY + '/' + applicabilityId, data);
        if (response.status) {
            yield put(getAllApplicabilityListRequest());
            yield put(updateApplicabilityResponse(response.message, SUCCESS));
        } else {
            yield put(updateApplicabilityResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateApplicabilityStatusByApplicabilityId({ payload: { applicabilityId, status } }) {
    try {
        const response = yield call(invokeApi, 'patch', APPLICABILITY + '/' + applicabilityId + '/status/' + status);
        if (response.status) {
            yield put(getAllApplicabilityListRequest());
            yield put(updateApplicabilityStatusByApplicabilityIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateApplicabilityStatusByApplicabilityIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteApplicabilityByApplicabilityId({ payload: { applicabilityId } }) {
    try {
        const response = yield call(invokeApi, 'delete', APPLICABILITY + '/' + applicabilityId);
        if (response.status) {
            yield put(getAllApplicabilityListRequest());
            yield put(deleteApplicabilityByApplicabilityIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteApplicabilityByApplicabilityIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getApplicabilityListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', APPLICABILITY_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getApplicabilityListByStatusResponse(response.data.list));
        } else {
            yield put(getApplicabilityListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSubApplicabilityListByApplicability({ payload: { applicabilityIds } }) {
    try {
        const response = yield call(invokeApi, 'post', SUB_APPLICABILITY_LIST_BY_APPLICABILITY,
            { applicabilityId: applicabilityIds }
        );
        if (response.status) {
            yield put(getSubApplicabilityListByApplicabilityResponse(response.data.list));
        } else {
            yield put(getSubApplicabilityListByApplicabilityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchApplicability() {
    yield takeEvery(ADD_APPLICABILITY_REQUEST, addApplicability);
    yield takeEvery(GET_ALL_APPLICABILITY_LIST_REQUEST, getAllApplicabilityList);
    yield takeEvery(UPDATE_APPLICABILITY_REQUEST, updateApplicability);
    yield takeEvery(UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_REQUEST, updateApplicabilityStatusByApplicabilityId);
    yield takeEvery(DELETE_APPLICABILITY_BY_APPLICABILITY_ID_REQUEST, deleteApplicabilityByApplicabilityId);
    yield takeEvery(GET_APPLICABILITY_LIST_BY_STATUS_REQUEST, getApplicabilityListByStatus);
    yield takeEvery(GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_REQUEST, getSubApplicabilityListByApplicability);
}
function* applicabilitySaga() {
    yield all([fork(watchApplicability)]);
}
export default applicabilitySaga;