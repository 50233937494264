export const ADD_DEPARTMENT_REQUEST = 'add_department_request';
export const ADD_DEPARTMENT_RESPONSE = 'add_department_response';
export const GET_ALL_DEPARTMENT_LIST_REQUEST =
    'get_all_department_list_request';
export const GET_ALL_DEPARTMENT_LIST_RESPONSE =
    'get_all_department_list_response';
export const GET_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST =
    'get_department_by_department_id_request';
export const GET_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE =
    'get_department_by_department_id_response';
export const UPDATE_DEPARTMENT_REQUEST = 'update_department_request';
export const UPDATE_DEPARTMENT_RESPONSE = 'update_department_response';
export const UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_REQUEST =
    'update_department_status_by_department_id_request';
export const UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_RESPONSE =
    'update_department_status_by_department_id_response';
export const GET_DEPARTMENT_LIST_BY_STATUS_REQUEST =
    'get_department_list_by_status_request';
export const GET_DEPARTMENT_LIST_BY_STATUS_RESPONSE =
    'get_department_list_by_status_response';
export const DELETE_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST =
    'delete_department_by_department_id_request';
export const DELETE_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE =
    'delete_department_by_department_id_response';

export const GET_ENTITY_DEPARTMENT_BY_ENTITY_REQUEST =
    'get_entity_department_by_entity_request';
export const GET_ENTITY_DEPARTMENT_BY_ENTITY_RESPONSE =
    'get_entity_department_by_entity_response';
