import produce from 'immer';
import {
    ADD_COLLABORATOR_BY_TASK_ID_RESPONSE,
    ADD_SUB_TASK_RESPONSE,
    CHANGE_TASK_DETAILS_BY_TASK_ID_RESPONSE,
    DELETE_COLLABORATOR_BY_TASK_ID_RESPONSE,
    DELETE_SUB_TASK_BY_ID_RESPONSE,
    GET_ALL_SUB_TASK_BY_TASK_ID_LIST_RESPONSE,
    GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_RESPONSE,
    GET_ASSIGNED_TASK_SEARCH_LIST_RESPONSE,
    GET_AUDIT_TASK_SEARCH_LIST_RESPONSE,
    GET_COLLABORATOR_LIST_BY_TASK_ID_RESPONSE,
    GET_REPORT_TASK_SEARCH_LIST_RESPONSE,
    GET_REVIEW_TASK_SEARCH_LIST_RESPONSE,
    GET_TASK_ACTIVITY_LIST_BY_TASK_ID_RESPONSE,
    GET_TASK_DETAILS_BY_TASK_ID_RESPONSE,
    GET_TASK_DOCUMENTS_BY_TASK_ID_RESPONSE,
    GET_TASK_STATUS_LIST_RESPONSE,
    REOPEN_TASK_BY_TASK_ID_RESPONSE,
    REPORTING_TASK_BY_TASK_ID_RESPONSE,
    SEND_FOR_AUDIT_TASK_BY_TASK_IDS_RESPONSE,
    UPDATE_SUB_TASK_RESPONSE,
    ADD_FILE_UPLOAD_RESPONSE,
    UPDATE_QUICK_TASK_RESPONSE,
    GET_KNOW_YOUR_COMPLIANCES_LIST_RESPONSE,
    GET_TASK_USER_DETAILS_BY_TASK_ID_RESPONSE,
    GET_USER_ASSIGNED_TASK_SEARCH_LIST_RESPONSE,
    CLEAR_TASK_SEARCH_API_RESPONSE,
} from './actionTypes';
export const initialState = {
    taskStatusList: [],
    assignedTaskSearchApiResponse: null,
    userAssignedTaskSearchApiResponse: null,
    knowYourComplianceApiResponse: null,
    taskSearchApiResponse: null,
    taskDetailsApiResponse: null,
    taskUserDetailsApiResponse: null,
    taskActivityList: [],
    taskDocumentApiResponse: null,
    taskApiResponse: null,
    taskSendAuditApiResponse: null,
    reportingApiResponse: null,
    subTaskApiResponse: null,
    editSubTaskApiResponse: null,
    deleteSubTaskApiResponse: null,
    subTaskList: [],
    transactionalDocumentList: [],
    taskCollaboratorApiResponse: null,
    taskDeleteCollaboratorApiResponse: null,
    collaboratorList: [],
    fileUploadResponse: [],
    quickUpdateTaskApiResponse: [],
};
const task = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_TASK_STATUS_LIST_RESPONSE:
                draft.taskStatusList = action.payload.taskStatusList;
                break;

            case GET_ASSIGNED_TASK_SEARCH_LIST_RESPONSE:
                draft.assignedTaskSearchApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_USER_ASSIGNED_TASK_SEARCH_LIST_RESPONSE:
                draft.userAssignedTaskSearchApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_KNOW_YOUR_COMPLIANCES_LIST_RESPONSE:
                draft.knowYourComplianceApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_REPORT_TASK_SEARCH_LIST_RESPONSE:
            case GET_REVIEW_TASK_SEARCH_LIST_RESPONSE:
            case GET_AUDIT_TASK_SEARCH_LIST_RESPONSE:
                draft.taskSearchApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case CLEAR_TASK_SEARCH_API_RESPONSE:
                draft.taskSearchApiResponse = null
                break;
            case GET_TASK_DETAILS_BY_TASK_ID_RESPONSE:
                draft.taskDetailsApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_TASK_USER_DETAILS_BY_TASK_ID_RESPONSE:
                draft.taskUserDetailsApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_TASK_ACTIVITY_LIST_BY_TASK_ID_RESPONSE:
                draft.taskActivityList = action.payload.taskActivityList;
                break;
            case GET_TASK_DOCUMENTS_BY_TASK_ID_RESPONSE:
                draft.taskDocumentApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case REOPEN_TASK_BY_TASK_ID_RESPONSE:
            case CHANGE_TASK_DETAILS_BY_TASK_ID_RESPONSE:
                draft.taskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case SEND_FOR_AUDIT_TASK_BY_TASK_IDS_RESPONSE:
                draft.taskSendAuditApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case REPORTING_TASK_BY_TASK_ID_RESPONSE:
                draft.reportingApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;


            case ADD_SUB_TASK_RESPONSE:
                draft.subTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_SUB_TASK_BY_TASK_ID_LIST_RESPONSE:
                draft.subTaskList = action.payload.subTaskList;
                break;
            case UPDATE_SUB_TASK_RESPONSE:
                draft.editSubTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case DELETE_SUB_TASK_BY_ID_RESPONSE:
                draft.deleteSubTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_RESPONSE:
                draft.transactionalDocumentList =
                    action.payload.transactionalDocumentList;
                break;
            case ADD_COLLABORATOR_BY_TASK_ID_RESPONSE:
                draft.taskCollaboratorApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case DELETE_COLLABORATOR_BY_TASK_ID_RESPONSE:
                draft.taskDeleteCollaboratorApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_COLLABORATOR_LIST_BY_TASK_ID_RESPONSE:
                draft.collaboratorList = action.payload.collaboratorList;
                break;
            case ADD_FILE_UPLOAD_RESPONSE:
                draft.fileUploadResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case UPDATE_QUICK_TASK_RESPONSE:
                draft.quickUpdateTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default task;
