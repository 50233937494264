import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import {
    getTrackAllocationSearchListRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import TrackAllocationFilterForm from './TrackAllocationFilterForm';
import {
    initialTrackAllocationFilterDataState,
    listColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import { DEFAULT_PAGE_LENGTH } from 'constants/commonConstants';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { getTrackAllocationStatusNameById } from 'helpers/projectUtils';
import Activity from 'app/UpdateCenter/NewAdditions/Activity';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            trackAllocationList: [],
            searchFormData: initialTrackAllocationFilterDataState(),
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            resetTableSection: false,
            totalRecords: 0,
            lawComplianceData: null,
            isActivityLogsModalOpen: false,
        };
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        this.getTrackAllocationSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            {},
        );
    }
    getTrackAllocationSearchList = (limit, page, sortBy, values = {}) => {
        values.userAllocation = false;
        let apiData = {
            limit: limit,
            page: page,
            filter: values,
            orderBy: sortBy,
        };
        this.props.getTrackAllocationSearchListRequest(apiData, true);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
            },
            () => {
                this.getTrackAllocationSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialTrackAllocationFilterDataState(),
                currentPage: 1,
                resetTableSection: true,
            },
            () => {
                this.getTrackAllocationSearchList(this.state.pageSize, 1, {
                    key: 'createdAt',
                    value: 'DESC',
                }, {});
            }
        );
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getTrackAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getTrackAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        { key: data.sortBy[0]?.id, value: sortOrder },
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSearch(data) {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                this.getTrackAllocationSearchList(
                    this.state.pageSize,
                    1,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    }
    openActivityLogsModal = (lawComplianceData) => {
        this.setState({
            isActivityLogsModalOpen: true,
            lawComplianceData: lawComplianceData,
        });
    };
    closeActivityLogsModal = () => {
        this.setState({
            isActivityLogsModalOpen: false,
            lawComplianceData: {},
        });
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((allocationData, i) => {
            let actions = (
                <div className="tbl_action">
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            window.open(
                                '/statutory-compliances/view-details/' +
                                allocationData.lawComplianceId,
                                '_blank'
                            );
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('tables.new_additions.activity_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openActivityLogsModal(allocationData);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            return {
                companyGroupName:
                    allocationData.entity?.companyGroup?.companyGroupName,
                entityName: allocationData.entity?.entityName,
                complianceCode: allocationData.lawCompliance?.complianceCode,
                countryName:
                    allocationData.entityLocation?.country?.countryName,
                stateName: allocationData.entityLocation?.state?.stateName,
                cityName: allocationData.entityLocation?.city?.cityName,
                lawShortName: allocationData.lawCompliance?.law?.lawShortName,
                complianceTitle: allocationData.lawCompliance?.complianceTitle,
                complianceClassification:
                    allocationData.lawCompliance?.complianceCategory
                        ?.complianceCategoryName,
                status: getTrackAllocationStatusNameById(allocationData.status),
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.trackAllocationApiResponse &&
            this.props.trackAllocationApiResponse !==
            nextprops.trackAllocationApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                sortBy: nextprops.trackAllocationApiResponse.data.orderBy,
                pageCount: nextprops.trackAllocationApiResponse.data.totalPages,
                currentPage:
                    nextprops.trackAllocationApiResponse.data.currentPage,
                totalRecords:
                    nextprops.trackAllocationApiResponse.data.totalRecords,
                trackAllocationList: this.generateTable(
                    nextprops.trackAllocationApiResponse.data.complianceList
                ),
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.trackAllocationList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'track-allocation'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={__t('page_headers.track_allocation')}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <TrackAllocationFilterForm
                                    allocationFilterData={
                                        this.state.searchFormData
                                    }
                                    operationType={this.state.operationType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    {!this.state.resetTableSection && (
                        <Col lg="12">
                            <Card>
                                <CardBody className="custom_table table-responsive">
                                    <CustomisedReactTableWithPagination
                                        className="table table-bordered table-striped"
                                        displayEntries={true}
                                        searching={true}
                                        currentPage={this.state.currentPage}
                                        pageCount={this.state.pageCount}
                                        handleFetchData={this.handleFetchData}
                                        handleSort={this.handleSort}
                                        handleSearch={this.handleSearch}
                                        preFilledSearchText={
                                            this.state.searchFormData
                                                ?.searchText
                                        }
                                        tableData={data}
                                        totalRecords={this.state.totalRecords}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>

                <Activity
                    modalName={__t('form_labels.new_additions.activity_logs')}
                    isModalOpen={this.state.isActivityLogsModalOpen}
                    lawComplianceData={this.state.lawComplianceData}
                    onModalDismiss={() => this.closeActivityLogsModal()}
                    type={'track'}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    trackAllocationApiResponse: makeAllocationSelectField(
        'trackAllocationApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getTrackAllocationSearchListRequest,
});
export default compose(
    withConnect,
    withAllocationReducer,
    withAllocationSaga,
    withRouter
)(Index);
