export const GET_ROLE_LIST_REQUEST = 'get_role_list_request';
export const GET_ROLE_LIST_RESPONSE = 'get_role_list_response';

export const GET_MODULE_LIST_REQUEST = 'get_module_list_request';
export const GET_MODULE_LIST_RESPONSE = 'get_module_list_response';

export const GET_PERMISSION_LIST_REQUEST = 'get_permission_list_request';
export const GET_PERMISSION_LIST_RESPONSE = 'get_permission_list_response';

export const GET_ROLE_BY_ROLE_ID_REQUEST = 'get_role_by_role_id_request';
export const GET_ROLE_BY_ROLE_ID_RESPONSE = 'get_role_by_role_id_response';

export const UPDATE_ROLE_BY_ROLE_ID_REQUEST = 'update_role_by_role_id_request';
export const UPDATE_ROLE_BY_ROLE_ID_RESPONSE =
    'update_role_by_role_id_response';

export const GET_ROLE_PERMISSION_BY_LOGIN_USER_REQUEST =
    'get_role_permission_by_login_user_request';
export const GET_ROLE_PERMISSION_BY_LOGIN_USER_RESPONSE =
    'get_role_permission_by_login_user_response';

export const GET_ENTITY_ROLE_LIST_REQUEST = 'get_entity_role_list_request';
export const GET_ENTITY_ROLE_LIST_RESPONSE = 'get_entity_role_list_response';

export const GET_SYSTEM_CONFIGURATION_ROLE_LIST_REQUEST =
    'get_system_configuration_role_list_request';
export const GET_SYSTEM_CONFIGURATION_ROLE_LIST_RESPONSE =
    'get_system_configuration_role_list_response';

export const SAVE_SYSTEM_CONFIGURATION_USERS_REQUEST =
    'save_system_configuration_users_request';
export const SAVE_SYSTEM_CONFIGURATION_USERS_RESPONSE =
    'save_system_configuration_users_response';

export const GET_SYSTEM_CONFIGURATION_USERS_LIST_REQUEST =
    'get_system_configuration_users_list_request';
export const GET_SYSTEM_CONFIGURATION_USERS_LIST_RESPONSE =
    'get_system_configuration_users_list_response';

export const UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_REQUEST =
    'update_system_configuration_user_role_status_by_user_role_id_request';
export const UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_RESPONSE =
    'update_system_configuration_user_role_status_by_user_role_id_response';

export const DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_REQUEST =
    'delete_system_configuration_user_role_by_user_role_id_request';
export const DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_RESPONSE =
    'delete_system_configuration_user_role_by_user_role_id_response';

export const GET_USER_ROLE_WISE_COUNTRIES_LIST_REQUEST =
    'get_user_role_wise_countries_list_request';
export const GET_USER_ROLE_WISE_COUNTRIES_LIST_RESPONSE =
    'get_user_role_wise_countries_list_response';

export const GET_GCM_USERS_BY_COUNTRY_REQUEST =
    'get_gcm_users_by_country_request';
export const GET_GCM_USERS_BY_COUNTRY_RESPONSE =
    'get_gcm_users_by_country_response';
