import produce from 'immer';
import { GET_GENERATE_CERTIFICATE_RESPONSE, GET_REPORT_DATA_BY_TYPE_RESPONSE, GET_SYSTEM_RUNTIME_REPORT_RESPONSE } from './actionTypes';
export const initialState = {
    reportApiResponse: null,
    systemRuntimeReportApiResponse: null,
    generateCertificateResponse : null
};
const report = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_REPORT_DATA_BY_TYPE_RESPONSE:
                draft.reportApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_SYSTEM_RUNTIME_REPORT_RESPONSE:
                draft.systemRuntimeReportApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_GENERATE_CERTIFICATE_RESPONSE:
          
                draft.generateCertificateResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default report;