import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_RESPONSE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_RESPONSE,
    RESET_PASSWORD_TOKEN_REQUEST,
    RESET_PASSWORD_TOKEN_RESPONSE,
} from './actionTypes';
export const forgotPasswordRequest = (email) => {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        payload: { email },
    };
};
export const forgotPasswordResponse = (message, responseType, responseData) => {
    return {
        type: FORGOT_PASSWORD_RESPONSE,
        payload: { message, responseType, responseData },
    };
};
export const resetPasswordTokenRequest = (token) => {
    return {
        type: RESET_PASSWORD_TOKEN_REQUEST,
        payload: { token },
    };
};
export const resetPasswordTokenResponse = (message, responseType, responseData) => {
    return {
        type: RESET_PASSWORD_TOKEN_RESPONSE,
        payload: { message, responseType, responseData },
    };
};
export const resetPasswordRequest = (data) => {
    return {
        type: RESET_PASSWORD_REQUEST,
        payload: { data },
    };
};
export const resetPasswordResponse = (message, responseType, responseData) => {
    return {
        type: RESET_PASSWORD_RESPONSE,
        payload: { message, responseType, responseData },
    };
};