import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    CardHeader,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    ACTIVE,
    CANCEL,
    SUBMIT,
    ADD_NEW,
    DANGER,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getCompanyGroupListByStatusRequest,
    getUsersByEntityRequest,
    addEntityRequest,
    getEntityByEntityIdRequest,
    updateEntityRequest,
    getRolesWithEntityRoleByEntityListRequest,
    addFileUploadRequest
} from 'store/actions';

import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import entityRoleReducer from 'store/Masters/EntityRole/reducer';
import entityRoleSaga from 'store/Masters/EntityRole/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { formatDateForApi, formatDateForApiSystem } from 'helpers/dateTimeHelper';
import {
    customEntityFileValidator,
    customValidPhoneField,
    customValidTextField,
    customValidTextForEntityNameField,
} from 'helpers/customValidators';
import {
    emailTemplateListColumns,
    finListColumns,
    hodListColumns,
    initialEntityDataState,
    initialEntityEmailTemplatesDataState,
    initialEntityFinancialYearsDataState,
    initialEntityLocationsDataState,
    initialHodDataDataState,
    initialTeamDataState,
    locListColumns,
    teamColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import { makeEntitySelectField } from 'store/Entity/selector';
import AddEditFinancialYearFormModal from './AddEditFinancialYearFormModal';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import AddEditLocationFormModal from './AddEditLocationFormModal';
import { selectSortedUserList } from 'store/User/selector';
import AddEditTeamFormModal from './AddEditTeamFormModal';
import AuditLogModal from 'components/Application/AuditLogModal';
import AddEditHodFormModal from './AddEditHodFormModal';
import AddEditEmailTemplateFormModal from './AddEditEmailTemplateFormModal';
import { selectSortedRolesWithEntityRoleList } from 'store/Masters/EntityRole/selector';
import { emailConfigRoleList } from 'constants/dropdowns';
import { checkModulePermission } from 'helpers/projectUtils';
import { MODULE_ENTITY_LOCATION, PERMISSION_ADD } from 'constants/databaseConstants';
import Dropzone from 'react-dropzone';
import { makeTaskSelectField } from 'store/Task/selector';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const entityRoleKey = 'entityRole';
const withEntityRoleReducer = injectReducer({
    key: entityRoleKey,
    reducer: entityRoleReducer,
});
const withEntityRoleSaga = injectSaga({
    key: entityRoleKey,
    saga: entityRoleSaga,
});
class EntityForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField(),
                customValidTextForEntityNameField(),
                customValidPhoneField()
            ),
        });
        this.state = {
            downloadFileUrl: '',
            downloadFileName: '',
            selectedFile: [],
            errors: '',
            operationType: SUBMIT,
            entityId: this.props.match.params.entityId
                ? parseInt(this.props.match.params.entityId)
                : '',
            pageHeaderName: this.props.match.params.entityId
                ? __t('page_headers.entity_edit')
                : __t('page_headers.entity_add'),
            entityData: initialEntityDataState(),
            removeEntityLocations: [],
            removeFinancialYears: [],
            removeFinancialYearsData: [],
            removeEntityEmailTemplates: [],
            removeEntityHod: [],
            financialYearData: {},
            isModalOpen: false,
            financialYearOperationType: SUBMIT,
            isFinancialYearEdit: false,
            locationData: {},
            isLocationModalOpen: false,
            locationOperationType: SUBMIT,
            isLocationEdit: false,
            isEmailTemplateModalOpen: false,
            emailTemplateOperationType: SUBMIT,
            emailTemplateData: {},
            isEmailTemplateEdit: false,
            isHodModalOpen: false,
            hodOperationType: SUBMIT,
            hodData: {},
            isHodEdit: false,
            isTeamModalOpen: false,
            teamOperationType: SUBMIT,
            teamData: {},
            isTeamEdit: false,
            teamType: '',
            isAuditModalOpen: false,
            auditId: '',
            isAuditLocationModalOpen: false,
            auditLocationId: '',
            isAuditHodModalOpen: false,
            auditHodId: '',
            isAuditEmailTemplateModalOpen: false,
            auditEmailTemplateId: '',
            entityUsers: [],
            entitySystemFileName: '',
            entityFileName: '',
        };

        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }
    componentDidMount() {
        if (this.state.entityId) {
            this.props.getEntityByEntityIdRequest(this.state.entityId);
            this.props.getUsersByEntityRequest(this.state.entityId);
            this.props.getRolesWithEntityRoleByEntityListRequest(
                this.state.entityId
            );
        } else {
            this.props.getRolesWithEntityRoleByEntityListRequest(-1);
        }
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        var countryIdsData = countryIds.filter(function (el) {
            return el !== '';
        });
        this.props.getStateListByCountryRequest(countryIdsData);
    }
    getCityListByState(stateIds) {
        var stateIdsData = stateIds.filter(function (el) {
            return el !== '';
        });
        this.props.getCityListByStateRequest(stateIdsData);
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customEntityFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.entity_document_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({ errors: __t('form_labels.entity.document_file') + ' ' + __t('validations.custom.entity_document_file') });
        } else {
            this.setState({ errors: '' })
        }
        let formData = new FormData();
        files.map((file) => {
            formData.append('file', file);
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size)
            });
        });
        formData.append('type', 'entity');
        this.setState({ selectedFile: files }, () => {
            this.props.addFileUploadRequest(formData);
        });
    }
    /**
    * Formats the size
    */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    openAuditLogLocationModal = (auditLocationId) => {
        this.setState({
            isAuditLocationModalOpen: true,
            auditLocationId: auditLocationId,
        });
    };
    closeAuditLogLocationModal = () => {
        this.setState({
            isAuditLocationModalOpen: false,
            auditLocationId: '',
        });
    };
    openAuditLogEmailTemplateModal = (auditEmailTemplateId) => {
        this.setState({
            isAuditEmailTemplateModalOpen: true,
            auditEmailTemplateId: auditEmailTemplateId,
        });
    };
    closeAuditLogEmailTemplateModal = () => {
        this.setState({
            isAuditEmailTemplateModalOpen: false,
            auditEmailTemplateId: '',
        });
    };
    openAuditLogHodModal = (auditHodId) => {
        this.setState({
            isAuditHodModalOpen: true,
            auditHodId: auditHodId,
        });
    };
    closeAuditLogHodModal = () => {
        this.setState({
            isAuditHodModalOpen: false,
            auditHodId: '',
        });
    };
    addNewFinancialYear() {
        this.setState({
            isModalOpen: true,
            financialYearOperationType: SUBMIT,
            financialYearData: initialEntityFinancialYearsDataState(),
            isFinancialYearEdit: false,
        });
    }
    editFinancialYear = (data) => {
        this.setState({
            isModalOpen: true,
            financialYearOperationType: UPDATE,
            financialYearData: data,
            isFinancialYearEdit: true,
        });
    };
    deleteFinancialYear = async (data, setFieldValue, values) => {
        if (values.entityLocations.filter((entityLoc) => entityLoc.countryId == data.countryId)?.length > 0) {
            toastrMessage(__t('alerts.entity_financial_year_not_delete'), DANGER);
            return false;
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_financial_year_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeFinancialYears: [
                        ...this.state.removeFinancialYears,
                        data.id,
                    ],
                    removeFinancialYearsData: [
                        ...this.state.removeFinancialYearsData,
                        data,
                    ]
                });
            }
            let entityFinancialYears = values.entityFinancialYears.filter(
                (obj) => obj !== data
            );
            setFieldValue('entityFinancialYears', entityFinancialYears);
        }
    };
    closeFinancialYearModal() {
        this.setState({
            isModalOpen: false,
            financialYearOperationType: SUBMIT,
            financialYearData: {},
            isFinancialYearEdit: false,
        });
    }
    generateFinancialYearTable = (records, setFieldValue, values) => {
        let financialYearRecords = [];
        financialYearRecords = records.map((financialYearRecord, i) => {
            let actions = (
                <div className="text-center">
                    {financialYearRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() =>
                                this.editFinancialYear(financialYearRecord)
                            }
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}

                    {!financialYearRecord.id && (
                        <a
                            href={() => false}
                            title={__t('common.delete')}
                            className="btn btn-link text-danger tbl-action"
                            onClick={() => {
                                this.deleteFinancialYear(
                                    financialYearRecord,
                                    setFieldValue,
                                    values
                                );
                            }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </a>
                    )}
                    {financialYearRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogModal(financialYearRecord.id);
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                countryName: financialYearRecord.countryName,
                startMonth: financialYearRecord.startMonth,
                endMonth: financialYearRecord.endMonth,
                actions: actions,
            };
        });
        return financialYearRecords.reverse();
    };
    addNewLocation() {
        this.setState({
            isLocationModalOpen: true,
            locationOperationType: SUBMIT,
            locationData: initialEntityLocationsDataState(),
            isLocationEdit: false,
        });
    }
    editEntityLocation = (data) => {
        this.setState({
            isLocationModalOpen: true,
            locationOperationType: UPDATE,
            locationData: data,
            isLocationEdit: true,
        });
    };
    deleteLocation = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_location_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeEntityLocations: [
                        ...this.state.removeEntityLocations,
                        data.id,
                    ],
                });
            }
            let entityLocations = values.entityLocations.filter(
                (obj) => obj !== data
            );
            setFieldValue('entityLocations', entityLocations);
        }
    };
    closeLocationModal() {
        this.setState({
            isLocationModalOpen: false,
            locationOperationType: SUBMIT,
            locationData: {},
            isLocationEdit: false,
        });
    }

    generateLocationTable = (records, setFieldValue, values) => {
        let locationRecords = [];
        locationRecords = records.map((locationRecord, i) => {
            let actions = (
                <div className="text-center">
                    {locationRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() =>
                                this.editEntityLocation(locationRecord)
                            }
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    {!locationRecord.id && (
                        <a
                            title={__t('common.delete')}
                            className="btn btn-link text-danger tbl-action"
                            onClick={() => {
                                this.deleteLocation(
                                    locationRecord,
                                    setFieldValue,
                                    values
                                );
                            }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </a>
                    )}
                    {locationRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogLocationModal(
                                    locationRecord.id
                                );
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );

            return {
                countryName: locationRecord.countryName,
                stateName: locationRecord.stateName,
                cityName: locationRecord.cityName,
                pincode: (locationRecord.pincode && locationRecord.pincode != null) ? locationRecord.pincode : '',
                registrationNumber: (locationRecord.registrationNumber && locationRecord.registrationNumber != null) ? locationRecord.registrationNumber : '',
                registrationDate: (locationRecord.registrationDate && locationRecord.registrationDate != '0000-00-00')
                    ? formatDateForApiSystem(locationRecord.registrationDate)
                    : '',
                actions: actions,
            };
        });
        return locationRecords.reverse();
    };
    addNewEmailTemplate() {
        this.setState({
            isEmailTemplateModalOpen: true,
            emailTemplateOperationType: SUBMIT,
            emailTemplateData: initialEntityEmailTemplatesDataState(),
            isEmailTemplateEdit: false,
        });
    }
    editEntityEmailTemplate = (data) => {
        this.setState({
            isEmailTemplateModalOpen: true,
            emailTemplateOperationType: UPDATE,
            emailTemplateData: data,
            isEmailTemplateEdit: true,
        });
    };
    deleteEntityEmailTemplate = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_email_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeEntityEmailTemplates: [
                        ...this.state.removeEntityEmailTemplates,
                        data.id,
                    ],
                });
            }
            let entityEmailTemplates = values.entityEmailTemplates.filter(
                (obj) => obj !== data
            );
            setFieldValue('entityEmailTemplates', entityEmailTemplates);
        }
    };
    closeEntityEmailTemplateModal() {
        this.setState({
            isEmailTemplateModalOpen: false,
            emailTemplateOperationType: SUBMIT,
            emailTemplateData: {},
            isEmailTemplateEdit: false,
        });
    }
    generateEmailTemplateTable = (records, setFieldValue, values) => {
        let emailTemplateRecords = [];
        emailTemplateRecords = records.map((emailTemplateRecord, i) => {
            let actions = (
                <div className="text-center">
                    {emailTemplateRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() =>
                                this.editEntityEmailTemplate(
                                    emailTemplateRecord
                                )
                            }
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteEntityEmailTemplate(
                                emailTemplateRecord,
                                setFieldValue,
                                values
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                    {emailTemplateRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogEmailTemplateModal(
                                    emailTemplateRecord.id
                                );
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                emailTemplateType: emailTemplateRecord?.emailTemplateTypeName,
                toRoles: emailTemplateRecord?.toRolesName?.join(),
                ccRoles: emailTemplateRecord?.ccRolesName?.join(),
                daysFirstNotification:
                    emailTemplateRecord?.daysFirstNotification,
                daysSecondNotification:
                    emailTemplateRecord?.daysSecondNotification,
                actions: actions,
            };
        });
        return emailTemplateRecords.reverse();
    };
    addNewHod() {
        this.setState({
            isHodModalOpen: true,
            hodOperationType: SUBMIT,
            hodData: initialHodDataDataState(),
            isHodEdit: false,
        });
    }
    editEntityHod = (data) => {
        this.setState({
            isHodModalOpen: true,
            hodOperationType: UPDATE,
            hodData: data,
            isHodEdit: true,
        });
    };
    deleteEntityHod = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_hod_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeEntityHod: [...this.state.removeEntityHod, data.id],
                });
            }
            let entityHod = values.entityHod.filter((obj) => obj !== data);
            setFieldValue('entityHod', entityHod);
        }
    };
    closeHodModal() {
        this.setState({
            isHodModalOpen: false,
            hodOperationType: SUBMIT,
            hodData: {},
            isHodEdit: false,
        });
    }
    generateHodTable = (records, setFieldValue, values) => {
        let hodRecords = [];
        hodRecords = records.map((hodRecord, i) => {
            let actions = (
                <div className="text-center">
                    {hodRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => this.editEntityHod(hodRecord)}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteEntityHod(
                                hodRecord,
                                setFieldValue,
                                values
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                    {hodRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogHodModal(hodRecord.id);
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                departmentName: hodRecord.departmentName,
                userName: hodRecord.userName,
                actions: actions,
            };
        });
        return hodRecords.reverse();
    };

    addNewTeam(type) {
        this.setState({
            isTeamModalOpen: true,
            teamOperationType: SUBMIT,
            teamData: initialTeamDataState(),
            isTeamEdit: false,
            teamType: type,
        });
    }
    getTeamModalNameByType = (type) => {
        let modalName = '';
        switch (type) {
            case 'entityAdminUsers':
                modalName = __t('form_labels.entity.entity_admin');
                break;
            case 'entityTeamUsers':
                modalName = __t('form_labels.entity.entity_team');
                break;
            case 'entityManagementUsers':
                modalName = __t('form_labels.entity.entity_management_team');
                break;
            case 'entityConsultantUsers':
                modalName = __t('form_labels.entity.entity_consultant_team');
                break;
            default:
                break;
        }
        return modalName;
    };
    deleteTeam = async (data, setFieldValue, values, type) => {
        let message = '';
        switch (type) {
            case 'entityAdminUsers':
                message = __t('alerts.entity_admin_delete');
                break;
            case 'entityTeamUsers':
                message = __t('alerts.entity_team_delete');
                break;
            case 'entityManagementUsers':
                message = __t('alerts.entity_management_team_delete');
                break;
            case 'entityConsultantUsers':
                message = __t('alerts.entity_consultant_team_delete');
                break;
            default:
                break;
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            message
        );
        if (response) {
            let users = values[type].filter(
                (obj) => obj.userId !== data.userId
            );
            setFieldValue(type, users);
        }
    };
    closeTeamModal() {
        this.setState({
            isTeamModalOpen: false,
            teamOperationType: SUBMIT,
            teamData: {},
            isTeamEdit: false,
            teamType: '',
        });
    }
    generateTeamTable = (records, setFieldValue, values, type) => {
        let teamRecords = [];
        teamRecords = records.map((teamRecord, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteTeam(
                                teamRecord,
                                setFieldValue,
                                values,
                                type
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            );
            return {
                fullName: teamRecord?.user?.fullName,
                email: teamRecord?.user?.email,
                actions: actions,
            };
        });
        return teamRecords.reverse();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = {
                ...values,
                removeEntityLocations: this.state.removeEntityLocations,
                removeFinancialYears: this.state.removeFinancialYears,
                removeFinancialYearsData: this.state.removeFinancialYearsData,
                removeEntityHod: this.state.removeEntityHod,
                removeEntityEmailTemplates:
                    this.state.removeEntityEmailTemplates,
            };
            // if file is uploaded then need to send to api
            if (this.state.entityFileName != '' && this.state.entitySystemFileName != '') {
                formValues.entityFileName = this.state.entityFileName;
                formValues.entitySystemFileName = this.state.entitySystemFileName;
            }
            if (formValues?.entityLocations?.length > 0) {
                formValues.entityLocations = formValues.entityLocations.map(
                    (item) => ({
                        ...item,
                        registrationDate: item.registrationDate
                            ? formatDateForApi(item.registrationDate)
                            : '',
                    })
                );
            }
            formValues.entityAdmin = formValues.entityAdminUsers.map(
                (opt) => opt.userId
            );
            formValues.entityTeam = formValues.entityTeamUsers.map(
                (opt) => opt.userId
            );
            formValues.entityManagementTeam =
                formValues.entityManagementUsers.map((opt) => opt.userId);
            formValues.entityConsultantTeam =
                formValues.entityConsultantUsers.map((opt) => opt.userId);



            if (this.state.operationType === UPDATE) {
                delete formValues.companyGroup;
                this.props.updateEntityRequest(this.state.entityId, formValues);
            } else {
                this.props.addEntityRequest(formValues);
            }
        } else {
            window.scrollTo(0, 0);
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.entityApiResponse &&
            this.props.entityApiResponse !== nextprops.entityApiResponse
        ) {
            toastrMessage(
                nextprops.entityApiResponse.message,
                nextprops.entityApiResponse.responseType
            );
            if (nextprops.entityApiResponse.responseType === SUCCESS) {
                this.props.history.push('/entities');
            }
        }
        if (
            nextprops.editEntityApiResponse &&
            this.props.editEntityApiResponse !== nextprops.editEntityApiResponse
        ) {
            this.setState({
                operationType: UPDATE,
                entityData: {
                    ...nextprops.editEntityApiResponse.data.entity,
                    companyGroupId:
                        nextprops.editEntityApiResponse.data.entity.companyGroup
                            .id,
                    entityFinancialYears:
                        nextprops.editEntityApiResponse.data.entity.entityFinancialYears?.map(
                            (opt) => ({
                                ...opt,
                                countryName: opt.country
                                    ? opt.country.countryName
                                    : '',
                            })
                        ) || [],
                    entityLocations:
                        nextprops.editEntityApiResponse.data.entity.entityLocations?.map(
                            (opt) => ({
                                ...opt,
                                countryName: opt.country
                                    ? opt.country.countryName
                                    : '',
                                stateName: opt.state ? opt.state.stateName : '',
                                cityName: opt.city ? opt.city.cityName : '',
                            })
                        ) || [],
                    entityEmailTemplates:
                        nextprops.editEntityApiResponse.data.entity.entityEmailTemplates?.map(
                            (opt) => ({
                                ...opt,
                                emailTemplateTypeName: opt.emailTemplateType
                                    ? opt.emailTemplateType.displayName
                                    : '',
                                toRolesName:
                                    opt.toRoles.length > 0
                                        ? emailConfigRoleList
                                            .map((optionRole) =>
                                                opt.toRoles.includes(
                                                    optionRole.value
                                                )
                                                    ? optionRole.label
                                                    : ''
                                            )
                                            .filter((x) => x != '')
                                        : [],
                                ccRolesName:
                                    opt.ccRoles.length > 0
                                        ? emailConfigRoleList
                                            .map((optionRole) =>
                                                opt.ccRoles.includes(
                                                    optionRole.value
                                                )
                                                    ? optionRole.label
                                                    : ''
                                            )
                                            .filter((x) => x != '')
                                        : [],
                            })
                        ) || [],
                    entityAdmin:
                        nextprops.editEntityApiResponse.data.entity.entityAdmin?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityTeam:
                        nextprops.editEntityApiResponse.data.entity.entityTeam?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityManagementTeam:
                        nextprops.editEntityApiResponse.data.entity.entityManagementTeam?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityConsultantTeam:
                        nextprops.editEntityApiResponse.data.entity.entityConsultantTeam?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityAdminUsers:
                        nextprops.editEntityApiResponse.data.entity.entityAdmin,
                    entityManagementUsers:
                        nextprops.editEntityApiResponse.data.entity
                            .entityManagementTeam,
                    entityTeamUsers:
                        nextprops.editEntityApiResponse.data.entity.entityTeam,
                    entityConsultantUsers:
                        nextprops.editEntityApiResponse.data.entity
                            .entityConsultantTeam,
                    entityHod:
                        nextprops.editEntityApiResponse.data.entity.entityHods?.map(
                            (opt) => ({
                                ...opt,
                                departmentName: opt.department
                                    ? opt.department.departmentName
                                    : '',
                                userName: opt.user
                                    ? opt.user.fullName + ' - ' + opt.user.email
                                    : '',
                            })
                        ) || [],
                },
            });
        }
        if (nextprops.fileUploadResponse && this.props.fileUploadResponse !== nextprops.fileUploadResponse) {
            if (nextprops.fileUploadResponse.responseType === SUCCESS) {
                this.setState({
                    entitySystemFileName: nextprops.fileUploadResponse.data.uploadedFileName[0],
                    entityFileName: nextprops.fileUploadResponse.data.originalFilenames[0]
                })
            } else {
                toastrMessage(nextprops.fileUploadResponse.message, DANGER);
                this.setState({
                    selectedFile: []
                })
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Col lg="12">
                    <CommonBreadcrum type={'add-entity'} edit={(this.state.operationType == UPDATE)} />
                </Col>
                <div className="page-header">
                    <h4 className="with-back-btn mt-0 d-flex">
                        <a
                            href={() => false}
                            className="dripicons-arrow-thin-left"
                            onClick={() => {
                                this.props.history.push('/entities');
                            }}
                        >
                            { }
                        </a>{' '}
                        &nbsp;{this.state.pageHeaderName}{' '}
                    </h4>
                </div>
                <Formik
                    initialValues={this.state.entityData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="add-entity">

                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.basic_details'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                component={Select}
                                                name="companyGroupId"
                                                className="form-control selectbox"
                                                options={this.props.sortedCompanyGroupListDropdown}
                                                onChange={(opt) => {
                                                    setFieldValue(
                                                        'companyGroupId',
                                                        opt.value
                                                    );
                                                }}
                                                value={this.props.sortedCompanyGroupListDropdown.filter(
                                                    (option) =>
                                                        option.value ===
                                                        values.companyGroupId
                                                )}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.company_group'
                                                )}{' '}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.company_group'
                                                ),
                                                values.companyGroupId,
                                                'required',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.entity.company_group_required'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                name="entityName"
                                                className="form-control"
                                                placeholder={__t(
                                                    'form_labels.entity.entity_name'
                                                )}
                                                value={values.entityName}
                                                maxLength={250}

                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.entity_name'
                                                )}{' '}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.entity_name'
                                                ),
                                                values.entityName,
                                                'required|max:250|onlyCharactersForEntityName',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.entity.entity_name_required'
                                                        ),
                                                        max: __t(
                                                            'validations.entity.entity_name_max',
                                                            { max: 250 }
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                name="email"
                                                className="form-control"
                                                placeholder={__t(
                                                    'form_labels.entity.registered_email_id'
                                                )}
                                                maxLength={150}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.registered_email_id'
                                                )}{' '}
                                                {/* <span className="text-danger required">
                                                    *
                                                </span> */}
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.registered_email_id'
                                                ),
                                                values.email,
                                                'email|max:150',
                                                {
                                                    messages: {

                                                        max: __t(
                                                            'validations.entity.email_max',
                                                            { max: 150 }
                                                        ),
                                                        email: __t(
                                                            'validations.entity.email_email'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                name="contactNo"
                                                className="form-control"
                                                placeholder={__t(
                                                    'form_labels.entity.contact_no'
                                                )}
                                                maxLength={14}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.contact_no'
                                                )}
                                                {/* <span className="text-danger required">
                                                    *
                                                </span> */}
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.contact_no'
                                                ),
                                                values.contactNo,
                                                'customPhone|max:14',
                                                {
                                                    messages: {

                                                        max: __t(
                                                            'validations.entity.contact_no_max',
                                                            { max: 14 }
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className='col-lg-12'>
                                            <Dropzone multiple={false} onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div className="dz-message needsclick" {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <h3>Drop files here or click to upload.</h3>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            {this.state.errors != '' && (
                                                <div className="srv-validation-message">{this.state.errors}</div>
                                            )}
                                            <div className="dropzone-previews mt-2" id="file-previews">
                                                {this.state.selectedFile.map((f, i) => {
                                                    return <Card className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                                                        <div className="p-2">
                                                            <Row className="align-items-center">
                                                                <Col>
                                                                    <Link to="#" className="upload-document-title font-weight-bold">{f.name}</Link>
                                                                    <p className="mb-0"><strong>{f.formattedSize}</strong></p>

                                                                </Col>
                                                                <Col className='d-flex justify-content-end'>
                                                                    <Button
                                                                        className="btn btn-danger btn-sm"
                                                                        title="Delete file"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                entityFileName: '',
                                                                                entitySystemFileName: '',
                                                                                selectedFile: []
                                                                            })
                                                                        }}
                                                                    >
                                                                        <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                        </svg>
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                })}
                                            </div>
                                        </FormGroup>

                                        {values.fileUrl != '' && this.state.operationType == UPDATE && (
                                            <div className='col-12 d-flex justify-content-end'>
                                                <Button className='px-2' color='warning' size='sm' onClick={() => {
                                                    this.saveFile(values);
                                                }}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10L7.29289 10.7071L8 11.4142L8.70711 10.7071L8 10ZM9 1C9 0.447715 8.55229 2.42698e-07 8 2.18557e-07C7.44772 1.94416e-07 7 0.447715 7 1L9 1ZM2.29289 5.70711L7.29289 10.7071L8.70711 9.29289L3.70711 4.29289L2.29289 5.70711ZM8.70711 10.7071L13.7071 5.70711L12.2929 4.29289L7.29289 9.29289L8.70711 10.7071ZM9 10L9 1L7 1L7 10L9 10Z" fill="currentColor" /><path d="M1 12L1 13C1 14.1046 1.89543 15 3 15L13 15C14.1046 15 15 14.1046 15 13V12" stroke="currentColor" strokeWidth="2" /></svg> {__t('form_labels.common.click_to_download_existing_file')}
                                                </Button>
                                            </div>
                                        )}
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity locations */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.locations'
                                            )}<span className="text-danger required">
                                                *
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="page-action">
                                        {(checkModulePermission(
                                            MODULE_ENTITY_LOCATION,
                                            PERMISSION_ADD
                                        ) === true) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-sm float-right"
                                                    onClick={() =>
                                                        this.addNewLocation()
                                                    }
                                                >
                                                    {ADD_NEW}
                                                </button>
                                            )}
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <AddEditLocationFormModal
                                                isModalOpen={
                                                    this.state
                                                        .isLocationModalOpen
                                                }
                                                operationType={
                                                    this.state
                                                        .locationOperationType
                                                }
                                                locationData={
                                                    this.state.locationData
                                                }
                                                locationList={
                                                    values.entityLocations
                                                }
                                                onFormValid={(locValues) => {
                                                    let entityLocationsList =
                                                        values.entityLocations;
                                                    var updateDetailsIndex =
                                                        entityLocationsList.findIndex(
                                                            (opt) =>
                                                                opt.id ===
                                                                locValues.id
                                                        );
                                                    if (
                                                        updateDetailsIndex >
                                                        -1 &&
                                                        this.state
                                                            .isLocationEdit
                                                    ) {
                                                        entityLocationsList[
                                                            updateDetailsIndex
                                                        ] = locValues;
                                                        setFieldValue(
                                                            'entityLocations',
                                                            entityLocationsList
                                                        );
                                                    } else {
                                                        let entityLocationValues =
                                                            [
                                                                ...values.entityLocations,
                                                                locValues,
                                                            ];
                                                        let uniqueEntityLocationValues =
                                                            Array.from(
                                                                new Set(
                                                                    entityLocationValues.map(
                                                                        JSON.stringify
                                                                    )
                                                                )
                                                            ).map(JSON.parse);
                                                        setFieldValue(
                                                            'entityLocations',
                                                            uniqueEntityLocationValues
                                                        );
                                                    }
                                                    this.closeLocationModal();
                                                }}
                                                onModalDismiss={() =>
                                                    this.closeLocationModal()
                                                }
                                            />
                                            <CustomisedDatatable
                                                tableRecords={{
                                                    columns: locListColumns,
                                                    rows: this.generateLocationTable(
                                                        values.entityLocations,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Financial Year  */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.financial_year'
                                            )}<span className="text-danger required">
                                                *
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="page-action">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() =>
                                                this.addNewFinancialYear()
                                            }
                                        >
                                            {ADD_NEW}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <AddEditFinancialYearFormModal
                                                isModalOpen={
                                                    this.state.isModalOpen
                                                }
                                                operationType={
                                                    this.state
                                                        .financialYearOperationType
                                                }
                                                financialYearData={
                                                    this.state.financialYearData
                                                }
                                                financialYearList={
                                                    values.entityFinancialYears
                                                }
                                                onFormValid={(finValues) => {
                                                    let financialYearDataList =
                                                        values.entityFinancialYears;
                                                    var updateDetailsIndex =
                                                        financialYearDataList.findIndex(
                                                            (opt) =>
                                                                opt.id ===
                                                                finValues.id
                                                        );
                                                    if (
                                                        updateDetailsIndex >
                                                        -1 &&
                                                        this.state
                                                            .isFinancialYearEdit
                                                    ) {
                                                        financialYearDataList[
                                                            updateDetailsIndex
                                                        ] = finValues;
                                                        setFieldValue(
                                                            'entityFinancialYears',
                                                            financialYearDataList
                                                        );
                                                    } else {
                                                        setFieldValue(
                                                            'entityFinancialYears',
                                                            [
                                                                ...values.entityFinancialYears,
                                                                finValues,
                                                            ]
                                                        );
                                                    }
                                                    this.closeFinancialYearModal();
                                                }}
                                                onModalDismiss={() =>
                                                    this.closeFinancialYearModal()
                                                }
                                                entityLocationsIds={
                                                    values.entityLocations &&
                                                        values.entityLocations
                                                            .length > 0
                                                        ? values.entityLocations.map(
                                                            (location) =>
                                                                location.countryId
                                                        )
                                                        : []
                                                }
                                            />
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: finListColumns,
                                                    rows: this.generateFinancialYearTable(
                                                        values.entityFinancialYears,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity User */}
                            <AddEditTeamFormModal
                                isModalOpen={this.state.isTeamModalOpen}
                                operationType={this.state.teamOperationType}
                                teamData={this.state.teamData}
                                teamList={values[this.state.teamType]}
                                modalName={this.getTeamModalNameByType(
                                    this.state.teamType
                                )}
                                onFormValid={(teamValues) => {
                                    setFieldValue(this.state.teamType, [
                                        ...values[this.state.teamType],
                                        ...teamValues.users,
                                    ]);
                                    this.closeTeamModal();
                                }}
                                onModalDismiss={() => this.closeTeamModal()}
                                entityId={this.state.entityId}
                            />
                            {/* Entity Admins */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_admin'
                                            )}
                                        </h4>
                                    </div>
                                    <div className="page-action">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() =>
                                                this.addNewTeam(
                                                    'entityAdminUsers'
                                                )
                                            }
                                        >
                                            {ADD_NEW}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns,
                                                    rows: this.generateTeamTable(
                                                        values.entityAdminUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityAdminUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Team */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_team'
                                            )}
                                        </h4>
                                    </div>
                                    <div className="page-action">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() =>
                                                this.addNewTeam(
                                                    'entityTeamUsers'
                                                )
                                            }
                                        >
                                            {ADD_NEW}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns,
                                                    rows: this.generateTeamTable(
                                                        values.entityTeamUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityTeamUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Management Team */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_management_team'
                                            )}
                                        </h4>
                                    </div>
                                    <div className="page-action">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() =>
                                                this.addNewTeam(
                                                    'entityManagementUsers'
                                                )
                                            }
                                        >
                                            {ADD_NEW}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns,
                                                    rows: this.generateTeamTable(
                                                        values.entityManagementUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityManagementUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Consultant Team */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_consultant_team'
                                            )}
                                        </h4>
                                    </div>
                                    <div className="page-action">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() =>
                                                this.addNewTeam(
                                                    'entityConsultantUsers'
                                                )
                                            }
                                        >
                                            {ADD_NEW}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns,
                                                    rows: this.generateTeamTable(
                                                        values.entityConsultantUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityConsultantUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity HoD  */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>{__t('form_labels.entity.hod')}</h4>
                                    </div>
                                    <div className="page-action">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() => this.addNewHod()}
                                        >
                                            {ADD_NEW}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <AddEditHodFormModal
                                                companyGroupId={values.companyGroupId}
                                                isModalOpen={
                                                    this.state.isHodModalOpen
                                                }
                                                operationType={
                                                    this.state.hodOperationType
                                                }
                                                hodData={this.state.hodData}
                                                hodList={values.entityHod}
                                                departmentList={
                                                    values.entityHod
                                                }
                                                onFormValid={(finValues) => {
                                                    let hodDataList =
                                                        values.entityHod;
                                                    var updateDetailsIndex =
                                                        hodDataList.findIndex(
                                                            (opt) =>
                                                                opt.id ===
                                                                finValues.id
                                                        );
                                                    if (
                                                        updateDetailsIndex >
                                                        -1 &&
                                                        this.state.isHodEdit
                                                    ) {
                                                        hodDataList[
                                                            updateDetailsIndex
                                                        ] = finValues;
                                                        setFieldValue(
                                                            'entityHod',
                                                            hodDataList
                                                        );
                                                    } else {
                                                        this.state
                                                            .hodOperationType ===
                                                            SUBMIT
                                                            ? setFieldValue(
                                                                'entityHod',
                                                                [
                                                                    ...values.entityHod,
                                                                    ...finValues.departmentId.map(
                                                                        (
                                                                            x,
                                                                            index
                                                                        ) => ({
                                                                            departmentId:
                                                                                x,
                                                                            departmentName:
                                                                                finValues
                                                                                    .departmentName[
                                                                                index
                                                                                ],
                                                                            id: 0,
                                                                            userId: finValues.userId,
                                                                            userName:
                                                                                finValues.userName,
                                                                        })
                                                                    ),
                                                                ]
                                                            )
                                                            : setFieldValue(
                                                                'entityHod',
                                                                [
                                                                    ...values.entityHod,
                                                                    finValues,
                                                                ]
                                                            );
                                                    }
                                                    this.closeHodModal();
                                                }}
                                                onModalDismiss={() =>
                                                    this.closeHodModal()
                                                }
                                                entityId={this.state.entityId}
                                            />
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: hodListColumns,
                                                    rows: this.generateHodTable(
                                                        values.entityHod,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Emails */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.email_templates'
                                            )}
                                        </h4>
                                    </div>
                                    <div className="page-action">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm float-right"
                                            onClick={() =>
                                                this.addNewEmailTemplate()
                                            }
                                        >
                                            {ADD_NEW}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <AddEditEmailTemplateFormModal
                                                isModalOpen={
                                                    this.state
                                                        .isEmailTemplateModalOpen
                                                }
                                                operationType={
                                                    this.state
                                                        .emailTemplateOperationType
                                                }
                                                emailTemplateData={
                                                    this.state.emailTemplateData
                                                }
                                                emailTemplateList={
                                                    values.entityEmailTemplates
                                                }
                                                onFormValid={(
                                                    emailTemplateValues
                                                ) => {
                                                    let entityEmailTemplatesList =
                                                        values.entityEmailTemplates;
                                                    var updateDetailsIndex =
                                                        entityEmailTemplatesList.findIndex(
                                                            (opt) =>
                                                                opt.id ===
                                                                emailTemplateValues.id
                                                        );
                                                    if (
                                                        updateDetailsIndex >
                                                        -1 &&
                                                        this.state
                                                            .isEmailTemplateEdit
                                                    ) {
                                                        entityEmailTemplatesList[
                                                            updateDetailsIndex
                                                        ] = emailTemplateValues;
                                                        setFieldValue(
                                                            'entityEmailTemplates',
                                                            entityEmailTemplatesList
                                                        );
                                                    } else {
                                                        setFieldValue(
                                                            'entityEmailTemplates',
                                                            [
                                                                ...values.entityEmailTemplates,
                                                                emailTemplateValues,
                                                            ]
                                                        );
                                                    }
                                                    this.closeEntityEmailTemplateModal();
                                                }}
                                                onModalDismiss={() =>
                                                    this.closeEntityEmailTemplateModal()
                                                }
                                                entityId={this.state.entityId}
                                            />
                                            <CustomisedDatatable
                                                tableRecords={{
                                                    columns:
                                                        emailTemplateListColumns,
                                                    rows: this.generateEmailTemplateTable(
                                                        values.entityEmailTemplates,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >
                                                {this.state.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/entities'
                                                    );
                                                }}
                                            >
                                                {CANCEL}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
                <AuditLogModal
                    exportBtn={true}
                    key={'finYear'}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityFinancialYear'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
                <AuditLogModal
                    exportBtn={true}
                    key={'location'}
                    isModalOpen={this.state.isAuditLocationModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityLocation'}
                    auditId={this.state.auditLocationId}
                    onModalDismiss={() => this.closeAuditLogLocationModal()}
                />
                <AuditLogModal
                    exportBtn={true}
                    key={'entityEmailTemplate'}
                    isModalOpen={this.state.isAuditEmailTemplateModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityEmailTemplate'}
                    auditId={this.state.auditEmailTemplateId}
                    onModalDismiss={() =>
                        this.closeAuditLogEmailTemplateModal()
                    }
                />

                <AuditLogModal
                    exportBtn={true}
                    key={'entityDepartmentHod'}
                    isModalOpen={this.state.isAuditHodModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityDepartmentHod'}
                    auditId={this.state.auditHodId}
                    onModalDismiss={() => this.closeAuditLogHodModal()}
                />
                <DownloadFileByModel
                    modelType='entity'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedUserListDropdown: selectSortedUserList(),
    entityApiResponse: makeEntitySelectField('entityApiResponse'),
    editEntityApiResponse: makeEntitySelectField('editEntityApiResponse'),
    sortedRoleListDropDown: selectSortedRolesWithEntityRoleList(),
    fileUploadResponse: makeTaskSelectField('fileUploadResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getCompanyGroupListByStatusRequest,
    getUsersByEntityRequest,
    addEntityRequest,
    getEntityByEntityIdRequest,
    updateEntityRequest,
    getRolesWithEntityRoleByEntityListRequest,
    addFileUploadRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withUserReducer,
    withUserSaga,
    withEntityReducer,
    withEntitySaga,
    withEntityRoleReducer,
    withEntityRoleSaga,
    withTaskReducer,
    withTaskSaga,
    withRouter
)(EntityForm);
