import {
    GET_FUND_TASK_USERS_BY_ENTITY_REQUEST,
    GET_FUND_TASK_USERS_BY_ENTITY_RESPONSE,
    ADD_FUND_REQUEST,
    ADD_FUND_RESPONSE,
    GET_FUND_LIST_REQUEST,
    GET_FUND_LIST_RESPONSE,
    GET_FUND_DETAILS_REQUEST,
    GET_FUND_DETAILS_RESPONSE,
    UPDATE_FUND_REQUEST,
    UPDATE_FUND_RESPONSE,
    DELETE_FUND_BY_FUND_ID_REQUEST,
    DELETE_FUND_BY_FUND_ID_RESPONSE,
    GET_FUND_TASK_LIST_REQUEST,
    GET_FUND_TASK_LIST_RESPONSE,
    ADD_FUND_TASK_REQUEST,
    ADD_FUND_TASK_RESPONSE,
    UPDATE_FUND_TASK_REQUEST,
    UPDATE_FUND_TASK_RESPONSE,
    DELETE_FUND_TASK_BY_TASK_ID_REQUEST,
    DELETE_FUND_TASK_BY_TASK_ID_RESPONSE,
    ADD_FUND_SUB_TASK_REQUEST,
    ADD_FUND_SUB_TASK_RESPONSE,
    DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_REQUEST,
    DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_RESPONSE,
    GET_FUND_TASK_DETAILS_REQUEST,
    GET_FUND_TASK_DETAILS_RESPONSE,
    ADD_FUND_TASK_COMMENT_REQUEST,
    ADD_FUND_TASK_COMMENT_RESPONSE,
    ADD_FUND_TASK_ATTACHMENT_REQUEST,
    ADD_FUND_TASK_ATTACHMENT_RESPONSE,
    GET_FUND_TASK_ACTIVITY_REQUEST,
    GET_FUND_TASK_ACTIVITY_RESPONSE,
    DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST,
    DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE,
    CLOSE_FUND_REQUEST,
    CLOSE_FUND_RESPONSE,
    ACTIVE_OR_DEACTIVE_FUND_REQUEST,
    ACTIVE_OR_DEACTIVE_FUND_RESPONSE,
    GET_FUND_HEALTH_CHECK_REQUEST,
    GET_FUND_HEALTH_CHECK_RESPONSE,
    GET_FUND_CHECK_ACCESS_REQUEST,
    GET_FUND_CHECK_ACCESS_RESPONSE,
    UPDATE_FUND_TASK_SEQUENCE_ORDER_REQUEST,
    UPDATE_FUND_TASK_SEQUENCE_ORDER_RESPONSE,
    GET_FUND_TASK_COUNT_BY_FUND_ID_REQUEST,
    GET_FUND_TASK_COUNT_BY_FUND_ID_RESPONSE,
    RETRIEVE_FUND_BY_FUND_ID_REQUEST,
    RETRIEVE_FUND_BY_FUND_ID_RESPONSE
} from './actionTypes';

export const getFundTaskUsersByEntityRequest = (data) => {
    return {
        type: GET_FUND_TASK_USERS_BY_ENTITY_REQUEST,
        payload: { data: data },
    };
};

export const getFundTaskUsersByEntityResponse = (entityAllUsersList) => {
    return {
        type: GET_FUND_TASK_USERS_BY_ENTITY_RESPONSE,
        payload: { entityAllUsersList },
    };
};

export const addFundRequest = (data) => {
    return {
        type: ADD_FUND_REQUEST,
        payload: { data: data },
    };
};

export const addFundResponse = (message, responseType) => {
    return {
        type: ADD_FUND_RESPONSE,
        payload: { message, responseType },
    };
};

export const getFundListRequest = (data) => {
    return {
        type: GET_FUND_LIST_REQUEST,
        payload: { data: data },
    };
};

export const getFundListResponse = (fundList) => {
    return {
        type: GET_FUND_LIST_RESPONSE,
        payload: { fundList },
    };
};

export const getFundDetailsRequest = (fundDetailsId) => {
    return {
        type: GET_FUND_DETAILS_REQUEST,
        payload: { fundDetailsId },
    };
};

export const getFundDetailsResponse = (message, responseType, data) => {
    return {
        type: GET_FUND_DETAILS_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const updateFundRequest = (fundId, data) => {
    return {
        type: UPDATE_FUND_REQUEST,
        payload: { fundId, data },
    };
};

export const updateFundResponse = (message, responseType) => {
    return {
        type: UPDATE_FUND_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteFundByFundIdRequest = (fundId) => {
    return {
        type: DELETE_FUND_BY_FUND_ID_REQUEST,
        payload: { fundId },
    };
};

export const deleteFundByFundIdResponse = (message, responseType) => {
    return {
        type: DELETE_FUND_BY_FUND_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getFundTaskListRequest = (data) => {
    return {
        type: GET_FUND_TASK_LIST_REQUEST,
        payload: { data: data },
    };
};

export const getFundTaskListResponse = (message, responseType, data) => {
    return {
        type: GET_FUND_TASK_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const addFundTaskRequest = (data) => {
    return {
        type: ADD_FUND_TASK_REQUEST,
        payload: { data: data },
    };
};

export const addFundTaskResponse = (message, responseType) => {
    return {
        type: ADD_FUND_TASK_RESPONSE,
        payload: { message, responseType },
    };
};

export const updateFundTaskRequest = (taskId, data) => {
    return {
        type: UPDATE_FUND_TASK_REQUEST,
        payload: { taskId, data },
    };
};

export const updateFundTaskResponse = (message, responseType) => {
    return {
        type: UPDATE_FUND_TASK_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteFundTaskByTaskidRequest = (taskId) => {
    return {
        type: DELETE_FUND_TASK_BY_TASK_ID_REQUEST,
        payload: { taskId },
    };
};

export const deleteFundTaskByTaskidResponse = (message, responseType) => {
    return {
        type: DELETE_FUND_TASK_BY_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const addFundSubTaskRequest = (taskId, data) => {
    return {
        type: ADD_FUND_SUB_TASK_REQUEST,
        payload: { taskId, data },
    };
};

export const addFundSubTaskResponse = (message, responseType) => {
    return {
        type: ADD_FUND_SUB_TASK_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteFundSubTaskBySubTaskIdRequest = (subTaskId) => {
    return {
        type: DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_REQUEST,
        payload: { subTaskId },
    };
};

export const deleteFundSubTaskBySubTaskIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getFundTaskDetailsRequest = (taskId) => {
    return {
        type: GET_FUND_TASK_DETAILS_REQUEST,
        payload: { taskId },
    };
};

export const getFundTaskDetailsResponse = (taskDetails) => {
    return {
        type: GET_FUND_TASK_DETAILS_RESPONSE,
        payload: { taskDetails },
    };
};

export const addFundTaskCommentRequest = (taskId, data) => {
    return {
        type: ADD_FUND_TASK_COMMENT_REQUEST,
        payload: { taskId, data },
    };
};

export const addFundTaskCommentResponse = (message, responseType) => {
    return {
        type: ADD_FUND_TASK_COMMENT_RESPONSE,
        payload: { message, responseType },
    };
};

export const addFundTaskAttachmentRequest = (data) => {
    return {
        type: ADD_FUND_TASK_ATTACHMENT_REQUEST,
        payload: { data },
    };
};

export const addFundTaskAttachmentResponse = (message, responseType) => {
    return {
        type: ADD_FUND_TASK_ATTACHMENT_RESPONSE,
        payload: { message, responseType },
    };
};

export const getFundTaskActivityRequest = (taskId) => {
    return {
        type: GET_FUND_TASK_ACTIVITY_REQUEST,
        payload: { taskId },
    };
};

export const getFundTaskActivityResponse = (message, data, responseType) => {
    return {
        type: GET_FUND_TASK_ACTIVITY_RESPONSE,
        payload: { message, data, responseType },
    };
};

export const deleteFundTaskDocumentByDocumentIdRequest = (documentId) => {
    return {
        type: DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST,
        payload: { documentId },
    };
};

export const deleteFundTaskDocumentByDocumentIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const closeFundRequest = (id) => {
    return {
        type: CLOSE_FUND_REQUEST,
        payload: { id },
    };
};

export const closeFundResponse = (
    message,
    responseType
) => {
    return {
        type: CLOSE_FUND_RESPONSE,
        payload: { message, responseType },
    };
};

export const activeOrDeactiveFundRequest = (id, data) => {
    return {
        type: ACTIVE_OR_DEACTIVE_FUND_REQUEST,
        payload: { id, data },
    };
};

export const activeOrDeactiveFundResponse = (
    message,
    responseType
) => {
    return {
        type: ACTIVE_OR_DEACTIVE_FUND_RESPONSE,
        payload: { message, responseType },
    };
};

export const getFundHealthCheckRequest = (data) => {
    return {
        type: GET_FUND_HEALTH_CHECK_REQUEST,
        payload: { data: data },
    };
};

export const getFundHealthCheckResponse = (message, responseType, data) => {
    return {
        type: GET_FUND_HEALTH_CHECK_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const getFundCheckAccessRequest = (data) => {
    return {
        type: GET_FUND_CHECK_ACCESS_REQUEST,
        payload: { data: data },
    };
};

export const getFundCheckAccessResponse = (message, responseType, data) => {
    return {
        type: GET_FUND_CHECK_ACCESS_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const updateFundTaskSequenceOrderRequest = (data) => {
    return {
        type: UPDATE_FUND_TASK_SEQUENCE_ORDER_REQUEST,
        payload: { data },
    };
};

export const updateFundTaskSequenceOrderResponse = (message, responseType) => {
    return {
        type: UPDATE_FUND_TASK_SEQUENCE_ORDER_RESPONSE,
        payload: { message, responseType },
    };
};

export const getFundTaskCountByFundIdRequest = (fundId) => {
    return {
        type: GET_FUND_TASK_COUNT_BY_FUND_ID_REQUEST,
        payload: { fundId },
    };
};

export const getFundTaskCountByFundIdResponse = (message, responseType, data) => {
    return {
        type: GET_FUND_TASK_COUNT_BY_FUND_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};


export const retrieveFundByFundIdRequest = (fundId) => {
    return {
        type: RETRIEVE_FUND_BY_FUND_ID_REQUEST,
        payload: { fundId },
    };
};

export const retrieveFundByFundIdResponse = (message, responseType) => {
    return {
        type: RETRIEVE_FUND_BY_FUND_ID_RESPONSE,
        payload: { message, responseType },
    };
};
