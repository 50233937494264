import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Short Name of Law/Regulation',
        field: 'lawShortName',
    },
    {
        label: 'Document Name',
        field: 'documentName',
    },
    {
        label: 'Document Type',
        field: 'documentTypeName',
    },
    {
        label: 'Reference No',
        field: 'referenceNo',
    },
    {
        label: 'Date Of Publication',
        field: 'dateOfPublication',
    },
    {
        label: 'Effective Date',
        field: 'effectiveDate',
    },

    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialLawDocumentDataState = () => {
    return {
        // lawId: '',
        // documentTypeId: '',
        // documentName: '',
        // referenceNo: '',
        // dateOfPublication: '',
        // effectiveDate: '',
        // descriptionOfTheDocument: '',
        // briefNotes: '',
        documentFile: '',
        // isActive: ACTIVE
    };
};
export const initialMultipleDocumentDataState = () => {
    return {
        formData: { files: null },
        lawId: '',
    };
};

export const intFields = ['lawId', 'documentTypeId'];
export const nullableFields = [
    'referenceNo',
    'effectiveDate',
    'dateOfPublication',
    'descriptionOfTheDocument',
    'briefNotes',
];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
