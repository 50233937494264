import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class NotAllowedInIframe extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
            <React.Fragment>
                <div className='error_wrapper'>
                    <Row className='error_main'>
                        <Col lg={5} className="errorcontent">
                            <article>
                                <h1>OOPS! Not Allowed In Iframe...</h1>
                            </article>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});
export default compose(withConnect, withRouter)(NotAllowedInIframe);
