import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADD_NEW } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getNewsListRequest,
    activateOrDeactivateNewsRequest,
    publishNewsByNewsIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import newsSaga from 'store/News/saga';
import newsReducer from 'store/News/reducer';
import { listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { confirmBox, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_NEWS_ALERTS,
    PERMISSION_ADD,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import { makeNewsSelectField } from 'store/News/selector';
import ShowMoreText from 'react-show-more-text';
import * as authConstants from 'constants/authConstants';
import { getValueByKey } from 'helpers/authUtils';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';

const newsKey = 'news';
const withNewsReducer = injectReducer({
    key: newsKey,
    reducer: newsReducer,
});
const withNewsSaga = injectSaga({ key: newsKey, saga: newsSaga });
class MainIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsList: [],
            isAuditModalOpen: false,
            auditId: '',
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        this.props.getNewsListRequest();
    }
    editNewsDetails = (record) => {
        this.props.history.push('/legal-watch-dog/manage/edit/' + record.id);
    };
    addNewsDetails = () => {
        this.props.history.push('/legal-watch-dog/manage/add');
    };
    onStatusChange(checked, e, id) {
        this.props.activateOrDeactivateNewsRequest(checked, id);
    }
    publishNews = async (newsId) => {
        const response = await confirmBox(
            __t('common.are_you_sure'),
            __t('alerts.news_publish')
        );
        if (response) {
            this.props.publishNewsByNewsIdRequest(newsId);
        }
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let newsRecords = [];
        newsRecords = records.map((newsData, i) => {
            let actions = (
                <div className="text-right text-nowrap">
                    {checkModulePermission(
                        MODULE_NEWS_ALERTS,
                        PERMISSION_EDIT
                    ) === true &&
                        newsData.isPublish === false &&
                        newsData.isActive === true &&
                        parseInt(newsData.createdBy) ===
                        this.state.loginUserId && (
                            <a
                                href={() => false}
                                title={__t('common.publish')}
                                className="btn btn-link text-warning tbl-action"
                                onClick={() => this.publishNews(newsData.id)}
                            >
                                <i className="far fa-paper-plane"></i>
                            </a>
                        )}

                    {checkModulePermission(
                        MODULE_NEWS_ALERTS,
                        PERMISSION_EDIT
                    ) === true &&
                        newsData.isPublish === false &&
                        parseInt(newsData.createdBy) ===
                        this.state.loginUserId && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => this.editNewsDetails(newsData)}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-info tbl-action"
                        onClick={() => {
                            this.props.history.push(
                                '/legal-watch-dog/view-details/' + newsData.id
                            );
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(newsData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(newsData.id)}
                        checked={newsData.isActive ? true : false}
                    // disabled={
                    //     parseInt(newsData.createdBy) !==
                    //     this.state.loginUserId
                    // }
                    />
                    &nbsp;
                </div>
            );
            return {
                isActive: isActive,
                actions: actions,
                releaseType: newsData?.releaseType?.releaseTypeName,
                newsCode: newsData.newsCode,
                country: newsData.country.countryName,
                state: newsData.state ? newsData.state?.stateName : '',
                newsTitle: newsData.newsTitle,
                lawCategory: newsData.lawCategory.lawCategoryName,
                publicationDate: newsData.publicationDate,
            };
        });
        return newsRecords;
    };

    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.newsList && this.props.newsList !== nextprops.newsList) {
            this.setState({
                newsList: this.generateTable(nextprops.newsList),
            });
        }
        if (
            nextprops.newsStatusUpdateApiResponse &&
            this.props.newsStatusUpdateApiResponse !==
            nextprops.newsStatusUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.newsStatusUpdateApiResponse.message,
                nextprops.newsStatusUpdateApiResponse.responseType
            );
        }
        if (
            nextprops.publishNewsApiResponse &&
            this.props.publishNewsApiResponse !==
            nextprops.publishNewsApiResponse
        ) {
            toastrMessage(
                nextprops.publishNewsApiResponse.message,
                nextprops.publishNewsApiResponse.responseType
            );
            this.props.getNewsListRequest();
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.newsList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card className="mb-2">
                            <CardHeader>
                                <div className="page-header">
                                    <h4>{__t('page_headers.news_list')}</h4>
                                </div>
                                <div className="page-action">
                                    {(checkModulePermission(
                                        MODULE_NEWS_ALERTS,
                                        PERMISSION_ADD
                                    ) === true ||
                                        checkModulePermission(
                                            MODULE_NEWS_ALERTS,
                                            PERMISSION_EDIT
                                        ) === true) && (
                                            <a
                                                href={() => false}
                                                className="btn btn-primary waves-effect waves-light float-right ml-3"
                                                onClick={() => {
                                                    this.addNewsDetails();
                                                }}
                                            >
                                                <i className="fas fa-plus"> </i>{' '}
                                                {ADD_NEW}
                                            </a>
                                        )}
                                </div>
                            </CardHeader>
                            <CardBody className="legal_watch_dog_table">

                                <CustomisedMDBDatatablePage
                                    activepage={this.state.activePage}
                                    onHandlePageChange={this.handlePageChange}
                                    tableRecords={data}
                                    sortRows={['newsTitle']}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <AuditLogModal
                    exportBtn={true}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'news'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    newsList: makeNewsSelectField('newsList'),
    newsStatusUpdateApiResponse: makeNewsSelectField(
        'newsStatusUpdateApiResponse'
    ),
    publishNewsApiResponse: makeNewsSelectField('publishNewsApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    activateOrDeactivateNewsRequest,
    getNewsListRequest,
    publishNewsByNewsIdRequest,
});
export default compose(
    withConnect,
    withNewsReducer,
    withNewsSaga,
    withRouter
)(MainIndex);
