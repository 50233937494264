import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'City Name',
        field: 'cityName',
    },
    {
        label: 'State Name',
        field: 'stateName',
    },   
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialCityDataState = () => {
    return {
        cityName: '',
        stateId: '',
        isActive: ACTIVE
    }
}
export const intFields = ['stateId'];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};