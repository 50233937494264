import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import __t from 'i18n/translator';
import { initialTaskCommentState } from './CommonFunctions';
import {
    customDateValidator,
    getDateTimeFromDateTimeString,
} from 'helpers/dateTimeHelper';
import 'react-datepicker/dist/react-datepicker.css';
import {
    addProjectTaskCommentRequest,
    getProjectTaskActivityRequest,
} from 'store/actions';
import { makeProjectsSelectField } from 'store/Masters/Projects/selector';

class CommentAndList extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            commentFormData: initialTaskCommentState(),
            taskActivityList: [],
            userId: '',
        };
    }
    componentDidMount() {
        if (this.props.taskId) {
            this.getProjectTaskActivityByTaskId(this.props.taskId);
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.addProjectTaskCommentRequest(this.props.taskId, values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.projectTaskCommentApiResponse &&
            this.props.projectTaskCommentApiResponse !==
            nextprops.projectTaskCommentApiResponse
        ) {
            if (this.props.taskId) {
                this.getProjectTaskActivityByTaskId(this.props.taskId);
            }
            this.onModalDismiss();
            this.props.projectTaskApi(true);
        }
        if (
            nextprops.projectTaskActivityApiResponse &&
            this.props.projectTaskActivityApiResponse !==
            nextprops.projectTaskActivityApiResponse
        ) {
            let userId = localStorage.getItem('userId');
            this.setState({
                taskActivityList:
                    nextprops.projectTaskActivityApiResponse?.filter(
                        (x) => x.projectActivityTypeId === 11
                    ),
                userId: userId ? userId : '',
            });
        }
    }
    onModalDismiss = () => {
        this.validator.hideMessages();
        this.setState(
            {
                commentFormData: '',
            },
            () => {
                this.setState({
                    commentFormData: initialTaskCommentState(),
                });
            }
        );
    };

    getProjectTaskActivityByTaskId(taskId) {
        this.props.getProjectTaskActivityRequest(taskId);
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        {this.state.taskActivityList?.map((activity) => (
                            <>
                                <div className="list-group">
                                    <a href={() => false} className="list-group-item list-group-item-action flex-column align-items-start ">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">
                                                {this.state.userId ==
                                                    activity.createdBy
                                                    ? 'You'
                                                    : activity.user.fullName}
                                            </h5>
                                            <small>
                                                {getDateTimeFromDateTimeString(
                                                    activity.createdAt
                                                )}
                                            </small>
                                        </div>
                                        <p className="mb-1">
                                            {activity?.remarks}
                                        </p>
                                        <small>
                                            {
                                                activity.projectActivityTypes
                                                    ?.activityTypeName
                                            }
                                        </small>
                                    </a>
                                </div>
                            </>
                        ))}
                    </Col>

                    <Col lg="12">
                        <Formik
                            initialValues={this.state.commentFormData}
                            enableReinitialize={true}
                            onSubmit={this.formSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>

                                    <Row>
                                        <FormGroup className="col-lg-12">
                                            <Field
                                                name="remarks"
                                                className="form-control"
                                                value={values.remarks}
                                                placeholder={__t(
                                                    'form_labels.project_task.title'
                                                )}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.project_task.comment'
                                                )}
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.project_task.comment'
                                                ),
                                                values?.remarks,
                                                'required',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.project_task.title_required'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>

                                        <FormGroup className="col-lg-3">
                                            <Button
                                                type="submit"
                                                color="link"
                                                className="send-btn"
                                            >
                                                <svg
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="m6 12-3 9 18-9L3 3l3 9zm0 0h6"
                                                    />
                                                </svg>
                                            </Button>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    projectTaskCommentApiResponse: makeProjectsSelectField(
        'projectTaskCommentApiResponse'
    ),
    projectTaskActivityApiResponse: makeProjectsSelectField(
        'projectTaskActivityApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    addProjectTaskCommentRequest,
    getProjectTaskActivityRequest,
});

export default compose(withConnect, withRouter)(CommentAndList);
