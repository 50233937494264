import produce from 'immer';
import { GET_AUDIT_LIST_RESPONSE } from './actionTypes';
export const initialState = {
    auditList: []
};
const audit = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case GET_AUDIT_LIST_RESPONSE:
                draft.auditList = action.payload.auditList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default audit;
