import { ACTIVE } from 'constants/commonConstants';
import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Entity Name',
        field: 'entityName',
    },
    {
        label: 'Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Register Email ID',
        field: 'email',
    },
    {
        label: 'Contact No',
        field: 'contactNo',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialEntityDataState = () => {
    return {
        companyGroupId: '', //ENTITY_GROUP_NONE,
        entityName: '',
        email: '',
        contactNo: '',
        entityAdmin: [],
        entityTeam: [],
        entityManagementTeam: [],
        entityConsultantTeam: [],
        entityHod: [],
        entityAdminUsers: [],
        entityManagementUsers: [],
        entityTeamUsers: [],
        entityConsultantUsers: [],
        entityHodUsers: [],
        isActive: ACTIVE,
        entityFinancialYears: [],
        entityLocations: [],
        entityEmailTemplates: [],
        removeFinancialYears: [],
        removeEntityLocations: [],
        removeEntityHod: [],
        removeEntityEmailTemplates: [],
    };
};
export const initialEntityFinancialYearsDataState = () => {
    return {
        id: 0,
        countryName: '',
        countryId: '',
        startMonth: '',
        endMonth: '',
    };
};
export const initialEntityLocationsDataState = () => {
    return {
        id: 0,
        countryName: '',
        countryId: '',
        stateName: '',
        stateId: '',
        cityName: '',
        cityId: '',
        pincode: '',
        registrationNumber: '',
        registrationDate: '',
        // isActive: ACTIVE
    };
};

export const initialEntityEmailTemplatesDataState = () => {
    return {
        id: 0,
        emailTemplateTypeId: '',
        toRoles: [],
        ccRoles: [],
        daysFirstNotification: 0,
        daysSecondNotification: 0,
        isActive: ACTIVE,
    };
};
export const initialHodDataDataState = () => {
    return {
        id: 0,
        departmentId: [],
        userId: '',
    };
};
export const initialTeamDataState = () => {
    return {
        users: [],
    };
};
export const intFields = ['companyGroupId'];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
export const finListColumns = [
    {
        label: 'Country',
        field: 'countryName',
        sort: 'disabled',
    },
    {
        label: 'Start Month',
        field: 'startMonth',
        sort: 'disabled',
    },
    {
        label: 'End Month',
        field: 'endMonth',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const locListColumns = [
    {
        label: 'Country',
        field: 'countryName',
        sort: 'disabled',
    },
    {
        label: 'State',
        field: 'stateName',
        sort: 'disabled',
    },
    {
        label: 'City',
        field: 'cityName',
        sort: 'disabled',
    },
    {
        label: 'Registration Number',
        field: 'registrationNumber',
        sort: 'disabled',
    },
    {
        label: 'Registration Date',
        field: 'registrationDate',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const teamColumns = [
    {
        label: 'Full Name',
        field: 'fullName',
        sort: 'disabled',
    },
    {
        label: 'Email',
        field: 'email',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];

export const hodListColumns = [
    {
        label: 'Department',
        field: 'departmentName',
    },
    {
        label: 'User',
        field: 'userName',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];

export const emailTemplateListColumns = [
    {
        label: 'Email Template',
        field: 'emailTemplateType',
        sort: 'disabled',
    },
    {
        label: 'To Roles',
        field: 'toRoles',
        sort: 'disabled',
    },
    {
        label: 'CC Roles',
        field: 'ccRoles',
        sort: 'disabled',
    },
    {
        label: 'Days 1st Notification',
        field: 'daysFirstNotification',
        sort: 'disabled',
    },
    {
        label: 'Days 2nd Notification',
        field: 'daysSecondNotification',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
