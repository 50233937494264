import React, { Component } from 'react';
import __t from 'i18n/translator';
import { Button, Popover, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';

class CommonSectionRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopoverId: null
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {

    }
    togglePopover = (id) => {
        if (this.state.openPopoverId === id) {
            this.setState({
                openPopoverId: null // Close the popover if it's already open
            });
        } else {
            this.setState({
                openPopoverId: id // Open the selected popover
            });
        }
    };

    render() {
        let listData = (this.props.type === 'section') ? this.props.sectionInfo : this.props.ruleInfo;
        let list = (this.props.type === 'section') ? this.props.sectionReference.split(';') : this.props.ruleReference.split(';');
        return (
            <React.Fragment>
                {list.length > 0 && list.map((title, key) => {
                    const popoverId = this.props.type + 'Popover' + key;
                    let listItem = listData.find((l) => l?.sectionName.trim() + '-' + l?.lawShortName?.trim() === title.trim());
                    return (
                        <React.Fragment key={key}>
                            <Button color='link' className='py-0 px-1 mr-1 mb-1 section-dec-btn' id={popoverId} type="button" title='Click here to view section description' onClick={() => {
                                if (listItem) {
                                    return this.togglePopover(popoverId)
                                } else {
                                    return false;
                                }
                            }}>
                                {title.trim()}
                            </Button>
                            {listItem && (
                                <Popover
                                    className='section-description-popover'
                                    placement="bottom"
                                    isOpen={this.state.openPopoverId === popoverId}
                                    target={popoverId}
                                    toggle={() => this.togglePopover(popoverId)}
                                >
                                    <PopoverHeader>{listItem?.lawShortName?.trim()}  <Button color='link' type="button" className='p-0' aria-label="Close" onClick={() => this.togglePopover(popoverId)}>&times;</Button></PopoverHeader>
                                    <PopoverBody>{listItem?.sectionDescription?.trim()}</PopoverBody>
                                </Popover>
                            )}
                        </React.Fragment>
                    )

                })}
            </React.Fragment>
        );
    }
}
export default CommonSectionRule;