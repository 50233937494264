import { compareString } from 'helpers/generalUtils';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.chat || initialState;
const makeChatSelectField = field =>
    createSelector(reducerState, chatState => chatState[field]);

const selectSortedTaskUserList = () =>
    createSelector(makeChatSelectField('taskUserList'), taskUserList =>
        taskUserList
            .map(opt => ({
                value: opt.id,
                label: opt.fullName + "-" + opt.email,
                fullName: opt.fullName,
                email: opt.email
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );

export {
    makeChatSelectField,
    selectSortedTaskUserList
};