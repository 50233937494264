import React, { Component } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import {
    getPlanEventDetailsByPlanEventIdRequest,
    getTaskUserDetailsByTaskIdRequest,
    updateUserDetailsByTaskIdRequest
} from 'store/actions';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { PENDING_TO_TRIGGERED, SUCCESS } from 'constants/commonConstants';
import { makeEventAllocationSelectField } from 'store/EventAllocation/selector';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import ViewTaskUser from './ViewTaskUser';
import { makeTaskSelectField } from 'store/Task/selector';
import UpdateUserDetailsModal from './UpdateUserDetailsModal';
import { toastrMessage } from 'helpers/messageHelper';
import { checkModulePermission } from 'helpers/projectUtils';
import { MODULE_ONGOING_EVENTS, PERMISSION_EDIT } from 'constants/databaseConstants';

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State',
        field: 'stateName',
    },
    {
        label: 'City/Location',
        field: 'cityName',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Status',
        field: 'taskStatusId',
    }
];
class ViewEventTasksModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            eventId: '',
            taskList: [],
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
            taskDetails: {},
            isUpdateUserDetailsModalOpen: false
        }
    }

    openUpdateUserDetailsModal = (data) => {
        this.setState({
            isUpdateUserDetailsModalOpen: true,
            taskDetails: {
                id: data.id,
                taskCode: data.taskCode,
                entityId: data.complianceAllocation?.entity?.id,
                taskStatusId: data.taskStatusId,
                submitterId: data.submitterId,
                departmentId: data.taskDepartmentIds,
                firstApproverUserId: data.firstApproverUserId,
                secondApproverUserId: data.secondApproverUserId,
                parentEventTaskStatusId: data.parentEventTask?.taskStatusId,
                eventDueDateFormula: (data.complianceAllocation?.lawCompliance?.eventDueDateFormula == "" || data.complianceAllocation?.lawCompliance?.eventDueDateFormula == null) ? false : true
            }
        });
    };
    closeUpdateUserDetailsModal = () => {
        this.setState({
            isUpdateUserDetailsModalOpen: false,
            taskDetails: {}
        });
    };
    closeViewTaskUserModal() {
        this.setState({
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
        });
    }
    generateTable = records => {
        let taskRecords = [];
        taskRecords = records.map((taskData, i) => {
            let action = (
                <a
                    href={() => false}
                    onClick={() => {
                        window.open('/statutory-compliances/view-details/' + taskData.complianceAllocation.lawCompliance.id, '_blank');
                    }} className='text-nowrap'>{taskData?.complianceAllocation?.lawCompliance?.complianceCode}</a>
            );
            let infoVal = (
                <React.Fragment>
                    <div className='status_info'>
                        <span>{taskData.taskStatus?.statusName}</span>
                        <a
                            href={() => false}
                            onClick={() => {
                                this.props.getTaskUserDetailsByTaskIdRequest(taskData.id)
                            }}
                            className="text-primary"><i className="fas fa-info-circle"></i></a>
                    </div>

                </React.Fragment>
            );
            let iconVal = ((taskData.submitterId == '' || taskData.submitterId == null) || (taskData.firstApproverUserId == '' || taskData.firstApproverUserId == null) || (taskData.taskDepartmentIds == '' || taskData.taskDepartmentIds == null)) ? <i className="fas fa-user-plus"></i> : <i className="fas fa-user-check"></i>

            let actionVal = (
                <React.Fragment>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.openUpdateUserDetailsModal(taskData)
                        }}
                        className="text-primary user-info">{iconVal}</a>
                </React.Fragment>
            );
            let cTitle = (taskData.complianceVersionLawComplianceTitle) ? taskData.complianceVersionLawComplianceTitle : taskData.complianceAllocation?.lawCompliance?.complianceTitle;
            return {
                taskCode: taskData.taskCode,
                complianceCode: action,
                countryName: taskData.complianceAllocation?.entityLocation?.country?.countryName,
                stateName: taskData.complianceAllocation?.entityLocation?.state?.stateName,
                cityName: taskData.complianceAllocation?.entityLocation?.city?.cityName,
                complianceTitle: (taskData.parentTaskId === null) ? cTitle : taskData.title,
                applicabilityCondition: taskData.complianceAllocation?.lawCompliance?.applicabilityCondition,
                dueDate: (taskData.dueDate == '' || taskData.dueDate == null) ? PENDING_TO_TRIGGERED : <Label searchvalue={taskData.dueDate}>{taskData.dueDate}</Label>,
                taskStatusId: (checkModulePermission(MODULE_ONGOING_EVENTS, PERMISSION_EDIT) && ((taskData.dueDate == '' || taskData.dueDate == null) || ((taskData.submitterId == '' || taskData.submitterId == null) || (taskData.firstApproverUserId == '' || taskData.firstApproverUserId == null) || (taskData.taskDepartmentIds == '' || taskData.taskDepartmentIds == null)))) ? actionVal : infoVal,
            };
        });
        return taskRecords;
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.eventData?.id && nextprops.eventData?.id !== this.props.eventData?.id) {
            this.setState({
                eventId: nextprops.eventData.id
            }, () => {
                this.props.getPlanEventDetailsByPlanEventIdRequest(this.state.eventId);
            })
        }
        if (nextprops.planEventDetailsApiResponse !== this.props.planEventDetailsApiResponse) {
            this.setState({
                taskList: (nextprops.planEventDetailsApiResponse.responseType === SUCCESS) ? this.generateTable(nextprops.planEventDetailsApiResponse.data.triggeredEvent?.allocationTasks) : []
            });
        }
        if (nextprops.taskUserDetailsApiResponse && nextprops.taskUserDetailsApiResponse !== this.props.taskUserDetailsApiResponse) {
            if (nextprops.taskUserDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    isViewTaskUserModalOpen: true,
                    viewTaskUserData: nextprops.taskUserDetailsApiResponse.data?.taskUserData
                })
            }
        }

        if (
            nextprops.updateUserDetailsApiResponse &&
            this.props.updateUserDetailsApiResponse !== nextprops.updateUserDetailsApiResponse
        ) {
            toastrMessage(
                nextprops.updateUserDetailsApiResponse.message,
                nextprops.updateUserDetailsApiResponse.responseType
            );
            if (nextprops.updateUserDetailsApiResponse.responseType === SUCCESS) {
                this.closeUpdateUserDetailsModal();
                this.props.getPlanEventDetailsByPlanEventIdRequest(this.state.eventId);
            }
        }
    }
    render() {
        const eventTaskData = {
            columns: listColumns,
            rows: this.state.taskList,
        };
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <Row>
                        <Col lg="12">
                            <CustomisedDatatable responsive striped
                                tableRecords={eventTaskData}
                            />
                        </Col>
                    </Row>
                    <UpdateUserDetailsModal
                        modalName={__t(
                            'form_labels.events.update_user_details'
                        ) + ' | ' + this.state.taskDetails?.taskCode}
                        isModalOpen={this.state.isUpdateUserDetailsModalOpen}
                        taskDetails={this.state.taskDetails}
                        onModalDismiss={() => this.closeUpdateUserDetailsModal()}
                        onFormValid={(values) => {
                            this.props.updateUserDetailsByTaskIdRequest(
                                values.id,
                                values
                            );
                        }}
                    />
                    <ViewTaskUser
                        modalName={__t('form_labels.reporting.view_task_user')}
                        isModalOpen={this.state.isViewTaskUserModalOpen}
                        taskUserData={this.state.viewTaskUserData}
                        onModalDismiss={() => this.closeViewTaskUserModal()}
                    />
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    planEventDetailsApiResponse: makeEventAllocationSelectField('planEventDetailsApiResponse'),
    taskUserDetailsApiResponse: makeTaskSelectField('taskUserDetailsApiResponse'),
    updateUserDetailsApiResponse: makeEventAllocationSelectField('updateUserDetailsApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getPlanEventDetailsByPlanEventIdRequest,
    getTaskUserDetailsByTaskIdRequest,
    updateUserDetailsByTaskIdRequest
});
export default compose(
    withConnect,
    withTaskReducer,
    withTaskSaga,
    withRouter
)(ViewEventTasksModal);