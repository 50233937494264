import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ENABLE_DELETE, SUBMIT, UPDATE, ACTIVE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllDepartmentListRequest,
    updateDepartmentStatusByDepartmentIdRequest,
    addDepartmentRequest,
    updateDepartmentRequest,
    deleteDepartmentByDepartmentIdRequest,
    getCompanyGroupListByStatusRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import { makeDepartmentSelectField } from 'store/Masters/Department/selector';
import { initialAddDepartmentDataState, initialDepartmentDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import AddDepartmentForm from './AddDepartmentForm';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_DEPARTMENT,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import EditDepartmentForm from './EditDepartmentForm';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector'
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';

const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            departmentList: [],
            exportDepartmentList: [],
            departmentData: initialAddDepartmentDataState(),
            editDepartmentData: initialDepartmentDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve department list
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getAllDepartmentListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateDepartmentStatusByDepartmentIdRequest(id, checked);
    }
    viewDepartment(departmentData) {
        this.setState(
            {
                editDepartmentData: initialDepartmentDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ editDepartmentData: departmentData });
            }
        );
    }
    deleteDepartment = async (departmentId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.department_delete')
        );
        if (response) {
            this.props.deleteDepartmentByDepartmentIdRequest(departmentId);
        }
    };
    formSubmit = (values) => {
        this.setState({ editDepartmentData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateDepartmentRequest(values.id, values);
        }
    };
    resetForm = () => {
        this.setState({
            departmentData: initialAddDepartmentDataState(),
            editDepartmentData: initialDepartmentDataState(),
            operationType: SUBMIT,
        });
    };
    formSubmitForAdd = (values) => {
        this.setState({ departmentData: values });
        this.props.addDepartmentRequest(values);
    };
    resetFormForAdd = () => {
        this.setState({
            editDepartmentData: initialDepartmentDataState(),
            departmentData: initialAddDepartmentDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let departmentRecords = [];
        departmentRecords = records.map((departmentData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(
                        MODULE_DEPARTMENT,
                        PERMISSION_EDIT
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewDepartment(departmentData);
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('department') &&
                        checkModulePermission(
                            MODULE_DEPARTMENT,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteDepartment(departmentData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(departmentData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(departmentData.id)}
                        checked={departmentData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            return {
                companyGroupName: departmentData?.companyGroup?.companyGroupName,
                departmentName: departmentData.departmentName,
                isActive: isActive,
                actions: actions,
            };
        });
        return departmentRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.departmentList &&
            this.props.departmentList !== nextprops.departmentList
        ) {
            this.setState({
                exportDepartmentList: nextprops.departmentList,
                departmentList: this.generateTable(nextprops.departmentList),
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.departmentList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'department'} />
                        </Col>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'page_headers.department_list'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage
                                        activepage={this.state.activePage}
                                        onHandlePageChange={this.handlePageChange}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {(checkModulePermission(
                            MODULE_DEPARTMENT,
                            PERMISSION_ADD
                        ) === true ||
                            checkModulePermission(
                                MODULE_DEPARTMENT,
                                PERMISSION_EDIT
                            ) === true) && (
                                <Col lg="5">
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'page_headers.department_form'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            {this.state.operationType == UPDATE && (
                                                <EditDepartmentForm
                                                    operationType={UPDATE}
                                                    departmentData={this.state.editDepartmentData}
                                                    operationResponse={this.props.departmentApiResponse}
                                                    onFormValid={this.formSubmit}
                                                    onFormReset={this.resetForm}
                                                    sortedCompanyGroupListDropdown={this.props.sortedCompanyGroupListDropdown}
                                                />
                                            )}
                                            {this.state.operationType == SUBMIT && (
                                                <AddDepartmentForm
                                                    operationType={SUBMIT}
                                                    departmentData={this.state.departmentData}
                                                    operationResponse={this.props.departmentApiResponse}
                                                    onFormValid={this.formSubmitForAdd}
                                                    onFormReset={this.resetFormForAdd}
                                                    sortedCompanyGroupListDropdown={this.props.sortedCompanyGroupListDropdown}
                                                />
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                        <AuditLogModal
                    exportBtn={true}
                            isModalOpen={this.state.isAuditModalOpen}
                            modalName={__t('page_headers.audit_trails')}
                            auditType={'department'}
                            auditId={this.state.auditId}
                            onModalDismiss={() => this.closeAuditLogModal()}
                        />
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    departmentList: makeDepartmentSelectField('departmentList'),
    departmentApiResponse: makeDepartmentSelectField('departmentApiResponse'),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList()
});
const withConnect = connect(mapStatetoProps, {
    getAllDepartmentListRequest,
    updateDepartmentStatusByDepartmentIdRequest,
    addDepartmentRequest,
    updateDepartmentRequest,
    deleteDepartmentByDepartmentIdRequest,
    getCompanyGroupListByStatusRequest
});
export default compose(
    withConnect,
    withDepartmentReducer,
    withDepartmentSaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withRouter
)(Index);
