import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Button,
    Label,
    Progress,
    Alert,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RESET, SUBMIT, SUCCESS } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form } from 'formik';
import { customFileValidator } from 'helpers/customValidators';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { createStructuredSelector } from 'reselect';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import {
    addLawComplianceRequest,
    getAllUploadedFilesRequest,
    deleteComplianceQueueFileByIdRequest
} from 'store/actions';
import { compose } from 'redux';
import Dropzone from 'react-dropzone';
import { formatBytes } from 'helpers/generalUtils';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { uploadColumns } from './CommonFunctions';
import {
    MODULE_STATUTORY_LAW_COMPLIANCES_UPLOAD,
    PERMISSION_DELETE,
    UPLOAD_COMPLETED,
    UPLOAD_ERROR,
    UPLOAD_INPROGRESS,
} from 'constants/databaseConstants';
import __t from 'i18n/translator';
import FileSaver from 'file-saver';
import { getFullDateTimeFromDateTimeString, getFullDateTimeFromDateTimeStringForSorting } from 'helpers/dateTimeHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import { checkModulePermission } from 'helpers/projectUtils';
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class UploadCompliance extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.state = {
            formData: { files: null },
            operationType: SUBMIT,
            selectedFiles: [],
            uploadedFiles: [],
            errors: [],
            fileProgress: [],
            uploadedFileList: [],
            apiCalling: false,
            isInterval: '',
            downloadFileUrl: '',
            downloadFileName: ''
        };
    }

    componentDidMount() {
        this.props.getAllUploadedFilesRequest();
    }

    async apiCalling() {
        if (this.props.location.pathname === '/statutory-compliances/upload') {
            try {
                this.setState({
                    isInterval: setInterval(async () => {
                        if (
                            this.props.location.pathname ===
                            '/statutory-compliances/upload'
                        ) {
                            this.props.getAllUploadedFilesRequest();
                        }
                    }, 15000), // 15 seconds
                });
            } catch (e) {
                console.log(e);
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.isInterval);
    }

    formSubmit = (values) => {
        const data = new FormData();
        if (this.state.selectedFiles.length > 0) {
            this.state.selectedFiles.forEach((file, i) => {
                if (!this.state.uploadedFiles.includes(file.name)) {
                    let items = [...this.state.fileProgress];
                    let itemIndex = items.findIndex(
                        (filedata) => filedata.fileName === file.name
                    );
                    items[itemIndex].percentage = 100;
                    this.setState(
                        {
                            fileProgress: items,
                        },
                        () => {
                            data.append('complianceFile', file);
                        }
                    );
                }
            });
            this.props.addLawComplianceRequest(data);
        } else {
            this.setState({ errors: ['Please select files to upload'] });
        }
    };
    resetForm = () => {
        this.setState({
            selectedFiles: [],
            uploadedFiles: [],
            errors: [],
            fileProgress: [],
        });
        this.validator.hideMessages();
    };
    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customFileValidator(file.name)) {
                errorsArray.push('Please select valid file');
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({ errors: ['Please select valid file'] });
        } else {
            files.map((file) => {
                // push new item to the state value
                this.state.fileProgress.push({
                    fileName: file.name,
                    percentage: 0,
                });
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size),
                });
            });
            this.setState({
                selectedFiles: files,
                errors: [],
            });
        }
    };
    removeFile = (file) => {
        var array = [...this.state.selectedFiles]; // make a separate copy of the array
        var index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ selectedFiles: array }, () => {
                if (this.state.selectedFiles.length === 0) {
                    this.setState({
                        uploadedFiles: [],
                        errors: [],
                        fileProgress: [],
                    });
                }
            });
        }
    };
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    deleteComplianceQueueFile = async (id) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.upload_delete')
        );
        if (response) {
            this.props.deleteComplianceQueueFileByIdRequest(id)
        }
    }
    generateTable = (records) => {
        let uploadedFileRecords = [];
        uploadedFileRecords = records.map((uploadedFileData, i) => {
            let actions = (
                <div>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.saveFile(uploadedFileData);
                        }}
                        title="Download Uploaded File"
                        className="btn btn-link tbl-action text-success"
                    >
                        <i className="fas fa-download"></i>
                    </a>
                    {uploadedFileData.status != 'COMPLETED' && checkModulePermission(
                        MODULE_STATUTORY_LAW_COMPLIANCES_UPLOAD,
                        PERMISSION_DELETE
                    ) === true &&
                        (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() =>
                                    this.deleteComplianceQueueFile(uploadedFileData.id)
                                    }
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                </div>
            );
            let statusClassName = '';
            let statusName = uploadedFileData.status;
            switch (uploadedFileData.status) {
                case UPLOAD_INPROGRESS:
                    statusClassName = 'primary';
                    statusName = 'IN PROGRESS';
                    break;
                case UPLOAD_COMPLETED:
                    statusClassName = 'success';
                    break;
                case UPLOAD_ERROR:
                    statusClassName = 'danger';
                    break;
                default:
                    statusClassName = 'primary';
                    break;
            }
            let status = (
                <Label
                    searchvalue={uploadedFileData.status}
                    className={`m-0 file-status badge badge-` + statusClassName}
                >
                    {statusName}
                </Label>
            );
            return {
                batchId: uploadedFileData.batchId,
                fileName: uploadedFileData.fileName,
                status: status,
                uploadedOn: <span searchvalue={getFullDateTimeFromDateTimeStringForSorting(uploadedFileData.createdAt)}>{getFullDateTimeFromDateTimeString(
                    uploadedFileData.createdAt
                )}</span>,
                uploadedBy: uploadedFileData.createdBy
                    ? uploadedFileData.createdBy
                    : '',
                actions: actions,
            };
        });
        return uploadedFileRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawComplianceApiResponse &&
            this.props.lawComplianceApiResponse !==
            nextprops.lawComplianceApiResponse
        ) {
            if (nextprops.lawComplianceApiResponse.responseType === SUCCESS) {
                this.resetForm();
                toastrMessage(
                    nextprops.lawComplianceApiResponse.message,
                    SUCCESS
                );
                this.props.getAllUploadedFilesRequest();
            } else {
                if (
                    nextprops.lawComplianceApiResponse?.data?.failedFiles
                        ?.length > 0
                ) {
                    let lstFailedFiles =
                        nextprops.lawComplianceApiResponse.data?.failedFiles;
                    this.state.selectedFiles.forEach((file) => {
                        let items = [...this.state.fileProgress];
                        let itemIndex = items.findIndex(
                            (filedata) => filedata.fileName === file.name
                        );
                        if (!lstFailedFiles.includes(file.name)) {
                            items[itemIndex].percentage = 100;
                            this.state.uploadedFiles.push(file.name);
                        } else {
                            items[itemIndex].percentage = 0;
                        }
                        this.setState({
                            fileProgress: items,
                        });
                    });
                }

                window.scrollTo(0, 0);
                toastrMessage(
                    nextprops.lawComplianceApiResponse.message,
                    nextprops.lawComplianceApiResponse.responseType
                );
            }
        }
        if (this.props.uploadedFileList !== nextprops.uploadedFileList) {
            if (
                nextprops.uploadedFileList.filter(
                    (x) => x.status == 'INPROGRESS'
                ).length > 0
            ) {
                if (!this.state.apiCalling) {
                    this.setState(
                        {
                            apiCalling: true,
                        },
                        () => {
                            this.apiCalling();
                        }
                    );
                }
            } else {
                this.setState({
                    apiCalling: false,
                });
                clearInterval(this.state.isInterval);
            }

            this.setState({
                uploadedFileList: this.generateTable(
                    nextprops.uploadedFileList
                ),
            });
        }
        if (
            nextprops.deleteComplianceQueueFileApiResponse &&
            this.props.deleteComplianceQueueFileApiResponse !==
            nextprops.deleteComplianceQueueFileApiResponse
        ) {
            this.props.getAllUploadedFilesRequest();
        }
    }
    render() {
        const data = {
            columns: uploadColumns,
            rows: this.state.uploadedFileList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'upload-compliance'} />
                    </Col>
                    <Col lg="12" className="mb-2">
                        <Alert
                            color="danger"
                            className="mb-0"
                            isOpen={
                                this.state.errors?.length > 0 ? true : false
                            }
                            toggle={() => this.setState({ errors: [] })}
                        >
                            <strong>Whoops! </strong>There were some problems
                            with your input.
                            <ul>
                                {this.state.errors.map((msg, i) => {
                                    if (msg !== '') {
                                        return <li key={i}>{msg}</li>;
                                    }
                                })}
                            </ul>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        {this.props.history.length != 1 && (
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/home'
                                                    );
                                                }}
                                            >
                                                { }
                                            </a>
                                        )}
                                        {__t('page_headers.upload_excel')}
                                    </h4>
                                    <Link
                                        to="/data/download_sample.xlsx"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary btn-sm"
                                        download
                                    >
                                        <i className="fas fa-download"></i>{' '}
                                        &nbsp;
                                        {__t(
                                            'form_labels.upload.download_sample_file'
                                        )}
                                    </Link>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={this.state.formData}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <Dropzone
                                                        onDrop={(
                                                            acceptedFiles
                                                        ) =>
                                                            this.handleAcceptedFiles(
                                                                acceptedFiles
                                                            )
                                                        }
                                                    >
                                                        {({
                                                            getRootProps,
                                                            getInputProps,
                                                        }) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input
                                                                        {...getInputProps()}
                                                                    />
                                                                    <h3>
                                                                        {__t(
                                                                            'form_labels.upload.drag_drop'
                                                                        )}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </Col>
                                                <Col className="col-md-auto mt-3">
                                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                                        <div className="d-inline-block">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                                tabIndex="35"
                                                            >
                                                                {
                                                                    this.state
                                                                        .operationType
                                                                }
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="secondary"
                                                                onClick={
                                                                    this
                                                                        .resetForm
                                                                }
                                                            >
                                                                {RESET}
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {this.state.selectedFiles
                                                    .length > 0 && (
                                                        <Col>
                                                            <div
                                                                className="dropzone-previews mt-3"
                                                                id="file-previews"
                                                            >
                                                                <Row>
                                                                    {this.state.selectedFiles.map(
                                                                        (f, i) => {
                                                                            let currentFileProgressData =
                                                                                this.state.fileProgress.find(
                                                                                    (
                                                                                        fileP
                                                                                    ) =>
                                                                                        fileP.fileName ===
                                                                                        f.name
                                                                                );
                                                                            return (
                                                                                <Col
                                                                                    key={
                                                                                        i +
                                                                                        '-file'
                                                                                    }
                                                                                    md="4"
                                                                                    className="animate__animated animate__fadeInRight"
                                                                                >
                                                                                    <div className="file-upload shadow-none border p-2">
                                                                                        <div className="file-upload-info mr-2">
                                                                                            <Link
                                                                                                to="#"
                                                                                                className="upload-document-title"
                                                                                            >
                                                                                                {
                                                                                                    f.name
                                                                                                }
                                                                                            </Link>
                                                                                            {!this.state.uploadedFiles.includes(
                                                                                                f.name
                                                                                            ) && (
                                                                                                    <div className="progress_bar">
                                                                                                        <Progress
                                                                                                            animated
                                                                                                            color="primary"
                                                                                                            style={{
                                                                                                                height: '1rem',
                                                                                                            }}
                                                                                                            value={
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .fileProgress[
                                                                                                                    i
                                                                                                                ]
                                                                                                                    .percentage
                                                                                                            }
                                                                                                        ></Progress>
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                        {currentFileProgressData?.percentage ===
                                                                                            0 && (
                                                                                                <Button
                                                                                                    className="btn btn-danger btn-sm"
                                                                                                    title="Delete file"
                                                                                                    type="button"
                                                                                                    onClick={() =>
                                                                                                        this.removeFile(
                                                                                                            f
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={
                                                                                                        currentFileProgressData?.percentage >
                                                                                                            0
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                >
                                                                                                    <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                                    </svg>
                                                                                                </Button>
                                                                                            )}
                                                                                        {this.state.uploadedFiles.includes(
                                                                                            f.name
                                                                                        ) && (
                                                                                                <Button
                                                                                                    className="btn btn-success btn-sm"
                                                                                                    title="Delete file"
                                                                                                    type="button"
                                                                                                >
                                                                                                    <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                                    </svg>
                                                                                                </Button>
                                                                                            )}
                                                                                    </div>
                                                                                </Col>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    )}
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                    {this.state.uploadedFileList?.length > 0 && (
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CustomisedDatatable
                                        sortRows={['status', 'uploadedOn']}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
                <DownloadFileByModel
                    modelType='complianceQueue'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawComplianceApiResponse: makeLawComplianceSelectField(
        'lawComplianceApiResponse'
    ),
    uploadedFileList: makeLawComplianceSelectField('uploadedFileList'),
    deleteComplianceQueueFileApiResponse: makeLawComplianceSelectField('deleteComplianceQueueFileApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    addLawComplianceRequest,
    getAllUploadedFilesRequest,
    deleteComplianceQueueFileByIdRequest
});
export default compose(
    withConnect,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(UploadCompliance);
