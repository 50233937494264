import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Role Term',
        field: 'roleTerm',
    },
    {
        label: 'Entity Name',
        field: 'entityName',
    },
    {
        label: 'Role Name',
        field: 'roleName',
    },
    {
        label: 'Active',
        attributes: {
          className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialEntityRoleDataState = () => {
    return {
        entityId: '',
        roleId: '',
        roleTerm: '',
        modules: '',
        isActive: ACTIVE
    }
}
export const intFields = ['entityId', 'roleId'];
export const nullableFields = ['modules'];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};