import produce from 'immer';
import {
    GET_FUND_TASK_USERS_BY_ENTITY_RESPONSE,
    ADD_FUND_RESPONSE,
    GET_FUND_LIST_RESPONSE,
    GET_FUND_DETAILS_RESPONSE,
    UPDATE_FUND_RESPONSE,
    DELETE_FUND_BY_FUND_ID_RESPONSE,
    GET_FUND_TASK_LIST_RESPONSE,
    ADD_FUND_TASK_RESPONSE,
    UPDATE_FUND_TASK_RESPONSE,
    DELETE_FUND_TASK_BY_TASK_ID_RESPONSE,
    ADD_FUND_SUB_TASK_RESPONSE,
    DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_RESPONSE,
    GET_FUND_TASK_DETAILS_RESPONSE,
    ADD_FUND_TASK_COMMENT_RESPONSE,
    ADD_FUND_TASK_ATTACHMENT_RESPONSE,
    GET_FUND_TASK_ACTIVITY_RESPONSE,
    DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE,
    CLOSE_FUND_RESPONSE,
    ACTIVE_OR_DEACTIVE_FUND_RESPONSE,
    GET_FUND_HEALTH_CHECK_RESPONSE,
    GET_FUND_CHECK_ACCESS_RESPONSE,
    UPDATE_FUND_TASK_SEQUENCE_ORDER_RESPONSE,
    GET_FUND_TASK_COUNT_BY_FUND_ID_RESPONSE,
    RETRIEVE_FUND_BY_FUND_ID_RESPONSE
} from './actionTypes';
export const initialState = {
    entityAllUsersList: [],
    addFundApiResponse: null,
    fundList: [],
    editFundApiResponse: null,
    deleteFundApiResponse: null,
    retrieveFundApiResponse: null,
    fundTaskList: [],
    fundTaskApiResponse: null,
    fundSubTaskApiResponse: null,
    fundTaskDetailsApiResponse: null,
    fundTaskCommentApiResponse: null,
    fundTaskAttachmentApiResponse: null,
    fundTaskActivityApiResponse: null,
    fundTaskDocumentApiResponse: null,
    closeFundApiResoponse: null,
    activeOrDeactiveFundApiResponse: null,
    fundHealthCheckApiResponse: null,
    fundAccessCheckApiResponse: null,
    updateFundTaskSequenceOrderApiResponse: null,
    fundTaskCountApiResponse: null
};
const funds = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_FUND_TASK_USERS_BY_ENTITY_RESPONSE:
                draft.entityAllUsersList = action.payload.entityAllUsersList;
                break;

            case ADD_FUND_RESPONSE:
            case UPDATE_FUND_RESPONSE:
                draft.addFundApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_FUND_LIST_RESPONSE:
                draft.fundList = action.payload.fundList;
                break;
            case GET_FUND_DETAILS_RESPONSE:
                draft.editFundApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case DELETE_FUND_BY_FUND_ID_RESPONSE:
                draft.deleteFundApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_FUND_TASK_LIST_RESPONSE:
                draft.fundTaskList = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case ADD_FUND_TASK_RESPONSE:
            case UPDATE_FUND_TASK_RESPONSE:
            case DELETE_FUND_TASK_BY_TASK_ID_RESPONSE:
                draft.fundTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case ADD_FUND_SUB_TASK_RESPONSE:
            case DELETE_FUND_SUB_TASK_BY_SUB_TASK_ID_RESPONSE:
                draft.fundSubTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_FUND_TASK_DETAILS_RESPONSE:
                draft.fundTaskDetailsApiResponse =
                    action.payload.taskDetails;
                break;
            case ADD_FUND_TASK_COMMENT_RESPONSE:
                draft.fundTaskCommentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case ADD_FUND_TASK_ATTACHMENT_RESPONSE:
                draft.fundTaskAttachmentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_FUND_TASK_ACTIVITY_RESPONSE:
                draft.fundTaskActivityApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                }
                break;
            case DELETE_FUND_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE:
                draft.fundTaskDocumentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case CLOSE_FUND_RESPONSE:
                draft.closeFundApiResoponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case ACTIVE_OR_DEACTIVE_FUND_RESPONSE:
                draft.activeOrDeactiveFundApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_FUND_HEALTH_CHECK_RESPONSE:
                draft.fundHealthCheckApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_FUND_CHECK_ACCESS_RESPONSE:
                draft.fundAccessCheckApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case UPDATE_FUND_TASK_SEQUENCE_ORDER_RESPONSE:
                draft.updateFundTaskSequenceOrderApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_FUND_TASK_COUNT_BY_FUND_ID_RESPONSE:
                draft.fundTaskCountApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case RETRIEVE_FUND_BY_FUND_ID_RESPONSE:
                draft.retrieveFundApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default funds;
