import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = (state) => state.entity || initialState;
const makeEntitySelectField = (field) =>
    createSelector(reducerState, (entityState) => entityState[field]);
const selectSortedEntityList = () =>
    createSelector(makeEntitySelectField('entityList'), (entityList) =>
        entityList
            .map((opt) => ({
                value: opt.id,
                label: opt.entityName,
                companyGroupId: opt.companyGroupId
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
const selectSortedEntityListWithDepartmentRoles = () =>
    createSelector(makeEntitySelectField('entityList'), (entityList) =>
        entityList
            .map((opt) => ({
                value: opt.id,
                label: opt.entityName,
                departments: opt.departments
                    ? opt.departments.map((department) => ({
                        value: department.id,
                        label: department.departmentName,
                    }))
                    : [],
                roles: opt.roles
                    ? opt.roles.map((role) => ({
                        value: role.id,
                        label: role.roleTerm,
                    }))
                    : [],
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
const selectSortedEntityManagementTeamUserList = () =>
    createSelector(makeEntitySelectField('entityTeams'), (entityTeams) =>
        entityTeams?.entityManagementTeam
            ?.map((opt) => ({
                value: opt.userId,
                label: opt?.user?.fullName + ' - ' + opt?.user?.email,
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedEntityTeamUserList = () =>
    createSelector(makeEntitySelectField('entityTeams'), (entityTeams) =>
        entityTeams?.entityTeam
            ?.map((opt) => ({
                value: opt.userId,
                label: opt?.user?.fullName + ' - ' + opt?.user?.email,
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedEntityTeamAndManagementTeamUserList = () =>
    createSelector(makeEntitySelectField('entityTeams'), (entityTeams) => {
        // merge of entity team and management team
        let entityTeamUsers = entityTeams?.entityTeam
            ? entityTeams?.entityTeam
            : [];
        let entityManagementTeamUsers = entityTeams?.entityManagementTeam
            ? entityTeams?.entityManagementTeam
            : [];
        let users = [...entityTeamUsers, ...entityManagementTeamUsers];
        let finalUsers = users.map((opt) => ({
            value: opt.userId,
            label: opt?.user?.fullName + ' - ' + opt?.user?.email,
        }));
        return finalUsers
            .filter(
                (a, i) => finalUsers.findIndex((s) => a.value === s.value) === i
            )
            .sort((item1, item2) => compareString(item1.label, item2.label));
    });

const selectSortedEntityTeamAndConsultantTeamUserList = () =>
    createSelector(makeEntitySelectField('entityTeams'), (entityTeams) => {
        // merge of entity team and consultants team
        let entityTeamUsers = entityTeams?.entityTeam
            ? entityTeams?.entityTeam
            : [];
        let entityConsultantTeamUsers = entityTeams?.entityConsultantTeam
            ? entityTeams?.entityConsultantTeam
            : [];
        let users = [...entityTeamUsers, ...entityConsultantTeamUsers];
        let finalUsers = users.map((opt) => ({
            value: opt.userId,
            label: opt?.user?.fullName + ' - ' + opt?.user?.email,
        }));
        return finalUsers
            .filter(
                (a, i) => finalUsers.findIndex((s) => a.value === s.value) === i
            )
            .sort((item1, item2) => compareString(item1.label, item2.label));
    });

const selectSortedEntityAllUsersList = () =>
    createSelector(
        makeEntitySelectField('entityAllUsersList'),
        (entityAllUsersList) => {
            let finalUsers = entityAllUsersList.map((opt) => ({
                value: opt.userId,
                label: opt?.user?.fullName + ' - ' + opt?.user?.email,
            }));
            return finalUsers
                .filter(
                    (a, i) =>
                        finalUsers.findIndex((s) => a.value === s.value) === i
                )
                .sort((item1, item2) =>
                    compareString(item1.label, item2.label)
                );
        }
    );

const selectSortedEntityTeamManagementTeamHodAndConsultantTeamUserList = () =>
    createSelector(makeEntitySelectField('entityTeams'), (entityTeams) => {
        // merge of entity team and consultants team
        let entityTeamUsers = entityTeams?.entityTeam
            ? entityTeams?.entityTeam
            : [];
        let entityManagementTeamUsers = entityTeams?.entityManagementTeam
            ? entityTeams?.entityManagementTeam
            : [];
        let entityConsultantTeamUsers = entityTeams?.entityConsultantTeam
            ? entityTeams?.entityConsultantTeam
            : [];
        let entityHodUsers = entityTeams?.entityHod
            ? entityTeams?.entityHod
            : [];
        let users = [
            ...entityTeamUsers,
            ...entityManagementTeamUsers,
            ...entityConsultantTeamUsers,
            ...entityHodUsers,
        ];
        let finalUsers = users.map((opt) => ({
            value: opt.userId,
            label: opt?.user?.fullName + ' - ' + opt?.user?.email,
        }));
        return finalUsers
            .filter(
                (a, i) => finalUsers.findIndex((s) => a.value === s.value) === i
            )
            .sort((item1, item2) => compareString(item1.label, item2.label));
    });

const selectSortedConsultantTeamUserList = () =>
    createSelector(makeEntitySelectField('entityTeams'), (entityTeams) => {
        // consultants team
        return entityTeams?.entityConsultantTeam
            ?.map((opt) => ({
                value: opt.userId,
                label: opt?.user?.fullName + ' - ' + opt?.user?.email,
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    });

const selectSortedUserListByEntityAndRole = () =>
    createSelector(makeEntitySelectField('userListByRole'), (userListByRole) =>
        userListByRole?.map((opt) => ({
            value: opt.id,
            label: opt?.fullName + ' - ' + opt?.email,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeEntitySelectField,
    selectSortedEntityList,
    selectSortedEntityListWithDepartmentRoles,
    selectSortedEntityTeamUserList,
    selectSortedEntityManagementTeamUserList,
    selectSortedEntityTeamAndManagementTeamUserList,
    selectSortedEntityTeamAndConsultantTeamUserList,
    selectSortedEntityTeamManagementTeamHodAndConsultantTeamUserList,
    selectSortedEntityAllUsersList,
    selectSortedUserListByEntityAndRole,
    selectSortedConsultantTeamUserList
};
