import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    CardBody,
    Card,
    CardHeader,
    CardFooter,
    Input,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    CANCEL,
    SUBMIT,
    DANGER,
    IMPORT_MODULE,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';

import { toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    getPermissionListRequest,
    getModuleListRequest,
    getRoleByRoleIdRequest,
    updateRoleByRoleIdRequest,
} from 'store/actions';

import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import roleReducer from 'store/Masters/Role/reducer';
import roleSaga from 'store/Masters/Role/saga';
import __t from 'i18n/translator';
import { makeRoleSelectField } from 'store/Masters/Role/selector';
import { initialRolePermissionDataState } from './CommonFunctions';
import { PERMISSION_IMPORT } from 'constants/databaseConstants';
const roleKey = 'role';
const withRoleReducer = injectReducer({
    key: roleKey,
    reducer: roleReducer,
});
const withRoleSaga = injectSaga({ key: roleKey, saga: roleSaga });
class RolePermissionForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            operationType: SUBMIT,
            roleId: this.props.match.params.roleId
                ? parseInt(this.props.match.params.roleId)
                : '',
            moduleList: [],
            permissionList: [],
            rolePermissionData: initialRolePermissionDataState(),
            formInfo: {},
            importModules: IMPORT_MODULE.map((m) => parseInt(m.moduleId)),
        };
    }
    componentDidMount() {
        this.props.getPermissionListRequest();
        this.props.getModuleListRequest();
        if (this.state.roleId) {
            this.props.getRoleByRoleIdRequest(this.state.roleId);
        }
    }
    handleChange = (e, module, permission) => {
        const { checked } = e.target;
        let formModule = this.state.formInfo.modules.find(
            (opt) => opt.moduleId === module.id
        );
        if (!formModule) {
            this.setState({
                formInfo: {
                    ...this.state.formInfo,
                    modules: [
                        ...this.state.formInfo.modules,
                        {
                            moduleId: module.id,
                            permissions: [permission.id],
                        },
                    ],
                },
            });
        } else {
            let formModulePermissions = this.state.formInfo.modules.find(
                (opt) => opt.moduleId === module.id
            ).permissions;

            if (checked === false) {
                formModulePermissions = formModulePermissions.filter((opt) => {
                    return opt !== permission.id && opt;
                });
            } else {
                formModulePermissions.push(permission.id);
            }

            let updateModules = this.state.formInfo.modules.map((opt) => {
                if (opt.moduleId === module.id) {
                    return {
                        moduleId: module.id,
                        permissions: formModulePermissions,
                    };
                } else {
                    return opt;
                }
            });
            this.setState({
                formInfo: {
                    ...this.state.formInfo,
                    modules: updateModules,
                },
            });
        }
    };
    formSubmit = () => {
        let formValues = this.state.formInfo;
        formValues.modules = formValues.modules.filter((opt) => {
            return (opt && opt !== 'null' && opt.permissions.length > 0) ?? opt;
        });
        this.props.updateRoleByRoleIdRequest(formValues);
    };
    checkModules = (module, permissionId) => {
        let formValues = this.state.formInfo?.modules?.find((opt) => {
            if (opt && opt !== 'null' && opt.moduleId === module.id) {
                return opt;
            }
        });
        if (formValues?.permissions) {
            return formValues.permissions.includes(permissionId) ? true : false;
        } else {
            return false;
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.roleApiResponse &&
            this.props.roleApiResponse !== nextprops.roleApiResponse
        ) {
            toastrMessage(
                nextprops.roleApiResponse.message,
                nextprops.roleApiResponse.responseType
            );
            if (nextprops.roleApiResponse.responseType === SUCCESS) {
                this.props.history.push('/role-permission');
            }
        }
        if (
            nextprops.moduleList &&
            this.props.moduleList !== nextprops.moduleList
        ) {
            this.setState({
                moduleList: nextprops.moduleList,
            });
        }
        if (
            nextprops.permissionList &&
            this.props.permissionList !== nextprops.permissionList
        ) {
            this.setState({
                permissionList: nextprops.permissionList,
            });
        }
        if (
            nextprops.editRoleApiResponse &&
            this.props.editRoleApiResponse !== nextprops.editRoleApiResponse
        ) {
            if (nextprops.editRoleApiResponse.responseType === DANGER) {
                toastrMessage(
                    nextprops.editRoleApiResponse.message,
                    nextprops.editRoleApiResponse.responseType
                );
                this.props.history.push('/role-permission');
            } else {
                this.setState(
                    {
                        operationType: UPDATE,
                        rolePermissionData: {
                            ...nextprops.editRoleApiResponse.data.role,
                            roleId: nextprops.editRoleApiResponse.data.role.id,
                            modules:
                                nextprops.editRoleApiResponse.data.role.modules?.map(
                                    (module) => {
                                        // unique permission ids
                                        let permissionsArray = [];
                                        if (module.permissions != '') {
                                            permissionsArray = [
                                                ...new Set(
                                                    module.permissions
                                                        .split(',')
                                                        ?.map((opt) =>
                                                            parseInt(opt)
                                                        ) || []
                                                ),
                                            ];
                                        }
                                        return {
                                            ...module,
                                            permissions: permissionsArray,
                                        };
                                    }
                                ) || [],
                        },
                    },
                    () => {
                        this.setState({
                            formInfo: this.state.rolePermissionData,
                        });
                    }
                );
            }
        }
    }
    redirectToRolePermission = () => {
        this.props.history.push('/role-permission');
    };

    render() {
        return (
            <React.Fragment>
                <Card>


                    <CardHeader>
                        <div className="page-header">
                            <h4 className="with-back-btn mt-0 d-flex">
                                <a
                                    href={() => false}
                                    className="dripicons-arrow-thin-left"
                                    onClick={() => this.redirectToRolePermission()}
                                >
                                    { }
                                </a>{' '}
                                &nbsp;
                                {__t('page_headers.role_edit') +
                                    ' | ' +
                                    this.state.formInfo.roleName}{' '}
                            </h4>
                        </div>
                    </CardHeader>
                    <CardBody className="custom_table">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th width="60%">
                                        {__t(
                                            'form_labels.masters.roles.module'
                                        )}
                                    </th>
                                    {this.state.permissionList?.length > 0 &&
                                        this.state.permissionList.map(
                                            (permission, key) => {
                                                return (
                                                    <th
                                                        className="text-center"
                                                        style={{
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                        key={key}
                                                    >
                                                        {
                                                            permission.permissionName
                                                        }
                                                    </th>
                                                );
                                            }
                                        )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.moduleList?.length > 0 &&
                                    this.state.moduleList.map((module, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {module.module}
                                                </td>
                                                {this.state.permissionList
                                                    ?.length > 0 &&
                                                    this.state.permissionList.map(
                                                        (permission, key) => {
                                                            return (
                                                                <td
                                                                    className="text-center role-checkbox"
                                                                    key={key}
                                                                >
                                                                    {((this.state.importModules.includes(
                                                                        module.id
                                                                    ) &&
                                                                        permission.id ==
                                                                        PERMISSION_IMPORT) ||
                                                                        permission.id !=
                                                                        PERMISSION_IMPORT) && (
                                                                            <React.Fragment>
                                                                                <FormGroup className="form-check">
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        id={`modules.${idx}.permissions.${permission.id}`}
                                                                                        name={`modules.${idx}.permissions`}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {

                                                                                            this.handleChange(
                                                                                                e,
                                                                                                module,
                                                                                                permission
                                                                                            );
                                                                                        }}
                                                                                        checked={this.checkModules(
                                                                                            module,
                                                                                            permission.id
                                                                                        )}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor={`modules.${idx}.permissions.${permission.id}`}
                                                                                    ></label>
                                                                                </FormGroup>
                                                                            </React.Fragment>
                                                                        )}
                                                                </td>
                                                            );
                                                        }
                                                    )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col lg="12">
                                <FormGroup className="mb-0 d-flex justify-content-end">
                                    <div className="d-inline-block">
                                        <Button
                                            onClick={() => this.formSubmit()}
                                            type="button"
                                            color="primary"
                                            className="mr-2"
                                        >
                                            {this.state.operationType}
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={() => this.redirectToRolePermission()}
                                        >
                                            {CANCEL}
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardFooter>

                </Card>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    moduleList: makeRoleSelectField('moduleList'),
    permissionList: makeRoleSelectField('permissionList'),
    editRoleApiResponse: makeRoleSelectField('editRoleApiResponse'),
    roleApiResponse: makeRoleSelectField('roleApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getPermissionListRequest,
    getModuleListRequest,
    getRoleByRoleIdRequest,
    updateRoleByRoleIdRequest,
});
export default compose(
    withConnect,
    withRoleReducer,
    withRoleSaga,
    withRouter
)(RolePermissionForm);
