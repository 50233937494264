import { ADD_SUB_INDUSTRY_REQUEST, ADD_SUB_INDUSTRY_RESPONSE, DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST, DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE, GET_ALL_SUB_INDUSTRY_LIST_REQUEST, GET_ALL_SUB_INDUSTRY_LIST_RESPONSE, GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST, GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE, GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_REQUEST, GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_RESPONSE, GET_SUB_INDUSTRY_LIST_BY_STATUS_REQUEST, GET_SUB_INDUSTRY_LIST_BY_STATUS_RESPONSE, UPDATE_SUB_INDUSTRY_REQUEST, UPDATE_SUB_INDUSTRY_RESPONSE, UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_REQUEST, UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_RESPONSE } from "./actionTypes";
export const addSubIndustryRequest = data => {
    return {
        type: ADD_SUB_INDUSTRY_REQUEST,
        payload: { data },
    };
};
export const addSubIndustryResponse = (message, responseType) => {
    return {
        type: ADD_SUB_INDUSTRY_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllSubIndustryListRequest = () => {
    return {
        type: GET_ALL_SUB_INDUSTRY_LIST_REQUEST
    };
};
export const getAllSubIndustryListResponse = subIndustryList => {
    return {
        type: GET_ALL_SUB_INDUSTRY_LIST_RESPONSE,
        payload: { subIndustryList },
    };
};
export const getSubIndustryBySubIndustryIdRequest = (subIndustryId) => {
    return {
        type: GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST,
        payload: { subIndustryId: subIndustryId },
    };
};
export const getSubIndustryBySubIndustryIdResponse = (message, responseType, data) => {
    return {
        type: GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateSubIndustryRequest = (subIndustryId, data) => {
    return {
        type: UPDATE_SUB_INDUSTRY_REQUEST,
        payload: { subIndustryId, data },
    };
};
export const updateSubIndustryResponse = (message, responseType) => {
    return {
        type: UPDATE_SUB_INDUSTRY_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateSubIndustryStatusBySubIndustryIdRequest = (subIndustryId, status) => {
    return {
        type: UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_REQUEST,
        payload: { subIndustryId, status },
    };
};
export const updateSubIndustryStatusBySubIndustryIdResponse = (message, responseType) => {
    return {
        type: UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getSubIndustryListByStatusRequest = status => {
    return {
        type: GET_SUB_INDUSTRY_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getSubIndustryListByStatusResponse = subIndustryList => {
    return {
        type: GET_SUB_INDUSTRY_LIST_BY_STATUS_RESPONSE,
        payload: { subIndustryList },
    };
};
export const deleteSubIndustryBySubIndustryIdRequest = (subIndustryId) => {
    return {
        type: DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST,
        payload: { subIndustryId },
    };
};
export const deleteSubIndustryBySubIndustryIdResponse = (message, responseType) => {
    return {
        type: DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getSubIndustryListByIndustryRequest = industryIds => {
    return {
        type: GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_REQUEST,
        payload: { industryIds },
    };
};
export const getSubIndustryListByIndustryResponse = subIndustryList => {
    return {
        type: GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_RESPONSE,
        payload: { subIndustryList },
    };
};