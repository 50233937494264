import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import {
    RESET,
    SUBMIT,
    SUCCESS
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import { toastrMessage } from 'helpers/messageHelper';
import { customValidTextField } from 'helpers/customValidators';
import __t from 'i18n/translator';
class AddStateForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField()
            )
        });
        this.state = {
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign({}, this.props.stateData)}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        name="stateName"
                                        className="form-control"
                                        placeholder={__t('form_labels.masters.state_name')}
                                        value={
                                            values.stateName
                                        }
                                        maxLength={250}
                                        disable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.state_name')} <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.state_name'),
                                        values.stateName,
                                        'required|max:250|alpha_space'
                                    )}
                                </FormGroup>
                                <FormGroup className='col-lg-12'>
                                    <Field
                                        component={Select}
                                        name="countryId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropDown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'countryId',
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedCountryListDropDown.filter(
                                            option =>
                                                option.value ===
                                                values.countryId
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.country')}
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.country'),
                                        values.countryId,
                                        'required'
                                    )}
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >{this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}>{RESET}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
export default AddStateForm;