import produce from 'immer';
import {
    ADD_DEPARTMENT_RESPONSE,
    DELETE_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE,
    GET_ALL_DEPARTMENT_LIST_RESPONSE,
    GET_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE,
    GET_DEPARTMENT_LIST_BY_STATUS_RESPONSE,
    GET_ENTITY_DEPARTMENT_BY_ENTITY_RESPONSE,
    UPDATE_DEPARTMENT_RESPONSE,
    UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_RESPONSE,
} from './actionTypes';
export const initialState = {
    departmentApiResponse: null,
    editDepartmentApiResponse: null,
    departmentList: [],
};
const department = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_DEPARTMENT_RESPONSE:
            case UPDATE_DEPARTMENT_RESPONSE:
            case UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_RESPONSE:
            case DELETE_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE:
                draft.departmentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_DEPARTMENT_LIST_RESPONSE:
                draft.departmentList = action.payload.departmentList;
                break;
            case GET_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE:
                draft.editDepartmentApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;




            case GET_DEPARTMENT_LIST_BY_STATUS_RESPONSE:
                draft.departmentList = action.payload.departmentList;
                break;



            case GET_ENTITY_DEPARTMENT_BY_ENTITY_RESPONSE:
                draft.departmentList = action.payload.departmentList;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default department;
