import React from 'react';
import { Card, CardHeader, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

class CustomisedCollapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDialog: true,
        };
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <Link
                        to="#"
                        onClick={() =>
                            this.setState({
                                viewDialog: !this.state.viewDialog,
                            })
                        }
                        className="card-title"
                    >
                        <h6 className="font-14 m-0">{this.props.dialogName}</h6>
                    </Link>
                </CardHeader>
                <Collapse isOpen={this.state.viewDialog}>
                    {this.props.children}
                </Collapse>
            </Card>
        );
    }
}

export default CustomisedCollapse;
