import { DANGER } from 'constants/commonConstants';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import React from 'react';
import { Collapse, Input } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { compareIntValue } from 'helpers/generalUtils';

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 8,
    width: 250
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

class EventComplianceSubItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openItemID: '',
            items: (this.props.subTask?.subTask) ? this.props.subTask?.subTask.sort((item1, item2) => compareIntValue(item1.sequenceOrder, item2.sequenceOrder)) : []
        };
        this.handleClick = this.handleClick.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }
    handleClick = (itemID) => {
        if (itemID === this.state.openItemID) {
            this.setState({ openItemID: '' });
        } else {
            this.setState({ openItemID: itemID });
        }
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const resultData = Array.from(this.state.items);
        const startIndex = resultData.findIndex((element, index) => {
            if (element.id === result.source.index) {
                return true
            }
        });
        const endIndex = resultData.findIndex((element, index) => {
            if (element.id === result.destination.index) {
                return true
            }
        });

        const [removed] = resultData.splice(startIndex, 1);
        resultData.splice(endIndex, 0, removed);
        this.setState({
            items: resultData
        }, () => {
            this.props.onDragReceive(this.state.items);
        });
    }

    render() {
        let complianceRecordSelected = this.props?.allocationData?.compliances?.find(
            (opt) => opt.complianceId == this.props.subTask.id
        );
        let alreadySelectedEntities =
            complianceRecordSelected?.entities || [];
        return (

            <div className="tree-item" key={'main-' + this.props.subTask.id}>
                <div className="inner-item">
                    <span className='drag_icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M17 9V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 1V1.01M5 1V1.01M9 1V1.01M13 1V1.01M1 5V5.01M1 9V9.01M1 13V13.01M11 11L20 14L16 16L14 20L11 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    <svg
                        onClick={() =>
                            this.handleClick(this.props.subTask.id)
                        }
                        width="48px"
                        height="48px"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            width="48"
                            height="48"
                            fill="white"
                            fillOpacity="0.01"
                        />
                        <path
                            d="M37 18L25 30L13 18"
                            stroke="currentColor"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <b
                        onClick={() =>
                            this.handleClick(this.props.subTask.id)
                        }
                        key={this.props.subTask.id}
                    >
                        <a href={() => false}>
                            <span>{this.props.subTask.complianceCode}</span>{this.props.subTask.complianceTitle}
                        </a>
                    </b>
                    <div className="task-action">
                        <React.Fragment>
                            {this.props?.type == 'allocation' && (
                                <React.Fragment>
                                    {alreadySelectedEntities.length > 0 && (
                                        <span className="text-info">
                                            <i className="fas fa-check-circle"></i>
                                        </span>
                                    )}
                                    <a
                                        href={() => false}
                                        onClick={() => {
                                            let parentComplianceId = this.props.subTask.triggeringComplianceId;
                                            if (parentComplianceId && parentComplianceId != null) {
                                                let isParentCompliance = this.props.allocationData.compliances.find((cmp) => cmp.complianceId == parentComplianceId);
                                                if (!isParentCompliance) {
                                                    toastrMessage(__t('alerts.select_parent_compliance'), DANGER);
                                                    return false;
                                                }
                                            }
                                            this.props.openMapLocationModal(
                                                this.props.subTask.id,
                                                this.props.subTask.complianceCode,
                                                this.props.subTask
                                            );
                                        }}
                                        className="allocate-entity-link text-primary py-0 px-2"
                                        color="link"
                                        title={__t('common.click_to_allocate_entity')}
                                    >
                                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16" y="7" width="4" height="4" rx="2" transform="rotate(90 16 7)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="16" y="15" width="4" height="4" rx="2" transform="rotate(90 16 15)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="1" y="5" width="4" height="4" rx="2" transform="rotate(-90 1 5)" fill="#7E869E" fillOpacity="1" stroke="currentColor" strokeWidth="1.5"></rect><path d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12" stroke="currentColor" strokeWidth="1.5"></path><path d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12" stroke="currentColor" strokeWidth="1.5"></path></svg>
                                    </a>

                                </React.Fragment>
                            )}

                            {this.props.subTask.isFinal && <span title={__t('form_labels.masters.events.final_activity')}>
                                <i className='fas fa-flag text-primary'></i>
                            </span>}
                            <Input
                                type='checkbox'
                                className={`mr-2 ${this.props.submitAction === false && 'd-none'}`}
                                onChange={e => {
                                    this.props.handleChecked(e.target.checked, this.props.subTask.id)
                                }}
                            />
                            <a
                                href={() => false}
                                className="text-success py-0 px-2"
                                color="link"
                                title={__t('common.view')}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                    window.open(
                                        '/statutory-compliances/view-details/' +
                                        this.props.subTask.id,
                                        '_blank'
                                    );
                                }}
                            >
                                <i className='fas fa-eye'></i>
                            </a>
                        </React.Fragment>
                    </div>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId={"droppable-" + this.props.subTask.id}>
                        {(provided, snapshot) => (
                            <div
                                className='droppable_box'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                <Collapse
                                    isOpen={this.state.openItemID === this.props.subTask.id}
                                    unmountOnExit
                                >
                                    {this.state.items.length > 0 &&
                                        this.state.items.map((subTask, key) => {
                                            return (
                                                <Draggable key={subTask.id} draggableId={subTask.id} index={subTask.id}>
                                                    {(subProvided, subSnapshot) => (
                                                        <div
                                                            className='droppable_row'
                                                            ref={subProvided.innerRef}
                                                            {...subProvided.draggableProps}
                                                            {...subProvided.dragHandleProps}
                                                            style={getItemStyle(
                                                                subSnapshot.isDragging,
                                                                subProvided.draggableProps.style
                                                            )}
                                                        >
                                                            <div
                                                                className={
                                                                    subTask.subTask.length > 0
                                                                        ? ' tree-parent has-child '
                                                                        : ' tree-parent '
                                                                }
                                                                key={'child-' + subTask.id}
                                                            >
                                                                <EventComplianceSubItem
                                                                    handleChecked={this.props.handleChecked}
                                                                    submitAction={this.props.submitAction}
                                                                    allocationData={this.props.allocationData}
                                                                    type={(this.props?.type == 'allocation') ? this.props?.type : ''}
                                                                    openMapLocationModal={(this.props?.openMapLocationModal) ? this.props.openMapLocationModal : ''}
                                                                    history={this.props.history}
                                                                    subTask={subTask}
                                                                    onDragReceive={(reArrangedSubTasks) => {
                                                                        let cmpList = this.state.items;
                                                                        this.setState({
                                                                            items: cmpList.map((opt) => {
                                                                                if (opt.id == subTask.id) {
                                                                                    return {
                                                                                        ...opt,
                                                                                        subTask: reArrangedSubTasks
                                                                                    }
                                                                                } else {
                                                                                    return opt
                                                                                }
                                                                            })
                                                                        }, () => {
                                                                            this.props.onDragReceive(this.state.items);
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                </Collapse>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

        );
    }
}
export default EventComplianceSubItem;
