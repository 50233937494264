import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, FormGroup, Label, Button, CardFooter } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';

import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';

import {
    getCountryListByStatusRequest,
} from 'store/actions';

import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Field, Form, Formik } from 'formik';

import Select from 'react-select';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { languageList, servicesList, topicsList } from 'constants/dropdowns';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });

class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            needHelpForm: { ServiceIds: [], TopicIds: [], CountryId: '', Name: '', Email: '', ContactNo: '' },
        };
    }

    componentDidMount() {
        this.props.getCountryListByStatusRequest();
    }
    formSubmit = (values) => {
    }
    generateDropdownOptions = (list) => {
        return list.map((item) => ({
            label: item.Name,
            value: item.Id
        }));
    };


    render() {
        const topicsListDropdown = this.generateDropdownOptions(topicsList);
        const servicesListDropdown = this.generateDropdownOptions(servicesList);
        const langListDropdown = this.generateDropdownOptions(languageList);


        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card className='upcoming_compliances_card h-auto animate__animated animate__fadeInUp'>
                            <CardHeader className='dashboard-head'>
                                <div className='page-header'>
                                    <h4 className='with-back-btn'>Need Additional Services</h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row className='additional_services'>
                                    <Col>
                                        <Link to="#" className="quick_links">New Entity Set Up Support <i className="fas fa-headset"></i></Link>
                                    </Col>
                                    <Col>
                                        <Link to="#" className="quick_links">Multi-National Payroll Management <i className="fas fa-globe-americas"></i></Link>
                                    </Col>
                                    <Col>
                                        <Link to="#" className="quick_links">CLM Services <i className="fas fa-cogs"></i></Link>
                                    </Col>
                                    <Col>
                                        <Link to="#" className="quick_links">Litigation/Case Management Services <i className="mdi mdi-gavel"></i></Link>
                                    </Col>
                                    <Col>
                                        <Link to="#" className="quick_links">Global Tax/Accounting Inquiry <i className="fas fa-search-dollar"></i></Link>
                                    </Col>
                                    <Col>
                                        <Link to="#" className="quick_links">Last Mile Compliance Execution Support <i className="ti-headphone-alt"></i></Link>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='d-flex justify-content-end'>
                                <Button type="submit" color="primary" className="mr-3">Submit</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Card className='upcoming_compliances_card h-auto animate__animated animate__fadeInUp'>
                            <CardHeader className='dashboard-head'>
                                <div className='page-header'>
                                    <h4 className='with-back-btn'>Customize Your Service Requirements</h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={this.state.needHelpForm}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <Row className='d-flex align-items-center'>
                                                <FormGroup className='col-lg-6'>
                                                    <Field
                                                        name="Name"
                                                        className="form-control"
                                                        value={values.Name}
                                                        placeholder="Name"
                                                    />
                                                    <Label className="form-label">
                                                        Name
                                                    </Label>
                                                    {this.validator.message(
                                                        'Name',
                                                        values.Name,
                                                        'required|max:20'
                                                    )}
                                                </FormGroup>
                                                <FormGroup className='col-lg-6'>
                                                    <Field
                                                        name="Email"
                                                        className="form-control"
                                                        value={values.Email}
                                                        placeholder="Email"
                                                    />
                                                    <Label className="form-label">
                                                        Email
                                                    </Label>
                                                    {this.validator.message(
                                                        'Email',
                                                        values.Email,
                                                        'required|email'
                                                    )}
                                                </FormGroup>

                                                <FormGroup className='col-lg-6'>
                                                    <Field
                                                        component={Select}
                                                        name="CountryId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props.sortedCountryListDropDown
                                                        }
                                                        value={this.props.sortedCountryListDropDown.filter(
                                                            option =>
                                                                option.value ===
                                                                values.CountryId
                                                        )}
                                                        onChange={opt => {
                                                            setFieldValue(
                                                                'CountryId',
                                                                opt.value
                                                            );
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        Jurisdiction/Country
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className='col-lg-6'>
                                                    <Field
                                                        name="TopicIds[]"
                                                        className="form-control selectbox h-100 report-dropdown"
                                                        component={Select}
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                        onChange={opt => {
                                                            setFieldValue(
                                                                'TopicIds',
                                                                opt &&
                                                                opt.map(
                                                                    x => x.value
                                                                )
                                                            );
                                                        }}
                                                        options={
                                                            topicsListDropdown
                                                        }
                                                        value={topicsListDropdown.filter(
                                                            option =>
                                                                values.TopicIds?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        Topic
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup className='col-lg-6'>
                                                    <Field
                                                        name="ServiceIds[]"
                                                        className="form-control selectbox h-100 report-dropdown"
                                                        component={Select}
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                        onChange={opt => {
                                                            setFieldValue(
                                                                'ServiceIds',
                                                                opt &&
                                                                opt.map(
                                                                    x => x.value
                                                                )
                                                            );
                                                        }}
                                                        options={
                                                            servicesListDropdown
                                                        }
                                                        value={servicesListDropdown.filter(
                                                            option =>
                                                                values.ServiceIds?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        Services
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup className='col-lg-6'>
                                                    <Field
                                                        component={Select}
                                                        name="LangaugeId"
                                                        className="form-control selectbox"
                                                        options={langListDropdown}
                                                        value={langListDropdown.filter(
                                                            option =>
                                                                option.value ===
                                                                values.LangaugeId
                                                        )}
                                                        onChange={opt => {
                                                            setFieldValue(
                                                                'LangaugeId',
                                                                opt.value
                                                            );
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        Language
                                                    </Label>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="d-flex justify-content-end">
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-3"
                                                        >
                                                            Request Now
                                                        </Button>
                                                        <Button
                                                            type="reset"
                                                            color="secondary"
                                                        >
                                                            Clear
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList(),
});

const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
});

export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withRouter
)(Index);