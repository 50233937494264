import produce from 'immer';
import { SAVE_USER_NOTIFICATION_RESPONSE, GET_USER_NOTIFICATION_RESPONSE, READ_USER_NOTIFICATION_RESPONSE, READ_NOTIFICATION_BY_ID_RESPONSE } from './actionTypes';
export const initialState = {
    saveChatNotificationApiResponse: null,
    notificationList: [],
    notificationListApiResponse:{},
    readUserNotificationApiResponse: null,
    readNotificationByIdApiResponse: null,
};
const notification = (state = initialState, action = '') => produce(state, draft => {
    switch (action.type) {

        case SAVE_USER_NOTIFICATION_RESPONSE:
            draft.saveChatNotificationApiResponse = {
                message: action.payload.message,
                data: action.payload.data,
                responseType: action.payload.responseType,
            }
            break;

        case GET_USER_NOTIFICATION_RESPONSE:
            draft.notificationListApiResponse = {
                message: action.payload.message,
                data: action.payload.data,
                responseType: action.payload.responseType,
            }
            break;

        case READ_USER_NOTIFICATION_RESPONSE:
            draft.readUserNotificationApiResponse = {
                message: action.payload.message,
                responseType: action.payload.responseType,
            };
            break;

        case READ_NOTIFICATION_BY_ID_RESPONSE:
            draft.readNotificationByIdApiResponse = {
                message: action.payload.message,
                responseType: action.payload.responseType,
            };
            break;

        default:
            state = { ...state };
            break;
    }
});
export default notification;
