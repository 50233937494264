import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.audit || initialState;
const makeAuditSelectField = field =>
    createSelector(reducerState, auditState => auditState[field]);
const selectSortedAuditList = () =>
    createSelector(makeAuditSelectField('auditList'), auditList =>
        auditList.map(opt => ({
            value: opt.id,
            label: opt.auditName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeAuditSelectField,
    selectSortedAuditList
};
