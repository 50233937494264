import React, { Component } from 'react';
import { Row, FormGroup, Button, Label, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CLEAR, DATE_FORMAT_DATEPICKER, DATE_FORMAT_PLACEHOLDER, GENERATE_REPORT, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { initialRunTimeFilterDataState } from './CommonFunctions';
import { selectSortedEntityTeamAndConsultantTeamUserList } from 'store/Entity/selector';
import {
    getEntityTeamsByEntityIdRequest
} from 'store/Entity/actions';

class RuntimeFilterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            filterData: initialRunTimeFilterDataState(),
            operationType: SUBMIT,
            sortedEntityTeamAndConsultantTeamUserDropdown: []
        }
    }

    getEntityTeamsByEntityId = (entityId) => {
        if (entityId.length > 0) {
            this.props.getEntityTeamsByEntityIdRequest(entityId);
        } else {
            this.setState({
                sortedEntityTeamAndConsultantTeamUserDropdown: []
            })
        }
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                filterData: initialRunTimeFilterDataState(),
            },
            () => {
                this.props.onFormReset();
            }
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.sortedEntityTeamAndConsultantTeamUserDropdown && nextprops.sortedEntityTeamAndConsultantTeamUserDropdown !== this.props.sortedEntityTeamAndConsultantTeamUserDropdown) {
            this.setState({
                sortedEntityTeamAndConsultantTeamUserDropdown: nextprops.sortedEntityTeamAndConsultantTeamUserDropdown
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.state.filterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Row>

                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropdown
                                        }
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let countryId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'countryId',
                                                countryId
                                            );
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            (option) =>
                                                values.countryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.reports.country'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="entityId[]"
                                        className="form-control selectbox"
                                        options={this.props.sortedEntityListDropdown}
                                        onChange={(opt) => {
                                            let entityId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('entityId', entityId);

                                            this.getEntityTeamsByEntityId(entityId);

                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            (option) =>
                                                values.entityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.entity')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.reports.entity'
                                        ),
                                        values.entityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.reports.entity_required'
                                                )
                                            }
                                        })}
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="departmentId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedDepartmentListDropdown
                                        }
                                        onChange={(opt) => {
                                            let departmentId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('departmentId', departmentId);
                                        }}
                                        value={this.props.sortedDepartmentListDropdown?.filter(
                                            (option) =>
                                                values.departmentId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.department')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="userId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedEntityTeamAndConsultantTeamUserDropdown
                                        }
                                        onChange={(opt) => {
                                            let userId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('userId', userId);
                                        }}
                                        value={this.state.sortedEntityTeamAndConsultantTeamUserDropdown?.filter(
                                            (option) =>
                                                values.userId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.user')}
                                    </Label>
                                </FormGroup>

                                <Col lg={6} className="d-flex">
                                    <FormGroup className='w-100 sticky_label mr-2 position-relative'>
                                        <Field
                                            component={
                                                DatePicker
                                            }
                                            isClearable={true}
                                            className="form-control"
                                            name="fromDate"
                                            selected={(values.fromDate) ? getDateObjectWithValueFromMoment(values.fromDate) : null}
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            placeholderText={DATE_FORMAT_PLACEHOLDER}
                                            onChange={date => {
                                                setFieldValue(
                                                    'fromDate',
                                                    date
                                                )
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            onFocus={e => e.target.blur()}
                                            maxDate={values.toDate}
                                        ></Field>
                                        <Label className="form-label pl-0">
                                            {__t('form_labels.task_filters.from_date')}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className='w-100 sticky_label ml-2 position-relative'>
                                        <Field
                                            component={
                                                DatePicker
                                            }
                                            isClearable={true}
                                            className="form-control"
                                            name="toDate"
                                            selected={(values.toDate) ? getDateObjectWithValueFromMoment(values.toDate) : null}
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            onChange={date => {
                                                setFieldValue(
                                                    'toDate',
                                                    date
                                                )
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            placeholderText={DATE_FORMAT_PLACEHOLDER}
                                            onFocus={e => e.target.blur()}
                                            minDate={values.fromDate}
                                        ></Field>
                                        <Label className="form-label pl-0">
                                            {__t('form_labels.reports.to_date')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <FormGroup className="col-lg-6 d-flex justify-content-end mb-2">
                                    <div className="d-inline-block pt-2">
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >
                                            {CLEAR}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="ml-2"
                                        >
                                            {GENERATE_REPORT}
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedEntityTeamAndConsultantTeamUserDropdown:
        selectSortedEntityTeamAndConsultantTeamUserList(),
});
const withConnect = connect(mapStatetoProps, {
    getEntityTeamsByEntityIdRequest
});
export default compose(
    withConnect,
    withRouter
)(RuntimeFilterForm);
