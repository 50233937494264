import { ADD_INDUSTRY_REQUEST, ADD_INDUSTRY_RESPONSE, DELETE_INDUSTRY_BY_INDUSTRY_ID_REQUEST, DELETE_INDUSTRY_BY_INDUSTRY_ID_RESPONSE, GET_ALL_INDUSTRY_LIST_REQUEST, GET_ALL_INDUSTRY_LIST_RESPONSE, GET_INDUSTRY_BY_INDUSTRY_ID_REQUEST, GET_INDUSTRY_BY_INDUSTRY_ID_RESPONSE, GET_INDUSTRY_LIST_BY_STATUS_REQUEST, GET_INDUSTRY_LIST_BY_STATUS_RESPONSE, UPDATE_INDUSTRY_REQUEST, UPDATE_INDUSTRY_RESPONSE, UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_REQUEST, UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_RESPONSE } from "./actionTypes";
export const addIndustryRequest = data => {
    return {
        type: ADD_INDUSTRY_REQUEST,
        payload: { data },
    };
};
export const addIndustryResponse = (message, responseType) => {
    return {
        type: ADD_INDUSTRY_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllIndustryListRequest = () => {
    return {
        type: GET_ALL_INDUSTRY_LIST_REQUEST
    };
};
export const getAllIndustryListResponse = industryList => {
    return {
        type: GET_ALL_INDUSTRY_LIST_RESPONSE,
        payload: { industryList },
    };
};
export const getIndustryByIndustryIdRequest = (industryId) => {
    return {
        type: GET_INDUSTRY_BY_INDUSTRY_ID_REQUEST,
        payload: { industryId: industryId },
    };
};
export const getIndustryByIndustryIdResponse = (message, responseType, data) => {
    return {
        type: GET_INDUSTRY_BY_INDUSTRY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateIndustryRequest = (industryId, data) => {
    return {
        type: UPDATE_INDUSTRY_REQUEST,
        payload: { industryId, data },
    };
};
export const updateIndustryResponse = (message, responseType) => {
    return {
        type: UPDATE_INDUSTRY_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateIndustryStatusByIndustryIdRequest = (industryId, status) => {
    return {
        type: UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_REQUEST,
        payload: { industryId, status },
    };
};
export const updateIndustryStatusByIndustryIdResponse = (message, responseType) => {
    return {
        type: UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getIndustryListByStatusRequest = status => {
    return {
        type: GET_INDUSTRY_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getIndustryListByStatusResponse = industryList => {
    return {
        type: GET_INDUSTRY_LIST_BY_STATUS_RESPONSE,
        payload: { industryList },
    };
};
export const deleteIndustryByIndustryIdRequest = (industryId) => {
    return {
        type: DELETE_INDUSTRY_BY_INDUSTRY_ID_REQUEST,
        payload: { industryId },
    };
};
export const deleteIndustryByIndustryIdResponse = (message, responseType) => {
    return {
        type: DELETE_INDUSTRY_BY_INDUSTRY_ID_RESPONSE,
        payload: { message, responseType },
    };
};