import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    CardHeader,
    Input,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    ACTIVE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    SUBMIT,
    NATURE_APPLICABILITY_MANDATORY,
    NATURE_APPLICABILITY_TENTATIVE,
    ADD_NEW,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import {
    commonConfirmBox,
    permissionDeniedMessage,
    toastrMessage,
} from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    addLawRequest,
    getLawByLawIdRequest,
    updateLawViaUpdateRequest,
    getLegalUpdateReferenceIdRequest,
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import {
    customDateValidator,
    formatDateForApi,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import {
    customValidPhoneField,
    customValidTextField,
} from 'helpers/customValidators';
import {
    initialLawDataState,
    initialSectionDataState,
    sectionListColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import {
    makeLawSelectField,
    selectSortedEntityTypeList,
    selectSortedFacilityTypeList,
} from 'store/Masters/Law/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import AddEditSectionFormModal from '../../Masters/Law/AddEditSectionFormModal';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import { compareString } from 'helpers/generalUtils';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });

const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLegalUpdatesSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});

class LawDetailsForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customDateValidator(),
                customValidTextField(),
                customValidPhoneField()
            ),
        });
        this.state = {
            operationType: UPDATE,
            lawId: this.props.match.params.lawId
                ? parseInt(this.props.match.params.lawId)
                : '',
            pageHeaderName: this.props.match.params.lawId
                ? __t('page_headers.law_edit')
                : __t('page_headers.law_add'),
            lawData: initialLawDataState(),
            isSectionModalOpen: false,
            sectionOperationType: SUBMIT,
            sectionData: {},
            isSectionEdit: false,
            removeLawSections: [],
            legalUpdateReferenceDropdownList: [],
        };
    }
    componentDidMount() {
        if (this.state.lawId) {
            this.props.getLawByLawIdRequest(this.state.lawId);
        }
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getAllEntityTypeListRequest();
        this.props.getAllFacilityTypeListRequest();
        this.props.getLegalUpdateReferenceIdRequest(
            this.state.lawId,
            'law_edit'
        );
    }
    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest([countryId]);
        this.props.getCityListByStateRequest([]);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest([stateId]);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = {
                ...values,
                effectiveDate: values.effectiveDate
                    ? formatDateForApi(values.effectiveDate)
                    : '',
                removeLawSections: this.state.removeLawSections,
            };
            if (formValues.lawSections?.length === 0) {
                delete formValues.lawSections;
            }
            if (this.state.operationType === UPDATE) {
                this.props.updateLawViaUpdateRequest(
                    this.state.lawId,
                    formValues
                );
            } else {
                this.props.addLawRequest(formValues);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    addNewSection() {
        this.setState({
            isSectionModalOpen: true,
            sectionOperationType: SUBMIT,
            sectionData: initialSectionDataState(),
            isSectionEdit: false,
        });
    }
    editSection = (data) => {
        this.setState({
            isSectionModalOpen: true,
            sectionOperationType: UPDATE,
            sectionData: data,
            isSectionEdit: true,
        });
    };
    deleteSection = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.section_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeLawSections: [
                        ...this.state.removeLawSections,
                        data.id,
                    ],
                });
            }
            let sections = values.lawSections.filter((obj) => obj !== data);
            setFieldValue('lawSections', sections);
        }
    };
    closeSectionModal() {
        this.setState({
            isSectionModalOpen: false,
            sectionOperationType: SUBMIT,
            sectionData: {},
            isSectionEdit: false,
        });
    }
    generateSectionTable = (records, setFieldValue, values) => {
        let sectionRecords = [];
        sectionRecords = records.map((sectionRecord, i) => {
            let actions = (
                <div className="text-center">
                    {sectionRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => this.editSection(sectionRecord)}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteSection(
                                sectionRecord,
                                setFieldValue,
                                values
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            );
            return {
                sectionName: sectionRecord.sectionName,
                actions: actions,
            };
        });
        return sectionRecords.reverse();
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawApiResponse &&
            this.props.lawApiResponse !== nextprops.lawApiResponse
        ) {
            toastrMessage(
                nextprops.lawApiResponse.message,
                nextprops.lawApiResponse.responseType
            );
            if (nextprops.lawApiResponse.responseType === SUCCESS) {
                if (this.props.history.length != 1) {
                    this.props.history.goBack();
                } else {
                    this.props.history.push('/statutory-compliances');
                }
            }
        }
        if (
            nextprops.lawDetailsApiResponse &&
            this.props.lawDetailsApiResponse !== nextprops.lawDetailsApiResponse
        ) {
            let effective_date = nextprops.lawDetailsApiResponse.data.law
                .effectiveDate
                ? getDateObjectWithValueFromMoment(
                    nextprops.lawDetailsApiResponse.data.law.effectiveDate
                )
                : null;
            this.setState(
                {
                    operationType: UPDATE,
                    lawData: {
                        ...nextprops.lawDetailsApiResponse.data.law,
                        effectiveDate: effective_date,
                    },
                },
                () => {
                    this.getStateListByCountry(this.state.lawData.countryId);
                    if (this.state.lawData.stateId) {
                        this.getCityListByState(this.state.lawData.stateId);
                    }
                    if (this.state.lawData.industryId.length > 0) {
                        this.getSubIndustryListByIndustry(
                            this.state.lawData.industryId
                        );
                    }
                }
            );
        }
        if (
            nextprops.legalUpdateReferenceList &&
            this.props.legalUpdateReferenceList !==
            nextprops.legalUpdateReferenceList
        ) {
            if (nextprops.legalUpdateReferenceList.length === 0) {
                this.permissionDenied(__t('alerts.permission_denied'));
            } else {
                this.setState({
                    legalUpdateReferenceList:
                        nextprops.legalUpdateReferenceList,
                    legalUpdateReferenceDropdownList:
                        nextprops.legalUpdateReferenceList
                            .map((opt) => ({
                                value: opt.id,
                                label: opt.legalUpdateCode,
                            }))
                            .sort((item1, item2) =>
                                compareString(item1.label, item2.label)
                            ),
                });
            }
        }
    }
    permissionDenied = async (message) => {
        const response = await permissionDeniedMessage(message);
        if (response) {
            if (this.props.history.length != 1) {
                this.props.history.goBack();
            } else {
                this.props.history.push('/statutory-compliances');
            }
        }
    };
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Formik
                            initialValues={Object.assign(
                                {},
                                this.state.lawData
                            )}
                            enableReinitialize={true}
                            onSubmit={this.formSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>

                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4 className="with-back-btn">
                                                    {this.props.history
                                                        .length != 1 && (
                                                            <a
                                                                href={() => false}
                                                                className="dripicons-arrow-thin-left"
                                                                onClick={() => {
                                                                    this.props.history.goBack();
                                                                }}
                                                            >
                                                                { }
                                                            </a>
                                                        )}
                                                    <span>
                                                        &nbsp;
                                                        {this.state.lawData
                                                            ?.lawCode +
                                                            ' - ' +
                                                            this.state.lawData
                                                                ?.lawShortName}
                                                    </span>
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component={Select}
                                                        name="referenceId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state
                                                                .legalUpdateReferenceDropdownList
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'referenceId',
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.state.legalUpdateReferenceDropdownList?.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.referenceId
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.compliance.legal_update_reference'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.compliance.legal_update_reference'
                                                        ),
                                                        values.referenceId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.statutory_compliances.legal_update_reference_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="countryId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedCountryListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            let selected = opt
                                                                ? opt.value
                                                                : '';
                                                            setFieldValue(
                                                                'countryId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                            setFieldValue(
                                                                'stateId',
                                                                ''
                                                            );
                                                            setFieldValue(
                                                                'cityId',
                                                                []
                                                            );
                                                            if (
                                                                selected != ''
                                                            ) {
                                                                this.getStateListByCountry(
                                                                    opt.value
                                                                );
                                                            }
                                                        }}
                                                        value={this.props.sortedCountryListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.countryId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.country'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.country'
                                                        ),
                                                        values.countryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.country_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="stateId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedStateListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            let selected = opt
                                                                ? opt.value
                                                                : '';
                                                            setFieldValue(
                                                                'stateId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                            if (
                                                                selected != ''
                                                            ) {
                                                                this.getCityListByState(
                                                                    opt.value
                                                                );
                                                            }
                                                        }}
                                                        value={this.props.sortedStateListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.stateId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.state'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="cityId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedCityListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'cityId',
                                                                opt &&
                                                                opt.map(
                                                                    (x) =>
                                                                        x.value
                                                                )
                                                            );
                                                        }}
                                                        value={this.props.sortedCityListDropDown.filter(
                                                            (option) =>
                                                                values.cityId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.city'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component="textarea"
                                                        name="lawName"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.laws.law_name'
                                                        )}
                                                        value={values.lawName}
                                                        maxLength={1000}
                                                        rows="3"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.law_name'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.law_name'
                                                        ),
                                                        values.lawName,
                                                        'required|max:1000',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.law_name_required'
                                                                ),
                                                                max: __t(
                                                                    'validations.laws.law_name_max',
                                                                    {
                                                                        max: 1000,
                                                                    }
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="lawCategoryId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedLawCategoryListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'lawCategoryId',
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.props.sortedLawCategoryListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.lawCategoryId
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.law_category'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.law_category'
                                                        ),
                                                        values.lawCategoryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.law_category_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        name="lawShortName"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.laws.law_short_name'
                                                        )}
                                                        value={
                                                            values.lawShortName
                                                        }
                                                        maxLength={250}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.law_short_name'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.law_short_name'
                                                        ),
                                                        values.lawShortName,
                                                        'required|max:250',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.law_short_name_required'
                                                                ),
                                                                max: __t(
                                                                    'validations.laws.law_short_name_max',
                                                                    { max: 250 }
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4 w-100 sticky_label">
                                                    <Field
                                                        name="effectiveDate"
                                                        component={DatePicker}
                                                        isClearable={true}
                                                        className="form-control"
                                                        placeholderText={__t(
                                                            'form_labels.laws.effective_date'
                                                        )}
                                                        selected={
                                                            values.effectiveDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.effectiveDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                `effectiveDate`,
                                                                date
                                                            );
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        dropdownMode="select"
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.effective_date'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.effective_date'
                                                        ),
                                                        values.effectiveDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.effectiveDate
                                                            )
                                                            : '',
                                                        'required|customDate',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.effective_date_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-8">
                                                    <Field
                                                        component="textarea"
                                                        name="applicabilityDetails"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.laws.applicability_details'
                                                        )}
                                                        value={
                                                            values.applicabilityDetails
                                                        }
                                                        maxLength={1000}
                                                        minLength={3}
                                                        rows="2"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.applicability_details'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.applicability_details'
                                                        ),
                                                        values.applicabilityDetails,
                                                        'required|max:1000|min:3',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.applicability_details_required'
                                                                ),
                                                                min: __t(
                                                                    'validations.laws.applicability_details_min',
                                                                    { min: 3 }
                                                                ),
                                                                max: __t(
                                                                    'validations.laws.applicability_details_max',
                                                                    {
                                                                        max: 1000,
                                                                    }
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4 sticky_label radio_btn mb-0">
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="natureApplicability"
                                                            id="natureApplicability1"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'natureApplicability',
                                                                        NATURE_APPLICABILITY_MANDATORY
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'natureApplicability',
                                                                        NATURE_APPLICABILITY_TENTATIVE
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                NATURE_APPLICABILITY_MANDATORY
                                                            }
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="natureApplicability1"
                                                        >
                                                            {__t(
                                                                'form_labels.laws.nature_applicability_mandatory'
                                                            )}
                                                        </Label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="natureApplicability"
                                                            id="natureApplicability2"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue(
                                                                        'natureApplicability',
                                                                        NATURE_APPLICABILITY_TENTATIVE
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'natureApplicability',
                                                                        NATURE_APPLICABILITY_MANDATORY
                                                                    );
                                                                }
                                                            }}
                                                            value={
                                                                NATURE_APPLICABILITY_TENTATIVE
                                                            }
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="natureApplicability2"
                                                        >
                                                            {__t(
                                                                'form_labels.laws.nature_applicability_tentative'
                                                            )}
                                                        </Label>
                                                    </div>
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.nature_applicability'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="entityTypeId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedEntityTypeListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            let entityTypeId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'entityTypeId',
                                                                entityTypeId
                                                            );
                                                        }}
                                                        value={this.props.sortedEntityTypeListDropDown?.filter(
                                                            (option) =>
                                                                values.entityTypeId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.entity_types'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.entity_types'
                                                        ),
                                                        values.entityTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.entity_types_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="facilityTypeId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedFacilityTypeListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            let facilityTypeId =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'facilityTypeId',
                                                                facilityTypeId
                                                            );
                                                        }}
                                                        value={this.props.sortedFacilityTypeListDropDown?.filter(
                                                            (option) =>
                                                                values.facilityTypeId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.facility_types'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.facility_types'
                                                        ),
                                                        values.facilityTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.facility_types_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="industryId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedIndustryListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            let selectedValues =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'industryId',
                                                                selectedValues
                                                            );
                                                            this.getSubIndustryListByIndustry(
                                                                selectedValues
                                                            );
                                                        }}
                                                        value={this.props.sortedIndustryListDropDown?.filter(
                                                            (option) =>
                                                                values.industryId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.industry'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.industry'
                                                        ),
                                                        values.industryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.industry_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="subIndustryId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedSubIndustryListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'subIndustryId',
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : []
                                                            );
                                                        }}
                                                        value={this.props.sortedSubIndustryListDropDown?.filter(
                                                            (option) =>
                                                                values.subIndustryId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.sub_industry'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.sub_industry'
                                                        ),
                                                        values.subIndustryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.laws.sub_industry_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-8">
                                                    <Field
                                                        name="weblink"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.laws.weblink'
                                                        )}
                                                        value={values.weblink}
                                                        maxLength={255}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.weblink'
                                                        )}
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.weblink'
                                                        ),
                                                        values.weblink,
                                                        'url',
                                                        {
                                                            messages: {
                                                                url: __t(
                                                                    'validations.laws.weblink_valid'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6">
                                                    <Field
                                                        component="textarea"
                                                        name="governingAuthorityDetails"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.laws.governing_authority_details'
                                                        )}
                                                        value={
                                                            values.governingAuthorityDetails
                                                        }
                                                        maxLength={1000}
                                                        minLength={3}
                                                        rows="5"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.governing_authority_details'
                                                        )}
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.governing_authority_details'
                                                        ),
                                                        values.governingAuthorityDetails,
                                                        'max:1000|min:3',
                                                        {
                                                            messages: {
                                                                min: __t(
                                                                    'validations.laws.governing_authority_details_min',
                                                                    { max: 3 }
                                                                ),
                                                                max: __t(
                                                                    'validations.laws.governing_authority_details_max',
                                                                    {
                                                                        max: 1000,
                                                                    }
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6">
                                                    <Field
                                                        component="textarea"
                                                        name="exemptions"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.laws.exemptions'
                                                        )}
                                                        value={
                                                            values.exemptions
                                                        }
                                                        maxLength={5000}
                                                        minLength={3}
                                                        rows="5"
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.laws.exemptions'
                                                        )}
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.laws.exemptions'
                                                        ),
                                                        values.exemptions,
                                                        'max:5000|min:3',
                                                        {
                                                            messages: {
                                                                min: __t(
                                                                    'validations.laws.exemptions_min',
                                                                    { min: 3 }
                                                                ),
                                                                max: __t(
                                                                    'validations.laws.exemptions_max',
                                                                    {
                                                                        max: 5000,
                                                                    }
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-12 revision_checkbox ml-4">
                                                    <Input
                                                        type="checkbox"
                                                        id="isRevision"
                                                        name="isRevision"
                                                        onChange={(e) => {
                                                            const { checked } =
                                                                e.target;

                                                            setFieldValue(
                                                                'isRevision',
                                                                checked
                                                            );
                                                        }}
                                                        checked={
                                                            values.isRevision
                                                        }
                                                    />
                                                    <label className="form-check-label">
                                                        {__t(
                                                            'form_labels.common.version_update'
                                                        )}
                                                    </label>
                                                </FormGroup>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardHeader className="py-2">
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'form_labels.laws.sections'
                                                    )}
                                                </h4>
                                            </div>
                                            <div className="page-action">
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-sm float-right"
                                                    onClick={() =>
                                                        this.addNewSection()
                                                    }
                                                >
                                                    {ADD_NEW}
                                                </button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <AddEditSectionFormModal
                                                        isModalOpen={
                                                            this.state
                                                                .isSectionModalOpen
                                                        }
                                                        operationType={
                                                            this.state
                                                                .sectionOperationType
                                                        }
                                                        sectionData={
                                                            this.state
                                                                .sectionData
                                                        }
                                                        sectionList={
                                                            values.lawSections
                                                        }
                                                        onFormValid={(
                                                            locValues
                                                        ) => {
                                                            let sectionsList =
                                                                values.lawSections;
                                                            let updateDetailsIndex =
                                                                sectionsList.findIndex(
                                                                    (opt) =>
                                                                        opt.id ===
                                                                        locValues.id
                                                                );
                                                            if (
                                                                updateDetailsIndex >
                                                                -1 &&
                                                                this.state
                                                                    .isSectionEdit
                                                            ) {
                                                                sectionsList[
                                                                    updateDetailsIndex
                                                                ] = locValues;
                                                                setFieldValue(
                                                                    'lawSections',
                                                                    sectionsList
                                                                );
                                                            } else {
                                                                setFieldValue(
                                                                    'lawSections',
                                                                    [
                                                                        ...values.lawSections,
                                                                        locValues,
                                                                    ]
                                                                );
                                                            }
                                                            this.closeSectionModal();
                                                        }}
                                                        onModalDismiss={() =>
                                                            this.closeSectionModal()
                                                        }
                                                    />
                                                    <CustomisedDatatable
                                                        tableRecords={{
                                                            columns:
                                                                sectionListColumns,
                                                            rows: this.generateSectionTable(
                                                                values.lawSections,
                                                                setFieldValue,
                                                                values
                                                            ),
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup className="mb-0 d-flex justify-content-end">
                                                <div className="d-inline-block">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {
                                                            this.state
                                                                .operationType
                                                        }
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={() => {
                                                            this.props.history.push(
                                                                '/laws'
                                                            );
                                                        }}
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedIndustryListDropDown: selectSortedIndustryList(),
    sortedSubIndustryListDropDown: selectSortedSubIndustryList(),
    sortedLawCategoryListDropDown: selectSortedLawCategoryList(),
    sortedEntityTypeListDropDown: selectSortedEntityTypeList(),
    sortedFacilityTypeListDropDown: selectSortedFacilityTypeList(),
    lawApiResponse: makeLawSelectField('lawApiResponse'),
    lawDetailsApiResponse: makeLawSelectField('lawDetailsApiResponse'),
    legalUpdateReferenceList: makeLawComplianceSelectField(
        'legalUpdateReferenceList'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getAllEntityTypeListRequest,
    getAllFacilityTypeListRequest,
    addLawRequest,
    getLawByLawIdRequest,
    updateLawViaUpdateRequest,
    getLegalUpdateReferenceIdRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withLegalUpdatesReducer,
    withLegalUpdatesSaga,
    withRouter
)(LawDetailsForm);
