import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_COLLABORATOR_BY_TASK_ID_REQUEST,
    ADD_SUB_TASK_REQUEST,
    CHANGE_TASK_DETAILS_BY_TASK_ID_REQUEST,
    DELETE_COLLABORATOR_BY_TASK_ID_REQUEST,
    DELETE_SUB_TASK_BY_ID_REQUEST,
    GET_ALL_SUB_TASK_BY_TASK_ID_LIST_REQUEST,
    GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_REQUEST,
    GET_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
    GET_AUDIT_TASK_SEARCH_LIST_REQUEST,
    GET_COLLABORATOR_LIST_BY_TASK_ID_REQUEST,
    GET_REPORT_TASK_SEARCH_LIST_REQUEST,
    GET_REVIEW_TASK_SEARCH_LIST_REQUEST,
    GET_TASK_ACTIVITY_LIST_BY_TASK_ID_REQUEST,
    GET_TASK_DETAILS_BY_TASK_ID_REQUEST,
    GET_TASK_DOCUMENTS_BY_TASK_ID_REQUEST,
    GET_TASK_STATUS_LIST_REQUEST,
    REOPEN_TASK_BY_TASK_ID_REQUEST,
    REPORTING_TASK_BY_TASK_ID_REQUEST,
    SEND_FOR_AUDIT_TASK_BY_TASK_IDS_REQUEST,
    UPDATE_SUB_TASK_REQUEST,
    ADD_FILE_UPLOAD_REQUEST,
    UPDATE_QUICK_TASK_REQUEST,
    GET_KNOW_YOUR_COMPLIANCES_LIST_REQUEST,
    GET_TASK_USER_DETAILS_BY_TASK_ID_REQUEST,
    GET_USER_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addCollaboratorByTaskIdResponse,
    addSubTaskResponse,
    changeTaskDetailsByTaskIdResponse,
    deleteCollaboratorByTaskIdResponse,
    deleteSubTaskByIdResponse,
    getAllSubTaskByTaskIdListResponse,
    getAllTransactionalDocumentsListResponse,
    getAssignedTaskSearchListResponse,
    getAuditTaskSearchListResponse,
    getCollaboratorListByTaskIdResponse,
    getReportTaskSearchListResponse,
    getReviewTaskSearchListResponse,
    getTaskActivityListByTaskIdResponse,
    getTaskDetailsByTaskIdResponse,
    getTaskDocumentsByTaskIdResponse,
    getTaskStatusListResponse,
    reopenTaskByTaskIdResponse,
    reportingTaskByTaskIdResponse,
    sendForAuditTaskByTaskIdsResponse,
    updateSubTaskResponse,
    addFileUploadResponse,
    updateQuickTaskResponse,
    getKnowYourComplianceListResponse,
    getTaskUserDetailsByTaskIdResponse,
    getUserAssignedTaskSearchListResponse,
} from './actions';
import {
    ASSIGNED_SEARCH_TASKS,
    SUB_TASK,
    SUB_TASK_BY_TASK,
    TASK,
    TASK_ACTIVITY,
    TASK_DOCUMENT,
    TASK_REOPEN,
    TASK_REPORTING,
    TASK_SEND_FOR_AUDIT,
    TASK_STATUS,
    TASK_CHANGE_DETAILS,
    DOCUMENT_REPOSITORY_TRANSACTIONAL,
    ASSIGNED_REVIEW_SEARCH_TASK,
    ASSIGNED_REPORT_SEARCH_TASK,
    ASSIGNED_AUDIT_SEARCH_TASK,
    LIST_COLLABORATOR,
    ADD_COLLABORATOR,
    DELETE_COLLABORATOR,
    FILE_UPLOAD,
    TASK_QUICK_UPDATE,
    KNOW_YOUR_TASKS,
    TASK_USER_DETAILS,
    USER_ASSIGNED_SEARCH_TASKS,
} from 'helpers/apiConstants';

function* getTaskStatusList() {
    try {
        const response = yield call(invokeApi, 'get', TASK_STATUS, '');
        if (response.status) {
            yield put(getTaskStatusListResponse(response.data.list));
        } else {
            yield put(getTaskStatusListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAssignedTaskSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ASSIGNED_SEARCH_TASKS,
            data
        );
        if (pagination) {
            yield put(
                getAssignedTaskSearchListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getUserAssignedTaskSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            USER_ASSIGNED_SEARCH_TASKS,
            data
        );
        if (pagination) {
            yield put(
                getUserAssignedTaskSearchListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getKnowYourComplianceList({ payload: { data, pagination } }) {
    try {
        const response = yield call(invokeApi, 'post', KNOW_YOUR_TASKS, data);
        if (pagination) {
            yield put(
                getKnowYourComplianceListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getReportTaskSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ASSIGNED_REPORT_SEARCH_TASK,
            data
        );
        if (pagination) {
            yield put(
                getReportTaskSearchListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getReviewTaskSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ASSIGNED_REVIEW_SEARCH_TASK,
            data
        );
        if (pagination) {
            yield put(
                getReviewTaskSearchListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAuditTaskSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ASSIGNED_AUDIT_SEARCH_TASK,
            data
        );
        if (pagination) {
            yield put(
                getAuditTaskSearchListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getTaskDetailsByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(invokeApi, 'get', TASK + '/' + taskId, '');
        if (response.status) {
            yield put(
                getTaskDetailsByTaskIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getTaskDetailsByTaskIdResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}


function* getTaskUserDetailsByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(invokeApi, 'get', TASK_USER_DETAILS + '/' + taskId, '');
        if (response.status) {
            yield put(
                getTaskUserDetailsByTaskIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getTaskUserDetailsByTaskIdResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getTaskActivityListByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            TASK_ACTIVITY + '/' + taskId,
            ''
        );
        if (response.status) {
            yield put(getTaskActivityListByTaskIdResponse(response.data.list));
        } else {
            yield put(getTaskActivityListByTaskIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getTaskDocumentsByTaskId({ payload: { taskId, complianceId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            TASK_DOCUMENT + '/' + taskId + '/' + complianceId,
            ''
        );
        if (response.status) {
            yield put(
                getTaskDocumentsByTaskIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getTaskDocumentsByTaskIdResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* reopenTaskByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            TASK_REOPEN + '/' + taskId
        );
        if (response.status) {
            yield put(
                reopenTaskByTaskIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                reopenTaskByTaskIdResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* sendForAuditTaskByTaskIds({ payload: { taskIds, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            TASK_SEND_FOR_AUDIT,
            { ...data, taskId: taskIds }
        );
        if (response.status) {
            yield put(
                sendForAuditTaskByTaskIdsResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                sendForAuditTaskByTaskIdsResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* reportingTaskByTaskId({ payload: { taskId, data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'post',
            TASK_REPORTING + '/' + taskId,
            data,
            headers
        );
        if (response.status) {
            yield put(reportingTaskByTaskIdResponse(response.message, SUCCESS));
        } else {
            yield put(reportingTaskByTaskIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* changeTaskDetailsByTaskId({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            TASK_CHANGE_DETAILS + '/' + taskId,
            data
        );
        if (response.status) {
            yield put(
                changeTaskDetailsByTaskIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                changeTaskDetailsByTaskIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addSubTask({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', SUB_TASK, data, headers);
        if (response.status) {
            yield put(addSubTaskResponse(response.message, SUCCESS));
        } else {
            yield put(addSubTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllSubTaskByTaskIdList({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            SUB_TASK_BY_TASK + '/' + taskId,
            ''
        );
        if (response.status) {
            yield put(getAllSubTaskByTaskIdListResponse(response.data.list));
        } else {
            yield put(getAllSubTaskByTaskIdListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateSubTask({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', SUB_TASK, data, headers);
        if (response.status) {
            yield put(updateSubTaskResponse(response.message, SUCCESS));
        } else {
            yield put(updateSubTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteSubTaskById({ payload: { subTaskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            SUB_TASK + '/' + subTaskId
        );
        if (response.status) {
            yield put(deleteSubTaskByIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteSubTaskByIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getAllTransactionalDocumentsList() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            DOCUMENT_REPOSITORY_TRANSACTIONAL,
            ''
        );
        if (response.status) {
            yield put(
                getAllTransactionalDocumentsListResponse(response.data.list)
            );
        } else {
            yield put(getAllTransactionalDocumentsListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getCollaboratorListByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            LIST_COLLABORATOR + '/' + taskId,
            ''
        );
        if (response.status) {
            yield put(getCollaboratorListByTaskIdResponse(response.data.list));
        } else {
            yield put(getCollaboratorListByTaskIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addCollaboratorByTaskId({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            ADD_COLLABORATOR + '/' + taskId,
            data
        );
        if (response.status) {
            yield put(
                addCollaboratorByTaskIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                addCollaboratorByTaskIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteCollaboratorByTaskId({ payload: { taskId, userId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            DELETE_COLLABORATOR + '/' + taskId + '/' + userId
        );
        if (response.status) {
            yield put(
                deleteCollaboratorByTaskIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                deleteCollaboratorByTaskIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* addFileUpload({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'post',
            FILE_UPLOAD,
            data,
            headers
        );

        if (response.status) {
            yield put(
                addFileUploadResponse(response.message, SUCCESS, response.data)
            );
        } else {
            yield put(addFileUploadResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateQuickTask({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', TASK_QUICK_UPDATE, data);

        if (response.status) {
            yield put(updateQuickTaskResponse(response.message, SUCCESS));
        } else {
            yield put(updateQuickTaskResponse(response.errors, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchTask() {
    yield takeEvery(GET_TASK_STATUS_LIST_REQUEST, getTaskStatusList);
    yield takeEvery(
        GET_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
        getAssignedTaskSearchList
    );
    yield takeEvery(
        GET_USER_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
        getUserAssignedTaskSearchList
    );
    yield takeEvery(
        GET_KNOW_YOUR_COMPLIANCES_LIST_REQUEST,
        getKnowYourComplianceList
    );
    yield takeEvery(
        GET_REPORT_TASK_SEARCH_LIST_REQUEST,
        getReportTaskSearchList
    );
    yield takeEvery(
        GET_REVIEW_TASK_SEARCH_LIST_REQUEST,
        getReviewTaskSearchList
    );
    yield takeEvery(GET_AUDIT_TASK_SEARCH_LIST_REQUEST, getAuditTaskSearchList);
    yield takeEvery(
        GET_TASK_DETAILS_BY_TASK_ID_REQUEST,
        getTaskDetailsByTaskId
    );
    yield takeEvery(GET_TASK_USER_DETAILS_BY_TASK_ID_REQUEST, getTaskUserDetailsByTaskId);
    yield takeEvery(
        GET_TASK_ACTIVITY_LIST_BY_TASK_ID_REQUEST,
        getTaskActivityListByTaskId
    );
    yield takeEvery(
        GET_TASK_DOCUMENTS_BY_TASK_ID_REQUEST,
        getTaskDocumentsByTaskId
    );
    yield takeEvery(REOPEN_TASK_BY_TASK_ID_REQUEST, reopenTaskByTaskId);
    yield takeEvery(
        SEND_FOR_AUDIT_TASK_BY_TASK_IDS_REQUEST,
        sendForAuditTaskByTaskIds
    );
    yield takeEvery(REPORTING_TASK_BY_TASK_ID_REQUEST, reportingTaskByTaskId);
    yield takeEvery(
        CHANGE_TASK_DETAILS_BY_TASK_ID_REQUEST,
        changeTaskDetailsByTaskId
    );

    yield takeEvery(ADD_SUB_TASK_REQUEST, addSubTask);
    yield takeEvery(
        GET_ALL_SUB_TASK_BY_TASK_ID_LIST_REQUEST,
        getAllSubTaskByTaskIdList
    );
    yield takeEvery(UPDATE_SUB_TASK_REQUEST, updateSubTask);
    yield takeEvery(DELETE_SUB_TASK_BY_ID_REQUEST, deleteSubTaskById);

    yield takeEvery(
        GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_REQUEST,
        getAllTransactionalDocumentsList
    );
    yield takeEvery(
        GET_COLLABORATOR_LIST_BY_TASK_ID_REQUEST,
        getCollaboratorListByTaskId
    );
    yield takeEvery(
        ADD_COLLABORATOR_BY_TASK_ID_REQUEST,
        addCollaboratorByTaskId
    );
    yield takeEvery(
        DELETE_COLLABORATOR_BY_TASK_ID_REQUEST,
        deleteCollaboratorByTaskId
    );
    yield takeEvery(ADD_FILE_UPLOAD_REQUEST, addFileUpload);
    yield takeEvery(UPDATE_QUICK_TASK_REQUEST, updateQuickTask);
}
function* taskSaga() {
    yield all([fork(watchTask)]);
}
export default taskSaga;
