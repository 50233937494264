import React, { Component } from 'react';
import {
    Card,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADD_NEW } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getProjectListRequest,
    updateEntityStatusByEntityIdRequest,
    deleteProjectByProjectIdRequest,
    activeOrDeactiveProjectRequest,
    closeProjectRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { listColumns } from './CommonFunctions';

import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { makeProjectsSelectField } from 'store/Masters/Projects/selector';
import projectsReducer from 'store/Masters/Projects/reducer';
import ProjectsSaga from 'store/Masters/Projects/saga';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';

const projectsKey = 'projects';
const withProjectsReducer = injectReducer({
    key: projectsKey,
    reducer: projectsReducer,
});
const withProjectsSaga = injectSaga({
    key: projectsKey,
    saga: ProjectsSaga,
});

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allProjectList: [],
            isAuditModalOpen: false,
            auditId: '',
            projectId: '',
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
        };
    }
    componentDidMount() {
        //Retrieve entities list
        this.props.getProjectListRequest();
    }
    editProjectDetails = (record) => {
        this.props.history.push('/projects/manage/edit/' + record.id);
    };
    projectDetails = (record) => {
        this.props.history.push('/projects/manage/details/' + record.id);
    };
    addProjectDetails = () => {
        this.props.history.push('/projects/manage/add');
    };
    deleteProject = async (projectId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.project_delete')
        );
        if (response) {
            this.props.deleteProjectByProjectIdRequest(projectId);
        }
    };
    activeOrDeactiveProject = async (projectId, isActive) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.project_status')
        );
        if (response) {
            this.props.activeOrDeactiveProjectRequest(projectId, { isActive: isActive === true ? false : true });
        }
    };
    closeProject = async (projectId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.project_close')
        );
        if (response) {
            this.props.closeProjectRequest(projectId);
        }
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let projectRecords = [];
        projectRecords = records.map((projectData, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title={__t('common.edit')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => this.editProjectDetails(projectData)}
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>

                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteProject(projectData.id);
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>

                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(projectData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );

            return {
                title: projectData.title,
                companyGroupName: projectData.companyGroup?.companyGroupName,
                startDate: projectData.startDate,
                endDate: projectData.endDate != '' ? projectData.endDate : '-',
                description: projectData.description,
                isActive: projectData.isActive ? 'Yes' : ' No',
                isClosed: projectData.isClosed ? 'Yes' : ' No',
                actions: actions,
            };
        });
        return projectRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.projectList &&
            this.props.projectList !== nextprops.projectList
        ) {
            this.setState({
                allProjectList: nextprops.projectList,
                projectList: this.generateTable(nextprops.projectList),
            });
        }

        if (
            nextprops.deleteProjectApiResponse &&
            this.props.deleteProjectApiResponse !==
            nextprops.deleteProjectApiResponse
        ) {
            toastrMessage(
                nextprops.deleteProjectApiResponse.message,
                nextprops.deleteProjectApiResponse.responseType
            );
        }

        if (
            nextprops.closeProjectApiResoponse &&
            this.props.closeProjectApiResoponse !==
            nextprops.closeProjectApiResoponse
        ) {
            toastrMessage(
                nextprops.closeProjectApiResoponse.message,
                nextprops.closeProjectApiResoponse.responseType
            );
            this.props.getProjectListRequest();
        }

        if (
            nextprops.activeOrDeactiveProjectApiResponse &&
            this.props.activeOrDeactiveProjectApiResponse !==
            nextprops.activeOrDeactiveProjectApiResponse
        ) {
            toastrMessage(
                nextprops.activeOrDeactiveProjectApiResponse.message,
                nextprops.activeOrDeactiveProjectApiResponse.responseType
            );
        }
    }
    searchText(e) {
        let formValues = {
            searchText: e.target.value,
        };
        this.props.getProjectListRequest(formValues);
    }
    actionToggle(taskId) {
        this.setState({
            projectId: this.state.projectId === taskId ? 0 : taskId,
        });
    }

    render() {
        const data = {
            columns: listColumns,
            rows: this.state.projectList,
        };
        return (
            <React.Fragment>
                <div className="project_list_row">
                    <div className="common-header">
                        <div className="common-flex-box">
                            <div className="page-header">
                                <h4>{__t('page_headers.projects_list')}</h4>
                            </div>
                            <div className="common-flex-box">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        onChange={(e) => this.searchText(e)}
                                    ></input>
                                </div>
                                <a
                                    href={() => false}
                                    className="btn btn-primary waves-effect waves-light float-right ml-2"
                                    onClick={() => {
                                        this.addProjectDetails();
                                    }}
                                >
                                    <i className="fas fa-plus"> </i> {ADD_NEW}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="project-list-data">
                        <ul className="mt-3">
                            <li>
                                <a
                                    href={() => false}
                                    onClick={() => {
                                        this.addProjectDetails();
                                    }}
                                >
                                    <Card className="card text-center">
                                        <svg
                                            className="mb-2"
                                            width="71"
                                            height="71"
                                            viewBox="0 0 94 94"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.5"
                                                y="0.5"
                                                width="93"
                                                height="93"
                                                rx="9.5"
                                                stroke="black"
                                                strokeDasharray="5 5"
                                            />
                                            <path
                                                d="M48.5 32L48.5 61"
                                                stroke="black"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M63 46.5L34 46.5"
                                                stroke="black"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                        <h5 className="add-card">
                                            Create Project
                                        </h5>
                                    </Card>
                                </a>
                            </li>
                            {this.state.allProjectList?.map((project, i) => {
                                let projectOwners = project.projectTeams.filter((teamUser) => teamUser.userId === this.state.loginUserId && teamUser.isOwner);
                                let displayProject = true;
                                if (projectOwners.length == 0 && project.isActive === false) {
                                    displayProject = false;
                                }
                                return displayProject && (
                                    <li
                                        key={'p_' + i}
                                        className={
                                            project.isClosed === false
                                                ? ''
                                                : 'disabled'
                                        }
                                    >
                                        {project.isClosed === false && (
                                            <Dropdown
                                                isOpen={
                                                    this.state.projectId ===
                                                    project.id
                                                }
                                                toggle={() =>
                                                    this.actionToggle(project.id)
                                                }
                                                className="project-list-dropdown"
                                                tag="div"
                                            >
                                                <DropdownToggle
                                                    tag="a"
                                                    title="Action"
                                                    className="btn btn-link"
                                                >
                                                    <svg
                                                        width="20"
                                                        height="4"
                                                        viewBox="0 0 20 4"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M16 2C16 1.60444 16.1173 1.21776 16.3371 0.88886C16.5568 0.559962 16.8692 0.303617 17.2346 0.152242C17.6001 0.000866562 18.0022 -0.0387401 18.3902 0.0384303C18.7781 0.115601 19.1345 0.306082 19.4142 0.585787C19.6939 0.865492 19.8844 1.22186 19.9616 1.60982C20.0387 1.99778 19.9991 2.39992 19.8478 2.76537C19.6964 3.13082 19.44 3.44318 19.1111 3.66294C18.7822 3.8827 18.3956 4 18 4C17.4696 4 16.9609 3.78929 16.5858 3.41421C16.2107 3.03914 16 2.53043 16 2V2ZM8 2C8 1.60444 8.1173 1.21776 8.33706 0.88886C8.55682 0.559962 8.86918 0.303617 9.23463 0.152242C9.60008 0.000866562 10.0022 -0.0387401 10.3902 0.0384303C10.7781 0.115601 11.1345 0.306082 11.4142 0.585787C11.6939 0.865492 11.8844 1.22186 11.9616 1.60982C12.0387 1.99778 11.9991 2.39992 11.8478 2.76537C11.6964 3.13082 11.44 3.44318 11.1111 3.66294C10.7822 3.8827 10.3956 4 10 4C9.46957 4 8.96086 3.78929 8.58579 3.41421C8.21071 3.03914 8 2.53043 8 2V2ZM0 2C0 1.60444 0.117298 1.21776 0.337061 0.88886C0.556824 0.559962 0.869181 0.303617 1.23463 0.152242C1.60009 0.000866562 2.00222 -0.0387401 2.39018 0.0384303C2.77814 0.115601 3.13451 0.306082 3.41421 0.585787C3.69392 0.865492 3.8844 1.22186 3.96157 1.60982C4.03874 1.99778 3.99913 2.39992 3.84776 2.76537C3.69638 3.13082 3.44004 3.44318 3.11114 3.66294C2.78224 3.8827 2.39556 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2H0Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    className="dropdown-menu"
                                                    right
                                                >
                                                    <DropdownItem
                                                        tag="a"
                                                        onClick={() =>
                                                            this.editProjectDetails(
                                                                project
                                                            )
                                                        }
                                                        className="text-primary notify-all"
                                                    >
                                                        <i className="fas fa-pencil-alt"></i>{' '}
                                                        Edit
                                                    </DropdownItem>
                                                    {project.projectTeams.filter((teamUser) => teamUser.userId === this.state.loginUserId && teamUser.isOwner).length > 0 && (
                                                        <DropdownItem
                                                            tag="a"
                                                            onClick={() =>
                                                                this.activeOrDeactiveProject(
                                                                    project.id, project.isActive
                                                                )
                                                            }
                                                            className="text-primary notify-all"
                                                        >
                                                            <i className="fas fa-wrench"></i>{' '}
                                                            {project.isActive === true ? <a href={() => false}>Inactive</a> : <a href={() => false}>Active</a>}
                                                        </DropdownItem>
                                                    )}
                                                    <DropdownItem
                                                        tag="a"
                                                        onClick={() => {
                                                            this.deleteProject(
                                                                project.id
                                                            );
                                                        }}
                                                        className="text-primary notify-all"
                                                    >
                                                        <i className="fas fa-trash-alt"></i>{' '}
                                                        Delete
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        tag="a"
                                                        onClick={() => {
                                                            this.closeProject(
                                                                project.id
                                                            );
                                                        }}
                                                        className="text-primary notify-all"
                                                    >
                                                        <i className="fa fa-times"></i>{' '}
                                                        Close
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        )}

                                        <a
                                            href={() => false}
                                            onClick={() =>
                                                this.projectDetails(project)
                                            }
                                        >
                                            <Card
                                                className="card text-center"
                                                style={{
                                                    width: '15rem',
                                                }}
                                            >
                                                <svg
                                                    className="mb-2"
                                                    width="71"
                                                    height="71"
                                                    viewBox="0 0 71 71"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        width="71"
                                                        height="71"
                                                        rx="10"
                                                        fill="#4F2D7F"
                                                        fillOpacity="0.24"
                                                    />
                                                    <path
                                                        d="M21.6946 17.75L57.1946 17.75"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                    />
                                                    <path
                                                        d="M21.6946 29.5833L41.4168 29.5833"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                    />
                                                    <path
                                                        d="M21.6946 41.4167L49.3057 41.4168"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                    />
                                                    <path
                                                        d="M21.6946 53.25L33.5279 53.25"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                    />
                                                    <rect
                                                        x="10.5879"
                                                        y="14.5322"
                                                        width="5.19421"
                                                        height="5.19421"
                                                        rx="2.59711"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="10.5879"
                                                        y="26.3655"
                                                        width="5.19421"
                                                        height="5.19421"
                                                        rx="2.59711"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="10.5879"
                                                        y="38.199"
                                                        width="5.19421"
                                                        height="5.19421"
                                                        rx="2.59711"
                                                        fill="white"
                                                    />
                                                    <rect
                                                        x="10.5879"
                                                        y="50.0322"
                                                        width="5.19421"
                                                        height="5.19421"
                                                        rx="2.59711"
                                                        fill="white"
                                                    />
                                                </svg>
                                                <a
                                                    href={() => false}
                                                    title={__t(
                                                        'form_labels.projects.view_task'
                                                    )}
                                                >
                                                    <div className="card-body">
                                                        <h5 className="card-text">
                                                            {project.title}
                                                        </h5>
                                                        <p className="card-subtitle">
                                                            {
                                                                project.projectTasks
                                                                    .length
                                                            }{' '}
                                                            Tasks due soon
                                                        </p>
                                                    </div>
                                                </a>
                                            </Card>
                                        </a>
                                    </li>
                                )
                            }
                            )}
                        </ul>
                    </div>

                </div>
                <AuditLogModal
                    exportBtn={true}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'project'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    deleteProjectApiResponse: makeProjectsSelectField(
        'deleteProjectApiResponse'
    ),
    projectList: makeProjectsSelectField('projectList'),
    closeProjectApiResoponse: makeProjectsSelectField(
        'closeProjectApiResoponse'
    ),
    activeOrDeactiveProjectApiResponse: makeProjectsSelectField(
        'activeOrDeactiveProjectApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getProjectListRequest,
    updateEntityStatusByEntityIdRequest,
    deleteProjectByProjectIdRequest,
    activeOrDeactiveProjectRequest,
    closeProjectRequest,
});
export default compose(
    withConnect,
    withProjectsReducer,
    withProjectsSaga,
    withRouter
)(Index);
