import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import __t from 'i18n/translator';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    Container,
    Input,
} from 'reactstrap';

import { Field, Form, Formik } from 'formik';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    formatDateForApi,
    getDateObjectWithValueFromMoment,
    getDateStringFromDateObject,
    getFullDateTimeFromDateTimeString,
} from 'helpers/dateTimeHelper';
import {
    ACTIVE,
    CLEAR,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
    DEFAULT_PAGE_LENGTH_FOR_NEWS,
    FILTER,
    SUCCESS,
} from 'constants/commonConstants';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getNewsWithSearchRequest,
} from 'store/actions';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import Select from 'react-select';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { makeNewsSelectField } from 'store/News/selector';
import newsSaga from 'store/News/saga';
import newsReducer from 'store/News/reducer';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
const newsKey = 'news';
const withNewsReducer = injectReducer({
    key: newsKey,
    reducer: newsReducer,
});
const withNewsSaga = injectSaga({ key: newsKey, saga: newsSaga });
const initialNewsFilterDataState = (fromDate = '') => {
    return {
        searchText: '',
        countryId: [],
        stateId: [],
        cityId: [],
        lawCategoryId: [],
        fromDate: fromDate,
        toDate: '',
        industryId: [],
        subIndustryId: [],
    };
};
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: initialNewsFilterDataState((this.props?.defaultDate) ? getDateStringFromDateObject(this.props?.defaultDate) : ''),
            sortedStateListDropDown: [],
            sortedCityListDropDown: [],
            currentPage: 1,
            hasNextPage: false,
            pageSize: DEFAULT_PAGE_LENGTH_FOR_NEWS,
            newsList: [],
            orderByKey: 'createdAt',
            orderByValue: 'desc',
        };
    }
    componentDidMount() {
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.getNewsByPage(this.state.currentPage);
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds);
        this.props.getCityListByStateRequest([]);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    getNewsByPage = (page, searchData = {}) => {
        let searchDataValues = {
            ...searchData,
            fromDate: searchData.fromDate
                ? formatDateForApi(searchData.fromDate)
                : '',
            toDate: searchData.toDate
                ? formatDateForApi(searchData.toDate)
                : '',
        };
        this.props.getNewsWithSearchRequest({
            limit: this.state.pageSize,
            page: page,
            orderBy: {
                key: this.state.orderByKey,
                value: this.state.orderByValue,
            },
            filter: searchDataValues,
        });
    };
    formSubmit = (values) => {
        this.setState(
            {
                filters: values,
            },
            () => {
                this.getNewsByPage(1, this.state.filters);
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                filters: initialNewsFilterDataState(),
            },
            () => {
                this.getNewsByPage(1, this.state.filters);
            }
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.sortedStateListDropDown &&
            this.props.sortedStateListDropDown !==
            nextprops.sortedStateListDropDown
        ) {
            this.setState({
                sortedStateListDropDown: nextprops.sortedStateListDropDown,
            });
        }
        if (
            nextprops.sortedCityListDropDown &&
            this.props.sortedCityListDropDown !==
            nextprops.sortedCityListDropDown
        ) {
            this.setState({
                sortedCityListDropDown: nextprops.sortedCityListDropDown,
            });
        }
        if (
            nextprops.newsApiResponse &&
            nextprops.newsApiResponse !== this.props.newsApiResponse
        ) {
            if (nextprops.newsApiResponse.responseType === SUCCESS) {
                this.setState({
                    currentPage: nextprops.newsApiResponse.data.currentPage,
                    newsList:
                        nextprops.newsApiResponse.data.currentPage == 1
                            ? nextprops.newsApiResponse.data.news
                            : [
                                ...this.state.newsList,
                                ...nextprops.newsApiResponse.data.news,
                            ],
                    hasNextPage: nextprops.newsApiResponse.data.hasNextPage,
                });
            }
        }
    }
    render() { 
        return (
            <React.Fragment>

                <Container fluid className="news_alerts">
                    <Row className="news_title mb-2">
                        <Col lg="3">
                            <h4 className="generatereport_title mb-0">
                                {__t('page_headers.news_list')}
                            </h4>
                        </Col>
                        <Col lg="8" className="d-flex pr-0">
                            <div className="search_box">
                                <Input
                                    type="search"
                                    placeholder="Keywords..."
                                    onKeyPress={(e) => {
                                        (e.keyCode === 13 ||
                                            e.key === 'Enter') &&
                                            e.preventDefault();
                                    }}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                filters: {
                                                    ...this.state.filters,
                                                    searchText: e.target.value
                                                        .trim()
                                                        .toLowerCase(),
                                                },
                                            },
                                            () => {
                                                this.getNewsByPage(
                                                    1,
                                                    this.state.filters
                                                );
                                            }
                                        );
                                    }}
                                />
                                <svg
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                        </Col>
                        <Col lg="1" className="d-flex justify-content-end pl-0">
                            <Button
                                className="py-2"
                                onClick={(e) => {
                                    this.setState(
                                        {
                                            orderByValue:
                                                this.state.orderByValue ==
                                                    'desc'
                                                    ? 'asc'
                                                    : 'desc',
                                        },
                                        () => {
                                            this.getNewsByPage(
                                                1,
                                                this.state.filters
                                            );
                                        }
                                    );
                                }}
                            >
                                Date
                                {this.state.orderByValue == 'desc' ? (
                                    <i className="fas fa-sort-alpha-up"></i>
                                ) : (
                                    <i className="fas fa-sort-alpha-down"></i>
                                )}
                                { }

                            </Button>
                        </Col>
                    </Row>
                    <Row className="news_contant">
                        <Col lg="3" className="filter_col">
                            <Formik
                                initialValues={this.state.filters}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Row className="m-0">
                                            <Col lg="12" className="">
                                                <h5>Customize Search</h5>
                                            </Col>

                                            <FormGroup className="col-lg-12">
                                                <Label>
                                                    {__t(
                                                        'form_labels.news.filters.country'
                                                    )}{' '}
                                                </Label>
                                                <Field
                                                    component={Select}
                                                    name="countryId"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedCountryListDropDown
                                                    }
                                                    onChange={(opt) => {
                                                        let selected = opt
                                                            ? opt.map(
                                                                (x) => x.value
                                                            )
                                                            : [];
                                                        setFieldValue(
                                                            'countryId',
                                                            selected
                                                        );
                                                        setFieldValue(
                                                            'stateId',
                                                            []
                                                        );
                                                        setFieldValue(
                                                            'cityId',
                                                            []
                                                        );
                                                        this.getStateListByCountry(
                                                            selected
                                                        );
                                                    }}
                                                    value={this.props.sortedCountryListDropDown.filter(
                                                        (option) =>
                                                            values.countryId.includes(
                                                                option.value
                                                            )
                                                    )}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    allowSelectAll={true}
                                                    components={{
                                                        Option,
                                                        MultiValue,
                                                        animatedComponents,
                                                    }}
                                                    key="countryId"
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-lg-12">
                                                <Label>
                                                    {__t(
                                                        'form_labels.news.filters.state'
                                                    )}
                                                </Label>
                                                <Field
                                                    component={Select}
                                                    name="stateId[]"
                                                    className="form-control selectbox"
                                                    options={this.state.sortedStateListDropDown.filter(
                                                        (opt) => opt.value != -1
                                                    )}
                                                    onChange={(opt) => {
                                                        let selected = opt
                                                            ? opt.map(
                                                                (x) => x.value
                                                            )
                                                            : [];
                                                        setFieldValue(
                                                            'stateId',
                                                            selected
                                                        );
                                                        setFieldValue(
                                                            'cityId',
                                                            []
                                                        );
                                                        this.getCityListByState(
                                                            selected
                                                        );
                                                    }}
                                                    value={this.state.sortedStateListDropDown.filter(
                                                        (option) =>
                                                            values.stateId.includes(
                                                                option.value
                                                            )
                                                    )}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    allowSelectAll={true}
                                                    components={{
                                                        Option,
                                                        MultiValue,
                                                        animatedComponents,
                                                    }}
                                                    key="stateId"
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-lg-12">
                                                <Label>
                                                    {__t(
                                                        'form_labels.news.filters.city'
                                                    )}
                                                </Label>
                                                <Field
                                                    component={
                                                        CustomMultiSelect
                                                    }
                                                    name="cityId[]"
                                                    className="form-control selectbox"
                                                    options={this.state.sortedCityListDropDown.filter(
                                                        (opt) => opt.value != -1
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'cityId',
                                                            opt &&
                                                            opt.map(
                                                                (x) =>
                                                                    x.value
                                                            )
                                                        );
                                                    }}
                                                    value={this.state.sortedCityListDropDown.filter(
                                                        (option) =>
                                                            values.cityId?.includes(
                                                                option.value
                                                            )
                                                    )}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    allowSelectAll={true}
                                                    components={{
                                                        Option,
                                                        MultiValue,
                                                        animatedComponents,
                                                    }}
                                                    key="cityId"
                                                />
                                            </FormGroup>

                                            <FormGroup className="col-lg-12 formgroup_height">
                                                <Label>
                                                    {__t(
                                                        'form_labels.news.filters.law_category'
                                                    )}
                                                </Label>
                                                <Field
                                                    component={
                                                        CustomMultiSelect
                                                    }
                                                    name="lawCategoryId[]"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedLawCategoryListDropDown
                                                    }
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'lawCategoryId',
                                                            opt &&
                                                            opt.map(
                                                                (x) =>
                                                                    x.value
                                                            )
                                                        );
                                                    }}
                                                    value={this.props.sortedLawCategoryListDropDown.filter(
                                                        (option) =>
                                                            values.lawCategoryId?.includes(
                                                                option.value
                                                            )
                                                    )}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    allowSelectAll={true}
                                                    components={{
                                                        Option,
                                                        MultiValue,
                                                        animatedComponents,
                                                    }}
                                                    key="lawCategoryId"
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-6 pr-2">
                                                <Label>
                                                    {__t(
                                                        'form_labels.news.filters.from_period'
                                                    )}
                                                </Label>
                                                <Field
                                                    component={DatePicker}
                                                    isClearable={true}
                                                    className="form-control"
                                                    name="fromDate"
                                                    selected={
                                                        values.fromDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.fromDate
                                                            )
                                                            : null
                                                    }
                                                    dateFormat={
                                                        DATE_FORMAT_DATEPICKER
                                                    }
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'fromDate',
                                                            date
                                                        );
                                                        let dateCheck =
                                                            getDateObjectWithValueFromMoment(
                                                                values.toDate
                                                            ) <
                                                                getDateObjectWithValueFromMoment(
                                                                    date
                                                                )
                                                                ? true
                                                                : false;
                                                        if (dateCheck) {
                                                            setFieldValue(
                                                                'toDate',
                                                                ''
                                                            );
                                                        }
                                                    }}
                                                    showMonthDropdown={true}
                                                    showYearDropdown={true}
                                                    dropdownMode="select"
                                                    placeholderText={
                                                        DATE_FORMAT_PLACEHOLDER
                                                    }
                                                    onFocus={(e) =>
                                                        e.target.blur()
                                                    }
                                                ></Field>
                                            </FormGroup>
                                            <FormGroup className="col-6 pl-2">
                                                <Label>
                                                    {__t(
                                                        'form_labels.news.filters.to_period'
                                                    )}
                                                </Label>
                                                <Field
                                                    component={DatePicker}
                                                    isClearable={true}
                                                    className="form-control"
                                                    name="toDate"
                                                    selected={
                                                        values.toDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.toDate
                                                            )
                                                            : null
                                                    }
                                                    dateFormat={
                                                        DATE_FORMAT_DATEPICKER
                                                    }
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'toDate',
                                                            date
                                                        );
                                                    }}
                                                    showMonthDropdown={true}
                                                    showYearDropdown={true}
                                                    dropdownMode="select"
                                                    placeholderText={
                                                        DATE_FORMAT_PLACEHOLDER
                                                    }
                                                    onFocus={(e) =>
                                                        e.target.blur()
                                                    }
                                                    minDate={
                                                        values?.fromDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values?.fromDate
                                                            )
                                                            : ''
                                                    }
                                                ></Field>
                                            </FormGroup>
                                            <FormGroup className="col-12 d-flex justify-content-center mb-3 mt-2">
                                                <Button
                                                    type="reset"
                                                    className="mr-2 w-50"
                                                    color="outline-secondary"
                                                    onClick={this.resetForm}
                                                >
                                                    {CLEAR}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="ml-2 w-50 d-flex align-items-center justify-content-center"
                                                >
                                                    <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M17 2C17 1.44772 16.5523 1 16 1H2C1.44772 1 1 1.44772 1 2V3.58579C1 3.851 1.10536 4.10536 1.29289 4.29289L5.7071 8.7071C5.89464 8.89464 5.99999 9.14899 5.99999 9.41421V16.3063C5.99999 16.6476 6.33434 16.8886 6.65811 16.7806L11.6581 15.114C11.8623 15.0459 12 14.8548 12 14.6396V9.41422C12 9.149 12.1054 8.89465 12.2929 8.70711L16.7071 4.29289C16.8946 4.10536 17 3.851 17 3.58579V2Z"
                                                            fill="currentColor"
                                                            fillOpacity="0.25"
                                                        />
                                                        <path
                                                            d="M6.65811 16.7806L6.84784 17.3498H6.84784L6.65811 16.7806ZM11.6581 15.114L11.8478 15.6832H11.8478L11.6581 15.114ZM16.7071 4.29289L17.1314 4.71716L16.7071 4.29289ZM12.2929 8.70711L11.8686 8.28285L12.2929 8.70711ZM2 1.6H16V0.4H2V1.6ZM1.6 3.58579V2H0.4V3.58579H1.6ZM6.13136 8.28284L1.71716 3.86863L0.868629 4.71716L5.28284 9.13136L6.13136 8.28284ZM5.39999 9.41421V16.3063H6.59999V9.41421H5.39999ZM5.39999 16.3063C5.39999 17.0571 6.13556 17.5873 6.84784 17.3498L6.46837 16.2114C6.53312 16.1898 6.59999 16.238 6.59999 16.3063H5.39999ZM6.84784 17.3498L11.8478 15.6832L11.4684 14.5448L6.46837 16.2114L6.84784 17.3498ZM11.8478 15.6832C12.297 15.5335 12.6 15.1131 12.6 14.6396H11.4C11.4 14.5966 11.4275 14.5584 11.4684 14.5448L11.8478 15.6832ZM12.6 14.6396V9.41422H11.4V14.6396H12.6ZM16.2828 3.86863L11.8686 8.28285L12.7172 9.13138L17.1314 4.71716L16.2828 3.86863ZM16.4 2V3.58579H17.6V2H16.4ZM17.1314 4.71716C17.4314 4.4171 17.6 4.01013 17.6 3.58579H16.4C16.4 3.69187 16.3579 3.79361 16.2828 3.86863L17.1314 4.71716ZM12.6 9.41422C12.6 9.30813 12.6421 9.20639 12.7172 9.13138L11.8686 8.28285C11.5686 8.58291 11.4 8.98987 11.4 9.41422H12.6ZM5.28284 9.13136C5.35785 9.20638 5.39999 9.30812 5.39999 9.41421H6.59999C6.59999 8.98986 6.43142 8.58289 6.13136 8.28284L5.28284 9.13136ZM0.4 3.58579C0.4 4.01013 0.568571 4.4171 0.868629 4.71716L1.71716 3.86863C1.64214 3.79361 1.6 3.69187 1.6 3.58579H0.4ZM16 1.6C16.2209 1.6 16.4 1.77909 16.4 2H17.6C17.6 1.11634 16.8837 0.4 16 0.4V1.6ZM2 0.4C1.11634 0.4 0.4 1.11634 0.4 2H1.6C1.6 1.77909 1.77909 1.6 2 1.6V0.4Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                    {FILTER}
                                                </Button>
                                            </FormGroup>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                        <Col lg="9" className="content_col px-2">
                            <ul className="news_card">
                                {this.state.newsList.length > 0 &&
                                    this.state.newsList.map((news, key) => {
                                        return (
                                            <li>
                                                <Link
                                                    to={
                                                        '/legal-watch-dog/view-details/' +
                                                        news.id
                                                    }
                                                    title="Click here to read more"
                                                >
                                                    <div className="news_tags">
                                                        <div>
                                                            <span
                                                                class={
                                                                    'badge ' +
                                                                    (news
                                                                        .releaseType
                                                                        .releaseTypeCode !=
                                                                        ''
                                                                        ? news
                                                                            .releaseType
                                                                            .releaseTypeCode +
                                                                        '_badge'
                                                                        : 'news_badge')
                                                                }
                                                            >
                                                                {
                                                                    news
                                                                        ?.releaseType
                                                                        ?.releaseTypeName
                                                                }
                                                            </span>
                                                            <p>
                                                                {this.state
                                                                    .newsDetails
                                                                    ?.country
                                                                    ?.isoCode2 !=
                                                                    '' ? (
                                                                    <img
                                                                        className="country-flag"
                                                                        src={`/data/flags/${news?.country?.isoCode2}.svg`}
                                                                        alt='flag'
                                                                    />
                                                                ) : (
                                                                    <svg
                                                                        width="800px"
                                                                        height="800px"
                                                                        viewBox="0 0 25 25"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M5.5 16.5H19.5M5.5 8.5H19.5M4.5 12.5H20.5M12.5 20.5C12.5 20.5 8 18.5 8 12.5C8 6.5 12.5 4.5 12.5 4.5M12.5 4.5C12.5 4.5 17 6.5 17 12.5C17 18.5 12.5 20.5 12.5 20.5M12.5 4.5V20.5M20.5 12.5C20.5 16.9183 16.9183 20.5 12.5 20.5C8.08172 20.5 4.5 16.9183 4.5 12.5C4.5 8.08172 8.08172 4.5 12.5 4.5C16.9183 4.5 20.5 8.08172 20.5 12.5Z"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.2"
                                                                        />
                                                                    </svg>
                                                                )}
                                                                {
                                                                    news.country
                                                                        .countryName
                                                                }
                                                            </p>
                                                        </div>
                                                        <p>
                                                            <span className="mr-2">
                                                                <svg
                                                                    width="20"
                                                                    height="14"
                                                                    style={{
                                                                        width: '1rem',
                                                                    }}
                                                                    viewBox="0 0 20 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <circle
                                                                        cx="10"
                                                                        cy="7"
                                                                        r="3"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                    />
                                                                    <path
                                                                        d="M18.188 5.93429C18.5762 6.40556 18.7703 6.64119 18.7703 7C18.7703 7.35881 18.5762 7.59444 18.188 8.06571C16.7679 9.78986 13.6357 13 10 13C6.36427 13 3.23206 9.78986 1.81197 8.06571C1.42381 7.59444 1.22973 7.35881 1.22973 7C1.22973 6.64119 1.42381 6.40556 1.81197 5.93429C3.23206 4.21014 6.36427 1 10 1C13.6357 1 16.7679 4.21014 18.188 5.93429Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                    />
                                                                </svg>{' '}
                                                                {news.readTime}{' '}
                                                                {news.readTime >
                                                                    1
                                                                    ? 'mins'
                                                                    : 'min'}
                                                            </span>{' '}
                                                            {getFullDateTimeFromDateTimeString(
                                                                news.systemPublishAt
                                                            )}
                                                        </p>
                                                    </div>
                                                    <h2>{news.newsTitle}</h2>
                                                    <p>{news.particularsOfUpdate}</p>
                                                </Link>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {this.state.hasNextPage && (
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={() => {
                                            this.getNewsByPage(
                                                this.state.currentPage + 1
                                            );
                                        }}
                                    >
                                        Load More
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedIndustryListDropDown: selectSortedIndustryList(),
    sortedSubIndustryListDropDown: selectSortedSubIndustryList(),
    sortedLawCategoryListDropDown: selectSortedLawCategoryList(),
    newsApiResponse: makeNewsSelectField('newsApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getNewsWithSearchRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withNewsReducer,
    withNewsSaga,
    withRouter
)(Index);
