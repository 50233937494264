import { ADD_NEW_MESSAGE_REQUEST, GET_CHAT_LIST_BY_TASK_ID_REQUEST, GET_MESSAGE_LIST_BY_CHAT_ID_REQUEST, GET_TASK_USERS_REQUEST, ADD_OR_REMOVE_USER_REQUEST, CREATE_CHAT_REQUEST, RENAME_GROUP_REQUEST } from "./actionTypes";
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getChatListByTaskIdResponse, getMessageListByChatIdResponse, addNewMessageResponse, getTaskUsersResponse, addOrRemoveUserResponse, createChatResponse, renameGroupResponse } from "./actions";
import { ADD_OR_REMOVE_USER, CHATS_BY_TASK, MESSAGE, MESSAGES_BY_CHAT, TASK_USERS, CHAT, RENAME_GROUP } from "helpers/apiConstants";
import { DANGER, SUCCESS } from "constants/commonConstants";
import { invokeApi } from "helpers/axiosHelper";
import { handleApiCallException } from "store/Shared/actions";

function* getChatListByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(invokeApi, 'get', CHATS_BY_TASK + '/' + taskId, '');
        if (response.status) {
            yield put(getChatListByTaskIdResponse(response.data.list));
        } else {
            yield put(getChatListByTaskIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getMessageListByChatId({ payload: { chatId } }) {
    try {
        const response = yield call(invokeApi, 'get', MESSAGES_BY_CHAT + '/' + chatId, '');
        if (response.status) {
            yield put(getMessageListByChatIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getMessageListByChatIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addNewMessage({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', MESSAGE, data, headers);
        if (response.status) {
            yield put(addNewMessageResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(addNewMessageResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* taskUserList({ payload: { taskId } }) {
    try {
        const response = yield call(invokeApi, 'get', TASK_USERS + '/' + taskId, '');
        if (response.status) {
            yield put(getTaskUsersResponse(response.data.list));
        } else {
            yield put(getTaskUsersResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addOrRemoveUser({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ADD_OR_REMOVE_USER, data);
        if (response.status) {
            yield put(addOrRemoveUserResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(addOrRemoveUserResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* createChat({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', CHAT, data);
        if (response.status) {
            yield put(createChatResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(createChatResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* renameGroup({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', RENAME_GROUP, data);
        if (response.status) {
            yield put(renameGroupResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(renameGroupResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchChat() {
    yield takeEvery(GET_CHAT_LIST_BY_TASK_ID_REQUEST, getChatListByTaskId);
    yield takeEvery(GET_MESSAGE_LIST_BY_CHAT_ID_REQUEST, getMessageListByChatId);
    yield takeEvery(ADD_NEW_MESSAGE_REQUEST, addNewMessage);
    yield takeEvery(GET_TASK_USERS_REQUEST, taskUserList);
    yield takeEvery(ADD_OR_REMOVE_USER_REQUEST, addOrRemoveUser);
    yield takeEvery(CREATE_CHAT_REQUEST, createChat);
    yield takeEvery(RENAME_GROUP_REQUEST, renameGroup);
}
function* chatSaga() {
    yield all([fork(watchChat)]);
}
export default chatSaga;