import { ADD_SECTION_REQUEST, ADD_SECTION_RESPONSE, DELETE_SECTION_BY_SECTION_ID_REQUEST, DELETE_SECTION_BY_SECTION_ID_RESPONSE, GET_ALL_SECTION_LIST_REQUEST, GET_ALL_SECTION_LIST_RESPONSE, GET_SECTION_BY_SECTION_ID_REQUEST, GET_SECTION_BY_SECTION_ID_RESPONSE, GET_SECTION_LIST_BY_LAW_REQUEST, GET_SECTION_LIST_BY_LAW_RESPONSE, GET_SECTION_LIST_BY_STATUS_REQUEST, GET_SECTION_LIST_BY_STATUS_RESPONSE, UPDATE_SECTION_REQUEST, UPDATE_SECTION_RESPONSE, UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST, UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE } from "./actionTypes";
export const addSectionRequest = data => {
    return {
        type: ADD_SECTION_REQUEST,
        payload: { data },
    };
};
export const addSectionResponse = (message, responseType) => {
    return {
        type: ADD_SECTION_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllSectionListRequest = () => {
    return {
        type: GET_ALL_SECTION_LIST_REQUEST
    };
};
export const getAllSectionListResponse = sectionList => {
    return {
        type: GET_ALL_SECTION_LIST_RESPONSE,
        payload: { sectionList },
    };
};
export const getSectionBySectionIdRequest = (sectionId) => {
    return {
        type: GET_SECTION_BY_SECTION_ID_REQUEST,
        payload: { sectionId: sectionId },
    };
};
export const getSectionBySectionIdResponse = (message, responseType, data) => {
    return {
        type: GET_SECTION_BY_SECTION_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateSectionRequest = (sectionId, data) => {
    return {
        type: UPDATE_SECTION_REQUEST,
        payload: { sectionId, data },
    };
};
export const updateSectionResponse = (message, responseType) => {
    return {
        type: UPDATE_SECTION_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateSectionStatusBySectionIdRequest = (sectionId, status) => {
    return {
        type: UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST,
        payload: { sectionId, status },
    };
};
export const updateSectionStatusBySectionIdResponse = (message, responseType) => {
    return {
        type: UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getSectionListByStatusRequest = status => {
    return {
        type: GET_SECTION_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getSectionListByStatusResponse = lawCategoryList => {
    return {
        type: GET_SECTION_LIST_BY_STATUS_RESPONSE,
        payload: { lawCategoryList },
    };
};
export const deleteSectionBySectionIdRequest = (sectionId) => {
    return {
        type: DELETE_SECTION_BY_SECTION_ID_REQUEST,
        payload: { sectionId },
    };
};
export const deleteSectionBySectionIdResponse = (message, responseType) => {
    return {
        type: DELETE_SECTION_BY_SECTION_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getSectionListByLawRequest = (lawId) => {
    return {
        type: GET_SECTION_LIST_BY_LAW_REQUEST,
        payload: { lawId },
    };
};
export const getSectionListByLawResponse = sectionList => {
    return {
        type: GET_SECTION_LIST_BY_LAW_RESPONSE,
        payload: { sectionList },
    };
};