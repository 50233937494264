import produce from 'immer';
import { ADD_ENTITY_ROLE_RESPONSE, DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE, GET_ALL_ENTITY_ROLE_LIST_RESPONSE, GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE, GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_RESPONSE, GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_RESPONSE, UPDATE_ENTITY_ROLE_RESPONSE, UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_RESPONSE } from './actionTypes';
export const initialState = {
    entityRoleApiResponse: null,
    editEntityRoleApiResponse: null,
    entityRoleList: [],
    roleList: []
};
const entityRole = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_ENTITY_ROLE_RESPONSE:
            case UPDATE_ENTITY_ROLE_RESPONSE:
            case UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_RESPONSE:
            case DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE:
                draft.entityRoleApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_ENTITY_ROLE_LIST_RESPONSE:
                draft.entityRoleList = action.payload.entityRoleList;
                break;
            case GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE:
                draft.editEntityRoleApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_RESPONSE:
                draft.entityRoleList = action.payload.entityRoleList;
                break;

            case GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_RESPONSE:
                draft.roleList = action.payload.roleList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default entityRole;
