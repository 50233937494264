import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Card } from 'reactstrap';
import {
    CANCEL,
    SUCCESS,
    UPLOAD,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
import { getAllDocumentTypeListRequest } from 'store/actions';
import 'react-datepicker/dist/react-datepicker.css';
import { legalUpdatesDocumentFileValidator } from 'helpers/customValidators';
import Dropzone from 'react-dropzone';
import CustomisedModal from 'components/Application/CustomisedModal';
import { makeLegalUpdateSelectField } from 'store/UpdateCenter/LegalUpdates/selector';
class UploadDocumentForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(legalUpdatesDocumentFileValidator()),
        });
        this.state = {
            selectedFile: [],
            errors: '',
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }

    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!legalUpdatesDocumentFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.document_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({
                errors:
                    __t('form_labels.document_repository.document_file') +
                    ' ' +
                    __t('validations.custom.document_file'),
            });
        } else {
            this.setState({ errors: '' });
        }
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );
        this.setState({ selectedFile: files });
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };

    resetForm = () => {
        this.setState(
            {
                selectedFile: [],
                errors: '',
            },
            () => {
                this.props.onModalDismiss();
            }
        );
    };
    formSubmit = (values) => {
        if (this.state.selectedFile.length === 0) {
            this.setState({
                errors: __t(
                    'validations.document_repository.document_file_required'
                ),
            });
        } else {
            if (this.state.errors.length === 0) {
                this.props.onFormValid({
                    ...values,
                    documentFile: this.state.selectedFile,
                });
            }
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.legalUpdatesDocumentApiResponse &&
            this.props.legalUpdatesDocumentApiResponse !==
            nextprops.legalUpdatesDocumentApiResponse
        ) {
            toastrMessage(
                nextprops.legalUpdatesDocumentApiResponse.message,
                nextprops.legalUpdatesDocumentApiResponse.responseType
            );
            if (
                nextprops.legalUpdatesDocumentApiResponse.responseType ===
                SUCCESS
            ) {
                this.resetForm();
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={__t(
                        'form_labels.document_repository.upload_document'
                    )}
                    isModalOpen={this.props.isModalOpen}
                    width="150%"
                    onModalDismiss={() => {
                        this.resetForm();
                    }}
                >
                    <Formik
                        initialValues={Object.assign(
                            {},
                            this.props.legalUpdatesDocumentData
                        )}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>

                                <Row>
                                    <FormGroup className="col-lg-12">
                                        <Dropzone
                                            multiple={false}
                                            onDrop={(acceptedFiles) =>
                                                this.handleAcceptedFiles(
                                                    acceptedFiles
                                                )
                                            }
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => (
                                                <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps()}
                                                    >
                                                        <input
                                                            {...getInputProps()}
                                                        />
                                                        <h3>
                                                            Drop files here or
                                                            click to upload.
                                                        </h3>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {this.state.errors != '' && (
                                            <div className="srv-validation-message">
                                                {this.state.errors}
                                            </div>
                                        )}
                                        <div
                                            className="dropzone-previews mt-2"
                                            id="file-previews"
                                        >
                                            {this.state.selectedFile.map(
                                                (f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + '-file'}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="upload-document-title font-weight-bold"
                                                                        >
                                                                            {
                                                                                f.name
                                                                            }
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>
                                                                                {
                                                                                    f.formattedSize
                                                                                }
                                                                            </strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                            <div className="d-inline-block">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {UPLOAD}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={this.resetForm}
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    legalUpdatesDocumentApiResponse: makeLegalUpdateSelectField(
        'legalUpdatesDocumentApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getAllDocumentTypeListRequest,
});
export default compose(withConnect, withRouter)(UploadDocumentForm);
