import { ACTIVE, NATURE_APPLICABILITY_MANDATORY } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Code',
        field: 'lawCode',
    },
    {
        label: 'Short Name of Law/Regulation',
        field: 'lawShortName',
    },
    {
        label: 'Category of Law',
        field: 'lawCategoryName',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State/Province',
        field: 'stateName',
    },
    {
        label: 'City/Location',
        field: 'cityName',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialLawDataState = () => {
    return {
        lawName: '',
        lawCategoryId: '',
        countryId: '',
        stateId: '',
        cityId: [],
        lawShortName: '',
        effectiveDate: '',
        applicabilityDetails: '',
        natureApplicability: NATURE_APPLICABILITY_MANDATORY,
        entityTypeId: [],
        facilityTypeId: [],
        industryId: [],
        subIndustryId: [],
        weblink: '',
        governingAuthorityDetails: '',
        exemptions: '',
        isActive: ACTIVE,
        lawSections: [],
        displayMode: 0,
    }
}
export const initialSectionDataState = () => {
    return {
        id: 0,
        sectionName: ''
    };
}
export const intFields = ['lawCategoryId', 'countryId', 'stateId'];
export const nullableFields = ['weblink', 'governingAuthorityDetails', 'exemptions'];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
export const sectionListColumns = [
    {
        label: 'Section/Rule Name',
        field: 'sectionName',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
]