export const GET_ALL_LAW_DOCUMENTS_REQUEST = 'get_all_law_documents_request';
export const GET_ALL_LAW_DOCUMENTS_RESPONSE = 'get_all_law_documents_response';
export const GET_LAW_DOCUMENTS_BY_LAW_ID_REQUEST = 'get_law_documents_by_law_id_request';
export const GET_LAW_DOCUMENTS_BY_LAW_ID_RESPONSE = 'get_law_documents_by_law_id_response';

export const GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST = 'get_law_document_by_law_document_id_request';
export const GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE = 'get_law_document_by_law_document_id_response';
export const UPDATE_LAW_DOCUMENT_REQUEST = 'update_law_document_request';
export const UPDATE_LAW_DOCUMENT_RESPONSE = 'update_law_document_response';
export const UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_REQUEST = 'update_law_document_status_by_law_document_id_request';
export const UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_RESPONSE = 'update_law_document_status_by_law_document_id_response';
export const GET_LAW_DOCUMENT_LIST_BY_STATUS_REQUEST = 'get_law_document_list_by_status_request';
export const GET_LAW_DOCUMENT_LIST_BY_STATUS_RESPONSE = 'get_law_document_list_by_status_response';
export const DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST = 'delete_law_document_by_law_document_id_request';
export const DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE = 'delete_law_document_by_law_document_id_response';
export const GET_ALL_DOCUMENT_TYPE_LIST_REQUEST = 'get_all_document_type_list_request';
export const GET_ALL_DOCUMENT_TYPE_LIST_RESPONSE = 'get_all_document_type_list_response';
export const STORE_DOWNLOAD_LOG_REQUEST = 'store_download_log_request';
export const STORE_DOWNLOAD_LOG_RESPONSE = 'store_download_log_response';
export const GET_DOWNLOAD_LOG_REQUEST = 'get_download_log_request';
export const GET_DOWNLOAD_LOG_RESPONSE = 'get_download_log_response';

export const DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_REQUEST = 'document_repository_standard_bulk_upload_request';
export const DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_RESPONSE = 'document_repository_standard_bulk_upload_response';

export const DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_REQUEST = 'document_repository_compliance_list_by_doc_id_request';
export const DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_RESPONSE = 'document_repository_compliance_list_by_doc_id_response';
export const UPDATE_LAW_DOCUMENT_LEGAL_REQUEST = 'update_law_document_legal_request';
export const UPDATE_LAW_DOCUMENT_LEGAL_RESPONSE = 'update_law_document_legal_response';
export const ADD_LAW_DOCUMENT_REQUEST = 'add_law_document_request';
export const ADD_LAW_DOCUMENT_RESPONSE = 'add_law_document_response';
export const GET_LAW_DOCUMENT_VERSIONS_LIST_REQUEST = 'get_law_document_versions_list_request';
export const GET_LAW_DOCUMENT_VERSIONS_LIST_RESPONSE = 'get_law_document_versions_list_response';
