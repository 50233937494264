import { compareString } from 'helpers/generalUtils';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.lawDocument || initialState;
const makeLawDocumentSelectField = field =>
    createSelector(reducerState, lawDocumentState => lawDocumentState[field]);
const selectSortedLawDocumentList = () =>
    createSelector(makeLawDocumentSelectField('lawDocumentList'), lawDocumentList =>
        lawDocumentList.map(opt => ({
            value: opt.id,
            label: opt.documentName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedDocumentTypeList = () =>
    createSelector(makeLawDocumentSelectField('documentTypeList'), documentTypeList =>
        documentTypeList.map(opt => ({
            value: opt.id,
            label: opt.documentTypeName
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeLawDocumentSelectField,
    selectSortedLawDocumentList,
    selectSortedDocumentTypeList
};
