import { compareString } from 'helpers/generalUtils';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = (state) => state.ticket || initialState;
const makeTicketSelectField = (field) =>
    createSelector(reducerState, (ticket) => ticket[field]);

const selectSortedTicketCategoryList = () =>
    createSelector(
        makeTicketSelectField('ticketCategoryList'),
        (ticketCategoryList) =>
            ticketCategoryList
                .map((opt) => ({
                    value: opt.id,
                    label: opt.ticketCategoryName,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedTicketAssigneeUserList = () =>
    createSelector(
        makeTicketSelectField('ticketAssigneeUserList'),
        (ticketAssigneeUserList) =>
            ticketAssigneeUserList
                ?.map((opt) => ({
                    value: opt.id,
                    label: opt.fullName + ' - ' + opt.email,
                    fullName: opt.fullName,
                    email: opt.email,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );

export {
    reducerState,
    makeTicketSelectField,
    selectSortedTicketCategoryList,
    selectSortedTicketAssigneeUserList
};