import React, { useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { DEFAULT_PAGE_LENGTH, DEFAULT_PAGE_SIZE_OPTIONS } from 'constants/commonConstants';

const Table = ({ searching, paging, entriesOptions, displayEntries, entries, sortRows, activePage, tableRecords, onFetchData }) => {
    const [page, setPage] = useState(activePage);
    const handlePageChange = (newPage) => {
        setPage(newPage.activePage);
    };
    React.useEffect(() => {
        onFetchData && onFetchData({ page });
    }, [onFetchData, page]);
    return (
        <React.Fragment>
            <MDBDataTable
                responsive
                striped
                bordered
                data={tableRecords}
                noBottomColumns
                searching={searching}
                paging={paging}
                entriesOptions={entriesOptions}
                displayEntries={displayEntries}
                entries={entries}
                sortRows={sortRows}
                activepage={activePage}
                onPageChange={handlePageChange}
                disableRetreatAfterSorting={true}
            />
        </React.Fragment>
    );
}

class CustomisedMDBDatatablePage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table
                    {...this.props}
                    searching={
                        this.props.searching === false
                            ? this.props.searching
                            : true
                    }
                    paging={
                        this.props.paging === false ? this.props.paging : true
                    }
                    entriesOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    displayEntries={this.props.displayEntries === false ? this.props.displayEntries : true}
                    entries={(this.props.entries) ? this.props.entries : DEFAULT_PAGE_LENGTH}
                    sortRows={(this.props.sortRows) ? this.props.sortRows : []}
                    activePage={this.props.activepage}
                    tableRecords={this.props.tableRecords}
                    onFetchData={this.props.onHandlePageChange}
                />
            </React.Fragment>
        );
    }
}

export default CustomisedMDBDatatablePage;
