import React, { Component } from 'react';
import { Button, Label, Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CANCEL } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Field } from 'formik';
import { createStructuredSelector } from 'reselect';

import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';

import __t from 'i18n/translator';

class AddEditProjectTeamsFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            projectList: this.props.projectList,
            formData: {
                userId: '',
                isOwner: false
            }
        };
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.projectList !== this.props.projectList) {
            this.setState({
                projectList: nextprops.projectList,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="add-project-form form-card">
                    <div className="form-group">
                        <Field
                            component={Select}
                            name="userId"
                            className="form-control selectbox"
                            options={this.props.selectSortedUserList?.filter(
                                (option) => {
                                    let countryArr =
                                        this.state.projectList?.map(
                                            ({ userId, ...rest }) =>
                                                userId
                                        );
                                    return !countryArr?.includes(
                                        option.value
                                    );
                                }
                            )}
                            onChange={(opt) => {
                                let values = this.state.formData;
                                this.setState({
                                    formData: {
                                        ...values,
                                        userId: opt.value
                                    }
                                })
                            }}
                            value={this.props.selectSortedUserList.filter(
                                (option) =>
                                    option.value === this.state.formData.userId
                            )}
                        />
                        <Label className="form-label">
                            User
                            <span className="text-danger">*</span>
                        </Label>
                        {this.validator.message(
                            __t('form_labels.projects.user'),
                            this.state.formData.userId,
                            'required',
                            {
                                messages: {
                                    required: __t(
                                        'validations.projects.user_required'
                                    ),
                                },
                            }
                        )}
                    </div>
                    <div className="form-group">
                        <Input
                            type="checkbox"
                            id="isOwner"
                            name="isOwner"
                            onChange={(e) => {
                                const { checked } = e.target;
                                let values = this.state.formData;

                                this.setState({
                                    formData: {
                                        ...values,
                                        isOwner: (checked == true) ? true : false
                                    }
                                })
                            }}
                        />
                        <label className="form-check-label">
                            Owner
                        </label>
                    </div>
                    <div className="d-flex">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                this.formSubmit(this.state.formData);
                            }}
                        >
                            Submit
                        </button>

                        <Button
                            className="btn btn-outline-secondary"
                            type="reset"
                            title="Cancel"
                            onClick={this.props.onModalDismiss}
                        >
                            {CANCEL}
                        </Button>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({});
const withConnect = connect(mapStatetoProps, {});
export default compose(withConnect, withRouter)(AddEditProjectTeamsFormModal);
