import React, { Component } from 'react';
import {
    Col,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE_LABEL, ADD_NEW, INACTIVE_LABEL, NA, SUCCESS } from 'constants/commonConstants';
import classnames from 'classnames';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getLawByLawIdRequest,
    getLawCompliancesByLawIdRequest,
    getLawDocumentsByLawIdRequest,
    getLawDefinitionsByLawIdRequest,
    getLawComplianceDetailsByLawComplianceIdRequest,
    updateLawComplianceStatusByLawComplianceIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import lawDocumentReducer from 'store/LawDocument/reducer';
import lawDocumentSaga from 'store/LawDocument/saga';
import lawDefinitionReducer from 'store/LawDefinition/reducer';
import lawDefinitionSaga from 'store/LawDefinition/saga';
import { toastrMessage } from 'helpers/messageHelper';
import {
    complianceTableColumnsTableData,
    definitionTableColumns,
    documentTableColumns,
} from './CommonFunctions';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { makeLawDefinitionSelectField } from 'store/LawDefinition/selector';
import ShowMoreText from 'react-show-more-text';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import ShowComplianceDetails from './ShowComplianceDetails';
import { makeLawSelectField } from 'store/Masters/Law/selector';
import { makeLawDocumentSelectField } from 'store/LawDocument/selector';
import __t from 'i18n/translator';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import AuditLogModal from 'components/Application/AuditLogModal';
import DefinitionForm from './DefinitionForm';
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const lawDefinitionKey = 'lawDefinition';
const withLawDefinitionReducer = injectReducer({
    key: lawDefinitionKey,
    reducer: lawDefinitionReducer,
});
const withLawDefinitionSaga = injectSaga({
    key: lawDefinitionKey,
    saga: lawDefinitionSaga,
});
const lawDocumentKey = 'lawDocument';
const withLawDocumentReducer = injectReducer({
    key: lawDocumentKey,
    reducer: lawDocumentReducer,
});
const withLawDocumentSaga = injectSaga({
    key: lawDocumentKey,
    saga: lawDocumentSaga,
});
class ShowLaw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lawId: parseInt(this.props.lawId),
            lawData: {},
            complianceData: [],
            documentData: [],
            definitionData: [],
            activeTab: 'complianceChecklistTab',
            viewComplianceSection: false,
            viewComplianceData: {},
            downloadFileName: '',
            downloadFileUrl: '',
            isAuditModalOpen: false,
            auditId: ''
        };
        this.toggle = this.toggle.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => {
                    switch (tab) {
                        case 'basicDetailsTab':
                            this.props.getLawByLawIdRequest(this.state.lawId);
                            break;
                        case 'complianceChecklistTab':
                            this.props.getLawCompliancesByLawIdRequest(
                                this.state.lawId,
                                this.props.displayMode
                            );
                            break;
                        case 'definitionsTab':
                            this.props.getLawDefinitionsByLawIdRequest(
                                this.state.lawId,
                                this.props.displayMode
                            );
                            break;
                        case 'actDocumentTab':
                            this.props.getLawDocumentsByLawIdRequest(
                                this.state.lawId,
                                this.props.displayMode
                            );
                            break;
                        default:
                            break;
                    }
                }
            );
        }
    }
    componentDidMount() {
        this.props.getLawCompliancesByLawIdRequest(
            this.state.lawId,
            this.props.displayMode
        );
        this.props.getLawByLawIdRequest(this.state.lawId);
    }
    viewComplianceDetails = (complianceId) => {
        this.setState(
            {
                viewComplianceSection: true,
                complianceId: complianceId,
            },
            () => {
                this.props.getLawComplianceDetailsByLawComplianceIdRequest(
                    complianceId
                );
            }
        );
    };
    closeComplianceSection = () => {
        this.setState({
            viewComplianceSection: false,
            complianceId: '',
            viewComplianceData: {},
        });
    };
    onStatusChange(checked, e, id) {
        this.props.updateLawComplianceStatusByLawComplianceIdRequest(
            id,
            checked
        );
    }
    generateComplianceTable = (records) => {
        let complianceListDataRecords = [];
        complianceListDataRecords = records.map((data, i) => {
            let actions = (
                <a
                    href={() => false}
                    className="btn btn-link tbl-action text-success"
                    onClick={() => {
                        this.viewComplianceDetails(data.id);
                    }}
                >
                    <i className="fas fa-eye"></i>
                </a>
            );
            return {
                complianceCode: data?.complianceCode,
                sectionReference: data.sectionReference,
                ruleReference: data?.ruleReference,
                complianceTitle: data.complianceTitle,
                lawCode: (data.law) ? data.law.lawCode : '',
                lawShortName: (data.law) ? data.law.lawShortName : '',
                complianceTypeName: data.complianceType?.complianceTypeName,
                complianceCategoryName:
                    data.complianceCategory?.complianceCategoryName,
                applicabilityCondition: data.applicabilityConditionName,
                periodicityName: data.periodicity?.periodicityName,
                isActive: data.isActive ? ACTIVE_LABEL : INACTIVE_LABEL,
                actions: actions,
            };
        });
        return complianceListDataRecords;
    };
    generateDefinitionTable = (records) => {
        let definitionListDataRecords = [];
        definitionListDataRecords = records.map((data, i) => {
            let action = (
                <a
                    href={() => false}
                    title={__t('common.audit_logs')}
                    className="btn btn-link text-primary tbl-action"
                    onClick={() => {
                        this.openAuditLogModal(data.id);
                    }}
                >
                    <i className="fas fa-history"></i>
                </a>
            )
            return {
                terminology: data.terminology,
                section: data.section,
                rule: data.rule,
                definition: this.getShowMoreTextElement(data.definition),
                definitionRemarks: data.definitionRemarks,
                actions: action
            };
        });
        return definitionListDataRecords;
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    generateDocumentsTable = (records) => {
        let documentListDataRecords = [];
        documentListDataRecords = records.map((data, i) => {
            let documentNameLink = '';
            if (data.fileUrl != '') {
                documentNameLink = (
                    <a
                        // href={data.fileUrl}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        onClick={() => {
                            this.saveFile({
                                fileName: data.fileName,
                                fileUrl: data.fileUrl
                            })
                        }}
                        className="text-primary btn-link"
                    >
                        {data.documentName}&nbsp;
                        <small>
                            <i className="fas fa-external-link-alt"></i>
                        </small>
                    </a>
                );
            } else {
                documentNameLink = data.documentName;
            }
            return {
                documentType: data.documentType?.documentTypeName,
                documentName: documentNameLink,
                releaseDate: data.releaseDate,
                effectiveDate: data.effectiveDate,
                referenceNo: data.referenceNo,
                documentDescription: data.documentDescription,
                briefNote: data.briefNote,
            };
        });
        return documentListDataRecords;
    };
    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawDetailsApiResponse &&
            this.props.lawDetailsApiResponse !== nextprops.lawDetailsApiResponse
        ) {
            if (nextprops.lawDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    lawData: nextprops.lawDetailsApiResponse.data.law,
                });
            } else {
                toastrMessage(
                    nextprops.lawDetailsApiResponse.message,
                    nextprops.lawDetailsApiResponse.responseType
                );
            }
        }
        if (
            nextprops.lawComplianceList &&
            this.props.lawComplianceList !== nextprops.lawComplianceList
        ) {
            this.setState({
                complianceData: this.generateComplianceTable(
                    nextprops.lawComplianceList
                ),
            });
        }
        if (
            nextprops.lawDefinitionsListResponse &&
            this.props.lawDefinitionsListResponse !== nextprops.lawDefinitionsListResponse
        ) {
            this.setState({
                definitionData: this.generateDefinitionTable(
                    nextprops.lawDefinitionsListResponse.lawDefinitionsList
                ),
            });
        }
        if (nextprops.lawDocumentsListResponse && this.props.lawDocumentsListResponse !== nextprops.lawDocumentsListResponse) {
            this.setState({
                documentData: this.generateDocumentsTable(nextprops.lawDocumentsListResponse.lawDocumentList)
            });
        }
        if (
            nextprops.lawComplianceDetailsApiResponse &&
            this.props.lawComplianceDetailsApiResponse !==
            nextprops.lawComplianceDetailsApiResponse
        ) {
            this.setState({
                viewComplianceData:
                    nextprops.lawComplianceDetailsApiResponse.data.compliance,
            });
        }
        if (
            nextprops.lawComplianceStatusUpdateApiResponse &&
            this.props.lawComplianceStatusUpdateApiResponse !==
            nextprops.lawComplianceStatusUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.lawComplianceStatusUpdateApiResponse.message,
                nextprops.lawComplianceStatusUpdateApiResponse.responseType
            );
            if (
                nextprops.lawComplianceStatusUpdateApiResponse.responseType ===
                SUCCESS
            ) {
                this.props.getLawCompliancesByLawIdRequest(
                    this.state.lawId,
                    this.props.displayMode
                );
            }
        }
    }
    render() {
        const complianceTableData = {
            columns: complianceTableColumnsTableData,
            rows: this.state.complianceData,
        };
        const definitionTableData = {
            columns: definitionTableColumns,
            rows: this.state.definitionData,
        };
        const documentTableData = {
            columns: documentTableColumns,
            rows: this.state.documentData,
        };
        return (
            <React.Fragment>
                <Col lg="12">
                    <Card>
                        <CardHeader>
                            <div className="page-header">
                                <h4 className="with-back-btn">
                                    <a
                                        href={() => false}
                                        className="dripicons-arrow-thin-left"
                                        onClick={() => {
                                            this.props.onCloseViewLawDetails();
                                        }}
                                    >
                                        { }
                                    </a>{' '}
                                    <span>
                                        &nbsp;
                                        {this.state.lawData?.lawCode +
                                            ' - ' +
                                            this.state.lawData?.lawShortName}
                                    </span>
                                </h4>
                            </div>
                        </CardHeader>
                        <CardBody className="compliance-view-main h-100">
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'complianceChecklistTab',
                                        })}
                                        onClick={() => {
                                            this.toggle(
                                                'complianceChecklistTab'
                                            );
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                            {__t(
                                                'form_labels.statutory.details.compliance_details'
                                            )}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'basicDetailsTab',
                                        })}
                                        onClick={() => {
                                            this.toggle('basicDetailsTab');
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-home"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                            {__t(
                                                'form_labels.statutory.details.legislation_details'
                                            )}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'definitionsTab',
                                        })}
                                        onClick={() => {
                                            this.toggle('definitionsTab');
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                            {__t(
                                                'form_labels.statutory.details.definitions'
                                            )}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab ===
                                                'actDocumentTab',
                                        })}
                                        onClick={() => {
                                            this.toggle('actDocumentTab');
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                            {__t(
                                                'form_labels.statutory.details.related_documents'
                                            )}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane
                                    tabId="complianceChecklistTab"
                                    className="p-3"
                                >
                                    {!this.state.viewComplianceSection && (
                                        <CustomisedDatatable
                                            responsive
                                            striped
                                            tableRecords={complianceTableData}
                                        />
                                    )}
                                    {this.state.viewComplianceSection && (
                                        <ShowComplianceDetails
                                            complianceData={
                                                this.state.viewComplianceData
                                            }
                                            onClose={() => {
                                                this.closeComplianceSection();
                                            }}
                                            saveFile={(uploadedFileData) => {
                                                this.saveFile(uploadedFileData);
                                            }}
                                            toggle={this.toggle}
                                        />
                                    )}
                                </TabPane>
                                <TabPane
                                    tabId="basicDetailsTab"
                                    className="p-3"
                                >
                                    <div className='compliance-basic-details details-view law-detail-view'>
                                        <div className='compliance-card'>
                                            <h3>Law Details</h3>
                                            <div className='compliance-details'>
                                                <ul className="basic-details">
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.country'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.state.lawData
                                                                        ?.country
                                                                        ?.countryName
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.state'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.state.lawData?.state
                                                                    ?.stateName
                                                                    ? this.state.lawData
                                                                        ?.state?.stateName
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.city'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.state.lawData?.city
                                                                    ?.length > 0
                                                                    ? this.state.lawData?.city
                                                                        ?.map(
                                                                            (opt) =>
                                                                                opt.cityName
                                                                        )
                                                                        .join('; ')
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>

                                                </ul>
                                                <ul className="basic-details">
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.law_name'
                                                                )}
                                                            </b>
                                                            <ShowMoreText
                                                                lines={2}
                                                                more="Show more"
                                                                less="Show less"
                                                                className="show-more-content"
                                                                anchorClass="show-more-link"
                                                                expandByClick={true}
                                                                expanded={false}
                                                            >
                                                                {
                                                                    this.state.lawData
                                                                        ?.lawName
                                                                }
                                                            </ShowMoreText>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.law_short_name'
                                                                )}
                                                            </b>
                                                            <p>
                                                                <a
                                                                    onClick={() => { this.toggle('actDocumentTab'); }}
                                                                    className="text-primary btn-link">{this.state.lawData?.lawShortName}&nbsp;
                                                                    <small>
                                                                        <i className="fas fa-external-link-alt"></i>
                                                                    </small>
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.effective_date'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.state.lawData
                                                                        ?.effectiveDate
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="basic-details">
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.law_category'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.state.lawData
                                                                        ?.lawCategory
                                                                        ?.lawCategoryName
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.nature_applicability'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.state.lawData
                                                                        ?.natureApplicabilityName
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.applicability_details'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.state.lawData
                                                                        ?.applicabilityDetails
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <ul className="basic-details">

                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.governing_authority_details'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.state.lawData
                                                                    ?.governingAuthorityDetails
                                                                    ? this.getShowMoreTextElement(this.state.lawData
                                                                        ?.governingAuthorityDetails)
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.weblink'
                                                                )}
                                                            </b>
                                                            <a
                                                                href={
                                                                    this.state.lawData
                                                                        .weblink
                                                                }
                                                                className="btn btn-primary"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="ion ion-ios-link"></i>
                                                                {' Link '}
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className='compliance-card'>
                                            <h3>More Details on Applicability of Law</h3>
                                            <div className='compliance-details'>
                                                <ul className="basic-details">
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.entity_type'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.state.lawData?.entityType
                                                                    ?.map(
                                                                        (item) =>
                                                                            item.entityTypeName
                                                                    )
                                                                    .join('; ')}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.facility_type'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.state.lawData?.facilityType
                                                                    ?.map(
                                                                        (item) =>
                                                                            item.facilityTypeName
                                                                    )
                                                                    .join('; ')}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="basic-details">
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.industry'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.getShowMoreTextElement(this.state.lawData?.industry
                                                                    ?.map(
                                                                        (item) =>
                                                                            item.industryName
                                                                    )
                                                                    .join('; '))}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.sub_industry'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.getShowMoreTextElement(this.state.lawData?.subIndustry
                                                                    ?.map(
                                                                        (item) =>
                                                                            item.subIndustryName
                                                                    )
                                                                    .join('; '))}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="basic-details">
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t(
                                                                    'form_labels.statutory.details.exemptions'
                                                                )}
                                                            </b>
                                                            <p>
                                                                {this.state.lawData
                                                                    ?.exemptions
                                                                    ? this.getShowMoreTextElement(this.state.lawData
                                                                        ?.exemptions)
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="definitionsTab" className="p-3">
                                    <CustomisedDatatable
                                        tableRecords={definitionTableData}
                                    />
                                </TabPane>
                                <TabPane tabId="actDocumentTab" className="p-3">
                                    <CustomisedDatatable
                                        tableRecords={documentTableData}
                                    />
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
                <DownloadFileByModel
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
                <AuditLogModal
                    exportBtn={false}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'lawDefinitions'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment >
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawDetailsApiResponse: makeLawSelectField('lawDetailsApiResponse'),
    lawComplianceList: makeLawComplianceSelectField('lawComplianceList'),
    lawDefinitionsListResponse: makeLawDefinitionSelectField('lawDefinitionsListResponse'),
    lawDocumentsListResponse: makeLawDocumentSelectField('lawDocumentsListResponse'),
    lawComplianceDetailsApiResponse: makeLawComplianceSelectField('lawComplianceDetailsApiResponse'),
    lawComplianceStatusUpdateApiResponse: makeLawComplianceSelectField('lawComplianceStatusUpdateApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getLawByLawIdRequest,
    getLawCompliancesByLawIdRequest,
    getLawDocumentsByLawIdRequest,
    getLawDefinitionsByLawIdRequest,
    getLawComplianceDetailsByLawComplianceIdRequest,
    updateLawComplianceStatusByLawComplianceIdRequest,
});
export default compose(
    withConnect,
    withLawReducer,
    withLawSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withLawDocumentReducer,
    withLawDocumentSaga,
    withLawDefinitionReducer,
    withLawDefinitionSaga,
    withRouter
)(ShowLaw);
