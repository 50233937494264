import {
    MODULE_COMPANY_GROUP,
    MODULE_DEPARTMENT,
    MODULE_ENTITY,
    MODULE_ENTITY_LOCATION,
    MODULE_EVENT_MASTER,
    MODULE_INDUSTRY,
    MODULE_LAW,
    MODULE_LAW_CATEGORY,
    MODULE_LAW_SECTION_RULE,
    MODULE_USER,
} from './databaseConstants';

export const SUCCESS = 'success';
export const DANGER = 'error';
export const WARNING = 'warning';
export const INFO = 'info';

export const INACTIVE = false;
export const ACTIVE = true;

export const ACTIVE_LABEL = 'Active';
export const INACTIVE_LABEL = 'Inactive';

export const SUBMIT = 'Submit';
export const DRAFT = 'Save as draft';
export const CONFIRMUPDATE = 'Confirm Update';
export const QUICK_UPDATE = 'Quick Update';
export const AUTO_CLOSE = 'Auto Close';

export const SEND = 'Send';
export const APPROVE = 'Approve';
export const APPROVE_REVIEW = 'Review/Approve';
export const SEND_BACK = 'Send Back';
export const REWORK = 'Rework';
export const SEND_BACK_TO = 'Rework To';
export const SEND_BACK_TO_SUBMITTER_BTN = 'Submitter';
export const SEND_BACK_TO_APPROVER_BTN = 'Reviewer';
export const SEND_BACK_TO_APPROVER2_BTN = 'Approver';
export const AUDIT_BTN = 'Audit & Close';
export const DISCREPANCY_AUDIT_BTN = 'Discrepancy';
export const AUDIT_TBD_BTN = 'Share Observation';
export const CANCEL = 'Cancel';
export const UPDATE = 'Update';
export const ADD_NEW = 'Add New';
export const RESET = 'Reset';
export const SEARCH = 'Search';
export const FILTER = 'Filter';
export const GENERATE_REPORT = 'Generate Report';
export const GENERATE = 'Generate';
export const GENERATE_DOWNLOAD = 'Generate & Download';
export const CLEAR = 'Clear';
export const BACK = 'Back';
export const YES = 'Yes';
export const NO = 'No';
export const NA = 'NA';
export const SAVE_AND_CONTINUE = 'Save & Continue';
export const PROCEED = 'Proceed';
export const UPLOAD_EXCEL = 'Upload Excel';
export const PLAN_EVENT = 'Plan Event';
export const EXPORT_BTN = 'Export';
export const CLOSE = 'Close';
export const UPLOAD = 'Upload';
export const ADD_MEMBER = 'Add Member';
export const CLOSE_LEGAL = 'Close Update';

export const SUBMIT_ICON = 'far fa-save';
export const UPDATE_ICON = 'far fa-edit';
export const STATUS = 'far fa-check';

export const DATE_FORMAT_PLACEHOLDER = 'DD-MM-YYYY';
export const DATE_FORMAT_DATEPICKER = 'dd-MM-yyyy';
export const APP_DATE_FORMAT = 'DD-MM-YYYY';

export const AUTH_MODE_LC = 1;
export const AUTH_MODE_SSO = 2;

export const ONE_TIME = 1;
export const RECURRING = 2;

export const NATURE_APPLICABILITY_MANDATORY = 1;
export const NATURE_APPLICABILITY_TENTATIVE = 2;

export const DEFAULT_PAGE_LENGTH = 10;
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100, 150, 200, 500];
export const DEFAULT_PAGE_LENGTH_FOR_NEWS = 10;
export const DEFAULT_PAGE_LENGTH_FOR_TICKETS = 15;

export const SELECT_ALL = 'Select All';
export const ENABLE_DELETE = [
    // 'user',
    // 'companyGroup',
    // 'entity',
    // 'entityLocation',
    // 'entityRole',
    // 'lawCategory',
    // 'law',
    // 'section',
    // 'industry',
    // 'applicablity',
    // 'department',
    // 'city',
    // 'state',
    'systemConfiguration',
    'lawDocument',
    // 'event',
    'task',
    'delegation',
    // 'tenant'
];

export const IMPORT_MODULE = [
    { masterName: 'user', name: 'User Master', moduleId: MODULE_USER },
    {
        masterName: 'company-group',
        name: 'Company Group Master',
        moduleId: MODULE_COMPANY_GROUP,
    },
    { masterName: 'entity', name: 'Entity Master', moduleId: MODULE_ENTITY },
    {
        masterName: 'entity-financial-year',
        name: 'Entity Financial Year Master',
        moduleId: MODULE_ENTITY,
    },
    {
        masterName: 'entity-location',
        name: 'Entity Location Master',
        moduleId: MODULE_ENTITY_LOCATION,
    },
    {
        masterName: 'entity-admin',
        name: 'Entity Admin Master',
        moduleId: MODULE_ENTITY,
    },
    {
        masterName: 'entity-team',
        name: 'Entity Team Master',
        moduleId: MODULE_ENTITY,
    },
    {
        masterName: 'entity-management-team',
        name: 'Entity Management Team Master',
        moduleId: MODULE_ENTITY,
    },
    {
        masterName: 'entity-consultants-team',
        name: 'Entity Consultants Team Master',
        moduleId: MODULE_ENTITY,
    },
    {
        masterName: 'entity-department-hod',
        name: 'Entity HOD Master',
        moduleId: MODULE_ENTITY,
    },
    {
        masterName: 'entity-email-configuration',
        name: 'Entity Email Configuration Master',
        moduleId: MODULE_ENTITY,
    },
    {
        masterName: 'law-category',
        name: 'Law Category Master',
        moduleId: MODULE_LAW_CATEGORY,
    },
    { masterName: 'law', name: 'Law Master', moduleId: MODULE_LAW },
    {
        masterName: 'section-rule',
        name: 'Section/Rule Master',
        moduleId: MODULE_LAW_SECTION_RULE,
    },
    {
        masterName: 'industry',
        name: 'Industry Master',
        moduleId: MODULE_INDUSTRY,
    },
    {
        masterName: 'sub-industry',
        name: 'Sub Industry Master',
        moduleId: MODULE_INDUSTRY,
    },
    {
        masterName: 'department',
        name: 'Department Master',
        moduleId: MODULE_DEPARTMENT,
    },
    {
        masterName: 'event',
        name: 'Event Master',
        moduleId: MODULE_EVENT_MASTER,
    },
];

export const HOD = 9;
export const TASK_CREATOR = 10;
export const SUBMITTER = 11;
export const REVIEWER = 12;
export const AUDITOR = 13;
export const FOLLOWER = 14;

export const COMMON_GROUP_NAME = 'General';
export const NONE = 1;

export const ADD_SUB_TASK = 'Add Subtask';
export const PENDING_TO_TRIGGERED = 'Pending to be triggered';

export const SUBMIT_COMPLIANCE_ORDER = 'Submit Compliance(s) Order';
export const DELETE = "Delete";

export const TO_DO_FUND_STATUS = 1;
export const IN_PROGRESS_FUND_STATUS = 2;
export const COMPLETED_FUND_STATUS = 3;
export const PENDING_WITH_REVIEWER_STATUS = 4;
export const REJECTED_BY_REVIEWER = 5;
export const PENDING_WITH_APPROVER_STATUS = 6;
export const REJECTED_BY_APPROVER = 7;



