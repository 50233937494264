import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import { getTaskUserDetailsByTaskIdRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { makeTaskSelectField } from 'store/Task/selector';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
    PENDING_FOR_AUDIT,
    PENDING_WITH_FIRST_APPROVER,
    PENDING_WITH_SECOND_APPROVER,
    SEND_BACK_BY_AUDITOR_TO_APPROVER,
    SEND_BACK_TO_APPROVER,
} from 'constants/databaseConstants';
import ReactTooltip from 'react-tooltip';
import { PENDING_TO_TRIGGERED, SUCCESS } from 'constants/commonConstants';
import ViewTaskUser from 'components/Application/ViewTaskUser';
import { formatDateForApiSystem } from 'helpers/dateTimeHelper';
import { redirectUrl } from 'helpers/generalUtils';

const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Status',
        field: 'taskStatusId',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });
class DashboardTaskList extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            taskList: [],
            userDepartments: [],
            userDepartmentEntities: [],
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
        };
    }
    openViewTaskUserModal = (data) => {
        this.setState({
            isViewTaskUserModalOpen: true,
            viewTaskUserData: data,
        });
    };
    closeViewTaskUserModal() {
        this.setState({
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
        });
    }
    getRoleName = (taskData) => {
        let roles = [];
        const departmentIds =
            taskData.complianceAllocation?.departmentId != '' &&
                taskData.complianceAllocation?.departmentId != null
                ? taskData.complianceAllocation?.departmentId.split(',')
                : [];
        const collaboratorUserIds =
            taskData.collaboratorUserId != '' &&
                taskData.collaboratorUserId != null
                ? taskData.collaboratorUserId.split(',').map(Number)
                : [];
        if (taskData.submitterId == this.state.loginUserId) {
            roles.push('Submitter');
        } else if (taskData.firstApproverUserId == this.state.loginUserId) {
            roles.push('Reviewer');
        } else if (taskData.secondApproverUserId == this.state.loginUserId) {
            roles.push('Approver');
        } else if (taskData.auditorId == this.state.loginUserId) {
            roles.push('Auditor');
        } else if (
            departmentIds.some((department) => {
                let departmentHod = this.state.userDepartments?.find(
                    (departmentEntity) =>
                        departmentEntity.entityId ==
                        taskData.complianceAllocation?.entityId &&
                        departmentEntity.departmentId == department
                );
                if (departmentHod) {
                    return true;
                }
            })
        ) {
            roles.push('HOD');
        } else if (
            taskData.collaboratorUserId != '' &&
            collaboratorUserIds.includes(this.state.loginUserId)
        ) {
            roles.push('Collaborator');
        } else if (taskData.createdBy == this.state.loginUserId) {
            roles.push('Task Creator');
        }
        return roles;
    };
    generateTable = (records) => {
        let taskRecords = [];
        taskRecords = records.map((taskData, i) => {
            let roleNames = this.getRoleName(taskData);

            let type = 'report';
            if (
                [PENDING_FOR_AUDIT, AUDITED_AND_CLOSED].includes(
                    taskData.taskStatusId
                )
            ) {
                type = 'audit';
            }
            if (
                [
                    PENDING_WITH_FIRST_APPROVER,
                    PENDING_WITH_SECOND_APPROVER,
                    SEND_BACK_TO_APPROVER,
                    SEND_BACK_BY_AUDITOR_TO_APPROVER,
                ].includes(taskData.taskStatusId)
            ) {
                type = 'review';
            }
            let actions = (
                <div className="tbl_action">
                    {(taskData.taskStatusId == CLOSED ||
                        taskData.taskStatusId == AUDITED_AND_CLOSED) && (
                            <a
                                href={() => false}
                                title={__t('common.view')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    redirectUrl(this.props.history, '/compliances/' + type + '/' + taskData.id)


                                }}
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                        )}
                    {taskData.taskStatusId != CLOSED &&
                        taskData.taskStatusId != AUDITED_AND_CLOSED && (
                            <a
                                href={() => false}
                                title={__t('common.view')}
                                className="btn btn-link text-primary tbl-action"
                                onClick={() => {
                                    redirectUrl(this.props.history, '/compliances/' +
                                        type +
                                        '/' +
                                        taskData.id)
                                }}
                            >
                                <i className="fas fa-file-import"></i>
                            </a>
                        )}
                </div>
            );
            let taskCodeVal = (
                <span className="code_td" searchvalue={taskData.taskCode}>
                    {taskData.taskCode}
                </span>
            );
            let infoVal = (
                <div
                    className="status_info"
                    searchvalue={taskData?.taskStatus?.statusName}
                >
                    <span>{taskData?.taskStatus?.statusName}</span>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.props.getTaskUserDetailsByTaskIdRequest(
                                taskData.id
                            );
                        }}
                        className="text-primary"
                    >
                        <i className="fas fa-info-circle"></i>
                    </a>
                </div>
            );
            let complianceAction = (
                <a
                    href={() => false}
                    searchvalue={
                        taskData?.complianceAllocation?.lawCompliance
                            ?.complianceCode
                    }
                    title="View Details"
                    className="tbl-link"
                    onClick={() =>
                        window.open(
                            '/statutory-compliances/view-details/' +
                            taskData?.complianceAllocation?.complianceId,
                            '_blank'
                        )
                    }
                >
                    {
                        taskData?.complianceAllocation?.lawCompliance
                            ?.complianceCode
                    }{' '}
                </a>
            );
            return {
                id: taskData.id,
                taskCode: taskCodeVal,
                companyGroupName:
                    taskData?.complianceAllocation?.entity?.companyGroupName,
                entityName: taskData.complianceAllocation?.entity?.entityName,
                complianceCode: complianceAction,
                countryName:
                    taskData.complianceAllocation?.entityLocation?.country
                        ?.countryName,
                stateName:
                    taskData.complianceAllocation?.entityLocation?.state
                        ?.stateName,
                cityName:
                    taskData.complianceAllocation?.entityLocation?.city
                        ?.cityName,
                complianceTitle: taskData.parentTaskId === null ?
                    ((taskData.lawcomplianceVersion && taskData.lawcomplianceVersion != null && taskData.lawcomplianceVersion?.complianceTitle != null)
                        ? taskData.lawcomplianceVersion?.complianceTitle
                        : taskData.complianceAllocation?.lawCompliance?.complianceTitle
                    ) : taskData.title,
                complianceClass: (taskData.lawcomplianceVersion &&
                    taskData.lawcomplianceVersion != null && taskData.lawcomplianceVersion?.complianceCategory?.complianceCategoryName) ? taskData.lawcomplianceVersion?.complianceCategory?.complianceCategoryName : taskData.complianceAllocation?.lawCompliance?.complianceCategory?.complianceCategoryName,
                complianceType:
                    taskData.complianceAllocation?.lawCompliance?.complianceType
                        ?.complianceTypeName,
                dueDate: taskData.dueDate ? (
                    <Label
                        searchvalue={formatDateForApiSystem(taskData.dueDate)}
                    >
                        {taskData.dueDate}
                    </Label>
                ) : (
                    <Label searchvalue={''}>{PENDING_TO_TRIGGERED}</Label>
                ),
                taskStatusId: infoVal,
                parentTaskId:
                    taskData.parentTaskId === null
                        ? 'Statutory'
                        : 'Non-Statutory',
                role: roleNames.length > 0 ? roleNames.join('<br/>') : '-',
                actions: actions,
            };
        });
        return taskRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.clickedData) {
            this.setState(
                {
                    userDepartments: nextprops.userDepartments,
                    taskList: this.generateTable([]),
                },
                () => {
                    this.setState({
                        taskList: this.generateTable(nextprops.clickedData),
                    });
                }
            );
        }
        if (
            nextprops.taskUserDetailsApiResponse &&
            nextprops.taskUserDetailsApiResponse !==
            this.props.taskUserDetailsApiResponse
        ) {
            if (nextprops.taskUserDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    isViewTaskUserModalOpen: true,
                    viewTaskUserData:
                        nextprops.taskUserDetailsApiResponse.data?.taskUserData,
                });
            }
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.taskList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <Link
                            to="#"
                            className="btn btn-primary mb-3 dashboard_back_btn "
                            onClick={() => {
                                this.props.closeClickedData();
                            }}
                        >
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                    <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Back
                        </Link>
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable
                                    tableRecords={data}
                                    sortRows={[
                                        'dueDate',
                                        'complianceCode',
                                        'taskCode',
                                        'taskStatusId',
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ViewTaskUser
                    modalName={__t('form_labels.reporting.view_task_user')}
                    isModalOpen={this.state.isViewTaskUserModalOpen}
                    taskUserData={this.state.viewTaskUserData}
                    onModalDismiss={() => this.closeViewTaskUserModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    taskUserDetailsApiResponse: makeTaskSelectField(
        'taskUserDetailsApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getTaskUserDetailsByTaskIdRequest,
});
export default compose(
    withConnect,
    withTaskReducer,
    withTaskSaga,
    withRouter
)(DashboardTaskList);
