import {
    ADD_TENANT_REQUEST,
    ADD_TENANT_RESPONSE,
    DELETE_TENANT_BY_TENANT_ID_REQUEST,
    DELETE_TENANT_BY_TENANT_ID_RESPONSE,
    GET_ALL_TENANT_LIST_REQUEST,
    GET_ALL_TENANT_LIST_RESPONSE,
    GET_TENANT_BY_TENANT_ID_REQUEST,
    GET_TENANT_BY_TENANT_ID_RESPONSE,
    GET_TENANT_LIST_BY_STATUS_REQUEST,
    GET_TENANT_LIST_BY_STATUS_RESPONSE,
    UPDATE_TENANT_REQUEST,
    UPDATE_TENANT_RESPONSE,
    UPDATE_TENANT_STATUS_BY_TENANT_ID_REQUEST,
    UPDATE_TENANT_STATUS_BY_TENANT_ID_RESPONSE,
    GET_TENANT_LIST_REQUEST,
    GET_TENANT_LIST_RESPONSE,
    GENERATE_DATABASE_BY_TENANT_ID_REQUEST,
    GENERATE_DATABASE_BY_TENANT_ID_RESPONSE,
} from './actionTypes';
export const addTenantRequest = (data) => {
    return {
        type: ADD_TENANT_REQUEST,
        payload: { data },
    };
};
export const addTenantResponse = (message, responseType) => {
    return {
        type: ADD_TENANT_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllTenantListRequest = () => {
    return {
        type: GET_ALL_TENANT_LIST_REQUEST
    };
};
export const getAllTenantListResponse = (tenantList) => {
    return {
        type: GET_ALL_TENANT_LIST_RESPONSE,
        payload: { tenantList },
    };
};
export const getTenantListRequest = () => {
    return {
        type: GET_TENANT_LIST_REQUEST,
    };
};
export const getTenantListResponse = (tenantList) => {
    return {
        type: GET_TENANT_LIST_RESPONSE,
        payload: { tenantList },
    };
};
export const getTenantByTenantIdRequest = (tenantId) => {
    return {
        type: GET_TENANT_BY_TENANT_ID_REQUEST,
        payload: { tenantId: tenantId },
    };
};
export const getTenantByTenantIdResponse = (message, responseType, data) => {
    return {
        type: GET_TENANT_BY_TENANT_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateTenantRequest = (tenantId, data) => {
    return {
        type: UPDATE_TENANT_REQUEST,
        payload: { tenantId, data },
    };
};
export const updateTenantResponse = (message, responseType) => {
    return {
        type: UPDATE_TENANT_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateTenantStatusByTenantIdRequest = (tenantId, status) => {
    return {
        type: UPDATE_TENANT_STATUS_BY_TENANT_ID_REQUEST,
        payload: { tenantId, status },
    };
};
export const updateTenantStatusByTenantIdResponse = (message, responseType) => {
    return {
        type: UPDATE_TENANT_STATUS_BY_TENANT_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getTenantListByStatusRequest = (status) => {
    return {
        type: GET_TENANT_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getTenantListByStatusResponse = (tenantList) => {
    return {
        type: GET_TENANT_LIST_BY_STATUS_RESPONSE,
        payload: { tenantList },
    };
};

export const deleteTenantByTenantIdRequest = (tenantId) => {
    return {
        type: DELETE_TENANT_BY_TENANT_ID_REQUEST,
        payload: { tenantId },
    };
};
export const deleteTenantByTenantIdResponse = (message, responseType) => {
    return {
        type: DELETE_TENANT_BY_TENANT_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const generateDatabaseByTenantIdRequest = (tenantId) => {
    return {
        type: GENERATE_DATABASE_BY_TENANT_ID_REQUEST,
        payload: { tenantId: tenantId },
    };
};
export const generateDatabaseByTenantIdResponse = (message, responseType, data) => {
    return {
        type: GENERATE_DATABASE_BY_TENANT_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};