import { compareString } from 'helpers/generalUtils';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = (state) => state.legalUpdates || initialState;
const makeLegalUpdateSelectField = (field) =>
    createSelector(reducerState, (legalUpdates) => legalUpdates[field]);
const legalUpdatesList = () =>
    createSelector(makeLegalUpdateSelectField('legalUpdatesList'));

const selectSortedUpdateTypeList = () =>
    createSelector(
        makeLegalUpdateSelectField('updateTypeList'),
        (updateTypeList) =>
            updateTypeList
                .map((opt) => ({
                    value: opt.id,
                    label: opt.updateTypeName,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedSubUpdateTypeList = () =>
    createSelector(
        makeLegalUpdateSelectField('subUpdateTypeList'),
        (updateTypeList) =>
            updateTypeList
                .map((opt) => ({
                    value: opt.id,
                    label: opt.subUpdateTypeName,
                    parentUpdateTypeId: opt.parentUpdateTypeId,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedLegalUpdateStatusList = () =>
    createSelector(
        makeLegalUpdateSelectField('legalUpdateStatusList'),
        (updateTypeList) =>
            updateTypeList
                .map((opt) => ({
                    value: opt.id,
                    label: opt.statusName,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );

export {
    reducerState,
    makeLegalUpdateSelectField,
    legalUpdatesList,
    selectSortedUpdateTypeList,
    selectSortedSubUpdateTypeList,
    selectSortedLegalUpdateStatusList,
};
