import produce from 'immer';
import {
    DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE,
    DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_RESPONSE,
    GET_ALL_DOCUMENT_TYPE_LIST_RESPONSE,
    GET_ALL_LAW_DOCUMENTS_RESPONSE,
    GET_DOWNLOAD_LOG_RESPONSE,
    GET_LAW_DOCUMENTS_BY_LAW_ID_RESPONSE,
    GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE,
    GET_LAW_DOCUMENT_LIST_BY_STATUS_RESPONSE,
    STORE_DOWNLOAD_LOG_RESPONSE,
    UPDATE_LAW_DOCUMENT_RESPONSE,
    UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_RESPONSE,
    DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_RESPONSE,
    UPDATE_LAW_DOCUMENT_LEGAL_RESPONSE,
    ADD_LAW_DOCUMENT_RESPONSE,
    GET_LAW_DOCUMENT_VERSIONS_LIST_RESPONSE
} from './actionTypes';
export const initialState = {
    lawDocumentApiResponse: null,
    editLawDocumentApiResponse: null,
    deleteLawDocumentApiResponse: null,
    downloadLogApiResponse: null,
    lawDocumentList: [],
    lawDocumentsListResponse: {},
    allLawDocuments: [],
    documentTypeList: [],
    downloadLogList: [],
    standardBulkUploadApiResponse: [],
    documentComplianceList: [],
    updateLawDocumentApiResponse: null,
    addLawDocumentApiResponse: null,
    lawDocumentVersionsList: [],
};
const lawDocument = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_ALL_LAW_DOCUMENTS_RESPONSE:
                draft.allLawDocuments = action.payload.allLawDocuments;
                break;
            case GET_LAW_DOCUMENTS_BY_LAW_ID_RESPONSE:
                draft.lawDocumentsListResponse = {
                    lawDocumentList: action.payload.lawDocumentList,
                    legalUpdateList: action.payload.legalUpdateListForDocument
                };
                break;
            case GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE:
                draft.editLawDocumentApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case UPDATE_LAW_DOCUMENT_RESPONSE:
            case UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_RESPONSE:
                draft.lawDocumentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_LAW_DOCUMENT_LIST_BY_STATUS_RESPONSE:
                draft.lawDocumentList = action.payload.lawDocumentList;
                break;
            case DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE:
                draft.deleteLawDocumentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_DOCUMENT_TYPE_LIST_RESPONSE:
                draft.documentTypeList = action.payload.documentTypeList;
                break;
            case STORE_DOWNLOAD_LOG_RESPONSE:
                draft.downloadLogApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_DOWNLOAD_LOG_RESPONSE:
                draft.downloadLogList = action.payload.downloadLogList;
                break;
            case DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_RESPONSE:
                draft.standardBulkUploadApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_RESPONSE:
                draft.documentComplianceList = action.payload.documentComplianceList;
                break;
            case UPDATE_LAW_DOCUMENT_LEGAL_RESPONSE:
                draft.updateLawDocumentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case ADD_LAW_DOCUMENT_RESPONSE:
                draft.addLawDocumentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_LAW_DOCUMENT_VERSIONS_LIST_RESPONSE:
                draft.lawDocumentVersionsList = action.payload.lawDocumentVersionsList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default lawDocument;
