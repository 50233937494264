import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
    DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_REQUEST,
    GET_ALL_DOCUMENT_TYPE_LIST_REQUEST,
    GET_ALL_LAW_DOCUMENTS_REQUEST,
    GET_DOWNLOAD_LOG_REQUEST,
    GET_LAW_DOCUMENTS_BY_LAW_ID_REQUEST,
    GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
    GET_LAW_DOCUMENT_LIST_BY_STATUS_REQUEST,
    STORE_DOWNLOAD_LOG_REQUEST,
    UPDATE_LAW_DOCUMENT_REQUEST,
    UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_REQUEST,
    DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_REQUEST,
    ADD_LAW_DOCUMENT_REQUEST,
    UPDATE_LAW_DOCUMENT_LEGAL_REQUEST,
    GET_LAW_DOCUMENT_VERSIONS_LIST_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import {
    deleteLawDocumentByLawDocumentIdResponse,
    documentRespositoryStandardBulkUploadsResponse,
    getAllDocumentTypeListResponse,
    getAllLawDocumentsRequest,
    getAllLawDocumentsResponse,
    getDownloadLogResponse,
    getLawDocumentByLawDocumentIdResponse,
    getLawDocumentListByStatusResponse,
    getLawDocumentsByLawIdResponse,
    storeDownloadLogResponse,
    updateLawDocumentResponse,
    updateLawDocumentStatusByLawDocumentIdResponse,
    getComplianceListByDocIdResponse,
    addLawDocumentResponse,
    updateLawDocumentLegalResponse,
    getLawDocumentVersionsListResponse
} from './actions';
import {
    DOCUMENT_REPOSITORY_STANDARD,
    DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD,
    DOCUMENT_REPOSITORY_STANDARD_UPLOAD,
    DOCUMENT_TYPE,
    GET_DOWNLOAD_LOG,
    LAW_DOCUMENT,
    LAW_DOCUMENTS_BY_LAW,
    LAW_DOCUMENT_BY_STATUS,
    STORE_DOWNLOAD_LOG,
    DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID,
    LAW_DOCUMENTS_ADD,
    LAW_DOCUMENTS_VERSION_ADD,
    LAW_DOCUMENTS_VERSION_LIST
} from 'helpers/apiConstants';
import { DANGER, SUCCESS } from 'constants/commonConstants';
function* getAllLawDocuments({ payload: { data } }) {

    try {
        const response = yield call(
            invokeApi,
            'post',
            DOCUMENT_REPOSITORY_STANDARD,
            data ? data : {}
        );
        if (response.status) {
            yield put(getAllLawDocumentsResponse(response.data.list));
        } else {
            yield put(getAllLawDocumentsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawDocumentsByLawId({ payload: { lawId, displayMode } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW_DOCUMENTS_BY_LAW + '/' + lawId, { displayMode });
        if (response.status) {
            yield put(getLawDocumentsByLawIdResponse(response.data.list, response.data.legalUpdates));
        } else {
            yield put(getLawDocumentsByLawIdResponse([], []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawDocumentByLawDocumentId({ payload: { lawDocumentId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            LAW_DOCUMENT + '/' + lawDocumentId,
            ''
        );
        if (response.status) {
            yield put(
                getLawDocumentByLawDocumentIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getLawDocumentByLawDocumentIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLawDocument({ payload: { lawDocumentId, data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'put',
            DOCUMENT_REPOSITORY_STANDARD_UPLOAD + '/' + lawDocumentId,
            data,
            headers
        );
        if (response.status) {
            yield put(getAllLawDocumentsRequest());
            yield put(updateLawDocumentResponse(response.message, SUCCESS));
        } else {
            yield put(updateLawDocumentResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLawDocumentStatusByLawDocumentId({
    payload: { lawDocumentId, status },
}) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            DOCUMENT_REPOSITORY_STANDARD +
            '/' +
            lawDocumentId +
            '/status/' +
            status
        );
        if (response.status) {
            yield put(getAllLawDocumentsRequest());
            yield put(
                updateLawDocumentStatusByLawDocumentIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateLawDocumentStatusByLawDocumentIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawDocumentListByStatus({ payload: { status } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            LAW_DOCUMENT_BY_STATUS + '/' + status
        );
        if (response.status) {
            yield put(getLawDocumentListByStatusResponse(response.data.list));
        } else {
            yield put(getLawDocumentListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteLawDocumentByLawDocumentId({ payload: { lawDocumentId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            DOCUMENT_REPOSITORY_STANDARD + '/' + lawDocumentId
        );
        if (response.status) {
            yield put(getAllLawDocumentsRequest());
            yield put(
                deleteLawDocumentByLawDocumentIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteLawDocumentByLawDocumentIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllDocumentTypeList() {
    try {
        const response = yield call(invokeApi, 'get', DOCUMENT_TYPE, '');
        if (response.status) {
            yield put(getAllDocumentTypeListResponse(response.data.list));
        } else {
            yield put(getAllDocumentTypeListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* storeDownloadLog({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            STORE_DOWNLOAD_LOG + '/' + id,
            ''
        );
        if (response.status) {
            yield put(storeDownloadLogResponse(response.message, SUCCESS));
        } else {
            yield put(storeDownloadLogResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getDownloadLog({ payload: { documentId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            GET_DOWNLOAD_LOG + '/' + documentId,
            ''
        );
        if (response.status) {
            yield put(getDownloadLogResponse(response.data.list));
        } else {
            yield put(getDownloadLogResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* documentRespositorystandardBulkUploads({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'post',
            DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD,
            data,
            headers
        );
        if (response.status) {
            yield put(
                documentRespositoryStandardBulkUploadsResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                documentRespositoryStandardBulkUploadsResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getDocumentCompliaceList({ payload: { docId } }) {
    try {
        const response = yield call(invokeApi, 'get', DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID + '/' + docId, '');
        if (response.status) {
            yield put(getComplianceListByDocIdResponse(response.data.list));
        } else {
            yield put(getComplianceListByDocIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateLawDocumentLegal({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', LAW_DOCUMENTS_VERSION_ADD, data, headers);
        if (response.status) {
            yield put(updateLawDocumentLegalResponse(response.message, SUCCESS));
        } else {
            yield put(updateLawDocumentLegalResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* addLawDocument({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', LAW_DOCUMENTS_ADD, data, headers);
        if (response.status) {
            yield put(addLawDocumentResponse(response.message, SUCCESS));
        } else {
            yield put(addLawDocumentResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawDocumentVersionsList({ payload: { lawDocumentVersionIds } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW_DOCUMENTS_VERSION_LIST, { versionId: lawDocumentVersionIds });
        if (response.status) {
            yield put(getLawDocumentVersionsListResponse(response.data.list));
        } else {
            yield put(getLawDocumentVersionsListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchLawDocument() {
    yield takeEvery(GET_ALL_LAW_DOCUMENTS_REQUEST, getAllLawDocuments);
    yield takeEvery(
        GET_LAW_DOCUMENTS_BY_LAW_ID_REQUEST,
        getLawDocumentsByLawId
    );
    yield takeEvery(
        GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
        getLawDocumentByLawDocumentId
    );
    yield takeEvery(UPDATE_LAW_DOCUMENT_REQUEST, updateLawDocument);
    yield takeEvery(
        UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_REQUEST,
        updateLawDocumentStatusByLawDocumentId
    );
    yield takeEvery(
        GET_LAW_DOCUMENT_LIST_BY_STATUS_REQUEST,
        getLawDocumentListByStatus
    );
    yield takeEvery(
        DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
        deleteLawDocumentByLawDocumentId
    );
    yield takeEvery(GET_ALL_DOCUMENT_TYPE_LIST_REQUEST, getAllDocumentTypeList);
    yield takeEvery(STORE_DOWNLOAD_LOG_REQUEST, storeDownloadLog);
    yield takeEvery(GET_DOWNLOAD_LOG_REQUEST, getDownloadLog);
    yield takeEvery(
        DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_REQUEST,
        documentRespositorystandardBulkUploads
    );
    yield takeEvery(DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_REQUEST, getDocumentCompliaceList);
    yield takeEvery(ADD_LAW_DOCUMENT_REQUEST, addLawDocument);
    yield takeEvery(UPDATE_LAW_DOCUMENT_LEGAL_REQUEST, updateLawDocumentLegal);
    yield takeEvery(GET_LAW_DOCUMENT_VERSIONS_LIST_REQUEST, getLawDocumentVersionsList);
}
function* lawDocumentSaga() {
    yield all([fork(watchLawDocument)]);
}
export default lawDocumentSaga;
