import React from "react";
import { withMsal } from "@azure/msal-react";
import { Button } from "reactstrap";
import __t from "i18n/translator";
import { InteractionStatus } from "@azure/msal-browser";

class SSOLoginButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showButton: true, // Initially hide the button
            isLoginInProgress: false, // Track MSAL login status (optional)
        };
    }
    login = async () => {
        const msalInstance = this.props.msalContext.instance;
        try {
            const loginResponse = await msalInstance.loginPopup({
                scopes: [process.env.REACT_APP_SSO_CLIENT_ID + "/.default"],
                redirectUri: window.location.origin + '/auth.html'
            });
            if (loginResponse && loginResponse.accessToken) {
                this.props.setLocalStorage(loginResponse.accessToken)
            }
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    logout = () => {
        const msalInstance = this.props.msalContext.instance;
        msalInstance.logout();
    };
    render() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        return (
            <Button
                onClick={() => this.login()}
                color="link"
                className="sso_login_btn pb-0"
            >
                {__t(
                    'form_labels.login.sso_login'
                )}
            </Button>
        );
    }
}

export default withMsal(SSOLoginButton);
