import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.department || initialState;
const makeDepartmentSelectField = field =>
    createSelector(reducerState, departmentState => departmentState[field]);
const selectSortedDepartmentList = () =>
    createSelector(makeDepartmentSelectField('departmentList'), departmentList =>
        departmentList.map(opt => ({
            value: opt.id,
            label: opt.departmentName,
            companyGroupId: opt.companyGroupId
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeDepartmentSelectField,
    selectSortedDepartmentList
};
