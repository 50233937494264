import React, { Component } from 'react';
import { Row, Label, FormGroup, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, CLEAR, SEARCH, DATE_FORMAT_DATEPICKER, DATE_FORMAT_PLACEHOLDER, } from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawListRequest,
    getLawCategoryListByStatusRequest,
    getUserEntityListRequest,
    getAllFacilityTypeListRequest
} from 'store/actions';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { setEmptyToNull } from 'helpers/generalUtils';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import { Option, MultiValue, animatedComponents, isGTROLE } from 'helpers/projectUtils';
import __t from 'i18n/translator';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { selectSortedFacilityTypeList, selectSortedLawSearchList } from 'store/Masters/Law/selector';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedEntityList } from 'store/Entity/selector';
import { getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import DatePicker from "react-datepicker";

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({ key: subIndustryKey, saga: subIndustrySaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({ key: lawCategoryKey, saga: lawCategorySaga });
export const intFields = ['country', 'state', 'city'];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            searchFormData: this.props.searchFormData,
            sortedStateListDropDown: [],
            sortedCityListDropDown: [],
            sortedSubIndustryListDropDown: [],
            lawListByLawCategory: []
        };
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getUserEntityListRequest();
        this.props.getAllFacilityTypeListRequest();
    }
    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest([countryId]);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest([stateId]);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: values.countryId ? [values.countryId] : [],
                stateId: values.stateId ? [values.stateId] : [],
                cityId: values.cityId ? [values.cityId] : [],
                industryId: values.industryId ? values.industryId : [],
                subIndustryId: values.subIndustryId
                    ? values.subIndustryId
                    : [],
                lawCategoryId: values.lawCategoryId ? values.lawCategoryId : [],
                facilityTypeId: values.facilityTypeId ? values.facilityTypeId : [],
                type: this.props.pageType,
            },
            orderBy: {},
            displayMode: true
        };
        this.props.getLawListRequest(apiData, false);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.setState({
                searchFormData: values
            }, () => {
                this.props.onFormSubmit(formatValuesForApi(values));
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.setState({
            sortedStateListDropDown: [],
            sortedCityListDropDown: [],
            sortedSubIndustryListDropDown: [],
            lawListByLawCategory: []
        }, () => {
            this.props.onResetForm();
        })
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.sortedLawListDropDown &&
            this.props.sortedLawListDropDown !==
            nextprops.sortedLawListDropDown
        ) {
            this.setState({ lawListByLawCategory: nextprops.sortedLawListDropDown });
        }
        if (
            nextprops.sortedStateListDropDown &&
            this.props.sortedStateListDropDown !==
            nextprops.sortedStateListDropDown
        ) {
            this.setState({ sortedStateListDropDown: nextprops.sortedStateListDropDown });
        }
        if (
            nextprops.sortedCityListDropDown &&
            this.props.sortedCityListDropDown !==
            nextprops.sortedCityListDropDown
        ) {
            this.setState({ sortedCityListDropDown: nextprops.sortedCityListDropDown });
        }
        if (
            nextprops.sortedSubIndustryListDropDown &&
            this.props.sortedSubIndustryListDropDown !==
            nextprops.sortedSubIndustryListDropDown
        ) {
            this.setState({ sortedSubIndustryListDropDown: nextprops.sortedSubIndustryListDropDown });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.searchFormData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row className='filter-form'>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="countryId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropDown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'countryId',
                                                (opt) ? opt.value : ''
                                            );
                                            if (opt && opt.value) {
                                                this.getStateListByCountry(opt.value);
                                            } else {
                                                setFieldValue('stateId', '')
                                                setFieldValue('cityId', '')
                                                this.setState({
                                                    sortedStateListDropDown: [],
                                                    sortedCityListDropDown: [],
                                                })
                                            }
                                            this.getLawsByFilters({
                                                ...values,
                                                countryId: (opt) ? opt.value : '',
                                            });
                                        }}
                                        value={this.props.sortedCountryListDropDown.filter(
                                            option =>
                                                option.value ===
                                                values.countryId
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.statutory.country')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="stateId"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedStateListDropDown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'stateId',
                                                opt ? opt.value : ''
                                            );
                                            if (opt && opt.value) {
                                                this.getCityListByState(opt.value);
                                            } else {
                                                setFieldValue('cityId', '')
                                                this.setState({
                                                    sortedCityListDropDown: [],
                                                })
                                            }
                                            this.getLawsByFilters({
                                                ...values,
                                                stateId: opt ? opt.value : '',
                                            });
                                        }}
                                        value={this.state.sortedStateListDropDown.filter(
                                            option =>
                                                option.value ===
                                                values.stateId
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.statutory.state')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="cityId"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedCityListDropDown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'cityId',
                                                opt ? opt.value : ''
                                            );
                                            this.getLawsByFilters({
                                                ...values,
                                                cityId: opt ? opt.value : '',
                                            });
                                        }}
                                        value={this.state.sortedCityListDropDown.filter(
                                            option =>
                                                option.value ===
                                                values.cityId
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.statutory.city')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="industryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedIndustryListDropDown
                                        }
                                        onChange={opt => {
                                            let selectedValues = opt ? opt.map(x => x.value) : []; 
                                            setFieldValue(
                                                'industryId',
                                                selectedValues
                                            );
                                            if (selectedValues.length > 0) {
                                                this.getSubIndustryListByIndustry(selectedValues);
                                            } else {
                                                setFieldValue('subIndustryId', [])
                                                this.setState({
                                                    sortedSubIndustryListDropDown: []
                                                })
                                            }
                                            this.getLawsByFilters({
                                                ...values,
                                                industryId: selectedValues,
                                            });
                                        }}
                                        value={this.props.sortedIndustryListDropDown?.filter(
                                            option => values.industryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.statutory.industry')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="subIndustryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedSubIndustryListDropDown
                                        }
                                        onChange={opt => {
                                            let selectedValues = opt ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'subIndustryId',
                                                selectedValues
                                            );
                                            this.getLawsByFilters({
                                                ...values,
                                                subIndustryId: selectedValues,
                                            });
                                        }}
                                        value={this.state.sortedSubIndustryListDropDown?.filter(
                                            option => values.subIndustryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.statutory.sub_industry')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="facilityTypeId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedFacilityTypeListDropDown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'facilityTypeId',
                                                selectedValues
                                            );
                                            this.getLawsByFilters({
                                                ...values,
                                                facilityTypeId: selectedValues,
                                            });
                                        }}
                                        value={this.props.sortedFacilityTypeListDropDown?.filter(
                                            option => values.facilityTypeId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.laws.facility_types'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="lawCategoryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedLawCategoryListDropDown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'lawCategoryId',
                                                selectedValues
                                            );
                                            setFieldValue('lawId', []);
                                            if (selectedValues.length > 0) {
                                                this.getLawsByFilters({
                                                    ...values,
                                                    lawCategoryId:
                                                        selectedValues,
                                                });
                                            } else {
                                                this.setState({ lawListByLawCategory: [] })
                                            }
                                        }}
                                        value={this.props.sortedLawCategoryListDropDown?.filter(
                                            option => values.lawCategoryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.statutory.law_category')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        className="form-control selectbox"
                                        name="lawId[]"
                                        options={
                                            this.state.lawListByLawCategory
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'lawId',
                                                selectedValues
                                            );
                                        }}
                                        value={this.state.lawListByLawCategory?.filter(
                                            (option) =>
                                                values.lawId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        key="laws"
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.law')}{' '}
                                    </Label>
                                </FormGroup>
                                {isGTROLE() == false && (
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="entityId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedEntityListDropdown
                                            }
                                            onChange={(opt) => {
                                                let entityId = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue('entityId', entityId);
                                            }}
                                            value={this.props.sortedEntityListDropdown?.filter(
                                                (option) =>
                                                    values.entityId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.task_filters.entity')}
                                        </Label>
                                    </FormGroup>
                                )}
                                <FormGroup className='col-lg-2 sticky_label'>
                                    <Field
                                        component={
                                            DatePicker
                                        }
                                        isClearable={true}
                                        className="form-control"
                                        name="fromDate"
                                        selected={
                                            values.fromDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.fromDate
                                                )
                                                : null
                                        }
                                        dateFormat={
                                            DATE_FORMAT_DATEPICKER
                                        }
                                        onChange={(
                                            date
                                        ) => {
                                            setFieldValue(
                                                'fromDate',
                                                date
                                            );
                                            let dateCheck =
                                                getDateObjectWithValueFromMoment(
                                                    values.toDate
                                                ) <
                                                    getDateObjectWithValueFromMoment(
                                                        date
                                                    )
                                                    ? true
                                                    : false;
                                            if (dateCheck) {
                                                setFieldValue(
                                                    'toDate',
                                                    ''
                                                );
                                            }
                                        }}
                                        showMonthDropdown={
                                            true
                                        }
                                        showYearDropdown={
                                            true
                                        }
                                        dropdownMode="select"
                                        placeholderText={DATE_FORMAT_PLACEHOLDER}
                                        onFocus={(e) =>
                                            e.target.blur()
                                        }
                                    //  maxDate={new Date()}
                                    ></Field>
                                    <Label className="form-label">
                                        {__t('form_labels.archive_records.from_period')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-2 sticky_label'>
                                    <Field
                                        component={
                                            DatePicker
                                        }
                                        isClearable={true}
                                        className="form-control"
                                        name="toDate"
                                        selected={
                                            values.toDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.toDate
                                                )
                                                : null
                                        }
                                        dateFormat={
                                            DATE_FORMAT_DATEPICKER
                                        }
                                        onChange={(
                                            date
                                        ) => {
                                            setFieldValue(
                                                'toDate',
                                                date
                                            );
                                        }}
                                        showMonthDropdown={
                                            true
                                        }
                                        showYearDropdown={
                                            true
                                        }
                                        dropdownMode="select"
                                        placeholderText={DATE_FORMAT_PLACEHOLDER}
                                        onFocus={(e) =>
                                            e.target.blur()
                                        }
                                        minDate={(values?.fromDate) ? getDateObjectWithValueFromMoment(
                                            values?.fromDate
                                        ) : ''}
                                    ></Field>
                                    <Label className="form-label">
                                        {__t('form_labels.archive_records.to_period')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className={isGTROLE() == false ? "col-lg-8 mb-0 d-flex justify-content-end" : "col-lg-12 mb-0 d-flex justify-content-end"}>
                                    <div className="d-inline-block">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="mr-1"
                                        >{SEARCH}</Button>
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >{CLEAR}</Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedLawListDropDown: selectSortedLawSearchList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedIndustryListDropDown: selectSortedIndustryList(),
    sortedSubIndustryListDropDown: selectSortedSubIndustryList(),
    sortedLawCategoryListDropDown: selectSortedLawCategoryList(),
    sortedFacilityTypeListDropDown: selectSortedFacilityTypeList(),
    sortedEntityListDropdown: selectSortedEntityList(),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getLawListRequest,
    getUserEntityListRequest,
    getAllFacilityTypeListRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(SearchForm);