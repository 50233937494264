import { ADD_LAW_REQUEST, ADD_LAW_RESPONSE, DELETE_LAW_BY_LAW_ID_REQUEST, DELETE_LAW_BY_LAW_ID_RESPONSE, GET_ALL_ENTITY_TYPE_LIST_REQUEST, GET_ALL_ENTITY_TYPE_LIST_RESPONSE, GET_ALL_FACILITY_TYPE_LIST_REQUEST, GET_ALL_FACILITY_TYPE_LIST_RESPONSE, GET_ALL_LAW_LIST_REQUEST, GET_ALL_LAW_LIST_RESPONSE, GET_COUNTRY_COVERAGE_SEARCH_LIST_REQUEST, GET_COUNTRY_COVERAGE_SEARCH_LIST_RESPONSE, GET_LAWS_LIST_BY_LOGIN_USER_REQUEST, GET_LAWS_LIST_BY_LOGIN_USER_RESPONSE, GET_LAWS_LIST_REQUEST, GET_LAWS_LIST_RESPONSE, GET_LAWS_SEARCH_LIST_REQUEST, GET_LAWS_SEARCH_LIST_RESPONSE, GET_LAW_BY_LAW_ID_REQUEST, GET_LAW_BY_LAW_ID_RESPONSE, GET_LAW_LIST_BY_STATUS_REQUEST, GET_LAW_LIST_BY_STATUS_RESPONSE, UPDATE_LAW_REQUEST, UPDATE_LAW_RESPONSE, UPDATE_LAW_STATUS_BY_LAW_ID_REQUEST, UPDATE_LAW_STATUS_BY_LAW_ID_RESPONSE, UPDATE_LAW_VIA_UPDATE_REQUEST, UPDATE_LAW_VIA_UPDATE_RESPONSE } from "./actionTypes";
export const addLawRequest = data => {
    return {
        type: ADD_LAW_REQUEST,
        payload: { data },
    };
};
export const addLawResponse = (message, responseType) => {
    return {
        type: ADD_LAW_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllLawListRequest = () => {
    return {
        type: GET_ALL_LAW_LIST_REQUEST
    };
};
export const getAllLawListResponse = lawList => {
    return {
        type: GET_ALL_LAW_LIST_RESPONSE,
        payload: { lawList },
    };
};
export const getLawByLawIdRequest = (lawId) => {
    return {
        type: GET_LAW_BY_LAW_ID_REQUEST,
        payload: { lawId: lawId },
    };
};
export const getLawByLawIdResponse = (message, responseType, data) => {
    return {
        type: GET_LAW_BY_LAW_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateLawRequest = (lawId, data) => {
    return {
        type: UPDATE_LAW_REQUEST,
        payload: { lawId, data },
    };
};
export const updateLawResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateLawStatusByLawIdRequest = (lawId, status) => {
    return {
        type: UPDATE_LAW_STATUS_BY_LAW_ID_REQUEST,
        payload: { lawId, status },
    };
};
export const updateLawStatusByLawIdResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_STATUS_BY_LAW_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getLawListByStatusRequest = status => {
    return {
        type: GET_LAW_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getLawListByStatusResponse = lawList => {
    return {
        type: GET_LAW_LIST_BY_STATUS_RESPONSE,
        payload: { lawList },
    };
};
export const deleteLawByLawIdRequest = (lawId) => {
    return {
        type: DELETE_LAW_BY_LAW_ID_REQUEST,
        payload: { lawId },
    };
};
export const deleteLawByLawIdResponse = (message, responseType) => {
    return {
        type: DELETE_LAW_BY_LAW_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllEntityTypeListRequest = () => {
    return {
        type: GET_ALL_ENTITY_TYPE_LIST_REQUEST
    };
};
export const getAllEntityTypeListResponse = entityTypeList => {
    return {
        type: GET_ALL_ENTITY_TYPE_LIST_RESPONSE,
        payload: { entityTypeList },
    };
};
export const getAllFacilityTypeListRequest = () => {
    return {
        type: GET_ALL_FACILITY_TYPE_LIST_REQUEST
    };
};
export const getAllFacilityTypeListResponse = facilityTypeList => {
    return {
        type: GET_ALL_FACILITY_TYPE_LIST_RESPONSE,
        payload: { facilityTypeList },
    };
};

export const getLawsSearchListRequest = (data, pagination) => {
    return {
        type: GET_LAWS_SEARCH_LIST_REQUEST,
        payload: { data, pagination }
    };
};
export const getLawsSearchListResponse = (message, responseType, data) => {
    return {
        type: GET_LAWS_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data }
    };
};

export const getLawListRequest = (data, pagination) => {
    return {
        type: GET_LAWS_LIST_REQUEST,
        payload: { data, pagination }
    };
};
export const getLawListResponse = lawSearchList => {
    return {
        type: GET_LAWS_LIST_RESPONSE,
        payload: { lawSearchList },
    };
};


export const getCountryCoverageSearchListRequest = (data) => {
    return {
        type: GET_COUNTRY_COVERAGE_SEARCH_LIST_REQUEST,
        payload: { data }
    };
};
export const getCountryCoverageSearchListResponse = (countryCoverageList) => {
    return {
        type: GET_COUNTRY_COVERAGE_SEARCH_LIST_RESPONSE,
        payload: { countryCoverageList }
    };
};


export const getLawsListByLoginUserRequest = () => {
    return {
        type: GET_LAWS_LIST_BY_LOGIN_USER_REQUEST
    };
};
export const getLawsListByLoginUserResponse = lawsListOfLoginUser => {
    return {
        type: GET_LAWS_LIST_BY_LOGIN_USER_RESPONSE,
        payload: { lawsListOfLoginUser },
    };
};
export const updateLawViaUpdateRequest = (lawId, data) => {
    return {
        type: UPDATE_LAW_VIA_UPDATE_REQUEST,
        payload: { lawId, data }
    };
};
export const updateLawViaUpdateResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_VIA_UPDATE_RESPONSE,
        payload: { message, responseType }
    };
};