import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isGTROLE } from 'helpers/projectUtils';
import MainIndex from './MainIndex';
import NewsIndex from './Index';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { Col } from 'reactstrap';

class CommonIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGtRole: isGTROLE(),
        };
    }



    render() {
        return (
            <React.Fragment>
                <Col lg="12">
                    <CommonBreadcrum type={'legal-watch-dog'} />
                </Col>
                {this.state.isGtRole === true ? <MainIndex /> : <NewsIndex defaultDate={(this.props.match.params?.defaultDate) ? this.props.match.params?.defaultDate : ''} />}
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({});
const withConnect = connect(mapStatetoProps, {});
export default compose(withConnect, withRouter)(CommonIndex);
