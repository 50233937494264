import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { getEntityTeamsByEntityIdRequest } from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedEntityTeamAndConsultantTeamUserList } from 'store/Entity/selector';
import {
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    SUCCESS,
} from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import { Button, Card, Col, FormGroup, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import SimpleReactValidator from 'simple-react-validator';
import {
    customDateValidator,
    formatDateForApi,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { customSubTaskAttachmentFileValidator } from 'helpers/customValidators';
import Dropzone from 'react-dropzone';
import { toastrMessage } from 'helpers/messageHelper';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

class AddSubTaskModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: customDateValidator(),
        });
        this.state = {
            selectedFiles: [],
            errors: '',
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }

    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customSubTaskAttachmentFileValidator(file.name)) {
                errorsArray.push(
                    __t('validations.custom.sub_task_attachment_file')
                );
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({
                errors:
                    __t('form_labels.reporting.sub_task.attachment_file') +
                    ' ' +
                    __t('validations.custom.sub_task_attachment_file'),
            });
        } else {
            this.setState({ errors: '' });
        }
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );
        this.setState({ selectedFiles: files });
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid({
                ...values,
                dueDate: formatDateForApi(values.dueDate),
                attachmentFile: this.state.selectedFiles,
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    removeFile = (file) => {
        var array = [...this.state.selectedFiles]; // make a separate copy of the array
        var index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ selectedFiles: array }, () => {
                if (this.state.selectedFiles.length === 0) {
                    this.setState({
                        errors: '',
                    });
                }
            });
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.subTaskApiResponse &&
            this.props.subTaskApiResponse !== nextprops.subTaskApiResponse
        ) {
            toastrMessage(
                nextprops.subTaskApiResponse.message,
                nextprops.subTaskApiResponse.responseType
            );
            if (nextprops.subTaskApiResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
        if (
            nextprops.editSubTaskApiResponse &&
            this.props.editSubTaskApiResponse !==
            nextprops.editSubTaskApiResponse
        ) {
            toastrMessage(
                nextprops.editSubTaskApiResponse.message,
                nextprops.editSubTaskApiResponse.responseType
            );
            if (nextprops.editSubTaskApiResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
        if (nextprops.taskDetails !== this.props.taskDetails) {
            this.props.getEntityTeamsByEntityIdRequest(
                nextprops.taskDetails?.entityId
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={
                        this.props.modalName +
                        ' | ' +
                        this.props.subTaskData?.parentTask?.taskCode
                    }
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Row className='add-sub-task-info'>
                                <Col>
                                    <p>
                                        <b className="text-primary">Entity:</b> {
                                            this.props.taskDetails
                                                .complianceAllocation?.entity
                                                ?.entityName
                                        }
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <b className="text-primary">Entity Location:</b> {
                                            this.props.taskDetails
                                                .complianceAllocation
                                                ?.entityLocation?.city?.cityName
                                        }
                                    </p>
                                </Col>
                            </Row>
                            <Formik
                                initialValues={this.props.subTaskData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>

                                        <Row className="d-flex mr-0">
                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    name="title"
                                                    className="form-control"
                                                    placeholder={__t(
                                                        'form_labels.reporting.sub_task.title'
                                                    )}
                                                    value={values.title}
                                                    maxLength={250}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.sub_task.title'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.reporting.sub_task.title'
                                                    ),
                                                    values.title,
                                                    'required|max:250',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.sub_task.task_title_required'
                                                            ),
                                                            max: __t(
                                                                'validations.sub_task.task_title_max',
                                                                { max: 250 }
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    component="textarea"
                                                    name="description"
                                                    className="form-control"
                                                    placeholder={__t(
                                                        'form_labels.reporting.sub_task.description'
                                                    )}
                                                    value={values.description}
                                                    maxLength={1000}
                                                    rows="3"
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.sub_task.description'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.reporting.sub_task.description'
                                                    ),
                                                    values.description,
                                                    'required|max:1000',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.sub_task.task_description_required'
                                                            ),
                                                            max: __t(
                                                                'validations.sub_task.task_description_max',
                                                                { max: 1000 }
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-6 w-100 sticky_label">
                                                <Field
                                                    name="dueDate"
                                                    component={DatePicker}
                                                    isClearable={true}
                                                    className="form-control"
                                                    placeholderText={__t(
                                                        'form_labels.reporting.sub_task.due_date'
                                                    )}
                                                    selected={
                                                        values.dueDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.dueDate
                                                            )
                                                            : null
                                                    }
                                                    dateFormat={
                                                        DATE_FORMAT_DATEPICKER
                                                    }
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            `dueDate`,
                                                            date
                                                        );
                                                    }}
                                                    maxDate={getDateObjectWithValueFromMoment(
                                                        this.props.maxDueDate
                                                    )}
                                                    showMonthDropdown={true}
                                                    showYearDropdown={true}
                                                    dropdownMode="select"
                                                    onFocus={(e) =>
                                                        e.target.blur()
                                                    }
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.sub_task.due_date'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.reporting.sub_task.due_date'
                                                    ),
                                                    values.dueDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.dueDate
                                                        )
                                                        : '',
                                                    'required|customDate',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.sub_task.due_date_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-6">
                                                <Field
                                                    component={Select}
                                                    name="submitterId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (opt) =>
                                                            opt.value !==
                                                            values.firstApproverUserId &&
                                                            opt.value !==
                                                            values.secondApproverUserId
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'submitterId',
                                                            opt ? opt.value : ''
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.submitterId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.sub_task.submitter'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.reporting.sub_task.submitter'
                                                    ),
                                                    values.submitterId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.sub_task.submitter_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-6">
                                                <Field
                                                    component={Select}
                                                    name="firstApproverUserId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (opt) =>
                                                            opt.value !==
                                                            values.submitterId &&
                                                            opt.value !==
                                                            values.secondApproverUserId
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'firstApproverUserId',
                                                            opt ? opt.value : ''
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.firstApproverUserId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.sub_task.first_approver'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.reporting.sub_task.first_approver'
                                                    ),
                                                    values.firstApproverUserId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.sub_task.first_approver_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-6">
                                                <Field
                                                    component={Select}
                                                    name="secondApproverUserId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (opt) =>
                                                            opt.value !==
                                                            values.submitterId &&
                                                            opt.value !==
                                                            values.firstApproverUserId
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'secondApproverUserId',
                                                            opt ? opt.value : ''
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.secondApproverUserId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.sub_task.second_approver'
                                                    )}
                                                </Label>
                                            </FormGroup>
                                            <FormGroup className="col-lg-12">
                                                <Label>
                                                    {__t(
                                                        'form_labels.reporting.sub_task.attachment_file'
                                                    )}
                                                </Label>
                                                <Dropzone
                                                    onDrop={(acceptedFiles) =>
                                                        this.handleAcceptedFiles(
                                                            acceptedFiles
                                                        )
                                                    }
                                                >
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                    }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}
                                                            >
                                                                <input
                                                                    {...getInputProps()}
                                                                />
                                                                <h3>
                                                                    {__t(
                                                                        'form_labels.reporting.drag_drop'
                                                                    )}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {this.state.errors != '' && (
                                                    <div className="srv-validation-message">
                                                        {this.state.errors}
                                                    </div>
                                                )}
                                                <div
                                                    className="dropzone-previews mt-2"
                                                    id="file-previews"
                                                >
                                                    {this.state.selectedFiles.map(
                                                        (f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={
                                                                        i +
                                                                        '-file'
                                                                    }
                                                                >
                                                                    <div className="p-2">
                                                                        <Row className="align-items-center">
                                                                            <Col>
                                                                                <Link
                                                                                    to="#"
                                                                                    className="text-muted font-weight-bold"
                                                                                >
                                                                                    {
                                                                                        f.name
                                                                                    }
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                    <strong>
                                                                                        {
                                                                                            f.formattedSize
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            </Col>
                                                                            <Col>
                                                                                <Button
                                                                                    className="btn btn-danger btn-sm"
                                                                                    title="Delete file"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        this.removeFile(
                                                                                            f
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                    </svg>
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </FormGroup>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {
                                                            this.props
                                                                .operationType
                                                        }
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={
                                                            this.props
                                                                .onModalDismiss
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedEntityTeamAndConsultantTeamUserDropdown:
        selectSortedEntityTeamAndConsultantTeamUserList(),
});
const withConnect = connect(mapStatetoProps, {
    getEntityTeamsByEntityIdRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(AddSubTaskModal);
