import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = (state) => state.projects || initialState;
const makeProjectsSelectField = (field) =>
    createSelector(reducerState, (projects) => projects[field]);
const selectProjectsList = () =>
    createSelector(makeProjectsSelectField('projectsList'));

const selectSortedEntityAllUsersList = () =>
    createSelector(
        makeProjectsSelectField('entityAllUsersList'),
        (entityAllUsersList) => {
            let finalUsers = entityAllUsersList.map((opt) => ({
                value: opt.id,
                label: opt?.fullName + ' - ' + opt?.email,
            }));
            return finalUsers
                .filter(
                    (a, i) =>
                        finalUsers.findIndex((s) => a.value === s.value) === i
                )
                .sort((item1, item2) =>
                    compareString(item1.label, item2.label)
                );
        }
    );

export {
    reducerState,
    makeProjectsSelectField,
    selectProjectsList,
    selectSortedEntityAllUsersList,
};
