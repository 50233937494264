export const listColumns = [
    {
        label: 'Role Name',
        field: 'roleName',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialRolePermissionDataState = () => {
    return {
        roleId: "",
        roleName: "",
        modules: [],
    }
}