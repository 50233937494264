export const ADD_ENTITY_REQUEST = 'add_entity_request';
export const ADD_ENTITY_RESPONSE = 'add_entity_success';
export const GET_ALL_ENTITY_LIST_REQUEST = 'get_all_entity_list_request';
export const GET_ALL_ENTITY_LIST_RESPONSE = 'get_all_entity_list_response';
export const GET_ENTITY_BY_ENTITY_ID_REQUEST = 'get_entity_by_entity_id_request';
export const GET_ENTITY_BY_ENTITY_ID_RESPONSE = 'get_entity_by_entity_id_response';
export const UPDATE_ENTITY_REQUEST = 'update_entity_request';
export const UPDATE_ENTITY_RESPONSE = 'update_entity_success';
export const UPDATE_ENTITY_STATUS_BY_ENTITY_ID_REQUEST = 'update_entity_status_by_entity_id_request';
export const UPDATE_ENTITY_STATUS_BY_ENTITY_ID_RESPONSE = 'update_entity_status_by_entity_id_response';
export const GET_ENTITY_LIST_BY_STATUS_REQUEST = 'get_entity_list_by_status_request';
export const GET_ENTITY_LIST_BY_STATUS_RESPONSE = 'get_entity_list_by_status_response';
export const DELETE_ENTITY_BY_ENTITY_ID_REQUEST = 'delete_entity_by_entity_id_request';
export const DELETE_ENTITY_BY_ENTITY_ID_RESPONSE = 'delete_entity_by_entity_id_response';
export const GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_REQUEST = 'get_entity_list_by_company_group_id_request';
export const GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_RESPONSE = 'get_entity_list_by_company_group_id_response';
export const GET_ENTITY_TEAMS_BY_ENTITY_ID_REQUEST = 'get_entity_teams_by_entity_id_request';
export const GET_ENTITY_TEAMS_BY_ENTITY_ID_RESPONSE = 'get_entity_teams_by_entity_id_response';
export const GET_ENTITY_ALL_USERS_BY_ENTITY_ID_REQUEST = 'get_entity_all_users_by_entity_id_request';
export const GET_ENTITY_ALL_USERS_BY_ENTITY_ID_RESPONSE = 'get_entity_all_users_by_entity_id_response';
export const GET_USER_ENTITY_LIST_REQUEST = 'get_user_entity_list_request';
export const GET_USER_ENTITY_LIST_RESPONSE = 'get_user_entity_list_response';
export const GET_ENTITY_LIST_BY_COUNTRY_REQUEST = 'get_entity_list_by_country_request';
export const GET_ENTITY_LIST_BY_COUNTRY_RESPONSE = 'get_entity_list_by_country_response';
export const GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_REQUEST = 'get_all_entity_list_on_user_list_page_request';
export const GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_RESPONSE = 'get_all_entity_list_on_user_list_page_response';
export const GET_USER_LIST_BY_ENTITY_AND_ROLE_REQUEST = 'get_user_list_by_entity_and_role_request';
export const GET_USER_LIST_BY_ENTITY_AND_ROLE_RESPONSE = 'get_user_list_by_entity_and_role_response';
export const CLEAR_FILTER_ENTITY_USER_RESPONSE = 'clear_filter_entity_user_response';