import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_COMPLIANCE_UPDATE_UPLOAD_REQUEST,
    GET_COMPLIANCE_UPDATE_FILE_LOGS_REQUEST,
    GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_REQUEST,
    UPDATE_ASSIGN_GCM_LEGAL_UPDATE_REQUEST,
    GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_REQUEST,
    GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_REQUEST,
    LEGAL_UPDATE_DOCUMENT_UPLOAD_REQUEST,
    COMPLIANCE_LEGAL_UPDATE_REQUEST,
    DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_REQUEST,
    GET_ALL_UPDATE_TYPE_LIST_REQUEST,
    GET_ALL_SUB_UPDATE_TYPE_LIST_REQUEST,
    GET_ALL_LEGAL_UPDATE_STATUS_LIST_REQUEST,
    GET_ALL_RELEVANT_UPDATE_REQUEST,
    CLOSE_LEGAL_UPDATE_BY_ID_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addCompliancesUpdateUploadResponse,
    getComplianceUploadedFilesLogsResponse,
    getComplianceLegalUpdateSearchResponse,
    updateAssigngcmLegalUpdateResponse,
    getComplianceLegalUpdateDetailsByIdResponse,
    getComplianceLegalUpdateActivityByIdResponse,
    legalUpdateDocumentUploadResponse,
    complianceLegalUpdateResponse,
    deleteLegalUpdateByLegalUpdateIdResponse,
    getAllupdateTypeListResponse,
    getAllSubUpdateTypeListResponse,
    getAllLegalUpdateStatusListResponse,
    getRelevantUpdateSearchResponse,
    closeLegalUpdateByIdResponse,
    getComplianceLegalUpdateDetailsByIdRequest
} from './actions';
import {
    COMPLIANCES_UPDATE_UPLOADS,
    COMPLIANCES_UPDATE_FILE_LOGS,
    COMPLIANCES_LEGAL_UPDATE_SEARCH,
    ASSIGN_GCM_UPDATER_LEGAL_UPDATE,
    GET_COMPLIANCE_LEGAL_UPDATE_BY_ID,
    GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID,
    LEGAL_UPDATE_UPLOAD_DOCUMENT,
    COMPLIANCE_LEGAL_UPDATE,
    UPDATE_TYPE,
    SUB_UPDATE_TYPE,
    LEGAL_UPDATE_STATUS,
    RELEVANT_UPDATE_SEARCH,
    CLOSE_LEGAL_UPDATE
} from 'helpers/apiConstants';
function* addCompliancesUpdateUpload({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'post',
            COMPLIANCES_UPDATE_UPLOADS,
            data,
            headers
        );
        if (response.status) {
            yield put(
                addCompliancesUpdateUploadResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                addCompliancesUpdateUploadResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllUploadedFilesLogs() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            COMPLIANCES_UPDATE_FILE_LOGS
        );
        if (response.status) {
            yield put(
                getComplianceUploadedFilesLogsResponse(response.data.list)
            );
        } else {
            yield put(getComplianceUploadedFilesLogsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getComplianceLegalUpdateSearch({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            COMPLIANCES_LEGAL_UPDATE_SEARCH,
            data
        );
        if (response.status) {
            if (pagination) {
                yield put(
                    getComplianceLegalUpdateSearchResponse(
                        response.message,
                        SUCCESS,
                        response.data
                    )
                );
            }
        } else {
            if (pagination) {
                yield put(
                    getComplianceLegalUpdateSearchResponse(
                        response.message,
                        DANGER,
                        {}
                    )
                );
            }
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getRelevantUpdateSearch({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            RELEVANT_UPDATE_SEARCH,
            data
        );
        if (response.status) {
            yield put(
                getRelevantUpdateSearchResponse(response.message,
                    SUCCESS,
                    response.data)
            );
        } else {
            yield put(getRelevantUpdateSearchResponse(response.message,
                DANGER,
                {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateAssigngcmLegalUpdate({ payload: { data, id } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            ASSIGN_GCM_UPDATER_LEGAL_UPDATE + id,
            data
        );
        if (response.status) {
            yield put(
                updateAssigngcmLegalUpdateResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                updateAssigngcmLegalUpdateResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getComplianceLegalUpdateDetailsById({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            GET_COMPLIANCE_LEGAL_UPDATE_BY_ID + '/' + id,
            ''
        );
        if (response.status) {
            yield put(
                getComplianceLegalUpdateDetailsByIdResponse(
                    response.message,
                    SUCCESS,
                    response.data.data
                )
            );
        } else {
            yield put(
                getComplianceLegalUpdateDetailsByIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getComplianceLegalUpdateActivityById({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID + '/' + id,
            ''
        );
        if (response.status) {
            yield put(
                getComplianceLegalUpdateActivityByIdResponse(
                    response.message,
                    SUCCESS,
                    response.data.list
                )
            );
        } else {
            yield put(
                getComplianceLegalUpdateActivityByIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* legalUpdateDocumentUpload({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'post',
            LEGAL_UPDATE_UPLOAD_DOCUMENT,
            data,
            headers
        );
        if (response.status) {
            yield put(
                legalUpdateDocumentUploadResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                legalUpdateDocumentUploadResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* complianceLegalUpdate({ payload: { id, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            COMPLIANCE_LEGAL_UPDATE + '/' + id,
            data
        );
        if (response.status) {
            yield put(complianceLegalUpdateResponse(response.message, SUCCESS));
        } else {
            yield put(complianceLegalUpdateResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteLegalUpdateByLegalUpdateId({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            COMPLIANCE_LEGAL_UPDATE + '/' + id
        );
        if (response.status) {
            yield put(
                deleteLegalUpdateByLegalUpdateIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteLegalUpdateByLegalUpdateIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllupdateTypeList() {
    try {
        const response = yield call(invokeApi, 'get', UPDATE_TYPE, '');
        if (response.status) {
            yield put(getAllupdateTypeListResponse(response.data.list));
        } else {
            yield put(getAllupdateTypeListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllSubUpdateTypeList() {
    try {
        const response = yield call(invokeApi, 'get', SUB_UPDATE_TYPE, '');
        if (response.status) {
            yield put(getAllSubUpdateTypeListResponse(response.data.list));
        } else {
            yield put(getAllSubUpdateTypeListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllLegalUpdateStatusList() {
    try {
        const response = yield call(invokeApi, 'get', LEGAL_UPDATE_STATUS, '');
        if (response.status) {
            yield put(getAllLegalUpdateStatusListResponse(response.data.list));
        } else {
            yield put(getAllLegalUpdateStatusListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* closeLegalUpdateById({ payload: { data, id } }) {
    try {
        const response = yield call(invokeApi, 'put', CLOSE_LEGAL_UPDATE + '/' + id, data);
        if (response.status) {
            yield put(getComplianceLegalUpdateDetailsByIdRequest(id));
            yield put(closeLegalUpdateByIdResponse(response.message, SUCCESS));
        } else {
            yield put(closeLegalUpdateByIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchLawCompliance() {
    yield takeEvery(ADD_COMPLIANCE_UPDATE_UPLOAD_REQUEST, addCompliancesUpdateUpload);
    yield takeEvery(GET_COMPLIANCE_UPDATE_FILE_LOGS_REQUEST, getAllUploadedFilesLogs);
    yield takeEvery(GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_REQUEST, getComplianceLegalUpdateSearch);
    yield takeEvery(GET_ALL_RELEVANT_UPDATE_REQUEST, getRelevantUpdateSearch);
    yield takeEvery(UPDATE_ASSIGN_GCM_LEGAL_UPDATE_REQUEST, updateAssigngcmLegalUpdate);
    yield takeEvery(GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_REQUEST, getComplianceLegalUpdateDetailsById);
    yield takeEvery(GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_REQUEST, getComplianceLegalUpdateActivityById);
    yield takeEvery(LEGAL_UPDATE_DOCUMENT_UPLOAD_REQUEST, legalUpdateDocumentUpload);
    yield takeEvery(COMPLIANCE_LEGAL_UPDATE_REQUEST, complianceLegalUpdate);
    yield takeEvery(DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_REQUEST, deleteLegalUpdateByLegalUpdateId);
    yield takeEvery(GET_ALL_UPDATE_TYPE_LIST_REQUEST, getAllupdateTypeList);
    yield takeEvery(GET_ALL_SUB_UPDATE_TYPE_LIST_REQUEST, getAllSubUpdateTypeList);
    yield takeEvery(GET_ALL_LEGAL_UPDATE_STATUS_LIST_REQUEST, getAllLegalUpdateStatusList);
    yield takeEvery(CLOSE_LEGAL_UPDATE_BY_ID_REQUEST, closeLegalUpdateById);
}
function* legalUpdatesSaga() {
    yield all([fork(watchLawCompliance)]);
}
export default legalUpdatesSaga;
