export const ADD_SUB_INDUSTRY_REQUEST = 'add_sub_industry_request';
export const ADD_SUB_INDUSTRY_RESPONSE = 'add_sub_industry_response';
export const GET_ALL_SUB_INDUSTRY_LIST_REQUEST = 'get_all_sub_industry_list_request';
export const GET_ALL_SUB_INDUSTRY_LIST_RESPONSE = 'get_all_sub_industry_list_response';
export const GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST = 'get_sub_industry_by_sub_industry_id_request';
export const GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE = 'get_sub_industry_by_sub_industry_id_response';
export const UPDATE_SUB_INDUSTRY_REQUEST = 'update_sub_industry_request';
export const UPDATE_SUB_INDUSTRY_RESPONSE = 'update_sub_industry_response';
export const UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_REQUEST = 'update_sub_industry_status_by_sub_industry_id_request';
export const UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_RESPONSE = 'update_sub_industry_status_by_sub_industry_id_response';
export const GET_SUB_INDUSTRY_LIST_BY_STATUS_REQUEST = 'get_sub_industry_list_by_status_request';
export const GET_SUB_INDUSTRY_LIST_BY_STATUS_RESPONSE = 'get_sub_industry_list_by_status_response';
export const DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST = 'delete_sub_industry_by_sub_industry_id_request';
export const DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE = 'delete_sub_industry_by_sub_industry_id_response';
export const GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_REQUEST = 'get_sub_industry_list_by_industry_request';
export const GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_RESPONSE = 'get_sub_industry_list_by_industry_response';
