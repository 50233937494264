import {
    ACTIVATE_ACCOUNT_REQUEST,
    ACTIVATE_ACCOUNT_RESPONSE,
    ACTIVATE_ACCOUNT_TOKEN_REQUEST,
    ACTIVATE_ACCOUNT_TOKEN_RESPONSE,
} from './actionTypes';
export const activateAccountTokenRequest = (token) => {
    return {
        type: ACTIVATE_ACCOUNT_TOKEN_REQUEST,
        payload: { token },
    };
};
export const activateAccountTokenResponse = (message, responseType, responseData) => {
    return {
        type: ACTIVATE_ACCOUNT_TOKEN_RESPONSE,
        payload: { message, responseType, responseData },
    };
};
export const activateAccountRequest = (data) => {
    return {
        type: ACTIVATE_ACCOUNT_REQUEST,
        payload: { data },
    };
};
export const activateAccountResponse = (message, responseType, responseData) => {
    return {
        type: ACTIVATE_ACCOUNT_RESPONSE,
        payload: { message, responseType, responseData },
    };
};
