import {
    ADD_SUB_TASK_REQUEST,
    ADD_SUB_TASK_RESPONSE,
    CHANGE_TASK_DETAILS_BY_TASK_ID_REQUEST,
    CHANGE_TASK_DETAILS_BY_TASK_ID_RESPONSE,
    DELETE_SUB_TASK_BY_ID_REQUEST,
    DELETE_SUB_TASK_BY_ID_RESPONSE,
    GET_ALL_SUB_TASK_BY_TASK_ID_LIST_REQUEST,
    GET_ALL_SUB_TASK_BY_TASK_ID_LIST_RESPONSE,
    GET_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
    GET_ASSIGNED_TASK_SEARCH_LIST_RESPONSE,
    GET_TASK_ACTIVITY_LIST_BY_TASK_ID_REQUEST,
    GET_TASK_ACTIVITY_LIST_BY_TASK_ID_RESPONSE,
    GET_TASK_DETAILS_BY_TASK_ID_REQUEST,
    GET_TASK_DETAILS_BY_TASK_ID_RESPONSE,
    GET_TASK_DOCUMENTS_BY_TASK_ID_REQUEST,
    GET_TASK_DOCUMENTS_BY_TASK_ID_RESPONSE,
    GET_TASK_STATUS_LIST_REQUEST,
    GET_TASK_STATUS_LIST_RESPONSE,
    GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_REQUEST,
    GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_RESPONSE,
    REOPEN_TASK_BY_TASK_ID_REQUEST,
    REOPEN_TASK_BY_TASK_ID_RESPONSE,
    REPORTING_TASK_BY_TASK_ID_REQUEST,
    REPORTING_TASK_BY_TASK_ID_RESPONSE,
    SEND_FOR_AUDIT_TASK_BY_TASK_IDS_REQUEST,
    SEND_FOR_AUDIT_TASK_BY_TASK_IDS_RESPONSE,
    UPDATE_SUB_TASK_REQUEST,
    UPDATE_SUB_TASK_RESPONSE,
    GET_REPORT_TASK_SEARCH_LIST_REQUEST,
    GET_REPORT_TASK_SEARCH_LIST_RESPONSE,
    GET_REVIEW_TASK_SEARCH_LIST_REQUEST,
    GET_REVIEW_TASK_SEARCH_LIST_RESPONSE,
    GET_AUDIT_TASK_SEARCH_LIST_REQUEST,
    GET_AUDIT_TASK_SEARCH_LIST_RESPONSE,
    ADD_COLLABORATOR_BY_TASK_ID_RESPONSE,
    ADD_COLLABORATOR_BY_TASK_ID_REQUEST,
    DELETE_COLLABORATOR_BY_TASK_ID_REQUEST,
    DELETE_COLLABORATOR_BY_TASK_ID_RESPONSE,
    GET_COLLABORATOR_LIST_BY_TASK_ID_REQUEST,
    GET_COLLABORATOR_LIST_BY_TASK_ID_RESPONSE,
    ADD_FILE_UPLOAD_REQUEST,
    ADD_FILE_UPLOAD_RESPONSE,
    UPDATE_QUICK_TASK_REQUEST,
    UPDATE_QUICK_TASK_RESPONSE,
    GET_KNOW_YOUR_COMPLIANCES_LIST_REQUEST,
    GET_KNOW_YOUR_COMPLIANCES_LIST_RESPONSE,
    GET_TASK_USER_DETAILS_BY_TASK_ID_REQUEST,
    GET_TASK_USER_DETAILS_BY_TASK_ID_RESPONSE,
    GET_USER_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
    GET_USER_ASSIGNED_TASK_SEARCH_LIST_RESPONSE,
    CLEAR_TASK_SEARCH_API_RESPONSE,
} from './actionTypes';

export const getTaskStatusListRequest = () => {
    return {
        type: GET_TASK_STATUS_LIST_REQUEST,
    };
};
export const getTaskStatusListResponse = (taskStatusList) => {
    return {
        type: GET_TASK_STATUS_LIST_RESPONSE,
        payload: { taskStatusList },
    };
};

export const getAssignedTaskSearchListRequest = (data, pagination) => {
    return {
        type: GET_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getAssignedTaskSearchListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_ASSIGNED_TASK_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getUserAssignedTaskSearchListRequest = (data, pagination) => {
    return {
        type: GET_USER_ASSIGNED_TASK_SEARCH_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getUserAssignedTaskSearchListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_USER_ASSIGNED_TASK_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getKnowYourComplianceListRequest = (data, pagination) => {
    return {
        type: GET_KNOW_YOUR_COMPLIANCES_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getKnowYourComplianceListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_KNOW_YOUR_COMPLIANCES_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getReportTaskSearchListRequest = (data, pagination) => {
    return {
        type: GET_REPORT_TASK_SEARCH_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getReportTaskSearchListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_REPORT_TASK_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getReviewTaskSearchListRequest = (data, pagination) => {
    return {
        type: GET_REVIEW_TASK_SEARCH_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getReviewTaskSearchListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_REVIEW_TASK_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getAuditTaskSearchListRequest = (data, pagination) => {
    return {
        type: GET_AUDIT_TASK_SEARCH_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getAuditTaskSearchListResponse = (message, responseType, data) => {
    return {
        type: GET_AUDIT_TASK_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getTaskDetailsByTaskIdRequest = (taskId) => {
    return {
        type: GET_TASK_DETAILS_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId },
    };
};
export const getTaskDetailsByTaskIdResponse = (message, responseType, data) => {
    return {
        type: GET_TASK_DETAILS_BY_TASK_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getTaskUserDetailsByTaskIdRequest = (taskId) => {
    return {
        type: GET_TASK_USER_DETAILS_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId },
    };
};
export const getTaskUserDetailsByTaskIdResponse = (message, responseType, data) => {
    return {
        type: GET_TASK_USER_DETAILS_BY_TASK_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const getTaskActivityListByTaskIdRequest = (taskId) => {
    return {
        type: GET_TASK_ACTIVITY_LIST_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId },
    };
};
export const getTaskActivityListByTaskIdResponse = (taskActivityList) => {
    return {
        type: GET_TASK_ACTIVITY_LIST_BY_TASK_ID_RESPONSE,
        payload: { taskActivityList },
    };
};

export const getTaskDocumentsByTaskIdRequest = (taskId, complianceId) => {
    return {
        type: GET_TASK_DOCUMENTS_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId, complianceId: complianceId },
    };
};
export const getTaskDocumentsByTaskIdResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_TASK_DOCUMENTS_BY_TASK_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const reopenTaskByTaskIdRequest = (taskId) => {
    return {
        type: REOPEN_TASK_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId },
    };
};
export const reopenTaskByTaskIdResponse = (message, responseType, data) => {
    return {
        type: REOPEN_TASK_BY_TASK_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const sendForAuditTaskByTaskIdsRequest = (taskIds, data) => {
    return {
        type: SEND_FOR_AUDIT_TASK_BY_TASK_IDS_REQUEST,
        payload: { taskIds, data },
    };
};
export const sendForAuditTaskByTaskIdsResponse = (message, responseType) => {
    return {
        type: SEND_FOR_AUDIT_TASK_BY_TASK_IDS_RESPONSE,
        payload: { message, responseType },
    };
};

export const reportingTaskByTaskIdRequest = (taskId, data) => {
    return {
        type: REPORTING_TASK_BY_TASK_ID_REQUEST,
        payload: { taskId, data },
    };
};
export const reportingTaskByTaskIdResponse = (message, responseType) => {
    return {
        type: REPORTING_TASK_BY_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const changeTaskDetailsByTaskIdRequest = (taskId, data) => {
    return {
        type: CHANGE_TASK_DETAILS_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId, data: data },
    };
};
export const changeTaskDetailsByTaskIdResponse = (message, responseType) => {
    return {
        type: CHANGE_TASK_DETAILS_BY_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};
/**
 *  -------------------- START --------------------
 * Crud for Sub Task
 * */
export const addSubTaskRequest = (data) => {
    return {
        type: ADD_SUB_TASK_REQUEST,
        payload: { data },
    };
};
export const addSubTaskResponse = (message, responseType) => {
    return {
        type: ADD_SUB_TASK_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllSubTaskByTaskIdListRequest = (taskId) => {
    return {
        type: GET_ALL_SUB_TASK_BY_TASK_ID_LIST_REQUEST,
        payload: { taskId },
    };
};
export const getAllSubTaskByTaskIdListResponse = (subTaskList) => {
    return {
        type: GET_ALL_SUB_TASK_BY_TASK_ID_LIST_RESPONSE,
        payload: { subTaskList },
    };
};
export const updateSubTaskRequest = (data) => {
    return {
        type: UPDATE_SUB_TASK_REQUEST,
        payload: { data },
    };
};
export const updateSubTaskResponse = (message, responseType) => {
    return {
        type: UPDATE_SUB_TASK_RESPONSE,
        payload: { message, responseType },
    };
};
export const deleteSubTaskByIdRequest = (subTaskId) => {
    return {
        type: DELETE_SUB_TASK_BY_ID_REQUEST,
        payload: { subTaskId: subTaskId },
    };
};
export const deleteSubTaskByIdResponse = (message, responseType) => {
    return {
        type: DELETE_SUB_TASK_BY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
/**
 *  -------------------- END --------------------
 * Crud for Sub Task
 * */
export const getAllTransactionalDocumentsListRequest = () => {
    return {
        type: GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_REQUEST,
    };
};
export const getAllTransactionalDocumentsListResponse = (
    transactionalDocumentList
) => {
    return {
        type: GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_RESPONSE,
        payload: { transactionalDocumentList },
    };
};

export const addCollaboratorByTaskIdRequest = (taskId, data) => {
    return {
        type: ADD_COLLABORATOR_BY_TASK_ID_REQUEST,
        payload: { taskId, data },
    };
};
export const addCollaboratorByTaskIdResponse = (message, responseType) => {
    return {
        type: ADD_COLLABORATOR_BY_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteCollaboratorByTaskIdRequest = (taskId, userId) => {
    return {
        type: DELETE_COLLABORATOR_BY_TASK_ID_REQUEST,
        payload: { taskId, userId },
    };
};
export const deleteCollaboratorByTaskIdResponse = (message, responseType) => {
    return {
        type: DELETE_COLLABORATOR_BY_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getCollaboratorListByTaskIdRequest = (taskId) => {
    return {
        type: GET_COLLABORATOR_LIST_BY_TASK_ID_REQUEST,
        payload: { taskId },
    };
};
export const getCollaboratorListByTaskIdResponse = (collaboratorList) => {
    return {
        type: GET_COLLABORATOR_LIST_BY_TASK_ID_RESPONSE,
        payload: { collaboratorList },
    };
};

export const addFileUploadRequest = (data) => {
    return {
        type: ADD_FILE_UPLOAD_REQUEST,
        payload: { data },
    };
};

export const addFileUploadResponse = (message, responseType, data) => {
    return {
        type: ADD_FILE_UPLOAD_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const updateQuickTaskRequest = (data) => {
    return {
        type: UPDATE_QUICK_TASK_REQUEST,
        payload: { data },
    };
};

export const updateQuickTaskResponse = (message, responseType) => {
    return {
        type: UPDATE_QUICK_TASK_RESPONSE,
        payload: { message, responseType },
    };
};

export const clearTaskSearchApiResponse = () => {
    return {
        type: CLEAR_TASK_SEARCH_API_RESPONSE
    };
};
