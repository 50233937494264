import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    InputGroupText,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    ACTIVE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    SUBMIT,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getAllReleaseTypeListRequest,
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import newsSaga from 'store/News/saga';
import newsReducer from 'store/News/reducer';
import {
    createNewsRequest,
    updateNewsRequest,
    getNewsDetailsByIdRequest,
} from 'store/News/actions';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import {
    makeNewsSelectField,
    selectSortedReleaseTypeList,
} from 'store/News/selector';
import {
    customDateValidator,
    formatDateForApi,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import {
    customValidPhoneField,
    customValidTextField,
} from 'helpers/customValidators';
import { initialNewsDataState } from './CommonFunctions';
import __t from 'i18n/translator';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { IntComponent } from 'components/Application/NumberFormat';
import JoditEditor from 'jodit-react';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { redirectUrl } from 'helpers/generalUtils';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import CustomMultiSelectCreatable from 'components/Application/CustomMultiSelectCreatable';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
const newsKey = 'news';
const withNewsReducer = injectReducer({
    key: newsKey,
    reducer: newsReducer,
});
const withNewsSaga = injectSaga({ key: newsKey, saga: newsSaga });

const config = {
    spellcheck: true,
    askBeforePasteFromWord: false,
    limitChars: 60000,
    limitHTML: false,
    askBeforePasteHTML: false,
    useSearch: true,
    placeholder: 'Details of News...',
    removeButtons: [
        'cut',
        'copy',
        'paste',
        'change mode',
        'print',
        'insert',
        'about',
        'subscript',
        'superscript',
        'source',
        'classSpan',
        'copyformat',
        'file',
        'video'
    ],
};

class NewsForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customDateValidator(),
                customValidTextField(),
                customValidPhoneField()
            ),
        });
        this.state = {
            operationType: SUBMIT,
            newsId: this.props.match.params.newsId
                ? parseInt(this.props.match.params.newsId)
                : '',
            pageHeaderName: this.props.match.params.newsId
                ? __t('page_headers.news_edit')
                : __t('page_headers.news_add'),
            newsData: initialNewsDataState(),
            sortedStateListDropDown: [],
            sortedCityListDropDown: [],
            newsDetailsSection: false
        };
    }
    componentDidMount() {
        if (this.state.newsId) {
            this.props.getNewsDetailsByIdRequest(this.state.newsId);
        }
        this.props.getAllReleaseTypeListRequest();
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest([countryId]);
        this.props.getCityListByStateRequest([]);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest([stateId]);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = {
                ...values,
                publicationDate: formatDateForApi(values.publicationDate),
                effectiveDate: formatDateForApi(values.effectiveDate),
            };
            if (this.state.operationType === UPDATE) {
                this.props.updateNewsRequest(this.state.newsId, formValues);
            } else {
                this.props.createNewsRequest(formValues);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.sortedStateListDropDown &&
            this.props.sortedStateListDropDown !==
            nextprops.sortedStateListDropDown
        ) {
            this.setState({
                sortedStateListDropDown: nextprops.sortedStateListDropDown,
            });
        }
        if (
            nextprops.sortedCityListDropDown &&
            this.props.sortedCityListDropDown !==
            nextprops.sortedCityListDropDown
        ) {
            this.setState({
                sortedCityListDropDown: nextprops.sortedCityListDropDown,
            });
        }
        if (
            nextprops.newsApiResponse &&
            this.props.newsApiResponse !== nextprops.newsApiResponse
        ) {
            toastrMessage(
                nextprops.newsApiResponse.message,
                nextprops.newsApiResponse.responseType
            );
            if (nextprops.newsApiResponse.responseType === SUCCESS) {
                this.props.history.push('/legal-watch-dog');
            }
        }
        if (
            nextprops.newsDetailsApiResponse &&
            this.props.newsDetailsApiResponse !==
            nextprops.newsDetailsApiResponse
        ) {
            let publicationDate = nextprops.newsDetailsApiResponse.data?.news
                .publicationDate
                ? getDateObjectWithValueFromMoment(
                    nextprops.newsDetailsApiResponse.data?.news
                        .publicationDate
                )
                : null;
            let effectiveDate = nextprops.newsDetailsApiResponse.data?.news
                .effectiveDate
                ? getDateObjectWithValueFromMoment(
                    nextprops.newsDetailsApiResponse.data?.news
                        .effectiveDate
                )
                : null;

            this.setState(
                {
                    operationType: UPDATE,
                    newsData: {
                        ...nextprops.newsDetailsApiResponse.data?.news,
                        publicationDate: publicationDate,
                        effectiveDate: effectiveDate
                    },
                },
                () => {
                    this.getStateListByCountry(this.state.newsData.countryId);
                    if (this.state.newsData.stateId) {
                        this.getCityListByState(this.state.newsData.stateId);
                    }
                    if (this.state.newsData.industryId.length > 0) {
                        this.getSubIndustryListByIndustry(
                            this.state.newsData.industryId
                        );
                    }
                }
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    {
                        <Col lg="12">
                            <CommonBreadcrum
                                type={'add-legal-watch-dog'}
                                edit={this.state.operationType == UPDATE}
                            />
                        </Col>
                    }

                    <Col lg="12">
                        <Formik
                            initialValues={this.state.newsData}
                            enableReinitialize={true}
                            onSubmit={this.formSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className="news_edit_form">

                                    <div className="page-header">
                                        <h4 className="with-back-btn">
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/legal-watch-dog'
                                                    );
                                                }}
                                            >
                                                { }
                                            </a>{' '}
                                            {this.state.pageHeaderName}{' '}
                                        </h4>
                                    </div>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="releaseTypeId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedReleaseTypeListDropDown.filter((opt) => opt.type === 'release')
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'releaseTypeId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                        }}
                                                        value={this.props.sortedReleaseTypeListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.releaseTypeId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.release_type'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.release_type'
                                                        ),
                                                        values.releaseTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.release_type_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="countryId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedCountryListDropDown
                                                        }
                                                        onChange={(opt) => {
                                                            let selected = opt
                                                                ? opt.value
                                                                : '';
                                                            setFieldValue(
                                                                'countryId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                            setFieldValue(
                                                                'stateId',
                                                                ''
                                                            );
                                                            setFieldValue(
                                                                'cityId',
                                                                ''
                                                            );
                                                            if (
                                                                selected != ''
                                                            ) {
                                                                this.getStateListByCountry(
                                                                    opt.value
                                                                );
                                                            } else {
                                                                this.setState({
                                                                    sortedStateListDropDown:
                                                                        [],
                                                                });
                                                            }
                                                        }}
                                                        value={this.props.sortedCountryListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.countryId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.country'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.country'
                                                        ),
                                                        values.countryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.country_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="stateId"
                                                        className="form-control selectbox"
                                                        options={this.state.sortedStateListDropDown.filter(
                                                            (opt) =>
                                                                opt.value !== -1
                                                        )}
                                                        onChange={(opt) => {
                                                            let selected = opt
                                                                ? opt.value
                                                                : '';
                                                            setFieldValue(
                                                                'stateId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                            setFieldValue(
                                                                'cityId',
                                                                ''
                                                            );
                                                            if (
                                                                selected != ''
                                                            ) {
                                                                this.getCityListByState(
                                                                    opt.value
                                                                );
                                                            } else {
                                                                this.setState({
                                                                    sortedCityListDropDown:
                                                                        [],
                                                                });
                                                            }
                                                        }}
                                                        value={this.state.sortedStateListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.stateId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.state'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="cityId"
                                                        className="form-control selectbox"
                                                        options={this.state.sortedCityListDropDown.filter(
                                                            (opt) =>
                                                                opt.value !== -1
                                                        )}
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'cityId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                        }}
                                                        value={this.state.sortedCityListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.cityId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.city'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="lawCategoryId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedLawCategoryListDropDown
                                                        }
                                                        onChange={(opt) => {

                                                            setFieldValue(
                                                                'lawCategoryId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                        }}
                                                        value={this.props.sortedLawCategoryListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.lawCategoryId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.law_category'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.law_category'
                                                        ),
                                                        values.countryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.law_category_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-3 w-100 sticky_label">
                                                    <Field
                                                        component={DatePicker}
                                                        className="form-control"
                                                        name="publicationDate"
                                                        selected={
                                                            values.publicationDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.publicationDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                'publicationDate',
                                                                date
                                                            );
                                                            let dateCheck =
                                                                getDateObjectWithValueFromMoment(
                                                                    values.publicationDate
                                                                ) <
                                                                    getDateObjectWithValueFromMoment(
                                                                        date
                                                                    )
                                                                    ? true
                                                                    : false;
                                                            if (dateCheck) {
                                                                setFieldValue(
                                                                    'publicationDate',
                                                                    ''
                                                                );
                                                            }
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        isClearable={true}
                                                        dropdownMode="select"
                                                        placeholderText="DD/MM/YYYY"
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.publication_date'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.publication_date'
                                                        ),
                                                        values.publicationDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.publicationDate
                                                            )
                                                            : '',
                                                        'required|customDate',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.publication_date_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="industryId[]"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedIndustryListDropDown.filter(
                                                            (opt) =>
                                                                opt.value !== -1
                                                        )}
                                                        onChange={(opt) => {
                                                            let selectedValues =
                                                                opt &&
                                                                opt.map(
                                                                    (x) =>
                                                                        x.value
                                                                );
                                                            setFieldValue(
                                                                'industryId',
                                                                selectedValues
                                                            );
                                                            this.getSubIndustryListByIndustry(
                                                                selectedValues
                                                            );
                                                        }}
                                                        value={this.props.sortedIndustryListDropDown?.filter(
                                                            (option) =>
                                                                values.industryId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.industry'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.industry'
                                                        ),
                                                        values.industryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.industry_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="subIndustryId[]"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedSubIndustryListDropDown.filter(
                                                            (opt) =>
                                                                opt.value !== -1
                                                        )}
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'subIndustryId',
                                                                opt &&
                                                                opt.map(
                                                                    (x) =>
                                                                        x.value
                                                                )
                                                            );
                                                        }}
                                                        value={this.props.sortedSubIndustryListDropDown?.filter(
                                                            (option) =>
                                                                values.subIndustryId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.sub_industry'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.sub_industry'
                                                        ),
                                                        values.subIndustryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.sub_industry_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        name="webLink"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.news.weblink'
                                                        )}
                                                        value={values.webLink}
                                                        maxLength={255}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.weblink'
                                                        )}

                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.weblink'
                                                        ),
                                                        values.webLink,
                                                        'url',
                                                        {
                                                            messages: {
                                                                url: __t(
                                                                    'validations.news.weblink_valid'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-3 input-group">
                                                    <Field
                                                        as={IntComponent}
                                                        name="readTime"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.news.read_time'
                                                        )}
                                                        min={1}
                                                        max={300}
                                                    />
                                                    <InputGroupText>
                                                        {__t(
                                                            'form_labels.news.in_minutes'
                                                        )}
                                                    </InputGroupText>

                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.read_time'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.read_time'
                                                        ),
                                                        values.readTime,
                                                        'required|numeric|min:1,num|max:300,num',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.read_time_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={CustomMultiSelect}
                                                        name="updateTypeId[]"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedReleaseTypeListDropDown.filter((opt) => opt.type === 'update')}
                                                        onChange={(opt) => {
                                                            setFieldValue('updateTypeId', opt && opt.map((x) => x.value));
                                                        }}
                                                        value={this.props.sortedReleaseTypeListDropDown?.filter((option) =>
                                                            values.updateTypeId?.includes(option.value)
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.news.update_type'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.news.update_type'
                                                        ),
                                                        values.updateTypeId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.news.update_type_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-3 w-100 sticky_label">
                                                    <Field
                                                        component={DatePicker}
                                                        className="form-control"
                                                        name="effectiveDate"
                                                        selected={values.effectiveDate ? getDateObjectWithValueFromMoment(values.effectiveDate) : null}
                                                        dateFormat={DATE_FORMAT_DATEPICKER}
                                                        onChange={(date) => {
                                                            setFieldValue('effectiveDate', date);
                                                            let dateCheck = getDateObjectWithValueFromMoment(values.effectiveDate) < getDateObjectWithValueFromMoment(date) ? true : false;
                                                            if (dateCheck) {
                                                                setFieldValue('effectiveDate', '');
                                                            }
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        isClearable={true}
                                                        dropdownMode="select"
                                                        placeholderText="DD/MM/YYYY"
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                    />
                                                    <Label className="form-label">
                                                        {__t('form_labels.news.effective_date_of_publication')}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t('form_labels.news.effective_date_of_publication'),
                                                        values.effectiveDate
                                                            ? getDateObjectWithValueFromMoment(values.effectiveDate)
                                                            : '',
                                                        'required|customDate',
                                                        {
                                                            messages: {
                                                                required: __t('validations.news.effective_date_of_publication_required'),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <CustomisedCollapseForOne
                                        viewDialog={this.state.newsDetailsSection}
                                        dialogName={__t('form_labels.news.enter_full_article')}
                                    >
                                        <Row className='p-3'>
                                            <FormGroup className='col-12'>
                                                <Field
                                                    name="newsTitle"
                                                    className="form-control"
                                                    placeholder={__t(
                                                        'form_labels.news.news_title'
                                                    )}
                                                    value={values.newsTitle}
                                                    maxLength={255}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.news.news_title'
                                                    )}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.news.news_title'
                                                    ),
                                                    values.newsTitle,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.news.news_title_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <Col lg="6">
                                                <Row>
                                                    <FormGroup className='col-12'>
                                                        <Field
                                                            component="textarea"
                                                            name="particularsOfUpdate"
                                                            className="form-control"
                                                            placeholder={__t(
                                                                'form_labels.news.particulars_of_update'
                                                            )}
                                                            value={
                                                                values.particularsOfUpdate
                                                            }
                                                            maxLength={1000}
                                                            rows={3}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.news.particulars_of_update'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.news.particulars_of_update'
                                                            ),
                                                            values.particularsOfUpdate,
                                                            'required|min:3|max:1000',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.news.particulars_of_update_required'
                                                                    ),
                                                                    min: __t(
                                                                        'validations.news.particulars_of_update_min',
                                                                        { min: 3 }
                                                                    ),
                                                                    max: __t(
                                                                        'validations.news.particulars_of_update_max',
                                                                        {
                                                                            max: 1000,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-12">
                                                        <Field
                                                            component="textarea"
                                                            name="impactOfUpdate"
                                                            className="form-control"
                                                            placeholder={__t(
                                                                'form_labels.news.impact_of_update'
                                                            )}
                                                            value={
                                                                values.impactOfUpdate
                                                            }
                                                            maxLength={1000}
                                                            rows={3}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.news.impact_of_update'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.news.impact_of_update'
                                                            ),
                                                            values.impactOfUpdate,
                                                            'required|min:3|max:1000',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.news.impact_of_update_required'
                                                                    ),
                                                                    min: __t(
                                                                        'validations.news.impact_of_update_min',
                                                                        { min: 3 }
                                                                    ),
                                                                    max: __t(
                                                                        'validations.news.impact_of_update_max',
                                                                        {
                                                                            max: 1000,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-12">
                                                        <Field
                                                            component="textarea"
                                                            name="remarks"
                                                            className="form-control"
                                                            placeholder={__t(
                                                                'form_labels.news.remarks'
                                                            )}
                                                            value={
                                                                values.remarks
                                                            }
                                                            maxLength={1000}
                                                            rows={3}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.news.remarks'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.news.remarks'
                                                            ),
                                                            values.remarks,
                                                            'required|min:3|max:1000',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.news.remarks_required'
                                                                    ),
                                                                    min: __t(
                                                                        'validations.news.remarks_min',
                                                                        { min: 3 }
                                                                    ),
                                                                    max: __t(
                                                                        'validations.news.remarks_max',
                                                                        {
                                                                            max: 1000,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                </Row>
                                            </Col>
                                            <Col lg="6">
                                                <Row>
                                                    <FormGroup className="col-12">
                                                        <Field
                                                            component="textarea"
                                                            name="sourceOfUpdate"
                                                            className="form-control"
                                                            placeholder={__t(
                                                                'form_labels.news.source_of_update'
                                                            )}
                                                            value={
                                                                values.sourceOfUpdate
                                                            }
                                                            maxLength={1000}
                                                            rows={3}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.news.source_of_update'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.news.source_of_update'
                                                            ),
                                                            values.sourceOfUpdate,
                                                            'required|min:3|max:1000',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.news.source_of_update_required'
                                                                    ),
                                                                    min: __t(
                                                                        'validations.news.source_of_update_min',
                                                                        { min: 3 }
                                                                    ),
                                                                    max: __t(
                                                                        'validations.news.source_of_update_max',
                                                                        {
                                                                            max: 1000,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-12">
                                                        <Field
                                                            component="textarea"
                                                            name="businessFunctionImpacted"
                                                            className="form-control"
                                                            placeholder={__t(
                                                                'form_labels.news.business_function_impacted'
                                                            )}
                                                            value={
                                                                values.businessFunctionImpacted
                                                            }
                                                            maxLength={1000}
                                                            rows={3}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.news.business_function_impacted'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.news.business_function_impacted'
                                                            ),
                                                            values.businessFunctionImpacted,
                                                            'required|min:3|max:1000',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.news.business_function_impacted_required'
                                                                    ),
                                                                    min: __t(
                                                                        'validations.news.business_function_impacted_min',
                                                                        { min: 3 }
                                                                    ),
                                                                    max: __t(
                                                                        'validations.news.business_function_impacted_max',
                                                                        {
                                                                            max: 1000,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-12">
                                                        <Field
                                                            component={CustomMultiSelectCreatable}
                                                            name="keywords[]"
                                                            className="form-control selectbox"
                                                            options={[]}
                                                            onChange={(opt) => {
                                                                setFieldValue('keywords', opt ? opt.map((x) => x.value.replace(/\s/g, "")) : []);
                                                            }}
                                                            value={
                                                                values.keywords.map((keyword) => ({
                                                                    label: keyword,
                                                                    value: keyword,
                                                                }))
                                                            }
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={true}
                                                            components={{ MultiValue, animatedComponents }}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.news.keywords'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.news.keywords'
                                                            ),
                                                            values.keywords,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.news.keywords_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                </Row>
                                            </Col>


                                            <FormGroup className="col-12 mb-0">
                                                <JoditEditor
                                                    value={
                                                        values.newsDetails
                                                    }
                                                    config={config}
                                                    onBlur={(
                                                        newContent
                                                    ) => {
                                                        setFieldValue(
                                                            'newsDetails',
                                                            newContent
                                                        );
                                                    }}
                                                    // maxLength={60000}
                                                    minLength={1000}
                                                    rows="10"
                                                    className="form-control"
                                                />
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.news.news_details'
                                                    ),
                                                    values.newsDetails,
                                                    'required|min:1000',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.news.news_details_required'
                                                            ),
                                                            min: __t(
                                                                'validations.news.news_details_min',
                                                                {
                                                                    min: 1000,
                                                                }
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>

                                        </Row>
                                    </CustomisedCollapseForOne>

                                    <Row>
                                        <Col lg="12">
                                            <FormGroup className="mb-0 d-flex justify-content-end">
                                                <div className="d-inline-block">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {
                                                            this.state
                                                                .operationType
                                                        }
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={() => {
                                                            redirectUrl(
                                                                this.props.history,
                                                                '/legal-watch-dog'
                                                            );
                                                        }}
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedReleaseTypeListDropDown: selectSortedReleaseTypeList(),
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedIndustryListDropDown: selectSortedIndustryList(),
    sortedSubIndustryListDropDown: selectSortedSubIndustryList(),
    sortedLawCategoryListDropDown: selectSortedLawCategoryList(),
    newsApiResponse: makeNewsSelectField('newsApiResponse'),
    newsDetailsApiResponse: makeNewsSelectField('newsDetailsApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getAllReleaseTypeListRequest,
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    createNewsRequest,
    updateNewsRequest,
    getNewsDetailsByIdRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withNewsReducer,
    withNewsSaga,
    withRouter
)(NewsForm);
