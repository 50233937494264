import React, { Component } from 'react';
import { getComplianceListByDocIdRequest } from 'store/actions';
import { makeLawDocumentSelectField } from 'store/LawDocument/selector';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import ShowMoreText from "react-show-more-text";

const columns = [
    {
        label: 'Code',
        field: 'complianceCode',
    },
    {
        label: 'Compliance Title',
        field: 'complianceTitle',
    },
    // {
    //     label: 'Law Code',
    //     field: 'lawCode',
    // },
    // {
    //     label: 'Short Name of Act/Regulation',
    //     field: 'lawShortName',
    // },
    {
        label: 'Applicability Details',
        field: 'complianceApplicability',
    },
    // {
    //     label: 'Classification',
    //     field: 'complianceCategoryName',
    // },
    // {
    //     label: 'Applicability Type',
    //     field: 'applicabilityCondition',
    // },
    // {
    //     label: 'Frequency',
    //     field: 'periodicityName',
    // },
];
class ViewRelevantCompliances extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentId: '',
            documentComplianceList: []
        };
    }
    generateTable = records => {
        let complianceRecords = [];
        complianceRecords = records.map((complianceItem) => {
            let complianceCodeActions = (
                <a
                    href={() => false}
                    title="View Details"
                    className="tbl-link"
                    onClick={() => {
                        window.open('/statutory-compliances/view-details/' + complianceItem.id, '_blank');
                    }}
                >{complianceItem?.complianceCode}</a>
            );
            return {
                complianceTitle: complianceItem?.complianceTitle,
                complianceCode: complianceCodeActions,
                // lawCode: (complianceItem?.law) ? complianceItem?.law.lawCode : '',
                // lawShortName: (complianceItem?.law) ? complianceItem?.law.lawShortName : '',
                // complianceCategoryName:
                //     complianceItem?.complianceCategory?.complianceCategoryName,
                // complianceTypeName:
                //     complianceItem?.complianceType?.complianceTypeName,
                complianceApplicability: this.getShowMoreTextElement(complianceItem.complianceApplicability),
                periodicityName: complianceItem.periodicity?.periodicityName,
            };
        })
        return complianceRecords;
    } 
    getShowMoreTextElement = (element, lines = 2) => {
        return (
            <ShowMoreText
                lines={lines}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.documentId !== this.props.documentId) {
            this.setState({
                documentId: nextprops.documentId
            }, () => {
                this.props.getComplianceListByDocIdRequest(this.state.documentId)
            });
        }
        if (nextprops.documentComplianceList !== this.props.documentComplianceList) {
            this.setState({
                documentComplianceList: this.generateTable(nextprops.documentComplianceList)
            })
        }
    }
    render() {
        const reactTableData = {
            columns: columns,
            rows: this.state.documentComplianceList,
        };
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider logs_modal'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <CustomisedDatatable
                        className="table table-bordered"
                        tableRecords={reactTableData}
                    />
                </CustomisedSideModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    documentComplianceList: makeLawDocumentSelectField('documentComplianceList')
});
const withConnect = connect(mapStatetoProps, {
    getComplianceListByDocIdRequest
});
export default compose(
    withConnect,
    withRouter
)(ViewRelevantCompliances);
