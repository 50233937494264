import { ADD_EVENT_REQUEST, ADD_EVENT_RESPONSE, DELETE_EVENT_BY_EVENT_ID_REQUEST, DELETE_EVENT_BY_EVENT_ID_RESPONSE, GET_ALL_EVENT_LIST_REQUEST, GET_ALL_EVENT_LIST_RESPONSE, GET_EVENT_BY_EVENT_ID_REQUEST, GET_EVENT_BY_EVENT_ID_RESPONSE, GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_REQUEST, GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_RESPONSE, GET_EVENT_LIST_BY_SEARCH_REQUEST, GET_EVENT_LIST_BY_SEARCH_RESPONSE, GET_EVENT_LIST_BY_STATUS_REQUEST, GET_EVENT_LIST_BY_STATUS_RESPONSE, GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_REQUEST, GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_RESPONSE, UPDATE_EVENT_ORDER_BY_EVENT_ID_REQUEST, UPDATE_EVENT_ORDER_BY_EVENT_ID_RESPONSE, UPDATE_EVENT_REQUEST, UPDATE_EVENT_RESPONSE, UPDATE_EVENT_STATUS_BY_EVENT_ID_REQUEST, UPDATE_EVENT_STATUS_BY_EVENT_ID_RESPONSE } from "./actionTypes";
export const addEventRequest = data => {
    return {
        type: ADD_EVENT_REQUEST,
        payload: { data },
    };
};
export const addEventResponse = (message, responseType) => {
    return {
        type: ADD_EVENT_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllEventListRequest = () => {
    return {
        type: GET_ALL_EVENT_LIST_REQUEST
    };
};
export const getAllEventListResponse = eventList => {
    return {
        type: GET_ALL_EVENT_LIST_RESPONSE,
        payload: { eventList },
    };
};
export const getEventByEventIdRequest = (eventId) => {
    return {
        type: GET_EVENT_BY_EVENT_ID_REQUEST,
        payload: { eventId: eventId },
    };
};
export const getEventByEventIdResponse = (message, responseType, data) => {
    return {
        type: GET_EVENT_BY_EVENT_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateEventRequest = (eventId, data) => {
    return {
        type: UPDATE_EVENT_REQUEST,
        payload: { eventId, data },
    };
};
export const updateEventResponse = (message, responseType) => {
    return {
        type: UPDATE_EVENT_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateEventStatusByEventIdRequest = (eventId, status) => {
    return {
        type: UPDATE_EVENT_STATUS_BY_EVENT_ID_REQUEST,
        payload: { eventId, status },
    };
};
export const updateEventStatusByEventIdResponse = (message, responseType) => {
    return {
        type: UPDATE_EVENT_STATUS_BY_EVENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getEventListByStatusRequest = status => {
    return {
        type: GET_EVENT_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getEventListByStatusResponse = eventList => {
    return {
        type: GET_EVENT_LIST_BY_STATUS_RESPONSE,
        payload: { eventList },
    };
};
export const deleteEventByEventIdRequest = (eventId) => {
    return {
        type: DELETE_EVENT_BY_EVENT_ID_REQUEST,
        payload: { eventId },
    };
};
export const deleteEventByEventIdResponse = (message, responseType) => {
    return {
        type: DELETE_EVENT_BY_EVENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};


export const getEventListBySearchRequest = (data) => {
    return {
        type: GET_EVENT_LIST_BY_SEARCH_REQUEST,
        payload: { data },
    };
};
export const getEventListBySearchResponse = (eventList) => {
    return {
        type: GET_EVENT_LIST_BY_SEARCH_RESPONSE,
        payload: { eventList },
    };
};

export const getEventListByFilterForAllocationRequest = (data) => {
    return {
        type: GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_REQUEST,
        payload: { data },
    };
};
export const getEventListByFilterForAllocationResponse = (eventList) => {
    return {
        type: GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_RESPONSE,
        payload: { eventList },
    };
};
export const getLawComplianceListByEventIdRequest = (eventId) => {
    return {
        type: GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_REQUEST,
        payload: { eventId: eventId },
    };
};
export const getLawComplianceListByEventIdResponse = (message, responseType, data) => {
    return {
        type: GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const updateEventOrderByEventIdRequest = (eventId, data) => {
    return {
        type: UPDATE_EVENT_ORDER_BY_EVENT_ID_REQUEST,
        payload: { eventId, data },
    };
};
export const updateEventOrderByEventIdResponse = (message, responseType) => {
    return {
        type: UPDATE_EVENT_ORDER_BY_EVENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};