import { ACTIVE } from 'constants/commonConstants';
import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Title',
        field: 'title',
    },
    {
        label: 'Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Start Date',
        field: 'startDate',
    },
    {
        label: 'End Date',
        field: 'endDate',
    },
    {
        label: 'Description',
        field: 'description',
    },
    {
        label: 'Active',
        field: 'isActive',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialEntityDataState = () => {
    return {
        title: '',
        companyGroupId: '', //ENTITY_GROUP_NONE,
        startDate: '',
        endDate: '',
        description: '',
        entityId: [],
        isActive: ACTIVE,
        projectTeams: [],
    };
};
export const initialProjectTeamsDataState = () => {
    return {
        id: 0,
        userId: '',
        isOwner: false,
    };
};

export const intFields = ['companyGroupId'];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
export const finListColumns = [
    {
        label: 'Users Name',
        field: 'users',
        sort: 'disabled',
    },
    {
        label: 'isOwner',
        field: 'isOwner',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];

export const initialTaskState = () => {
    return {
        projectId: '',
        taskTitle: '',
        taskDescription: '',
        startDate: '',
        dueDate: '',
        entityId: '',
        userId: [],
        projectStatusId: 1,
        priority: '',
        numberOfDays: '',
    };
};

export const initialSubTaskState = () => {
    return {
        taskTitle: '',
        taskDescription: '',
        startDate: '',
        dueDate: '',
        entityId: '',
        userId: '',
        projectStatusId: '1',
        priority: '',
    };
};

export const initialTaskCommentState = () => {
    return {
        remarks: '',
    };
};
