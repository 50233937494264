import React, { Component } from 'react';
import {
    Row, Col,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {  ACTIVE, CANCEL, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest
} from 'store/actions';

import "react-datepicker/dist/react-datepicker.css";
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import __t from 'i18n/translator';
import { monthList } from 'constants/dropdowns';
import CustomisedModal from 'components/Application/CustomisedModal';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
class AddEditFinancialYearFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            financialYearList: this.props.financialYearList
        }
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.financialYearList !== this.props.financialYearList) {
            this.setState({
                financialYearList: nextprops.financialYearList
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={(this.props.operationType === SUBMIT) ? __t('form_labels.entity.add_financial_year') : __t('form_labels.entity.edit_financial_year')}
                    isModalOpen={this.props.isModalOpen}
                    width="150%"
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.props.financialYearData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Row className='d-flex mr-0'>
                                            <FormGroup className='col mb-0'>
                                                <Field
                                                    component={Select}
                                                    name="countryId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedCountryListDropDown
                                                        .filter(option => {
                                                            let countryArr = this.state.financialYearList?.map(
                                                                ({
                                                                    countryId,
                                                                    ...rest
                                                                }) =>
                                                                    countryId
                                                            );
                                                            return this.props.entityLocationsIds.includes(option.value) && !countryArr?.includes(
                                                                option.value
                                                            );
                                                        })
                                                    }
                                                    onChange={opt => {
                                                        setFieldValue(
                                                            `countryId`,
                                                            opt.value
                                                        );
                                                        setFieldValue(
                                                            `countryName`,
                                                            opt.label
                                                        );
                                                    }}
                                                    value={this.props.sortedCountryListDropDown.filter(
                                                        option =>
                                                            option.value ===
                                                            values.countryId
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.entity.country')} <span className='text-danger required'>*</span>
                                                </Label>
                                                {this.validator.message(
                                                    __t('form_labels.entity.country'),
                                                    values.countryId,
                                                    'required', {
                                                    messages: {
                                                        required: __t('validations.entity.country_required')
                                                    }
                                                }
                                                )}
                                            </FormGroup>
                                            <FormGroup className='col mb-0'>
                                                <Field
                                                    component={Select}
                                                    name="startMonth"
                                                    className="form-control selectbox"
                                                    options={monthList.filter(option => option.value !== values.endMonth)}
                                                    onChange={opt => {
                                                        setFieldValue(
                                                            `startMonth`,
                                                            opt.value
                                                        );
                                                    }}
                                                    value={monthList.filter(option =>
                                                        option.value ===
                                                        values.startMonth
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.entity.start_month')} <span className='text-danger required'>*</span>
                                                </Label>
                                                {this.validator.message(
                                                    __t('form_labels.entity.start_month'),
                                                    values.startMonth,
                                                    'required', {
                                                    messages: {
                                                        required: __t('validations.entity.start_month_required')
                                                    }
                                                }
                                                )}
                                            </FormGroup>
                                            <FormGroup className='col mb-0'>
                                                <Field
                                                    component={Select}
                                                    name="endMonth"
                                                    className="form-control selectbox"
                                                    options={monthList.filter(option => option.value !== values.startMonth)}
                                                    onChange={opt => {
                                                        setFieldValue(
                                                            `endMonth`,
                                                            opt.value
                                                        );
                                                    }}
                                                    value={monthList.filter(option =>
                                                        option.value ===
                                                        values.endMonth
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.entity.end_month')}<span className='text-danger required'>*</span>
                                                </Label>
                                                {this.validator.message(
                                                    __t('form_labels.entity.end_month'),
                                                    values.endMonth,
                                                    'required', {
                                                    messages: {
                                                        required: __t('validations.entity.end_month_required')
                                                    }
                                                }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="mb-0 d-flex align-items-centerr">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {this.props.operationType}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={this.props.onModalDismiss}
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </FormGroup>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList()
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withRouter
)(AddEditFinancialYearFormModal);