import React, { Component } from 'react';
import { Row, FormGroup, Button, Label, Col, CustomInput } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, NONE, RESET, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawListRequest
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import __t from 'i18n/translator';
import { selectSortedLawSearchList } from 'store/Masters/Law/selector';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({ key: subIndustryKey, saga: subIndustrySaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({ key: companyGroupKey, saga: companyGroupSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({ key: lawCategoryKey, saga: lawCategorySaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
class AllocationFilterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            operationType: SUBMIT,
            sortedSubIndustryListDropdown: []
        };
    }
    componentDidMount() {
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest((countryIds) ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest((stateIds) ? stateIds : []);
    }
    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: (values.countryId) ? values.countryId : [],
                stateId: (values.stateId) ? values.stateId : [],
                cityId: (values.cityId) ? values.cityId : [],
                industryId: (values.industryId) ? [values.industryId] : [],
                subIndustryId: (values.subIndustryId) ? [values.subIndustryId] : [],
                lawCategoryId: (values.lawCategoryId) ? values.lawCategoryId : [],
                type: this.props.pageType,
                isActive: true
            },
            orderBy: {}
        }
        this.props.getLawListRequest(apiData, false);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.sortedSubIndustryListDropdown && nextprops.sortedSubIndustryListDropdown !== this.props.sortedSubIndustryListDropdown) {
            this.setState({
                sortedSubIndustryListDropdown: nextprops.sortedSubIndustryListDropdown
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.allocationFilterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row className='filter-form'>
                                <React.Fragment>
                                    <FormGroup className='col-lg-4'>
                                        <Field
                                            component={Select}
                                            name="companyGroupId"
                                            id="companyGroupId"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedCompanyGroupListDropdown.filter((opt) => opt.value != NONE)
                                            }
                                            onChange={opt => {
                                                let companyGroup = (opt) ? opt.value : '';
                                                setFieldValue(
                                                    'companyGroupId',
                                                    companyGroup
                                                );
                                            }}
                                            value={this.props.sortedCompanyGroupListDropdown.filter(
                                                option =>
                                                    option.value ===
                                                    values.companyGroupId
                                            )}
                                            isClearable={true}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.company_group')} <span className='text-danger required'>*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t('form_labels.allocation.company_group'),
                                            values.companyGroupId,
                                            'required', {
                                            messages: {
                                                required: __t('validations.allocation.company_group_required')
                                            }
                                        }
                                        )}
                                    </FormGroup>
                                    <FormGroup className='col-lg-4'>
                                        <Field
                                            component={Select}
                                            name="industryId"
                                            className="form-control selectbox"
                                            options={this.props.sortedIndustryListDropdown}
                                            onChange={opt => {
                                                let industry = (opt) ? opt.value : '';
                                                setFieldValue(
                                                    'industryId',
                                                    industry
                                                );
                                                setFieldValue(
                                                    'subIndustryId',
                                                    ''
                                                );
                                                setFieldValue(
                                                    'lawId',
                                                    []
                                                );
                                                if (industry != '') {
                                                    this.props.getSubIndustryListByIndustryRequest([industry])
                                                } else {
                                                    this.setState({
                                                        sortedSubIndustryListDropdown: []
                                                    })
                                                }
                                                this.getLawsByFilters({ ...values, industryId: industry });
                                            }}
                                            value={this.props.sortedIndustryListDropdown?.filter(
                                                option =>
                                                    option.value === values.industryId
                                            )}
                                            isClearable={true}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.industry')}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className='col-lg-4'>
                                        <Field
                                            component={Select}
                                            name="subIndustryId"
                                            className="form-control selectbox"
                                            options={this.state.sortedSubIndustryListDropdown}
                                            onChange={opt => {
                                                let subIndustry = (opt) ? opt.value : ''
                                                setFieldValue(
                                                    'subIndustryId',
                                                    subIndustry
                                                );
                                                setFieldValue(
                                                    'lawId',
                                                    []
                                                );
                                                this.getLawsByFilters({ ...values, subIndustryId: subIndustry });
                                            }}
                                            value={this.state.sortedSubIndustryListDropdown?.filter(
                                                option =>
                                                    option.value === values.subIndustryId
                                            )}
                                            isClearable={true}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.sub_industry')}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className='col-lg-4'>
                                        <Field
                                            component={Select}
                                            name="countryId[]"
                                            className="form-control selectbox"
                                            options={this.props.sortedCountryListDropdown}
                                            styles={{
                                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                                menu: (provided) => ({ ...provided, zIndex: 9999 })
                                            }}
                                            onChange={opt => {
                                                let selectedValues = (opt) ? opt.map(x => x.value) : []
                                                setFieldValue(
                                                    'countryId',
                                                    selectedValues
                                                );
                                                setFieldValue(
                                                    'stateId',
                                                    []
                                                );
                                                setFieldValue(
                                                    'cityId',
                                                    []
                                                );
                                                setFieldValue(
                                                    'lawId',
                                                    []
                                                );
                                                this.getStateListByCountry(selectedValues);
                                                this.getCityListByState([]);
                                                this.getLawsByFilters({ ...values, countryId: selectedValues });
                                            }}
                                            value={this.props.sortedCountryListDropdown?.filter(
                                                option =>
                                                    values.countryId?.includes(option.value)
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.country')} <span className='text-danger required'>*</span>
                                        </Label>
                                        {this.validator.message(
                                            __t('form_labels.allocation.country'),
                                            values.countryId,
                                            'required', {
                                            messages: {
                                                required: __t('validations.allocation.country_required')
                                            }
                                        }
                                        )}
                                    </FormGroup>
                                    <FormGroup className='col-lg-4'>
                                        <Field
                                            component={CustomMultiSelect}
                                            name="stateId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedStateListDropdown
                                            }
                                            onChange={opt => {
                                                let selectedValues = (opt) ? opt.map(x => x.value) : []
                                                setFieldValue(
                                                    'stateId',
                                                    selectedValues
                                                );
                                                setFieldValue(
                                                    'cityId',
                                                    []
                                                );
                                                setFieldValue(
                                                    'lawId',
                                                    []
                                                );
                                                this.getCityListByState(selectedValues);
                                                this.getLawsByFilters({ ...values, stateId: selectedValues });
                                            }}
                                            value={this.props.sortedStateListDropdown?.filter(
                                                option =>
                                                    values.stateId?.includes(option.value)
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{ Option, MultiValue, animatedComponents }}
                                            key="stateId"
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.state')}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className='col-lg-4'>
                                        <Field
                                            component={CustomMultiSelect}
                                            name="cityId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedCityListDropdown
                                            }
                                            onChange={opt => {
                                                let selectedValues = (opt) ? opt.map(x => x.value) : []
                                                setFieldValue(
                                                    'cityId',
                                                    selectedValues
                                                );
                                                setFieldValue(
                                                    'lawId',
                                                    []
                                                );
                                                this.getLawsByFilters({ ...values, cityId: selectedValues });
                                            }}
                                            value={this.props.sortedCityListDropdown?.filter(
                                                option => values.cityId?.includes(option.value)
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{ Option, MultiValue, animatedComponents }}
                                            key="cityId"
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.city')}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className='col-lg-4 mb-0'>
                                        <Field
                                            component={CustomMultiSelect}
                                            name="lawCategoryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props.sortedLawCategoryListDropdown
                                            }
                                            onChange={opt => {
                                                let selectedValues = (opt) ? opt.map(x => x.value) : []
                                                setFieldValue(
                                                    'lawCategoryId',
                                                    selectedValues
                                                );
                                                setFieldValue(
                                                    'lawId',
                                                    []
                                                );
                                                this.getLawsByFilters({ ...values, lawCategoryId: selectedValues });
                                            }}
                                            value={this.props.sortedLawCategoryListDropdown?.filter(
                                                option =>
                                                    values.lawCategoryId?.includes(option.value)
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{ Option, MultiValue, animatedComponents }}
                                            key="lawCategoryId"
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.law_category')}

                                        </Label>

                                    </FormGroup>
                                    <Col lg="8">
                                        <Row>
                                            <FormGroup className='col-lg-6 mb-0'>
                                                <Field
                                                    component={CustomMultiSelect}
                                                    className="form-control selectbox"
                                                    name="lawId[]"
                                                    options={this.props.sortedLawListDropDown}
                                                    onChange={opt => {
                                                        let selectedValues = (opt) ? opt.map(x => x.value) : []
                                                        setFieldValue(
                                                            'lawId',
                                                            selectedValues
                                                        );
                                                    }}
                                                    value={this.props.sortedLawListDropDown?.filter(
                                                        option =>
                                                            values.lawId?.includes(option.value)
                                                    )}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    allowSelectAll={true}
                                                    components={{ Option, MultiValue, animatedComponents }}
                                                    key="laws"
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.allocation.laws')}

                                                </Label>

                                            </FormGroup>
                                            <FormGroup className="col-lg-2 mb-0 d-flex align-items-center">
                                                <CustomInput type="checkbox"
                                                    id="displayMode"
                                                    name="displayMode"
                                                    className='new_addition_check'
                                                    onChange={(e) => {
                                                        const { checked } =
                                                            e.target;

                                                        setFieldValue(
                                                            'displayMode',
                                                            checked
                                                        );
                                                    }}
                                                    checked={
                                                        values.displayMode
                                                    }
                                                    label={__t(
                                                        'form_labels.allocation.new_additions'
                                                    )} />
                                            </FormGroup>
                                            <FormGroup className="col-lg-4 mb-0">
                                                <div className="d-flex align-items-center justify-content-end h-100">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >{__t('form_labels.allocation.generate_compliances')}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={() => {
                                                            this.resetForm()
                                                        }}
                                                    >
                                                        {RESET}
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </React.Fragment>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedLawListDropDown: selectSortedLawSearchList()
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawListRequest
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withEntityReducer,
    withEntitySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withRouter
)(AllocationFilterForm);