import { ADD_AUDIT_OBSERVATION_REQUEST, ADD_AUDIT_OBSERVATION_RESPONSE, GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_REQUEST, GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_RESPONSE, UPDATE_AUDIT_OBSERVATION_STATUS_REQUEST, UPDATE_AUDIT_OBSERVATION_STATUS_RESPONSE } from "./actionTypes";

export const addAuditObservationRequest = data => {
    return {
        type: ADD_AUDIT_OBSERVATION_REQUEST,
        payload: { data },
    };
};

export const addAuditObservationResponse = (message, responseType) => {
    return {
        type: ADD_AUDIT_OBSERVATION_RESPONSE,
        payload: { message, responseType },
    };
};

export const getAuditObservationByTaskIdRequest = (taskId) => {
    return {
        type: GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_REQUEST,
        payload: { taskId },
    };
}

export const getAuditObservationByTaskIdResponse = (auditObservationList) => {
    return {
        type: GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_RESPONSE,
        payload: { auditObservationList },
    };
}

export const updateAuditObservationStatusRequest = taskId => {
    return {
        type: UPDATE_AUDIT_OBSERVATION_STATUS_REQUEST,
        payload: { taskId },
    };
};

export const updateAuditObservationStatusResponse = (message, responseType) => {
    return {
        type: UPDATE_AUDIT_OBSERVATION_STATUS_RESPONSE,
        payload: { message, responseType },
    };
};