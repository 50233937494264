export const ADD_DELEGATION_REQUEST = 'add_delegation_request';
export const ADD_DELEGATION_RESPONSE = 'add_delegation_response';
export const GET_ALL_DELEGATION_LIST_REQUEST =
    'get_all_delegation_list_REQUEST';
export const GET_ALL_DELEGATION_LIST_RESPONSE =
    'get_all_delegation_list_response';
export const UPDATE_DELEGATION_REQUEST = 'update_delegation_request';
export const UPDATE_DELEGATION_RESPONSE = 'update_delegation_response';
export const DELETE_DELEGATION_REQUEST = 'delete_delegation_request';
export const DELETE_DELEGATION_RESPONSE = 'delete_delegation_response';
