import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IMPORT_MODULE, UPLOAD } from 'constants/commonConstants';
import __t from 'i18n/translator';
import UploadForm from './UploadForm';
import ViewLogsModal from './ViewLogsModal';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    importFileByTypeRequest,
    getMasterSampleRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import importMasterReducer from 'store/Masters/ImportMaster/reducer';
import importMasterSaga from 'store/Masters/ImportMaster/saga';
import { makeImportMasterSelectField } from 'store/Masters/ImportMaster/selector';
import { PERMISSION_IMPORT } from 'constants/databaseConstants';
import { checkModulePermission } from 'helpers/projectUtils';
import FileSaver from 'file-saver';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const importMasterKey = 'importMaster';
const withImportMasterReducer = injectReducer({
    key: importMasterKey,
    reducer: importMasterReducer,
});
const withImportMasterSaga = injectSaga({ key: importMasterKey, saga: importMasterSaga });

const listColumns = [
    {
        label: 'Master',
        field: 'masterName',
    },
    {
        label: 'Action',
        attributes: {
            className: 'upload_col'
        },
        field: 'actions',
    }
]
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            importMastersList: [],
            isModalOpen: false,
            importRecord: {},
            isLogsModalOpen: false,
            isDashboardParamType: (this.props.match.params.type) ? this.props.match.params.type : ''
        };
    }
    componentDidMount() {
        this.setState({
            importMastersList: this.generateTable()
        }, () => {
            if (this.state.isDashboardParamType != '') {
                const importOpenRecord = IMPORT_MODULE.find((opt) => opt.masterName == this.state.isDashboardParamType);
                this.openUploadForm(importOpenRecord);
            }
        })
    }
    openUploadForm = (data) => {
        this.setState({
            isModalOpen: true,
            importRecord: data
        })
    }
    closeUploadForm = () => {
        this.setState({
            isModalOpen: false,
            importRecord: {}
        })
    }
    saveFile = (fileUrl, fileName) => {
        return FileSaver.saveAs(
            fileUrl,
            fileName
        );
    };
    openViewLogsModal = (data) => {
        this.setState({
            isLogsModalOpen: true,
            importRecord: { ...data, masterName: data.masterName.replace(/-/g, '') }
        })
    }
    closeViewLogsModal = () => {
        this.setState({
            isLogsModalOpen: false,
            importRecord: {}
        })
    }
    formSubmit = values => {
        let formValues = values;
        const formData = new FormData();
        formData.append('modelName', values.masterName.replace(/-/g, ''));
        formData.append('file', formValues.file[0]);
        this.props.importFileByTypeRequest(formData);
    };
    generateTable = () => {
        let importModules = [];
        importModules = IMPORT_MODULE.filter((opt) => {
            return checkModulePermission(opt.moduleId, PERMISSION_IMPORT)
        }).map((importModuleRecord, i) => {

            let actions = (
                <div className="tbl_action">
                    <a
                        href={() => false}
                        title={__t('common.upload_file')}
                        className='btn btn-sm text-primary tbl-action'
                        onClick={() => {
                            this.openUploadForm(importModuleRecord)
                        }}
                    >
                        <i className="fas fa-upload"></i> {UPLOAD}
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.download_template')}
                        className='btn btn-sm text-info tbl-action'
                        onClick={() => this.props.getMasterSampleRequest({ masterSampleFile: importModuleRecord.masterName })}
                    >
                        <i className="fas fa-download"></i> {__t('common.download_template')}
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.view_logs')}
                        className='btn btn-sm text-success tbl-action'
                        onClick={() => {
                            this.openViewLogsModal(importModuleRecord)
                        }}
                    >
                        <i className="fas fa-history"></i> View Logs
                    </a>
                </div>
            );
            return {
                masterName: importModuleRecord.name,
                actions: actions,
            };
        })
        return importModules;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.getMasterSampleApiResponse &&
            this.props.getMasterSampleApiResponse !== nextprops.getMasterSampleApiResponse
        ) {
            this.saveFile(
                nextprops.getMasterSampleApiResponse.fileUrl,
                nextprops.getMasterSampleApiResponse.downloadFileName
            )
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.importMastersList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'excel-upload'} />
                    </Col>
                    <Col lg="12">
                        <Card className='mb-2'>
                            <CardHeader>
                                <div className='page-header'>
                                    <h4>{__t('page_headers.import_masters')}</h4>
                                </div>
                            </CardHeader>
                            <CardBody className='custom_table'>

                                <CustomisedMDBDatatablePage
                                    className="table table-bordered table-striped"
                                    activepage={this.state.activePage}
                                    onHandlePageChange={this.handlePageChange}
                                    tableRecords={data}
                                    entries={20}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <UploadForm
                        isModalOpen={this.state.isModalOpen}
                        importRecord={this.state.importRecord}
                        operationResponse={this.props.importMasterApiResponse}
                        onFormValid={(values) => {
                            this.formSubmit(values)
                        }}
                        onModalDismiss={this.closeUploadForm}
                    />

                    <ViewLogsModal
                        isModalOpen={this.state.isLogsModalOpen}
                        importRecord={this.state.importRecord}
                        onModalDismiss={this.closeViewLogsModal}
                    />
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    importMasterApiResponse: makeImportMasterSelectField('importMasterApiResponse'),
    getMasterSampleApiResponse: makeImportMasterSelectField('getMasterSampleApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    importFileByTypeRequest,
    getMasterSampleRequest
});
export default compose(
    withConnect,
    withImportMasterReducer,
    withImportMasterSaga,
    withRouter
)(Index);