import produce from 'immer';
import { ADD_EVENT_RESPONSE, DELETE_EVENT_BY_EVENT_ID_RESPONSE, GET_ALL_EVENT_LIST_RESPONSE, GET_EVENT_BY_EVENT_ID_RESPONSE, GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_RESPONSE, GET_EVENT_LIST_BY_SEARCH_RESPONSE, GET_EVENT_LIST_BY_STATUS_RESPONSE, GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_RESPONSE, UPDATE_EVENT_ORDER_BY_EVENT_ID_RESPONSE, UPDATE_EVENT_RESPONSE, UPDATE_EVENT_STATUS_BY_EVENT_ID_RESPONSE } from './actionTypes';
export const initialState = {
    eventApiResponse: null,
    editEventApiResponse: null,
    eventStatusUpdateApiResponse: null,
    deleteEventApiResponse: null,
    eventList: [],
    eventComplianceListApiResponse: null,
    eventOrderUpdateApiResponse: null
};
const event = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_EVENT_RESPONSE:
            case UPDATE_EVENT_RESPONSE:
                draft.eventApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_EVENT_LIST_RESPONSE:
                draft.eventList = action.payload.eventList;
                break;
            case GET_EVENT_BY_EVENT_ID_RESPONSE:
                draft.editEventApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case UPDATE_EVENT_STATUS_BY_EVENT_ID_RESPONSE:
                draft.eventStatusUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_EVENT_LIST_BY_STATUS_RESPONSE:
                draft.eventList = action.payload.eventList;
                break;
            case DELETE_EVENT_BY_EVENT_ID_RESPONSE:
                draft.deleteEventApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_EVENT_LIST_BY_SEARCH_RESPONSE:
                draft.eventList = action.payload.eventList;
                break;
            case GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_RESPONSE:
                draft.eventList = action.payload.eventList;
                break;
            case GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_RESPONSE:
                draft.eventComplianceListApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case UPDATE_EVENT_ORDER_BY_EVENT_ID_RESPONSE:
                draft.eventOrderUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default event;
