import produce from 'immer';
import { ADD_STATE_RESPONSE, DELETE_STATE_BY_STATE_ID_RESPONSE, GET_ALL_STATE_LIST_RESPONSE, GET_STATE_BY_STATE_ID_RESPONSE, GET_STATE_LIST_BY_COUNTRY_RESPONSE, GET_STATE_LIST_BY_STATUS_RESPONSE, UPDATE_STATE_RESPONSE, UPDATE_STATE_STATUS_BY_STATE_ID_RESPONSE } from './actionTypes';
export const initialState = {
    stateApiResponse: null,
    editStateApiResponse: null,
    stateList: []
};
const state = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_STATE_RESPONSE:
            case UPDATE_STATE_RESPONSE:
            case UPDATE_STATE_STATUS_BY_STATE_ID_RESPONSE:
            case DELETE_STATE_BY_STATE_ID_RESPONSE:
                draft.stateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_STATE_LIST_RESPONSE:
                draft.stateList = action.payload.stateList;
                break;
            case GET_STATE_BY_STATE_ID_RESPONSE:
                draft.editStateApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;




            case GET_STATE_LIST_BY_STATUS_RESPONSE:
                draft.stateList = action.payload.stateList;
                break;


            case GET_STATE_LIST_BY_COUNTRY_RESPONSE:
                draft.stateList = action.payload.stateList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default state;
