import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

// src/authConfig.js
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_SSO_CLIENT_ID,
        authority: process.env.REACT_APP_SSO_AUTHORITY, // :tenant ID
        redirectUri: process.env.REACT_APP_SSO_REDIRECT_URL,
        postLogoutRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URL + '/logout',
        knownAuthorities: [],
        cloudDiscoveryMetadata: "",
        navigateToLoginRequestUrl: true,
        clientCapabilities: ["CP1"],
        protocolMode: "AAD"
    },
    cache: {
        cacheLocation: "localStorage",
        temporaryCacheLocation: "localStorage",
        storeAuthStateInCookie: true,
        secureCookies: false,
        claimsBasedCachingEnabled: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (
                level,
                message,
                containsPii
            )=> {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false,
        },
        // windowHashTimeout: 6000,
        // iframeHashTimeout: 600,
        // loadFrameTimeout: 0,
        // asyncPopups: false,
    },telemetry: {
        application: {
            appName: "My Application",
            appVersion: "1.0.0",
        },
    },
};

export const msalLoginRequest = {
    scopes: ["User.Read", 'openid', 'profile']
};

export const pca = new PublicClientApplication(msalConfig);

