import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_DEPARTMENT_REQUEST,
    DELETE_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
    GET_ALL_DEPARTMENT_LIST_REQUEST,
    GET_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
    GET_DEPARTMENT_LIST_BY_STATUS_REQUEST,
    GET_ENTITY_DEPARTMENT_BY_ENTITY_REQUEST,
    UPDATE_DEPARTMENT_REQUEST,
    UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addDepartmentResponse,
    deleteDepartmentByDepartmentIdResponse,
    getAllDepartmentListRequest,
    getAllDepartmentListResponse,
    getDepartmentByDepartmentIdResponse,
    getDepartmentListByStatusResponse,
    getEntityDepartByEntityResponse,
    updateDepartmentResponse,
    updateDepartmentStatusByDepartmentIdResponse,
} from './actions';
import {
    DEPARTMENT,
    DEPARTMENT_BY_STATUS,
    ENTITY_DEPARTMENT,
} from 'helpers/apiConstants';
function* addDepartment({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', DEPARTMENT, data);
        if (response.status) {
            yield put(getAllDepartmentListRequest());
            yield put(addDepartmentResponse(response.message, SUCCESS));
        } else {
            yield put(addDepartmentResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllDepartmentList() {
    try {
        const response = yield call(invokeApi, 'get', DEPARTMENT, '');
        if (response.status) {
            yield put(getAllDepartmentListResponse(response.data.list));
        } else {
            yield put(getAllDepartmentListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getDepartmentByDepartmentId({ payload: { departmentId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            DEPARTMENT + '/' + departmentId,
            ''
        );
        if (response.status) {
            yield put(
                getDepartmentByDepartmentIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDepartmentByDepartmentIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateDepartment({ payload: { departmentId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            DEPARTMENT + '/' + departmentId,
            data
        );
        if (response.status) {
            yield put(getAllDepartmentListRequest());
            yield put(updateDepartmentResponse(response.message, SUCCESS));
        } else {
            yield put(updateDepartmentResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateDepartmentStatusByDepartmentId({
    payload: { departmentId, status },
}) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            DEPARTMENT + '/' + departmentId + '/status/' + status
        );
        if (response.status) {
            yield put(getAllDepartmentListRequest());
            yield put(
                updateDepartmentStatusByDepartmentIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateDepartmentStatusByDepartmentIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getDepartmentListByStatus({ payload: { status } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            DEPARTMENT_BY_STATUS + '/' + status
        );
        if (response.status) {
            yield put(getDepartmentListByStatusResponse(response.data.list));
        } else {
            yield put(getDepartmentListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteDepartmentByDepartmentId({ payload: { departmentId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            DEPARTMENT + '/' + departmentId
        );
        if (response.status) {
            yield put(getAllDepartmentListRequest());
            yield put(
                deleteDepartmentByDepartmentIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteDepartmentByDepartmentIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEntityDepartByEntity({ payload: { entityId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            ENTITY_DEPARTMENT + '/' + entityId,
            ''
        );
        if (response.status) {
            yield put(
                getEntityDepartByEntityResponse(response.data.departments)
            );
        } else {
            yield put(getEntityDepartByEntityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchDepartment() {
    yield takeEvery(ADD_DEPARTMENT_REQUEST, addDepartment);
    yield takeEvery(GET_ALL_DEPARTMENT_LIST_REQUEST, getAllDepartmentList);
    yield takeEvery(
        GET_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
        getDepartmentByDepartmentId
    );
    yield takeEvery(UPDATE_DEPARTMENT_REQUEST, updateDepartment);
    yield takeEvery(
        UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_REQUEST,
        updateDepartmentStatusByDepartmentId
    );
    yield takeEvery(
        GET_DEPARTMENT_LIST_BY_STATUS_REQUEST,
        getDepartmentListByStatus
    );
    yield takeEvery(
        DELETE_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
        deleteDepartmentByDepartmentId
    );
    yield takeEvery(
        GET_ENTITY_DEPARTMENT_BY_ENTITY_REQUEST,
        getEntityDepartByEntity
    );
}
function* departmentSaga() {
    yield all([fork(watchDepartment)]);
}
export default departmentSaga;
