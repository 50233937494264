import {
    GET_PROJECT_TASK_USERS_BY_ENTITY_REQUEST,
    GET_PROJECT_TASK_USERS_BY_ENTITY_RESPONSE,
    ADD_PROJECT_REQUEST,
    ADD_PROJECT_RESPONSE,
    GET_PROJECT_LIST_REQUEST,
    GET_PROJECT_LIST_RESPONSE,
    GET_PROJECT_DETAILS_REQUEST,
    GET_PROJECT_DETAILS_RESPONSE,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_RESPONSE,
    DELETE_PROJECT_BY_PROJECT_ID_REQUEST,
    DELETE_PROJECT_BY_PROJECT_ID_RESPONSE,
    GET_PROJECT_TASK_LIST_REQUEST,
    GET_PROJECT_TASK_LIST_RESPONSE,
    ADD_PROJECT_TASK_REQUEST,
    ADD_PROJECT_TASK_RESPONSE,
    UPDATE_PROJECT_TASK_REQUEST,
    UPDATE_PROJECT_TASK_RESPONSE,
    DELETE_PROJECT_TASK_BY_TASK_ID_REQUEST,
    DELETE_PROJECT_TASK_BY_TASK_ID_RESPONSE,
    ADD_PROJECT_SUB_TASK_REQUEST,
    ADD_PROJECT_SUB_TASK_RESPONSE,
    DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_REQUEST,
    DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_RESPONSE,
    GET_PROJECT_TASK_DETAILS_REQUEST,
    GET_PROJECT_TASK_DETAILS_RESPONSE,
    ADD_PROJECT_TASK_COMMENT_REQUEST,
    ADD_PROJECT_TASK_COMMENT_RESPONSE,
    ADD_PROJECT_TASK_ATTACHMENT_REQUEST,
    ADD_PROJECT_TASK_ATTACHMENT_RESPONSE,
    GET_PROJECT_TASK_ACTIVITY_REQUEST,
    GET_PROJECT_TASK_ACTIVITY_RESPONSE,
    DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST,
    DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE,
    CLOSE_PROJECT_REQUEST,
    CLOSE_PROJECT_RESPONSE,
    ACTIVE_OR_DEACTIVE_PROJECT_REQUEST,
    ACTIVE_OR_DEACTIVE_PROJECT_RESPONSE
} from './actionTypes';

export const getProjectTaskUsersByEntityRequest = (data) => {
    return {
        type: GET_PROJECT_TASK_USERS_BY_ENTITY_REQUEST,
        payload: { data: data },
    };
};

export const getProjectTaskUsersByEntityResponse = (entityAllUsersList) => {
    return {
        type: GET_PROJECT_TASK_USERS_BY_ENTITY_RESPONSE,
        payload: { entityAllUsersList },
    };
};

export const addProjectRequest = (data) => {
    return {
        type: ADD_PROJECT_REQUEST,
        payload: { data: data },
    };
};

export const addProjectResponse = (message, responseType) => {
    return {
        type: ADD_PROJECT_RESPONSE,
        payload: { message, responseType },
    };
};

export const getProjectListRequest = (data) => {
    return {
        type: GET_PROJECT_LIST_REQUEST,
        payload: { data: data },
    };
};

export const getProjectListResponse = (projectList) => {
    return {
        type: GET_PROJECT_LIST_RESPONSE,
        payload: { projectList },
    };
};

export const getProjectDetailsRequest = (projectDetailsId) => {
    return {
        type: GET_PROJECT_DETAILS_REQUEST,
        payload: { projectDetailsId },
    };
};

export const getProjectDetailsResponse = (message, responseType, data) => {
    return {
        type: GET_PROJECT_DETAILS_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const updateProjectRequest = (projectId, data) => {
    return {
        type: UPDATE_PROJECT_REQUEST,
        payload: { projectId, data },
    };
};

export const updateProjectResponse = (message, responseType) => {
    return {
        type: UPDATE_PROJECT_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteProjectByProjectIdRequest = (projectId) => {
    return {
        type: DELETE_PROJECT_BY_PROJECT_ID_REQUEST,
        payload: { projectId },
    };
};

export const deleteProjectByProjectIdResponse = (message, responseType) => {
    return {
        type: DELETE_PROJECT_BY_PROJECT_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getProjectTaskListRequest = (data) => {
    return {
        type: GET_PROJECT_TASK_LIST_REQUEST,
        payload: { data: data },
    };
};

export const getProjectTaskListResponse = (message, responseType, data) => {
    return {
        type: GET_PROJECT_TASK_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const addProjectTaskRequest = (data) => {
    return {
        type: ADD_PROJECT_TASK_REQUEST,
        payload: { data: data },
    };
};

export const addProjectTaskResponse = (message, responseType) => {
    return {
        type: ADD_PROJECT_TASK_RESPONSE,
        payload: { message, responseType },
    };
};

export const updateProjectTaskRequest = (taskId, data) => {
    return {
        type: UPDATE_PROJECT_TASK_REQUEST,
        payload: { taskId, data },
    };
};

export const updateProjectTaskResponse = (message, responseType) => {
    return {
        type: UPDATE_PROJECT_TASK_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteProjectTaskByTaskidRequest = (taskId) => {
    return {
        type: DELETE_PROJECT_TASK_BY_TASK_ID_REQUEST,
        payload: { taskId },
    };
};

export const deleteProjectTaskByTaskidResponse = (message, responseType) => {
    return {
        type: DELETE_PROJECT_TASK_BY_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const addProjectSubTaskRequest = (taskId, data) => {
    return {
        type: ADD_PROJECT_SUB_TASK_REQUEST,
        payload: { taskId, data },
    };
};

export const addProjectSubTaskResponse = (message, responseType) => {
    return {
        type: ADD_PROJECT_SUB_TASK_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteProjectSubTaskBySubTaskIdRequest = (subTaskId) => {
    return {
        type: DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_REQUEST,
        payload: { subTaskId },
    };
};

export const deleteProjectSubTaskBySubTaskIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getProjectTaskDetailsRequest = (taskId) => {
    return {
        type: GET_PROJECT_TASK_DETAILS_REQUEST,
        payload: { taskId },
    };
};

export const getProjectTaskDetailsResponse = (taskDetails) => {
    return {
        type: GET_PROJECT_TASK_DETAILS_RESPONSE,
        payload: { taskDetails },
    };
};

export const addProjectTaskCommentRequest = (taskId, data) => {
    return {
        type: ADD_PROJECT_TASK_COMMENT_REQUEST,
        payload: { taskId, data },
    };
};

export const addProjectTaskCommentResponse = (message, responseType) => {
    return {
        type: ADD_PROJECT_TASK_COMMENT_RESPONSE,
        payload: { message, responseType },
    };
};

export const addProjectTaskAttachmentRequest = (data) => {
    return {
        type: ADD_PROJECT_TASK_ATTACHMENT_REQUEST,
        payload: { data },
    };
};

export const addProjectTaskAttachmentResponse = (message, responseType) => {
    return {
        type: ADD_PROJECT_TASK_ATTACHMENT_RESPONSE,
        payload: { message, responseType },
    };
};

export const getProjectTaskActivityRequest = (taskId) => {
    return {
        type: GET_PROJECT_TASK_ACTIVITY_REQUEST,
        payload: { taskId },
    };
};

export const getProjectTaskActivityResponse = (activityDetails) => {
    return {
        type: GET_PROJECT_TASK_ACTIVITY_RESPONSE,
        payload: { activityDetails },
    };
};

export const deleteProjectTaskDocumentByDocumentIdRequest = (documentId) => {
    return {
        type: DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST,
        payload: { documentId },
    };
};

export const deleteProjectTaskDocumentByDocumentIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const closeProjectRequest = (id) => {
    return {
        type: CLOSE_PROJECT_REQUEST,
        payload: { id },
    };
};

export const closeProjectResponse = (
    message,
    responseType
) => {
    return {
        type: CLOSE_PROJECT_RESPONSE,
        payload: { message, responseType },
    };
};

export const activeOrDeactiveProjectRequest = (id, data) => {
    return {
        type: ACTIVE_OR_DEACTIVE_PROJECT_REQUEST,
        payload: { id, data },
    };
};

export const activeOrDeactiveProjectResponse = (
    message,
    responseType
) => {
    return {
        type: ACTIVE_OR_DEACTIVE_PROJECT_RESPONSE,
        payload: { message, responseType },
    };
};
