import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    Row,
    Col,
    CardBody,
    Card,
    CardHeader,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
    Progress,
} from 'reactstrap';
import { compose } from 'redux';
import { Field, Form, Formik } from 'formik';
import Select, { components } from 'react-select';
import CountUp from 'react-countup';
import classnames from 'classnames';
import {
    getTaskDetailsByTaskIdRequest,
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getCompanyGroupListByStatusRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { Chart as GoogleChart } from 'react-google-charts';
import {
    geoOptions,
} from './Charts/GeoChartData.js';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
    ArcElement,
    LineController,
    BarController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import ApexCharts from 'react-apexcharts';
import {
    ACTIVE,
} from 'constants/commonConstants';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import TotalCountry from '../../images/country_icon.png';
import TotalCompliances from '../../images/compliances_icon.png';
import TotalEntity from '../../images/entity_icon.png';
import TotalLaws from '../../images/laws_icon.png';
import TotalUpdates from '../../images/updates_icon.png';
import { TableBody, TableHead } from '@material-ui/core';
import {
    areaOfLawOptions,
    complianceAnalysisByBarChart,
    regulatoryUpdatesChart,
} from './CommonFunctions.js';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCityList } from 'store/Masters/City/selector.js';
import { selectSortedStateList } from 'store/Masters/State/selector.js';
import ExportLaw from './Modals/ExportLaw.js';
import ExportLawCompliance from './Modals/ExportLawCompliance.js';
import ExportLawDocument from './Modals/ExportLawDocument.js';
import { compareString, isDarkMode } from 'helpers/generalUtils.js';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector.js';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });

const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const placeholderStyle = (base) => ({
    ...base,
    fontSize:
        '1em',
    fontWeight: 500,
});
/*--------Charts--------*/
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    LineController,
    BarController
);

const regulatory_options = {
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: false,
        },
    },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    plotOptions: {
        bar: {
            borderRadius: 100,
        },
    },
    scales: {
        x: {
            stacked: true,
            barThickness: 6,
            maxBarThickness: 8,
        },
        y: {
            stacked: false,
            barThickness: 6, // number (pixels) or 'flex'
            maxBarThickness: 8,
        },
    },
};
const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
export const regulatory_data = {
    labels,
    datasets: [
        {
            label: 'Laws',
            data: [5, 10, 15, 18, 45, 15, 5, 14, 10, 15, 20, 35, 15],
            backgroundColor: '#00A7B5',
            borderWidth: 1,
            borderRadius: 100,
            stack: 'Stack 0',
            maxBarThickness: 15,
        },
        {
            label: 'Compliances',
            data: [
                -14, -18, -20, -10, -42, -20, -15, -14, -18, -20, -10, -35, -30,
            ],
            backgroundColor: '#4F2D7F',
            borderWidth: 1,
            borderRadius: 100,
            stack: 'Stack 0',
            maxBarThickness: 15,
        },
    ],
};

const countries_options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                color: isDarkMode() ? '#dee2e6' : '#495057',
            },
        },
    },
    scales: {
        y: {
            ticks: {
                color: isDarkMode() ? '#dee2e6' : '#344767',
                font: {
                    size: 12,
                    family: 'GT Walsheim Pro',
                    style: 'normal',
                },
            },
            grid: {
                color: isDarkMode() ? '#383c5c' : '#e9ecef',
                borderColor: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
        },
        x: {
            ticks: {
                color: isDarkMode() ? '#dee2e6' : 'rgba(79, 45, 127, 1)#344767',
                font: {
                    size: 16,
                    family: 'GT Walsheim Pro',
                    style: 'normal',
                },
            },
            grid: {
                color: isDarkMode() ? '#383c5c' : '#e9ecef',
                borderColor: isDarkMode() ? '#dee2e6' : '#adb5bd',
            }
        },
    },

};
const countries_labels = [
    // 'India',
    // 'Belgium',
    // 'Brazil',
    // 'Mexico',
    // 'Denmark',
    // 'Egypt',
    // 'France',
    // 'Germany',
    // 'Israel',
    // 'Japan',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const countries_data = {
    labels: countries_labels,
    datasets: [
        {
            label: 'Laws',
            data: [5, 10, 15, 18, 45, 15, 5, 14, 10, 15, 5, 10],
            tension: 0.4,
            borderWidth: 0,
            borderRadius: {
                topLeft: 4,
                topRight: 4,
                bottomLeft: 0,
                bottomRight: 0,
            },
            borderSkipped: false,
            borderColor: 'rgb(0 167 181)',
            backgroundColor: 'rgba(79, 45, 127, 1)',
            maxBarThickness: 20,
            categoryPercentage: 0.4,
            barPercentage: 1.0,
        },
        {
            label: 'Compliances',
            data: [14, 18, 20, 10, 42, 20, 15, 14, 18, 20, 10, 5],
            tension: 0.4,
            borderWidth: 0,
            borderRadius: {
                topLeft: 4,
                topRight: 4,
                bottomLeft: 0,
                bottomRight: 0,
            },
            borderSkipped: false,
            borderColor: 'rgb(155 215 50)',
            backgroundColor: 'rgb(155 215 50)',
            maxBarThickness: 20,
            categoryPercentage: 0.4,
            barPercentage: 1.0,
        },
    ],
};

/*--------End-Charts--------*/

const initialDashboardSearchDataState = () => {
    return {
        countryId: 0,
        stateId: '',
        cityId: '',
        date: '',
    };
};
class SuperAdminIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'graphViewTab',
            lawCountByCountry: [],
            lawComplianceCountByCountry: [],
            lawCategoryWiseLawAndComplianceCountByCountry: [],
            monthlyLawsCompliancesCountByCountry: [],
            monthlyLawsComplianceUpdatesCountByCountryDetails: [],
            monthlyLawsComplianceUpdatesCounts: [],
            graphMonthlyData: {
                options: {},
                data: {},
            },
            updateAnalysisGraph: {
                options: {},
                data: {},
            },
            topAreaOfLawsGraphData: {
                options: {},
                data: {},
            },
            isExportLawModalOpen: false,
            isExportLawComplianceModalOpen: false,
            isExportLawDocumentModalOpen: false,
            geoData: [],
            geoOptions: geoOptions,
            regionWise: false,
            countryIso: '',
            dashboardSearchData: initialDashboardSearchDataState(),
            areaOfLawOptions: {},
            areaOfLawData: [],
        };
        this.handleDataPointSelection =
            this.handleDataPointSelection.bind(this);
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? [countryIds] : []);
        this.props.getCityListByStateRequest([]);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? [stateIds] : []);
    }
    changeGeoGraph = (regionWise, countryIso, countryId) => {
        this.setState(
            {
                regionWise: regionWise,
                countryIso: countryIso,
            },
            () => {
                this.props.countryChangeEvent(
                    countryId,
                    regionWise,
                    countryIso
                );
            }
        );
    };
    handleDataPointSelection = (event, chartContext, config) => {
        const dataPointIndex = config.dataPointIndex;
        let categoryId = config.config.chart.lawCategoryIds[dataPointIndex];
        this.props.history.push(
            '/statutory-compliances/from-dashboard/laws/lawCategory/' +
            categoryId
        );
    };
    openExportLawModal = () => {
        this.setState({
            isExportLawModalOpen: true,
        });
    };
    closeExportLawModal = () => {
        this.setState({
            isExportLawModalOpen: false,
        });
    };
    openExportLawComplianceModal = () => {
        this.setState({
            isExportLawComplianceModalOpen: true,
        });
    };
    closeExportLawComplianceModal = () => {
        this.setState({
            isExportLawComplianceModalOpen: false,
        });
    };
    openExportLawDocumentModal = () => {
        this.setState({
            isExportLawDocumentModalOpen: true,
        });
    };
    closeExportLawDocumentModal = () => {
        this.setState({
            isExportLawDocumentModalOpen: false,
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.apiResponseData &&
            nextprops.apiResponseData !== this.props.apiResponseData
        ) {
            let geoOptionsData = geoOptions;
            let geoDataResult = [];
            let regionWise = false;
            let countryIso = '';
            geoDataResult = [
                ['Country', 'Laws', 'Compliances'],
                ...nextprops.apiResponseData.mapData.map((countryWiseData) => {
                    return [
                        countryWiseData?.countryName
                            ? countryWiseData.countryName
                            : '',
                        countryWiseData?.lawsCount
                            ? countryWiseData.lawsCount
                            : 0,
                        countryWiseData?.complianceCount
                            ? countryWiseData?.complianceCount
                            : 0,
                    ];
                }),
            ];
            if (
                nextprops.apiResponseData?.countryInfo?.isoCode2 &&
                nextprops.apiResponseData?.countryInfo?.isoCode2 != ''
            ) {
                countryIso = nextprops.apiResponseData.countryInfo.isoCode2;
                geoOptionsData = {
                    region: countryIso,
                    // colorAxis: { colors: ['#00853f', 'black', '#e31b23'] },
                    colorAxis: { minValue: 10, colors: ['#4F2D7F', '#4F2D7F'] },
                    legend: 'none',
                    backgroundColor: '#transparent',
                    datalessRegionColor: '#C2CCCE',
                    defaultColor: '#4F2D7F',
                    domain: 'IN',
                };
                regionWise = true;
                if (nextprops.apiResponseData.mapData.length == 0) {
                    geoDataResult = [
                        ['Country', 'Laws', 'Compliances'],
                        ['', 0, 0],
                    ];
                }
            }

            this.setState({
                lawCountByCountry: nextprops.apiResponseData.lawCountByCountry,
                lawComplianceCountByCountry:
                    nextprops.apiResponseData.lawComplianceCountByCountry,
                geoOptions: geoOptionsData,
                regionWise: regionWise,
                countryIso: countryIso,
                geoData: geoDataResult,
                lawCategoryWiseLawAndComplianceCountByCountry:
                    nextprops.apiResponseData
                        .lawCategoryWiseLawAndComplianceCountByCountry,
                monthlyLawsCompliancesCountByCountry:
                    nextprops.apiResponseData
                        .monthlyLawsCompliancesCountByCountry,
                updateAnalysisGraph: regulatoryUpdatesChart(
                    nextprops.apiResponseData.monthlyLawsCountByCountry,
                    nextprops.apiResponseData
                        .monthlyLawsCompliancesCountByCountry
                ),
            });
        }

        if (
            nextprops.dashboardDetailsResponse &&
            nextprops.dashboardDetailsResponse !==
            this.props.dashboardDetailsResponse
        ) {
            const dataForLawCategory =
                nextprops.dashboardDetailsResponse?.lawCategoryWiseLawAndComplianceCountByCountry
                    .slice(0, 7)
                    .sort((x1, x2) =>
                        compareString(x1.lawCategoryName, x2.lawCategoryName)
                    );
            let lawCategoryNames = dataForLawCategory?.map(
                (x) => x.lawCategoryName
            );
            let lawCategoryIds = dataForLawCategory?.map(
                (x) => x.lawCategoryId
            );
            this.setState({
                monthlyLawsComplianceUpdatesCounts:
                    nextprops.dashboardDetailsResponse
                        .monthlyLawsCompliancesUpdatesCountByCountry,

                areaOfLawOptions: areaOfLawOptions(
                    lawCategoryNames,
                    lawCategoryIds,
                    this.handleDataPointSelection
                ),
                areaOfLawData: [
                    {
                        name: 'Laws',
                        data: dataForLawCategory?.map((x) =>
                            parseInt(x.lawsCount)
                        ),
                    },
                ],
                graphMonthlyData: complianceAnalysisByBarChart(
                    nextprops.dashboardDetailsResponse
                        .monthlyLawsCompliancesCountByCountry,
                    nextprops.dashboardDetailsResponse
                        .monthlyLawsCompliancesUpdatesCountByCountry
                ),
            });
        }
    }
    dashboardFilter = (values) => {
        this.props.dashboardDetailsFilter(values);
    };
    render() {
        const countryList = this.props.sortedCountryListDropdown?.map(
            (opt) => ({
                value: opt.value,
                label: opt.label,
                isoCode2: opt.isoCode2,
                image: '/data/flags/' + opt.isoCode2 + '.svg',
            })
        );
        const { Option, SingleValue } = components;
        const IconSingleValue = (props) => (
            <SingleValue {...props}>
                <img src={props.data.image} />
                {props.data.label}
            </SingleValue>
        );

        const IconOption = (props) => (
            <Option {...props}>
                <img
                    src={props.data.image}
                    style={{
                        height: '30px',
                        width: '30px',
                        // borderRadius: '50%',
                        marginRight: '10px',
                    }}
                />
                {props.data.label}
            </Option>
        );
        const applyFlexRowStyle = (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
        });
        const customStyles = {
            option: applyFlexRowStyle,
            singleValue: applyFlexRowStyle,
        };


        return (
            <React.Fragment>
                <div className="dashboard_content">
                    <Nav
                        tabs
                        className={
                            this.state.activeTab == 'graphViewTab'
                                ? 'nav-tabs-custom nav-justified dashboard_tabs'
                                : 'nav-tabs-custom nav-justified dashboard_tabs details_tab_open'
                        }
                    >
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab === 'graphViewTab',
                                })}
                                onClick={() => {
                                    this.toggle('graphViewTab');
                                }}
                            >
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1V21.3077C1 22.287 1.38901 23.2261 2.08145 23.9185C2.77389 24.611 3.71305 25 4.69231 25H25"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M4.69238 17.6154L10.2308 12.0769L13.9232 15.7692L23.1539 6.53845"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M23.1537 12.0769V6.53845H17.6152"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active:
                                        this.state.activeTab ===
                                        'detailsViewTab',
                                })}
                                onClick={() => {
                                    this.toggle('detailsViewTab');
                                }}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.33333 1H2.22222C1.54721 1 1 1.54721 1 2.22222V8.33333C1 9.00835 1.54721 9.55556 2.22222 9.55556H8.33333C9.00835 9.55556 9.55556 9.00835 9.55556 8.33333V2.22222C9.55556 1.54721 9.00835 1 8.33333 1Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8.33333 14.4445H2.22222C1.54721 14.4445 1 14.9917 1 15.6667V21.7778C1 22.4528 1.54721 23 2.22222 23H8.33333C9.00835 23 9.55556 22.4528 9.55556 21.7778V15.6667C9.55556 14.9917 9.00835 14.4445 8.33333 14.4445Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21.7777 1H15.6666C14.9915 1 14.4443 1.54721 14.4443 2.22222V8.33333C14.4443 9.00835 14.9915 9.55556 15.6666 9.55556H21.7777C22.4527 9.55556 22.9999 9.00835 22.9999 8.33333V2.22222C22.9999 1.54721 22.4527 1 21.7777 1Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21.7777 14.4445H15.6666C14.9915 14.4445 14.4443 14.9917 14.4443 15.6667V21.7778C14.4443 22.4528 14.9915 23 15.6666 23H21.7777C22.4527 23 22.9999 22.4528 22.9999 21.7778V15.6667C22.9999 14.9917 22.4527 14.4445 21.7777 14.4445Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="graphViewTab">
                            <div className="dashboard_section country_list_height mb-0">
                                <Row>
                                    <Col lg="3">
                                        <div className="dashboard_head">
                                            <h5>Laws Per Country</h5>
                                            <ul>
                                                {this.state.lawCountByCountry.map(
                                                    (countryData, key) => {
                                                        return (
                                                            <li
                                                                key={key}
                                                                onClick={() => {
                                                                    this.props.history.push(
                                                                        '/statutory-compliances/from-dashboard/laws/country/' +
                                                                        countryData.countryId
                                                                    );
                                                                }}
                                                            >
                                                                <div className="country_list_info">
                                                                    <p>
                                                                        <img
                                                                            src={`/data/flags/${countryData?.isoCode2}.svg`}
                                                                        />
                                                                        {
                                                                            countryData.countryName
                                                                        }
                                                                    </p>
                                                                    <span>
                                                                        {
                                                                            countryData.lawsCount
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <Progress
                                                                    value={100}
                                                                // value={
                                                                //     countryData.lawsCount
                                                                // }
                                                                />
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg="6" className="geo_chart">
                                        <Formik
                                            initialValues={{
                                                countryId: 0,
                                                role: '',
                                            }}
                                            enableReinitialize={true}
                                            onSubmit={this.formSubmit}
                                        >
                                            {({ values, setFieldValue }) => (
                                                <div className="geo_chart_dropdown">
                                                    <FormGroup>
                                                        <Field
                                                            styles={
                                                                customStyles
                                                            }
                                                            component={Select}
                                                            name="countryId"
                                                            className="form-control selectbox"
                                                            components={{
                                                                SingleValue:
                                                                    IconSingleValue,
                                                                Option: IconOption,
                                                            }}
                                                            options={
                                                                countryList
                                                            }
                                                            onChange={(opt) => {
                                                                let countryId =
                                                                    opt
                                                                        ? opt.value
                                                                        : 0;
                                                                setFieldValue(
                                                                    'countryId',
                                                                    countryId
                                                                );
                                                                let regionWise =
                                                                    opt
                                                                        ? true
                                                                        : false;
                                                                let isoCode2 =
                                                                    opt
                                                                        ? opt.isoCode2
                                                                        : '';
                                                                this.changeGeoGraph(
                                                                    regionWise,
                                                                    isoCode2,
                                                                    countryId
                                                                );
                                                            }}
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}
                                        </Formik>
                                        {this.state.geoData.length > 1 && (
                                            <GoogleChart
                                                className="geo_chart-inner"
                                                chartType="GeoChart"
                                                height="50vh"
                                                data={this.state.geoData}
                                                options={this.state.geoOptions}
                                                chartEvents={[
                                                    {
                                                        eventName: 'ready',
                                                        callback: ({
                                                            chartWrapper,
                                                            google,
                                                        }) => {

                                                            chartWrapper.getChart();

                                                        },
                                                    },
                                                ]}
                                            />
                                        )}
                                    </Col>
                                    <Col lg="3">
                                        <div className="dashboard_head">
                                            <h5>Compliances Per Country</h5>
                                            <ul>
                                                {this.state.lawComplianceCountByCountry.map(
                                                    (countryData, key) => {
                                                        return (
                                                            <li
                                                                key={key}
                                                                onClick={() => {
                                                                    this.props.history.push(
                                                                        '/statutory-compliances/from-dashboard/compliances/country/' +
                                                                        countryData.countryId
                                                                    );
                                                                }}
                                                            >
                                                                <div className="country_list_info">
                                                                    <p>
                                                                        <img
                                                                            src={`/data/flags/${countryData?.isoCode2}.svg`}
                                                                        />
                                                                        {
                                                                            countryData.countryName
                                                                        }
                                                                    </p>
                                                                    <span>
                                                                        {
                                                                            countryData.lawCompliancesCount
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <Progress
                                                                    value={100}
                                                                // value={
                                                                //     countryData.lawCompliancesCount
                                                                // }
                                                                />
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="4">
                                        <Card className="chart-card catagory_card dashboard_head animate__animated animate__fadeInUp m-0">
                                            <CardHeader>
                                                <h5>
                                                    Analysis as Per Category of
                                                    Law
                                                </h5>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                <Table className="m-0 number_laws">
                                                    <TableHead>
                                                        <tr>
                                                            <th className="category_name">
                                                                Category Of Law
                                                            </th>
                                                            <th>Laws</th>
                                                            <th>Compliances</th>
                                                        </tr>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.lawCategoryWiseLawAndComplianceCountByCountry.map(
                                                            (
                                                                lawCategoryData,
                                                                key
                                                            ) => {
                                                                return (
                                                                    <tr
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        <td>
                                                                            {
                                                                                lawCategoryData.lawCategoryName
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            onClick={() => {
                                                                                this.props.history.push(
                                                                                    '/statutory-compliances/from-dashboard/laws/lawCategory/' +
                                                                                    lawCategoryData?.lawCategoryId
                                                                                );
                                                                            }}
                                                                        >
                                                                            <span className="badge badge-info">
                                                                                {
                                                                                    lawCategoryData.lawsCount
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td
                                                                            onClick={() => {
                                                                                this.props.history.push(
                                                                                    '/statutory-compliances/from-dashboard/compliances/lawCategory/' +
                                                                                    lawCategoryData?.lawCategoryId
                                                                                );
                                                                            }}
                                                                        >
                                                                            <span className="badge badge-success">
                                                                                {
                                                                                    lawCategoryData.lawCompliancesCount
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="8">
                                        <Card className="chart-card dashboard_head animate__animated animate__fadeInUp">
                                            <CardHeader>
                                                <h5>
                                                    Update Analysis - New Laws &
                                                    Compliances
                                                </h5>
                                            </CardHeader>
                                            <CardBody className="p-0 details_view">
                                                <div className="d-block">
                                                    {this.state
                                                        .updateAnalysisGraph
                                                        ?.datasets && (
                                                            <Bar
                                                                options={
                                                                    countries_options
                                                                }
                                                                data={
                                                                    this.state
                                                                        .updateAnalysisGraph
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tabId="detailsViewTab">
                            <div className="dashboard-filter">
                                <Formik
                                    initialValues={
                                        this.state.dashboardSearchData
                                    }
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form className="row w-50 m-0">
                                            <FormGroup className="col">

                                                <Field
                                                    component={Select}
                                                    name="countryId"
                                                    placeholder={'Country'}
                                                    styles={{
                                                        placeholder: placeholderStyle,
                                                    }}
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedCountryListDropdown
                                                    }
                                                    onChange={(opt) => {
                                                        let selectedValue =
                                                            opt?.value
                                                                ? opt.value
                                                                : '';
                                                        setFieldValue(
                                                            'countryId',
                                                            selectedValue
                                                        );
                                                        setFieldValue(
                                                            'stateId',
                                                            ''
                                                        );
                                                        setFieldValue(
                                                            'cityId',
                                                            ''
                                                        );
                                                        this.dashboardFilter({
                                                            ...values,
                                                            countryId:
                                                                selectedValue,
                                                        });
                                                        if (selectedValue) {
                                                            this.getStateListByCountry(
                                                                opt.value
                                                            );
                                                        }
                                                    }}
                                                    value={this.props.sortedCountryListDropDown?.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.countryId
                                                    )}
                                                    isClearable={true}
                                                />
                                            </FormGroup>
                                            <FormGroup className="col">
                                                <Field
                                                    component={Select}
                                                    name="stateId"
                                                    placeholder={'State'}
                                                    styles={{
                                                        placeholder: placeholderStyle,
                                                    }}
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedStateListDropdown
                                                    }
                                                    onChange={(opt) => {
                                                        let selectedValue =
                                                            opt?.value
                                                                ? opt.value
                                                                : '';
                                                        setFieldValue(
                                                            'stateId',
                                                            selectedValue
                                                        );
                                                        setFieldValue(
                                                            'cityId',
                                                            ''
                                                        );
                                                        if (selectedValue) {
                                                            this.getCityListByState(
                                                                opt.value
                                                            );
                                                        }
                                                        this.dashboardFilter({
                                                            ...values,
                                                            stateId:
                                                                selectedValue,
                                                        });
                                                    }}
                                                    value={this.props.sortedStateListDropdown?.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.stateId
                                                    )}
                                                    isClearable={true}
                                                />
                                            </FormGroup>
                                            <FormGroup className="col">
                                                <Field
                                                    component={Select}
                                                    name="cityId"
                                                    placeholder={'City'}
                                                    styles={{
                                                        placeholder: placeholderStyle,
                                                    }}
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedCityListDropdown
                                                    }
                                                    onChange={(opt) => {
                                                        let selectedValue =
                                                            opt?.value
                                                                ? opt.value
                                                                : '';
                                                        setFieldValue(
                                                            'cityId',
                                                            selectedValue
                                                        );
                                                        this.dashboardFilter({
                                                            ...values,
                                                            cityId: selectedValue,
                                                        });
                                                    }}
                                                    value={this.props.sortedCityListDropdown?.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.cityId
                                                    )}
                                                    isClearable={true}
                                                />
                                            </FormGroup>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            <div className="dashboard_section mb-0">
                                <Row className="mt-2">
                                    <Col lg="12" className="mb-3">
                                        <ul className="details_count">
                                            <li>
                                                <Link to="/statutory-compliances/country-coverage">
                                                    <div>
                                                        <small>Country</small>
                                                        {this.state.activeTab ==
                                                            'detailsViewTab' && (
                                                                <CountUp
                                                                    duration="2.5"
                                                                    end={
                                                                        this.props
                                                                            .dashboardDetailsResponse
                                                                            ?.counts
                                                                            ?.countriesCount
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    <p>
                                                        <img
                                                            src={TotalCountry}
                                                            alt="Total country"
                                                        />
                                                    </p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/statutory-compliances">
                                                    <div>
                                                        <small>Laws</small>
                                                        {this.state.activeTab ==
                                                            'detailsViewTab' && (
                                                                <CountUp
                                                                    duration="2.5"
                                                                    end={
                                                                        this.props
                                                                            .dashboardDetailsResponse
                                                                            ?.counts
                                                                            ?.lawsCount
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    <p>
                                                        <img
                                                            src={TotalLaws}
                                                            alt="Total laws"
                                                        />
                                                    </p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/statutory-compliances/from-dashboard/compliances">
                                                    <div>
                                                        <small>
                                                            Compliances
                                                        </small>
                                                        {this.state.activeTab ==
                                                            'detailsViewTab' && (
                                                                <CountUp
                                                                    duration="2.5"
                                                                    end={
                                                                        this.props
                                                                            .dashboardDetailsResponse
                                                                            ?.counts
                                                                            ?.lawCompliancesCount
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    <p>
                                                        <img
                                                            src={
                                                                TotalCompliances
                                                            }
                                                            alt="Total compliances"
                                                        />
                                                    </p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/legal-updates">
                                                    <div>
                                                        <small>
                                                            Regulatory Updates
                                                        </small>
                                                        {this.state.activeTab ==
                                                            'detailsViewTab' && (
                                                                <CountUp
                                                                    duration="2.5"
                                                                    end={
                                                                        this.props
                                                                            .dashboardDetailsResponse
                                                                            ?.counts
                                                                            ?.updatesCount
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    <p>
                                                        <img
                                                            src={TotalUpdates}
                                                            alt="Total updates"
                                                        />
                                                    </p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/entities">
                                                    <div>
                                                        <small>
                                                            Group Entities
                                                        </small>
                                                        {this.state.activeTab ==
                                                            'detailsViewTab' && (
                                                                <CountUp
                                                                    duration="2.5"
                                                                    end={
                                                                        this.props
                                                                            .dashboardDetailsResponse
                                                                            ?.counts
                                                                            ?.entitiesCount
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    <p>
                                                        <img
                                                            src={TotalEntity}
                                                            alt="Total entity"
                                                        />
                                                    </p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col lg="8" className="pr-0">
                                        <Card className="chart-card dashboard_head animate__animated animate__fadeInUp">
                                            <CardHeader className="border-0">
                                                <h5>
                                                    Regulatory Updates Analysis
                                                    (Compliances)
                                                </h5>
                                            </CardHeader>
                                            <CardBody className="p-0 details_view">
                                                {this.state.graphMonthlyData
                                                    ?.options?.responsive && (
                                                        <div className="d-block">
                                                            <Bar
                                                                data={
                                                                    this.state
                                                                        .graphMonthlyData
                                                                        .data
                                                                }
                                                                options={
                                                                    this.state
                                                                        .graphMonthlyData
                                                                        .options
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className="top_area dashboard_head animate__animated animate__fadeInUp">
                                            <CardHeader className="border-0">
                                                <h5>Area of laws</h5>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                <div className="bar-companyData d-block">

                                                    {this.state.areaOfLawData
                                                        .length > 0 && (
                                                            <ApexCharts
                                                                options={
                                                                    this.state
                                                                        .areaOfLawOptions
                                                                }
                                                                series={
                                                                    this.state
                                                                        .areaOfLawData
                                                                }
                                                                type="bar"
                                                                events={{
                                                                    dataPointSelection:
                                                                        this
                                                                            .handleDataPointSelection,
                                                                }}
                                                            />
                                                        )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card className="chart-card details_dashboard animate__animated animate__fadeInUp mt-3 mb-0">
                                            <CardHeader className="border-0 dashboard_head h-auto px-3">
                                                <h5>Quick Links</h5>
                                            </CardHeader>
                                            <CardBody className="p-0">
                                                <ul className="quick_link">
                                                    <li>
                                                        <Link
                                                            to="/statutory-compliances/upload"
                                                            title=""
                                                        >
                                                            <span>
                                                                <svg
                                                                    width="40"
                                                                    height="40"
                                                                    viewBox="0 0 40 40"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M34.1667 20.8333H28.9415C28.2458 20.8333 27.8979 20.8333 27.6361 21.0221C27.3742 21.2108 27.2642 21.5408 27.0442 22.2009L26.2892 24.4658C26.0692 25.1258 25.9592 25.4558 25.6973 25.6446C25.4354 25.8333 25.0875 25.8333 24.3918 25.8333H15.6082C14.9125 25.8333 14.5646 25.8333 14.3027 25.6446C14.0408 25.4558 13.9308 25.1258 13.7108 24.4658L12.9559 22.2009C12.7359 21.5408 12.6258 21.2108 12.364 21.0221C12.1021 20.8333 11.7542 20.8333 11.0585 20.8333H5.83334"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <path
                                                                        d="M15.8333 15.8333L20 20M20 20L24.1667 15.8333M20 20L20 8.33333"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <path
                                                                        d="M10.8333 14.1667L7.11581 16.645C6.64692 16.9576 6.41248 17.1139 6.24276 17.3204C6.09251 17.5032 5.97978 17.7139 5.91101 17.9403C5.83334 18.1961 5.83334 18.4778 5.83334 19.0413V27.9534C5.83334 28.9614 5.83334 29.4655 6.02953 29.8505C6.2021 30.1892 6.47747 30.4646 6.81616 30.6372C7.2012 30.8334 7.70525 30.8334 8.71334 30.8334H31.2867C32.2948 30.8334 32.7988 30.8334 33.1839 30.6372C33.5226 30.4646 33.7979 30.1892 33.9705 29.8505C34.1667 29.4655 34.1667 28.9614 34.1667 27.9534V19.0413C34.1667 18.4778 34.1667 18.1961 34.089 17.9403C34.0202 17.7139 33.9075 17.5032 33.7573 17.3204C33.5875 17.1139 33.3531 16.9576 32.8842 16.645L29.1667 14.1667"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <label>
                                                                Import
                                                                Compliances
                                                            </label>
                                                            <svg
                                                                className="quick_right_arrow"
                                                                width="9"
                                                                height="14"
                                                                viewBox="0 0 9 14"
                                                                fill="transparent"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1 1L7 7L1 13"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="/document-repository/standard"
                                                            title=""
                                                        >
                                                            <span>
                                                                <svg
                                                                    width="38"
                                                                    height="38"
                                                                    viewBox="0 0 38 38"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M4.75 4.75H33.25"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M7.91667 4.75H30.0833V28.4006C30.0833 28.4373 30.0833 28.4556 30.0792 28.4729C30.0755 28.4882 30.0695 28.5028 30.0612 28.5162C30.052 28.5314 30.039 28.5444 30.013 28.5703L25.4036 33.1797C25.3777 33.2056 25.3647 33.2186 25.3496 33.2279C25.3362 33.2361 25.3215 33.2422 25.3062 33.2459C25.289 33.25 25.2706 33.25 25.2339 33.25H11.1167C9.99657 33.25 9.43651 33.25 9.00869 33.032C8.63237 32.8403 8.32641 32.5343 8.13466 32.158C7.91667 31.7302 7.91667 31.1701 7.91667 30.05V4.75Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M22.1667 33.25V25.4833C22.1667 25.4005 22.2338 25.3333 22.3167 25.3333H30.0833"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M19 20.5833V11.0833"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M14.25 15.8333L18.8939 11.1894C18.9525 11.1308 19.0475 11.1308 19.1061 11.1894L23.75 15.8333"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <label>
                                                                Upload Documents
                                                            </label>
                                                            <svg
                                                                className="quick_right_arrow"
                                                                width="9"
                                                                height="14"
                                                                viewBox="0 0 9 14"
                                                                fill="transparent"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1 1L7 7L1 13"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="/upload-masters/law"
                                                            title="Import
                                                                Law"
                                                        >
                                                            <span>
                                                                <svg
                                                                    width="38"
                                                                    height="38"
                                                                    viewBox="0 0 38 38"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M14.25 20.5833L23.75 20.5833"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M14.25 14.25L20.5833 14.25"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M14.25 26.9167L20.5833 26.9167"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M30.0833 20.5833V27.25C30.0833 30.0784 30.0833 31.4926 29.2046 32.3713C28.326 33.25 26.9118 33.25 24.0833 33.25H13.9167C11.0882 33.25 9.67402 33.25 8.79534 32.3713C7.91666 31.4926 7.91666 30.0784 7.91666 27.25V10.75C7.91666 7.92157 7.91666 6.50736 8.79534 5.62868C9.67402 4.75 11.0882 4.75 13.9167 4.75H17.4167"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <path
                                                                        d="M28.5 4.75L28.5 14.25"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M33.25 9.5L23.75 9.5"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <label>
                                                                Import Law
                                                            </label>
                                                            <svg
                                                                className="quick_right_arrow"
                                                                width="9"
                                                                height="14"
                                                                viewBox="0 0 9 14"
                                                                fill="transparent"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1 1L7 7L1 13"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={() => false}
                                                            title=""
                                                            onClick={() => {
                                                                this.openExportLawModal();
                                                            }}
                                                        >
                                                            <span>
                                                                <svg
                                                                    width="40"
                                                                    height="40"
                                                                    viewBox="0 0 40 40"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M34.1667 20.8333H28.9415C28.2458 20.8333 27.8979 20.8333 27.6361 21.0221C27.3742 21.2108 27.2642 21.5408 27.0442 22.2009L26.2892 24.4658C26.0692 25.1258 25.9592 25.4558 25.6973 25.6446C25.4354 25.8333 25.0875 25.8333 24.3918 25.8333H15.6082C14.9125 25.8333 14.5646 25.8333 14.3027 25.6446C14.0408 25.4558 13.9308 25.1258 13.7108 24.4658L12.9559 22.2009C12.7359 21.5408 12.6258 21.2108 12.364 21.0221C12.1021 20.8333 11.7542 20.8333 11.0585 20.8333H5.83334"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <path
                                                                        d="M15.8333 15.8333L20 20M20 20L24.1667 15.8333M20 20L20 8.33333"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <path
                                                                        d="M10.8333 14.1667L7.11581 16.645C6.64692 16.9576 6.41248 17.1139 6.24276 17.3204C6.09251 17.5032 5.97978 17.7139 5.91101 17.9403C5.83334 18.1961 5.83334 18.4778 5.83334 19.0413V27.9534C5.83334 28.9614 5.83334 29.4655 6.02953 29.8505C6.2021 30.1892 6.47747 30.4646 6.81616 30.6372C7.2012 30.8334 7.70525 30.8334 8.71334 30.8334H31.2867C32.2948 30.8334 32.7988 30.8334 33.1839 30.6372C33.5226 30.4646 33.7979 30.1892 33.9705 29.8505C34.1667 29.4655 34.1667 28.9614 34.1667 27.9534V19.0413C34.1667 18.4778 34.1667 18.1961 34.089 17.9403C34.0202 17.7139 33.9075 17.5032 33.7573 17.3204C33.5875 17.1139 33.3531 16.9576 32.8842 16.645L29.1667 14.1667"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <label>
                                                                Export Client
                                                                Mapped Law
                                                            </label>
                                                            <svg
                                                                className="quick_right_arrow"
                                                                width="9"
                                                                height="14"
                                                                viewBox="0 0 9 14"
                                                                fill="transparent"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1 1L7 7L1 13"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={() => false}
                                                            title=""
                                                            onClick={() => {
                                                                this.openExportLawComplianceModal();
                                                            }}
                                                        >
                                                            <span>
                                                                <svg
                                                                    width="38"
                                                                    height="38"
                                                                    viewBox="0 0 38 38"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M4.75 4.75H33.25"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M7.91667 4.75H30.0833V28.4006C30.0833 28.4373 30.0833 28.4556 30.0792 28.4729C30.0755 28.4882 30.0695 28.5028 30.0612 28.5162C30.052 28.5314 30.039 28.5444 30.013 28.5703L25.4036 33.1797C25.3777 33.2056 25.3647 33.2186 25.3496 33.2279C25.3362 33.2361 25.3215 33.2422 25.3062 33.2459C25.289 33.25 25.2706 33.25 25.2339 33.25H11.1167C9.99657 33.25 9.43651 33.25 9.00869 33.032C8.63237 32.8403 8.32641 32.5343 8.13466 32.158C7.91667 31.7302 7.91667 31.1701 7.91667 30.05V4.75Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M22.1667 33.25V25.4833C22.1667 25.4005 22.2338 25.3333 22.3167 25.3333H30.0833"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M19 20.5833V11.0833"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M14.25 15.8333L18.8939 11.1894C18.9525 11.1308 19.0475 11.1308 19.1061 11.1894L23.75 15.8333"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <label>
                                                                Export Client
                                                                Mapped
                                                                Compliances
                                                            </label>
                                                            <svg
                                                                className="quick_right_arrow"
                                                                width="9"
                                                                height="14"
                                                                viewBox="0 0 9 14"
                                                                fill="transparent"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1 1L7 7L1 13"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={() => false}
                                                            title=""
                                                            onClick={() => {
                                                                this.openExportLawDocumentModal();
                                                            }}
                                                        >
                                                            <span>
                                                                <svg
                                                                    width="38"
                                                                    height="38"
                                                                    viewBox="0 0 38 38"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M30.9821 11.9541L29.9898 11.8301L30.9821 11.9541C31.0927 11.0697 30.6046 10.2189 29.7854 9.86776L19.7877 5.58302C19.2846 5.36741 18.7151 5.36741 18.212 5.58302L8.21428 9.86776C7.39505 10.2189 6.90702 11.0697 7.01757 11.9541L8.21389 21.5247C8.43404 23.2859 9.31501 24.8977 10.6785 26.034L17.7195 31.9014C18.4612 32.5195 19.5385 32.5195 20.2802 31.9014L27.3211 26.034C28.6847 24.8977 29.5656 23.2859 29.7858 21.5247L30.9821 11.9541Z"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M14.2502 19L18.5689 23.3187C18.792 23.5418 19.1635 23.5051 19.3385 23.2425L25.3335 14.25"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <label>
                                                                Access Client
                                                                Mapped Documents
                                                            </label>
                                                            <svg
                                                                className="quick_right_arrow"
                                                                width="9"
                                                                height="14"
                                                                viewBox="0 0 9 14"
                                                                fill="transparent"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1 1L7 7L1 13"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </div>
                        </TabPane>
                    </TabContent>
                </div>
                <ExportLaw
                    isModalOpen={this.state.isExportLawModalOpen}
                    onModalDismiss={this.closeExportLawModal}
                    sortedCompanyGroupListDropdown={
                        this.props.sortedCompanyGroupListDropdown
                    }
                />
                <ExportLawCompliance
                    isModalOpen={this.state.isExportLawComplianceModalOpen}
                    onModalDismiss={this.closeExportLawComplianceModal}
                    sortedCompanyGroupListDropdown={
                        this.props.sortedCompanyGroupListDropdown
                    }
                />
                <ExportLawDocument
                    isModalOpen={this.state.isExportLawDocumentModalOpen}
                    onModalDismiss={this.closeExportLawDocumentModal}
                    sortedCompanyGroupListDropdown={
                        this.props.sortedCompanyGroupListDropdown
                    }
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
});
const withConnect = connect(mapStatetoProps, {
    getTaskDetailsByTaskIdRequest,
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getCompanyGroupListByStatusRequest,
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withRouter
)(SuperAdminIndex);
