import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    downloadFileByModelRequest
} from 'store/actions';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import commonReducer from 'store/Common/reducer';
import commonSaga from 'store/Common/saga';
import { makeCommonSelectField } from 'store/Common/selector';
import FileSaver from 'file-saver';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { toastrMessage } from 'helpers/messageHelper';

const commonKey = 'common';
const withCommonReducer = injectReducer({
    key: commonKey,
    reducer: commonReducer,
});
const withCommonSaga = injectSaga({ key: commonKey, saga: commonSaga });

class DownloadFileByModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileKey: '',
            fileName: ''
        }
    }
    downloadFile = (fileUrl,fileName) => {
        this.props.downloadFileByModelRequest({
            fileKey: fileUrl,
            fileName: fileName
        });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.downloadFileUrl !== this.props.downloadFileUrl) {
            if (nextprops.downloadFileUrl !== '') {
                this.setState({
                    fileKey: nextprops.downloadFileUrl,
                    fileName: nextprops.downloadFileName
                }, () => {
                    this.downloadFile(this.state.fileKey,this.state.fileName);
                })
            } else {
                this.setState({
                    fileKey: '',
                    fileName: ''
                });
            }
        }

        if (nextprops.downloadFileApiResponse && nextprops.downloadFileApiResponse !== this.props.downloadFileApiResponse) {
            if (nextprops.downloadFileApiResponse.responseType == SUCCESS && nextprops.downloadFileApiResponse.data.file.data) {
                return FileSaver.saveAs(
                    new Blob([new Uint8Array(nextprops.downloadFileApiResponse.data.file.data)], { type: nextprops.downloadFileApiResponse.data.file.mimeType }),
                    this.state.fileName
                );
            } else {
                toastrMessage(nextprops.downloadFileApiResponse.message, DANGER);
            }
        }
    }
    render() {
        return (
            <></>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    downloadFileApiResponse: makeCommonSelectField('downloadFileApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    downloadFileByModelRequest
});
export default compose(
    withConnect,
    withCommonReducer,
    withCommonSaga,
    withRouter
)(DownloadFileByModel);