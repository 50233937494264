import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateNonAuthLayout, logoutRequest } from '../../store/actions';
import { removeLoggedInUserDetails } from 'helpers/authUtils';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import reducer from 'store/Auth/Login/reducer';
import saga from 'store/Auth/Login/saga';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

const key = 'Login';
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });
class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.logoutRequest();
        this.props.activateNonAuthLayout();
        // Remove all Item from sessionStorage and redirect to login page
        removeLoggedInUserDetails();
        this.props.history.push('/login');
    }
    render() {
        return (
            <React.Fragment>
                <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
    logoutRequest,
    activateNonAuthLayout
});
export default compose(withConnect, withReducer, withSaga, withRouter)(Logout);

