import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.applicability || initialState;
const makeApplicabilitySelectField = field =>
    createSelector(reducerState, applicabilityState => applicabilityState[field]);
const selectSortedApplicabilityList = () =>
    createSelector(makeApplicabilitySelectField('applicabilityList'), applicabilityList =>
        applicabilityList.map(opt => ({
            value: opt.id,
            label: opt.applicabilityName
        }))
    );
const selectSortedSubApplicabilityList = () =>
    createSelector(makeApplicabilitySelectField('subApplicabilityList'), subApplicabilityList =>
        subApplicabilityList.map(opt => ({
            value: opt.id,
            label: opt.subApplicabilityName
        }))
    );
export {
    makeApplicabilitySelectField,
    selectSortedApplicabilityList,
    selectSortedSubApplicabilityList
};
