import React, { Component } from 'react';
import {
    Row, Col,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CANCEL } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getUserListByStatusRequest,
    getUsersByEntityRequest
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedUserList } from 'store/User/selector';
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
class AddEditTeamFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            teamList: this.props.teamList
        }
    }
    componentDidMount() {
        if (this.props.entityId)
            this.props.getUsersByEntityRequest(this.props.entityId);
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.teamList !== this.props.teamList) {
            this.setState({
                teamList: nextprops.teamList
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    modalSize={'modal-xl'}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.props.teamData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>

                                        <Row className='d-flex mr-0'>
                                            <FormGroup className='col mb-0'>
                                                <Field
                                                    component={Select}
                                                    name="users[]"
                                                    className="form-control selectbox"
                                                    options={this.props?.sortedUserListDropDown
                                                        ?.filter(option => {
                                                            let userArr = this.state.teamList?.map(
                                                                ({
                                                                    userId,
                                                                    ...rest
                                                                }) =>
                                                                    userId
                                                            );
                                                            return !userArr?.includes(
                                                                option.value
                                                            );
                                                        })
                                                    }
                                                    onChange={opt => {
                                                        setFieldValue(
                                                            `users`,
                                                            opt && opt.map((optVal) => {
                                                                return {
                                                                    userId: optVal.value,
                                                                    user: {
                                                                        fullName: optVal.fullName,
                                                                        email: optVal.email
                                                                    }
                                                                }
                                                            })
                                                        );
                                                    }}
                                                    value={this.props?.sortedUserListDropDown?.filter(
                                                        option =>
                                                            values.users?.map(opt => opt.userId).includes(option.value)
                                                    )}
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.entity.user')} <span className='text-danger required'>*</span>
                                                </Label>
                                                {this.validator.message(
                                                    __t('form_labels.entity.user'),
                                                    values.users,
                                                    'required', {
                                                    messages: {
                                                        required: __t('validations.entity.user_required')
                                                    }
                                                }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="mb-0 d-flex align-items-centerr">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {this.props.operationType}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={this.props.onModalDismiss}
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </FormGroup>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedUserListDropDown: selectSortedUserList()
});
const withConnect = connect(mapStatetoProps, {
    getUserListByStatusRequest,
    getUsersByEntityRequest
});
export default compose(
    withConnect,
    withUserReducer,
    withUserSaga,
    withRouter
)(AddEditTeamFormModal);