import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = (state) => state.law || initialState;
const makeLawSelectField = (field) =>
    createSelector(reducerState, (lawState) => lawState[field]);
const selectSortedLawList = () =>
    createSelector(makeLawSelectField('lawList'), (lawList) =>
        lawList
            .map((opt) => ({
                value: opt.id,
                label: opt?.lawCode + ' - ' + opt.lawShortName,
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
const selectSortedEntityTypeList = () =>
    createSelector(makeLawSelectField('entityTypeList'), (entityTypeList) =>
        entityTypeList.map((opt) => ({
            value: opt.id,
            label: opt.entityTypeName,
        }))
    );
const selectSortedFacilityTypeList = () =>
    createSelector(makeLawSelectField('facilityTypeList'), (facilityTypeList) =>
        facilityTypeList.map((opt) => ({
            value: opt.id,
            label: opt.facilityTypeName,
        }))
    );
const selectSortedLawSearchList = () =>
    createSelector(makeLawSelectField('lawSearchList'), (lawSearchList) =>
        lawSearchList
            .map((opt) => ({
                value: opt.id,
                label: opt?.lawCode + ' - ' + opt.lawShortName,
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedLawListOfLoginUser = () =>
    createSelector(
        makeLawSelectField('lawsListOfLoginUser'),
        (lawsListOfLoginUser) =>
            lawsListOfLoginUser
                .map((opt) => ({
                    value: opt.id,
                    label: opt?.lawCode + ' - ' + opt.lawShortName,
                    lawCategoryId: opt.lawCategoryId,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeLawSelectField,
    selectSortedLawList,
    selectSortedEntityTypeList,
    selectSortedFacilityTypeList,
    selectSortedLawSearchList,
    selectSortedLawListOfLoginUser,
};
