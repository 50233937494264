import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ENABLE_DELETE, SUBMIT, UPDATE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllIndustryListRequest,
    updateIndustryStatusByIndustryIdRequest,
    addIndustryRequest,
    updateIndustryRequest,
    deleteIndustryByIndustryIdRequest,
    getAllSubIndustryListRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import { makeIndustrySelectField } from 'store/Masters/Industry/selector';
import { makeSubIndustrySelectField } from 'store/Masters/SubIndustry/selector';
import { initialIndustryDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import AddIndustryForm from './AddIndustryForm';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_INDUSTRY,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            industryList: [],
            exportIndustryList: [],
            subIndustryList: [],
            industryData: initialIndustryDataState(),
            operationType: SUBMIT,
            clickExportBtn: false,
            exportData: [],
            isAuditModalOpen: false,
            auditId: '',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve industry list
        this.props.getAllIndustryListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateIndustryStatusByIndustryIdRequest(id, checked);
    }
    viewIndustry(industryData) {
        this.setState(
            {
                industryData: initialIndustryDataState(),
                operationType: UPDATE,
            },
            () => {
                let subIndustries = industryData.subIndustry;
                this.setState({
                    industryData: {
                        ...industryData,
                        subIndustries: subIndustries,
                    },
                });
            }
        );
    }
    deleteIndustry = async (industryId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.industry_delete')
        );
        if (response) {
            this.props.deleteIndustryByIndustryIdRequest(industryId);
        }
    };
    formSubmit = (values) => {
        this.setState({ industryData: values });
        let valuesData = values;
        valuesData.subIndustries = valuesData.subIndustries.filter(
            (opt) => opt.subIndustryName !== ''
        );
        if (this.state.operationType === UPDATE) {
            this.props.updateIndustryRequest(values.id, valuesData);
        } else {
            this.props.addIndustryRequest(valuesData);
        }
    };
    resetForm = () => {
        this.setState({
            industryData: initialIndustryDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let industryRecords = [];
        industryRecords = records.map((industryData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_INDUSTRY, PERMISSION_EDIT) ===
                        true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewIndustry(industryData);
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('industry') &&
                        checkModulePermission(
                            MODULE_INDUSTRY,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteIndustry(industryData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(industryData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(industryData.id)}
                        checked={industryData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            return {
                industryName: industryData.industryName,
                subIndustriesCount: industryData.subIndustry
                    ? industryData.subIndustry.length
                    : 0,
                isActive: isActive,
                actions: actions,
            };
        });
        return industryRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.industryList &&
            this.props.industryList !== nextprops.industryList
        ) {
            this.setState({
                exportIndustryList: nextprops.industryList,
                industryList: this.generateTable(nextprops.industryList),
            });
        }
        if (
            nextprops.subIndustryList &&
            this.props.subIndustryList !== nextprops.subIndustryList
        ) {
            this.setState({
                subIndustryList: nextprops.subIndustryList,
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.industryList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'industry'} />
                        </Col>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t('page_headers.industry_list')}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage
                                        activepage={this.state.activePage}
                                        onHandlePageChange={this.handlePageChange}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {(checkModulePermission(
                            MODULE_INDUSTRY,
                            PERMISSION_ADD
                        ) === true ||
                            checkModulePermission(
                                MODULE_INDUSTRY,
                                PERMISSION_EDIT
                            ) === true) && (
                                <Col lg="5">
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'page_headers.industry_form'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <AddIndustryForm
                                                operationType={
                                                    this.state.operationType
                                                }
                                                industryData={
                                                    this.state.industryData
                                                }
                                                operationResponse={
                                                    this.props.industryApiResponse
                                                }
                                                onFormValid={this.formSubmit}
                                                onFormReset={this.resetForm}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                    </Row>

                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'industry'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    industryList: makeIndustrySelectField('industryList'),
    industryApiResponse: makeIndustrySelectField('industryApiResponse'),
    subIndustryList: makeSubIndustrySelectField('subIndustryList'),
});
const withConnect = connect(mapStatetoProps, {
    getAllIndustryListRequest,
    updateIndustryStatusByIndustryIdRequest,
    addIndustryRequest,
    updateIndustryRequest,
    deleteIndustryByIndustryIdRequest,
    getAllSubIndustryListRequest,
});
export default compose(
    withConnect,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withRouter
)(Index);
