import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.subIndustry || initialState;
const makeSubIndustrySelectField = field =>
    createSelector(reducerState, subIndustryState => subIndustryState[field]);
const selectSortedSubIndustryList = () =>
    createSelector(makeSubIndustrySelectField('subIndustryList'), subIndustryList =>
        subIndustryList.map(opt => ({
            value: opt.id,
            label: opt.subIndustryName
        }))
    );
export {
    makeSubIndustrySelectField,
    selectSortedSubIndustryList
};
