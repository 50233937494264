import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label, Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    SUBMIT,
    SUCCESS,
    CONFIRMUPDATE,
    DANGER,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { initialQuickTaskUpdateState } from './CommonFunctions';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import {
    customDateValidator,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { completionStatusList } from 'constants/dropdowns';
import { makeTaskSelectField } from 'store/Task/selector';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import { addFileUploadRequest, updateQuickTaskRequest } from 'store/actions';
import { customProofFileValidator } from 'helpers/customValidators';
import { toastrMessage } from 'helpers/messageHelper';
import { SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS } from 'constants/databaseConstants';

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

class QuickSelectedUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.validator2 = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            quickTaskUpdateFormData: initialQuickTaskUpdateState(),
            quickTaskUpdateList: [],
            fileUploadId: '',
        };
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let customJson = [];
            this.props.quickUpdateSelectedData.map((x) => {
                customJson.push({
                    taskId: x.id,
                    compliancePerformanceDate: values.dateOfCompletion,
                    completionStatus: values.completionStatus,
                    remarks: values.remarks,
                    poc: '',
                    originalFilenames: '',
                    taskCode: x.taskCode,
                    title: x.complianceTitle,
                    dueDate: x.dueDate,
                    departmentId: '',
                    complianceAllocationDepartmentIds:
                        x.complianceAllocationDepartmentId &&
                            x.complianceAllocationDepartmentId != null
                            ? x.complianceAllocationDepartmentId
                                .split(',')
                                .map(Number)
                            : [],
                    proofRequired: x.proofRequired,
                });
            });
            this.setState({
                quickTaskUpdateList: customJson,
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    formFinalSubmit = (values) => {
        if (this.validator2.allValid()) {
            let customJson = [];
            values.map((x) => {
                customJson.push({
                    taskId: x.taskId,
                    compliancePerformanceDate: x.compliancePerformanceDate,
                    completionStatus: x.completionStatus,
                    remarks: x.remarks,
                    poc: x.poc,
                    originalFilenames: x.originalFilenames,
                    departmentId: x.departmentId,
                });
            });
            this.props.updateQuickTaskRequest({
                tasks: customJson,
            });
        } else {
            this.validator2.showMessages();
            this.forceUpdate();
        }
    };

    handleFileUpload = (values, id) => (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        let errorsArray = [];
        chosenFiles.map((file) => {
            if (!customProofFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.proof_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            toastrMessage(
                __t('form_labels.reporting.proof_file') +
                ' ' +
                __t('validations.custom.proof_file'),
                DANGER
            );
            return false;
        }
        if (chosenFiles) {
            let formData = new FormData();
            chosenFiles.map((x) => formData.append('file', x));
            formData.append('type', 'quickupdate');
            this.setState(
                {
                    fileUploadId: id,
                    quickTaskUpdateList: values,
                },
                () => {
                    this.props.addFileUploadRequest(formData);
                }
            );
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.fileUploadResponse &&
            this.props.fileUploadResponse !== nextprops.fileUploadResponse
        ) {
            if (nextprops.fileUploadResponse.responseType === SUCCESS) {
                let updatePoc = [];
                updatePoc = this.state.quickTaskUpdateList.map((obj) =>
                    obj.taskId === this.state.fileUploadId
                        ? {
                            ...obj,
                            poc: nextprops.fileUploadResponse.data
                                .uploadedFileName,
                            originalFilenames:
                                nextprops.fileUploadResponse.data
                                    .originalFilenames,
                        }
                        : obj
                );

                this.setState({
                    quickTaskUpdateList: updatePoc,
                    fileUploadId: '',
                });
            } else {
                toastrMessage(nextprops.fileUploadResponse.message, DANGER);
                this.setState({
                    fileUploadId: '',
                });
            }
        }

        if (
            nextprops.quickUpdateTaskApiResponse &&
            this.props.quickUpdateTaskApiResponse !==
            nextprops.quickUpdateTaskApiResponse
        ) {
            this.onModalDismiss();
        }
    }
    onModalDismiss = () => {
        this.setState({
            quickTaskUpdateFormData: initialQuickTaskUpdateState(),
            quickTaskUpdateList: [],
            fileUploadId: '',
        });
        this.validator.hideMessages();
        this.validator2.hideMessages();
        this.props.onModalDismiss();
    };

    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={
                        this.state.quickTaskUpdateList.length > 0
                            ? 'modal-xxl'
                            : 'modal-lg'
                    }
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={
                                    this.state.quickTaskUpdateFormData
                                }
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form id="a">

                                        <Row className="d-flex m-0">
                                            <FormGroup className="col-lg-6 w-100 sticky_label pl-0 pr-2">
                                                <Row className="m-0">
                                                    <Col className="col-12 mb-3 p-0">
                                                        <Field
                                                            component={
                                                                DatePicker
                                                            }
                                                            className="form-control"
                                                            name="dateOfCompletion"
                                                            selected={
                                                                values.dateOfCompletion
                                                                    ? getDateObjectWithValueFromMoment(
                                                                        values.dateOfCompletion
                                                                    )
                                                                    : null
                                                            }
                                                            dateFormat={
                                                                DATE_FORMAT_DATEPICKER
                                                            }
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                setFieldValue(
                                                                    'dateOfCompletion',
                                                                    date
                                                                );
                                                            }}
                                                            showMonthDropdown={
                                                                true
                                                            }
                                                            showYearDropdown={
                                                                true
                                                            }
                                                            dropdownMode="select"
                                                            placeholderText={__t(
                                                                'form_labels.reporting.quick_update.date_of_completion'
                                                            )}
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                            maxDate={new Date()}
                                                        ></Field>
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.reporting.quick_update.date_of_completion'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.reporting.quick_update.date_of_completion'
                                                            ),
                                                            values.dateOfCompletion
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.dateOfCompletion
                                                                )
                                                                : '',
                                                            'required|customDate',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.reporting.date_of_completion_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </Col>
                                                    <Col className="col-12 p-0">
                                                        <Field
                                                            component={Select}
                                                            name="completionStatus"
                                                            className="form-control selectbox"
                                                            options={
                                                                completionStatusList
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'completionStatus',
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={completionStatusList.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.completionStatus
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.reporting.quick_update.completion_status'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.reporting.quick_update.completion_status'
                                                            ),
                                                            values.completionStatus,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.reporting.completion_status_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup className="col-6 pr-0 pl-2">
                                                <Field
                                                    type="text"
                                                    component="textarea"
                                                    name="remarks"
                                                    rows="2"
                                                    className="form-control h-100"
                                                    placeholder={__t(
                                                        'form_labels.reporting.remarks'
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.remarks'
                                                    )}
                                                    {values?.completionStatus &&
                                                        values.completionStatus ==
                                                        SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS && (
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        )}
                                                </Label>
                                                {values.completionStatus ==
                                                    SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS
                                                    ? this.validator.message(
                                                        __t(
                                                            'form_labels.reporting.remarks'
                                                        ),
                                                        values.remarks,
                                                        'required|min:3|max:5000',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.reporting.remarks_required'
                                                                ),
                                                                min: __t(
                                                                    'validations.reporting.remarks_min',
                                                                    {
                                                                        min: 3,
                                                                    }
                                                                ),
                                                                max: __t(
                                                                    'validations.reporting.remarks_max',
                                                                    {
                                                                        max: 1000,
                                                                    }
                                                                ),
                                                            },
                                                        }
                                                    )
                                                    : ''}
                                            </FormGroup>

                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {SUBMIT}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={() =>
                                                            this.onModalDismiss()
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                            {this.state.quickTaskUpdateList.length > 0 && (
                                <Formik
                                    initialValues={
                                        this.state.quickTaskUpdateList
                                    }
                                    enableReinitialize={true}
                                    onSubmit={this.formFinalSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>

                                            <Row className=" mt-3">
                                                <Col className="col-12">
                                                    <MDBTable bordered>
                                                        <MDBTableHead>
                                                            <tr>
                                                                <th
                                                                    colSpan={8}
                                                                    className="bg-primary text-center text-light"
                                                                >
                                                                    Quick Update
                                                                    List
                                                                </th>
                                                            </tr>
                                                            <tr className="sub_header">
                                                                <th>
                                                                    {__t(
                                                                        'form_labels.reporting.quick_update.task_code'
                                                                    )}
                                                                </th>
                                                                <th>
                                                                    {__t(
                                                                        'form_labels.reporting.quick_update.title'
                                                                    )}
                                                                </th>
                                                                <th>
                                                                    {__t(
                                                                        'form_labels.reporting.quick_update.due_date'
                                                                    )}
                                                                </th>
                                                                <th>
                                                                    {__t(
                                                                        'form_labels.reporting.quick_update.date_of_completion'
                                                                    )}
                                                                </th>
                                                                <th className="completion_status_th">
                                                                    {__t(
                                                                        'form_labels.reporting.quick_update.completion_status'
                                                                    )}
                                                                </th>

                                                                <th className="completion_status_th">
                                                                    {__t(
                                                                        'form_labels.reporting.quick_update.department'
                                                                    )}
                                                                </th>
                                                                <th>
                                                                    {__t(
                                                                        'form_labels.reporting.remarks'
                                                                    )}
                                                                </th>
                                                                <th>
                                                                    {__t(
                                                                        'form_labels.reporting.quick_update.poc'
                                                                    )}
                                                                </th>
                                                            </tr>
                                                        </MDBTableHead>

                                                        <MDBTableBody>
                                                            {values ? (
                                                                values?.map(
                                                                    (
                                                                        x,
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <tr
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                <td className="text-nowrap">
                                                                                    {
                                                                                        x.taskCode
                                                                                    }
                                                                                </td>
                                                                                <td className="text-nowrap">
                                                                                    {
                                                                                        x.title
                                                                                    }
                                                                                </td>
                                                                                <td className="text-nowrap">
                                                                                    {
                                                                                        x.dueDate
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <Field
                                                                                        component={
                                                                                            DatePicker
                                                                                        }
                                                                                        className="form-control table-datepicker"
                                                                                        name="compliancePerformanceDate"
                                                                                        selected={
                                                                                            x.compliancePerformanceDate
                                                                                                ? getDateObjectWithValueFromMoment(
                                                                                                    x.compliancePerformanceDate
                                                                                                )
                                                                                                : null
                                                                                        }
                                                                                        dateFormat={
                                                                                            DATE_FORMAT_DATEPICKER
                                                                                        }
                                                                                        onChange={(
                                                                                            date
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                `${idx}.compliancePerformanceDate`,
                                                                                                date
                                                                                            );
                                                                                        }}
                                                                                        showMonthDropdown={
                                                                                            true
                                                                                        }
                                                                                        showYearDropdown={
                                                                                            true
                                                                                        }
                                                                                        dropdownMode="select"
                                                                                        placeholderText={__t(
                                                                                            'form_labels.reporting.quick_update.date_of_completion'
                                                                                        )}
                                                                                        onFocus={(
                                                                                            e
                                                                                        ) =>
                                                                                            e.target.blur()
                                                                                        }
                                                                                        maxDate={
                                                                                            new Date()
                                                                                        }
                                                                                    ></Field>
                                                                                </td>
                                                                                <td>
                                                                                    <Field
                                                                                        component={
                                                                                            Select
                                                                                        }
                                                                                        name="completionStatus"
                                                                                        className="form-control selectbox"
                                                                                        options={
                                                                                            completionStatusList
                                                                                        }
                                                                                        onChange={(
                                                                                            opt
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                `${idx}.completionStatus`,
                                                                                                opt.value
                                                                                            );
                                                                                        }}
                                                                                        value={completionStatusList.filter(
                                                                                            (
                                                                                                option
                                                                                            ) =>
                                                                                                option.value ===
                                                                                                x.completionStatus
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <Field
                                                                                        component={
                                                                                            Select
                                                                                        }
                                                                                        name="departmentId"
                                                                                        className="form-control selectbox"
                                                                                        options={this.props.sortedDepartmentListDropdown.filter(
                                                                                            (
                                                                                                opt
                                                                                            ) =>
                                                                                                x.complianceAllocationDepartmentIds?.includes(
                                                                                                    opt.value
                                                                                                )
                                                                                        )}
                                                                                        onChange={(
                                                                                            opt
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                `${idx}.departmentId`,
                                                                                                opt.value
                                                                                            );
                                                                                        }}
                                                                                        value={this.props.sortedDepartmentListDropdown.filter(
                                                                                            (
                                                                                                option
                                                                                            ) =>
                                                                                                option.value ===
                                                                                                x.departmentId
                                                                                        )}
                                                                                    />
                                                                                    {this.validator2.message(
                                                                                        __t(
                                                                                            'form_labels.reporting.department'
                                                                                        ),
                                                                                        x.departmentId,
                                                                                        'required',
                                                                                        {
                                                                                            messages:
                                                                                            {
                                                                                                required:
                                                                                                    __t(
                                                                                                        'validations.reporting.department_required'
                                                                                                    ),
                                                                                            },
                                                                                        }
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    <Field
                                                                                        className="form-control"
                                                                                        name="remarks"
                                                                                        value={
                                                                                            x.remarks
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                `${idx}.remarks`,
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    {x.completionStatus ==
                                                                                        SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS
                                                                                        ? this.validator2.message(
                                                                                            __t(
                                                                                                'form_labels.reporting.remarks'
                                                                                            ),
                                                                                            x.remarks,
                                                                                            'required|min:3|max:5000',
                                                                                            {
                                                                                                messages:
                                                                                                {
                                                                                                    required:
                                                                                                        __t(
                                                                                                            'validations.reporting.remarks_required'
                                                                                                        ),
                                                                                                    min: __t(
                                                                                                        'validations.reporting.remarks_min',
                                                                                                        {
                                                                                                            min: 3,
                                                                                                        }
                                                                                                    ),
                                                                                                    max: __t(
                                                                                                        'validations.reporting.remarks_max',
                                                                                                        {
                                                                                                            max: 1000,
                                                                                                        }
                                                                                                    ),
                                                                                                },
                                                                                            }
                                                                                        )
                                                                                        : ''}
                                                                                </td>
                                                                                <td>
                                                                                    <Input
                                                                                        className="Quick_Update_List_file text-wrap"
                                                                                        type="file"
                                                                                        name={`${idx}.poc`}
                                                                                        onChange={this.handleFileUpload(
                                                                                            values,
                                                                                            x.taskId
                                                                                        )}
                                                                                        multiple={
                                                                                            true
                                                                                        }
                                                                                        accept=".zip, .pdf, .jpg, .jpeg, .png, .txt, .doc, .docx, .csv, .xlsx, .xls"
                                                                                    />
                                                                                    {x.proofRequired ===
                                                                                        1
                                                                                        ? this.validator2.message(
                                                                                            __t(
                                                                                                'form_labels.reporting.quick_update.poc'
                                                                                            ),
                                                                                            x.poc,
                                                                                            'required',
                                                                                            {
                                                                                                messages:
                                                                                                {
                                                                                                    required:
                                                                                                        __t(
                                                                                                            'validations.reporting.poc_required'
                                                                                                        ),
                                                                                                },
                                                                                            }
                                                                                        )
                                                                                        : ''}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td
                                                                        colSpan={
                                                                            6
                                                                        }
                                                                    >
                                                                        No
                                                                        Record
                                                                        Found
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </MDBTableBody>
                                                    </MDBTable>

                                                    <Col lg="12">
                                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                            >
                                                                {CONFIRMUPDATE}
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="secondary"
                                                                onClick={() =>
                                                                    this.onModalDismiss()
                                                                }
                                                            >
                                                                {CANCEL}
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    fileUploadResponse: makeTaskSelectField('fileUploadResponse'),
    quickUpdateTaskApiResponse: makeTaskSelectField(
        'quickUpdateTaskApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    addFileUploadRequest,
    updateQuickTaskRequest,
});

export default compose(
    withConnect,
    withTaskReducer,
    withTaskSaga,
    withRouter
)(QuickSelectedUpdateModal);
