import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import {
    CLOSE,
    EXPORT_BTN,
    NONE,
    SUCCESS,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import {
    getEntityListByCompanyGroupIdRequest,
    getDashboardLawCompliancesSearchRequest,
} from 'store/actions';
import { selectSortedEntityList } from 'store/Entity/selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { withRouter } from 'react-router-dom';
import { makeDashboardSelectField } from 'store/Dashboard/selector';
import { exportToCSV } from 'components/Application/ExportCSVManual';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const initialFilterDataState = () => {
    return {
        companyGroupId: '',
        entityId: '',
    };
};

class ExportLawCompliance extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            filterData: initialFilterDataState(),
        };
    }

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.getDashboardLawCompliancesSearchRequest({
                filter: values,
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                filterData: initialFilterDataState(),
            },
            () => {
                this.props.onModalDismiss();
            }
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.dashboardLawCompliancesSearchApiResponse &&
            this.props.dashboardLawCompliancesSearchApiResponse !==
            nextprops.dashboardLawCompliancesSearchApiResponse
        ) {
            if (
                nextprops.dashboardLawCompliancesSearchApiResponse
                    .responseType === SUCCESS
            ) {
                exportToCSV(
                    [nextprops.dashboardLawCompliancesSearchApiResponse.data],
                    'law-compliance'
                );
            } else {
                toastrMessage(
                    __t('alerts.no_law_compliance_found'),
                    nextprops.dashboardLawCompliancesSearchApiResponse
                        .responseType
                );
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={__t('page_headers.export_law_compliances')}
                    isModalOpen={this.props.isModalOpen}
                    width="100%"
                    onModalDismiss={() => {
                        this.resetForm();
                    }}
                >
                    <Formik
                        initialValues={this.state.filterData}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>

                                <Row>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component={Select}
                                            name="companyGroupId"
                                            className="form-control selectbox"
                                            options={this.props.sortedCompanyGroupListDropdown.filter(
                                                (opt) => opt.value != NONE
                                            )}
                                            onChange={(opt) => {
                                                let companyGroupId = opt
                                                    ? opt.value
                                                    : '';
                                                setFieldValue(
                                                    'companyGroupId',
                                                    companyGroupId
                                                );
                                                setFieldValue('entityId', '');
                                                if (companyGroupId != '') {
                                                    this.props.getEntityListByCompanyGroupIdRequest(
                                                        [companyGroupId]
                                                    );
                                                }
                                            }}
                                            value={this.props.sortedCompanyGroupListDropdown.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.companyGroupId
                                            )}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.dashboard.company_group'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t(
                                                'form_labels.dashboard.company_group'
                                            ),
                                            values.companyGroupId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.dashboard.company_group_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Field
                                            component={Select}
                                            name="entityId"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedEntityListDropdown
                                            }
                                            onChange={(opt) => {
                                                setFieldValue(
                                                    'entityId',
                                                    opt ? opt.value : ''
                                                );
                                            }}
                                            value={this.props.sortedEntityListDropdown?.filter(
                                                (option) =>
                                                    values.entityId ==
                                                    option.value
                                            )}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.dashboard.entity'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t('form_labels.dashboard.entity'),
                                            values.entityId,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.dashboard.entity_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                            <div className="d-inline-block">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {EXPORT_BTN}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={this.resetForm}
                                                >
                                                    {CLOSE}
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedEntityListDropdown: selectSortedEntityList(),
    dashboardLawCompliancesSearchApiResponse: makeDashboardSelectField(
        'dashboardLawCompliancesSearchApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getEntityListByCompanyGroupIdRequest,
    getDashboardLawCompliancesSearchRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(ExportLawCompliance);
