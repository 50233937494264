import {
    LOGIN_RESPONSE,
    LOGIN_PASSWORD_CHANGE_RESPONSE,
    LOGOUT_RESPONSE,
    SEND_CODE_RESPONSE,
    VERIFY_TOKEN_RESPONSE,
    VERIFY_CODE_RESPONSE,
} from './actionTypes';
import produce from 'immer';
export const initialState = {
    loginApiResponse: null,
    sendCodeApiResponse: null,
    loginPasswordChangeApiResponse: null,
    logoutApiResponse: null,
    verifyCodeApiResponse: null,
    verifySSOTokenApiResponse: null
};
const login = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOGIN_RESPONSE:
                draft.loginApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            case SEND_CODE_RESPONSE:
                draft.sendCodeApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            case LOGIN_PASSWORD_CHANGE_RESPONSE:
                draft.loginPasswordChangeApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            case LOGOUT_RESPONSE:
                draft.logoutApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case VERIFY_TOKEN_RESPONSE:
                if (action.payload.type === "default") {
                    draft.verifySSOTokenApiResponse = {
                        message: action.payload.message,
                        responseType: action.payload.responseType,
                        responseData: action.payload.responseData,
                        type: action.payload.type
                    };
                } else {
                    draft.loginApiResponse = {
                        message: action.payload.message,
                        responseType: action.payload.responseType,
                        responseData: action.payload.responseData,
                        type: action.payload.type
                    };
                }
                break;
            case VERIFY_CODE_RESPONSE:
                draft.verifyCodeApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default login;
