import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_ENTITY_ROLE_REQUEST,
    DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST,
    GET_ALL_ENTITY_ROLE_LIST_REQUEST,
    GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST,
    GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_REQUEST,
    GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_REQUEST,
    UPDATE_ENTITY_ROLE_REQUEST,
    UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addEntityRoleResponse,
    deleteEntityRoleByEntityRoleIdResponse,
    getAllEntityRoleListRequest,
    getAllEntityRoleListResponse,
    getEntityRoleByEntityRoleIdResponse,
    getEntityRoleListByEntityIdResponse,
    getRolesWithEntityRoleByEntityListResponse,
    updateEntityRoleResponse,
    updateEntityRoleStatusByEntityRoleIdResponse,
} from './actions';
import { ENTITY_ROLE, ENTITY_ROLE_BY_ENTITY, ROLES_WITH_ENTITY_ROLE_BY_ENTITY } from 'helpers/apiConstants';
function* addEntityRole({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ENTITY_ROLE, data);
        if (response.status) {
            yield put(getAllEntityRoleListRequest());
            yield put(addEntityRoleResponse(response.message, SUCCESS));
        } else {
            yield put(addEntityRoleResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllEntityRoleList() {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_ROLE, '');
        if (response.status) {
            yield put(getAllEntityRoleListResponse(response.data.list));
        } else {
            yield put(getAllEntityRoleListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityRoleByEntityRoleId({ payload: { entityRoleId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_ROLE + '/' + entityRoleId);
        if (response.status) {
            yield put(getEntityRoleByEntityRoleIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getEntityRoleByEntityRoleIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateEntityRole({ payload: { entityRoleId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', ENTITY_ROLE + '/' + entityRoleId, data);
        if (response.status) {
            yield put(getAllEntityRoleListRequest());
            yield put(updateEntityRoleResponse(response.message, SUCCESS));
        } else {
            yield put(updateEntityRoleResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateEntityRoleStatusByEntityRoleId({
    payload: { entityRoleId, status },
}) {
    try {
        const response = yield call(invokeApi, 'patch', ENTITY_ROLE + '/' + entityRoleId + '/status/' + status);
        if (response.status) {
            yield put(getAllEntityRoleListRequest());
            yield put(updateEntityRoleStatusByEntityRoleIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateEntityRoleStatusByEntityRoleIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteEntityRoleByEntityRoleId({ payload: { entityRoleId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            ENTITY_ROLE + '/' + entityRoleId
        );
        if (response.status) {
            yield put(getAllEntityRoleListRequest());
            yield put(
                deleteEntityRoleByEntityRoleIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteEntityRoleByEntityRoleIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityRoleListByEntityId({ payload: { entityId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_ROLE_BY_ENTITY + '/' + entityId, '');
        if (response.status) {
            yield put(getEntityRoleListByEntityIdResponse(response.data.list));
        } else {
            yield put(getEntityRoleListByEntityIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getRolesWithEntityRoleByEntityList({ payload: { entityId } }) {
    try {
        const response = yield call(invokeApi, 'get', ROLES_WITH_ENTITY_ROLE_BY_ENTITY + '/' + entityId, '');
        if (response.status) {
            yield put(getRolesWithEntityRoleByEntityListResponse(response.data.list));
        } else {
            yield put(getRolesWithEntityRoleByEntityListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchEntityRole() {
    yield takeEvery(ADD_ENTITY_ROLE_REQUEST, addEntityRole);
    yield takeEvery(GET_ALL_ENTITY_ROLE_LIST_REQUEST, getAllEntityRoleList);
    yield takeEvery(GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST, getEntityRoleByEntityRoleId);
    yield takeEvery(UPDATE_ENTITY_ROLE_REQUEST, updateEntityRole);
    yield takeEvery(UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_REQUEST, updateEntityRoleStatusByEntityRoleId);
    yield takeEvery(DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST, deleteEntityRoleByEntityRoleId);
    yield takeEvery(GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_REQUEST, getEntityRoleListByEntityId);
    yield takeEvery(GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_REQUEST, getRolesWithEntityRoleByEntityList);
}
function* entityRoleSaga() {
    yield all([fork(watchEntityRole)]);
}
export default entityRoleSaga;
