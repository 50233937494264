import { compareString } from 'helpers/generalUtils';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.allocation || initialState;
const makeAllocationSelectField = field => createSelector(reducerState,
    allocationState => allocationState[field]);
const selectSortedAssignedComplianceList = () =>
    createSelector(makeAllocationSelectField('assignedComplianceList'), assignedComplianceList =>
        assignedComplianceList
            .map(opt => ({
                value: opt.id,
                label: opt.complianceCode + ' - ' + opt.complianceTitle,
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeAllocationSelectField,
    selectSortedAssignedComplianceList
};
