import { setEmptyToNull } from 'helpers/generalUtils';
import { isManagementRole } from 'helpers/projectUtils';
export const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State',
        field: 'stateName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Type',
        field: 'complianceType',
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Status',
        field: 'taskStatusId',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const listAuditColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State',
        field: 'stateName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Type',
        field: 'complianceType',
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Task Status',
        field: 'taskStatusId',
    },
    {
        label: 'Audit Status',
        field: 'auditStatus',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const listColumnsWithCheckbox = [
    {
        Header: 'Code',
        accessor: 'taskCode',
        disableSortBy: true,
    },
    {
        Header: 'Entity',
        accessor: 'entityName',
        disableSortBy: true,
    },
    {
        Header: 'Location',
        accessor: 'cityName',
        disableSortBy: true,
    },
    {
        Header: 'Compliance Code',
        accessor: 'complianceCode',
        disableSortBy: true,
    },
    {
        Header: 'Title',
        accessor: 'complianceTitle',
        disableSortBy: true,
    },
    {
        Header: 'Type',
        accessor: 'complianceType',
        disableSortBy: true,
    },
    {
        Header: 'Classification',
        accessor: 'complianceClass',
        disableSortBy: true,
    },
    {
        Header: 'Due Date',
        accessor: 'dueDate',
        disableSortBy: true,
    },
    {
        Header: 'Task Type',
        accessor: 'parentTaskId',
        disableSortBy: true,
    },
    {
        Header: 'Status',
        accessor: 'taskStatusId',
        disableSortBy: true,
    },
    {
        Header: 'Action',
        className: 'action_col',
        accessor: 'actions',
        disableSortBy: true,
    },
];

export const initialTaskFilterDataState = () => {
    return {
        companyGroupId: [],
        entityId: [],
        countryId: [],
        stateId: [],
        cityId: [],
        statusId: [],
        departmentId: [],
        isManagementUser: isManagementRole(),
        searchText: '',
        listType: 'ALL',
        riskRatingId: [],
    };
};
export const intFields = [];
export const nullableFields = ['searchText'];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
export const initialTaskReportingFormDataState = () => {
    return {
        completionStatus: 1,
        systemReportingDate: new Date(),
        compliancePerformanceDate: '',
        documentProcurementDate: '',
        expenseType: [1],
        amount1: 0,
        currency1: '',
        amount2: 0,
        currency2: '',
        matterInLitigation: 0,
        description: '',
        remarks: '',
        recommendations: '',
        activityType: '',
        departmentId: '',
        discrepancyType: [],
        discrepancyDepartmentAmountData: [
            {
                departmentId: '',
                currency: '',
                amount: 0.00
            }
        ],
        discrepancyNonMonetaryDescription: '',
        discrepancyOthersDescription: ''
    }
}
export const initialSubTaskDetailsDataState = () => {
    return {
        id: '',
        parentTaskId: '',
        title: '',
        description: '',
        dueDate: '',
        submitterId: '',
        firstApproverUserId: '',
        secondApproverUserId: '',
        attachmentFile: '',
    };
};
export const transactionalDocumentTableColumns = [
    {
        label: 'Document Name',
        field: 'documentName',
    },
    {
        label: 'Uploaded Window',
        field: 'documentType',
    },
    {
        label: 'Uploaded By',
        field: 'uploadedBy',
    },
    {
        label: 'Uploaded At',
        field: 'uploadedAt',
    },
];

export const standardDocumentTableColumns = [
    // {
    //     label: 'Law Code',
    //     field: 'lawCode'
    // },
    {
        label: 'Document Name',
        field: 'documentName',
    },
    {
        label: 'Document Type',
        field: 'documentType',
    },
    {
        label: 'Reference No',
        field: 'referenceNo',
    },
    {
        label: 'Date of Publication',
        field: 'releaseDate',
    },
    {
        label: 'Effective Date',
        field: 'effectiveDate',
    },
    {
        label: 'Description of the Document',
        field: 'documentDescription',
    },
    {
        label: 'Brief Notes (If Any)',
        field: 'briefNote',
    },
];

export const recentActivityTableColumns = [
    {
        label: 'Activity',
        field: 'activityType',
    },
    {
        label: 'Compliance Performance Date',
        field: 'compliancePerformanceDate',
    },
    {
        label: 'System Reporting Date',
        field: 'referenceDate',
    },
    {
        label: 'Completion Status',
        field: 'completionStatus',
    },
    {
        label: 'Remarks',
        field: 'remarks',
    },
    {
        label: 'Files',
        field: 'files',
    },
    {
        label: 'Created On',
        field: 'createdAt',
    },
    {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
    },
];

export const initialCollaboratorDataState = () => {
    return {
        userId: '',
    };
};

export const initialQuickTaskUpdateState = () => {
    return {
        dateOfCompletion: '',
        completionStatus: '',
        remarks: '',
    };
};

export const roleListForObservation = [
    {
        label: 'Submitter',
        value: 'submitter'
    },
    {
        label: 'Reviewer',
        value: 'reviewer'
    },
    {
        label: 'Approver',
        value: 'approver'
    }
];