// TawkToChat.js
import { TAWK_CLIENT_UAT_URL, TAWK_DEV_URL, getBaseUrl } from 'helpers/axiosHelper';
import React, { useEffect } from 'react';

function TawkToChat() {
  
  useEffect(() => {
    const baseUrl = getBaseUrl();
    let url = (baseUrl === "https://gt1.hcshub.in/gt1-api") ? TAWK_DEV_URL : TAWK_CLIENT_UAT_URL;

    // Create the Tawk_API object if it doesn't exist
    window.Tawk_API = window.Tawk_API || {};

    // Load the script
    const s1 = document.createElement('script');
    s1.async = true;
    s1.src = url;
    // s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');

    // Append the script to the document
    document.getElementsByTagName('head')[0].appendChild(s1);
    if (window && window.Tawk_API && window.Tawk_API?.showWidget) {
      window.Tawk_API.showWidget();
    }
    return () => {
      // Cleanup when the component unmounts
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
    };
  }, []);

  return (
    <div>
      {/* The chat widget will only be shown when showChatWidget is true */}
    </div>
  );
}

export default TawkToChat;
