import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_EMAIL_TEMPLATE_TYPES_LIST_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { EMAIL_TEMPLATE_TYPES } from 'helpers/apiConstants';
import { getEmailTemplateTypesListResponse } from './actions';
function* getEmailTemplateTypesList() {
    try {
        const response = yield call(invokeApi, 'get', EMAIL_TEMPLATE_TYPES, '');
        if (response.status) {
            yield put(getEmailTemplateTypesListResponse(response.data.list));
        } else {
            yield put(getEmailTemplateTypesListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchEmailTemplate() {
    yield takeEvery(GET_EMAIL_TEMPLATE_TYPES_LIST_REQUEST, getEmailTemplateTypesList);

}
function* emailTemplateSaga() {
    yield all([fork(watchEmailTemplate)]);
}
export default emailTemplateSaga;