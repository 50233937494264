import {
    ADD_USER_REQUEST,
    ADD_USER_RESPONSE,
    DELETE_USER_BY_USER_ID_REQUEST,
    DELETE_USER_BY_USER_ID_RESPONSE,
    GET_ALL_USER_LIST_REQUEST,
    GET_ALL_USER_LIST_RESPONSE,
    GET_USER_BY_USER_ID_REQUEST,
    GET_USER_BY_USER_ID_RESPONSE,
    GET_USER_LIST_BY_STATUS_REQUEST,
    GET_USER_LIST_BY_STATUS_RESPONSE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_RESPONSE,
    UPDATE_USER_STATUS_BY_USER_ID_REQUEST,
    UPDATE_USER_STATUS_BY_USER_ID_RESPONSE,
    GET_USER_LIST_BY_ENTITY_ID_REQUEST,
    GET_USER_LIST_BY_ENTITY_ID_RESPONSE,
    GET_USER_PROFILE_REQUEST,
    GET_USER_PROFILE_RESPONSE,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_RESPONSE,
    PASSWORD_RESEND_USER_BY_USER_ID_REQUEST,
    PASSWORD_RESEND_USER_BY_USER_ID_RESPONSE,
    SET_NEW_PASSWORD_OF_LOGIN_USER_REQUEST,
    SET_NEW_PASSWORD_OF_LOGIN_USER_RESPONSE,
    GET_USERS_BY_COMPANYGROUP_REQUEST,
    GET_USERS_BY_COMPANYGROUP_RESPONSE,
    GET_USERS_BY_ENTITY_REQUEST,
    GET_USERS_BY_ENTITY_RESPONSE,
    FILTER_USER_REQUEST,
    FILTER_USER_RESPONSE,
    UPDATE_APPLICATION_THEME_REQUEST,
    UPDATE_APPLICATION_THEME_RESPONSE,
    CLEARE_FILTER_USER_RESPONSE,
    UNBLOCK_EMAIL_REQUEST,
    UNBLOCK_EMAIL_RESPONSE,
    GET_USER_ROLES_BY_USER_ID_REQUEST,
    GET_USER_ROLES_BY_USER_ID_RESPONSE
} from './actionTypes';
export const addUserRequest = (data) => {
    return {
        type: ADD_USER_REQUEST,
        payload: { data },
    };
};
export const addUserResponse = (message, responseType) => {
    return {
        type: ADD_USER_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllUserListRequest = () => {
    return {
        type: GET_ALL_USER_LIST_REQUEST,
    };
};
export const getAllUserListResponse = (userList) => {
    return {
        type: GET_ALL_USER_LIST_RESPONSE,
        payload: { userList },
    };
};
export const getUserByUserIdRequest = (userId) => {
    return {
        type: GET_USER_BY_USER_ID_REQUEST,
        payload: { userId: userId },
    };
};
export const getUserByUserIdResponse = (message, responseType, data) => {
    return {
        type: GET_USER_BY_USER_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateUserRequest = (userId, data) => {
    return {
        type: UPDATE_USER_REQUEST,
        payload: { userId, data },
    };
};
export const updateUserResponse = (message, responseType) => {
    return {
        type: UPDATE_USER_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateUserStatusByUserIdRequest = (userId, status) => {
    return {
        type: UPDATE_USER_STATUS_BY_USER_ID_REQUEST,
        payload: { userId, status },
    };
};
export const updateUserStatusByUserIdResponse = (message, responseType) => {
    return {
        type: UPDATE_USER_STATUS_BY_USER_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getUserListByStatusRequest = (status) => {
    return {
        type: GET_USER_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getUserListByStatusResponse = (userList) => {
    return {
        type: GET_USER_LIST_BY_STATUS_RESPONSE,
        payload: { userList },
    };
};

export const deleteUserByUserIdRequest = (userId) => {
    return {
        type: DELETE_USER_BY_USER_ID_REQUEST,
        payload: { userId },
    };
};
export const deleteUserByUserIdResponse = (message, responseType) => {
    return {
        type: DELETE_USER_BY_USER_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getUserListByCompanyIdRequest = (companyId) => {
    return {
        type: GET_USER_LIST_BY_ENTITY_ID_REQUEST,
        payload: { companyId },
    };
};
export const getUserListByCompanyIdResponse = (userList) => {
    return {
        type: GET_USER_LIST_BY_ENTITY_ID_RESPONSE,
        payload: { userList },
    };
};

export const getUserProfileRequest = () => {
    return {
        type: GET_USER_PROFILE_REQUEST,
    };
};
export const getUserProfileResponse = (message, responseType, data) => {
    return {
        type: GET_USER_PROFILE_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateUserProfileRequest = (data) => {
    return {
        type: UPDATE_USER_PROFILE_REQUEST,
        payload: { data },
    };
};
export const updateUserProfileResponse = (message, responseType, data) => {
    return {
        type: UPDATE_USER_PROFILE_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const passwordResendUserByUserIdRequest = (userId) => {
    return {
        type: PASSWORD_RESEND_USER_BY_USER_ID_REQUEST,
        payload: { userId },
    };
};
export const passwordResendUserByUserIdResponse = (message, responseType) => {
    return {
        type: PASSWORD_RESEND_USER_BY_USER_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const setNewPasswordOfLoginUserRequest = (data) => {
    return {
        type: SET_NEW_PASSWORD_OF_LOGIN_USER_REQUEST,
        payload: { data },
    };
};
export const setNewPasswordOfLoginUserResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: SET_NEW_PASSWORD_OF_LOGIN_USER_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getUsersByEntityRequest = (entityId) => {
    return {
        type: GET_USERS_BY_ENTITY_REQUEST,
        payload: { entityId },
    };
};
export const getUsersByEntityResponse = (userList) => {
    return {
        type: GET_USERS_BY_ENTITY_RESPONSE,
        payload: { userList },
    };
};

export const getUsersByCompanyGroupRequest = (companyGroupId) => {
    return {
        type: GET_USERS_BY_COMPANYGROUP_REQUEST,
        payload: { companyGroupId },
    };
};
export const getUsersByCompanyGroupResponse = (userList) => {
    return {
        type: GET_USERS_BY_COMPANYGROUP_RESPONSE,
        payload: { userList },
    };
};

export const filterUserRequest = (data) => {
    return {
        type: FILTER_USER_REQUEST,
        payload: { data },
    };
};

export const filterUserResponse = (userList) => {
    return {
        type: FILTER_USER_RESPONSE,
        payload: { userList },
    };
};

export const cleareFilterUserResponse = () => {
    return {
        type: CLEARE_FILTER_USER_RESPONSE
    };
};

export const updateApplicationThemeRequest = (data) => {
    return {
        type: UPDATE_APPLICATION_THEME_REQUEST,
        payload: { data },
    };
};
export const updateApplicationThemeResponse = (message, responseType, data) => {
    return {
        type: UPDATE_APPLICATION_THEME_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const unblockEmailRequest = (data) => {
    return {
        type: UNBLOCK_EMAIL_REQUEST,
        payload: { data },
    };
};
export const unblockEmailResponse = (message, responseType) => {
    return {
        type: UNBLOCK_EMAIL_RESPONSE,
        payload: { message, responseType },
    };
};
export const getUserRoleListRequest = (userId) => {
    return {
        type: GET_USER_ROLES_BY_USER_ID_REQUEST,
        payload: { userId },
    };
};
export const getUserRoleListResponse = (userRoleList) => {
    return {
        type: GET_USER_ROLES_BY_USER_ID_RESPONSE,
        payload: { userRoleList },
    };
};