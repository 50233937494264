import React, { Component } from 'react';
import { Row, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, CLEAR, SEARCH, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getEntityListByCountryRequest,
    getEventListBySearchRequest
} from 'store/actions';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import __t from 'i18n/translator';
import { selectSortedEventList } from 'store/Masters/Event/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });
class ExecutedEventFilterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            operationType: SUBMIT,
        };
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest((countryIds) ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest((stateIds) ? stateIds : []);
    }
    getEventsBySearch(values) {
        this.props.getEventListBySearchRequest(values)
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.props.onFormReset();
    }

    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.filterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={this.props.sortedCountryListDropdown}
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={opt => {
                                            let countryIds = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'countryId',
                                                countryIds
                                            );
                                            this.props.getEntityListByCountryRequest(countryIds);
                                            this.getStateListByCountry(countryIds);
                                            this.getEventsBySearch({ ...values, countryId: countryIds })
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            option =>
                                                values.countryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.events.country')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedStateListDropdown
                                        }
                                        onChange={opt => {
                                            let stateIds = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'stateId',
                                                stateIds
                                            );
                                            setFieldValue(
                                                'cityId',
                                                []
                                            );
                                            this.getCityListByState(stateIds);
                                            this.getEventsBySearch({ ...values, stateId: stateIds })
                                        }}
                                        value={this.props.sortedStateListDropdown?.filter(
                                            option =>
                                                values.stateId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                        key="stateId"
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.events.state')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCityListDropdown
                                        }
                                        onChange={opt => {
                                            let cityIds = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'cityId',
                                                cityIds
                                            );
                                            this.getEventsBySearch({ ...values, stateId: cityIds })
                                        }}
                                        value={this.props.sortedCityListDropdown?.filter(
                                            option => values.cityId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                        key="cityId"
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.events.city')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="entityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropdown
                                        }
                                        onChange={opt => {
                                            let entityIds = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'entityId',
                                                entityIds
                                            );
                                            this.getEventsBySearch({ ...values, entityId: entityIds })
                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            option =>
                                                values.entityId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.events.entity')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="eventId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEventListDropdown
                                        }
                                        onChange={opt => {
                                            let eventIds = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'eventId',
                                                eventIds
                                            );
                                        }}
                                        value={this.props.sortedEventListDropdown?.filter(
                                            option =>
                                                values.eventId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.events.event')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-12 d-flex justify-content-end">
                                    <div className="d-inline-block">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="mr-1"
                                        >
                                            {SEARCH}
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >{CLEAR}</Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedEventListDropdown: selectSortedEventList(),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest, 
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getEntityListByCountryRequest,
    getEventListBySearchRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withEntityReducer,
    withEntitySaga,
    withEventReducer,
    withEventSaga,
    withRouter
)(ExecutedEventFilterForm);