import invariant from 'invariant';
import { isEmpty, isFunction, isString } from 'lodash';
import checkStore from './checkStore';
import createReducer from '../store/rootReducer';
export function injectReducerFactory(store, isValid) {
    return function injectReducer(key, reducer) {
        if (!isValid) checkStore(store);
        invariant(
            isString(key) && !isEmpty(key) && isFunction(reducer),
            '(app/utils...) injectReducer: Expected `reducer` to be a reducer function'
        );
        // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different
        // i.e. no need to inject reducer again if the key and reducer is already present in the store
        if (
            Reflect.has(store.injectedReducers, key) &&
            store.injectedReducers[key] === reducer
        )
            return;
        //this is where new reducer is included based on key
        store.injectedReducers[key] = reducer; // eslint-disable-line no-param-reassign
        store.replaceReducer(createReducer(store.injectedReducers));
    };
}
export default function getInjectors(store) {
    checkStore(store);
    return {
        injectReducer: injectReducerFactory(store, true),
    };
}
