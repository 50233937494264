import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Progress,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    DATE_FORMAT_DATEPICKER,
    SUBMIT,
    SUCCESS,
    DANGER,
    UPDATE,
    ADD_SUB_TASK,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import { initialTaskState, initialSubTaskState } from './CommonFunctions';
import {
    customDateValidator,
    getDateObjectWithValueFromMoment,
    formatDateForApi,
    getDateTimeFromDateTimeString,
    getAddDaysDate,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { projectStatusList, taskPriorityStatusList } from 'constants/dropdowns';
import {
    addProjectTaskRequest,
    updateProjectTaskRequest,
    deleteProjectSubTaskBySubTaskIdRequest,
    getProjectTaskDetailsRequest,
    addProjectTaskAttachmentRequest,
    getProjectTaskActivityRequest,
    deleteProjectTaskDocumentByDocumentIdRequest,
} from 'store/actions';
import { customProjectTaskFileValidator } from 'helpers/customValidators';
import ContentEditable from 'react-contenteditable';
import { toastrMessage, commonConfirmBox } from 'helpers/messageHelper';
import { makeProjectsSelectField } from 'store/Masters/Projects/selector';
import SubTaskForm from './SubTaskForm';
import TaskCommentForm from './TaskCommentForm';
import { getInitials } from 'helpers/generalUtils';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import { PROJECT_ACTIVITY_TYPE_COMMENT } from 'constants/databaseConstants';
import FileSaver from 'file-saver';
import parse from 'html-react-parser';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
class AddEditProjectTaskModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customProjectTaskFileValidator()),
        });
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.validator2 = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            downloadFileUrl: '',
downloadFileName: '',
            viewOnlyAccess: false,
            taskFormData: initialTaskState(),
            subTaskFormData: initialSubTaskState(),
            operationType: SUBMIT,
            subTaskForm: false,
            projectTaskAttchments: [],
            taskActivityList: [],
            isPriority: false,
            isModalDismiss: true,
            projectTaskUsers: [],
            userId: '',
            activeTab: 'commentTab',
            actionId: '',
            subTask: false,
            subTaskToggleUserId: '',
            subTaskId: '',
            fileProgress: 100,
            isFileProgress: false,
            parentProjectTaskUsers: [],
            isStatus: false,
            selectedFiles: [],
            isSubTaskCompleted: false,
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
        };
        this.contentEditable = React.createRef();
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }
    handleChange = (evt, subTask) => {
        this.setState({ isModalDismiss: false });
        if (evt.target.innerHTML !== '') {
            let formValues = {
                projectId: this.props.projectDetails.id,
                taskTitle: evt.target.innerHTML,
            };
            this.props.updateProjectTaskRequest(subTask.id, formValues);
        }
    };
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = async (values) => {
        if (this.validator.allValid()) {
            let response = null;
            if (
                this.state.isSubTaskCompleted === true &&
                this.state.operationType == UPDATE &&
                values.projectStatusId == 3
            ) {
                response = await commonConfirmBox(
                    __t('common.are_you_sure'),
                    __t('alerts.sub_task_close')
                );
            }
            if (response != null && response != true) {
                return;
            }
            let formValues = {
                ...values,
                startDate: formatDateForApi(values.startDate),
                dueDate: values.dueDate
                    ? formatDateForApi(values.dueDate)
                    : null,
                projectId: this.props.projectDetails.id,
                entityId: this.props.selectedEntityId,
            };
            if (this.state.operationType === UPDATE) {
                delete formValues.id;
                this.props.updateProjectTaskRequest(values.id, formValues);
            } else {
                let formData = new FormData();
                formData.append('dueDate', formValues.dueDate);
                formData.append('entityId', formValues.entityId);
                formData.append('priority', formValues.priority);
                formData.append('projectId', formValues.projectId);
                formData.append('projectStatusId', formValues.projectStatusId);
                formData.append('startDate', formValues.startDate);
                formData.append('taskDescription', formValues.taskDescription);
                formData.append('taskTitle', formValues.taskTitle);
                formValues.userId.forEach((userId) => {
                    formData.append('userId[]', userId);
                });
                this.state.selectedFiles.forEach((file) => {
                    formData.append('file', file);
                });
                this.props.addProjectTaskRequest(formData);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    getProjectTaskDetailsByTaskId(taskId) {
        this.props.getProjectTaskDetailsRequest(taskId);
    }
    getProjectTaskActivityByTaskId(taskId) {
        this.props.getProjectTaskActivityRequest(taskId);
    }
    onChangeDateInput = (selectedDate, subTask, type) => {
        let formValues = '';
        this.setState({ isModalDismiss: false });
        if (type == 'startDate') {
            formValues = {
                projectId: this.props.projectDetails.id,
                startDate: formatDateForApi(selectedDate),
                taskTitle: subTask.taskTitle,
            };
        } else {
            formValues = {
                projectId: this.props.projectDetails.id,
                dueDate: formatDateForApi(selectedDate),
                taskTitle: subTask.taskTitle,
            };
        }
        this.props.updateProjectTaskRequest(subTask.id, formValues);
    };
    onChangeUser = (userId, subTask) => {
        this.setState({ isModalDismiss: false });
        let formValues = {
            projectId: this.props.projectDetails.id,
            userId: [userId],
            taskTitle: subTask.taskTitle,
        };
        this.props.updateProjectTaskRequest(subTask.id, formValues);
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.projectTaskApiResponse &&
            this.props.projectTaskApiResponse !==
            nextprops.projectTaskApiResponse
        ) {
            toastrMessage(
                nextprops.projectTaskApiResponse.message,
                nextprops.projectTaskApiResponse.responseType
            );
            if (
                this.state.isModalDismiss === true &&
                this.state.subTask === false
            ) {
                this.onModalDismiss();
            } else {
                this.setState(
                    {
                        isModalDismiss: true,
                    },
                    () => {
                        if (this.state.subTask == true) {
                            this.getProjectTaskDetailsByTaskId(
                                this.state.subTaskId
                            );
                            this.getProjectTaskActivityByTaskId(
                                this.state.subTaskId
                            );
                        } else {
                            this.getProjectTaskDetailsByTaskId(
                                nextprops.taskId
                            );
                            this.getProjectTaskActivityByTaskId(
                                nextprops.taskId
                            );
                        }
                    }
                );
            }
        }
        if (
            nextprops.projectSubTaskApiResponse &&
            this.props.projectSubTaskApiResponse !==
            nextprops.projectSubTaskApiResponse
        ) {
            toastrMessage(
                nextprops.projectSubTaskApiResponse.message,
                nextprops.projectSubTaskApiResponse.responseType
            );
            if (this.props.taskId) {
                this.getProjectTaskDetailsByTaskId(this.props.taskId);
            }
            this.closeSubTaskModal();
        }
        if (nextprops.taskId && this.props.taskId !== nextprops.taskId) {
            this.getProjectTaskDetailsByTaskId(nextprops.taskId);
            this.getProjectTaskActivityByTaskId(nextprops.taskId);
            if (nextprops.addSubtask === true) {
                this.addSubTask();
            }
        }
        if (
            nextprops.projectTaskDetailsApiResponse &&
            this.props.projectTaskDetailsApiResponse !==
            nextprops.projectTaskDetailsApiResponse
        ) {
            this.setState({
                projectTaskAttchments:
                    nextprops.projectTaskDetailsApiResponse
                        .projectTaskAttchments,
            });
            if (nextprops.projectTaskDetailsApiResponse.id) {
                let data = nextprops.projectTaskDetailsApiResponse;
                let isSubTaskCompleted = false;
                data.projectSubTasks.map((x) => {
                    if (x.projectStatusId != 3) {
                        isSubTaskCompleted = true;
                    }
                });
                let projectOwners = data?.project?.projectTeams.filter(
                    (teamUser) =>
                        teamUser.userId === this.state.loginUserId &&
                        teamUser.isOwner
                );
                let viewOnlyAccess = false;
                if (projectOwners.length == 0) {
                    if (this.props.taskType == 'task') {
                        viewOnlyAccess = true;
                    }
                    if (data.parentProjectTaskId) {
                        let tuserCheck = data?.parentProjectTask?.projectTaskUsers.filter((pu) => pu.user.id == this.state.loginUserId);
                        if (tuserCheck.length == 0) {
                            viewOnlyAccess = true;
                        } else {
                            viewOnlyAccess = false;
                        }
                    }
                }
                this.setState(
                    {
                        viewOnlyAccess: viewOnlyAccess,
                        taskFormData: {
                            projectId:
                                this.props.taskType == 'task'
                                    ? data?.project.id
                                    : data?.project?.id,
                            taskTitle: data?.taskTitle,
                            taskDescription: data?.taskDescription,
                            startDate: data?.startDate
                                ? getDateObjectWithValueFromMoment(
                                    data?.startDate
                                )
                                : null,
                            dueDate: data?.dueDate
                                ? getDateObjectWithValueFromMoment(
                                    data?.dueDate
                                )
                                : null,
                            entityId: data.entity.id,
                            userId: data.projectTaskUsers.map(
                                (opt) => opt.user.id
                            ),
                            projectStatusId: data?.projectStatus?.id,
                            priority: data?.priority,
                            id: data.id,
                            projectSubTasks: data?.projectSubTasks,
                            numberOfDays: data?.numberOfDays,
                        },
                        projectTaskUsers:
                            this.props.taskType == 'task' &&
                                this.state.subTask === false
                                ? this.props.projectDetails?.projectTeams
                                : data?.parentProjectTask?.projectTaskUsers !=
                                    undefined
                                    ? data?.parentProjectTask?.projectTaskUsers
                                    : [],
                        parentProjectTaskUsers:
                            data?.projectTaskUsers.length > 0
                                ? data?.projectTaskUsers?.map(this.mapToLabelValuePair)
                                : [],
                        isSubTaskCompleted: isSubTaskCompleted,
                    },
                    () => {
                        this.validator.purgeFields();
                    }
                );
            }
        }
        if (
            nextprops.projectTaskAttachmentApiResponse &&
            this.props.projectTaskAttachmentApiResponse !==
            nextprops.projectTaskAttachmentApiResponse
        ) {
            toastrMessage(
                nextprops.projectTaskAttachmentApiResponse.message,
                nextprops.projectTaskAttachmentApiResponse.responseType
            );
            if (
                nextprops.projectTaskAttachmentApiResponse.responseType ===
                DANGER
            ) {
                this.setState({ isFileProgress: false });
            }
            this.setState({ fileProgress: 0 }, () => {
                this.setState({ isFileProgress: false }, () => {
                    if (
                        this.state.subTask == true &&
                        this.state.subTaskId != ''
                    ) {
                        this.getProjectTaskDetailsByTaskId(
                            this.state.subTaskId
                        );
                        this.getProjectTaskActivityByTaskId(
                            this.state.subTaskId
                        );
                    } else {
                        this.getProjectTaskDetailsByTaskId(this.props.taskId);
                        this.getProjectTaskActivityByTaskId(this.props.taskId);
                    }
                });
            });
        }
        if (
            nextprops.projectTaskActivityApiResponse &&
            this.props.projectTaskActivityApiResponse !==
            nextprops.projectTaskActivityApiResponse
        ) {
            let userId = localStorage.getItem('userId');
            this.setState({
                taskActivityList: nextprops.projectTaskActivityApiResponse,
                userId: userId ? userId : '',
            });
        }
        if (
            nextprops.operationType &&
            this.state.operationType !== nextprops.operationType
        ) {
            this.setState({
                operationType: nextprops.operationType,
            });
        }
        if (
            nextprops.projectTaskDocumentApiResponse &&
            this.props.projectTaskDocumentApiResponse !==
            nextprops.projectTaskDocumentApiResponse
        ) {
            toastrMessage(
                nextprops.projectTaskDocumentApiResponse.message,
                nextprops.projectTaskDocumentApiResponse.responseType
            );
            if (
                nextprops.projectTaskDocumentApiResponse
                    .responseType === SUCCESS
            ) {
                if (this.state.subTask == true && this.state.subTaskId != '') {
                    this.getProjectTaskDetailsByTaskId(this.state.subTaskId);
                    this.getProjectTaskActivityByTaskId(this.state.subTaskId);
                } else {
                    this.getProjectTaskDetailsByTaskId(this.props.taskId);
                    this.getProjectTaskActivityByTaskId(this.props.taskId);
                }
            }
        }
        if (
            nextprops.activeTab &&
            this.props.activeTab !== nextprops.activeTab
        ) {
            this.setState({
                activeTab: nextprops.activeTab,
            });
        }
    }
    addNumberOfDay(days, startDay, setFieldValue) {
        if (startDay && days > 0) {
            let dueDate = getAddDaysDate(days, startDay);
            if (dueDate) {
                if (days > 0) {
                    setFieldValue('dueDate', dueDate);
                }
            }
        } else if (days > 0) {
            setFieldValue('numberOfDays', days);
        } else {
            setFieldValue('numberOfDays', '');
        }
    }
    onModalDismiss = () => {
        this.validator.hideMessages();
        this.validator2.hideMessages();
        this.setState({
            taskFormData: initialTaskState(),
            subTaskFormData: initialSubTaskState(),
            operationType: SUBMIT,
            subTaskForm: false,
            selectedFiles: [],
            projectTaskAttchments: [],
            taskActivityList: [],
            isPriority: false,
            isModalDismiss: true,
            projectTaskUsers: [],
            subTask: false,
        });
        this.closeSubTaskModal();
        this.props.onModalDismiss();
    };
    addSubTask() {
        this.setState({
            subTaskFormData: initialSubTaskState(),
            subTaskForm: true,
        });
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    deleteSubTask = async (subTaskId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.task_delete')
        );
        if (response) {
            this.props.deleteProjectSubTaskBySubTaskIdRequest(subTaskId);
        }
    };
    deleteTaskDocument = async (subTaskId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.document_delete')
        );
        if (response) {
            this.props.deleteProjectTaskDocumentByDocumentIdRequest(
                subTaskId
            );
        }
    };
    closeSubTaskModal() {
        this.setState({
            subTaskFormData: initialSubTaskState(),
            subTaskForm: false,
        });
    }
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    priorityColor(priority) {
        let priorityColor = '';
        switch (priority) {
            case 'Urgent':
                priorityColor = '#007bff';
                break;
            case 'High':
                priorityColor = '#dc3545';
                break;
            case 'Normal':
                priorityColor = '#28a745';
                break;
            case 'Low':
                priorityColor = '#ffc107';
                break;
            default:
                priorityColor = '#6c757d';
                break;
        }
        return priorityColor;
    }
    priorityToggle(value) {
        this.setState({
            isPriority: this.state.isPriority === value ? 0 : value,
        });
    }
    statusToggle(value) {
        this.setState({
            isStatus: this.state.isStatus === value ? 0 : value,
        });
    }
    subTaskUsersToggle(value) {
        this.setState({
            subTaskToggleUserId:
                this.state.subTaskToggleUserId === value ? 0 : value,
        });
    }
    selectSortedUserFilterArray(selectedUserId, values) {
        if (this.state.subTask === true) {
            return [selectedUserId];
        } else {
            const index = values.findIndex((item) => item === selectedUserId);
            const newArray = values;
            if (index !== -1) {
                newArray.splice(index, 1);
            } else {
                newArray.push(selectedUserId);
            }
            return newArray;
        }
    }
    selectedUserDisplayName(usersList, value) {
        let userName = usersList.filter((user) => user.value === value)[0]
            ?.label;
        if (userName) {
            return userName;
        } else {
            return '';
        }
    }
    taskUsersToggle(value) {
        this.setState({
            taskUserToggle: this.state.taskUserToggle === value ? false : true,
        });
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => {
                    if (this.props.taskId) {
                        this.getProjectTaskDetailsByTaskId(this.props.taskId);
                    }
                }
            );
        }
    }
    actionToggle(taskId) {
        this.setState({
            actionId: this.state.actionId === taskId ? 0 : taskId,
        });
    }
    editSubTask(subTaskId) {
        this.setState({
            subTaskId: subTaskId,
            subTask: true,
        });
        this.getProjectTaskDetailsByTaskId(subTaskId);
        this.getProjectTaskActivityByTaskId(subTaskId);
    }
    statusColor(status) {
        let statusColor = '';
        switch (status) {
            case 1:
                statusColor = 'todo';
                break;
            case 2:
                statusColor = 'inprogress';
                break;
            case 3:
                statusColor = 'completed';
                break;
            default:
                statusColor = 'todo';
                break;
        }
        return statusColor;
    }
    handleFileUpload = (files) => {
        this.setState({ isFileProgress: true }, () => {
            this.setState({ fileProgress: 80 });
        });
        const chosenFiles = Array.prototype.slice.call(files);
        let errorsArray = [];
        chosenFiles.map((file) => {
            if (!customProjectTaskFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.chat_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            toastrMessage(__t('validations.custom.proof_file'), DANGER);
            this.setState({ fileProgress: 0 }, () => {
                this.setState({ isFileProgress: false });
            });
            return false;
        }
        if (chosenFiles) {
            let formData = new FormData();
            chosenFiles.map((x) => formData.append('file', x));
            formData.append('projectTaskId', this.state.taskFormData.id);
            chosenFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: this.formatBytes(file.size),
                })
            );
            if (this.state.operationType === UPDATE) {
                this.setState({ fileProgress: 30 });
                this.props.addProjectTaskAttachmentRequest(formData);
            } else {
                this.setState(
                    {
                        fileProgress: 0,
                        selectedFiles: [
                            ...this.state.selectedFiles,
                            ...chosenFiles,
                        ],
                    },
                    () => {
                        this.setState({ isFileProgress: false });
                    }
                );
            }
        }
    };
    removeFile = async (file) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.task_delete')
        );
        if (response) {
            var array = [...this.state.selectedFiles]; // make a separate copy of the array
            var index = array.indexOf(file);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ selectedFiles: array });
            }
        }
    };
    mapToLabelValuePair = (item) => ({
        label: item?.user?.fullName,
        value: item?.userId,
    });
    render() {
        let projectTaskUsersList =
            this.state.operationType === SUBMIT
                ? this.props.projectDetails?.projectTeams
                : this.state.projectTaskUsers;
        let projectUsers = projectTaskUsersList?.map(this.mapToLabelValuePair);
        let userName = '';
        let projectStatus = '';
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={
                        'modal-xxl modal-dialog-centered modal-dialog-scrollable side_slider project_task_modal add_project_detail_task'
                    }
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.onModalDismiss()}
                >
                    <div className="add-project-task-detail-box">
                        <Formik
                            initialValues={this.state.taskFormData}
                            enableReinitialize={true}
                            onSubmit={this.formSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <div className="d-flex w-100 add-task-header-modual">
                                        <div className="d-flex add-task-title-box">
                                            <a
                                                href={() => false}
                                                title="Back"
                                                className="back-btn"
                                                onClick={() => {
                                                    this.onModalDismiss();
                                                    this.validator.purgeFields();
                                                }}
                                            >
                                                <svg
                                                    width="28"
                                                    height="19"
                                                    viewBox="0 0 28 19"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M16.4 1L26 9.5M26 9.5L16.4 18M26 9.5H1"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </a>
                                            <h6>
                                                {this.state.subTask ===
                                                    false ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.props
                                                                .modalName,
                                                        }}
                                                    ></div>
                                                ) : (
                                                    <span id="edit_sub_task">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    this.props
                                                                        .projectEntityName +
                                                                    __t(
                                                                        'form_labels.project_task.edit_sub_task'
                                                                    ),
                                                            }}
                                                        ></div>
                                                    </span>
                                                )}
                                            </h6>
                                            <div className="d-flex">
                                                <div className="assigne-group-box">
                                                    {values?.userId?.map(
                                                        (x) => (
                                                            <>
                                                                <div className="d-none">
                                                                    {
                                                                        (userName =
                                                                            this.selectedUserDisplayName(
                                                                                projectUsers,
                                                                                x
                                                                            ))
                                                                    }
                                                                </div>
                                                                <span
                                                                    className="assigne-box green-round"
                                                                    title={__t(
                                                                        userName
                                                                    )}
                                                                >
                                                                    {getInitials(
                                                                        userName
                                                                    )}
                                                                </span>
                                                            </>
                                                        )
                                                    )}
                                                    <Dropdown
                                                        isOpen={
                                                            this.state
                                                                .taskUserToggle ===
                                                            true
                                                        }
                                                        toggle={() => {
                                                            if (this.state.viewOnlyAccess) {
                                                                toastrMessage(__t('alerts.project_permission_denied'), DANGER);
                                                                return false;
                                                            }
                                                            this.taskUsersToggle(true)
                                                        }
                                                        }
                                                        className="notification-list list-inline-item mr-1"
                                                        tag="li"
                                                    >
                                                        <DropdownToggle
                                                            className="arrow-none waves-effect"
                                                            tag="a"
                                                        >
                                                            <a
                                                                href={() => false}
                                                                title="Assign"
                                                                className="assigne-box assign-dropdown"
                                                            >
                                                                <svg
                                                                    width="19"
                                                                    height="20"
                                                                    viewBox="0 0 19 20"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <circle
                                                                        cx="8.22754"
                                                                        cy="5"
                                                                        r="4"
                                                                        stroke="currentColor"
                                                                        stroke-opacity="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                        fill="currentColor"
                                                                        fillOpacity="0.5"
                                                                    />
                                                                    <path
                                                                        d="M14.2275 11L14.2275 19"
                                                                        stroke="currentColor"
                                                                        stroke-opacity="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M18.2275 15L10.2275 15"
                                                                        stroke="currentColor"
                                                                        stroke-opacity="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu small_scroll"
                                                            right
                                                        >
                                                            {projectUsers?.map(
                                                                (users) => (
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        className="priority-dropdown"
                                                                        active={
                                                                            values.userId.indexOf(
                                                                                users.value
                                                                            ) >
                                                                            -1
                                                                        }
                                                                        onClick={() => {
                                                                            setFieldValue(
                                                                                'userId',
                                                                                this.selectSortedUserFilterArray(
                                                                                    users.value,
                                                                                    values.userId
                                                                                )
                                                                            );
                                                                        }}
                                                                    >
                                                                        {' ' +
                                                                            users.label}
                                                                    </DropdownItem>
                                                                )
                                                            )}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                                <Dropdown
                                                    title="Priority"
                                                    isOpen={
                                                        this.state
                                                            .isPriority === true
                                                    }
                                                    toggle={() => {
                                                        if (this.state.viewOnlyAccess) {
                                                            toastrMessage(__t('alerts.project_permission_denied'), DANGER);
                                                            return false;
                                                        }
                                                        this.priorityToggle(true)
                                                    }
                                                    }
                                                    className="notification-list list-inline-item mr-1"
                                                    tag="li"
                                                >
                                                    <DropdownToggle
                                                        className="nav-link arrow-none waves-effect"
                                                        tag="a"
                                                    >
                                                        <svg
                                                            color={this.priorityColor(
                                                                values.priority
                                                            )}
                                                            width="16"
                                                            height="20"
                                                            viewBox="0 0 16 20"
                                                            fill={this.priorityColor(
                                                                values.priority
                                                            )}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                stroke="currentColor"
                                                                stroke-opacity="0.5"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </DropdownToggle>
                                                    <DropdownMenu
                                                        className="dropdown-menu"
                                                        right
                                                    >
                                                        {taskPriorityStatusList.map(
                                                            (priority) => (
                                                                <DropdownItem
                                                                    tag="a"
                                                                    className="priority-dropdown"
                                                                    active={
                                                                        priority.value ===
                                                                        values.priority
                                                                    }
                                                                    onClick={() =>
                                                                        setFieldValue(
                                                                            'priority',
                                                                            priority.value
                                                                        )
                                                                    }
                                                                >
                                                                    <svg
                                                                        color={this.priorityColor(
                                                                            priority.value
                                                                        )}
                                                                        width="16"
                                                                        height="20"
                                                                        viewBox="0 0 16 20"
                                                                        fill="currentColor"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                            stroke="currentColor"
                                                                            stroke-opacity="0.5"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    </svg>
                                                                    {' ' +
                                                                        priority.label}
                                                                </DropdownItem>
                                                            )
                                                        )}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="d-flex submit-back-btn">
                                            <Dropdown
                                                title="Status"
                                                isOpen={
                                                    this.state.isStatus === true
                                                }
                                                toggle={() =>
                                                    this.statusToggle(true)
                                                }
                                                className="notification-list list-inline-item mr-1"
                                                tag="li"
                                            >
                                                <DropdownToggle
                                                    className={
                                                        'nav-link arrow-none waves-effect ' +
                                                        this.statusColor(
                                                            projectStatusList?.filter(
                                                                (x) =>
                                                                    x.value ===
                                                                    values.projectStatusId
                                                            )?.[0]?.value
                                                        )
                                                    }
                                                    tag="a"
                                                >
                                                    <span className="project-list-status-box"></span>
                                                    {
                                                        (projectStatus =
                                                            projectStatusList?.filter(
                                                                (x) =>
                                                                    x.value ===
                                                                    values.projectStatusId
                                                            )?.[0]?.label)
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    className="dropdown-menu"
                                                    right
                                                >
                                                    {projectStatusList.map(
                                                        (status) => (
                                                            <DropdownItem
                                                                tag="a"
                                                                className={
                                                                    'priority-dropdown ' +
                                                                    this.statusColor(
                                                                        status.value
                                                                    )
                                                                }
                                                                active={
                                                                    status.value ===
                                                                    values.projectStatusId
                                                                }
                                                                onClick={() =>
                                                                    setFieldValue(
                                                                        'projectStatusId',
                                                                        status.value
                                                                    )
                                                                }
                                                            >
                                                                <span className="project-list-status-box"></span>
                                                                {' ' +
                                                                    status.label}
                                                            </DropdownItem>
                                                        )
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                            {this.state.taskFormData?.projectStatusId !== 3 && (
                                                <Button
                                                    type="submit"
                                                    className="btn btn-primary submit-btn"
                                                    title="Submit"
                                                >
                                                    {SUBMIT}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="add-task-content-body">
                                        <Row className="m-0 w-100">
                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    disabled={this.state.viewOnlyAccess}
                                                    name="taskTitle"
                                                    className="form-control task-title-form-control"
                                                    value={values.taskTitle}
                                                    placeholder={__t(
                                                        'form_labels.project_task.title'
                                                    )}
                                                />
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.project_task.title'
                                                    ),
                                                    values.taskTitle,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.project_task.title_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-12 date-box">
                                                <Row>
                                                    <Col>
                                                        <div>
                                                            <Label>
                                                                {__t(
                                                                    'form_labels.project_task.number_of_day'
                                                                )}
                                                            </Label>
                                                            <Field
                                                                name="numberOfDays"
                                                                className="form-control"
                                                                type="number"
                                                                placeholder={__t(
                                                                    'form_labels.project_task.number_of_day'
                                                                )}
                                                                disabled={this.state.viewOnlyAccess}
                                                                onBlur={(e) => {
                                                                    this.validator.purgeFields();
                                                                    this.addNumberOfDay(
                                                                        e.target
                                                                            .value,
                                                                        values.startDate,
                                                                        setFieldValue
                                                                    );
                                                                }}
                                                                value={
                                                                    values.numberOfDays
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Label>
                                                            {__t(
                                                                'form_labels.project_task.start_date'
                                                            )}
                                                        </Label>
                                                        <Field
                                                            component={
                                                                DatePicker
                                                            }
                                                            className="form-control"
                                                            name="startDate"
                                                            selected={
                                                                values.startDate
                                                                    ? getDateObjectWithValueFromMoment(
                                                                        values.startDate
                                                                    )
                                                                    : null
                                                            }
                                                            dateFormat={
                                                                DATE_FORMAT_DATEPICKER
                                                            }
                                                            disabled={this.state.viewOnlyAccess}
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                setFieldValue(
                                                                    'startDate',
                                                                    date
                                                                );
                                                                let dateCheck =
                                                                    getDateObjectWithValueFromMoment(
                                                                        values.dueDate
                                                                    ) <
                                                                        getDateObjectWithValueFromMoment(
                                                                            date
                                                                        )
                                                                        ? true
                                                                        : false;
                                                                if (dateCheck) {
                                                                    setFieldValue(
                                                                        'dueDate',
                                                                        ''
                                                                    );
                                                                }
                                                                this.addNumberOfDay(
                                                                    values.numberOfDays,
                                                                    date,
                                                                    setFieldValue
                                                                );
                                                            }}
                                                            showMonthDropdown={
                                                                true
                                                            }
                                                            showYearDropdown={
                                                                true
                                                            }
                                                            dropdownMode="select"
                                                            placeholderText="DD/MM/YYYY"
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                        //  maxDate={new Date()}
                                                        ></Field>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.project_task.start_date'
                                                            ),
                                                            values.startDate,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.project_task.start_date_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <Label>
                                                            {__t(
                                                                'form_labels.project_task.due_date'
                                                            )}
                                                        </Label>
                                                        <Field
                                                            component={
                                                                DatePicker
                                                            }
                                                            className="form-control"
                                                            name="dueDate"
                                                            selected={
                                                                values.dueDate
                                                                    ? getDateObjectWithValueFromMoment(
                                                                        values.dueDate
                                                                    )
                                                                    : null
                                                            }
                                                            dateFormat={
                                                                DATE_FORMAT_DATEPICKER
                                                            }
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                setFieldValue(
                                                                    'dueDate',
                                                                    date
                                                                );
                                                            }}
                                                            showMonthDropdown={
                                                                true
                                                            }
                                                            showYearDropdown={
                                                                true
                                                            }
                                                            dropdownMode="select"
                                                            placeholderText="DD/MM/YYYY"
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                            minDate={getDateObjectWithValueFromMoment(
                                                                values?.startDate
                                                            )}
                                                            disabled={
                                                                (this.state.viewOnlyAccess || (values.numberOfDays >
                                                                    0
                                                                    ? true
                                                                    : false))
                                                            }
                                                        ></Field>
                                                        {!values.numberOfDays
                                                            ? this.validator.message(
                                                                __t(
                                                                    'form_labels.project_task.due_date'
                                                                ),
                                                                values.dueDate,
                                                                'required',
                                                                {
                                                                    messages:
                                                                    {
                                                                        required:
                                                                            __t(
                                                                                'validations.project_task.due_date_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )
                                                            : ''}
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup className="col-lg-12 h-100">
                                                <Field
                                                    type="text"
                                                    component="textarea"
                                                    name="taskDescription"
                                                    rows="2"
                                                    className="form-control"
                                                    placeholder={__t(
                                                        'form_labels.project_task.description'
                                                    )}
                                                    maxLength={250}
                                                    disabled={this.state.viewOnlyAccess}
                                                />
                                            </FormGroup>
                                        </Row>
                                        {this.props.taskType === 'task' &&
                                            this.state.operationType ===
                                            UPDATE &&
                                            this.state.subTask === false && (
                                                <div className="add-sub-task-box m-3">
                                                    <React.Fragment>
                                                        <div className="add-sub-task-header d-flex">
                                                            <h4>
                                                                SubTask List
                                                            </h4>
                                                        </div>
                                                        <ul>
                                                            {this.state.taskFormData?.projectSubTasks?.map(
                                                                (
                                                                    subTask,
                                                                    key
                                                                ) => (
                                                                    <React.Fragment>
                                                                        <li>
                                                                            <div className="d-flex">
                                                                                <span className="project-list-status-box"></span>
                                                                                <ContentEditable
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    html={
                                                                                        subTask?.taskTitle
                                                                                    }
                                                                                    disabled={
                                                                                        false
                                                                                    }
                                                                                    onBlur={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.handleChange(
                                                                                            e,
                                                                                            subTask
                                                                                        )
                                                                                    }
                                                                                    tagName="article"
                                                                                />
                                                                            </div>
                                                                            <div className="add-sub-task-list-box">
                                                                                <div
                                                                                    className={
                                                                                        subTask?.startDate !=
                                                                                            ''
                                                                                            ? 'projectdetail-date-picker'
                                                                                            : 'projectdetail-date-icon'
                                                                                    }
                                                                                >
                                                                                    <DatePicker
                                                                                        selected={
                                                                                            subTask?.startDate
                                                                                                ? getDateObjectWithValueFromMoment(
                                                                                                    subTask?.startDate
                                                                                                )
                                                                                                : null
                                                                                        }
                                                                                        dateFormat={
                                                                                            DATE_FORMAT_DATEPICKER
                                                                                        }
                                                                                        onChange={(
                                                                                            date
                                                                                        ) =>
                                                                                            this.onChangeDateInput(
                                                                                                date,
                                                                                                subTask,
                                                                                                'startDate'
                                                                                            )
                                                                                        }
                                                                                        onFocus={(
                                                                                            e
                                                                                        ) =>
                                                                                            e.target.blur()
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className={
                                                                                        subTask?.dueDate !=
                                                                                            ''
                                                                                            ? 'projectdetail-date-picker'
                                                                                            : 'projectdetail-date-icon'
                                                                                    }
                                                                                >
                                                                                    <DatePicker
                                                                                        selected={
                                                                                            subTask?.dueDate
                                                                                                ? getDateObjectWithValueFromMoment(
                                                                                                    subTask?.dueDate
                                                                                                )
                                                                                                : null
                                                                                        }
                                                                                        dateFormat={
                                                                                            DATE_FORMAT_DATEPICKER
                                                                                        }
                                                                                        onChange={(
                                                                                            date
                                                                                        ) =>
                                                                                            this.onChangeDateInput(
                                                                                                date,
                                                                                                subTask
                                                                                            )
                                                                                        }
                                                                                        minDate={
                                                                                            subTask?.startDate
                                                                                                ? getDateObjectWithValueFromMoment(
                                                                                                    subTask?.startDate
                                                                                                )
                                                                                                : ''
                                                                                        }
                                                                                        onFocus={(
                                                                                            e
                                                                                        ) =>
                                                                                            e.target.blur()
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <Dropdown
                                                                                    isOpen={
                                                                                        subTask.id ===
                                                                                        this
                                                                                            .state
                                                                                            .subTaskToggleUserId
                                                                                    }
                                                                                    toggle={() =>
                                                                                        this.subTaskUsersToggle(
                                                                                            subTask.id
                                                                                        )
                                                                                    }
                                                                                    className="notification-list list-inline-item mr-1"
                                                                                    tag="li"
                                                                                >
                                                                                    <DropdownToggle
                                                                                        className="arrow-none waves-effect"
                                                                                        tag="a"
                                                                                    >
                                                                                        <a
                                                                                            href={() => false}
                                                                                            title={
                                                                                                subTask
                                                                                                    .projectTaskUsers[0]
                                                                                                    ?.user
                                                                                                    ?.fullName !=
                                                                                                    undefined
                                                                                                    ? subTask
                                                                                                        .projectTaskUsers[0]
                                                                                                        ?.user
                                                                                                        ?.fullName
                                                                                                    : 'select users'
                                                                                            }
                                                                                            className="calander-dropdown assigne-box"
                                                                                        >
                                                                                            <svg
                                                                                                width="16"
                                                                                                height="22"
                                                                                                viewBox="0 0 16 22"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path
                                                                                                    d="M14.5073 15.2708C14.1235 14.1964 13.2777 13.2471 12.1012 12.57C10.9246 11.8929 9.48304 11.5259 8.00003 11.5259C6.51702 11.5259 5.07545 11.8929 3.8989 12.57C2.72235 13.2471 1.87657 14.1964 1.49274 15.2708"
                                                                                                    stroke="black"
                                                                                                    stroke-opacity="0.5"
                                                                                                    strokeWidth="1.2"
                                                                                                    strokeLinecap="round"
                                                                                                />
                                                                                                <circle
                                                                                                    cx="8.00001"
                                                                                                    cy="4.78932"
                                                                                                    r="3.36842"
                                                                                                    fill="#7E869E"
                                                                                                    fillOpacity="0.25"
                                                                                                    stroke="currentColor"
                                                                                                    stroke-opacity="0.5"
                                                                                                    strokeWidth="1.2"
                                                                                                    strokeLinecap="round"
                                                                                                />
                                                                                            </svg>
                                                                                        </a>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu
                                                                                        className="dropdown-menu"
                                                                                        right
                                                                                    >
                                                                                        {this.state.parentProjectTaskUsers?.map(
                                                                                            (
                                                                                                users
                                                                                            ) => (
                                                                                                <DropdownItem
                                                                                                    tag="a"
                                                                                                    className="priority-dropdown"
                                                                                                    active={
                                                                                                        users.value ===
                                                                                                        subTask
                                                                                                            .projectTaskUsers[0]
                                                                                                            ?.userId
                                                                                                    }
                                                                                                    onClick={() =>
                                                                                                        this.onChangeUser(
                                                                                                            users.value,
                                                                                                            subTask
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {' ' +
                                                                                                        users.label}
                                                                                                </DropdownItem>
                                                                                            )
                                                                                        )}
                                                                                    </DropdownMenu>
                                                                                </Dropdown>
                                                                                <Dropdown
                                                                                    isOpen={
                                                                                        this
                                                                                            .state
                                                                                            .actionId ===
                                                                                        subTask.id
                                                                                    }
                                                                                    toggle={() =>
                                                                                        this.actionToggle(
                                                                                            subTask.id
                                                                                        )
                                                                                    }
                                                                                    className="notification-list list-inline-item"
                                                                                    tag="li"
                                                                                >
                                                                                    <DropdownToggle
                                                                                        className="nav-link arrow-none waves-effect"
                                                                                        tag="a"
                                                                                        title={__t(
                                                                                            'form_labels.projects.action'
                                                                                        )}
                                                                                    >
                                                                                        <svg
                                                                                            width="20"
                                                                                            height="4"
                                                                                            viewBox="0 0 20 4"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M16 2C16 1.60444 16.1173 1.21776 16.3371 0.88886C16.5568 0.559962 16.8692 0.303617 17.2346 0.152242C17.6001 0.000866562 18.0022 -0.0387401 18.3902 0.0384303C18.7781 0.115601 19.1345 0.306082 19.4142 0.585787C19.6939 0.865492 19.8844 1.22186 19.9616 1.60982C20.0387 1.99778 19.9991 2.39992 19.8478 2.76537C19.6964 3.13082 19.44 3.44318 19.1111 3.66294C18.7822 3.8827 18.3956 4 18 4C17.4696 4 16.9609 3.78929 16.5858 3.41421C16.2107 3.03914 16 2.53043 16 2V2ZM8 2C8 1.60444 8.1173 1.21776 8.33706 0.88886C8.55682 0.559962 8.86918 0.303617 9.23463 0.152242C9.60008 0.000866562 10.0022 -0.0387401 10.3902 0.0384303C10.7781 0.115601 11.1345 0.306082 11.4142 0.585787C11.6939 0.865492 11.8844 1.22186 11.9616 1.60982C12.0387 1.99778 11.9991 2.39992 11.8478 2.76537C11.6964 3.13082 11.44 3.44318 11.1111 3.66294C10.7822 3.8827 10.3956 4 10 4C9.46957 4 8.96086 3.78929 8.58579 3.41421C8.21071 3.03914 8 2.53043 8 2V2ZM0 2C0 1.60444 0.117298 1.21776 0.337061 0.88886C0.556824 0.559962 0.869181 0.303617 1.23463 0.152242C1.60009 0.000866562 2.00222 -0.0387401 2.39018 0.0384303C2.77814 0.115601 3.13451 0.306082 3.41421 0.585787C3.69392 0.865492 3.8844 1.22186 3.96157 1.60982C4.03874 1.99778 3.99913 2.39992 3.84776 2.76537C3.69638 3.13082 3.44004 3.44318 3.11114 3.66294C2.78224 3.8827 2.39556 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2H0Z"
                                                                                                fill="currentColor"
                                                                                            />
                                                                                        </svg>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu
                                                                                        className="dropdown-menu"
                                                                                        right
                                                                                    >
                                                                                        <DropdownItem
                                                                                            tag="a"
                                                                                            onClick={() =>
                                                                                                this.editSubTask(
                                                                                                    subTask.id
                                                                                                )
                                                                                            }
                                                                                            className="text-primary notify-all"
                                                                                        >
                                                                                            <i className="fas fa-pencil-alt"></i>{' '}
                                                                                            Edit
                                                                                        </DropdownItem>
                                                                                        <DropdownItem
                                                                                            tag="a"
                                                                                            onClick={() => {
                                                                                                this.deleteSubTask(
                                                                                                    subTask.id
                                                                                                );
                                                                                            }}
                                                                                            className="text-primary notify-all"
                                                                                        >
                                                                                            <i className="fas fa-trash-alt"></i>{' '}
                                                                                            Delete
                                                                                        </DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </Dropdown>
                                                                            </div>
                                                                        </li>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </ul>
                                                        <div className="mt-3 d-flex">
                                                            {this.state
                                                                .subTaskForm ===
                                                                false && (
                                                                    <a
                                                                        href={() => false}
                                                                        className="btn btn-primary add-subtask-btn"
                                                                        onClick={() => {
                                                                            this.addSubTask();
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            width="15"
                                                                            height="15"
                                                                            viewBox="0 0 15 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M7.5 2L7.5 13"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2.5"
                                                                                strokeLinecap="round"
                                                                            />
                                                                            <path
                                                                                d="M13 7.5L2 7.5"
                                                                                stroke="currentColor"
                                                                                strokeWidth="2.5"
                                                                                strokeLinecap="round"
                                                                            />
                                                                        </svg>
                                                                        {
                                                                            ADD_SUB_TASK
                                                                        }
                                                                    </a>
                                                                )}
                                                        </div>
                                                        {this.state
                                                            .subTaskForm ===
                                                            true && (
                                                                <SubTaskForm
                                                                    subTaskFormData={
                                                                        this.state
                                                                            .subTaskFormData
                                                                    }
                                                                    taskId={
                                                                        values.id
                                                                    }
                                                                    projectUsers={
                                                                        projectUsers
                                                                    }
                                                                    onModalDismiss={() =>
                                                                        this.closeSubTaskModal()
                                                                    }
                                                                />
                                                            )}
                                                    </React.Fragment>
                                                </div>
                                            )}
                                        <div className="add-attachment-box">
                                            <h4>File Attachment</h4>
                                            <div className="d-flex mt-3">
                                                <Dropzone
                                                    multiple={true}
                                                    onDrop={(acceptedFiles) =>
                                                        this.handleFileUpload(
                                                            acceptedFiles
                                                        )
                                                    }
                                                >
                                                    {({
                                                        getRootProps,
                                                        getInputProps,
                                                    }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}
                                                            >
                                                                <input
                                                                    {...getInputProps()}
                                                                />
                                                                <h3>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {this.state.isFileProgress ===
                                                    true && (
                                                        <div>
                                                            <div className="attachment-data-box">
                                                                <a
                                                                    href={() => false}
                                                                    title="File"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <svg
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M11.2594 7.90347C11.0936 7.90347 10.9347 7.83762 10.8174 7.7204C10.7002 7.60319 10.6344 7.44422 10.6344 7.27847V1.95972H5C4.50272 1.95972 4.02581 2.15726 3.67419 2.50889C3.32253 2.86052 3.125 3.33744 3.125 3.83472V16.3597C3.125 16.857 3.32253 17.3339 3.67419 17.6855C4.02581 18.0372 4.50272 18.2347 5 18.2347H14.7063C15.2033 18.2338 15.6796 18.036 16.0311 17.6845C16.3825 17.3331 16.5803 16.8567 16.5813 16.3597V7.90347H11.2594Z"
                                                                            fill="currentColor"
                                                                        />
                                                                        <path
                                                                            d="M16.2124 6.65335L11.8843 2.3252V2.58458V6.65335H15.9562H16.2124Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                    <div className="progress_bar">
                                                                        <Progress
                                                                            animated
                                                                            color="primary"
                                                                            style={{
                                                                                height: '1rem',
                                                                            }}
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .fileProgress
                                                                            }
                                                                        ></Progress>
                                                                    </div>
                                                                    <span>
                                                                        File name
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                {this.state.projectTaskAttchments?.map(
                                                    (file) => (
                                                        <div>
                                                            <div className="attachment-data-box">
                                                                <a
                                                                    href={() => false}
                                                                    className="remove-box"
                                                                    title="remove"
                                                                    onClick={() =>
                                                                        this.deleteTaskDocument(
                                                                            file.id
                                                                        )
                                                                    }
                                                                >
                                                                    <svg
                                                                        width="16"
                                                                        height="17"
                                                                        viewBox="0 0 16 17"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                        <path
                                                                            d="M1.3335 4H14.6668"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                        <path
                                                                            d="M6.3335 7.33325V12.3333"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                        <path
                                                                            d="M9.6665 7.33325V12.3333"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                        <path
                                                                            d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1"
                                                                            strokeLinecap="round"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                                <a
                                                                    href={() => false}
                                                                    title={__t(
                                                                        'common.download_file'
                                                                    )}
                                                                    onClick={() =>
                                                                        this.saveFile(
                                                                            {
                                                                                id: file.id,
                                                                                fileName:
                                                                                    file.fileName.replace(
                                                                                        'project-tasks/',
                                                                                        ''
                                                                                    ),
                                                                                fileUrl:
                                                                                    file.fileUrl,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <svg
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M11.2594 7.90347C11.0936 7.90347 10.9347 7.83762 10.8174 7.7204C10.7002 7.60319 10.6344 7.44422 10.6344 7.27847V1.95972H5C4.50272 1.95972 4.02581 2.15726 3.67419 2.50889C3.32253 2.86052 3.125 3.33744 3.125 3.83472V16.3597C3.125 16.857 3.32253 17.3339 3.67419 17.6855C4.02581 18.0372 4.50272 18.2347 5 18.2347H14.7063C15.2033 18.2338 15.6796 18.036 16.0311 17.6845C16.3825 17.3331 16.5803 16.8567 16.5813 16.3597V7.90347H11.2594Z"
                                                                            fill="currentColor"
                                                                        />
                                                                        <path
                                                                            d="M16.2124 6.65335L11.8843 2.3252V2.58458V6.65335H15.9562H16.2124Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                    <span className="attchment-file_name">
                                                                        {file.fileName.replace(
                                                                            /project-tasks\//gi,
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                {this.state.selectedFiles.map(
                                                    (f, i) => {
                                                        return (
                                                            <div>
                                                                <div className="attachment-data-box">
                                                                    <a
                                                                        href={
                                                                            f.preview
                                                                        }
                                                                        title={
                                                                            f.name
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 20 20"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M11.2594 7.90347C11.0936 7.90347 10.9347 7.83762 10.8174 7.7204C10.7002 7.60319 10.6344 7.44422 10.6344 7.27847V1.95972H5C4.50272 1.95972 4.02581 2.15726 3.67419 2.50889C3.32253 2.86052 3.125 3.33744 3.125 3.83472V16.3597C3.125 16.857 3.32253 17.3339 3.67419 17.6855C4.02581 18.0372 4.50272 18.2347 5 18.2347H14.7063C15.2033 18.2338 15.6796 18.036 16.0311 17.6845C16.3825 17.3331 16.5803 16.8567 16.5813 16.3597V7.90347H11.2594Z"
                                                                                fill="currentColor"
                                                                            />
                                                                            <path
                                                                                d="M16.2124 6.65335L11.8843 2.3252V2.58458V6.65335H15.9562H16.2124Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                        <a
                                                                            href={() => false}
                                                                            className="remove-box"
                                                                            title="remove"
                                                                            onClick={() =>
                                                                                this.removeFile(
                                                                                    f
                                                                                )
                                                                            }
                                                                        >
                                                                            <svg
                                                                                width="16"
                                                                                height="17"
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M1.3335 4H14.6668"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M6.3335 7.33325V12.3333"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.6665 7.33325V12.3333"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                            </svg>
                                                                        </a>
                                                                        <span className="attchment-file_name">
                                                                            {
                                                                                f.name
                                                                            }
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        {this.props.operationType ===
                                            UPDATE && (
                                                <div className="mt-4">
                                                    <Nav
                                                        tabs
                                                        className="nav-tabs-custom nav-justified"
                                                    >
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames(
                                                                    {
                                                                        active:
                                                                            this
                                                                                .state
                                                                                .activeTab ===
                                                                            'commentTab',
                                                                    }
                                                                )}
                                                                onClick={() => {
                                                                    this.toggle(
                                                                        'commentTab'
                                                                    );
                                                                }}
                                                            >
                                                                Comment
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                className={classnames(
                                                                    {
                                                                        active:
                                                                            this
                                                                                .state
                                                                                .activeTab ===
                                                                            'activityLogTab',
                                                                    }
                                                                )}
                                                                onClick={() => {
                                                                    this.toggle(
                                                                        'activityLogTab'
                                                                    );
                                                                }}
                                                            >
                                                                Activity Log
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                    <TabContent
                                                        activeTab={
                                                            this.state.activeTab
                                                        }
                                                    >
                                                        <TabPane
                                                            className="laws_tab"
                                                            tabId="commentTab"
                                                        >
                                                            {this.state
                                                                .activeTab ===
                                                                'commentTab' && (
                                                                    <div>
                                                                        {this.state
                                                                            .operationType ===
                                                                            UPDATE && (
                                                                                <div className="mt-0">
                                                                                    <div className="comment-box">
                                                                                        <ul>
                                                                                            {this.state.taskActivityList
                                                                                                .filter(
                                                                                                    (
                                                                                                        activityInfo
                                                                                                    ) =>
                                                                                                        activityInfo.projectActivityTypeId ===
                                                                                                        PROJECT_ACTIVITY_TYPE_COMMENT
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        activity
                                                                                                    ) => (
                                                                                                        <>
                                                                                                            <li
                                                                                                                className={
                                                                                                                    this
                                                                                                                        .state
                                                                                                                        .userId ==
                                                                                                                        activity.createdBy
                                                                                                                        ? 'sent_comment_box'
                                                                                                                        : 'receive_comment_box'
                                                                                                                }
                                                                                                            >
                                                                                                                <div className="chat-title">
                                                                                                                    <span
                                                                                                                        className="assigne-box green-round"
                                                                                                                        title={__t(
                                                                                                                            activity
                                                                                                                                .user
                                                                                                                                .fullName
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        {getInitials(
                                                                                                                            activity
                                                                                                                                .user
                                                                                                                                .fullName
                                                                                                                        )}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="ml-3">
                                                                                                                    <div>
                                                                                                                        <h5>
                                                                                                                            {this
                                                                                                                                .state
                                                                                                                                .userId ==
                                                                                                                                activity.createdBy
                                                                                                                                ? 'You'
                                                                                                                                : activity
                                                                                                                                    .user
                                                                                                                                    .fullName}
                                                                                                                        </h5>
                                                                                                                        <div className="small-title">
                                                                                                                            <p className="m-0">
                                                                                                                                <svg
                                                                                                                                    width="38"
                                                                                                                                    height="22"
                                                                                                                                    viewBox="0 0 38 22"
                                                                                                                                    fill="none"
                                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                                >
                                                                                                                                    <path
                                                                                                                                        d="M0 12.8361L9.13634 22L11.4409 19.6721L2.32086 10.5246M35.6791 0L18.3871 17.3607L11.588 10.5246L9.25075 12.8361L18.3871 22L38 2.32787M28.7492 2.32787L26.4447 0L16.0662 10.4098L18.3871 12.7213L28.7492 2.32787Z"
                                                                                                                                        fill="currentColor"
                                                                                                                                    />
                                                                                                                                </svg>
                                                                                                                            </p>
                                                                                                                            <small>
                                                                                                                                {getDateTimeFromDateTimeString(
                                                                                                                                    activity.createdAt
                                                                                                                                )}
                                                                                                                            </small>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <p className="mb-1">
                                                                                                                            {
                                                                                                                                activity?.remarks
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </>
                                                                                                    )
                                                                                                )}
                                                                                        </ul>
                                                                                        <div className="comment-chat-box">
                                                                                            {this.state.taskFormData?.projectStatusId !== 3 && (
                                                                                                <TaskCommentForm
                                                                                                    taskId={
                                                                                                        values.id
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                )}
                                                        </TabPane>
                                                        <TabPane
                                                            className="laws_tab"
                                                            tabId="activityLogTab"
                                                        >
                                                            {this.state
                                                                .activeTab ===
                                                                'activityLogTab' && (
                                                                    <div>
                                                                        {this.state
                                                                            .operationType ===
                                                                            UPDATE && (
                                                                                <div className="mt-0">
                                                                                    <div className="comment-box">
                                                                                        <ul>
                                                                                            {this.state.taskActivityList.map(
                                                                                                (
                                                                                                    activity
                                                                                                ) => (
                                                                                                    <>
                                                                                                        <li
                                                                                                            className={
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .userId ==
                                                                                                                    activity.createdBy
                                                                                                                    ? 'sent_comment_box'
                                                                                                                    : 'receive_comment_box'
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="chat-title">
                                                                                                                <span
                                                                                                                    className="assigne-box green-round"
                                                                                                                    title={__t(
                                                                                                                        activity
                                                                                                                            .user
                                                                                                                            .fullName
                                                                                                                    )}
                                                                                                                >
                                                                                                                    {getInitials(
                                                                                                                        activity
                                                                                                                            .user
                                                                                                                            .fullName
                                                                                                                    )}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="ml-3">
                                                                                                                <div>
                                                                                                                    <h5>
                                                                                                                        {this
                                                                                                                            .state
                                                                                                                            .userId ==
                                                                                                                            activity.createdBy
                                                                                                                            ? 'You'
                                                                                                                            : activity
                                                                                                                                .user
                                                                                                                                .fullName}
                                                                                                                    </h5>
                                                                                                                    <div className="small-title">
                                                                                                                        <p className="m-0">
                                                                                                                            {
                                                                                                                                activity
                                                                                                                                    .projectActivityTypes
                                                                                                                                    ?.activityTypeName
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                        <small>
                                                                                                                            {getDateTimeFromDateTimeString(
                                                                                                                                activity.createdAt
                                                                                                                            )}
                                                                                                                        </small>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <p className="mb-1 text-left">
                                                                                                                        <p>
                                                                                                                            {activity.projectActivityTypeId ===
                                                                                                                                10 && (
                                                                                                                                    <>
                                                                                                                                        <p>
                                                                                                                                            <strong>
                                                                                                                                                Old
                                                                                                                                                Date
                                                                                                                                            </strong>
                                                                                                                                            :{' '}
                                                                                                                                            {
                                                                                                                                                activity
                                                                                                                                                    ?.projectTaskRevisedDate
                                                                                                                                                    ?.oldDate
                                                                                                                                            }
                                                                                                                                        </p>
                                                                                                                                        <p>
                                                                                                                                            <strong>
                                                                                                                                                Revised
                                                                                                                                                Date
                                                                                                                                            </strong>
                                                                                                                                            :{' '}
                                                                                                                                            {
                                                                                                                                                activity
                                                                                                                                                    ?.projectTaskRevisedDate
                                                                                                                                                    ?.revisedDate
                                                                                                                                            }
                                                                                                                                        </p>
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                            {activity.projectActivityTypeId ===
                                                                                                                                12 && (
                                                                                                                                    <>
                                                                                                                                        <p>
                                                                                                                                            <strong>
                                                                                                                                                Old
                                                                                                                                                Date
                                                                                                                                            </strong>
                                                                                                                                            :{' '}
                                                                                                                                            {
                                                                                                                                                activity
                                                                                                                                                    ?.projectTaskRevisedDate
                                                                                                                                                    ?.oldDate
                                                                                                                                            }
                                                                                                                                        </p>
                                                                                                                                        <p>
                                                                                                                                            <strong>
                                                                                                                                                Revised
                                                                                                                                                Date
                                                                                                                                            </strong>
                                                                                                                                            :{' '}
                                                                                                                                            {
                                                                                                                                                activity
                                                                                                                                                    ?.projectTaskRevisedDate
                                                                                                                                                    ?.revisedDate
                                                                                                                                            }
                                                                                                                                        </p>
                                                                                                                                    </>
                                                                                                                                )}
                                                                                                                        </p>
                                                                                                                        {activity?.projectActivityTypeId ==
                                                                                                                            13
                                                                                                                            ? activity?.remarks.replace(
                                                                                                                                /project-tasks\//gi,
                                                                                                                                ''
                                                                                                                            )
                                                                                                                            : ''}
                                                                                                                        {(activity.projectActivityTypeId !==
                                                                                                                            10 &&
                                                                                                                            activity.projectActivityTypeId !==
                                                                                                                            12 &&
                                                                                                                            activity?.projectActivityTypeId !==
                                                                                                                            13) ??
                                                                                                                            activity?.remarks !=
                                                                                                                            null
                                                                                                                            ? parse(
                                                                                                                                activity?.remarks.toString()
                                                                                                                            )
                                                                                                                            : ''}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                )}
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                            )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </CustomisedSideModal>
                <DownloadFileByModel
                    modelType='projectTask'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    projectTaskApiResponse: makeProjectsSelectField('projectTaskApiResponse'),
    projectSubTaskApiResponse: makeProjectsSelectField(
        'projectSubTaskApiResponse'
    ),
    projectTaskDetailsApiResponse: makeProjectsSelectField(
        'projectTaskDetailsApiResponse'
    ),
    projectTaskAttachmentApiResponse: makeProjectsSelectField(
        'projectTaskAttachmentApiResponse'
    ),
    projectTaskActivityApiResponse: makeProjectsSelectField(
        'projectTaskActivityApiResponse'
    ),
    projectTaskDocumentApiResponse: makeProjectsSelectField(
        'projectTaskDocumentApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    addProjectTaskRequest,
    updateProjectTaskRequest,
    deleteProjectSubTaskBySubTaskIdRequest,
    getProjectTaskDetailsRequest,
    addProjectTaskAttachmentRequest,
    getProjectTaskActivityRequest,
    deleteProjectTaskDocumentByDocumentIdRequest,
});
export default compose(withConnect, withRouter)(AddEditProjectTaskModal);
