import {
    ALLOCATE_CHECK_COMPLIANCES_ENTITY_REQUEST,
    ALLOCATE_CHECK_COMPLIANCES_ENTITY_RESPONSE,
    ALLOCATE_COMPLIANCES_TO_ENTITY_REQUEST,
    ALLOCATE_COMPLIANCES_TO_ENTITY_RESPONSE,
    ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_REQUEST,
    ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_RESPONSE,
    CHANGE_STATUS_TRACK_ALLOCATION_REQUEST,
    CHANGE_STATUS_TRACK_ALLOCATION_RESPONSE,
    DELETE_ALLOCATION_BY_ALLOCATION_ID_REQUEST,
    DELETE_ALLOCATION_BY_ALLOCATION_ID_RESPONSE,
    DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_REQUEST,
    DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_RESPONSE,
    GET_ALLOCATION_SEARCH_LIST_REQUEST,
    GET_ALLOCATION_SEARCH_LIST_RESPONSE,
    GET_ASSIGNED_EVENTS_LIST_REQUEST,
    GET_ASSIGNED_EVENTS_LIST_RESPONSE,
    GET_ASSIGNED_EVENT_COMPLIANCES_LIST_REQUEST,
    GET_ASSIGNED_EVENT_COMPLIANCES_LIST_RESPONSE,
    GET_SCHEDULE_LIST_BY_ALLOCATION_ID_REQUEST,
    GET_SCHEDULE_LIST_BY_ALLOCATION_ID_RESPONSE,
    GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_REQUEST,
    GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_RESPONSE,
    GET_TRACK_ALLOCATION_BY_COMPLIANCE_REQUEST,
    GET_TRACK_ALLOCATION_BY_COMPLIANCE_RESPONSE,
    GET_TRACK_ALLOCATION_SEARCH_LIST_REQUEST,
    GET_TRACK_ALLOCATION_SEARCH_LIST_RESPONSE,
    GET_USER_ASSIGNED_COMPLIANCES_REQUEST,
    GET_USER_ASSIGNED_COMPLIANCES_RESPONSE,
    UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_REQUEST,
    UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_RESPONSE,
    UPDATE_SCHEDULE_BY_ALLOCATION_ID_REQUEST,
    UPDATE_SCHEDULE_BY_ALLOCATION_ID_RESPONSE,
    UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_REQUEST,
    UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_RESPONSE,
    UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_REQUEST,
    UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_RESPONSE,
} from './actionTypes';
export const allocateCompliancesToEntityRequest = (data) => {
    return {
        type: ALLOCATE_COMPLIANCES_TO_ENTITY_REQUEST,
        payload: { data },
    };
};
export const allocateCompliancesToEntityResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: ALLOCATE_COMPLIANCES_TO_ENTITY_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const allocateCheckCompliancesEntityRequest = (data) => {
    return {
        type: ALLOCATE_CHECK_COMPLIANCES_ENTITY_REQUEST,
        payload: { data },
    };
};
export const allocateCheckCompliancesEntityResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: ALLOCATE_CHECK_COMPLIANCES_ENTITY_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getAllocationSearchListRequest = (data, pagination) => {
    return {
        type: GET_ALLOCATION_SEARCH_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getAllocationSearchListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_ALLOCATION_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getTrackAllocationSearchListRequest = (data, pagination) => {
    return {
        type: GET_TRACK_ALLOCATION_SEARCH_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getTrackAllocationSearchListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_TRACK_ALLOCATION_SEARCH_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const deleteAllocationByAllocationIdRequest = (allocationId) => {
    return {
        type: DELETE_ALLOCATION_BY_ALLOCATION_ID_REQUEST,
        payload: { allocationId },
    };
};
export const deleteAllocationByAllocationIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_ALLOCATION_BY_ALLOCATION_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const assignUserAndDepartmentRequest = (allocationId, data) => {
    return {
        type: ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_REQUEST,
        payload: { allocationId, data },
    };
};
export const assignUserAndDepartmentResponse = (message, responseType) => {
    return {
        type: ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const updateAllocationStatusByAllocationIdsRequest = (data) => {
    return {
        type: UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_REQUEST,
        payload: { data },
    };
};
export const updateAllocationStatusByAllocationIdsResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_RESPONSE,
        payload: { message, responseType },
    };
};

export const getScheduleListByAllocationIdRequest = (allocationId) => {
    return {
        type: GET_SCHEDULE_LIST_BY_ALLOCATION_ID_REQUEST,
        payload: { allocationId },
    };
};
export const getScheduleListByAllocationIdResponse = (scheduleList) => {
    return {
        type: GET_SCHEDULE_LIST_BY_ALLOCATION_ID_RESPONSE,
        payload: { scheduleList },
    };
};

export const updateViewScheduleStatusByAllocationTaskIdRequest = (
    id,
    status
) => {
    return {
        type: UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_REQUEST,
        payload: { id, status },
    };
};
export const updateViewScheduleStatusByAllocationTaskIdResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const updateScheduleByAllocationIdRequest = (allocationId, data) => {
    return {
        type: UPDATE_SCHEDULE_BY_ALLOCATION_ID_REQUEST,
        payload: { allocationId, data },
    };
};
export const updateScheduleByAllocationIdResponse = (message, responseType) => {
    return {
        type: UPDATE_SCHEDULE_BY_ALLOCATION_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getUserAssignedComplianceListRequest = (data) => {
    return {
        type: GET_USER_ASSIGNED_COMPLIANCES_REQUEST,
        payload: { data },
    };
};
export const getUserAssignedComplianceListResponse = (
    assignedComplianceList
) => {
    return {
        type: GET_USER_ASSIGNED_COMPLIANCES_RESPONSE,
        payload: { assignedComplianceList },
    };
};

export const getAssignedEventsListRequest = (data) => {
    return {
        type: GET_ASSIGNED_EVENTS_LIST_REQUEST,
        payload: { data },
    };
};
export const getAssignedEventsListResponse = (message, responseType, data) => {
    return {
        type: GET_ASSIGNED_EVENTS_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getAssignedEventCompliancesListRequest = (data, pagination) => {
    return {
        type: GET_ASSIGNED_EVENT_COMPLIANCES_LIST_REQUEST,
        payload: { data, pagination },
    };
};
export const getAssignedEventCompliancesListResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_ASSIGNED_EVENT_COMPLIANCES_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const changeStatusTrackAllocationRequest = (data) => {
    return {
        type: CHANGE_STATUS_TRACK_ALLOCATION_REQUEST,
        payload: { data },
    };
};
export const changeStatusTrackAllocationResponse = (message, responseType) => {
    return {
        type: CHANGE_STATUS_TRACK_ALLOCATION_RESPONSE,
        payload: { message, responseType },
    };
};

export const getTrackAllocationByComplianceRequest = (complianceId, data) => {
    return {
        type: GET_TRACK_ALLOCATION_BY_COMPLIANCE_REQUEST,
        payload: { complianceId, data },
    };
};
export const getTrackAllocationByComplianceResponse = (trackAllocationList) => {
    return {
        type: GET_TRACK_ALLOCATION_BY_COMPLIANCE_RESPONSE,
        payload: { trackAllocationList },
    };
};

export const getTrackAllocationActivitiesByComplianceRequest = (
    complianceId,
    type
) => {
    return {
        type: GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_REQUEST,
        payload: { complianceId, type },
    };
};
export const getTrackAllocationActivitiesByComplianceResponse = (
    trackAllocationActivityList
) => {
    return {
        type: GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_RESPONSE,
        payload: { trackAllocationActivityList },
    };
};

export const updateTaskScheduleRenewalDateRequest = (data) => {
    return {
        type: UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_REQUEST,
        payload: { data },
    };
};
export const updateTaskScheduleRenewalDateResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteTrackAllocationsByComplianceRequest = (data) => {
    return {
        type: DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_REQUEST,
        payload: { data },
    };
};
export const deleteTrackAllocationsByComplianceResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_RESPONSE,
        payload: { message, responseType },
    };
};