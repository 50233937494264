export const ADD_STATE_REQUEST = 'add_state_request';
export const ADD_STATE_RESPONSE = 'add_state_response';
export const GET_ALL_STATE_LIST_REQUEST = 'get_all_state_list_request';
export const GET_ALL_STATE_LIST_RESPONSE = 'get_all_state_list_response';
export const GET_STATE_BY_STATE_ID_REQUEST = 'get_state_by_state_id_request';
export const GET_STATE_BY_STATE_ID_RESPONSE = 'get_state_by_state_id_response';
export const UPDATE_STATE_REQUEST = 'update_state_request';
export const UPDATE_STATE_RESPONSE = 'update_state_response';
export const UPDATE_STATE_STATUS_BY_STATE_ID_REQUEST = 'update_state_status_by_state_id_request';
export const UPDATE_STATE_STATUS_BY_STATE_ID_RESPONSE = 'update_state_status_by_state_id_response';
export const GET_STATE_LIST_BY_STATUS_REQUEST = 'get_state_list_by_status_request';
export const GET_STATE_LIST_BY_STATUS_RESPONSE = 'get_state_list_by_status_response';
export const DELETE_STATE_BY_STATE_ID_REQUEST = 'delete_state_by_state_id_request';
export const DELETE_STATE_BY_STATE_ID_RESPONSE = 'delete_state_by_state_id_response';
export const GET_STATE_LIST_BY_COUNTRY_REQUEST = 'get_state_list_by_country_request';
export const GET_STATE_LIST_BY_COUNTRY_RESPONSE = 'get_state_list_by_country_response';