import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { SUCCESS } from 'constants/commonConstants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { getLawComplianceListByEventIdRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import { toastrMessage } from 'helpers/messageHelper';
import CommonBreadcrum from './CommonBreadcrum';
import { makeEventSelectField } from 'store/Masters/Event/selector';
import ComplianceSubItem from './ComplianceSubItem';
import { compareIntValue } from 'helpers/generalUtils';

const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });

class CommonEventTreeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.eventId
                ? parseInt(this.props.match.params.eventId)
                : '',
            eventData: {},
            complianceList: []
        };
    }
    componentDidMount() {
        this.props.getLawComplianceListByEventIdRequest(this.state.eventId);
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.eventComplianceListApiResponse &&
            this.props.eventComplianceListApiResponse !==
            nextprops.eventComplianceListApiResponse
        ) {
            if (
                nextprops.eventComplianceListApiResponse.responseType ===
                SUCCESS
            ) {
                this.setState({
                    eventData:
                        nextprops.eventComplianceListApiResponse.data
                            .eventData,
                    complianceList: nextprops.eventComplianceListApiResponse.data
                        .complianceList,
                });
            } else {
                toastrMessage(
                    nextprops.eventComplianceListApiResponse.message,
                    nextprops.eventComplianceListApiResponse.responseType
                );
            }
        }
    }

    render() {
        let complianceLoop = this.state.complianceList;
        const sortedComplianceLoop = complianceLoop?.sort((item1, item2) => compareIntValue(item1.sequenceOrder, item2.sequenceOrder));
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum
                            type={'event-details'}
                        />
                    </Col>
                    <Col lg="12">
                        <Card className='event_compliances'>
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        {this.props.history.length != 1 && (
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.goBack();
                                                }}
                                            >
                                                { }
                                            </a>
                                        )}
                                        <span>
                                            {this.state.eventData?.eventName}
                                        </span>
                                    </h4>
                                    <span className='event_country'><img width={20} src={'/data/flags/' + this.state.eventData?.country?.isoCode2 + '.svg'} alt='country-flag' /> {this.state.eventData?.country?.countryName}</span>
                                </div>
                            </CardHeader>
                            <CardBody className='modal_tree_details'>
                                {/* Event Compliance Tree List */}
                                <div className="event-compliances tree_details">
                                    {sortedComplianceLoop?.length > 0 &&
                                        sortedComplianceLoop?.map((compliance, key) => {
                                            return (
                                                <div
                                                    className={
                                                        compliance.subTask.length > 0
                                                            ? ' tree-parent has-child '
                                                            : ' tree-parent'
                                                    }
                                                    key={compliance.id}
                                                >
                                                    <ComplianceSubItem
                                                        history={this.props.history}
                                                        subTask={compliance}
                                                    />
                                                </div>
                                            );
                                        })}



                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    eventComplianceListApiResponse: makeEventSelectField(
        'eventComplianceListApiResponse'
    )
});

const withConnect = connect(mapStatetoProps, {
    getLawComplianceListByEventIdRequest
});

export default compose(
    withConnect,
    withEventReducer,
    withEventSaga,
    withRouter
)(CommonEventTreeDetails);
