export const ADD_ENTITY_ROLE_REQUEST = 'add_entity_role_request';
export const ADD_ENTITY_ROLE_RESPONSE = 'add_entity_role_response';
export const GET_ALL_ENTITY_ROLE_LIST_REQUEST = 'get_all_entity_role_list_request';
export const GET_ALL_ENTITY_ROLE_LIST_RESPONSE = 'get_all_entity_role_list_response';
export const GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST = 'get_entity_role_by_entity_role_id_request';
export const GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE = 'get_entity_role_by_entity_role_id_response';
export const UPDATE_ENTITY_ROLE_REQUEST = 'update_entity_role_request';
export const UPDATE_ENTITY_ROLE_RESPONSE = 'update_entity_role_response';
export const UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_REQUEST = 'update_entity_role_status_by_entity_role_id_request';
export const UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_RESPONSE = 'update_entity_role_status_by_entity_role_id_response';
export const DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST = 'delete_entity_role_by_entity_role_id_request';
export const DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE = 'delete_entity_role_by_entity_role_id_response';
export const GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_REQUEST = 'get_entity_role_list_by_entity_id_request';
export const GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_RESPONSE = 'get_entity_role_list_by_entity_id_response';

export const GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_REQUEST = 'get_roles_with_entity_role_by_entity_list_request';
export const GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_RESPONSE = 'get_roles_with_entity_role_by_entity_list_response';