import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getImportLogsListByTypeRequest
} from 'store/actions';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import { Label } from 'reactstrap';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { makeImportMasterSelectField } from 'store/Masters/ImportMaster/selector';
import FileSaver from 'file-saver';
import { getFullDateTimeFromDateTimeString, getFullDateTimeFromDateTimeStringForSorting } from 'helpers/dateTimeHelper';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
const columns = [
    {
        label: 'File Name',
        field: 'fileName',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Uploaded On',
        field: 'uploadedOn',
    },
    {
        label: 'Uploaded By',
        field: 'uploadedBy',
    },
    {
        label: 'Action',
        field: 'actions',
        sort: 'disabled'
    }
];
class ViewLogsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logsList: [],
            importType: '',
            downloadFileUrl: '',
downloadFileName: '',
        }
    }

    getImportFileLogs = () => {
        this.props.getImportLogsListByTypeRequest({ modelName: this.state.importType });
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    }
    generateTable = records => {
        let uploadedFileRecords = [];
        uploadedFileRecords = records.map((uploadedFileData, i) => {
            let actions = (
                <div>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.saveFile(uploadedFileData)
                        }}
                        title="Download Uploaded File"
                        className='btn btn-link tbl-action text-success'
                    >
                        <i className="fas fa-download"></i>
                    </a>
                </div>
            );
            let statusClassName = (uploadedFileData.status === 'COMPLETED') ? 'success' : 'danger'
            let status = (
                <Label className={`m-0 file-status badge badge-` + statusClassName}>{uploadedFileData.status}</Label>
            );
            return {
                fileName: uploadedFileData?.fileName ? uploadedFileData.fileName : '',
                status: status,
                uploadedOn: <span searchvalue={getFullDateTimeFromDateTimeStringForSorting(uploadedFileData.createdAt)}>{getFullDateTimeFromDateTimeString(
                    uploadedFileData.createdAt
                )}</span>,
                uploadedBy: (uploadedFileData.user) ? uploadedFileData.user?.fullName : '',
                actions: actions
            };
        })
        return uploadedFileRecords;
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.importRecord !== this.props.importRecord) {
            this.setState({
                importType: (nextprops.importRecord.masterName) ? nextprops.importRecord.masterName : '',
            }, () => {
                if (this.state.importType !== '') {
                    this.getImportFileLogs()
                }
            })
        }
        if (nextprops.importLogsList !== this.props.importLogsList) {
            this.setState({
                logsList: this.generateTable(nextprops.importLogsList)
            })
        }
    }
    render() {
        const reactTableData = {
            columns: columns,
            rows: this.state.logsList,
        };
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider logs_modal'}
                    modalName={__t('common.view_logs') + ' | ' + this.props.importRecord.name}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <CustomisedDatatable
                        className="table table-bordered"
                        sortRows={['uploadedOn']}
                        tableRecords={reactTableData}
                    />
                </CustomisedSideModal>
                <DownloadFileByModel
                    modelType='masterUpload'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    importLogsList: makeImportMasterSelectField('importLogsList')
});
const withConnect = connect(mapStatetoProps, {
    getImportLogsListByTypeRequest
});
export default compose(
    withConnect,
    withRouter
)(ViewLogsModal);