import React from "react";
import PropTypes from "prop-types";
import CreatableSelect from 'react-select/creatable';

const CustomMultiSelectCreatable = props => {
    return <CreatableSelect {...props} />;
};

CustomMultiSelectCreatable.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

CustomMultiSelectCreatable.defaultProps = {
};

export default CustomMultiSelectCreatable;
