import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Industry Name',
        field: 'industryName',
    },
    {
        label: 'Sub Industries Count',
        field: 'subIndustriesCount',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialIndustryDataState = () => {
    return {
        industryName: '',
        subIndustries: initialSubIndustriesDataState(),
        isActive: ACTIVE
    }
}
export const initialSubIndustriesDataState = () => {
    return [{
        id: 0,
        subIndustryName: ''
    }];
}
export const intFields = [];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};