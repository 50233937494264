import produce from 'immer';
import {
    ALLOCATE_CHECK_COMPLIANCES_ENTITY_RESPONSE,
    ALLOCATE_COMPLIANCES_TO_ENTITY_RESPONSE,
    ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_RESPONSE,
    CHANGE_STATUS_TRACK_ALLOCATION_RESPONSE,
    DELETE_ALLOCATION_BY_ALLOCATION_ID_RESPONSE,
    DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_RESPONSE,
    GET_ALLOCATION_SEARCH_LIST_RESPONSE,
    GET_ASSIGNED_EVENTS_LIST_RESPONSE,
    GET_ASSIGNED_EVENT_COMPLIANCES_LIST_RESPONSE,
    GET_SCHEDULE_LIST_BY_ALLOCATION_ID_RESPONSE,
    GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_RESPONSE,
    GET_TRACK_ALLOCATION_BY_COMPLIANCE_RESPONSE,
    GET_TRACK_ALLOCATION_SEARCH_LIST_RESPONSE,
    GET_USER_ASSIGNED_COMPLIANCES_RESPONSE,
    UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_RESPONSE,
    UPDATE_SCHEDULE_BY_ALLOCATION_ID_RESPONSE,
    UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_RESPONSE,
    UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_RESPONSE,
} from './actionTypes';
export const initialState = {
    allocationApiResponse: null,
    getComplianceLocationApiResponse: null,
    deleteAllocationApiResponse: null,
    userAllocationApiResponse: null,
    allocationStatusApiResponse: null,
    scheduleList: [],
    assignedComplianceList: [],
    scheduleApiResponse: null,
    trackAllocationApiResponse: null,
    trackAllocationList: [],
    trackAllocationActivityList: [],
    taskScheduleRenewalDateApiResponse: null,
    deleteTrackAllocationApiResponse: null
};
const allocation = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ALLOCATE_COMPLIANCES_TO_ENTITY_RESPONSE:
            case GET_ALLOCATION_SEARCH_LIST_RESPONSE:
            case GET_ASSIGNED_EVENTS_LIST_RESPONSE:
            case GET_ASSIGNED_EVENT_COMPLIANCES_LIST_RESPONSE:
                draft.allocationApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case ALLOCATE_CHECK_COMPLIANCES_ENTITY_RESPONSE:
                draft.getComplianceLocationApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case GET_TRACK_ALLOCATION_SEARCH_LIST_RESPONSE:
                draft.trackAllocationApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case DELETE_ALLOCATION_BY_ALLOCATION_ID_RESPONSE:
                draft.deleteAllocationApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case ASSIGN_USER_AND_DEPARTMENT_BY_ALLOCATION_ID_RESPONSE:
                draft.userAllocationApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case UPDATE_ALLOCATION_STATUS_BY_ALLOCATION_IDS_RESPONSE:
                draft.allocationStatusApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_SCHEDULE_LIST_BY_ALLOCATION_ID_RESPONSE:
                draft.scheduleList = action.payload.scheduleList;
                break;
            case UPDATE_VIEW_SCHEDULE_STATUS_BY_ALLOCATION_TASK_ID_RESPONSE:
            case UPDATE_SCHEDULE_BY_ALLOCATION_ID_RESPONSE:
                draft.scheduleApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;


            case GET_USER_ASSIGNED_COMPLIANCES_RESPONSE:
                draft.assignedComplianceList =
                    action.payload.assignedComplianceList;
                break;




            case CHANGE_STATUS_TRACK_ALLOCATION_RESPONSE:
                draft.trackAllocationApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_TRACK_ALLOCATION_BY_COMPLIANCE_RESPONSE:
                draft.trackAllocationList = action.payload.trackAllocationList;
                break;

            case GET_TRACK_ALLOCATION_ACTIVITIES_BY_COMPLIANCE_RESPONSE:
                draft.trackAllocationActivityList =
                    action.payload.trackAllocationActivityList;
                break;

            case UPDATE_TASKS_SCHEDULE_RENEWAL_DATE_RESPONSE:
                draft.taskScheduleRenewalDateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case DELETE_TRACK_ALLOCATIONS_BY_COMPLIANCE_RESPONSE:
                draft.deleteTrackAllocationApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default allocation;
