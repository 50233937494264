import React, { Component } from 'react';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { getCountryCoverageSearchListRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { makeLawSelectField } from 'store/Masters/Law/selector';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
export const countryCoverageListColumns = [
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'No. of Laws',
        field: 'laws',
    },
    {
        label: 'No. of Compliances',
        field: 'compliances',
    },
    {
        label: 'No. of Updates',
        field: 'updates',
    },
];
const initialSearchDataState = () => {
    return {
        countryId: [],
        stateId: [],
        cityId: [],
        industryId: [],
        subIndustryId: [],
    };
};
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            viewDialog: true,
            countryCoverageList: [],
            searchFormData: initialSearchDataState(),
        };
    }
    componentDidMount() {
        this.getCountryCoverageSearchList({});
    }
    formSubmit = (values) => {
        this.setState({
            searchFormData: values,
        });
    };
    getCountryCoverageSearchList = (values = {}) => {
        values = {
            ...values,
            countryId: values.countryId ? values.countryId : [],
            stateId: values.stateId ? values.stateId : [],
            cityId: values.cityId ? values.cityId : [],
        };
        let apiData = {
            filter: values,
        };
        this.props.getCountryCoverageSearchListRequest(apiData, true);
    };
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialSearchDataState(),
            },
            () => {
                this.getCountryCoverageSearchList({});
            }
        );
    };
    generateTable = (records) => {
        let actsRecords = [];
        actsRecords = records.map((lawDataRecord, i) => {
            return {
                countryName: lawDataRecord.countryName,
                laws: lawDataRecord.lawCount,
                compliances: lawDataRecord.complianceCount,
                updates: lawDataRecord?.updateCount,
            };
        });
        return actsRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.countryCoverageList &&
            this.props.countryCoverageList !== nextprops.countryCoverageList
        ) {
            this.setState({
                countryCoverageList: this.generateTable(
                    nextprops.countryCoverageList
                ),
            });
        }
    }
    render() {
        const data = {
            columns: countryCoverageListColumns,
            rows: this.state.countryCoverageList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'country-coverage'} />
                    </Col>

                    <Col lg="12">
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable tableRecords={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    countryCoverageList: makeLawSelectField('countryCoverageList'),
});
const withConnect = connect(mapStatetoProps, {
    getCountryCoverageSearchListRequest,
});
export default compose(
    withConnect,
    withLawReducer,
    withLawSaga,
    withRouter
)(Index);
