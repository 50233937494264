import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.emailTemplate || initialState;
const makeEmailTemplateSelectField = field =>
    createSelector(reducerState, emailTemplate => emailTemplate[field]);
const selectSortedEmailTemplateTypeList = () =>
    createSelector(makeEmailTemplateSelectField('emailTemplateTypeList'), emailTemplateTypeList =>
    emailTemplateTypeList
            .map(opt => ({
                value: opt.id,
                label: opt.displayName,
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeEmailTemplateSelectField,
    selectSortedEmailTemplateTypeList
};
