import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, ENABLE_DELETE, SUBMIT, UPDATE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllCityListRequest,
    updateCityStatusByCityIdRequest,
    addCityRequest,
    updateCityRequest,
    getStateListByStatusRequest,
    deleteCityByCityIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import { makeCitySelectField } from 'store/Masters/City/selector';
import { initialCityDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import AddCityForm from './AddCityForm';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import { MODULE_CITY, PERMISSION_ADD, PERMISSION_DELETE, PERMISSION_EDIT } from 'constants/databaseConstants';
import { checkModulePermission } from 'helpers/projectUtils';
import AuditLogModal from 'components/Application/AuditLogModal';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cityList: [],
            cityData: initialCityDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: '',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        // Retrieve state list
        this.props.getStateListByStatusRequest(ACTIVE);
        // Retrieve city list
        this.props.getAllCityListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateCityStatusByCityIdRequest(id, checked);
    }
    viewCity(cityData) {
        this.setState(
            {
                cityData: initialCityDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ cityData: cityData });
            }
        );
    }
    deleteCity = async (cityId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.city_delete')
        );
        if (response) {
            this.props.deleteCityByCityIdRequest(cityId);
        }
    };
    formSubmit = (values) => {
        this.setState({ cityData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateCityRequest(values.id, values);
        } else {
            this.props.addCityRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            cityData: initialCityDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let cityRecords = [];
        cityRecords = records.map((cityData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(
                        MODULE_CITY,
                        PERMISSION_EDIT
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewCity(cityData);
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('city') &&
                        checkModulePermission(
                            MODULE_CITY,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteCity(cityData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(cityData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(cityData.id)}
                        checked={cityData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            return {
                cityName: cityData.cityName,
                stateName: cityData.stateName,
                isActive: isActive,
                actions: actions
            };
        });
        return cityRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.cityList && this.props.cityList !== nextprops.cityList) {
            this.setState({
                cityList: this.generateTable(nextprops.cityList),
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.cityList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'city'} />
                        </Col>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>{__t('page_headers.city_list')}</h4>
                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage
                                        activepage={this.state.activePage}
                                        onHandlePageChange={this.handlePageChange}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {(checkModulePermission(
                            MODULE_CITY,
                            PERMISSION_ADD
                        ) === true ||
                            checkModulePermission(
                                MODULE_CITY,
                                PERMISSION_EDIT
                            ) === true) && (
                                <Col lg="5">
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'page_headers.city_form'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <AddCityForm
                                                operationType={
                                                    this.state.operationType
                                                }
                                                sortedStateListDropDown={this.props.sortedStateListDropDown}
                                                cityData={
                                                    this.state.cityData
                                                }
                                                operationResponse={
                                                    this.props.cityApiResponse
                                                }
                                                onFormValid={this.formSubmit}
                                                onFormReset={this.resetForm}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                    </Row>
                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'city'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    cityList: makeCitySelectField('cityList'),
    cityApiResponse: makeCitySelectField('cityApiResponse'),
    sortedStateListDropDown: selectSortedStateList(),
});
const withConnect = connect(mapStatetoProps, {
    getAllCityListRequest,
    updateCityStatusByCityIdRequest,
    getStateListByStatusRequest,
    addCityRequest,
    updateCityRequest,
    deleteCityByCityIdRequest,
});
export default compose(
    withConnect,
    withCityReducer,
    withCitySaga,
    withStateReducer,
    withStateSaga,
    withRouter
)(Index);
