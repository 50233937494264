import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getUserRoleListRequest
} from 'store/actions';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import { Label } from 'reactstrap';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { makeImportMasterSelectField } from 'store/Masters/ImportMaster/selector';
import FileSaver from 'file-saver';
import { getFullDateTimeFromDateTimeString, getFullDateTimeFromDateTimeStringForSorting } from 'helpers/dateTimeHelper';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import { makeUserSelectField } from 'store/User/selector';
const columns = [
    {
        label: 'Tenant Name',
        field: 'tenantName',
    },
    {
        label: 'Company Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Entity Name',
        field: 'entityName',
    },
    {
        label: 'Role Name',
        field: 'roleName',
    },
    {
        label: 'Role Assigned On',
        field: 'assignedOn',
    },
    {
        label: 'Assigned By',
        field: 'assignedBy',
    }
];
class ViewUserRolesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRoleList: [],
            userId: ''
        }
    }

    getUserRoles = () => {
        this.props.getUserRoleListRequest(this.state.userId);
    }
    generateTable = records => {
        let userRoleRecords = [];
        userRoleRecords = records.map((userRoleRecord, i) => {
            return {
                tenantName: (userRoleRecord.tenant) ? userRoleRecord.tenant?.name : ' - ',
                companyGroupName: (userRoleRecord.companyGroup) ? userRoleRecord.companyGroup?.companyGroupName : ' - ',
                entityName: (userRoleRecord.entity) ? userRoleRecord.entity.entityName : ' - ',
                roleName: userRoleRecord.role.roleName,
                assignedOn: (userRoleRecord.createdAt) ? <span searchvalue={getFullDateTimeFromDateTimeStringForSorting(userRoleRecord.createdAt)}>{getFullDateTimeFromDateTimeString(
                    userRoleRecord.createdAt
                )}</span> : '',
                assignedBy: (userRoleRecord.createdByUser) ? userRoleRecord.createdByUser?.fullName : '',
            };
        })
        return userRoleRecords;
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.activeUserId !== this.props.activeUserId) {
            this.setState({
                userId: nextprops.activeUserId
            }, () => {
                if (this.state.userId !== '') {
                    this.getUserRoles()
                }
            })
        }
        if (nextprops.userRoleList !== this.props.userRoleList) {

            this.setState({
                userRoleList: this.generateTable(nextprops.userRoleList)
            })
        }
    }
    render() {
        const reactTableData = {
            columns: columns,
            rows: this.state.userRoleList,
        };
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider logs_modal'}
                    modalName={__t('common.view_user_roles')}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <CustomisedDatatable
                        className="table table-bordered"
                        sortRows={['assignedOn']}
                        tableRecords={reactTableData}
                    />
                </CustomisedSideModal>
                <DownloadFileByModel
                    modelType='masterUpload'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    userRoleList: makeUserSelectField('userRoleList')
});
const withConnect = connect(mapStatetoProps, {
    getUserRoleListRequest
});
export default compose(
    withConnect,
    withRouter
)(ViewUserRolesModal);