import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'State Name',
        field: 'stateName',
    },
    {
        label: 'Country Name',
        field: 'countryName',
    },
    {
        label: 'Active',
        attributes: {
          class: 'active_switch',
        },
        field: 'isActive',
    },
    {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialStateDataState = () => {
    return {
        stateName: '',
        countryId: '',
        isActive: ACTIVE
    }
}
export const intFields = ['countryId'];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};