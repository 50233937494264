import React, { Component } from 'react';
import { Button, Label, Input, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CLEAR } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Field } from 'formik';
import { createStructuredSelector } from 'reselect';

import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';

import __t from 'i18n/translator';

class AddEditFundTeamsFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            fundList: this.props.fundList,
            formData: {
                userId: [],
                isOwner: false,
                isManagement: false,
                isSubmitter: false,
                isReviewer: false,
                isApprover: false
            }
        };
    }

    resetForm = () => {
        this.setState({
            formData: {
                userId: [],
                isOwner: false,
                isManagement: false,
                isSubmitter: false,
                isReviewer: false,
                isApprover: false
            }
        })
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.resetForm();
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.fundList !== this.props.fundList) {
            this.setState({
                fundList: nextprops.fundList,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="add-project-form form-card row mx-0">
                    <Col lg="5" className='pl-0'>
                        <div className="form-group p-0 mb-0">
                            <Field
                                component={Select}
                                name="userId"
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                className="form-control selectbox"
                                options={this.props.selectSortedUserList?.filter(
                                    (option) => {
                                        let countryArr =
                                            this.state.fundList?.map(
                                                ({ userId, ...rest }) =>
                                                    userId
                                            );
                                        return !countryArr?.includes(
                                            option.value
                                        );
                                    }
                                )}
                                onChange={(opt) => {
                                    let values = this.state.formData;
                                    this.setState({
                                        formData: {
                                            ...values,
                                            userId: (opt) ? opt.map((x) => x.value) : []
                                        }
                                    })
                                }}
                                value={this.props.selectSortedUserList.filter(
                                    (option) =>
                                        (this.state.formData.userId.includes(option.value))
                                )}
                                isClearable={true}
                                isMulti={true}
                                closeMenuOnSelect={false}
                            />
                            <Label className="form-label">
                                {__t('form_labels.funds.user')}
                                <span className="text-danger">*</span>
                            </Label>
                            {this.validator.message(
                                __t('form_labels.projects.user'),
                                this.state.formData.userId,
                                'required',
                                {
                                    messages: {
                                        required: __t(
                                            'validations.projects.user_required'
                                        ),
                                    },
                                }
                            )}
                        </div>
                    </Col>
                    <Col className='d-flex align-items-center px-0 flex-auto'>
                        <div className='fund-team'>
                            <div className="form-group">
                                <Input
                                    type="checkbox"
                                    id="isOwner"
                                    name="isOwner"
                                    checked={this.state.formData.isOwner}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        let values = this.state.formData;

                                        this.setState({
                                            formData: {
                                                ...values,
                                                isOwner: (checked == true) ? true : false
                                            }
                                        })
                                    }}
                                />
                                <label className="form-check-label" for="isOwner">
                                    {__t('form_labels.funds.owner')}
                                </label>
                            </div>
                            <div className="form-group">
                                <Input
                                    type="checkbox"
                                    id="isManagement"
                                    name="isManagement"
                                    checked={this.state.formData.isManagement}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        let values = this.state.formData;

                                        this.setState({
                                            formData: {
                                                ...values,
                                                isManagement: (checked == true) ? true : false
                                            }
                                        })
                                    }}
                                />
                                <label className="form-check-label" for="isManagement">
                                    {__t('form_labels.funds.management')}
                                </label>
                            </div>
                            <div className="form-group">
                                <Input
                                    type="checkbox"
                                    id="isSubmitter"
                                    name="isSubmitter"
                                    checked={this.state.formData.isSubmitter}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        let values = this.state.formData;
                                        if (checked) {
                                            values.isReviewer = false;
                                        }
                                        this.setState({
                                            formData: {
                                                ...values,
                                                isSubmitter: (checked == true) ? true : false
                                            }
                                        })
                                    }}
                                />
                                <label className="form-check-label" for="isSubmitter">
                                    {__t('form_labels.funds.submitter')}
                                </label>
                            </div>
                            <div className="form-group">
                                <Input
                                    type="checkbox"
                                    id="isReviewer"
                                    name="isReviewer"
                                    checked={this.state.formData.isReviewer}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        let values = this.state.formData;
                                        if (checked) {
                                            values.isSubmitter = false;
                                        }
                                        this.setState({
                                            formData: {
                                                ...values,
                                                isReviewer: (checked == true) ? true : false
                                            }
                                        })
                                    }}
                                />
                                <label className="form-check-label" for="isReviewer">
                                    {__t('form_labels.funds.reviewer')}
                                </label>
                            </div>
                            <div className="form-group">
                                <Input
                                    type="checkbox"
                                    id="isApprover"
                                    name="isApprover"
                                    checked={this.state.formData.isApprover}
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        let values = this.state.formData;
                                        if (checked) {
                                            values.isSubmitter = false;
                                        }
                                        this.setState({
                                            formData: {
                                                ...values,
                                                isApprover: (checked == true) ? true : false
                                            }
                                        })
                                    }}
                                />
                                <label className="form-check-label" for="isApprover">
                                    {__t('form_labels.funds.approver')}
                                </label>
                            </div>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center px-0">
                        <Button
                            type="button"
                            color='secondary'
                            outline
                            title="Cancel"
                            className='mr-2'
                            onClick={() => {
                                this.resetForm()
                            }
                            }
                        >
                            {CLEAR}
                        </Button>
                        <Button
                            type="button"
                            color='primary'
                            onClick={() => {
                                this.formSubmit(this.state.formData);
                            }}
                        >
                            Submit
                        </Button>
                    </Col>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({});
const withConnect = connect(mapStatetoProps, {});
export default compose(withConnect, withRouter)(AddEditFundTeamsFormModal);
