import { DATE_FORMAT_DATEPICKER } from 'constants/commonConstants';
import __t from 'i18n/translator';
import moment from 'moment';
export const getDateStringFromDateObject = (value) => {
    return moment(value, 'YYYY-MM-DD').toDate();
};
export const getDateFromDateTimeStringOfDatepicker = (value) => {
    return moment(value, 'ddd MMM DD YYYY HH:mm:ss ZZ').format(
        DATE_FORMAT_DATEPICKER
    );
};
export const getDateFromDateTimeString = (value) => {
    return moment(value, 'DD-MM-YYYY').format('DD-MM-YYYY');
};
export const getDateFromString = (value) => {
    return moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
};
export const getTimeFromTimeString = (value) => {
    return moment(value, 'HH:mm').format('HH:mm');
};
export const getTimeFromDateTimeString = (value) => {
    return moment(value, 'DD-MM-YYYY HH:mm:ss').format('HH:mm');
};
export const getFullDateTimeFromDateTimeString = (value) => {
    return moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
};

export const getFullDateTimeFromDateTimeStringForSorting = (value) => {
    return moment(value, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
};
export const getCurrentDayDateString = () => {
    let day = moment(new Date()).format('DD');
    let month = moment(new Date()).format('MM');
    let year = moment(new Date()).format('YYYY');
    return { day, month, year };
};
export const getPreviousDayDateString = () => {
    let day = moment(new Date()).subtract(1, 'day').format('DD');
    let month = moment(new Date()).format('MM');
    let year = moment(new Date()).format('YYYY');
    return { day, month, year };
};
export const getDateTimeString = (
    value,
    currentMessageDay,
    currentMessageMonth,
    currentMessageYear
) => {
    let result,
        currentDateString = getCurrentDayDateString(),
        previousDateString = getPreviousDayDateString();
    if (
        currentMessageDay == currentDateString.day &&
        currentMessageMonth == currentDateString.month &&
        currentMessageYear == currentDateString.year
    )
        result = 'Today';
    else if (
        currentMessageDay == previousDateString.day &&
        currentMessageMonth == previousDateString.month &&
        currentMessageYear == previousDateString.year
    )
        result = 'Yesterday';
    else result = moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY');
    return result;
};
export const getYearFromDateTimeString = (value) => {
    return moment(value, 'DD-MM-YYYY HH:mm:ss').format('YYYY');
};
export const getMonthFromDateTimeString = (value) => {
    return moment(value, 'DD-MM-YYYY HH:mm:ss').format('MM');
};
export const getDayFromDateTimeString = (value) => {
    return moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD');
};
export const getDateObjectFromMoment = () => {
    return moment().toDate();
};
export const getDateObjectWithValueFromMoment = (value) => {
    return moment(value, 'DD-MM-YYYY').toDate();
};
export const getCurrentDate = () => {
    return moment(new Date()).format('DD-MM-YYYY');
};

export const getCurrentDateForReportPDF = () => {
    return moment(new Date()).format('DD-MM-YYYY');
};

export const getCurrentDateInFormat = () => {
    return moment(new Date()).format('YYYY-MM-DD');
};
export const getCurrentDateTime = () => {
    return moment(new Date()).format('DD-MM-YYYY-HH:mm:ss');
};

export const getFirstDateOfMonth = () => {
    return moment(new Date()).startOf('month').toDate();
};
export const getLastDateBy3MonthAdd = () => {
    return moment(new Date()).add(3, 'months').endOf('month').toDate();
};
export const getAddDaysDate = (addDays, startDate, noWeekEnd = false) => {
    let WEEKEND = [
        moment().day('Saturday').weekday(),
        moment().day('Sunday').weekday(),
    ];
    if (noWeekEnd) {
        WEEKEND = []
    }
    var daysAdded = 1,
        momentDate = moment(startDate);
    while (daysAdded < addDays) {
        momentDate = momentDate.add(1, 'days');
        if (!WEEKEND.includes(momentDate.weekday())) {
            daysAdded++;
        }
    }
    return getDateObjectWithValueFromMoment(momentDate);
};
export const getFirstDateOfCurrentMonth = () => {
    return moment().startOf('month').format('DD-MM-YYYY');
};
export const getFirstDateOfCurrentWeek = () => {
    return moment().startOf('week').format('DD-MM-YYYY');
};
export const getCurrentTime = () => {
    return moment().format('HH:mm');
};

export const getFirstDateOfMonthByDate = (date) => {
    return moment(date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD');
};
export const getLastDateOfMonthByDate = (date) => {
    return moment(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');
};
export const isValidDate = (value) => {
    let result;
    if (value !== '__-__-____' && value !== '') {
        // as input date mask has '__-__-____' value by default
        result = moment(value, 'DD-MM-YYYY', true).isValid();
    } else {
        // if it has default value then return true, as it will be blank and will be handled by the required validator if specified
        result = true;
    }
    return result;
};
export const isValidTime = (value) => {
    let result;
    if (value !== '__:__' && value !== '') {
        // as input time mask has '__:__' value by default
        result = moment(value, 'HH:mm', true).isValid();
    } else {
        // if it has default value then return true, as it will be blank and will be handled by the required validator if specified
        result = true;
    }
    return result;
};
export const customDateValidator = () => {
    return {
        customDate: {
            // name the rule
            message:
                ':attribute ' + __t('validations.custom.custom_date_valid'),
            rule: (val, params, validator) => {
                return isValidDate(val);
            },
            messageReplace: (message, params) =>
                message.replace(':values', this.helpers.toSentence(params)), // optional
            required: true, // optional
        },
    };
};
export const customTimeValidator = () => {
    return {
        customTime: {
            // name the rule
            message: 'The :attribute must be a valid time.',
            rule: (val, params, validator) => {
                return isValidTime(val);
            },
        },
    };
};
export const getDayName = (date) => {
    let dateTime;
    dateTime = moment(date, 'DD-MM-YYYY');
    return dateTime.format('dddd');
};

export const getDayDateName = (date) => {
    let dateTime;
    dateTime = moment(date, 'DD-MM-YYYY');
    return dateTime.format('DD');
};

export const getMonthName = (date) => {
    let dateTime;
    dateTime = moment(date, 'DD-MM-YYYY');
    return dateTime.format('MMM');
};

export const getYearName = (date) => {
    let dateTime;
    dateTime = moment(date, 'DD-MM-YYYY');
    return dateTime.format('YYYY');
};

export const getAddDaysIntoGivenDate = (values, date) => {
    return moment(date, 'DD-MM-YYYY').add(values, 'days').toDate();
};
export const getStartDateOfCurrentFinancialYear = () => {
    //get current month in number formate for comparision
    let currentMonth = moment(new Date()).format('M');
    //get last year
    let lastYear = moment().subtract(1, 'year').format('YYYY');
    // Check the current month and based on that get the start date of current financial year
    // compare currentMonth with the 4(April) as 4(April) is the starting month of the current financial year
    if (currentMonth >= 4) {
        return moment().month(3).startOf('month').format('DD-MM-YYYY');
    } else {
        return moment()
            .year(lastYear)
            .month(3)
            .startOf('month')
            .format('DD-MM-YYYY');
    }
};
export const lessThanDateValidator = () => {
    return {
        lessThanDate: {
            // name the rule
            message: 'The :attribute can not be less than from date.',
            rule: (val, params) => {
                let fromDate = moment(params[0], 'DD-MM-YYYY').unix();
                let toDate = moment(val, 'DD-MM-YYYY').unix();
                return fromDate <= toDate ? true : false;
            },
            required: true, // optional
        },
    };
};

export function formatDateForApi(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
}
export function formatDateForYearApi(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return year;
}
export function formatDateForApiSystem(date) {
    return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
}
// new Date(Date.UTC(0, 0, cell.value - 1, 0, 0, 0))

export const getDateFrom = (value) => {
    return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
};

export const getDateFromFormat = (value) => {
    return moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
};

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const getSixMonthNameWiseData = (add = false) => {
    let monthWiseData = [];
    let date = '';
    if (add === true) {
        for (let i = 0; i < 6; i++) {
            date = moment()
                .add(i + 1, 'M')
                .toDate();
            let monthNumber = date.getMonth();
            if (monthNumber === 0) {
                monthNumber = 12;
            }
            monthWiseData.push(monthNames[monthNumber - 1]);
        }
    } else {
        for (let i = 6; i > 0; i -= 1) {
            let o = i;
            date = moment()
                .subtract(o - 1, 'M')
                .toDate();
            let monthNumber = date.getMonth();
            if (monthNumber === 0) {
                monthNumber = 12;
            }
            monthWiseData.push(monthNames[monthNumber - 1]);
        }
    }
    return monthWiseData;
};
export const getSixMonthWiseData = (add = false) => {
    let monthWiseData = [];
    let date = '';
    if (add === true) {
        for (let i = 0; i < 6; i++) {
            date = moment()
                .add(i + 1, 'M')
                .toDate();
            let monthNumber = date.getMonth();
            if (monthNumber === 0) {
                monthNumber = 12;
            }
            monthWiseData.push(monthNumber);
        }
    } else {
        for (let i = 6; i > 0; i -= 1) {
            let o = i;
            date = moment()
                .subtract(o - 1, 'M')
                .toDate();
            let monthNumber = date.getMonth();
            if (monthNumber === 0) {
                monthNumber = 12;
            }
            monthWiseData.push(monthNumber);
        }
    }
    return monthWiseData;
};

export const getSixMonthYearWiseData = (add = false) => {
    let monthWiseData = [];
    let date = '';
    if (add === true) {
        for (let i = 0; i < 6; i++) {
            date = moment()
                .add(i + 1, 'M')
                .toDate();
            let monthNumber = date.getMonth();
            let monthYearNumber = moment(date).year();
            if (monthNumber === 0) {
                monthNumber = 12;
                monthYearNumber = monthYearNumber - 1;
            }
            monthWiseData.push(monthNumber + '-' + monthYearNumber);
        }
    } else {
        for (let i = 6; i > 0; i -= 1) {
            let o = i;
            date = moment()
                .subtract(o - 1, 'M')
                .toDate();
            let monthNumber = date.getMonth();
            let monthYearNumber = moment(date).year();
            if (monthNumber === 0) {
                monthNumber = 12;
                monthYearNumber = monthYearNumber - 1;
            }
            monthWiseData.push(monthNumber + '-' + monthYearNumber);
        }
    }
    return monthWiseData;
};

export const getDateTimeFromDateTimeString = (value) => {
    return moment(value, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm');
};

export const diffYMDHMS = (date1, date2) => {
    if (!date2) {
        return '-';
    }

    var a = moment(date2, 'DD-MM-YYYY HH:mm:ss');
    var b = moment(date1, 'DD-MM-YYYY HH:mm:ss');
    if (a <= b) {
        return '-';
    }
    // Calculate the duration between the dates
    var duration = moment.duration(a.diff(b));

    // Extract the individual components of the duration
    var diffDays = duration.days();
    var diffHours = duration.hours();
    var diffMinutes = duration.minutes();
    var diffSeconds = duration.seconds();

    // Construct the display string
    var displayString = '';

    if (diffDays > 0) {
        displayString += diffDays + ' days, ';
    }

    if (diffHours > 0) {
        displayString += diffHours + ' hours, ';
    }

    if (diffMinutes > 0) {
        displayString += diffMinutes + ' minutes, ';
    }
    displayString += diffSeconds + ' seconds'; // Output: 5 days, 0 hours, 0 minutes, 0 seconds
    return displayString;
};

export const diffYMDHMSInSeconds = (date1, date2) => {
    if (!date2) {
        return false;
    }
    var a = moment(date2, 'DD-MM-YYYY HH:mm:ss');
    var b = moment(date1, 'DD-MM-YYYY HH:mm:ss');
    if (a <= b) {
        return false;
    }
    return a.diff(b);
};

export const getNextDayDate = () => {
    // To add Days
    var d = new Date();
    d.setDate(d.getDate() + 1);
    return d;
};

export const dateDifference = (date1, date2) => {
    if (!date1 || !date2) {
        return '-';
    }

    // Parse the dates using moment
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);

    // Check if date2 is before or equal to date1
    if (momentDate2.isSameOrBefore(momentDate1)) {
        return '-';
    }

    // Calculate the difference in days
    const diffDays = momentDate2.diff(momentDate1, 'days');
    if (isNaN(diffDays)) {
        return '-';
    } else {
        return diffDays + ' days';
    }
};

export const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${day}-${month}-${year}`;
}

