import { parseFloatHelper } from 'helpers/mathHelper';
import __t from 'i18n/translator';
export const isValidEmail = (email) => {
    const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email) ? true : false;
};
export const customEmailvalidator = () => {
    return {
        customEmail: {
            // name the rule
            message: 'The :attribute must be a valid Email.',
            rule: (val, params, validator) => {
                return isValidEmail(val);
            },
        },
    };
};
// allow only character and number
export const customValidatorForOnlyCharacterAndNumber = () => {
    return {
        onlyCharacterAndNumber: {
            // name the rule
            message: 'The :attribute allow only character and number.',
            rule: (val, params, validator) => {
                const regexp = /^[ A-Za-z0-9]*$/;
                return regexp.test(val) ? true : false;
            },
        },
    };
};
export const greaterThanValidator = () => {
    return {
        greaterThan: {
            // name the rule
            message: 'The :attribute must be greater than :values.',
            rule: (val, params) => {
                return parseFloatHelper(val) > parseFloatHelper(params[0])
                    ? true
                    : false;
            },
            messageReplace: (message, params) =>
                message.replace(':values', params[0]), // optional
            required: true, // optional
        },
    };
};
export const greaterThanEqualToValidator = () => {
    return {
        greaterThanEqualTo: {
            // name the rule
            message: 'The :attribute must be greater than or equal to :values.',
            rule: (val, params) => {
                return parseFloatHelper(val) >= parseFloatHelper(params[0])
                    ? true
                    : false;
            },
            messageReplace: (message, params) =>
                message.replace(':values', params[0]), // optional
            required: true, // optional
        },
    };
};
export const lessThanValidator = () => {
    return {
        lessThan: {
            // name the rule
            message: 'The :attribute must be less than :values.',
            rule: (val, params) => {
                return parseFloatHelper(val) < parseFloatHelper(params[0])
                    ? true
                    : false;
            },
            messageReplace: (message, params) =>
                message.replace(':values', params[0]), // optional
            required: true, // optional
        },
    };
};
export const lessThanEqualToValidator = () => {
    return {
        lessThanEqualTo: {
            // name the rule
            message: 'The :attribute must be less than or equal to :values.',
            rule: (val, params) => {
                return parseFloatHelper(val) <= parseFloatHelper(params[0])
                    ? true
                    : false;
            },
            messageReplace: (message, params) =>
                message.replace(':values', params[0]), // optional
            required: true, // optional
        },
    };
};
//  validation function to check the value of input is valid or not
export const isValidNumericField = (value, precision, scale) => {
    var regex = new RegExp(
        '^(0|-?\\d{0,' + (precision - scale) + '}(\\.\\d{0,' + scale + '})?)$',
        'i'
    );
    return regex.test(value) ? true : false;
};
//  validation function to check the value of input is valid or not
export const isValidCharacterField = (value, maxLength) => {
    var regex = new RegExp(`^.{0,${maxLength}}$`);
    return regex.test(value) ? true : false;
};
export const customImageValidator = (value) => {
    return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(value) ? true : false;
};
// allow only decimal number with comma seperated
export const customValidatorForOnlyDecimalNumberWithComma = () => {
    return {
        onlyDecimalNumberWithComma: {
            // name the rule
            message:
                'The :attribute allow only comma seperated decimal numbers.',
            rule: (val, params, validator) => {
                const regexp = /^[,.0-9]*$/;
                return regexp.test(val) ? true : false;
            },
        },
    };
};
export const customFileValidator = (value) => {
    return /\.(xlsx|xls)$/i.test(value) ? true : false;
};

const validateFileFormat = (value) => {
    return /\.(zip|pdf|jpg|jpeg|png|txt|doc|docx|csv|xlsx|xls)$/i.test(value) ? true : false;
};

export const customEntityFileValidator = (value) => {
    return validateFileFormat(value);
};

const validateImageFileFormat = (value) => {
    return /\.(jpg|jpeg|png)$/i.test(value) ? true : false;
};

export const customReportLogoFileValidator = (value) => {
    return validateImageFileFormat(value);
};

export const customDocumentFileValidator = (value) => {
    return validateFileFormat(value);
};

export const customChatFileValidator = (value) => {
    return validateFileFormat(value);
};

export const customProofFileValidator = (value) => {
    return validateFileFormat(value);
};

export const customSubTaskAttachmentFileValidator = (value) => {
    return validateFileFormat(value);
};

export const customTicketChatFileValidator = (value) => {
    return validateFileFormat(value);
};
export const isStrongPassword = (password) => {

    const regexp =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=_!@#\$%\^&\*])(?=.{16,})/;
    return regexp.test(password) ? true : false;
};
export const customValidatorForStrongPassword = () => {
    return {
        strongPassword: {
            // name the rule
            message: ':attribute ' + __t('validations.custom.strong_password'),
            rule: (val, params, validator) => {
                return isStrongPassword(val);
            },
        },
    };
};
export const isValidCharacterValField = (text) => {
    const regexp = /^[a-zA-Z @._,()&-]+$/;
    return regexp.test(text) ? true : false;
};
export const customValidTextField = () => {
    return {
        onlyCharacters: {
            message: ':attribute ' + __t('validations.custom.only_characters'),
            rule: (val, params, validator) => {
                return isValidCharacterValField(val);
            },
        },
    };
};

export const isValidCharacterForSpecificValField = (text) => {
    const regexp = /^[a-zA-Z0-9 @._,()&-]+$/;
    return regexp.test(text) ? true : false;
};
export const customValidTextForSpecificField = () => {
    return {
        onlyCharactersForSpecific: {
            message: ':attribute ' + __t('validations.custom.only_characters_specific'),
            rule: (val, params, validator) => {
                return isValidCharacterForSpecificValField(val);
            },
        },
    };
};


export const isValidCharacterForEntityNameField = (text) => {
    const regexp = /^[a-zA-Z0-9 @._,()&-]+$/;
    return regexp.test(text) ? true : false;
};
export const customValidTextForEntityNameField = () => {
    return {
        onlyCharactersForEntityName: {
            message: ':attribute ' + __t('validations.custom.only_characters_entity_name'),
            rule: (val, params, validator) => {
                return isValidCharacterForEntityNameField(val);
            },
        },
    };
};

export const isValidCharacterForTenantNameField = (text) => {
    const regexp = /^[a-zA-Z0-9 @._,()&-]+$/;
    return regexp.test(text) ? true : false;
};
export const customValidTextForTenantNameField = () => {
    return {
        onlyCharactersForTenantName: {
            message: __t('validations.custom.only_characters_tenant_name'),
            rule: (val, params, validator) => {
                return isValidCharacterForTenantNameField(val);
            },
        },
    };
};

export const isValidDbNameField = (text) => {
    const regexp = /^[a-zA-Z0-9_]+$/;
    return regexp.test(text) ? true : false;
};
export const customValidTextForDbNameField = () => {
    return {
        validDbName: {
            message: __t('validations.custom.only_characters_tenant_db_name'),
            rule: (val, params, validator) => {
                return isValidDbNameField(val);
            },
        },
    };
};

export const isValidDbHostField = (text) => {
    const regexp = /^[a-z0-9.]+$/;
    return regexp.test(text) ? true : false;
};
export const customValidTextForDbHostField = () => {
    return {
        validDbHost: {
            message: __t('validations.custom.only_characters_tenant_db_host'),
            rule: (val, params, validator) => {
                return isValidDbHostField(val);
            },
        },
    };
};

export const isValidDbUserField = (text) => {
    const regexp = /^[a-z0-9.]+$/;
    return regexp.test(text) ? true : false;
};
export const customValidTextForDbUserField = () => {
    return {
        validDbUser: {
            message: __t('validations.custom.only_characters_tenant_db_user'),
            rule: (val, params, validator) => {
                return isValidDbUserField(val);
            },
        },
    };
};
export const isValidPhoneValField = (text) => {
    const regexp = /^([(]?[+]?\d{1,3}[)]?[-\s]?|)\d{7,11}$/;
    return regexp.test(text) ? true : false;
};
export const customValidPhoneField = () => {
    return {
        customPhone: {
            message: __t('validations.custom.custom_phone'),
            rule: (val, params, validator) => {
                return isValidPhoneValField(val);
            },
        },
    };
};

export const customProjectTaskFileValidator = (value) => {
    return validateFileFormat(value);

};

export const customValidatorForUserName = () => {
    return {
        customUserName: {
            message: __t('validations.custom.custom_username'),
            rule: (val, params, validator) => {
                const regexp = /^(?=[a-zA-Z0-9.@_-]{6,60}$)(?!.*[.@_-]{4})[^.@_-].*[^_.@-]$/g;
                return regexp.test(val) ? true : false;
            },
        },
    };
};

export const legalUpdatesDocumentFileValidator = (value) => {
    return /\.(jpeg|png|zip|pdf|jpg|csv|txt|doc|docx|xlsx|xls)$/i.test(value)
        ? true
        : false;
};

export const isValidForSectionNameField = (text) => {
    const regexp = /^[A-Za-z0-9.(); ]*$/;
    return regexp.test(text) ? true : false;
};
export const customValidatorForSectionName = () => {
    return {
        onlySectionName: {
            // name the rule
            message: __t('validations.custom.custom_section_name'),
            rule: (val, params, validator) => {
                return isValidForSectionNameField(val)
            },
        },
    };
};

export const customFundTaskFileValidator = (value) => {
    return validateFileFormat(value);
};
