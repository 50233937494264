import { ADD_COUNTRY_REQUEST, ADD_COUNTRY_RESPONSE, DELETE_COUNTRY_BY_COUNTRY_ID_REQUEST, DELETE_COUNTRY_BY_COUNTRY_ID_RESPONSE, GET_ALL_COUNTRY_LIST_REQUEST, GET_ALL_COUNTRY_LIST_RESPONSE, GET_COUNTRY_BY_COUNTRY_ID_REQUEST, GET_COUNTRY_BY_COUNTRY_ID_RESPONSE, GET_COUNTRY_LIST_BY_STATUS_REQUEST, GET_COUNTRY_LIST_BY_STATUS_RESPONSE, UPDATE_COUNTRY_REQUEST, UPDATE_COUNTRY_RESPONSE, UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_REQUEST, UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_RESPONSE, GET_GCM_AUTH_USER_COUNTRY_LIST_REQUEST, GET_GCM_AUTH_USER_COUNTRY_LIST_RESPONSE } from "./actionTypes";
export const addCountryRequest = data => {
    return {
        type: ADD_COUNTRY_REQUEST,
        payload: { data },
    };
};
export const addCountryResponse = (message, responseType) => {
    return {
        type: ADD_COUNTRY_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllCountryListRequest = () => {
    return {
        type: GET_ALL_COUNTRY_LIST_REQUEST
    };
};
export const getAllCountryListResponse = countryList => {
    return {
        type: GET_ALL_COUNTRY_LIST_RESPONSE,
        payload: { countryList },
    };
};
export const getCountryByCountryIdRequest = (countryId) => {
    return {
        type: GET_COUNTRY_BY_COUNTRY_ID_REQUEST,
        payload: { countryId: countryId },
    };
};
export const getCountryByCountryIdResponse = (message, responseType, data) => {
    return {
        type: GET_COUNTRY_BY_COUNTRY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateCountryRequest = (countryId, data) => {
    return {
        type: UPDATE_COUNTRY_REQUEST,
        payload: { countryId, data },
    };
};
export const updateCountryResponse = (message, responseType) => {
    return {
        type: UPDATE_COUNTRY_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateCountryStatusByCountryIdRequest = (countryId, status) => {
    return {
        type: UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_REQUEST,
        payload: { countryId, status },
    };
};
export const updateCountryStatusByCountryIdResponse = (message, responseType) => {
    return {
        type: UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getCountryListByStatusRequest = status => {
    return {
        type: GET_COUNTRY_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getCountryListByStatusResponse = countryList => {
    return {
        type: GET_COUNTRY_LIST_BY_STATUS_RESPONSE,
        payload: { countryList },
    };
};
export const deleteCountryByCountryIdRequest = (countryId) => {
    return {
        type: DELETE_COUNTRY_BY_COUNTRY_ID_REQUEST,
        payload: { countryId },
    };
};
export const deleteCountryByCountryIdResponse = (message, responseType) => {
    return {
        type: DELETE_COUNTRY_BY_COUNTRY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getGcmAuthUserCountryListRequest = () => {
    return {
        type: GET_GCM_AUTH_USER_COUNTRY_LIST_REQUEST
    };
};
export const getGcmAuthUserCountryListResponse = (list) => {
    return {
        type: GET_GCM_AUTH_USER_COUNTRY_LIST_RESPONSE,
        payload: { list },
    };
};