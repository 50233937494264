export const GET_CHAT_LIST_BY_TASK_ID_REQUEST = 'get_chat_list_by_task_id_request';
export const GET_CHAT_LIST_BY_TASK_ID_RESPONSE = 'get_chat_list_by_task_id_response';
export const GET_MESSAGE_LIST_BY_CHAT_ID_REQUEST = 'get_message_list_by_chat_id_request';
export const GET_MESSAGE_LIST_BY_CHAT_ID_RESPONSE = 'get_message_list_by_chat_id_response';
export const ADD_NEW_MESSAGE_REQUEST = 'add_new_message_request';
export const ADD_NEW_MESSAGE_RESPONSE = 'add_new_message_response';
export const GET_TASK_USERS_REQUEST = 'get_task_users_request';
export const GET_TASK_USERS_RESPONSE = 'get_task_users_response';
export const ADD_OR_REMOVE_USER_REQUEST = 'add_or_remove_user_request';
export const ADD_OR_REMOVE_USER_RESPONSE = 'add_or_remove_user_response';
export const CREATE_CHAT_REQUEST = 'create_chat_request';
export const CREATE_CHAT_RESPONSE = 'create_chat_response';
export const RENAME_GROUP_REQUEST = 'rename_group_request';
export const RENAME_GROUP_RESPONSE = 'rename_group_response';