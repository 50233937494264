import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.user || initialState;
const makeUserSelectField = field =>
    createSelector(reducerState, userState => userState[field]);
const selectSortedUserList = () =>
    createSelector(makeUserSelectField('userList'), userList =>
        userList?.map(opt => ({
            value: opt.id,
            label: opt.fullName + ' - ' + opt.email,
            fullName: opt.fullName,
            email: opt.email,
        }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeUserSelectField,
    selectSortedUserList
};
