import {
    DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_REQUEST,
    DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_RESPONSE,
    GET_ENTITY_ROLE_LIST_REQUEST,
    GET_ENTITY_ROLE_LIST_RESPONSE,
    GET_MODULE_LIST_REQUEST,
    GET_MODULE_LIST_RESPONSE,
    GET_PERMISSION_LIST_REQUEST,
    GET_PERMISSION_LIST_RESPONSE,
    GET_ROLE_BY_ROLE_ID_REQUEST,
    GET_ROLE_BY_ROLE_ID_RESPONSE,
    GET_ROLE_LIST_REQUEST,
    GET_ROLE_LIST_RESPONSE,
    GET_ROLE_PERMISSION_BY_LOGIN_USER_REQUEST,
    GET_ROLE_PERMISSION_BY_LOGIN_USER_RESPONSE,
    GET_SYSTEM_CONFIGURATION_ROLE_LIST_REQUEST,
    GET_SYSTEM_CONFIGURATION_ROLE_LIST_RESPONSE,
    GET_SYSTEM_CONFIGURATION_USERS_LIST_REQUEST,
    GET_SYSTEM_CONFIGURATION_USERS_LIST_RESPONSE,
    SAVE_SYSTEM_CONFIGURATION_USERS_REQUEST,
    SAVE_SYSTEM_CONFIGURATION_USERS_RESPONSE,
    UPDATE_ROLE_BY_ROLE_ID_REQUEST,
    UPDATE_ROLE_BY_ROLE_ID_RESPONSE,
    UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_REQUEST,
    UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_RESPONSE,
    GET_USER_ROLE_WISE_COUNTRIES_LIST_REQUEST,
    GET_USER_ROLE_WISE_COUNTRIES_LIST_RESPONSE,
    GET_GCM_USERS_BY_COUNTRY_REQUEST,
    GET_GCM_USERS_BY_COUNTRY_RESPONSE,
} from './actionTypes';
export const getRoleListRequest = () => {
    return {
        type: GET_ROLE_LIST_REQUEST,
    };
};
export const getRoleListResponse = (roleList) => {
    return {
        type: GET_ROLE_LIST_RESPONSE,
        payload: { roleList },
    };
};
export const getModuleListRequest = () => {
    return {
        type: GET_MODULE_LIST_REQUEST,
    };
};
export const getModuleListResponse = (moduleList) => {
    return {
        type: GET_MODULE_LIST_RESPONSE,
        payload: { moduleList },
    };
};
export const getPermissionListRequest = () => {
    return {
        type: GET_PERMISSION_LIST_REQUEST,
    };
};
export const getPermissionListResponse = (permissionList) => {
    return {
        type: GET_PERMISSION_LIST_RESPONSE,
        payload: { permissionList },
    };
};

export const getRoleByRoleIdRequest = (roleId) => {
    return {
        type: GET_ROLE_BY_ROLE_ID_REQUEST,
        payload: { roleId },
    };
};
export const getRoleByRoleIdResponse = (message, responseType, data) => {
    return {
        type: GET_ROLE_BY_ROLE_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateRoleByRoleIdRequest = (data) => {
    return {
        type: UPDATE_ROLE_BY_ROLE_ID_REQUEST,
        payload: { data },
    };
};
export const updateRoleByRoleIdResponse = (message, responseType) => {
    return {
        type: UPDATE_ROLE_BY_ROLE_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getRolePermissionByLoginUserRequest = () => {
    return {
        type: GET_ROLE_PERMISSION_BY_LOGIN_USER_REQUEST,
    };
};
export const getRolePermissionByLoginUserResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_ROLE_PERMISSION_BY_LOGIN_USER_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const getEntityRoleListRequest = () => {
    return {
        type: GET_ENTITY_ROLE_LIST_REQUEST,
    };
};
export const getEntityRoleListResponse = (roleList) => {
    return {
        type: GET_ENTITY_ROLE_LIST_RESPONSE,
        payload: { roleList },
    };
};

export const getSystemConfigurationRoleListRequest = () => {
    return {
        type: GET_SYSTEM_CONFIGURATION_ROLE_LIST_REQUEST,
    };
};
export const getSystemConfigurationRoleListResponse = (roleList) => {
    return {
        type: GET_SYSTEM_CONFIGURATION_ROLE_LIST_RESPONSE,
        payload: { roleList },
    };
};

export const saveSystemConfigurationUsersRequest = (data) => {
    return {
        type: SAVE_SYSTEM_CONFIGURATION_USERS_REQUEST,
        payload: { data },
    };
};
export const saveSystemConfigurationUsersResponse = (message, responseType) => {
    return {
        type: SAVE_SYSTEM_CONFIGURATION_USERS_RESPONSE,
        payload: { message, responseType },
    };
};

export const getSystemConfigurationUsersListRequest = () => {
    return {
        type: GET_SYSTEM_CONFIGURATION_USERS_LIST_REQUEST,
    };
};
export const getSystemConfigurationUsersListResponse = (
    systemConfigurationUserList
) => {
    return {
        type: GET_SYSTEM_CONFIGURATION_USERS_LIST_RESPONSE,
        payload: { systemConfigurationUserList },
    };
};

export const updateSystemConfigurationUserRoleStatusByUserRoleIdRequest = (
    userRoleId,
    status
) => {
    return {
        type: UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_REQUEST,
        payload: { userRoleId, status },
    };
};
export const updateSystemConfigurationUserRoleStatusByUserRoleIdResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteSystemConfigurationUserRoleByUserRoleIdRequest = (
    userRoleId
) => {
    return {
        type: DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_REQUEST,
        payload: { userRoleId },
    };
};
export const deleteSystemConfigurationUserRoleByUserRoleIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getUserRoleWiseCountriesListRequest = (data) => {
    return {
        type: GET_USER_ROLE_WISE_COUNTRIES_LIST_REQUEST,
        payload: { data },
    };
};
export const getUserRoleWiseCountriesListResponse = (countryList) => {
    return {
        type: GET_USER_ROLE_WISE_COUNTRIES_LIST_RESPONSE,
        payload: { countryList },
    };
};



export const getGcmUsersByCountryRequest = (data) => {
    return {
        type: GET_GCM_USERS_BY_COUNTRY_REQUEST,
        payload: { data },
    };
};
export const getGcmUsersByCountryResponse = (userList) => {
    return {
        type: GET_GCM_USERS_BY_COUNTRY_RESPONSE,
        payload: { userList },
    };
};
