export const ADD_INDUSTRY_REQUEST = 'add_industry_request';
export const ADD_INDUSTRY_RESPONSE = 'add_industry_response';
export const GET_ALL_INDUSTRY_LIST_REQUEST = 'get_all_industry_list_request';
export const GET_ALL_INDUSTRY_LIST_RESPONSE = 'get_all_industry_list_response';
export const GET_INDUSTRY_BY_INDUSTRY_ID_REQUEST = 'get_industry_by_industry_id_request';
export const GET_INDUSTRY_BY_INDUSTRY_ID_RESPONSE = 'get_industry_by_industry_id_response';
export const UPDATE_INDUSTRY_REQUEST = 'update_industry_request';
export const UPDATE_INDUSTRY_RESPONSE = 'update_industry_response';
export const UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_REQUEST = 'update_industry_status_by_industry_id_request';
export const UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_RESPONSE = 'update_industry_status_by_industry_id_response';
export const GET_INDUSTRY_LIST_BY_STATUS_REQUEST = 'get_industry_list_by_status_request';
export const GET_INDUSTRY_LIST_BY_STATUS_RESPONSE = 'get_industry_list_by_status_response';
export const DELETE_INDUSTRY_BY_INDUSTRY_ID_REQUEST = 'delete_industry_by_industry_id_request';
export const DELETE_INDUSTRY_BY_INDUSTRY_ID_RESPONSE = 'delete_industry_by_industry_id_response';