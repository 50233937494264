export const GET_PROJECT_TASK_USERS_BY_ENTITY_REQUEST =
    'get_project_task_users_by_entity_request';
export const GET_PROJECT_TASK_USERS_BY_ENTITY_RESPONSE =
    'get_project_task_users_by_entity_response';

export const ADD_PROJECT_REQUEST = 'add_project_request';
export const ADD_PROJECT_RESPONSE = 'add_project_response';

export const GET_PROJECT_LIST_REQUEST = 'get_project_list_request';
export const GET_PROJECT_LIST_RESPONSE = 'get_project_list_response';

export const GET_PROJECT_DETAILS_REQUEST = 'get_project_details_request';
export const GET_PROJECT_DETAILS_RESPONSE = 'get_project_details_response';

export const UPDATE_PROJECT_REQUEST = 'update_project_request';
export const UPDATE_PROJECT_RESPONSE = 'update_project_response';

export const DELETE_PROJECT_BY_PROJECT_ID_REQUEST =
    'delete_project_by_project_id_request';
export const DELETE_PROJECT_BY_PROJECT_ID_RESPONSE =
    'delete_project_by_project_id_response';

export const GET_PROJECT_TASK_LIST_REQUEST = 'get_project_task_list_request';
export const GET_PROJECT_TASK_LIST_RESPONSE = 'get_project_task_list_response';

export const ADD_PROJECT_TASK_REQUEST = 'add_project_task_request';
export const ADD_PROJECT_TASK_RESPONSE = 'add_project_task_response';

export const UPDATE_PROJECT_TASK_REQUEST = 'update_project_task_request';
export const UPDATE_PROJECT_TASK_RESPONSE = 'update_project_task_response';

export const DELETE_PROJECT_TASK_BY_TASK_ID_REQUEST =
    'delete_project_task_by_task_id_request';
export const DELETE_PROJECT_TASK_BY_TASK_ID_RESPONSE =
    'delete_project_task_by_task_id_response';

export const ADD_PROJECT_SUB_TASK_REQUEST = 'add_project_sub_task_request';
export const ADD_PROJECT_SUB_TASK_RESPONSE = 'add_project_sub_task_response';

export const DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_REQUEST =
    'delete_project_sub_task_by_sub_task_id_request';
export const DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_RESPONSE =
    'delete_project_sub_task_by_sub_task_id_response';

export const GET_PROJECT_TASK_DETAILS_REQUEST =
    'get_project_task_details_request';
export const GET_PROJECT_TASK_DETAILS_RESPONSE =
    'get_project_task_details_response';

export const ADD_PROJECT_TASK_COMMENT_REQUEST =
    'add_project_task_comment_request';
export const ADD_PROJECT_TASK_COMMENT_RESPONSE =
    'add_project_task_comment_response';

export const ADD_PROJECT_TASK_ATTACHMENT_REQUEST =
    'add_project_task_attachment_request';
export const ADD_PROJECT_TASK_ATTACHMENT_RESPONSE =
    'add_project_task_attachment_response';

export const GET_PROJECT_TASK_ACTIVITY_REQUEST =
    'get_project_task_activity_request';
export const GET_PROJECT_TASK_ACTIVITY_RESPONSE =
    'get_project_task_activity_response';

export const DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST =
    'delete_project_task_document_by_document_id_request';
export const DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE =
    'delete_project_task_document_by_document_id_response';

export const CLOSE_PROJECT_REQUEST = 'close_project_request';
export const CLOSE_PROJECT_RESPONSE = 'close_project_response';

export const ACTIVE_OR_DEACTIVE_PROJECT_REQUEST = 'active_or_deactive_project_request';
export const ACTIVE_OR_DEACTIVE_PROJECT_RESPONSE = 'active_or_deactive_project_response';