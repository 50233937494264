export const ADD_LAW_REQUEST = 'add_law_request';
export const ADD_LAW_RESPONSE = 'add_law_response';
export const GET_ALL_LAW_LIST_REQUEST = 'get_all_law_list_request';
export const GET_ALL_LAW_LIST_RESPONSE = 'get_all_law_list_response';
export const GET_LAW_BY_LAW_ID_REQUEST = 'get_law_by_law_id_request';
export const GET_LAW_BY_LAW_ID_RESPONSE = 'get_law_by_law_id_response';
export const UPDATE_LAW_REQUEST = 'update_law_request';
export const UPDATE_LAW_RESPONSE = 'update_law_response';
export const UPDATE_LAW_STATUS_BY_LAW_ID_REQUEST = 'update_law_status_by_law_id_request';
export const UPDATE_LAW_STATUS_BY_LAW_ID_RESPONSE = 'update_law_status_by_law_id_response';
export const GET_LAW_LIST_BY_STATUS_REQUEST = 'get_law_list_by_status_request';
export const GET_LAW_LIST_BY_STATUS_RESPONSE = 'get_law_list_by_status_response';
export const DELETE_LAW_BY_LAW_ID_REQUEST = 'delete_law_by_law_id_request';
export const DELETE_LAW_BY_LAW_ID_RESPONSE = 'delete_law_by_law_id_response';
export const GET_ALL_ENTITY_TYPE_LIST_REQUEST = 'get_all_entity_type_list_request';
export const GET_ALL_ENTITY_TYPE_LIST_RESPONSE = 'get_all_entity_type_list_response';
export const GET_ALL_FACILITY_TYPE_LIST_REQUEST = 'get_all_facility_type_list_request';
export const GET_ALL_FACILITY_TYPE_LIST_RESPONSE = 'get_all_facility_type_list_response';
export const GET_LAWS_SEARCH_LIST_REQUEST = 'get_laws_search_list_request';
export const GET_LAWS_SEARCH_LIST_RESPONSE = 'get_laws_search_list_response';
export const GET_LAWS_LIST_REQUEST = 'get_laws_list_request';
export const GET_LAWS_LIST_RESPONSE = 'get_laws_list_response';
export const GET_COUNTRY_COVERAGE_SEARCH_LIST_REQUEST = 'get_country_coverage_search_list_request';
export const GET_COUNTRY_COVERAGE_SEARCH_LIST_RESPONSE = 'get_country_coverage_search_list_response';
export const GET_LAWS_LIST_BY_LOGIN_USER_REQUEST = 'get_laws_list_by_login_user_request';
export const GET_LAWS_LIST_BY_LOGIN_USER_RESPONSE = 'get_laws_list_by_login_user_response';
export const UPDATE_LAW_VIA_UPDATE_REQUEST = 'update_law_via_update_request';
export const UPDATE_LAW_VIA_UPDATE_RESPONSE = 'update_law_via_update_response';