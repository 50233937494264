import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    REVIEWER,
    SUBMIT,
    SUBMITTER,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getDepartmentListByStatusRequest,
    getEntityTeamsByEntityIdRequest,
    getEntityRoleListByEntityIdRequest,
    getEntityDepartByEntityRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import entityRoleReducer from 'store/Masters/EntityRole/reducer';
import entityRoleSaga from 'store/Masters/EntityRole/saga';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import { selectSortedEntityTeamAndConsultantTeamUserList } from 'store/Entity/selector';
import { makeEntityRoleSelectField } from 'store/Masters/EntityRole/selector';
import { initialUserAllocationFormDataState } from './CommonFunctions';
import {
    customDateValidator,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const entityRoleKey = 'entityRole';
const withEntityRoleReducer = injectReducer({
    key: entityRoleKey,
    reducer: entityRoleReducer,
});
const withEntityRoleSaga = injectSaga({
    key: entityRoleKey,
    saga: entityRoleSaga,
});

class BulkMapLocationAndUserForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            submitterLabel: '',
            approverLabel: '',
            userMappingFormData: initialUserAllocationFormDataState(),
        };
    }
    componentDidMount() {
        if (this.props.eventData.entityId !== undefined) {
            this.props.getEntityDepartByEntityRequest(
                this.props.eventData.entityId
            );
            this.props.getEntityRoleListByEntityIdRequest(
                this.props.eventData.entityId
            );
            this.props.getEntityTeamsByEntityIdRequest(
                this.props.eventData.entityId
            );
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.userAllocationData !== this.props.userAllocationData) {
            this.setState(
                {
                    userMappingFormData: initialUserAllocationFormDataState(),
                },
                () => {
                    this.setState(
                        {
                            userMappingFormData: {
                                ...nextprops.userAllocationData,
                                entityId: this.props.eventData.entityId,
                            },
                        },
                        () => {
                            this.validator.purgeFields();
                            this.validator.hideMessages();
                        }
                    );
                }
            );
        }
        if (
            nextprops.entityRoleList &&
            nextprops.entityRoleList !== this.props.entityRoleList
        ) {
            this.setState(
                {
                    submitterLabel:
                        nextprops.entityRoleList.length > 0
                            ? nextprops.entityRoleList.find(
                                (opt) => opt.roleId === SUBMITTER
                            )?.roleTerm
                            : '',
                    approverLabel:
                        nextprops.entityRoleList.length > 0
                            ? nextprops.entityRoleList.find(
                                (opt) => opt.roleId === REVIEWER
                            )?.roleTerm
                            : '',
                }
            );
        }
    }
    render() {
        const locations = this.props.entityLocations?.map((opt) => ({
            value: opt.id,
            label: opt?.cityName,
            cityName: opt?.cityName,
        }));
        const submitterFormLabel = this.state.submitterLabel
            ? this.state.submitterLabel
            : __t('form_labels.events.plan.submitter');

        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.userMappingFormData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Row className="d-flex mr-0">
                                            {values.enableDueDate == true && (
                                                <FormGroup className="col-lg-6 w-100 sticky_label">
                                                    <Field
                                                        component={DatePicker}
                                                        isClearable={true}
                                                        className="form-control"
                                                        name="dueDate"
                                                        selected={
                                                            values.dueDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.dueDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                'dueDate',
                                                                date
                                                            );
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        dropdownMode="select"
                                                        placeholderText={__t(
                                                            'form_labels.events.plan.due_date'
                                                        )}
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                        minDate={new Date()}
                                                    ></Field>
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.due_date'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.events.plan.due_date'
                                                        ),
                                                        values.dueDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.dueDate
                                                            )
                                                            : '',
                                                        'required|customDate',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.events.plan.due_date_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            )}
                                            <FormGroup className="col-6">
                                                <Field
                                                    component={Select}
                                                    name="entityLocationId"
                                                    className="form-control selectbox"
                                                    options={locations}
                                                    onChange={(opt) => {
                                                        let selectedValues = opt
                                                            ? opt.map(
                                                                (x) => x.value
                                                            )
                                                            : [];
                                                        setFieldValue(
                                                            `entityLocationId`,
                                                            selectedValues
                                                        );
                                                        setFieldValue(
                                                            `cityName`,
                                                            opt
                                                                ? opt
                                                                    .map(
                                                                        (x) =>
                                                                            x.cityName
                                                                    )
                                                                    .join(',')
                                                                : []
                                                        );
                                                    }}
                                                    value={locations?.filter(
                                                        (option) =>
                                                            values?.entityLocationId?.includes(
                                                                option.value
                                                            )
                                                    )}
                                                    isMulti={true}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.events.plan.location'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.events.plan.location'
                                                    ),
                                                    values.entityLocationId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.events.plan.location_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-6">
                                                <Field
                                                    component={Select}
                                                    name="departmentId"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedDepartmentListDropdown
                                                    }
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            `departmentId`,
                                                            opt &&
                                                            opt.map(
                                                                (x) =>
                                                                    x.value
                                                            )
                                                        );
                                                    }}
                                                    value={this.props.sortedDepartmentListDropdown.filter(
                                                        (option) =>
                                                            values.departmentId?.includes(
                                                                option.value
                                                            )
                                                    )}
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.events.plan.department'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.events.plan.department'
                                                    ),
                                                    values.departmentId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.events.plan.department_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-6">
                                                <Field
                                                    component={Select}
                                                    name="submitterId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (opt) =>
                                                            opt.value !==
                                                            values.firstApproverUserId &&
                                                            opt.value !==
                                                            values.secondApproverUserId
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'submitterId',
                                                            opt && opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.submitterId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {submitterFormLabel}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    submitterFormLabel,
                                                    values.submitterId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required:
                                                                submitterFormLabel +
                                                                __t(
                                                                    'validations.common.required'
                                                                ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>

                                            <React.Fragment>
                                                <FormGroup className="col-lg-6">
                                                    <Field
                                                        component={Select}
                                                        name="firstApproverUserId"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (opt) =>
                                                                opt.value !==
                                                                values.submitterId &&
                                                                opt.value !==
                                                                values.secondApproverUserId
                                                        )}
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'firstApproverUserId',
                                                                opt && opt.value
                                                            );
                                                        }}
                                                        value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.firstApproverUserId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.first_approver'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.events.plan.first_approver'
                                                        ),
                                                        values.firstApproverUserId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required:
                                                                    __t(
                                                                        'form_labels.events.plan.first_approver'
                                                                    ) +
                                                                    __t(
                                                                        'validations.common.required'
                                                                    ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6">
                                                    <Field
                                                        component={Select}
                                                        name="secondApproverUserId"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (opt) =>
                                                                opt.value !==
                                                                values.submitterId &&
                                                                opt.value !==
                                                                values.firstApproverUserId
                                                        )}
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'secondApproverUserId',
                                                                opt && opt.value
                                                            );
                                                        }}
                                                        value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.secondApproverUserId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.second_approver'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                            </React.Fragment>

                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {SUBMIT}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={
                                                            this.props
                                                                .onModalDismiss
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    // sortedEntityTeamAndManagementTeamUserDropdown: selectSortedEntityTeamAndManagementTeamUserList(),
    sortedEntityTeamAndConsultantTeamUserDropdown:
        selectSortedEntityTeamAndConsultantTeamUserList(),
    entityRoleList: makeEntityRoleSelectField('entityRoleList'),
});
const withConnect = connect(mapStatetoProps, {
    getDepartmentListByStatusRequest,
    getEntityTeamsByEntityIdRequest,
    getEntityRoleListByEntityIdRequest,
    getEntityDepartByEntityRequest,
});
export default compose(
    withConnect,
    withDepartmentReducer,
    withDepartmentSaga,
    withUserReducer,
    withUserSaga,
    withEntityReducer,
    withEntitySaga,
    withEntityRoleReducer,
    withEntityRoleSaga,
    withRouter
)(BulkMapLocationAndUserForm);
