import React, { Component } from 'react';
import {
    Input,
    Row,
    Col,
    Container,
    Badge,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { getAllTransactionalDocumentsListRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import __t from 'i18n/translator';
import { makeTaskSelectField } from 'store/Task/selector';
import FileSaver from 'file-saver';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import ReactPaginate from 'react-paginate';
import Jpegfile from '../../../images/file-icon/jpeg.png';
import Pngfile from '../../../images/file-icon/png.png';
import Zipfile from '../../../images/file-icon/zip.png';
import Pdffile from '../../../images/file-icon/pdf.png';
import Jpgfile from '../../../images/file-icon/jpg.png';
import Csvfile from '../../../images/file-icon/csv.png';
import Txtfile from '../../../images/file-icon/txt.png';
import Docfile from '../../../images/file-icon/doc.png';
import Docxfile from '../../../images/file-icon/docx.png';
import Xlsxfile from '../../../images/file-icon/xlsx.png';
import Xlsfile from '../../../images/file-icon/xls.png';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import { redirectUrl } from 'helpers/generalUtils';
import { AUDITED_AND_CLOSED, PENDING_FOR_AUDIT, PENDING_WITH_FIRST_APPROVER, PENDING_WITH_SECOND_APPROVER, SEND_BACK_BY_AUDITOR_TO_APPROVER, SEND_BACK_TO_APPROVER } from 'constants/databaseConstants';
const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionalDocumentList: [],
            currentPage: 1,
            pageSize: 20,
            allRecords: [],
            fileIcons: {
                jpeg: Jpegfile,
                png: Pngfile,
                zip: Zipfile,
                pdf: Pdffile,
                jpg: Jpgfile,
                csv: Csvfile,
                txt: Txtfile,
                doc: Docfile,
                docx: Docxfile,
                xlsx: Xlsxfile,
                xls: Xlsfile,
            },
            downloadFileUrl: '',
            downloadFileName: ''
        };
    }
    componentDidMount() {
        this.props.getAllTransactionalDocumentsListRequest();
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };

    searchText = (searchValue) => {
        let isData;
        let searchedData = this.state.allRecords?.filter((values) => {
            isData = values.fileName.trim().toLowerCase().includes(searchValue);
            if (!isData) {
                isData = values?.documentType
                    .trim()
                    .toLowerCase()
                    .replace(/ /g, "_")
                    .includes(searchValue);
            }
            if (!isData) {
                isData = values.createdByUser?.fullName
                    .trim()
                    .toLowerCase()
                    .includes(searchValue);
            }
            if (!isData) {
                isData = values.fileName
                    .trim()
                    .toLowerCase()
                    .includes(searchValue);
            }
            return isData;
        });
        this.setState({
            transactionalDocumentList: searchedData,
        });
    };
    paginate = ({ selected }) => {
        const indexOfLastPost = (selected + 1) * this.state.pageSize;
        const indexOfFirstPost = indexOfLastPost - this.state.pageSize;
        const paginatedData = this.state.allRecords.slice(
            indexOfFirstPost,
            indexOfLastPost
        );
        this.setState({
            currentPage: selected + 1,
            transactionalDocumentList: paginatedData,
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.transactionalDocumentList &&
            this.props.transactionalDocumentList !==
            nextprops.transactionalDocumentList
        ) {
            let currentPage = this.state.currentPage;
            const indexOfLastPost = currentPage * this.state.pageSize;
            const indexOfFirstPost = indexOfLastPost - this.state.pageSize;
            let paginatedData = nextprops.transactionalDocumentList?.slice(
                indexOfFirstPost,
                indexOfLastPost
            );
            if (paginatedData.length == 0) {
                currentPage = 1;
                const indexOfLastPost1 = currentPage * this.state.pageSize;
                paginatedData = nextprops.transactionalDocumentList.slice(
                    indexOfFirstPost,
                    indexOfLastPost1
                );
            }
            this.setState({
                currentPage: currentPage
            }, () => {
                this.setState({
                    transactionalDocumentList: paginatedData,
                    allRecords: nextprops.transactionalDocumentList,
                });
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Container fluid>
                    <CommonBreadcrum type={'transactional-documents'} />
                    <div className="archive_record doc-vault">
                        <div className="page-header">
                            <h4>
                                {__t(
                                    'page_headers.transactional_document_list'
                                )}
                            </h4>

                            <div className="page-action">
                                <div className="search_box">
                                    <Input
                                        type="search"
                                        onChange={(e) =>
                                            this.searchText(
                                                e.target.value
                                                    .trim()
                                                    .toLowerCase()
                                            )
                                        }
                                        onKeyPress={(e) => {
                                            (e.key === 'Enter') &&
                                                e.preventDefault();
                                        }}
                                    />
                                    <svg
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <Row className="mt-2">
                            <Col lg="12" className="pr-0">
                                <div className="archive_list transaction-list">
                                    <ul>
                                        {this.state.transactionalDocumentList
                                            ?.length > 0 &&
                                            this.state.transactionalDocumentList.map(
                                                (transactionRecord, key) => {
                                                    let fileExtension = transactionRecord.fileName.split('.').pop();
                                                    let docType = transactionRecord.documentType
                                                        .replace(/_/g, ' ');
                                                    if (docType === 'poc') {
                                                        docType = __t('form_labels.upload.compliance_evidence');
                                                    } else if (docType === 'chat') {
                                                        docType = __t('form_labels.upload.chat');
                                                    } else if (docType === 'sub task') {
                                                        docType = __t('form_labels.upload.sub_task');
                                                    } else if (docType === 'observation') {
                                                        docType = __t('form_labels.upload.observation');
                                                    }
                                                    return (
                                                        <li key={key}>
                                                            <div className="file_name">
                                                                <div>
                                                                    <span>
                                                                        <img
                                                                            src={
                                                                                this
                                                                                    .state
                                                                                    .fileIcons[fileExtension]
                                                                            }
                                                                            alt="File extension"
                                                                        />
                                                                    </span>
                                                                    <div>
                                                                        <h6
                                                                            title={
                                                                                transactionRecord.fileName
                                                                            }
                                                                        >
                                                                            {
                                                                                transactionRecord.fileName
                                                                            }
                                                                        </h6>
                                                                        <p>
                                                                            <span>
                                                                                {
                                                                                    transactionRecord
                                                                                        .createdByUser
                                                                                        .fullName
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                        {
                                                                            transactionRecord.taskCode &&
                                                                            <Link className="badge badge-success" onClick={() => {
                                                                                let type = 'report';
                                                                                if (
                                                                                    [PENDING_FOR_AUDIT, AUDITED_AND_CLOSED].includes(
                                                                                        transactionRecord.taskStatusId
                                                                                    )
                                                                                ) {
                                                                                    type = 'audit';
                                                                                }
                                                                                if (
                                                                                    [
                                                                                        PENDING_WITH_FIRST_APPROVER,
                                                                                        PENDING_WITH_SECOND_APPROVER,
                                                                                        SEND_BACK_TO_APPROVER,
                                                                                        SEND_BACK_BY_AUDITOR_TO_APPROVER,
                                                                                    ].includes(transactionRecord.taskStatusId)
                                                                                ) {
                                                                                    type = 'review';
                                                                                }
                                                                                redirectUrl(
                                                                                    this.props.history,
                                                                                    '/compliances/' +
                                                                                    type +
                                                                                    '/' +
                                                                                    transactionRecord.taskId
                                                                                )
                                                                            }}>

                                                                                {transactionRecord.taskCode}
                                                                            </Link>
                                                                        }
                                                                        <p className="file-department">
                                                                            Uploaded Window :
                                                                            {' '}
                                                                            <span>{docType}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="file_details">
                                                                <div>
                                                                    <p>
                                                                        <span>{getFullDateTimeFromDateTimeString(transactionRecord.createdAt)}</span>
                                                                    </p>
                                                                    <div className="standard_card_btn mt-0">
                                                                        <Link
                                                                            to="#"
                                                                            onClick={() => {
                                                                                this.saveFile(
                                                                                    {
                                                                                        id: transactionRecord.id,
                                                                                        fileName:
                                                                                            transactionRecord.fileName?.split(
                                                                                                '/'
                                                                                            )[1],
                                                                                        fileUrl: transactionRecord.fileUrl,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            className="btn btn-primary"
                                                                            title="Download file"
                                                                        >
                                                                            <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 20H13.6667M7.33333 1V15.7778M7.33333 15.7778L12.6111 10.5M7.33333 15.7778L2.05556 10.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> Download
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                    </ul>
                                </div>
                                {this.state.allRecords?.length > 0 && (
                                    <ReactPaginate
                                        onPageChange={this.paginate}
                                        pageCount={Math.ceil(
                                            this.state.allRecords.length /
                                            this.state.pageSize
                                        )}
                                        previousLabel={'Previous'}
                                        nextLabel={'Next'}
                                        containerClassName={
                                            'pagination justify-content-end mb-0'
                                        }
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        activeLinkClassName={'active'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>

                </Container>
                <DownloadFileByModel
                    modelType='transactionalDocument'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    transactionalDocumentList: makeTaskSelectField('transactionalDocumentList'),
});
const withConnect = connect(mapStatetoProps, {
    getAllTransactionalDocumentsListRequest,
});
export default compose(
    withConnect,
    withTaskReducer,
    withTaskSaga,
    withRouter
)(Index);
