import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
    getEntityTeamsByEntityIdRequest,
    getCollaboratorListByTaskIdRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedEntityTeamManagementTeamHodAndConsultantTeamUserList } from 'store/Entity/selector';
import { Field, Form, Formik } from 'formik';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import SimpleReactValidator from 'simple-react-validator';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { makeTaskSelectField } from 'store/Task/selector';
import { commonConfirmBox } from 'helpers/messageHelper';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

export const collaboratorTableColumns = [
    {
        label: 'User Name',
        field: 'userName',
    },
    {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
    },
];

export const collaboratorDefaultTableColumns = [
    {
        label: 'User Name',
        field: 'userName',
    },
];
class CollaboratorModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            taskId: this.props.taskDetails.id,
            taskDetails: this.props.taskDetails,
            hodUserIds: [],
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            collaborators: [],
            collaboratorList: [],
        };
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    deleteCollaborator = async (collaboratorId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.collaborator_delete')
        );
        if (response) {
            this.props.onDeleteCollaborator(collaboratorId);
        }
    };
    generateTable = (records) => {
        let collaboratorUserRecords = [];
        collaboratorUserRecords = records.map((collaboratorUser) => {
            let actions = (
                <div className="text-center d-flex">
                    <div className="flex-fill">
                        <a
                            href={() => false}
                            title={__t('common.delete')}
                            className="btn btn-link text-danger tbl-action"
                            onClick={() => {
                                this.deleteCollaborator(collaboratorUser.id);
                            }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </a>
                    </div>
                </div>
            );
            return {
                userName:
                    collaboratorUser.fullName + ' - ' + collaboratorUser.email,
                actions: actions,
            };
        });
        return collaboratorUserRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.taskDetails !== this.props.taskDetails) {
            this.setState(
                {
                    taskId: nextprops.taskDetails.id,
                    taskDetails: nextprops.taskDetails,
                    hodUserIds: nextprops.hodUserIds,
                },
                () => {
                    this.props.getCollaboratorListByTaskIdRequest(
                        this.state.taskId
                    );
                    this.props.getEntityTeamsByEntityIdRequest(
                        nextprops.taskDetails?.entityId
                    );
                }
            );
        }
        if (
            nextprops.collaboratorList &&
            nextprops.collaboratorList !== this.props.collaboratorList
        ) {
            this.setState({
                collaborators:
                    nextprops.collaboratorList?.map((opt) => {
                        return { id: opt.id };
                    }) || [],
                collaboratorList: this.generateTable(
                    nextprops.collaboratorList
                ),
            });
        }
    }
    render() {
        const data = {
            rows: this.state.collaboratorList,
            columns:
                this.state.hodUserIds.includes(this.state.loginUserId) === true
                    ? collaboratorTableColumns
                    : collaboratorDefaultTableColumns,
        };
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        {this.state.hodUserIds.includes(
                            this.state.loginUserId
                        ) === true && (
                                <Col lg="12">
                                    <Formik
                                        initialValues={this.props.collaboratorData}
                                        enableReinitialize={true}
                                        onSubmit={this.formSubmit}
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form>

                                                <Row className="d-flex mr-0">
                                                    <FormGroup className="col-lg-10">
                                                        <Field
                                                            component={Select}
                                                            name="userId"
                                                            className="form-control selectbox"
                                                            options={this.props.sortedEntityTeamManagementTeamHodAndConsultantTeamUsersDropdown?.filter(
                                                                (option) => {
                                                                    let usersArr =
                                                                        this.state.collaborators?.map(
                                                                            ({
                                                                                id,
                                                                                ...rest
                                                                            }) => id
                                                                        );

                                                                    let usersData =
                                                                        [
                                                                            this
                                                                                .state
                                                                                .taskDetails
                                                                                .submitterId,
                                                                            this
                                                                                .state
                                                                                .taskDetails
                                                                                .firstApproverUserId,
                                                                            this
                                                                                .state
                                                                                .taskDetails
                                                                                .secondApproverUserId,
                                                                            this
                                                                                .state
                                                                                .taskDetails
                                                                                .auditorId,
                                                                            ...this
                                                                                .state
                                                                                .taskDetails
                                                                                .hodUserId,
                                                                            this
                                                                                .state
                                                                                .loginUserId,
                                                                        ];
                                                                    const allUserIds = [
                                                                        ...usersData,
                                                                        ...usersArr,
                                                                    ];
                                                                    return !allUserIds?.includes(
                                                                        option.value
                                                                    );
                                                                }
                                                            )}
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'userId',
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={this.props.sortedEntityTeamManagementTeamHodAndConsultantTeamUsersDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.userId
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.reporting.collaborator'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.reporting.collaborator'
                                                            ),
                                                            values.userId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.reporting.collaborator_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <Col lg="2">
                                                        <FormGroup className="mb-0 d-flex justify-content-end">
                                                            <Button
                                                                type="submit"
                                                                color="success"
                                                                className="mr-2 btn-block"
                                                            >
                                                                {__t(
                                                                    'form_labels.reporting.add_collaborator'
                                                                )}
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            )}

                        <Col lg="12">
                            <CustomisedDatatable
                                paging={false}
                                searching={false}
                                tableRecords={data}
                            />
                        </Col>

                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedEntityTeamManagementTeamHodAndConsultantTeamUsersDropdown:
        selectSortedEntityTeamManagementTeamHodAndConsultantTeamUserList(),
    collaboratorList: makeTaskSelectField('collaboratorList'),
});
const withConnect = connect(mapStatetoProps, {
    getEntityTeamsByEntityIdRequest,
    getCollaboratorListByTaskIdRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(CollaboratorModal);
