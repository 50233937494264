import { setEmptyToNull } from "helpers/generalUtils";

export const listColumns1 = [
    {
        label: 'Country',
        TransHeader: 'tables.allocation.country',
        field: 'country',
    },
    {
        label: 'Event Name',
        TransHeader: 'tables.allocation.event',
        field: 'eventName',
    },
    {
        label: 'Compliance(s)',
        field: 'action',
        width: 200,
        sort: 'disabled',
    }
];
export const initialAllocationDataState = () => {
    return {
        companyGroupId: '',
        countryId: [],
        stateId: [],
        cityId: [],
        industryId: '',
        subIndustryId: '',
        lawCategoryId: [],
        lawId: [],
        eventId: []
    }
}
export const intFields = ['companyGroupId'];
export const nullableFields = ['industry', 'subIndustry', 'event'];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};