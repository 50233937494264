import { isDarkMode } from "helpers/generalUtils";

export const geoData = [
    ['Country', 'One Time', 'AMC'],
    ['China', 90, 50],
    ['Russia', 150, 60],
    ['France', 89, 456],
    ['India', 90, 500],
    ['United States', 278, 62],
    ['Brazil', 178, 95],
    ['Saudi Arabia', 254, 65],
    ['Australia', 300, 21],
    ['Canada', 500, 600],
    ['Indonesia', 192, 25],
    ['Netherlands', 392, 125],
];

export const geoOptions = {
    colorAxis: { minValue: 10, colors: isDarkMode() ? ['#b78af1', '#b78af1'] : ['#4F2D7F', '#4F2D7F'] },
    backgroundColor: 'transparent',
    // backgroundColor: { stroke: "#4F2D7F", strokeWidth: 3 },
    datalessRegionColor: isDarkMode() ? '#564b64' : '#C2CCCE',
    displayMode: 'regions',
    legend: 'none',
    defaultColor: isDarkMode() ? '#b78af1' : '#4F2D7F',
    fullscreen: true,
    showZoomOut: true,
    magnifyingGlass: { enable: true, zoomFactor: 6.5 },
    domain: 'IN',
};

export const geoStateOptions = {
    // colorAxis: { minValue: 10, colors: ['#C2CCCE', '#4F2D7F'] },
    backgroundColor: 'transparent',
    // backgroundColor: { stroke: "#4F2D7F", strokeWidth: 3 },
    datalessRegionColor: '#C2CCCE',
    // displayMode: 'regions',
    // legend: 'none',
    defaultColor: '#4F2D7F',
    // fullscreen: true,
    // showZoomOut: true,
    // tooltip: { trigger: 'selection' },
    // magnifyingGlass: { enable: true, zoomFactor: 5.5 },
};

export const geoStateData = [
    ['Country', 'One Time', 'AMC'],
    ['Gujarat', 190, 150],
    ['Rajasthan', 150, 160],
];
