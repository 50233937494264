import React, { Component } from 'react';
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css";
import { Label } from "reactstrap";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import moment from 'moment';

class DatePickerRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDates: props.selectedDates || []
        };
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleDateChange(dates) {
        if (dates && dates.length > 0) {
            this.props.setSelectedDates(dates);
        } else {
            this.props.setSelectedDates([]);
        }
    }

    render() {
        return (
            <div className="form-floating sticky_label mb-0">
                <DatePicker
                    value={this.state.selectedDates}
                    onChange={(dates) => {
                        if (dates.length === 2) {
                            const startDate = new Date(dates[0]);
                            const endDate = new Date(dates[1]);
                            const formattedStartDate = `${startDate.getMonth() + 1}/${startDate.getFullYear()}`;
                            const formattedEndDate = `${endDate.getMonth() + 1}/${endDate.getFullYear()}`;
                            this.props.setSelectedDates([formattedStartDate, formattedEndDate]);
                        } else {
                            // Only one date is selected, so clear the end date
                            const selectedDate = new Date(dates[0]);
                            this.props.setSelectedDates([`${selectedDate.getMonth() + 1}/${selectedDate.getFullYear()}`, ""]);
                        }
                    }}
                    inputClass={""}
                    format='MM/YYYY'
                    range
                    calendarPosition={"bottom"}
                    placeholder="Select Range"
                    className="mr-1"
                />
            </div>
        );
    }
}

export default DatePickerRange;
