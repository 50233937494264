import produce from 'immer';
import { ADD_SECTION_RESPONSE, DELETE_SECTION_BY_SECTION_ID_RESPONSE, GET_ALL_SECTION_LIST_RESPONSE, GET_SECTION_BY_SECTION_ID_RESPONSE, GET_SECTION_LIST_BY_LAW_RESPONSE, GET_SECTION_LIST_BY_STATUS_RESPONSE, UPDATE_SECTION_RESPONSE, UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE } from './actionTypes';
export const initialState = {
    sectionApiResponse: null,
    editSectionApiResponse: null,
    sectionList: [],
    allCityData: []
};
const section = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_SECTION_RESPONSE:
            case UPDATE_SECTION_RESPONSE:
            case UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE:
            case DELETE_SECTION_BY_SECTION_ID_RESPONSE:
                draft.sectionApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_SECTION_LIST_RESPONSE:
                draft.sectionList = action.payload.sectionList.list;
                draft.allCityData = action.payload.sectionList.allCityData;
                break;
            case GET_SECTION_BY_SECTION_ID_RESPONSE:
                draft.editSectionApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;




            case GET_SECTION_LIST_BY_STATUS_RESPONSE:
                draft.sectionList = action.payload.sectionList;
                break;


            case GET_SECTION_LIST_BY_LAW_RESPONSE:
                draft.sectionList = action.payload.sectionList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default section;
