import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getAllSubTaskByTaskIdListRequest,
    getTaskDetailsByTaskIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { permissionDeniedMessage } from 'helpers/messageHelper';
import { makeTaskSelectField } from 'store/Task/selector';
import { compareString } from 'helpers/generalUtils';
import SubTaskItem from './AssignedTasks/Details/SubTaskItem';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import { SUCCESS } from 'constants/commonConstants';
import ShowMoreText from 'react-show-more-text';

class SubTaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskId: parseInt(this.props.taskId),
            taskCode: '',
            taskDetails: {},
            subTaskList: [],
        };
    }

    noTaskFound = async (message) => {
        const response = await permissionDeniedMessage(message);
        if (response) {
            this.props.history.goBack();
        }
    };
    getShowMoreTextElement = (element, lines = 2) => {
        return (
            <ShowMoreText
                lines={lines}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.taskData && this.props.taskData !== nextprops.taskData) {
            this.setState(
                {
                    taskId: nextprops.taskData.id,
                    taskCode: nextprops.taskData.taskCode,
                },
                () => {
                    this.props.getTaskDetailsByTaskIdRequest(this.state.taskId);
                    this.props.getAllSubTaskByTaskIdListRequest(
                        this.state.taskId
                    );
                }
            );
        }
        if (
            nextprops.subTaskList &&
            this.props.subTaskList !== nextprops.subTaskList
        ) {
            this.setState({
                subTaskList: nextprops.subTaskList,
            });
        }
        if (
            nextprops.taskDetailsApiResponse &&
            this.props.taskDetailsApiResponse !==
            nextprops.taskDetailsApiResponse
        ) {
            if (nextprops.taskDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    taskDetails: {
                        ...nextprops.taskDetailsApiResponse.data.task,
                        departmentDataForInput:
                            nextprops.taskDetailsApiResponse.data.task?.departmentDataForInput
                                .map((opt) => ({
                                    value: opt.id,
                                    label: opt.departmentName,
                                }))
                                .sort((item1, item2) =>
                                    compareString(item1.label, item2.label)
                                ),
                    },
                });
            }
        }
    }
    render() {
        const hodUserIds =
            this.state?.taskDetails?.hodUserId &&
                this.state?.taskDetails?.hodUserId != ''
                ? this.state?.taskDetails.hodUserId
                : [];
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={
                        'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider auditlog_modal'
                    }
                    modalName={
                        this.props.modalName + ' | ' + this.state.taskCode
                    }
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <div className="modal_tree_details">
                        <div className="tree_details">
                            {this.state.subTaskList?.length > 0 &&
                                this.state.subTaskList.map((subTask, key) => {
                                    return (
                                        <div
                                            className={
                                                subTask.subTask.length > 0
                                                    ? ' tree-parent has-child '
                                                    : ' tree-parent'
                                            }
                                            key={subTask.id}
                                        >
                                            <SubTaskItem
                                                openSubTaskModal={
                                                    this.openSubTaskModal
                                                }
                                                openEditSubTaskModal={
                                                    this.openEditSubTaskModal
                                                }
                                                getShowMoreTextElement={
                                                    this.getShowMoreTextElement
                                                }
                                                deleteSubTask={
                                                    this.deleteSubTask
                                                }
                                                history={this.props.history}
                                                subTask={subTask}
                                                loginUserId={
                                                    this.state.loginUserId
                                                }
                                                hodUserIds={hodUserIds}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </CustomisedSideModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    subTaskList: makeTaskSelectField('subTaskList'),
    taskDetailsApiResponse: makeTaskSelectField('taskDetailsApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getAllSubTaskByTaskIdListRequest,
    getTaskDetailsByTaskIdRequest,
});
export default compose(withConnect, withRouter)(SubTaskList);
