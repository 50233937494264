import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { GET_ARCHIVE_RECORDS_LIST, UPLOAD_ARCHIVE_RECORDS, ARCHIVE_RECORD, ARCHIVE_DEPARTMENTS } from 'helpers/apiConstants';
import { GET_ARCHIVE_RECORDS_LIST_REQUEST, UPLOAD_ARCHIVE_RECORDS_REQUEST, DELETE_ARCHIVE_RECORD_REQUEST, GET_ARCHIVE_DEPARTMENT_LIST_REQUEST } from './actionTypes';
import { getArchiveRecordsListResponse, uploadArchiveRecordsResponse, deleteArchiveRecordByIdResponse, getArchiveRecordsListRequest, getArchiveDocumentListResponse } from './actions';
import { DANGER, SUCCESS } from 'constants/commonConstants';

function* getArchiveRecordsList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', GET_ARCHIVE_RECORDS_LIST, data);
        if (response.status) {
            yield put(getArchiveRecordsListResponse(response.data.list));
        } else {
            yield put(getArchiveRecordsListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* uploadArchiveRecords({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            UPLOAD_ARCHIVE_RECORDS,
            data
        );

        if (response.status) {
            yield put(
                uploadArchiveRecordsResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                uploadArchiveRecordsResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteArchiveRecordById({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            ARCHIVE_RECORD + '/' + id
        );
        if (response.status) {
            yield put(getArchiveRecordsListRequest());
            yield put(
                deleteArchiveRecordByIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteArchiveRecordByIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getArchiveDepartmentList() {
    try {
        const response = yield call(invokeApi, 'get', ARCHIVE_DEPARTMENTS);
        if (response.status) {
            yield put(getArchiveDocumentListResponse(response.data.archiveDepartmentList));
        } else {
            yield put(getArchiveDocumentListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchArchiveRecords() {
    yield takeEvery(GET_ARCHIVE_RECORDS_LIST_REQUEST, getArchiveRecordsList);
    yield takeEvery(UPLOAD_ARCHIVE_RECORDS_REQUEST, uploadArchiveRecords);
    yield takeEvery(DELETE_ARCHIVE_RECORD_REQUEST, deleteArchiveRecordById);
    yield takeEvery(GET_ARCHIVE_DEPARTMENT_LIST_REQUEST, getArchiveDepartmentList);
}
function* archiveRecordSaga() {
    yield all([fork(watchArchiveRecords)]);
}
export default archiveRecordSaga;