import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Row,
    Col,
    FormGroup,
    Progress
} from 'reactstrap';

import { compose } from 'redux';
import { Field, Formik } from 'formik';
import Select, { components } from 'react-select';
import { getCountryListByStatusRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { Chart as GoogleChart } from 'react-google-charts';
import { geoOptions } from './Charts/GeoChartData.js';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
    ArcElement,
    LineController,
    BarController,
} from 'chart.js';
import 'react-datepicker/dist/react-datepicker.css';
import { ACTIVE } from 'constants/commonConstants';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCityList } from 'store/Masters/City/selector.js';
import { selectSortedStateList } from 'store/Masters/State/selector.js';
import { roundOffAmount } from 'helpers/mathHelper.js';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });

/*--------Charts--------*/
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    LineController,
    BarController
);

const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const regulatory_data = {
    labels,
    datasets: [
        {
            label: 'Laws',
            data: [5, 10, 15, 18, 45, 15, 5, 14, 10, 15, 20, 35, 15],
            backgroundColor: '#00A7B5',
            borderWidth: 1,
            borderRadius: 100,
            stack: 'Stack 0',
            maxBarThickness: 15,
        },
        {
            label: 'Compliances',
            data: [
                -14, -18, -20, -10, -42, -20, -15, -14, -18, -20, -10, -35, -30,
            ],
            backgroundColor: '#4F2D7F',
            borderWidth: 1,
            borderRadius: 100,
            stack: 'Stack 0',
            maxBarThickness: 15,
        },
    ],
};
/*--------End-Charts--------*/

const initialDashboardSearchDataState = () => {
    return {
        countryId: 0,
        stateId: '',
        cityId: '',
        date: '',
    };
};

const critical_labels = ['compliant', 'overdue', 'delay', 'pending'];
const ageing_labels = ['ageing_30', 'ageing_90', 'ageing_180', 'ageing_240'];
class TenantAdminIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'graphViewTab',
            lawCategoryDepartmentView: true,
            lawCountByCountry: [],
            lawComplianceCountByCountry: [],
            analysisAsPerCategoryOfLaw: [],
            analysisAsPerDepartment: [],
            monthlyLawsCompliancesCountByCountry: [],
            monthlyLawsComplianceUpdatesCountByCountryDetails: [],
            monthlyLawsCompliancesEffectiveCountByCountry: [],
            graphMonthlyData: {
                options: {},
                data: {},
            },
            geoData: [],
            geoOptions: geoOptions,
            regionWise: false,
            countryIso: '',
            dashboardSearchData: initialDashboardSearchDataState(),
            masterData: {
                compliancesCount: 0,
                countryCount: 0,
                departmentCount: 0,
                entityCount: 0,
                lawsCount: 0,
                locationCount: 0,
                updatesCount: 0,
                userCount: 0,
            },
            critical_options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                    events: {
                        dataPointSelection:
                            this.handleDataPointSelectionForCritical,
                    },
                },
                plotOptions: {
                    radialBar: {
                        track: {
                            // background: 'rgba(79, 45, 127, 0.2)',
                        },
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                                formatter: function (val) {
                                    return val;
                                },
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce(
                                        (a, b) => {
                                            return a + b;
                                        },
                                        0
                                    );
                                },
                            },
                        },
                    },
                },
                legend: {
                    show: true,
                },
                labels: ['Compliant', 'Overdue', 'Delay', 'In Progress'],
            },
            criticalRiskAnalysis: [0, 0, 0, 0],
            overallStatus: {
                compliantCount: 0,
                overdueCount: 0,
                delayCount: 0,
                pendingCount: 0,
            },
            ageingAnalysis: [0, 0, 0, 0],
            ageing_options: {
                chart: {
                    height: 'auto',
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                    events: {
                        dataPointSelection:
                            this.handleDataPointSelectionForAgeing,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        columnWidth: '25%',
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ['#304758'],
                    },
                },
                xaxis: {
                    categories: [
                        'Upto 30 Days',
                        '31-90 Days',
                        '91-180 Days',
                        '181-240 Days',
                    ],
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                    labels: {
                        style: {
                            colors: ['#000'],
                            fontSize: '12px',
                            fontFamily: 'GT Walsheim Pro',
                            fontWeight: 400,
                        },
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                    },
                },
                fill: {
                    colors: ['#9BD732'],
                },
            },
            isOpenLawCategoryTasks: false,
            isOpenDepartmentTasks: false,
            isOpenOverallTasks: false,
            userDepartments: [],
        };
        this.handleDataPointSelectionForCritical =
            this.handleDataPointSelectionForCritical.bind(this);
        this.handleDataPointSelectionForAgeing =
            this.handleDataPointSelectionForAgeing.bind(this);
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    toggle(tab) {
        if (this.state.activeTab != tab) {
            if (tab === 'detailsViewTab') {
                this.dashboardDetails();
            }
            this.setState({
                activeTab: tab,
            });
        }
    }
    toggleLawCategoryDepartment() {
        this.setState({
            lawCategoryDepartmentView: !this.state.lawCategoryDepartmentView,
        });
    }
    calculatePercentageForOverAllStatus = (percentageOn) => {
        const total = [
            this.state.overallStatus.compliantCount,
            this.state.overallStatus.overdueCount,
            this.state.overallStatus.delayCount,
            this.state.overallStatus.pendingCount,
        ].reduce((partialSum, a) => partialSum + a, 0);

        const percentage = (percentageOn * 100) / total;
        return roundOffAmount(percentage);
    };
    changeGeoGraph = (regionWise, countryIso, countryId) => {
        this.setState(
            {
                regionWise: regionWise,
                countryIso: countryIso,
            },
            () => {
                this.props.countryChangeEvent(
                    countryId,
                    regionWise,
                    countryIso
                );
            }
        );
    };
    handleDataPointSelectionForCritical = (event, chartContext, config) => {
        const clickedIndex = config.dataPointIndex;
        this.props.dashboardManagementDetails({
            clickableType: 'critical_risk_anaylsis',
            clickableTypeData: {
                statusType: critical_labels[clickedIndex],
            },
        });
    };
    handleDataPointSelectionForAgeing = (event, chartContext, config) => {
        const clickedIndex = config.dataPointIndex;
        this.props.dashboardManagementDetails({
            clickableType: 'ageing_anaylsis',
            clickableTypeData: {
                statusType: ageing_labels[clickedIndex],
            },
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.apiResponseData &&
            nextprops.apiResponseData != this.props.apiResponseData
        ) {
            let geoOptionsData = geoOptions;
            let geoDataResult = [];
            let regionWise = false;
            let countryIso = '';
            geoDataResult = [
                ['Country', 'Laws', 'Compliances'],
                ...nextprops.apiResponseData?.geoMap?.mapData.map(
                    (countryWiseData) => {
                        return [
                            countryWiseData?.countryName
                                ? countryWiseData.countryName
                                : '',
                            countryWiseData?.lawsCount
                                ? countryWiseData.lawsCount
                                : 0,
                            countryWiseData?.complianceCount
                                ? countryWiseData?.complianceCount
                                : 0,
                        ];
                    }
                ),
            ];
            if (
                nextprops.apiResponseData?.geoMap?.countryInfo?.isoCode2 &&
                nextprops.apiResponseData?.geoMap.countryInfo?.isoCode2 != ''
            ) {
                countryIso =
                    nextprops.apiResponseData.geoMap?.countryInfo.isoCode2;
                geoOptionsData = {
                    region: countryIso,
                    // colorAxis: { colors: ['#00853f', 'black', '#e31b23'] },
                    colorAxis: { minValue: 10, colors: ['#4F2D7F', '#4F2D7F'] },
                    legend: 'none',
                    backgroundColor: '#transparent',
                    datalessRegionColor: '#C2CCCE',
                    defaultColor: '#4F2D7F',
                    domain: 'IN',
                };
                regionWise = true;
            }
            if (nextprops.apiResponseData.geoMap.mapData.length === 0) {
                geoDataResult = [
                    ['Country', 'Laws', 'Compliances'],
                    ['', 0, 0],
                ];
            }
            this.setState({
                lawCountByCountry: nextprops.apiResponseData.lawCountByCountry,
                lawComplianceCountByCountry:
                    nextprops.apiResponseData.lawComplianceCountByCountry,
                geoOptions: geoOptionsData,
                regionWise: regionWise,
                countryIso: countryIso,
                geoData: geoDataResult,
            });
        }
    }
    render() {
        const countryList = this.props.sortedCountryListDropdown?.map(
            (opt) => ({
                value: opt.value,
                label: opt.label,
                isoCode2: opt.isoCode2,
                image: '/data/flags/' + opt.isoCode2 + '.svg',
            })
        );
        const { Option, SingleValue } = components;
        const IconSingleValue = (props) => (
            <SingleValue {...props}>
                <img src={props.data.image} alt='icon' />
                {props.data.label}
            </SingleValue>
        );

        const IconOption = (props) => (
            <Option {...props}>
                <img
                    alt='icon'
                    src={props.data.image}
                    style={{
                        height: '30px',
                        width: '30px',
                        // borderRadius: '50%',
                        marginRight: '10px',
                    }}
                />
                {props.data.label}
            </Option>
        );
        const customStyles = {
            option: this.applyFlexRowStyle,
            singleValue: this.applyFlexRowStyle,
        };

        const applyFlexRowStyle = (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
        });
        return (
            <React.Fragment>
                <div className="dashboard_content tenant_dashboard">
                    <div className="dashboard_section country_list_height mb-0">
                        <Row>
                            <Col lg="3">
                                <div className="dashboard_head">
                                    <h5>Laws Per Country</h5>
                                    <ul>
                                        {this.state.lawCountByCountry.map(
                                            (countryData, key) => {
                                                return (
                                                    <li
                                                        key={key}
                                                        onClick={() => {
                                                            this.props.history.push(
                                                                '/statutory-compliances/from-dashboard/laws/country/' +
                                                                countryData.countryId
                                                            );
                                                        }}
                                                    >
                                                        <div className="country_list_info">
                                                            <p>
                                                                <img
                                                                    alt='flag'
                                                                    src={`/data/flags/${countryData?.isoCode2}.svg`}
                                                                />
                                                                {
                                                                    countryData.countryName
                                                                }
                                                            </p>
                                                            <span>
                                                                {
                                                                    countryData.lawsCount
                                                                }
                                                            </span>
                                                        </div>
                                                        <Progress
                                                            value={100}
                                                        // value={
                                                        //     countryData.lawsCount
                                                        // }
                                                        />
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="6" className="geo_chart">
                                <Formik
                                    initialValues={{ countryId: 0 }}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <FormGroup>
                                            <Field
                                                styles={customStyles}
                                                component={Select}
                                                name="countryId"
                                                className="form-control selectbox"
                                                components={{
                                                    SingleValue:
                                                        IconSingleValue,
                                                    Option: IconOption,
                                                }}
                                                options={countryList}
                                                onChange={(opt) => {
                                                    let countryId = opt
                                                        ? opt.value
                                                        : 0;
                                                    setFieldValue(
                                                        'countryId',
                                                        countryId
                                                    );
                                                    let regionWise = opt
                                                        ? true
                                                        : false;
                                                    let isoCode2 = opt
                                                        ? opt.isoCode2
                                                        : '';
                                                    this.changeGeoGraph(
                                                        regionWise,
                                                        isoCode2,
                                                        countryId
                                                    );
                                                }}
                                                isClearable={true}
                                            />
                                        </FormGroup>
                                    )}
                                </Formik>
                                {this.state.geoData.length > 1 && (
                                    <GoogleChart
                                        className="geo_chart-inner"
                                        chartType="GeoChart"
                                        height="50vh"
                                        data={this.state.geoData}
                                        options={this.state.geoOptions}
                                        chartEvents={[
                                            {
                                                eventName: 'ready',
                                                callback: ({
                                                    chartWrapper,
                                                    google,
                                                }) => {

                                                    chartWrapper.getChart();

                                                },
                                            },
                                        ]}
                                    />
                                )}
                            </Col>
                            <Col lg="3">
                                <div className="dashboard_head">
                                    <h5>Compliances Per Country</h5>
                                    <ul>
                                        {this.state.lawComplianceCountByCountry.map(
                                            (countryData, key) => {
                                                return (
                                                    <li
                                                        key={key}
                                                        onClick={() => {
                                                            this.props.history.push(
                                                                '/statutory-compliances/from-dashboard/compliances/country/' +
                                                                countryData.countryId
                                                            );
                                                        }}
                                                    >
                                                        <div className="country_list_info">
                                                            <p>
                                                                <img
                                                                    alt='flag'
                                                                    src={`/data/flags/${countryData?.isoCode2}.svg`}
                                                                />
                                                                {
                                                                    countryData.countryName
                                                                }
                                                            </p>
                                                            <span>
                                                                {
                                                                    countryData.lawCompliancesCount
                                                                }
                                                            </span>
                                                        </div>
                                                        <Progress
                                                            value={100}
                                                        // value={
                                                        //     countryData.lawCompliancesCount
                                                        // }
                                                        />
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
});
export default compose(
    withConnect,
    withRouter,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga
)(TenantAdminIndex);
