import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getAuditListRequest
} from 'store/actions';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import auditReducer from 'store/Audit/reducer';
import auditSaga from 'store/Audit/saga';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import CustomisedDatatable from './CustomisedDatatable';
import { makeAuditSelectField } from 'store/Audit/selector';
import { ExportCSV } from './ExportCSV';
import { getFullDateTimeFromDateTimeString, getFullDateTimeFromDateTimeStringForSorting } from 'helpers/dateTimeHelper';
import ShowMoreText from "react-show-more-text";
import { decryptText } from 'helpers/crypto';

const auditKey = 'audit';
const withAuditReducer = injectReducer({
    key: auditKey,
    reducer: auditReducer,
});
const withAuditSaga = injectSaga({ key: auditKey, saga: auditSaga });
const columns = [
    {
        label: 'User',
        field: 'user'
    },
    {
        label: 'Date Time',
        field: 'dateTime',
    },
    {
        label: 'Field',
        field: 'field'
    },
    {
        label: 'Old Value',
        field: 'oldValue',
        sort: 'disabled'
    },
    {
        label: 'New Value',
        field: 'newValue',
        sort: 'disabled'
    },
];
class AuditLogModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditList: [],
            exportAuditList: [],
            auditType: ''
        }
    }

    getAuditRecords = () => {
        this.props.getAuditListRequest({
            modelType: this.state.auditType,
            modelId: this.state.auditId
        });
    }
    generateTable = records => {
        let auditRecords = [];
        auditRecords = records.map(item => {
            return {
                user: (
                    <>

                        <div className="audit_user">
                            <h5>{(item?.user && item.user?.fullName && item.user.fullName != null) ? item.user?.fullName : ''}</h5>
                            <div className="badge">{(item?.displayName && item?.displayName != null) ? item?.displayName : ''}</div>
                        </div>
                    </>
                ),

                dateTime: (
                    <div className='audit-date-time'>
                        <div>
                            {getFullDateTimeFromDateTimeString(item.createdAt)}
                        </div>
                        <div className="action_title">
                            {item?.event}
                        </div>
                    </div>
                ),
                newValue: (item?.newReferenceValue && item.newReferenceValue != null) ? (
                    <div className='new-value-box' searchvalue={item.newReferenceValue}>{this.getShowMoreTextElement(item?.newReferenceValue)}</div>
                ) : '',
                oldValue: (item?.oldReferenceValue && item?.oldReferenceValue != null) ? (
                    <div className='old-value-box' searchvalue={item.oldReferenceValue}>{this.getShowMoreTextElement(item?.oldReferenceValue)}</div>
                ) : ''
            }
        })
        return auditRecords;
    }
    getShowMoreTextElement = (element) => {
        return <ShowMoreText
            lines={2}
            more="Show more"
            less="Show less"
            className="show-more-content"
            anchorClass="show-more-link"
            expandByClick={true}
            expanded={false}
            keepNewLines={true}
        >{`` + element + '\n'}</ShowMoreText>
    }
    generateTableForExport = records => {
        let auditRecords = [];
        auditRecords = records.map(item => {
            let decryptedOldValue = (
                item.modelType &&
                (item.modelType.toLowerCase() == "users") &&
                item.field &&
                (
                    item.field.toLowerCase() == "email" ||
                    item.field.toLowerCase() == "contactno"
                ) &&
                item.oldReferenceValue
            ) && decryptText(item.oldReferenceValue) || null;

            let decryptedNewValue = (
                item.modelType &&
                (item.modelType.toLowerCase() == "users") &&
                item.field &&
                (
                    item.field.toLowerCase() == "email" ||
                    item.field.toLowerCase() == "contactno"
                ) &&
                item.newReferenceValue
            ) && decryptText(item.newReferenceValue) || null;

            if (item && item.field == 'entityTasks') {
                if (item.oldReferenceValue && item.oldReferenceValue.length > 0 && item.newReferenceValue && item.newReferenceValue.length > 0) {
                    item.oldReferenceValue = <ul>
                        {
                            JSON.parse(item.oldReferenceValue).map((entitytask) => <li>
                                <b>Entity</b> : {entitytask.entityName}
                                <b>Task</b> : {entitytask.taskName}
                            </li>)
                        }
                    </ul>
                    item.newReferenceValue = <ul>
                        {
                            JSON.parse(item.newReferenceValue).map((entitytask) => <li>
                                <b>Entity</b> : {entitytask.entityName}
                                <b>Task</b> : {entitytask.taskName}
                            </li>)
                        }
                    </ul>
                }
            }

            return {
                action: item?.event,
                user: (item?.user && item.user?.fullName && item.user.fullName != null) ? item.user.fullName : (item?.userFullName || ''),
                // field: item?.field,
                field: (item?.displayName && item?.displayName != null) ? item?.displayName : '',
                dateTime: <span searchvalue={getFullDateTimeFromDateTimeStringForSorting(item.createdAt)}>{getFullDateTimeFromDateTimeString(
                    item.createdAt
                )}</span>,
                oldValue: (item?.oldReferenceValue && item.oldReferenceValue != null && item.field != 'createdAt') ? decryptedOldValue || item?.oldReferenceValue : '',
                newValue: (item?.newReferenceValue && item.newReferenceValue != null && item.field != 'createdAt') ? decryptedNewValue || item?.newReferenceValue : '',
            }
        })
        return auditRecords;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.auditType !== this.props.auditType || nextprops.auditId !== this.props.auditId) {
            this.setState({
                auditType: nextprops.auditType,
                auditId: nextprops.auditId,
            }, () => {
                if (nextprops.auditId == '') {
                    this.setState({
                        auditList: [],
                        exportAuditList: []
                    })
                } else {
                    this.getAuditRecords();
                }
            })
        }
        if (nextprops.auditList && nextprops.auditList !== this.props.auditList) {
            this.setState({
                auditList: this.generateTableForExport(nextprops.auditList),
                exportAuditList: this.generateTableForExport(nextprops.auditList)
            })
        }
    }
    render() {
        const reactTableData = {
            columns: columns,
            rows: this.state.auditList,
        };
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider logs_modal'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    {this.props.exportBtn === true && (
                        <ExportCSV key={'audit'} propsData={[this.state.exportAuditList]} type={'audit'} auditType={this.state.auditType} />
                    )}
                    {/* Create a simple table for display the parent and child row */}
                    <CustomisedDatatable
                        className="table table-bordered"
                        tableRecords={reactTableData}
                        sortRows={['dateTime']}
                        entries={20}
                    />
                </CustomisedSideModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    auditList: makeAuditSelectField('auditList')
});
const withConnect = connect(mapStatetoProps, {
    getAuditListRequest
});
export default compose(
    withConnect,
    withAuditReducer,
    withAuditSaga,
    withRouter
)(AuditLogModal);