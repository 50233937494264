import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class CustomisedSideModal extends React.Component {
    constructor(props) {
        super(props);

        this.isFullScreenModal = false;
        this.modalSize = 'modal-xl';
        this.modalClass = 'modal-dialog-centered';
    }

    toggleFullScreenModal = () => {
        //if it is fullscreen, toggle to minimized screen and vice-versa
        if (this.isFullScreenModal) {
            this.modalClass = 'modal-dialog-centered';
            this.modalClass = '';
            this.isFullScreenModal = false;
        } else {
            this.modalClass = 'modal-dialog-centered modal_full';
            this.isFullScreenModal = true;
        }
    };

    render() {
        return (
            <Modal
                className={
                    this.props.modalSize
                        ? this.props.modalSize
                        : this.modalSize + ' ' + this.modalClass
                }
                isOpen={this.props.isModalOpen}
                backdrop="static"
                keyboard={false}
                toggle={this.props.onToggle}
            >
                <ModalHeader>
                    {this.props.modalName}
                    <div className='modal-action'>
                        <button
                            onClick={this.props.onModalDismiss}
                            type="reset"
                            className="btn-link modal_close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </ModalHeader>
                <ModalBody>{this.props.children}</ModalBody>
            </Modal>
        );
    }
}

export default CustomisedSideModal;
