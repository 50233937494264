const ENCRYPTION_SECRET = "62af132aa7197d8f67fc2b21c874f70ef2196b72742436fb661beaeedb7cd155e7f3ffb36c2b54fd3941201e394d3b74"
const CryptoJS = require("crypto-js");

const encryptData = (text) => {
  return CryptoJS.AES.encrypt(text, ENCRYPTION_SECRET).toString();
}

const decryptData = (text) => {
  return CryptoJS.AES.decrypt(text, ENCRYPTION_SECRET).toString(CryptoJS.enc.Utf8);
}

export { encryptData, decryptData };
