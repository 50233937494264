import produce from 'immer';
import {
    ADD_LAW_RESPONSE,
    DELETE_LAW_BY_LAW_ID_RESPONSE,
    GET_ALL_ENTITY_TYPE_LIST_RESPONSE,
    GET_ALL_FACILITY_TYPE_LIST_RESPONSE,
    GET_ALL_LAW_LIST_RESPONSE,
    GET_COUNTRY_COVERAGE_SEARCH_LIST_RESPONSE,
    GET_LAWS_LIST_BY_LOGIN_USER_RESPONSE,
    GET_LAWS_LIST_RESPONSE,
    GET_LAWS_SEARCH_LIST_RESPONSE,
    GET_LAW_BY_LAW_ID_RESPONSE,
    GET_LAW_LIST_BY_STATUS_RESPONSE,
    UPDATE_LAW_RESPONSE,
    UPDATE_LAW_STATUS_BY_LAW_ID_RESPONSE,
    UPDATE_LAW_VIA_UPDATE_RESPONSE,
} from './actionTypes';
export const initialState = {
    lawApiResponse: null,
    lawDetailsApiResponse: null,
    lawStatusUpdateApiResponse: null,
    lawSearchApiResponse: null,
    lawList: [],
    entityTypeList: [],
    facilityTypeList: [],
    lawSearchList: [],
    countryCoverageList: [],
    lawsListOfLoginUser: [],
    allCityData: []
};
const law = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_LAW_RESPONSE:
            case UPDATE_LAW_VIA_UPDATE_RESPONSE:
            case UPDATE_LAW_RESPONSE:
                draft.lawApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType
                };
                break;
            case GET_ALL_LAW_LIST_RESPONSE:
                draft.lawList = action.payload.lawList.list;
                draft.allCityData = action.payload.lawList.allCityData;
                break;
            case GET_LAW_BY_LAW_ID_RESPONSE:
                draft.lawDetailsApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType
                };
                break;


            case UPDATE_LAW_STATUS_BY_LAW_ID_RESPONSE:
                draft.lawStatusUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_LAW_LIST_BY_STATUS_RESPONSE:
                draft.lawList = action.payload.lawList;
                break;
            case DELETE_LAW_BY_LAW_ID_RESPONSE:
                draft.lawApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_ENTITY_TYPE_LIST_RESPONSE:
                draft.entityTypeList = action.payload.entityTypeList;
                break;
            case GET_ALL_FACILITY_TYPE_LIST_RESPONSE:
                draft.facilityTypeList = action.payload.facilityTypeList;
                break;
            case GET_LAWS_SEARCH_LIST_RESPONSE:
                draft.lawSearchApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_LAWS_LIST_RESPONSE:
                draft.lawSearchList = action.payload.lawSearchList;
                break;
            case GET_COUNTRY_COVERAGE_SEARCH_LIST_RESPONSE:
                draft.countryCoverageList = action.payload.countryCoverageList;
                break;
            case GET_LAWS_LIST_BY_LOGIN_USER_RESPONSE:
                draft.lawsListOfLoginUser = action.payload.lawsListOfLoginUser;
                break;


            default:
                state = { ...state };
                break;
        }
    });
export default law;
