import produce from 'immer';
import {
    GET_PROJECT_TASK_USERS_BY_ENTITY_RESPONSE,
    ADD_PROJECT_RESPONSE,
    GET_PROJECT_LIST_RESPONSE,
    GET_PROJECT_DETAILS_RESPONSE,
    UPDATE_PROJECT_RESPONSE,
    DELETE_PROJECT_BY_PROJECT_ID_RESPONSE,
    GET_PROJECT_TASK_LIST_RESPONSE,
    ADD_PROJECT_TASK_RESPONSE,
    UPDATE_PROJECT_TASK_RESPONSE,
    DELETE_PROJECT_TASK_BY_TASK_ID_RESPONSE,
    ADD_PROJECT_SUB_TASK_RESPONSE,
    DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_RESPONSE,
    GET_PROJECT_TASK_DETAILS_RESPONSE,
    ADD_PROJECT_TASK_COMMENT_RESPONSE,
    ADD_PROJECT_TASK_ATTACHMENT_RESPONSE,
    GET_PROJECT_TASK_ACTIVITY_RESPONSE,
    DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE,
    CLOSE_PROJECT_RESPONSE,
    ACTIVE_OR_DEACTIVE_PROJECT_RESPONSE
} from './actionTypes';
export const initialState = {
    entityAllUsersList: [],
    addProjectApiResponse: [],
    projectList: [],
    editProjectApiResponse: [],
    deleteProjectApiResponse: [],
    projectTaskList: [],
    projectTaskApiResponse: [],
    projectSubTaskApiResponse: [],
    projectTaskDetailsApiResponse: [],
    projectTaskCommentApiResponse: [],
    projectTaskAttachmentApiResponse: [],
    projectTaskActivityApiResponse: [],
    projectTaskDocumentApiResponse: [],
    closeProjectApiResoponse: [],
    activeOrDeactiveProjectApiResponse: [],
};
const projects = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_PROJECT_TASK_USERS_BY_ENTITY_RESPONSE:
                draft.entityAllUsersList = action.payload.entityAllUsersList;
                break;

            case ADD_PROJECT_RESPONSE:
            case UPDATE_PROJECT_RESPONSE:
                draft.addProjectApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_PROJECT_LIST_RESPONSE:
                draft.projectList = action.payload.projectList;
                break;
            case GET_PROJECT_DETAILS_RESPONSE:
                draft.editProjectApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case DELETE_PROJECT_BY_PROJECT_ID_RESPONSE:
                draft.deleteProjectApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_PROJECT_TASK_LIST_RESPONSE:
                draft.projectTaskList = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case ADD_PROJECT_TASK_RESPONSE:
            case UPDATE_PROJECT_TASK_RESPONSE:
            case DELETE_PROJECT_TASK_BY_TASK_ID_RESPONSE:
                draft.projectTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case ADD_PROJECT_SUB_TASK_RESPONSE:
            case DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_RESPONSE:
                draft.projectSubTaskApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;




            case GET_PROJECT_TASK_DETAILS_RESPONSE:
                draft.projectTaskDetailsApiResponse =
                    action.payload.taskDetails;
                break;
            case ADD_PROJECT_TASK_COMMENT_RESPONSE:
                draft.projectTaskCommentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case ADD_PROJECT_TASK_ATTACHMENT_RESPONSE:
                draft.projectTaskAttachmentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_PROJECT_TASK_ACTIVITY_RESPONSE:
                draft.projectTaskActivityApiResponse =
                    action.payload.activityDetails;
                break;
            case DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_RESPONSE:
                draft.projectTaskDocumentApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case CLOSE_PROJECT_RESPONSE:
                draft.closeProjectApiResoponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case ACTIVE_OR_DEACTIVE_PROJECT_RESPONSE:
                draft.activeOrDeactiveProjectApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default projects;
