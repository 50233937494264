import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import {
    getAllocationSearchListRequest,
    deleteAllocationByAllocationIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import ViewAllocationFilterForm from './ViewAllocationFilterForm';
import {
    initialViewAllocationFilterDataState,
    listColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import { DEFAULT_PAGE_LENGTH, SUCCESS } from 'constants/commonConstants';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_VIEW_ALLOCATION,
    PERMISSION_DELETE,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            allocationList: [],
            searchFormData: initialViewAllocationFilterDataState(),
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            resetTableSection: false,
            totalRecords: 0,
        };
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        this.getAllocationSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            {},
        );
    }
    getAllocationSearchList = (limit, page, sortBy, values = {}) => {
        values.userAllocation = false;
        let apiData = {
            limit: limit,
            page: page,
            filter: values,
            orderBy: sortBy,
        };
        this.props.getAllocationSearchListRequest(apiData, true);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
            },
            () => {
                this.getAllocationSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialViewAllocationFilterDataState(),
                currentPage: 1,
                resetTableSection: true,
            },
            () => {
                this.getAllocationSearchList(this.state.pageSize, 1, {
                    key: 'createdAt',
                    value: 'DESC',
                }, {});
            }
        );
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        { key: data.sortBy[0]?.id, value: sortOrder },
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSearch(data) {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                this.getAllocationSearchList(
                    this.state.pageSize,
                    1,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    }
    deleteAllocation = async (allocationId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.allocation_delete')
        );
        if (response) {
            this.props.deleteAllocationByAllocationIdRequest(allocationId);
        }
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((allocationData, i) => {
            let actions = (
                <div className="tbl_action">
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            window.open(
                                '/statutory-compliances/view-details/' +
                                allocationData.complianceId,
                                '_blank'
                            );
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                    {checkModulePermission(
                        MODULE_VIEW_ALLOCATION,
                        PERMISSION_DELETE
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteAllocation(allocationData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                </div>
            );
            return {
                companyGroupName:
                    allocationData.entity?.companyGroup?.companyGroupName,
                entityName: allocationData.entity?.entityName,
                complianceCode: allocationData.lawCompliance?.complianceCode,
                countryName:
                    allocationData.entityLocation?.country?.countryName,
                stateName: allocationData.entityLocation?.state?.stateName,
                cityName: allocationData.entityLocation?.city?.cityName,
                lawShortName: allocationData.lawCompliance?.law?.lawShortName,
                complianceTitle: allocationData.lawCompliance?.complianceTitle,
                complianceClassification:
                    allocationData.lawCompliance?.complianceCategory
                        ?.complianceCategoryName,
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allocationApiResponse &&
            this.props.allocationApiResponse !== nextprops.allocationApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                sortBy: nextprops.allocationApiResponse.data.orderBy,
                pageCount: nextprops.allocationApiResponse.data.totalPages,
                currentPage: nextprops.allocationApiResponse.data.currentPage,
                totalRecords: nextprops.allocationApiResponse.data.totalRecords,
                allocationList: this.generateTable(
                    nextprops.allocationApiResponse.data.complianceList
                ),
            });
        }
        if (
            nextprops.deleteAllocationApiResponse &&
            this.props.deleteAllocationApiResponse !==
            nextprops.deleteAllocationApiResponse
        ) {
            toastrMessage(
                nextprops.deleteAllocationApiResponse.message,
                nextprops.deleteAllocationApiResponse.responseType
            );
            if (
                nextprops.deleteAllocationApiResponse.responseType === SUCCESS
            ) {
                this.setState(
                    {
                        resetTableSection: true,
                    },
                    () => {
                        this.getAllocationSearchList(
                            this.state.pageSize,
                            this.state.currentPage,
                            this.state.sortBy,
                            {}
                        );
                    }
                );
            }
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.allocationList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'view-allocation'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={__t('page_headers.view_allocation')}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <ViewAllocationFilterForm
                                    allocationFilterData={
                                        this.state.searchFormData
                                    }
                                    operationType={this.state.operationType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    {!this.state.resetTableSection && (
                        <Col lg="12">
                            <Card>
                                <CardBody className="custom_table table-responsive">
                                    <CustomisedReactTableWithPagination
                                        className="table table-bordered table-striped"
                                        displayEntries={true}
                                        searching={true}
                                        currentPage={this.state.currentPage}
                                        pageCount={this.state.pageCount}
                                        handleFetchData={this.handleFetchData}
                                        handleSort={this.handleSort}
                                        handleSearch={this.handleSearch}
                                        preFilledSearchText={
                                            this.state.searchFormData
                                                ?.searchText
                                        }
                                        tableData={data}
                                        totalRecords={this.state.totalRecords}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    allocationApiResponse: makeAllocationSelectField('allocationApiResponse'),
    deleteAllocationApiResponse: makeAllocationSelectField(
        'deleteAllocationApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getAllocationSearchListRequest,
    deleteAllocationByAllocationIdRequest,
});
export default compose(
    withConnect,
    withAllocationReducer,
    withAllocationSaga,
    withRouter
)(Index);
