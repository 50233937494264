import { ACTIVATE_OR_DEACTIVATE_NEWS_REQUEST, ACTIVATE_OR_DEACTIVATE_NEWS_RESPONSE, CREATE_NEWS_REQUEST, CREATE_NEWS_RESPONSE, GET_NEWS_LIST_REQUEST, GET_NEWS_LIST_RESPONSE, UPDATE_NEWS_REQUEST, UPDATE_NEWS_RESPONSE, GET_NEWS_DETAILS_BY_ID_REQUEST, GET_NEWS_DETAILS_BY_ID_RESPONSE, PUBLISH_NEWS_BY_NEWS_ID_REQUEST, PUBLISH_NEWS_BY_NEWS_ID_RESPONSE, GET_NEWS_WITH_SEARCH_REQUEST, GET_NEWS_WITH_SEARCH_RESPONSE, GET_ALL_RELEASE_TYPE_LIST_REQUEST, GET_ALL_RELEASE_TYPE_LIST_RESPONSE } from './actionTypes';

export const getNewsListRequest = data => {
    return {
        type: GET_NEWS_LIST_REQUEST,
        payload: { data }
    };
};

export const getNewsListResponse = newsList => {
    return {
        type: GET_NEWS_LIST_RESPONSE,
        payload: { newsList },
    };
};

export const getNewsDetailsByIdRequest = newsId => {
    return {
        type: GET_NEWS_DETAILS_BY_ID_REQUEST,
        payload: { newsId }
    };
};

export const getNewsDetailsByIdResponse = (message, responseType, data) => {
    return {
        type: GET_NEWS_DETAILS_BY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const activateOrDeactivateNewsRequest = (isActive, id) => {
    let data = { isActive, id };
    return {
        type: ACTIVATE_OR_DEACTIVATE_NEWS_REQUEST,
        payload: { data },
    };
};

export const activateOrDeactivateNewsResponse = (
    message,
    responseType
) => {
    return {
        type: ACTIVATE_OR_DEACTIVATE_NEWS_RESPONSE,
        payload: { message, responseType },
    };
};

export const createNewsRequest = (data) => {
    return {
        type: CREATE_NEWS_REQUEST,
        payload: { data },
    };
};

export const createNewsResponse = (message, responseType) => {
    return {
        type: CREATE_NEWS_RESPONSE,
        payload: { message, responseType },
    };
};

export const updateNewsRequest = (newsId, data) => {
    return {
        type: UPDATE_NEWS_REQUEST,
        payload: { newsId, data },
    };
};

export const updateNewsResponse = (message, responseType) => {
    return {
        type: UPDATE_NEWS_RESPONSE,
        payload: { message, responseType },
    };
};

export const publishNewsByNewsIdRequest = (newsId) => {
    return {
        type: PUBLISH_NEWS_BY_NEWS_ID_REQUEST,
        payload: { newsId },
    };
};

export const publishNewsByNewsIdResponse = (message, responseType) => {
    return {
        type: PUBLISH_NEWS_BY_NEWS_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getNewsWithSearchRequest = (data) => {
    return {
        type: GET_NEWS_WITH_SEARCH_REQUEST,
        payload: { data }
    };
};

export const getNewsWithSearchResponse = (message, responseType, data) => {
    return {
        type: GET_NEWS_WITH_SEARCH_RESPONSE,
        payload: { message, responseType, data },
    };
};


export const getAllReleaseTypeListRequest = () => {
    return {
        type: GET_ALL_RELEASE_TYPE_LIST_REQUEST
    };
};

export const getAllReleaseTypeListResponse = releaseTypeList => {
    return {
        type: GET_ALL_RELEASE_TYPE_LIST_RESPONSE,
        payload: { releaseTypeList },
    };
};