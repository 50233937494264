import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    GET_DASHBOARD_DATA_BY_ROLE_REQUEST,
    GET_DASHBOARD_DETAILS_DATA_REQUEST,
    GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_REQUEST,
    GET_OVERALL_TASK_LIST_REQUEST,
    GET_DASHBOARD_LAW_SEARCH_REQUEST,
    GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_REQUEST,
    GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_REQUEST,
    GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_REQUEST,
    GET_DASHBOARD_ADMIN_DETAILS_DATA_REQUEST,
    GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_REQUEST,
    GET_DASHBOARD_DATA_FOR_RISK_REQUEST,
    GET_DASHBOARD_M_DETAILS_DATA_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import {
    GET_DASHBOARD_DATA_BY_ROLE,
    GET_DASHBOARD_DETAILS_DATA,
    GET_DASHBOARD_LAW_COMPLIANCES_SEARCH,
    GET_DASHBOARD_LAW_DOCUMENTS_SEARCH,
    GET_DASHBOARD_LAW_SEARCH,
    GET_DASHBOARD_MANAGEMENT_DETAILS_DATA,
    GET_OVERALL_TASK_LIST,
    GET_DASHBOARD_LAW_BY_FILTERS,
    GET_DASHBOARD_LAW_COMPLIANCE_BY_FILTERS,
    GET_DASHBOARD_LAW_DOCUMENTS_FILTERS,
    GET_DASHBOARD_DEPARTMENT_TASKS_BY_ENTITY,
    GET_DASHBOARD_ADMIN_DETAILS_DATA,
    GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE,
    GET_DASHBOARD_DATA_FOR_OVERALL,
    GET_DASHBOARD_M_DETAILS_DATA,
} from 'helpers/apiConstants';
import {
    getDashboardAdminDetailsDataResponse,
    getDashboardDataByRoleResponse,
    getDashboardDataForRiskResponse,
    getDashboardDataMonthWiseByRoleResponse,
    getDashboardDepartmentTaskByEntityResponse,
    getDashboardDetailsDataResponse,
    getDashboardLawCompliancesSearchResponse,
    getDashboardLawDocumentsSearchResponse,
    getDashboardLawSearchResponse,
    getDashboardMDetailsDataResponse,
    getDashboardManagementDetailsDataResponse,
    getOverallTaskListResponse,
} from './actions';
import { DANGER, SUCCESS } from 'constants/commonConstants';
function* dashboardDataByRole({ payload: { roleId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_DATA_BY_ROLE + '/' + roleId,
            data
        );

        if (response.status) {
            yield put(
                getDashboardDataByRoleResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardDataByRoleResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* dashboardDetailsData({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_DETAILS_DATA,
            data
        );
        if (response.status) {
            yield put(
                getDashboardDetailsDataResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardDetailsDataResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* dashboardAdminDetailsData({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_ADMIN_DETAILS_DATA,
            data
        );
        if (response.status) {
            yield put(
                getDashboardAdminDetailsDataResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardAdminDetailsDataResponse(
                    response.message,
                    DANGER,
                    {}
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* dashboardMDetailsData({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_M_DETAILS_DATA,
            data
        );
        if (response.status) {
            yield put(
                getDashboardMDetailsDataResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardMDetailsDataResponse(
                    response.message,
                    DANGER,
                    {}
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* dashboardManagementDetailsData({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_MANAGEMENT_DETAILS_DATA,
            data
        );
        if (response.status) {
            yield put(
                getDashboardManagementDetailsDataResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardManagementDetailsDataResponse(
                    response.message,
                    DANGER,
                    {}
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getOverallTaskList({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_OVERALL_TASK_LIST,
            data
        );
        if (response.status) {
            yield put(
                getOverallTaskListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getOverallTaskListResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* dashboardLawSearch({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_LAW_SEARCH,
            data
        );
        if (response.status) {
            yield put(
                getDashboardLawSearchResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardLawSearchResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* dashboardLawCompliancesSearch({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_LAW_COMPLIANCES_SEARCH,
            data
        );
        if (response.status) {
            yield put(
                getDashboardLawCompliancesSearchResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardLawCompliancesSearchResponse(
                    response.message,
                    DANGER,
                    {}
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* dashboardLawDocumentsSearch({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_LAW_DOCUMENTS_SEARCH,
            data
        );
        if (response.status) {
            yield put(
                getDashboardLawDocumentsSearchResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardLawDocumentsSearchResponse(
                    response.message,
                    DANGER,
                    {}
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getDashboardLawSearch({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_LAW_BY_FILTERS,
            data
        );

        if (response.status) {
            yield put(
                getDashboardLawSearchResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardLawSearchResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getDashboardLawCompliancesSearch({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_LAW_COMPLIANCE_BY_FILTERS,
            data
        );

        if (response.status) {
            yield put(
                getDashboardLawCompliancesSearchResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardLawCompliancesSearchResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getDashboardLawDocumentsSearch({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_LAW_DOCUMENTS_FILTERS,
            data
        );

        if (response.status) {
            yield put(
                getDashboardLawDocumentsSearchResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardLawDocumentsSearchResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* dashboardDepartmentTaskByEntity({ payload: { roleId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_DEPARTMENT_TASKS_BY_ENTITY + '/' + roleId,
            data
        );

        if (response.status) {
            yield put(
                getDashboardDepartmentTaskByEntityResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardDepartmentTaskByEntityResponse(
                    response.message,
                    DANGER,
                    {}
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* dashboardDataMonthWiseByRole({ payload: { roleId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE + '/' + roleId,
            data
        );

        if (response.status) {
            yield put(
                getDashboardDataMonthWiseByRoleResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardDataMonthWiseByRoleResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getDashboardDataForRisk({ payload: { roleId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_DASHBOARD_DATA_FOR_OVERALL + '/' + roleId,
            data
        );

        if (response.status) {
            yield put(
                getDashboardDataForRiskResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getDashboardDataForRiskResponse(response.message, DANGER, {})
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchDashboard() {
    yield takeEvery(GET_DASHBOARD_DATA_BY_ROLE_REQUEST, dashboardDataByRole);
    yield takeEvery(GET_DASHBOARD_DETAILS_DATA_REQUEST, dashboardDetailsData);
    yield takeEvery(GET_DASHBOARD_ADMIN_DETAILS_DATA_REQUEST, dashboardAdminDetailsData);
    yield takeEvery(GET_DASHBOARD_M_DETAILS_DATA_REQUEST, dashboardMDetailsData);
    yield takeEvery(GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_REQUEST, dashboardManagementDetailsData);
    yield takeEvery(GET_OVERALL_TASK_LIST_REQUEST, getOverallTaskList);
    yield takeEvery(GET_DASHBOARD_LAW_SEARCH, dashboardLawSearch);
    yield takeEvery(GET_DASHBOARD_LAW_COMPLIANCES_SEARCH, dashboardLawCompliancesSearch);
    yield takeEvery(GET_DASHBOARD_LAW_DOCUMENTS_SEARCH, dashboardLawDocumentsSearch);
    yield takeEvery(GET_DASHBOARD_LAW_SEARCH_REQUEST, getDashboardLawSearch);
    yield takeEvery(GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_REQUEST, getDashboardLawCompliancesSearch);
    yield takeEvery(GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_REQUEST, getDashboardLawDocumentsSearch);

    yield takeEvery(GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_REQUEST, dashboardDepartmentTaskByEntity);
    yield takeEvery(GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_REQUEST, dashboardDataMonthWiseByRole);


    yield takeEvery(GET_DASHBOARD_DATA_FOR_RISK_REQUEST, getDashboardDataForRisk);

}
function* DashboardSaga() {
    yield all([fork(watchDashboard)]);
}
export default DashboardSaga;
