import { ADD_AUDIT_OBSERVATION_REQUEST, GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_REQUEST, UPDATE_AUDIT_OBSERVATION_STATUS_REQUEST } from "./actionTypes";
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addAuditObservationResponse, getAuditObservationByTaskIdResponse, updateAuditObservationStatusResponse } from "./actions";
import { AUDIT_OBSERVATION } from 'helpers/apiConstants';
import { DANGER, SUCCESS } from "constants/commonConstants";
import { invokeApi } from "helpers/axiosHelper";
import { handleApiCallException } from "store/Shared/actions";

function* addAuditObservation({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', AUDIT_OBSERVATION, data, headers);
        if (response.status) {
            yield put(addAuditObservationResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(addAuditObservationResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* auditObservationList({ payload: { taskId } }) {
    try {
        const response = yield call(invokeApi, 'get', AUDIT_OBSERVATION + '/' + taskId, '');
        if (response.status) {
            yield put(getAuditObservationByTaskIdResponse(response.data.list));
        } else {
            yield put(getAuditObservationByTaskIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateAuditObservationStatus({ payload: { taskId } }) {
    try {
        const response = yield call(invokeApi, 'put', AUDIT_OBSERVATION + '/' + taskId, {});
        if (response.status) {
            yield put(updateAuditObservationStatusResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(updateAuditObservationStatusResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchAuditObservation() {
    yield takeEvery(ADD_AUDIT_OBSERVATION_REQUEST, addAuditObservation);
    yield takeEvery(GET_AUDIT_OBSERVATION_LIST_BY_TASK_ID_REQUEST, auditObservationList);
    yield takeEvery(UPDATE_AUDIT_OBSERVATION_STATUS_REQUEST, updateAuditObservationStatus);
}
function* auditObservationSaga() {
    yield all([fork(watchAuditObservation)]);
}
export default auditObservationSaga;