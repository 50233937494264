import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    ADD_NEW_TICKET_REQUEST,
    EDIT_TICKET_REQUEST,
    GET_TICKET_DETAILS_BY_ID_REQUEST,
    LIST_TICKET_CATEGORY_REQUEST,
    LIST_TICKET_REQUEST,
    LIST_TICKET_ASSIGNEE_USERS_REQUEST,
    ADD_NEW_TICKET_MESSAGE_REQUEST,
    GET_TICKET_MESSAGE_LIST_REQUEST,
    GET_TICKET_COUNT_REQUEST
} from './actionTypes';
import {
    addNewTicketResponse,
    editTicketResponse,
    getTicketDetailsByIdResponse,
    listTicketCategoryResponse,
    listTicketResponse,
    listTicketAssigneeUsersResponse,
    addNewTicketMessageResponse,
    getTicketMessageListResponse,
    getTicketCountResponse
} from './actions';
import { TICKET, TICKET_SEARCH, TICKET_CATEGORY, TICKET_ASSIGNEE_USER, TICKET_MESSAGE, TICKET_HISTORY_LIST, TICKET_COUNT } from 'helpers/apiConstants';

function* addNewTicket({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', TICKET, data, headers);
        if (response.status) {
            yield put(addNewTicketResponse(response.message, SUCCESS));
        } else {
            yield put(addNewTicketResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* editTicket({ payload: { ticketId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            TICKET + '/' + ticketId,
            data
        );
        if (response.status) {
            yield put(editTicketResponse(response.message, SUCCESS));
        } else {
            yield put(editTicketResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllTicketList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', TICKET_SEARCH, data);

        if (response.status) {
            yield put(listTicketResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(listTicketResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllTicketCategotyList() {
    try {
        const response = yield call(invokeApi, 'get', TICKET_CATEGORY, '');

        if (response.status) {
            yield put(listTicketCategoryResponse(response.data.list));
        } else {
            yield put(listTicketCategoryResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getTicketDetailsById({ payload: { ticketId } }) {
    try {
        const response = yield call(invokeApi, 'get', TICKET + '/' + ticketId, '');
        if (response.status) {
            yield put(getTicketDetailsByIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getTicketDetailsByIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getTicketAssigneeUserList() {
    try {
        const response = yield call(invokeApi, 'get', TICKET_ASSIGNEE_USER, '');
        if (response.status) {
            yield put(listTicketAssigneeUsersResponse(response.data.list));
        } else {
            yield put(listTicketAssigneeUsersResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addNewTicketMessage({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(invokeApi, 'post', TICKET_MESSAGE, data, headers);
        if (response.status) {
            yield put(addNewTicketMessageResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(addNewTicketMessageResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getTicketMessageList({ payload: { ticketId } }) {
    try {
        const response = yield call(invokeApi, 'get', TICKET_HISTORY_LIST + '/' + ticketId, '');
        if (response.status) {
            yield put(getTicketMessageListResponse(response.data.list));
        } else {
            yield put(getTicketMessageListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getTicketCount() {
    try {
        const response = yield call(invokeApi, 'get', TICKET_COUNT, '');
        if (response.status) {
            yield put(getTicketCountResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getTicketCountResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchTicket() {
    yield takeEvery(ADD_NEW_TICKET_REQUEST, addNewTicket);
    yield takeEvery(EDIT_TICKET_REQUEST, editTicket);
    yield takeEvery(LIST_TICKET_CATEGORY_REQUEST, getAllTicketCategotyList);
    yield takeEvery(LIST_TICKET_REQUEST, getAllTicketList);
    yield takeEvery(GET_TICKET_DETAILS_BY_ID_REQUEST, getTicketDetailsById);
    yield takeEvery(LIST_TICKET_ASSIGNEE_USERS_REQUEST, getTicketAssigneeUserList);
    yield takeEvery(ADD_NEW_TICKET_MESSAGE_REQUEST, addNewTicketMessage);
    yield takeEvery(GET_TICKET_MESSAGE_LIST_REQUEST, getTicketMessageList);
    yield takeEvery(GET_TICKET_COUNT_REQUEST, getTicketCount);
}

function* ticketSaga() {
    yield all([fork(watchTicket)]);
}
export default ticketSaga;