import * as authConstants from 'constants/authConstants';
import { compareIntValue } from './generalUtils';
import { checkModuleUrlPermission } from './projectUtils';
import { jwtDecode } from 'jwt-decode';
import { DANGER } from 'constants/commonConstants';
import { toastrMessage } from './messageHelper';
import __t from 'i18n/translator';
import { decryptData } from './encryptor';
//Set the logged in user data in local session
const setUserDetails = (userDetails) => {
    localStorage.setItem(authConstants.THEME_MODE, 'light');
    localStorage.setItem(authConstants.TOKEN, userDetails.accessToken);
    localStorage.setItem(authConstants.USER_ID, userDetails.user.userId);
    localStorage.setItem(
        authConstants.SET_NEW_PASSWORD,
        userDetails.user.issetPassword
    );
    localStorage.setItem(authConstants.USER_EMAIL, userDetails.user.email);
    localStorage.setItem(
        authConstants.USER_USERNAME,
        userDetails.user.userName
    );
    localStorage.setItem(
        authConstants.USER_FULL_NAME,
        userDetails.user.fullName
    );
    localStorage.setItem(
        authConstants.USER_CONTACT_NO,
        userDetails.user.contactNo
    );
    localStorage.setItem(authConstants.THEME_MODE, (userDetails.user.darkMode == 1) ? 'dark' : 'light');
    localStorage.setItem(authConstants.PROJECT_LAYOUT, 'false');
    if (userDetails?.user?.tenant) {
        localStorage.setItem(authConstants.TENANT_FUND, (userDetails?.user?.tenant?.isFundManagementEnabled === true) ? 'true' : 'false')
    }
    localStorage.setItem(authConstants.FUND_LAYOUT, 'false');
    // store roles in local storage with stringify as its an object
    localStorage.setItem(authConstants.ROLES, userDetails.user.roles);
    const roles = JSON.parse(decryptData(userDetails.user.roles))?.sort((item1, item2) =>
        compareIntValue(item1.priority, item2.priority)
    );
    localStorage.setItem(
        authConstants.CURRENT_ROLE,
        roles[0] ? roles[0].id : ''
    );

    localStorage.setItem(
        authConstants.PERMISSIONS,
        userDetails.user.permissions
    );
    localStorage.setItem(
        authConstants.USER_ROLES,
        userDetails.user.userRoles
    );
};
const getValueByKey = (key) => {
    return localStorage.getItem(key);
};
const removeLoggedInUserDetails = () => {
    const themeMode = localStorage.getItem(authConstants.THEME_MODE);
    localStorage.clear();
    sessionStorage.clear();
    if (themeMode) {
        localStorage.setItem(authConstants.THEME_MODE, themeMode);
    } else {
        localStorage.setItem(authConstants.THEME_MODE, 'light');
    }
    // window.location.reload();
};
const isUserAuthenticated = () => {
    const token = localStorage.getItem(authConstants.TOKEN);
    if (token) {
        return true;
    } else {
        return false;
    }
};
const setValueByKey = (key, value) => {
    if (value) {
        return localStorage.setItem(key, value);
    }
};

const checkRoutePath = (routePath, routeActualPath) => {
    const specificRoutes = [
        '/home',
        '/login',
        '/logout',
        '/forgot-password',
        '/set-new-password',
        '/reset-password/:token',
        '/edit-profile',
        '/change-password',
        '/coming-soon',
        '/projects',
        '/projects/manage/add',
        '/projects/manage/edit/:projectId',
        '/projects/manage/details/:projectId',
        '/statutory-compliances/view-law/:lawId',
        '/statutory-compliances/view-law/:lawId/:activeTab',
        '/statutory-compliances/view-details/:complianceId',
        '/compliances/report/:taskId',
        '/compliances/review/:taskId',
        '/compliances/audit/:taskId',
        '/legal-update/view-details/:id',
        '/statutory-compliances/edit-law-details/:lawId',
        '/statutory-compliances/edit-details/:complianceId',
        '/legal-update/edit/:legalUpdatesId',
        '/legal-watch-dog/view-details/:newsId',
        '/relevant-updates/view-details/:updateId',
        '/relevant-updates/view-analysis/:updateId',
        '/view-update-analysis/:updateId',
        '/funds/master',
        '/funds',
        '/funds/manage/add',
        '/funds/manage/edit/:fundId',
        '/funds/manage/details/:fundId',
        '/event-compliances/:eventId'
    ];

    if (!specificRoutes.includes(routeActualPath)) {
        return checkModuleUrlPermission(routePath, routeActualPath);
    } else {
        return true;
    }
};
const checkDataWithLocalStorage = () => {
    let loginType = getValueByKey(authConstants.LOGIN_TYPE);
    if (loginType != 'SSO') {
        let accessToken = getValueByKey('Token')
        let checkDecodeTokenData = jwtDecode(accessToken)
        if (checkDecodeTokenData && checkDecodeTokenData.userName) {
            checkDecodeTokenData = (({ iat, exp, type, issetPassword, fullName, contactNo, email, ...rest }) => rest)(checkDecodeTokenData);
            let localStorageJson = {
                userId: parseInt(getValueByKey(authConstants.USER_ID)),
                userName: getValueByKey(authConstants.USER_USERNAME),
                permissions: JSON.parse(decryptData(getValueByKey(authConstants.PERMISSIONS)))
            }

            const sortedObject1 = Object.fromEntries(Object.entries(checkDecodeTokenData).sort());
            const sortedObject2 = Object.fromEntries(Object.entries(localStorageJson).sort());
            if (JSON.stringify(sortedObject1) !== JSON.stringify(sortedObject2)) {
                localStorage.clear();
                setTimeout(function () {
                    window.location.href = '/home';
                }, 2000);
            }
        }
    }
}
export {
    setUserDetails,
    isUserAuthenticated,
    removeLoggedInUserDetails,
    getValueByKey,
    setValueByKey,
    checkRoutePath,
    checkDataWithLocalStorage
};
