import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST, RESET_PASSWORD_TOKEN_REQUEST
} from './actionTypes';
import {
    forgotPasswordResponse, resetPasswordResponse, resetPasswordTokenResponse
} from './actions';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { SUCCESS, DANGER } from 'constants/commonConstants';
import { CONFIRM_RESET_PASSWORD_TOKEN, FORGOT_PASSWORD, RESET_PASSWORD } from 'helpers/apiConstants';
function* forgotPassword({ payload: { email } }) {
    try {
        const response = yield call(invokeApi, 'post', FORGOT_PASSWORD, {
            email: email.toLowerCase()
        });
        if (response.status) {
            yield put(forgotPasswordResponse(response.message, SUCCESS, response.data)
            );
        } else {
            yield put(forgotPasswordResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* resetPasswordToken({ payload: { token } }) {
    try {
        const response = yield call(invokeApi, 'get', CONFIRM_RESET_PASSWORD_TOKEN + '/' + token);
        if (response.status) {
            yield put(
                resetPasswordTokenResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(resetPasswordTokenResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* resetPassword({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', RESET_PASSWORD, data);
        if (response.status) {
            yield put(resetPasswordResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(resetPasswordResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchUserForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword);
    yield takeEvery(RESET_PASSWORD_TOKEN_REQUEST, resetPasswordToken);
    yield takeEvery(RESET_PASSWORD_REQUEST, resetPassword);
}
function* forgotPasswordSaga() {
    yield all([fork(watchUserForgotPassword)]);
}
export default forgotPasswordSaga;
