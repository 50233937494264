export const ADD_ENTITY_LOCATION_REQUEST = 'add_entity_location_request';
export const ADD_ENTITY_LOCATION_RESPONSE = 'add_entity_location_response';
export const GET_ALL_ENTITY_LOCATION_LIST_REQUEST = 'get_all_entity_location_list_request';
export const GET_ALL_ENTITY_LOCATION_LIST_RESPONSE = 'get_all_entity_location_list_response';
export const GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST = 'get_entity_location_by_entity_location_id_request';
export const GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE = 'get_entity_location_by_entity_location_id_response';
export const UPDATE_ENTITY_LOCATION_REQUEST = 'update_entity_location_request';
export const UPDATE_ENTITY_LOCATION_RESPONSE = 'update_entity_location_response';
export const UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_REQUEST = 'update_entity_location_status_by_entity_location_id_request';
export const UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_RESPONSE = 'update_entity_location_status_by_entity_location_id_response';
export const DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST = 'delete_entity_location_by_entity_location_id_request';
export const DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE = 'delete_entity_location_by_entity_location_id_response';
export const GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_REQUEST = 'get_entity_location_list_by_entity_id_request';
export const GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_RESPONSE = 'get_entity_location_list_by_entity_id_response';