import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';


class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});
export default compose(
    withConnect,
    withRouter
)(Index);
