import React, { Component } from 'react';
import CustomisedModal from 'components/Application/CustomisedModal';
import { Col, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import ShowMoreText from 'react-show-more-text';

class ViewUnblockedComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unbockedEmailObj: {},
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.unbockedEmailObj !== this.props.unbockedEmailObj) {
            this.setState({
                unbockedEmailObj: nextprops.unbockedEmailObj,
            });
        }
    }

    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={
                        this.props.modalName
                    }
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row className="view_activity_modal">
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.unblock_comment.resolved_by')}
                                </Label>{' '}
                                <br />
                                {this.state.unbockedEmailObj?.fullName}
                            </p>
                        </Col>
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.unblock_comment.resolved_on')}
                                </Label>
                                <br />
                                {
                                    this.state.unbockedEmailObj?.createdAt
                                }
                            </p>
                        </Col>
                        <Col lg="12">
                            <p>
                                <Label>
                                    {__t(
                                        'form_labels.unblock_comment.resolved_reason'
                                    )}
                                </Label>{' '}
                                <br />
                                {this.getShowMoreTextElement(this.state.unbockedEmailObj?.comment)}
                            </p>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
export default ViewUnblockedComment;
