import {
    GET_GENERATE_CERTIFICATE_REQUEST,
    GET_GENERATE_CERTIFICATE_RESPONSE,
    GET_REPORT_DATA_BY_TYPE_REQUEST,
    GET_REPORT_DATA_BY_TYPE_RESPONSE,
    GET_SYSTEM_RUNTIME_REPORT_REQUEST,
    GET_SYSTEM_RUNTIME_REPORT_RESPONSE,
} from './actionTypes';

export const getReportDataByTypeRequest = (data) => {
    return {
        type: GET_REPORT_DATA_BY_TYPE_REQUEST,
        payload: { data }
    };
};
export const getReportDataByTypeResponse = (message, responseType, data) => {
    return {
        type: GET_REPORT_DATA_BY_TYPE_RESPONSE,
        payload: { message, responseType, data }
    };
};
export const getSystemRuntimeReportRequest = (data) => {
    return {
        type: GET_SYSTEM_RUNTIME_REPORT_REQUEST,
        payload: { data }
    };
};
export const getSystemRuntimeReportResponse = (message, responseType, data) => {
    return {
        type: GET_SYSTEM_RUNTIME_REPORT_RESPONSE,
        payload: { message, responseType, data }
    };
};
export const getGenerateCertificateRequest = (data) => {
    return {
        type: GET_GENERATE_CERTIFICATE_REQUEST,
        payload: { data }
    };
};
export const getGenerateCertificateResponse = (message, responseType, data) => {
    return {
        type: GET_GENERATE_CERTIFICATE_RESPONSE,
        payload: { message, responseType, data }
    };
};