import { ACTIVE } from 'constants/commonConstants';
import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Company Group',
        field: 'companyGroupName',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State',
        field: 'stateName',
    },
    {
        label: 'City',
        field: 'cityName',
    },
    {
        label: 'Registration Number',
        field: 'registrationNumber',
        sort: 'disabled',
    },
    {
        label: 'Registration Date',
        field: 'registrationDate',
        sort: 'disabled',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialEntityLocationDataState = () => {
    return {
        companyGroupId: '',
        entityId: '',
        countryId: '',
        stateId: '',
        cityId: '',
        pincode: '',
        registrationNumber: '',
        registrationDate: '',
        isActive: ACTIVE,
    };
};
export const intFields = [
    'companyGroupId',
    'entityId',
    'countryId',
    'stateId',
    'cityId',
];
export const nullableFields = ['registrationNumber', 'registrationDate'];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
