import produce from 'immer';
import {
    SHOW_LAW_COMPLIANCE,
    ADD_LAW_COMPLIANCE_RESPONSE,
    GET_ALL_LAW_COMPLIANCES_RESPONSE,
    GET_LAW_COMPLIANCES_BY_LAW_ID_RESPONSE,
    GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_RESPONSE,
    GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_RESPONSE,
    GET_ALL_UPLOADED_FILES_RESPONSE,
    GET_LAW_COMPLIANCES_FOR_ALLOCATION_RESPONSE,
    UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_RESPONSE,
    GET_LAW_COMPLIANCE_SEARCH_RESPONSE,
    GET_COMPLIANCE_TYPES_RESPONSE,
    GET_COMPLIANCE_CATEGORIES_RESPONSE,
    GET_PERIODICITIES_RESPONSE,
    GET_PENALTY_TYPES_RESPONSE,
    GET_RISK_RATING_RESPONSE,
    UPDATE_LAW_COMPLIANCE_RESPONSE,
    GET_LAW_COMPLIANCE_LOW_ID_RESPONSE,
    GET_LAW_SECTION_BY_LOW_ID_RESPONSE,
    GET_LEGAL_UPDATE_REFERENCE_ID_RESPONSE,
    GET_IMPACT_MANAGEMENT_PERSONNELS_RESPONSE,
    GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_RESPONSE,
    DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_RESPONSE,
    CLEAR_COMPLIANCE_TYPE_RESPONSE,
    CLEAR_COMPLIANCE_CATEGORY_RESPONSE,
    CLEAR_RISK_RATING_RESPONSE
} from './actionTypes';
export const initialState = {
    lawComplianceApiResponse: null,
    lawComplianceList: [],
    allLawCompliances: [],
    percentageApiResponse: null,
    fileProgress: 0,
    uploadedFileList: [],
    lawComplianceDetailsApiResponse: null,
    lawComplianceStatusUpdateApiResponse: null,
    lawComplianceSearchApiResponse: null,
    allCompliancesTypesList: null,
    allCompliancesCategoriesList: null,
    allPeriodicitiesList: null,
    penaltyTypesList: null,
    riskRatingsList: null,
    impactManagementPersonnelList: null,
    updateLawComplianceApiResponse: null,
    lawComplianceListApiResponse: null,
    lawSectionApiResponse: null,
    deleteComplianceQueueFileApiResponse: null,
    legalUpdateReferenceList: [],
    complianceVersionList: []
};
const lawCompliance = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_LAW_COMPLIANCE_RESPONSE:
                draft.lawComplianceApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_ALL_UPLOADED_FILES_RESPONSE:
                draft.uploadedFileList = action.payload.uploadedFileList;
                break;
            case GET_ALL_LAW_COMPLIANCES_RESPONSE:
                draft.allLawCompliances = action.payload.allLawCompliances;
                break;
            case GET_LAW_COMPLIANCES_BY_LAW_ID_RESPONSE:
                draft.lawComplianceList = action.payload.lawComplianceList;
                break;
            case SHOW_LAW_COMPLIANCE:
                draft.percentageApiResponse = {
                    // percentage: action.payload.percentage,
                    fileProgress: action.payload.percentage,
                };
                break;
            case GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_RESPONSE:
                draft.lawComplianceList = action.payload.lawComplianceList;
                break;
            case GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_RESPONSE:
                draft.lawComplianceDetailsApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case GET_LAW_COMPLIANCES_FOR_ALLOCATION_RESPONSE:
                draft.lawComplianceList = action.payload.lawComplianceList;
                break;
            case UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_RESPONSE:
                draft.lawComplianceStatusUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_LAW_COMPLIANCE_SEARCH_RESPONSE:
                draft.lawComplianceSearchApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;

            case GET_COMPLIANCE_TYPES_RESPONSE:
                draft.allCompliancesTypesList =
                    action.payload.allCompliancesTypes;
                break;
            case GET_COMPLIANCE_CATEGORIES_RESPONSE:
                draft.allCompliancesCategoriesList =
                    action.payload.allCompliancesCategoriesList;
                break;
            case GET_PERIODICITIES_RESPONSE:
                draft.allPeriodicitiesList = action.payload.allperiodicities;
                break;
            case GET_PENALTY_TYPES_RESPONSE:
                draft.penaltyTypesList = action.payload.penaltyTypes;
                break;
            case GET_RISK_RATING_RESPONSE:
                draft.riskRatingsList = action.payload.riskRatings;
                break;
            case GET_IMPACT_MANAGEMENT_PERSONNELS_RESPONSE:
                draft.impactManagementPersonnelList = action.payload.list;
                break;

            case UPDATE_LAW_COMPLIANCE_RESPONSE:
                draft.updateLawComplianceApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_LAW_COMPLIANCE_LOW_ID_RESPONSE:
                draft.lawComplianceListApiResponse =
                    action.payload.lawCompliance;
                break;
            case GET_LAW_SECTION_BY_LOW_ID_RESPONSE:
                draft.lawSectionApiResponse = action.payload.lawSection;
                break;
            case GET_LEGAL_UPDATE_REFERENCE_ID_RESPONSE:
                draft.legalUpdateReferenceList =
                    action.payload.legalUpdateReference;
                break;
            case GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_RESPONSE:
                draft.complianceVersionList = action.payload.complianceVersionList;
                break;
            case DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_RESPONSE:
                draft.deleteComplianceQueueFileApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };;
                break;
            case CLEAR_COMPLIANCE_TYPE_RESPONSE:
                draft.allCompliancesTypesList = null
                break;
            case CLEAR_COMPLIANCE_CATEGORY_RESPONSE:
                draft.allCompliancesCategoriesList = null
                break;
            case CLEAR_RISK_RATING_RESPONSE:
                draft.riskRatingsList = null
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default lawCompliance;
