import produce from 'immer';
import {
    ADD_DELEGATION_RESPONSE,
    DELETE_DELEGATION_RESPONSE,
    GET_ALL_DELEGATION_LIST_RESPONSE,
    UPDATE_DELEGATION_RESPONSE,
} from './actionTypes';
export const initialState = {
    delegationApiResponse: null,
    editEntityLocationApiResponse: null,
    delegationList: [],
};
const delegation = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_DELEGATION_RESPONSE:
            case UPDATE_DELEGATION_RESPONSE:
            case DELETE_DELEGATION_RESPONSE:
                draft.delegationApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_DELEGATION_LIST_RESPONSE:
                draft.delegationList = action.payload.delegationList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default delegation;
