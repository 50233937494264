import { setEmptyToNull } from 'helpers/generalUtils';
import { isManagementRole } from 'helpers/projectUtils';
export const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Class',
        field: 'complianceType',
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Task Status',
        field: 'taskStatusId',
    },    
    {
        label: 'Audit Status',
        field: 'auditStatus',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];


export const listColumnsWithCheckbox = [
    {
        Header: 'Code',
        accessor: 'taskCode',
        disableSortBy: true,
    },
    {
        Header: 'Entity',
        accessor: 'entityName',
        disableSortBy: true,
    },
    {
        Header: 'Location',
        accessor: 'cityName',
        disableSortBy: true,
    },
    {
        Header: 'Compliance Code',
        accessor: 'complianceCode',
        disableSortBy: true,
    },
    {
        Header: 'Title',
        accessor: 'complianceTitle',
        disableSortBy: true,
    },
    {
        Header: 'Type',
        accessor: 'complianceType',
        disableSortBy: true,
    },
    {
        Header: 'Classification',
        accessor: 'complianceClass',
        disableSortBy: true,
    },
    {
        Header: 'Due Date',
        accessor: 'dueDate',
        disableSortBy: true,
    },
    {
        Header: 'Task Type',
        accessor: 'parentTaskId',
        disableSortBy: true,
    },    
    {
        Header: 'Role',
        accessor: 'role',
        disableSortBy: true,
    },
    {
        Header: 'Task Status',
        accessor: 'taskStatusId',
        disableSortBy: true,
    },
    {
        Header: 'Audit Status',
        accessor: 'auditStatus',
        disableSortBy: true,
    },
    {
        Header: 'Action',
        className: 'action_col',
        accessor: 'actions',
        disableSortBy: true,
    },
];
export const initialTrackComplianceStatusFilterDataState = () => {
    return {
        companyGroupId: [],
        entityId: [],
        countryId: [],
        stateId: [],
        cityId: [],
        statusId: [],
        isManagementUser: isManagementRole(),
        searchText: '',
        riskRatingId: [],
        complianceCategoryId: []
    };
};
export const intFields = [];
export const nullableFields = ['searchText'];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
