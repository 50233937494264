import {
    GET_FILING_TYPES_REQUEST,
    GET_FILING_TYPES_RESPONSE,
    GET_IMPORT_LOGS_LIST_BY_TYPE_REQUEST,
    GET_IMPORT_LOGS_LIST_BY_TYPE_RESPONSE,
    IMPORT_FILE_BY_TYPE_REQUEST,
    IMPORT_FILE_BY_TYPE_RESPONSE,
    GET_MASTER_SAMPLE_REQUEST,
    GET_MASTER_SAMPLE_RESPONSE
} from './actionTypes';
export const importFileByTypeRequest = (data) => {
    return {
        type: IMPORT_FILE_BY_TYPE_REQUEST,
        payload: { data },
    };
};
export const importFileByTypeResponse = (message, responseType) => {
    return {
        type: IMPORT_FILE_BY_TYPE_RESPONSE,
        payload: { message, responseType },
    };
};
export const getImportLogsListByTypeRequest = (data) => {
    return {
        type: GET_IMPORT_LOGS_LIST_BY_TYPE_REQUEST,
        payload: { data },
    };
};
export const getImportLogsListByTypeResponse = (importLogsList) => {
    return {
        type: GET_IMPORT_LOGS_LIST_BY_TYPE_RESPONSE,
        payload: { importLogsList },
    };
};

export const getFilingTypesRequest = () => {
    return {
        type: GET_FILING_TYPES_REQUEST,
    };
};
export const getFilingTypesResponse = (filingTypes) => {
    return {
        type: GET_FILING_TYPES_RESPONSE,
        payload: { filingTypes },
    };
};

export const getMasterSampleRequest = (data) => {
    return {
        type: GET_MASTER_SAMPLE_REQUEST,
        payload: { data },
    };
};

export const getMasterSampleResponse = (data) => {
    return {
        type: GET_MASTER_SAMPLE_RESPONSE,
        payload: { data },
    };
};