import { ADD_APPLICABILITY_REQUEST, ADD_APPLICABILITY_RESPONSE, DELETE_APPLICABILITY_BY_APPLICABILITY_ID_REQUEST, DELETE_APPLICABILITY_BY_APPLICABILITY_ID_RESPONSE, GET_ALL_APPLICABILITY_LIST_REQUEST, GET_ALL_APPLICABILITY_LIST_RESPONSE, GET_APPLICABILITY_LIST_BY_STATUS_REQUEST, GET_APPLICABILITY_LIST_BY_STATUS_RESPONSE, GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_REQUEST, GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_RESPONSE, UPDATE_APPLICABILITY_REQUEST, UPDATE_APPLICABILITY_RESPONSE, UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_REQUEST, UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_RESPONSE } from "./actionTypes";
export const addApplicabilityRequest = data => {
    return {
        type: ADD_APPLICABILITY_REQUEST,
        payload: { data },
    };
};
export const addApplicabilityResponse = (message, responseType) => {
    return {
        type: ADD_APPLICABILITY_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllApplicabilityListRequest = () => {
    return {
        type: GET_ALL_APPLICABILITY_LIST_REQUEST
    };
};
export const getAllApplicabilityListResponse = applicabilityList => {
    return {
        type: GET_ALL_APPLICABILITY_LIST_RESPONSE,
        payload: { applicabilityList },
    };
};
export const updateApplicabilityRequest = (applicabilityId, data) => {
    return {
        type: UPDATE_APPLICABILITY_REQUEST,
        payload: { applicabilityId, data },
    };
};
export const updateApplicabilityResponse = (message, responseType) => {
    return {
        type: UPDATE_APPLICABILITY_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateApplicabilityStatusByApplicabilityIdRequest = (applicabilityId, status) => {
    return {
        type: UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_REQUEST,
        payload: { applicabilityId, status },
    };
};
export const updateApplicabilityStatusByApplicabilityIdResponse = (message, responseType) => {
    return {
        type: UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const deleteApplicabilityByApplicabilityIdRequest = (applicabilityId) => {
    return {
        type: DELETE_APPLICABILITY_BY_APPLICABILITY_ID_REQUEST,
        payload: { applicabilityId },
    };
};
export const deleteApplicabilityByApplicabilityIdResponse = (message, responseType) => {
    return {
        type: DELETE_APPLICABILITY_BY_APPLICABILITY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getApplicabilityListByStatusRequest = status => {
    return {
        type: GET_APPLICABILITY_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getApplicabilityListByStatusResponse = applicabilityList => {
    return {
        type: GET_APPLICABILITY_LIST_BY_STATUS_RESPONSE,
        payload: { applicabilityList },
    };
};
export const getSubApplicabilityListByApplicabilityRequest = applicabilityIds => {
    return {
        type: GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_REQUEST,
        payload: { applicabilityIds },
    };
};
export const getSubApplicabilityListByApplicabilityResponse = subApplicabilityList => {
    return {
        type: GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_RESPONSE,
        payload: { subApplicabilityList },
    };
};