export const PLAN_EVENT_REQUEST = 'plan_event_request'
export const PLAN_EVENT_RESPONSE = 'plan_event_response'

export const GET_ONGOING_EVENT_LIST_REQUEST = 'get_ongoing_event_list_request'
export const GET_ONGOING_EVENT_LIST_RESPONSE = 'get_ongoing_event_list_response'

export const UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST = 'update_plan_event_status_by_plan_event_id_request'
export const UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE = 'update_plan_event_status_by_plan_event_id_response'

export const GET_EXECUTED_EVENT_LIST_REQUEST = 'get_executed_event_list_request'
export const GET_EXECUTED_EVENT_LIST_RESPONSE = 'get_executed_event_list_response'

export const GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_REQUEST = 'get_plan_event_details_by_plan_event_id_request'
export const GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_RESPONSE = 'get_plan_event_details_by_plan_event_id_response'

export const CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST = 'cancel_plan_event_status_by_plan_event_id_request'
export const CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE = 'cancel_plan_event_status_by_plan_event_id_response'


export const UPDATE_USER_DETAILS_BY_TASK_ID_REQUEST =
    'update_user_details_by_task_id_request';
export const UPDATE_USER_DETAILS_BY_TASK_ID_RESPONSE =
    'update_user_details_by_task_id_response';