import React, { Component } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import {
    getScheduleListByAllocationIdRequest,
    updateViewScheduleStatusByAllocationTaskIdRequest,
    updateScheduleByAllocationIdRequest,
    updateTaskScheduleRenewalDateRequest,
} from 'store/actions';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import Switch from 'react-switch';
import { DATE_FORMAT_DATEPICKER, SUCCESS, DATE_FORMAT_PLACEHOLDER } from 'constants/commonConstants';
import { toastrMessage } from 'helpers/messageHelper';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    customDateValidator,
    formatDateForApi,
    getDateObjectWithValueFromMoment,
    getNextDayDate,
} from 'helpers/dateTimeHelper';
import {
    COMPLIANCE_CATEGORY_ONE_TIME,
    COMPLIANCE_TYPE_RENEWAL,
} from 'constants/databaseConstants';

export const scheduleColumns = [
    {
        label: 'Due Date',
        field: 'dueDate',
        sort: 'disabled',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },

];
class ViewScheduleModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            scheduleList: [],
            initialDocumentProcurementDataState: {
                documentProcurementDate: '',
            },
            complianceAllocation: {},
            minDateForSelection: ''
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }

    onStatusChange(checked, e, id) {
        this.props.updateViewScheduleStatusByAllocationTaskIdRequest(
            id,
            checked
        );
    }
    onDateChange(date, id) {
        this.props.updateScheduleByAllocationIdRequest(id, {
            dueDate: formatDateForApi(date),
        });
    }
    generateTable = (records) => {
        let scheduleRecords = [];
        scheduleRecords = records.map((scheduleData, i) => {

            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(scheduleData.id)}
                        checked={scheduleData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            const finalDate = scheduleData.rescheduleDate
                ? scheduleData.rescheduleDate
                : scheduleData.dueDate;
            let dueDate = (
                <div className="view_schedule_date">
                    <ReactDatePicker
                        key={i}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                        className="form-control"
                        onFocus={(e) => e.target.blur()}
                        minDate={getNextDayDate()}
                        selected={
                            finalDate
                                ? getDateObjectWithValueFromMoment(finalDate)
                                : null
                        }
                        value={
                            finalDate
                                ? getDateObjectWithValueFromMoment(finalDate)
                                : ''
                        }
                        dateFormat={DATE_FORMAT_DATEPICKER}
                        placeholderText={DATE_FORMAT_PLACEHOLDER}
                        onChange={(date) => {
                            this.onDateChange(date, scheduleData.id);
                        }}
                    />
                </div>
            );

            return {
                dueDate: dueDate,
                isActive: isActive,
            };
        });
        return scheduleRecords;
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allocationData?.id &&
            nextprops.allocationData?.id !== this.props.allocationData?.id
        ) {
            this.setState(
                {
                    allocationId: nextprops.allocationData?.id,
                },
                () => {
                    this.props.getScheduleListByAllocationIdRequest(
                        this.state.allocationId
                    );
                }
            );
        }
        if (nextprops.scheduleList !== this.props.scheduleList) {
            this.setState({
                scheduleList: this.generateTable(nextprops.scheduleList.list),
                complianceAllocation: nextprops.scheduleList?.complianceAllocation,
                minDateForSelection: nextprops.scheduleList?.minDateForDpd,
                formOpen: (nextprops.scheduleList?.complianceAllocation
                    ?.lawCompliance?.complianceCategoryId ==
                    COMPLIANCE_CATEGORY_ONE_TIME &&
                    nextprops.scheduleList?.complianceAllocation
                        ?.lawCompliance?.complianceTypeId ==
                    COMPLIANCE_TYPE_RENEWAL && nextprops.scheduleList?.complianceAllocation?.documentProcurementDate == '') ? true : false
            });
        }
        if (
            nextprops.scheduleApiResponse &&
            this.props.scheduleApiResponse !== nextprops.scheduleApiResponse
        ) {
            toastrMessage(
                nextprops.scheduleApiResponse.message,
                nextprops.scheduleApiResponse.responseType
            );
            if (nextprops.scheduleApiResponse.responseType === SUCCESS) {
                this.props.getScheduleListByAllocationIdRequest(
                    this.state.allocationId
                );
            }
        }
        if (
            nextprops.taskScheduleRenewalDateApiResponse &&
            this.props.taskScheduleRenewalDateApiResponse !==
            nextprops.taskScheduleRenewalDateApiResponse
        ) {
            toastrMessage(
                nextprops.taskScheduleRenewalDateApiResponse.message,
                nextprops.taskScheduleRenewalDateApiResponse.responseType
            );
            if (
                nextprops.taskScheduleRenewalDateApiResponse.responseType ===
                SUCCESS
            ) {
                this.props.getScheduleListByAllocationIdRequest(
                    this.state.allocationId
                );
                this.setState({
                    initialDocumentProcurementDataState: {
                        documentProcurementDate: '',
                    },
                });
            }
        }
    }

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = {
                allocationId: this.state.allocationId,
                documentProcurementDate: formatDateForApi(
                    values.documentProcurementDate
                ),
            };

            this.props.updateTaskScheduleRenewalDateRequest(formValues);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        const scheduleTableData = {
            columns: scheduleColumns,
            rows: this.state.scheduleList,
        };
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col className="mb-2 view_schedule_title">
                            <b>Compliance Title</b>
                            <p className="w-100 m-0">
                                {
                                    this.props.allocationData?.lawCompliance
                                        ?.complianceTitle
                                }
                            </p>
                        </Col>
                        <Col lg="4" className="mt-2">

                            {this.state.complianceAllocation?.lawCompliance?.complianceCategoryId == COMPLIANCE_CATEGORY_ONE_TIME && this.state.complianceAllocation?.lawCompliance?.complianceTypeId == COMPLIANCE_TYPE_RENEWAL && this.state.complianceAllocation?.documentProcurementDate != '' && (
                                <Label className='text-right mt-2 mb-0 w-100 font-weight-bold'>{__t(
                                    'form_labels.allocation.document_procurement_date'
                                )}: <span className='font-weight-normal'>{this.state.complianceAllocation?.documentProcurementDate}</span></Label>
                            )}

                        </Col>

                        <Col lg="12">
                            <CustomisedDatatable
                                responsive
                                striped
                                displayEntries={false}
                                entries={30}
                                searching={false}
                                tableRecords={scheduleTableData}
                            />
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    scheduleList: makeAllocationSelectField('scheduleList'),
    taskScheduleRenewalDateApiResponse: makeAllocationSelectField(
        'taskScheduleRenewalDateApiResponse'
    ),

    scheduleApiResponse: makeAllocationSelectField('scheduleApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getScheduleListByAllocationIdRequest,
    updateViewScheduleStatusByAllocationTaskIdRequest,
    updateScheduleByAllocationIdRequest,
    updateTaskScheduleRenewalDateRequest,
});
export default compose(withConnect, withRouter)(ViewScheduleModal);
