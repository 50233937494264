import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import {
    RESET,
    SUCCESS,
    UPDATE
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field, FieldArray } from 'formik';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { customValidTextField } from 'helpers/customValidators';
import __t from 'i18n/translator';
import { initialSubIndustriesDataState } from './CommonFunctions';
class AddIndustryForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField()
            )
        });
        this.state = {
            industryData: this.props.industryData,
            removeSubIndustries: []
        };
    }
    componentDidMount() {
        if (this.props.operationType === UPDATE) {
            if (this.props.industryData?.subIndustries?.length === 0) {
                this.props.industryData.subIndustries = initialSubIndustriesDataState()
            }
        }
    }
    deleteSubIndustry = async subIndustryId => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.sub_industry_delete')
        );
        if (response) {
            this.setState({
                removeSubIndustries: [...this.state.removeSubIndustries, subIndustryId]
            });
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
        if (nextprops.industryData !== this.props.industryData) {
            if (nextprops.operationType === UPDATE) {
                if (nextprops.industryData?.subIndustries?.length === 0) {
                    nextprops.industryData.subIndustries = initialSubIndustriesDataState()
                }
            }
            this.setState({
                industryData: nextprops.industryData
            })
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.props.onFormValid({ ...values, removeSubIndustries: this.state.removeSubIndustries });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign({}, this.state.industryData)}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Row>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        name="industryName"
                                        className="form-control"
                                        placeholder={__t('form_labels.masters.industry_name')}
                                        value={
                                            values.industryName
                                        }
                                        maxLength={255}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.industry_name')} <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.industry_name'),
                                        values.industryName,
                                        'required|max:255|onlyCharacters', {
                                        messages: {
                                            required: __t('validations.masters.industry_name_required'),
                                            max: __t('validations.masters.industry_name_max', { max: 255 })
                                        }
                                    }
                                    )}
                                </FormGroup>
                                <Col lg="12">
                                    <FieldArray
                                        name="subIndustries"
                                        render={arrayHelpers => (
                                            <div className='industry-sub-industry'>
                                                {values.subIndustries?.length > 0 &&
                                                    values.subIndustries.map(
                                                        (item, idx) => {
                                                            if (!this.state.removeSubIndustries?.includes(item.id)) {
                                                                return (
                                                                    <Row key={idx}>
                                                                        <FormGroup className='col'>
                                                                            <Field
                                                                                name={`subIndustries.${idx}.subIndustryName`}
                                                                                className="form-control"
                                                                                placeholder={__t('form_labels.masters.sub_industry_name')}
                                                                                value={
                                                                                    item.subIndustryName
                                                                                }
                                                                                maxLength={255}
                                                                            />
                                                                            <Label className="form-label">
                                                                                {__t('form_labels.masters.sub_industry_name')} <span className='text-danger required'>*</span>
                                                                            </Label>
                                                                            {this.validator.message(
                                                                                __t('form_labels.masters.sub_industry_name'),
                                                                                item.subIndustryName,
                                                                                'required|max:255|onlyCharacters', {
                                                                                messages: {
                                                                                    required: __t('validations.masters.sub_industry_name_required'),
                                                                                    max: __t('validations.masters.sub_industry_name_max', { max: 255 })
                                                                                }
                                                                            }
                                                                            )}
                                                                        </FormGroup>
                                                                        <Col lg="2">
                                                                            {values.subIndustries
                                                                                .length >
                                                                                1 ? (
                                                                                <Button
                                                                                    type="button"
                                                                                    className="btn btn-danger mr-3 mt-1"
                                                                                    onClick={() => {
                                                                                        if (item.id && item.id !== 0) {
                                                                                            this.deleteSubIndustry(item.id);
                                                                                        } else {
                                                                                            arrayHelpers.remove(
                                                                                                idx
                                                                                            );
                                                                                        }
                                                                                    }} // remove a row from the list
                                                                                >
                                                                                    <i className="fas fa-minus"></i>
                                                                                </Button>
                                                                            ) : null}
                                                                            {(values
                                                                                .subIndustries
                                                                                .length ===
                                                                                idx + 1) ? (
                                                                                <Button
                                                                                    type="button"
                                                                                    className='btn btn-primary mt-1'
                                                                                    onClick={() => {
                                                                                        arrayHelpers.insert(idx + 1, {
                                                                                            id: 0,
                                                                                            subIndustryName: '',
                                                                                        });
                                                                                        this.validator.purgeFields();
                                                                                        this.validator.hideMessages();

                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-plus"></i>
                                                                                </Button>
                                                                            ) : null}
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            } else {
                                                                arrayHelpers.remove(
                                                                    idx
                                                                );
                                                            }
                                                        }
                                                    )}
                                            </div>
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >{this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}>{RESET}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
export default AddIndustryForm;