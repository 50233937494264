import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Entity Name',
        field: 'entityName',
    },
    {
        label: 'From User',
        field: 'fromUser',
    },
    {
        label: 'To User',
        field: 'toUser',
    },
    {
        label: 'Type',
        field: 'type',
    },
    {
        label: 'From Date',
        field: 'fromDate',
    },
    {
        label: 'To Date',
        field: 'toDate',
    },

    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialDelegationDataState = () => {
    return {
        entityId: '',
        fromUserId: '',
        toUserId: '',
        type: '',
        fromDate: '',
        toDate: '',
        delegationStatus: 1,
    };
};
export const intFields = [
    'entityId',
    'fromUserId',
    'toUserId',
    'type',
    'delegationStatus',
];
export const nullableFields = ['fromDate', 'toDate'];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
