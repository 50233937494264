import produce from 'immer';
import {
    ADD_USER_RESPONSE,
    GET_ALL_USER_LIST_RESPONSE,
    GET_USER_BY_USER_ID_RESPONSE,
    GET_USER_LIST_BY_STATUS_RESPONSE,
    UPDATE_USER_RESPONSE,
    UPDATE_USER_STATUS_BY_USER_ID_RESPONSE,
    DELETE_USER_BY_USER_ID_RESPONSE,
    GET_USER_LIST_BY_ENTITY_ID_RESPONSE,
    GET_USER_PROFILE_RESPONSE,
    UPDATE_USER_PROFILE_RESPONSE,
    PASSWORD_RESEND_USER_BY_USER_ID_RESPONSE,
    SET_NEW_PASSWORD_OF_LOGIN_USER_RESPONSE,
    GET_USERS_BY_ENTITY_RESPONSE,
    GET_USERS_BY_COMPANYGROUP_RESPONSE,
    FILTER_USER_RESPONSE,
    UPDATE_APPLICATION_THEME_RESPONSE,
    CLEARE_FILTER_USER_RESPONSE,
    UNBLOCK_EMAIL_RESPONSE,
    GET_USER_ROLES_BY_USER_ID_RESPONSE
} from './actionTypes';
export const initialState = {
    userApiResponse: null,
    editUserApiResponse: null,
    deleteUserApiResponse: null,
    userProfileApiResponse: null,
    userStatusUpdateApiResponse: null,
    passwordResendUserApiResponse: null,
    unblockEmailApiResponse: null,
    themeApiResponse: null,
    userList: [],
    userRoleList: [],
};
const user = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_USER_RESPONSE:
            case UPDATE_USER_RESPONSE:
                draft.userApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_USER_LIST_RESPONSE:
                draft.userList = action.payload.userList;
                break;
            case GET_USER_BY_USER_ID_RESPONSE:
                draft.editUserApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case UPDATE_USER_STATUS_BY_USER_ID_RESPONSE:
                draft.userStatusUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_USER_LIST_BY_STATUS_RESPONSE:
                draft.userList = action.payload.userList;
                break;
            case DELETE_USER_BY_USER_ID_RESPONSE:
                draft.deleteUserApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_USER_LIST_BY_ENTITY_ID_RESPONSE:
                draft.userList = action.payload.userList;
                break;
            case GET_USER_PROFILE_RESPONSE:
            case SET_NEW_PASSWORD_OF_LOGIN_USER_RESPONSE:
                draft.userApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case UPDATE_USER_PROFILE_RESPONSE:
                draft.userProfileApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;
            case PASSWORD_RESEND_USER_BY_USER_ID_RESPONSE:
                draft.passwordResendUserApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_USERS_BY_ENTITY_RESPONSE:
                draft.userList = action.payload.userList;
                break;
            case GET_USERS_BY_COMPANYGROUP_RESPONSE:
                draft.userList = action.payload.userList;
                break;
            case FILTER_USER_RESPONSE:
                draft.userList = action.payload.userList;
                break;
            case UPDATE_APPLICATION_THEME_RESPONSE:
                draft.themeApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    data: action.payload.data,
                };
                break;

            case CLEARE_FILTER_USER_RESPONSE:
                draft.userList = null
                break;

            case UNBLOCK_EMAIL_RESPONSE:
                draft.unblockEmailApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_USER_ROLES_BY_USER_ID_RESPONSE:
                
                draft.userRoleList = action.payload.userRoleList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default user;
