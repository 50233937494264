import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    SUBMIT,
    UPDATE,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
} from 'store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import {
    customDateValidator,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
class AddEditLocationFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            locationList: this.props.locationList,
            locationData: this.props.locationData,
        };
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {

        this.props.getStateListByCountryRequest([countryIds]);
    }
    getCityListByState(stateIds) {

        this.props.getCityListByStateRequest([stateIds]);
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.locationList !== this.props.locationList) {
            this.setState({
                locationList: nextprops.locationList,
            });
        }
        if (nextprops.operationType !== this.props.operationType) {
            this.setState(
                {
                    locationData: nextprops.locationData,
                    operationType: nextprops.operationType,
                },
                () => {
                    if (this.state.operationType === UPDATE) {
                        this.getStateListByCountry(
                            this.state.locationData.countryId
                        );
                        this.getCityListByState(
                            this.state.locationData.stateId
                        );
                    }
                }
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={
                        this.props.operationType === SUBMIT
                            ? __t('form_labels.entity.add_location')
                            : __t('form_labels.entity.edit_location')
                    }
                    isModalOpen={this.props.isModalOpen}
                    width="150%"
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.locationData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Row>
                                            <FormGroup className="col-lg-4">
                                                <Field
                                                    component={Select}
                                                    name="countryId"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedCountryListDropDown
                                                    }
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'countryId',
                                                            opt.value
                                                        );
                                                        setFieldValue(
                                                            'countryName',
                                                            opt.label
                                                        );
                                                        this.getStateListByCountry(
                                                            opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedCountryListDropDown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.countryId
                                                    )}
                                                    isDisabled={
                                                        values.id &&
                                                        values.id !== 0
                                                    }
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.country'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.country'
                                                    ),
                                                    values.countryId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.entity.country_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-4">
                                                <Field
                                                    component={Select}
                                                    name="stateId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedStateListDropDown.filter(
                                                        (opt) => opt.value != -1
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            `stateId`,
                                                            opt.value
                                                        );
                                                        setFieldValue(
                                                            `stateName`,
                                                            opt.label
                                                        );
                                                        this.getCityListByState(
                                                            opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedStateListDropDown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.stateId
                                                    )}
                                                    isDisabled={
                                                        values.id &&
                                                        values.id !== 0
                                                    }
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.state'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.state'
                                                    ),
                                                    values.stateId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.entity.state_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-4">
                                                <Field
                                                    component={Select}
                                                    name="cityId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedCityListDropDown.filter(
                                                        (option) => {
                                                            if (
                                                                option.stateId ===
                                                                values.stateId &&
                                                                option.value !=
                                                                -1
                                                            ) {
                                                                let citiesArr =
                                                                    this.props.locationList?.map(
                                                                        ({
                                                                            cityId,
                                                                            ...rest
                                                                        }) =>
                                                                            cityId
                                                                    );
                                                                return !citiesArr?.includes(
                                                                    option.value
                                                                );
                                                            }
                                                        }
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'cityId',
                                                            opt.value
                                                        );
                                                        setFieldValue(
                                                            'cityName',
                                                            opt.label
                                                        );
                                                    }}
                                                    value={this.props.sortedCityListDropDown
                                                        .filter(
                                                            (x) =>
                                                                x.stateId ===
                                                                values.stateId
                                                        )
                                                        ?.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.cityId
                                                        )}
                                                    isDisabled={
                                                        values.id &&
                                                        values.id !== 0
                                                    }
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.city'
                                                    )}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.city'
                                                    ),
                                                    values.cityId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.entity.city_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>

                                            <FormGroup className="col-lg-4">
                                                <Field
                                                    name="registrationNumber"
                                                    className="form-control"
                                                    placeholder={__t(
                                                        'form_labels.entity.registration_number'
                                                    )}
                                                    maxLength={250}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.registration_number'
                                                    )}
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.registration_number'
                                                    ),
                                                    values.registrationNumber,
                                                    'alpha_num_dash|max:250',
                                                    {
                                                        messages: {
                                                            max: __t(
                                                                'validations.entity.registration_number_max',
                                                                { max: 250 }
                                                            ),
                                                            alpha_num_dash: __t(
                                                                'validations.entity.registration_number_alpha_num_dash'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-4 w-100 sticky_label">
                                                <Field
                                                    component={DatePicker}
                                                    isClearable={true}
                                                    className="form-control"
                                                    name="registrationDate"
                                                    selected={
                                                        values.registrationDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.registrationDate
                                                            )
                                                            : null
                                                    }
                                                    dateFormat={
                                                        DATE_FORMAT_DATEPICKER
                                                    }
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'registrationDate',
                                                            date
                                                        );
                                                    }}
                                                    showMonthDropdown={true}
                                                    showYearDropdown={true}
                                                    dropdownMode="select"
                                                    placeholderText={__t(
                                                        'form_labels.entity.registration_date'
                                                    )}
                                                    onFocus={(e) =>
                                                        e.target.blur()
                                                    }
                                                    maxDate={new Date()}
                                                ></Field>
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.registration_date'
                                                    )}
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.registration_date'
                                                    ),
                                                    values.registrationDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.registrationDate
                                                        )
                                                        : '',
                                                    'customDate',
                                                    {
                                                        messages: {
                                                            // required: __t('validations.entity.registration_date_required')
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {
                                                            this.props
                                                                .operationType
                                                        }
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={
                                                            this.props
                                                                .onModalDismiss
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withRouter
)(AddEditLocationFormModal);
