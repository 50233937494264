import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_LAW_REQUEST,
    DELETE_LAW_BY_LAW_ID_REQUEST,
    GET_ALL_ENTITY_TYPE_LIST_REQUEST,
    GET_ALL_FACILITY_TYPE_LIST_REQUEST,
    GET_ALL_LAW_LIST_REQUEST,
    GET_COUNTRY_COVERAGE_SEARCH_LIST_REQUEST,
    GET_LAWS_LIST_BY_LOGIN_USER_REQUEST,
    GET_LAWS_LIST_REQUEST,
    GET_LAWS_SEARCH_LIST_REQUEST,
    GET_LAW_BY_LAW_ID_REQUEST,
    GET_LAW_LIST_BY_STATUS_REQUEST,
    UPDATE_LAW_REQUEST,
    UPDATE_LAW_STATUS_BY_LAW_ID_REQUEST,
    UPDATE_LAW_VIA_UPDATE_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addLawResponse,
    deleteLawByLawIdResponse,
    getAllEntityTypeListResponse,
    getAllFacilityTypeListResponse,
    getAllLawListRequest,
    getAllLawListResponse,
    getCountryCoverageSearchListResponse,
    getLawByLawIdResponse,
    getLawListByStatusResponse,
    getLawListResponse,
    getLawsListByLoginUserResponse,
    getLawsSearchListResponse,
    updateLawResponse,
    updateLawStatusByLawIdResponse,
    updateLawViaUpdateResponse,
} from './actions';
import {
    ENTITY_TYPE,
    FACILITY_TYPE,
    LAW,
    LAWS_BY_LOGIN_USER,
    LAWS_COUNTRY_COVERAGE_SEARCH,
    LAWS_SEARCH,
    LAW_BY_STATUS,
    LAW_DETAILS,
    LAW_EDIT_VIA_UPDATE,
} from 'helpers/apiConstants';
function* addLaw({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW, data);
        if (response.status) {
            yield put(getAllLawListRequest());
            yield put(addLawResponse(response.message, SUCCESS));
        } else {
            yield put(addLawResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllLawList() {
    try {
        const response = yield call(invokeApi, 'get', LAW, '');
        if (response.status) {
            yield put(getAllLawListResponse(response.data));
        } else {
            yield put(getAllLawListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawByLawId({ payload: { lawId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            LAW_DETAILS + '/' + lawId,
            ''
        );
        if (response.status) {
            yield put(
                getLawByLawIdResponse(response.message, SUCCESS, response.data)
            );
        } else {
            yield put(getLawByLawIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLaw({ payload: { lawId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', LAW + '/' + lawId, data);
        if (response.status) {
            yield put(getAllLawListRequest());
            yield put(updateLawResponse(response.message, SUCCESS));
        } else {
            yield put(updateLawResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLawViaUpdate({ payload: { lawId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', LAW_EDIT_VIA_UPDATE + '/' + lawId, data);
        if (response.status) {
            yield put(updateLawViaUpdateResponse(response.message, SUCCESS));
        } else {
            yield put(updateLawViaUpdateResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLawStatusByLawId({ payload: { lawId, status } }) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            LAW + '/' + lawId + '/status/' + status
        );
        if (response.status) {
            yield put(getAllLawListRequest());
            yield put(
                updateLawStatusByLawIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(updateLawStatusByLawIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawListByStatus({ payload: { status } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            LAW_BY_STATUS + '/' + status
        );
        if (response.status) {
            yield put(getLawListByStatusResponse(response.data.list));
        } else {
            yield put(getLawListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteLawByLawId({ payload: { lawId } }) {
    try {
        const response = yield call(invokeApi, 'delete', LAW + '/' + lawId);
        if (response.status) {
            yield put(getAllLawListRequest());
            yield put(deleteLawByLawIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteLawByLawIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllEntityTypeList() {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_TYPE, '');
        if (response.status) {
            yield put(getAllEntityTypeListResponse(response.data.list));
        } else {
            yield put(getAllEntityTypeListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllFacilityTypeList() {
    try {
        const response = yield call(invokeApi, 'get', FACILITY_TYPE, '');
        if (response.status) {
            yield put(getAllFacilityTypeListResponse(response.data.list));
        } else {
            yield put(getAllFacilityTypeListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawsSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(invokeApi, 'post', LAWS_SEARCH, data);
        if (response.status) {
            if (pagination) {
                yield put(
                    getLawsSearchListResponse(
                        response.message,
                        SUCCESS,
                        response.data
                    )
                );
            } else {
                yield put(getLawListResponse(response.data.laws));
            }
        } else {
            if (pagination) {
                yield put(
                    getLawsSearchListResponse(
                        response.message,
                        SUCCESS,
                        response.data
                    )
                );
            } else {
                yield put(getLawListResponse([]));
            }
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getCountryCoverageSearchList({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            LAWS_COUNTRY_COVERAGE_SEARCH,
            data
        );
        if (response.status) {
            yield put(getCountryCoverageSearchListResponse(response.data.list));
        } else {
            yield put(getCountryCoverageSearchListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLawsListByLoginUser() {
    try {
        const response = yield call(invokeApi, 'get', LAWS_BY_LOGIN_USER);
        if (response.status) {
            yield put(getLawsListByLoginUserResponse(response.data.list));
        } else {
            yield put(getLawsListByLoginUserResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchLaw() {
    yield takeEvery(ADD_LAW_REQUEST, addLaw);
    yield takeEvery(GET_ALL_LAW_LIST_REQUEST, getAllLawList);
    yield takeEvery(GET_LAW_BY_LAW_ID_REQUEST, getLawByLawId);
    yield takeEvery(UPDATE_LAW_REQUEST, updateLaw);
    yield takeEvery(UPDATE_LAW_VIA_UPDATE_REQUEST, updateLawViaUpdate);
    yield takeEvery(
        UPDATE_LAW_STATUS_BY_LAW_ID_REQUEST,
        updateLawStatusByLawId
    );
    yield takeEvery(GET_LAW_LIST_BY_STATUS_REQUEST, getLawListByStatus);
    yield takeEvery(DELETE_LAW_BY_LAW_ID_REQUEST, deleteLawByLawId);
    yield takeEvery(GET_ALL_ENTITY_TYPE_LIST_REQUEST, getAllEntityTypeList);
    yield takeEvery(GET_ALL_FACILITY_TYPE_LIST_REQUEST, getAllFacilityTypeList);
    yield takeEvery(GET_LAWS_SEARCH_LIST_REQUEST, getLawsSearchList);
    yield takeEvery(GET_LAWS_LIST_REQUEST, getLawsSearchList);
    yield takeEvery(
        GET_COUNTRY_COVERAGE_SEARCH_LIST_REQUEST,
        getCountryCoverageSearchList
    );
    yield takeEvery(
        GET_LAWS_LIST_BY_LOGIN_USER_REQUEST,
        getLawsListByLoginUser
    );
}
function* lawSaga() {
    yield all([fork(watchLaw)]);
}
export default lawSaga;
