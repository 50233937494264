import React, { Component } from 'react';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CLEAR, SEARCH } from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedEntityList } from 'store/Entity/selector';

import {
    getEntityListByCompanyGroupIdRequest,
} from 'store/actions';

import { setEmptyToNull } from 'helpers/generalUtils';
import __t from 'i18n/translator';
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
export const intFields = ['companyGroupId', 'entityId'];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
class BlockEmailSearchForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            searchUsersFormData: this.props.searchUsersFormData,
        };
    }

    getEntityListByCompanyGroup(companyGroupId) {
        this.props.getEntityListByCompanyGroupIdRequest(companyGroupId);
    }

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.setState({
                searchUsersFormData: values,
            }, () => {
                this.props.onFormSubmit(values);
            }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onResetForm();
    };

    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.searchUsersFormData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Row className="filter-form">
                                <React.Fragment>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="companyGroupId"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedCompanyGroupListDropdown
                                            }
                                            onChange={(opt) => {
                                                setFieldValue(
                                                    'companyGroupId',
                                                    opt
                                                        ? opt.map(
                                                            (x) => x.value
                                                        )
                                                        : []
                                                );
                                                setFieldValue('entityId', []);
                                                this.getEntityListByCompanyGroup(
                                                    opt
                                                        ? opt.map(
                                                            (x) => x.value
                                                        )
                                                        : []
                                                );
                                            }}
                                            value={this.props.sortedCompanyGroupListDropdown?.filter(
                                                (option) =>
                                                    values?.companyGroupId.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti={true}
                                        />

                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.entity.company_group'
                                            )}{' '}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="entityId"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedEntityListDropdown
                                            }
                                            onChange={(opt) => {
                                                setFieldValue(
                                                    'entityId',
                                                    opt
                                                        ? opt.map(
                                                            (x) => x.value
                                                        )
                                                        : []
                                                );
                                            }}
                                            value={this.props.sortedEntityListDropdown?.filter(
                                                (option) =>
                                                    values?.entityId.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti={true}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.masters.entity')}
                                        </Label>
                                    </FormGroup>                                    
                                </React.Fragment>
                                <Col>
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-1"
                                            >
                                                {SEARCH}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {CLEAR}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedEntityListDropdown: selectSortedEntityList(),
});
const withConnect = connect(mapStatetoProps, {
    getEntityListByCompanyGroupIdRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(BlockEmailSearchForm);
