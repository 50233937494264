import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { ACTIVATE_OR_DEACTIVATE_NEWS, NEWS, NEWS_LIST, NEWS_WITH_SEARCH, PUBLISH_NEWS, RELEASE_TYPE } from 'helpers/apiConstants';
import { activateOrDeactivateNewsResponse, createNewsResponse, getAllReleaseTypeListResponse, getNewsDetailsByIdResponse, getNewsListRequest, getNewsListResponse, getNewsWithSearchResponse, publishNewsByNewsIdResponse, updateNewsResponse } from './actions';
import { ACTIVATE_OR_DEACTIVATE_NEWS_REQUEST, CREATE_NEWS_REQUEST, GET_ALL_RELEASE_TYPE_LIST_REQUEST, GET_NEWS_DETAILS_BY_ID_REQUEST, GET_NEWS_LIST_REQUEST, GET_NEWS_WITH_SEARCH_REQUEST, PUBLISH_NEWS_BY_NEWS_ID_REQUEST, UPDATE_NEWS_REQUEST } from './actionTypes';

function* getNewsList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', NEWS_LIST, data);
        if (response.status) {
            yield put(getNewsListResponse(response.data.list));
        } else {
            yield put(getNewsListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* activateOrDeactivateNews({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            ACTIVATE_OR_DEACTIVATE_NEWS + '/' + data.id,
            { isActive: data.isActive }
        );
        if (response.status) {
            yield put(getNewsListRequest());
            yield put(
                activateOrDeactivateNewsResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                activateOrDeactivateNewsResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getNewsDetailsById({ payload: { newsId } }) {
    try {
        const response = yield call(invokeApi, 'get', NEWS + '/' + newsId, ''
        );
        if (response.status) {
            yield put(getNewsDetailsByIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getNewsDetailsByIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* createNews({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', NEWS, data);
        if (response.status) {
            yield put(createNewsResponse(response.message, SUCCESS));
        } else {
            yield put(createNewsResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateNews({ payload: { newsId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', NEWS + '/' + newsId, data);

        if (response.status) {
            yield put(updateNewsResponse(response.message, SUCCESS));
        } else {
            yield put(updateNewsResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* publishNewsByNewsId({ payload: { newsId } }) {
    try {
        const response = yield call(invokeApi, 'post', PUBLISH_NEWS + '/' + newsId);
        if (response.status) {
            yield put(getNewsListRequest());
            yield put(publishNewsByNewsIdResponse(response.message, SUCCESS));
        } else {
            yield put(publishNewsByNewsIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getNewsWithSearch({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', NEWS_WITH_SEARCH, data);
        if (response.status) {
            yield put(
                getNewsWithSearchResponse(response.message, SUCCESS, response.data)
            );
        } else {
            yield put(getNewsWithSearchResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllReleaseTypeList() {
    try {
        const response = yield call(invokeApi, 'get', RELEASE_TYPE);
        if (response.status) {
            yield put(getAllReleaseTypeListResponse(response.data.releaseTypeList));
        } else {
            yield put(getAllReleaseTypeListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchNews() {
    yield takeEvery(GET_NEWS_LIST_REQUEST, getNewsList);
    yield takeEvery(ACTIVATE_OR_DEACTIVATE_NEWS_REQUEST, activateOrDeactivateNews);
    yield takeEvery(CREATE_NEWS_REQUEST, createNews);
    yield takeEvery(UPDATE_NEWS_REQUEST, updateNews);
    yield takeEvery(GET_NEWS_DETAILS_BY_ID_REQUEST, getNewsDetailsById);
    yield takeEvery(PUBLISH_NEWS_BY_NEWS_ID_REQUEST, publishNewsByNewsId);
    yield takeEvery(GET_NEWS_WITH_SEARCH_REQUEST, getNewsWithSearch);
    yield takeEvery(GET_ALL_RELEASE_TYPE_LIST_REQUEST, getAllReleaseTypeList);
}
function* newsSaga() {
    yield all([fork(watchNews)]);
}
export default newsSaga;