import { ADD_ENTITY_ROLE_REQUEST, ADD_ENTITY_ROLE_RESPONSE, DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST, DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE, GET_ALL_ENTITY_ROLE_LIST_REQUEST, GET_ALL_ENTITY_ROLE_LIST_RESPONSE, GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST, GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE, GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_REQUEST, GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_RESPONSE, GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_REQUEST, GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_RESPONSE, UPDATE_ENTITY_ROLE_REQUEST, UPDATE_ENTITY_ROLE_RESPONSE, UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_REQUEST, UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_RESPONSE } from "./actionTypes";
export const addEntityRoleRequest = data => {
    return {
        type: ADD_ENTITY_ROLE_REQUEST,
        payload: { data },
    };
};
export const addEntityRoleResponse = (message, responseType) => {
    return {
        type: ADD_ENTITY_ROLE_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllEntityRoleListRequest = () => {
    return {
        type: GET_ALL_ENTITY_ROLE_LIST_REQUEST
    };
};
export const getAllEntityRoleListResponse = entityRoleList => {
    return {
        type: GET_ALL_ENTITY_ROLE_LIST_RESPONSE,
        payload: { entityRoleList },
    };
};
export const getEntityRoleByEntityRoleIdRequest = (entityRoleId) => {
    return {
        type: GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST,
        payload: { entityRoleId: entityRoleId },
    };
};
export const getEntityRoleByEntityRoleIdResponse = (message, responseType, data) => {
    return {
        type: GET_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateEntityRoleRequest = (entityRoleId, data) => {
    return {
        type: UPDATE_ENTITY_ROLE_REQUEST,
        payload: { entityRoleId, data },
    };
};
export const updateEntityRoleResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_ROLE_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateEntityRoleStatusByEntityRoleIdRequest = (entityRoleId, status) => {
    return {
        type: UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_REQUEST,
        payload: { entityRoleId, status },
    };
};
export const updateEntityRoleStatusByEntityRoleIdResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_ROLE_STATUS_BY_ENTITY_ROLE_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const deleteEntityRoleByEntityRoleIdRequest = (entityRoleId) => {
    return {
        type: DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_REQUEST,
        payload: { entityRoleId },
    };
};
export const deleteEntityRoleByEntityRoleIdResponse = (message, responseType) => {
    return {
        type: DELETE_ENTITY_ROLE_BY_ENTITY_ROLE_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getEntityRoleListByEntityIdRequest = (entityId) => {
    return {
        type: GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_REQUEST,
        payload: { entityId }
    };
};
export const getEntityRoleListByEntityIdResponse = entityRoleList => {
    return {
        type: GET_ENTITY_ROLE_LIST_BY_ENTITY_ID_RESPONSE,
        payload: { entityRoleList },
    };
};

export const getRolesWithEntityRoleByEntityListRequest = (entityId) => {
    return {
        type: GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_REQUEST,
        payload: { entityId }
    };
};
export const getRolesWithEntityRoleByEntityListResponse = roleList => {
    return {
        type: GET_ROLES_WITH_ENTITY_ROLE_BY_ENTITY_LIST_RESPONSE,
        payload: { roleList },
    };
};
