export const listColumns = [
    {
        label: 'Company Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Entity Name',
        field: 'entityName',
    },
    {
        label: 'File Name',
        field: 'fileName',
    },
    {
        label: 'From Period',
        field: 'fromDate',
    },
    {
        label: 'To Period',
        field: 'toDate',
    },
    {
        label: 'Uploaded At',
        field: 'uploadedAt',
    },
    {
        label: 'Uploaded By',
        field: 'uploadedBy',
    },
];
export const initialMultipleDocumentDataState = () => {
    return {
        formData: { files: null },
        companyGroupId: '',
        archiveDepartmentId: '',
        entityId: '',
        fromDate: '',
        toDate: '',
    };
};
