import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    CANCEL,
    HOD,
    REVIEWER,
    SUBMITTER,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getDepartmentListByStatusRequest,
    getUserListByStatusRequest,
    getEntityTeamsByEntityIdRequest,
    getEntityRoleListByEntityIdRequest,
    getEntityDepartByEntityRequest,
    cleareFilterUserResponse
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import entityRoleReducer from 'store/Masters/EntityRole/reducer';
import entityRoleSaga from 'store/Masters/EntityRole/saga';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import { setEmptyToNull } from 'helpers/generalUtils';
import { selectSortedUserList } from 'store/User/selector';
import { selectSortedEntityTeamAndConsultantTeamUserList } from 'store/Entity/selector';
import { makeEntityRoleSelectField } from 'store/Masters/EntityRole/selector';
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const entityRoleKey = 'entityRole';
const withEntityRoleReducer = injectReducer({
    key: entityRoleKey,
    reducer: entityRoleReducer,
});
const withEntityRoleSaga = injectSaga({
    key: entityRoleKey,
    saga: entityRoleSaga,
});

export const initialUserAllocationFormDataState = () => {
    return {
        entityId: '',
        allocationId: '',
        departmentId: [],
        submitterId: '',
        firstApproverUserId: '',
        secondApproverUserId: '',
        sortedDepartmentListDropdown: [],
    };
};
export const intFields = ['allocationId', 'submitterId', 'firstApproverUserId'];
export const nullableFields = ['secondApproverUserId'];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
class UserMappingFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            submitterLabel: '',
            approverLabel: '',
            hodLabel: '',
            userMappingFormData: initialUserAllocationFormDataState(),
        };
    }
    componentDidMount() {
        this.props.getUserListByStatusRequest(ACTIVE);
    }
    componentWillUnmount() {
        this.props.cleareFilterUserResponse();
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.userAllocationData !== this.props.userAllocationData) {
            this.validator.hideMessages();
            this.setState(
                {
                    userMappingFormData: nextprops.userAllocationData,
                },
                () => {
                    if (this.state.userMappingFormData.entityId !== undefined) {
                        this.props.getEntityDepartByEntityRequest(
                            this.state.userMappingFormData.entityId
                        );
                        this.props.getEntityRoleListByEntityIdRequest(
                            this.state.userMappingFormData.entityId
                        );
                        this.props.getEntityTeamsByEntityIdRequest(
                            this.state.userMappingFormData.entityId
                        );
                    }
                }
            );
        }
        if (
            nextprops.entityRoleList &&
            nextprops.entityRoleList !== this.props.entityRoleList
        ) {
            this.setState(
                {
                    submitterLabel:
                        nextprops.entityRoleList.length > 0
                            ? nextprops.entityRoleList.find(
                                (opt) => opt.roleId === SUBMITTER
                            )?.roleTerm
                            : '',
                    approverLabel:
                        nextprops.entityRoleList.length > 0
                            ? nextprops.entityRoleList.find(
                                (opt) => opt.roleId === REVIEWER
                            )?.roleTerm
                            : '',
                    hodLabel:
                        nextprops.entityRoleList.length > 0
                            ? nextprops.entityRoleList.find(
                                (opt) => opt.roleId === HOD
                            )?.roleTerm
                            : '',
                },
                () => {
                    this.validator.purgeFields();
                }
            );
        }
    }
    render() {
        const submitterFormLabel = this.state.submitterLabel
            ? this.state.submitterLabel
            : __t('form_labels.allocation.submitter');
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.userMappingFormData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>

                                        <Row className="d-flex mr-0">
                                            <FormGroup className="col-12">
                                                <Field
                                                    component={Select}
                                                    name="departmentId"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedDepartmentListDropdown
                                                    }
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            `departmentId`,
                                                            opt &&
                                                            opt.map(
                                                                (x) =>
                                                                    x.value
                                                            )
                                                        );
                                                    }}
                                                    value={this.props.sortedDepartmentListDropdown.filter(
                                                        (option) =>
                                                            values.departmentId?.includes(
                                                                option.value
                                                            )
                                                    )}
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.allocation.department'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.allocation.department'
                                                    ),
                                                    values.departmentId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.allocation.department_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>

                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    component={Select}
                                                    name="submitterId"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.props
                                                            .sortedEntityTeamAndConsultantTeamUserDropdown.filter((opt) =>
                                                            (opt.value !==
                                                                values.firstApproverUserId && opt.value !==
                                                                values.secondApproverUserId))
                                                    }
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'submitterId',
                                                            opt && opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.submitterId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {submitterFormLabel}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    submitterFormLabel,
                                                    values.submitterId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required:
                                                                submitterFormLabel +
                                                                __t(
                                                                    'validations.common.required'
                                                                ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <React.Fragment>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component={Select}
                                                        name="firstApproverUserId"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (opt) =>
                                                            (opt.value !==
                                                                values.submitterId && opt.value !==
                                                                values.secondApproverUserId)
                                                        )}
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'firstApproverUserId',
                                                                opt &&
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.firstApproverUserId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.allocation.first_approver'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.allocation.first_approver'
                                                        ),
                                                        values.firstApproverUserId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required:
                                                                    __t(
                                                                        'form_labels.allocation.first_approver'
                                                                    ) +
                                                                    __t(
                                                                        'validations.common.required'
                                                                    ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component={Select}
                                                        name="secondApproverUserId"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (opt) =>
                                                                opt.value !==
                                                                values.submitterId &&
                                                                opt.value !==
                                                                values.firstApproverUserId
                                                        )}
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'secondApproverUserId',
                                                                opt &&
                                                                opt.value
                                                            );
                                                        }}
                                                        value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.secondApproverUserId
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.allocation.second_approver'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                            </React.Fragment>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {
                                                            this.props
                                                                .operationType
                                                        }
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={
                                                            this.props
                                                                .onModalDismiss
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    sortedUserListDropdown: selectSortedUserList(),

    sortedEntityTeamAndConsultantTeamUserDropdown:
        selectSortedEntityTeamAndConsultantTeamUserList(),
    entityRoleList: makeEntityRoleSelectField('entityRoleList'),
});
const withConnect = connect(mapStatetoProps, {
    getDepartmentListByStatusRequest,
    getUserListByStatusRequest,
    getEntityTeamsByEntityIdRequest,
    getEntityRoleListByEntityIdRequest,
    getEntityDepartByEntityRequest,
    cleareFilterUserResponse
});
export default compose(
    withConnect,
    withDepartmentReducer,
    withDepartmentSaga,
    withUserReducer,
    withUserSaga,
    withEntityReducer,
    withEntitySaga,
    withEntityRoleReducer,
    withEntityRoleSaga,
    withRouter
)(UserMappingFormModal);
