import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import {
    getAssignedTaskSearchListRequest,
    getTaskStatusListRequest,
    getTaskUserDetailsByTaskIdRequest,
    getRiskRatingsRequest,
    getComplianceTypesRequest,
    getComplianceCategoriesRequest,
    clearRiskRatingResponse,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
    sendForAuditTaskByTaskIdsRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
    initialTrackComplianceStatusFilterDataState,
    listColumns,
    listColumnsWithCheckbox
} from './CommonFunctions';
import __t from 'i18n/translator';
import {
    makeTaskSelectField,
    selectSortedTaskStatusList,
} from 'store/Task/selector';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import TrackComplianceStatusFilterForm from './TrackComplianceStatusFilterForm';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
    DISCREPANCY_COMPLETED_RESOLUTION,
    DISCREPANCY_ONGOING_RESOLUTION,
    DISCREPANCY_PENDING_RESOLUTION,
    PENDING_FOR_AUDIT,
    PENDING_FOR_COMPLETION,
    PENDING_WITH_FIRST_APPROVER,
    PENDING_WITH_SECOND_APPROVER,
    REOPENED,
    SEND_BACK_BY_AUDITOR_TO_APPROVER,
    SEND_BACK_TO_APPROVER,
    SEND_BACK_TO_SUBMITTER,
} from 'constants/databaseConstants';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { compareIntValue, compareString, redirectUrl } from 'helpers/generalUtils';
import { customStatusList, roleList } from 'constants/dropdowns';
import Select from 'react-select';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import {
    Option,
    MultiValue,
    animatedComponents,
    isManagementRole,
    actionPendingIcon,
    getTaskStatusById,
    getAuditStatusById,
} from 'helpers/projectUtils';
import ReactTooltip from 'react-tooltip';
import ViewTaskUser from './ViewTaskUser';
import { PENDING_TO_TRIGGERED, SUCCESS } from 'constants/commonConstants';
import { formatDateForApiSystem, getDateFrom } from 'helpers/dateTimeHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
import DelayReporting from './DelayReporting';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            activePage: 1,
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            taskList: [],
            userDepartments: [],
            userDepartmentEntities: [],
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            isViewDelayReportingModalOpen: false,
            viewDelayReportingData: {},
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
            viewTaskUserTaskId: '',
            riskRatingsList: [],
            masterCityData: [],
            fromDashboard: {
                filterType: this.props.match.params.filterType
                    ? this.props.match.params.filterType
                    : '',
                selectedId: this.props.match.params.selectedId
                    ? this.props.match.params.selectedId
                    : '',
            },
            searchFormData: initialTrackComplianceStatusFilterDataState(),
            compliancesTypesList: [],
            compliancesCategoriesList: [],
            fetchPreData: false,
        };
    }
    componentDidMount() {
        this.props.getComplianceCategoriesRequest();
        this.props.getComplianceTypesRequest();
        this.props.getTaskStatusListRequest();
        this.props.getRiskRatingsRequest();
        if (this.state.fromDashboard.selectedId != '') {
            let dataFiltered = {};
            if (this.state.fromDashboard.filterType == 'entity') {
                dataFiltered = {
                    entityId: [Number(this.state.fromDashboard.selectedId)],
                };
            }
            if (this.state.fromDashboard.filterType == 'classification') {
                dataFiltered = {
                    complianceCategoryId: [
                        Number(this.state.fromDashboard.selectedId),
                    ],
                };
            }
            let searchFormData = this.state.searchFormData;
            this.setState(
                {
                    searchFormData: {
                        ...searchFormData,
                        ...dataFiltered,
                    },
                },
                () => {
                    this.getTaskSearchList(
                        this.state.sortBy,
                        this.state.searchFormData
                    );
                }
            );
        } else {
            this.getTaskSearchList(this.state.sortBy, {});
        }
    }
    componentWillUnmount() {
        this.props.clearRiskRatingResponse();
        this.props.clearComplianceTypeResponse();
        this.props.clearComplianceCategoryResponse();
    }
    getTaskSearchList = (sortBy, values = {}) => {
        let apiData = {
            limit: -1,
            page: -1,
            filter: { ...values, isManagementUser: isManagementRole() },
            orderBy: sortBy,
        };
        this.props.getAssignedTaskSearchListRequest(apiData, true);
    };
    formSubmit = (values, dateModify = false) => {
        this.setState(
            {
                taskList: [],
                searchFormData: {
                    ...values,
                    fromDate:
                        values.fromDate && dateModify
                            ? formatDateForApiSystem(values.fromDate)
                            : '',
                    toDate:
                        values.toDate && dateModify
                            ? formatDateForApiSystem(values.toDate)
                            : '',
                },
            },
            () => {

                this.getTaskSearchList(
                    this.state.sortBy,
                    this.state.searchFormData
                );
            }
        );
    };
    resetForm = () => {
        this.setState({}, () => {
            this.getTaskSearchList({});
        });
    };

    openViewTaskUserModal = (taskId) => {
        this.setState(
            {
                viewTaskUserTaskId: taskId,
            },
            () => {
                this.props.getTaskUserDetailsByTaskIdRequest(taskId);
            }
        );
    };
    closeViewTaskUserModal() {
        this.setState({
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
        });
    }
    closeViewDelayReportingModal() {
        this.setState({
            isViewDelayReportingModalOpen: false,
            viewTaskUserData: {},
        });
    }
    getRoleName = (taskData) => {
        let roles = [];
        const departmentIds =
            taskData.complianceAllocationDepartmentId != '' &&
                taskData.complianceAllocationDepartmentId != null
                ? taskData.complianceAllocationDepartmentId.split(',')
                : [];
        const collaboratorUserIds =
            taskData.collaboratorUserId != '' &&
                taskData.collaboratorUserId != null
                ? taskData.collaboratorUserId.split(',').map(Number)
                : [];
        if (taskData.submitterId == this.state.loginUserId) {
            roles.push('Submitter');
        } else if (taskData.firstApproverUserId == this.state.loginUserId) {
            roles.push('Reviewer');
        } else if (taskData.secondApproverUserId == this.state.loginUserId) {
            roles.push('Approver');
        } else if (taskData.auditorId == this.state.loginUserId) {
            roles.push('Auditor');
        } else if (
            departmentIds.some((department) => {
                let departmentHod = this.state.userDepartments?.find(
                    (departmentEntity) =>
                        departmentEntity.entityId ==
                        taskData.complianceAllocationEntityId &&
                        departmentEntity.departmentId == department
                );
                if (departmentHod) {
                    return true;
                }
            })
        ) {
            roles.push('HOD');
        } else if (
            taskData.collaboratorUserId != '' &&
            collaboratorUserIds.includes(this.state.loginUserId)
        ) {
            roles.push('Collaborator');
        } else if (taskData.createdBy == this.state.loginUserId) {
            roles.push('Task Creator');
        }
        return roles;
    };
    getCountryName = (cityId) => {
        const cityData = this.state.masterCityData.find((x) => x.id === cityId);
        const cityName = cityData ? cityData.cityName : '';
        return cityName;
    };
    getDelayLabel = (taskData) => {
        let delayLabel = '';
        if (taskData.compliancePerformanceDate > taskData.dueDate) {
            delayLabel += 'Delay Reporting';
        }
        if (taskData.systemReportingDate > taskData.dueDate) {
            delayLabel += '\nDelay System Reporting';
        }
        return delayLabel;
    }

    generateTable = (records) => {
        let taskRecords = [];
        let enableForHOD = false;
        taskRecords = records.map((taskData, i) => {
            let taskStatusInfo = getTaskStatusById(taskData.taskStatusId, taskData.taskStatusName);
            let auditStatusInfo = getAuditStatusById(taskData.taskStatusId, taskData.taskStatusName);
            let roleNames = this.getRoleName(taskData);
            if (roleNames.includes('HOD')) {
                enableForHOD = true;
            }
            let type = 'report';
            if (
                [PENDING_FOR_AUDIT, AUDITED_AND_CLOSED].includes(
                    taskData.taskStatusId
                )
            ) {
                type = 'audit';
            }
            if (
                [
                    PENDING_WITH_FIRST_APPROVER,
                    PENDING_WITH_SECOND_APPROVER,
                    SEND_BACK_TO_APPROVER,
                    SEND_BACK_BY_AUDITOR_TO_APPROVER,
                ].includes(taskData.taskStatusId)
            ) {
                type = 'review';
            }
            let iconName = actionPendingIcon(this.state.loginUserId, taskData.taskStatusId, taskData.submitterId, taskData.firstApproverUserId, taskData.secondApproverUserId, taskData.auditorId);
            let actions = (
                <div className="tbl_action">
                    {(iconName === 'view') && (
                        <a
                            href={() => false}
                            title={__t('common.view')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => {
                                redirectUrl(
                                    this.props.history,
                                    '/compliances/' + type + '/' + taskData.id
                                );
                            }}
                        >
                            <i className="fas fa-eye"></i>
                        </a>
                    )}
                    {iconName === 'action' && (
                        <a
                            href={() => false}
                            title={__t('tables.tasks.reporting')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                redirectUrl(
                                    this.props.history,
                                    '/compliances/' +
                                    type +
                                    '/' +
                                    taskData.id
                                );
                            }}
                        >
                            <i className="fas fa-file-import"></i>
                        </a>
                    )}

                    {
                        (taskData?.dueDate ? taskData.dueDate : false)
                        && (taskData?.systemReportingDate ? taskData.systemReportingDate : false)
                        && (taskData?.compliancePerformanceDate ? taskData.compliancePerformanceDate : false)
                        && ((taskData?.systemReportingDate && taskData?.compliancePerformanceDate) && (taskData.compliancePerformanceDate > taskData.dueDate || taskData.systemReportingDate > taskData.dueDate))
                        && (
                            <a
                                href={() => false}
                                title={this.getDelayLabel(taskData)}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.setState({
                                        isViewDelayReportingModalOpen: true,
                                        viewDelayReportingData: taskData,
                                    });
                                }}
                                style={{ lineHeight: '1' }}
                            >
                                <svg width="19" height="18" style={{ width: '1rem', height: '1rem' }} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
                                    <path d="M8 4.5L8 8.1875C8 8.32557 7.88807 8.4375 7.75 8.4375L4.9375 8.4375" stroke="currentColor" strokeLinecap="round" />
                                    <path d="M13.5 17.5C15.975 17.5 18 15.475 18 13C18 10.525 15.975 8.5 13.5 8.5C11.025 8.5 9 10.525 9 13C9 15.475 11.025 17.5 13.5 17.5Z" fill="currentColor" opacity="0.8" stroke="currentColor" stroke-width="0.9" strokeLinecap="round" stroke-linejoin="round" />
                                    <path d="M13.5 11.2V13.45" stroke="#ffffff" stroke-width="1" strokeLinecap="round" stroke-linejoin="round" />
                                    <path d="M13.4974 14.8H13.5014" stroke="#ffffff" strokeLinecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                        )}
                </div>
            );
            let taskCodeVal = (
                <>
                    <span searchvalue={taskData.taskCode} className="code_td">
                        {taskData.taskCode}
                    </span>
                </>
            );
            let infoVal = (
                <div
                    className="status_info"
                    searchvalue={taskStatusInfo}
                >
                    <span>{taskStatusInfo}</span>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.openViewTaskUserModal(taskData.id);
                        }}
                        className="text-primary"
                    >
                        <i className="fas fa-info-circle"></i>
                    </a>
                </div>
            );
            let auditStatusVal = (
                <div
                    className="audit-status"
                    searchvalue={auditStatusInfo}
                >
                    <span>{auditStatusInfo}</span>
                    {(taskData.taskStatusId === DISCREPANCY_PENDING_RESOLUTION ||
                        taskData.taskStatusId === DISCREPANCY_ONGOING_RESOLUTION ||
                        taskData.taskStatusId === DISCREPANCY_COMPLETED_RESOLUTION
                    ) && (
                            <a
                                href={() => false}
                                className="text-danger"
                            >
                                <i className="fas fa-flag"></i>
                            </a>
                        )}
                </div>
            );
            let complianceAction = (
                <a
                    href={() => false}
                    searchvalue={
                        taskData?.complianceAllocationLawComplianceCode
                    }
                    title="View Details"
                    className="tbl-link"
                    onClick={() =>
                        window.open(
                            '/statutory-compliances/view-details/' +
                            taskData?.complianceAllocationLawComplianceId,
                            '_blank'
                        )
                    }
                >
                    {taskData?.complianceAllocationLawComplianceCode}{' '}
                </a>
            );
            let complianceTypeId = (taskData.complianceVersionLawComplianceTypeId == null) ? taskData.complianceAllocationLawComplianceTypeId : taskData.complianceVersionLawComplianceTypeId;
            let complianceTypeName = this.state.compliancesTypesList.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = (taskData.complianceVersionLawComplianceCategoryId == null) ? taskData.complianceAllocationLawComplianceCategoryId : taskData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.compliancesCategoriesList.find((c) => c.value === complianceCategoryId)?.label || '';

            let complianceRiskRatingId = taskData.complianceVersionLawComplianceRiskRatingId == null
                ? taskData.complianceAllocationLawComplianceRiskRatingId
                : taskData.complianceVersionLawComplianceRiskRatingId;
            let complianceRiskRatingName = this.state.riskRatingsList.find((c) => c.value === complianceRiskRatingId)?.label || '';


            return {
                checkbox: taskData.taskStatusId === CLOSED && enableForHOD ? true : false,
                id: taskData.id,
                taskCode: taskCodeVal,
                companyGroupName:
                    taskData?.ComplianceAllocationEntityCompanyGroupName,
                entityId: taskData.complianceAllocationEntityId,
                entityName: taskData.complianceAllocationEntityName,
                complianceCode: complianceAction,
                countryName:
                    taskData.complianceAllocationEntityLocationCountryName,
                stateName: taskData.complianceAllocationEntityLocationStateName,
                cityName: this.getCountryName(taskData.complianceAllocationEntityLocationCityId),
                complianceTitle:
                    taskData.parentTaskId === null
                        ? taskData.complianceVersionLawComplianceTitle == null
                            ? taskData.complianceAllocationLawComplianceTitle
                            : taskData.complianceVersionLawComplianceTitle
                        : taskData.title,
                complianceClass: complianceCategoryName,
                complianceType: complianceTypeName,
                dueDate: taskData.dueDate ? (
                    <Label searchvalue={taskData.dueDate}>
                        {getDateFrom(taskData.dueDate)}
                    </Label>
                ) : (
                    <Label searchvalue={''}>{PENDING_TO_TRIGGERED}</Label>
                ),
                riskLevel: complianceRiskRatingName,
                taskStatusId: infoVal,
                auditStatus: auditStatusVal,
                parentTaskId:
                    taskData.parentTaskId === null
                        ? 'Statutory'
                        : 'Non-Statutory',
                role: roleNames.length > 0 ? roleNames.join('<br/>') : '-',
                actions: actions,
            };
        });
        return { taskRecords, enableForHOD };
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList
            && this.state.taskList.length === 0
        ) {

            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !== nextprops.allCompliancesTypesList
            && this.state.taskList.length === 0
        ) {
            this.setState({
                compliancesTypesList: nextprops.allCompliancesTypesList,
            });
        }

        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList &&
            this.state.taskList.length === 0
        ) {
            this.setState({
                compliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }

        if (nextprops.allCompliancesTypesList && nextprops.allCompliancesCategoriesList &&
            nextprops.riskRatingsList &&
            nextprops.assignedTaskSearchApiResponse &&
            this.state.taskList.length === 0
        ) {
            this.setState(
                {
                    userDepartments:
                        nextprops.assignedTaskSearchApiResponse.data
                            .userDepartments,
                    taskList: [],
                    masterCityData:
                        nextprops.assignedTaskSearchApiResponse.data
                            .countryStateCityData.masterCityData,
                },
                () => {
                    let { taskRecords, enableForHOD } = this.generateTable(nextprops.assignedTaskSearchApiResponse.data.tasks);
                    this.setState({
                        isViewTaskUserModalOpen: false,
                        viewTaskUserData: {},
                        taskList: taskRecords,
                        enableForHOD: enableForHOD
                    });
                }
            );
        }

        if (
            nextprops.taskUserDetailsApiResponse &&
            nextprops.taskUserDetailsApiResponse !==
            this.props.taskUserDetailsApiResponse
        ) {
            if (nextprops.taskUserDetailsApiResponse.responseType === SUCCESS) {
                if (
                    nextprops.taskUserDetailsApiResponse.data?.taskUserData
                        ?.taskId == this.state.viewTaskUserTaskId
                ) {
                    this.setState({
                        isViewTaskUserModalOpen: true,
                        viewTaskUserData:
                            nextprops.taskUserDetailsApiResponse.data
                                ?.taskUserData,
                    });
                }
            }
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: newPage?.page ? newPage?.page : 1,
        });
    };

    render() {
        let sortedStatusDropdown = this.props.sortedTaskStatusListDropdown;
        sortedStatusDropdown = [...sortedStatusDropdown, ...customStatusList];

        let taskStatusDropdownArray = sortedStatusDropdown.filter((s) => {
            if (s.value !== PENDING_FOR_AUDIT &&
                s.value !== AUDITED_AND_CLOSED &&
                s.value !== DISCREPANCY_PENDING_RESOLUTION &&
                s.value !== DISCREPANCY_ONGOING_RESOLUTION &&
                s.value !== DISCREPANCY_COMPLETED_RESOLUTION) {
                return true;
            }
        });
        let auditStatusDropdownArray = sortedStatusDropdown.filter((s) => {
            if (s.value === PENDING_FOR_AUDIT ||
                s.value === AUDITED_AND_CLOSED ||
                s.value === DISCREPANCY_PENDING_RESOLUTION ||
                s.value === DISCREPANCY_ONGOING_RESOLUTION ||
                s.value === DISCREPANCY_COMPLETED_RESOLUTION) {
                return true;
            }
        });
        sortedStatusDropdown = [
            {
                label: 'Task Status',
                options: taskStatusDropdownArray.sort((item1, item2) => compareIntValue(item1.value, item2.value))
            },
            {
                label: 'Audit Status',
                options: auditStatusDropdownArray.sort((item1, item2) => compareIntValue(item1.value, item2.value))
            },
        ];
        const data = {
            columns: listColumns,
            rows: this.state.taskList,
        };
        const dataWithCheckbox = {
            columns: listColumnsWithCheckbox,
            rows: this.state.bulkSendTaskList
        };
        let roleListDropdown = roleList;
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'track-compliance-status'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={__t(
                                'page_headers.track_compliance_status'
                            )}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <TrackComplianceStatusFilterForm
                                    taskFilterData={this.state.searchFormData}
                                    pageType={'all'}
                                    operationType={this.state.operationType}
                                    complianceCategoryList={this.state.compliancesCategoriesList}
                                    riskRatingsList={this.state.riskRatingsList}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values, true);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <CardBody className="custom_table">
                                <div className="report-select-box track-compliance-status-box">
                                    <div className="inner-report-select-box">
                                        <FormGroup>
                                            <Select
                                                name="role"
                                                className="form-control selectbox"
                                                options={roleListDropdown}
                                                onChange={(opt) => {
                                                    let roleVal =
                                                        opt && opt?.value
                                                            ? opt.value
                                                            : '';
                                                    this.formSubmit({
                                                        ...this.state
                                                            .searchFormData,
                                                        role: roleVal,
                                                    });
                                                }}
                                                value={roleListDropdown.filter(
                                                    (option) =>
                                                        this.state
                                                            .searchFormData
                                                            ?.role ==
                                                        option.value
                                                )}
                                                closeMenuOnSelect={true}
                                                isClearable={true}
                                            />
                                            <Label className="form-label">
                                                Who Am I?
                                            </Label>
                                        </FormGroup>
                                        <FormGroup>
                                            <CustomMultiSelect
                                                component={CustomMultiSelect}
                                                name="statusId[]"
                                                className="form-control selectbox select-optgroup-field"
                                                options={sortedStatusDropdown}
                                                onChange={(opt) => {
                                                    let selectedValues = opt
                                                        ? opt.map(
                                                            (x) => x.value
                                                        )
                                                        : [];
                                                    this.formSubmit({
                                                        ...this.state
                                                            .searchFormData,
                                                        statusId:
                                                            selectedValues,
                                                    });
                                                }}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                allowSelectAll={true}
                                                components={{
                                                    Option,
                                                    MultiValue,
                                                    animatedComponents,
                                                }}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.task_filters.status'

                                                )}
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </div>
                                <CustomisedMDBDatatablePage
                                    activepage={this.state.activePage}
                                    onHandlePageChange={this.handlePageChange}
                                    tableRecords={data}
                                    sortRows={[
                                        'taskCode',
                                        'complianceCode',
                                        'dueDate',
                                        'taskStatusId',
                                    ]}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <DelayReporting
                    modalName={'Delay Reporting Info'}
                    isModalOpen={this.state.isViewDelayReportingModalOpen}
                    DelayReporting={this.state.viewDelayReportingData}
                    onModalDismiss={() => this.closeViewDelayReportingModal()}
                />
                <ViewTaskUser
                    modalName={__t('form_labels.reporting.view_task_user')}
                    isModalOpen={this.state.isViewTaskUserModalOpen}
                    taskUserData={this.state.viewTaskUserData}
                    onModalDismiss={() => this.closeViewTaskUserModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    assignedTaskSearchApiResponse: makeTaskSelectField('assignedTaskSearchApiResponse'),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
    taskUserDetailsApiResponse: makeTaskSelectField('taskUserDetailsApiResponse'),
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    allCompliancesCategoriesList: makeLawComplianceSelectField('allCompliancesCategoriesList'),
    allCompliancesTypesList: makeLawComplianceSelectField('allCompliancesTypesList')
});
const withConnect = connect(mapStatetoProps, {
    getAssignedTaskSearchListRequest,
    getTaskStatusListRequest,
    getTaskUserDetailsByTaskIdRequest,
    getRiskRatingsRequest,
    getComplianceTypesRequest,
    getComplianceCategoriesRequest,
    clearRiskRatingResponse,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
    sendForAuditTaskByTaskIdsRequest
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withTaskReducer,
    withTaskSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(Index);
