import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    CANCEL,
    SUBMIT,
    SUCCESS,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { customDateValidator } from 'helpers/dateTimeHelper';
import 'react-datepicker/dist/react-datepicker.css';
import { makeLegalUpdateSelectField } from 'store/UpdateCenter/LegalUpdates/selector';

import {
    addFileUploadRequest,
    getGcmUsersByCountryRequest,
    updateAssigngcmLegalUpdateRequest,
} from 'store/actions';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import roleReducer from 'store/Masters/Role/reducer';
import roleSaga from 'store/Masters/Role/saga';
import { makeRoleSelectField } from 'store/Masters/Role/selector';
import { compareString } from 'helpers/generalUtils';
import { initialApprovalFormDataState } from './CommonFunctions';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { ACKNOWLEDGE_BY_UPDATER, APPROVED } from 'constants/databaseConstants';

const roleKey = 'role';
const withRoleReducer = injectReducer({
    key: roleKey,
    reducer: roleReducer,
});
const withRoleSaga = injectSaga({ key: roleKey, saga: roleSaga });

class ApproveModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });

        this.state = {
            approvalFormData: initialApprovalFormDataState(),
            usersList: [],
            actionType: 'Approve',
        };
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    approveAndRejectUser = async (formData) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            this.state.actionType == 'Approve'
                ? __t('alerts.assign_legal_update')
                : this.props.statusType === ACKNOWLEDGE_BY_UPDATER
                    ? 'abc'
                    : __t('alerts.reject_user')
        );
        if (response) {
            this.props.updateAssigngcmLegalUpdateRequest(
                formData,
                this.props.compliancesId
            );
        }
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formData = {};
            if (this.state.actionType == 'Approve') {
                formData = {
                    userId: values.userId,
                    loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
                    remarks: values.remarks,
                };
            } else {
                formData = {
                    status: 'REJECTED',
                    remarks: values.remarks,
                };
            }
            this.approveAndRejectUser(formData);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.countryId &&
            this.props.countryId !== nextprops.countryId
        ) {
            this.props.getGcmUsersByCountryRequest({
                countryId: nextprops.countryId,
                type:
                    nextprops.statusType === 1
                        ? 'APPROVER'
                        : nextprops.statusType === APPROVED
                            ? 'SUPER_ADMIN'
                            : 'UPDATER',
            });
        }
        if (
            nextprops.getGcmUsersList &&
            this.props.getGcmUsersList !== nextprops.getGcmUsersList
        ) {
            this.setState({
                usersList: nextprops.getGcmUsersList
                    .map((opt) => ({
                        value: opt.id,
                        label: opt.userName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.assignGcmLegalUpdateApiResponse &&
            this.props.assignGcmLegalUpdateApiResponse !==
            nextprops.assignGcmLegalUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.assignGcmLegalUpdateApiResponse.message,
                nextprops.assignGcmLegalUpdateApiResponse.responseType
            );
            if (
                nextprops.assignGcmLegalUpdateApiResponse.responseType ===
                SUCCESS
            ) {
                this.props.history.push('/legal-updates');
                this.onModalDismiss();
            }
        }
        if (
            nextprops.actionType &&
            this.props.actionType !== nextprops.actionType
        ) {
            this.setState(
                {
                    actionType: nextprops.actionType,
                },
                () => this.validator.purgeFields()
            );
        }
    }
    onModalDismiss = () => {
        this.setState({
            approvalFormData: initialApprovalFormDataState(),
        });
        this.validator.hideMessages();
        this.props.onModalDismiss();
    };

    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize="modal-lg"
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.approvalFormData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form id="a">

                                        <Row className="d-flex m-0">
                                            {this.state.actionType ==
                                                'Approve' && (
                                                    <FormGroup className="col-lg-12  pl-0 pr-2">
                                                        <Field
                                                            component={Select}
                                                            name="userId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.state.usersList
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'userId',
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={this.state.usersList.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.userId
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {this.props
                                                                .statusType == 2
                                                                ? __t(
                                                                    'tables.legal_updates.select_updater'
                                                                )
                                                                : this.props
                                                                    .statusType ===
                                                                    APPROVED
                                                                    ? __t(
                                                                        'tables.legal_updates.select_super_admin'
                                                                    )
                                                                    : __t(
                                                                        'tables.legal_updates.select_approver'
                                                                    )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.masters.system_configuration.user'
                                                            ),
                                                            values.userId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.masters.system_configuration.user_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                )}

                                            <FormGroup className="col-lg-12  pl-0 pr-2">
                                                <Field
                                                    type="text"
                                                    component="textarea"
                                                    name="remarks"
                                                    rows="4"
                                                    className="form-control"
                                                    placeholder={__t(
                                                        'form_labels.reporting.remarks'
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.remarks'
                                                    )}
                                                    {this.state.actionType ===
                                                        'Reject' && (
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        )}
                                                </Label>
                                                {this.state.actionType ==
                                                    'Reject'
                                                    ? this.validator.message(
                                                        __t(
                                                            'form_labels.reporting.remarks'
                                                        ),
                                                        values?.remarks,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.reporting.remarks_required'
                                                                ),
                                                            },
                                                        }
                                                    )
                                                    : ''}
                                            </FormGroup>

                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {SUBMIT}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={() =>
                                                            this.onModalDismiss()
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    getGcmUsersList: makeRoleSelectField('getGcmUsersList'),
    assignGcmLegalUpdateApiResponse: makeLegalUpdateSelectField(
        'assignGcmLegalUpdateApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    addFileUploadRequest,
    getGcmUsersByCountryRequest,
    updateAssigngcmLegalUpdateRequest,
});

export default compose(
    withConnect,
    withRoleReducer,
    withRoleSaga,
    withRouter
)(ApproveModal);
