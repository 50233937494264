import React, { Component, useState } from 'react';
import mainLogo from "../../../images/logo-dark.png"
import reportBG from "../../../images/report_bg.png"
import Location from '../../../images/location.png';
import LocationLight from '../../../images/location_light.png';
import { getComplianceScore } from 'helpers/projectUtils';
import { RISK_RATING_CRITICAL, RISK_RATING_HIGH, RISK_RATING_LOW, RISK_RATING_MODERATE } from 'constants/databaseConstants';
import { getCurrentDateForReportPDF, getCurrentDateTime, getDateFromDateTimeString } from 'helpers/dateTimeHelper';
import { NA } from 'constants/commonConstants';

// import { world_map } from '../PDFUtils/world_map.ts';
// import { MapsComponent, LayersDirective, LayerDirective } from '@syncfusion/ej2-react-maps';

class CertificatesPdfReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false // Add loader state
        };
    }

    render() {
        let departmentWiseData = this.props.departmentWiseData?.filter((e) => e.cityWiseCount.length > 0);
        let auditorWiseData = this.props.departmentWiseData?.filter((e) => e.cityWiseCount.length > 0 && e.cityWiseCount.filter((c) => c.auditAllocated ||
            c.audited || c.pendingObservation || c.pendingAudit || c.discrepancy || c.discrepancyResolution).length > 0);
        let auditorWiseDataDiscrepancy = auditorWiseData?.filter((e) => e.cityWiseCount.length > 0 && e.cityWiseCount.filter((c) => c.discrepancy).length > 0);

        let companyGroupName = this.props?.companyGroup?.companyGroupName ? this.props?.companyGroup?.companyGroupName : ""
        let countryWiseSummaryData = {
            totalCountries: this.props.countryWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }
        let lawWiseSummaryData = {
            totalLaws: this.props.lawWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }

        let entityWiseSummaryData = {
            totalEntities: this.props.entityWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }
        let departmentWiseSummaryData = {
            totalDepartments: this.props.departmentWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }
        const currentDateTime = getCurrentDateTime();
        const currentDate = getCurrentDateForReportPDF();
        const styles = `
            .page-break {
                page-break-before: always;
                display: inline-block;
            }`;
        return (
            <React.Fragment>
                <style>{styles}</style>
                <table style={{ width: '100%', height: '100%', background: '#4f2d7f' }}>
                    <thead style={{ background: '#ffffff' }}>
                        <tr>
                            <th style={{ textAlign: 'right', padding: '1rem 2rem 0rem' }}>
                                <img src={this.props.pdfLogo ? this.props.pdfLogo : mainLogo} width={'130'} />
                            </th>
                        </tr>
                        <tr style={{ padding: '0rem 2rem 1rem' }}>
                            <th style={{ padding: '0rem 2rem 1rem' }}>
                                <h1 style={{ width: '100%', color: '#2a3142', fontSize: '42px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>Anushaasan</h1>
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '60%', maxHeight: '210mm', minHeight: '210mm', width: '100%', backgroundImage: `url(${reportBG})`, backgroundSize: 'cover', backgroundPosition: 'center', padding: '50px 40px', display: 'inline-block' }}>
                        <tr>
                            <td>
                                <div style={{ width: '100%', display: 'inline-block' }}>
                                    <h2 style={{ maxWidth: '75%', color: '#ffffff', fontSize: '32px', fontWeight: 'bold', letterSpacing: '1px', padding: '10px 0', margin: '0', display: 'inline-block', borderBottom: '5px solid #ffffff59' }}>Compliance Certificate</h2>
                                </div>
                                {this.props.filters?.fromDate && this.props.filters.toDate && (
                                    <h6 style={{ Width: '100%', color: '#ffffff', fontSize: '18px', fontWeight: '100', letterSpacing: '1px', padding: '10px 0', margin: '0', display: 'inline-block' }}>{getDateFromDateTimeString(this.props.filters.fromDate)} to {getDateFromDateTimeString(this.props.filters.toDate)}</h6>
                                )}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot style={{ background: '#4f2d7f', minHeight: '50mm', padding: '10px 40px', display: 'inline-flex', alignItems: 'center', width: '100%', position: 'absolute', left: '0', bottom: '0rem', zIndex: '1' }}>
                        <tr style={{ height: '100%' }}>
                            <td style={{ verticalAlign: 'middle', height: '100%' }}>
                                <div style={{ background: '#ffffff', width: '100%', borderRadius: '10px', fontSize: '12px', padding: '10px 10px 5px' }}>
                                    <p style={{ color: '#2A3142', fontSize: '12px', lineHeight: 'normal', margin: '0', display: 'inline-block', width: '100%' }}>This compliance certificate has been generated on [{currentDate}] and provides analysis of compliance status of the company under various jurisdictions, locations, regulations, etc. as of the given date.  In our opinion and to the best of our information and according to the data available in the CMS we certify the following compliance status.
                                    </p>
                                    <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    <div style={{ width: '100%', display: 'inline-block', margin: '0' }}>
                                        <div style={{ width: '50%', float: 'left' }}>
                                            <b style={{ color: '#000', margin: '0', fontWeight: 'bold', width: '100%', display: 'inline-block' }}>{companyGroupName}</b>
                                            {/* <span style={{ color: '#666666', margin: '0' }}>Demo company group</span> */}
                                        </div>
                                        <div style={{ width: '50%', float: 'right', textAlign: 'right' }}>
                                            <b style={{ color: '#000', margin: '0', fontWeight: 'bold', width: '100%', display: 'inline-block' }}>{currentDateTime}</b>
                                            {/* <span style={{ color: '#666666', margin: '0' }}>Demo company group</span> */}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {/* I - Country wise analysis  */}
                {this.props.filters?.typeOfReport.includes('country') && (
                    <>
                        <div className="page-break"></div>
                        <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                            <thead>
                                <tr>
                                    <th colSpan="7" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>I - Country wise analysis </h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <thead style={{ display: 'table-header-group' }}>
                                <tr>
                                    <th colSpan="7" style={{ textAlign: 'center', border: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', backgroundColor: '#4F2D7F', color: '#ffffff', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}>Particulars</th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'left', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>State</th>
                                    <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                    <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                    <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                    <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                    <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                    <th style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '3.5rem' }}>Compliance Score</th>
                                </tr>
                            </thead>
                            {this.props.countryWiseData?.map((country, ckey) => {
                                let complianceScorePerCountry = getComplianceScore(country.pending.length, country.completed.length);
                                countryWiseSummaryData.totalMappedCompliances += country.allocated.length;
                                countryWiseSummaryData.totalReportedCompliances += country.reported.length;
                                countryWiseSummaryData.totalPendingCompliances += country.pending.length;
                                countryWiseSummaryData.totalDelayedCompliances += country.delayed.length;
                                countryWiseSummaryData.totalOverdueCompliances += country.overdue.length;

                                countryWiseSummaryData.riskWiseOverdueCompliances.critical += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                countryWiseSummaryData.riskWiseOverdueCompliances.high += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                countryWiseSummaryData.riskWiseOverdueCompliances.moderate += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                countryWiseSummaryData.riskWiseOverdueCompliances.low += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                countryWiseSummaryData.riskWiseDelayedCompliances.critical += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                countryWiseSummaryData.riskWiseDelayedCompliances.high += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                countryWiseSummaryData.riskWiseDelayedCompliances.moderate += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                countryWiseSummaryData.riskWiseDelayedCompliances.low += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                let statewiseDate = Array.from(Object.values(country.statewiseCount));

                                return (
                                    <>
                                        <tbody className='page-break-avoid'>
                                            <tr>
                                                <td colSpan='7' style={{ backgroundColor: '#ECE9FA', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 25%)', lineHeight: '1' }}>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'center', width: '100%', }}>
                                                        <span style={{ display: 'block', color: '#000000', fontSize: '0.6rem', fontWeight: '500', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}><img src={Location} width={'6'} style={{ marginTop: '-0.25rem' }} alt="location" />&nbsp; {country.countryName}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            {statewiseDate.map((state) => {
                                                let complianceScorePerState = getComplianceScore(state.pending, state.completed);
                                                return (
                                                    <tr style={{ pageBreakInside: 'avoid', }}>
                                                        <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.stateName}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.allocated}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.reported}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.pending}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.delayed}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.overdue}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{complianceScorePerState}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>Total</td>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.allocated.length}</td>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.reported.length}</td>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.pending.length}</td>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.delayed.length}</td>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.overdue.length}</td>
                                                <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{complianceScorePerCountry}</td>
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })}
                            <tfoot>
                                <tr>
                                    <td colSpan="7">
                                        <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                1. Total [{countryWiseSummaryData.totalCountries}] country compliances have been mapped in the Anushaasan. Overall for these countries [{countryWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{countryWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{countryWiseSummaryData.totalReportedCompliances}] reported compliances, [{countryWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                {countryWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                    <>
                                                        2. [{countryWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and  [{countryWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{countryWiseSummaryData.riskWiseDelayedCompliances.moderate}] as moderate and [{countryWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality compliances.</>
                                                ) : (
                                                    <>2. No compliances has been delayed till now.</>
                                                )}

                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                {countryWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                    <>
                                                        3. [{countryWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and  [{countryWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{countryWiseSummaryData.riskWiseOverdueCompliances.moderate}] as moderate and [{countryWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality compliances.
                                                    </>
                                                ) : (
                                                    <>3. No compliances has been overdue till now.</>
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </>
                )}
                {/* II - Regulation wise analysis */}
                {this.props.filters?.typeOfReport.includes('law') && (
                    <>
                        <div className="page-break"></div>
                        <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                            <thead>
                                <tr>
                                    <th colSpan="6" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>II - Regulation wise analysis</h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <thead style={{ display: 'table-header-group' }}>
                                <tr>
                                    <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>State</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                </tr>
                            </thead>
                            {this.props.lawWiseData?.map((country, lkey) => {
                                let lawWiseDataList = Array.from(Object.values(country.lawWiseData));
                                return (
                                    <>
                                        <tbody className='page-break-avoid'>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', border: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', backgroundColor: '#4F2D7F', color: '#ffffff', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}><img src={LocationLight} width={'6'} style={{ marginTop: '-0.25rem' }} alt="location" />&nbsp; {country.countryName}</th>
                                            </tr>
                                            {
                                                lawWiseDataList.map((law, key) => {

                                                    lawWiseSummaryData.totalMappedCompliances += law.allocated.length;
                                                    lawWiseSummaryData.totalReportedCompliances += law.reported.length;
                                                    lawWiseSummaryData.totalPendingCompliances += law.pending.length;
                                                    lawWiseSummaryData.totalDelayedCompliances += law.delayed.length;
                                                    lawWiseSummaryData.totalOverdueCompliances += law.overdue.length;

                                                    lawWiseSummaryData.riskWiseOverdueCompliances.critical += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                    lawWiseSummaryData.riskWiseOverdueCompliances.high += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                    lawWiseSummaryData.riskWiseOverdueCompliances.moderate += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                    lawWiseSummaryData.riskWiseOverdueCompliances.low += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                                    lawWiseSummaryData.riskWiseDelayedCompliances.critical += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                    lawWiseSummaryData.riskWiseDelayedCompliances.high += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                    lawWiseSummaryData.riskWiseDelayedCompliances.moderate += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                    lawWiseSummaryData.riskWiseDelayedCompliances.low += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                                    return (
                                                        <>
                                                            <tr>
                                                                <td colSpan='6' style={{ backgroundColor: '#ECE9FA', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Law Name : </strong>{law.lawShortName}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr style={{ pageBreakInside: 'avoid' }}>
                                                                <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.stateName ? law.stateName : (law.stateId === -1 ? "All" : NA)}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.allocated.length}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.reported.length}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.pending.length}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.delayed.length}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.overdue.length}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </>
                                )
                            })}
                            <tfoot>
                                <tr>
                                    <td colSpan="6">
                                        <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                1. Total [{lawWiseSummaryData.totalLaws}] regulations have been mapped in the Anushaasan. Overall for these regulations [{lawWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{lawWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{lawWiseSummaryData.totalReportedCompliances}] reported compliances, [{lawWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                {lawWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                    <>
                                                        2. [{lawWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and [{lawWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{lawWiseSummaryData.riskWiseDelayedCompliances.moderate}] as medium and [{lawWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality.
                                                    </>
                                                )
                                                    : (
                                                        <>2. No compliances has been delayed till now</>
                                                    )}
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                {lawWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                    <>
                                                        3. [{lawWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and [{lawWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{lawWiseSummaryData.riskWiseOverdueCompliances.moderate}] as medium and [{lawWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality.
                                                    </>
                                                ) : (
                                                    <>3. No compliances has been overdue till now</>
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </>
                )}

                {/* III - Department wise analysis */}
                {this.props.filters?.typeOfReport.includes('department') && (
                    <>
                        <div className="page-break"></div>
                        <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                            <thead>
                                <tr>
                                    <th colSpan="6" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>III - Department wise analysis</h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <thead style={{ display: 'table-header-group' }}>
                                <tr>
                                    <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                </tr>
                            </thead>
                            {departmentWiseData?.map((department, edkey) => {
                                let hodUserList = department.hods.map((h) => h.userName).join();
                                return (
                                    <>
                                        <tbody className='page-break-avoid'>
                                            <tr>
                                                <td colSpan='6' style={{ backgroundColor: '#ECE9FA', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', lineHeight: '1.2' }}>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Name : </strong>{department.entityName}</span>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Department Name : </strong>{department.departmentName}</span>
                                                    </div>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>HoD(s) : </strong>{hodUserList}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                department.cityWiseCount.map((city) => {
                                                    departmentWiseSummaryData.totalMappedCompliances += city.allocated;
                                                    departmentWiseSummaryData.totalReportedCompliances += city.reported;
                                                    departmentWiseSummaryData.totalPendingCompliances += city.pending;
                                                    departmentWiseSummaryData.totalDelayedCompliances += city.delayed;
                                                    departmentWiseSummaryData.totalOverdueCompliances += city.overdue;

                                                    departmentWiseSummaryData.riskWiseOverdueCompliances.critical += city.overdueCritical;
                                                    departmentWiseSummaryData.riskWiseOverdueCompliances.high += city.overdueHigh;
                                                    departmentWiseSummaryData.riskWiseOverdueCompliances.moderate += city.overdueModerate;
                                                    departmentWiseSummaryData.riskWiseOverdueCompliances.low += city.overdueLow;

                                                    departmentWiseSummaryData.riskWiseDelayedCompliances.critical += city.delayedCritical;
                                                    departmentWiseSummaryData.riskWiseDelayedCompliances.high += city.delayedHigh;
                                                    departmentWiseSummaryData.riskWiseDelayedCompliances.moderate += city.delayedModerate;
                                                    departmentWiseSummaryData.riskWiseDelayedCompliances.low += city.delayedLow;
                                                    return (
                                                        <tr style={{ pageBreakInside: 'avoid' }}>
                                                            <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.cityName}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.allocated}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.reported}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.pending}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.delayed}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.overdue}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </>
                                )
                            }
                            )}
                            <tfoot>
                                <tr>
                                    <td colSpan="6">
                                        <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                1. Total [{departmentWiseSummaryData.totalDepartments}] departments have been mapped in the Anushaasan. Overall for these departments [{departmentWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{departmentWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{departmentWiseSummaryData.totalReportedCompliances}] reported compliances, [{departmentWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                {departmentWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                    <>
                                                        2. [{departmentWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and [{departmentWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{departmentWiseSummaryData.riskWiseDelayedCompliances.moderate}] as medium and [{departmentWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality.
                                                    </>
                                                ) : (
                                                    <>2. No compliances has been delayed till now</>
                                                )}
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                {departmentWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                    <>
                                                        3. [{departmentWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and [{departmentWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{departmentWiseSummaryData.riskWiseOverdueCompliances.moderate}] as medium and [{departmentWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality.
                                                    </>
                                                ) : (
                                                    <>3. No compliances has been overdue till now</>
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </>
                )}
                {/* IV - Entity wise analysis */}
                {this.props.filters?.typeOfReport.includes('entity') && (
                    <>
                        <div className="page-break"></div>
                        <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                            <thead>
                                <tr>
                                    <th colSpan="6" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>IV - Entity wise analysis</h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <thead style={{ display: 'table-header-group' }}>
                                <tr>
                                    <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                </tr>
                            </thead>
                            {this.props.entityWiseData?.map((entity, ekey) => {
                                let cityWiseCount = Array.from(Object.values(entity.cityWiseCount));
                                let entityAdminUserList = this.props.entityAdmins.filter((e) => e.entityId == entity.entityId)?.map((eUser) => eUser.user.userName)
                                return (
                                    <tbody className='page-break-avoid'>
                                        <React.Fragment key={`entity_${ekey}`}>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', border: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', backgroundColor: '#4F2D7F', color: '#ffffff', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}>{entity.entityName}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan='6' style={{ backgroundColor: '#ECE9FA', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Admin : </strong>{entityAdminUserList.join(',')}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            {cityWiseCount.map((data) => {
                                                entityWiseSummaryData.totalMappedCompliances += data.allocated.length;
                                                entityWiseSummaryData.totalReportedCompliances += data.reported.length;
                                                entityWiseSummaryData.totalPendingCompliances += data.pending.length;
                                                entityWiseSummaryData.totalDelayedCompliances += data.delayed.length;
                                                entityWiseSummaryData.totalOverdueCompliances += data.overdue.length;

                                                entityWiseSummaryData.riskWiseOverdueCompliances.critical += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                entityWiseSummaryData.riskWiseOverdueCompliances.high += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                entityWiseSummaryData.riskWiseOverdueCompliances.moderate += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                entityWiseSummaryData.riskWiseOverdueCompliances.low += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                                entityWiseSummaryData.riskWiseDelayedCompliances.critical += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                entityWiseSummaryData.riskWiseDelayedCompliances.high += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                entityWiseSummaryData.riskWiseDelayedCompliances.moderate += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                entityWiseSummaryData.riskWiseDelayedCompliances.low += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;
                                                return <>
                                                    <tr style={{ pageBreakInside: 'avoid' }}>
                                                        <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.cityName}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.allocated.length}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.reported.length}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.pending.length}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.delayed.length}</td>
                                                        <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.overdue.length}</td>
                                                    </tr>
                                                </>
                                            })}
                                        </React.Fragment>
                                    </tbody>
                                );
                            })
                            }
                            <tfoot>
                                <tr>
                                    <td colSpan="6">
                                        <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                1. Total [{entityWiseSummaryData.totalEntities}] entities have been mapped in the Anushaasan. Overall for these regulations [{entityWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{entityWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{entityWiseSummaryData.totalReportedCompliances}] reported compliances, [{entityWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                {entityWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                    <>
                                                        2. [{entityWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and [{entityWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{entityWiseSummaryData.riskWiseDelayedCompliances.moderate}] as medium and [{entityWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality.
                                                    </>
                                                ) : (
                                                    <>2. No compliances has been delayed till now</>
                                                )}
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                {entityWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                    <>
                                                        3. [{entityWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and [{entityWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{entityWiseSummaryData.riskWiseOverdueCompliances.moderate}] as medium and [{entityWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality.
                                                    </>
                                                ) : (
                                                    <>3. No compliances has been overdue till now</>
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </>
                )}
                {/* V - Auditor wise analysis */}
                {this.props.filters?.typeOfReport.includes('auditor') &&
                    <>
                        <div className="page-break"></div>
                        <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                            <thead>
                                <tr>
                                    <th colSpan="7" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Auditor wise analysis</h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <th colSpan="7" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '14px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Master Audit Analysis</h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <thead style={{ display: 'table-header-group' }}>
                                <tr>
                                    <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocation to
                                        Auditor</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Audited & Closed </th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending Action on Observation</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Pending with
                                        Auditor</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Discrepancy</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Discrepancy Resolution</th>
                                </tr>
                            </thead>

                            {auditorWiseData.map((department, edkey) => {
                                let hodUserList = department.hods.map((h) => h.userName).join();
                                let lastIndex = auditorWiseData?.length - 1;
                                let isLastRecord = (lastIndex === edkey) ? true : false;
                                return (
                                    <>
                                        <tbody className='page-break-avoid'>
                                            <tr>
                                                <td colSpan='7' style={{ backgroundColor: '#ECE9FA', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', lineHeight: '1.2' }}>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Name: </strong>{department.entityName}</span>
                                                        {/* <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Country: </strong>-</span> */}
                                                    </div>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Department Name: </strong>{department.departmentName}</span>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>HoD(s): </strong>{hodUserList}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                department.cityWiseCount.map((city, k) => {
                                                    let lastIndex1 = department.cityWiseCount?.length - 1;
                                                    let isLastRecord1 = (lastIndex1 === k) ? true : false;
                                                    let borderBottom = '1px solid rgb(79 45 127 / 15%)';
                                                    if (isLastRecord === true && isLastRecord1 === true) {
                                                        borderBottom = '1px solid #4F2D7F';
                                                    }
                                                    // departmentWiseSummaryData.totalAllocatedCompliances += city.auditAllocated;
                                                    // departmentWiseSummaryData.totalReportedCompliances += city.reported;
                                                    // departmentWiseSummaryData.totalPendingCompliances += city.pending;
                                                    // departmentWiseSummaryData.totalDelayedCompliances += city.delayed;
                                                    // departmentWiseSummaryData.totalOverdueCompliances += city.overdue;
                                                    return (
                                                        <tr style={{ pageBreakInside: 'avoid' }}>
                                                            <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.cityName}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.auditAllocated}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.audited}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.pendingObservation}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.pendingAudit}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancy}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancyResolution}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </>
                                );
                            }
                            )}

                        </table>

                        {/* Impact Discrepancy Audit Analysis */}
                        <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                            <thead>
                                <tr>
                                    <th colSpan="3" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '14px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Impact of Discrepancy in Compliance</h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <thead style={{ display: 'table-header-group' }}>
                                <tr>
                                    <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Monetary Impact</th>
                                    <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Non-monetary Impact</th>
                                </tr>
                            </thead>

                            {auditorWiseDataDiscrepancy.map((department, edkey) => {
                                let hodUserList = department.hods.map((h) => h.userName).join();
                                let lastIndex = auditorWiseData?.length - 1;
                                let isLastRecord = (lastIndex === edkey) ? true : false;
                                return (
                                    <>
                                        <tbody className='page-break-avoid'>
                                            <tr>
                                                <td colSpan='3' style={{ backgroundColor: '#ECE9FA', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', lineHeight: '1.2' }}>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Name: </strong>{department.entityName}</span>
                                                        {/* <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Country: </strong>-</span> */}
                                                    </div>
                                                    <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Department Name: </strong>{department.departmentName}</span>
                                                        <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>HoD(s): </strong>{hodUserList}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            {
                                                department.cityWiseCount.map((city, k) => {
                                                    let lastIndex1 = department.cityWiseCount?.length - 1;
                                                    let isLastRecord1 = (lastIndex1 === k) ? true : false;
                                                    let borderBottom = '1px solid rgb(79 45 127 / 15%)';
                                                    if (isLastRecord === true && isLastRecord1 === true) {
                                                        borderBottom = '1px solid #4F2D7F';
                                                    }
                                                    return (
                                                        <tr style={{ pageBreakInside: 'avoid' }}>
                                                            <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.cityName}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancyMonetary}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancyNonMonetary}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </>
                                );
                            }
                            )}

                        </table>
                    </>
                }

            </React.Fragment>
        );
    }
}

export default CertificatesPdfReport;
