import { GET_EMAIL_TEMPLATE_TYPES_LIST_REQUEST, GET_EMAIL_TEMPLATE_TYPES_LIST_RESPONSE } from "./actionTypes";

export const getEmailTemplateTypesListRequest = () => {
    return {
        type: GET_EMAIL_TEMPLATE_TYPES_LIST_REQUEST
    };
};
export const getEmailTemplateTypesListResponse = emailTemplateTypeList => {
    return {
        type: GET_EMAIL_TEMPLATE_TYPES_LIST_RESPONSE,
        payload: { emailTemplateTypeList },
    };
};
