import {
    FORGOT_PASSWORD_RESPONSE,
    RESET_PASSWORD_RESPONSE,
    RESET_PASSWORD_TOKEN_RESPONSE
} from './actionTypes';
import produce from 'immer';
export const initialState = {
    forgotPasswordApiResponse: null,
    resetPasswordTokenApiResponse: null,
    resetPasswordApiResponse: null
};
const forgotPassword = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case FORGOT_PASSWORD_RESPONSE:
                draft.forgotPasswordApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            case RESET_PASSWORD_TOKEN_RESPONSE:
                draft.resetPasswordTokenApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            case RESET_PASSWORD_RESPONSE:
                draft.resetPasswordApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default forgotPassword;
