export const ADD_SECTION_REQUEST = 'add_section_request';
export const ADD_SECTION_RESPONSE = 'add_section_response';
export const GET_ALL_SECTION_LIST_REQUEST = 'get_all_section_list_request';
export const GET_ALL_SECTION_LIST_RESPONSE = 'get_all_section_list_response';
export const GET_SECTION_BY_SECTION_ID_REQUEST = 'get_section_by_section_id_request';
export const GET_SECTION_BY_SECTION_ID_RESPONSE = 'get_section_by_section_id_response';
export const UPDATE_SECTION_REQUEST = 'update_section_request';
export const UPDATE_SECTION_RESPONSE = 'update_section_response';
export const UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST = 'update_section_status_by_section_id_request';
export const UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE = 'update_section_status_by_section_id_response';
export const GET_SECTION_LIST_BY_STATUS_REQUEST = 'get_section_list_by_status_request';
export const GET_SECTION_LIST_BY_STATUS_RESPONSE = 'get_section_list_by_status_response';
export const DELETE_SECTION_BY_SECTION_ID_REQUEST = 'delete_section_by_section_id_request';
export const DELETE_SECTION_BY_SECTION_ID_RESPONSE = 'delete_section_by_section_id_response';
export const GET_SECTION_LIST_BY_LAW_REQUEST = 'get_section_list_by_law_request';
export const GET_SECTION_LIST_BY_LAW_RESPONSE = 'get_section_list_by_law_response';
