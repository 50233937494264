import { CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST, CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE, GET_EXECUTED_EVENT_LIST_REQUEST, GET_EXECUTED_EVENT_LIST_RESPONSE, GET_ONGOING_EVENT_LIST_REQUEST, GET_ONGOING_EVENT_LIST_RESPONSE, GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_REQUEST, GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_RESPONSE, PLAN_EVENT_REQUEST, PLAN_EVENT_RESPONSE, UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST, UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE, UPDATE_USER_DETAILS_BY_TASK_ID_REQUEST, UPDATE_USER_DETAILS_BY_TASK_ID_RESPONSE } from "./actionTypes";

export const planEventRequest = (data) => {
    return {
        type: PLAN_EVENT_REQUEST,
        payload: { data }
    };
};
export const planEventResponse = (message, responseType, data) => {
    return {
        type: PLAN_EVENT_RESPONSE,
        payload: { message, responseType, data }
    };
};

export const getOngoingEventsListRequest = (data) => {
    return {
        type: GET_ONGOING_EVENT_LIST_REQUEST,
        payload: { data }
    }
}
export const getOngoingEventsListResponse = (ongoingEventList) => {
    return {
        type: GET_ONGOING_EVENT_LIST_RESPONSE,
        payload: { ongoingEventList }
    };
};

export const updatePlanEventStatusByPlanEventIdRequest = (planEventId, status) => {
    return {
        type: UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST,
        payload: { planEventId, status },
    };
};
export const updatePlanEventStatusByPlanEventIdResponse = (message, responseType) => {
    return {
        type: UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getExecutedEventsListRequest = (data) => {
    return {
        type: GET_EXECUTED_EVENT_LIST_REQUEST,
        payload: { data }
    }
}
export const getExecutedEventsListResponse = (executedEventList) => {
    return {
        type: GET_EXECUTED_EVENT_LIST_RESPONSE,
        payload: { executedEventList }
    };
};


export const getPlanEventDetailsByPlanEventIdRequest = (planEventId) => {
    return {
        type: GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_REQUEST,
        payload: { planEventId }
    };
};
export const getPlanEventDetailsByPlanEventIdResponse = (message, responseType, data) => {
    return {
        type: GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_RESPONSE,
        payload: { message, responseType, data }
    };
};

export const cancelPlanEventByPlanEventIdRequest = (planEventId) => {
    return {
        type: CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST,
        payload: { planEventId },
    };
};
export const cancelPlanEventByPlanEventIdResponse = (message, responseType) => {
    return {
        type: CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const updateUserDetailsByTaskIdRequest = (taskId, data) => {
    return {
        type: UPDATE_USER_DETAILS_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId, data: data },
    };
};
export const updateUserDetailsByTaskIdResponse = (message, responseType) => {
    return {
        type: UPDATE_USER_DETAILS_BY_TASK_ID_RESPONSE,
        payload: { message, responseType },
    };
};