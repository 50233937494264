import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import __t from 'i18n/translator';
import { getDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import 'react-datepicker/dist/react-datepicker.css';
import { getFundTaskActivityRequest } from 'store/actions';
import { makeFundsSelectField } from 'store/Masters/Funds/selector';
import CustomisedModal from 'components/Application/CustomisedModal';

class TaskActivityLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fundActivityTypes: [],
            taskActivityList: [],
            userId: '',
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.fundTaskActivityApiResponse &&
            this.props.fundTaskActivityApiResponse !==
            nextprops.fundTaskActivityApiResponse
        ) {
            let userId = localStorage.getItem('userId');
            this.setState({
                fundActivityTypes: nextprops.fundTaskActivityApiResponse.data?.fundActivityTypes,
                userId: userId ? userId : '',
            }, () => {
                this.setState({
                    taskActivityList: nextprops.fundTaskActivityApiResponse.data?.fundTaskActivites
                })
            });
        }
        if (nextprops.taskId && this.props.taskId !== nextprops.taskId) {
            this.getFundTaskActivityByTaskId(nextprops.taskId);
        }
    }
    getFundTaskActivityByTaskId(taskId) {
        this.props.getFundTaskActivityRequest(taskId);
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={__t('form_labels.funds.task_activity_log')}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            {this.state.taskActivityList?.map((activity) => (
                                <>
                                {JSON.stringify(activity)}
                                    <div className="list-group">
                                        <a href={() => false} className="list-group-item list-group-item-action flex-column align-items-start ">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">
                                                    {this.state.userId ==
                                                        activity.createdBy
                                                        ? 'You'
                                                        : activity.user
                                                            .fullName}
                                                </h5>
                                                <small>
                                                    {getDateTimeFromDateTimeString(
                                                        activity.createdAt
                                                    )}
                                                </small>
                                            </div>
                                            <p className="mb-1">
                                                {activity?.remarks}
                                            </p>
                                            <small>
                                                {(activity.fundActivityTypeId != '') ? this.state.fundActivityTypes.find((type) => type.id == activity.fundActivityTypeId)?.activityTypeName : ''}
                                            </small>
                                        </a>
                                    </div>
                                </>
                            ))}
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    fundTaskActivityApiResponse: makeFundsSelectField(
        'fundTaskActivityApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getFundTaskActivityRequest,
});

export default compose(withConnect, withRouter)(TaskActivityLog);
