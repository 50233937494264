import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.news || initialState;
const makeNewsSelectField = field =>
    createSelector(reducerState, newsState => newsState[field]);

const selectSortedReleaseTypeList = () =>
    createSelector(makeNewsSelectField('releaseTypeList'), releaseTypeList => {
        return releaseTypeList.map(opt => ({
            value: opt.id,
            label: opt.releaseTypeName,
            type: opt?.type
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    }
    );
export {
    makeNewsSelectField,
    selectSortedReleaseTypeList
};
