import React, { Component } from 'react';
import { getDownloadLogRequest } from 'store/actions';
import { makeLawDocumentSelectField } from 'store/LawDocument/selector';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
const columns = [
    {
        label: 'User Name',
        field: 'userName'
    },
    {
        label: 'User Email',
        field: 'userEmail'
    },
    {
        label: 'Downloaded At',
        field: 'downloadedAt'
    },
];
class ViewDownloadLogsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentId: '',
            downloadLogList: []
        };
    }
    generateTable = records => {
        let downloadRecords = [];
        downloadRecords = records.map(item => {
            return {
                userName: item.user?.userName,
                userEmail: item.user?.email,
                downloadedAt: getFullDateTimeFromDateTimeString(item?.downloadedAt)
            }
        })
        return downloadRecords;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.documentId !== this.props.documentId) {
            this.setState({
                documentId: nextprops.documentId
            }, () => {
                this.props.getDownloadLogRequest(this.state.documentId)
            });
        }
        if (nextprops.downloadLogList !== this.props.downloadLogList) {
            this.setState({
                downloadLogList: this.generateTable(nextprops.downloadLogList)
            })
        }
    }
    render() {
        const reactTableData = {
            columns: columns,
            rows: this.state.downloadLogList,
        };
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider logs_modal'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <CustomisedDatatable
                        className="table table-bordered"
                        tableRecords={reactTableData}
                    />
                </CustomisedSideModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    downloadLogList: makeLawDocumentSelectField('downloadLogList')
});
const withConnect = connect(mapStatetoProps, {
    getDownloadLogRequest
});
export default compose(
    withConnect,
    withRouter
)(ViewDownloadLogsModal);
