import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_USER_REQUEST,
    DELETE_USER_BY_USER_ID_REQUEST,
    GET_ALL_USER_LIST_REQUEST,
    GET_USER_BY_USER_ID_REQUEST,
    GET_USER_LIST_BY_STATUS_REQUEST,
    UPDATE_USER_REQUEST,
    UPDATE_USER_STATUS_BY_USER_ID_REQUEST,
    GET_USER_LIST_BY_ENTITY_ID_REQUEST,
    GET_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_REQUEST,
    PASSWORD_RESEND_USER_BY_USER_ID_REQUEST,
    SET_NEW_PASSWORD_OF_LOGIN_USER_REQUEST,
    GET_USERS_BY_ENTITY_REQUEST,
    GET_USERS_BY_COMPANYGROUP_REQUEST,
    FILTER_USER_REQUEST,
    UPDATE_APPLICATION_THEME_REQUEST,
    UNBLOCK_EMAIL_REQUEST,
    GET_USER_ROLES_BY_USER_ID_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addUserResponse,
    deleteUserByUserIdResponse,
    getAllUserListRequest,
    getAllUserListResponse,
    getUserByUserIdResponse,
    getUserListByStatusResponse,
    updateUserResponse,
    updateUserStatusByUserIdResponse,
    getUserListByCompanyIdResponse,
    getUserProfileResponse,
    getUserProfileRequest,
    updateUserProfileResponse,
    passwordResendUserByUserIdResponse,
    setNewPasswordOfLoginUserResponse,
    getUsersByEntityResponse,
    getUsersByCompanyGroupResponse,
    filterUserResponse,
    filterUserRequest,
    updateApplicationThemeResponse,
    unblockEmailResponse,
    getUserRoleListResponse
} from './actions';
import {
    COMPANYGROUP,
    ENTITY,
    USER,
    USERS,
    USER_BY_ENTITY,
    USER_BY_STATUS,
    USER_PROFILE,
    USER_RESEND_PASSWORD,
    USER_SET_NEW_PASSWORD,
    USERS_SEARCH,
    USER_APPLICATION_THEME,
    UNBLOCK_EMAIL,
    USER_ROLE_LIST,
} from 'helpers/apiConstants';
function* addUser({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', USER, data);
        if (response.status) {
            yield put(filterUserRequest({}));
            yield put(addUserResponse(response.message, SUCCESS));
        } else {
            yield put(addUserResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllUserList() {
    try {
        const response = yield call(invokeApi, 'get', USER, '');
        if (response.status) {
            yield put(getAllUserListResponse(response.data.list));
        } else {
            yield put(getAllUserListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUserByUserId({ payload: { userId } }) {
    try {
        const response = yield call(invokeApi, 'get', USER + '/' + userId);
        if (response.status) {
            yield put(
                getUserByUserIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getUserByUserIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateUser({ payload: { userId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            USER + '/' + userId,
            data
        );
        if (response.status) {
            yield put(filterUserRequest({}));
            yield put(updateUserResponse(response.message, SUCCESS));
        } else {
            yield put(updateUserResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateUserStatusByUserId({ payload: { userId, status } }) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            USER + '/' + userId + '/status/' + status
        );
        if (response.status) {
            yield put(getAllUserListRequest());
            yield put(
                updateUserStatusByUserIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                updateUserStatusByUserIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUserListByStatus({ payload: { status } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            USER_BY_STATUS + '/' + status
        );
        if (response.status) {
            yield put(getUserListByStatusResponse(response.data.list));
        } else {
            yield put(getUserListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteUserByUserId({ payload: { userId } }) {
    try {
        const response = yield call(invokeApi, 'delete', USER + '/' + userId);
        if (response.status) {
            yield put(deleteUserByUserIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteUserByUserIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUserListByCompanyId({ payload: { companyId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            USER_BY_ENTITY + '/' + companyId
        );
        if (response.status) {
            yield put(getUserListByCompanyIdResponse(response.data));
        } else {
            yield put(getUserListByCompanyIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getUserProfile() {
    try {
        const response = yield call(invokeApi, 'get', USER_PROFILE);
        if (response.status) {
            yield put(
                getUserProfileResponse(response.message, SUCCESS, response.data)
            );
        } else {
            yield put(getUserProfileResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateUserProfile({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'put', USER_PROFILE, data);
        if (response.status) {
            yield put(getUserProfileRequest());
            yield put(
                updateUserProfileResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                updateUserProfileResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateApplicationTheme({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', USER_APPLICATION_THEME, data);
        if (response.status) {
            yield put(updateApplicationThemeResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(updateApplicationThemeResponse(response.message, DANGER, response.data));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* passwordResendUserByUserId({ payload: { userId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            USER_RESEND_PASSWORD + '/' + userId
        );
        if (response.status) {
            yield put(
                passwordResendUserByUserIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                passwordResendUserByUserIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* setNewPasswordOfLoginUser({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            USER_SET_NEW_PASSWORD,
            data
        );
        if (response.status) {
            yield put(
                setNewPasswordOfLoginUserResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                setNewPasswordOfLoginUserResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUsersByEntity({ payload: { entityId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            `${USERS}${ENTITY}/${entityId}`,
            ''
        );
        if (response.status) {
            yield put(getUsersByEntityResponse(response.data.list));
        } else {
            yield put(getUsersByEntityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getUsersByCompanyGroup({ payload: { companyGroupId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            `${USERS}${COMPANYGROUP}/${companyGroupId}`,
            ''
        );
        if (response.status) {
            yield put(getUsersByCompanyGroupResponse(response.data.list));
        } else {
            yield put(getUsersByCompanyGroupResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* filterUserList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', USERS_SEARCH, data);
        if (response.status) {
            yield put(filterUserResponse(response.data.list));
        } else {
            yield put(filterUserResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* unblockEmail({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', UNBLOCK_EMAIL, data);
        if (response.status) {
            yield put(unblockEmailResponse(response.message, SUCCESS));
        } else {
            yield put(unblockEmailResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* userRoleList({ payload: { userId } }) {
    try {
        const response = yield call(invokeApi, 'get', USER_ROLE_LIST + '/' + userId);
        if (response.status) {
            yield put(getUserRoleListResponse(response.data.userRoleList));
        } else {
            yield put(getUserRoleListResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchUser() {
    yield takeEvery(ADD_USER_REQUEST, addUser);
    yield takeEvery(GET_ALL_USER_LIST_REQUEST, getAllUserList);
    yield takeEvery(GET_USER_BY_USER_ID_REQUEST, getUserByUserId);
    yield takeEvery(UPDATE_USER_REQUEST, updateUser);
    yield takeEvery(
        UPDATE_USER_STATUS_BY_USER_ID_REQUEST,
        updateUserStatusByUserId
    );
    yield takeEvery(GET_USER_LIST_BY_STATUS_REQUEST, getUserListByStatus);
    yield takeEvery(DELETE_USER_BY_USER_ID_REQUEST, deleteUserByUserId);
    yield takeEvery(GET_USER_LIST_BY_ENTITY_ID_REQUEST, getUserListByCompanyId);
    yield takeEvery(GET_USER_PROFILE_REQUEST, getUserProfile);
    yield takeEvery(UPDATE_USER_PROFILE_REQUEST, updateUserProfile);
    yield takeEvery(UPDATE_APPLICATION_THEME_REQUEST, updateApplicationTheme);
    yield takeEvery(
        PASSWORD_RESEND_USER_BY_USER_ID_REQUEST,
        passwordResendUserByUserId
    );
    yield takeEvery(
        SET_NEW_PASSWORD_OF_LOGIN_USER_REQUEST,
        setNewPasswordOfLoginUser
    );
    yield takeEvery(GET_USERS_BY_ENTITY_REQUEST, getUsersByEntity);
    yield takeEvery(GET_USERS_BY_COMPANYGROUP_REQUEST, getUsersByCompanyGroup);
    yield takeEvery(FILTER_USER_REQUEST, filterUserList);
    yield takeEvery(UNBLOCK_EMAIL_REQUEST, unblockEmail);
    yield takeEvery(GET_USER_ROLES_BY_USER_ID_REQUEST, userRoleList);
}
function* userSaga() {
    yield all([fork(watchUser)]);
}
export default userSaga;
