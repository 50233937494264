import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    CardHeader,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    UPDATE,
    ACTIVE,
    SUBMIT,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getCompanyGroupListByStatusRequest,
    getUsersByEntityRequest,
    addEntityRequest,
    getEntityByEntityIdRequest,
    updateEntityRequest,
    getRolesWithEntityRoleByEntityListRequest,
    addFileUploadRequest
} from 'store/actions';

import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import entityRoleReducer from 'store/Masters/EntityRole/reducer';
import entityRoleSaga from 'store/Masters/EntityRole/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { formatDateForApi } from 'helpers/dateTimeHelper';
import {
    customValidPhoneField,
    customValidTextField,
    customValidTextForEntityNameField,
} from 'helpers/customValidators';
import {
    emailTemplateListColumns,
    finListColumns,
    hodListColumns,
    initialEntityDataState,
    locListColumns,
    teamColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import { makeEntitySelectField } from 'store/Entity/selector';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { selectSortedUserList } from 'store/User/selector';
import AuditLogModal from 'components/Application/AuditLogModal';
import { selectSortedRolesWithEntityRoleList } from 'store/Masters/EntityRole/selector';
import { emailConfigRoleList } from 'constants/dropdowns';
import { makeTaskSelectField } from 'store/Task/selector';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const entityRoleKey = 'entityRole';
const withEntityRoleReducer = injectReducer({
    key: entityRoleKey,
    reducer: entityRoleReducer,
});
const withEntityRoleSaga = injectSaga({
    key: entityRoleKey,
    saga: entityRoleSaga,
});
class ViewEntityForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField(),
                customValidTextForEntityNameField(),
                customValidPhoneField()
            ),
        });
        this.state = {
            selectedFile: [],
            errors: '',
            operationType: SUBMIT,
            entityId: this.props.match.params.entityId
                ? parseInt(this.props.match.params.entityId)
                : '',
            pageHeaderName: __t('page_headers.entity_view'),
            entityData: initialEntityDataState(),
            removeEntityLocations: [],
            removeFinancialYears: [],
            removeEntityEmailTemplates: [],
            removeEntityHod: [],
            financialYearData: {},
            isModalOpen: false,
            financialYearOperationType: SUBMIT,
            isFinancialYearEdit: false,
            locationData: {},
            isLocationModalOpen: false,
            locationOperationType: SUBMIT,
            isLocationEdit: false,
            isEmailTemplateModalOpen: false,
            emailTemplateOperationType: SUBMIT,
            emailTemplateData: {},
            isEmailTemplateEdit: false,
            isHodModalOpen: false,
            hodOperationType: SUBMIT,
            hodData: {},
            isHodEdit: false,
            isTeamModalOpen: false,
            teamOperationType: SUBMIT,
            teamData: {},
            isTeamEdit: false,
            teamType: '',
            isAuditModalOpen: false,
            auditId: '',
            isAuditLocationModalOpen: false,
            auditLocationId: '',
            isAuditHodModalOpen: false,
            auditHodId: '',
            isAuditEmailTemplateModalOpen: false,
            auditEmailTemplateId: '',
            entityUsers: [],
            entitySystemFileName: '',
            entityFileName: '',
        };
    }
    componentDidMount() {
        if (this.state.entityId) {
            this.props.getEntityByEntityIdRequest(this.state.entityId);
            this.props.getUsersByEntityRequest(this.state.entityId);
            this.props.getRolesWithEntityRoleByEntityListRequest(
                this.state.entityId
            );
        } else {
            this.props.getRolesWithEntityRoleByEntityListRequest(-1);
        }
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        var countryIdsData = countryIds.filter(function (el) {
            return el !== '';
        });
        this.props.getStateListByCountryRequest(countryIdsData);
    }
    getCityListByState(stateIds) {
        var stateIdsData = stateIds.filter(function (el) {
            return el !== '';
        });
        this.props.getCityListByStateRequest(stateIdsData);
    }
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    openAuditLogLocationModal = (auditLocationId) => {
        this.setState({
            isAuditLocationModalOpen: true,
            auditLocationId: auditLocationId,
        });
    };
    closeAuditLogLocationModal = () => {
        this.setState({
            isAuditLocationModalOpen: false,
            auditLocationId: '',
        });
    };
    openAuditLogEmailTemplateModal = (auditEmailTemplateId) => {
        this.setState({
            isAuditEmailTemplateModalOpen: true,
            auditEmailTemplateId: auditEmailTemplateId,
        });
    };
    closeAuditLogEmailTemplateModal = () => {
        this.setState({
            isAuditEmailTemplateModalOpen: false,
            auditEmailTemplateId: '',
        });
    };
    openAuditLogHodModal = (auditHodId) => {
        this.setState({
            isAuditHodModalOpen: true,
            auditHodId: auditHodId,
        });
    };
    closeAuditLogHodModal = () => {
        this.setState({
            isAuditHodModalOpen: false,
            auditHodId: '',
        });
    };
    generateFinancialYearTable = (records, setFieldValue, values) => {
        let financialYearRecords = [];
        financialYearRecords = records.map((financialYearRecord, i) => {
            let actions = (
                <div className="text-center">
                    {financialYearRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogModal(financialYearRecord.id);
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                countryName: financialYearRecord.countryName,
                startMonth: financialYearRecord.startMonth,
                endMonth: financialYearRecord.endMonth,
                actions: actions,
            };
        });
        return financialYearRecords.reverse();
    };
    generateLocationTable = (records, setFieldValue, values) => {
        let locationRecords = [];
        locationRecords = records.map((locationRecord, i) => {
            let actions = (
                <div className="text-center">
                    {locationRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogLocationModal(
                                    locationRecord.id
                                );
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                countryName: locationRecord.countryName,
                stateName: locationRecord.stateName,
                cityName: locationRecord.cityName,
                pincode: (locationRecord.pincode && locationRecord.pincode != null) ? locationRecord.pincode : '',
                registrationNumber: (locationRecord.registrationNumber && locationRecord.registrationNumber != null) ? locationRecord.registrationNumber : '',
                registrationDate: (locationRecord.registrationDate && locationRecord.registrationDate != '0000-00-00')
                    ? formatDateForApi(locationRecord.registrationDate)
                    : '',
                // isActive: isActive,
                actions: actions,
            };
        });
        return locationRecords.reverse();
    };
    generateEmailTemplateTable = (records, setFieldValue, values) => {
        let emailTemplateRecords = [];
        emailTemplateRecords = records.map((emailTemplateRecord, i) => {
            let actions = (
                <div className="text-center">
                    {emailTemplateRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogEmailTemplateModal(
                                    emailTemplateRecord.id
                                );
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                emailTemplateType: emailTemplateRecord?.emailTemplateTypeName,
                toRoles: emailTemplateRecord?.toRolesName?.join(),
                ccRoles: emailTemplateRecord?.ccRolesName?.join(),
                daysFirstNotification:
                    emailTemplateRecord?.daysFirstNotification,
                daysSecondNotification:
                    emailTemplateRecord?.daysSecondNotification,
                actions: actions,
            };
        });
        return emailTemplateRecords.reverse();
    };
    generateHodTable = (records, setFieldValue, values) => {
        let hodRecords = [];
        hodRecords = records.map((hodRecord, i) => {
            let actions = (
                <div className="text-center">
                    {hodRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogHodModal(hodRecord.id);
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                departmentName: hodRecord.departmentName,
                userName: hodRecord.userName,
                actions: actions,
            };
        });
        return hodRecords.reverse();
    };
    generateTeamTable = (records, setFieldValue, values, type) => {
        let teamRecords = [];
        teamRecords = records.map((teamRecord, i) => {
            return {
                fullName: teamRecord?.user?.fullName,
                email: teamRecord?.user?.email,
            };
        });
        return teamRecords.reverse();
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.editEntityApiResponse &&
            this.props.editEntityApiResponse !== nextprops.editEntityApiResponse
        ) {
            this.setState({
                operationType: UPDATE,
                entityData: {
                    ...nextprops.editEntityApiResponse.data.entity,
                    companyGroupId:
                        nextprops.editEntityApiResponse.data.entity.companyGroup
                            .id,
                    entityFinancialYears:
                        nextprops.editEntityApiResponse.data.entity.entityFinancialYears?.map(
                            (opt) => ({
                                ...opt,
                                countryName: opt.country
                                    ? opt.country.countryName
                                    : '',
                            })
                        ) || [],
                    entityLocations:
                        nextprops.editEntityApiResponse.data.entity.entityLocations?.map(
                            (opt) => ({
                                ...opt,
                                countryName: opt.country
                                    ? opt.country.countryName
                                    : '',
                                stateName: opt.state ? opt.state.stateName : '',
                                cityName: opt.city ? opt.city.cityName : '',
                            })
                        ) || [],
                    entityEmailTemplates:
                        nextprops.editEntityApiResponse.data.entity.entityEmailTemplates?.map(
                            (opt) => ({
                                ...opt,
                                emailTemplateTypeName: opt.emailTemplateType
                                    ? opt.emailTemplateType.displayName
                                    : '',
                                toRolesName:
                                    opt.toRoles.length > 0
                                        ? emailConfigRoleList
                                            .map((optionRole) =>
                                                opt.toRoles.includes(
                                                    optionRole.value
                                                )
                                                    ? optionRole.label
                                                    : ''
                                            )
                                            .filter((x) => x != '')
                                        : [],
                                ccRolesName:
                                    opt.ccRoles.length > 0
                                        ? emailConfigRoleList
                                            .map((optionRole) =>
                                                opt.ccRoles.includes(
                                                    optionRole.value
                                                )
                                                    ? optionRole.label
                                                    : ''
                                            )
                                            .filter((x) => x != '')
                                        : [],
                            })
                        ) || [],
                    entityAdmin:
                        nextprops.editEntityApiResponse.data.entity.entityAdmin?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityTeam:
                        nextprops.editEntityApiResponse.data.entity.entityTeam?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityManagementTeam:
                        nextprops.editEntityApiResponse.data.entity.entityManagementTeam?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityConsultantTeam:
                        nextprops.editEntityApiResponse.data.entity.entityConsultantTeam?.map(
                            (opt) => opt.userId
                        ) || [],
                    entityAdminUsers:
                        nextprops.editEntityApiResponse.data.entity.entityAdmin,
                    entityManagementUsers:
                        nextprops.editEntityApiResponse.data.entity
                            .entityManagementTeam,
                    entityTeamUsers:
                        nextprops.editEntityApiResponse.data.entity.entityTeam,
                    entityConsultantUsers:
                        nextprops.editEntityApiResponse.data.entity
                            .entityConsultantTeam,
                    entityHod:
                        nextprops.editEntityApiResponse.data.entity.entityHods?.map(
                            (opt) => ({
                                ...opt,
                                departmentName: opt.department
                                    ? opt.department.departmentName
                                    : '',
                                userName: opt.user
                                    ? opt.user.fullName + ' - ' + opt.user.email
                                    : '',
                            })
                        ) || [],
                },
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Col lg="12">
                    <CommonBreadcrum type={'view-entity'} />
                </Col>
                <div className="page-header">
                    <h4 className="with-back-btn mt-0 d-flex">
                        <a
                            href={() => false}
                            className="dripicons-arrow-thin-left"
                            onClick={() => {
                                this.props.history.push('/entities');
                            }}
                        >
                            { }
                        </a>{' '}
                        &nbsp;{this.state.pageHeaderName}{' '}
                    </h4>
                </div>
                <Formik
                    initialValues={this.state.entityData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="add-entity">

                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.basic_details'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                component={Select}
                                                name="companyGroupId"
                                                className="form-control selectbox"
                                                options={
                                                    this.props
                                                        .sortedCompanyGroupListDropdown
                                                }
                                                onChange={(opt) => {
                                                    setFieldValue(
                                                        'companyGroupId',
                                                        opt.value
                                                    );
                                                }}
                                                value={this.props.sortedCompanyGroupListDropdown.filter(
                                                    (option) =>
                                                        option.value ===
                                                        values.companyGroupId
                                                )}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.company_group'
                                                )}{' '}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.company_group'
                                                ),
                                                values.companyGroupId,
                                                'required',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.entity.company_group_required'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                name="entityName"
                                                className="form-control"
                                                placeholder={__t(
                                                    'form_labels.entity.entity_name'
                                                )}
                                                value={values.entityName}
                                                maxLength={250}

                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.entity_name'
                                                )}{' '}
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.entity_name'
                                                ),
                                                values.entityName,
                                                'required|max:250|onlyCharactersForEntityName',
                                                {
                                                    messages: {
                                                        required: __t(
                                                            'validations.entity.entity_name_required'
                                                        ),
                                                        max: __t(
                                                            'validations.entity.entity_name_max',
                                                            { max: 250 }
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                name="email"
                                                className="form-control"
                                                placeholder={__t(
                                                    'form_labels.entity.registered_email_id'
                                                )}
                                                maxLength={150}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.registered_email_id'
                                                )}{' '}
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.registered_email_id'
                                                ),
                                                values.email,
                                                'email|max:150',
                                                {
                                                    messages: {

                                                        max: __t(
                                                            'validations.entity.email_max',
                                                            { max: 150 }
                                                        ),
                                                        email: __t(
                                                            'validations.entity.email_email'
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>
                                        <FormGroup className="col-lg-6">
                                            <Field
                                                name="contactNo"
                                                className="form-control"
                                                placeholder={__t(
                                                    'form_labels.entity.contact_no'
                                                )}
                                                maxLength={14}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.entity.contact_no'
                                                )}
                                            </Label>
                                            {this.validator.message(
                                                __t(
                                                    'form_labels.entity.contact_no'
                                                ),
                                                values.contactNo,
                                                'customPhone|max:14',
                                                {
                                                    messages: {

                                                        max: __t(
                                                            'validations.entity.contact_no_max',
                                                            { max: 14 }
                                                        ),
                                                    },
                                                }
                                            )}
                                        </FormGroup>

                                        {values.fileUrl != '' && this.state.operationType == UPDATE && (
                                            <div className='col-12 d-flex justify-content-end'>
                                                <Button className='px-2' color='warning' size='sm' onClick={() => {
                                                    this.saveFile(values);
                                                }}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10L7.29289 10.7071L8 11.4142L8.70711 10.7071L8 10ZM9 1C9 0.447715 8.55229 2.42698e-07 8 2.18557e-07C7.44772 1.94416e-07 7 0.447715 7 1L9 1ZM2.29289 5.70711L7.29289 10.7071L8.70711 9.29289L3.70711 4.29289L2.29289 5.70711ZM8.70711 10.7071L13.7071 5.70711L12.2929 4.29289L7.29289 9.29289L8.70711 10.7071ZM9 10L9 1L7 1L7 10L9 10Z" fill="currentColor" /><path d="M1 12L1 13C1 14.1046 1.89543 15 3 15L13 15C14.1046 15 15 14.1046 15 13V12" stroke="currentColor" strokeWidth="2" /></svg> {__t('form_labels.common.click_to_download_existing_file')}
                                                </Button>
                                            </div>
                                        )}
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity locations */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.locations'
                                            )}
                                        </h4>
                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                tableRecords={{
                                                    columns: locListColumns,
                                                    rows: this.generateLocationTable(
                                                        values.entityLocations,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Financial Year  */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.financial_year'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: finListColumns,
                                                    rows: this.generateFinancialYearTable(
                                                        values.entityFinancialYears,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Admins */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_admin'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns.filter((cl) => cl.field != 'actions'),
                                                    rows: this.generateTeamTable(
                                                        values.entityAdminUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityAdminUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Team */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_team'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns.filter((cl) => cl.field != 'actions'),
                                                    rows: this.generateTeamTable(
                                                        values.entityTeamUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityTeamUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Management Team */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_management_team'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns.filter((cl) => cl.field != 'actions'),
                                                    rows: this.generateTeamTable(
                                                        values.entityManagementUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityManagementUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Consultant Team */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.entity_consultant_team'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: teamColumns.filter((cl) => cl.field != 'actions'),
                                                    rows: this.generateTeamTable(
                                                        values.entityConsultantUsers,
                                                        setFieldValue,
                                                        values,
                                                        'entityConsultantUsers'
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity HoD  */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>{__t('form_labels.entity.hod')}</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <CustomisedDatatable
                                                entries={10}
                                                tableRecords={{
                                                    columns: hodListColumns.filter((cl) => cl.field != 'actions'),
                                                    rows: this.generateHodTable(
                                                        values.entityHod,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Entity Emails */}
                            <Card>
                                <CardHeader className="py-2">
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'form_labels.entity.email_templates'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>

                                            <CustomisedDatatable
                                                tableRecords={{
                                                    columns:
                                                        emailTemplateListColumns,
                                                    rows: this.generateEmailTemplateTable(
                                                        values.entityEmailTemplates,
                                                        setFieldValue,
                                                        values
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                        </Form>
                    )}
                </Formik>
                <AuditLogModal
                    exportBtn={true}
                    key={'finYear'}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityFinancialYear'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
                <AuditLogModal
                    exportBtn={true}
                    key={'location'}
                    isModalOpen={this.state.isAuditLocationModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityLocation'}
                    auditId={this.state.auditLocationId}
                    onModalDismiss={() => this.closeAuditLogLocationModal()}
                />
                <AuditLogModal
                    exportBtn={true}
                    key={'entityEmailTemplate'}
                    isModalOpen={this.state.isAuditEmailTemplateModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityEmailTemplate'}
                    auditId={this.state.auditEmailTemplateId}
                    onModalDismiss={() =>
                        this.closeAuditLogEmailTemplateModal()
                    }
                />

                <AuditLogModal
                    exportBtn={true}
                    key={'entityDepartmentHod'}
                    isModalOpen={this.state.isAuditHodModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'entityDepartmentHod'}
                    auditId={this.state.auditHodId}
                    onModalDismiss={() => this.closeAuditLogHodModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedUserListDropdown: selectSortedUserList(),
    entityApiResponse: makeEntitySelectField('entityApiResponse'),
    editEntityApiResponse: makeEntitySelectField('editEntityApiResponse'),
    sortedRoleListDropDown: selectSortedRolesWithEntityRoleList(),
    fileUploadResponse: makeTaskSelectField('fileUploadResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getCompanyGroupListByStatusRequest,
    getUsersByEntityRequest,
    addEntityRequest,
    getEntityByEntityIdRequest,
    updateEntityRequest,
    getRolesWithEntityRoleByEntityListRequest,
    addFileUploadRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withUserReducer,
    withUserSaga,
    withEntityReducer,
    withEntitySaga,
    withEntityRoleReducer,
    withEntityRoleSaga,
    withTaskReducer,
    withTaskSaga,
    withRouter
)(ViewEntityForm);
