import produce from 'immer';
import { ACTIVATE_OR_DEACTIVATE_NEWS_RESPONSE, CREATE_NEWS_RESPONSE, GET_ALL_RELEASE_TYPE_LIST_RESPONSE, GET_NEWS_DETAILS_BY_ID_RESPONSE, GET_NEWS_LIST_RESPONSE, GET_NEWS_WITH_SEARCH_RESPONSE, PUBLISH_NEWS_BY_NEWS_ID_RESPONSE, UPDATE_NEWS_RESPONSE } from './actionTypes';

export const initialState = {
    newsList: [],
    newsStatusUpdateApiResponse: [],
    newsDetailsApiResponse: null,
    newsApiResponse: [],
    publishNewsApiResponse: null,
    releaseTypeList: []
}

const news = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {

            case GET_NEWS_LIST_RESPONSE:
                draft.newsList = action.payload.newsList;
                break;

            case ACTIVATE_OR_DEACTIVATE_NEWS_RESPONSE:
                draft.newsStatusUpdateApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case CREATE_NEWS_RESPONSE:
            case UPDATE_NEWS_RESPONSE:
                draft.newsApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;


            case GET_NEWS_DETAILS_BY_ID_RESPONSE:
                draft.newsDetailsApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case PUBLISH_NEWS_BY_NEWS_ID_RESPONSE:
                draft.publishNewsApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_NEWS_WITH_SEARCH_RESPONSE:
                draft.newsApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_ALL_RELEASE_TYPE_LIST_RESPONSE:
                draft.releaseTypeList = action.payload.releaseTypeList;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default news;