import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.role || initialState;
const makeRoleSelectField = field =>
    createSelector(reducerState, roleState => roleState[field]);
const selectSortedRoleList = () =>
    createSelector(makeRoleSelectField('roleList'), roleList =>
        roleList.map(opt => ({
            value: opt.id,
            label: opt.roleName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export { makeRoleSelectField, selectSortedRoleList };
