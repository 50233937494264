import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_SECTION_REQUEST, DELETE_SECTION_BY_SECTION_ID_REQUEST, GET_ALL_SECTION_LIST_REQUEST, GET_SECTION_BY_SECTION_ID_REQUEST, GET_SECTION_LIST_BY_LAW_REQUEST, GET_SECTION_LIST_BY_STATUS_REQUEST, UPDATE_SECTION_REQUEST, UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addSectionResponse, deleteSectionBySectionIdResponse, getAllSectionListRequest, getAllSectionListResponse, getSectionBySectionIdResponse, getSectionListByLawResponse, getSectionListByStatusResponse, updateSectionResponse, updateSectionStatusBySectionIdResponse } from './actions';
import { SECTION, SECTION_BY_STATUS, SECTION_LIST_BY_LAW } from 'helpers/apiConstants';
function* addSection({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', SECTION, data);
        if (response.status) {
            yield put(getAllSectionListRequest());
            yield put(addSectionResponse(response.message, SUCCESS));
        } else {
            yield put(addSectionResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllSectionList() {
    try {
        const response = yield call(invokeApi, 'get', SECTION, '');
        if (response.status) {
            yield put(getAllSectionListResponse(response.data));
        } else {
            yield put(getAllSectionListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSectionBySectionId({ payload: { sectionId } }) {
    try {
        const response = yield call(invokeApi, 'get', SECTION + '/' + sectionId, '');
        if (response.status) {
            yield put(getSectionBySectionIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getSectionBySectionIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateSection({ payload: { sectionId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', SECTION + '/' + sectionId, data);
        if (response.status) {
            yield put(getAllSectionListRequest());
            yield put(updateSectionResponse(response.message, SUCCESS));
        } else {
            yield put(updateSectionResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateSectionStatusBySectionId({ payload: { sectionId, status } }) {
    try {
        const response = yield call(invokeApi, 'patch', SECTION + '/' + sectionId + '/status/' + status);
        if (response.status) {
            yield put(getAllSectionListRequest());
            yield put(updateSectionStatusBySectionIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateSectionStatusBySectionIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSectionListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', SECTION_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getSectionListByStatusResponse(response.data.list));
        } else {
            yield put(getSectionListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteSectionBySectionId({ payload: { sectionId } }) {
    try {
        const response = yield call(invokeApi, 'delete', SECTION + '/' + sectionId);
        if (response.status) {
            yield put(getAllSectionListRequest());
            yield put(deleteSectionBySectionIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteSectionBySectionIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSectionListByLaw({ payload: { lawId } }) {
    try {
        const response = yield call(invokeApi, 'get', SECTION_LIST_BY_LAW+'/'+lawId);
        if (response.status) {
            yield put(getSectionListByLawResponse(response.data.list));
        } else {
            yield put(getSectionListByLawResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchSection() {
    yield takeEvery(ADD_SECTION_REQUEST, addSection);
    yield takeEvery(GET_ALL_SECTION_LIST_REQUEST, getAllSectionList);
    yield takeEvery(GET_SECTION_BY_SECTION_ID_REQUEST, getSectionBySectionId);
    yield takeEvery(UPDATE_SECTION_REQUEST, updateSection);
    yield takeEvery(UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST, updateSectionStatusBySectionId);
    yield takeEvery(GET_SECTION_LIST_BY_STATUS_REQUEST, getSectionListByStatus);
    yield takeEvery(DELETE_SECTION_BY_SECTION_ID_REQUEST, deleteSectionBySectionId);
    yield takeEvery(GET_SECTION_LIST_BY_LAW_REQUEST, getSectionListByLaw);
}
function* sectionSaga() {
    yield all([fork(watchSection)]);
}
export default sectionSaga;