import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Applicability Name',
        field: 'applicabilityName',
    },
    {
        label: 'Sub Applicabilities Count',
        field: 'subApplicabilitiesCount',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialApplicabilityDataState = () => {
    return {
        applicabilityName: '',
        subApplicabilities: initialSubApplicabilitiesDataState(),
        isActive: ACTIVE
    }
}
export const initialSubApplicabilitiesDataState = () => {
    return [{
        id: 0,
        subApplicabilityName: ''
    }];
}
export const intFields = [];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};