import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ENABLE_DELETE, SUBMIT, UPDATE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllApplicabilityListRequest,
    updateApplicabilityStatusByApplicabilityIdRequest,
    addApplicabilityRequest,
    updateApplicabilityRequest,
    deleteApplicabilityByApplicabilityIdRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import applicabilityReducer from 'store/Masters/Applicability/reducer';
import applicabilitySaga from 'store/Masters/Applicability/saga';
import { makeApplicabilitySelectField } from 'store/Masters/Applicability/selector';
import { initialApplicabilityDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import AddApplicabilityForm from './AddApplicabilityForm';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_APPLICABILITY,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const applicabilityKey = 'applicability';
const withApplicabilityReducer = injectReducer({
    key: applicabilityKey,
    reducer: applicabilityReducer,
});
const withApplicabilitySaga = injectSaga({ key: applicabilityKey, saga: applicabilitySaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicabilityList: [],
            exportApplicabilityList: [],
            applicabilityData: initialApplicabilityDataState(),
            operationType: SUBMIT,
            clickExportBtn: false,
            exportData: [],
            isAuditModalOpen: false,
            auditId: '',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve applicability list
        this.props.getAllApplicabilityListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateApplicabilityStatusByApplicabilityIdRequest(id, checked);
    }
    viewApplicability(applicabilityData) {
        this.setState(
            {
                applicabilityData: initialApplicabilityDataState(),
                operationType: UPDATE,
            },
            () => {
                let subApplicabilities = applicabilityData.subApplicability;
                this.setState({
                    applicabilityData: {
                        ...applicabilityData,
                        subApplicabilities: subApplicabilities,
                    },
                });
            }
        );
    }
    deleteApplicability = async (applicabilityId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.applicability_delete')
        );
        if (response) {
            this.props.deleteApplicabilityByApplicabilityIdRequest(applicabilityId);
        }
    };
    formSubmit = (values) => {
        this.setState({ applicabilityData: values });
        let valuesData = values;
        valuesData.subApplicabilities = valuesData.subApplicabilities.filter(
            (opt) => opt.subApplicabilityName !== ''
        );
        if (this.state.operationType === UPDATE) {
            this.props.updateApplicabilityRequest(values.id, valuesData);
        } else {
            this.props.addApplicabilityRequest(valuesData);
        }
    };
    resetForm = () => {
        this.setState({
            applicabilityData: initialApplicabilityDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let applicabilityRecords = records.map((applicabilityData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_APPLICABILITY, PERMISSION_EDIT) ===
                        true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewApplicability(applicabilityData);
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('applicability') &&
                        checkModulePermission(
                            MODULE_APPLICABILITY,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteApplicability(applicabilityData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(applicabilityData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(applicabilityData.id)}
                        checked={applicabilityData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            return {
                applicabilityName: applicabilityData.applicabilityName,
                subApplicabilitiesCount: applicabilityData.subApplicability ? applicabilityData.subApplicability.length : 0,
                isActive: isActive,
                actions: actions,
            };
        });
        return applicabilityRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.applicabilityList &&
            this.props.applicabilityList !== nextprops.applicabilityList
        ) {
            this.setState({
                exportApplicabilityList: nextprops.applicabilityList,
                applicabilityList: this.generateTable(nextprops.applicabilityList),
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.applicabilityList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'applicability'} />
                        </Col>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t('page_headers.applicability_list')}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage
                                        activepage={this.state.activePage}
                                        onHandlePageChange={this.handlePageChange}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {(checkModulePermission(
                            MODULE_APPLICABILITY,
                            PERMISSION_ADD
                        ) === true ||
                            checkModulePermission(
                                MODULE_APPLICABILITY,
                                PERMISSION_EDIT
                            ) === true) && (
                                <Col lg="5">
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'page_headers.applicability_form'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <AddApplicabilityForm
                                                operationType={
                                                    this.state.operationType
                                                }
                                                applicabilityData={
                                                    this.state.applicabilityData
                                                }
                                                operationResponse={
                                                    this.props.applicabilityApiResponse
                                                }
                                                onFormValid={this.formSubmit}
                                                onFormReset={this.resetForm}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                    </Row>

                    <AuditLogModal
                        exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'applicability'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    applicabilityList: makeApplicabilitySelectField('applicabilityList'),
    applicabilityApiResponse: makeApplicabilitySelectField('applicabilityApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getAllApplicabilityListRequest,
    updateApplicabilityStatusByApplicabilityIdRequest,
    addApplicabilityRequest,
    updateApplicabilityRequest,
    deleteApplicabilityByApplicabilityIdRequest,
});
export default compose(
    withConnect,
    withApplicabilityReducer,
    withApplicabilitySaga,
    withRouter
)(Index);
