import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.archive || initialState;
const makeArchiveRecordSelectField = field =>
    createSelector(reducerState, archiveState => archiveState[field]);

const selectSortedArchiveDepartmentList = () =>
    createSelector(makeArchiveRecordSelectField('archiveDepartmentList'), archiveDepartmentList => {
        return archiveDepartmentList.map(opt => ({
            value: opt.id,
            label: opt.archiveDepartmentName
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    }
    );

export {
    makeArchiveRecordSelectField,
    selectSortedArchiveDepartmentList
};
