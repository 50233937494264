import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.state || initialState;
const makeStateSelectField = field =>
    createSelector(reducerState, stateState => stateState[field]);
const selectSortedStateList = () =>
    createSelector(makeStateSelectField('stateList'), stateList =>
        stateList.map(opt => ({
            value: opt.id,
            label: opt.stateName,
        }))
    );
export {
    makeStateSelectField,
    selectSortedStateList
};
