import produce from 'immer';
import { ADD_APPLICABILITY_RESPONSE, DELETE_APPLICABILITY_BY_APPLICABILITY_ID_RESPONSE, GET_ALL_APPLICABILITY_LIST_RESPONSE, GET_APPLICABILITY_LIST_BY_STATUS_RESPONSE, GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_RESPONSE, UPDATE_APPLICABILITY_RESPONSE, UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_RESPONSE } from './actionTypes';
export const initialState = {
    applicabilityApiResponse: null,
    applicabilityList: [],
    subApplicabilityList: []
};
const applicability = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_APPLICABILITY_RESPONSE:
            case UPDATE_APPLICABILITY_RESPONSE:
            case UPDATE_APPLICABILITY_STATUS_BY_APPLICABILITY_ID_RESPONSE:
            case DELETE_APPLICABILITY_BY_APPLICABILITY_ID_RESPONSE:
                draft.applicabilityApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_APPLICABILITY_LIST_RESPONSE:
                draft.applicabilityList = action.payload.applicabilityList;
                break;

            case GET_APPLICABILITY_LIST_BY_STATUS_RESPONSE:
                draft.applicabilityList = action.payload.applicabilityList;
                break;

            case GET_SUB_APPLICABILITY_LIST_BY_APPLICABILITY_RESPONSE:
                draft.subApplicabilityList = action.payload.subApplicabilityList;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default applicability;
