import produce from 'immer';
import { GET_EMAIL_TEMPLATE_TYPES_LIST_RESPONSE } from './actionTypes';
export const initialState = {
    emailTemplateTypeList: []
};
const emailTemplate = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case GET_EMAIL_TEMPLATE_TYPES_LIST_RESPONSE:
                draft.emailTemplateTypeList = action.payload.emailTemplateTypeList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default emailTemplate;
