import React, { Component } from 'react';
import { Badge, Button, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE_LABEL, ADD_NEW, INACTIVE_LABEL, NA, SUCCESS } from 'constants/commonConstants';
import classnames from 'classnames';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getLawByLawIdRequest,
    getLawCompliancesByLawIdRequest,
    getLawDocumentsByLawIdRequest,
    getLawDefinitionsByLawIdRequest,
    getLawComplianceDetailsByLawComplianceIdRequest,
    updateLawComplianceStatusByLawComplianceIdRequest,
    getAllDocumentTypeListRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import lawDocumentReducer from 'store/LawDocument/reducer';
import lawDocumentSaga from 'store/LawDocument/saga';
import lawDefinitionReducer from 'store/LawDefinition/reducer';
import lawDefinitionSaga from 'store/LawDefinition/saga';
import { toastrMessage } from 'helpers/messageHelper';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { makeLawDefinitionSelectField } from 'store/LawDefinition/selector';
import ShowMoreText from "react-show-more-text";
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { makeLawSelectField } from 'store/Masters/Law/selector';
import { makeLawDocumentSelectField, selectSortedDocumentTypeList } from 'store/LawDocument/selector';
import __t from 'i18n/translator';
import { definitionTableColumns } from 'app/ComplianceLibrary/ViewLawsCompliances/CommonFunctions';
import { documentTableColumns } from 'app/ComplianceLibrary/ViewLawsCompliances/CommonFunctions';
import AuditLogModal from './AuditLogModal';
import DefinitionForm from 'app/ComplianceLibrary/ViewLawsCompliances/DefinitionForm';
import DocumentForm from 'app/ComplianceLibrary/ViewLawsCompliances/DocumentForm';
import { isGTROLE } from 'helpers/projectUtils';
import DownloadFileByModel from './DownloadFileByModel';
import { getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({ key: lawComplianceKey, saga: lawComplianceSaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const lawDefinitionKey = 'lawDefinition';
const withLawDefinitionReducer = injectReducer({
    key: lawDefinitionKey,
    reducer: lawDefinitionReducer,
});
const withLawDefinitionSaga = injectSaga({ key: lawDefinitionKey, saga: lawDefinitionSaga });
const lawDocumentKey = 'lawDocument';
const withLawDocumentReducer = injectReducer({
    key: lawDocumentKey,
    reducer: lawDocumentReducer,
});
const withLawDocumentSaga = injectSaga({ key: lawDocumentKey, saga: lawDocumentSaga });
export const complianceTableColumnsTableData = [
    {
        label: 'Code',
        field: 'complianceCode',
    },
    {
        label: 'Section / Reference',
        field: 'sectionReference',
    },
    {
        label: 'Rule / Reference',
        field: 'ruleReference',
    },
    {
        label: 'Compliance Title',
        field: 'complianceTitle',
    },
    {
        label: 'Law Code',
        field: 'lawCode',
    },
    {
        label: 'Short Name of Act/Regulation',
        field: 'lawShortName',
    },
    {
        label: 'Class',
        field: 'complianceTypeName',
    },
    {
        label: 'Classification',
        field: 'complianceCategoryName',
    },
    {
        label: 'Applicability Type',
        field: 'applicabilityCondition',
    },
    {
        label: 'Frequency',
        field: 'periodicityName',
    },
    {
        label: 'View',
        field: 'actions',
        sort: 'disabled',
    },
];
class CommonShowLawDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lawData: {},
            lawId: parseInt(this.props.match.params.lawId),
            complianceData: [],
            documentData: [],
            definitionData: [],
            activeTab: this.getActiveTab(),
            isAuditModalOpen: false,
            auditId: '',
            auditType: '',
            isOpenLawDefinitionModal: false,
            lawDefinitionId: 0,
            lawDefinitionData: {},
            isOpenLawDocumentModal: false,
            lawDocumentId: 0,
            lawDocumentData: {},
            allDefinitionLegalUpdates: [],
            allDocumentLegalUpdates: [],
            downloadFileName: '',
            downloadFileUrl: ''
        };
        this.toggle = this.toggle.bind(this);
    }
    getActiveTab() {
        if (this.props.match.params?.activeTab) {
            if (this.props.match.params?.activeTab === 'definition') {
                return 'definitionsTab';
            } else if (this.props.match.params?.activeTab === 'document') {
                return 'actDocumentTab';
            } else if (this.props.match.params?.activeTab === 'law') {
                return 'basicDetailsTab';
            } else if (this.props.match.params?.activeTab === 'compliance') {
                return 'complianceChecklistTab';
            }
        } else {
            return 'actDocumentTab';
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, () => {
                switch (tab) {
                    case 'basicDetailsTab':
                        this.props.getLawByLawIdRequest(this.state.lawId);
                        break;
                    case 'complianceChecklistTab':
                        this.props.getLawCompliancesByLawIdRequest(this.state.lawId, this.props.displayMode);
                        break;
                    case 'definitionsTab':
                        this.props.getLawDefinitionsByLawIdRequest(this.state.lawId, this.props.displayMode);
                        break;
                    case 'actDocumentTab':
                        this.props.getLawDocumentsByLawIdRequest(this.state.lawId, this.props.displayMode);
                        break;
                    default:
                        break;
                }
            });
        }
    }
    componentDidMount() {
        this.props.getLawByLawIdRequest(this.state.lawId);
        this.props.getAllDocumentTypeListRequest();
        if (this.state.activeTab === 'definitionsTab') {
            this.props.getLawDefinitionsByLawIdRequest(this.state.lawId, this.props.displayMode);
        }
        if (this.state.activeTab === 'actDocumentTab') {
            this.props.getLawDocumentsByLawIdRequest(this.state.lawId, this.props.displayMode);
        }
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    getShowMoreTextElement = (element, lines = 2) => {
        return (
            <ShowMoreText
                lines={lines}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    generateComplianceTable = records => {
        let complianceListDataRecords = [];
        complianceListDataRecords = records.map((data, i) => {
            let actions = (
                <a
                    href={() => false}
                    className='btn btn-link tbl-action text-success'
                    onClick={() => {
                        window.open(
                            '/statutory-compliances/view-details/' +
                            data.id,
                            '_blank'
                        );
                    }}>
                    <i className='fas fa-eye'></i>
                </a>
            );
            return {
                complianceCode: data?.complianceCode,
                sectionReference: data.sectionReference,
                ruleReference: data?.ruleReference,
                complianceTitle: data.complianceTitle,
                lawCode: (data.law) ? data.law.lawCode : '',
                lawShortName: (data.law) ? data.law.lawShortName : '',
                complianceTypeName: data.complianceType?.complianceTypeName,
                complianceCategoryName: data.complianceCategory?.complianceCategoryName,
                applicabilityCondition: data.applicabilityConditionName,
                periodicityName: data.periodicity?.periodicityName,
                actions: actions
            }
        });
        return complianceListDataRecords;
    }
    generateDefinitionTable = (records) => {
        let definitionListDataRecords = [];
        definitionListDataRecords = records.map((record, i) => {
            let allDUIds = [];
            let allDNIds = [];
            let pendingLegalUpdates = [];
            let legalUpdateIncluedDids = [];
            pendingLegalUpdates = this.state.allDefinitionLegalUpdates.filter((l) => {
                let dUIds = (l.updatedLawDefinitionIds) ? l.updatedLawDefinitionIds.split(',').map(Number) : [];
                let dNIds = (l.newLawDefinitionIds) ? l.newLawDefinitionIds.split(',').map(Number) : [];
                if (dUIds.includes(record.id) || dNIds.includes(record.id)) {
                    if (dUIds.includes(record.id)) {
                        allDUIds = [...allDUIds, ...dUIds];
                        legalUpdateIncluedDids.push(l.legalUpdateCode);
                    }
                    if (dNIds.includes(record.id)) {
                        allDNIds = [...allDNIds, ...dNIds];
                        legalUpdateIncluedDids.push(l.legalUpdateCode);
                    }
                    return false;
                } else {
                    return true;
                }
            });
            let active = '';
            if (isGTROLE() === true) {
                if (record?.isActive === true) {
                    active = <Badge color='success' pill>{ACTIVE_LABEL}</Badge>
                }
                if (record?.isActive === false) {
                    active = <Badge color='danger' pill>{INACTIVE_LABEL}</Badge>
                }
            }
            let action = (
                <div className="text-center table-action d-flex">
                    {active}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(record.id, 'lawDefinitions');
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>

                    {pendingLegalUpdates.length > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.add_version')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => {
                                this.openLawDefinitionModal('edit', record.id, record);
                            }}
                        >
                            <i className="fas fa-plus"></i>
                        </a>
                    )}
                    {(allDUIds.length > 0 || allDNIds.length > 0) && (
                        <i className='fas fa-check' title={legalUpdateIncluedDids.join(', ')}></i>
                    )}
                </div>
            )
            return {
                terminology: record.terminology,
                section: record.section,
                rule: record.rule,
                definition: this.getShowMoreTextElement(record.definition),
                definitionRemarks: this.getShowMoreTextElement(record.definitionRemarks),
                actions: action
            }
        });
        return definitionListDataRecords;
    }
    generateDocumentsTable = (records) => {
        let documentListDataRecords = [];
        documentListDataRecords = records.map((record, i) => {
            let allDUIds = [];
            let allDNIds = [];
            let legalUpdateIncluedDids = [];
            let pendingLegalUpdates = [];
            pendingLegalUpdates = this.state.allDocumentLegalUpdates.filter((l) => {
                if (l.isDocumentUpdated === 1) {
                    let dUIds = (l.updatedLawDocumentIds) ? l.updatedLawDocumentIds.split(',').map(Number) : [];
                    let dNIds = (l.newLawDocumentIds) ? l.newLawDocumentIds.split(',').map(Number) : [];
                    if (dUIds.includes(record.id) || dNIds.includes(record.id)) {
                        if (dUIds.includes(record.id)) {
                            allDUIds = [...allDUIds, ...dUIds];
                            legalUpdateIncluedDids.push(l.legalUpdateCode);
                        }
                        if (dNIds.includes(record.id)) {
                            allDNIds = [...allDNIds, ...dNIds];
                            legalUpdateIncluedDids.push(l.legalUpdateCode);
                        }
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            });
            let documentNameLink = '';
            if (record.fileUrl != '') {
                documentNameLink = (
                    <a
                        // href={record.fileUrl}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        onClick={() => {
                            this.saveFile({
                                fileName: record.fileName,
                                fileUrl: record.fileUrl
                            })
                        }}
                        className="text-primary btn-link"
                    >
                        {record.documentName}&nbsp;
                        <small>
                            <i className="fas fa-external-link-alt"></i>
                        </small>
                    </a>
                );
            } else {
                documentNameLink = record.documentName
            }
            let active = '';
            if (isGTROLE() === true) {
                if (record?.isActive === true) {
                    active = <Badge color='success' pill>{ACTIVE_LABEL}</Badge>
                }
                if (record?.isActive === false) {
                    active = <Badge color='danger' pill>{INACTIVE_LABEL}</Badge>
                }
            }
            let action = (
                <div className="text-center table-action d-flex">
                    {active}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(record.id, 'lawDocuments');
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>

                    {pendingLegalUpdates.length > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.add_version')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => {
                                this.openLawDocumentModal('edit', record.id, record);
                            }}
                        >
                            <i className="fas fa-plus"></i>
                        </a>
                    )}
                    {(allDUIds.length > 0 || allDNIds.length > 0) && (
                        <i className='fas fa-check' title={legalUpdateIncluedDids.join(', ')}></i>
                    )}
                </div>
            )
            return {
                documentType: record.documentType?.documentTypeName,
                documentName: documentNameLink,
                releaseDate: record.releaseDate,
                effectiveDate: record.effectiveDate,
                referenceNo: record.referenceNo,
                documentDescription: this.getShowMoreTextElement(record.documentDescription),
                briefNote: this.getShowMoreTextElement(record.briefNote),
                actions: action
            }
        });
        return documentListDataRecords;
    }
    openAuditLogModal = (auditId, auditType = '') => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
            auditType: auditType
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
            auditType: ''
        });
    };
    openLawDefinitionModal = (type = 'add', lawDefinitionId = 0, lawDefinitionData = {}) => {
        this.setState({
            isOpenLawDefinitionModal: true,
            lawDefinitionId: lawDefinitionId,
            lawDefinitionData: lawDefinitionData
        })
    }
    closeLawDefinitionModal = (refreshList) => {
        this.setState({
            isOpenLawDefinitionModal: false,
            lawDefinitionId: 0,
            lawDefinitionData: {}
        }, () => {
            if (refreshList) {
                this.props.getLawDefinitionsByLawIdRequest(this.state.lawId);
            }
        });
    }
    openLawDocumentModal = (type = 'add', lawDocumentId = 0, lawDocumentData = {}) => {
        if (type !== 'add') {
            let effective_date = lawDocumentData.effectiveDate ? getDateObjectWithValueFromMoment(lawDocumentData.effectiveDate) : null;
            let release_date = lawDocumentData.effectiveDate ? getDateObjectWithValueFromMoment(lawDocumentData.effectiveDate) : null;
            lawDocumentData = { ...lawDocumentData, effectiveDate: effective_date, releaseDate: release_date }
        }
        this.setState({
            isOpenLawDocumentModal: true,
            lawDocumentId: lawDocumentId,
            lawDocumentData: lawDocumentData
        })
    }
    closeLawDocumentModal = (refreshList) => {
        this.setState({
            isOpenLawDocumentModal: false,
            lawDocumentId: 0,
            lawDocumentData: {}
        }, () => {
            if (refreshList) {
                this.props.getLawDocumentsByLawIdRequest(this.state.lawId);
            }
        });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.lawDetailsApiResponse && this.props.lawDetailsApiResponse !== nextprops.lawDetailsApiResponse) {
            if (nextprops.lawDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    lawData: nextprops.lawDetailsApiResponse.data.law,
                });
            } else {
                toastrMessage(nextprops.lawDetailsApiResponse.message, nextprops.lawDetailsApiResponse.responseType);
            }
        }
        if (nextprops.lawComplianceList && this.props.lawComplianceList !== nextprops.lawComplianceList) {
            this.setState({
                complianceData: this.generateComplianceTable(nextprops.lawComplianceList)
            });
        }
        if (nextprops.lawDefinitionsListResponse && this.props.lawDefinitionsListResponse !== nextprops.lawDefinitionsListResponse) {
            this.setState({
                allDefinitionLegalUpdates: nextprops.lawDefinitionsListResponse.legalUpdateList
            }, () => {
                this.setState({
                    definitionData: this.generateDefinitionTable(nextprops.lawDefinitionsListResponse.lawDefinitionsList)
                })
            });
        }
        if (nextprops.lawDocumentsListResponse && this.props.lawDocumentsListResponse !== nextprops.lawDocumentsListResponse) {
            this.setState({
                allDocumentLegalUpdates: nextprops.lawDocumentsListResponse.legalUpdateList
            }, () => {
                this.setState({
                    documentData: this.generateDocumentsTable(nextprops.lawDocumentsListResponse.lawDocumentList)
                })
            });
        }
    }
    render() {
        const complianceTableData = {
            columns: complianceTableColumnsTableData,
            rows: this.state.complianceData,
        };
        const definitionTableData = {
            columns: definitionTableColumns,
            rows: this.state.definitionData,
        };
        const documentTableData = {
            columns: documentTableColumns,
            rows: this.state.documentData,
        };
        return (
            <React.Fragment>
                {this.state.lawData?.lawCode && (
                    <div className='law_show'>
                        <div className='page-header mb-3'>
                            <h4>
                                {this.props.history.length != 1 && (
                                    <a
                                        href={() => false}
                                        className="dripicons-arrow-thin-left"
                                        onClick={() => {
                                            this.props.history.goBack()
                                        }}
                                    >
                                        { }
                                    </a>
                                )}
                                <span>&nbsp;{this.state.lawData?.lawCode + ' - ' + this.state.lawData?.lawShortName}</span>
                            </h4>
                        </div>
                        <div className='compliance-view-main h-100'>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === 'basicDetailsTab' })}
                                        onClick={() => { this.toggle('basicDetailsTab'); }} >
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">{__t('form_labels.statutory.details.legislation_details')}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === 'complianceChecklistTab' })}
                                        onClick={() => { this.toggle('complianceChecklistTab'); }} >
                                        <span className="d-block d-sm-none"><i className="fas fa-user"></i></span>
                                        <span className="d-none d-sm-block">{__t('form_labels.statutory.details.compliance_details')}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === 'definitionsTab' })}
                                        onClick={() => { this.toggle('definitionsTab'); }}>
                                        <span className="d-block d-sm-none"><i className="fas fa-envelope"></i></span>
                                        <span className="d-none d-sm-block">{__t('form_labels.statutory.details.definitions')}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === 'actDocumentTab' })}
                                        onClick={() => { this.toggle('actDocumentTab'); }}>
                                        <span className="d-block d-sm-none"><i className="fas fa-envelope"></i></span>
                                        <span className="d-none d-sm-block">{__t('form_labels.statutory.details.related_documents')}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab} >
                                <TabPane tabId="basicDetailsTab">
                                    <Card>
                                        <CardBody>
                                            <div className='compliance-basic-details details-view law-detail-view'>
                                                <div className='compliance-card'>
                                                    <h3>Law Details</h3>
                                                    <div className='compliance-details'>
                                                        <ul className='basic-details'>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.country')}</b>
                                                                    <p>{this.state.lawData?.country?.countryName}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.state')}</b>
                                                                    <p>{(this.state.lawData?.state?.stateName) ? this.state.lawData?.state?.stateName : NA}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.city')}</b>
                                                                    <p>{(this.state.lawData?.city?.length > 0) ? this.state.lawData?.city?.map(opt => opt.cityName).join('; ') : NA}</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className='basic-details'>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.law_name')}</b>
                                                                    {this.getShowMoreTextElement(this.state.lawData?.lawName)}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.law_short_name')}</b>
                                                                    <p>
                                                                        <a
                                                                            onClick={() => { this.toggle('actDocumentTab'); }}
                                                                            className="text-primary btn-link">{this.state.lawData?.lawShortName}&nbsp;
                                                                            <small>
                                                                                <i className="fas fa-external-link-alt"></i>
                                                                            </small>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.effective_date')}</b>
                                                                    <p>{this.state.lawData?.effectiveDate}</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className='basic-details'>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.law_category')}</b>
                                                                    <p>{this.state.lawData?.lawCategory?.lawCategoryName}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.nature_applicability')}</b>
                                                                    <p>{this.state.lawData?.natureApplicabilityName}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.applicability_details')}</b>
                                                                    <p>{this.state.lawData?.applicabilityDetails}</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='compliance-card'>
                                                    <h3>More Details on Applicability of Law</h3>
                                                    <div className='compliance-details'>
                                                        <ul className='basic-details'>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.entity_type')}</b>
                                                                    <p>{this.state.lawData?.entityType?.map(item => item.entityTypeName).join('; ')}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.facility_type')}</b>
                                                                    <p>{this.state.lawData?.facilityType?.map(item => item.facilityTypeName).join('; ')}</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className='basic-details'>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.industry')}</b>
                                                                    <p>{this.getShowMoreTextElement(this.state.lawData?.industry?.map(item => item.industryName).join('; '))}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.sub_industry')}</b>
                                                                    <p>{this.getShowMoreTextElement(this.state.lawData?.subIndustry?.map(item => item.subIndustryName).join('; '))}</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className='basic-details'>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.governing_authority_details')}</b>
                                                                    <p>{(this.state.lawData?.governingAuthorityDetails) ? this.getShowMoreTextElement(this.state.lawData?.governingAuthorityDetails) : NA}</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.weblink')}</b>
                                                                    <a href={this.state.lawData.weblink} className="btn btn-primary" target="_blank" rel="noopener noreferrer"><i className='ion ion-ios-link'></i>{' Link '}</a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className='basic-details'>
                                                            <li>
                                                                <div className='details-info'>
                                                                    <b>{__t('form_labels.statutory.details.exemptions')}</b>
                                                                    <p>{(this.state.lawData?.exemptions) ? this.getShowMoreTextElement(this.state.lawData?.exemptions) : NA}</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="complianceChecklistTab">
                                    <Card className='overflow-hidden'>
                                        <CardBody>
                                            <CustomisedDatatable responsive striped
                                                tableRecords={complianceTableData}
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="definitionsTab">
                                    <Card className='overflow-hidden'>
                                        <CardBody>
                                            {this.state.allDefinitionLegalUpdates.length > 0 && (
                                                <Button
                                                    type="button"
                                                    color='primary'
                                                    className='btn-in-table'
                                                    onClick={() => {
                                                        this.openLawDefinitionModal('add', 0, {});
                                                    }}
                                                >
                                                    <i class="fas fa-plus"></i> {ADD_NEW}
                                                </Button>
                                            )}
                                            <CustomisedDatatable
                                                tableRecords={definitionTableData}
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="actDocumentTab">
                                    <Card className='overflow-hidden'>
                                        <CardBody>
                                            {this.state.allDocumentLegalUpdates.length > 0 && (
                                                <Button
                                                    type="button"
                                                    color='primary'
                                                    className='btn-in-table'
                                                    onClick={() => {
                                                        this.openLawDocumentModal('add', 0, {});
                                                    }}
                                                >
                                                    <i class="fas fa-plus"></i> {ADD_NEW}
                                                </Button>
                                            )}
                                            <CustomisedDatatable
                                                tableRecords={documentTableData}
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                )}
                <AuditLogModal
                    exportBtn={false}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={this.state.auditType}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
                <DownloadFileByModel
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
                <DefinitionForm
                    isModalOpen={this.state.isOpenLawDefinitionModal}
                    lawDefinitionId={this.state.lawDefinitionId}
                    lawDefinitionData={this.state.lawDefinitionData}
                    onModalDismiss={(refreshList) => this.closeLawDefinitionModal(refreshList)}
                    lawId={this.state.lawId}
                />
                <DocumentForm
                    isModalOpen={this.state.isOpenLawDocumentModal}
                    lawDocumentId={this.state.lawDocumentId}
                    lawDocumentData={this.state.lawDocumentData}
                    sortedDocumentTypeListDropdown={this.props.sortedDocumentTypeListDropdown}
                    onModalDismiss={(refreshList) => this.closeLawDocumentModal(refreshList)}
                    lawId={this.state.lawId}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawDetailsApiResponse: makeLawSelectField('lawDetailsApiResponse'),
    lawComplianceList: makeLawComplianceSelectField('lawComplianceList'),
    lawDefinitionsListResponse: makeLawDefinitionSelectField('lawDefinitionsListResponse'),
    lawDocumentsListResponse: makeLawDocumentSelectField('lawDocumentsListResponse'),
    lawComplianceDetailsApiResponse: makeLawComplianceSelectField('lawComplianceDetailsApiResponse'),
    lawComplianceStatusUpdateApiResponse: makeLawComplianceSelectField('lawComplianceStatusUpdateApiResponse'),
    sortedDocumentTypeListDropdown: selectSortedDocumentTypeList('documentTypeList')
});
const withConnect = connect(mapStatetoProps, {
    getLawByLawIdRequest,
    getLawCompliancesByLawIdRequest,
    getLawDocumentsByLawIdRequest,
    getLawDefinitionsByLawIdRequest,
    getLawComplianceDetailsByLawComplianceIdRequest,
    updateLawComplianceStatusByLawComplianceIdRequest,
    getAllDocumentTypeListRequest
});
export default compose(
    withConnect,
    withLawReducer,
    withLawSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withLawDocumentReducer,
    withLawDocumentSaga,
    withLawDefinitionReducer,
    withLawDefinitionSaga,
    withRouter
)(CommonShowLawDetails);