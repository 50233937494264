export const IMPORT_FILE_BY_TYPE_REQUEST = 'import_file_by_type_request';
export const IMPORT_FILE_BY_TYPE_RESPONSE = 'import_file_by_type_response';
export const GET_IMPORT_LOGS_LIST_BY_TYPE_REQUEST =
    'get_import_logs_list_by_type_request';
export const GET_IMPORT_LOGS_LIST_BY_TYPE_RESPONSE =
    'get_import_logs_list_by_type_response';

export const GET_FILING_TYPES_REQUEST = 'get_filing_types_request';
export const GET_FILING_TYPES_RESPONSE = 'get_filing_types_response';

export const GET_MASTER_SAMPLE_REQUEST = 'get_master_sample_request';
export const GET_MASTER_SAMPLE_RESPONSE = 'get_master_sample_response';
