import { ADD_STATE_REQUEST, ADD_STATE_RESPONSE, DELETE_STATE_BY_STATE_ID_REQUEST, DELETE_STATE_BY_STATE_ID_RESPONSE, GET_ALL_STATE_LIST_REQUEST, GET_ALL_STATE_LIST_RESPONSE, GET_STATE_BY_STATE_ID_REQUEST, GET_STATE_BY_STATE_ID_RESPONSE, GET_STATE_LIST_BY_COUNTRY_REQUEST, GET_STATE_LIST_BY_COUNTRY_RESPONSE, GET_STATE_LIST_BY_STATUS_REQUEST, GET_STATE_LIST_BY_STATUS_RESPONSE, UPDATE_STATE_REQUEST, UPDATE_STATE_RESPONSE, UPDATE_STATE_STATUS_BY_STATE_ID_REQUEST, UPDATE_STATE_STATUS_BY_STATE_ID_RESPONSE } from "./actionTypes";
export const addStateRequest = data => {
    return {
        type: ADD_STATE_REQUEST,
        payload: { data },
    };
};
export const addStateResponse = (message, responseType) => {
    return {
        type: ADD_STATE_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllStateListRequest = () => {
    return {
        type: GET_ALL_STATE_LIST_REQUEST
    };
};
export const getAllStateListResponse = stateList => {
    return {
        type: GET_ALL_STATE_LIST_RESPONSE,
        payload: { stateList },
    };
};
export const getStateByStateIdRequest = (stateId) => {
    return {
        type: GET_STATE_BY_STATE_ID_REQUEST,
        payload: { stateId: stateId },
    };
};
export const getStateByStateIdResponse = (message, responseType, data) => {
    return {
        type: GET_STATE_BY_STATE_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateStateRequest = (stateId, data) => {
    return {
        type: UPDATE_STATE_REQUEST,
        payload: { stateId, data },
    };
};
export const updateStateResponse = (message, responseType) => {
    return {
        type: UPDATE_STATE_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateStateStatusByStateIdRequest = (stateId, status) => {
    return {
        type: UPDATE_STATE_STATUS_BY_STATE_ID_REQUEST,
        payload: { stateId, status },
    };
};
export const updateStateStatusByStateIdResponse = (message, responseType) => {
    return {
        type: UPDATE_STATE_STATUS_BY_STATE_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getStateListByStatusRequest = status => {
    return {
        type: GET_STATE_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getStateListByStatusResponse = stateList => {
    return {
        type: GET_STATE_LIST_BY_STATUS_RESPONSE,
        payload: { stateList },
    };
};
export const deleteStateByStateIdRequest = (stateId) => {
    return {
        type: DELETE_STATE_BY_STATE_ID_REQUEST,
        payload: { stateId },
    };
};
export const deleteStateByStateIdResponse = (message, responseType) => {
    return {
        type: DELETE_STATE_BY_STATE_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getStateListByCountryRequest = countryIds => {
    return {
        type: GET_STATE_LIST_BY_COUNTRY_REQUEST,
        payload: { countryIds },
    };
};
export const getStateListByCountryResponse = stateList => {
    return {
        type: GET_STATE_LIST_BY_COUNTRY_RESPONSE,
        payload: { stateList },
    };
};