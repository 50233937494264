import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_EVENT_REQUEST, DELETE_EVENT_BY_EVENT_ID_REQUEST, GET_ALL_EVENT_LIST_REQUEST, GET_EVENT_BY_EVENT_ID_REQUEST, GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_REQUEST, GET_EVENT_LIST_BY_SEARCH_REQUEST, GET_EVENT_LIST_BY_STATUS_REQUEST, GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_REQUEST, UPDATE_EVENT_ORDER_BY_EVENT_ID_REQUEST, UPDATE_EVENT_REQUEST, UPDATE_EVENT_STATUS_BY_EVENT_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addEventResponse, deleteEventByEventIdResponse, getAllEventListRequest, getAllEventListResponse, getEventByEventIdResponse, getEventListByFilterForAllocationResponse, getEventListBySearchResponse, getEventListByStatusResponse, getLawComplianceListByEventIdResponse, updateEventOrderByEventIdResponse, updateEventResponse, updateEventStatusByEventIdResponse } from './actions';
import { EVENT, EVENT_BY_FILTER_FOR_ALLOCATION, EVENT_BY_SEARCH, EVENT_BY_STATUS, EVENT_LAW_COMPLIANCE_LIST, EVENT_ORDER_UPDATE } from 'helpers/apiConstants';
function* addEvent({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', EVENT, data);
        if (response.status) {
            yield put(getAllEventListRequest());
            yield put(addEventResponse(response.message, SUCCESS));
        } else {
            yield put(addEventResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllEventList() {
    try {
        const response = yield call(invokeApi, 'get', EVENT, '');
        if (response.status) {
            yield put(getAllEventListResponse(response.data.list));
        } else {
            yield put(getAllEventListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEventByEventId({ payload: { eventId } }) {
    try {
        const response = yield call(invokeApi, 'get', EVENT + '/' + eventId, '');
        if (response.status) {
            yield put(getEventByEventIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getEventByEventIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateEvent({ payload: { eventId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', EVENT + '/' + eventId, data);
        if (response.status) {
            yield put(getAllEventListRequest());
            yield put(updateEventResponse(response.message, SUCCESS));
        } else {
            yield put(updateEventResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateEventStatusByEventId({ payload: { eventId, status } }) {
    try {
        const response = yield call(invokeApi, 'patch', EVENT + '/' + eventId + '/status/' + status);
        if (response.status) {
            yield put(getAllEventListRequest());
            yield put(updateEventStatusByEventIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateEventStatusByEventIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEventListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', EVENT_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getEventListByStatusResponse(response.data.list));
        } else {
            yield put(getEventListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteEventByEventId({ payload: { eventId } }) {
    try {
        const response = yield call(invokeApi, 'delete', EVENT + '/' + eventId);
        if (response.status) {
            yield put(getAllEventListRequest());
            yield put(deleteEventByEventIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteEventByEventIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEventListBySearch({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', EVENT_BY_SEARCH, data);
        if (response.status) {
            yield put(getEventListBySearchResponse(response.data.list));
        } else {
            yield put(getEventListBySearchResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEventListByFilterForAllocation({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', EVENT_BY_FILTER_FOR_ALLOCATION, data);
        if (response.status) {
            yield put(getEventListByFilterForAllocationResponse(response.data.list));
        } else {
            yield put(getEventListByFilterForAllocationResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawComplianceListByEventId({ payload: { eventId } }) {
    try {
        const response = yield call(invokeApi, 'get', EVENT_LAW_COMPLIANCE_LIST + '/' + eventId, '');
        if (response.status) {
            yield put(getLawComplianceListByEventIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getLawComplianceListByEventIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateEventOrderByEventId({ payload: { eventId, data } }) {
    try {
        const response = yield call(invokeApi, 'post', EVENT_ORDER_UPDATE + '/' + eventId, data);
        if (response.status) {
            yield put(updateEventOrderByEventIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateEventOrderByEventIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchEvent() {
    yield takeEvery(ADD_EVENT_REQUEST, addEvent);
    yield takeEvery(GET_ALL_EVENT_LIST_REQUEST, getAllEventList);
    yield takeEvery(GET_EVENT_BY_EVENT_ID_REQUEST, getEventByEventId);
    yield takeEvery(UPDATE_EVENT_REQUEST, updateEvent);
    yield takeEvery(UPDATE_EVENT_STATUS_BY_EVENT_ID_REQUEST, updateEventStatusByEventId);
    yield takeEvery(GET_EVENT_LIST_BY_STATUS_REQUEST, getEventListByStatus);
    yield takeEvery(DELETE_EVENT_BY_EVENT_ID_REQUEST, deleteEventByEventId);
    yield takeEvery(GET_EVENT_LIST_BY_SEARCH_REQUEST, getEventListBySearch);
    yield takeEvery(GET_EVENT_LIST_BY_FILTER_FOR_ALLOCATION_REQUEST, getEventListByFilterForAllocation);
    yield takeEvery(GET_LAW_COMPLIANCE_LIST_BY_EVENT_ID_REQUEST, getLawComplianceListByEventId);
    yield takeEvery(UPDATE_EVENT_ORDER_BY_EVENT_ID_REQUEST, updateEventOrderByEventId);
}
function* eventSaga() {
    yield all([fork(watchEvent)]);
}
export default eventSaga;