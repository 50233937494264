import React, { Component } from 'react';
import {
    Card,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Col,
    Row,
    FormGroup,
    Label,
    CardBody,
    Badge,
    Button,
    Input,
    CardHeader,
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getFundListRequest,
    updateEntityStatusByEntityIdRequest,
    deleteFundByFundIdRequest,
    retrieveFundByFundIdRequest,
    activeOrDeactiveFundRequest,
    closeFundRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getFundMasterListByStatusRequest,
    getFundHealthCheckRequest,
    getFundCheckAccessRequest
} from 'store/actions';
import DatePicker from 'react-datepicker';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { makeFundsSelectField } from 'store/Masters/Funds/selector';
import fundsReducer from 'store/Masters/Funds/reducer';
import FundsSaga from 'store/Masters/Funds/saga';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { ACTIVE, DATE_FORMAT_DATEPICKER } from 'constants/commonConstants';
import { selectSortedEntityList } from 'store/Entity/selector';
import { selectSortedFundMasterList } from 'store/Masters/FundMaster/selector';
import fundMasterReducer from 'store/Masters/FundMaster/reducer';
import fundMasterSaga from 'store/Masters/FundMaster/saga';
import CountUp from 'react-countup';
import { isTenantAdminCompanyGroupAdminEntityAdminRole, isTenantAdminRole } from 'helpers/projectUtils';
import { formatDateForApi, formatDateForYearApi, getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import FundEntityAnalysis from './FundEntityAnalysis';
import Switch from 'react-switch';

const fundsKey = 'funds';
const withFundsReducer = injectReducer({
    key: fundsKey,
    reducer: fundsReducer,
});
const withFundsSaga = injectSaga({
    key: fundsKey,
    saga: FundsSaga,
});
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const fundMasterKey = 'fundMaster';
const withFundMasterReducer = injectReducer({
    key: fundMasterKey,
    reducer: fundMasterReducer,
});
const withFundMasterSaga = injectSaga({
    key: fundMasterKey,
    saga: fundMasterSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenProjectId: '',
            isOpenFilter: false,
            activeTab: 'fundHealthCheckTab',
            allFundList: [],
            isAuditModalOpen: false,
            auditId: '',
            fundId: '',
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            filterEntityId: '',
            filterFundId: '',
            roleType: 'both',
            fundHealthCheckData: {
                totalProjects: 0,
                inProgressProjects: 0,
                deactivatedProjects: 0,
                completedProjects: 0
            },
            roleDropdownListData: [],
            fundHealthCheckTabEnabled: false,
            initialFilterValues: {
                entityId: [],
                fundMasterId: [],
                fromDate: '',
                toDate: '',
                year: '',
            }
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        this.props.getFundCheckAccessRequest({});
        this.getFundHealthCheck();
        this.getFundList();
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getFundMasterListByStatusRequest(ACTIVE);
    }
    getFundList = () => {
        this.props.getFundListRequest({
            filter: {
                entityId: this.state.filterEntityId,
                fundMasterId: this.state.filterFundId
            }
        });
    }
    getFundHealthCheck = () => {
        this.props.getFundHealthCheckRequest({
            filter: {
                roleType: this.state.roleType
            }
        });
    }
    editFundDetails = (record) => {
        this.props.history.push('/funds/manage/edit/' + record.id);
    };
    fundDetails = (record) => {
        this.props.history.push('/funds/manage/details/' + record.id);
    };
    addFundDetails = () => {
        this.props.history.push('/funds/manage/add');
    };
    deleteFund = async (fundId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.fund_delete')
        );
        if (response) {
            this.props.deleteFundByFundIdRequest(fundId);
        }
    };
    retrieveFund = async (fundId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.fund_retrieve')
        );
        if (response) {
            this.props.retrieveFundByFundIdRequest(fundId);
        }
    };
    onStatusChange = async (checked, e, id) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.fund_status')
        );
        if (response) {
            this.props.activeOrDeactiveFundRequest(id, { isActive: checked });
        }
    }
    closeFund = async (fundId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.fund_close')
        );
        if (response) {
            this.props.closeFundRequest(fundId);
        }
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let fundRecords = [];
        fundRecords = records.map((fundData, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title={__t('common.edit')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => this.editFundDetails(fundData)}
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteFund(fundData.id);
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(fundData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            return {
                title: fundData.title,
                companyGroupName: fundData.companyGroup?.companyGroupName,
                startDate: fundData.startDate,
                endDate: fundData.endDate != '' ? fundData.endDate : '-',
                description: fundData.description,
                isActive: fundData.isActive ? 'Yes' : ' No',
                isClosed: fundData.isClosed ? 'Yes' : ' No',
                actions: actions,
            };
        });
        return fundRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.fundAccessCheckApiResponse && this.props.fundAccessCheckApiResponse !== nextprops.fundAccessCheckApiResponse) {
            this.setState({
                fundHealthCheckTabEnabled: (nextprops.fundAccessCheckApiResponse.data?.isOwner == true || nextprops.fundAccessCheckApiResponse.data?.isManagement == true || isTenantAdminRole() === true)
            })
        }
        if (nextprops.fundList && this.props.fundList !== nextprops.fundList) {
            this.setState({
                allFundList: nextprops.fundList
            });
        }
        if (
            nextprops.deleteFundApiResponse &&
            this.props.deleteFundApiResponse !==
            nextprops.deleteFundApiResponse
        ) {
            toastrMessage(
                nextprops.deleteFundApiResponse.message,
                nextprops.deleteFundApiResponse.responseType
            );
        }
        if (
            nextprops.retrieveFundApiResponse &&
            this.props.retrieveFundApiResponse !==
            nextprops.retrieveFundApiResponse
        ) {
            toastrMessage(
                nextprops.retrieveFundApiResponse.message,
                nextprops.retrieveFundApiResponse.responseType
            );
        }
        if (
            nextprops.closeFundApiResoponse &&
            this.props.closeFundApiResoponse !==
            nextprops.closeFundApiResoponse
        ) {
            toastrMessage(
                nextprops.closeFundApiResoponse.message,
                nextprops.closeFundApiResoponse.responseType
            );
            this.props.getFundListRequest();
        }
        if (
            nextprops.activeOrDeactiveFundApiResponse &&
            this.props.activeOrDeactiveFundApiResponse !==
            nextprops.activeOrDeactiveFundApiResponse
        ) {
            toastrMessage(
                nextprops.activeOrDeactiveFundApiResponse.message,
                nextprops.activeOrDeactiveFundApiResponse.responseType
            );
        }
        if (nextprops.sortedCompanyGroupListDropdown && this.props.sortedCompanyGroupListDropdown !== nextprops.sortedCompanyGroupListDropdown) {
            if (nextprops.sortedCompanyGroupListDropdown[0] && nextprops.sortedCompanyGroupListDropdown[0].value) {
                this.props.getEntityListByCompanyGroupIdRequest([nextprops.sortedCompanyGroupListDropdown[0].value]);
            }
        }
        if (nextprops.fundHealthCheckApiResponse && this.props.fundHealthCheckApiResponse !== nextprops.fundHealthCheckApiResponse) {
            const roleDropdownListData = [];
            const roleListOfFundsData = nextprops.fundHealthCheckApiResponse.data?.roleListOfFunds;
            if (roleListOfFundsData) {
                for (let i = 0; i < roleListOfFundsData.length; i++) {
                    const fundTeam = roleListOfFundsData[i];

                    if (fundTeam.isOwner === true) {
                        roleDropdownListData.push({ value: 'owner', label: 'Owner' });
                    }
                    if (fundTeam.isManagement === true) {
                        roleDropdownListData.push({ value: 'management', label: 'Management' });
                    }
                }
            }

            const roleDropdownList = roleDropdownListData.filter((obj, index) => {
                return index === roleDropdownListData.findIndex(o => obj.value === o.value);
            });
            this.setState({
                roleDropdownListData: roleDropdownList,
                fundHealthCheckData: {
                    totalProjects: nextprops.fundHealthCheckApiResponse.data?.dataCount?.totalProjects,
                    inProgressProjects: nextprops.fundHealthCheckApiResponse.data?.dataCount?.inProgressProjects,
                    deactivatedProjects: nextprops.fundHealthCheckApiResponse.data?.dataCount?.deactivatedProjects,
                    completedProjects: nextprops.fundHealthCheckApiResponse.data?.dataCount?.completedProjects,
                }
            })
        }

    }
    searchText(e) {
        let formValues = {
            searchText: e.target.value,
            entityId: this.state.filterEntityId,
            fundMasterId: this.state.filterFundId
        };
        this.props.getFundListRequest(formValues);
    }
    actionToggle(taskId) {
        this.setState({
            fundId: this.state.fundId === taskId ? 0 : taskId,
        });
    }
    formSubmit = (values) => {
        this.props.getFundListRequest({
            filter: {
                entityId: values.entityId,
                fundMasterId: values.fundMasterId,
                fromDate: values.fromDate ? formatDateForApi(values.fromDate) : null,
                toDate: values?.toDate
                    ? formatDateForApi(values.toDate)
                    : null,
                year: values?.year ? formatDateForYearApi(values?.year) : null,
            }
        });

        this.setState({
            filterFundId: values.fundMasterId ? values.fundMasterId : [],
            filterEntityId: values.entityId ? values.entityId : []
        })
    }
    render() {
        const currentYear = new Date().getFullYear();
        const { isOpenFilter } = this.state;
        return (
            <React.Fragment>
                <div className='fund_main_row project_list_row'>
                    <div className="common-header">
                        <div className="common-flex-box">
                            <div className="page-header">
                                <h4>{__t('page_headers.funds_list')}</h4>
                            </div>
                            <div className="common-flex-box">
                                {isTenantAdminCompanyGroupAdminEntityAdminRole() == true && (
                                    <a
                                        href={() => false}
                                        className="btn btn-primary btn-sm py-1 px-2 waves-effect waves-light float-right ml-2"
                                        onClick={() => {
                                            this.props.history.push('/funds/master')
                                        }}
                                    >
                                        <i className="fas fa-list"> </i> {__t('form_labels.funds.fund_master')}
                                    </a>
                                )}
                                {isTenantAdminCompanyGroupAdminEntityAdminRole() == true && (
                                    <a
                                        href={() => false}
                                        className="btn btn-info btn-sm py-1 px-2 waves-effect waves-light float-right ml-2"
                                        onClick={() => {
                                            this.addFundDetails();
                                        }}
                                    >
                                        <i className="fas fa-plus"> </i>  {__t('form_labels.funds.fund_setup')}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div fluid className="support_tickets">
                        <Row className='support-head'>
                            <Col md="12" lg="6" xl="7" className="filter_col">
                                <ul className="list-group">
                                    <li className='list-group-item bg-primary'>
                                        <strong>
                                            <CountUp duration="2.5" end={this.state.fundHealthCheckData?.totalProjects} />
                                        </strong>
                                        Total Projects
                                    </li>
                                    <li className='list-group-item bg-info'>
                                        <strong>
                                            <CountUp duration="2.5" end={this.state.fundHealthCheckData?.inProgressProjects}
                                            />
                                        </strong>
                                        On-Going Projects
                                    </li>
                                    <li className='list-group-item bg-danger'>
                                        <div>
                                            <strong>
                                                <CountUp duration="2.5"
                                                    end={
                                                        this.state
                                                            .fundHealthCheckData?.deactivatedProjects
                                                    }
                                                />
                                            </strong>
                                            Deactivated Projects
                                        </div>
                                    </li>
                                    <li className='list-group-item bg-warning'>
                                        <div>
                                            <strong>
                                                <CountUp duration="2.5"
                                                    end={
                                                        this.state
                                                            .fundHealthCheckData?.completedProjects
                                                    }
                                                />
                                            </strong>
                                            Closed Projects
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                            <Col md="12" lg="6" xl="5" className='d-flex align-items-center justify-content-end'>
                                <div className="fund_tabs card-box">
                                    {this.state.activeTab == 'fundHealthCheckTab' && this.state.fundHealthCheckTabEnabled == true && this.state.roleDropdownListData.length > 0 && (
                                        <div className='fund-who'>
                                            <FormGroup className='mb-0'>
                                                <Select
                                                    name="roleType"
                                                    className="form-control selectbox"
                                                    options={
                                                        this.state
                                                            .roleDropdownListData
                                                    }
                                                    onChange={(opt) => {
                                                        this.setState({
                                                            roleType: opt.value
                                                        }, () => {
                                                            this.getFundList();
                                                        })
                                                    }}
                                                    defaultValue={this.state.roleType}
                                                    isClearable
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.funds.who_am_i'
                                                    )}{' '}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    )}
                                </div>
                                <div className="search_box mr-3">
                                    <Input
                                        type="text"
                                        className="form-control pl-4"
                                        placeholder="Search"
                                        onChange={(e) => this.searchText(e)}
                                    />
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z" fill="currentColor" /></svg>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='project-details-box support_tickets'>
                        <div className={`search-form ${isOpenFilter ? 'filter-open' : 'filter-close'}`}>
                            <Button type='button' color={`${isOpenFilter ? 'link' : 'primary'}`} title='Filter' onClick={() => {
                                this.setState({ isOpenFilter: !this.state.isOpenFilter })
                            }}>
                                {isOpenFilter ? (
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1L1 13" stroke="currentColor" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" /><path d="M1 1L13 13" stroke="currentColor" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" /></svg>
                                ) : (
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65811 16.7806L6.81622 17.255H6.81622L6.65811 16.7806ZM11.6581 15.114L11.8162 15.5883H11.8162L11.6581 15.114ZM16.7071 4.29289L17.0607 4.64645L16.7071 4.29289ZM12.2929 8.70711L11.9393 8.35356L12.2929 8.70711ZM2 1.5H16V0.5H2V1.5ZM1.5 3.58579V2H0.5V3.58579H1.5ZM6.06065 8.35355L1.64645 3.93934L0.93934 4.64645L5.35355 9.06065L6.06065 8.35355ZM5.49999 9.41421V16.3063H6.49999V9.41421H5.49999ZM5.49999 16.3063C5.49999 16.9888 6.16869 17.4708 6.81622 17.255L6.49999 16.3063V16.3063H5.49999ZM6.81622 17.255L11.8162 15.5883L11.5 14.6396L6.49999 16.3063L6.81622 17.255ZM11.8162 15.5883C12.2246 15.4522 12.5 15.0701 12.5 14.6396H11.5L11.8162 15.5883ZM12.5 14.6396V9.41422H11.5V14.6396H12.5ZM16.3536 3.93934L11.9393 8.35356L12.6464 9.06067L17.0607 4.64645L16.3536 3.93934ZM16.5 2V3.58579H17.5V2H16.5ZM17.0607 4.64645C17.342 4.36514 17.5 3.98361 17.5 3.58579H16.5C16.5 3.71839 16.4473 3.84557 16.3536 3.93934L17.0607 4.64645ZM12.5 9.41422C12.5 9.28161 12.5527 9.15444 12.6464 9.06067L11.9393 8.35356C11.658 8.63486 11.5 9.0164 11.5 9.41422H12.5ZM5.35355 9.06065C5.44731 9.15442 5.49999 9.2816 5.49999 9.41421H6.49999C6.49999 9.01638 6.34196 8.63485 6.06065 8.35355L5.35355 9.06065ZM0.5 3.58579C0.5 3.98361 0.658035 4.36514 0.93934 4.64645L1.64645 3.93934C1.55268 3.84557 1.5 3.71839 1.5 3.58579H0.5ZM16 1.5C16.2761 1.5 16.5 1.72386 16.5 2H17.5C17.5 1.17157 16.8284 0.5 16 0.5V1.5ZM2 0.5C1.17157 0.5 0.5 1.17157 0.5 2H1.5C1.5 1.72386 1.72386 1.5 2 1.5V0.5Z" fill="currentColor" /></svg>
                                )}
                            </Button>
                            {this.state.isOpenFilter && (
                                <Card>
                                    <CardHeader>
                                        <h6>Customised search</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <Formik
                                            initialValues={this.state.initialFilterValues}
                                            enableReinitialize={true}
                                            onSubmit={this.formSubmit}
                                        >
                                            {({ values, setFieldValue }) => (
                                                <Form className="form-row">
                                                    <FormGroup className="col-lg-12">
                                                        <Field
                                                            component={Select}
                                                            name="entityId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedEntityListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'entityId',
                                                                    opt
                                                                        ? opt.map(
                                                                            (x) =>
                                                                                x.value
                                                                        )
                                                                        : []
                                                                );
                                                                setFieldValue(
                                                                    'projectTeams',
                                                                    []
                                                                );
                                                            }}
                                                            value={this.props.sortedEntityListDropdown?.filter(
                                                                (option) =>
                                                                    values.entityId.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti={true}

                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.events.plan.entity'
                                                            )}{' '}

                                                        </Label>
                                                    </FormGroup>
                                                    {/* <FormGroup className="col-lg-12">
                                                        <Field
                                                            component={Select}
                                                            name="fundMasterId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedFundMasterListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'fundMasterId',
                                                                    opt
                                                                        ? opt.map(
                                                                            (x) =>
                                                                                x.value
                                                                        )
                                                                        : []
                                                                );
                                                            }}
                                                            value={this.props.sortedFundMasterListDropdown?.filter(
                                                                (option) =>
                                                                    values.fundMasterId.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.funds.fund'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup> */}
                                                    <FormGroup className="col-lg-12 sticky_label">
                                                        <Field
                                                            name="fromDate"
                                                            component={DatePicker}
                                                            isClearable={true}
                                                            className="form-control"
                                                            placeholderText={__t(
                                                                'form_labels.common.from'
                                                            )}
                                                            selected={
                                                                values.fromDate
                                                                    ? getDateObjectWithValueFromMoment(
                                                                        values.fromDate
                                                                    )
                                                                    : null
                                                            }
                                                            dateFormat={
                                                                DATE_FORMAT_DATEPICKER
                                                            }
                                                            onChange={(date) => {
                                                                setFieldValue(
                                                                    `fromDate`,
                                                                    date
                                                                );

                                                                let dateCheck =
                                                                    getDateObjectWithValueFromMoment(
                                                                        values.toDate
                                                                    ) <
                                                                        getDateObjectWithValueFromMoment(
                                                                            date
                                                                        )
                                                                        ? true
                                                                        : false;
                                                                if (dateCheck) {
                                                                    setFieldValue(
                                                                        'toDate',
                                                                        ''
                                                                    );
                                                                }
                                                            }}

                                                            showMonthDropdown={true}
                                                            showYearDropdown={true}
                                                            dropdownMode="select"
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.common.from'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-12 sticky_label">
                                                        <Field
                                                            name="toDate"
                                                            component={DatePicker}
                                                            isClearable={true}
                                                            className="form-control"
                                                            placeholderText={__t(
                                                                'form_labels.common.to'
                                                            )}
                                                            selected={
                                                                values.toDate
                                                                    ? getDateObjectWithValueFromMoment(
                                                                        values.toDate
                                                                    )
                                                                    : null
                                                            }
                                                            dateFormat={
                                                                DATE_FORMAT_DATEPICKER
                                                            }
                                                            onChange={(date) => {
                                                                setFieldValue(
                                                                    `toDate`,
                                                                    date
                                                                );
                                                            }}
                                                            showMonthDropdown={true}
                                                            showYearDropdown={true}
                                                            dropdownMode="select"
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                            minDate={getDateObjectWithValueFromMoment(
                                                                values?.fromDate
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.common.to'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-12">
                                                        <Field name="year">
                                                            {({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    selected={values.year}
                                                                    onChange={date => setFieldValue('year', date)}
                                                                    showYearPicker
                                                                    dateFormat="yyyy"
                                                                    className="form-control"
                                                                    placeholderText="Select Year"
                                                                    isClearable
                                                                    showMonthDropdown={true}
                                                                    showYearDropdown={true}
                                                                    dropdownMode="select"
                                                                    onFocus={e => e.target.blur()}
                                                                    maxDate={new Date(currentYear, 11, 31)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormGroup>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        block
                                                        className=""
                                                    >
                                                        Search
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            )}
                        </div>
                        <div className="fund-detil-main support_wrapper">
                            <ul className="news_card">
                                <li className='ticket-head'>
                                    <span className='ticket-code'>
                                        Project code
                                    </span>
                                    <span className='ticket-title'>
                                    </span>
                                    <span className='ticket-raised'>
                                        Start date
                                    </span>
                                    <span className='ticket-due'>
                                        End date
                                    </span>
                                    <span className='ticket-tenant'>
                                        Project Status
                                    </span>
                                    {/* <span className='ticket-by'>
                                        Raised By
                                    </span>
                                    <span className='ticket-department'>
                                        Department
                                    </span> */}
                                    <span className='ticket-action'>
                                    </span>
                                </li>
                                {this.state.allFundList?.map((fund, i) => {
                                    let fundOwners = fund.fundTeams.filter((teamUser) => teamUser.userId === this.state.loginUserId && teamUser.isOwner);
                                    let displayFund = true;
                                    // if (fundOwners.length == 0 && fund.isActive === false) {
                                    //     displayFund = false;
                                    // }
                                    return displayFund && (
                                        <>
                                            <li key={'p_' + i} className={fund.isClosed === false ? 'project-pro-list' : 'project-pro-list disabled'}>
                                                <a href={() => false} >
                                                    <span className='ticket-code'>
                                                        <span className='code_td'>#{fund?.code}</span>
                                                    </span>
                                                    <span className='ticket-title' onClick={() => this.fundDetails(fund)} title={fund.title}>
                                                        <strong>{fund.fundShortName}</strong>
                                                    </span>
                                                    <span className='ticket-raised'>
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 11C1 7.22876 1 5.34315 2.17157 4.17157C3.34315 3 5.22876 3 9 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H9C5.22876 21 3.34315 21 2.17157 19.8284C1 18.6569 1 16.7712 1 13V11Z" stroke="currentColor" stroke-width="1.5" /><path d="M6 3V1.5" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" /><path d="M16 3V1.5" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" /><path d="M1.5 8H20.5" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" /><path d="M17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z" fill="currentColor" /><path d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z" fill="currentColor" /><path d="M12 16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15C11.5523 15 12 15.4477 12 16Z" fill="currentColor" /><path d="M12 12C12 12.5523 11.5523 13 11 13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11C11.5523 11 12 11.4477 12 12Z" fill="currentColor" /><path d="M7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16Z" fill="currentColor" /><path d="M7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z" fill="currentColor" /></svg>
                                                        {fund.startDate}
                                                    </span>
                                                    <span className='ticket-due'>
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 11C1 7.22876 1 5.34315 2.17157 4.17157C3.34315 3 5.22876 3 9 3H13C16.7712 3 18.6569 3 19.8284 4.17157C21 5.34315 21 7.22876 21 11V13C21 16.7712 21 18.6569 19.8284 19.8284C18.6569 21 16.7712 21 13 21H9C5.22876 21 3.34315 21 2.17157 19.8284C1 18.6569 1 16.7712 1 13V11Z" stroke="currentColor" stroke-width="1.5" /><path d="M6 3V1.5" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" /><path d="M16 3V1.5" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" /><path d="M1.5 8H20.5" stroke="currentColor" stroke-width="1.5" strokeLinecap="round" /><path d="M17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z" fill="currentColor" /><path d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z" fill="currentColor" /><path d="M12 16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15C11.5523 15 12 15.4477 12 16Z" fill="currentColor" /><path d="M12 12C12 12.5523 11.5523 13 11 13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11C11.5523 11 12 11.4477 12 12Z" fill="currentColor" /><path d="M7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16Z" fill="currentColor" /><path d="M7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z" fill="currentColor" /></svg>
                                                        {fund.endDate}
                                                    </span>
                                                    <span className='ticket-tenant'>
                                                        {fund.isClosed === true ? (
                                                            <span>Closed&nbsp;</span>
                                                        ) : (
                                                            <div className="d-flex">
                                                                <Switch
                                                                    className="switch-sm"
                                                                    onColor="#9BD732"
                                                                    onChange={this.onStatusChange}
                                                                    id={String(fund.id)}
                                                                    checked={fund.isActive ? true : false}
                                                                    disabled={((fundOwners.length > 0) || isTenantAdminRole() === true) ? false : true}
                                                                />
                                                                &nbsp;
                                                            </div>

                                                        )}
                                                    </span>
                                                    <span className='ticket-action'>
                                                    </span>
                                                </a>
                                                <div className={`project-action ${this.state.fundId === fund.id ? 'active' : ''}`}>
                                                    {fund.isClosed === true && isTenantAdminRole() && (
                                                        <a onClick={() => {
                                                            this.retrieveFund(fund.id);
                                                        }}
                                                            className="text-info notify-all"
                                                        >
                                                            <i className="fas fa-undo"></i>{' '}
                                                            Retrieve
                                                        </a>
                                                    )}
                                                    <Button type='button' title='View Entity Details' color='link' className='project-detail-view' onClick={() => {
                                                        this.setState({
                                                            isOpenProjectId: (fund.id !== this.state.isOpenProjectId) ? fund.id : ''
                                                        })
                                                    }}>
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" > <circle cx="10" cy="7" r="3" stroke="currentColor" strokeWidth="1.5" /> <path d="M18.188 5.93429C18.5762 6.40556 18.7703 6.64119 18.7703 7C18.7703 7.35881 18.5762 7.59444 18.188 8.06571C16.7679 9.78986 13.6357 13 10 13C6.36427 13 3.23206 9.78986 1.81197 8.06571C1.42381 7.59444 1.22973 7.35881 1.22973 7C1.22973 6.64119 1.42381 6.40556 1.81197 5.93429C3.23206 4.21014 6.36427 1 10 1C13.6357 1 16.7679 4.21014 18.188 5.93429Z" stroke="currentColor" strokeWidth="1.5" /></svg>
                                                    </Button>
                                                    {fund.isClosed === false && (isTenantAdminRole() == true || (fundOwners.length > 0)) && (
                                                        <Dropdown isOpen={this.state.fundId === fund.id} toggle={() => this.actionToggle(fund.id)} className="more-action" tag="div">
                                                            <DropdownToggle tag="a" title="Action" className="btn btn-link"><svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M16 2C16 1.60444 16.1173 1.21776 16.3371 0.88886C16.5568 0.559962 16.8692 0.303617 17.2346 0.152242C17.6001 0.000866562 18.0022 -0.0387401 18.3902 0.0384303C18.7781 0.115601 19.1345 0.306082 19.4142 0.585787C19.6939 0.865492 19.8844 1.22186 19.9616 1.60982C20.0387 1.99778 19.9991 2.39992 19.8478 2.76537C19.6964 3.13082 19.44 3.44318 19.1111 3.66294C18.7822 3.8827 18.3956 4 18 4C17.4696 4 16.9609 3.78929 16.5858 3.41421C16.2107 3.03914 16 2.53043 16 2V2ZM8 2C8 1.60444 8.1173 1.21776 8.33706 0.88886C8.55682 0.559962 8.86918 0.303617 9.23463 0.152242C9.60008 0.000866562 10.0022 -0.0387401 10.3902 0.0384303C10.7781 0.115601 11.1345 0.306082 11.4142 0.585787C11.6939 0.865492 11.8844 1.22186 11.9616 1.60982C12.0387 1.99778 11.9991 2.39992 11.8478 2.76537C11.6964 3.13082 11.44 3.44318 11.1111 3.66294C10.7822 3.8827 10.3956 4 10 4C9.46957 4 8.96086 3.78929 8.58579 3.41421C8.21071 3.03914 8 2.53043 8 2V2ZM0 2C0 1.60444 0.117298 1.21776 0.337061 0.88886C0.556824 0.559962 0.869181 0.303617 1.23463 0.152242C1.60009 0.000866562 2.00222 -0.0387401 2.39018 0.0384303C2.77814 0.115601 3.13451 0.306082 3.41421 0.585787C3.69392 0.865492 3.8844 1.22186 3.96157 1.60982C4.03874 1.99778 3.99913 2.39992 3.84776 2.76537C3.69638 3.13082 3.44004 3.44318 3.11114 3.66294C2.78224 3.8827 2.39556 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2H0Z" fill="currentColor" /></svg></DropdownToggle>
                                                            <DropdownMenu
                                                                className="dropdown-menu"
                                                                right
                                                            >
                                                                <>
                                                                    {(fundOwners.length > 0) && (
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            onClick={() => {
                                                                                this.props.history.push('/funds/manage/details/' + fund.id);
                                                                            }}
                                                                            className="notify-all"
                                                                        >
                                                                            <i className="fas fa-plus"></i>{' '}
                                                                            Add New Task
                                                                        </DropdownItem>
                                                                    )}
                                                                    {(isTenantAdminRole() == true || (fundOwners.length > 0)) && (
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            onClick={() =>
                                                                                this.editFundDetails(
                                                                                    fund
                                                                                )
                                                                            }
                                                                            className="notify-all"
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>{' '}
                                                                            Edit
                                                                        </DropdownItem>
                                                                    )}
                                                                    {(isTenantAdminRole() == true || (fundOwners.length > 0)) && (
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            onClick={() => {
                                                                                this.closeFund(
                                                                                    fund.id
                                                                                );
                                                                            }}
                                                                            className="notify-all"
                                                                        >
                                                                            <i className="fa fa-times"></i>{' '}
                                                                            Close
                                                                        </DropdownItem>
                                                                    )}
                                                                    {(isTenantAdminRole() == true) && (
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            onClick={() => {
                                                                                this.deleteFund(
                                                                                    fund.id
                                                                                );
                                                                            }}
                                                                            className="notify-all"
                                                                        >
                                                                            <i className="fas fa-trash-alt"></i>{' '}
                                                                            Delete
                                                                        </DropdownItem>
                                                                    )}
                                                                    {(isTenantAdminRole() == true || (fundOwners.length > 0)) && (
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            onClick={() => {
                                                                                this.openAuditLogModal(fund.id);
                                                                            }}
                                                                            className="notify-all"
                                                                        >
                                                                            <i className="fas fa-history"></i>{' '}
                                                                            {__t('common.audit_logs')}
                                                                        </DropdownItem>
                                                                    )}
                                                                </>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    )}
                                                </div>
                                            </li>
                                            {this.state.isOpenProjectId === fund.id && (
                                                <li className='project-detail-item'>
                                                    <FundEntityAnalysis
                                                        fundId={this.state.isOpenProjectId}
                                                    />
                                                </li>
                                            )}
                                        </>
                                    )
                                }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <AuditLogModal
                    exportBtn={true}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'fund'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    deleteFundApiResponse: makeFundsSelectField('deleteFundApiResponse'),
    retrieveFundApiResponse: makeFundsSelectField('retrieveFundApiResponse'),
    fundList: makeFundsSelectField('fundList'),
    closeFundApiResoponse: makeFundsSelectField('closeFundApiResoponse'),
    activeOrDeactiveFundApiResponse: makeFundsSelectField('activeOrDeactiveFundApiResponse'),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedFundMasterListDropdown: selectSortedFundMasterList(),
    fundHealthCheckApiResponse: makeFundsSelectField('fundHealthCheckApiResponse'),
    fundAccessCheckApiResponse: makeFundsSelectField('fundAccessCheckApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getFundListRequest,
    updateEntityStatusByEntityIdRequest,
    deleteFundByFundIdRequest,
    retrieveFundByFundIdRequest,
    activeOrDeactiveFundRequest,
    closeFundRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getFundMasterListByStatusRequest,
    getFundHealthCheckRequest,
    getFundCheckAccessRequest
});
export default compose(
    withConnect,
    withFundsReducer,
    withFundsSaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withEntityReducer,
    withEntitySaga,
    withFundMasterReducer,
    withFundMasterSaga,
    withRouter
)(Index);
