import {
    ADD_DELEGATION_REQUEST,
    ADD_DELEGATION_RESPONSE,
    DELETE_DELEGATION_REQUEST,
    DELETE_DELEGATION_RESPONSE,
    GET_ALL_DELEGATION_LIST_REQUEST,
    GET_ALL_DELEGATION_LIST_RESPONSE,
    UPDATE_DELEGATION_REQUEST,
    UPDATE_DELEGATION_RESPONSE,
} from './actionTypes';
export const addDelegationRequest = (data) => {
    return {
        type: ADD_DELEGATION_REQUEST,
        payload: { data },
    };
};
export const addDelegationResponse = (message, responseType) => {
    return {
        type: ADD_DELEGATION_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllDelegationListRequest = () => {
    return {
        type: GET_ALL_DELEGATION_LIST_REQUEST,
    };
};
export const getAllDelegationListResponse = (delegationList) => {
    return {
        type: GET_ALL_DELEGATION_LIST_RESPONSE,
        payload: { delegationList },
    };
};

export const updateDelegationRequest = (userDelegationId, data) => {
    return {
        type: UPDATE_DELEGATION_REQUEST,
        payload: { userDelegationId, data },
    };
};
export const updateDelegatioResponse = (message, responseType) => {
    return {
        type: UPDATE_DELEGATION_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteDelegationRequest = (delegationId) => {
    return {
        type: DELETE_DELEGATION_REQUEST,
        payload: { delegationId },
    };
};
export const deleteDelegationResponse = (message, responseType) => {
    return {
        type: DELETE_DELEGATION_RESPONSE,
        payload: { message, responseType },
    };
};
