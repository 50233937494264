import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST, GET_EXECUTED_EVENT_LIST_REQUEST, GET_ONGOING_EVENT_LIST_REQUEST, GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_REQUEST, PLAN_EVENT_REQUEST, UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST, UPDATE_USER_DETAILS_BY_TASK_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { cancelPlanEventByPlanEventIdResponse, getExecutedEventsListResponse, getOngoingEventsListResponse, getPlanEventDetailsByPlanEventIdResponse, planEventResponse, updatePlanEventStatusByPlanEventIdResponse, updateUserDetailsByTaskIdResponse } from './actions';
import { GET_EXECUTED_EVENTS, GET_ONGOING_EVENTS, PLAN_EVENT, PLAN_EVENT_CANCEL, PLAN_EVENT_DETAILS, PLAN_EVENT_UPDATE_USER_DETAILS } from 'helpers/apiConstants';
function* planEvent({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', PLAN_EVENT, data);
        if (response.status) {
            yield put(planEventResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(planEventResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getOngoingEventsList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', GET_ONGOING_EVENTS, data);
        if (response.status) {
            yield put(getOngoingEventsListResponse(response.data.list));
        } else {
            yield put(getOngoingEventsListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updatePlanEventStatusByPlanEventId({ payload: { planEventId, status } }) {
    try {
        const response = yield call(invokeApi, 'patch', PLAN_EVENT + '/' + planEventId + '/status/' + status);
        if (response.status) {
            yield put(updatePlanEventStatusByPlanEventIdResponse(response.message, SUCCESS));
        } else {
            yield put(updatePlanEventStatusByPlanEventIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getExecutedEventsList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', GET_EXECUTED_EVENTS, data);
        if (response.status) {
            yield put(getExecutedEventsListResponse(response.data.list));
        } else {
            yield put(getExecutedEventsListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getPlanEventDetailsByPlanEventId({ payload: { planEventId } }) {
    try {
        const response = yield call(invokeApi, 'get', PLAN_EVENT_DETAILS + '/' + planEventId);
        if (response.status) {
            yield put(getPlanEventDetailsByPlanEventIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getPlanEventDetailsByPlanEventIdResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* cancelPlanEventByPlanEventId({ payload: { planEventId } }) {
    try {
        const response = yield call(invokeApi, 'post', PLAN_EVENT_CANCEL + '/' + planEventId);
        if (response.status) {
            yield put(cancelPlanEventByPlanEventIdResponse(response.message, SUCCESS));
        } else {
            yield put(cancelPlanEventByPlanEventIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateUserDetailsByTaskId({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            PLAN_EVENT_UPDATE_USER_DETAILS + '/' + taskId,
            data
        );
        if (response.status) {
            yield put(
                updateUserDetailsByTaskIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                updateUserDetailsByTaskIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchEventAllocation() {
    yield takeEvery(PLAN_EVENT_REQUEST, planEvent);
    yield takeEvery(GET_ONGOING_EVENT_LIST_REQUEST, getOngoingEventsList);
    yield takeEvery(UPDATE_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST, updatePlanEventStatusByPlanEventId);
    yield takeEvery(CANCEL_PLAN_EVENT_STATUS_BY_PLAN_EVENT_ID_REQUEST, cancelPlanEventByPlanEventId);
    yield takeEvery(GET_EXECUTED_EVENT_LIST_REQUEST, getExecutedEventsList);
    yield takeEvery(GET_PLAN_EVENT_DETAILS_BY_PLAN_EVENT_ID_REQUEST, getPlanEventDetailsByPlanEventId);
    yield takeEvery(UPDATE_USER_DETAILS_BY_TASK_ID_REQUEST, updateUserDetailsByTaskId);
}
function* eventAllocationSaga() {
    yield all([fork(watchEventAllocation)]);
}
export default eventAllocationSaga;