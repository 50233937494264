import { compareIntValue } from 'helpers/generalUtils';
import __t from 'i18n/translator';
import React from 'react';
import { Collapse } from 'reactstrap';

class ComplianceSubItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openItemID: '',
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick = (itemID) => {
        if (itemID === this.state.openItemID) {
            this.setState({ openItemID: '' });
        } else {
            this.setState({ openItemID: itemID });
        }
    };
    render() {
        let complianceRecordSelected = this.props?.allocationData?.compliances?.find(
            (opt) => opt.complianceId == this.props.subTask.id
        );
        let alreadySelectedEntities =
            complianceRecordSelected?.entities || [];
        return (

            <div className="tree-item" key={'main-' + this.props.subTask.id}>
                <div className="inner-item">
                    <svg
                        onClick={() =>
                            this.handleClick(this.props.subTask.id)
                        }
                        width="48px"
                        height="48px"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            width="48"
                            height="48"
                            fill="white"
                            fillOpacity="0.01"
                        />
                        <path
                            d="M37 18L25 30L13 18"
                            stroke="currentColor"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <b
                        onClick={() =>
                            this.handleClick(this.props.subTask.id)
                        }
                        key={this.props.subTask.id}
                    >
                        <a href={() => false}>
                            <span>{this.props.subTask.complianceCode}</span>{this.props.subTask.complianceTitle}
                        </a>
                    </b>
                    <div className="task-action">
                        <React.Fragment>
                            {this.props?.type == 'allocation' && (
                                <React.Fragment>
                                    {alreadySelectedEntities.length > 0 && (
                                        <span className="text-info">
                                            <i className="fas fa-check-circle"></i>
                                        </span>
                                    )}
                                    <a
                                        href={() => false}
                                        onClick={() => {

                                            this.props.openMapLocationModal(
                                                this.props.subTask.id,
                                                this.props.subTask.complianceCode,
                                                this.props.subTask
                                            );
                                        }}
                                        className="allocate-entity-link text-primary py-0 px-2"
                                        color="link"
                                        title={__t('common.click_to_allocate_entity')}
                                    >
                                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16" y="7" width="4" height="4" rx="2" transform="rotate(90 16 7)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="16" y="15" width="4" height="4" rx="2" transform="rotate(90 16 15)" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" strokeWidth="1.5"></rect><rect x="1" y="5" width="4" height="4" rx="2" transform="rotate(-90 1 5)" fill="#7E869E" fillOpacity="1" stroke="currentColor" strokeWidth="1.5"></rect><path d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12" stroke="currentColor" strokeWidth="1.5"></path><path d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12" stroke="currentColor" strokeWidth="1.5"></path></svg>
                                    </a>

                                </React.Fragment>
                            )}
                            {this.props.subTask.isFinal && <span title={__t('form_labels.masters.events.final_activity')}>
                                <i className='fas fa-flag'></i>
                            </span>}
                            <a
                                href={() => false}
                                className="text-success py-0 px-2"
                                color="link"
                                title={__t('common.view')}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                    window.open(
                                        '/statutory-compliances/view-details/' +
                                        this.props.subTask.id,
                                        '_blank'
                                    );
                                }}
                            >
                                <i className='fas fa-eye'></i>
                            </a>
                        </React.Fragment>
                    </div>

                </div>
                <Collapse
                    isOpen={this.state.openItemID === this.props.subTask.id}
                    unmountOnExit
                >
                    {this.props.subTask?.subTask.length > 0 &&
                        this.props.subTask.subTask.sort((item1, item2) => compareIntValue(item1.sequenceOrder, item2.sequenceOrder)).map((subTask, key) => {
                            return (
                                <div
                                    className={
                                        subTask.subTask.length > 0
                                            ? ' tree-parent has-child '
                                            : ' tree-parent '
                                    }
                                    key={'child-' + subTask.id}
                                >
                                    <ComplianceSubItem
                                        allocationData={this.props.allocationData}
                                        type={(this.props?.type == 'allocation') ? this.props?.type : ''}
                                        openMapLocationModal={(this.props?.openMapLocationModal) ? this.props.openMapLocationModal : ''}
                                        history={this.props.history}
                                        subTask={subTask}
                                    />
                                </div>
                            );
                        })}
                </Collapse>
            </div>

        );
    }
}
export default ComplianceSubItem;
