import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { compose } from 'redux';
import CountUp from 'react-countup';
import { createStructuredSelector } from 'reselect';
import TotalCountry from '../../images/totaluser.png';
import Companygroup from '../../images/company-group.png';
import TotalEntity from '../../images/entity_icon.png';
import TotalLaws from '../../images/laws_icon.png';
import TotalUpdates from '../../images/updates_icon.png';
import TotalEvents from '../../images/events.png';

class ItAdminIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userCount: 0,
            companyGroupCount: 0,
            entityCount: 0,
            lawCount: 0,
            industryCount: 0,
            subIndustryCount: 0,
            eventCount: 0,
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.apiResponseData &&
            nextprops.apiResponseData !== this.props.apiResponseData
        ) {
            this.setState({
                userCount: nextprops.apiResponseData?.counts?.userCount,
                companyGroupCount:
                    nextprops.apiResponseData?.counts?.companyGroupCount,
                entityCount: nextprops.apiResponseData?.counts?.entityCount,
                lawCount: nextprops.apiResponseData?.counts?.lawCount,
                industryCount: nextprops.apiResponseData?.counts?.industryCount,
                subIndustryCount:
                    nextprops.apiResponseData?.counts?.subIndustryCount,
                eventCount: nextprops.apiResponseData?.counts?.eventCount,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="dashboard_content">
                    <div className="dashboard_section mb-0 mt-4">
                        <Row className="mt-2">
                            <Col lg="12" className="mb-3">
                                <ul className="details_count it_admin_dashboard">
                                    <li>
                                        <Link to="/users">
                                            <p>
                                                <img
                                                    src={TotalCountry}
                                                    alt="Users"
                                                />
                                            </p>
                                            <div>
                                                <small>Users</small>
                                                <CountUp
                                                    duration="2.5"
                                                    end={this.state.userCount}
                                                />
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/company-groups">
                                            <p>
                                                <img
                                                    src={Companygroup}
                                                    alt="Company Groups"
                                                />
                                            </p>
                                            <div>
                                                <small>Company Groups</small>
                                                <CountUp
                                                    duration="2.5"
                                                    end={
                                                        this.state
                                                            .companyGroupCount
                                                    }
                                                />
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/entities">
                                            <p>
                                                <img
                                                    src={TotalEntity}
                                                    alt="Entity"
                                                />
                                            </p>
                                            <div>
                                                <small>Entities</small>
                                                <CountUp
                                                    duration="2.5"
                                                    end={this.state.entityCount}
                                                />
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/laws">
                                            <p>
                                                <img
                                                    src={TotalLaws}
                                                    alt="Laws"
                                                />
                                            </p>
                                            <div>
                                                <small>Laws</small>

                                                <CountUp
                                                    duration="2.5"
                                                    end={this.state.lawCount}
                                                />
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/industries">
                                            <p>
                                                <img
                                                    src={TotalUpdates}
                                                    alt="Industry & SubIndustry"
                                                />
                                            </p>
                                            <div>
                                                <small>
                                                    Industry & SubIndustry
                                                </small>
                                                <div className="d-flex justify-content-center">
                                                    <CountUp
                                                        duration="2.5"
                                                        end={
                                                            this.state
                                                                .industryCount
                                                        }
                                                    />
                                                    <span>&nbsp; & &nbsp;</span>
                                                    <CountUp
                                                        duration="2.5"
                                                        end={
                                                            this.state
                                                                .subIndustryCount
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/events">
                                            <p>
                                                <img
                                                    src={TotalEvents}
                                                    alt="Events"
                                                />
                                            </p>
                                            <div>
                                                <small>Events</small>

                                                <CountUp
                                                    duration="2.5"
                                                    end={this.state.eventCount}
                                                />
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({});
const withConnect = connect(mapStatetoProps, {});
export default compose(withConnect, withRouter)(ItAdminIndex);
