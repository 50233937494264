import React, { Component } from 'react';
import CustomisedModal from 'components/Application/CustomisedModal';
import { Col, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import {
    getDiscrepancyTypeNameById,
    getExpenseTypeNameById,
    getFileUrlByModule,
    getSubmitterCompletionStatusNameById,
} from 'helpers/projectUtils';
import {
    ACTIVITY_TYPE_AUDITED,
    ACTIVITY_TYPE_DISCREPANCY,
    ACTIVITY_TYPE_SUBMITTED,
    DISCREPANCY_TYPE_MONETARY,
    DISCREPANCY_TYPE_NON_MONETARY,
    DISCREPANCY_TYPE_OTHERS,
    EXPENSE_TYPE_PENALTY,
    EXPENSE_TYPE_STATUTORY_PAYMENT,
} from 'constants/databaseConstants';
import { NO, YES } from 'constants/commonConstants';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';

class ViewActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activityData: {},
            downloadFileUrl: '',
            downloadFileName: ''
        };
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.activityData !== this.props.activityData) {
            this.setState({
                activityData: {
                    ...nextprops.activityData,
                    expenseType:
                        nextprops.activityData.expenseType
                            ?.split(',')
                            .map((opt) => {
                                return parseInt(opt);
                            }) || [],
                    discrepancyTypeIds:
                        nextprops.activityData.discrepancyType
                            ?.split(',')
                            .map((opt) => {
                                return parseInt(opt);
                            }) || [],
                    discrepancyType:
                        nextprops.activityData.discrepancyType
                            ?.split(',')
                            .map((opt) => {
                                return getDiscrepancyTypeNameById(parseInt(opt));
                            }) || [],
                }
            });
        }
    }
    render() {
        let currency1 = this.state.activityData?.currency1 ? JSON.parse(this.state.activityData?.currency1) : {};
        let currency2 = this.state.activityData?.currency2 ? JSON.parse(this.state.activityData?.currency2) : {};
        let departmentForDiscrepancy = [];
        let discrepancyTypes = this.state.activityData?.discrepancyType || [];
        if (discrepancyTypes.length > 0 && this.state.activityData?.discrepancyDepartmentAmountData) {
            departmentForDiscrepancy = this.state.activityData?.discrepancyDepartmentAmountData !== null && this.state.activityData?.discrepancyDepartmentAmountData !== '' ? JSON.parse(this.state.activityData?.discrepancyDepartmentAmountData) : [];
        }

        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row className='view_activity_modal'>
                        <Col lg="12" className='viewactivity_title'>
                            <h2>
                                <span>
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.9091 0C18.2019 0 21.6818 3.47997 21.6818 7.77273C21.6818 10.7065 20.0565 13.2606 17.6571 14.5837C22.8492 16.3205 26.5909 21.2233 26.5909 27H24.9545C24.9545 20.6738 19.8262 15.5455 13.5 15.5455C7.17382 15.5455 2.04545 20.6738 2.04545 27H0.409088C0.409088 21.0275 4.40868 15.9892 9.87561 14.4173C7.63372 13.0548 6.13636 10.5886 6.13636 7.77273C6.13636 3.47997 9.61633 0 13.9091 0ZM13.9091 1.63636C10.5201 1.63636 7.77272 4.38371 7.77272 7.77273C7.77272 11.1617 10.5201 13.9091 13.9091 13.9091C17.2981 13.9091 20.0455 11.1617 20.0455 7.77273C20.0455 4.38371 17.2981 1.63636 13.9091 1.63636Z" fill="currentColor" />
                                    </svg>
                                </span>
                                {
                                    this.state.activityData
                                        ?.createdByUser?.fullName
                                }
                            </h2>

                            {this.state.activityData.status && (
                                <span>
                                    {getSubmitterCompletionStatusNameById(
                                        this.state.activityData.status
                                    )}
                                </span>
                            )}
                        </Col>
                        <Col lg="12">
                            <Row>
                                <Col lg="4">
                                    <p>
                                        <Label>
                                            {__t('form_labels.reporting.activity')}
                                        </Label>
                                        <br />
                                        {
                                            this.state.activityData?.activityType
                                                ?.activityTypeName
                                        }
                                    </p>
                                </Col>
                                {this.state.activityData
                                    .compliancePerformanceDate && (
                                        <Col lg="4">
                                            <p>
                                                <Label>
                                                    {__t(
                                                        'form_labels.reporting.compliance_performance_date'
                                                    )}
                                                </Label>{' '}
                                                <br />
                                                {
                                                    this.state.activityData
                                                        .compliancePerformanceDate
                                                }
                                            </p>
                                        </Col>
                                    )}
                                {this.state.activityData
                                    .documentProcurementDate && (
                                        <Col lg="4">
                                            <p>
                                                <Label>
                                                    {__t(
                                                        'form_labels.reporting.document_procurement_date'
                                                    )}
                                                </Label>{' '}
                                                <br />
                                                {
                                                    this.state.activityData
                                                        .documentProcurementDate
                                                }
                                            </p>
                                        </Col>
                                    )}                                
                                {(this.state.activityData?.department?.departmentName) && (
                                    <Col lg="4">
                                        <p>
                                            <Label>
                                                {__t(
                                                    'form_labels.reporting.department'
                                                )}
                                            </Label>{' '}
                                            <br />
                                            {this.state.activityData?.department?.departmentName}
                                        </p>
                                    </Col>
                                )}
                                {this.state.activityData
                                    .activityTypeId == ACTIVITY_TYPE_SUBMITTED && (
                                        <>
                                            <Col lg="4">
                                                <p>
                                                    <Label>
                                                        {__t(
                                                            'form_labels.reporting.expense_type_incurred'
                                                        )}
                                                    </Label>{' '}
                                                    <br />
                                                    <ul>
                                                        {this.state.activityData?.expenseType?.map(
                                                            (opt, key) => {
                                                                return (
                                                                    <li key={key}>
                                                                        {getExpenseTypeNameById(
                                                                            opt
                                                                        )}
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </p>
                                            </Col>
                                            {this.state.activityData?.expenseType?.includes(
                                                EXPENSE_TYPE_STATUTORY_PAYMENT
                                            ) && (
                                                    <>
                                                        <Col lg="4">
                                                            <div className='expense_incurred_inner'>
                                                                {this.state.activityData?.expenseType.length > 1 && (
                                                                    <span>&nbsp;&nbsp;
                                                                        {getExpenseTypeNameById(
                                                                            EXPENSE_TYPE_STATUTORY_PAYMENT
                                                                        )}
                                                                    </span>
                                                                )}
                                                                <Row className='m-0'>
                                                                    <Col className='px-1'>
                                                                        <p>
                                                                            <Label>
                                                                                {__t(
                                                                                    'form_labels.reporting.amount'
                                                                                )}
                                                                            </Label>{' '}
                                                                            <br />
                                                                            {this.state.activityData
                                                                                .amount1
                                                                                ? this.state
                                                                                    .activityData
                                                                                    .amount1
                                                                                : '-'}
                                                                        </p>
                                                                    </Col>
                                                                    <Col className='px-1'>
                                                                        <p>
                                                                            <Label>
                                                                                {__t(
                                                                                    'form_labels.reporting.currency'
                                                                                )}
                                                                            </Label>{' '}
                                                                            <br />
                                                                            {currency1?.currency
                                                                                ? currency1?.currency
                                                                                : '-'}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </>
                                                )}
                                            {this.state.activityData?.expenseType?.includes(
                                                EXPENSE_TYPE_PENALTY
                                            ) && (
                                                    <>
                                                        <Col lg="4">
                                                            <div className='expense_incurred_inner'>
                                                                {this.state.activityData?.expenseType.length > 1 && (

                                                                    <span>&nbsp;&nbsp;
                                                                        {getExpenseTypeNameById(
                                                                            EXPENSE_TYPE_PENALTY
                                                                        )}
                                                                    </span>
                                                                )}
                                                                <Row className='m-0'>
                                                                    <Col className='px-1'>
                                                                        <p>
                                                                            <Label>
                                                                                {__t(
                                                                                    'form_labels.reporting.amount'
                                                                                )}
                                                                            </Label>{' '}
                                                                            <br />
                                                                            {this.state.activityData
                                                                                .amount2
                                                                                ? this.state
                                                                                    .activityData
                                                                                    .amount2
                                                                                : '-'}
                                                                        </p>
                                                                    </Col>
                                                                    <Col className='px-1'>
                                                                        <p>
                                                                            <Label>
                                                                                {__t(
                                                                                    'form_labels.reporting.currency'
                                                                                )}
                                                                            </Label>{' '}
                                                                            <br />
                                                                            {currency2?.currency
                                                                                ? currency2?.currency
                                                                                : '-'}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </>
                                                )}
                                        </>
                                    )}

                                {this.state.activityData
                                    .activityTypeId == ACTIVITY_TYPE_SUBMITTED && (
                                        <Col lg="4">
                                            <p>
                                                <Label>
                                                    {__t(
                                                        'form_labels.reporting.matter_in_litigation'
                                                    )}
                                                </Label>{' '}
                                                <br />
                                                {this.state.activityData
                                                    .matterInLitigation
                                                    ? YES
                                                    : NO}
                                            </p>
                                        </Col>
                                    )}
                                {this.state.activityData
                                    .activityTypeId == ACTIVITY_TYPE_SUBMITTED && this.state.activityData.matterInLitigation === 1 && (
                                        <Col>
                                            <p>
                                                <Label>
                                                    {__t(
                                                        'form_labels.reporting.description'
                                                    )}
                                                </Label>{' '}
                                                <br />
                                                {this.props.getShowMoreTextElement(
                                                    this.state.activityData
                                                        .matterInLitigationDescription
                                                )}
                                            </p>
                                        </Col>
                                    )}
                            </Row>
                            {this.state.activityData.remarks !== '' && (
                                <p>
                                    <Label>
                                        {__t('form_labels.reporting.remarks')}
                                    </Label>
                                    {this.props.getShowMoreTextElement(
                                        this.state.activityData.remarks
                                    )}
                                </p>
                            )}
                            {(this.state.activityData.activityTypeId === ACTIVITY_TYPE_AUDITED || this.state.activityData.activityTypeId === ACTIVITY_TYPE_DISCREPANCY) && this.state.activityData.recommendations !== '' && this.state.activityData.recommendations !== null && (
                                <p>
                                    <Label>
                                        {__t('form_labels.reporting.recommendation')}
                                    </Label>
                                    {this.props.getShowMoreTextElement(
                                        this.state.activityData.recommendations
                                    )}
                                </p>
                            )}
                            {this.state.activityData?.discrepancyType?.length > 0 && (
                                <Row>
                                    <Col lg="6">
                                        <Row>
                                            <Col lg="12">
                                                <p>
                                                    <Label>
                                                        {__t('form_labels.reporting.discrepancy_type')}
                                                    </Label>{' '}
                                                    <br />
                                                    {this.state.activityData?.discrepancyType?.join(', ')}
                                                    {
                                                        departmentForDiscrepancy.map((dd) => {
                                                            let departmentName = this.props.departmentDataForInput.find((e) => {
                                                                return dd.departmentId === e.value;
                                                            })?.label;
                                                            let discrepanceyCurrency = dd.currency;
                                                            let discrepanceyAmount = dd.amount;
                                                            return (
                                                                <Row className='mt-2'>
                                                                    <Col lg="4" className='pr-0'>
                                                                        <p className='mb-0'>
                                                                            <Label>{__t('form_labels.reporting.discrepancy_department')}</Label>
                                                                            <br />
                                                                            {departmentName}
                                                                        </p>
                                                                    </Col>
                                                                    <Col lg="4" className='pr-0'>
                                                                        <p className='mb-0'>
                                                                            <Label>{__t('form_labels.reporting.currency')}</Label>
                                                                            <br />
                                                                            {discrepanceyCurrency?.currency ? discrepanceyCurrency?.currency : '-'}
                                                                        </p>
                                                                    </Col>
                                                                    <Col lg="4">
                                                                        <p className='mb-0'>
                                                                            <Label>{__t('form_labels.reporting.amount')}</Label>
                                                                            <br />
                                                                            {discrepanceyAmount}
                                                                        </p>
                                                                    </Col>
                                                                </Row>)
                                                        })
                                                    }
                                                </p>
                                            </Col>
                                            <Col lg="12">
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="6">
                                        <Row>
                                            {this.state.activityData?.discrepancyTypeIds?.includes(DISCREPANCY_TYPE_NON_MONETARY) && (
                                                <>
                                                    <Col lg="12">
                                                        <p>
                                                            <Label>   {__t('form_labels.reporting.discrepancy_non_monetary_description')}</Label>
                                                            {this.props.getShowMoreTextElement(this.state.activityData?.discrepancyNonMonetaryDescription)}
                                                        </p>
                                                    </Col>
                                                </>
                                            )}
                                            {this.state.activityData?.discrepancyTypeIds?.includes(DISCREPANCY_TYPE_OTHERS) && (
                                                <>
                                                    <Col lg="12">
                                                        <p>
                                                            <Label>   {__t('form_labels.reporting.discrepancy_others_description')}</Label>
                                                            {this.props.getShowMoreTextElement(this.state.activityData.discrepancyOthersDescription)}
                                                        </p>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            )}

                            {this.state.activityData?.transactionalDocuments
                                ?.length > 0 && (
                                    <p className='px-2 py-3 mb-0 d-flex align-items-center'>
                                        <Label>
                                            {__t('form_labels.reporting.files')}
                                        </Label>
                                        {this.state.activityData?.transactionalDocuments.map(
                                            (file, key) => {
                                                return (
                                                    <>
                                                        <a
                                                            // href={getFileUrlByModule('poc', file.fileUrl, { companyGroupId: this.props.companyGroupId, entityId: this.props.entityId })}
                                                            // target="_blank"
                                                            onClick={() => {
                                                                this.props.saveFile({
                                                                    type: 'poc',
                                                                    fileName: file.fileName.split('/')[1],
                                                                    fileUrl: file.fileUrl,
                                                                })
                                                            }}
                                                            rel="noopener noreferrer"
                                                            className="text-primary file_view_btn ml-2"
                                                        >
                                                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9 5V1H5C3.11438 1 2.17157 1 1.58579 1.58579C1 2.17157 1 3.11438 1 5V15C1 16.8856 1 17.8284 1.58579 18.4142C2.17157 19 3.11438 19 5 19H11C12.8856 19 13.8284 19 14.4142 18.4142C15 17.8284 15 16.8856 15 15V7H11C10.0572 7 9.58579 7 9.29289 6.70711C9 6.41421 9 5.94281 9 5Z" fill="#4F2682" fillOpacity="0.13" />
                                                                <path d="M9.17157 1H5C3.11438 1 2.17157 1 1.58579 1.58579C1 2.17157 1 3.11438 1 5V15C1 16.8856 1 17.8284 1.58579 18.4142C2.17157 19 3.11438 19 5 19H11C12.8856 19 13.8284 19 14.4142 18.4142C15 17.8284 15 16.8856 15 15V6.82843C15 6.41968 15 6.2153 14.9239 6.03153C14.8478 5.84776 14.7032 5.70324 14.4142 5.41421L10.5858 1.58579C10.2968 1.29676 10.1522 1.15224 9.96847 1.07612C9.7847 1 9.58032 1 9.17157 1Z" stroke="currentColor" strokeWidth="1.2" />
                                                                <path d="M5 11L11 11" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                                                                <path d="M5 15L9 15" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                                                                <path d="M9 1V5C9 5.94281 9 6.41421 9.29289 6.70711C9.58579 7 10.0572 7 11 7H15" stroke="currentColor" strokeWidth="1.2" />
                                                            </svg>
                                                            {file.fileName}
                                                        </a>
                                                        <br />
                                                    </>
                                                );
                                            }
                                        )}
                                    </p>
                                )}
                        </Col>
                    </Row>
                </CustomisedModal >
            </React.Fragment >
        );
    }
}
export default ViewActivity;
