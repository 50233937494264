import {
    ADD_LAW_DEFINITION_REQUEST,
    ADD_LAW_DEFINITION_RESPONSE,
    GET_ALL_LAW_DEFINITIONS_REQUEST,
    GET_ALL_LAW_DEFINITIONS_RESPONSE,
    GET_LAW_DEFINITION_VERSIONS_LIST_REQUEST,
    GET_LAW_DEFINITION_VERSIONS_LIST_RESPONSE,
    GET_LAW_DEFINITIONS_BY_LAW_ID_REQUEST,
    GET_LAW_DEFINITIONS_BY_LAW_ID_RESPONSE,
    UPDATE_LAW_DEFINITION_REQUEST,
    UPDATE_LAW_DEFINITION_RESPONSE
} from './actionTypes';
export const getAllLawDefinitionsRequest = () => {
    return {
        type: GET_ALL_LAW_DEFINITIONS_REQUEST
    };
};
export const getAllLawDefinitionsResponse = allLawDefinitions => {
    return {
        type: GET_ALL_LAW_DEFINITIONS_RESPONSE,
        payload: { allLawDefinitions },
    };
};
export const getLawDefinitionsByLawIdRequest = (lawId, displayMode = false) => {
    return {
        type: GET_LAW_DEFINITIONS_BY_LAW_ID_REQUEST,
        payload: { lawId: lawId, displayMode: displayMode }
    };
};
export const getLawDefinitionsByLawIdResponse = (lawDefinitionsList, legalUpdateListForDefinition) => {
    return {
        type: GET_LAW_DEFINITIONS_BY_LAW_ID_RESPONSE,
        payload: { lawDefinitionsList, legalUpdateListForDefinition },
    };
};

export const updateLawDefinitionRequest = (lawDefinitionId, data) => {
    return {
        type: UPDATE_LAW_DEFINITION_REQUEST,
        payload: { lawDefinitionId, data },
    };
};
export const updateLawDefinitionResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_DEFINITION_RESPONSE,
        payload: { message, responseType },
    };
};

export const addLawDefinitionRequest = (data) => {
    return {
        type: ADD_LAW_DEFINITION_REQUEST,
        payload: { data },
    };
};
export const addLawDefinitionResponse = (message, responseType) => {
    return {
        type: ADD_LAW_DEFINITION_RESPONSE,
        payload: { message, responseType },
    };
};

export const getLawDefinitionVersionsListRequest = (lawDefinitionVersionIds = []) => {
    return {
        type: GET_LAW_DEFINITION_VERSIONS_LIST_REQUEST,
        payload: { lawDefinitionVersionIds }
    };
};
export const getLawDefinitionVersionsListResponse = lawDefinitionVersionsList => {
    return {
        type: GET_LAW_DEFINITION_VERSIONS_LIST_RESPONSE,
        payload: { lawDefinitionVersionsList },
    };
};