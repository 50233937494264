export const roundOff = value => {
    return Number(
        Math.round(parseFloatHelper(value + 'e' + 3)) + 'e-' + 3
    ).toFixed(3);
};
export const roundOffAmount = value => {
    return Number(
        Math.round(parseFloatHelper(value + 'e' + 2)) + 'e-' + 2
    ).toFixed(2);
};
export const parseFloatHelper = value => {
    return parseFloat(value) || 0; // returns 0 it parseFloat contains any false value.
};
export const parseIntHelper = value => {
    return parseInt(value) || 0; // returns 0 it parseInt contains any false value.
};
export const isFloat = value => {
    return Number(value) === value && value % 1 !== 0;
};
//this function will return the sum of particular property
//from array of objects. The property would be of string type
//but decimal value
//e.g. for 100 it would be "100.00" value in property
export const sumDecimalProperty = (list, prop) => {
    return list.reduce(function(a, b) {
        return parseFloatHelper(a) + parseFloatHelper(b[prop]);
    }, 0);
};
//Find the smallest emement from the given array and return its key for further process
export const findSmallestElementInArray = value => {
    let smallestValue = Math.min(...value);
    var key = value.indexOf(smallestValue);
    return key;
};

export const sumAllProperty = (list) => {
    return list.reduce(function(a, b) {
        return parseIntHelper(a) + parseIntHelper(b);
    }, 0);
};