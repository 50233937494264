import React, { Component } from 'react';
import {
    CardBody,
    Card,
    CardHeader,
    Container,
    Col,
    Row,
    Collapse,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    ADD_NEW,
    AUTH_MODE_LC,
    ENABLE_DELETE,
    SUBMIT,
    UPDATE,
} from 'constants/commonConstants';
import * as authConstants from 'constants/authConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    addUserRequest,
    updateUserRequest,
    updateUserStatusByUserIdRequest,
    deleteUserByUserIdRequest,
    passwordResendUserByUserIdRequest,
    filterUserRequest,
    getCompanyGroupListByStatusRequest,
    getAllEntityListOnUserListPageRequest,
    cleareFilterUserResponse,
    clearCompanyGroupListResponse
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import { makeUserSelectField } from 'store/User/selector';
import {
    initialUserDataState,
    listColumns,
    initialUserSearchDataState,
} from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import AddUserForm from './AddUserForm';
import { getValueByKey } from 'helpers/authUtils';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { checkModulePermission } from 'helpers/projectUtils';
import UsersSearchForm from './UsersSearchForm';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import {
    MODULE_USER,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import { makeCompanyGroupSelectField, selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { makeEntitySelectField } from 'store/Entity/selector';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
import ViewUserRolesModal from './ViewUserRolesModal';
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportUserList: [],
            userList: [],
            userData: initialUserDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: '',
            isFormOpen: false,
            viewDialog: true,
            searchUsersFormData: initialUserSearchDataState(),
            activePage: 1,
            isUserRolesModalOpen: false,
            activeUserId: '',
            fromDashboard: {
                filterType: this.props.match.params.filterType
                    ? this.props.match.params.filterType
                    : '',
                selectedId: this.props.match.params.selectedId
                    ? this.props.match.params.selectedId
                    : '',
                companyGroupId: this.props.match.params.companyGroupId
                    ? this.props.match.params.companyGroupId
                    : '',
            },
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve user list
        this.props.getCompanyGroupListByStatusRequest(ACTIVE, 'user');
        this.props.getAllEntityListOnUserListPageRequest();
    }
    viewUser(userData) {
        this.setState(
            {
                isFormOpen: true,
                userData: initialUserDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ userData: userData });
            }
        );
    }
    onStatusChange(checked, e, id) {
        this.props.updateUserStatusByUserIdRequest(id, checked);
    }
    deleteTask = async (userId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.user_delete')
        );
        if (response) {
            this.props.deleteUserByUserIdRequest(userId);
        }
    };
    passwordResendUser = async (userId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.user_password_resend')
        );
        if (response) {
            this.props.passwordResendUserByUserIdRequest(userId);
        }
    };
    addUser() {
        this.setState({
            isFormOpen: true,
        });
    }
    formSubmit = (values) => {
        this.setState({ userData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateUserRequest(values.id, values);
        } else {
            this.props.addUserRequest(values);
        }
    };
    filterFormSubmit = (values) => {
        this.setState({
            searchUsersFormData: values
        }, () => {
            this.props.filterUserRequest({ filter: values });
        })
    };
    resetForm = () => {
        this.setState(
            {
                isFormOpen: false,
                userData: initialUserDataState(),
                operationType: SUBMIT,
                searchUsersFormData: initialUserSearchDataState(),
            },
            () => {
                this.props.filterUserRequest({});
            }
        );
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    openViewUserRolesModal = (data) => {
        this.setState({
            isUserRolesModalOpen: true,
            activeUserId: data.id
        })
    }
    closeViewUserRolesModal = () => {
        this.setState({
            isUserRolesModalOpen: false,
            activeUserId: ''
        })
    }
    generateTable = (records) => {
        let userRecords = [];
        userRecords = records.map((userData, i) => {
            let userCompanyGroupIds = userData?.companyGroupId?.split(',').map(Number);
            let actions = (
                <div className="text-center d-flex">
                    {checkModulePermission(MODULE_USER, PERMISSION_EDIT) ===
                        true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() =>
                                    this.viewUser({
                                        ...userData,
                                        companyGroupId: userCompanyGroupIds,
                                        entityId: userData.userEntities.map(
                                            (opt) => opt.entityId
                                        ),
                                    })
                                }
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}

                    {ENABLE_DELETE.includes('user') &&
                        userData.id !==
                        parseInt(getValueByKey(authConstants.USER_ID)) &&
                        checkModulePermission(
                            MODULE_USER,
                            PERMISSION_DELETE
                        ) === true && (
                            <div className="flex-fill">
                                <a
                                    href={() => false}
                                    title={__t('common.delete')}
                                    className="btn btn-link text-danger tbl-action"
                                    onClick={() => {
                                        this.deleteTask(userData.id);
                                    }}
                                >
                                    <i className="fas fa-trash-alt"></i>
                                </a>
                            </div>
                        )}
                    {userData.authenticationMode === AUTH_MODE_LC && (
                        <a
                            href={() => false}
                            title={__t('common.password_resend')}
                            className="btn btn-link text-secondary tbl-action"
                            onClick={() => {
                                this.passwordResendUser(userData.id);
                            }}
                        >
                            <i className="fas fa-unlock-alt"></i>
                        </a>
                    )}

                    <a
                        href={() => false}
                        title={__t('common.user_roles')}
                        className="btn btn-link text-warning tbl-action"
                        onClick={() => {
                            this.openViewUserRolesModal(userData);
                        }}
                    >
                        <i className="fas fa-user-shield"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(userData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(userData.id)}
                        checked={userData.isActive ? true : false}
                        disabled={(!checkModulePermission(MODULE_USER, PERMISSION_EDIT) ===
                            true) || (checkModulePermission(MODULE_USER, PERMISSION_EDIT) ===
                                true && parseInt(userData.id) === parseInt(getValueByKey(authConstants.USER_ID)))}
                    />
                    &nbsp;
                </div>
            );
            let companyGroupNames =
                this.props.companyGroupList
                    ?.filter((opt) => userCompanyGroupIds?.includes(opt.id))
                    ?.map((opt) => opt?.companyGroupName) || [];

            let entityIds = userData?.userEntities?.map(
                (opt) => opt.entityId
            )
            let entityName = this.props.entityListOnUserList?.filter(item => entityIds.includes(item.id))?.map(x => x.entityName).join(',')
            return {
                fullName: userData.fullName,
                userName: userData?.userName,
                authenticationMode:
                    userData.authenticationMode === AUTH_MODE_LC
                        ? __t('form_labels.masters.users.lc')
                        : __t('form_labels.masters.users.sso'),
                email: userData?.email ? userData.email : '',
                companyGroupName: companyGroupNames.join(','),
                entityName: entityName,
                contactNo: userData?.contactNo ? userData.contactNo : '',
                isActive: isActive,
                actions: actions,
            };
        });
        return userRecords;
    };
    componentWillUnmount() {
        this.props.cleareFilterUserResponse();
        this.props.clearCompanyGroupListResponse();
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.companyGroupList &&
            this.props.companyGroupList !== nextprops.companyGroupList
        ) {
            this.setState(
                {
                    companyGroupList: nextprops.companyGroupList,
                },
                () => {
                    if (this.state.fromDashboard.companyGroupId != '') {
                        let dataFiltered = {
                            companyGroupId: [Number(this.state.fromDashboard.companyGroupId)],
                            entityId: [Number(this.state.fromDashboard.selectedId)],
                        };
                        if (this.state.fromDashboard.selectedId != '') {
                            dataFiltered = { ...dataFiltered, entityId: [Number(this.state.fromDashboard.selectedId)] };
                        }
                        if (this.state.fromDashboard.filterType == 'active') {
                            dataFiltered = { ...dataFiltered, typeId: 1 };
                        }
                        if (this.state.fromDashboard.filterType == 'inactive') {
                            dataFiltered = { ...dataFiltered, typeId: 2 };
                        }
                        if (this.state.fromDashboard.filterType == 'dormant') {
                            dataFiltered = { ...dataFiltered, typeId: 3 };
                        }
                        let searchUsersFormData = this.state.searchUsersFormData;
                        this.setState({
                            searchUsersFormData: { ...searchUsersFormData, ...dataFiltered }
                        }, () => {
                            this.props.filterUserRequest({ filter: this.state.searchUsersFormData });
                        });
                    } else {
                        this.props.filterUserRequest({ filter: initialUserSearchDataState() });
                    }
                }
            );
        }
        if (nextprops.userList && this.props.userList !== nextprops.userList) {
            this.setState({
                exportUserList: nextprops.userList,
                userList: this.generateTable(nextprops.userList),
            });
        }
        if (
            nextprops.deleteUserApiResponse &&
            this.props.deleteUserApiResponse !== nextprops.deleteUserApiResponse
        ) {
            toastrMessage(
                nextprops.deleteUserApiResponse.message,
                nextprops.deleteUserApiResponse.responseType
            );
            this.props.filterUserRequest({ filter: this.state.searchUsersFormData });
        }
        if (
            nextprops.userStatusUpdateApiResponse &&
            this.props.userStatusUpdateApiResponse !==
            nextprops.userStatusUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.userStatusUpdateApiResponse.message,
                nextprops.userStatusUpdateApiResponse.responseType
            );
        }
        if (
            nextprops.passwordResendUserApiResponse &&
            this.props.passwordResendUserApiResponse !==
            nextprops.passwordResendUserApiResponse
        ) {
            toastrMessage(
                nextprops.passwordResendUserApiResponse.message,
                nextprops.passwordResendUserApiResponse.responseType
            );
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.userList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'user'} />
                        </Col>
                        {this.state.isFormOpen && (
                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <div className="page-header">
                                            <h4 className="with-back-btn">
                                                <a
                                                    href={() => false}
                                                    className="dripicons-arrow-thin-left"
                                                    onClick={() => {
                                                        this.resetForm();
                                                    }}
                                                >
                                                    { }
                                                </a>{' '}
                                                &nbsp;
                                                {this.state.operationType ===
                                                    SUBMIT
                                                    ? __t(
                                                        'form_labels.masters.add_user'
                                                    )
                                                    : __t(
                                                        'form_labels.masters.edit_user'
                                                    )}{' '}
                                            </h4>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <AddUserForm
                                            operationType={
                                                this.state.operationType
                                            }
                                            sortedCompanyGroupListDropdown={this.props.sortedCompanyGroupListDropdown}
                                            userData={this.state.userData}
                                            operationResponse={
                                                this.props.userApiResponse
                                            }
                                            onFormValid={this.formSubmit}
                                            onFormReset={this.resetForm}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {!this.state.isFormOpen && (
                            <Col lg="12">
                                <CustomisedCollapseForOne
                                    className="accordion"
                                    viewDialog={false}
                                    dialogName={__t('page_headers.user_filter')}
                                >
                                    <CardBody className="px-4 pt-4 pb-2">
                                        <Collapse
                                            isOpen={this.state.viewDialog}
                                        >
                                            <UsersSearchForm
                                                sortedCompanyGroupListDropdown={this.props.sortedCompanyGroupListDropdown}
                                                searchUsersFormData={
                                                    this.state
                                                        .searchUsersFormData
                                                }
                                                onFormSubmit={(
                                                    searchFormData
                                                ) => {
                                                    this.filterFormSubmit(
                                                        searchFormData
                                                    );
                                                }}
                                                onResetForm={() => {
                                                    this.resetForm();
                                                }}
                                            />
                                        </Collapse>
                                    </CardBody>
                                </CustomisedCollapseForOne>
                                <Card>
                                    <CardHeader>
                                        <div className="page-header">
                                            <h4>
                                                {__t('page_headers.user_list')}
                                            </h4>
                                        </div>
                                        <div className="page-action">

                                            {(checkModulePermission(
                                                MODULE_USER,
                                                PERMISSION_ADD
                                            ) === true ||
                                                checkModulePermission(
                                                    MODULE_USER,
                                                    PERMISSION_EDIT
                                                ) === true) && (
                                                    <a
                                                        href={() => false}
                                                        className="btn btn-primary waves-effect waves-light float-right ml-2"
                                                        onClick={() => {
                                                            this.addUser();
                                                        }}
                                                    >
                                                        <i className="fas fa-plus">
                                                            {' '}
                                                        </i>{' '}
                                                        {ADD_NEW}
                                                    </a>
                                                )}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <CustomisedMDBDatatablePage
                                            activepage={this.state.activePage}
                                            onHandlePageChange={this.handlePageChange}
                                            tableRecords={data}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'user'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                    <ViewUserRolesModal
                        isModalOpen={this.state.isUserRolesModalOpen}
                        activeUserId={this.state.activeUserId}
                        onModalDismiss={this.closeViewUserRolesModal}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    userList: makeUserSelectField('userList'),
    companyGroupList: makeCompanyGroupSelectField('companyGroupList'),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    userApiResponse: makeUserSelectField('userApiResponse'),
    userStatusUpdateApiResponse: makeUserSelectField(
        'userStatusUpdateApiResponse'
    ),
    deleteUserApiResponse: makeUserSelectField('deleteUserApiResponse'),
    passwordResendUserApiResponse: makeUserSelectField(
        'passwordResendUserApiResponse'
    ),
    entityListOnUserList: makeEntitySelectField('entityListOnUserList'),
});
const withConnect = connect(mapStatetoProps, {
    addUserRequest,
    updateUserRequest,
    updateUserStatusByUserIdRequest,
    deleteUserByUserIdRequest,
    passwordResendUserByUserIdRequest,
    filterUserRequest,
    getCompanyGroupListByStatusRequest,
    getAllEntityListOnUserListPageRequest,
    cleareFilterUserResponse,
    clearCompanyGroupListResponse
});
export default compose(
    withConnect,
    withUserReducer,
    withUserSaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withRouter
)(Index);
