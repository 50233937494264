import {
    CLEAR_OVERALL_TASK_API_RESPONSE,
    GET_DASHBOARD_ADMIN_DETAILS_DATA_REQUEST,
    GET_DASHBOARD_ADMIN_DETAILS_DATA_RESPONSE,
    GET_DASHBOARD_DATA_BY_ROLE_REQUEST,
    GET_DASHBOARD_DATA_BY_ROLE_RESPONSE,
    GET_DASHBOARD_DATA_FOR_RISK_REQUEST,
    GET_DASHBOARD_DATA_FOR_RISK_RESPONSE,
    GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_REQUEST,
    GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_RESPONSE,
    GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_REQUEST,
    GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_RESPONSE,
    GET_DASHBOARD_DETAILS_DATA_REQUEST,
    GET_DASHBOARD_DETAILS_DATA_RESPONSE,
    GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_REQUEST,
    GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_RESPONSE,
    GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_REQUEST,
    GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_RESPONSE,
    GET_DASHBOARD_LAW_SEARCH_REQUEST,
    GET_DASHBOARD_LAW_SEARCH_RESPONSE,
    GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_REQUEST,
    GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_RESPONSE,
    GET_DASHBOARD_M_DETAILS_DATA_REQUEST,
    GET_DASHBOARD_M_DETAILS_DATA_RESPONSE,
    GET_OVERALL_TASK_LIST_REQUEST,
    GET_OVERALL_TASK_LIST_RESPONSE,
} from './actionTypes';

export const getDashboardDataByRoleRequest = (roleId, data) => {
    return {
        type: GET_DASHBOARD_DATA_BY_ROLE_REQUEST,
        payload: { roleId: roleId, data: data },
    };
};
export const getDashboardDataByRoleResponse = (message, responseType, data) => {
    return {
        type: GET_DASHBOARD_DATA_BY_ROLE_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardDetailsDataRequest = (data) => {
    return {
        type: GET_DASHBOARD_DETAILS_DATA_REQUEST,
        payload: { data: data },
    };
};
export const getDashboardDetailsDataResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DASHBOARD_DETAILS_DATA_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardAdminDetailsDataRequest = (data) => {
    return {
        type: GET_DASHBOARD_ADMIN_DETAILS_DATA_REQUEST,
        payload: { data: data },
    };
};
export const getDashboardAdminDetailsDataResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DASHBOARD_ADMIN_DETAILS_DATA_RESPONSE,
        payload: { message, responseType, data },
    };
}

export const getDashboardMDetailsDataRequest = (data) => {
    return {
        type: GET_DASHBOARD_M_DETAILS_DATA_REQUEST,
        payload: { data: data },
    };
};
export const getDashboardMDetailsDataResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DASHBOARD_M_DETAILS_DATA_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardManagementDetailsDataRequest = (data) => {
    return {
        type: GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_REQUEST,
        payload: { data: data },
    };
};
export const getDashboardManagementDetailsDataResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DASHBOARD_MANAGEMENT_DETAILS_DATA_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getOverallTaskListRequest = (data) => {
    return {
        type: GET_OVERALL_TASK_LIST_REQUEST,
        payload: { data },
    };
};
export const getOverallTaskListResponse = (message, responseType, data) => {
    return {
        type: GET_OVERALL_TASK_LIST_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardLawSearchRequest = (data) => {
    return {
        type: GET_DASHBOARD_LAW_SEARCH_REQUEST,
        payload: { data: data },
    };
};
export const getDashboardLawSearchResponse = (message, responseType, data) => {
    return {
        type: GET_DASHBOARD_LAW_SEARCH_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardLawCompliancesSearchRequest = (data) => {
    return {
        type: GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_REQUEST,
        payload: { data: data },
    };
};
export const getDashboardLawCompliancesSearchResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DASHBOARD_LAW_COMPLIANCES_SEARCH_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardLawDocumentsSearchRequest = (data) => {
    return {
        type: GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_REQUEST,
        payload: { data: data },
    };
};
export const getDashboardLawDocumentsSearchResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DASHBOARD_LAW_DOCUMENTS_SEARCH_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardDepartmentTaskByEntityRequest = (roleId, data) => {
    return {
        type: GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_REQUEST,
        payload: { roleId: roleId, data: data },
    };
};
export const getDashboardDepartmentTaskByEntityResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DASHBOARD_DEPARTMENT_TASK_BY_ENTITY_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getDashboardDataMonthWiseByRoleRequest = (roleId, data) => {
    return {
        type: GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_REQUEST,
        payload: { roleId: roleId, data: data },
    };
};
export const getDashboardDataMonthWiseByRoleResponse = (message, responseType, data) => {
    return {
        type: GET_DASHBOARD_DATA_MONTH_WISE_BY_ROLE_RESPONSE,
        payload: { message, responseType, data },
    };
};


export const getDashboardDataForRiskRequest = (roleId, data) => {
    return {
        type: GET_DASHBOARD_DATA_FOR_RISK_REQUEST,
        payload: { roleId: roleId, data: data },
    };
};
export const getDashboardDataForRiskResponse = (message, responseType, data) => {
    return {
        type: GET_DASHBOARD_DATA_FOR_RISK_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const clearOverallTaskApiResponse = () => {
    return {
        type: CLEAR_OVERALL_TASK_API_RESPONSE
    };
};