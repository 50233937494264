import { compareString } from 'helpers/generalUtils';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.lawDefinition || initialState;
const makeLawDefinitionSelectField = field =>
    createSelector(reducerState, lawDefinitionState => lawDefinitionState[field]);
const selectSortedLawDefinitionsList = () =>
    createSelector(makeLawDefinitionSelectField('lawDefinitionsList'), lawDefinitionsList =>
    lawDefinitionsList.map(opt => ({
            value: opt.id,
            label: opt.definition,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeLawDefinitionSelectField,
    selectSortedLawDefinitionsList
};
