import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { SUCCESS, RESET, DANGER } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import Select from 'react-select';

class AddEventForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
        };
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
        this.props.resetSortedStateAndCityDropDown();
    };
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
                this.props.resetSortedStateAndCityDropDown();
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.eventData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className='col-lg-6'>
                                    <Field
                                        name="eventName"
                                        className="form-control"
                                        value={values.eventName}
                                        placeholder={__t('form_labels.masters.events.event_name')}
                                        maxLength={100}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.events.event_name')}
                                        <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.events.event_name'),
                                        values.eventName,
                                        'required|min:3|max:100|alpha_num_dash_space',
                                        {
                                            messages: {
                                                required: __t('validations.masters.events.event_name_required'),
                                                min: __t('validations.masters.events.event_name_min', { min: 3 }),
                                                max: __t('validations.masters.events.event_name_max', { max: 100 }),
                                                alpha_num_dash_space: __t('validations.masters.events.event_name_alpha_num_dash_space')
                                            }
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className='col-lg-2'>
                                    <Field
                                        component={Select}
                                        name="countryId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropDown
                                        }
                                        onChange={opt => {
                                            let selected = (opt) ? opt.value : ''
                                            setFieldValue(
                                                'countryId',
                                                selected
                                            );
                                            setFieldValue('stateId', '')
                                            setFieldValue('cityId', '')
                                            if (opt.value) {
                                                this.props.getStateListByCountry(
                                                    opt.value
                                                );
                                            }
                                        }}
                                        value={this.props.sortedCountryListDropDown.filter(
                                            option =>
                                                option.value ===
                                                values.countryId
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.events.country')} <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.events.country'),
                                        values.countryId,
                                        'required', {
                                        messages: {
                                            required: __t('validations.masters.events.country_required')
                                        }
                                    }
                                    )}
                                </FormGroup>

                                <FormGroup className="col-lg-2">
                                    <Field
                                        component={Select}
                                        name="stateId"
                                        className="form-control selectbox"
                                        options={this.props.sortedStateListDropDown}
                                        onChange={(opt) => {
                                            setFieldValue(`stateId`, opt.value);
                                            if (opt.value) {
                                                this.props.getCityListByState(opt.value);
                                            }
                                        }}
                                        value={this.props.sortedStateListDropDown.filter(
                                            (option) =>
                                                option.value === values.stateId
                                        )}
                                        // isDisabled={
                                        //     values.id && values.id !== 0
                                        // }
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.events.state')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.events.state'),
                                        values.stateId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.events.state_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <Field
                                        component={Select}
                                        name="cityId"
                                        className="form-control selectbox"
                                        options={this.props.sortedCityListDropDown}
                                        onChange={(opt) => {
                                            setFieldValue('cityId', opt.value);
                                        }}
                                        value={this.props.sortedCityListDropDown?.filter(
                                            (option) =>
                                                option.value === values.cityId
                                        )}
                                        // isDisabled={
                                        //     values.id && values.id !== 0
                                        // }
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.events.city')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.events.city'),
                                        values.cityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.masters.events.city_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        component="textarea"
                                        name="eventDescription"
                                        className="form-control"
                                        placeholder={__t('form_labels.masters.events.event_description')}
                                        value={
                                            values.eventDescription
                                        }
                                        maxLength={1000}
                                        rows="3"
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.events.event_description')}
                                        <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.events.event_description'),
                                        values.eventDescription,
                                        'required|min:3|max:1000|alpha_num_dash_space', {
                                        messages: {
                                            required: __t('validations.masters.events.event_description_required'),
                                            min: __t('validations.masters.events.event_description_min', { min: 3 }),
                                            max: __t('validations.masters.events.event_description_max', { max: 1000 }),
                                            alpha_num_dash_space: __t('validations.masters.events.event_description_alpha_num_dash_space')
                                        }
                                    }
                                    )}
                                </FormGroup>

                                <FormGroup className="col-lg-12">
                                    <Field
                                        component="textarea"
                                        name="eventApplicability"
                                        className="form-control"
                                        placeholder={__t('form_labels.masters.events.event_applicability')}
                                        value={
                                            values.eventApplicability
                                        }
                                        rows="3"
                                        maxLength={3000}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.events.event_applicability')}
                                        <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.events.event_applicability'),
                                        values.eventApplicability,
                                        'required|min:3|max:3000', {
                                        messages: {
                                            required: __t('validations.masters.events.event_applicability_required'),
                                            min: __t('validations.masters.events.event_applicability_min', { min: 3 }),
                                            max: __t('validations.masters.events.event_applicability_max', { max: 3000 })
                                        }
                                    }
                                    )}
                                </FormGroup>
                                <Col lg="12" className='d-flex align-items-end justify-content-end'>
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >
                                                {
                                                    this
                                                        .props
                                                        .operationType
                                                }
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {RESET}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
export default AddEventForm;