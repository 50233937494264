import {
    LOGIN_REQUEST,
    LOGIN_RESPONSE,
    LOGIN_PASSWORD_CHANGE_REQUEST,
    LOGIN_PASSWORD_CHANGE_RESPONSE,
    LOGOUT_REQUEST,
    LOGOUT_RESPONSE,
    SEND_CODE_REQUEST,
    SEND_CODE_RESPONSE,
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_RESPONSE,
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_RESPONSE,
} from './actionTypes';
export const loginRequest = (email, password, code, tenantId,vToken) => {
    return {
        type: LOGIN_REQUEST,
        payload: { email, password, code, tenantId,vToken },
    };
};
export const loginResponse = (message, responseType, responseData) => {
    return {
        type: LOGIN_RESPONSE,
        payload: { message, responseType, responseData },
    };
};
export const sendCodeRequest = (email, password) => {
    return {
        type: SEND_CODE_REQUEST,
        payload: { email, password },
    };
};
export const sendCodeResponse = (message, responseType, responseData) => {
    return {
        type: SEND_CODE_RESPONSE,
        payload: { message, responseType, responseData },
    };
};
export const loginPasswordChangeRequest = (data) => {
    return {
        type: LOGIN_PASSWORD_CHANGE_REQUEST,
        payload: { data },
    };
};
export const loginPasswordChangeResponse = (
    message,
    responseType,
    responseData
) => {
    return {
        type: LOGIN_PASSWORD_CHANGE_RESPONSE,
        payload: { message, responseType, responseData },
    };
};
export const logoutRequest = () => {
    return {
        type: LOGOUT_REQUEST,
    };
};
export const logoutResponse = (message, responseType) => {
    return {
        type: LOGOUT_RESPONSE,
        payload: { message, responseType },
    };
};

export const verifyTokenRequest = (type = 'default', tenantId = '') => {
    return {
        type: VERIFY_TOKEN_REQUEST,
        payload: { type, tenantId }
    };
};
export const verifyTokenResponse = (message, responseType, responseData,type) => {
    return {
        type: VERIFY_TOKEN_RESPONSE,
        payload: { message, responseType, responseData ,type},
    };
};

export const verifyCodeRequest = (email, password, code) => {
    return {
        type: VERIFY_CODE_REQUEST,
        payload: { email, password, code },
    };
};
export const verifyCodeResponse = (message, responseType, responseData) => {
    return {
        type: VERIFY_CODE_RESPONSE,
        payload: { message, responseType, responseData },
    };
};