import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { DANGER, NA, NO, SUCCESS, YES } from 'constants/commonConstants';
import ShowMoreText from 'react-show-more-text';
import { complianceAllHeaders } from 'app/ComplianceLibrary/ViewLawsCompliances/CommonFunctions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { getLawComplianceDetailsByLawComplianceIdRequest, getLawComplianceVersionsbyComplianceIdRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import CommonBreadcrum from './CommonBreadcrum';
import DownloadFileByModel from './DownloadFileByModel';
import CommonSectionRule from './CommonSectionRule';
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class CommonShowComplianceDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            complianceId: this.props.match.params.complianceId,
            complianceData: {},
            mainComplianceData: {},
            complianceVersionId: '',
            complianceVersionList: [],
            versionNumber: false,
            complianceVersionNumber: '',
            downloadFileName: '',
            downloadFileUrl: '',
            complianceVersionLegalUpdate: {}
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState(prevState => ({
            versionNumber: !prevState.versionNumber
        }));
    }
    componentDidMount() {
        this.props.getLawComplianceDetailsByLawComplianceIdRequest(
            this.state.complianceId
        );
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    scrollToSection = (className) => {
        const section = document.querySelector('#' + className);
        let position = section.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 140);
        document.getElementById(className).classList.add('animate__bounceIn');
        setTimeout(function () {
            document
                .getElementById(className)
                .classList.remove('animate__bounceIn');
        }, 2000);
    };
    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    showVersion = (complianceVersionId, legalUpdate = {}) => {
        this.setState({
            complianceVersionId: complianceVersionId,
            complianceVersionLegalUpdate: legalUpdate
        }, () => {
            this.props.getLawComplianceDetailsByLawComplianceIdRequest(this.state.complianceId, complianceVersionId);
        })
    }
    // check if string is url
    checkUrl(str) {
        let isUrl;
        try {
            isUrl = !!new URL(str).hostname;
        } catch (e) {
            isUrl = false;
        }

        return isUrl;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawComplianceDetailsApiResponse &&
            this.props.lawComplianceDetailsApiResponse !==
            nextprops.lawComplianceDetailsApiResponse
        ) {
            if (
                nextprops.lawComplianceDetailsApiResponse.responseType ===
                SUCCESS
            ) {
                this.setState({
                    complianceData:
                        nextprops.lawComplianceDetailsApiResponse.data
                            .compliance
                }, () => {
                    if (!this.state.complianceData?.lawComplianceId) {
                        this.setState({
                            mainComplianceData: this.state.complianceData
                        })
                    } else {
                        this.setState({
                            complianceVersionNumber: this.state.complianceData.versionNumber.toFixed(1)
                        })
                    }
                    this.props.getLawComplianceVersionsbyComplianceIdRequest((this.state.complianceData?.lawComplianceId) ? this.state.complianceData?.lawComplianceId : this.state.complianceData.id);
                });
            } else {
                toastrMessage(
                    nextprops.lawComplianceDetailsApiResponse.message,
                    nextprops.lawComplianceDetailsApiResponse.responseType
                );
            }
        }
        if (nextprops.complianceVersionList && nextprops.complianceVersionList !== this.props.complianceVersionList) {
            this.setState({
                complianceVersionNumber: (!this.state.complianceData?.lawComplianceId) ? ((nextprops.complianceVersionList[0]) ? nextprops.complianceVersionList[0]?.versionNumber.toFixed(1) : '') : this.state.complianceVersionNumber,
                complianceVersionList: nextprops.complianceVersionList,
                mainComplianceData: this.state.complianceData
            })
        }
    }
    render() {
        const formNos =
            this.state?.complianceData?.formNo &&
                this.state?.complianceData?.formNo != ''
                ? this.state?.complianceData?.formNo.split(';')
                : [];
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum
                            type={'statutory-compliances-view-details'}
                        />
                    </Col>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        {this.props.history.length != 1 && (
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.goBack();
                                                }}
                                            >
                                                { }
                                            </a>
                                        )}
                                        <span>
                                            &nbsp;
                                            {this.state.complianceData
                                                ?.complianceCode +
                                                ' - ' +
                                                this.state.complianceData
                                                    ?.complianceTitle}
                                        </span>
                                    </h4>
                                    <div className='d-flex align-items-center'>
                                        {this.state.complianceData && !this.state.complianceData.isActive && (
                                            <b className='text-danger mr-2'>Deactivated</b>
                                        )}
                                        {this.state.complianceVersionLegalUpdate?.legalUpdateCode && (
                                            <a href={() => { return false }} className='view_log_btn mr-2' title='View analysis' onClick={() => {
                                                this.props.history.push('/relevant-updates/view-analysis/' + this.state.complianceVersionLegalUpdate.id)
                                            }}>
                                                Update Analysis:
                                                <span className='ml-1 code-number'>{this.state.complianceVersionLegalUpdate.legalUpdateCode}</span>
                                            </a>)
                                            ||
                                            this.state.complianceVersionList[0] && this.state.complianceVersionList[0].complianceLegalUpdate && this.state.complianceVersionList[0].complianceLegalUpdate.legalUpdateCode && ((
                                                <a href={() => { return false }} className='view_log_btn mr-2' title='View analysis' onClick={() => {
                                                    this.props.history.push('/relevant-updates/view-analysis/' + this.state.complianceVersionList[0].complianceLegalUpdate.id)
                                                }}>
                                                    Update Analysis:
                                                    <span className='ml-1 code-number'>{this.state.complianceVersionList[0].complianceLegalUpdate.legalUpdateCode}</span>
                                                </a>))
                                        }
                                        {this.state?.complianceVersionList?.length > 1 && (
                                            <>
                                                <Dropdown className='version_no' isOpen={this.state.versionNumber} toggle={this.toggle}>
                                                    <DropdownToggle caret color="primary">Version {this.state.complianceVersionNumber}</DropdownToggle>
                                                    <DropdownMenu>
                                                        {this.state.complianceVersionList.map((complianceVersion, key) => {
                                                            return (
                                                                <DropdownItem className={(complianceVersion.id == this.state.complianceVersionId) ? 'active' : ''}
                                                                    onClick={() => {
                                                                        this.showVersion(complianceVersion.id, complianceVersion.complianceLegalUpdate)
                                                                    }}>
                                                                    {complianceVersion.versionNumber.toFixed(1)}
                                                                </DropdownItem>
                                                            );
                                                        })}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="compliance_details">
                                    <Row className='compliance_badge'>
                                        <Col>
                                            {complianceAllHeaders.map(
                                                (itemData, i) => {
                                                    let classNameHighlight = (itemData.highlightClass) ? 'success' : 'primary';
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <a
                                                                href={() => false}
                                                                className={`badge badge-` + classNameHighlight + `  mr-2 p-1`}
                                                                onClick={() =>
                                                                    this.scrollToSection(
                                                                        itemData.field
                                                                    )
                                                                }
                                                                title={__t('common.click_to_move')}
                                                            >
                                                                {itemData.label}
                                                            </a>
                                                        </React.Fragment>
                                                    );
                                                }
                                            )}
                                        </Col>
                                        <Col lg="12">
                                            <div className='compliance-basic-details details-view'>
                                                <div className='compliance-card'>
                                                    <h3>Compliance Details</h3>
                                                    <div className='compliance-details'>
                                                        <ul className="basic-details">
                                                            <li key="sectionReference" id="sectionReference">
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.section_reference')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData.sectionReference ? <CommonSectionRule
                                                                            type={'section'}
                                                                            sectionReference={this.state.complianceData.sectionReference}
                                                                            sectionInfo={(this.state.complianceData.verbatimSectionReference) ? JSON.parse(this.state.complianceData.verbatimSectionReference) : []}
                                                                        />
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li key="lawName" id="">
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.statutory.details.law_short_name')}
                                                                    </b>
                                                                    <p className='has-links'>
                                                                        <a
                                                                            href={() => false}
                                                                            title="View Details"
                                                                            className="btn btn-link p-0 text-left law_id_btn"
                                                                            onClick={() =>
                                                                                this.props.history.push(
                                                                                    '/statutory-compliances/view-law/' +
                                                                                    this.state.complianceData?.lawId
                                                                                )
                                                                            }
                                                                        >
                                                                            {this.state.complianceData?.law?.lawCode +
                                                                                ' - ' +
                                                                                this.state.complianceData?.law?.lawShortName}{' '}
                                                                            <i className="fas fa-external-link-alt"></i>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="ruleReference"
                                                                id="ruleReference"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.rule_reference')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData.ruleReference ?
                                                                            <CommonSectionRule
                                                                                type={'rule'}
                                                                                ruleReference={this.state.complianceData.ruleReference}
                                                                                ruleInfo={(this.state.complianceData.verbatimRuleReference) ? JSON.parse(this.state.complianceData.verbatimRuleReference) : []}
                                                                            />
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="relevantLaws"
                                                                id="relevantLaws"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.relevant_law_id')}
                                                                    </b>
                                                                    <p className='has-links'>
                                                                        {this.state
                                                                            .complianceData
                                                                            .relevantLaws?.length > 0
                                                                            ? this.state
                                                                                .complianceData
                                                                                .relevantLaws.map((law) => {
                                                                                    return (
                                                                                        <a
                                                                                            href={() => false}
                                                                                            title="View Details"
                                                                                            className="btn btn-link p-0 text-left law_id_btn"
                                                                                            onClick={() =>
                                                                                                window.open(
                                                                                                    '/statutory-compliances/view-law/' +
                                                                                                    law.id,
                                                                                                    '_blank'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {law.lawCode +
                                                                                                ' - ' +
                                                                                                law.lawShortName}{' '}
                                                                                            <i className="fas fa-external-link-alt"></i>
                                                                                        </a>
                                                                                    );
                                                                                }) : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className="basic-details">
                                                            <li
                                                                key="complianceApplicability"
                                                                id="complianceApplicability"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.compliance_applicability')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                            ?.complianceApplicability
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="complianceSpecification"
                                                                id="complianceSpecification"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.specification_of_compliance')}
                                                                    </b>
                                                                    {this.getShowMoreTextElement(
                                                                        this.state
                                                                            .complianceData
                                                                            .complianceSpecification
                                                                    )}
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="complianceDetails"
                                                                id="complianceDetails"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.more_details_on_compliance')}
                                                                    </b>
                                                                    {this.getShowMoreTextElement(
                                                                        this.state
                                                                            .complianceData
                                                                            .complianceDetails
                                                                    )}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className="basic-details">
                                                            <li
                                                                key="dueDateDescription"
                                                                id="dueDateDescription"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.due_date_description')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                            .dueDateDescription
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="periodicityName"
                                                                id="periodicityName"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.frequency')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .periodicity
                                                                            ?.periodicityName
                                                                            ? this.state
                                                                                .complianceData
                                                                                .periodicity
                                                                                ?.periodicityName
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="filingRequired"
                                                                id="filingRequired"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.filing_required')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .isFilingRequired
                                                                            ? YES
                                                                            : NO}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="filingTypeName"
                                                                id="filingTypeName"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.filing_type')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .filingType
                                                                            ?.filingTypeName
                                                                            ? this.state
                                                                                .complianceData
                                                                                .filingType
                                                                                ?.filingTypeName
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li key="formNo" id="formNo">
                                                                {formNos.length > 0 ? (
                                                                    <div className="">
                                                                        <b>
                                                                            {__t(
                                                                                'form_labels.compliance.formno_formats'
                                                                            )}
                                                                        </b>
                                                                        <ul className="list-styled pl-0 documents-list">
                                                                            {formNos.map(
                                                                                (
                                                                                    formNo,
                                                                                    key
                                                                                ) => {

                                                                                    let trimedFormNo = formNo.trim();

                                                                                    if (!trimedFormNo.startsWith('#')) {
                                                                                        let tempFormNo = trimedFormNo.includes("#") ? trimedFormNo.split("#")[0].trim() : trimedFormNo;
                                                                                        let isUrl = this.checkUrl(tempFormNo);
                                                                                        let formNodescription = trimedFormNo.includes("#") ? (trimedFormNo.split("#")[1].trim() || null) : null;

                                                                                        if (isUrl === true) {
                                                                                            return (
                                                                                                <li
                                                                                                    key={
                                                                                                        key
                                                                                                    }
                                                                                                >
                                                                                                    <a href={tempFormNo.trim()} target="_blank">
                                                                                                        {tempFormNo.trim()}
                                                                                                    </a>
                                                                                                    {
                                                                                                        formNodescription &&
                                                                                                        (<span>
                                                                                                            {
                                                                                                                ' - ' + formNodescription
                                                                                                            }
                                                                                                        </span>)
                                                                                                    }
                                                                                                </li>
                                                                                            )
                                                                                        } else {
                                                                                            let documentData =
                                                                                                this.state.complianceData?.documents?.find(
                                                                                                    (
                                                                                                        opt
                                                                                                    ) =>
                                                                                                        opt.documentName ==
                                                                                                        tempFormNo
                                                                                                );
                                                                                            if (
                                                                                                documentData &&
                                                                                                documentData.fileUrl &&
                                                                                                documentData.fileUrl !=
                                                                                                ''
                                                                                            ) {
                                                                                                return (
                                                                                                    <li
                                                                                                        key={
                                                                                                            key
                                                                                                        }
                                                                                                    >
                                                                                                        <a
                                                                                                            // target="_blank"
                                                                                                            // rel="noopener noreferrer"
                                                                                                            // href={
                                                                                                            //     documentData.fileUrl
                                                                                                            // }
                                                                                                            onClick={() => {
                                                                                                                this.saveFile({
                                                                                                                    fileName: documentData.fileName,
                                                                                                                    fileUrl: documentData.fileUrl
                                                                                                                })
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                tempFormNo
                                                                                                            }
                                                                                                        </a>
                                                                                                        {
                                                                                                            formNodescription &&
                                                                                                            (<span>
                                                                                                                {
                                                                                                                    ' - ' + formNodescription
                                                                                                                }
                                                                                                            </span>)
                                                                                                        }
                                                                                                    </li>
                                                                                                );
                                                                                            } else {
                                                                                                return (
                                                                                                    <li
                                                                                                        key={
                                                                                                            key
                                                                                                        }
                                                                                                    >
                                                                                                        <a
                                                                                                            href={() => false}
                                                                                                            onClick={() => {
                                                                                                                toastrMessage(
                                                                                                                    __t(
                                                                                                                        'alerts.no_document_found'
                                                                                                                    ),
                                                                                                                    DANGER
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                tempFormNo
                                                                                                            }
                                                                                                        </a>
                                                                                                        {
                                                                                                            formNodescription &&
                                                                                                            (<span>
                                                                                                                {
                                                                                                                    ' - ' + formNodescription
                                                                                                                }
                                                                                                            </span>)
                                                                                                        }
                                                                                                    </li>
                                                                                                );
                                                                                            }

                                                                                        }

                                                                                    } else {
                                                                                        return (
                                                                                            <li
                                                                                                key={
                                                                                                    key
                                                                                                }
                                                                                            >
                                                                                                <a
                                                                                                    href={() => false}
                                                                                                >
                                                                                                    {
                                                                                                        trimedFormNo.slice(1).trim()
                                                                                                    }
                                                                                                </a>
                                                                                            </li>
                                                                                        );
                                                                                    }

                                                                                }
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                ) : (
                                                                    <div className="details-info">
                                                                        <b>
                                                                            {__t(
                                                                                'form_labels.compliance.formno_formats'
                                                                            )}
                                                                        </b>
                                                                        <p>{NA}</p>
                                                                    </div>
                                                                )}
                                                            </li>
                                                            <li
                                                                key="isProofRequired"
                                                                id="isProofRequired"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.need_compliance_evidence')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .isProofRequired
                                                                            ? YES
                                                                            : NO}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            {/* <li
                                                                key="complianceTitle"
                                                                id="complianceTitle"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.compliance_title')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                                .complianceTitle
                                                                        }
</p>
                                                                </div>
                                                            </li> */}
                                                        </ul>
                                                        {/* <ul className="basic-details">
                                                            <li key="lawName" id="">
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.laws.law_name')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                                ?.law
                                                                                ?.lawName
                                                                        }
</p>
                                                                </div>
                                                            </li>
                                                        </ul> */}
                                                    </div>
                                                </div>
                                                <div className='compliance-card'>
                                                    <h3>Consequence of Non-Compliance</h3>
                                                    <div className='compliance-details'>
                                                        <ul className="basic-details">
                                                            <li
                                                                key="penaltyDescription"
                                                                id="penaltyDescription"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.consequence_of_non_compliance')}
                                                                    </b>
                                                                    {this.getShowMoreTextElement(
                                                                        this.state
                                                                            .complianceData
                                                                            .penaltyDescription
                                                                    )}
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="penaltyTypeName"
                                                                id="penaltyTypeName"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.consequence_category')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .penaltyType?.length > 0
                                                                            ? this.state
                                                                                .complianceData
                                                                                .penaltyType.map((penaltyType) => penaltyType.penaltyTypeName).join("; ")
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="monetaryPenaltyValue"
                                                                id="monetaryPenaltyValue"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.monetary_penalty_value')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                            .monetaryPenaltyValue
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className="basic-details">
                                                            <li
                                                                key="impactManagementPersonnel"
                                                                id="impactManagementPersonnel"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.management_personnel_impact')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .impactManagementPersonnels?.length > 0
                                                                            ? this.state
                                                                                .complianceData
                                                                                .impactManagementPersonnels.map((impactManagementPersonnel) => impactManagementPersonnel.impactManagementPersonnelName).join("; ")
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="riskRatingName"
                                                                id="riskRatingName"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.risk_level')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .riskRating
                                                                            ?.riskRatingName
                                                                            ? this.state
                                                                                .complianceData
                                                                                .riskRating
                                                                                ?.riskRatingName
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            {/* <li
                                                                key="compliancePeriod"
                                                                id="compliancePeriod"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.compliance_period')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .compliancePeriod
                                                                            ? this.state
                                                                                .complianceData
                                                                                .compliancePeriod
                                                                            : NA}
</p>
                                                                </div>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='compliance-card'>
                                                    <h3>Other Details of Compliance</h3>
                                                    <div className='compliance-details'>
                                                        {/* <ul className="basic-details">
                                                        </ul> */}
                                                        <ul className="basic-details">
                                                            <li
                                                                key="complianceTypeName"
                                                                id="complianceTypeName"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.compliance_class')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                            .complianceType
                                                                            ?.complianceTypeName
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="complianceCategoryName"
                                                                id="complianceCategoryName"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.compliance_classification')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                            .complianceCategory
                                                                            ?.complianceCategoryName
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="applicabilityCondition"
                                                                id="applicabilityCondition"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.applicability_type')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                            .applicabilityConditionName
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="enforcementDate"
                                                                id="enforcementDate"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.date_of_enforcement')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.complianceData
                                                                            .enforcementDate
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className="basic-details">
                                                            <li
                                                                key="eventName"
                                                                id="eventName"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.if_event_event_name')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .event
                                                                            ? (
                                                                                <a
                                                                                    href={() => false}
                                                                                    title={__t('common.compliance_hierarchy')}
                                                                                    className="btn btn-link law_id_btn"
                                                                                    onClick={() => {
                                                                                        window.open(
                                                                                            '/event-compliances/' +
                                                                                            this.state
                                                                                                .complianceData
                                                                                                .event?.id,
                                                                                            '_blank'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {this.state
                                                                                        .complianceData
                                                                                        .event
                                                                                        ?.eventName} &nbsp;<i className="fas fa-external-link-alt"></i>
                                                                                </a>
                                                                            )
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="triggeringCompliance"
                                                                id="triggeringCompliance"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.triggering_compliance')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .triggeringCompliance &&
                                                                            this.state
                                                                                .complianceData
                                                                                .triggeringCompliance !=
                                                                            null &&
                                                                            this.state
                                                                                .complianceData
                                                                                .triggeringCompliance
                                                                                ?.complianceTitle !=
                                                                            '' ? (
                                                                            <>
                                                                                <a
                                                                                    href={() => false}
                                                                                    title="View Details"
                                                                                    className="btn btn-link law_id_btn"
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            '/statutory-compliances/view-details/' +
                                                                                            this
                                                                                                .state
                                                                                                .complianceData
                                                                                                .triggeringComplianceId,
                                                                                            '_blank'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {this
                                                                                        .state
                                                                                        .complianceData
                                                                                        .triggeringCompliance
                                                                                        ?.complianceCode +
                                                                                        ' - ' +
                                                                                        this
                                                                                            .state
                                                                                            .complianceData
                                                                                            .triggeringCompliance
                                                                                            ?.complianceTitle}{' '}
                                                                                    <i className="fas fa-external-link-alt"></i>
                                                                                </a>
                                                                            </>
                                                                        ) : (
                                                                            NA
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="governingAuthorityWebsite"
                                                                id="governingAuthorityWebsite"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.governing_authority_website')}
                                                                    </b>
                                                                    <p>
                                                                        {this.state
                                                                            .complianceData
                                                                            .governingAuthorityWebsite
                                                                            ? this.state
                                                                                .complianceData
                                                                                .governingAuthorityWebsite
                                                                            : NA}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li
                                                                key="briefNotes"
                                                                id="briefNotes"
                                                            >
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t('form_labels.compliance.brief_notes')}
                                                                    </b>
                                                                    {this.getShowMoreTextElement(
                                                                        this.state
                                                                            .complianceData
                                                                            .briefNotes
                                                                    )}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <DownloadFileByModel
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawComplianceDetailsApiResponse: makeLawComplianceSelectField(
        'lawComplianceDetailsApiResponse'
    ),
    complianceVersionList: makeLawComplianceSelectField('complianceVersionList')
});
const withConnect = connect(mapStatetoProps, {
    getLawComplianceDetailsByLawComplianceIdRequest,
    getLawComplianceVersionsbyComplianceIdRequest
});
export default compose(
    withConnect,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(CommonShowComplianceDetails);
