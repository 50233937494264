import {
    ACTIVATE_ACCOUNT_TOKEN_RESPONSE,
    ACTIVATE_ACCOUNT_RESPONSE
} from './actionTypes';
import produce from 'immer';
export const initialState = {
    activateAccountTokenApiResponse: null,
    activateAccountApiResponse: null
};
const activateAccount = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ACTIVATE_ACCOUNT_TOKEN_RESPONSE:
                draft.activateAccountTokenApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            case ACTIVATE_ACCOUNT_RESPONSE:
                draft.activateAccountApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                    responseData: action.payload.responseData,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default activateAccount;
