import React, { Component } from 'react';
import { Button, Collapse, CustomInput } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DANGER } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form } from 'formik';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import CustomisedModal from 'components/Application/CustomisedModal';
import {
    getTrackAllocationByComplianceRequest
} from 'store/actions';
import { toastrMessage } from 'helpers/messageHelper';

class EditMapLocations extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            lawComplianceData: {},
            collapseEntityId: '',
            collapseStateId: '',
            entityListWithLocations: [],
            entityWithLocations: [],
            trackAllocationIds: [],
            deletedTrackAllocationIds: []
        };
        this.toggle = this.toggle.bind(this);
        this.toggleSub = this.toggleSub.bind(this);
    }

    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({
            collapseEntityId:
                this.state.collapseEntityId == Number(event)
                    ? 0
                    : Number(event),
        });
    }
    toggleSub(e) {
        let event = e.target.dataset.event;
        this.setState({
            collapseStateId:
                this.state.collapseStateId == Number(event)
                    ? 0
                    : Number(event),
        });
    }
    handleChange = (e, entityId, trackAllocationId) => {
        // Destructuring
        const { checked } = e.target;
        let deletedTrackAllocationIds = [...this.state.deletedTrackAllocationIds];
        if (checked) {
            this.setState({
                deletedTrackAllocationIds: deletedTrackAllocationIds.filter((tA) => tA !== trackAllocationId)
            });
        } else {
            deletedTrackAllocationIds.push(Number(trackAllocationId));
            this.setState({
                deletedTrackAllocationIds: deletedTrackAllocationIds
            });
        }
    };
    formSubmit = () => {
        if (this.state.deletedTrackAllocationIds.length == 0) {
            toastrMessage("There are no changes found in the mapped locations.", DANGER)
        } else {
            this.props.onFormSubmit({
                complianceId: this.state.lawComplianceData.id,
                trackAllocationId: this.state.deletedTrackAllocationIds
            });
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.lawComplianceDataForEntity && nextprops.lawComplianceDataForEntity !== this.props.lawComplianceDataForEntity) {
            this.setState({
                lawComplianceData: nextprops.lawComplianceDataForEntity,
                entityListWithLocations: []
            }, () => {
                if (this.state.lawComplianceData?.id) {
                    this.props.getTrackAllocationByComplianceRequest(this.state.lawComplianceData.id, {
                        screenType: nextprops.screenType,
                        allocationReview: true
                    });
                }
            })
        }
        if (nextprops.trackAllocationList && nextprops.trackAllocationList !== this.props.trackAllocationList) {
            let trackAllocationIds = [];
            let info = nextprops.trackAllocationList.reduce((trackInfo, obj) => {
                trackAllocationIds.push(Number(obj.id));
                let { entityId, entity, entityLocation } = obj;
                trackInfo[entityId] = trackInfo[entityId] ?? {
                    entityId: entityId,
                    entityName: entity.entityName,
                    entityLocations: [],
                };
                trackInfo[entityId].entityLocations.push({
                    ...entityLocation,
                    trackAllocationId: obj.id
                });
                return trackInfo;
            }, []);
            // grouping all locations data by state and their cities
            let entityListWithLocations = info.map((opt) => {
                let entityLocationsList = opt.entityLocations.reduce(function (
                    res,
                    obj
                ) {
                    if (!res[obj.stateId]) {
                        res[obj.stateId] = {
                            countryId: obj.countryId,
                            stateId: obj.stateId,
                            stateName: obj?.state?.stateName,
                            cities: [],
                        };
                    }
                    res[obj.stateId].cities.push({
                        trackAllocationId: obj.trackAllocationId,
                        locationId: obj.id,
                        cityId: obj.cityId,
                        cityName: obj?.city?.cityName,
                        pincode: obj.pincode,
                    });
                    return res;
                },
                    Object.create(null));
                return {
                    entityId: opt.entityId,
                    entityName: opt.entityName,
                    entityLocations: Object.values(entityLocationsList).map(
                        (option) => {
                            if (option.cities.length > 0) {
                                return option;
                            }
                        }
                    ),
                };
            });
            this.setState({
                trackAllocationIds: trackAllocationIds,
                deletedTrackAllocationIds: [],
                entityListWithLocations: entityListWithLocations
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <Formik
                        initialValues={{}}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                {this.state.entityListWithLocations?.length > 0 && (
                                    <React.Fragment>
                                        <div className="location_modal allocation_entity">
                                            <ul className="list_group_main">
                                                {
                                                    this.state.entityListWithLocations.map(
                                                        (item, idx) => {
                                                            return (
                                                                item.entityLocations
                                                                    .length > 0 && (
                                                                    <li
                                                                        className="list-group-item"
                                                                        key={idx}
                                                                    >
                                                                        <label onClick={
                                                                            this.toggle
                                                                        }
                                                                            data-event={
                                                                                item.entityId
                                                                            }>
                                                                            {
                                                                                item.entityName
                                                                            }
                                                                        </label>
                                                                        <Collapse
                                                                            isOpen={
                                                                                this.state
                                                                                    .collapseEntityId ===
                                                                                item.entityId
                                                                            }
                                                                        >
                                                                            <ul className="list_group_sub d-block w-100 m-0">
                                                                                {item
                                                                                    .entityLocations
                                                                                    ?.length >
                                                                                    0 &&
                                                                                    item.entityLocations.map(
                                                                                        (
                                                                                            subItem,
                                                                                            subIdx
                                                                                        ) => (
                                                                                            <React.Fragment
                                                                                                key={
                                                                                                    item.entityId +
                                                                                                    subItem.stateId
                                                                                                }
                                                                                            >
                                                                                                <li
                                                                                                    className={
                                                                                                        `list-group-item ` +
                                                                                                        (this
                                                                                                            .state
                                                                                                            .collapseStateId ==
                                                                                                            item.entityId +
                                                                                                            subItem.stateId
                                                                                                            ? 'active'
                                                                                                            : '')
                                                                                                    }
                                                                                                >
                                                                                                    <label className='m-0' onClick={
                                                                                                        this
                                                                                                            .toggleSub
                                                                                                    }
                                                                                                        data-event={
                                                                                                            item.entityId +
                                                                                                            subItem.stateId
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            subItem.stateName
                                                                                                        }
                                                                                                    </label>
                                                                                                    <Collapse
                                                                                                        isOpen={
                                                                                                            this
                                                                                                                .state
                                                                                                                .collapseStateId ==
                                                                                                            item.entityId +
                                                                                                            subItem.stateId
                                                                                                        }
                                                                                                    >
                                                                                                        <ul className="list_group_child m-0 ml-2 p-2 allocation_entity_city">
                                                                                                            {subItem
                                                                                                                .cities
                                                                                                                ?.length >
                                                                                                                0 &&
                                                                                                                subItem.cities.map(
                                                                                                                    (
                                                                                                                        subChildItem,
                                                                                                                        subChildIdx
                                                                                                                    ) => (
                                                                                                                        <li
                                                                                                                            className={(this.props.screenType != 'to_do' && this.props.screenType != 'rejected') ? " disabled list-group-item" : "list-group-item"}
                                                                                                                            key={
                                                                                                                                subChildIdx
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <CustomInput type="checkbox"
                                                                                                                                className='new_addition_check'
                                                                                                                                id={`locations_` + subChildItem.trackAllocationId}
                                                                                                                                name={`locations_` + subChildItem.trackAllocationId}
                                                                                                                                value={subChildItem.trackAllocationId}
                                                                                                                                onChange={(e) => { this.handleChange(e, item.entityId, subChildItem.trackAllocationId); }}
                                                                                                                                checked={this.state.trackAllocationIds.includes(subChildItem.trackAllocationId) && !this.state.deletedTrackAllocationIds.includes(subChildItem.trackAllocationId)}
                                                                                                                                disabled={(this.props.screenType != 'to_do' && this.props.screenType != 'rejected') ? true : false}
                                                                                                                                htmlFor={
                                                                                                                                    `locations_` +
                                                                                                                                    subChildItem.trackAllocationId
                                                                                                                                }
                                                                                                                                label={
                                                                                                                                    subChildItem.cityName
                                                                                                                                } />
                                                                                                                        </li>
                                                                                                                    )
                                                                                                                )}
                                                                                                        </ul>
                                                                                                    </Collapse>
                                                                                                </li>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )}
                                                                            </ul>
                                                                        </Collapse>
                                                                    </li>
                                                                )
                                                            );
                                                        }
                                                    )}
                                            </ul>
                                        </div>
                                        {(this.props.screenType == 'to_do' || this.props.screenType == 'rejected') && (
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="mr-2"
                                                    onClick={this.formSubmit}
                                                >
                                                    Update Mapped Location(s)
                                                </Button>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </Form>
                        )}
                    </Formik>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    trackAllocationList: makeAllocationSelectField('trackAllocationList'),
});
const withConnect = connect(mapStatetoProps, {
    getTrackAllocationByComplianceRequest
});
export default compose(withConnect, withRouter)(EditMapLocations);
