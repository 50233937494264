import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import eventAllocationReducer from 'store/EventAllocation/reducer';
import eventAllocationSaga from 'store/EventAllocation/saga';
import {
    getOngoingEventsListRequest,
    updatePlanEventStatusByPlanEventIdRequest,
    cancelPlanEventByPlanEventIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { initialOngoingFilterDataState, listColumns } from './CommonFunctions';
import __t from 'i18n/translator';
import { makeEventAllocationSelectField } from 'store/EventAllocation/selector';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import OngoingEventFilterForm from './OngoingEventFilterForm';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import Switch from 'react-switch';
import moment from 'moment';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import { SUCCESS } from 'constants/commonConstants';
import ViewEventTasksModal from '../ViewEventTasksModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_ONGOING_EVENTS,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { getFirstCapitalize } from 'helpers/generalUtils';

const eventAllocationKey = 'eventAllocation';
const withEventAllocationReducer = injectReducer({
    key: eventAllocationKey,
    reducer: eventAllocationReducer,
});
const withEventAllocationSaga = injectSaga({
    key: eventAllocationKey,
    saga: eventAllocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ongoingEventList: [],
            searchFormData: initialOngoingFilterDataState(),
            isViewEventTaskModalOpen: false,
            eventData: {},
            resetTableSection: false,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        this.getOngoingEventsListRequest({});
    }
    getOngoingEventsListRequest = (values = {}) => {
        let apiData = {
            filter: values,
        };
        this.props.getOngoingEventsListRequest(apiData, true);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
            },
            () => {
                this.getOngoingEventsListRequest(this.state.searchFormData);
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialOngoingFilterDataState(),
            },
            () => {
                this.getOngoingEventsListRequest({});
            }
        );
    };
    onStatusChange(checked, e, id) {
        this.props.updatePlanEventStatusByPlanEventIdRequest(id, checked);
    }
    cancelEvent = async (eventId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.event_cancel')
        );
        if (response) {
            this.props.cancelPlanEventByPlanEventIdRequest(eventId);
        }
    };
    openViewEventTaskModal = (eventData) => {
        this.setState({
            isViewEventTaskModalOpen: true,
            eventData: eventData,
        });
    };
    closeViewEventTaskModal = () => {
        this.setState({
            isViewEventTaskModalOpen: false,
            eventData: {},
        });
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((eventData, i) => {
            let currentDate = moment().format('YYYY-MM-DD'); //today
            let occurrenceDate = moment(
                eventData.eventOccurrenceDate,
                'DD-MM-YYYY'
            ).format('YYYY-MM-DD'); //occurrence date

            let cancelTillDate = moment(
                eventData.cancelTillDate,
                'DD-MM-YYYY'
            ).format('YYYY-MM-DD'); //cancel Till Date
            let actions = (
                <div className="text-right mr-1">
                    {checkModulePermission(
                        MODULE_ONGOING_EVENTS,
                        PERMISSION_DELETE
                    ) === true &&
                        cancelTillDate >= currentDate && (
                            <a
                                href={() => false}
                                title={__t('tables.event.cancel_event')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.cancelEvent(eventData.id);
                                }}
                            >
                                <i className="fas fa-times-circle"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            this.openViewEventTaskModal(eventData);
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                </div>
            );
            let isActive = '';
            if (checkModulePermission(MODULE_ONGOING_EVENTS, PERMISSION_EDIT)) {
                isActive = (
                    <div className="d-flex">
                        <Switch
                            className="switch-sm"
                            onColor="#9BD732"
                            onChange={this.onStatusChange}
                            id={String(eventData.id)}
                            checked={eventData.isActive ? true : false}
                            disabled={
                                occurrenceDate < currentDate ? true : false
                            }
                        // disabled={(occurrenceDate < currentDate || eventData.isActive === false) ? true : false}
                        />
                        &nbsp;
                    </div>
                );
            } else {
                isActive = (
                    <div className="d-flex">
                        <Switch
                            className="switch-sm"
                            onColor="#9BD732"
                            onChange={this.onStatusChange}
                            id={String(eventData.id)}
                            checked={eventData.isActive ? true : false}
                            disabled={true}
                        />
                        &nbsp;
                    </div>
                );
            }
            return {
                entityName: eventData.entity?.entityName,
                countryName: eventData.event?.country?.countryName,
                stateName: eventData.event.state?.stateName,
                cityName: eventData.event.city?.cityName,
                eventName: eventData.event?.eventName,
                type: (eventData?.eventType) ? getFirstCapitalize(eventData?.eventType) : '',
                eventOccurrenceDate: eventData.eventOccurrenceDate,
                cancelTillDate: eventData.cancelTillDate,
                isActive: isActive,
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.ongoingEventList &&
            this.props.ongoingEventList !== nextprops.ongoingEventList
        ) {
            this.setState({
                resetTableSection: false,
                ongoingEventList: this.generateTable(
                    nextprops.ongoingEventList
                ),
            });
        }
        if (
            nextprops.updateStatusEventAllocationApiResponse &&
            this.props.updateStatusEventAllocationApiResponse !==
            nextprops.updateStatusEventAllocationApiResponse
        ) {
            toastrMessage(
                nextprops.updateStatusEventAllocationApiResponse.message,
                nextprops.updateStatusEventAllocationApiResponse.responseType
            );
            if (
                nextprops.updateStatusEventAllocationApiResponse
                    .responseType === SUCCESS
            ) {
                this.setState(
                    {
                        resetTableSection: true,
                    },
                    () => {
                        this.getOngoingEventsListRequest(
                            this.state.searchFormData
                        );
                    }
                );
            }
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.ongoingEventList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'ongoing-event'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={__t('page_headers.ongoing_events')}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <OngoingEventFilterForm
                                    filterData={this.state.searchFormData}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <CardBody className="custom_table">
                                {!this.state.resetTableSection && (
                                    <CustomisedDatatable tableRecords={data} />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <ViewEventTasksModal
                        modalName={__t('form_labels.events.tasks')}
                        isModalOpen={this.state.isViewEventTaskModalOpen}
                        eventData={this.state.eventData}
                        onModalDismiss={() => this.closeViewEventTaskModal()}
                    />
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    ongoingEventList: makeEventAllocationSelectField('ongoingEventList'),
    updateStatusEventAllocationApiResponse: makeEventAllocationSelectField(
        'updateStatusEventAllocationApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getOngoingEventsListRequest,
    updatePlanEventStatusByPlanEventIdRequest,
    cancelPlanEventByPlanEventIdRequest,
});
export default compose(
    withConnect,
    withEventAllocationReducer,
    withEventAllocationSaga,
    withRouter
)(Index);
