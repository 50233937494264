export const GET_TASK_STATUS_LIST_REQUEST = 'get_task_status_list_request';
export const GET_TASK_STATUS_LIST_RESPONSE = 'get_task_status_list_response';
export const GET_ASSIGNED_TASK_SEARCH_LIST_REQUEST =
    'get_assigned_task_search_list_request';
export const GET_ASSIGNED_TASK_SEARCH_LIST_RESPONSE =
    'get_assigned_task_search_list_response';

export const GET_USER_ASSIGNED_TASK_SEARCH_LIST_REQUEST =
    'get_user_assigned_task_search_list_request';
export const GET_USER_ASSIGNED_TASK_SEARCH_LIST_RESPONSE =
    'get_user_assigned_task_search_list_response';

export const GET_KNOW_YOUR_COMPLIANCES_LIST_REQUEST =
    'get_know_your_compliances_list_request';
export const GET_KNOW_YOUR_COMPLIANCES_LIST_RESPONSE =
    'get_know_your_compliances_list_response';

export const GET_REPORT_TASK_SEARCH_LIST_REQUEST =
    'get_report_task_search_list_request';
export const GET_REPORT_TASK_SEARCH_LIST_RESPONSE =
    'get_report_task_search_list_response';
export const GET_REVIEW_TASK_SEARCH_LIST_REQUEST =
    'get_review_task_search_list_request';
export const GET_REVIEW_TASK_SEARCH_LIST_RESPONSE =
    'get_review_task_search_list_response';
export const GET_AUDIT_TASK_SEARCH_LIST_REQUEST =
    'get_audit_task_search_list_request';
export const GET_AUDIT_TASK_SEARCH_LIST_RESPONSE =
    'get_audit_task_search_list_response';

export const GET_TASK_DETAILS_BY_TASK_ID_REQUEST =
    'get_task_details_by_task_id_request';
export const GET_TASK_DETAILS_BY_TASK_ID_RESPONSE =
    'get_task_details_by_task_id_response';

export const GET_TASK_USER_DETAILS_BY_TASK_ID_REQUEST =
    'get_task_user_details_by_task_id_request';
export const GET_TASK_USER_DETAILS_BY_TASK_ID_RESPONSE =
    'get_task_user_details_by_task_id_response';

export const GET_TASK_ACTIVITY_LIST_BY_TASK_ID_REQUEST =
    'get_task_activity_list_by_task_id_request';
export const GET_TASK_ACTIVITY_LIST_BY_TASK_ID_RESPONSE =
    'get_task_activity_list_by_task_id_response';

export const GET_TASK_DOCUMENTS_BY_TASK_ID_REQUEST =
    'get_task_documents_by_task_id_request';
export const GET_TASK_DOCUMENTS_BY_TASK_ID_RESPONSE =
    'get_task_documents_by_task_id_response';
export const REOPEN_TASK_BY_TASK_ID_REQUEST = 'reopen_task_by_task_id_request';
export const REOPEN_TASK_BY_TASK_ID_RESPONSE =
    'reopen_task_by_task_id_response';
export const SEND_FOR_AUDIT_TASK_BY_TASK_IDS_REQUEST =
    'send_for_audit_task_by_task_ids_request';
export const SEND_FOR_AUDIT_TASK_BY_TASK_IDS_RESPONSE =
    'send_for_audit_task_by_task_ids_response';
export const REPORTING_TASK_BY_TASK_ID_REQUEST =
    'reporting_task_by_task_id_request';
export const REPORTING_TASK_BY_TASK_ID_RESPONSE =
    'reporting_task_by_task_id_response';
export const CHANGE_TASK_DETAILS_BY_TASK_ID_REQUEST =
    'change_task_details_by_task_id_request';
export const CHANGE_TASK_DETAILS_BY_TASK_ID_RESPONSE =
    'change_task_details_by_task_id_response';

export const ADD_SUB_TASK_REQUEST = 'add_sub_task_request';
export const ADD_SUB_TASK_RESPONSE = 'add_sub_task_response';
export const GET_ALL_SUB_TASK_BY_TASK_ID_LIST_REQUEST =
    'get_all_sub_task_by_task_id_list_request';
export const GET_ALL_SUB_TASK_BY_TASK_ID_LIST_RESPONSE =
    'get_all_sub_task_by_task_id_list_response';
export const UPDATE_SUB_TASK_REQUEST = 'update_sub_task_request';
export const UPDATE_SUB_TASK_RESPONSE = 'update_sub_task_response';
export const DELETE_SUB_TASK_BY_ID_REQUEST = 'delete_sub_task_by_id_request';
export const DELETE_SUB_TASK_BY_ID_RESPONSE = 'delete_sub_task_by_id_response';

export const GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_REQUEST =
    'get_all_transaction_document_list_request';
export const GET_ALL_TRANSACTIONAL_DOCUMENT_LIST_RESPONSE =
    'get_all_transaction_document_list_response';

export const ADD_COLLABORATOR_BY_TASK_ID_REQUEST =
    'add_collaborator_by_task_id_request';
export const ADD_COLLABORATOR_BY_TASK_ID_RESPONSE =
    'add_collaborator_by_task_id_response';

export const DELETE_COLLABORATOR_BY_TASK_ID_REQUEST =
    'delete_collaborator_by_task_id_request';
export const DELETE_COLLABORATOR_BY_TASK_ID_RESPONSE =
    'delete_collaborator_by_task_id_response';

export const GET_COLLABORATOR_LIST_BY_TASK_ID_REQUEST =
    'get_collaborator_list_by_task_id_request';
export const GET_COLLABORATOR_LIST_BY_TASK_ID_RESPONSE =
    'get_collaborator_list_by_task_id_response';

export const ADD_FILE_UPLOAD_REQUEST = 'add_file_upload_request';
export const ADD_FILE_UPLOAD_RESPONSE = 'add_file_upload_response';

export const UPDATE_QUICK_TASK_REQUEST = 'update_quick_task_request';
export const UPDATE_QUICK_TASK_RESPONSE = 'update_quick_task_response';

export const CLEAR_TASK_SEARCH_API_RESPONSE = 'clear_task_search_api_response';
