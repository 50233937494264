import { DEFAULT_PAGE_LENGTH, DEFAULT_PAGE_SIZE_OPTIONS } from 'constants/commonConstants';
import __t from 'i18n/translator';
import React from 'react';
import { useTable, useExpanded, useSortBy, usePagination, useFilters, useGlobalFilter, useRowSelect } from 'react-table';
import { Col, Row } from 'reactstrap';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <div className="custom-control custom-checkbox singal-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    ref={resolvedRef}
                    {...rest}
                />
                <label className="custom-control-label"></label>
            </div>
        );
    }
);
function Table({ className, colSpan, columns, data, onFetchData, onSort, onSearch, pageCount: controlledPageCount, isPaginated, totalRecords, preFilledSearchText, displayEntries, searching, onSelectedRows, selectionColumn, paging }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        state: { pageIndex, pageSize, sortBy, globalFilter },
    } = useTable(
        {
            className,
            colSpan,
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: DEFAULT_PAGE_LENGTH,
                globalFilter: preFilledSearchText
                // sortBy: [],
            },
            manualPagination: true,
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: controlledPageCount,
            autoResetPagination: true
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded, // Use the useExpanded plugin hook
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columnsData => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    disableSortBy: true,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div className='d-flex align-items-center justify-content-center pb-1'>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => {
                        return row.original.checkbox ? (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ) : null;
                    },
                },
                ...columnsData,
            ]);
        }
    );

    // Add Only Id & Status object to the selectedFlatRows
    React.useEffect(() => {
        onSelectedRows && onSelectedRows(selectedFlatRows.filter(d => {
            return (d.original.checkbox == true)
        }).map((d1) => d1.original));
    }, [selectedFlatRows]);

    React.useEffect(() => {
        onSort && onSort({ sortBy, pageIndex, pageSize, globalFilter });
        onSearch && onSearch({ sortBy, pageIndex, pageSize, globalFilter });
    }, [onFetchData, onSort, onSearch, pageIndex, pageSize, sortBy, globalFilter]);

    React.useEffect(() => {
        onFetchData && onFetchData({ sortBy, pageIndex, pageSize, globalFilter });
    }, [onFetchData, pageIndex, pageSize]);

    const bottomLimitData = (((pageIndex + 1) * pageSize) - pageSize) + 1;
    const bottomLimit = (totalRecords < bottomLimitData) ? 1 : bottomLimitData;
    const topLimitData = (pageIndex + 1) * pageSize;
    const topLimit = (topLimitData > totalRecords) ? totalRecords : topLimitData;
    let pages = 1;
    if (pageSize != 0) {
        pages = Math.ceil(totalRecords / pageSize);
    }
    // Render the UI for your table
    return (
        <React.Fragment>
            <Row className='pb-3 d-flex align-items-center'>
                <Col lg="6">
                    {displayEntries === true && (
                        <>
                            {"Show entries"}
                            <select
                                className='ml-2'
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {DEFAULT_PAGE_SIZE_OPTIONS.map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </>
                    )}
                </Col>
                <Col lg="6">
                    {searching === true && (
                        <>
                            <div className='search_box'>
                                <input
                                    type="text"
                                    className='search_input'
                                    value={globalFilter || ''}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        // useAsyncDebounce = (value) => {
                                        setGlobalFilter(value || undefined);
                                        // }
                                    }}
                                />
                                <svg
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
            <table className={className} {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={(!column.disableSortBy) ? column.isSorted
                                    ? column.isSortedDesc
                                        ? " sorting_desc"
                                        : " sorting_asc"
                                    : " sorting " : "" + column?.className}>
                                    {(column.TransHeader) ? __t(column.TransHeader) : column.render('Header')}
                                </th>
                            )
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps({ className: row.original?.className ? row.original.className : '' })}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {rows.length === 0 && (
                        <tr>
                            <td colSpan={colSpan}>No Record Found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {paging && (
                <Row className='pt-3 d-flex align-items-center'>
                    <Col lg="6">
                        {"Showing " + bottomLimit + " to " + topLimit + " of " + totalRecords + " entries"}
                    </Col>
                    <Col lg="6">
                        {Boolean(isPaginated) && <>
                            <ul className='pagination float-right m-0'>
                                <li key="pre" onClick={() => previousPage()} className={canPreviousPage ? "page-item" : " disabled page-item"}>
                                    <a href={() => false} aria-label="Previous" className="page-link page-link"><span>Previous</span></a>
                                </li>

                                {(pages) > 5 ? (
                                    <>
                                        {pageIndex >= 4 && (
                                            <li onClick={() => previousPage()} className={"page-item"}>
                                                <a href={() => false} className="page-link page-link">...
                                                    <span className="sr-only">(current)</span>
                                                </a>
                                            </li>
                                        )}
                                        {
                                            pageOptions.map((pageKey, index) => {
                                                let pageNumber = pageKey + 1;
                                                const pageView = 5
                                                let pageIndexMap = (pageIndex == pages - 2 && pages % 5 == 0) ? pageIndex - 5 : pageIndex;
                                                if (pageIndexMap < 0)
                                                    pageIndexMap = 0
                                                const startFrom = ((pageIndexMap + 1) % pageView == 0 ? (pageIndexMap + 1) : ((Math.ceil((pageIndexMap + 1) / pageView) * pageView) - pageView));
                                                if (index >= startFrom - 1 && index < startFrom + pageView) {
                                                    const pageElement = (
                                                        <React.Fragment key={pageKey}>
                                                            <li onClick={() => gotoPage(pageKey)} className={(pageKey === pageIndex) ? " active page-item" : "page-item"}>
                                                                <a href={() => false} className="page-link page-link">{pageNumber}
                                                                    <span className="sr-only">(current)</span>
                                                                </a>
                                                            </li>
                                                            {(!(index + 1 >= startFrom && index + 1 < startFrom + pageView)) && !(pageIndex >= pages - 7) &&
                                                                <li onClick={() => nextPage()} className={"page-item"}>
                                                                    <a href={() => false} className="page-link page-link">...
                                                                        <span className="sr-only">(current)</span>
                                                                    </a>
                                                                </li>
                                                            }
                                                        </React.Fragment>
                                                    );

                                                    return pageElement;
                                                }

                                                return null;
                                            })

                                        }
                                    </>
                                ) : (
                                    <>
                                        {pageOptions.map((pageKey, index) => {
                                            let pageNumber = pageKey + 1;
                                            return (
                                                <React.Fragment key={pageKey}>
                                                    <li onClick={() => gotoPage(pageKey)} className={(pageKey == pageIndex) ? " active page-item" : "page-item"}>
                                                        <a href={() => false} className="page-link page-link">{pageNumber}
                                                            <span className="sr-only">(current)</span>
                                                        </a>
                                                    </li>
                                                </React.Fragment>
                                            )
                                        })}
                                    </>
                                )}
                                <li key="next" onClick={() => nextPage()} className={canNextPage ? "page-item" : " disabled page-item"}>
                                    <a href={() => false} aria-label="Next" className="page-link page-link"><span>Next</span></a>
                                </li>
                            </ul>
                        </>}
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
}

class CustomisedReactTableWithPaginationAndRowSelection extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Table
                    {...this.props}
                    columns={this.props.tableData.columns}
                    data={this.props.tableData.rows}
                    colSpan={
                        this.props.colSpan
                            ? this.props.colSpan
                            : this.props.tableData?.columns?.length
                    }
                    pageCount={this.props.pageCount}
                    displayEntries={this.props.displayEntries}
                    searching={this.props.searching}
                    paging={this.props.paging}
                    onFetchData={this.props.handleFetchData}
                    onSort={this.props.handleSort}
                    onSearch={this.props.handleSearch}
                    preFilledSearchText={this.props.preFilledSearchText}
                    totalRecords={this.props.totalRecords}
                    isPaginated={true}
                    onSelectedRows={rows => this.props.onchangeCheckbox(rows)}
                />
            </React.Fragment>
        );
    }
}

export default CustomisedReactTableWithPaginationAndRowSelection;
