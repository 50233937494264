import produce from 'immer';
import { ADD_INDUSTRY_RESPONSE, DELETE_INDUSTRY_BY_INDUSTRY_ID_RESPONSE, GET_ALL_INDUSTRY_LIST_RESPONSE, GET_INDUSTRY_BY_INDUSTRY_ID_RESPONSE, GET_INDUSTRY_LIST_BY_STATUS_RESPONSE, UPDATE_INDUSTRY_RESPONSE, UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_RESPONSE } from './actionTypes';
export const initialState = {
    industryApiResponse: null,
    editIndustryApiResponse: null,
    industryList: []
};
const industry = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_INDUSTRY_RESPONSE:
            case UPDATE_INDUSTRY_RESPONSE:
            case UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_RESPONSE:
            case DELETE_INDUSTRY_BY_INDUSTRY_ID_RESPONSE:
                draft.industryApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_INDUSTRY_LIST_RESPONSE:
                draft.industryList = action.payload.industryList;
                break;
            case GET_INDUSTRY_BY_INDUSTRY_ID_RESPONSE:
                draft.editIndustryApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case GET_INDUSTRY_LIST_BY_STATUS_RESPONSE:
                draft.industryList = action.payload.industryList;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default industry;
