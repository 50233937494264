import { ACTIVE } from 'constants/commonConstants';
import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Title',
        field: 'title',
    },
    {
        label: 'Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Start Date',
        field: 'startDate',
    },
    {
        label: 'End Date',
        field: 'endDate',
    },
    {
        label: 'Description',
        field: 'description',
    },
    {
        label: 'Active',
        field: 'isActive',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialfundFormDataState = () => {
    return {
        title: '',
        companyGroupId: '',
        fundMasterId: '',
        startDate: '',
        endDate: '',
        startMonth: '',
        endMonth: '',
        description: '',
        entityId: [],
        isActive: ACTIVE,
        fundTeams: [],
        entityTasks: initialEntityTaskDataState(),
    };
};


export const initialEntityTaskDataState = () => {
    return [{
        fundMasterId: '',
        entityId: []
    }];
}
export const initialFundTeamsDataState = () => {
    return {
        id: 0,
        userId: '',
        isOwner: false,
        isManagement: false,
        isSubmitter: false,
        isReviewer: false,
        isApprover: false
    };
};
export const intFields = ['companyGroupId'];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
export const finListColumns = [
    {
        label: 'Users Name',
        field: 'users',
        sort: 'disabled',
    },
    {
        label: 'isOwner',
        field: 'isOwner',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialTaskState = () => {
    return {
        fundId: '',
        taskTitle: '',
        taskDescription: '',
        startDate: '',
        dueDate: '',
        entityId: '',
        fundMasterId: '',
        userId: [],
        submitterId: [],
        reviewerId: [],
        approverId: [],
        fundStatusId: 1,
        oldFundStatusId: 1,
        priority: '',
        // numberOfDays: '',
        isDraft: false,
        taskType: 1,
        frequencyId: '',
        isCopySubtasks: 0
    };
};
export const initialSubTaskState = () => {
    return {
        taskTitle: '',
        taskDescription: '',
        startDate: '',
        dueDate: '',
        entityId: '',
        fundMasterId: '',
        userId: '',
        submitterId: [],
        reviewerId: [],
        approverId: [],
        fundStatusId: '1',
        priority: '',
    };
};
export const initialTaskCommentState = () => {
    return {
        remarks: '',
    };
};
