import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.industry || initialState;
const makeIndustrySelectField = field =>
    createSelector(reducerState, industryState => industryState[field]);
const selectSortedIndustryList = () =>
    createSelector(makeIndustrySelectField('industryList'), industryList =>
        industryList.map(opt => ({
            value: opt.id,
            label: opt.industryName
        }))
    );
export {
    makeIndustrySelectField,
    selectSortedIndustryList
};
