import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        Header: 'Compliance Code',
        TransHeader: 'tables.allocation.compliance_code',
        accessor: 'complianceCode',

    },
    {
        Header: 'Compliance Title',
        TransHeader: 'tables.allocation.compliance_title',
        accessor: 'complianceTitle',

    },
    {
        Header: 'Company Group',
        TransHeader: 'tables.allocation.company_group',
        accessor: 'companyGroupName',

    },
    {
        Header: 'Entity',
        TransHeader: 'tables.allocation.entity',
        accessor: 'entityName',

    },
    {
        Header: 'Country',
        TransHeader: 'tables.allocation.country',
        accessor: 'countryName',

    },
    {
        Header: 'State',
        TransHeader: 'tables.allocation.state',
        accessor: 'stateName',

    },
    {
        Header: 'City',
        TransHeader: 'tables.allocation.city',
        accessor: 'cityName',
    },
    {
        Header: 'Short Name of Act/Regulation',
        TransHeader: 'tables.allocation.law_short_name',
        accessor: 'lawShortName',
    },
    {
        Header: 'complianceClassification',
        TransHeader: 'tables.allocation.compliance_classification',
        accessor: 'complianceClassification',
    },
    {
        Header: 'Status',
        TransHeader: 'tables.allocation.status',
        accessor: 'status',
        disableSortBy: true,
    },
    {
        Header: 'Action',
        TransHeader: 'common.view',
        accessor: 'actions',
        className: 'action_col',
        disableSortBy: true,
    }
];
export const initialTrackAllocationFilterDataState = () => {
    return {
        companyGroupId: [],
        entityId: [],
        countryId: [],
        stateId: [],
        cityId: [],
        lawCategoryId: [],
        lawId: [],
    }
}
export const intFields = [];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};