import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../helpers/history';
import SharedReducer from './Shared/reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
export default function createReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        router: connectRouter(history),
        toastr: toastrReducer,
        SharedReducer,
        ...injectedReducers,
    });
    return rootReducer;
}
