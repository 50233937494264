import { ACTIVE } from 'constants/commonConstants';
export const listColumns = [
    {
        label: 'Code',
        field: 'code',
    },
    {
        label: 'Tenant Name',
        field: 'tenantName',
    },
    {
        label: 'Tenant Admin',
        field: 'tenantAdminUsers',
    },
    {
        label: 'Created On',
        field: 'createdOn',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialTenantDataState = () => {
    return {
        // code: '',
        name: '',
        // startDate: '',
        // endDate: '',
        // databaseName: '',
        // databaseHost: '',
        // databaseUsername: '',
        // databasePassword: '',
        // databasePort: '',
        tenantAdmin: [],
        isActive: ACTIVE,
        isFundManagementEnabled: false
    };
};