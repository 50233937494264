import React, { Component } from 'react';
import CustomisedModal from 'components/Application/CustomisedModal';
import { Col, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import { dateDifference, getDateFrom } from 'helpers/dateTimeHelper';

class DelayReporting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDelayReportingData: {},
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.viewDelayReportingData !== this.props.viewDelayReportingData) {
            this.setState({
                viewDelayReportingData: nextprops.viewDelayReportingData,
            });
        }
    }
    render() {
        let modalName = this.props?.DelayReporting?.taskCode;
        if (this.props.DelayReporting?.compliancePerformanceDate > this.props.DelayReporting?.dueDate) {
            modalName += `<div className="delay-badge"><span className="badge badge-danger text-white p-1">${__t('form_labels.reporting.delay_reporting')}</span>`;
        }
        let delayDays = dateDifference(this.props.DelayReporting?.dueDate, this.props.DelayReporting?.systemReportingDate);
        if (delayDays != '-') {
            modalName += `<span className="badge badge-warning text-white p-1">${__t('form_labels.reporting.system_reporting_delay')}</span> </div>`;
        }
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg compliance-delay-modal'}
                    modalName={modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row className="view_activity_modal">
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.delay_popup.due_date')}
                                </Label>
                                <br />
                                {getDateFrom(this.props.DelayReporting?.dueDate)}
                            </p>
                        </Col>
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.delay_popup.actual_cmp_per_date')}
                                </Label>
                                <br />
                                {getDateFrom(this.props.DelayReporting?.compliancePerformanceDate)}
                            </p>
                        </Col>
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.delay_popup.system_reporting_date')}
                                </Label>{' '}
                                <br />
                                {getDateFrom(this.props.DelayReporting?.systemReportingDate)}
                            </p>
                        </Col>
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.delay_popup.delay_in_system')}
                                </Label>{' '}
                                <br />
                                {delayDays}
                            </p>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
export default DelayReporting;
