import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DANGER, RESET, SUBMIT, SUCCESS } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import { createStructuredSelector } from 'reselect';
import { loginPasswordChangeRequest } from 'store/actions';
import { compose } from 'redux';
import { makeSelectField } from 'store/Auth/Login/selector';
import reducer from 'store/Auth/Login/reducer';
import saga from 'store/Auth/Login/saga';
const key = 'Login';
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            formData: { oldPassword: '', newPassword: '', confirmPassword: '' },
            errors: []
        }
    }

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.loginPasswordChangeRequest(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.setState({
            errors: [],
            formData: { oldPassword: '', newPassword: '', confirmPassword: '' }
        }, () => {
            this.validator.hideMessages();
        });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.loginPasswordChangeApiResponse && this.props.loginPasswordChangeApiResponse !== nextprops.loginPasswordChangeApiResponse) {
            if (nextprops.loginPasswordChangeApiResponse.responseType === SUCCESS) {
                this.resetForm();
                toastrMessage(nextprops.loginPasswordChangeApiResponse.message, SUCCESS);
                setTimeout(() => {
                    this.props.history.push('logout');
                }, 1000);
            } else {
                toastrMessage(nextprops.loginPasswordChangeApiResponse.message, DANGER);
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className='page-header'>
                                    <h4 className='with-back-btn'>
                                        Change Password </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <p className='text-danger'><b>Note:</b> After changing your password, you need to login again into the application.</p>
                                <Formik
                                    initialValues={this.state.formData}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>

                                            <Row>
                                                <FormGroup className='col-lg-4'>
                                                    <Field
                                                        type="password"
                                                        name="oldPassword"
                                                        className="form-control"
                                                        onChange={e => {
                                                            setFieldValue('oldPassword', e.target.value)
                                                        }}
                                                        placeholder="Old Password"
                                                    />
                                                    {this.validator.message(
                                                        'Old Password',
                                                        values.oldPassword,
                                                        'required'
                                                    )}
                                                    <Label className="form-label">
                                                        Old Password <span className='text-danger required'>*</span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className='col-lg-4'>
                                                    <Field
                                                        type="password"
                                                        name="newPassword"
                                                        className="form-control"
                                                        onChange={e => {
                                                            setFieldValue('newPassword', e.target.value)
                                                        }}
                                                        placeholder="New Password"
                                                    />
                                                    {this.validator.message(
                                                        'New Password',
                                                        values.newPassword,
                                                        'required'
                                                    )}
                                                    <Label className="form-label">
                                                        New Password <span className='text-danger required'>*</span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className='col-lg-4'>
                                                    <Field
                                                        type="password"
                                                        name="confirmPassword"
                                                        className="form-control"
                                                        onChange={e => {
                                                            setFieldValue('confirmPassword', e.target.value)
                                                        }}
                                                        placeholder="Confirm Password"
                                                    />
                                                    {this.validator.message(
                                                        'Confirm Password',
                                                        values.confirmPassword,
                                                        'required'
                                                    )}
                                                    <Label className="form-label">
                                                        Confirm Password <span className='text-danger required'>*</span>
                                                    </Label>
                                                </FormGroup>
                                                <Col lg="12">
                                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                                        <div className="d-inline-block">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                            >
                                                                {SUBMIT}
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="secondary"
                                                                onClick={this.resetForm}
                                                            >
                                                                {RESET}
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    loginPasswordChangeApiResponse: makeSelectField('loginPasswordChangeApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    loginPasswordChangeRequest
});
export default compose(
    withConnect,
    withReducer,
    withSaga,
    withRouter
)(ChangePassword);