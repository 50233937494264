import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_LAW_DEFINITION_REQUEST,
    GET_ALL_LAW_DEFINITIONS_REQUEST,
    GET_LAW_DEFINITION_VERSIONS_LIST_REQUEST,
    GET_LAW_DEFINITIONS_BY_LAW_ID_REQUEST,
    UPDATE_LAW_DEFINITION_REQUEST,
} from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { addLawDefinitionResponse, getAllLawDefinitionsResponse, getLawDefinitionsByLawIdResponse, getLawDefinitionVersionsListResponse, updateLawDefinitionResponse } from './actions';
import { LAW_DEFINITIONS_ADD, LAW_DEFINITIONS_BY_LAW, LAW_DEFINITIONS_VERSION_ADD, LAW_DEFINITIONS_VERSION_LIST } from 'helpers/apiConstants';
import { DANGER, SUCCESS } from 'constants/commonConstants';
function* getAllLawDefinitions() {
    try {
        const response = yield call(invokeApi, 'get', '', '');
        if (response.status) {
            yield put(getAllLawDefinitionsResponse(response.data));
        } else {
            yield put(getAllLawDefinitionsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawDefinitionsByLawId({ payload: { lawId, displayMode } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW_DEFINITIONS_BY_LAW + '/' + lawId, { displayMode });
        if (response.status) {
            yield put(getLawDefinitionsByLawIdResponse(response.data.list, response.data.legalUpdates));
        } else {
            yield put(getLawDefinitionsByLawIdResponse([], []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateLawDefinition({ payload: { lawDefinitionId, data } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW_DEFINITIONS_VERSION_ADD, data);
        if (response.status) {
            yield put(updateLawDefinitionResponse(response.message, SUCCESS));
        } else {
            yield put(updateLawDefinitionResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* addLawDefinition({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW_DEFINITIONS_ADD, data);
        if (response.status) {
            yield put(addLawDefinitionResponse(response.message, SUCCESS));
        } else {
            yield put(addLawDefinitionResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawDefinitionVersionsList({ payload: { lawDefinitionVersionIds } }) {
    try {
        const response = yield call(invokeApi, 'post', LAW_DEFINITIONS_VERSION_LIST, { versionId: lawDefinitionVersionIds });
        if (response.status) {
            yield put(getLawDefinitionVersionsListResponse(response.data.list));
        } else {
            yield put(getLawDefinitionVersionsListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchLawDefinition() {
    yield takeEvery(GET_ALL_LAW_DEFINITIONS_REQUEST, getAllLawDefinitions);
    yield takeEvery(GET_LAW_DEFINITIONS_BY_LAW_ID_REQUEST, getLawDefinitionsByLawId);
    yield takeEvery(UPDATE_LAW_DEFINITION_REQUEST, updateLawDefinition);
    yield takeEvery(ADD_LAW_DEFINITION_REQUEST, addLawDefinition);
    yield takeEvery(GET_LAW_DEFINITION_VERSIONS_LIST_REQUEST, getLawDefinitionVersionsList);

}
function* lawDefinitionSaga() {
    yield all([fork(watchLawDefinition)]);
}
export default lawDefinitionSaga;