import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppLogout from './AppLogout';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import history from './helpers/history';
import './i18n/config';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import configureStore from './store/configureStore';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'helpers/authConfig';
import { MsalProvider } from '@azure/msal-react';
const initialState = {};

const store = configureStore(initialState, history);

const msalInstance = new PublicClientApplication(msalConfig);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <div>
                <ReduxToastr
                    // timeOut={3000}
                    // newestOnTop={false}
                    // position="top-right"
                    // getState={(state) => state.toastr} // This is the default
                    // closeOnToastrClick
                    timeOut={8000} // Disable auto-close globally
                    position="top-right"
                    progressBar
                    closeOnToastrClick
                />
            </div>
            <AppLogout>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </AppLogout>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
