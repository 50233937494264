import produce from 'immer';
import { HANDLE_API_CALL_EXCEPTION } from './actionTypes';
export const initialState = {
    error: null,
    Requests: {},
};
const sharedReducer = (state = initialState, action = '') =>
    produce(state, draft => {
        const matches = /(.*)_(request|success|response|failure)/.exec(
            action.type
        );
        let SET_UNSET_LOADER;
        if (matches) {
            SET_UNSET_LOADER = action.type;
        }
        switch (action.type) {
            case HANDLE_API_CALL_EXCEPTION:
                draft.error = action.payload.error;
                //reset the requests, otherwise when exception occurs, it will continously show loader.
                //handle these exceptions by showing some kind of custom error page.
                draft.Requests = {};
                break;
            case SET_UNSET_LOADER:
                const [, requestName, requestState] = matches;
                draft.Requests[requestName] = requestState === 'request';
                break;
            default:
                break;
        }
    });
export default sharedReducer;
