import {
    GET_ALL_LAW_DOCUMENTS_RESPONSE,
    GET_ALL_LAW_DOCUMENTS_REQUEST,
    GET_LAW_DOCUMENTS_BY_LAW_ID_REQUEST,
    GET_LAW_DOCUMENTS_BY_LAW_ID_RESPONSE,
    GET_ALL_DOCUMENT_TYPE_LIST_REQUEST,
    GET_ALL_DOCUMENT_TYPE_LIST_RESPONSE,
    GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
    GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE,
    UPDATE_LAW_DOCUMENT_REQUEST,
    UPDATE_LAW_DOCUMENT_RESPONSE,
    UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_REQUEST,
    UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_RESPONSE,
    GET_LAW_DOCUMENT_LIST_BY_STATUS_REQUEST,
    GET_LAW_DOCUMENT_LIST_BY_STATUS_RESPONSE,
    DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
    DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE,
    STORE_DOWNLOAD_LOG_REQUEST,
    STORE_DOWNLOAD_LOG_RESPONSE,
    GET_DOWNLOAD_LOG_REQUEST,
    GET_DOWNLOAD_LOG_RESPONSE,
    DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_REQUEST,
    DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_RESPONSE,
    DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_REQUEST,
    DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_RESPONSE,
    UPDATE_LAW_DOCUMENT_LEGAL_RESPONSE,
    UPDATE_LAW_DOCUMENT_LEGAL_REQUEST,
    ADD_LAW_DOCUMENT_REQUEST,
    ADD_LAW_DOCUMENT_RESPONSE,
    GET_LAW_DOCUMENT_VERSIONS_LIST_REQUEST,
    GET_LAW_DOCUMENT_VERSIONS_LIST_RESPONSE
} from './actionTypes';
export const getAllLawDocumentsRequest = (data) => {

    return {
        type: GET_ALL_LAW_DOCUMENTS_REQUEST,
        payload: { data },
    };
};
export const getAllLawDocumentsResponse = (allLawDocuments) => {
    return {
        type: GET_ALL_LAW_DOCUMENTS_RESPONSE,
        payload: { allLawDocuments },
    };
};
export const getLawDocumentsByLawIdRequest = (lawId, displayMode = false) => {
    return {
        type: GET_LAW_DOCUMENTS_BY_LAW_ID_REQUEST,
        payload: { lawId: lawId, displayMode },
    };
};
export const getLawDocumentsByLawIdResponse = (lawDocumentList,legalUpdateListForDocument) => {
    return {
        type: GET_LAW_DOCUMENTS_BY_LAW_ID_RESPONSE,
        payload: { lawDocumentList ,legalUpdateListForDocument},
    };
};
export const getLawDocumentByLawDocumentIdRequest = (lawDocumentId) => {
    return {
        type: GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
        payload: { lawDocumentId: lawDocumentId },
    };
};
export const getLawDocumentByLawDocumentIdResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateLawDocumentRequest = (lawDocumentId, data) => {
    return {
        type: UPDATE_LAW_DOCUMENT_REQUEST,
        payload: { lawDocumentId, data },
    };
};
export const updateLawDocumentResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_DOCUMENT_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateLawDocumentStatusByLawDocumentIdRequest = (
    lawDocumentId,
    status
) => {
    return {
        type: UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_REQUEST,
        payload: { lawDocumentId, status },
    };
};
export const updateLawDocumentStatusByLawDocumentIdResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_LAW_DOCUMENT_STATUS_BY_LAW_DOCUMENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getLawDocumentListByStatusRequest = (status) => {
    return {
        type: GET_LAW_DOCUMENT_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getLawDocumentListByStatusResponse = (lawDocumentsList) => {
    return {
        type: GET_LAW_DOCUMENT_LIST_BY_STATUS_RESPONSE,
        payload: { lawDocumentsList },
    };
};
export const deleteLawDocumentByLawDocumentIdRequest = (lawDocumentId) => {
    return {
        type: DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_REQUEST,
        payload: { lawDocumentId },
    };
};
export const deleteLawDocumentByLawDocumentIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_LAW_DOCUMENT_BY_LAW_DOCUMENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getAllDocumentTypeListRequest = () => {
    return {
        type: GET_ALL_DOCUMENT_TYPE_LIST_REQUEST,
    };
};
export const getAllDocumentTypeListResponse = (documentTypeList) => {
    return {
        type: GET_ALL_DOCUMENT_TYPE_LIST_RESPONSE,
        payload: { documentTypeList },
    };
};

export const storeDownloadLogRequest = (id) => {
    return {
        type: STORE_DOWNLOAD_LOG_REQUEST,
        payload: { id },
    };
};
export const storeDownloadLogResponse = (message, responseType) => {
    return {
        type: STORE_DOWNLOAD_LOG_RESPONSE,
        payload: { message, responseType },
    };
};

export const getDownloadLogRequest = (documentId) => {
    return {
        type: GET_DOWNLOAD_LOG_REQUEST,
        payload: { documentId },
    };
};
export const getDownloadLogResponse = (downloadLogList, responseType) => {
    return {
        type: GET_DOWNLOAD_LOG_RESPONSE,
        payload: { downloadLogList },
    };
};

export const documentRespositoryStandardBulkUploadsRequest = (data) => {
    return {
        type: DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_REQUEST,
        payload: { data },
    };
};
export const documentRespositoryStandardBulkUploadsResponse = (
    message,
    responseType
) => {
    return {
        type: DOCUMENT_REPOSITORY_STANDARD_BULK_UPLOAD_RESPONSE,
        payload: { message, responseType },
    };
};

export const getComplianceListByDocIdRequest = (docId) => {
    return {
        type: DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_REQUEST,
        payload: { docId: docId },
    };
};

export const getComplianceListByDocIdResponse = (documentComplianceList) => {
    return {
        type: DOCUMENT_REPOSITORY_COMPLIANCE_LIST_BY_DOC_ID_RESPONSE,
        payload: { documentComplianceList },
    };
};

export const updateLawDocumentLegalRequest = (lawDocumentId, data) => {
    return {
        type: UPDATE_LAW_DOCUMENT_LEGAL_REQUEST,
        payload: { lawDocumentId, data },
    };
};
export const updateLawDocumentLegalResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_DOCUMENT_LEGAL_RESPONSE,
        payload: { message, responseType },
    };
};

export const addLawDocumentRequest = (data) => {
    return {
        type: ADD_LAW_DOCUMENT_REQUEST,
        payload: { data },
    };
};
export const addLawDocumentResponse = (message, responseType) => {
    return {
        type: ADD_LAW_DOCUMENT_RESPONSE,
        payload: { message, responseType },
    };
};

export const getLawDocumentVersionsListRequest = (lawDocumentVersionIds = []) => {
    return {
        type: GET_LAW_DOCUMENT_VERSIONS_LIST_REQUEST,
        payload: { lawDocumentVersionIds }
    };
};
export const getLawDocumentVersionsListResponse = lawDocumentVersionsList => {
    return {
        type: GET_LAW_DOCUMENT_VERSIONS_LIST_RESPONSE,
        payload: { lawDocumentVersionsList },
    };
};