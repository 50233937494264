import produce from 'immer';
import {
    GET_FILING_TYPES_RESPONSE,
    GET_IMPORT_LOGS_LIST_BY_TYPE_RESPONSE,
    IMPORT_FILE_BY_TYPE_RESPONSE,
    GET_MASTER_SAMPLE_RESPONSE
} from './actionTypes';
export const initialState = {
    importMasterApiResponse: null,
    importLogsList: [],
    filingTypesList: [],
    getMasterSampleApiResponse: null,
};
const importMaster = (state = initialState, action = '') =>
    produce(state, (draft) => {
        switch (action.type) {
            case IMPORT_FILE_BY_TYPE_RESPONSE:
                draft.importMasterApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_IMPORT_LOGS_LIST_BY_TYPE_RESPONSE:
                draft.importLogsList = action.payload.importLogsList;
                break;
            case GET_FILING_TYPES_RESPONSE:
                draft.filingTypesList = action.payload.filingTypes;
                break;

            case GET_MASTER_SAMPLE_RESPONSE:
                draft.getMasterSampleApiResponse = action.payload.data;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default importMaster;
