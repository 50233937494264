import produce from 'immer';
import { GET_ARCHIVE_RECORDS_LIST_RESPONSE, UPLOAD_ARCHIVE_RECORDS_RESPONSE, DELETE_ARCHIVE_RECORD_RESPONSE, GET_ARCHIVE_DEPARTMENT_LIST_RESPONSE } from './actionTypes';
export const initialState = {
    archiveRecordsList: [],
    uploadArchiveRecordsApiResponse: [],
    archiveRecordDeleteApiResponse: [],
    archiveDepartmentList: []
};

const archive = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {

            case GET_ARCHIVE_RECORDS_LIST_RESPONSE:
                draft.archiveRecordsList = action.payload.archiveRecordsList;
                break;

            case UPLOAD_ARCHIVE_RECORDS_RESPONSE:
                draft.uploadArchiveRecordsApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case DELETE_ARCHIVE_RECORD_RESPONSE:
                draft.archiveRecordDeleteApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;

            case GET_ARCHIVE_DEPARTMENT_LIST_RESPONSE:
                draft.archiveDepartmentList = action.payload.archiveDepartmentList;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default archive;