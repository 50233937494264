import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_SUB_INDUSTRY_REQUEST, DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST, GET_ALL_SUB_INDUSTRY_LIST_REQUEST, GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST, GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_REQUEST, GET_SUB_INDUSTRY_LIST_BY_STATUS_REQUEST, UPDATE_SUB_INDUSTRY_REQUEST, UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addSubIndustryResponse, deleteSubIndustryBySubIndustryIdResponse, getAllSubIndustryListRequest, getAllSubIndustryListResponse, getSubIndustryBySubIndustryIdResponse, getSubIndustryListByIndustryResponse, getSubIndustryListByStatusResponse, updateSubIndustryResponse, updateSubIndustryStatusBySubIndustryIdResponse } from './actions';
import { SUB_INDUSTRY, SUB_INDUSTRY_BY_STATUS, SUB_INDUSTRY_LIST_BY_INDUSTRY } from 'helpers/apiConstants';
function* addSubIndustry({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', SUB_INDUSTRY, data);
        if (response.status) {
            yield put(getAllSubIndustryListRequest());
            yield put(addSubIndustryResponse(response.message, SUCCESS));
        } else {
            yield put(addSubIndustryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllSubIndustryList() {
    try {
        const response = yield call(invokeApi, 'get', SUB_INDUSTRY, '');
        if (response.status) {
            yield put(getAllSubIndustryListResponse(response.data.list));
        } else {
            yield put(getAllSubIndustryListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSubIndustryBySubIndustryId({ payload: { subIndustryId } }) {
    try {
        const response = yield call(invokeApi, 'get', SUB_INDUSTRY + '/' + subIndustryId, '');
        if (response.status) {
            yield put(getSubIndustryBySubIndustryIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getSubIndustryBySubIndustryIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateSubIndustry({ payload: { subIndustryId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', SUB_INDUSTRY + '/' + subIndustryId, data);
        if (response.status) {
            yield put(getAllSubIndustryListRequest());
            yield put(updateSubIndustryResponse(response.message, SUCCESS));
        } else {
            yield put(updateSubIndustryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateSubIndustryStatusBySubIndustryId({
    payload: { subIndustryId, status },
}) {
    try {
        const response = yield call(invokeApi, 'patch', SUB_INDUSTRY + '/' + subIndustryId + '/status/' + status);
        if (response.status) {
            yield put(getAllSubIndustryListRequest());
            yield put(updateSubIndustryStatusBySubIndustryIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateSubIndustryStatusBySubIndustryIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSubIndustryListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', SUB_INDUSTRY_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getSubIndustryListByStatusResponse(response.data.list));
        } else {
            yield put(getSubIndustryListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteSubIndustryBySubIndustryId({ payload: { subIndustryId } }) {
    try {
        const response = yield call(invokeApi, 'delete', SUB_INDUSTRY + '/' + subIndustryId);
        if (response.status) {
            yield put(getAllSubIndustryListRequest());
            yield put(deleteSubIndustryBySubIndustryIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteSubIndustryBySubIndustryIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSubIndustryListByIndustry({ payload: { industryIds } }) {
    try {
        const response = yield call(invokeApi, 'post', SUB_INDUSTRY_LIST_BY_INDUSTRY,
            { industryId: industryIds }
        );
        if (response.status) {
            yield put(getSubIndustryListByIndustryResponse(response.data.list));
        } else {
            yield put(getSubIndustryListByIndustryResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchSubIndustry() {
    yield takeEvery(ADD_SUB_INDUSTRY_REQUEST, addSubIndustry);
    yield takeEvery(GET_ALL_SUB_INDUSTRY_LIST_REQUEST, getAllSubIndustryList);
    yield takeEvery(GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST, getSubIndustryBySubIndustryId);
    yield takeEvery(UPDATE_SUB_INDUSTRY_REQUEST, updateSubIndustry);
    yield takeEvery(UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_REQUEST, updateSubIndustryStatusBySubIndustryId);
    yield takeEvery(GET_SUB_INDUSTRY_LIST_BY_STATUS_REQUEST, getSubIndustryListByStatus);
    yield takeEvery(DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_REQUEST, deleteSubIndustryBySubIndustryId);
    yield takeEvery(GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_REQUEST, getSubIndustryListByIndustry);
}
function* subIndustrySaga() {
    yield all([fork(watchSubIndustry)]);
}
export default subIndustrySaga;