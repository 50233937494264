import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADD_NEW, ENABLE_DELETE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllTenantListRequest,
    addTenantRequest,
    updateTenantRequest,
    updateTenantStatusByTenantIdRequest,
    deleteTenantByTenantIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import tenantReducer from 'store/Masters/Tenant/reducer';
import tenantSaga from 'store/Masters/Tenant/saga';
import { makeTenantSelectField } from 'store/Masters/Tenant/selector';
import { listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_TENANT,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const tenantKey = 'tenant';
const withTenantReducer = injectReducer({
    key: tenantKey,
    reducer: tenantReducer,
});
const withTenantSaga = injectSaga({ key: tenantKey, saga: tenantSaga });

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantList: [],
            exportTenantList: [],
            isAuditModalOpen: false,
            auditId: '',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve tenants list
        this.props.getAllTenantListRequest();
    }
    editTenantDetails = (record) => {
        this.props.history.push('/tenants/manage/edit/' + record.id);
    };
    addTenantDetails = () => {
        this.props.history.push('/tenants/manage/add');
    };
    onStatusChange = async (checked, e, id) => {
        let message = checked
            ? __t('alerts.tenant_status_active')
            : __t('alerts.tenant_status_inactive');
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            message
        );
        if (response) {
            this.props.updateTenantStatusByTenantIdRequest(id, checked);
        }
    };
    deleteTenant = async (tenantId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.tenant_delete')
        );
        if (response) {
            this.props.deleteTenantByTenantIdRequest(tenantId);
        }
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let tenantRecords = [];
        tenantRecords = records.map((tenantData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_TENANT, PERMISSION_EDIT) ===
                        true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => this.editTenantDetails(tenantData)}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('tenant') &&
                        checkModulePermission(
                            MODULE_TENANT,
                            PERMISSION_DELETE
                        ) === true &&
                        tenantData.dbConfigStatus == 'PENDING' && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteTenant(tenantData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(tenantData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(tenantData.id)}
                        checked={tenantData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );

            return {
                code: tenantData.code,
                tenantAdminUsers: tenantData?.tenantAdminUsers
                    ? tenantData?.tenantAdminUsers
                    : '',
                tenantName: tenantData.name,
                databaseName: tenantData.databaseName,
                startDate: tenantData.startDate ? tenantData.startDate : '',
                endDate: tenantData.endDate ? tenantData.endDate : '',
                createdOn: getFullDateTimeFromDateTimeString(
                    tenantData.createdAt
                ),
                isActive: isActive,
                actions: actions,
            };
        });
        return tenantRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.tenantList &&
            this.props.tenantList !== nextprops.tenantList
        ) {
            this.setState({
                exportTenantList: nextprops.tenantList,
                tenantList: this.generateTable(nextprops.tenantList),
            });
        }
        if (
            nextprops.deleteTenantApiResponse &&
            this.props.deleteTenantApiResponse !==
            nextprops.deleteTenantApiResponse
        ) {
            toastrMessage(
                nextprops.deleteTenantApiResponse.message,
                nextprops.deleteTenantApiResponse.responseType
            );
            this.props.getAllTenantListRequest();
        }
        if (
            nextprops.tenantStatusUpdateApiResponse &&
            this.props.tenantStatusUpdateApiResponse !==
            nextprops.tenantStatusUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.tenantStatusUpdateApiResponse.message,
                nextprops.tenantStatusUpdateApiResponse.responseType
            );
            this.props.getAllTenantListRequest();
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: newPage?.page ? newPage?.page : 1,
        });
    };
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.tenantList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'tenant'} />
                    </Col>
                    <Col lg="12">
                        <Card className="mb-2">
                            <CardHeader>
                                <div className="page-header">
                                    <h4>{__t('page_headers.tenant_list')}</h4>
                                </div>
                                <div className="page-action">
                                    {checkModulePermission(
                                        MODULE_TENANT,
                                        PERMISSION_ADD
                                    ) === true && (
                                            <a
                                                href={() => false}
                                                className="btn btn-primary waves-effect waves-light float-right ml-2"
                                                onClick={() => {
                                                    this.addTenantDetails();
                                                }}
                                            >
                                                <i className="fas fa-plus"> </i>{' '}
                                                {ADD_NEW}
                                            </a>
                                        )}
                                </div>
                            </CardHeader>
                            <CardBody>
                                <CustomisedMDBDatatablePage
                                    activepage={this.state.activePage}
                                    onHandlePageChange={this.handlePageChange}
                                    tableRecords={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <AuditLogModal
                    exportBtn={true}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'tenant'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    tenantList: makeTenantSelectField('tenantList'),
    tenantApiResponse: makeTenantSelectField('tenantApiResponse'),
    tenantStatusUpdateApiResponse: makeTenantSelectField(
        'tenantStatusUpdateApiResponse'
    ),
    deleteTenantApiResponse: makeTenantSelectField('deleteTenantApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getAllTenantListRequest,
    addTenantRequest,
    updateTenantRequest,
    updateTenantStatusByTenantIdRequest,
    deleteTenantByTenantIdRequest,
});
export default compose(
    withConnect,
    withTenantReducer,
    withTenantSaga,
    withRouter
)(Index);
