import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Metis-menu for menu
import MetisMenu from 'metismenujs';

// Redux Call
import { ToggleRedux } from '../../store/actions';

import { connect } from 'react-redux';

import { makeLayoutSelectField } from 'store/layout/selector';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import TopMenuNav from './TopMenuNav';

class TopMenuBar extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    initializeMetisMenu() {
        // new MetisMenu("#menu", {});
        // Use the object as intended
        const metisMenuInstance = new MetisMenu("#menu", {});
    }
    componentDidMount() {

        this.initializeMetisMenu()

        var matchingMenuItem = null;
        var ul = document.getElementById("menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }
    activateParentDropdown = (item) => {

        item.classList.add('mm-active');
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add('mm-active'); // li 
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");
                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {

                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add('active');
                        }
                    }
                }
            }
            return false;
        }
    }

    componentDidUpdate() {
        if (this.props.is_large_state === true) {
            this.initializeMetisMenu()
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="top-menu">
                    <TopMenuNav />
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    is_large_state: makeLayoutSelectField('is_large_state'),
});

const withConnect = connect(mapStatetoProps, {
    ToggleRedux
});

export default compose(
    withConnect,
    withRouter
)(TopMenuBar);
