import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import CustomisedModal from 'components/Application/CustomisedModal';
import {
    getTrackAllocationByComplianceRequest
} from 'store/actions';

class MapLocations extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            lawComplianceData: {},
            collapseEntityId: '',
            collapseStateId: '',
            entityListWithLocations: []
        };
        this.toggle = this.toggle.bind(this);
        this.toggleSub = this.toggleSub.bind(this);
    }

    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({
            collapseEntityId:
                this.state.collapseEntityId == Number(event)
                    ? 0
                    : Number(event),
        });
    }
    toggleSub(e) {
        let event = e.target.dataset.event;
        this.setState({
            collapseStateId:
                this.state.collapseStateId == Number(event)
                    ? 0
                    : Number(event),
        });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.lawComplianceData && nextprops.lawComplianceData !== this.props.lawComplianceData) {
            this.setState({
                lawComplianceData: nextprops.lawComplianceData,
                entityListWithLocations: []
            }, () => {
                if (this.state.lawComplianceData?.id) {
                    this.props.getTrackAllocationByComplianceRequest(this.state.lawComplianceData.id);
                }
            })
        }
        if (nextprops.trackAllocationList && nextprops.trackAllocationList !== this.props.trackAllocationList) {
            let info = nextprops.trackAllocationList.reduce((trackInfo, obj) => {
                const { entityId, entity, entityLocation } = obj;
                trackInfo[entityId] = trackInfo[entityId] ?? {
                    entityId: entityId,
                    entityName: entity.entityName,
                    entityLocations: [],
                };
                trackInfo[entityId].entityLocations.push(entityLocation);
                return trackInfo;
            }, []);
            // grouping all locations data by state and their cities
            let entityListWithLocations = info.map((opt) => {
                let entityLocationsList = opt.entityLocations.reduce(function (
                    res,
                    obj
                ) {
                    if (!res[obj.stateId]) {
                        res[obj.stateId] = {
                            countryId: obj.countryId,
                            stateId: obj.stateId,
                            stateName: obj?.state?.stateName,
                            cities: [],
                        };
                    }
                    res[obj.stateId].cities.push({
                        locationId: obj.id,
                        cityId: obj.cityId,
                        cityName: obj?.city?.cityName,
                        pincode: obj.pincode,
                    });
                    return res;
                },
                    Object.create(null));
                return {
                    entityId: opt.entityId,
                    entityName: opt.entityName,
                    entityLocations: Object.values(entityLocationsList).filter(
                        (x) => (x.cities.length > 0)
                    ),
                };
            });
            this.setState({
                entityListWithLocations: entityListWithLocations
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >
                    <ul className="list_group_main new-allocation">
                        {this.state.entityListWithLocations?.length > 0 &&
                            this.state.entityListWithLocations.map(
                                (item, idx) => {
                                    return (
                                        item.entityLocations
                                            .length > 0 && (
                                            <li
                                                className="list-group-item"
                                                key={idx}
                                            >
                                                <label onClick={
                                                    this.toggle
                                                }
                                                    data-event={
                                                        item.entityId
                                                    }>
                                                    {
                                                        item.entityName
                                                    }
                                                </label>
                                                <Collapse
                                                    isOpen={
                                                        this.state
                                                            .collapseEntityId ===
                                                        item.entityId
                                                    }
                                                >
                                                    <ul className="list_group_sub d-block p-3 w-100 m-0">
                                                        {item
                                                            .entityLocations
                                                            ?.length >
                                                            0 &&
                                                            item.entityLocations.map(
                                                                (
                                                                    subItem,
                                                                    subIdx
                                                                ) => (
                                                                    <React.Fragment
                                                                        key={
                                                                            item.entityId +
                                                                            subItem.stateId
                                                                        }
                                                                    >
                                                                        <li
                                                                            className={
                                                                                `list-group-item ` +
                                                                                (this
                                                                                    .state
                                                                                    .collapseStateId ==
                                                                                    item.entityId +
                                                                                    subItem.stateId
                                                                                    ? 'active'
                                                                                    : '')
                                                                            }
                                                                        >
                                                                            <label className='m-0' onClick={
                                                                                this
                                                                                    .toggleSub
                                                                            }
                                                                                data-event={
                                                                                    item.entityId +
                                                                                    subItem.stateId
                                                                                }
                                                                            >
                                                                                {
                                                                                    subItem.stateName
                                                                                }
                                                                            </label>
                                                                            <Collapse
                                                                                isOpen={
                                                                                    this
                                                                                        .state
                                                                                        .collapseStateId ==
                                                                                    item.entityId +
                                                                                    subItem.stateId
                                                                                }
                                                                            >
                                                                                <ul className="list_group_child m-0 ml-2 p-2">
                                                                                    {subItem
                                                                                        .cities
                                                                                        ?.length >
                                                                                        0 &&
                                                                                        subItem.cities.map(
                                                                                            (
                                                                                                subChildItem,
                                                                                                subChildIdx
                                                                                            ) => (
                                                                                                <li
                                                                                                    className="list-group-item w-100"
                                                                                                    key={
                                                                                                        subChildIdx
                                                                                                    }
                                                                                                >
                                                                                                    <label
                                                                                                        className='m-0'
                                                                                                        htmlFor={
                                                                                                            `locations_` +
                                                                                                            subChildItem.locationId
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            subChildItem.cityName
                                                                                                        }
                                                                                                    </label>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                </ul>
                                                                            </Collapse>
                                                                        </li>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </ul>
                                                </Collapse>
                                            </li>
                                        )
                                    );
                                }
                            )}
                    </ul>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    trackAllocationList: makeAllocationSelectField('trackAllocationList'),
});
const withConnect = connect(mapStatetoProps, {
    getTrackAllocationByComplianceRequest
});
export default compose(withConnect, withRouter)(MapLocations);
