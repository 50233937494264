export const ADD_COMPLIANCE_UPDATE_UPLOAD_REQUEST = 'add_compliance_update_upload_request';
export const ADD_COMPLIANCE_UPDATE_UPLOAD_RESPONSE = 'add_compliance_update_upload_response';
export const GET_COMPLIANCE_UPDATE_FILE_LOGS_REQUEST = 'get_compliance_update_file_logs_request';
export const GET_COMPLIANCE_UPDATE_FILE_LOGS_RESPONSE = 'get_compliance_update_file_logs_response';

export const GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_REQUEST = 'get_compliance_legal_update_search_request';
export const GET_COMPLIANCE_LEGAL_UPDATE_SEARCH_RESPONSE = 'get_compliance_legal_update_search_response';

export const UPDATE_ASSIGN_GCM_LEGAL_UPDATE_REQUEST = 'update_assign_gcm_legal_update_request';
export const UPDATE_ASSIGN_GCM_LEGAL_UPDATE_RESPONSE = 'update_assign_gcm_legal_update_response';

export const GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_REQUEST = 'get_compliance_legal_update_by_id_request';
export const GET_COMPLIANCE_LEGAL_UPDATE_BY_ID_RESPONSE = 'get_compliance_legal_update_by_id_response';

export const GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_REQUEST = 'get_compliance_legal_update_activity_by_id_request';
export const GET_COMPLIANCE_LEGAL_UPDATE_ACTIVITY_BY_ID_RESPONSE = 'get_compliance_legal_update_activity_by_id_response';

export const LEGAL_UPDATE_DOCUMENT_UPLOAD_REQUEST = 'legal_update_document_upload_Request';
export const LEGAL_UPDATE_DOCUMENT_UPLOAD_RESPONSE = 'legal_update_document_upload_response';

export const COMPLIANCE_LEGAL_UPDATE_REQUEST = 'compliance_legal_update_request';
export const COMPLIANCE_LEGAL_UPDATE_RESPONSE = 'compliance_legal_update_response';

export const DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_REQUEST = 'delete_legal_update_by_legal_update_id_request';
export const DELETE_LEGAL_UPDATE_BY_LEGAL_UPDATE_ID_RESPONSE = 'delete_legal_update_by_legal_update_id_response';

export const GET_ALL_UPDATE_TYPE_LIST_REQUEST = 'get_all_update_type_list_request';
export const GET_ALL_UPDATE_TYPE_LIST_RESPONSE = 'get_all_update_type_list_response';

export const GET_ALL_SUB_UPDATE_TYPE_LIST_REQUEST = 'get_all_sub_update_type_list_request';
export const GET_ALL_SUB_UPDATE_TYPE_LIST_RESPONSE = 'get_all_sub_update_type_list_response';

export const GET_ALL_LEGAL_UPDATE_STATUS_LIST_REQUEST = 'get_all_legal_update_status_list_request';
export const GET_ALL_LEGAL_UPDATE_STATUS_LIST_RESPONSE = 'get_all_legal_update_status_list_response';

export const GET_ALL_RELEVANT_UPDATE_REQUEST = 'get_all_relevant_update_request';
export const GET_ALL_RELEVANT_UPDATE_RESPONSE = 'get_all_relevant_update_response';

export const CLOSE_LEGAL_UPDATE_BY_ID_REQUEST = 'close_legal_update_by_id_request';
export const CLOSE_LEGAL_UPDATE_BY_ID_RESPONSE = 'close_legal_update_by_id_response';