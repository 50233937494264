import React from 'react';
import { Card, CardHeader, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

class CustomisedCollapseForOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDialog: this.props.viewDialog,
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle = () => {
        this.setState({
            viewDialog: !this.state.viewDialog,
        }, () => {
            if (this.props?.dataEvent) {
                this.props.toggleCollapse(this.props.dataEvent)
            }
        })
    }
    UNSAFE_componentWillReceiveProps(nextprops){
        if(nextprops.viewDialog !== this.props.viewDialog){
            this.setState({
                viewDialog: nextprops.viewDialog
            })
        }
    }
    render() {
        return (
            <Card className='accordion-main'>
                <CardHeader className='p-0'>
                    <Link
                        to="#"
                        onClick={() =>
                            this.toggle()
                        }
                        className="page-header accordion_header px-4 py-3 m-0"
                    >
                        <h4 className="m-0">{this.props.dialogName}</h4>
                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292893L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292894L7.70711 6.29289L6.29289 7.70711Z" fill="currentColor"/></svg>
                    </Link>
                    {this.props?.actionBtns}
                </CardHeader>
                <Collapse isOpen={this.state.viewDialog}>
                    {this.props.children}
                </Collapse>
            </Card>
        );
    }
}

export default CustomisedCollapseForOne;
