import {
    AUDITED_AND_CLOSED,
    CLOSED,
    CLOSED_NAME,
    DISCREPANCY_COMPLETED_RESOLUTION,
    DISCREPANCY_ONGOING_RESOLUTION,
    DISCREPANCY_PENDING_RESOLUTION,
    DISCREPANCY_TYPE_MONETARY,
    DISCREPANCY_TYPE_NON_MONETARY,
    DISCREPANCY_TYPE_OTHERS,
    EXPENSE_TYPE_NONE,
    EXPENSE_TYPE_PENALTY,
    EXPENSE_TYPE_STATUTORY_PAYMENT,
    LOCAL_FILE_STORAGE,
    PENDING_FOR_AUDIT,
    PENDING_FOR_COMPLETION,
    PENDING_WITH_FIRST_APPROVER,
    PENDING_WITH_SECOND_APPROVER,
    REOPENED,
    ROLE_COMPANY_GROUP_ADMIN,
    ROLE_ENTITY_ADMIN,
    ROLE_FUNCTIONAL_HEAD,
    ROLE_GLOBAL_COMPLIANCE_UPDATER,
    ROLE_IT_ADMIN,
    ROLE_MANAGEMENT_TEAM,
    ROLE_SUPER_ADMIN,
    ROLE_TENANT_ADMIN,
    SEND_BACK_BY_AUDITOR_TO_APPROVER,
    SEND_BACK_BY_AUDITOR_TO_SUBMITTER,
    SEND_BACK_TO_APPROVER,
    SEND_BACK_TO_SUBMITTER,
    SUBMITTER_COMPLETION_STATUS,
    SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS,
    SUBMITTER_DROP_STATUS,
    TICKET_CLOSE,
    TICKET_IN_PROGRESS,
    TICKET_ON_HOLD,
    TICKET_OPEN,
    TICKET_REOPEN,
    TRACK_STATUS_ACKNOELEDGE,
    TRACK_STATUS_APPROVED,
    TRACK_STATUS_DECLINE,
    TRACK_STATUS_PENDING,
    TRACK_STATUS_REJECTED,
    TRACK_STATUS_TBD,
} from 'constants/databaseConstants';
import React from 'react';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { checkDataWithLocalStorage, getValueByKey } from './authUtils';
import * as authConstants from 'constants/authConstants';
import routes from 'routes';
import { getBaseUrl } from './axiosHelper';
import { decryptData } from './encryptor';
import { parseFloatHelper, roundOffAmount } from './mathHelper';
const fileStorage = process.env.REACT_APP_FILE_STORAGE;
const s3Url = process.env.REACT_APP_S3_URL;
export const animatedComponents = makeAnimated();
export const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
export const MultiValue = (props) => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

export const getSubmitterCompletionStatusNameById = (statusId) => {
    let statusName = '';

    switch (statusId) {
        case SUBMITTER_COMPLETION_STATUS:
            statusName = 'Completed';
            break;
        case SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS:
            statusName = 'Conditional Execution';
            break;
        case SUBMITTER_DROP_STATUS:
            statusName = 'Dropped';
            break;
        default:
            break;
    }

    return statusName;
};

export const getExpenseTypeNameById = (typeId) => {
    let typeName = '';

    switch (typeId) {
        case EXPENSE_TYPE_NONE:
            typeName = 'None';
            break;
        case EXPENSE_TYPE_STATUTORY_PAYMENT:
            typeName = 'Statutory Payment';
            break;
        case EXPENSE_TYPE_PENALTY:
            typeName = 'Penalty';
            break;
        default:

            break;
    }
    return typeName;
};

export const getDiscrepancyTypeNameById = (typeId) => {
    let typeName = '';

    switch (typeId) {
        case DISCREPANCY_TYPE_MONETARY:
            typeName = 'Monetary';
            break;
        case DISCREPANCY_TYPE_NON_MONETARY:
            typeName = 'Non-monetary';
            break;
        case DISCREPANCY_TYPE_OTHERS:
            typeName = 'Others';
            break;
        default:
            break;
    }
    return typeName;
};

export const getTrackAllocationStatusNameById = (typeId) => {
    let typeName = '';

    switch (typeId) {
        case TRACK_STATUS_PENDING:
            typeName = 'Pending';
            break;
        case TRACK_STATUS_APPROVED:
            typeName = 'Allocated';
            break;
        case TRACK_STATUS_REJECTED:
            typeName = 'Rejected';
            break;
        case TRACK_STATUS_ACKNOELEDGE:
            typeName = 'Applicable';
            break;
        case TRACK_STATUS_DECLINE:
            typeName = 'Decline';
            break;
        case TRACK_STATUS_TBD:
            typeName = 'To Be Discussed';
            break;
        default:

            break;
    }
    return typeName;
};


// check if login user is GT user
export const isGTROLE = () => {
    const roles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
    const gtRole = roles.find(
        (opt) =>
            opt.id === ROLE_IT_ADMIN ||
            opt.id === ROLE_SUPER_ADMIN ||
            opt.id === ROLE_GLOBAL_COMPLIANCE_UPDATER
    );
    if (gtRole) {
        return true;
    } else {
        return false;
    }
};
// check if login user have GT Superadmin role access or not
export const isGTSuperAdminRole = () => {
    const roles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
    const superAdminRole = roles.find((opt) => opt.id === ROLE_SUPER_ADMIN);
    if (superAdminRole) {
        return true;
    } else {
        return false;
    }
};
// check if login user have management role access or not
export const isManagementRole = () => {
    const roles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
    const managementRole = roles.find((opt) => opt.id === ROLE_MANAGEMENT_TEAM);
    if (managementRole) {
        return true;
    } else {
        return false;
    }
};

// check if login user have management role access or not
export const isHodRole = () => {
    const roles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
    const hodRole = roles.find((opt) => opt.id === ROLE_FUNCTIONAL_HEAD);
    if (hodRole) {
        return true;
    } else {
        return false;
    }
};
// check if login user have company group admin role access or not
export const isCompanyGroupAdminRole = () => {
    const roles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
    const managementRole = roles.find(
        (opt) => opt.id === ROLE_COMPANY_GROUP_ADMIN
    );
    if (managementRole) {
        return true;
    } else {
        return false;
    }
};
// check if login user have tenant admin role access or not
export const isTenantAdminRole = () => {
    const roles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
    const tenantRole = roles.find(
        (opt) => opt.id === ROLE_TENANT_ADMIN
    );
    if (tenantRole) {
        return true;
    } else {
        return false;
    }
};
// check if login user have tenant admin /company group admin / entityadminrole access or not
export const isTenantAdminCompanyGroupAdminEntityAdminRole = () => {
    const roles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
    const checkRole = roles.find(
        (opt) => (opt.id === ROLE_TENANT_ADMIN || opt.id === ROLE_COMPANY_GROUP_ADMIN || opt.id === ROLE_ENTITY_ADMIN)
    );
    if (checkRole) {
        return true;
    } else {
        return false;
    }
};

// check the given module has the given specific permission or not
export const checkModulePermission = (moduleId, permissionId) => {
    const modules = JSON.parse(decryptData(getValueByKey(authConstants.PERMISSIONS)));
    const moduleHasAccess = modules.filter((opt) => opt.moduleId === moduleId);
    let permissions = [];
    moduleHasAccess.map((ap) => {
        ap.permissions.split(',').map((a) => {
            permissions.push(Number(a));
            return a;
        });
        return ap.permissions;
    });
    permissions = [...new Set(permissions)];
    if (moduleHasAccess.length > 0 && permissions.length > 0) {
        return permissions.includes(permissionId) ? true : false;
    }
    return false;
};

// check the given url has the given specific permission or not
export const checkModuleUrlPermission = (url, routeActualPath) => {
    checkDataWithLocalStorage();
    let routeHasModule, routeHasModulePermission;
    if (routeActualPath.includes(':') === true) {
        routeHasModule = routes.find(
            (opt) => opt.path === routeActualPath
        )?.module;
        routeHasModulePermission =
            routes.find((opt) => opt.path === routeActualPath) ||
            [];
        if (routeHasModulePermission.permissions) {
            routeHasModulePermission = routeHasModulePermission.permissions || []
        }
    } else {
        routeHasModule = routes.find((opt) => opt.path === url)?.module;
        routeHasModulePermission =
            routes.find((opt) => opt.path === url)?.permissions || [];
    }
    const modules = JSON.parse(decryptData(getValueByKey(authConstants.PERMISSIONS)));
    const moduleHasAccess = modules.filter(
        (opt) => opt.moduleId === routeHasModule
    );
    let permissions = [];
    moduleHasAccess.map((ap) => {
        ap.permissions.split(',').map((a) => {
            permissions.push(Number(a));
            return a;
        });
        return ap.permissions;
    });
    permissions = [...new Set(permissions)];
    if (moduleHasAccess.length && permissions.length > 0) {
        return routeHasModulePermission.some((permissionId) =>
            permissions.includes(permissionId) ? true : false
        );
    }
    return false;
};

export const getFileUrlByModule = (moduleType, path, data) => {
    let fileUrl = '';
    const baseUrl = getBaseUrl();
    const fileHostUrl = (fileStorage === LOCAL_FILE_STORAGE) ? baseUrl : s3Url;
    moduleType = moduleType.toLowerCase();
    if (moduleType == 'poc' || moduleType == 'subtask') {
        if (data.companyGroupId && data.entityId) {
            fileUrl = fileHostUrl + '/transactional-documents/' + `companyGroup-${data.companyGroupId}/entity-${data.entityId}/` + path;
        }
    }
    if (moduleType == 'chat') {
        fileUrl = fileHostUrl + '/transactional-documents/' + path;
    }
    return fileUrl;
};

export const getComplianceScore = (pendingTasks, completedTasks) => {
    let totalTasks = parseInt(pendingTasks + completedTasks);
    const score = roundOffAmount(
        parseFloatHelper((completedTasks * 100) / totalTasks)
    );
    return score;
};

export const actionPendingIcon = (loginUserId, taskStatusId, submitterId, firstApproverUserId, secondApproverUserId, auditorId) => {
    let submitterStatusIds = [PENDING_FOR_COMPLETION, SEND_BACK_TO_SUBMITTER, SEND_BACK_BY_AUDITOR_TO_SUBMITTER];
    let firstApproverStatusIds = [PENDING_WITH_FIRST_APPROVER, SEND_BACK_TO_APPROVER, SEND_BACK_BY_AUDITOR_TO_APPROVER];
    let secondApproverStatusIds = [PENDING_WITH_SECOND_APPROVER];
    let auditorStatusIds = [PENDING_FOR_AUDIT];
    if (loginUserId == submitterId && submitterStatusIds.includes(taskStatusId)) {
        return 'action';
    }
    if (loginUserId == firstApproverUserId && firstApproverStatusIds.includes(taskStatusId)) {
        return 'action';
    }
    if (loginUserId == secondApproverUserId && secondApproverStatusIds.includes(taskStatusId)) {
        return 'action';
    }
    if (loginUserId == auditorId && auditorStatusIds.includes(taskStatusId)) {
        return 'action';
    }
    return 'view';
}
export const getTicketStatusNameById = (status) => {
    let statusName = '';
    let statusClassName = '';

    switch (status) {
        case TICKET_OPEN:
            statusName = 'Open';
            statusClassName = 'badge badge-primary';
            break;
        case TICKET_IN_PROGRESS:
            statusName = 'In Progress';
            statusClassName = 'badge badge-info';
            break;
        case TICKET_CLOSE:
            statusName = 'Solved';
            statusClassName = 'badge badge-success';
            break;
        case TICKET_ON_HOLD:
            statusName = 'On Hold';
            statusClassName = 'badge badge-secondary';
            break;
        case TICKET_REOPEN:
            statusName = 'Reopen';
            statusClassName = 'badge badge-warning';
            break;
        default:
            break;
    }
    return { statusName, statusClassName };
};
export const getAttchmentClassExtWise = (fileName) => {
    let attachClass;
    if (fileName) {
        const extName = fileName.split('.').pop();
        switch (extName.toLowerCase()) {
            case 'txt':
                attachClass = 'fas fa-file-alt';
                break;

            case 'pdf':
                attachClass = 'fas fa-file-pdf';
                break;

            case 'doc':
                attachClass = 'fas fa-file';
                break;

            case 'jpg':
                attachClass = 'fas fa-file-image';
                break;

            case 'png':
                attachClass = 'fas fa-file-image';
                break;

            case 'jpeg':
                attachClass = 'fas fa-file-image';
                break;

            case 'csv':
                attachClass = 'fas fa-file-csv';
                break;

            case 'zip':
                attachClass = 'fas fa-file-archive';
                break;

            case 'docx':
                attachClass = 'fas fa-file-word';
                break;

            case 'xlsx':
                attachClass = 'fas fa-file-excel';
                break;
            default:
                break;
        }
        return attachClass;
    }
    return attachClass;
};

export const getAuditStatusById = (taskStatusId, taskStatusName) => {
    if (taskStatusId !== PENDING_FOR_AUDIT &&
        taskStatusId !== AUDITED_AND_CLOSED &&
        taskStatusId !== DISCREPANCY_PENDING_RESOLUTION &&
        taskStatusId !== DISCREPANCY_ONGOING_RESOLUTION &&
        taskStatusId !== DISCREPANCY_COMPLETED_RESOLUTION
    ) {
        return '-';
    } else {
        return taskStatusName;
    }
}


export const getTaskStatusById = (taskStatusId, taskStatusName) => {
    if (taskStatusId !== PENDING_FOR_COMPLETION &&
        taskStatusId !== PENDING_WITH_FIRST_APPROVER &&
        taskStatusId !== PENDING_WITH_SECOND_APPROVER &&
        taskStatusId !== CLOSED &&
        taskStatusId !== REOPENED &&
        taskStatusId !== SEND_BACK_TO_SUBMITTER &&
        taskStatusId !== SEND_BACK_TO_APPROVER
    ) {
        return CLOSED_NAME;
    } else {
        return taskStatusName;
    }
}