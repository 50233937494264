import React, { Component } from 'react';
import { Row, Col, Label, FormGroup, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    CLEAR,
    SEARCH,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
} from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import SimpleReactValidator from 'simple-react-validator';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getAllupdateTypeListRequest,
    getLawListRequest
} from 'store/actions';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { setEmptyToNull } from 'helpers/generalUtils';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedLawSearchList } from 'store/Masters/Law/selector';
import __t from 'i18n/translator';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import {
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { selectSortedUpdateTypeList } from 'store/UpdateCenter/LegalUpdates/selector';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
export const intFields = ['country', 'state', 'city'];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};

class SearchForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            searchFormData: this.props.searchFormData,
            sortedStateListDropdown: [],
            sortedCityListDropdown: [],
            sortedLawListDropdown: []
        };
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getAllupdateTypeListRequest();
    }
    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest(countryId);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest(stateId);
    }
    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: values.countryId ? values.countryId : [],
                stateId: values.stateId ? values.stateId : [],
                cityId: values.cityId ? values.cityId : [],
            },
            orderBy: {}
        };
        this.props.getLawListRequest(apiData, false);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {

            this.setState(
                {
                    searchFormData: values,
                },
                () => {
                    this.props.onFormSubmit(formatValuesForApi(values));
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.validator.hideMessages();
        this.setState({
            sortedStateListDropdown: [],
            sortedCityListDropdown: [],
            sortedLawListDropdown: []
        }, () => {
            this.props.onResetForm();
        })
    };
    UNSAFE_componentWillReceiveProps(nextprops) {

        if (nextprops.sortedStateListDropdown && this.props.sortedStateListDropdown != nextprops.sortedStateListDropdown) {
            this.setState({
                sortedStateListDropdown: nextprops.sortedStateListDropdown
            })
        }
        if (nextprops.sortedCityListDropdown && this.props.sortedCityListDropdown != nextprops.sortedCityListDropdown) {
            this.setState({
                sortedCityListDropdown: nextprops.sortedCityListDropdown
            })
        }

        if (nextprops.sortedLawListDropdown && this.props.sortedLawListDropdown != nextprops.sortedLawListDropdown) {
            this.setState({
                sortedLawListDropdown: nextprops.sortedLawListDropdown
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.searchFormData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row className="filter-form">

                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedCountryListDropdown
                                        }
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'countryId',
                                                selectedValues
                                            );
                                            setFieldValue('stateId', []);
                                            setFieldValue('cityId', []);
                                            setFieldValue('lawId', []);
                                            this.getStateListByCountry(
                                                selectedValues
                                            );
                                            this.getLawsByFilters({
                                                ...values,
                                                countryId: selectedValues,
                                            });
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            (option) =>
                                                values.countryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        key="countryId"
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.relevant_updates.country'
                                        )}{' '}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state
                                                .sortedStateListDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'stateId',
                                                selectedValues
                                            );
                                            setFieldValue('cityId', []);
                                            setFieldValue('lawId', []);
                                            if (selectedValues.length > 0) {
                                                this.getCityListByState(
                                                    selectedValues
                                                );
                                            } else {
                                                this.setState({
                                                    sortedCityListDropdown: []
                                                })
                                            }
                                            this.getLawsByFilters({
                                                ...values,
                                                stateId: selectedValues,
                                            });

                                        }}
                                        value={this.state.sortedStateListDropdown?.filter(
                                            (option) =>
                                                values.stateId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        key="stateId"
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.relevant_updates.state'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state
                                                .sortedCityListDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'cityId',
                                                selectedValues
                                            );
                                            setFieldValue('lawId', []);
                                            this.getLawsByFilters({
                                                ...values,
                                                cityId: selectedValues,
                                            });
                                        }}
                                        value={this.state.sortedCityListDropdown?.filter(
                                            (option) =>
                                                values.cityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        key="cityId"
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.relevant_updates.city')}
                                    </Label>
                                </FormGroup>


                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        className="form-control selectbox"
                                        name="lawId[]"
                                        options={
                                            this.state.sortedLawListDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'lawId',
                                                selectedValues
                                            );
                                        }}
                                        value={this.state.sortedLawListDropdown?.filter(
                                            (option) =>
                                                values.lawId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        key="laws"
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.relevant_updates.law')}{' '}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-4">
                                    <Field
                                        name="updateTypeId"
                                        className="form-control selectbox"
                                        options={this.props.updateTypeList}
                                        component={CustomMultiSelect}
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];

                                            setFieldValue(
                                                'updateTypeId',
                                                selectedValues
                                            );
                                        }}
                                        value={this.props.updateTypeList?.filter(
                                            (option) =>
                                                values.updateTypeId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.relevant_updates.update_type'
                                        )}{' '}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-2 w-100 sticky_label">
                                    <Field
                                        name="fromDate"
                                        component={DatePicker}
                                        isClearable={true}
                                        className="form-control"
                                        placeholderText={DATE_FORMAT_PLACEHOLDER}
                                        selected={
                                            values.fromDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.fromDate
                                                )
                                                : null
                                        }
                                        dateFormat={DATE_FORMAT_DATEPICKER}
                                        onChange={(date) => {
                                            setFieldValue(`fromDate`, date);

                                            let dateCheck =
                                                getDateObjectWithValueFromMoment(
                                                    values.toDate
                                                ) <
                                                    getDateObjectWithValueFromMoment(
                                                        date
                                                    )
                                                    ? true
                                                    : false;
                                            if (dateCheck) {
                                                setFieldValue('toDate', '');
                                            }
                                        }}

                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dropdownMode="select"
                                        onFocus={(e) => e.target.blur()}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.relevant_updates.from_period'
                                        )}{' '}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-2 w-100 sticky_label">
                                    <Field
                                        name="toDate"
                                        component={DatePicker}
                                        isClearable={true}
                                        className="form-control"
                                        placeholderText={DATE_FORMAT_PLACEHOLDER}
                                        selected={
                                            values.toDate
                                                ? getDateObjectWithValueFromMoment(
                                                    values.toDate
                                                )
                                                : null
                                        }
                                        dateFormat={DATE_FORMAT_DATEPICKER}
                                        onChange={(date) => {
                                            setFieldValue(`toDate`, date);
                                        }}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dropdownMode="select"
                                        onFocus={(e) => e.target.blur()}
                                        minDate={getDateObjectWithValueFromMoment(
                                            values?.fromDate
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.relevant_updates.to_period'
                                        )}{' '}
                                    </Label>
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-2 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-1"
                                            >
                                                {SEARCH}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {CLEAR}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedLawListDropdown: selectSortedLawSearchList(),
    updateTypeList: selectSortedUpdateTypeList('updateTypeList'),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawListRequest,
    getAllupdateTypeListRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withLawReducer,
    withLawSaga,
    withRouter
)(SearchForm);
