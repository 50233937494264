import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import {
    getTrackAllocationActivitiesByComplianceRequest
} from 'store/actions';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import { getTrackAllocationStatusNameById } from 'helpers/projectUtils';
import { getFullDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';

class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lawComplianceData: {},
            trackAllocationActivityList: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.lawComplianceData && nextprops.lawComplianceData !== this.props.lawComplianceData) {
            this.setState({
                trackAllocationActivityList: [],
                lawComplianceData: nextprops.lawComplianceData
            }, () => {
                if (this.state.lawComplianceData?.id) {
                    this.props.getTrackAllocationActivitiesByComplianceRequest(this.state.lawComplianceData.id, this.props.type);
                }
            })
        }
        if (nextprops.trackAllocationActivityList && nextprops.trackAllocationActivityList !== this.props.trackAllocationActivityList) {
            this.setState({
                trackAllocationActivityList: nextprops.trackAllocationActivityList
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={'modal-xl modal-dialog-centered modal-dialog-scrollable side_slider auditlog_modal'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() =>
                        this.props.onModalDismiss()
                    }
                >

                    {this.state.trackAllocationActivityList.length > 0 && this.state.trackAllocationActivityList.map((activity, kk) => {
                        return (
                            <div className='activity-log-view-list' key={kk}>
                                <div className='activity-log-head'>
                                    <div>


                                        <span>
                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.6667 3.33325L5 23.3333H20L18.3333 36.6666L35 16.6666H20L21.6667 3.33325Z" stroke="currentColor" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </span>
                                        <div className="activity-log-user">
                                            <h5>{activity?.createdByUser?.userName}</h5>

                                            <div className="badge">
                                                <svg width="800px" height="800px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 16.5H19.5M5.5 8.5H19.5M4.5 12.5H20.5M12.5 20.5C12.5 20.5 8 18.5 8 12.5C8 6.5 12.5 4.5 12.5 4.5M12.5 4.5C12.5 4.5 17 6.5 17 12.5C17 18.5 12.5 20.5 12.5 20.5M12.5 4.5V20.5M20.5 12.5C20.5 16.9183 16.9183 20.5 12.5 20.5C8.08172 20.5 4.5 16.9183 4.5 12.5C4.5 8.08172 8.08172 4.5 12.5 4.5C16.9183 4.5 20.5 8.08172 20.5 12.5Z" stroke="currentColor" strokeWidth="1.2"></path>
                                                </svg>
                                                {activity?.entityLocation?.city?.cityName}, {activity?.entityLocation?.state?.stateName}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p>{getFullDateTimeFromDateTimeString(activity?.createdAt)}</p>
                                        <div className="badge action_title">{getTrackAllocationStatusNameById(activity?.status)}</div>
                                    </div>
                                </div>
                                <p className='remarks-box'>
                                    {activity?.remarks}
                                </p>

                            </div>
                        )
                    })}
                </CustomisedSideModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    trackAllocationActivityList: makeAllocationSelectField('trackAllocationActivityList'),
});
const withConnect = connect(mapStatetoProps, {
    getTrackAllocationActivitiesByComplianceRequest
});
export default compose(withConnect, withRouter)(Activity);
