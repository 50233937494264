import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Event Name',
        field: 'eventName',
    },
    {
        label: 'Event Applicability',
        field: 'eventApplicability',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State',
        field: 'stateName',
    },
    {
        label: 'City',
        field: 'cityName',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
export const initialEventDataState = () => {
    return {
        eventName: "",
        countryId: "",
        stateId: "",
        cityId: "",
        eventDescription: "",
        eventApplicability: "",
        isActive: ACTIVE,
    }
}
export const intFields = ['countryId'];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};