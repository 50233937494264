import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    CardFooter,
    Form,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import {
    getEntityListByCompanyGroupIdRequest,
    getLawCompliancesForAllocationRequest,
    allocateCompliancesToEntityRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeEntitySelectField } from 'store/Entity/selector';
import { DANGER, NA, RESET, SUCCESS } from 'constants/commonConstants';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { Formik } from 'formik';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import { toastrMessage } from 'helpers/messageHelper';
import MapLocationForm from './MapLocationForm';
import CustomisedModal from 'components/Application/CustomisedModal';
import ReactTooltip from 'react-tooltip';
import AllocationFilterForm from './AllocationFilterForm';
import __t from 'i18n/translator';
import {
    initialAllocationDataState,
    listColumns1,
} from './CommonFunctions';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawComplianceKey = 'lawCompliance';
const withComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            pageType: 'new',
            pageHeaderName: __t('page_headers.new_allocation'),
            allComplianceRecords: [],
            lawComplianceList: [],
            allocationFilterData: initialAllocationDataState(),
            allocationData: {
                compliances: [],
            },
            mapLocationModal: false,
            complianceId: '',
            complianceCode: '',
            entityList: [],
            entityListData: [],
            isTable: false,
            searchedText: '',
        };
        this.generateTable = this.generateTable.bind(this);
    }
    formSubmit = (values) => {
        this.setState(
            {
                allocationFilterData: values,
                isTable: true,
            },
            () => {
                this.props.getEntityListByCompanyGroupIdRequest([
                    values.companyGroupId,
                ]);
                this.props.getLawCompliancesForAllocationRequest({
                    type: this.state.pageType,
                    filter: {
                        ...this.state.allocationFilterData,
                        industryId: this.state.allocationFilterData.industryId
                            ? [this.state.allocationFilterData.industryId]
                            : [],
                        subIndustryId: this.state.allocationFilterData
                            .subIndustryId
                            ? [this.state.allocationFilterData.subIndustryId]
                            : [],
                    },
                });
            }
        );
    };
    formSubmitForMapLocation = (locations, entities, entityWithLocations) => {
        let compliances = this.state.allocationData.compliances;
        if (
            compliances.find(
                (opt) => opt.complianceId === this.state.complianceId
            )
        ) {
            compliances = compliances.map((opt) =>
                opt.complianceId === this.state.complianceId
                    ? {
                        ...opt,
                        locations: locations,
                        entities: entities,
                        entityWithLocations: entityWithLocations,
                    }
                    : opt
            );
        } else {
            compliances.push({
                complianceId: this.state.complianceId,
                locations: locations,
                entities: entities,
                entityWithLocations: entityWithLocations,
            });
        }
        this.setState(
            {
                allocationData: {
                    compliances: compliances.filter(
                        (opt) => opt.entityWithLocations?.length > 0
                    ),
                },
            },
            () => {
                this.setState(
                    {
                        lawComplianceList: this.generateTable(
                            this.state.allComplianceRecords
                        ),
                    },
                    () => {
                        this.searchText(this.state.searchedText);
                    }
                );
                this.closeMapLocationModal();
            }
        );
    };
    formSubmitForAssign = () => {
        this.props.allocateCompliancesToEntityRequest(
            this.state.allocationData
        );
    };
    resetForm = (values) => {
        this.setState({
            allocationFilterData: {
                ...values,
                companyGroupId: '',
                countryId: [],
                stateId: [],
                cityId: [],
                industryId: '',
                subIndustryId: '',
                lawCategoryId: [],
                lawId: [],
            },
            allComplianceRecords: [],
            lawComplianceList: [],
            isTable: false,
        });
    };
    resetAllForm = (values) => {
        this.setState(
            {
                allocationData: {
                    compliances: [],
                },
                allComplianceRecords: [],
                lawComplianceList: [],
                isTable: false,
            },
            () => {
                this.setState({
                    allocationFilterData: initialAllocationDataState(),
                });
            }
        );
    };
    openMapLocationModal = (complianceId, complianceCode, complianceData) => {
        /**
         *  WARNING | Do not Modify code without any proper requirement confirmation
         */
        let info = this.props.entityList; // getting all entity list by company group from api
        let entityListByGroup = info.filter((opt) => {
            return opt.isActive;
        });
        // grouping all locations data by state and their cities
        info = entityListByGroup.map((opt) => {
            let entityLocationsList = opt.entityLocations.filter((opt1) => {
                return opt1.isActive;
            }).reduce(function (
                res,
                obj
            ) {
                if (!res[obj.stateId]) {
                    res[obj.stateId] = {
                        countryId: obj.countryId,
                        stateId: obj.stateId,
                        stateName: obj?.state?.stateName,
                        cities: [],
                    };
                }
                res[obj.stateId].cities.push({
                    locationId: obj.id,
                    cityId: obj.cityId,
                    cityName: obj?.city?.cityName,
                    pincode: obj.pincode,
                });
                return res;

            },
                Object.create(null));
            return {
                entityId: opt.id,
                entityName: opt.entityName,
                entityLocations: Object.values(entityLocationsList).filter(
                    (option) => (option.cities.length > 0)
                ),
            };
        });
        /**
         * -------------------------------------------------------------------------------------------------------------
         * Filter and map city data as per compliance selection
         * Example: National Level | "Compliance" => "Abc", "Country" => "India", "State" => "", "City" => ""
         * Example: National Level | "Compliance" => "Abc", "Country" => "India", "State" => "All", "City" => "All"
         * Example: State Level | "Compliance" => "Abc", "Country" => "India", "State" => "Gujarat", "City" => "All"
         * Example: City Level | "Compliance" => "Abc", "Country" => "India", "State" => "Gujarat", "City"=> "Ahmadabad;Surat"
         * We are filtering data by the compliance level whether its "national","state","city" and in last setting the state
         * value for "entityListData" for the child component UI => "MapLocationForm.js"
         * -------------------------------------------------------------------------------------------------------------
         * */
        let entityListParticularCountry = info.map((optData) => {
            optData.entityLocations = optData.entityLocations?.map((opt) => {
                if (opt.countryId == complianceData.law.countryId) {
                    if (
                        (complianceData.law.stateId == null ||
                            complianceData.law.stateId == '' ||
                            complianceData.law.stateId == -1) &&
                        (complianceData.law.cityId == null ||
                            complianceData.law.cityId == '' ||
                            complianceData.law.stateId == '' ||
                            complianceData.law.cityId == -1)
                    ) {
                        return opt;
                    } else {
                        if (opt.stateId == complianceData.law.stateId) {
                            if (
                                complianceData.law.cityId == null ||
                                complianceData.law.cityId == '' ||
                                complianceData.law.cityId == -1
                            ) {
                                return opt;
                            } else {
                                const cityIds =
                                    complianceData.law?.cityId
                                        .split(',')
                                        .map((c) => parseInt(c)) || [];
                                const entityLocationCityIds =
                                    opt?.cities.map((c) => c.cityId) || [];
                                if (
                                    cityIds.some((cityId) =>
                                        entityLocationCityIds.includes(
                                            parseInt(cityId)
                                        )
                                    )
                                ) {
                                    const cities = opt.cities?.filter((o) => {
                                        if (
                                            cityIds.includes(parseInt(o.cityId))
                                        ) {
                                            return o;
                                        }
                                    });
                                    return { ...opt, cities: cities };
                                }
                            }
                        }
                    }
                }
            });
            optData.entityLocations = optData.entityLocations.filter((opt) => {
                if (opt && opt.cities.length > 0) return opt;
            });
            if (optData.entityLocations?.length > 0) {
                return optData;
            } else {
                return false;
            }
        });
        this.setState({
            mapLocationModal: true,
            complianceId: complianceId,
            complianceCode: complianceCode,
            entityListData: entityListParticularCountry.filter((opt) =>
                opt?.entityId ? opt : false
            ),
        });
    };
    closeMapLocationModal = () => {
        this.setState({
            mapLocationModal: false,
            complianceId: '',
            complianceCode: '',
        });
    };
    searchText(value) {
        let searchText = value;
        let isData;
        let filterData = this.state.allComplianceRecords?.filter((values) => {
            isData = values.complianceCode
                .trim()
                .toLowerCase()
                .includes(searchText);
            if (!isData) {
                isData = values.complianceTitle
                    .trim()
                    .toLowerCase()
                    .includes(searchText);
            }
            if (!isData) {
                isData = values?.law?.country?.countryName
                    .trim()
                    .toLowerCase()
                    .includes(searchText);
            }
            if (!isData) {
                if (searchText == 'na') {
                    isData = values?.law?.stateId == '' ? true : false;
                } else {
                    isData = values?.law?.state?.stateName
                        .trim()
                        .toLowerCase()
                        .includes(searchText);
                }
            }
            if (!isData) {
                isData = values?.law?.lawShortName
                    .trim()
                    .toLowerCase()
                    .includes(searchText);
            }
            if (!isData) {
                isData = values?.complianceCategory?.complianceCategoryName
                    .trim()
                    .toLowerCase()
                    .includes(searchText);
            }
            return isData;
        });

        this.setState({
            searchedText: searchText,
            lawComplianceList: this.generateTable(filterData),
        });
    }
    generateTable = (records) => {
        let complianceRecords = [];
        complianceRecords = records.map((complianceData, i) => {
            let action = (
                <a
                    href={() => false}
                    searchvalue={complianceData?.complianceCode}
                    onClick={() => {
                        window.open(
                            '/statutory-compliances/view-details/' +
                            complianceData.id,
                            '_blank'
                        );
                    }}
                    className="text-nowrap"
                >
                    {complianceData?.complianceCode}
                </a>
            );
            let complianceRecordSelected =
                this.state.allocationData?.compliances?.find(
                    (opt) => opt.complianceId == complianceData.id
                );
            let alreadySelectedEntities =
                complianceRecordSelected?.entities || [];
            let alreadySelectedCompanyNames = this.state.entityList
                ?.filter((opt) =>
                    alreadySelectedEntities.includes(opt.entityId)
                )
                ?.map(
                    (opt) =>
                        opt.entityName +
                        ' - ' +
                        complianceRecordSelected?.entityWithLocations?.filter(
                            (optL) => optL.entityId === opt.entityId
                        )?.length
                );
            let entities = alreadySelectedCompanyNames.join('<br />');
            let entityAction = (
                <React.Fragment key={complianceData.id}>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.openMapLocationModal(
                                complianceData.id,
                                complianceData.complianceCode,
                                complianceData
                            );
                        }}
                        className="btn btn-link tbl-action text-primary"
                        title={__t('common.click_to_allocate_entity')}
                    >
                        <svg
                            width="17"
                            height="20"
                            viewBox="0 0 17 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="16"
                                y="7"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(90 16 7)"
                                fill="#7E869E"
                                fillOpacity="0.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <rect
                                x="16"
                                y="15"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(90 16 15)"
                                fill="#7E869E"
                                fillOpacity="0.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <rect
                                x="1"
                                y="5"
                                width="4"
                                height="4"
                                rx="2"
                                transform="rotate(-90 1 5)"
                                fill="#7E869E"
                                fillOpacity="1"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                            <path
                                d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            />
                        </svg>
                    </a>
                    {(complianceData?.complianceAllocation.length > 0 ||
                        complianceData?.trackAllocation.length > 0 ||
                        alreadySelectedEntities.length > 0) && (
                            <>
                                <ReactTooltip
                                    id={
                                        `show-mapped-entities-list-` +
                                        complianceData.id
                                    }
                                    place="left"
                                    html={true}
                                />
                                <a
                                    href={() => false}
                                    className="text-success"
                                    data-tip={entities}
                                    data-for={
                                        `show-mapped-entities-list-` +
                                        complianceData.id
                                    }
                                >
                                    <i className="fas fa-check-circle"></i>
                                </a>
                            </>
                        )}
                </React.Fragment>
            );
            return {
                id: complianceData.id,
                complianceCode: action,
                country: complianceData.law?.country?.countryName,
                state:
                    complianceData.law?.stateId != ''
                        ? complianceData.law?.state?.stateName
                        : NA,
                city:
                    complianceData.law?.cityId != ''
                        ? complianceData.law?.cities
                            ?.map((opt) => opt.cityName)
                            .join('; ')
                        : NA,
                legislationName: complianceData.law?.lawShortName,
                lawCategoryName:
                    complianceData.law?.lawCategory &&
                        complianceData.law?.lawCategory != null
                        ? complianceData.law.lawCategory?.lawCategoryName
                        : '',
                complianceTitle: complianceData.complianceTitle,
                complianceClassification:
                    complianceData?.complianceCategory.complianceCategoryName,
                entity: entityAction,
            };
        });
        return complianceRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawComplianceList &&
            this.props.lawComplianceList !== nextprops.lawComplianceList
        ) {
            if (nextprops.lawComplianceList.length === 0) {
                toastrMessage(__t('alerts.no_compliances_found'), DANGER);
            }
            this.setState({
                allComplianceRecords: nextprops.lawComplianceList,
                lawComplianceList: this.generateTable(
                    nextprops.lawComplianceList
                ),
            });
        }
        if (
            nextprops.allocationApiResponse &&
            this.props.allocationApiResponse !== nextprops.allocationApiResponse
        ) {
            toastrMessage(
                nextprops.allocationApiResponse.message,
                nextprops.allocationApiResponse.responseType
            );
            if (nextprops.allocationApiResponse.responseType === SUCCESS) {
                this.props.history.push('/allocations/users');

            }
        }
    }
    render() {
        const data = {
            columns: listColumns1,
            rows: this.state.lawComplianceList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'new-allocation'} />
                    </Col>
                    <Col lg="12">
                        <Card className="mb-2">
                            <CardHeader>
                                <div className="page-header">

                                    <h4 className="with-back-btn">
                                        <a
                                            href={() => false}
                                            className="dripicons-arrow-thin-left"
                                            onClick={() => {
                                                this.props.history.push(
                                                    '/allocations/users'
                                                );
                                            }}
                                        >
                                            { }
                                        </a>
                                        {this.state.pageHeaderName}
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <AllocationFilterForm
                                    allocationFilterData={
                                        this.state.allocationFilterData
                                    }
                                    operationType={this.state.operationType}
                                    entityData={this.state.entityData}
                                    pageType={this.state.pageType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={(values) => {
                                        this.resetForm(values);
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>

                    {this.state.isTable === true && (
                        <React.Fragment>
                            <Col lg="12">
                                <Card>
                                    <Formik
                                        initialValues={
                                            this.state.allocationData
                                        }
                                        enableReinitialize={true}
                                        onSubmit={this.formSubmitForAssign}
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form>
                                                <CardBody className="custom_table">

                                                    <Button
                                                        className="btn btn-info bulk_btn"
                                                        onClick={() =>
                                                            alert('Coming Soon')
                                                        }
                                                    >
                                                        Bulk Select
                                                    </Button>
                                                    <CustomisedDatatable
                                                        className="table table-bordered table-striped"
                                                        tableRecords={data}
                                                        entries={50}
                                                        sortRows={[
                                                            'complianceCode',
                                                        ]}
                                                    />
                                                </CardBody>
                                                {this.state.lawComplianceList
                                                    ?.length > 0 && (
                                                        <CardFooter>
                                                            <div className="d-flex justify-content-end">
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    className="mr-2"
                                                                    onClick={
                                                                        this
                                                                            .formSubmitForAssign
                                                                    }
                                                                >
                                                                    {__t(
                                                                        'form_labels.allocation.allocate_to_entity'
                                                                    )}
                                                                </Button>
                                                                <Button
                                                                    type="button"
                                                                    color="secondary"
                                                                    className="mr-2"
                                                                    onClick={() => {
                                                                        this.resetAllForm(
                                                                            this
                                                                                .state
                                                                                .allocationFilterData
                                                                        );
                                                                    }}
                                                                >
                                                                    {RESET}
                                                                </Button>
                                                            </div>
                                                        </CardFooter>
                                                    )}
                                            </Form>
                                        )}
                                    </Formik>
                                </Card>
                            </Col>
                            <CustomisedModal
                                isModalOpen={this.state.mapLocationModal}
                                width="100%"
                                onModalDismiss={() =>
                                    this.closeMapLocationModal()
                                }
                                modalName={
                                    __t(
                                        'form_labels.allocation.map_entity_location_for_compliance'
                                    ) +
                                    ' | ' +
                                    this.state.complianceCode
                                }
                            >
                                {this.state.mapLocationModal && (
                                    <MapLocationForm
                                        complianceId={this.state.complianceId}
                                        entityListData={{
                                            entityAllocations:
                                                this.state.entityListData,
                                        }}
                                        allocationFilterData={
                                            this.state.allocationFilterData
                                        }
                                        alreadySelectedLocations={
                                            this.state.allocationData.compliances?.find(
                                                (opt) =>
                                                    opt.complianceId ==
                                                    this.state.complianceId
                                            )?.locations || []
                                        }
                                        alreadySelectedEntities={
                                            this.state.allocationData.compliances?.find(
                                                (opt) =>
                                                    opt.complianceId ==
                                                    this.state.complianceId
                                            )?.entities || []
                                        }
                                        entityWithLocations={
                                            this.state.allocationData.compliances?.find(
                                                (opt) =>
                                                    opt.complianceId ==
                                                    this.state.complianceId
                                            )?.entityWithLocations || []
                                        }
                                        onFormSubmit={(entityWithLocations) => {
                                            let locations =
                                                entityWithLocations.map(
                                                    (opt) => opt.locationId
                                                );
                                            let entities = entityWithLocations
                                                .map((opt) => opt.entityId)
                                                .reduce(function (a, b) {
                                                    if (a.indexOf(b) < 0)
                                                        a.push(b);
                                                    return a;
                                                }, []);
                                            this.formSubmitForMapLocation(
                                                locations,
                                                entities,
                                                entityWithLocations
                                            );
                                        }}

                                    />
                                )}
                            </CustomisedModal>
                        </React.Fragment>
                    )}
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    entityList: makeEntitySelectField('entityList'),
    lawComplianceList: makeLawComplianceSelectField('lawComplianceList'),
    allocationApiResponse: makeAllocationSelectField('allocationApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getEntityListByCompanyGroupIdRequest,
    getLawCompliancesForAllocationRequest,
    allocateCompliancesToEntityRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withComplianceReducer,
    withComplianceSaga,
    withAllocationReducer,
    withAllocationSaga,
    withRouter
)(Index);
