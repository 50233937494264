var i18n = require("i18next");



/* function for get message as per localization */
const __t = (messageKey, replaceData = {}) => {
    let message = "";
    if (messageKey) {
        message = i18n.t(messageKey, replaceData);
    }
    if (message == '') {
        message = messageKey;
    }
    return message;
}

module.exports = __t;