export const listColumns = [
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Event Name',
        field: 'eventName',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State/Province',
        field: 'stateName',
    },
    {
        label: 'City/Location',
        field: 'cityName',
    },
    {
        label: 'Type',
        field: 'type',
    },
    {
        label: 'Event Date',
        field: 'eventOccurrenceDate',
    },
    {
        label: 'Cancel Till Date',
        field: 'cancelTillDate',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        field: 'actions',
        attributes: {
            className: 'action_col',
        },
    }
];
export const initialOngoingFilterDataState = () => {
    return {
        eventId: [],
        entityId: [],
        countryId: [],
        stateId: [],
        cityId: []
    }
}
