import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import {
    getAllocationSearchListRequest,
    assignUserAndDepartmentRequest,
    updateAllocationStatusByAllocationIdsRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
    initialUserAllocationFilterDataState,
    listColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import UserAllocationFilterForm from './UserAllocationFilterForm';
import CustomisedReactTableWithPaginationAndRowSelection from 'components/Application/CustomisedReactTableWithPaginationAndRowSelection';
import {
    DANGER,
    DEFAULT_PAGE_LENGTH,
    SUBMIT,
    SUCCESS,
} from 'constants/commonConstants';
import UserMappingFormModal from './UserMappingFormModal';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import ViewScheduleModal from './ViewScheduleModal';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_NEW_ALLOCATION,
    MODULE_USER_ALLOCATION,
    PERMISSION_ADD,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import AuditLogModal from 'components/Application/AuditLogModal';
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            allocationList: [],
            searchFormData: initialUserAllocationFilterDataState(this.props.match.params.filterType
                ? this.props.match.params.filterType
                : '', this.props.match.params.selectedId
                ? this.props.match.params.selectedId
                : ''),
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            resetTableSection: false,
            totalRecords: 0,
            selectedAllocations: [],
            isModalOpen: false,
            userAllocationData: {},
            isViewScheduleModalOpen: false,
            allocationData: {},
            isAuditModalOpen: false,
            auditId: '',
            fromDashboard: {
                filterType: this.props.match.params.filterType
                    ? this.props.match.params.filterType
                    : '',
                selectedId: this.props.match.params.selectedId
                    ? this.props.match.params.selectedId
                    : ''
            }
        };
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        this.getAllocationSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            this.state.searchFormData
        );
    }
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    getAllocationSearchList = (limit, page, sortBy, values = {}) => {
        let formValues = values;
        if (formValues.type && formValues.type != 1) {
            formValues.isPendingMapping = formValues.type == 2 ? true : false;
        } else {
            delete formValues.isPendingMapping;
        }
        formValues.userAllocation = true;
        let apiData = {
            limit: limit,
            page: page,
            filter: formValues,
            orderBy: sortBy,
        };
        this.props.getAllocationSearchListRequest(apiData, true);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
                currentPage: 1,
                resetTableSection: true
            },
            () => {
                this.getAllocationSearchList(
                    this.state.pageSize,
                    1,
                    this.state.sortBy,
                    this.state.searchFormData
                );
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialUserAllocationFilterDataState(),
                currentPage: 1,
                resetTableSection: true,
            },
            () => {
                this.getAllocationSearchList(this.state.pageSize, 1, {
                    key: 'createdAt',
                    value: 'DESC',
                }, {});
            }
        );
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        { key: data.sortBy[0]?.id, value: sortOrder },
                        this.state.searchFormData
                    );
                }
            );
        }
    }
    handleSearch(data) {
        let pageNum =
            data?.globalFilter == this.state.searchFormData?.searchText
                ? data.pageIndex + 1
                : 1;
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                this.getAllocationSearchList(
                    this.state.pageSize,
                    pageNum,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        );
    }
    openUserMappingFormModal = (allocationData) => {
        this.setState({
            isModalOpen: true,
            userAllocationData: {
                ...allocationData,
                allocationId: allocationData.id,
                entityId: allocationData.entity?.id,
            },
            userMappingOperationType: SUBMIT,
        });
    };
    closeUserMappingFormModal = () => {
        this.setState({
            isModalOpen: false,
            userAllocationData: {},
            userMappingOperationType: SUBMIT,
        });
    };

    openViewScheduleModal = (allocationData) => {
        this.setState({
            isViewScheduleModalOpen: true,
            allocationData: {
                ...allocationData,
                allocationId: allocationData.id,
                entityId: allocationData.entity?.id,
            },
        });
    };
    closeViewScheduleModal = () => {
        this.setState({
            isViewScheduleModalOpen: false,
            allocationData: {},
        });
    };
    scheduleUpdateAllocation = async (allocationId, status) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            status === true
                ? __t('alerts.allocation_schedule_start')
                : __t('alerts.allocation_schedule_stop')
        );
        if (response) {
            this.props.updateAllocationStatusByAllocationIdsRequest({
                allocationId: [allocationId],
                isSchedulerActive: status,
            });
        }
    };

    scheduleUpdateMultiAllocation = async (selectedAllocations, status) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            status === true
                ? __t('alerts.allocation_schedule_start')
                : __t('alerts.allocation_schedule_stop')
        );
        if (response) {
            if (selectedAllocations.length > 0) {
                this.props.updateAllocationStatusByAllocationIdsRequest({
                    allocationId: selectedAllocations.map((opt) => opt.id),
                    isSchedulerActive: status,
                });
            }
        }
    };
    toggleScheduler = (id, isSchedulerActive) => {
        this.scheduleUpdateAllocation(id, !isSchedulerActive);
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((allocationData, i) => {
            let actions = (
                <div className="tbl_action">
                    {allocationData?.entity?.companyGroup?.tenant?.isDbCreated === true && (
                        <a
                            href={() => false}
                            className="btn btn-link text-primary tbl-action"
                            title={__t(
                                'form_labels.allocation.map_departments_users'
                            )}
                            onClick={() => {
                                this.openUserMappingFormModal(allocationData);
                            }}
                        >
                            {allocationData.isPendingMapping === true ? (
                                <i className="fas fa-user-plus"></i>
                            ) : (
                                <i className="fas fa-user-check"></i>
                            )}
                        </a>
                    )}
                    {checkModulePermission(
                        MODULE_USER_ALLOCATION,
                        PERMISSION_EDIT
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('form_labels.allocation.view_schedule')}
                                className="btn btn-link text-info tbl-action"
                                onClick={() => {
                                    this.openViewScheduleModal(allocationData);
                                }}
                            >
                                <i className="fas fa-calendar-alt"></i>
                            </a>
                        )}
                    {allocationData?.entity?.companyGroup?.tenant?.isDbCreated === true && allocationData.isSchedulerActive === false &&
                        allocationData.isPendingMapping === false && (
                            <a
                                href={() => false}
                                title={__t(
                                    'form_labels.allocation.start_schedule'
                                )}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.toggleScheduler(allocationData.id, allocationData.isSchedulerActive)
                                }}
                            >
                                <i className="fas fa-play-circle"></i>
                            </a>
                        )}
                    {allocationData.isSchedulerActive === true &&
                        allocationData.isPendingMapping === false && (
                            <a
                                href={() => false}
                                title={__t(
                                    'form_labels.allocation.stop_schedule'
                                )}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {

                                    this.toggleScheduler(allocationData.id, allocationData.isSchedulerActive)
                                }}
                            >
                                <i className="fas fa-stop-circle"></i>
                            </a>
                        )}

                </div>
            );

            return {
                checkbox: !allocationData.isPendingMapping,
                id: allocationData.id,
                isPendingMapping: allocationData.isPendingMapping,
                isSchedulerActive: allocationData.isSchedulerActive,
                companyGroupName:
                    allocationData.entity?.companyGroup?.companyGroupName,
                entityName: allocationData.entity?.entityName,
                complianceCode: allocationData.lawCompliance?.complianceCode,
                countryName:
                    allocationData.entityLocation?.country?.countryName,
                stateName: allocationData.entityLocation?.state?.stateName,
                cityName: allocationData.entityLocation?.city?.cityName,
                lawShortName: allocationData.lawCompliance?.law?.lawShortName,
                complianceTitle: allocationData.lawCompliance?.complianceTitle,
                complianceClassification:
                    allocationData.lawCompliance?.complianceCategory
                        ?.complianceCategoryName,
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allocationApiResponse &&
            this.props.allocationApiResponse !== nextprops.allocationApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                sortBy: nextprops.allocationApiResponse.data.orderBy,
                pageCount: nextprops.allocationApiResponse.data.totalPages,
                currentPage: nextprops.allocationApiResponse.data.currentPage,
                totalRecords: nextprops.allocationApiResponse.data.totalRecords,
                allocationList: this.generateTable(
                    nextprops.allocationApiResponse.data.complianceList
                ),
            });
        }
        if (
            nextprops.allocationStatusApiResponse &&
            this.props.allocationStatusApiResponse !==
            nextprops.allocationStatusApiResponse
        ) {
            toastrMessage(
                nextprops.allocationStatusApiResponse.message,
                nextprops.allocationStatusApiResponse.responseType
            );
            if (
                nextprops.allocationStatusApiResponse.responseType === SUCCESS
            ) {
                this.getAllocationSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        }
        if (
            nextprops.userAllocationApiResponse &&
            this.props.userAllocationApiResponse !==
            nextprops.userAllocationApiResponse
        ) {
            toastrMessage(
                nextprops.userAllocationApiResponse.message,
                nextprops.userAllocationApiResponse.responseType
            );
            if (nextprops.userAllocationApiResponse.responseType === SUCCESS) {
                this.closeUserMappingFormModal();
                this.getAllocationSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.allocationList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'users-allocation'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={true}
                            dialogName={__t('page_headers.user_allocation')}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <UserAllocationFilterForm
                                    allocationFilterData={
                                        this.state.searchFormData
                                    }
                                    operationType={this.state.operationType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    {!this.state.resetTableSection && (
                        <>
                            <Col lg="12" className="mt-2 mb-3 text-right">
                                {checkModulePermission(
                                    MODULE_NEW_ALLOCATION,
                                    PERMISSION_ADD
                                ) === true && (
                                        <Button
                                            className="btn btn-primary mr-3"
                                            onClick={() => {
                                                this.props.history.push(
                                                    '/allocation/new'
                                                );
                                            }}
                                        >
                                            {__t(
                                                'form_labels.allocation.new_allocation'
                                            )}
                                        </Button>
                                    )}
                                {/* display submit buttons while select any record for start */}
                                {(this.state.selectedAllocations.length > 0 &&
                                    this.state.selectedAllocations.filter(
                                        (opt) => opt.isSchedulerActive === true
                                    ).length ===
                                    this.state.selectedAllocations
                                        .length) || (
                                        <Button
                                            className="btn btn-success mr-3"
                                            onClick={() => {
                                                if (
                                                    this.state.selectedAllocations
                                                        .length === 0
                                                ) {
                                                    toastrMessage(
                                                        __t(
                                                            'alerts.please_select_record'
                                                        ),
                                                        DANGER
                                                    );
                                                } else {
                                                    this.scheduleUpdateMultiAllocation(
                                                        this.state
                                                            .selectedAllocations,
                                                        true
                                                    );
                                                }
                                            }}
                                        >
                                            <i className="fas fa-play"></i>
                                            {__t(
                                                'form_labels.allocation.start_schedule'
                                            )}
                                        </Button>
                                    )}
                                {(this.state.selectedAllocations.length > 0 &&
                                    this.state.selectedAllocations.filter(
                                        (opt) => opt.isSchedulerActive === false
                                    ).length ===
                                    this.state.selectedAllocations
                                        .length) || (
                                        <Button
                                            className="btn btn-danger"
                                            onClick={() => {
                                                if (
                                                    this.state.selectedAllocations
                                                        .length === 0
                                                ) {
                                                    toastrMessage(
                                                        __t(
                                                            'alerts.please_select_record'
                                                        ),
                                                        DANGER
                                                    );
                                                } else {
                                                    this.scheduleUpdateMultiAllocation(
                                                        this.state
                                                            .selectedAllocations,
                                                        false
                                                    );
                                                }
                                            }}
                                        >
                                            <i className="fas fa-stop"></i>
                                            {__t(
                                                'form_labels.allocation.stop_schedule'
                                            )}
                                        </Button>
                                    )}
                            </Col>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="custom_table table-responsive">
                                        <CustomisedReactTableWithPaginationAndRowSelection
                                            onchangeCheckbox={(row) => {
                                                this.setState({
                                                    selectedAllocations: row
                                                        .map((itemRow) => ({
                                                            id: itemRow.id,
                                                            isSchedulerActive:
                                                                itemRow.isSchedulerActive,
                                                            isPendingMapping:
                                                                itemRow.isPendingMapping,
                                                        }))
                                                        .filter((opt) => {
                                                            if (
                                                                !opt.isPendingMapping
                                                            )
                                                                return opt;
                                                        }),
                                                });
                                            }}
                                            paging={true}
                                            className="table table-bordered table-striped"
                                            displayEntries={true}
                                            searching={true}
                                            currentPage={this.state.currentPage}
                                            pageCount={this.state.pageCount}
                                            handleFetchData={
                                                this.handleFetchData
                                            }
                                            handleSort={this.handleSort}
                                            handleSearch={this.handleSearch}
                                            preFilledSearchText={
                                                this.state.searchFormData
                                                    ?.searchText
                                            }
                                            tableData={data}
                                            totalRecords={
                                                this.state.totalRecords
                                            }
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </>
                    )}
                    <UserMappingFormModal
                        modalName={__t(
                            'form_labels.allocation.map_departments_users'
                        )}
                        isModalOpen={this.state.isModalOpen}
                        operationType={this.state.userMappingOperationType}
                        userAllocationData={this.state.userAllocationData}
                        onModalDismiss={() => this.closeUserMappingFormModal()}
                        onFormValid={(values) => {
                            this.props.assignUserAndDepartmentRequest(
                                values.allocationId,
                                values
                            );
                        }}
                    />

                    <ViewScheduleModal
                        modalName={__t('form_labels.allocation.view_schedule')}
                        isModalOpen={this.state.isViewScheduleModalOpen}
                        allocationData={this.state.allocationData}
                        onModalDismiss={() => this.closeViewScheduleModal()}
                    />
                </Row>
                <AuditLogModal
                    exportBtn={true}
                    isModalOpen={this.state.isAuditModalOpen}
                    modalName={__t('page_headers.audit_trails')}
                    auditType={'complianceAllocation'}
                    auditId={this.state.auditId}
                    onModalDismiss={() => this.closeAuditLogModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    allocationApiResponse: makeAllocationSelectField('allocationApiResponse'),
    userAllocationApiResponse: makeAllocationSelectField(
        'userAllocationApiResponse'
    ),
    allocationStatusApiResponse: makeAllocationSelectField(
        'allocationStatusApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getAllocationSearchListRequest,
    assignUserAndDepartmentRequest,
    updateAllocationStatusByAllocationIdsRequest,
});
export default compose(
    withConnect,
    withAllocationReducer,
    withAllocationSaga,
    withRouter
)(Index);
