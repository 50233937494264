import React, { Component } from 'react';
import { Row, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, CLEAR, NONE, SEARCH, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawListRequest,
    getComplianceCategoriesRequest
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { initialTrackAllocationFilterDataState } from './CommonFunctions';
import __t from 'i18n/translator';
import { selectSortedLawSearchList } from 'store/Masters/Law/selector';
import { selectSortedComplianceCategoriesList } from 'store/LawCompliance/selector';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({ key: subIndustryKey, saga: subIndustrySaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({ key: companyGroupKey, saga: companyGroupSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({ key: lawCategoryKey, saga: lawCategorySaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class ViewAllocationFilterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            filterData: initialTrackAllocationFilterDataState(),
            operationType: SUBMIT,
        };
    }
    componentDidMount() {
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getComplianceCategoriesRequest();
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest((countryIds) ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest((stateIds) ? stateIds : []);
    }
    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: (values.countryId) ? values.countryId : [],
                stateId: (values.stateId) ? values.stateId : [],
                cityId: (values.cityId) ? values.cityId : [],
                lawCategoryId: (values.lawCategoryId) ? values.lawCategoryId : []
            },
            orderBy: {}
        }
        this.props.getLawListRequest(apiData, false);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.setState({
            filterData: initialTrackAllocationFilterDataState()
        }, () => {
            this.props.onFormReset();
        });
    }
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.state.filterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="companyGroupId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCompanyGroupListDropdown.filter((opt) => opt.value != NONE)
                                        }
                                        onChange={opt => {
                                            let companyGroupIds = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'companyGroupId',
                                                companyGroupIds
                                            );
                                            if (companyGroupIds.length > 0) {
                                                this.props.getEntityListByCompanyGroupIdRequest(companyGroupIds);
                                            }
                                        }}
                                        value={this.props.sortedCompanyGroupListDropdown.filter(
                                            option =>
                                                values.companyGroupId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.company_groups')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="entityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropdown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'entityId',
                                                (opt) ? opt.map(x => x.value) : []
                                            );
                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            option =>
                                                values.entityId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.entity')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={this.props.sortedCountryListDropdown}
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={opt => {
                                            let countryIds = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'countryId',
                                                countryIds
                                            );
                                            setFieldValue(
                                                'stateId',
                                                []
                                            );
                                            setFieldValue(
                                                'cityId',
                                                []
                                            );
                                            this.getStateListByCountry(countryIds);
                                            this.getCityListByState([]);
                                            this.getLawsByFilters({ ...values, countryId: countryIds });
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            option =>
                                                values.countryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.country')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedStateListDropdown
                                        }
                                        onChange={opt => {
                                            let stateId = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'stateId',
                                                stateId
                                            );
                                            setFieldValue(
                                                'cityId',
                                                []
                                            );
                                            this.getCityListByState(opt && opt.map(x => x.value));
                                            this.getLawsByFilters({ ...values, stateId: stateId });
                                        }}
                                        value={this.props.sortedStateListDropdown?.filter(
                                            option =>
                                                values.stateId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.state')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCityListDropdown
                                        }
                                        onChange={opt => {
                                            let cityId = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'cityId',
                                                cityId
                                            );
                                            this.getLawsByFilters({ ...values, cityId: cityId });
                                        }}
                                        value={this.props.sortedCityListDropdown?.filter(
                                            option => values.cityId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.city')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="lawCategoryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedLawCategoryListDropdown
                                        }
                                        onChange={opt => {
                                            let lawCategoryId = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'lawCategoryId',
                                                lawCategoryId
                                            );
                                            this.getLawsByFilters({ ...values, lawCategoryId: lawCategoryId });
                                        }}
                                        value={this.props.sortedLawCategoryListDropdown?.filter(
                                            option =>
                                                values.lawCategoryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.law_category')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-10'>
                                    <Field
                                        component={CustomMultiSelect}
                                        className="form-control selectbox"
                                        name="lawId[]"
                                        options={this.props.sortedLawListDropDown}
                                        onChange={opt => {
                                            let selectedValues = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'lawId',
                                                selectedValues
                                            );
                                        }}
                                        value={this.props.sortedLawListDropDown?.filter(
                                            option =>
                                                values.lawId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                        key="laws"
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.laws')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-2 d-flex justify-content-end">
                                    <div className="d-inline-block">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="mr-1"
                                        >
                                            {SEARCH}
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >{CLEAR}</Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedLawListDropDown: selectSortedLawSearchList(),
    sortedComplianceCategoryListDropDown: selectSortedComplianceCategoriesList()
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawListRequest,
    getComplianceCategoriesRequest
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withEntityReducer,
    withEntitySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(ViewAllocationFilterForm);