import { GET_CHAT_LIST_BY_TASK_ID_REQUEST, GET_CHAT_LIST_BY_TASK_ID_RESPONSE, GET_MESSAGE_LIST_BY_CHAT_ID_REQUEST, GET_MESSAGE_LIST_BY_CHAT_ID_RESPONSE, ADD_NEW_MESSAGE_RESPONSE, ADD_NEW_MESSAGE_REQUEST, GET_TASK_USERS_REQUEST, GET_TASK_USERS_RESPONSE, ADD_OR_REMOVE_USER_REQUEST, ADD_OR_REMOVE_USER_RESPONSE, CREATE_CHAT_REQUEST, CREATE_CHAT_RESPONSE, RENAME_GROUP_REQUEST, RENAME_GROUP_RESPONSE } from "./actionTypes";

export const getChatListByTaskIdRequest = (taskId) => {
    return {
        type: GET_CHAT_LIST_BY_TASK_ID_REQUEST,
        payload: { taskId: taskId },
    };
};

export const getChatListByTaskIdResponse = (chatList) => {
    return {
        type: GET_CHAT_LIST_BY_TASK_ID_RESPONSE,
        payload: { chatList },
    };
};

export const getMessageListByChatIdRequest = (chatId) => {
    return {
        type: GET_MESSAGE_LIST_BY_CHAT_ID_REQUEST,
        payload: { chatId: chatId },
    };
}

export const getMessageListByChatIdResponse = (message, responseType, data) => {
    return {
        type: GET_MESSAGE_LIST_BY_CHAT_ID_RESPONSE,
        payload: { message, responseType, data },
    };
}

export const addNewMessageRequest = data => {
    return {
        type: ADD_NEW_MESSAGE_REQUEST,
        payload: { data },
    };
};

export const addNewMessageResponse = (message, responseType, data) => {
    return {
        type: ADD_NEW_MESSAGE_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getTaskUsersRequest = (taskId) => {
    return {
        type: GET_TASK_USERS_REQUEST,
        payload: { taskId: taskId },
    };
}

export const getTaskUsersResponse = (taskUserList) => {
    return {
        type: GET_TASK_USERS_RESPONSE,
        payload: { taskUserList },
    };
}

export const addOrRemoveUserRequest = data => {
    return {
        type: ADD_OR_REMOVE_USER_REQUEST,
        payload: { data },
    };
};

export const addOrRemoveUserResponse = (message, responseType, data) => {
    return {
        type: ADD_OR_REMOVE_USER_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const createChatRequest = data => {
    return {
        type: CREATE_CHAT_REQUEST,
        payload: { data },
    };
};

export const createChatResponse = (message, responseType, data) => {
    return {
        type: CREATE_CHAT_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const renameGroupRequest = data => {
    return {
        type: RENAME_GROUP_REQUEST,
        payload: { data },
    };
};

export const renameGroupResponse = (message, responseType, data) => {
    return {
        type: RENAME_GROUP_RESPONSE,
        payload: { message, responseType, data },
    };
};