import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.city || initialState;
const makeCitySelectField = field =>
    createSelector(reducerState, cityState => cityState[field]);
const selectSortedCityList = () =>
    createSelector(makeCitySelectField('cityList'), cityList =>
        cityList.map(opt => ({
            value: opt.id,
            label: opt.cityName,
            stateId: opt.stateId
        }))
    );
export {
    makeCitySelectField,
    selectSortedCityList
};
