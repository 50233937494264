import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_DELEGATION_REQUEST,
    DELETE_DELEGATION_REQUEST,
    GET_ALL_DELEGATION_LIST_REQUEST,
    UPDATE_DELEGATION_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addDelegationResponse,
    deleteDelegationResponse,
    getAllDelegationListRequest,
    getAllDelegationListResponse,
    updateDelegatioResponse,
} from './actions';
import {
    TASK_USER_DELEGATION,
    TASK_USER_DELEGATIONS,
} from 'helpers/apiConstants';
function* addDelegation({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            TASK_USER_DELEGATION,
            data
        );
        if (response.status) {
            yield put(getAllDelegationListRequest());
            yield put(addDelegationResponse(response.message, SUCCESS));
        } else {
            yield put(addDelegationResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllDelegationList() {
    try {
        const response = yield call(invokeApi, 'get', TASK_USER_DELEGATIONS);

        if (response.status) {
            yield put(getAllDelegationListResponse(response.data.data));
        } else {
            yield put(getAllDelegationListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateDelegation({ payload: { userDelegationId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            TASK_USER_DELEGATION + '/' + userDelegationId,
            data
        );
        if (response.status) {
            yield put(getAllDelegationListRequest());
            yield put(updateDelegatioResponse(response.message, SUCCESS));
        } else {
            yield put(updateDelegatioResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteDelegationId({ payload: { delegationId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            TASK_USER_DELEGATION + '/' + delegationId
        );
        if (response.status) {
            yield put(getAllDelegationListRequest());
            yield put(deleteDelegationResponse(response.message, SUCCESS));
        } else {
            yield put(deleteDelegationResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchDelegation() {
    yield takeEvery(ADD_DELEGATION_REQUEST, addDelegation);
    yield takeEvery(GET_ALL_DELEGATION_LIST_REQUEST, getAllDelegationList);
    yield takeEvery(UPDATE_DELEGATION_REQUEST, updateDelegation);
    yield takeEvery(DELETE_DELEGATION_REQUEST, deleteDelegationId);
}
function* delegationSaga() {
    yield all([fork(watchDelegation)]);
}
export default delegationSaga;
