import React, { Component } from 'react';
import {
    Row, Col, FormGroup, Button, Label
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { Field, FieldArray } from 'formik';
import Select from 'react-select';

class FundEntityTasksFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { values, setFieldValue, fundNameDropdownList, sortedEntityListDropdown } = this.props;
        return (
            <FieldArray
                name="entityTasks"
                render={arrayHelpers => (
                    <>
                        {values.entityTasks?.length > 0 &&
                            values.entityTasks.map(
                                (item, idx) => {
                                    let entityDropdownListPerItem = sortedEntityListDropdown.filter((x) => {
                                        if (values?.entityId.includes(x.value)) {
                                            let fundMaster = fundNameDropdownList.find((f) => f.value === item.fundMasterId);
                                            if (fundMaster) {
                                                return fundMaster.entityId.includes(x.value)
                                            }
                                        }
                                    });
                                    return (
                                        <Row key={idx}>
                                            <FormGroup className='col-4'>
                                                <Field
                                                    component={Select}
                                                    name={`entityTasks.${idx}.fundMasterId`}
                                                    className="form-control selectbox"
                                                    options={fundNameDropdownList.filter((option) => {
                                                        let fundMasterIds = values.entityTasks.map((et) => et.fundMasterId);
                                                        return !(fundMasterIds.includes(option.value))
                                                    })}
                                                    onChange={(opt) => {
                                                        let fundMasterEntity = [];
                                                        if (opt) {
                                                            fundMasterEntity = opt.entityId.filter((x) => this.props.entityIds.includes(x));
                                                        }
                                                        setFieldValue(`entityTasks.${idx}.fundMasterId`, opt ? opt.value : '');
                                                        setFieldValue(`entityTasks.${idx}.entityId`, opt ? fundMasterEntity.map((x) => x) : []);
                                                    }}
                                                    value={fundNameDropdownList?.filter((option) => option.value === item.fundMasterId)}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.funds.task')}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup className='col'>
                                                <Field
                                                    component={Select}
                                                    name={`entityTasks.${idx}.entityId`}
                                                    className="form-control selectbox"
                                                    options={entityDropdownListPerItem}
                                                    onChange={(opt) => {
                                                        setFieldValue(`entityTasks.${idx}.entityId`, opt ? opt.map((x) => x.value) : []);
                                                    }}
                                                    value={sortedEntityListDropdown?.filter((option) => item.entityId.includes(option.value))}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.funds.entity')}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <Col lg="2" className='pl-0' style={{ flex: 'auto', maxWidth: 'fit-content' }}>
                                                {values.entityTasks.length > 1 ? (
                                                    <Button
                                                        type="button"
                                                        color='danger'
                                                        className="btn-sm mr-2 mt-1 px-2"
                                                        onClick={() => {
                                                            arrayHelpers.remove(
                                                                idx
                                                            );
                                                        }} // remove a row from the list
                                                    >
                                                        <i className="fas fa-minus m-0"></i>
                                                    </Button>
                                                ) : null}
                                                {(values
                                                    .entityTasks
                                                    .length ===
                                                    idx + 1) ? (
                                                    <Button
                                                        type="button"
                                                        color='primary'
                                                        className='btn-sm mt-1 px-2'
                                                        onClick={() => {
                                                            arrayHelpers.insert(idx + 1, {
                                                                id: 0,
                                                                fundMasterId: '',
                                                                entityId: ''
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-plus m-0"></i>
                                                    </Button>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    )

                                }
                            )}
                    </>
                )}
            />
        );
    }
}
const mapStatetoProps = createStructuredSelector({

});
const withConnect = connect(mapStatetoProps, {

});

export default compose(withConnect, withRouter)(FundEntityTasksFields);
