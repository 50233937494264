import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, ENABLE_DELETE, SUBMIT, UPDATE } from 'constants/commonConstants';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllStateListRequest,
    updateStateStatusByStateIdRequest,
    addStateRequest,
    updateStateRequest,
    getCountryListByStatusRequest,
    deleteStateByStateIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import { makeStateSelectField } from 'store/Masters/State/selector';
import { initialStateDataState, listColumns } from './CommonFunctions';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import Switch from 'react-switch';
import AddStateForm from './AddStateForm';
import { checkModulePermission } from 'helpers/projectUtils';
import { MODULE_STATE, PERMISSION_DELETE, PERMISSION_EDIT } from 'constants/databaseConstants';
import AuditLogModal from 'components/Application/AuditLogModal';
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateList: [],
            stateData: initialStateDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: '',
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        // Retrieve country list
        this.props.getCountryListByStatusRequest(ACTIVE);
        // Retrieve state list
        this.props.getAllStateListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateStateStatusByStateIdRequest(id, checked);
    }
    viewState(stateData) {
        this.setState(
            {
                stateData: initialStateDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ stateData: stateData });
            }
        );
    }
    deleteState = async (stateId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.state_delete')
        );
        if (response) {
            this.props.deleteStateByStateIdRequest(stateId);
        }
    };
    formSubmit = (values) => {
        this.setState({ stateData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateStateRequest(values.id, values);
        } else {
            this.props.addStateRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            stateData: initialStateDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let stateRecords = [];
        stateRecords = records.map((stateData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_STATE, PERMISSION_EDIT) ===
                        true && (
                            <a
                                title={__t('common.edit')}
                                className='btn btn-link text-success tbl-action'
                                href={void 0}
                                onClick={() => {
                                    this.viewState(stateData)
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('state') &&
                        checkModulePermission(
                            MODULE_STATE,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                title={__t('common.delete')}
                                className='btn btn-link text-danger tbl-action'
                                href={void 0}
                                onClick={() => {
                                    this.deleteState(stateData.id)
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(stateData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(stateData.id)}
                        checked={stateData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            return {
                stateName: stateData.stateName,
                countryName: stateData.countryName,
                isActive: isActive,
                actions: actions,
            };
        })
        return stateRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.stateList &&
            this.props.stateList !== nextprops.stateList
        ) {
            this.setState({
                stateList: this.generateTable(nextprops.stateList),
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.stateList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'state'} />
                        </Col>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t('page_headers.state_list')}
                                        </h4>
                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <CustomisedDatatable tableRecords={data} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="5">
                            <Card>
                                <CardHeader>
                                    <div className='page-header'>
                                        <h4>
                                            {__t(
                                                'page_headers.state_form'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <AddStateForm
                                        operationType={this.state.operationType}
                                        stateData={this.state.stateData}
                                        sortedCountryListDropDown={
                                            this.props.sortedCountryListDropDown
                                        }
                                        operationResponse={
                                            this.props.stateApiResponse
                                        }
                                        onFormValid={this.formSubmit}
                                        onFormReset={this.resetForm}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <AuditLogModal
                        exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'state'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    stateList: makeStateSelectField('stateList'),
    stateApiResponse: makeStateSelectField('stateApiResponse'),
    sortedCountryListDropDown: selectSortedCountryList(),
});
const withConnect = connect(mapStatetoProps, {
    getAllStateListRequest,
    updateStateStatusByStateIdRequest,
    getCountryListByStatusRequest,
    addStateRequest,
    updateStateRequest,
    deleteStateByStateIdRequest,
});
export default compose(
    withConnect,
    withStateReducer,
    withStateSaga,
    withCountryReducer,
    withCountrySaga,
    withRouter
)(Index);
