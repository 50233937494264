import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ENABLE_DELETE, SUBMIT, UPDATE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllLawCategoryListRequest,
    updateLawCategoryStatusByLawCategoryIdRequest,
    addLawCategoryRequest,
    updateLawCategoryRequest,
    deleteLawCategoryByLawCategoryIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import { makeLawCategorySelectField } from 'store/Masters/LawCategory/selector';
import { initialLawCategoryDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import AddLawCategoryForm from './AddLawCategoryForm';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_LAW_CATEGORY,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportLawCategoryList: [],
            lawCategoryList: [],
            lawCategoryData: initialLawCategoryDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: '',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve lawCategory list
        this.props.getAllLawCategoryListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateLawCategoryStatusByLawCategoryIdRequest(id, checked);
    }
    viewLawCategory(lawCategoryData) {
        this.setState(
            {
                lawCategoryData: initialLawCategoryDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ lawCategoryData: lawCategoryData });
            }
        );
    }
    deleteLawCategory = async (lawCategoryId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.law_category_delete')
        );
        if (response) {
            this.props.deleteLawCategoryByLawCategoryIdRequest(lawCategoryId);
        }
    };
    formSubmit = (values) => {
        this.setState({ lawCategoryData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateLawCategoryRequest(values.id, values);
        } else {
            this.props.addLawCategoryRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            lawCategoryData: initialLawCategoryDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let lawCategoryRecords = [];
        lawCategoryRecords = records.map((lawCategoryData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(
                        MODULE_LAW_CATEGORY,
                        PERMISSION_EDIT
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewLawCategory(lawCategoryData);
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('lawCategory') && checkModulePermission(
                        MODULE_LAW_CATEGORY,
                        PERMISSION_DELETE
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteLawCategory(lawCategoryData.id);
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(lawCategoryData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(lawCategoryData.id)}
                        checked={lawCategoryData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            return {
                lawCategoryName: lawCategoryData.lawCategoryName,
                isActive: isActive,
                actions: actions,
            };
        });
        return lawCategoryRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.lawCategoryList &&
            this.props.lawCategoryList !== nextprops.lawCategoryList
        ) {
            this.setState({
                exportLawCategoryList: nextprops.lawCategoryList,
                lawCategoryList: this.generateTable(nextprops.lawCategoryList),
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.lawCategoryList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'law-category'} />
                        </Col>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'page_headers.law_category_list'
                                            )}
                                        </h4>
                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage
                                        activepage={this.state.activePage}
                                        onHandlePageChange={this.handlePageChange}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {(checkModulePermission(
                            MODULE_LAW_CATEGORY,
                            PERMISSION_ADD
                        ) === true ||
                            checkModulePermission(
                                MODULE_LAW_CATEGORY,
                                PERMISSION_EDIT
                            ) === true) && (
                                <Col lg="5">
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'page_headers.law_category_form'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <AddLawCategoryForm
                                                operationType={
                                                    this.state.operationType
                                                }
                                                lawCategoryData={
                                                    this.state.lawCategoryData
                                                }
                                                operationResponse={
                                                    this.props
                                                        .lawCategoryApiResponse
                                                }
                                                onFormValid={this.formSubmit}
                                                onFormReset={this.resetForm}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                    </Row>

                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'lawCategory'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    lawCategoryList: makeLawCategorySelectField('lawCategoryList'),
    lawCategoryApiResponse: makeLawCategorySelectField(
        'lawCategoryApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getAllLawCategoryListRequest,
    updateLawCategoryStatusByLawCategoryIdRequest,
    addLawCategoryRequest,
    updateLawCategoryRequest,
    deleteLawCategoryByLawCategoryIdRequest,
});
export default compose(
    withConnect,
    withLawCategoryReducer,
    withLawCategorySaga,
    withRouter
)(Index);
