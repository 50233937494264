import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import { RESET, SUCCESS } from 'constants/commonConstants';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLawComplianceListByEventIdRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { toastrMessage } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import { makeEventSelectField } from 'store/Masters/Event/selector';
import ComplianceSubItem from 'components/Application/ComplianceSubItem';
import CustomisedModal from 'components/Application/CustomisedModal';
import MapLocationForm from './MapLocationForm';
import { compareIntValue } from 'helpers/generalUtils';
let childCmpIds = [];
class EventComplianceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.eventId
                ? parseInt(this.props.eventId)
                : '',
            formEventData: this.props.formEventData,
            eventData: {},
            complianceList: [],
            mapLocationModal: false,
            complianceId: '',
            complianceCode: '',
            activeComplianceData: {},
            entityListData: [],
            allocationData: this.props.formEventData.preSelected,
            childCmpIds: []
        };
    }
    componentDidMount() {
        this.props.getLawComplianceListByEventIdRequest(this.state.eventId);
    }
    openMapLocationModal = (complianceId, complianceCode, complianceData) => {
        childCmpIds = [];
        /**
         *  WARNING | Do not Modify code without any proper requirement confirmation
         */
        let info = this.props.entityList; // getting all entity list by company group from api
        let entityListByGroup = info.filter((opt) => {
            return opt.isActive;
        });
        // grouping all locations data by state and their cities
        info = entityListByGroup.map((opt) => {
            let entityLocationsList = opt.entityLocations.filter((opt1) => {
                return opt1.isActive;
            }).reduce(function (
                res,
                obj
            ) {
                if (!res[obj.stateId]) {
                    res[obj.stateId] = {
                        countryId: obj.countryId,
                        stateId: obj.stateId,
                        stateName: obj?.state?.stateName,
                        cities: [],
                    };
                }
                res[obj.stateId].cities.push({
                    locationId: obj.id,
                    cityId: obj.cityId,
                    cityName: obj?.city?.cityName,
                    pincode: obj.pincode,
                });
                return res;
            },
                Object.create(null));
            return {
                entityId: opt.id,
                entityName: opt.entityName,
                entityLocations: Object.values(entityLocationsList).map(
                    (option) => {
                        if (option.cities.length > 0) {
                            return option;
                        }
                    }
                ),
            };
        });
        /**
         * -------------------------------------------------------------------------------------------------------------
         * Filter and map city data as per compliance selection
         * Example: National Level | "Compliance" => "Abc", "Country" => "India", "State" => "", "City" => ""
         * Example: National Level | "Compliance" => "Abc", "Country" => "India", "State" => "All", "City" => "All"
         * Example: State Level | "Compliance" => "Abc", "Country" => "India", "State" => "Gujarat", "City" => "All"
         * Example: City Level | "Compliance" => "Abc", "Country" => "India", "State" => "Gujarat", "City"=> "Ahmadabad;Surat"
         * We are filtering data by the compliance level whether its "national","state","city" and in last setting the state
         * value for "entityListData" for the child component UI => "MapLocationForm.js"
         * -------------------------------------------------------------------------------------------------------------
         * */
        let entityListParticularCountry = info.map((optData) => {
            optData.entityLocations = optData.entityLocations?.map((opt) => {
                if (opt.countryId == complianceData.law.countryId) {
                    if (
                        (complianceData.law.stateId == null ||
                            complianceData.law.stateId == '' ||
                            complianceData.law.stateId == -1) &&
                        (complianceData.law.cityId == null ||
                            complianceData.law.stateId == '' ||
                            complianceData.law.cityId == -1)
                    ) {
                        return opt;
                    } else {
                        if (opt.stateId == complianceData.law.stateId) {
                            if (
                                complianceData.law.cityId == null ||
                                complianceData.law.cityId == -1
                            ) {
                                return opt;
                            } else {
                                const cityIds =
                                    complianceData.law?.cityId
                                        .split(',')
                                        .map((c) => parseInt(c)) || [];
                                const entityLocationCityIds =
                                    opt?.cities.map((c) => c.cityId) || [];
                                if (
                                    cityIds.some((cityId) =>
                                        entityLocationCityIds.includes(
                                            parseInt(cityId)
                                        )
                                    )
                                ) {
                                    const cities = opt.cities?.filter((o) => {
                                        if (
                                            cityIds.includes(parseInt(o.cityId))
                                        ) {
                                            return o;
                                        }
                                    });
                                    return { ...opt, cities: cities };
                                }
                            }
                        }
                    }
                }
            });
            optData.entityLocations = optData.entityLocations.filter((opt) => {
                if (opt && opt.cities.length > 0) return opt;
            });
            if (optData.entityLocations?.length > 0) {
                return optData;
            } else {
                return false;
            }
        });
        this.setState({
            mapLocationModal: true,
            complianceId: complianceId,
            complianceCode: complianceCode,
            activeComplianceData: complianceData,
            entityListData: entityListParticularCountry.filter((opt) =>
                opt?.entityId ? opt : false
            ),
        });
    };
    closeMapLocationModal = () => {
        this.setState({
            mapLocationModal: false,
            complianceId: '',
            complianceCode: '',
            activeComplianceData: {}
        });
    };
    getAllNestedChildCompliances = async (row) => {
        if (row && row.subTask.length > 0) {
            await Promise.all(row.subTask.map(async (opt) => {
                childCmpIds.push(opt.id);
                await this.getAllNestedChildCompliances(opt);
            }));
        }
    }
    formSubmitForMapLocation = async (locations, entities, entityWithLocations) => {
        await this.getAllNestedChildCompliances(this.state.activeComplianceData);
        let compliances = this.state.allocationData.compliances;
        if (compliances.find((opt) => opt.complianceId === this.state.complianceId)) {
            compliances = compliances.map((opt) =>
                opt.complianceId === this.state.complianceId
                    ? {
                        ...opt,
                        locations: locations,
                        entities: entities,
                        entityWithLocations: entityWithLocations,
                    }
                    : opt
            );
        } else {
            compliances.push({
                complianceId: this.state.complianceId,
                locations: locations,
                entities: entities,
                entityWithLocations: entityWithLocations,
            });
        }
        if (locations.length == 0 && entities.length == 0 && entityWithLocations.length == 0) {
            compliances = compliances.filter((cmp) => {
                if (!childCmpIds.includes(cmp.complianceId)) {
                    return cmp;
                }
            });

        } else {
            childCmpIds.forEach((childCmpId) => {
                let complianceIsExist = compliances.findIndex((cmp) => cmp.complianceId == childCmpId);
                if (complianceIsExist > -1) {
                    compliances.splice(complianceIsExist, 1);
                }
                compliances.push({
                    complianceId: childCmpId,
                    locations: locations,
                    entities: entities,
                    entityWithLocations: entityWithLocations,
                });
            });
        }

        this.setState({
            allocationData: {
                compliances: compliances.filter((opt) => opt.entityWithLocations?.length > 0)
            }
        }, () => {
            this.closeMapLocationModal();
        });
    };
    formSubmitForAssignEvent = () => {
        this.props.onFormSubmitForAssignEvent(this.state.eventId, this.state.allocationData)
    }
    resetAllForm = () => {
        this.setState({
            allocationData: {
                compliances: []
            }
        })
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.eventComplianceListApiResponse &&
            this.props.eventComplianceListApiResponse !==
            nextprops.eventComplianceListApiResponse
        ) {
            if (
                nextprops.eventComplianceListApiResponse.responseType ===
                SUCCESS
            ) {
                this.setState({
                    eventData:
                        nextprops.eventComplianceListApiResponse.data
                            .eventData,
                    complianceList: nextprops.eventComplianceListApiResponse.data
                        .complianceList,
                });
            } else {
                toastrMessage(
                    nextprops.eventComplianceListApiResponse.message,
                    nextprops.eventComplianceListApiResponse.responseType
                );
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card className='event_compliances'>
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        <a
                                            href={() => false}
                                            className="dripicons-arrow-thin-left"
                                            onClick={() => {
                                                this.props.hideComplianceList()
                                            }}
                                        >
                                            { }
                                        </a>
                                        <span>
                                            {this.state.eventData?.eventName}
                                        </span>
                                    </h4>
                                    <span className='event_country'><img width={20} src={'/data/flags/' + this.state.eventData?.country?.isoCode2 + '.svg'} alt='flag' /> {this.state.eventData?.country?.countryName}</span>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {/* Event Compliance Tree List */}
                                <div className="event-compliances tree_details">
                                    {this.state.complianceList?.length > 0 &&
                                        [...this.state.complianceList].sort((item1, item2) => compareIntValue(item1.sequenceOrder, item2.sequenceOrder))?.map((compliance, key) => {
                                            if (this.state.formEventData.compliances.includes(compliance.id)) {
                                                return (
                                                    <div
                                                        className={
                                                            compliance.subTask.length > 0
                                                                ? ' tree-parent has-child '
                                                                : ' tree-parent'
                                                        }
                                                        key={compliance.id}
                                                    >

                                                        <ComplianceSubItem
                                                            allocationData={this.state.allocationData}
                                                            type={'allocation'}
                                                            openMapLocationModal={this.openMapLocationModal}
                                                            history={this.props.history}
                                                            subTask={compliance}
                                                        />
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="mr-2"
                                        onClick={
                                            this
                                                .formSubmitForAssignEvent
                                        }
                                    >
                                        {__t(
                                            'form_labels.allocation.assign_event_compliances'
                                        )}
                                    </Button>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        className="mr-2"
                                        onClick={() => {
                                            this.resetAllForm();
                                        }}
                                    >
                                        {RESET}
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <CustomisedModal
                    isModalOpen={this.state.mapLocationModal}
                    width="100%"
                    onModalDismiss={() =>
                        this.closeMapLocationModal()
                    }
                    modalName={
                        __t(
                            'form_labels.allocation.map_entity_location_for_compliance'
                        ) +
                        ' | ' +
                        this.state.complianceCode
                    }
                >
                    {this.state.mapLocationModal && (
                        <MapLocationForm
                            complianceId={this.state.complianceId}
                            entityListData={{
                                entityAllocations:
                                    this.state.entityListData,
                            }}
                            allocationFilterData={
                                this.props.allocationFilterData
                            }
                            alreadySelectedLocations={
                                this.state.allocationData.compliances?.find(
                                    (opt) =>
                                        opt.complianceId ==
                                        this.state.complianceId
                                )?.locations || []
                            }
                            alreadySelectedEntities={
                                this.state.allocationData.compliances?.find(
                                    (opt) =>
                                        opt.complianceId ==
                                        this.state.complianceId
                                )?.entities || []
                            }
                            entityWithLocations={
                                this.state.allocationData.compliances?.find(
                                    (opt) =>
                                        opt.complianceId ==
                                        this.state.complianceId
                                )?.entityWithLocations || []
                            }
                            onFormSubmit={(entityWithLocations) => {
                                let locations =
                                    entityWithLocations.map(
                                        (opt) => opt.locationId
                                    );
                                let entities = entityWithLocations
                                    .map((opt) => opt.entityId)
                                    .reduce(function (a, b) {
                                        if (a.indexOf(b) < 0)
                                            a.push(b);
                                        return a;
                                    }, []);
                                this.formSubmitForMapLocation(locations, entities, entityWithLocations);
                            }}
                            activeComplianceData={this.state.activeComplianceData}
                            alreadySelectedParentLocations={
                                this.state.allocationData.compliances?.find(
                                    (opt) =>
                                        opt.complianceId ==
                                        this.state.activeComplianceData?.triggeringComplianceId
                                )?.locations || []
                            }

                        />
                    )}
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    eventComplianceListApiResponse: makeEventSelectField('eventComplianceListApiResponse')
});

const withConnect = connect(mapStatetoProps, {
    getLawComplianceListByEventIdRequest
});

export default compose(
    withConnect,
    withRouter
)(EventComplianceList);
