import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    GET_PROJECT_TASK_USERS_BY_ENTITY_REQUEST,
    ADD_PROJECT_REQUEST,
    GET_PROJECT_LIST_REQUEST,
    GET_PROJECT_DETAILS_REQUEST,
    UPDATE_PROJECT_REQUEST,
    DELETE_PROJECT_BY_PROJECT_ID_REQUEST,
    GET_PROJECT_TASK_LIST_REQUEST,
    ADD_PROJECT_TASK_REQUEST,
    UPDATE_PROJECT_TASK_REQUEST,
    DELETE_PROJECT_TASK_BY_TASK_ID_REQUEST,
    ADD_PROJECT_SUB_TASK_REQUEST,
    DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_REQUEST,
    GET_PROJECT_TASK_DETAILS_REQUEST,
    ADD_PROJECT_TASK_COMMENT_REQUEST,
    ADD_PROJECT_TASK_ATTACHMENT_REQUEST,
    GET_PROJECT_TASK_ACTIVITY_REQUEST,
    DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST,
    CLOSE_PROJECT_REQUEST,
    ACTIVE_OR_DEACTIVE_PROJECT_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import {
    PROJECT_TASK_USERS_BY_ENTITY,
    PROJECT,
    PROJECT_LIST,
    PROJECT_DETAILS,
    PROJECT_TASK_LIST,
    PROJECT_TASK,
    PROJECT_SUB_TASK,
    PROJECT_TASK_DETAILS,
    PROJECT_TASK_COMMENT,
    PROJECT_TASK_ATTACHMENT,
    PROJECT_TASK_ACTIVITY,
    PROJECT_TASK_DOCUMENT,
    CLOSE_PROJECT,
    ACTIVE_OR_DEACTIVE_PROJECT
} from 'helpers/apiConstants';
import {
    getProjectTaskUsersByEntityResponse,
    addProjectResponse,
    getProjectListResponse,
    getProjectDetailsResponse,
    updateProjectResponse,
    deleteProjectByProjectIdResponse,
    getProjectListRequest,
    getProjectTaskListResponse,
    addProjectTaskResponse,
    updateProjectTaskResponse,
    deleteProjectTaskByTaskidResponse,
    addProjectSubTaskResponse,
    deleteProjectSubTaskBySubTaskIdResponse,
    getProjectTaskDetailsResponse,
    addProjectTaskCommentResponse,
    addProjectTaskAttachmentResponse,
    getProjectTaskActivityResponse,
    deleteProjectTaskDocumentByDocumentIdResponse,
    closeProjectResponse,
    activeOrDeactiveProjectResponse,
} from './actions';
import { DANGER, SUCCESS } from 'constants/commonConstants';

function* projectTaskUsersByEntityList({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            PROJECT_TASK_USERS_BY_ENTITY,
            data
        );
        if (response.status) {
            yield put(getProjectTaskUsersByEntityResponse(response.data.list));
        } else {
            yield put(getProjectTaskUsersByEntityResponse({}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* addProject({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', PROJECT, data);
        if (response.status) {
            yield put(getProjectListRequest());
            yield put(addProjectResponse(response.message, SUCCESS));
        } else {
            yield put(addProjectResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getProjectList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', PROJECT_LIST, data);

        if (response.status) {
            yield put(getProjectListResponse(response.data.list));
        } else {
            yield put(getProjectListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getProjectDetails({ payload: { projectDetailsId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            PROJECT_DETAILS + projectDetailsId
        );

        if (response.status) {
            yield put(
                getProjectDetailsResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getProjectDetailsResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateProject({ payload: { projectId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            PROJECT + '/' + projectId,
            data
        );

        if (response.status) {
            yield put(getProjectListRequest());
            yield put(updateProjectResponse(response.message, SUCCESS));
        } else {
            yield put(updateProjectResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteProjectByProjectId({ payload: { projectId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            PROJECT + '/' + projectId
        );

        if (response.status) {
            yield put(getProjectListRequest());
            yield put(
                deleteProjectByProjectIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                deleteProjectByProjectIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getProjectTaskList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', PROJECT_TASK_LIST, data);

        if (response.status) {
            yield put(
                getProjectTaskListResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getProjectTaskListResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addProjectTask({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', PROJECT_TASK, data);

        if (response.status) {
            yield put(addProjectTaskResponse(response.message, SUCCESS));
        } else {
            yield put(addProjectTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateProjectTask({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            PROJECT_TASK + '/' + taskId,
            data
        );
        if (response.status) {
            yield put(updateProjectTaskResponse(response.message, SUCCESS));
        } else {
            yield put(updateProjectTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteProjectTaskByTaskId({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            PROJECT_TASK + '/' + taskId
        );

        if (response.status) {
            yield put(
                deleteProjectTaskByTaskidResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                deleteProjectTaskByTaskidResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addProjectSubTask({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            PROJECT_SUB_TASK + '/' + taskId,
            data
        );

        if (response.status) {
            yield put(addProjectSubTaskResponse(response.message, SUCCESS));
        } else {
            yield put(addProjectSubTaskResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteProjectSubTaskBySubTaskId({ payload: { subTaskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            PROJECT_TASK + '/' + subTaskId
        );

        if (response.status) {
            yield put(
                deleteProjectSubTaskBySubTaskIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteProjectSubTaskBySubTaskIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getProjectTaskDetaiksList({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            PROJECT_TASK_DETAILS + taskId
        );

        if (response.status) {
            yield put(getProjectTaskDetailsResponse(response.data.list));
        } else {
            yield put(getProjectTaskDetailsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* addProjectTaskComment({ payload: { taskId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            PROJECT_TASK_COMMENT + taskId,
            data
        );

        if (response.status) {
            yield put(addProjectTaskCommentResponse(response.message, SUCCESS));
        } else {
            yield put(addProjectTaskCommentResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* addProjectTaskAttachment({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            PROJECT_TASK_ATTACHMENT,
            data
        );

        if (response.status) {
            yield put(
                addProjectTaskAttachmentResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                addProjectTaskAttachmentResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getProjectTaskActivity({ payload: { taskId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            PROJECT_TASK_ACTIVITY + taskId
        );

        if (response.status) {
            yield put(
                getProjectTaskActivityResponse(
                    response.data.projectTaskActivites
                )
            );
        } else {
            yield put(getProjectTaskActivityResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteProjectTaskDocumentByDocumentId({ payload: { documentId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            PROJECT_TASK_DOCUMENT + '/' + documentId
        );

        if (response.status) {
            yield put(
                deleteProjectTaskDocumentByDocumentIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteProjectTaskDocumentByDocumentIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* closeProject({ payload: { id } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            CLOSE_PROJECT + '/' + id
        );

        if (response.status) {
            yield put(
                closeProjectResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                closeProjectResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* activeOrDeactiveProject({ payload: { id, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            ACTIVE_OR_DEACTIVE_PROJECT + '/' + id,
            data
        );

        if (response.status) {
            yield put(getProjectListRequest());
            yield put(
                activeOrDeactiveProjectResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                activeOrDeactiveProjectResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchProjects() {
    yield takeEvery(
        GET_PROJECT_TASK_USERS_BY_ENTITY_REQUEST,
        projectTaskUsersByEntityList
    );
    yield takeEvery(ADD_PROJECT_REQUEST, addProject);
    yield takeEvery(GET_PROJECT_LIST_REQUEST, getProjectList);
    yield takeEvery(GET_PROJECT_DETAILS_REQUEST, getProjectDetails);
    yield takeEvery(UPDATE_PROJECT_REQUEST, updateProject);
    yield takeEvery(
        DELETE_PROJECT_BY_PROJECT_ID_REQUEST,
        deleteProjectByProjectId
    );
    yield takeEvery(GET_PROJECT_TASK_LIST_REQUEST, getProjectTaskList);
    yield takeEvery(ADD_PROJECT_TASK_REQUEST, addProjectTask);
    yield takeEvery(UPDATE_PROJECT_TASK_REQUEST, updateProjectTask);
    yield takeEvery(
        DELETE_PROJECT_TASK_BY_TASK_ID_REQUEST,
        deleteProjectTaskByTaskId
    );
    yield takeEvery(ADD_PROJECT_SUB_TASK_REQUEST, addProjectSubTask);
    yield takeEvery(
        DELETE_PROJECT_SUB_TASK_BY_SUB_TASK_ID_REQUEST,
        deleteProjectSubTaskBySubTaskId
    );
    yield takeEvery(
        GET_PROJECT_TASK_DETAILS_REQUEST,
        getProjectTaskDetaiksList
    );
    yield takeEvery(ADD_PROJECT_TASK_COMMENT_REQUEST, addProjectTaskComment);
    yield takeEvery(
        ADD_PROJECT_TASK_ATTACHMENT_REQUEST,
        addProjectTaskAttachment
    );
    yield takeEvery(GET_PROJECT_TASK_ACTIVITY_REQUEST, getProjectTaskActivity);
    yield takeEvery(
        DELETE_PROJECT_TASK_DOCUMENT_BY_DOCUMENT_ID_REQUEST,
        deleteProjectTaskDocumentByDocumentId
    );
    yield takeEvery(
        CLOSE_PROJECT_REQUEST,
        closeProject
    );
    yield takeEvery(
        ACTIVE_OR_DEACTIVE_PROJECT_REQUEST,
        activeOrDeactiveProject
    );
}
function* ProjectsSaga() {
    yield all([fork(watchProjects)]);
}
export default ProjectsSaga;
