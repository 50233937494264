export const ADD_USER_REQUEST = 'add_user_request';
export const ADD_USER_RESPONSE = 'add_user_success';
export const GET_ALL_USER_LIST_REQUEST = 'get_all_user_list_request';
export const GET_ALL_USER_LIST_RESPONSE = 'get_all_user_list_response';
export const GET_USER_BY_USER_ID_REQUEST = 'get_user_by_user_id_request';
export const GET_USER_BY_USER_ID_RESPONSE = 'get_user_by_user_id_response';
export const UPDATE_USER_REQUEST = 'update_user_request';
export const UPDATE_USER_RESPONSE = 'update_user_success';
export const UPDATE_USER_STATUS_BY_USER_ID_REQUEST =
    'update_user_status_by_user_id_request';
export const UPDATE_USER_STATUS_BY_USER_ID_RESPONSE =
    'update_user_status_by_user_id_response';
export const GET_USER_LIST_BY_STATUS_REQUEST =
    'get_user_list_by_status_request';
export const GET_USER_LIST_BY_STATUS_RESPONSE =
    'get_user_list_by_status_response';
export const DELETE_USER_BY_USER_ID_REQUEST = 'delete_user_by_user_id_request';
export const DELETE_USER_BY_USER_ID_RESPONSE =
    'delete_user_by_user_id_response';
export const GET_USER_LIST_BY_ENTITY_ID_REQUEST =
    'get_user_list_by_company_id_request';
export const GET_USER_LIST_BY_ENTITY_ID_RESPONSE =
    'get_user_list_by_company_id_response';
export const GET_USER_PROFILE_REQUEST = 'get_user_profile_request';
export const GET_USER_PROFILE_RESPONSE = 'get_user_profile_response';
export const UPDATE_USER_PROFILE_REQUEST = 'update_user_profile_request';
export const UPDATE_USER_PROFILE_RESPONSE = 'update_user_profile_response';
export const PASSWORD_RESEND_USER_BY_USER_ID_REQUEST =
    'password_resend_user_by_user_id_request';
export const PASSWORD_RESEND_USER_BY_USER_ID_RESPONSE =
    'password_resend_user_by_user_id_response';
export const SET_NEW_PASSWORD_OF_LOGIN_USER_REQUEST =
    'set_new_password_of_login_user_id_request';
export const SET_NEW_PASSWORD_OF_LOGIN_USER_RESPONSE =
    'set_new_password_of_login_user_id_response';
export const GET_USERS_BY_ENTITY_REQUEST = 'get_users_by_entity_request';
export const GET_USERS_BY_ENTITY_RESPONSE = 'get_users_by_entity_response';
export const GET_USERS_BY_COMPANYGROUP_REQUEST =
    'get_users_by_companygroup_request';
export const GET_USERS_BY_COMPANYGROUP_RESPONSE =
    'get_users_by_companygroup_response';

export const FILTER_USER_REQUEST = 'filter_user_request';
export const FILTER_USER_RESPONSE = 'filter_user_response';
export const UPDATE_APPLICATION_THEME_REQUEST = 'update_application_theme_request';
export const UPDATE_APPLICATION_THEME_RESPONSE = 'update_application_theme_response';
export const CLEARE_FILTER_USER_RESPONSE = 'cleare_filter_user_response';
export const UNBLOCK_EMAIL_REQUEST = 'unblock_email_request';
export const UNBLOCK_EMAIL_RESPONSE = 'unblock_email_response';

export const GET_USER_ROLES_BY_USER_ID_REQUEST = 'get_user_roles_by_user_id_request';
export const GET_USER_ROLES_BY_USER_ID_RESPONSE = 'get_user_roles_by_user_id_response';