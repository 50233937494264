import { ACTIVE } from "constants/commonConstants";
import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        label: 'Country Name',
        field: 'countryName',
    },
    {
        label: 'ISO Code',
        field: 'isoCode',
    },
    {
        label: 'Numeric Code',
        field: 'numericCode',
    },
    {
        label: 'Dialing Code',
        field: 'dialingCode',
    },
    {
        label: 'Currency Code',
        field: 'currency',
    }
];
export const initialCountryDataState = () => {
    return {
        countryName: '',
        numericCode: '',
        isoCode: '',
        dialingCode: '',
        isActive: ACTIVE
    }
}
export const intFields = ['numericCode', 'dialingCode'];
export const nullableFields = [];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};