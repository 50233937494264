import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import { compose } from 'redux';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import { TableBody, TableHead } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import __t from 'i18n/translator';
import { Field, Form, Formik } from 'formik';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getAllupdateTypeListRequest,
} from 'store/actions';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import { ACTIVE, DATE_FORMAT_DATEPICKER } from 'constants/commonConstants';
import {
    formatDateForApi,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';

import ApexCharts from 'react-apexcharts';
import LegalUpdateList from './GCM/LegalUpdateList';

import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import { selectSortedUpdateTypeList } from 'store/UpdateCenter/LegalUpdates/selector';
import {
    COMPLIANCE_CATEGORY_DUE_DATE,
    COMPLIANCE_CATEGORY_EVENT_BASED,
    COMPLIANCE_CATEGORY_ONE_TIME,
    COMPLIANCE_CATEGORY_ON_GOING,
} from 'constants/databaseConstants';
import { isDarkMode } from 'helpers/generalUtils';

const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLegalUpdatesSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });

const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});

const update_type_labels = [1, 2, 3, 4, 5, 6];
const sub_update_type_labels = [15, 16, 17, 18];
const initialDashboardSearchDataState = () => {
    return {
        countryId: '',
        stateId: '',
        cityId: '',
        date: '',
        industryId: '',
        subIndustryId: '',
    };
};
const calculateArraySum = (array) => {
    return array.reduce((a, b) => a + b, 0);
};
const placeholderStyle = (base) => ({
    ...base,
    fontSize:
        '1em',
    fontWeight: 500,
});

class GCMIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entityCount: 0,
            countryCount: 0,
            lawComplianceCount: 0,
            departmentCount: 0,
            overallSeries: [0, 0, 0, 0],
            recentActivityList: [],
            lawCategoriesComplianceClass: [],
            statusWiseTasks: [],
            dashboardSearchData: initialDashboardSearchDataState(),
            updateAgingAnalysisCount: {},
            eventBasedCount: [],
            overAllStatusCount: [],
            complianceCategoryCount: [],
            updateTypeCount: [],
            subUpdateTypeCount: [],
            clickableData: [],
            isOpenLegalUpdateList: false,
            clickableType: '',
            pieOptions: {
                chart: {
                    type: 'donut',
                    fontFamily: 'GT Walsheim Pro',
                    height: '100%',
                    width: '100%',
                    events: {
                        dataPointSelection:
                            this.handleDataPointSelectionForUpdateType,
                    },
                },
                labels: [
                    'Modification',
                    'Restrospective Change',
                    'Information Hub',
                    'High Impact Update',
                    'De-activation',
                    'New Additions',
                ],
                stroke:{
                    width: 0,
                },
                fill: {
                    colors:isDarkMode() ?
                    [
                        '#8568a5',
                        '#00A7B5',
                        '#9BD732',
                        '#C24200',
                        '#DE002E',
                        '#171E31',
                    ]: [
                        '#4F2D7F',
                        '#00A7B5',
                        '#9BD732',
                        '#C24200',
                        '#DE002E',
                        '#171E31',
                    ],
                },
                colors: isDarkMode() ?
                [
                    '#8568a5',
                    '#00A7B5',
                    '#9BD732',
                    '#C24200',
                    '#DE002E',
                    '#171E31',
                ]:[
                    '#4F2D7F',
                    '#00A7B5',
                    '#9BD732',
                    '#C24200',
                    '#DE002E',
                    '#171E31',
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    labels: {
                        colors: isDarkMode() ? ['#ced4da']:['#000']
                    },
                    fontSize: '14px',
                    fontWeight: 400,
                    itemMargin: {
                        horizontal: 10,
                        vertical: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    textAnchor: 'middle',
                    style: {
                        fontSize: '10px',
                        fontWeight: '100',
                    },
                },
                plotOptions: {
                    pie: {
                        // customScale: 1.1,
                        size: 200,
                        donut: {
                            size: '60%',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    fontWeight: 600,
                                    offsetY: -10,
                                    formatter: function (val) {
                                        return val;
                                    },
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    offsetY: 16,
                                    formatter: function (val) {
                                        return val;
                                    },
                                    color: isDarkMode() ? '#ced4da':'#000',
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Total',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    color: isDarkMode() ? '#ced4da' : '#4F2D7F',
                                    formatter: function (w) {
                                        return calculateArraySum(w.globals.seriesTotals);
                                    },
                                },
                            },
                        },
                    },
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    fillSeriesColor: true,
                    style: {
                        fontSize: '12px',
                    },

                    onDatasetHover: {
                        highlightDataSeries: true,
                    },
                },
            },
            pieOptions1: {
                chart: {
                    type: 'donut',
                    fontFamily: 'GT Walsheim Pro',
                    height: '100%',
                    width: '100%',
                    events: {
                        dataPointSelection:
                            this.handleDataPointSelectionForSubUpdateType,
                    },
                },
                labels: [
                    'New Laws',
                    'New Compliances',
                    'New Events',
                    'New Documents',
                ],
                stroke:{
                    width: 0,
                },
                fill: {
                    colors:isDarkMode() ?
                    [
                        '#8568a5',
                        '#00A7B5',
                        '#9BD732',
                        '#C24200',
                        '#DE002E',
                        '#171E31',
                    ]: [
                        '#4F2D7F',
                        '#00A7B5',
                        '#9BD732',
                        '#C24200',
                        '#DE002E',
                        '#171E31',
                    ],
                },
                colors: isDarkMode() ?
                [
                    '#8568a5',
                    '#00A7B5',
                    '#9BD732',
                    '#C24200',
                    '#DE002E',
                    '#171E31',
                ]:[
                    '#4F2D7F',
                    '#00A7B5',
                    '#9BD732',
                    '#C24200',
                    '#DE002E',
                    '#171E31',
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                ],
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    labels: {
                        colors: isDarkMode() ? ['#ced4da']:['#000']
                    },
                    fontSize: '14px',
                    fontWeight: 400,
                    itemMargin: {
                        horizontal: 10,
                        vertical: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    textAnchor: 'middle',
                    style: {
                        fontSize: '10px',
                        fontWeight: '100',
                    },
                },
                plotOptions: {
                    pie: {
                        // customScale: 1.1,
                        size: 200,
                        donut: {
                            size: '60%',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    fontWeight: 600,
                                    offsetY: -10,
                                    formatter: function (val) {
                                        return val;
                                    },
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    offsetY: 16,
                                    formatter: function (val) {
                                        return val;
                                    },
                                    color: isDarkMode() ? '#ced4da':'#000',
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Total',                                    
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    color: isDarkMode() ? '#ced4da' : '#4F2D7F',
                                    formatter: function (w) {
                                        return calculateArraySum(w.globals.seriesTotals);
                                    },
                                },
                            },
                        },
                    },
                },
                tooltip: {
                    enabled: true,
                    shared: true,
                    fillSeriesColor: true,
                    style: {
                        fontSize: '12px',
                    },

                    onDatasetHover: {
                        highlightDataSeries: true,
                    },
                },
            },
        };
        this.handleDataPointSelectionForUpdateType =
            this.handleDataPointSelectionForUpdateType.bind(this);
        this.handleDataPointSelectionForSubUpdateType =
            this.handleDataPointSelectionForSubUpdateType.bind(this);
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getAllupdateTypeListRequest();
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? [countryIds] : []);
        this.props.getCityListByStateRequest([]);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? [stateIds] : []);
    }
    dashboardFilter = (values) => {
        this.props.dashboardFilter(values);
    };
    handleDataPointSelectionForUpdateType = (event, chartContext, config) => {
        const clickedIndex = config.dataPointIndex;
        const updateTypeId = update_type_labels[clickedIndex];
        this.getDataByType('update_type', updateTypeId);
    };
    handleDataPointSelectionForSubUpdateType = (
        event,
        chartContext,
        config
    ) => {
        const clickedIndex = config.dataPointIndex;
        const subUpdateTypeId = sub_update_type_labels[clickedIndex];
        this.getDataByType('sub_update_type', subUpdateTypeId);
    };
    getDataByType = (type, clickableType, lawCategoryId = '') => {
        this.props.getDataByClickableType({
            clickableType: type,
            clickableTypeData: {
                gcmType: clickableType,
                lawCategoryId: lawCategoryId,
            },
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.entityRoleDashboardRes &&
            this.props.entityRoleDashboardRes !==
            nextprops.entityRoleDashboardRes
        ) {
            this.setState(
                {
                    complianceCategoryCount:
                        nextprops.entityRoleDashboardRes
                            ?.complianceCategoryCounts,
                    updateAgingAnalysisCount:
                        nextprops.entityRoleDashboardRes?.updateAgingAnalysis,

                    overAllStatusCount:
                        nextprops.entityRoleDashboardRes?.overAllStatusCount,

                    lawCategoriesComplianceClass:
                        nextprops.entityRoleDashboardRes
                            .lawCategoriesComplianceClass,
                    updateTypeCount:
                        nextprops.entityRoleDashboardRes.updateTypeData?.map(
                            (x) => x.counts
                        ),
                    subUpdateTypeCount:
                        nextprops.entityRoleDashboardRes.newAdditionSubUpdateTypeCount?.map(
                            (x) => x.counts
                        ),
                    isOpenLegalUpdateList:
                        nextprops.entityRoleDashboardRes.requestBody
                            ?.clickableType &&
                            nextprops.entityRoleDashboardRes.requestBody
                                ?.clickableType != ''
                            ? true
                            : false,
                },
                () => {
                    this.setState({
                        clickableType:
                            nextprops.entityRoleDashboardRes.requestBody
                                ?.clickableType,
                        clickableData:
                            nextprops.entityRoleDashboardRes?.clickableData,
                    });
                }
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                {!this.state.isOpenLegalUpdateList ? (
                    <React.Fragment>
                        <Row key={0} className="mb-3">
                            <Col>
                                <div className="dashboard-filter">
                                    <Formik
                                        initialValues={
                                            this.state.dashboardSearchData
                                        }
                                        enableReinitialize={true}
                                        onSubmit={this.formSubmit}
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form>
                                                <Row className="d-flex flex-wrap mx-0">
                                                    <FormGroup className="col">
                                                        <Field
                                                            component={Select}
                                                            name="countryId"
                                                            placeholder={
                                                                'Country'
                                                            }
                                                            styles={{
                                                                placeholder: placeholderStyle,
                                                            }}
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedCountryListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let selectedValue =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'countryId',
                                                                    selectedValue
                                                                );
                                                                setFieldValue(
                                                                    'stateId',
                                                                    ''
                                                                );
                                                                setFieldValue(
                                                                    'cityId',
                                                                    ''
                                                                );
                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,
                                                                        countryId:
                                                                            selectedValue,
                                                                    }
                                                                );
                                                                if (
                                                                    selectedValue
                                                                ) {
                                                                    this.getStateListByCountry(
                                                                        opt.value
                                                                    );
                                                                }
                                                            }}
                                                            value={this.props.sortedCountryListDropDown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.countryId
                                                            )}
                                                            closeMenuOnSelect={
                                                                true
                                                            }
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col">
                                                        <Field
                                                            component={Select}
                                                            name="stateId"
                                                            placeholder={
                                                                'State'
                                                            }
                                                            styles={{
                                                                placeholder: placeholderStyle,
                                                            }}

                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedStateListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let selectedValue =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';

                                                                setFieldValue(
                                                                    'stateId',
                                                                    selectedValue
                                                                );
                                                                if (
                                                                    selectedValue
                                                                ) {
                                                                    this.getCityListByState(
                                                                        opt.value
                                                                    );
                                                                }
                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,
                                                                        stateId:
                                                                            selectedValue,
                                                                    }
                                                                );
                                                            }}
                                                            value={this.props.sortedStateListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.stateId
                                                            )}
                                                            closeMenuOnSelect={
                                                                true
                                                            }
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col">
                                                        <Field
                                                            component={Select}
                                                            name="cityId"
                                                            placeholder={'City'}
                                                            styles={{
                                                                placeholder: placeholderStyle,
                                                            }}
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedCityListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let selectedValue =
                                                                    opt?.value
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'cityId',
                                                                    selectedValue
                                                                );
                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,
                                                                        cityId: selectedValue,
                                                                    }
                                                                );
                                                            }}
                                                            value={this.props.sortedCityListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.cityId
                                                            )}
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col">
                                                        <Field
                                                            name="date"
                                                            component={
                                                                DatePicker
                                                            }
                                                            isClearable={true}
                                                            className="form-control"
                                                            placeholderText={__t(
                                                                'form_labels.dashboard.date'
                                                            )}
                                                            selected={
                                                                values.date
                                                                    ? getDateObjectWithValueFromMoment(
                                                                        values.date
                                                                    )
                                                                    : null
                                                            }
                                                            dateFormat={
                                                                DATE_FORMAT_DATEPICKER
                                                            }
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                setFieldValue(
                                                                    `date`,
                                                                    date
                                                                );

                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,
                                                                        date: formatDateForApi(
                                                                            date
                                                                        ),
                                                                    }
                                                                );
                                                            }}
                                                            showMonthDropdown={
                                                                true
                                                            }
                                                            showYearDropdown={
                                                                true
                                                            }
                                                            dropdownMode="select"
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col">
                                                        <Field
                                                            component={Select}
                                                            name="industryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedIndustryListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let industry =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'industryId',
                                                                    industry
                                                                );
                                                                setFieldValue(
                                                                    'subIndustryId',
                                                                    ''
                                                                );
                                                                if (
                                                                    industry !=
                                                                    ''
                                                                ) {
                                                                    this.props.getSubIndustryListByIndustryRequest(
                                                                        [
                                                                            industry,
                                                                        ]
                                                                    );
                                                                    this.dashboardFilter(
                                                                        {
                                                                            ...values,
                                                                            industryId:
                                                                                industry,
                                                                            subIndustryId:
                                                                                '',
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            value={this.props.sortedIndustryListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.industryId
                                                            )}
                                                            placeholder={
                                                                'Industry'
                                                            }
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col">
                                                        <Field
                                                            component={Select}
                                                            name="subIndustryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedSubIndustryListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let subIndustry =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'subIndustryId',
                                                                    subIndustry
                                                                );
                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,

                                                                        subIndustryId:
                                                                            subIndustry,
                                                                    }
                                                                );
                                                            }}
                                                            placeholder={
                                                                'SubIndustry'
                                                            }
                                                            value={this.props.sortedSubIndustryListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.subIndustryId
                                                            )}
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        </Row>
                        <Row key={1} className='know-your-compliances'>
                            <Col lg="6" className="mb-3">
                                <Card className="short_list dashboardcard_head">
                                    <CardBody>
                                        <h5 className="m-0">Overall Status</h5>
                                        <ul>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'overall',
                                                            'todo'
                                                        )
                                                    }
                                                >
                                                    To Do{' '}
                                                    <div className="overall_stat">

                                                        <span>
                                                            {
                                                                this.state
                                                                    .overAllStatusCount
                                                                    ?.toDoCount
                                                            }
                                                        </span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'overall',
                                                            'pendingWithSuperAdmin'
                                                        )
                                                    }
                                                >
                                                    Pending With Super Admin{' '}
                                                    <div className="overall_stat">

                                                        <span>
                                                            {
                                                                this.state
                                                                    .overAllStatusCount
                                                                    ?.pendingWithSuperAdminCount
                                                            }
                                                        </span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'overall',
                                                            'confirmedBySuperAdmin'
                                                        )
                                                    }
                                                >
                                                    Confirmed By Super Admin{' '}
                                                    <div className="overall_stat">

                                                        <span>
                                                            {
                                                                this.state
                                                                    .overAllStatusCount
                                                                    ?.confirmedBySuperAdminCount
                                                            }
                                                        </span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'overall',
                                                            'overdue'
                                                        )
                                                    }
                                                >
                                                    Overdue{' '}
                                                    <div className="overall_stat">

                                                        <span>
                                                            {
                                                                this.state
                                                                    .overAllStatusCount
                                                                    ?.overDueCount
                                                            }
                                                        </span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'overall',
                                                            'executed'
                                                        )
                                                    }
                                                >
                                                    Executed{' '}
                                                    <div className="overall_stat">

                                                        <span>
                                                            {
                                                                this.state
                                                                    .overAllStatusCount
                                                                    ?.executedCount
                                                            }
                                                        </span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'overall',
                                                            'rejected'
                                                        )
                                                    }
                                                >
                                                    Rejected{' '}
                                                    <div className="overall_stat">

                                                        <span>
                                                            {
                                                                this.state
                                                                    .overAllStatusCount
                                                                    ?.rejectedCount
                                                            }
                                                        </span>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" className="mb-3">
                                <Card className="short_list dashboardcard_head gcm_dashboard">
                                    <CardBody>
                                        <h5 className="m-0">
                                            Compliance Category
                                        </h5>
                                        <ul>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'category',
                                                            COMPLIANCE_CATEGORY_DUE_DATE
                                                        )
                                                    }
                                                >
                                                    Due Date{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .complianceCategoryCount
                                                                ?.dueDateCount
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'category',
                                                            COMPLIANCE_CATEGORY_EVENT_BASED
                                                        )
                                                    }
                                                >
                                                    Event Based{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .complianceCategoryCount
                                                                ?.eventBasedCount
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'category',
                                                            COMPLIANCE_CATEGORY_ONE_TIME
                                                        )
                                                    }
                                                >
                                                    One Time{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .complianceCategoryCount
                                                                ?.oneTimeCount
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'category',
                                                            COMPLIANCE_CATEGORY_ON_GOING
                                                        )
                                                    }
                                                >
                                                    On Going{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .complianceCategoryCount
                                                                ?.onGoingCount
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3" className="mb-3">
                                <Card className="short_list dashboardcard_head gcm_dashboard">
                                    <CardBody>
                                        <h5 className="m-0">Ageing Analysis</h5>
                                        <ul>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'ageing',
                                                            'last_0_to_7_days_count'
                                                        )
                                                    }
                                                >
                                                    Up to 7 days{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .updateAgingAnalysisCount
                                                                ?.last_0_to_7_days_count
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'ageing',
                                                            'last_7_to_15_days_count'
                                                        )
                                                    }
                                                >
                                                    7 to 15 days{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .updateAgingAnalysisCount
                                                                ?.last_7_to_15_days_count
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'ageing',
                                                            'last_15_to_30_days_count'
                                                        )
                                                    }
                                                >
                                                    15 to 30 days{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .updateAgingAnalysisCount
                                                                ?.last_15_to_30_days_count
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        this.getDataByType(
                                                            'ageing',
                                                            'last_30_to_45_days_count'
                                                        )
                                                    }
                                                >
                                                    30 to 45 days{' '}
                                                    <span>
                                                        {
                                                            this.state
                                                                .updateAgingAnalysisCount
                                                                ?.last_30_to_45_days_count
                                                        }
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row key={2} className='know-your-compliances'>
                            <Col lg="3">
                                <Card className="short_list dashboardcard_head update-type">
                                    <CardBody className="h-100">
                                        <h5 className="m-0">Update Type</h5>
                                        {this.state.updateTypeCount.length >
                                            0 && (
                                                <ApexCharts
                                                    className="mt-3 h-100"
                                                    options={this.state.pieOptions}
                                                    series={
                                                        this.state.updateTypeCount
                                                    }
                                                    type="donut"
                                                />
                                            )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card className="short_list dashboardcard_head update-type">
                                    <CardBody>
                                        <h5 className="m-0">New Additions</h5>
                                        {this.state.subUpdateTypeCount.length >
                                            0 && (
                                                <ApexCharts
                                                    className="mt-3 h-100"
                                                    options={this.state.pieOptions1}
                                                    series={
                                                        this.state
                                                            .subUpdateTypeCount
                                                    }
                                                    type="donut"
                                                />
                                            )}
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="6">
                                <Card className="chart-card gcm_dashboard catagory_card short_list dashboardcard_head animate__animated animate__fadeInUp m-0">
                                    <CardBody className="p-0">
                                        <h5>Analysis as Per Category of Law</h5>
                                        <Table className="m-0 number_laws">
                                            <TableHead>
                                                <tr>
                                                    <th className="category_name">
                                                        Category Of Law
                                                    </th>
                                                    <th>On-Going</th>
                                                    <th>One-Time</th>
                                                    <th>Due-Date</th>
                                                    <th>Event-Based</th>
                                                </tr>
                                            </TableHead>
                                            <TableBody>
                                                {this.state
                                                    .lawCategoriesComplianceClass
                                                    .length > 0 &&
                                                    this.state.lawCategoriesComplianceClass.map(
                                                        (
                                                            lawCategoryData,
                                                            keyIndex
                                                        ) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            lawCategoryData.lawCategoryName
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        onClick={() => {
                                                                            this.getDataByType(
                                                                                'law_category',
                                                                                COMPLIANCE_CATEGORY_ON_GOING,
                                                                                lawCategoryData.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span className="badge badge-primary">
                                                                            {
                                                                                lawCategoryData
                                                                                    .counts
                                                                                    .onGoingCount
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td
                                                                        onClick={() => {
                                                                            this.getDataByType(
                                                                                'law_category',
                                                                                COMPLIANCE_CATEGORY_ONE_TIME,
                                                                                lawCategoryData.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span className="badge badge-success">
                                                                            {
                                                                                lawCategoryData
                                                                                    .counts
                                                                                    .oneTimeCount
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td
                                                                        onClick={() => {
                                                                            this.getDataByType(
                                                                                'law_category',
                                                                                COMPLIANCE_CATEGORY_DUE_DATE,
                                                                                lawCategoryData.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span className="badge badge-orange">
                                                                            {
                                                                                lawCategoryData
                                                                                    .counts
                                                                                    .dueDateCount
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td
                                                                        onClick={() => {
                                                                            this.getDataByType(
                                                                                'law_category',
                                                                                COMPLIANCE_CATEGORY_EVENT_BASED,
                                                                                lawCategoryData.id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span className="badge badge-info">
                                                                            {
                                                                                lawCategoryData
                                                                                    .counts
                                                                                    .eventBasedCount
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <LegalUpdateList
                        updateTypeList={this.props.updateTypeList}
                        clickableType={this.state.clickableType}
                        clickedData={this.state.clickableData}
                        closeClickedData={() => {
                            this.setState(
                                {
                                    isOpenLegalUpdateList: false,
                                },
                                () => {
                                    this.props.getDataByClickableType({
                                        clickableType: '',
                                        clickableTypeData: {
                                            gcmType: '',
                                        },
                                    });
                                }
                            );
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    updateTypeList: selectSortedUpdateTypeList('updateTypeList'),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getAllupdateTypeListRequest,
});
export default compose(
    withConnect,
    withRouter,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLegalUpdatesReducer,
    withLegalUpdatesSaga
)(GCMIndex);
