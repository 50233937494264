import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_CITY_REQUEST, DELETE_CITY_BY_CITY_ID_REQUEST, GET_ALL_CITY_LIST_REQUEST, GET_CITY_BY_CITY_ID_REQUEST, GET_CITY_LIST_BY_STATE_REQUEST, GET_CITY_LIST_BY_STATUS_REQUEST, UPDATE_CITY_REQUEST, UPDATE_CITY_STATUS_BY_CITY_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addCityResponse, deleteCityByCityIdResponse, getAllCityListRequest, getAllCityListResponse, getCityByCityIdResponse, getCityListByStateResponse, getCityListByStatusResponse, updateCityResponse, updateCityStatusByCityIdResponse } from './actions';
import { CITY, CITY_BY_STATE, CITY_BY_STATUS } from 'helpers/apiConstants';
function* addCity({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', CITY, data);
        if (response.status) {
            yield put(getAllCityListRequest());
            yield put(addCityResponse(response.message, SUCCESS));
        } else {
            yield put(addCityResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllCityList() {
    try {
        const response = yield call(invokeApi, 'get', CITY, '');
        if (response.status) {
            yield put(getAllCityListResponse(response.data.list));
        } else {
            yield put(getAllCityListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getCityByCityId({ payload: { cityId } }) {
    try {
        const response = yield call(invokeApi, 'get', CITY + '/' + cityId, '');
        if (response.status) {
            yield put(getCityByCityIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getCityByCityIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateCity({ payload: { cityId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', CITY + '/' + cityId, data);
        if (response.status) {
            yield put(getAllCityListRequest());
            yield put(updateCityResponse(response.message, SUCCESS));
        } else {
            yield put(updateCityResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateCityStatusByCityId({
    payload: { cityId, status },
}) {
    try {
        const response = yield call(invokeApi, 'patch', CITY + '/' + cityId + '/status/' + status);
        if (response.status) {
            yield put(getAllCityListRequest());
            yield put(updateCityStatusByCityIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateCityStatusByCityIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getCityListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', CITY_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getCityListByStatusResponse(response.data.list));
        } else {
            yield put(getCityListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteCityByCityId({ payload: { cityId } }) {
    try {
        const response = yield call(invokeApi, 'delete', CITY + '/' + cityId);
        if (response.status) {
            yield put(getAllCityListRequest());
            yield put(deleteCityByCityIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteCityByCityIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getCityListByState({ payload: { stateIds } }) {
    try {
        const response = yield call(invokeApi, 'post', CITY_BY_STATE,
            { stateId: stateIds }
        );
        if (response.status) {
            yield put(getCityListByStateResponse(response.data.list));
        } else {
            yield put(getCityListByStateResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchCity() {
    yield takeEvery(ADD_CITY_REQUEST, addCity);
    yield takeEvery(GET_ALL_CITY_LIST_REQUEST, getAllCityList);
    yield takeEvery(GET_CITY_BY_CITY_ID_REQUEST, getCityByCityId);
    yield takeEvery(UPDATE_CITY_REQUEST, updateCity);
    yield takeEvery(UPDATE_CITY_STATUS_BY_CITY_ID_REQUEST, updateCityStatusByCityId);
    yield takeEvery(GET_CITY_LIST_BY_STATUS_REQUEST, getCityListByStatus);
    yield takeEvery(DELETE_CITY_BY_CITY_ID_REQUEST, deleteCityByCityId);
    yield takeEvery(GET_CITY_LIST_BY_STATE_REQUEST, getCityListByState);
}
function* citySaga() {
    yield all([fork(watchCity)]);
}
export default citySaga;