import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {  TASK_CREATOR } from 'constants/commonConstants';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import roleReducer from 'store/Masters/Role/reducer';
import roleSaga from 'store/Masters/Role/saga';
import {
    getRoleListRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { listColumns } from './CommonFunctions';
import __t from 'i18n/translator';
import { makeRoleSelectField } from 'store/Masters/Role/selector';
import { checkModulePermission } from 'helpers/projectUtils';
import { MODULE_ROLE_PERMISSION, PERMISSION_EDIT } from 'constants/databaseConstants';
const roleKey = 'role';
const withRoleReducer = injectReducer({
    key: roleKey,
    reducer: roleReducer,
});
const withRoleSaga = injectSaga({ key: roleKey, saga: roleSaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleList: []
        };
    }
    componentDidMount() {
        //Retrieve roles list
        this.props.getRoleListRequest();
    }
    editRolePermission = (record) => {
        this.props.history.push('/role-permission/manage/edit/' + record.id);
    }
    generateTable = (records) => {
        let roleRecords = [];
        roleRecords = records.map((roleData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_ROLE_PERMISSION, PERMISSION_EDIT) === true && (
                    <a
                        href={() => false}
                        title={__t('common.edit')}
                        className='btn btn-link text-success tbl-action'

                        onClick={() =>
                            this.editRolePermission(roleData)
                        }
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    )}
                </div>
            );
            return {
                roleName: roleData.roleName,
                actions: actions,
            };
        })
        return roleRecords;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.roleList && this.props.roleList !== nextprops.roleList) {
            this.setState({
                roleList: this.generateTable(nextprops.roleList.filter((opt) => opt.id < TASK_CREATOR))
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.roleList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card className='mb-2'>
                            <CardHeader>
                                <div className='page-header'>
                                    <h4>{__t('page_headers.role_list')}</h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <CustomisedDatatable
                                    entries={20}
                                    tableRecords={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    roleList: makeRoleSelectField('roleList')
});
const withConnect = connect(mapStatetoProps, {
    getRoleListRequest
});
export default compose(
    withConnect,
    withRoleReducer,
    withRoleSaga,
    withRouter
)(Index);