import produce from 'immer';
import {
    ADD_LAW_DEFINITION_RESPONSE,
    GET_ALL_LAW_DEFINITIONS_RESPONSE,
    GET_LAW_DEFINITION_VERSIONS_LIST_RESPONSE,
    GET_LAW_DEFINITIONS_BY_LAW_ID_RESPONSE,
    UPDATE_LAW_DEFINITION_RESPONSE,
} from './actionTypes';
export const initialState = {
    lawDefinitionApiResponse: null,
    allLawDefinitions: [],
    lawDefinitionsListResponse: {},
    updateLawDefinitionApiResponse: null,
    addLawDefinitionApiResponse: null,
    lawDefinitionVersionsList: [],
};
const definition = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case GET_ALL_LAW_DEFINITIONS_RESPONSE:
                draft.allLawDefinitions = action.payload.allLawDefinitions;
                break;
            case GET_LAW_DEFINITIONS_BY_LAW_ID_RESPONSE:
                draft.lawDefinitionsListResponse = {
                    lawDefinitionsList: action.payload.lawDefinitionsList,
                    legalUpdateList: action.payload.legalUpdateListForDefinition,
                }
                break;
            case UPDATE_LAW_DEFINITION_RESPONSE:
                draft.updateLawDefinitionApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case ADD_LAW_DEFINITION_RESPONSE:
                draft.addLawDefinitionApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_LAW_DEFINITION_VERSIONS_LIST_RESPONSE:
                draft.lawDefinitionVersionsList = action.payload.lawDefinitionVersionsList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default definition;
