import { setEmptyToNull } from "helpers/generalUtils";
export const listColumns = [
    {
        Header: 'Compliance Code',
        TransHeader: 'tables.allocation.compliance_code',
        accessor: 'complianceCode',
    },
    {
        Header: 'Country',
        TransHeader: 'tables.allocation.country',
        accessor: 'country',
    },
    {
        Header: 'State',
        TransHeader: 'tables.allocation.state',
        accessor: 'state',
    },
    {
        Header: 'City',
        TransHeader: 'tables.allocation.city',
        accessor: 'city',
    },
    {
        Header: 'Short Name of Act/Regulation',
        TransHeader: 'tables.allocation.law_short_name',
        accessor: 'legislationName',
    },
    {
        Header: 'Compliance Title',
        TransHeader: 'tables.allocation.compliance_title',
        accessor: 'complianceTitle',
    },
    {
        Header: 'compliance_classification',
        TransHeader: 'tables.allocation.compliance_classification',
        accessor: 'complianceClassification',
    },
    {
        Header: 'Entity',
        TransHeader: 'tables.allocation.entity',
        accessor: 'entity',
        width: 200,
        disableSortBy: true,
    }
];

export const listColumns1 = [
    {
        label: 'Compliance Code',
        TransHeader: 'tables.allocation.compliance_code',
        field: 'complianceCode',
    },
    {
        label: 'Country',
        TransHeader: 'tables.allocation.country',
        field: 'country',
    },
    {
        label: 'State/Province',
        TransHeader: 'tables.allocation.state',
        field: 'state',
    },
    {
        label: 'City/Location',
        TransHeader: 'tables.allocation.city',
        field: 'city',
    },
    {
        label: 'Short Name of Act/Regulation',
        TransHeader: 'tables.allocation.law_short_name',
        field: 'legislationName',
    },
    {
        label: 'Law Category',
        TransHeader: 'tables.allocation.law_category_name',
        field: 'lawCategoryName',
    },
    {
        label: 'Compliance Title',
        TransHeader: 'tables.allocation.compliance_title',
        field: 'complianceTitle',
    },
    {
        label: 'Classification',
        TransHeader: 'tables.allocation.compliance_classification',
        field: 'complianceClassification',
    },
    {
        label: 'Entity',
        TransHeader: 'tables.allocation.entity',
        field: 'entity',
        width: 200,
        disabled: true,
    }
];
export const initialAllocationDataState = () => {
    return {
        companyGroupId: '',
        countryId: [],
        stateId: [],
        cityId: [],
        industryId: '',
        subIndustryId: '',
        lawCategoryId: [],
        lawId: [],
        displayMode: ''
    }
}
export const intFields = ['companyGroupId'];
export const nullableFields = ['industry', 'subIndustry'];
export const formatValuesForApi = values => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};