import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { RESET, SUCCESS } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field, FieldArray } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import { customValidTextField } from 'helpers/customValidators';
import __t from 'i18n/translator';
import Select from 'react-select';
class AddDepartmentForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customValidTextField()),
        });
        this.state = {};
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign({}, this.props.departmentData)}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>

                            <Row>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        component={Select}
                                        name="companyGroupId"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedCompanyGroupListDropdown
                                        }
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'companyGroupId',
                                                opt.value
                                            );
                                        }}
                                        value={this.props.sortedCompanyGroupListDropdown.filter(
                                            (option) =>
                                                option.value ===
                                                values.companyGroupId
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.entity.company_group'
                                        )}{' '}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.entity.company_group'),
                                        values.companyGroupId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.entity.company_group_required'
                                                ),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <Col lg="12">
                                    <FieldArray
                                        name="departmentNames"
                                        render={(arrayHelpers) => (
                                            <div className="industry-sub-industry">
                                                {values.departmentNames
                                                    ?.length > 0 &&
                                                    values.departmentNames.map(
                                                        (item, idx) => {
                                                            return (
                                                                <Row key={idx}>
                                                                    <FormGroup className="col">
                                                                        <Field
                                                                            name={`departmentNames.${idx}`}
                                                                            className="form-control"
                                                                            placeholder={__t(
                                                                                'form_labels.masters.department_name'
                                                                            )}
                                                                            value={
                                                                                item
                                                                            }
                                                                            maxLength={
                                                                                200
                                                                            }
                                                                        />
                                                                        <Label className="form-label">
                                                                            {__t(
                                                                                'form_labels.masters.department_name'
                                                                            )}{' '}
                                                                            <span className="text-danger required">
                                                                                *
                                                                            </span>
                                                                        </Label>
                                                                        {this.validator.message(
                                                                            __t(
                                                                                'form_labels.masters.department_name'
                                                                            ),
                                                                            item,
                                                                            'required|min:3|max:200|alpha_space',
                                                                            {
                                                                                messages:
                                                                                {
                                                                                    required:
                                                                                        __t(
                                                                                            'validations.masters.department_name_required'
                                                                                        ),
                                                                                    min: __t(
                                                                                        'validations.masters.department_name_min',
                                                                                        {
                                                                                            min: 3,
                                                                                        }
                                                                                    ),
                                                                                    max: __t(
                                                                                        'validations.masters.department_name_max',
                                                                                        {
                                                                                            max: 200,
                                                                                        }
                                                                                    ),
                                                                                    alpha_space:
                                                                                        __t(
                                                                                            'validations.masters.department_name_alpha_space'
                                                                                        ),
                                                                                },
                                                                            }
                                                                        )}
                                                                    </FormGroup>
                                                                    <Col lg="2">
                                                                        {values
                                                                            .departmentNames
                                                                            .length >
                                                                            1 ? (
                                                                            <Button
                                                                                type="button"
                                                                                className="btn btn-danger mr-3 mt-1"
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(
                                                                                        idx
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-minus"></i>
                                                                            </Button>
                                                                        ) : null}
                                                                        {values
                                                                            .departmentNames
                                                                            .length ===
                                                                            idx +
                                                                            1 ? (
                                                                            <Button
                                                                                type="button"
                                                                                className="btn btn-primary mt-1"
                                                                                onClick={() => {
                                                                                    arrayHelpers.insert(
                                                                                        idx +
                                                                                        1,
                                                                                        ''
                                                                                    );
                                                                                    this.validator.purgeFields();
                                                                                    this.validator.hideMessages();
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-plus"></i>
                                                                            </Button>
                                                                        ) : null}
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >
                                                {this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {RESET}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
export default AddDepartmentForm;
