import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.task || initialState;
const makeTaskSelectField = field =>
    createSelector(reducerState, taskState => taskState[field]);
const selectSortedTaskStatusList = () =>
    createSelector(makeTaskSelectField('taskStatusList'), taskStatusList =>
        taskStatusList.map(opt => ({
            value: opt.id,
            label: opt.statusName,
        }))
    );

const selectSortedTaskList = () =>
    createSelector(makeTaskSelectField('subTaskList'), subTaskList =>
        subTaskList.map(opt => ({
            value: opt.id,
            label: opt.taskCode + ' - ' + opt.title,
        }))
    );
export {
    makeTaskSelectField,
    selectSortedTaskStatusList,
    selectSortedTaskList
};
