export const listColumns = [
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State/Province',
        field: 'stateName',
    },
    {
        label: 'City/Location',
        field: 'cityName',
    },
    {
        label: 'Event Name',
        field: 'eventName',
        sort: 'disabled'
    },
    {
        label: 'Event Applicability',
        field: 'eventApplicability',
    },
    {
        label: 'Action',
        field: 'actions',
        attributes: {
            className: 'action_col',
        },
        sort: 'disabled',
    }
];
export const initialAssignedFilterDataState = () => {
    return {
        eventId: [],
        entityId: [],
        countryId: [],
        stateId: [],
        cityId: [],
    }
}

export const initialPlanEventFilterDataState = () => {
    return {
        eventId: '',
        stateId: [],
        cityId: [],
        entityId: '',
        countryId: '',
        eventType: 'past',
        eventOccurrenceDate: '',
        compliances: []
    }
}
export const listComplianceColumns = [
    {
        Header: 'Compliance Code',
        TransHeader: 'tables.event.compliance_code',
        accessor: 'complianceCode',
        disableSortBy: true,
    },
    {
        Header: 'Title',
        TransHeader: 'tables.event.compliance_title',
        accessor: 'complianceTitle',
        disableSortBy: true,
    },
    {
        Header: 'Due Date',
        TransHeader: 'tables.event.due_date',
        accessor: 'dueDate',
        disableSortBy: true,
    },
    {
        Header: 'City',
        TransHeader: 'tables.event.city',
        accessor: 'cityName',
        disableSortBy: true,
    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        className: 'action_col',
        disableSortBy: true,
    }
];

export const initialUserAllocationFormDataState = () => {
    return {
        complianceAllocationId: [],
        entityId: '',
        entityLocationId: [],
        cityName: '',
        eventDueDateFormula: '',
        dueDate: '',
        departmentId: [],
        submitterId: '',
        firstApproverUserId: '',
        secondApproverUserId: ''
    }
}