import { GET_AUDIT_LIST_REQUEST, GET_AUDIT_LIST_RESPONSE } from "./actionTypes";

export const getAuditListRequest = data => {
    return {
        type: GET_AUDIT_LIST_REQUEST,
        payload: { data }
    };
};
export const getAuditListResponse = auditList => {
    return {
        type: GET_AUDIT_LIST_RESPONSE,
        payload: { auditList },
    };
};