import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_TENANT_REQUEST,
    DELETE_TENANT_BY_TENANT_ID_REQUEST,
    GET_ALL_TENANT_LIST_REQUEST,
    GET_TENANT_BY_TENANT_ID_REQUEST,
    GET_TENANT_LIST_BY_STATUS_REQUEST,
    UPDATE_TENANT_REQUEST,
    UPDATE_TENANT_STATUS_BY_TENANT_ID_REQUEST,
    GET_TENANT_LIST_REQUEST,
    GENERATE_DATABASE_BY_TENANT_ID_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addTenantResponse,
    deleteTenantByTenantIdResponse,
    generateDatabaseByTenantIdResponse,
    getAllTenantListRequest,
    getAllTenantListResponse,
    getTenantByTenantIdResponse,
    getTenantListByStatusResponse,
    getTenantListResponse,
    updateTenantResponse,
    updateTenantStatusByTenantIdResponse,
} from './actions';
import {
    TENANT,
    TENANTS,
    TENANT_BY_STATUS,
    TENANT_DB_GENERATE,
    TENANT_LIST,
    TENANT_STATUS_UPDATE,
} from 'helpers/apiConstants';
function* addTenant({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', TENANT, data);
        if (response.status) {
            yield put(getAllTenantListRequest());
            yield put(addTenantResponse(response.message, SUCCESS));
        } else {
            yield put(addTenantResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllTenantList() {
    try {
        const response = yield call(invokeApi, 'get', TENANTS, '');
        if (response.status) {
            yield put(getAllTenantListResponse(response.data.tenantList));
        } else {
            yield put(getAllTenantListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getTenantList() {
    try {
        const response = yield call(invokeApi, 'get', TENANT_LIST, '');
        if (response.status) {
            yield put(getTenantListResponse(response.data.tenantList));
        } else {
            yield put(getTenantListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getTenantByTenantId({ payload: { tenantId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            TENANT + '/' + tenantId
        );
        if (response.status) {
            yield put(
                getTenantByTenantIdResponse(
                    response.message,
                    SUCCESS,
                    response.data.data
                )
            );
        } else {
            yield put(
                getTenantByTenantIdResponse(response.message, DANGER, [])
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateTenant({ payload: { tenantId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            TENANT + '/' + tenantId,
            data
        );
        if (response.status) {
            yield put(updateTenantResponse(response.message, SUCCESS));
        } else {
            yield put(updateTenantResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateTenantStatusByTenantId({ payload: { tenantId, status } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            TENANT_STATUS_UPDATE + '/' + tenantId,
            { status: status }
        );
        if (response.status) {
            yield put(getAllTenantListRequest());
            yield put(
                updateTenantStatusByTenantIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                updateTenantStatusByTenantIdResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getTenantListByStatus({ payload: { status } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            TENANT_BY_STATUS + '/' + status
        );
        if (response.status) {
            yield put(getTenantListByStatusResponse(response.data.tenantList));
        } else {
            yield put(getTenantListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteTenantByTenantId({ payload: { tenantId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            TENANT + '/' + tenantId
        );
        if (response.status) {
            yield put(getAllTenantListRequest());
            yield put(
                deleteTenantByTenantIdResponse(response.message, SUCCESS)
            );
        } else {
            yield put(deleteTenantByTenantIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* generateDatabaseByTenantId({ payload: { tenantId } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            TENANT_DB_GENERATE + '/' + tenantId
        );
        if (response.status) {
            yield put(
                generateDatabaseByTenantIdResponse(
                    response.message,
                    SUCCESS,
                    response.data.data
                )
            );
        } else {
            yield put(
                generateDatabaseByTenantIdResponse(response.message, DANGER, [])
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchTenant() {
    yield takeEvery(ADD_TENANT_REQUEST, addTenant);
    yield takeEvery(GET_ALL_TENANT_LIST_REQUEST, getAllTenantList);
    yield takeEvery(GET_TENANT_LIST_REQUEST, getTenantList);
    yield takeEvery(GET_TENANT_BY_TENANT_ID_REQUEST, getTenantByTenantId);
    yield takeEvery(UPDATE_TENANT_REQUEST, updateTenant);
    yield takeEvery(
        UPDATE_TENANT_STATUS_BY_TENANT_ID_REQUEST,
        updateTenantStatusByTenantId
    );
    yield takeEvery(GET_TENANT_LIST_BY_STATUS_REQUEST, getTenantListByStatus);
    yield takeEvery(DELETE_TENANT_BY_TENANT_ID_REQUEST, deleteTenantByTenantId);
    yield takeEvery(GENERATE_DATABASE_BY_TENANT_ID_REQUEST, generateDatabaseByTenantId);
}
function* tenantSaga() {
    yield all([fork(watchTenant)]);
}
export default tenantSaga;
