import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, ENABLE_DELETE, SUBMIT, UPDATE } from 'constants/commonConstants';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllEntityRoleListRequest,
    updateEntityRoleStatusByEntityRoleIdRequest,
    addEntityRoleRequest,
    updateEntityRoleRequest,
    deleteEntityRoleByEntityRoleIdRequest,
    getEntityListByStatusRequest,
    getEntityRoleListRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import entityRoleReducer from 'store/Masters/EntityRole/reducer';
import entityRoleSaga from 'store/Masters/EntityRole/saga';
import roleReducer from 'store/Masters/Role/reducer';
import roleSaga from 'store/Masters/Role/saga';
import { makeEntityRoleSelectField } from 'store/Masters/EntityRole/selector';
import { initialEntityRoleDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox } from 'helpers/messageHelper';
import AddEntityRoleForm from './AddEntityRoleForm';
import { selectSortedEntityList } from 'store/Entity/selector';
import { selectSortedRoleList } from 'store/Masters/Role/selector';
import __t from 'i18n/translator';
import { ExportCSV } from 'components/Application/ExportCSV';
import AuditLogModal from 'components/Application/AuditLogModal';
import { MODULE_ENTITY_ROLE, PERMISSION_ADD, PERMISSION_DELETE, PERMISSION_EDIT } from 'constants/databaseConstants';
import { checkModulePermission } from 'helpers/projectUtils';
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const entityRoleKey = 'entityRole';
const withEntityRoleReducer = injectReducer({
    key: entityRoleKey,
    reducer: entityRoleReducer,
});
const withEntityRoleSaga = injectSaga({ key: entityRoleKey, saga: entityRoleSaga });
const roleKey = 'role';
const withRoleReducer = injectReducer({
    key: roleKey,
    reducer: roleReducer,
});
const withRoleSaga = injectSaga({ key: roleKey, saga: roleSaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportEntityRoleList: [],
            entityRoleList: [],
            entityRoleData: initialEntityRoleDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: ''
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve entityRole list
        this.props.getAllEntityRoleListRequest();
        this.props.getEntityListByStatusRequest(ACTIVE);
        this.props.getEntityRoleListRequest();
    }
    onStatusChange(checked, e, id) {
        this.props.updateEntityRoleStatusByEntityRoleIdRequest(id, checked);
    }
    viewEntityRole(entityRoleData) {
        this.setState({
            entityRoleData: initialEntityRoleDataState(),
            operationType: UPDATE
        }, () => {
            this.setState({ entityRoleData: entityRoleData });
        });
    };
    deleteEntityRole = async entityRoleId => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_role_delete')
        );
        if (response) {
            this.props.deleteEntityRoleByEntityRoleIdRequest(entityRoleId);
        }
    };
    formSubmit = values => {
        this.setState({ entityRoleData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateEntityRoleRequest(values.id, values);
        } else {
            this.props.addEntityRoleRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            entityRoleData: initialEntityRoleDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId
        })
    }
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: ''
        })
    }
    generateTable = (records) => {
        let entityRoleRecords = [];
        entityRoleRecords = records.map((entityRoleData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_ENTITY_ROLE, PERMISSION_EDIT) === true && (
                    <a
                        href={() => false}
                        title={__t('common.edit')}
                        className='btn btn-link text-success tbl-action'

                        onClick={() => {
                            this.viewEntityRole(entityRoleData)
                        }}
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    )}
                    {ENABLE_DELETE.includes('entityRole') && checkModulePermission(MODULE_ENTITY_ROLE, PERMISSION_DELETE) === true && (
                        <a
                            href={() => false}
                            title={__t('common.delete')}
                            className='btn btn-link text-danger tbl-action'
                            onClick={() => {
                                this.deleteEntityRole(entityRoleData.id)
                            }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </a>
                    )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className='btn btn-link text-primary tbl-action'
                        onClick={() => {
                            this.openAuditLogModal(entityRoleData.id)
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(entityRoleData.id)}
                        checked={entityRoleData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            return {
                roleTerm: entityRoleData.roleTerm,
                entityName: entityRoleData.entity?.entityName,
                roleName: entityRoleData.role?.roleName,
                isActive: isActive,
                actions: actions,
            };
        })
        return entityRoleRecords;
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.entityRoleList && this.props.entityRoleList !== nextprops.entityRoleList) {
            this.setState({
                exportEntityRoleList: nextprops.entityRoleList,
                entityRoleList: this.generateTable(nextprops.entityRoleList)
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.entityRoleList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className='page-header'>
                                        <h4>{__t('page_headers.entity_role_list')}</h4>
                                    </div>
                                    <div className='page-action'>
                                        <ExportCSV propsData={[this.state.exportEntityRoleList]} type={'entityRole'} />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <CustomisedDatatable
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {(checkModulePermission(MODULE_ENTITY_ROLE, PERMISSION_ADD) === true || checkModulePermission(MODULE_ENTITY_ROLE, PERMISSION_EDIT) === true) && (
                            <Col lg="5">
                                <Card>
                                    <CardHeader>
                                        <div className='page-header'>
                                            <h4>{__t('page_headers.entity_role_form')}</h4>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <AddEntityRoleForm
                                            operationType={this.state.operationType}
                                            entityRoleData={this.state.entityRoleData}
                                            sortedEntityListDropDown={this.props.sortedEntityListDropDown}
                                            sortedRoleListDropDown={this.props.sortedRoleListDropDown}
                                            operationResponse={
                                                this.props.entityRoleApiResponse
                                            }
                                            onFormValid={this.formSubmit}
                                            onFormReset={this.resetForm}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={"entityRole"}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    entityRoleList: makeEntityRoleSelectField('entityRoleList'),
    entityRoleApiResponse: makeEntityRoleSelectField('entityRoleApiResponse'),
    sortedEntityListDropDown: selectSortedEntityList(),
    sortedRoleListDropDown: selectSortedRoleList(),
});
const withConnect = connect(mapStatetoProps, {
    getAllEntityRoleListRequest,
    updateEntityRoleStatusByEntityRoleIdRequest,
    addEntityRoleRequest,
    updateEntityRoleRequest,
    deleteEntityRoleByEntityRoleIdRequest,
    getEntityListByStatusRequest,
    getEntityRoleListRequest
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withEntityRoleReducer,
    withEntityRoleSaga,
    withRoleReducer,
    withRoleSaga,
    withRouter
)(Index);