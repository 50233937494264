import {
    ADD_LAW_COMPLIANCE_REQUEST,
    ADD_LAW_COMPLIANCE_RESPONSE,
    SHOW_LAW_COMPLIANCE,
    GET_ALL_LAW_COMPLIANCES_REQUEST,
    GET_ALL_LAW_COMPLIANCES_RESPONSE,
    GET_LAW_COMPLIANCES_BY_LAW_ID_REQUEST,
    GET_LAW_COMPLIANCES_BY_LAW_ID_RESPONSE,
    GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_REQUEST,
    GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_RESPONSE,
    GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_REQUEST,
    GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_RESPONSE,
    GET_ALL_UPLOADED_FILES_RESPONSE,
    GET_ALL_UPLOADED_FILES_REQUEST,
    GET_LAW_COMPLIANCES_FOR_ALLOCATION_REQUEST,
    GET_LAW_COMPLIANCES_FOR_ALLOCATION_RESPONSE,
    UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_REQUEST,
    UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_RESPONSE,
    GET_LAW_COMPLIANCE_SEARCH_REQUEST,
    GET_LAW_COMPLIANCE_SEARCH_RESPONSE,
    GET_COMPLIANCE_TYPES_REQUEST,
    GET_COMPLIANCE_TYPES_RESPONSE,
    GET_COMPLIANCE_CATEGORIES_REQUEST,
    GET_COMPLIANCE_CATEGORIES_RESPONSE,
    GET_PERIODICITIES_REQUEST,
    GET_PERIODICITIES_RESPONSE,
    GET_PENALTY_TYPES_REQUEST,
    GET_PENALTY_TYPES_RESPONSE,
    GET_RISK_RATING_REQUEST,
    GET_RISK_RATING_RESPONSE,
    UPDATE_LAW_COMPLIANCE_REQUEST,
    UPDATE_LAW_COMPLIANCE_RESPONSE,
    GET_LAW_COMPLIANCE_LOW_ID_REQUEST,
    GET_LAW_COMPLIANCE_LOW_ID_RESPONSE,
    GET_LAW_SECTION_BY_LOW_ID_REQUEST,
    GET_LAW_SECTION_BY_LOW_ID_RESPONSE,
    GET_LEGAL_UPDATE_REFERENCE_ID_REQUEST,
    GET_LEGAL_UPDATE_REFERENCE_ID_RESPONSE,
    GET_IMPACT_MANAGEMENT_PERSONNELS_REQUEST,
    GET_IMPACT_MANAGEMENT_PERSONNELS_RESPONSE,
    GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_REQUEST,
    GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_RESPONSE,
    DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_REQUEST,
    DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_RESPONSE,
    CLEAR_RISK_RATING_RESPONSE,
    CLEAR_COMPLIANCE_CATEGORY_RESPONSE,
    CLEAR_COMPLIANCE_TYPE_RESPONSE
} from './actionTypes';
export const addLawComplianceRequest = (data) => {
    return {
        type: ADD_LAW_COMPLIANCE_REQUEST,
        payload: { data },
    };
};
export const addLawComplianceResponse = (message, responseType, data) => {
    return {
        type: ADD_LAW_COMPLIANCE_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const getAllUploadedFilesRequest = () => {
    return {
        type: GET_ALL_UPLOADED_FILES_REQUEST,
    };
};
export const getAllUploadedFilesResponse = (uploadedFileList) => {
    return {
        type: GET_ALL_UPLOADED_FILES_RESPONSE,
        payload: { uploadedFileList },
    };
};

export const showPercentageResponse = (percentage) => {
    return {
        type: SHOW_LAW_COMPLIANCE,
        payload: { percentage },
    };
};
export const getAllLawCompliancesRequest = () => {
    return {
        type: GET_ALL_LAW_COMPLIANCES_REQUEST,
    };
};
export const getAllLawCompliancesResponse = (allLawCompliances) => {
    return {
        type: GET_ALL_LAW_COMPLIANCES_RESPONSE,
        payload: { allLawCompliances },
    };
};
export const getLawCompliancesByLawIdRequest = (lawId, displayMode = false) => {
    return {
        type: GET_LAW_COMPLIANCES_BY_LAW_ID_REQUEST,
        payload: { lawId: lawId, displayMode },
    };
};
export const getLawCompliancesByLawIdResponse = (lawComplianceList) => {
    return {
        type: GET_LAW_COMPLIANCES_BY_LAW_ID_RESPONSE,
        payload: { lawComplianceList },
    };
};
export const getLawCompliancesByLawCategoryIdRequest = (lawCategoryId) => {
    return {
        type: GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_REQUEST,
        payload: { lawCategoryId: lawCategoryId },
    };
};
export const getLawCompliancesByLawCategoryIdResponse = (lawComplianceList) => {
    return {
        type: GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_RESPONSE,
        payload: { lawComplianceList },
    };
};
export const getLawComplianceDetailsByLawComplianceIdRequest = (
    lawComplianceId, versionId = ''
) => {
    return {
        type: GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_REQUEST,
        payload: { lawComplianceId: lawComplianceId, versionId: versionId },
    };
};
export const getLawComplianceDetailsByLawComplianceIdResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const getLawCompliancesForAllocationRequest = (data) => {
    return {
        type: GET_LAW_COMPLIANCES_FOR_ALLOCATION_REQUEST,
        payload: { data },
    };
};
export const getLawCompliancesForAllocationResponse = (lawComplianceList) => {
    return {
        type: GET_LAW_COMPLIANCES_FOR_ALLOCATION_RESPONSE,
        payload: { lawComplianceList },
    };
};

export const updateLawComplianceStatusByLawComplianceIdRequest = (
    lawComplianceId,
    status
) => {
    return {
        type: UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_REQUEST,
        payload: { lawComplianceId, status },
    };
};
export const updateLawComplianceStatusByLawComplianceIdResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getLawComplianceSearchRequest = (data, pagination) => {
    return {
        type: GET_LAW_COMPLIANCE_SEARCH_REQUEST,
        payload: { data, pagination },
    };
};

export const getLawComplianceSearchResponse = (message, responseType, data) => {
    return {
        type: GET_LAW_COMPLIANCE_SEARCH_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getComplianceTypesRequest = () => {
    return {
        type: GET_COMPLIANCE_TYPES_REQUEST,
    };
};
export const getComplianceTypesResponse = (allCompliancesTypes) => {
    return {
        type: GET_COMPLIANCE_TYPES_RESPONSE,
        payload: { allCompliancesTypes },
    };
};

export const getComplianceCategoriesRequest = () => {
    return {
        type: GET_COMPLIANCE_CATEGORIES_REQUEST,
    };
};
export const getComplianceCategoriesResponse = (allCompliancesCategoriesList) => {
    return {
        type: GET_COMPLIANCE_CATEGORIES_RESPONSE,
        payload: { allCompliancesCategoriesList },
    };
};

export const getPeriodicitiesRequest = () => {
    return {
        type: GET_PERIODICITIES_REQUEST,
    };
};
export const getPeriodicitiesResponse = (allperiodicities) => {
    return {
        type: GET_PERIODICITIES_RESPONSE,
        payload: { allperiodicities },
    };
};

export const getPenaltyTypesRequest = () => {
    return {
        type: GET_PENALTY_TYPES_REQUEST,
    };
};
export const getPenaltyTypesResponse = (penaltyTypes) => {
    return {
        type: GET_PENALTY_TYPES_RESPONSE,
        payload: { penaltyTypes },
    };
};

export const getRiskRatingsRequest = () => {
    return {
        type: GET_RISK_RATING_REQUEST,
    };
};
export const getRiskRatingsResponse = (riskRatings) => {
    return {
        type: GET_RISK_RATING_RESPONSE,
        payload: { riskRatings },
    };
};

export const getImpactManagementPersonnelsRequest = () => {
    return {
        type: GET_IMPACT_MANAGEMENT_PERSONNELS_REQUEST,
    };
};
export const getImpactManagementPersonnelsResponse = (list) => {
    return {
        type: GET_IMPACT_MANAGEMENT_PERSONNELS_RESPONSE,
        payload: { list },
    };
};

export const updateLawcomplianceRequest = (lawcompliancId, data) => {
    return {
        type: UPDATE_LAW_COMPLIANCE_REQUEST,
        payload: { lawcompliancId, data },
    };
};
export const updateLawcomplianceResponse = (message, responseType) => {
    return {
        type: UPDATE_LAW_COMPLIANCE_RESPONSE,
        payload: { message, responseType },
    };
};

export const getLawCompliancebyLowIdRequest = (lowId) => {
    return {
        type: GET_LAW_COMPLIANCE_LOW_ID_REQUEST,
        payload: { lowId },
    };
};
export const getLawCompliancebyLowIdResponse = (lawCompliance) => {
    return {
        type: GET_LAW_COMPLIANCE_LOW_ID_RESPONSE,
        payload: { lawCompliance },
    };
};

export const getlawSectionByLowIdRequest = (lowId) => {
    return {
        type: GET_LAW_SECTION_BY_LOW_ID_REQUEST,
        payload: { lowId },
    };
};
export const getlawSectionByLowIdResponse = (lawSection) => {
    return {
        type: GET_LAW_SECTION_BY_LOW_ID_RESPONSE,
        payload: { lawSection },
    };
};

export const getLegalUpdateReferenceIdRequest = (id, edit = 'compliance_edit') => {
    return {
        type: GET_LEGAL_UPDATE_REFERENCE_ID_REQUEST,
        payload: { id, edit },
    };
};
export const getLegalUpdateReferenceIdResponse = (legalUpdateReference) => {
    return {
        type: GET_LEGAL_UPDATE_REFERENCE_ID_RESPONSE,
        payload: { legalUpdateReference },
    };
};

export const getLawComplianceVersionsbyComplianceIdRequest = (lawComplianceId) => {
    return {
        type: GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_REQUEST,
        payload: { lawComplianceId },
    };
};
export const getLawComplianceVersionsbyComplianceIdResponse = (complianceVersionList) => {
    return {
        type: GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_RESPONSE,
        payload: { complianceVersionList },
    };
};

export const deleteComplianceQueueFileByIdRequest = (complianceQueueFileId) => {
    return {
        type: DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_REQUEST,
        payload: { complianceQueueFileId },
    };
};
export const deleteComplianceQueueFileByIdResponse = (message, responseType) => {
    return {
        type: DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_RESPONSE,
        payload: { message, responseType },
    };
};


export const clearRiskRatingResponse = () => {
    return {
        type: CLEAR_RISK_RATING_RESPONSE
    };
};

export const clearComplianceCategoryResponse = () => {
    return {
        type: CLEAR_COMPLIANCE_CATEGORY_RESPONSE
    };
};

export const clearComplianceTypeResponse = () => {
    return {
        type: CLEAR_COMPLIANCE_TYPE_RESPONSE
    };
};