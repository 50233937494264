import { GET_ARCHIVE_RECORDS_LIST_REQUEST, GET_ARCHIVE_RECORDS_LIST_RESPONSE, UPLOAD_ARCHIVE_RECORDS_REQUEST, UPLOAD_ARCHIVE_RECORDS_RESPONSE, DELETE_ARCHIVE_RECORD_REQUEST, DELETE_ARCHIVE_RECORD_RESPONSE, GET_ARCHIVE_DEPARTMENT_LIST_REQUEST, GET_ARCHIVE_DEPARTMENT_LIST_RESPONSE } from "./actionTypes";

export const getArchiveRecordsListRequest = data => {
    return {
        type: GET_ARCHIVE_RECORDS_LIST_REQUEST,
        payload: { data }
    };
};

export const getArchiveRecordsListResponse = archiveRecordsList => {
    return {
        type: GET_ARCHIVE_RECORDS_LIST_RESPONSE,
        payload: { archiveRecordsList },
    };
};

export const uploadArchiveRecordsRequest = (data) => {
    return {
        type: UPLOAD_ARCHIVE_RECORDS_REQUEST,
        payload: { data },
    };
};

export const uploadArchiveRecordsResponse = (message, responseType) => {
    return {
        type: UPLOAD_ARCHIVE_RECORDS_RESPONSE,
        payload: { message, responseType },
    };
};

export const deleteArchiveRecordByIdRequest = (id) => {
    return {
        type: DELETE_ARCHIVE_RECORD_REQUEST,
        payload: { id },
    };
};
export const deleteArchiveRecordByIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_ARCHIVE_RECORD_RESPONSE,
        payload: { message, responseType },
    };
};

export const getArchiveDocumentListRequest = () => {
    return {
        type: GET_ARCHIVE_DEPARTMENT_LIST_REQUEST
    };
};

export const getArchiveDocumentListResponse = archiveDepartmentList => {
    return {
        type: GET_ARCHIVE_DEPARTMENT_LIST_RESPONSE,
        payload: { archiveDepartmentList },
    };
};