import React from 'react';
import {
    parseFloatHelper,
    roundOff,
    roundOffAmount,
    parseIntHelper,
} from 'helpers/mathHelper';
import { THEME_MODE } from 'constants/authConstants';
export const compareString = (item1, item2) => {
    var item1D = item1.toUpperCase(); // ignore upper and lowercase
    var item2D = item2.toUpperCase(); // ignore upper and lowercase
    if (item1D < item2D) {
        return -1;
    }
    if (item1D > item2D) {
        return 1;
    }
    // items must be equal
    return 0;
};
export const compareIntValue = (itemA, itemB) => {
    let item1 = parseInt(itemA); // update value to int
    let item2 = parseInt(itemB); // update value to in
    if (item1 < item2) {
        return -1;
    }
    if (item1 > item2) {
        return 1;
    }
    // items must be equal
    return 0;
};
// This function will convert the keys of objects which are present in keysOfFloatTypes array to float
// e.g "100.00" to 100 , "100.01" to 100.01
export const convertStringValuesToFloat = (obj, keysOfFloatTypes) => {
    //create clone of whole object by value
    var data = Object.assign({}, obj);
    //traverse each property of object
    Object.entries(data).map(([key, value]) => {
        //if value of property is of object type, then invoke convertStringValuesToFloat recursively.
        if (isObject(value)) {
            data[key] = convertStringValuesToFloat(value, keysOfFloatTypes);
        }
        //if value of property is of array type and if contents of array are of object types
        else if (isArray(value) && isObject(value[0])) {
            var emptyArr = [];
            value.forEach(function (arrayValue) {
                // loop through each array value
                // and push the value to new empty array
                emptyArr.push(
                    convertStringValuesToFloat(arrayValue, keysOfFloatTypes)
                );
            });
            //assign the whole new created array to old object by key.
            data[key] = emptyArr;
        } else {
            if (keysOfFloatTypes.includes(key)) {
                //if key is present in decimal amount fields, then round value by 2 decimals
                data[key] = parseFloatHelper(value);
            }
        }
    });
    return data;
};
// This function will convert the keys of objects which are present in decimalAmountFields and decimalFields array to string
//of decimal rounded off by 2 and 3 digits after decimal respectively.
// e.g 100 to "100.00", 100.001 to "100.001"
export const convertFloatValuesToString = (
    obj,
    decimalAmountFields,
    decimalFields
) => {
    //create clone of whole object by value
    var data = Object.assign({}, obj);
    //traverse each property of object
    Object.entries(data).map(([key, value]) => {
        //if value of property is of object type, then invoke convertFloatValuesToString recursively.
        if (isObject(value)) {
            data[key] = convertFloatValuesToString(
                value,
                decimalAmountFields,
                decimalFields
            );
        }
        //if value of property is of array type and if contents of array are of object types
        else if (isArray(value) && isObject(value[0])) {
            var emptyArr = [];
            value.forEach(function (arrayValue) {
                // loop through each array value
                // and push the value to new empty array
                emptyArr.push(
                    convertFloatValuesToString(
                        arrayValue,
                        decimalAmountFields,
                        decimalFields
                    )
                );
            });
            //assign the whole new created array to old object by key.
            data[key] = emptyArr;
        } else {
            if (decimalAmountFields.includes(key)) {
                //if key is present in decimal amount fields, then round value by 2 decimals
                data[key] = roundOffAmount(value);
            }
            if (decimalFields.includes(key)) {
                //if key is present in decimal fields, then round value by 3 decimals
                data[key] = roundOff(value);
            }
        }
    });
    return data;
};
// This function will convert the fields passed (which would be empty strings) to null for the obj.
// Also, parse string to float for keysOfFloatTypes and parse string to int for keysOfIntTypes to be later passed to API.
export const setEmptyToNull = (obj, nullableFields, intFields, floatFields) => {
    //create clone of whole object by value
    var data = Object.assign({}, obj);
    //traverse each property of object
    Object.entries(data).map(([key, value]) => {
        //if value of property is of object type, then invoke setEmptyToNull recursively.
        if (isObject(value)) {
            data[key] = setEmptyToNull(
                value,
                nullableFields,
                intFields,
                floatFields
            );
        }
        //if value of property is of array type and if contents of array are of object types
        else if (isArray(value) && isObject(value[0])) {
            var emptyArr = [];
            value.forEach(function (arrayValue) {
                // loop through each array value
                // and push the value to new empty array
                emptyArr.push(
                    setEmptyToNull(
                        arrayValue,
                        nullableFields,
                        intFields,
                        floatFields
                    )
                );
            });
            //assign the whole new created array to old object by key.
            data[key] = emptyArr;
        } else {
            if (floatFields.includes(key)) {
                data[key] = parseFloatHelper(value);
            } else if (intFields.includes(key)) {
                data[key] = parseIntHelper(value);
            } else if (nullableFields.includes(key) && value === '')
                //if key is present in fields and is an empty string, then make it null
                data[key] = null;
        }
    });
    return data;
};
// This function will convert the keys of objects which are present in decimalAmountFields and decimalFields array to string
//of decimal rounded off by 2 and 3 digits after decimal respectively.
// e.g 100 to "100.00", 100.001 to "100.001"
export const initializeFloatValues = (
    obj,
    decimalAmountFields,
    decimalFields
) => {
    //create clone of whole object by value
    var data = Object.assign({}, obj);
    //traverse each property of object
    Object.entries(data).map(([key, value]) => {
        if (decimalAmountFields.includes(key)) {
            //if key is present in decimal amount fields, then round value by 2 decimals
            data[key] = roundOffAmount(0);
        }
        if (decimalFields.includes(key)) {
            //if key is present in decimal fields, then round value by 3 decimals
            data[key] = roundOff(0);
        }
    });
    return data;
};
export const isArray = data => {
    return !!data && data.constructor === Array;
};
export const isObject = data => {
    return !!data && data.constructor === Object;
};
export const isObjectEmpty = obj => {
    return Object.keys(obj).length === 0;
};
// Create Ul -> Li structure for the given array list
export const convertArrayListToHTMLStructure = arrayList => {
    let htmlList = '<ul>';
    arrayList.map((opt, index) => {
        htmlList += '<li key=' + index + ' className="text-left">' + opt + '</li>';
    });
    htmlList += '</ul>';
    return htmlList;
};
// style text align in grid
export const alignTextInGrid = (value, className = 'text-right') => {
    return <div className={className}>{value}</div>;
};
// add multiple values with dash (-) in dropsdowns
export const combineMultipleValueInDropdown = (value, addDash = false) => {
    let resposeValue = '';

    resposeValue = value && addDash ? ' - ' + value : value;

    return resposeValue;
};
/*** Formats the size */
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getInitials = function (string) {
    if (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[1].substring(0, 1).toUpperCase();
        }
        return initials;
    } else {
        return '';
    }
};
export const getFirstCapitalize = function (string) {
    //split the above string into an array of strings 
    //whenever a blank space is encountered
    const arr = string.split(" ");
    //loop through each element of the array and capitalize the first letter.
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    //Join all the elements of the array back into a string 
    //using a blankspace as a separator 
    const str2 = arr.join(" ");
    return str2;
}



export const getFileExtension = function (filename) {
    if (filename) {
        return filename.split('.').pop();
    } else {
        return '';
    }
};

export const redirectUrl = (history, value) => {
    history.push(value);
};

export const redirectUrlWindowOpen = (url) => {
    window.open(url)
};
export const isDarkMode = () => {
    const isDark = localStorage.getItem(THEME_MODE) === "dark"
    return isDark
};