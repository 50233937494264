import produce from 'immer';
import { ADD_ENTITY_LOCATION_RESPONSE, DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE, GET_ALL_ENTITY_LOCATION_LIST_RESPONSE, GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE, GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_RESPONSE, UPDATE_ENTITY_LOCATION_RESPONSE, UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_RESPONSE } from './actionTypes';
export const initialState = {
    entityLocationApiResponse: null,
    editEntityLocationApiResponse: null,
    entityLocationList: [],
    entityLocationStatusApiResponse: null
};
const entityLocation = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_ENTITY_LOCATION_RESPONSE:
            case DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE:
            case UPDATE_ENTITY_LOCATION_RESPONSE:
                draft.entityLocationApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_ENTITY_LOCATION_LIST_RESPONSE:
                draft.entityLocationList = action.payload.entityLocationList;
                break;
            case GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE:
                draft.editEntityLocationApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;


            case UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_RESPONSE:
                draft.entityLocationStatusApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;


            case GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_RESPONSE:
                draft.entityLocationList = action.payload.entityLocationList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default entityLocation;
