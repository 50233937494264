import {
    ADD_DEPARTMENT_REQUEST,
    ADD_DEPARTMENT_RESPONSE,
    DELETE_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
    DELETE_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE,
    GET_ALL_DEPARTMENT_LIST_REQUEST,
    GET_ALL_DEPARTMENT_LIST_RESPONSE,
    GET_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
    GET_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE,
    GET_DEPARTMENT_LIST_BY_STATUS_REQUEST,
    GET_DEPARTMENT_LIST_BY_STATUS_RESPONSE,
    UPDATE_DEPARTMENT_REQUEST,
    UPDATE_DEPARTMENT_RESPONSE,
    UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_REQUEST,
    UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_RESPONSE,
    GET_ENTITY_DEPARTMENT_BY_ENTITY_REQUEST,
    GET_ENTITY_DEPARTMENT_BY_ENTITY_RESPONSE,
} from './actionTypes';
export const addDepartmentRequest = (data) => {
    return {
        type: ADD_DEPARTMENT_REQUEST,
        payload: { data },
    };
};
export const addDepartmentResponse = (message, responseType) => {
    return {
        type: ADD_DEPARTMENT_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllDepartmentListRequest = () => {
    return {
        type: GET_ALL_DEPARTMENT_LIST_REQUEST,
    };
};
export const getAllDepartmentListResponse = (departmentList) => {
    return {
        type: GET_ALL_DEPARTMENT_LIST_RESPONSE,
        payload: { departmentList },
    };
};
export const getDepartmentByDepartmentIdRequest = (departmentId) => {
    return {
        type: GET_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
        payload: { departmentId: departmentId },
    };
};
export const getDepartmentByDepartmentIdResponse = (
    message,
    responseType,
    data
) => {
    return {
        type: GET_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateDepartmentRequest = (departmentId, data) => {
    return {
        type: UPDATE_DEPARTMENT_REQUEST,
        payload: { departmentId, data },
    };
};
export const updateDepartmentResponse = (message, responseType) => {
    return {
        type: UPDATE_DEPARTMENT_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateDepartmentStatusByDepartmentIdRequest = (
    departmentId,
    status
) => {
    return {
        type: UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_REQUEST,
        payload: { departmentId, status },
    };
};
export const updateDepartmentStatusByDepartmentIdResponse = (
    message,
    responseType
) => {
    return {
        type: UPDATE_DEPARTMENT_STATUS_BY_DEPARTMENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getDepartmentListByStatusRequest = (status) => {
    return {
        type: GET_DEPARTMENT_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getDepartmentListByStatusResponse = (departmentList) => {
    return {
        type: GET_DEPARTMENT_LIST_BY_STATUS_RESPONSE,
        payload: { departmentList },
    };
};
export const deleteDepartmentByDepartmentIdRequest = (departmentId) => {
    return {
        type: DELETE_DEPARTMENT_BY_DEPARTMENT_ID_REQUEST,
        payload: { departmentId },
    };
};
export const deleteDepartmentByDepartmentIdResponse = (
    message,
    responseType
) => {
    return {
        type: DELETE_DEPARTMENT_BY_DEPARTMENT_ID_RESPONSE,
        payload: { message, responseType },
    };
};

export const getEntityDepartByEntityRequest = (entityId) => {
    return {
        type: GET_ENTITY_DEPARTMENT_BY_ENTITY_REQUEST,
        payload: { entityId },
    };
};
export const getEntityDepartByEntityResponse = (departmentList) => {
    return {
        type: GET_ENTITY_DEPARTMENT_BY_ENTITY_RESPONSE,
        payload: { departmentList },
    };
};
