import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { getEntityTeamsByEntityIdRequest } from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedEntityTeamAndConsultantTeamUserList } from 'store/Entity/selector';
import {
    CANCEL,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
    UPDATE,
} from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    customDateValidator,
    formatDateForApiSystem,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import { PENDING_FOR_AUDIT } from 'constants/databaseConstants';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
class ChangeDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {};
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        if (this.validator.allValid()) {
            this.props.onFormValid({
                ...values,
                dueDate: values.dueDate
                    ? formatDateForApiSystem(values.dueDate)
                    : '',
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.taskDetails.entityId &&
            nextprops.taskDetails !== this.props.taskDetails
        ) {
            this.props.getEntityTeamsByEntityIdRequest(
                nextprops.taskDetails.entityId
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.props.taskDetails}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>

                                        <Row className="d-flex mr-0">
                                            {this.props.taskDetails.dueDate !==
                                                null &&
                                                this.props.taskDetails
                                                    .dueDate != '' && (
                                                    <FormGroup className="col-lg-6 w-100 sticky_label">
                                                        <Field
                                                            name="dueDate"
                                                            component={
                                                                DatePicker
                                                            }
                                                            className="form-control"
                                                            placeholderText={
                                                                DATE_FORMAT_PLACEHOLDER
                                                            }
                                                            selected={
                                                                values.dueDate
                                                                    ? getDateObjectWithValueFromMoment(
                                                                        values.dueDate
                                                                    )
                                                                    : null
                                                            }
                                                            dateFormat={
                                                                DATE_FORMAT_DATEPICKER
                                                            }
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                setFieldValue(
                                                                    `dueDate`,
                                                                    date
                                                                );
                                                            }}
                                                            showMonthDropdown={
                                                                true
                                                            }
                                                            showYearDropdown={
                                                                true
                                                            }
                                                            dropdownMode="select"
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.reporting.sub_task.due_date'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.reporting.sub_task.due_date'
                                                            ),
                                                            values.dueDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.dueDate
                                                                )
                                                                : '',
                                                            'required|customDate',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.reporting.sub_task.due_date_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                )}
                                            <FormGroup className="col-lg-6">
                                                <Field
                                                    component={Select}
                                                    name="submitterId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (opt) =>
                                                            opt.value !==
                                                            values.firstApproverUserId &&
                                                            opt.value !==
                                                            values.secondApproverUserId
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'submitterId',
                                                            opt && opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.submitterId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.allocation.submitter'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.allocation.submitter'
                                                    ),
                                                    values.submitterId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.allocation.submitter_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-6">
                                                <Field
                                                    component={Select}
                                                    name="firstApproverUserId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (opt) =>
                                                            opt.value !==
                                                            values.submitterId &&
                                                            opt.value !==
                                                            values.secondApproverUserId
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'firstApproverUserId',
                                                            opt && opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.firstApproverUserId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.allocation.first_approver'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.allocation.first_approver'
                                                    ),
                                                    values.firstApproverUserId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.allocation.first_approver_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-6">
                                                <Field
                                                    component={Select}
                                                    name="secondApproverUserId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (opt) =>
                                                            opt.value !==
                                                            values.submitterId &&
                                                            opt.value !==
                                                            values.firstApproverUserId
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'secondApproverUserId',
                                                            opt && opt.value
                                                        );
                                                    }}
                                                    value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.secondApproverUserId
                                                    )}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.allocation.second_approver'
                                                    )}
                                                </Label>
                                            </FormGroup>
                                            {this.props.taskDetails
                                                .taskStatusId ===
                                                PENDING_FOR_AUDIT && (
                                                    <FormGroup className="col-lg-12">
                                                        <Field
                                                            component={Select}
                                                            name="auditorId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedEntityTeamAndConsultantTeamUserDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'auditorId',
                                                                    opt && opt.value
                                                                );
                                                            }}
                                                            value={this.props.sortedEntityTeamAndConsultantTeamUserDropdown.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.auditorId
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.reporting.auditor'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.reporting.auditor'
                                                            ),
                                                            values.auditorId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.reporting.auditor_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                )}
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {UPDATE}
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={
                                                            this.props
                                                                .onModalDismiss
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedEntityTeamAndConsultantTeamUserDropdown:
        selectSortedEntityTeamAndConsultantTeamUserList(),
});
const withConnect = connect(mapStatetoProps, {
    getEntityTeamsByEntityIdRequest,
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(ChangeDetailsModal);
