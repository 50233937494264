import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { activateAuthLayout } from 'store/actions';
class ComingSoon extends Component {
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12 text-center m-5 p-5">
                        <div className='coming-soon'>
                            <h1>COMING SOON...</h1>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
    activateAuthLayout,
});
export default compose(
    withConnect,
    withRouter
)(ComingSoon);