import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_REQUEST,
    GET_ENTITY_ROLE_LIST_REQUEST,
    GET_MODULE_LIST_REQUEST,
    GET_PERMISSION_LIST_REQUEST,
    GET_ROLE_BY_ROLE_ID_REQUEST,
    GET_ROLE_LIST_REQUEST,
    GET_ROLE_PERMISSION_BY_LOGIN_USER_REQUEST,
    GET_SYSTEM_CONFIGURATION_ROLE_LIST_REQUEST,
    GET_SYSTEM_CONFIGURATION_USERS_LIST_REQUEST,
    SAVE_SYSTEM_CONFIGURATION_USERS_REQUEST,
    UPDATE_ROLE_BY_ROLE_ID_REQUEST,
    UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_REQUEST,
    GET_USER_ROLE_WISE_COUNTRIES_LIST_REQUEST,
    GET_GCM_USERS_BY_COUNTRY_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import {
    deleteSystemConfigurationUserRoleByUserRoleIdResponse,
    getEntityRoleListResponse,
    getModuleListResponse,
    getPermissionListResponse,
    getRoleByRoleIdResponse,
    getRoleListResponse,
    getRolePermissionByLoginUserResponse,
    getSystemConfigurationRoleListResponse,
    getSystemConfigurationUsersListRequest,
    getSystemConfigurationUsersListResponse,
    saveSystemConfigurationUsersResponse,
    updateRoleByRoleIdResponse,
    updateSystemConfigurationUserRoleStatusByUserRoleIdResponse,
    getUserRoleWiseCountriesListResponse,
    getGcmUsersByCountryResponse,
} from './actions';
import {
    ENTITY_ROLES,
    MODULES,
    PERMISSIONS,
    ROLES,
    ROLE_PERMISSION,
    ROLE_PERMISSION_LOGIN_USER,
    SYSTEM_CONFIGURATION_ROLES,
    SYSTEM_CONFIGURATION_USERS,
    GET_USER_ROLE_WISE_COUNTRIES,
    GCM_USERS_BY_COUNTRY,
} from 'helpers/apiConstants';
import { DANGER, SUCCESS } from 'constants/commonConstants';
function* getRoleList() {
    try {
        const response = yield call(invokeApi, 'get', ROLES);
        if (response.status) {
            yield put(getRoleListResponse(response.data.list));
        } else {
            yield put(getRoleListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getModuleList() {
    try {
        const response = yield call(invokeApi, 'get', MODULES);
        if (response.status) {
            yield put(getModuleListResponse(response.data.list));
        } else {
            yield put(getModuleListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getPermissionList() {
    try {
        const response = yield call(invokeApi, 'get', PERMISSIONS);
        if (response.status) {
            yield put(getPermissionListResponse(response.data.list));
        } else {
            yield put(getPermissionListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getRoleByRoleId({ payload: { roleId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            ROLE_PERMISSION + '/' + roleId
        );
        if (response.status) {
            yield put(
                getRoleByRoleIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(getRoleByRoleIdResponse(response.message, DANGER, {}));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateRoleByRoleId({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ROLE_PERMISSION, data);
        if (response.status) {
            yield put(updateRoleByRoleIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateRoleByRoleIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getRolePermissionByLoginUser() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            ROLE_PERMISSION_LOGIN_USER
        );
        if (response.status) {
            yield put(
                getRolePermissionByLoginUserResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getRolePermissionByLoginUserResponse(
                    response.message,
                    DANGER,
                    {}
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getEntityRoleList() {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_ROLES);
        if (response.status) {
            yield put(getEntityRoleListResponse(response.data.list));
        } else {
            yield put(getEntityRoleListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSystemConfigurationRoleList() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            SYSTEM_CONFIGURATION_ROLES
        );
        if (response.status) {
            yield put(
                getSystemConfigurationRoleListResponse(response.data.list)
            );
        } else {
            yield put(getSystemConfigurationRoleListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* saveSystemConfigurationUsers({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            SYSTEM_CONFIGURATION_USERS,
            data
        );
        if (response.status) {
            yield put(getSystemConfigurationUsersListRequest());
            yield put(
                saveSystemConfigurationUsersResponse(response.message, SUCCESS)
            );
        } else {
            yield put(
                saveSystemConfigurationUsersResponse(response.message, DANGER)
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getSystemConfigurationUsersList() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            SYSTEM_CONFIGURATION_USERS
        );
        if (response.status) {
            yield put(
                getSystemConfigurationUsersListResponse(response.data.list)
            );
        } else {
            yield put(getSystemConfigurationUsersListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateSystemConfigurationUserRoleStatusByUserRoleId({
    payload: { userRoleId, status },
}) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            SYSTEM_CONFIGURATION_USERS + '/' + userRoleId + '/status/' + status
        );
        if (response.status) {
            yield put(getSystemConfigurationUsersListRequest());
            yield put(
                updateSystemConfigurationUserRoleStatusByUserRoleIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateSystemConfigurationUserRoleStatusByUserRoleIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteSystemConfigurationUserRoleByUserRoleId({
    payload: { userRoleId },
}) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            SYSTEM_CONFIGURATION_USERS + '/' + userRoleId
        );
        if (response.status) {
            yield put(getSystemConfigurationUsersListRequest());
            yield put(
                deleteSystemConfigurationUserRoleByUserRoleIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteSystemConfigurationUserRoleByUserRoleIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getUserRoleWiseCountriesList({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GET_USER_ROLE_WISE_COUNTRIES,
            data
        );
        if (response.status) {
            yield put(getUserRoleWiseCountriesListResponse(response.data.list));
        } else {
            yield put(getUserRoleWiseCountriesListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getGcmUsersByCountryId({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            GCM_USERS_BY_COUNTRY,
            data
        );
        if (response.status) {
            yield put(getGcmUsersByCountryResponse(response.data.list));
        } else {
            yield put(getGcmUsersByCountryResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchRole() {
    yield takeEvery(GET_ROLE_LIST_REQUEST, getRoleList);
    yield takeEvery(GET_MODULE_LIST_REQUEST, getModuleList);
    yield takeEvery(GET_PERMISSION_LIST_REQUEST, getPermissionList);
    yield takeEvery(GET_ROLE_BY_ROLE_ID_REQUEST, getRoleByRoleId);
    yield takeEvery(UPDATE_ROLE_BY_ROLE_ID_REQUEST, updateRoleByRoleId);
    yield takeEvery(
        GET_ROLE_PERMISSION_BY_LOGIN_USER_REQUEST,
        getRolePermissionByLoginUser
    );
    yield takeEvery(GET_ENTITY_ROLE_LIST_REQUEST, getEntityRoleList);
    yield takeEvery(
        GET_SYSTEM_CONFIGURATION_ROLE_LIST_REQUEST,
        getSystemConfigurationRoleList
    );
    yield takeEvery(
        SAVE_SYSTEM_CONFIGURATION_USERS_REQUEST,
        saveSystemConfigurationUsers
    );
    yield takeEvery(
        GET_SYSTEM_CONFIGURATION_USERS_LIST_REQUEST,
        getSystemConfigurationUsersList
    );
    yield takeEvery(
        UPDATE_SYSTEM_CONFIGURATION_USER_ROLE_STATUS_BY_USER_ROLE_ID_REQUEST,
        updateSystemConfigurationUserRoleStatusByUserRoleId
    );
    yield takeEvery(
        DELETE_SYSTEM_CONFIGURATION_USER_ROLE_BY_USER_ROLE_ID_REQUEST,
        deleteSystemConfigurationUserRoleByUserRoleId
    );
    yield takeEvery(
        GET_USER_ROLE_WISE_COUNTRIES_LIST_REQUEST,
        getUserRoleWiseCountriesList
    );
    yield takeEvery(GET_GCM_USERS_BY_COUNTRY_REQUEST, getGcmUsersByCountryId);
}
function* roleSaga() {
    yield all([fork(watchRole)]);
}
export default roleSaga;
