export const ADD_NEW_TICKET_REQUEST = 'add_new_ticket_request';
export const ADD_NEW_TICKET_RESPONSE = 'add_new_ticket_response';
export const EDIT_TICKET_REQUEST = 'edit_ticket_request';
export const EDIT_TICKET_RESPONSE = 'edit_ticket_response';
export const LIST_TICKET_REQUEST = 'list_ticket_request';
export const LIST_TICKET_RESPONSE = 'list_ticket_response';
export const LIST_TICKET_CATEGORY_REQUEST = 'list_ticket_category_request';
export const LIST_TICKET_CATEGORY_RESPONSE = 'list_ticket_category_response';
export const LIST_TICKET_ASSIGNEE_USERS_REQUEST = 'list_ticket_assignee_users_request';
export const LIST_TICKET_ASSIGNEE_USERS_RESPONSE = 'list_ticket_assignee_users_response';
export const GET_TICKET_DETAILS_BY_ID_REQUEST = 'get_ticket_details_by_id_request';
export const GET_TICKET_DETAILS_BY_ID_RESPONSE = 'get_ticket_details_by_id_response';
export const ADD_NEW_TICKET_MESSAGE_REQUEST = 'add_new_ticket_message_request';
export const ADD_NEW_TICKET_MESSAGE_RESPONSE = 'add_new_ticket_message_response';
export const GET_TICKET_MESSAGE_LIST_REQUEST = 'get_ticket_message_list_request';
export const GET_TICKET_MESSAGE_LIST_RESPONSE = 'get_ticket_message_list_response';
export const GET_TICKET_COUNT_REQUEST = 'get_ticket_count_request';
export const GET_TICKET_COUNT_RESPONSE = 'get_ticket_count_response';