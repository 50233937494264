import React, { Component, useState } from 'react';
import mainLogo from "../../../images/logo-dark.png"
import reportBG from "../../../images/report_bg.png"
import Country from '../../../images/country-icon_earth.png';
import Entities from '../../../images/entities-icon.png';
import Users from '../../../images/users-icon.png';
import Compliances from '../../../images/compliances-icon.png';
import Location from '../../../images/location.png';
import LocationLight from '../../../images/location_light.png';
import Laws from '../../../images/laws_icon+.png';
import { getComplianceScore } from 'helpers/projectUtils';
import { RISK_RATING_CRITICAL, RISK_RATING_HIGH, RISK_RATING_LOW, RISK_RATING_MODERATE } from 'constants/databaseConstants';
import { getCurrentDate, getCurrentDateForReportPDF, getCurrentDateTime, getDateFromDateTimeString } from 'helpers/dateTimeHelper';
import { NA } from 'constants/commonConstants';
import { CardBody } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import { compareIntValue } from 'helpers/generalUtils';

// import { world_map } from '../PDFUtils/world_map.ts';
// import { MapsComponent, LayersDirective, LayerDirective } from '@syncfusion/ej2-react-maps';
const getChartUrl = (data) => {
    const score = parseInt(data.complianceScore, 10);
    const config = {
        type: 'doughnut',
        data: {
            datasets: [
                {
                    data: [score, 100 - score],
                    backgroundColor: ['#4CAF50', '#EEEEEE'],
                },
            ],
        },
        options: {
            rotation: 1.5 * Math.PI, // Rotate so the "start" is at the bottom
            circumference: 2 * Math.PI, // Only show the bottom half
            cutoutPercentage: 80, // Make the center part bigger
            plugins: {
                datalabels: {
                    display: false,
                },
            },
        },
    };

    // Encode the config as a URL
    const encodedConfig = encodeURIComponent(JSON.stringify(config));
    return `https://quickchart.io/chart?c=${encodedConfig}`;
};
class HtmlReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false // Add loader state
        };
    }
    renderListItem = (data, index) => {
        const chartUrl = getChartUrl(data);
        return (
            <li key={index} style={{
                position: 'relative',
                margin: '5px',
                display: 'inline-flex',
                maxWidth: '4rem',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '0.5rem',
                    color: '#4CAF50'
                }}>
                    {`${parseInt(data.complianceScore, 10)}%`}
                </div>
                <img src={chartUrl} alt="Compliance Chart" style={{ 'width': '100%' }} />
                <h6 style={{ fontSize: '0.6rem', color: '#000' }} title={data.userFullName}>{data.userFullName}</h6>
            </li>
        )
    };
    render() {
        let departmentWiseData = this.props.departmentWiseData?.filter((e) => e.cityWiseCount.length > 0);
        let auditorWiseData = this.props.departmentWiseData?.filter((e) => e.cityWiseCount.length > 0 && e.cityWiseCount.filter((c) => c.auditAllocated ||
            c.audited || c.pendingObservation || c.pendingAudit || c.discrepancy || c.discrepancyResolution).length > 0);
        let auditorWiseDataDiscrepancy = auditorWiseData?.filter((e) => e.cityWiseCount.length > 0 && e.cityWiseCount.filter((c) => c.discrepancy).length > 0);

        let companyGroupName = this.props?.companyGroup?.companyGroupName ? this.props?.companyGroup?.companyGroupName : ""
        let totalCompliacnce = this.props?.uniqueComplianceIds?.length || 0;
        let imgObj1 = {
            type: 'bar',
            data: {
                labels: [2012, 2013, 2014, 2015, 2016],
                datasets: [{
                    label: 'Users',
                    data: [120, 60, 50, 180, 120]
                }]
            }
        }

        let chartUrl1 = `https://quickchart.io/chart?c=${encodeURIComponent(JSON.stringify(imgObj1))}`;

        // let chartRiskRatingCritical = [
        //     this.props?.graphRiskRatingWiseData[0]?.closed,
        //     this.props?.graphRiskRatingWiseData[0]?.inprogress,
        //     this.props?.graphRiskRatingWiseData[0]?.delayed,
        //     this.props?.graphRiskRatingWiseData[0]?.overdue
        // ];
        // let chartRiskRatingHigh = [
        //     this.props?.graphRiskRatingWiseData[1]?.closed,
        //     this.props?.graphRiskRatingWiseData[1]?.inprogress,
        //     this.props?.graphRiskRatingWiseData[1]?.delayed,
        //     this.props?.graphRiskRatingWiseData[1]?.overdue
        // ];
        // let chartRiskRatingModerate = [
        //     this.props?.graphRiskRatingWiseData[2]?.closed,
        //     this.props?.graphRiskRatingWiseData[2]?.inprogress,
        //     this.props?.graphRiskRatingWiseData[2]?.delayed,
        //     this.props?.graphRiskRatingWiseData[2]?.overdue
        // ];
        // let chartRiskRatingLow = [
        //     this.props?.graphRiskRatingWiseData[3]?.closed,
        //     this.props?.graphRiskRatingWiseData[3]?.inprogress,
        //     this.props?.graphRiskRatingWiseData[3]?.delayed,
        //     this.props?.graphRiskRatingWiseData[3]?.overdue
        // ];

        // let imgObjForRiskRatingGraph = {
        //     type: 'radar',
        //     data: {
        //         labels: ['Closed', 'In Progress', 'Delay', 'Overdue'],
        //         datasets: [
        //             {
        //                 label: 'Critical',
        //                 data: chartRiskRatingCritical,
        //                 backgroundColor: 'rgba(222, 0, 46, 0.2)',
        //                 borderColor: 'rgba(222, 0, 46, 0.8)',
        //             },
        //             {
        //                 label: 'High',
        //                 data: chartRiskRatingHigh,
        //                 backgroundColor: 'rgba(194, 66, 0, 0.2)',
        //                 borderColor: 'rgba(194, 66, 0, 0.8)',
        //             },
        //             {
        //                 label: 'Moderate',
        //                 data: chartRiskRatingModerate,
        //                 backgroundColor: 'rgba(248, 180, 37, 0.2)',
        //                 borderColor: 'rgba(248, 180, 37, 0.8)',
        //             },
        //             {
        //                 label: 'Low',
        //                 data: chartRiskRatingLow,
        //                 backgroundColor: 'rgba(79, 45, 127, 0.2)',
        //                 borderColor: 'rgba(79, 45, 127, 0.8)',
        //             },
        //         ],
        //     },
        //     options: {
        //         scale: {
        //             pointLabels: {
        //                 fontSize: 14,
        //                 fontFamily: 'Arial',
        //             },
        //         },
        //     },
        // };
        // const riskRatingChartUrl = `https://quickchart.io/chart?w=400&h=300&c=${encodeURIComponent(JSON.stringify(imgObjForRiskRatingGraph))}`;

        let risKRatingOverdueTotal = this.props?.graphRiskRatingWiseData[0]?.overdue +
            this.props?.graphRiskRatingWiseData[1]?.overdue +
            this.props?.graphRiskRatingWiseData[2]?.overdue +
            this.props?.graphRiskRatingWiseData[3]?.overdue;

        let risKRatingDelayedTotal = this.props?.graphRiskRatingWiseData[0]?.delayed +
            this.props?.graphRiskRatingWiseData[1]?.delayed +
            this.props?.graphRiskRatingWiseData[2]?.delayed +
            this.props?.graphRiskRatingWiseData[3]?.delayed;
        // let imgObjForRiskRatingGraph1 = {
        //     type: 'doughnut',
        //     data: {
        //         datasets: [
        //             {
        //                 data: [
        //                     this.props?.graphRiskRatingWiseData[0]?.overdue + this.props?.graphRiskRatingWiseData[0]?.delayed,
        //                     this.props?.graphRiskRatingWiseData[1]?.overdue + this.props?.graphRiskRatingWiseData[1]?.delayed,
        //                     this.props?.graphRiskRatingWiseData[2]?.overdue + this.props?.graphRiskRatingWiseData[2]?.delayed,
        //                     this.props?.graphRiskRatingWiseData[3]?.overdue + this.props?.graphRiskRatingWiseData[3]?.delayed
        //                 ],
        //                 backgroundColor: [
        //                     'rgb(222, 0, 46)',
        //                     'rgb(194, 66, 0)',
        //                     'rgb(248, 180, 37)',
        //                     'rgb(79, 45, 127)',
        //                 ],
        //             },
        //         ],
        //         labels: ['Critical', 'High', 'Moderate', 'Low'],
        //     },
        //     options: {
        //         plugins: {
        //             datalabels: {
        //                 display: true,
        //                 align: 'center',
        //                 backgroundColor: '#fff',
        //                 borderRadius: 2,
        //                 font: {
        //                     size: 12,
        //                 },
        //             },
        //         },
        //         title: {
        //             display: false,
        //         },
        //         legend: {
        //             display: true,
        //             position: 'bottom',
        //         },
        //     },
        // };
        let overdueArray = [
            this.props?.graphRiskRatingWiseData[0]?.overdue,
            this.props?.graphRiskRatingWiseData[1]?.overdue,
            this.props?.graphRiskRatingWiseData[2]?.overdue,
            this.props?.graphRiskRatingWiseData[3]?.overdue
        ];
        let delayArray = [
            this.props?.graphRiskRatingWiseData[0]?.delayed,
            this.props?.graphRiskRatingWiseData[1]?.delayed,
            this.props?.graphRiskRatingWiseData[2]?.delayed,
            this.props?.graphRiskRatingWiseData[3]?.delayed
        ];
        let imgObjForRiskRatingGraph2 = {
            type: 'bar',
            data: {
                labels: ['Critical', 'High', 'Moderate', 'Low'],
                datasets: [
                    {
                        label: 'Overdue',
                        data: overdueArray,
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(222, 0, 46,0.8)',
                        borderColor: 'rgb(222, 0, 46)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            font: {
                                weight: 'bold',
                                size: 9,
                                family: 'Arial',
                            },
                        },
                    },
                    {
                        label: 'Delayed',
                        data: delayArray,
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(194, 66, 0,0.8)',
                        borderColor: 'rgb(194, 66, 0)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            font: {
                                weight: 'bold',
                                size: 9,
                                family: 'Arial',
                            },
                        },
                    },
                ],
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 8,
                                suggestedMax:
                                    Math.max(...[...overdueArray, ...delayArray]) + 5,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                fontSize: 8,
                            },
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        color: 'black',
                        font: {
                            weight: 'bold',
                            size: 12,
                            family: 'Arial',
                        },
                    },
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 9,
                        fontFamily: 'Arial'
                    },
                },
            },
        };
        let riskRatingChartUrl = `https://quickchart.io/chart?w=300&h=200&c=${encodeURIComponent(JSON.stringify(imgObjForRiskRatingGraph2))}`;

        let riskRatingSummary = {
            overdue: {
                critical: this.props?.graphRiskRatingWiseData[0]?.overdue,
                high: this.props?.graphRiskRatingWiseData[1]?.overdue,
                moderate: this.props?.graphRiskRatingWiseData[2]?.overdue,
                low: this.props?.graphRiskRatingWiseData[3]?.overdue,
            },
            delayed: {
                critical: this.props?.graphRiskRatingWiseData[0]?.delayed,
                high: this.props?.graphRiskRatingWiseData[1]?.delayed,
                moderate: this.props?.graphRiskRatingWiseData[2]?.delayed,
                low: this.props?.graphRiskRatingWiseData[3]?.delayed,
            }
        }
        let chartDepartmentsLabels = this.props.graphDepartmentWiseData.map((d) => d.departmentName)?.slice(0, 8);
        // let chartDepartmentsCritical = this.props.graphDepartmentWiseData.map((d) => d.critical)?.slice(0, 5);
        // let chartDepartmentsHigh = this.props.graphDepartmentWiseData.map((d) => d.high)?.slice(0, 5);
        // let chartDepartmentsModerate = this.props.graphDepartmentWiseData.map((d) => d.moderate)?.slice(0, 5);
        // let chartDepartmentsLow = this.props.graphDepartmentWiseData.map((d) => d.low)?.slice(0, 5);
        let chartDepartmentsReported = this.props.graphDepartmentWiseData.map((d) => d.reported)?.slice(0, 8);
        let chartDepartmentsDelayed = this.props.graphDepartmentWiseData.map((d) => d.delayed)?.slice(0, 8);
        let chartDepartmentsOverdue = this.props.graphDepartmentWiseData.map((d) => d.overdue)?.slice(0, 8);
        let chartDepartmentsClosed = this.props.graphDepartmentWiseData.map((d) => d.closed)?.slice(0, 8);
        let imgObjForDepartmentGraph = {
            type: 'bar',
            data: {
                labels: chartDepartmentsLabels,
                datasets: [
                    {
                        label: 'Overdue',
                        data: chartDepartmentsOverdue,
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(222, 0, 46, 0.8)',
                        borderColor: 'rgba(222, 0, 46, 1)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    },
                    {
                        label: 'Delayed',
                        data: chartDepartmentsDelayed,
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(194, 66, 0, 0.8)',
                        borderColor: 'rgba(194, 66, 0, 1)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    },
                    {
                        label: 'Reported',
                        data: chartDepartmentsReported,
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(248, 180, 37, 0.8)',
                        borderColor: 'rgba(248, 180, 37, 1)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    },
                    {
                        label: 'Closed',
                        data: chartDepartmentsClosed,
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(79, 45, 127, 0.8)',
                        borderColor: 'rgba(79, 45, 127, 1)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    }
                ]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: Math.max(...[
                                ...chartDepartmentsOverdue,
                                ...chartDepartmentsDelayed,
                                ...chartDepartmentsReported,
                                ...chartDepartmentsClosed
                            ]) + 5
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        color: 'black',
                        font: {
                            weight: 'bold',
                            size: 12,
                            family: 'Arial',
                        },
                    },
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 15,
                        fontFamily: 'Arial',
                    },
                },
            }
        };

        let departmentChartUrl = `https://quickchart.io/chart?w=900&h=500&c=${encodeURIComponent(JSON.stringify(imgObjForDepartmentGraph))}`;

        let countryWiseSummaryData = {
            totalCountries: this.props.countryWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }

        let lawWiseSummaryData = {
            totalLaws: this.props.lawWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }
        let entityWiseSummaryData = {
            totalEntities: this.props.entityWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }

        let departmentWiseSummaryData = {
            totalDepartments: this.props.departmentWiseData.length,
            totalMappedCompliances: 0,
            totalPendingCompliances: 0,
            totalReportedCompliances: 0,
            totalDelayedCompliances: 0,
            totalOverdueCompliances: 0,
            riskWiseOverdueCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
            riskWiseDelayedCompliances: {
                critical: 0,
                high: 0,
                moderate: 0,
                low: 0
            },
        }
        let departmentIndex = 0;

        let complianceOverviewSummary = {
            totalCountries: this.props.countryWiseData.length,
            totalEntities: this.props.entityWiseData?.length,
            totalDepartments: this.props.departmentWiseData.length,
            totalLaws: this.props.lawWiseData?.length,
            totalSystemUsers: this.props.uniqueTaskUserCount,
            unreportedTasksCount: this.props.unreportedTasksCount,
            reportedTaskCount: this.props.reportedTaskCount,
            overdueTasksCount: this.props.overdueTasksCount,
            delayedTasksCount: this.props.delayedTasksCount,
            totalCompliance: totalCompliacnce,
        }
        const styles = `
            .page-break {
                page-break-before: always;
                display: inline-block;
            }`;

        let currentDateTime = getCurrentDateTime();
        let currentDate = getCurrentDateForReportPDF();
        let sortedTopPerformUsers = this.props.performersData.topPerformUsers;
        let sortedScopeOfImproveUsers = this.props.performersData.scopeOfImprovementUsers;

        let AuditChartdata = {
            type: 'bar',
            data: {
                labels: ['Critical', 'High', 'Moderate', 'Low'],
                datasets: [
                    {
                        label: 'Audited & Closed',
                        data: [
                            this.props.riskAnalysisWiseData?.criticalRiskTaskCounts?.auditedAndClosedTaskCount || 0,
                            this.props.riskAnalysisWiseData?.highRiskTaskCounts?.auditedAndClosedTaskCount || 0,
                            this.props.riskAnalysisWiseData?.moderateRiskTaskCounts?.auditedAndClosedTaskCount || 0,
                            this.props.riskAnalysisWiseData?.lowRiskTaskCounts?.auditedAndClosedTaskCount || 0
                        ],
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(205, 235, 152, 0.8)',
                        borderColor: 'rgb(205, 235, 152)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    },
                    {
                        label: 'Pending with Auditor',
                        data: [
                            this.props.riskAnalysisWiseData?.criticalRiskTaskCounts?.pendingAuditorTaskCount || 0,
                            this.props.riskAnalysisWiseData?.highRiskTaskCounts?.pendingAuditorTaskCount || 0,
                            this.props.riskAnalysisWiseData?.moderateRiskTaskCounts?.pendingAuditorTaskCount || 0,
                            this.props.riskAnalysisWiseData?.lowRiskTaskCounts?.pendingAuditorTaskCount || 0
                        ],
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(167, 150, 191, 0.8)',
                        borderColor: 'rgba(167, 150, 191, 1)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    },
                    {
                        label: 'Action Pending on Observations',
                        data: [
                            this.props.riskAnalysisWiseData?.criticalRiskTaskCounts?.pendingAuditObservationTaskCount || 0,
                            this.props.riskAnalysisWiseData?.highRiskTaskCounts?.pendingAuditObservationTaskCount || 0,
                            this.props.riskAnalysisWiseData?.moderateRiskTaskCounts?.pendingAuditObservationTaskCount || 0,
                            this.props.riskAnalysisWiseData?.lowRiskTaskCounts?.pendingAuditObservationTaskCount || 0
                        ],
                        barPercentage: 0.8,
                        backgroundColor: 'rgba(239, 128, 151, 0.8)',
                        borderColor: 'rgba(239, 128, 151, 1)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    },
                    {
                        label: 'Discrepancy',
                        data: [
                            this.props.riskAnalysisWiseData?.criticalRiskTaskCounts?.discrepancyTaskCount || 0,
                            this.props.riskAnalysisWiseData?.highRiskTaskCounts?.discrepancyTaskCount || 0,
                            this.props.riskAnalysisWiseData?.moderateRiskTaskCounts?.discrepancyTaskCount || 0,
                            this.props.riskAnalysisWiseData?.lowRiskTaskCounts?.discrepancyTaskCount || 0
                        ],
                        barPercentage: 0.8,
                        backgroundColor: 'rgb(137, 228, 228)',
                        borderColor: 'rgba(137, 228, 228, 1)',
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end'
                        }
                    },
                ]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: Math.max(...[
                                ...[4, 20, 10, 10],
                                ...[5, 5, 3, 2],
                                ...[10, 34, 2, 0],
                            ]) + 5
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        color: 'black',
                        font: {
                            weight: 'bold',
                            size: 15,
                            family: 'Arial'
                        }
                    }
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 15,
                        fontFamily: 'Arial',
                    }
                },
            }
        }
        let riskAnalysisAuditChartUrl = `https://quickchart.io/chart?w=900&h=500&c=${encodeURIComponent(JSON.stringify(AuditChartdata))}`;

        return (
            <React.Fragment>
                <style>{styles}</style>
                <table style={{ width: '100%', height: '100%', background: '#4f2d7f' }}>
                    <thead style={{ background: '#ffffff' }}>
                        <tr>
                            <th style={{ textAlign: 'right', padding: '1rem 2rem 0rem' }}>
                                <img src={(this.props.reportType === "custom" && this.props.pdfLogo) ? this.props.pdfLogo : mainLogo} width={'130'} />
                            </th>
                        </tr>
                        <tr style={{ padding: '0rem 2rem 1rem' }}>
                            <th style={{ padding: '0rem 2rem 1rem' }}>
                                <h1 style={{ width: '100%', color: '#2a3142', fontSize: '42px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>Anushaasan</h1>
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '60%', maxHeight: '210mm', minHeight: '210mm', width: '100%', backgroundImage: `url(${reportBG})`, backgroundSize: 'cover', backgroundPosition: 'center', padding: '50px 40px', display: 'inline-block' }}>
                        <tr>
                            <td>
                                <div style={{ width: '100%', display: 'inline-block' }}>
                                    <h2 style={{ maxWidth: '75%', color: '#ffffff', fontSize: '32px', fontWeight: 'bold', letterSpacing: '1px', padding: '10px 0', margin: '0', display: 'inline-block', borderBottom: '5px solid #ffffff59' }}>Compliance Report</h2>
                                </div>
                                {this.props.filters.fromDate && this.props.filters.toDate && (
                                    <h6 style={{ Width: '100%', color: '#ffffff', fontSize: '18px', fontWeight: '100', letterSpacing: '1px', padding: '10px 0', margin: '0', display: 'inline-block' }}>{getDateFromDateTimeString(this.props.filters.fromDate)} to {getDateFromDateTimeString(this.props.filters.toDate)}</h6>
                                )}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot style={{ background: '#4f2d7f', minHeight: '50mm', padding: '10px 40px', display: 'inline-flex', alignItems: 'center', width: '100%', position: 'absolute', left: '0', bottom: '0rem', zIndex: '1' }}>
                        <tr style={{ height: '100%' }}>
                            <td style={{ verticalAlign: 'middle', height: '100%' }}>
                                <div style={{ background: '#ffffff', width: '100%', borderRadius: '10px', fontSize: '12px', padding: '10px 10px 5px' }}>
                                    <p style={{ color: '#2A3142', fontSize: '12px', lineHeight: 'normal', margin: '0', display: 'inline-block', width: '100%' }}>This is a compliance analysis report generated by the Anushaasan as of {currentDate} and provides a comprehensive analysis of the compliances mapped and reported in the system. Additionally it provides analysis of the users logins, new updates and upcoming critical compliances. This report is solely for the management analysis and not for external use.</p>
                                    <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    <div style={{ width: '100%', display: 'inline-block', margin: '0' }}>
                                        <div style={{ width: '50%', float: 'left' }}>
                                            <b style={{ color: '#000', margin: '0', fontWeight: 'bold', width: '100%', display: 'inline-block' }}>{companyGroupName}</b>
                                        </div>
                                        <div style={{ width: '50%', float: 'right', textAlign: 'right' }}>
                                            <b style={{ color: '#000', margin: '0', fontWeight: 'bold', width: '100%', display: 'inline-block' }}>{currentDateTime}</b>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {this.props.isGraphical && (
                    <>
                        <table style={{ width: '100%', height: '100%', background: '#ffffff' }}>
                            <tr style={{ padding: '1rem 3rem 1rem' }}>
                                <td style={{ padding: '1.1rem 1rem 0rem' }}>
                                    <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>Compliance overview</h4>
                                    <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                </td>
                            </tr>
                            <tbody style={{ width: '100%', padding: '20px 0px 10', paddingTop: '0', display: 'inline-block' }}>
                                <tr>
                                    <td>
                                        <div style={{ width: 'calc(100% - 20px)', margin: "0 10px", float: 'left' }}>
                                            <h4 style={{ marginBottom: "0.5rem", marginTop: "0", fontSize: "0.8rem", lineHeight: "1.2", color: "#3c2261", background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(79, 45, 127, 0.19) 82%, rgba(79, 45, 127, 0.22) 100%)", borderLeft: "3px solid #3c2261", padding: "0.3rem 0.2rem 0.1rem", paddingLeft: "0.2rem" }}>Compliance overview graph/Table</h4>
                                            <div style={{ borderRadius: '10px', height: "auto", padding: '0.5rem', background: '#f4f4f4' }}>
                                                <div style={{ backgroundColor: "rgba(238, 240, 250, 0.5019607843)", padding: "0rem", display: 'inline-grid', gridTemplateColumns: 'repeat(6, 1fr)', width: "100%", gap: "0.3rem" }}>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Country} width={'20'} alt="location" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Country Covered</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.totalCountries}</span>
                                                    </div>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Entities} width={'20'} alt="location" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Active Entities</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.totalEntities}</span>
                                                    </div>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Laws} width={'20'} alt="location" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Departments</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.totalDepartments}</span>
                                                    </div>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Users} width={'20'} alt="Users" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>System Users</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.totalSystemUsers}</span>
                                                    </div>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Laws} width={'20'} alt="location" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Laws Covered</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.totalLaws}</span>
                                                    </div>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Compliances} width={'20'} alt="location" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Compliances Implemented</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{totalCompliacnce}</span>
                                                    </div>
                                                    {/* <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Users} width={'20'} alt="Users" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Pending for Reporting</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.unreportedTasksCount}</span>
                                                    </div>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Laws} width={'20'} alt="Laws" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Reported</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.reportedTaskCount}</span>
                                                    </div>
                                                    <div style={{ backgroundColor: "#ffffff", padding: '0.4rem', borderRadius: "0.5rem", textAlign: "center", }}>
                                                        <img src={Compliances} width={'16'} alt="Compliances" />
                                                        <h3 style={{ fontSize: '0.6rem', color: "#4F2D7F", marginBottom: "0.2rem", lineHeight: "1" }}>Delayed</h3>
                                                        <span style={{ fontSize: '0.8rem', color: "#4F2D7F" }}>{complianceOverviewSummary.delayedTasksCount}</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ width: 'calc(100% - 20px)', display: "inline-flex", flexDirection: "column", margin: "1rem 10px 0", float: 'left' }}>
                                            <h4 style={{ marginBottom: "0.5rem", marginTop: "0", fontSize: "0.8rem", lineHeight: "1.2", color: "#3c2261", background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(79, 45, 127, 0.19) 82%, rgba(79, 45, 127, 0.22) 100%)", borderLeft: "3px solid #3c2261", padding: "0.3rem 0.2rem 0.1rem", paddingLeft: "0.2rem" }}>Risk Rating wise analysis</h4>
                                            <div style={{ borderRadius: '10px', height: "15rem", padding: '0.5rem', background: '#f4f4f4', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={riskRatingChartUrl} style={{ display: 'inline-block', height: "100%", }} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ margin: "10px", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                1. There are total [{complianceOverviewSummary.totalCountries}] countries, [{complianceOverviewSummary.totalEntities}] active entities and [{complianceOverviewSummary.totalDepartments}] departments for which [{complianceOverviewSummary.totalLaws}] laws and [{complianceOverviewSummary.totalCompliance}] compliances have been mapped for [{complianceOverviewSummary.totalSystemUsers}] users in the Anushaasan application.
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                {risKRatingDelayedTotal > 0 ? (
                                                    <>
                                                        2. [{riskRatingSummary.delayed.critical}] of the delayed compliances have been marked as critical and [{riskRatingSummary.delayed.high}] high-risk compliances [{riskRatingSummary.delayed.moderate}] as moderate and [{riskRatingSummary.delayed.low}] low criticality compliances.
                                                    </>
                                                ) : (
                                                    <>2. No compliances has been delayed till now</>
                                                )}

                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                {risKRatingOverdueTotal > 0 ? (
                                                    <>
                                                        3. [{riskRatingSummary.overdue.critical}] of the overdue compliances have been marked as critical and [{riskRatingSummary.overdue.high}] high-risk compliances [{riskRatingSummary.overdue.moderate}] as moderate and [{riskRatingSummary.overdue.low}] low criticality compliances.
                                                    </>
                                                ) : (
                                                    <>3. No compliances has been overdue till now</>
                                                )}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="page-break"></div>
                        <table className='page-break-avoid' style={{ width: '100%', height: '100%', background: '#ffffff' }}>
                            <thead>
                                <tr style={{ padding: '0rem 3rem 1rem' }}>
                                    <th style={{ padding: '.5rem 1rem 0rem' }}>
                                        <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>Department and User performance analysis</h4>
                                        <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div style={{ width: 'calc(100% - 20px)', display: "inline-flex", flexDirection: "column", margin: "0 10px", marginBottom: '20px', float: 'left' }}>
                                            <h4 style={{ marginBottom: "0.5rem", marginTop: "0", fontSize: "0.8rem", lineHeight: "1.2", color: "#3c2261", background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(79, 45, 127, 0.19) 82%, rgba(79, 45, 127, 0.22) 100%)", borderLeft: "3px solid #3c2261", padding: "0.3rem 0.2rem 0.1rem", paddingLeft: "0.2rem" }}>Department wise analysis</h4>
                                            <div style={{ borderRadius: '10px', height: "30vh", padding: '0.5rem', background: '#f4f4f4', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={departmentChartUrl} style={{ width: "100%", height: '25vh' }} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>
                                        <div style={{ margin: "10px", marginTop: "10px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                1. Of the total [#] pending for reporting compliances [x department] has the highest no. of total pending for reporting compliances and [y department] has the highest no. of pending for reporting high criticality compliances.
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                2. [Mr. x] has the largest number of pending for reporting compliances in the system and [Mr. Y], [Mr. Z], etc. have reported non-compliances in the system.
                                            </p>
                                            <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                3. The following users have not logged into the system for the last 30 days and their compliance reporting / review status is as below
                                            </p>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>

                        <table style={{ width: '100%', height: '100%', background: '#ffffff' }}>
                            <tbody style={{ width: '100%', padding: '20px 0px 10', paddingTop: '0' }}>
                                <tr>
                                    <td>
                                        <div style={{ width: 'calc(50% - 20px)', display: "inline-flex", flexDirection: "column", margin: "0 10px", float: 'left' }}>
                                            <h4 style={{ marginBottom: "0.5rem", marginTop: "0", fontSize: "0.8rem", lineHeight: "1.2", color: "#3c2261", background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(79, 45, 127, 0.19) 82%, rgba(79, 45, 127, 0.22) 100%)", borderLeft: "3px solid #3c2261", padding: "0.3rem 0.2rem 0.1rem", paddingLeft: "0.2rem" }}>Top Performers</h4>
                                            <div style={{ borderRadius: '10px', minHeight: "8rem", padding: '0.5rem', background: '#f4f4f4' }}>
                                                <ul style={{ display: 'inline-flex', flexWrap: 'wrap', gap: '0.2rem', alignItems: 'flex-start', justifyContent: 'start', margin: '0', padding: '0' }}>
                                                    {sortedTopPerformUsers.map((data, index) => {
                                                        return this.renderListItem(data, index);
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div style={{ width: 'calc(50% - 20px)', display: "inline-flex", flexDirection: "column", margin: "0 10px", float: 'left' }}>
                                            <h4 style={{ marginBottom: "0.5rem", marginTop: "0", fontSize: "0.8rem", lineHeight: "1.2", color: "#3c2261", background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(79, 45, 127, 0.19) 82%, rgba(79, 45, 127, 0.22) 100%)", borderLeft: "3px solid #3c2261", padding: "0.3rem 0.2rem 0.1rem", paddingLeft: "0.2rem" }}>Scope of Improvement</h4>
                                            <div style={{ borderRadius: '10px', minHeight: "8rem", padding: '0.5rem', background: '#f4f4f4' }}>
                                                <ul style={{ display: 'inline-flex', flexWrap: 'wrap', gap: '0.2rem', alignItems: 'flex-start', justifyContent: 'start', margin: '0', padding: '0' }}>
                                                    {sortedScopeOfImproveUsers.map((data, index) => {
                                                        return this.renderListItem(data, index);
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {this.props.filters?.typeOfReport.includes('auditor') && <>
                            <div className="page-break"></div>
                            <table className='page-break-avoid' style={{ width: '100%', height: '100%', background: '#ffffff' }}>
                                <thead>
                                    <tr style={{ padding: '0rem 3rem 1rem' }}>
                                        <th style={{ padding: '.5rem 1rem 0rem' }}>
                                            <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>Auditor Wise Analysis</h4>
                                            <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div style={{ width: 'calc(100% - 20px)', display: "inline-flex", flexDirection: "column", margin: "0 10px", marginBottom: '20px', float: 'left' }}>
                                                <h4 style={{ marginBottom: "0.5rem", marginTop: "0", fontSize: "0.8rem", lineHeight: "1.2", color: "#3c2261", background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(79, 45, 127, 0.19) 82%, rgba(79, 45, 127, 0.22) 100%)", borderLeft: "3px solid #3c2261", padding: "0.3rem 0.2rem 0.1rem", paddingLeft: "0.2rem" }}>Risk Analysis of Compliances in Audit</h4>
                                                <div style={{ borderRadius: '10px', height: "30vh", padding: '0.5rem', background: '#f4f4f4', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <img src={riskAnalysisAuditChartUrl} style={{ width: "100%", height: '25vh' }} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </>}
                    </>
                )}


                {this.props.isTabular && (
                    <>
                        {/* Compliance Status of Users */}
                        {this.props.filters?.typeOfReport.includes('user') && <>      <div className="page-break"></div>
                            <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                                <thead>
                                    <tr>
                                        <th colSpan="8" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                            <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>Compliance Status of Users</h4>
                                            <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                        </th>
                                    </tr>
                                </thead>
                                <thead style={{ display: 'table-header-group' }}>
                                    <tr>
                                        <th colSpan="8" style={{ textAlign: 'center', border: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', backgroundColor: '#4F2D7F', color: '#ffffff', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}>Particulars</th>
                                    </tr>
                                    <tr>
                                        <th style={{ textAlign: 'center', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>User</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated </th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Department</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '3.5rem' }}>User Role</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '3.5rem' }}>Compliance Score</th>
                                    </tr>
                                </thead>
                                {this.props.entityWiseData?.map((entity, ekey) => {
                                    let lastIndex = this.props.entityWiseData?.length - 1;
                                    let isLastRecord = (lastIndex === ekey) ? true : false;
                                    let cityWiseData = Array.from(Object.values(entity.cityWiseCount));
                                    return (
                                        <>
                                            <tbody className='page-break-avoid'>
                                                <tr>
                                                    <td colSpan='8' style={{ backgroundColor: '#a9d4e4', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 25%)', lineHeight: '1' }}>
                                                        <div style={{ display: 'inline-flex', justifyContent: 'center', width: '100%', }}>
                                                            <span style={{ display: 'block', color: '#000000', fontSize: '0.6rem', fontWeight: '500', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}>Entity Name: {entity.entityName}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {cityWiseData.map((city, k) => {
                                                    let lastIndex1 = cityWiseData?.length - 1;
                                                    let isLastRecord1 = (lastIndex1 === k) ? true : false;
                                                    let userWiseData = Array.from(Object.values(city.userWiseData));
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td colSpan='8' style={{ backgroundColor: '#ECE9FA', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderTop: '1px solid rgb(79 45 127 / 25%)', borderBottom: '1px solid rgb(79 45 127 / 25%)', lineHeight: '1' }}>
                                                                    <div style={{ display: 'inline-flex', justifyContent: 'center', width: '100%', }}>
                                                                        <span style={{ display: 'block', color: '#000000', fontSize: '0.6rem', fontWeight: '500', verticalAlign: 'center', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}><img src={Location} width={'6'} style={{ marginTop: '-0.25rem' }} alt="location" />&nbsp;&nbsp;{city.cityName}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {userWiseData.map((user, u) => {
                                                                let lastIndex2 = userWiseData?.length - 1;
                                                                let isLastRecord2 = (lastIndex2 === u) ? true : false;
                                                                let borderBottom = '1px solid rgb(79 45 127 / 15%)';
                                                                if (isLastRecord === true && isLastRecord1 === true && isLastRecord2 === true) {
                                                                    borderBottom = '1px solid #4F2D7F';
                                                                }
                                                                let complianceScorePerUser = getComplianceScore(user.pending.length, user.completed.length);
                                                                return (
                                                                    <>
                                                                        <tr style={{ pageBreakInside: 'avoid' }}>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{user.userName}</td>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderRight: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{user.allocated.length}</td>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderRight: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{user.reported.length}</td>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderRight: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{user.pending.length}</td>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderRight: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{user.delayed.length}</td>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderRight: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{user.userDepartments.join(', ')}</td>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderRight: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{user.userRoles[0] || ""}</td>
                                                                            <td style={{ textAlign: 'center', borderBottom: borderBottom, borderRight: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{complianceScorePerUser}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </>
                                    )
                                })}
                            </table></>}

                        {/* I - Country wise analysis  */}
                        {this.props.filters?.typeOfReport.includes('country') && <>     <div className="page-break"></div>
                            <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                                <thead>
                                    <tr>
                                        <th colSpan="7" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                            <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}>Country wise analysis </h4>
                                            <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                        </th>
                                    </tr>
                                </thead>
                                <thead style={{ display: 'table-header-group' }}>
                                    <tr>
                                        <th colSpan="7" style={{ textAlign: 'center', border: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', backgroundColor: '#4F2D7F', color: '#ffffff', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}>Particulars</th>
                                    </tr>
                                    <tr>
                                        <th style={{ textAlign: 'left', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>State</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                        <th style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '3.5rem' }}>Compliance Score</th>
                                    </tr>
                                </thead>
                                {this.props.countryWiseData?.map((country, ckey) => {
                                    let complianceScorePerCountry = getComplianceScore(country.pending.length, country.completed.length);
                                    countryWiseSummaryData.totalMappedCompliances += country.allocated.length;
                                    countryWiseSummaryData.totalReportedCompliances += country.reported.length;
                                    countryWiseSummaryData.totalPendingCompliances += country.pending.length;
                                    countryWiseSummaryData.totalDelayedCompliances += country.delayed.length;
                                    countryWiseSummaryData.totalOverdueCompliances += country.overdue.length;

                                    countryWiseSummaryData.riskWiseOverdueCompliances.critical += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                    countryWiseSummaryData.riskWiseOverdueCompliances.high += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                    countryWiseSummaryData.riskWiseOverdueCompliances.moderate += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                    countryWiseSummaryData.riskWiseOverdueCompliances.low += country.overdue.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                    countryWiseSummaryData.riskWiseDelayedCompliances.critical += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                    countryWiseSummaryData.riskWiseDelayedCompliances.high += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                    countryWiseSummaryData.riskWiseDelayedCompliances.moderate += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                    countryWiseSummaryData.riskWiseDelayedCompliances.low += country.delayed.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                    let statewiseDate = Array.from(Object.values(country.statewiseCount));

                                    return (
                                        <>
                                            <tbody className='page-break-avoid'>
                                                <tr>
                                                    <td colSpan='7' style={{ backgroundColor: '#ECE9FA', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 25%)', lineHeight: '1' }}>
                                                        <div style={{ display: 'inline-flex', justifyContent: 'center', width: '100%', }}>
                                                            <span style={{ display: 'block', color: '#000000', fontSize: '0.6rem', fontWeight: '500', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}><img src={Location} width={'6'} style={{ marginTop: '-0.25rem' }} alt="location" />&nbsp;&nbsp;{country.countryName}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {statewiseDate.map((state) => {
                                                    let complianceScorePerState = getComplianceScore(state.pending, state.completed);
                                                    return (
                                                        <tr style={{ pageBreakInside: 'avoid', }}>
                                                            <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.stateName}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.allocated}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.reported}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.pending}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.delayed}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{state.overdue}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{complianceScorePerState}</td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>Total</td>
                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.allocated.length}</td>
                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.reported.length}</td>
                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.pending.length}</td>
                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.delayed.length}</td>
                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{country.overdue.length}</td>
                                                    <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{complianceScorePerCountry}</td>
                                                </tr>
                                            </tbody>
                                        </>
                                    )
                                })}
                                <tfoot>
                                    <tr>
                                        <td colSpan="7">
                                            <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    1. Total [{countryWiseSummaryData.totalCountries}] country compliances have been mapped in the Anushaasan. Overall for these countries [{countryWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{countryWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{countryWiseSummaryData.totalReportedCompliances}] reported compliances, [{countryWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    {countryWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                        <>
                                                            2. [{countryWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and [{countryWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{countryWiseSummaryData.riskWiseDelayedCompliances.moderate}] as moderate and [{countryWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality compliances.
                                                        </>
                                                    ) : (
                                                        <>2. No compliances has been delayed till now</>
                                                    )
                                                    }
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                    {countryWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                        <>
                                                            3. [{countryWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and [{countryWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{countryWiseSummaryData.riskWiseOverdueCompliances.moderate}] as moderate and [{countryWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality compliances.
                                                        </>
                                                    ) : (
                                                        <>3. No compliances has been overdue till now</>
                                                    )
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table></>}

                        {/* II - Regulation wise analysis */}
                        {this.props.filters?.typeOfReport.includes('law') && <>   <div className="page-break"></div>
                            <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                                <thead>
                                    <tr>
                                        <th colSpan="6" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                            <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Regulation wise analysis</h4>
                                            <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                        </th>
                                    </tr>
                                </thead>
                                <thead style={{ display: 'table-header-group' }}>
                                    <tr>
                                        <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>State</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                    </tr>
                                </thead>
                                {this.props.lawWiseData?.map((country, lkey) => {
                                    let lawWiseData = Array.from(Object.values(country.lawWiseData));
                                    return (
                                        <>
                                            <tbody className='page-break-avoid'>
                                                <tr>
                                                    <th colSpan="6" style={{ textAlign: 'center', border: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', backgroundColor: '#4F2D7F', color: '#ffffff', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}><img src={LocationLight} width={'6'} style={{ marginTop: '-0.25rem' }} alt="location" />&nbsp;&nbsp; {country.countryName}</th>
                                                </tr>
                                                {
                                                    lawWiseData.map((law, key) => {
                                                        lawWiseSummaryData.totalMappedCompliances += law.allocated.length;
                                                        lawWiseSummaryData.totalReportedCompliances += law.reported.length;
                                                        lawWiseSummaryData.totalPendingCompliances += law.pending.length;
                                                        lawWiseSummaryData.totalDelayedCompliances += law.delayed.length;
                                                        lawWiseSummaryData.totalOverdueCompliances += law.overdue.length;

                                                        lawWiseSummaryData.riskWiseOverdueCompliances.critical += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                        lawWiseSummaryData.riskWiseOverdueCompliances.high += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                        lawWiseSummaryData.riskWiseOverdueCompliances.moderate += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                        lawWiseSummaryData.riskWiseOverdueCompliances.low += law.overdue.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                                        lawWiseSummaryData.riskWiseDelayedCompliances.critical += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                        lawWiseSummaryData.riskWiseDelayedCompliances.high += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                        lawWiseSummaryData.riskWiseDelayedCompliances.moderate += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                        lawWiseSummaryData.riskWiseDelayedCompliances.low += law.delayed.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td colSpan='6' style={{ backgroundColor: '#ECE9FA', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                                        <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                                            <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Law Name: </strong>{law.lawShortName}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ pageBreakInside: 'avoid' }}>
                                                                    <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.stateName ? law.stateName : (law.stateId === -1 ? "All" : NA)}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.allocated.length}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.reported.length}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.pending.length}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.delayed.length}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{law.overdue.length}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </>
                                    )
                                })}
                                <tfoot>
                                    <tr>
                                        <td colSpan="6">
                                            <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    1. Total [{lawWiseSummaryData.totalLaws}] regulations have been mapped in the Anushaasan. Overall for these regulations [{lawWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{lawWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{lawWiseSummaryData.totalReportedCompliances}] reported compliances, [{lawWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    {lawWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                        <>
                                                            2. [{lawWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and [{lawWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{lawWiseSummaryData.riskWiseDelayedCompliances.moderate}] as medium and [{lawWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality.
                                                        </>
                                                    )
                                                        : (
                                                            <>2. No compliances has been delayed till now</>
                                                        )}
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                    {lawWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                        <>
                                                            3. [{lawWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and [{lawWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{lawWiseSummaryData.riskWiseOverdueCompliances.moderate}] as medium and [{lawWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality.
                                                        </>
                                                    )
                                                        : (
                                                            <>3. No compliances has been overdue till now</>
                                                        )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table></>}

                        {/* III - Department wise analysis */}
                        {this.props.filters?.typeOfReport.includes('department') && <>  <div className="page-break"></div>
                            <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                                <thead>
                                    <tr>
                                        <th colSpan="6" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                            <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Department wise analysis</h4>
                                            <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                        </th>
                                    </tr>
                                </thead>
                                <thead style={{ display: 'table-header-group' }}>
                                    <tr>
                                        <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                    </tr>
                                </thead>

                                {departmentWiseData?.map((department, edkey) => {
                                    let lastIndex = departmentWiseData?.length - 1;
                                    let isLastRecord = (lastIndex === edkey) ? true : false;
                                    let hodUserList = department.hods.map((h) => h.userName).join();
                                    return (
                                        <>
                                            <tbody className='page-break-avoid'>
                                                <tr>
                                                    <td colSpan='6' style={{ backgroundColor: '#ECE9FA', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                        <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', lineHeight: '1.2' }}>
                                                            <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Name: </strong>{department.entityName}</span>
                                                            <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>HoD(s): </strong>{hodUserList}</span>
                                                        </div>
                                                        <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                            <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Department Name: </strong>{department.departmentName}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    department.cityWiseCount.map((city, k) => {
                                                        let lastIndex1 = department.cityWiseCount?.length - 1;
                                                        let isLastRecord1 = (lastIndex1 === k) ? true : false;
                                                        let borderBottom = '1px solid rgb(79 45 127 / 15%)';
                                                        if (isLastRecord === true && isLastRecord1 === true) {
                                                            borderBottom = '1px solid #4F2D7F';
                                                        }
                                                        departmentWiseSummaryData.totalMappedCompliances += city.allocated;
                                                        departmentWiseSummaryData.totalReportedCompliances += city.reported;
                                                        departmentWiseSummaryData.totalPendingCompliances += city.pending;
                                                        departmentWiseSummaryData.totalDelayedCompliances += city.delayed;
                                                        departmentWiseSummaryData.totalOverdueCompliances += city.overdue;

                                                        departmentWiseSummaryData.riskWiseOverdueCompliances.critical += city.overdueCritical;
                                                        departmentWiseSummaryData.riskWiseOverdueCompliances.high += city.overdueHigh;
                                                        departmentWiseSummaryData.riskWiseOverdueCompliances.moderate += city.overdueModerate;
                                                        departmentWiseSummaryData.riskWiseOverdueCompliances.low += city.overdueLow;

                                                        departmentWiseSummaryData.riskWiseDelayedCompliances.critical += city.delayedCritical;
                                                        departmentWiseSummaryData.riskWiseDelayedCompliances.high += city.delayedHigh;
                                                        departmentWiseSummaryData.riskWiseDelayedCompliances.moderate += city.delayedModerate;
                                                        departmentWiseSummaryData.riskWiseDelayedCompliances.low += city.delayedLow;
                                                        return (
                                                            <tr style={{ pageBreakInside: 'avoid' }}>
                                                                <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.cityName}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.allocated}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.reported}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.pending}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.delayed}</td>
                                                                <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.overdue}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </>
                                    )
                                }

                                )}
                                <tfoot>
                                    <tr>
                                        <td colSpan="6">
                                            <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    1. Total [{departmentWiseSummaryData.totalDepartments}] departments have been mapped in the Anushaasan. Overall for these departments [{departmentWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{departmentWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{departmentWiseSummaryData.totalReportedCompliances}] reported compliances, [{departmentWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    {
                                                        departmentWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                            <>
                                                                2. [{departmentWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and [{departmentWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{departmentWiseSummaryData.riskWiseDelayedCompliances.moderate}] as medium and [{departmentWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality.
                                                            </>
                                                        ) : (
                                                            <>2. No compliances has been delayed till now.</>
                                                        )
                                                    }
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                    {
                                                        departmentWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                            <>
                                                                3. [{departmentWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and [{departmentWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{departmentWiseSummaryData.riskWiseOverdueCompliances.moderate}] as medium and [{departmentWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality.
                                                            </>
                                                        ) : (
                                                            <>3. No compliances has been overdue till now</>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table></>}

                        {/* IV - Entity wise analysis */}
                        {this.props.filters?.typeOfReport.includes('entity') && <>     <div className="page-break"></div>
                            <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                                <thead>
                                    <tr>
                                        <th colSpan="6" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                            <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Entity wise analysis</h4>
                                            <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                        </th>
                                    </tr>
                                </thead>
                                <thead style={{ display: 'table-header-group' }}>
                                    <tr>
                                        <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocated</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Reported </th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending for Reporting</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Delayed</th>
                                        <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Overdue</th>
                                    </tr>
                                </thead>
                                {this.props.entityWiseData?.map((entity, ekey) => {
                                    let lastIndex = this.props.entityWiseData?.length - 1;
                                    let isLastRecord = (lastIndex === ekey) ? true : false;
                                    let cityWiseCount = Array.from(Object.values(entity.cityWiseCount));
                                    let entityAdminUserList = this.props.entityAdmins.filter((e) => e.entityId == entity.entityId)?.map((eUser) => eUser.user.userName)
                                    return (
                                        <tbody className='page-break-avoid'>
                                            <React.Fragment key={`entity_${ekey}`}>
                                                <tr>
                                                    <th colSpan="6" style={{ textAlign: 'center', border: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid rgb(79 45 127 / 15%)', backgroundColor: '#4F2D7F', color: '#ffffff', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1' }}>{entity.entityName}</th>
                                                </tr>
                                                <tr>
                                                    <td colSpan='6' style={{ backgroundColor: '#ECE9FA', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                        <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                            <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Admin : </strong>{entityAdminUserList.join(',')}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {cityWiseCount.map((data, k) => {
                                                    let lastIndex1 = cityWiseCount?.length - 1;
                                                    let isLastRecord1 = (lastIndex1 === k) ? true : false;
                                                    let borderBottom = '1px solid rgb(79 45 127 / 15%)';
                                                    if (isLastRecord === true && isLastRecord1 === true) {
                                                        borderBottom = '1px solid #4F2D7F';
                                                    }
                                                    entityWiseSummaryData.totalMappedCompliances += data.allocated.length;
                                                    entityWiseSummaryData.totalReportedCompliances += data.reported.length;
                                                    entityWiseSummaryData.totalPendingCompliances += data.pending.length;
                                                    entityWiseSummaryData.totalDelayedCompliances += data.delayed.length;
                                                    entityWiseSummaryData.totalOverdueCompliances += data.overdue.length;

                                                    entityWiseSummaryData.riskWiseOverdueCompliances.critical += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                    entityWiseSummaryData.riskWiseOverdueCompliances.high += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                    entityWiseSummaryData.riskWiseOverdueCompliances.moderate += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                    entityWiseSummaryData.riskWiseOverdueCompliances.low += data.overdue.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                                    entityWiseSummaryData.riskWiseDelayedCompliances.critical += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_CRITICAL).length;
                                                    entityWiseSummaryData.riskWiseDelayedCompliances.high += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_HIGH).length;
                                                    entityWiseSummaryData.riskWiseDelayedCompliances.moderate += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_MODERATE).length;
                                                    entityWiseSummaryData.riskWiseDelayedCompliances.low += data.delayed.filter((c) => c.riskRatingId == RISK_RATING_LOW).length;

                                                    return <>
                                                        <tr style={{ pageBreakInside: 'avoid' }}>
                                                            <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.cityName}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.allocated.length}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.reported.length}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.pending.length}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.delayed.length}</td>
                                                            <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{data.overdue.length}</td>
                                                        </tr>
                                                    </>
                                                })}
                                            </React.Fragment>
                                        </tbody>
                                    );
                                })
                                }
                                <tfoot>
                                    <tr>
                                        <td colSpan="6">
                                            <div style={{ margin: "10px 0", marginTop: "20px", backgroundColor: '#eee7f7', float: 'left', padding: '0.9rem', borderRadius: '0.4rem' }}>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    1. Total [{entityWiseSummaryData.totalEntities}] entities have been mapped in the Anushaasan. Overall for these regulations [{entityWiseSummaryData.totalMappedCompliances}] compliances have been mapped in the system of which [{entityWiseSummaryData.totalPendingCompliances}] remain pending for reporting and of the [{entityWiseSummaryData.totalReportedCompliances}] reported compliances, [{entityWiseSummaryData.totalDelayedCompliances}] have been delayed (compliance performed beyond the due date).
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0.5rem' }}>
                                                    {entityWiseSummaryData.totalDelayedCompliances > 0 ? (
                                                        <>
                                                            2. [{entityWiseSummaryData.riskWiseDelayedCompliances.critical}] of the delayed compliances have been marked as critical and [{entityWiseSummaryData.riskWiseDelayedCompliances.high}] high-risk compliances [{entityWiseSummaryData.riskWiseDelayedCompliances.moderate}] as medium and [{entityWiseSummaryData.riskWiseDelayedCompliances.low}] low criticality.
                                                        </>
                                                    ) : (
                                                        <>2. No compliances has been delayed till now</>
                                                    )}
                                                </p>
                                                <p style={{ color: '#2A3142', fontSize: '0.7rem', lineHeight: '1.3', marginBottom: '0' }}>
                                                    {entityWiseSummaryData.totalOverdueCompliances > 0 ? (
                                                        <>
                                                            3. [{entityWiseSummaryData.riskWiseOverdueCompliances.critical}] of the overdue compliances have been marked as critical and [{entityWiseSummaryData.riskWiseOverdueCompliances.high}] high-risk compliances [{entityWiseSummaryData.riskWiseOverdueCompliances.moderate}] as medium and [{entityWiseSummaryData.riskWiseOverdueCompliances.low}] low criticality.
                                                        </>
                                                    ) : (
                                                        <>3. No compliances has been overdue till now</>
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </>
                        }
                        {/* V - Auditor wise analysis */}
                        {this.props.filters?.typeOfReport.includes('auditor') &&
                            <>
                                <div className="page-break"></div>
                                <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan="7" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                                <h4 style={{ width: '100%', color: '#2a3142', fontSize: '18px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Auditor wise analysis</h4>
                                                <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th colSpan="7" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                                <h4 style={{ width: '100%', color: '#2a3142', fontSize: '14px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Master Audit Analysis</h4>
                                                <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead style={{ display: 'table-header-group' }}>
                                        <tr>
                                            <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Allocation to
                                                Auditor</th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Audited & Closed </th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Pending Action on Observation</th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Pending with
                                                Auditor</th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Discrepancy</th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Discrepancy Resolution</th>
                                        </tr>
                                    </thead>

                                    {auditorWiseData.map((department, edkey) => {
                                        let hodUserList = department.hods.map((h) => h.userName).join();
                                        let lastIndex = auditorWiseData?.length - 1;
                                        let isLastRecord = (lastIndex === edkey) ? true : false;
                                        return (
                                            <>
                                                <tbody className='page-break-avoid'>
                                                    <tr>
                                                        <td colSpan='7' style={{ backgroundColor: '#ECE9FA', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                            <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', lineHeight: '1.2' }}>
                                                                <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Name: </strong>{department.entityName}</span>
                                                                {/* <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Country: </strong>-</span> */}
                                                            </div>
                                                            <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                                <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Department Name: </strong>{department.departmentName}</span>
                                                                <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>HoD(s): </strong>{hodUserList}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        department.cityWiseCount.map((city, k) => {
                                                            let lastIndex1 = department.cityWiseCount?.length - 1;
                                                            let isLastRecord1 = (lastIndex1 === k) ? true : false;
                                                            let borderBottom = '1px solid rgb(79 45 127 / 15%)';
                                                            if (isLastRecord === true && isLastRecord1 === true) {
                                                                borderBottom = '1px solid #4F2D7F';
                                                            }
                                                            // departmentWiseSummaryData.totalAllocatedCompliances += city.auditAllocated;
                                                            // departmentWiseSummaryData.totalReportedCompliances += city.reported;
                                                            // departmentWiseSummaryData.totalPendingCompliances += city.pending;
                                                            // departmentWiseSummaryData.totalDelayedCompliances += city.delayed;
                                                            // departmentWiseSummaryData.totalOverdueCompliances += city.overdue;
                                                            return (
                                                                <tr style={{ pageBreakInside: 'avoid' }}>
                                                                    <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.cityName}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.auditAllocated}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.audited}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.pendingObservation}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.pendingAudit}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancy}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancyResolution}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </>
                                        );
                                    }
                                    )}

                                </table>

                                {/* Impact Discrepancy Audit Analysis */}
                                <div className="page-break"></div>
                                <table style={{ width: 'calc(100% - 2rem)', margin: '0 1rem' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan="3" style={{ padding: '0.5rem 0rem 1rem', paddingBottom: '0.6rem' }}>
                                                <h4 style={{ width: '100%', color: '#2a3142', fontSize: '14px', fontWeight: 'bold', fontFamily: 'arial', letterSpacing: '1px', display: 'inline-block', marginBottom: '0', marginTop: '0' }}> Impact of Discrepancy in Compliance</h4>
                                                <hr style={{ margin: '5px 0', width: '100%', display: 'inline-block' }} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead style={{ display: 'table-header-group' }}>
                                        <tr>
                                            <th style={{ textAlign: 'left', borderTop: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '8rem' }}>Location</th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11' }}>Monetary Impact</th>
                                            <th style={{ textAlign: 'center', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderBottom: '1px solid #4F2D7F', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', backgroundColor: '#F7CAAC', color: '#C55A11', width: '4rem' }}>Non-monetary Impact</th>
                                        </tr>
                                    </thead>

                                    {auditorWiseDataDiscrepancy.map((department, edkey) => {
                                        let hodUserList = department.hods.map((h) => h.userName).join();
                                        let lastIndex = auditorWiseData?.length - 1;
                                        let isLastRecord = (lastIndex === edkey) ? true : false;
                                        return (
                                            <>
                                                <tbody className='page-break-avoid'>
                                                    <tr>
                                                        <td colSpan='3' style={{ backgroundColor: '#ECE9FA', borderTop: '1px solid #4F2D7F', borderRight: '1px solid #4F2D7F', borderLeft: '1px solid #4F2D7F', lineHeight: '1' }}>
                                                            <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', lineHeight: '1.2' }}>
                                                                <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Entity Name: </strong>{department.entityName}</span>
                                                                {/* <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Country: </strong>-</span> */}
                                                            </div>
                                                            <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%', }}>
                                                                <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>Department Name: </strong>{department.departmentName}</span>
                                                                <span style={{ display: 'block', color: '#717171', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.2rem', paddingBottom: '0', lineHeight: '0.9' }}><strong style={{ color: '#000', fontWeight: '600' }}>HoD(s): </strong>{hodUserList}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        department.cityWiseCount.map((city, k) => {
                                                            let lastIndex1 = department.cityWiseCount?.length - 1;
                                                            let isLastRecord1 = (lastIndex1 === k) ? true : false;
                                                            let borderBottom = '1px solid rgb(79 45 127 / 15%)';
                                                            if (isLastRecord === true && isLastRecord1 === true) {
                                                                borderBottom = '1px solid #4F2D7F';
                                                            }
                                                            return (
                                                                <tr style={{ pageBreakInside: 'avoid' }}>
                                                                    <td style={{ textAlign: 'left', borderRight: '1px solid rgb(79 45 127 / 15%)', borderLeft: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.cityName}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid rgb(79 45 127 / 15%)', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancyMonetary}</td>
                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid #4F2D7F', borderBottom: borderBottom, height: '1rem', fontSize: '0.6rem', wordBreak: 'break-word', padding: '0.3rem', lineHeight: '1', color: '#717171' }}>{city.discrepancyNonMonetary}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </>
                                        );
                                    }
                                    )}

                                </table>
                            </>
                        }
                    </>
                )}
            </React.Fragment>
        );
    }
}

export default HtmlReport;