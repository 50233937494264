import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.companyGroup || initialState;
const makeCompanyGroupSelectField = field =>
    createSelector(reducerState, companyGroupState => companyGroupState[field]);
const selectSortedCompanyGroupList = () =>
    createSelector(makeCompanyGroupSelectField('companyGroupList'), companyGroupList =>
        companyGroupList.map(opt => ({
            value: opt.id,
            label: opt.companyGroupName,
            isDbCreated: (opt?.tenant) ? opt?.tenant?.isDbCreated : false,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeCompanyGroupSelectField,
    selectSortedCompanyGroupList
};
