import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Topbar from './Topbar';
import Footer from './Footer';
import { makeLayoutSelectField } from 'store/layout/selector';
import { createStructuredSelector } from 'reselect';
import { Spinner } from 'reactstrap';
import { createLoadingSelector } from 'store/Shared/selector';
import TopMenuBar from './TopMenuBar';
import { checkRoutePath, getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';

// render if Auth Layout
const AuthLayoutContent = (props) => {
  document.body.classList.remove('bg-primary');
  // check the URL path name is available in the menu list array
  // else redirect to dashboard with error message

  let layoutDetails;
  switch (checkRoutePath(props.location.pathname, props.routePath)) {
    // load component details while proper route path found
    case true:
      layoutDetails = (
        <div id="wrapper">
          <Topbar isSideBar={false} projectLayout={props.projectLayout} fundLayout={props.fundLayout} />
          {props.projectLayout == 'false' && props.fundLayout == 'false' && (
            <TopMenuBar />
          )}
          <div className="content-page">
            <div className={props.routePath === "/legal-watch-dog/view-details/:newsId" ? "content news-content" : "content"}>
              {props.children}
            </div>
            <Footer />
          </div>
        </div>
      );
      break;

    // redirect to the dashboard screen with error message while proper route path not found
    case false:
      layoutDetails = (
        <Redirect
          to={{
            pathname: '/home',
            state: {
              wrongPath: true,
            },
          }}
        />
      );
      break;

    // redirect to the logout screen in any other cases
    default:
      layoutDetails = <Redirect to="/logout" />;
      break;
  }

  return <React.Fragment>{layoutDetails}</React.Fragment>;
}

// render if Non-Auth Layout
const NonAuthLayoutContent = (props) => {
  return <React.Fragment>
    {props.children}
  </React.Fragment>
}

class Layout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectLayout: getValueByKey(authConstants.PROJECT_LAYOUT),
      fundLayout: getValueByKey(authConstants.FUND_LAYOUT)
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loadingSelector && (
          <div className="loader">
            <Spinner color="black" />
          </div>
        )}
        {this.props.layoutType === 'Auth' ? <AuthLayoutContent fundLayout={this.state.fundLayout} projectLayout={this.state.projectLayout} {...this.props} /> : <NonAuthLayoutContent {...this.props} />}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = createStructuredSelector({
  layoutType: makeLayoutSelectField('layoutType'),
  loadingSelector: createLoadingSelector(),
});

export default withRouter(connect(mapStatetoProps)(Layout));