import React, { Component } from 'react';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { initialTaskState } from './CommonFunctions';
import { customDateValidator, formatDateForApi } from 'helpers/dateTimeHelper';
import 'react-datepicker/dist/react-datepicker.css';
import {
    addProjectSubTaskRequest,
    updateProjectTaskRequest,
} from 'store/actions';
import { toastrMessage } from 'helpers/messageHelper';
import { makeProjectsSelectField } from 'store/Masters/Projects/selector';

class SubTaskForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });

        this.state = {
            taskFormData: initialTaskState(),
            // formData: {
            //     taskTitle: '',
            //     taskDescription: '',
            //     startDate: '',
            //     dueDate: '',
            //     entityId: '',
            //     userId: '',
            //     projectStatusId: '1',
            //     priority: '',
            // }
        };
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            let formValues = {
                ...values,
                startDate: formatDateForApi(values.startDate),
                dueDate: formatDateForApi(values.dueDate),
            };

            this.props.addProjectSubTaskRequest(this.props.taskId, formValues);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.projectTaskApiResponse &&
            this.props.projectTaskApiResponse !==
            nextprops.projectTaskApiResponse
        ) {
            toastrMessage(
                nextprops.projectTaskApiResponse.message,
                nextprops.projectTaskApiResponse.responseType
            );
            this.onModalDismiss();
        }
    }
    onModalDismiss = () => {
        this.validator.hideMessages();
        this.props.onModalDismiss();
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Formik
                            initialValues={this.props.subTaskFormData}
                            enableReinitialize={true}
                            onSubmit={this.formSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>

                                    <Row>
                                        <FormGroup className="col-lg-12 add_new_subtask">
                                            <div className='d-flex flex-column w-100'>
                                                <Field
                                                    name="taskTitle"
                                                    className="form-control"
                                                    value={values.taskTitle}

                                                    placeholder={__t(
                                                        'form_labels.project_task.title'
                                                    )}
                                                />

                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.project_task.title'
                                                    ),
                                                    values.taskTitle,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.project_task.title_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </div>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="ml-2 mr-2"

                                            >
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 2L7.5 13" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"></path><path d="M13 7.5L2 7.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round"></path></svg>
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={() =>
                                                    this.onModalDismiss()
                                                }
                                            >
                                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.02602 6.49908L12.6799 1.8554C12.8837 1.65158 12.9982 1.37513 12.9982 1.08687C12.9982 0.798614 12.8837 0.522165 12.6799 0.318337C12.4761 0.114509 12.1997 0 11.9114 0C11.6232 0 11.3468 0.114509 11.143 0.318337L6.5 4.97283L1.85699 0.318337C1.65319 0.114509 1.37678 -2.14767e-09 1.08856 0C0.800348 2.14767e-09 0.523938 0.114509 0.320139 0.318337C0.11634 0.522165 0.00184703 0.798614 0.00184703 1.08687C0.00184703 1.37513 0.11634 1.65158 0.320139 1.8554L4.97397 6.49908L0.320139 11.1427C0.218698 11.2434 0.138182 11.3631 0.0832357 11.495C0.0282893 11.6269 0 11.7684 0 11.9113C0 12.0542 0.0282893 12.1957 0.0832357 12.3276C0.138182 12.4595 0.218698 12.5792 0.320139 12.6798C0.420751 12.7813 0.540454 12.8618 0.67234 12.9168C0.804227 12.9717 0.945688 13 1.08856 13C1.23144 13 1.3729 12.9717 1.50479 12.9168C1.63667 12.8618 1.75637 12.7813 1.85699 12.6798L6.5 8.02532L11.143 12.6798C11.2436 12.7813 11.3633 12.8618 11.4952 12.9168C11.6271 12.9717 11.7686 13 11.9114 13C12.0543 13 12.1958 12.9717 12.3277 12.9168C12.4595 12.8618 12.5792 12.7813 12.6799 12.6798C12.7813 12.5792 12.8618 12.4595 12.9168 12.3276C12.9717 12.1957 13 12.0542 13 11.9113C13 11.7684 12.9717 11.6269 12.9168 11.495C12.8618 11.3631 12.7813 11.2434 12.6799 11.1427L8.02602 6.49908Z" fill="currentColor" />
                                                </svg>
                                            </Button>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    projectTaskApiResponse: makeProjectsSelectField('projectTaskApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    addProjectSubTaskRequest,
    updateProjectTaskRequest,
});

export default compose(withConnect, withRouter)(SubTaskForm);
