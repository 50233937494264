import { isDarkMode } from "helpers/generalUtils";
import { parseIntHelper } from "helpers/mathHelper";

export const initialReportFilterDataForGraphState = () => {
    return {
        roleId: [],
        countryId: [],
        stateId: [],
        departmentId: [],
        entityId: [],
        lawId: [],
        taskStatusId: [],
        complianceCategoryId: [],
        complianceRiskRatingId: [],
        fromDate: '',
        toDate: '',
    };
};

export const initialReportFilterDataForProState = () => {
    return {
        typeOfReport: ['department', 'user', 'country', 'entity', 'law', 'auditor'],
        roleId: [],
        userId: [],
        countryId: [],
        stateId: [],
        cityId: [],
        departmentId: [],
        entityId: [],
        lawId: [],
        taskStatusId: [],
        complianceTypeId: [],
        complianceCategoryId: [],
        complianceRiskRatingId: [],
        fromDate: '',
        toDate: '',
    };
};
export const initialReportFilterDataState = () => {
    return {
        typeOfReport: [],
        roleId: [],
        userId: [],
        countryId: [],
        stateId: [],
        cityId: [],
        departmentId: [],
        entityId: [],
        lawId: [],
        taskStatusId: [],
        complianceTypeId: [],
        complianceCategoryId: [],
        complianceRiskRatingId: [],
        fromDate: '',
        toDate: '',
    };
};
export const initialRunTimeFilterDataState = () => {
    return {
        countryId: [],
        departmentId: [],
        entityId: [],
        userId: [],
        fromDate: '',
        toDate: '',
    };
};

export const complianceStatusChartOptions = (dataLabels, titleForY) => {
    return {
        chart: {
            type: 'bar',
            height: '300px',
            stacked: true,
            fontFamily: 'GT Walsheim Pro',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                },
            },
            toolbar: {
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: 'Compliance Status',
                        columnDelimiter: ',',
                        headerCategory: titleForY,
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    svg: {
                        filename: 'Compliance Status'
                    },
                    png: {
                        filename: 'Compliance Status'
                    }
                },
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 5,
                borderRadiusApplication: 'around',
                borderRadiusWhenStacked: 'last',
                barHeight: '45%',
                dataLabels: {
                    // position: 'left'
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '11px',
                            fontWeight: 400,
                            color: isDarkMode() ? '#fff' : "#000",

                        }
                    },
                },
            },
        },
        stroke: {
            width: 1,
            colors: isDarkMode() ? ['#fff'] : ['#fff']
        },
        xaxis: {
            categories: dataLabels,
            min: 0,
            forceNiceScale: true,
            decimalsInFloat: 0,
            axisBorder: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                formatter: function (val) {
                    return parseIntHelper(val)
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            total: {
                enabled: true,
                offsetX: 0,
                style: {
                    fontSize: '11px',
                    fontWeight: 400
                }
            },
        },
        yaxis: {
            axisBorder: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            title: {
                text: titleForY,
                style: {
                    color: isDarkMode() ? '#dee2e6' : '#000',
                },
            },
            style: {
                cssClass: 'break-label'
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
            total: {
                enabled: true,
                offsetY: 0,
                style: {
                    fontSize: '11px',
                    fontWeight: 400
                }
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                show: 'false',
                formatter: function (val) {
                    return val
                }
            }
        },
        fill: {
            opacity: 1
        },
        grid: {
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        colors: isDarkMode() ? ['#5bbc7b', '#81192f', '#f6732f', '#26FF39'] : ['#9BD732', '#DE002E', '#f6732f', '#f8b425'],
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            fontSize: '14px',
            fontWeight: 500,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            markers: {
                radius: 12,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 5
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        }
    };
}
export const listColumns1 = [
    {
        label: 'Entity Name',
        field: 'entityName',
        sort: 'disabled',
    },
    {
        label: 'Country',
        field: 'country',
        sort: 'disabled',
    },
    {
        label: 'State',
        field: 'state',
        sort: 'disabled',
    },
    {
        label: 'Department',
        field: 'department',
        sort: 'disabled',
    },
    {
        label: 'Short Law Name',
        field: 'lawShortName',
        sort: 'disabled',
    },
    {
        label: 'Compliance Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
        sort: 'disabled',
    },
    {
        label: 'Compliance Class',
        field: 'complianceType',
        sort: 'disabled',
    },
    {
        label: 'System Live Date',
        field: 'systemLiveDate',
        sort: 'disabled',
    },
    {
        label: 'Compliance Due Date',
        field: 'dueDate',
        sort: 'disabled',
    },
    {
        label: 'Actual Compliance Performance Date',
        field: 'compliancePerformanceDate',
        sort: 'disabled',
    },
    {
        label: 'System Reporting Date',
        field: 'systemReportingDate',
        sort: 'disabled',
    },
    {
        label: 'Delay in System Reporting',
        field: 'noDelayDays',
        sort: 'disabled',
    },
    {
        label: 'Status',
        field: 'statusName',
        sort: 'disabled',
    },
    {
        label: 'User Role',
        field: 'roleName',
        sort: 'disabled',
    }
];

export const listColumns2 = [
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'Entity Name',
        field: 'entityName',
    },
    {
        label: 'Department Name',
        field: 'departmentName',
    },
    {
        label: 'User Name',
        field: 'userName'
    },
    {
        label: 'User Role',
        field: 'userRole'
    },
    {
        label: 'User Email ID',
        field: 'userEmail'
    },
    {
        label: 'User Contact No.',
        field: 'userContactNo'
    },
    {
        label: 'Last Login Date',
        field: 'lastLoginDate'
    },
    {
        label: 'Last Login Duration',
        field: 'totalLoginTime'
    },
    {
        label: 'Compliance Score %',
        field: 'complianceScore'
    }
]
