import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADD_INDUSTRY_REQUEST, DELETE_INDUSTRY_BY_INDUSTRY_ID_REQUEST, GET_ALL_INDUSTRY_LIST_REQUEST, GET_INDUSTRY_BY_INDUSTRY_ID_REQUEST, GET_INDUSTRY_LIST_BY_STATUS_REQUEST, UPDATE_INDUSTRY_REQUEST, UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_REQUEST } from './actionTypes';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import { addIndustryResponse, deleteIndustryByIndustryIdResponse, getAllIndustryListRequest, getAllIndustryListResponse, getIndustryByIndustryIdResponse, getIndustryListByStatusResponse, updateIndustryResponse, updateIndustryStatusByIndustryIdResponse } from './actions';
import { INDUSTRY, INDUSTRY_BY_STATUS } from 'helpers/apiConstants';
function* addIndustry({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', INDUSTRY, data);
        if (response.status) {
            yield put(getAllIndustryListRequest());
            yield put(addIndustryResponse(response.message, SUCCESS));
        } else {
            yield put(addIndustryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllIndustryList() {
    try {
        const response = yield call(invokeApi, 'get', INDUSTRY, '');
        if (response.status) {
            yield put(getAllIndustryListResponse(response.data.list));
        } else {
            yield put(getAllIndustryListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getIndustryByIndustryId({ payload: { industryId } }) {
    try {
        const response = yield call(invokeApi, 'get', INDUSTRY + '/' + industryId, '');
        if (response.status) {
            yield put(getIndustryByIndustryIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getIndustryByIndustryIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateIndustry({ payload: { industryId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', INDUSTRY + '/' + industryId, data);
        if (response.status) {
            yield put(getAllIndustryListRequest());
            yield put(updateIndustryResponse(response.message, SUCCESS));
        } else {
            yield put(updateIndustryResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateIndustryStatusByIndustryId({ payload: { industryId, status } }) {
    try {
        const response = yield call(invokeApi, 'patch', INDUSTRY + '/' + industryId + '/status/' + status);
        if (response.status) {
            yield put(getAllIndustryListRequest());
            yield put(updateIndustryStatusByIndustryIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateIndustryStatusByIndustryIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getIndustryListByStatus({ payload: { status } }) {
    try {
        const response = yield call(invokeApi, 'get', INDUSTRY_BY_STATUS + '/' + status);
        if (response.status) {
            yield put(getIndustryListByStatusResponse(response.data.list));
        } else {
            yield put(getIndustryListByStatusResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteIndustryByIndustryId({ payload: { industryId } }) {
    try {
        const response = yield call(invokeApi, 'delete', INDUSTRY + '/' + industryId);
        if (response.status) {
            yield put(getAllIndustryListRequest());
            yield put(deleteIndustryByIndustryIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteIndustryByIndustryIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchIndustry() {
    yield takeEvery(ADD_INDUSTRY_REQUEST, addIndustry);
    yield takeEvery(GET_ALL_INDUSTRY_LIST_REQUEST, getAllIndustryList);
    yield takeEvery(GET_INDUSTRY_BY_INDUSTRY_ID_REQUEST, getIndustryByIndustryId);
    yield takeEvery(UPDATE_INDUSTRY_REQUEST, updateIndustry);
    yield takeEvery(UPDATE_INDUSTRY_STATUS_BY_INDUSTRY_ID_REQUEST, updateIndustryStatusByIndustryId);
    yield takeEvery(GET_INDUSTRY_LIST_BY_STATUS_REQUEST, getIndustryListByStatus);
    yield takeEvery(DELETE_INDUSTRY_BY_INDUSTRY_ID_REQUEST, deleteIndustryByIndustryId);
}
function* industrySaga() {
    yield all([fork(watchIndustry)]);
}
export default industrySaga;