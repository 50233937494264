import produce from 'immer';
import { ADD_SUB_INDUSTRY_RESPONSE, DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE, GET_ALL_SUB_INDUSTRY_LIST_RESPONSE, GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE, GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_RESPONSE, GET_SUB_INDUSTRY_LIST_BY_STATUS_RESPONSE, UPDATE_SUB_INDUSTRY_RESPONSE, UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_RESPONSE } from './actionTypes';
export const initialState = {
    subIndustryApiResponse: null,
    editSubIndustryApiResponse: null,
    subIndustryList: []
};
const subIndustry = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_SUB_INDUSTRY_RESPONSE:
            case UPDATE_SUB_INDUSTRY_RESPONSE:
            case UPDATE_SUB_INDUSTRY_STATUS_BY_SUB_INDUSTRY_ID_RESPONSE:
            case DELETE_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE:
                draft.subIndustryApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_SUB_INDUSTRY_LIST_RESPONSE:
                draft.subIndustryList = action.payload.subIndustryList;
                break;
            case GET_SUB_INDUSTRY_BY_SUB_INDUSTRY_ID_RESPONSE:
                draft.editIndustryApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;




            case GET_SUB_INDUSTRY_LIST_BY_STATUS_RESPONSE:
                draft.subIndustryList = action.payload.subIndustryList;
                break;

            case GET_SUB_INDUSTRY_LIST_BY_INDUSTRY_RESPONSE:
                draft.subIndustryList = action.payload.subIndustryList;
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default subIndustry;
