import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import __t from 'i18n/translator';
import { getDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import 'react-datepicker/dist/react-datepicker.css';
import { getProjectTaskActivityRequest } from 'store/actions';
import { makeProjectsSelectField } from 'store/Masters/Projects/selector';
import CustomisedModal from 'components/Application/CustomisedModal';

class TaskActivityLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskActivityList: [],
            userId: '',
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.projectTaskActivityApiResponse &&
            this.props.projectTaskActivityApiResponse !==
                nextprops.projectTaskActivityApiResponse
        ) {
            let userId = localStorage.getItem('userId');
            this.setState({
                taskActivityList: nextprops.projectTaskActivityApiResponse,
                userId: userId ? userId : '',
            });
        }
        if (nextprops.taskId && this.props.taskId !== nextprops.taskId) {
            this.getProjectTaskActivityByTaskId(nextprops.taskId);
        }
    }
    getProjectTaskActivityByTaskId(taskId) {
        this.props.getProjectTaskActivityRequest(taskId);
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={__t('form_labels.projects.task_activity_log')}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            {this.state.taskActivityList?.map((activity) => (
                                <>
                                    <div className="list-group">
                                        <a href={() => false} className="list-group-item list-group-item-action flex-column align-items-start ">
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">
                                                    {this.state.userId ==
                                                    activity.createdBy
                                                        ? 'You'
                                                        : activity.user
                                                              .fullName}
                                                </h5>
                                                <small>
                                                    {getDateTimeFromDateTimeString(
                                                        activity.createdAt
                                                    )}
                                                </small>
                                            </div>
                                            <p className="mb-1">
                                                {activity?.remarks}
                                            </p>
                                            <small>
                                                {
                                                    activity
                                                        .projectActivityTypes
                                                        ?.activityTypeName
                                                }
                                            </small>
                                        </a>
                                    </div>
                                </>
                            ))}
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    projectTaskActivityApiResponse: makeProjectsSelectField(
        'projectTaskActivityApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getProjectTaskActivityRequest,
});

export default compose(withConnect, withRouter)(TaskActivityLog);
