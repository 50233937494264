import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    handleApiCallException,
} from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { GET_AUDIT_LIST } from 'helpers/apiConstants';
import { GET_AUDIT_LIST_REQUEST } from './actionTypes';
import { getAuditListResponse } from './actions';

function* getAuditList({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', GET_AUDIT_LIST, data);
        if (response.status) {
            yield put(getAuditListResponse(response.data.auditTrailData));
        } else {
            yield put(getAuditListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

export function* watchAudit() {
    yield takeEvery(GET_AUDIT_LIST_REQUEST, getAuditList);
}
function* auditSaga() {
    yield all([fork(watchAudit)]);
}
export default auditSaga;