import React, { Component } from 'react';
import CustomisedModal from 'components/Application/CustomisedModal';
import { Col, Label, Row } from 'reactstrap';
import __t from 'i18n/translator';
import { decryptText } from 'helpers/crypto';class ViewTaskUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskUserData: {},
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.taskUserData !== this.props.taskUserData) {
            this.setState({
                taskUserData: nextprops.taskUserData,
            });
        }
    }
    render() {
        let departmentNames = '';
        if (this.state.taskUserData?.departments?.length > 0) {
            departmentNames = this.state.taskUserData?.departments
                .map((opt) => opt.departmentName)
                .join(', ');
        }
        return (
            <React.Fragment>
                <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={
                        this.props.modalName +
                        ' | ' +
                        this.state.taskUserData?.taskCode
                    }
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row className="view_activity_modal">
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.allocation.department')}
                                </Label>{' '}
                                <br />
                                {departmentNames}
                            </p>
                        </Col>
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t('form_labels.allocation.submitter')}
                                </Label>
                                <br />
                                {
                                    this.state.taskUserData?.submitterUser
                                        ?.fullName + ' (' + decryptText(this.state.taskUserData?.submitterUser
                                            ?.email) + ')'
                                }
                            </p>
                        </Col>
                        <Col lg="6">
                            <p>
                                <Label>
                                    {__t(
                                        'form_labels.allocation.first_approver'
                                    )}
                                </Label>{' '}
                                <br />
                                {
                                    this.state.taskUserData?.firstApproverUser
                                        ?.fullName + ' (' + decryptText(this.state.taskUserData?.firstApproverUser
                                            ?.email) + ')'
                                }
                            </p>
                        </Col>
                        <Col lg="6">
                            {this.state.taskUserData?.secondApproverUser && this.state.taskUserData
                                ?.secondApproverUser?.fullName && (
                                    <p>
                                        <Label>
                                            {__t(
                                                'form_labels.allocation.second_approver'
                                            )}
                                        </Label>{' '}
                                        <br />
                                        {
                                            this.state.taskUserData
                                                ?.secondApproverUser?.fullName + ' (' + decryptText(this.state.taskUserData?.secondApproverUser
                                                    ?.email) + ')'
                                        }
                                    </p>
                                )}
                        </Col>
                        <Col lg="6">
                            {this.state.taskUserData?.auditorUser && this.state.taskUserData?.auditorUser?.fullName && (
                                <p>
                                    <Label>
                                        {__t('form_labels.allocation.auditor')}
                                    </Label>{' '}
                                    <br />
                                    {this.state.taskUserData?.auditorUser?.fullName + ' (' + decryptText(this.state.taskUserData?.auditorUser
                                        ?.email) + ')'}
                                </p>
                            )}
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
export default ViewTaskUser;
