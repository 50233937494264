import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = (state) => state.tenant || initialState;
const makeTenantSelectField = (field) =>
    createSelector(reducerState, (tenantState) => tenantState[field]);
const selectSortedTenantList = () =>
    createSelector(makeTenantSelectField('tenantList'), (tenantList) =>
        tenantList
            .map((opt) => ({
                value: opt.id,
                label: opt.name,
                companyGroups: opt.companyGroups
            }))
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeTenantSelectField,
    selectSortedTenantList    
};
