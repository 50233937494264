import React from 'react';
import NumberFormat from 'react-number-format';
import ReactCodeInput from 'react-code-input';
import { isValidNumericField } from 'helpers/customValidators';

export const FloatComponent = props => {
    return (
        <NumberFormat
            {...props}
            defaultValue={0}
            isAllowed={values => {
                const { value } = values;
                return isValidNumericField(value, props.precision, props.scale)
                    ? true
                    : false;
            }}
            type="text"
            allowEmptyFormatting={true}
            decimalScale={props.scale}
            fixedDecimalScale={true}
        />
    );
};

export const IntComponent = props => {
    return (
        <NumberFormat
            {...props}
            allowEmptyFormatting={true}
            decimalScale={0}
            defaultValue={''}
            type="text"
        />
    );
};

export const CodeComponent = props =>{
    return (
        <ReactCodeInput type='number' inputMode="numeric" fields={props.digits} {...props}/>
    )
}
