import {
    ADD_NEW_TICKET_MESSAGE_REQUEST,
    ADD_NEW_TICKET_MESSAGE_RESPONSE,
    ADD_NEW_TICKET_REQUEST,
    ADD_NEW_TICKET_RESPONSE,
    EDIT_TICKET_REQUEST,
    EDIT_TICKET_RESPONSE,
    GET_TICKET_COUNT_REQUEST,
    GET_TICKET_COUNT_RESPONSE,
    GET_TICKET_DETAILS_BY_ID_REQUEST,
    GET_TICKET_DETAILS_BY_ID_RESPONSE,
    GET_TICKET_MESSAGE_LIST_REQUEST,
    GET_TICKET_MESSAGE_LIST_RESPONSE,
    LIST_TICKET_ASSIGNEE_USERS_REQUEST,
    LIST_TICKET_ASSIGNEE_USERS_RESPONSE,
    LIST_TICKET_CATEGORY_REQUEST,
    LIST_TICKET_CATEGORY_RESPONSE,
    LIST_TICKET_REQUEST,
    LIST_TICKET_RESPONSE
} from './actionTypes';

export const addNewTicketRequest = (data) => {
    return {
        type: ADD_NEW_TICKET_REQUEST,
        payload: { data },
    };
};
export const addNewTicketResponse = (
    message,
    responseType,
) => {
    return {
        type: ADD_NEW_TICKET_RESPONSE,
        payload: { message, responseType },
    };
};

export const editTicketRequest = (ticketId, data) => {
    return {
        type: EDIT_TICKET_REQUEST,
        payload: { ticketId, data },
    };
};
export const editTicketResponse = (
    message,
    responseType,
) => {
    return {
        type: EDIT_TICKET_RESPONSE,
        payload: { message, responseType },
    };
};

export const listTicketRequest = (data) => {
    return {
        type: LIST_TICKET_REQUEST,
        payload: { data }
    };
};
export const listTicketResponse = (message, responseType, data) => {
    return {
        type: LIST_TICKET_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const listTicketCategoryRequest = () => {
    return {
        type: LIST_TICKET_CATEGORY_REQUEST
    };
};
export const listTicketCategoryResponse = (
    ticketCategoryList
) => {
    return {
        type: LIST_TICKET_CATEGORY_RESPONSE,
        payload: { ticketCategoryList },
    };
};

export const getTicketDetailsByIdRequest = ticketId => {
    return {
        type: GET_TICKET_DETAILS_BY_ID_REQUEST,
        payload: { ticketId }
    };
};

export const getTicketDetailsByIdResponse = (message, responseType, data) => {
    return {
        type: GET_TICKET_DETAILS_BY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const listTicketAssigneeUsersRequest = () => {
    return {
        type: LIST_TICKET_ASSIGNEE_USERS_REQUEST
    };
};
export const listTicketAssigneeUsersResponse = (ticketAssigneeUserList) => {
    return {
        type: LIST_TICKET_ASSIGNEE_USERS_RESPONSE,
        payload: { ticketAssigneeUserList },
    };
};

export const addNewTicketMessageRequest = data => {
    return {
        type: ADD_NEW_TICKET_MESSAGE_REQUEST,
        payload: { data },
    };
};

export const addNewTicketMessageResponse = (message, responseType, data) => {
    return {
        type: ADD_NEW_TICKET_MESSAGE_RESPONSE,
        payload: { message, responseType, data },
    };
};


export const getTicketMessageListRequest = (ticketId) => {
    return {
        type: GET_TICKET_MESSAGE_LIST_REQUEST,
        payload: { ticketId },
    };
};

export const getTicketMessageListResponse = (ticketMessageList) => {
    return {
        type: GET_TICKET_MESSAGE_LIST_RESPONSE,
        payload: { ticketMessageList },
    };
};


export const getTicketCountRequest = () => {
    return {
        type: GET_TICKET_COUNT_REQUEST
    };
};

export const getTicketCountResponse = (message, responseType, data) => {
    return {
        type: GET_TICKET_COUNT_RESPONSE,
        payload: { message, responseType, data },
    };
};
