import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import {
    NONE,
    RESET,
    SUCCESS
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import { customValidTextField } from 'helpers/customValidators';
import __t from 'i18n/translator';
import Select from 'react-select';

class AddFundMasterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField()
            )
        });
        this.state = {
        };
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.operationResponse &&
            this.props.operationResponse !== nextprops.operationResponse
        ) {
            toastrMessage(
                nextprops.operationResponse.message,
                nextprops.operationResponse.responseType
            );
            if (nextprops.operationResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
    }
    getEntityListByCompanyGroupId = (companyGroupIds) => {
        this.props.getEntityListByCompanyGroup(companyGroupIds);
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={Object.assign({}, this.props.fundMasterData)}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className="col-lg-12">
                                    <Field
                                        name="fundName"
                                        className="form-control"
                                        placeholder={__t('form_labels.masters.fund_name')}
                                        value={
                                            values.fundName
                                        }
                                        maxLength={100}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.fund_name')} <span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.fund_name'),
                                        values.fundName,
                                        'required|min:3|max:100|alpha_space', {
                                        messages: {
                                            required: __t('validations.masters.fund_master_name_required'),
                                            min: __t('validations.masters.fund_master_name_min', { min: 3 }),
                                            max: __t('validations.masters.fund_master_name_max', { max: 100 }),
                                            alpha_space: __t('validations.masters.fund_master_name_alpha_space')
                                        }
                                    }
                                    )}
                                </FormGroup>
                                <FormGroup className='col-lg-12'>
                                    <Field
                                        component={Select}
                                        name="companyGroupId"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCompanyGroupListDropdown.filter((opt) => opt.value != NONE)
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'companyGroupId',
                                                opt.value
                                            );
                                            this.getEntityListByCompanyGroupId([opt.value]);
                                        }}
                                        value={this.props.sortedCompanyGroupListDropdown.filter(
                                            option =>
                                                values.companyGroupId == option.value
                                        )}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.fund_company_group')}<span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.fund_company_group'),
                                        values.companyGroupId,
                                        'required', {
                                        messages: {
                                            required: __t('validations.masters.fund_company_group_required')
                                        }
                                    }
                                    )}
                                </FormGroup>
                                <FormGroup className='col-lg-12'>
                                    <Field
                                        component={Select}
                                        name="entityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropdown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'entityId',
                                                (opt) ? opt.map(x => x.value) : []
                                            );
                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            option =>
                                                values.entityId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.masters.fund_entitys')}<span className='text-danger required'>*</span>
                                    </Label>
                                    {this.validator.message(
                                        __t('form_labels.masters.fund_entitys'),
                                        values.entityId,
                                        'required', {
                                        messages: {
                                            required: __t('validations.masters.fund_entitys_required')
                                        }
                                    }
                                    )}
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-2"
                                            >{this.props.operationType}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}>{RESET}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
export default AddFundMasterForm;