import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = (state) => state.funds || initialState;
const makeFundsSelectField = (field) =>
    createSelector(reducerState, (funds) => funds[field]);
const selectFundsList = () =>
    createSelector(makeFundsSelectField('fundsList'));

const selectSortedEntityAllUsersList = () =>
    createSelector(
        makeFundsSelectField('entityAllUsersList'),
        (entityAllUsersList) => {
            let finalUsers = entityAllUsersList.map((opt) => ({
                value: opt.id,
                label: opt?.fullName + ' - ' + opt?.email,
                fullName: opt?.fullName,
                email: opt?.email,
            }));
            return finalUsers
                .filter(
                    (a, i) =>
                        finalUsers.findIndex((s) => a.value === s.value) === i
                )
                .sort((item1, item2) =>
                    compareString(item1.label, item2.label)
                );
        }
    );

export {
    reducerState,
    makeFundsSelectField,
    selectFundsList,
    selectSortedEntityAllUsersList,
};
