import { SAVE_USER_NOTIFICATION_REQUEST, SAVE_USER_NOTIFICATION_RESPONSE, GET_USER_NOTIFICATION_REQUEST, GET_USER_NOTIFICATION_RESPONSE, READ_USER_NOTIFICATION_REQUEST, READ_USER_NOTIFICATION_RESPONSE, READ_NOTIFICATION_BY_ID_REQUEST, READ_NOTIFICATION_BY_ID_RESPONSE } from "./actionTypes";

export const saveUserNotificationRequest = data => {
    return {
        type: SAVE_USER_NOTIFICATION_REQUEST,
        payload: { data },
    };
};

export const saveUserNotificationResponse = (message, responseType, data) => {
    return {
        type: SAVE_USER_NOTIFICATION_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const getUserNotificationRequest = () => {
    return {
        type: GET_USER_NOTIFICATION_REQUEST
    };
};

export const getUserNotificationResponse = (message, responseType, data) => {
    return {
        type: GET_USER_NOTIFICATION_RESPONSE,
        payload: { message, responseType, data },
    };
};

export const readUserNotificationRequest = (data) => {
    return {
        type: READ_USER_NOTIFICATION_REQUEST,
        payload: { data },
    };
};

export const readUserNotificationResponse = (message, responseType) => {
    return {
        type: READ_USER_NOTIFICATION_RESPONSE,
        payload: { message, responseType },
    };
};

export const readNotificationByIdRequest = (id) => {
    return {
        type: READ_NOTIFICATION_BY_ID_REQUEST,
        payload: { id },
    };
};

export const readNotificationByIdResponse = (message, responseType) => {
    return {
        type: READ_NOTIFICATION_BY_ID_RESPONSE,
        payload: { message, responseType },
    };
};