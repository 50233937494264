export const GET_ALL_LAW_DEFINITIONS_REQUEST = 'get_all_law_definitions_request';
export const GET_ALL_LAW_DEFINITIONS_RESPONSE = 'get_all_law_definitions_response';
export const GET_LAW_DEFINITIONS_BY_LAW_ID_REQUEST = 'get_law_definitions_by_law_id_request';
export const GET_LAW_DEFINITIONS_BY_LAW_ID_RESPONSE = 'get_law_definitions_by_law_id_response';
export const UPDATE_LAW_DEFINITION_REQUEST = 'update_law_definition_request';
export const UPDATE_LAW_DEFINITION_RESPONSE = 'update_law_definition_response';
export const ADD_LAW_DEFINITION_REQUEST = 'add_law_definition_request';
export const ADD_LAW_DEFINITION_RESPONSE = 'add_law_definition_response';
export const GET_LAW_DEFINITION_VERSIONS_LIST_REQUEST = 'get_law_definition_versions_list_request';
export const GET_LAW_DEFINITION_VERSIONS_LIST_RESPONSE = 'get_law_definition_versions_list_response';
