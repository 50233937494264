export const listColumns = [
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'State/Province',
        field: 'stateName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Status',
        field: 'taskStatusId',
    }
];

export const legalUpdateSearchListColumns = [
    {
        label: 'Update ID',
        field: 'legalUpdateCode',
    },
    {
        label: 'Country',
        field: 'country',
    },
    {
        label: 'State',
        field: 'state',
    },
    {
        label: 'Business Function Impacted',
        field: 'areaOfLaw',
    },
    {
        label: 'Effective Date',
        field: 'effectiveDate',
    },
    {
        label: 'Law Short Name',
        field: 'lawShortName',
    },
    {
        label: 'Update Type',
        field: 'updateType',
    },
    {
        label: 'No. of Fields Impacted',
        field: 'fieldsImpacted',
    },
    {
        label: 'Received On',
        field: 'systemPublishAt',
    },
    {
        label: 'Actions',
        field: 'actions'
    },
];