import { ADD_ENTITY_REQUEST, ADD_ENTITY_RESPONSE, CLEAR_FILTER_ENTITY_USER_RESPONSE, DELETE_ENTITY_BY_ENTITY_ID_REQUEST, DELETE_ENTITY_BY_ENTITY_ID_RESPONSE, GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_REQUEST, GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_RESPONSE, GET_ALL_ENTITY_LIST_REQUEST, GET_ALL_ENTITY_LIST_RESPONSE, GET_ENTITY_ALL_USERS_BY_ENTITY_ID_REQUEST, GET_ENTITY_ALL_USERS_BY_ENTITY_ID_RESPONSE, GET_ENTITY_BY_ENTITY_ID_REQUEST, GET_ENTITY_BY_ENTITY_ID_RESPONSE, GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_REQUEST, GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_RESPONSE, GET_ENTITY_LIST_BY_COUNTRY_REQUEST, GET_ENTITY_LIST_BY_COUNTRY_RESPONSE, GET_ENTITY_LIST_BY_STATUS_REQUEST, GET_ENTITY_LIST_BY_STATUS_RESPONSE, GET_ENTITY_TEAMS_BY_ENTITY_ID_REQUEST, GET_ENTITY_TEAMS_BY_ENTITY_ID_RESPONSE, GET_USER_ENTITY_LIST_REQUEST, GET_USER_ENTITY_LIST_RESPONSE, GET_USER_LIST_BY_ENTITY_AND_ROLE_REQUEST, GET_USER_LIST_BY_ENTITY_AND_ROLE_RESPONSE, UPDATE_ENTITY_REQUEST, UPDATE_ENTITY_RESPONSE, UPDATE_ENTITY_STATUS_BY_ENTITY_ID_REQUEST, UPDATE_ENTITY_STATUS_BY_ENTITY_ID_RESPONSE } from "./actionTypes";
export const addEntityRequest = data => {
    return {
        type: ADD_ENTITY_REQUEST,
        payload: { data },
    };
};
export const addEntityResponse = (message, responseType) => {
    return {
        type: ADD_ENTITY_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllEntityListRequest = () => {
    return {
        type: GET_ALL_ENTITY_LIST_REQUEST
    };
};
export const getAllEntityListResponse = entityList => {
    return {
        type: GET_ALL_ENTITY_LIST_RESPONSE,
        payload: { entityList },
    };
};
export const getEntityByEntityIdRequest = (entityId) => {
    return {
        type: GET_ENTITY_BY_ENTITY_ID_REQUEST,
        payload: { entityId: entityId },
    };
};
export const getEntityByEntityIdResponse = (message, responseType, data) => {
    return {
        type: GET_ENTITY_BY_ENTITY_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateEntityRequest = (entityId, data) => {
    return {
        type: UPDATE_ENTITY_REQUEST,
        payload: { entityId, data },
    };
};
export const updateEntityResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateEntityStatusByEntityIdRequest = (entityId, status) => {
    return {
        type: UPDATE_ENTITY_STATUS_BY_ENTITY_ID_REQUEST,
        payload: { entityId, status },
    };
};
export const updateEntityStatusByEntityIdResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_STATUS_BY_ENTITY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getEntityListByStatusRequest = status => {
    return {
        type: GET_ENTITY_LIST_BY_STATUS_REQUEST,
        payload: { status },
    };
};
export const getEntityListByStatusResponse = entityList => {
    return {
        type: GET_ENTITY_LIST_BY_STATUS_RESPONSE,
        payload: { entityList },
    };
};

export const deleteEntityStatusByEntityIdRequest = (entityId) => {
    return {
        type: DELETE_ENTITY_BY_ENTITY_ID_REQUEST,
        payload: { entityId },
    };
};
export const deleteEntityByEntityIdResponse = (message, responseType) => {
    return {
        type: DELETE_ENTITY_BY_ENTITY_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getEntityListByCompanyGroupIdRequest = companyGroupIds => {
    return {
        type: GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_REQUEST,
        payload: { companyGroupIds },
    };
};
export const getEntityListByCompanyGroupIdResponse = entityList => {
    return {
        type: GET_ENTITY_LIST_BY_COMPANY_GROUP_ID_RESPONSE,
        payload: { entityList },
    };
};

export const getEntityTeamsByEntityIdRequest = entityId => {
    return {
        type: GET_ENTITY_TEAMS_BY_ENTITY_ID_REQUEST,
        payload: { entityId },
    };
};
export const getEntityTeamsByEntityIdResponse = (entityTeams) => {
    return {
        type: GET_ENTITY_TEAMS_BY_ENTITY_ID_RESPONSE,
        payload: { entityTeams },
    };
};

export const getEntityAllUsersByEntityIdRequest = entityId => {
    return {
        type: GET_ENTITY_ALL_USERS_BY_ENTITY_ID_REQUEST,
        payload: { entityId },
    };
};
export const getEntityAllUsersByEntityIdResponse = (entityAllUsersList) => {
    return {
        type: GET_ENTITY_ALL_USERS_BY_ENTITY_ID_RESPONSE,
        payload: { entityAllUsersList },
    };
};

export const getUserEntityListRequest = (roleId = 0) => {
    return {
        type: GET_USER_ENTITY_LIST_REQUEST,
        payload: { roleId }
    };
};
export const getUserEntityListResponse = entityList => {
    return {
        type: GET_USER_ENTITY_LIST_RESPONSE,
        payload: { entityList },
    };
};

export const getEntityListByCountryRequest = countryIds => {
    return {
        type: GET_ENTITY_LIST_BY_COUNTRY_REQUEST,
        payload: { countryIds },
    };
};
export const getEntityListByCountryResponse = entityList => {
    return {
        type: GET_ENTITY_LIST_BY_COUNTRY_RESPONSE,
        payload: { entityList },
    };
};

export const getAllEntityListOnUserListPageRequest = () => {
    return {
        type: GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_REQUEST
    };
};
export const getAllEntityListOnUserListPageResponse = entityListOnUserList => {
    return {
        type: GET_ALL_ENTITY_LIST_ON_USER_LIST_PAGE_RESPONSE,
        payload: { entityListOnUserList },
    };
};

export const getUserListByEntityAndRoleRequest = (entityIds, roleIds) => {
    return {
        type: GET_USER_LIST_BY_ENTITY_AND_ROLE_REQUEST,
        payload: { entityIds, roleIds }
    };
};
export const getUserListByEntityAndRoleResponse = userListByRole => {
    return {
        type: GET_USER_LIST_BY_ENTITY_AND_ROLE_RESPONSE,
        payload: { userListByRole },
    };
};

export const clearFilterEntityUserResponse = () => {
    return {
        type: CLEAR_FILTER_ENTITY_USER_RESPONSE
    };
};