import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = (state) => state.lawCompliance || initialState;
const makeLawComplianceSelectField = (field) =>
    createSelector(
        reducerState,
        (lawComplianceState) => lawComplianceState[field]
    );
const selectSortedAllLawCompliancesList = () =>
    createSelector(
        makeLawComplianceSelectField('allLawCompliances'),
        (allLawCompliances) =>
            allLawCompliances
                .map((opt) => ({
                    value: opt.id,
                    label: opt.title,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );
const selectSortedLawCompliancesList = () =>
    createSelector(
        makeLawComplianceSelectField('lawComplianceList'),
        (lawComplianceList) =>
            lawComplianceList
                .map((opt) => ({
                    value: opt.id,
                    label: opt.title,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedComplianceCategoriesList = () =>
    createSelector(
        makeLawComplianceSelectField('allCompliancesCategoriesList'),
        (allCompliancesCategoriesList) =>
            allCompliancesCategoriesList?.map((opt) => ({
                    value: opt.id,
                    label: opt.complianceCategoryName,
                }))
                .sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeLawComplianceSelectField,
    selectSortedAllLawCompliancesList,
    selectSortedLawCompliancesList,
    selectSortedComplianceCategoriesList
};
