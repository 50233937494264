import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_LAW_COMPLIANCE_REQUEST,
    GET_ALL_LAW_COMPLIANCES_REQUEST,
    GET_ALL_UPLOADED_FILES_REQUEST,
    GET_LAW_COMPLIANCES_BY_LAW_ID_REQUEST,
    GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_REQUEST,
    GET_LAW_COMPLIANCES_FOR_ALLOCATION_REQUEST,
    GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_REQUEST,
    UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_REQUEST,
    GET_LAW_COMPLIANCE_SEARCH_REQUEST,
    GET_COMPLIANCE_TYPES_REQUEST,
    GET_COMPLIANCE_CATEGORIES_REQUEST,
    GET_PERIODICITIES_REQUEST,
    GET_PENALTY_TYPES_REQUEST,
    GET_RISK_RATING_REQUEST,
    UPDATE_LAW_COMPLIANCE_REQUEST,
    GET_LAW_COMPLIANCE_LOW_ID_REQUEST,
    GET_LAW_SECTION_BY_LOW_ID_REQUEST,
    GET_LEGAL_UPDATE_REFERENCE_ID_REQUEST,
    GET_IMPACT_MANAGEMENT_PERSONNELS_REQUEST,
    GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_REQUEST,
    DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addLawComplianceResponse,
    getAllLawCompliancesResponse,
    getLawCompliancesByLawIdResponse,
    getLawCompliancesByLawCategoryIdResponse,
    getLawComplianceDetailsByLawComplianceIdResponse,
    getAllUploadedFilesResponse,
    getLawCompliancesForAllocationResponse,
    updateLawComplianceStatusByLawComplianceIdResponse,
    getLawComplianceSearchResponse,
    getComplianceTypesResponse,
    getComplianceCategoriesResponse,
    getPeriodicitiesResponse,
    getPenaltyTypesResponse,
    getRiskRatingsResponse,
    updateLawcomplianceResponse,
    getLawCompliancebyLowIdResponse,
    getlawSectionByLowIdResponse,
    getLegalUpdateReferenceIdResponse,
    getImpactManagementPersonnelsResponse,
    getLawComplianceVersionsbyComplianceIdResponse,
    deleteComplianceQueueFileByIdResponse
} from './actions';
import {
    LAW_COMPLIANCES_LIST_BY_LAW,
    LAW_COMPLIANCE_DETAILS_BY_LAW_ID,
    LAW_COMPLIANCES_FOR_ALLOCATION,
    UPLOAD_COMPLIANCES,
    UPLOAD_COMPLIANCES_FILE_LOGS,
    LAW_COMPLIANCE,
    LAW_COMPLIANCES_SEARCH,
    GET_COMPLIANCE_TYPES,
    GET_COMPLIANCE_CATEGORIES,
    GET_PERIODICITIES,
    GET_PENALTY_TYPES,
    GET_RISK_RATING,
    GET_LAW_COMPLIANCES,
    GET_LAW_SECTIONS,
    UPDATER_COMPLIANCE_LEGAL_UPDATE,
    GET_IMPACT_MANAGEMENT_PERSONNELS,
    COMPLIANCE_VERSIONS,
    COMPLAINCE_QUEUE_FILE
} from 'helpers/apiConstants';
function* addLawCompliance({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'post',
            UPLOAD_COMPLIANCES,
            data,
            headers
        );
        if (response.status) {
            yield put(
                addLawComplianceResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                addLawComplianceResponse(
                    response.message,
                    DANGER,
                    response.data
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllUploadedFiles() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            UPLOAD_COMPLIANCES_FILE_LOGS
        );
        if (response.status) {
            yield put(getAllUploadedFilesResponse(response.data.list));
        } else {
            yield put(getAllUploadedFilesResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllLawCompliances() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            '/ComplianceMaster/GetAll',
            ''
        );
        if (response.status) {
            yield put(getAllLawCompliancesResponse(response.data));
        } else {
            yield put(getAllLawCompliancesResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawCompliancesByLawId({ payload: { lawId, displayMode } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            LAW_COMPLIANCES_LIST_BY_LAW + '/' + lawId,
            { displayMode }
        );
        if (response.status) {
            yield put(getLawCompliancesByLawIdResponse(response.data.list));
        } else {
            yield put(getLawCompliancesByLawIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawCompliancesByLawCategoryId({ payload: { lawCategoryId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            '/ComplianceMaster/GetComplianceByLawCategoryId/' + lawCategoryId,
            ''
        );
        if (response.status) {
            yield put(getLawCompliancesByLawCategoryIdResponse(response.data));
        } else {
            yield put(getLawCompliancesByLawCategoryIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawComplianceDetailsByLawComplianceId({
    payload: { lawComplianceId, versionId },
}) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            LAW_COMPLIANCE_DETAILS_BY_LAW_ID + '/' + lawComplianceId + '/' + versionId,
            ''
        );
        if (response.status) {
            yield put(
                getLawComplianceDetailsByLawComplianceIdResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(
                getLawComplianceDetailsByLawComplianceIdResponse(
                    response.message,
                    DANGER,
                    []
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getLawCompliancesForAllocation({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            LAW_COMPLIANCES_FOR_ALLOCATION,
            data
        );
        if (response.status) {
            yield put(
                getLawCompliancesForAllocationResponse(response.data.list)
            );
        } else {
            yield put(getLawCompliancesForAllocationResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLawComplianceSearchList({ payload: { data, pagination } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            LAW_COMPLIANCES_SEARCH,
            data
        );

        yield put(getLawComplianceSearchResponse(response.message, SUCCESS, response.data));
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateLawComplianceStatusByLawComplianceId({
    payload: { lawComplianceId, status },
}) {
    try {
        const response = yield call(
            invokeApi,
            'patch',
            LAW_COMPLIANCE + '/' + lawComplianceId + '/status/' + status
        );
        if (response.status) {
            yield put(
                updateLawComplianceStatusByLawComplianceIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                updateLawComplianceStatusByLawComplianceIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getComplianceTypes() {
    try {
        const response = yield call(invokeApi, 'get', GET_COMPLIANCE_TYPES, '');

        if (response.status) {
            yield put(getComplianceTypesResponse(response.data.list));
        } else {
            yield put(getComplianceTypesResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getComplianceCategories() {
    try {
        const response = yield call(
            invokeApi,
            'get',
            GET_COMPLIANCE_CATEGORIES,
            ''
        );

        if (response.status) {
            yield put(getComplianceCategoriesResponse(response.data.list));
        } else {
            yield put(getComplianceCategoriesResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getPeriodicities() {
    try {
        const response = yield call(invokeApi, 'get', GET_PERIODICITIES, '');
        if (response.status) {
            yield put(getPeriodicitiesResponse(response.data.list));
        } else {
            yield put(getPeriodicitiesResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getPenaltyTypes() {
    try {
        const response = yield call(invokeApi, 'get', GET_PENALTY_TYPES, '');

        if (response.status) {
            yield put(getPenaltyTypesResponse(response.data.list));
        } else {
            yield put(getPenaltyTypesResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getRiskRatings() {
    try {
        const response = yield call(invokeApi, 'get', GET_RISK_RATING, '');

        if (response.status) {
            yield put(getRiskRatingsResponse(response.data.list));
        } else {
            yield put(getRiskRatingsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getImpactManagementPersonnels() {
    try {
        const response = yield call(invokeApi, 'get', GET_IMPACT_MANAGEMENT_PERSONNELS, '');

        if (response.status) {
            yield put(getImpactManagementPersonnelsResponse(response.data.list));
        } else {
            yield put(getImpactManagementPersonnelsResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* updateLawcompliance({ payload: { lawcompliancId, data } }) {
    try {
        const response = yield call(
            invokeApi,
            'put',
            LAW_COMPLIANCE + '/' + lawcompliancId,
            data
        );

        if (response.status) {
            yield put(updateLawcomplianceResponse(response.message, SUCCESS));
        } else {
            yield put(updateLawcomplianceResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLawCompliancebyLowId({ payload: { lowId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            GET_LAW_COMPLIANCES + lowId,
            ''
        );

        if (response.status) {
            yield put(getLawCompliancebyLowIdResponse(response.data.list));
        } else {
            yield put(getLawCompliancebyLowIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getlawSectionByLowId({ payload: { lowId } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            GET_LAW_SECTIONS + lowId,
            ''
        );

        if (response.status) {
            yield put(getlawSectionByLowIdResponse(response.data.list));
        } else {
            yield put(getlawSectionByLowIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLegalUpdateReferenceId({ payload: { id, edit } }) {
    try {
        const response = yield call(
            invokeApi,
            'get',
            UPDATER_COMPLIANCE_LEGAL_UPDATE + '/' + id + '/' + edit,
            ''
        );
        if (response.status) {
            yield put(getLegalUpdateReferenceIdResponse(response.data.list));
        } else {
            yield put(getLegalUpdateReferenceIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getLawComplianceVersionsbyComplianceId({ payload: { lawComplianceId } }) {
    try {
        const response = yield call(invokeApi, 'get', COMPLIANCE_VERSIONS + lawComplianceId, '');
        if (response.status) {
            yield put(getLawComplianceVersionsbyComplianceIdResponse(response.data.list));
        } else {
            yield put(getLawComplianceVersionsbyComplianceIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* deleteComplianceQueueFileById({ payload: { complianceQueueFileId } }) {
    try {
        const response = yield call(invokeApi, 'delete', COMPLAINCE_QUEUE_FILE + complianceQueueFileId, '');
        if (response.status) {
            yield put(deleteComplianceQueueFileByIdResponse(response.message, SUCCESS));
        } else {
            yield put(deleteComplianceQueueFileByIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchLawCompliance() {
    yield takeEvery(ADD_LAW_COMPLIANCE_REQUEST, addLawCompliance);
    yield takeEvery(GET_ALL_UPLOADED_FILES_REQUEST, getAllUploadedFiles);
    yield takeEvery(GET_ALL_LAW_COMPLIANCES_REQUEST, getAllLawCompliances);
    yield takeEvery(
        GET_LAW_COMPLIANCES_BY_LAW_ID_REQUEST,
        getLawCompliancesByLawId
    );
    yield takeEvery(
        GET_LAW_COMPLIANCES_BY_LAW_CATEGORY_ID_REQUEST,
        getLawCompliancesByLawCategoryId
    );
    yield takeEvery(
        GET_LAW_COMPLIANCE_DETAILS_BY_LAW_COMPLIANCE_ID_REQUEST,
        getLawComplianceDetailsByLawComplianceId
    );
    yield takeEvery(
        GET_LAW_COMPLIANCES_FOR_ALLOCATION_REQUEST,
        getLawCompliancesForAllocation
    );
    yield takeEvery(
        UPDATE_LAW_COMPLIANCE_STATUS_BY_LAW_COMPLIANCE_ID_REQUEST,
        updateLawComplianceStatusByLawComplianceId
    );

    yield takeEvery(
        GET_LAW_COMPLIANCE_SEARCH_REQUEST,
        getLawComplianceSearchList
    );
    yield takeEvery(GET_COMPLIANCE_TYPES_REQUEST, getComplianceTypes);
    yield takeEvery(GET_COMPLIANCE_CATEGORIES_REQUEST, getComplianceCategories);
    yield takeEvery(GET_PERIODICITIES_REQUEST, getPeriodicities);
    yield takeEvery(GET_PENALTY_TYPES_REQUEST, getPenaltyTypes);
    yield takeEvery(GET_RISK_RATING_REQUEST, getRiskRatings);
    yield takeEvery(GET_IMPACT_MANAGEMENT_PERSONNELS_REQUEST, getImpactManagementPersonnels);
    yield takeEvery(UPDATE_LAW_COMPLIANCE_REQUEST, updateLawcompliance);
    yield takeEvery(GET_LAW_COMPLIANCE_LOW_ID_REQUEST, getLawCompliancebyLowId);
    yield takeEvery(GET_LAW_SECTION_BY_LOW_ID_REQUEST, getlawSectionByLowId);
    yield takeEvery(
        GET_LEGAL_UPDATE_REFERENCE_ID_REQUEST,
        getLegalUpdateReferenceId
    );
    yield takeEvery(GET_LAW_COMPLIANCE_VERSIONS_BY_COMPLIANCE_ID_REQUEST, getLawComplianceVersionsbyComplianceId);
    yield takeEvery(DELETE_COMPLIANCE_QUEUE_FILE_BY_ID_REQUEST, deleteComplianceQueueFileById);
}
function* lawComplianceSaga() {
    yield all([fork(watchLawCompliance)]);
}
export default lawComplianceSaga;
