import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    GET_FILING_TYPES_REQUEST,
    GET_IMPORT_LOGS_LIST_BY_TYPE_REQUEST,
    IMPORT_FILE_BY_TYPE_REQUEST,
    GET_MASTER_SAMPLE_REQUEST
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    getFilingTypesResponse,
    getImportLogsListByTypeResponse,
    importFileByTypeResponse,
    getMasterSampleResponse
} from './actions';
import {
    GET_FILING_TYPES,
    GET_MASTER_SAMPLE,
    IMPORT_MASTER,
    IMPORT_MASTER_LOGS,
} from 'helpers/apiConstants';
function* importFileByType({ payload: { data } }) {
    try {
        const headers = { ContentType: 'multipart/form-data' };
        const response = yield call(
            invokeApi,
            'post',
            IMPORT_MASTER,
            data,
            headers
        );
        if (response.status) {
            yield put(importFileByTypeResponse(response.message, SUCCESS));
        } else {
            yield put(importFileByTypeResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getImportLogsListByType({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            'post',
            IMPORT_MASTER_LOGS,
            data
        );
        if (response.status) {
            yield put(getImportLogsListByTypeResponse(response.data.list));
        } else {
            yield put(getImportLogsListByTypeResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getFilingTypesRequest() {
    try {
        const response = yield call(invokeApi, 'get', GET_FILING_TYPES, '');

        if (response.status) {
            yield put(getFilingTypesResponse(response.data.list));
        } else {
            yield put(getFilingTypesResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* getMasterSampleRequest({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', GET_MASTER_SAMPLE, data);

        if (response.status) {
            yield put(getMasterSampleResponse(response.data));
        } else {
            yield put(getMasterSampleResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchImportMaster() {
    yield takeEvery(IMPORT_FILE_BY_TYPE_REQUEST, importFileByType);
    yield takeEvery(
        GET_IMPORT_LOGS_LIST_BY_TYPE_REQUEST,
        getImportLogsListByType
    );
    yield takeEvery(GET_FILING_TYPES_REQUEST, getFilingTypesRequest);
    yield takeEvery(GET_MASTER_SAMPLE_REQUEST, getMasterSampleRequest);
}
function* importMasterSaga() {
    yield all([fork(watchImportMaster)]);
}
export default importMasterSaga;
