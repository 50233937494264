import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    LOGIN_REQUEST,
    LOGIN_PASSWORD_CHANGE_REQUEST,
    LOGOUT_REQUEST,
    SEND_CODE_REQUEST,
    VERIFY_TOKEN_REQUEST,
    VERIFY_CODE_REQUEST,
} from './actionTypes';
import {
    loginResponse,
    loginPasswordChangeResponse,
    logoutResponse,
    sendCodeResponse,
    verifyTokenResponse,
    verifyCodeResponse,
} from './actions';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { SUCCESS, DANGER } from 'constants/commonConstants';
import {
    CHANGE_PASSWORD,
    LOGIN,
    LOGOUT,
    SEND_CODE,
    VERIFY_CODE,
    VERIFY_TOKEN,
} from 'helpers/apiConstants';
function* login({ payload: { email, password, code, tenantId, vToken } }) {
    try {
        const response = yield call(invokeApi, 'post', LOGIN, {
            email: email.toLowerCase(),
            password: password,
            code: code,
            tenantId: tenantId
        }, {
            vtoken: vToken
        });
        if (response.status) {
            yield put(loginResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(loginResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* sendCode({ payload: { email, password } }) {
    try {
        const response = yield call(invokeApi, 'post', SEND_CODE, {
            email: email.toLowerCase(),
            password: password,
        });
        if (response.status) {
            yield put(
                sendCodeResponse(response.message, SUCCESS, response.data)
            );
        } else {
            yield put(sendCodeResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* loginPasswordChange({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', CHANGE_PASSWORD, data);
        if (response.status) {
            yield put(
                loginPasswordChangeResponse(
                    response.message,
                    SUCCESS,
                    response.data
                )
            );
        } else {
            yield put(loginPasswordChangeResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* logout() {
    try {
        const response = yield call(invokeApi, 'post', LOGOUT, '');
        if (response.status) {
            yield put(logoutResponse(response.message, SUCCESS));
        } else {
            yield put(logoutResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* verifyToken({ payload: { type, tenantId } }) {
    try {
        const response = yield call(invokeApi, 'post', VERIFY_TOKEN, { type, tenantId }, {
            "login_type": "SSO"
        });
        if (response.status) {
            yield put(
                verifyTokenResponse(response.message, SUCCESS, response.data, type)
            );
        } else {
            yield put(verifyTokenResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* verifyCode({ payload: { email, password, code } }) {
    try {
        const response = yield call(invokeApi, 'post', VERIFY_CODE, {
            email: email.toLowerCase(),
            password: password,
            code: code,
        });
        if (response.status) {
            yield put(verifyCodeResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(verifyCodeResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchUserLogin() {
    yield takeEvery(LOGIN_REQUEST, login);
    yield takeEvery(SEND_CODE_REQUEST, sendCode);
    yield takeEvery(LOGIN_PASSWORD_CHANGE_REQUEST, loginPasswordChange);
    yield takeEvery(LOGOUT_REQUEST, logout);
    yield takeEvery(VERIFY_TOKEN_REQUEST, verifyToken);
    yield takeEvery(VERIFY_CODE_REQUEST, verifyCode);
}
function* loginSaga() {
    yield all([fork(watchUserLogin)]);
}
export default loginSaga;
