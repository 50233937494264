import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, SUBMIT, UPDATE } from 'constants/commonConstants';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllFundMasterListRequest,
    updateFundMasterStatusByFundMasterIdRequest,
    addFundMasterRequest,
    updateFundMasterRequest,
    deleteFundMasterByFundMasterIdRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getEntityListByStatusRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import fundMasterReducer from 'store/Masters/FundMaster/reducer';
import fundMasterSaga from 'store/Masters/FundMaster/saga';
import { makeFundMasterSelectField } from 'store/Masters/FundMaster/selector';
import { initialFundMasterDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import AddFundMasterForm from './AddFundMasterForm';
import { commonConfirmBox } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { makeEntitySelectField, selectSortedEntityList } from 'store/Entity/selector';
import { isTenantAdminCompanyGroupAdminEntityAdminRole } from 'helpers/projectUtils';
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({ key: companyGroupKey, saga: companyGroupSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const fundMasterKey = 'fundMaster';
const withFundMasterReducer = injectReducer({
    key: fundMasterKey,
    reducer: fundMasterReducer,
});
const withFundMasterSaga = injectSaga({
    key: fundMasterKey,
    saga: fundMasterSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fundMasterList: [],
            fundMasterData: initialFundMasterDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        if (isTenantAdminCompanyGroupAdminEntityAdminRole() == false) {
            this.props.history.goBack();
        }
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getEntityListByStatusRequest(ACTIVE);
    }
    onStatusChange(checked, e, id) {
        this.props.updateFundMasterStatusByFundMasterIdRequest(id, { status: checked });
    }
    viewFundMaster(fundMasterData) {
        this.setState({
            fundMasterData: initialFundMasterDataState(),
            operationType: UPDATE,
        },
            () => {
                this.setState({
                    fundMasterData: {
                        ...fundMasterData,
                        entityId: fundMasterData.entityId.split(',')?.map(Number)
                    }
                });
            }
        );
    }
    deleteFundMaster = async (fundMasterId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.fund_master_delete')
        );
        if (response) {
            this.props.deleteFundMasterByFundMasterIdRequest(fundMasterId);
        }
    };
    formSubmit = (values) => {
        this.setState({ fundMasterData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateFundMasterRequest(values.id, values);
        } else {
            this.props.addFundMasterRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            fundMasterData: initialFundMasterDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let fundMasterRecords = [];
        fundMasterRecords = records.map((fundMasterData, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title={__t('common.edit')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            this.props.getEntityListByCompanyGroupIdRequest([fundMasterData.companyGroupId]);
                            this.viewFundMaster(fundMasterData);
                        }}
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteFundMaster(fundMasterData.id);
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(fundMasterData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(fundMasterData.id)}
                        checked={fundMasterData.isActive ? true : false}
                    />
                    &nbsp;
                </div>
            );
            const fundEntityIds = (fundMasterData.entityId != '' && fundMasterData.entityId != null) ? fundMasterData.entityId.split(',')?.map(Number) : [];
            const fundEntityNames = this.state.entityListData.map((opt) => {
                if (fundEntityIds.includes(opt.id)) {
                    return opt.entityName;
                }
            })
            return {
                fundMasterName: fundMasterData.fundName,
                companyGroupName: fundMasterData.companyGroup?.companyGroupName,
                entityName: fundEntityNames.join('\r\n'),
                isActive: isActive,
                actions: actions,
            };
        });
        return fundMasterRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.allEntityList && this.props.allEntityList !== nextprops.allEntityList) {
            this.setState({
                entityListData: nextprops.allEntityList
            }, () => {
                this.props.getAllFundMasterListRequest()
            })
        }
        if (
            nextprops.fundMasterList &&
            this.props.fundMasterList !== nextprops.fundMasterList
        ) {
            this.setState({
                exportFundMasterList: nextprops.fundMasterList,
                fundMasterList: this.generateTable(nextprops.fundMasterList),
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.fundMasterList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="7">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4 className="with-back-btn mt-0 d-flex">
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.push('/funds');
                                                }}
                                            >
                                                { }
                                            </a>{' '}
                                            &nbsp; {__t(
                                                'page_headers.fund_master_list'
                                            )}{' '}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <CustomisedDatatable tableRecords={data} />
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="5">
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'page_headers.fund_master_form'
                                            )}
                                        </h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <AddFundMasterForm
                                        sortedCompanyGroupListDropdown={this.props.sortedCompanyGroupListDropdown}
                                        getEntityListByCompanyGroup={(cmpGroupIds) => {
                                            this.props.getEntityListByCompanyGroupIdRequest(cmpGroupIds);
                                        }}
                                        sortedEntityListDropdown={this.props.sortedEntityListDropdown}
                                        operationType={
                                            this.state.operationType
                                        }
                                        fundMasterData={
                                            this.state.fundMasterData
                                        }
                                        operationResponse={
                                            this.props.fundMasterApiResponse
                                        }
                                        onFormValid={this.formSubmit}
                                        onFormReset={this.resetForm}
                                    />
                                </CardBody>
                            </Card>
                        </Col>

                        <AuditLogModal
                    exportBtn={true}
                            isModalOpen={this.state.isAuditModalOpen}
                            modalName={__t('page_headers.audit_trails')}
                            auditType={'fundMaster'}
                            auditId={this.state.auditId}
                            onModalDismiss={() => this.closeAuditLogModal()}
                        />
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    allEntityList: makeEntitySelectField('entityList'),
    fundMasterList: makeFundMasterSelectField('fundMasterList'),
    fundMasterApiResponse: makeFundMasterSelectField('fundMasterApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getAllFundMasterListRequest,
    updateFundMasterStatusByFundMasterIdRequest,
    addFundMasterRequest,
    updateFundMasterRequest,
    deleteFundMasterByFundMasterIdRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getEntityListByStatusRequest
});
export default compose(
    withConnect,
    withFundMasterReducer,
    withFundMasterSaga,
    withEntityReducer,
    withEntitySaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withRouter
)(Index);
