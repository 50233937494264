import React, { Component } from 'react';
import { Row, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, CLEAR, SEARCH, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getUserEntityListRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    // getTaskStatusListRequest,
    getLawsListByLoginUserRequest,
    getLawCategoryListByStatusRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { initialChangeDetailsFilterDataState } from './CommonFunctions';
import __t from 'i18n/translator';
import { selectSortedLawListOfLoginUser } from 'store/Masters/Law/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
class ChangeDetailsFilterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            filterData: initialChangeDetailsFilterDataState(),
            operationType: SUBMIT,
        };
    }
    componentDidMount() {
        this.props.getUserEntityListRequest();
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getLawsListByLoginUserRequest();
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? stateIds : []);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                filterData: initialChangeDetailsFilterDataState(),
            },
            () => {
                this.props.onFormReset();
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.state.filterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropdown
                                        }
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let countryId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'countryId',
                                                countryId
                                            );
                                            setFieldValue('stateId', []);
                                            this.getStateListByCountry(
                                                countryId
                                            );
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            (option) =>
                                                values.countryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.task_filters.country'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedStateListDropdown
                                        }
                                        onChange={(opt) => {
                                            let stateId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('stateId', stateId);
                                            setFieldValue('cityId', []);
                                            this.getCityListByState(
                                                opt && opt.map((x) => x.value)
                                            );
                                        }}
                                        value={this.props.sortedStateListDropdown?.filter(
                                            (option) =>
                                                values.stateId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.task_filters.state')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCityListDropdown
                                        }
                                        onChange={(opt) => {
                                            let cityId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('cityId', cityId);
                                        }}
                                        value={this.props.sortedCityListDropdown?.filter(
                                            (option) =>
                                                values.cityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.task_filters.city')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={Select}
                                        name="entityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedEntityListDropdown
                                        }
                                        onChange={(opt) => {
                                            let entityId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('entityId', entityId);
                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            (option) =>
                                                values.entityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.task_filters.entity')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="lawCategoryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props
                                                .sortedLawCategoryListDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'lawCategoryId',
                                                selectedValues
                                            );
                                            setFieldValue('lawId', []);
                                        }}
                                        value={this.props.sortedLawCategoryListDropdown?.filter(
                                            (option) =>
                                                values.lawCategoryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">
                                        Law Category
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="lawId[]"
                                        className="form-control selectbox"
                                        options={
                                            values.lawCategoryId?.length > 0
                                                ? this.props.sortedLawListOfLoginUserDropdown.filter(
                                                    (opt) =>
                                                        values.lawCategoryId.includes(
                                                            opt.lawCategoryId
                                                        )
                                                )
                                                : this.props
                                                    .sortedLawListOfLoginUserDropdown
                                        }
                                        onChange={(opt) => {
                                            let selectedValues = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'lawId',
                                                selectedValues
                                            );
                                        }}
                                        value={this.props.sortedLawListOfLoginUserDropdown?.filter(
                                            (option) =>
                                                values.lawId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                    />
                                    <Label className="form-label">Law(s)</Label>
                                </FormGroup>
                                <FormGroup className="col-lg-12 d-flex justify-content-end mb-2">
                                    <div className="d-inline-block pt-0">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="mr-1"
                                        >
                                            {SEARCH}
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >
                                            {CLEAR}
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedLawListOfLoginUserDropdown: selectSortedLawListOfLoginUser(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getUserEntityListRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawsListByLoginUserRequest,
    getLawCategoryListByStatusRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withEntityReducer,
    withEntitySaga,
    withLawReducer,
    withLawSaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withRouter
)(ChangeDetailsFilterForm);
