import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.entityRole || initialState;
const makeEntityRoleSelectField = field =>
    createSelector(reducerState, entityRoleState => entityRoleState[field]);
const selectSortedEntityRoleList = () =>
    createSelector(makeEntityRoleSelectField('entityRoleList'), entityRoleList =>
        entityRoleList.map(opt => ({
            value: opt.id,
            label: opt.roleTerm,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );

const selectSortedRolesWithEntityRoleList = () =>
    createSelector(makeEntityRoleSelectField('roleList'), roleList =>
        roleList.map(opt => ({
            value: opt.id,
            label: (opt.EntityRoleTerminologies) ? ((opt?.EntityRoleTerminologies[0]?.roleTerm) ? opt.EntityRoleTerminologies[0].roleTerm : opt.roleName) : opt.roleName,
        })).sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    makeEntityRoleSelectField,
    selectSortedEntityRoleList,
    selectSortedRolesWithEntityRoleList
};
