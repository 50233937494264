export const ADD_COUNTRY_REQUEST = 'add_country_request';
export const ADD_COUNTRY_RESPONSE = 'add_country_response';
export const GET_ALL_COUNTRY_LIST_REQUEST = 'get_all_country_list_request';
export const GET_ALL_COUNTRY_LIST_RESPONSE = 'get_all_country_list_response';
export const GET_COUNTRY_BY_COUNTRY_ID_REQUEST = 'get_country_by_country_id_request';
export const GET_COUNTRY_BY_COUNTRY_ID_RESPONSE = 'get_country_by_country_id_response';
export const UPDATE_COUNTRY_REQUEST = 'update_country_request';
export const UPDATE_COUNTRY_RESPONSE = 'update_country_response';
export const UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_REQUEST = 'update_country_status_by_country_id_request';
export const UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_RESPONSE = 'update_country_status_by_country_id_response';
export const GET_COUNTRY_LIST_BY_STATUS_REQUEST = 'get_country_list_by_status_request';
export const GET_COUNTRY_LIST_BY_STATUS_RESPONSE = 'get_country_list_by_status_response';
export const DELETE_COUNTRY_BY_COUNTRY_ID_REQUEST = 'delete_country_by_country_id_request';
export const DELETE_COUNTRY_BY_COUNTRY_ID_RESPONSE = 'delete_country_by_country_id_response';
export const GET_GCM_AUTH_USER_COUNTRY_LIST_REQUEST = 'get_gcm_auth_user_country_list_request';
export const GET_GCM_AUTH_USER_COUNTRY_LIST_RESPONSE = 'get_gcm_auth_user_country_list_response';