import { createSelector } from 'reselect';
import { initialState } from './reducer';
const reducerState = state => state.SharedReducer || initialState;
const makeSharedSelectField = field =>
    createSelector(reducerState, sharedState => sharedState[field]);
const createLoadingSelector = () => state => {
    const sharedReducer = state.SharedReducer.Requests ?? {};
    const {
        // no loader for given requests
        get_dashboard_data_month_wise_by_role,
        get_dashboard_data_for_risk,
        get_all_sub_task_by_task_id_list,
        get_task_user_details_by_task_id,
        get_user_notification,
        get_collaborator_list_by_task_id,
        get_entity_teams_by_entity_id,
        ...newSharedReducer
    } = sharedReducer;
    const isAnyRequestInProcess = Object.values(newSharedReducer).includes(true);
    return isAnyRequestInProcess;

};
export { makeSharedSelectField, createLoadingSelector };
